import { compareDates, now } from '../../utils/dateUtils';
import { getExpirationDate } from '../../utils/digitalCodesUtils';

export const modalIdentifiers = {
	CANCEL_ORDER_MODAL: 'CANCEL_ORDER_MODAL',
	DELETE_ORDER_MODAL: 'DELETE_ORDER_MODAL',
	REVIEWER_APPROVAL_MODAL: 'REVIEWER_APPROVAL_MODAL',
	APPROVER_CONFIRM_APPROVAL_MODAL: 'APPROVER_CONFIRM_APPROVAL_MODAL',
	CANCEL_UPLOAD_MODAL: 'CANCEL_UPLOAD_MODAL',
	COMPLETE_ORDER_MODAL: 'COMPLETE_ORDER_MODAL',
	REJECT_REASON_MODAL: 'REJECT_REASON_MODAL',
	RESEND_ORDER_MODAL: 'RESEND_ORDER_MODAL',
	ASPERA_UPLOAD_CODES_MODAL: 'ASPERA_UPLOAD_CODES_MODAL',
	MANAGE_ACTIVATION_MODAL: 'MANAGE_ACTIVATION_MODAL',
	RETRACT_CODES_MODAL: 'RETRACT_CODES_MODAL',
	PAYMENT_MODAL: 'PAYMENT_MODAL',
} as const;

export const isUploadEligible = (orderData?: {
	is_upload_eligible: boolean;
	codes_upload_date: string | null;
}) => {
	// order data not is present, disqualify
	if (!orderData) {
		return false;
	}
	// order data has is_upload_eligible not set to true, disqualify
	if (!orderData.is_upload_eligible) {
		return false;
	}
	// no upload date in order data, allow
	if (!orderData.codes_upload_date) {
		return true;
	}
	// today's date is past the expiration date (not same day), disqualify
	if (compareDates(getExpirationDate(orderData.codes_upload_date), now(), true) === -1) {
		return false;
	}

	return true;
};
