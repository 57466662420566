import React, { Component } from 'react';
import { Alert, Col, FormControl, Row } from 'react-bootstrap';

import BaseModal from '../../../../components/BaseModal/BaseModal';
import HelpBlock from '../../../../components/HelpBlock/HelpBlock';
import { postProductRightsTransferRequests } from '../../../../services/productTransfersService';
import { toasterNotify, createMessageForError } from '../../../../utils/toaster';


export default class AcceptOwnershipTransferModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			display: true,
			acceptComment: ''
		};
	}

	acceptTransfer() {
		const {
			closeModal,
			updateTransferStatus,
			productRightsTransferId,
			newTransferStatus
		} = this.props;
		const { acceptComment } = this.state;
		const payload = {
			transfer_status: newTransferStatus,
			comment: acceptComment
		};


		postProductRightsTransferRequests(
			productRightsTransferId,
			payload
		)
			.then((response) => {
				updateTransferStatus(newTransferStatus);
				toasterNotify('Product rights transfer executed', 'success');
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'accepting a product owner rights transfer'),
					'error',
					error
				);
			})
			.finally(() => {
				closeModal();
			});
	}

	render() {
		const { closeModal } = this.props;
		const { display, acceptComment } = this.state;

		return (
			<BaseModal show={display} onCancel={closeModal}>
				<BaseModal.Title>Accept Transfer</BaseModal.Title>
				<Alert variant="info">
					This action will approve the ownership transfer. The
					initial publisher will no longer have access to this
					product. The new publisher will now be the owner.
				</Alert>
				<Row>
					<Col sm={3}>
						<strong>Comments:</strong>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<FormControl
							as="textarea"
							rows="6"
							value={acceptComment}
							onChange={(e) => {
								this.setState({
									acceptComment: e.target.value
								});
							}}
						/>
						<HelpBlock>
							*Optional
						</HelpBlock>
					</Col>
				</Row>
				<BaseModal.Submit
					onClick={() => {
						this.acceptTransfer();
					}}
				>
					Accept Transfer
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}
