import React, { useState } from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link, useHistory, useLocation } from 'react-router-dom';

import Announcement from '../../components/Announcement/Announcement';
import BaseModal from '../../components/BaseModal/BaseModal';
import FAIcon from '../../components/FAIcon/FAIcon';
import SimpleModal from '../../components/modals/SimpleModal/SimpleModal';
import Page from '../../components/Page/Page';
import PropertyDisplay from '../../components/PropertyDisplay/PropertyDisplay';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import { useUserProfile } from '../../hooks/reduxHooks';
import { getAnnouncementsPublished } from '../../services/announcementService';
import { dateFormat, formatDate } from '../../utils/dateUtils';

import switchSplash from '../../images/switch_splash.png';
import './Home.css';

declare global {
	var __BUILD_INFO__: any;
}

const LazyLoadImage = (props: any) => {
	const [completed, setComplete] = useState(false);
	return (
		<img
			alt=""
			{...props}
			onLoad={() => setComplete(true)}
			style={{ opacity: completed ? '1.0' : '0', transition: 'opacity 0.4s ease-out' }}
		/>
	);
};

const Home = () => {
	const [showBrowserWarning, setShowBrowserWarning] = useState('PDBSupportedBrowser' in global && global.PDBSupportedBrowser === false);
	const userProfile = useUserProfile();
	const history = useHistory();
	const location = useLocation();

	const announcementsQuery = useQuery(
		'getAnnouncementsPublished',
		() => getAnnouncementsPublished(),
		{ enabled: !!userProfile },
	);
	const rtnAnnouncements = announcementsQuery.data?.data;
	const isLoading = announcementsQuery.isLoading;

	function displayAnnoucements() {
		if (rtnAnnouncements && !isLoading) {
			return (
				<Container className="Home__announcements_area">
					<Row>
						<Col sm={{ span: 10, offset: 1 }} className="mt-3">
							{rtnAnnouncements.map((announcement: any, index: number) => (
								<Announcement
									announcement={announcement}
									key={`announcement-${index}`}
								/>
							))}
						</Col>
					</Row>
				</Container>
			);
		}
		return null;
	}

	const errorType = new URLSearchParams(location.search).get('error');

	return (
		<Page documentTitle="Home" className="Home" containerClass="mb-5">
			<div className="container-fluid Home__hero">
				<div className="row">
					<div className="Home__splash">
						{displayAnnoucements()}
						<LazyLoadImage
							src={switchSplash}
							alt="Welcome to the Nintendo Publisher Tool"
							width="1200"
							height="340"
						></LazyLoadImage>
					</div>
				</div>
			</div>

			<div className="container-fluid">
				<div className="container">
					<Row>
						<Col sm={{ span: 10, offset: 1 }} className="py-4">
							<SectionTitle>To use this site</SectionTitle>
							<ol className="Home__requirements-list">
								<li>A Company account exist on the Nintendo Developer Portal.</li>
								<li>A current Nintendo Publisher Licensing Agreement.</li>
								<li>You must have a Nintendo Developer ID (NDID).</li>
								<li>
									Your Nintendo Developer Portal Company Admin must assign your
									site roles.
								</li>
								<li>
									Use of this site is pursuant to the terms and conditions in your
									Nintendo Non-Disclosure Agreement (NDA).
								</li>
							</ol>
						</Col>
					</Row>
				</div>
			</div>
			<BaseModal show={!!errorType} size="lg">
				<div className="d-flex flex-row align-items-center">
					<FAIcon className="text-danger m-5" name="exclamation-triangle" size="5x" />
					<div className="mr-5">
						<h3>
							{errorType === 'UNKNOWN'
								? 'You have not been granted a role in the Nintendo Publisher Tool. Please contact your Nintendo Developer Portal Company Admin for assistance.'
								: errorType === 'INACTIVE'
								? 'Your Nintendo Publisher Tool access has been locked. Please contact your Nintendo Developer Portal Company Admin for assistance.'
								: 'Unexpected error logging into PDB. Please contact site support.'}
						</h3>
						<p>
							You may sign out of NDID and Nintendo Developer Portal to sign in again:{' '}
							<Link to="/logout">
								Sign out of NDID <FAIcon size="xs" name="chevron-right" />
							</Link>
						</p>
					</div>
				</div>
				<BaseModal.Submit variant="outline-secondary" onClick={() => history.replace('/')}>
					Close
				</BaseModal.Submit>
			</BaseModal>
			<BaseModal show={!errorType && showBrowserWarning}>
				<BaseModal.Title>Possible Browser Incompatibility</BaseModal.Title>
				<div className="d-flex flex-row align-items-center">
					<Alert className="mb-0" variant="warning">
						Nintendo Publisher Tool has been tested with the latest version of Google
						Chrome and Mozilla Firefox installed in the Windows environment. In order to
						use this site, JavaScript and cookies must be enabled.
					</Alert>
				</div>
				<BaseModal.Submit
					variant="outline-secondary"
					onClick={() => setShowBrowserWarning(false)}
				>
					Close
				</BaseModal.Submit>
			</BaseModal>
			<SimpleModal
				show={location.pathname === '/version'}
				closeModal={() => history.push('/')}
				title="About Publisher Tool Client"
			>
				<PropertyDisplay label="Build ID">{__BUILD_INFO__.SHA}</PropertyDisplay>
				<PropertyDisplay label="Build Date">
					{formatDate(__BUILD_INFO__.DATE, dateFormat.DATETIME_PT)}
				</PropertyDisplay>
			</SimpleModal>
		</Page>
	);
};

export default Home;
