import React from 'react';
import { Breadcrumb as BoostrapBreadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { isAuthorizedForUrl } from '../../utils/userUtils';

const BreadcrumbItem = (props) => {
	if (props.to) {
		if (isAuthorizedForUrl(props.to)) {
			const newProps = { ...props, to: undefined };
			newProps.linkAs = Link;
			newProps.linkProps = { to: props.to };
			return <BoostrapBreadcrumb.Item {...newProps} />;
		}
	}
	const newProps = { ...props, to: undefined, active: props.to ? true : props.active };
	return <BoostrapBreadcrumb.Item {...newProps} />;
};

const Breadcrumb = (props) => <BoostrapBreadcrumb {...props} />;
Breadcrumb.Item = BreadcrumbItem;

export default Breadcrumb;
