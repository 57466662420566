import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { dateFormat, formatDate, parseDateString } from '../../utils/dateUtils';

const Announcement = ({ announcement, dismissible = true }) => {
	const [display, setDisplay] = useState(true);
	let tmpParameters = announcement.parameters || [];
	let tmpMessage = announcement.message;
	if (!announcement.parameters && announcement.template_parameters) {
		announcement.template_parameters.forEach((t) => {
			if (announcement[t.announcement_column_name]) {
				tmpParameters[t.announcement_column_name] = {
					value: announcement[t.announcement_column_name],
					type: t.parameter_data_type,
				};
			}
		});
	}
	if (tmpParameters) {
		Object.keys(tmpParameters).forEach(function(parameterKey) {
			var parameterValue = tmpParameters[parameterKey];
			let fmtValue = parameterValue.value;
			if (parameterValue.type === 'DATETIME')
				fmtValue = formatDate(
					parseDateString(parameterValue.value),
					dateFormat.DATETIME_PT,
				);
			if (parameterValue.type === 'DATE')
				fmtValue = formatDate(parseDateString(parameterValue.value), dateFormat.DATE);
			// Replace the parameter in the message
			tmpMessage = tmpMessage.replace('{' + parameterKey + '}', fmtValue);
		});
	}

	let tmpAlert = 'info';
	if (announcement.priority_code === 'CRITICAL') {
		tmpAlert = 'danger';
	} else if (announcement.priority_code === 'MAJOR') {
		tmpAlert = 'warning';
	}

	return (
		(display && (
			<Alert
				className="alert-icon"
				variant={tmpAlert}
				style={{ marginLeft: '15px', marginRight: '15px' }}
				dismissible={dismissible}
				onClose={(dismissible && ((e) => setDisplay(false))) || null}
			>
				<strong>{announcement.display_heading}:</strong> {tmpMessage}
			</Alert>
		)) ||
		null
	);
};

export default Announcement;
