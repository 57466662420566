import React from 'react';

import { useCompanyNamesBulkQuery, useUserNamesBulkQuery } from '../EventTaskModel.hooks';
import EventTaskModalView, { READ, NONE } from '../views/EventTaskModalView';

import { useTaskQuery } from '../EventTaskModel.hooks';
import { eventConstants } from '../../../../constants/eventConstants';


const { INTERNAL } = eventConstants.TASKS.TYPE;

const getFieldPermissions = (task) => ({
	active: READ,
	status: READ,
	assignment_type: READ,
	name: READ,
	comment: READ,
	assigned_ndid_company_id: task.assignment_type === INTERNAL ? NONE : READ,
	assigned_ndid_user_id: READ,
	due_date: READ,
});

const ReadOnlyContainer = ({ show, taskId, onClose, currentAttachment }) => {
	const tasksQuery = useTaskQuery(taskId, { enabled: !!show });
	const task = tasksQuery.isSuccess && tasksQuery.data.data;
	const token = tasksQuery.isSuccess &&
		!tasksQuery.isFetching &&
		(Date.now() - tasksQuery.dataUpdatedAt < 60000) && // token expires in 1 min
		tasksQuery.data.headers['x-pdb-authorization'];

	const userId = show && task && task.assigned_ndid_user_id;
	const assignedCompanyId = show && task && task.assigned_ndid_company_id;
	const unassignedCompanyId = show && task && task.unassigned_ndid_company_id;
	const companyIds = [assignedCompanyId, unassignedCompanyId].filter((id) => id);

	const companyNameQuery = useCompanyNamesBulkQuery(companyIds, null, {
		enabled: !!(show && companyIds.length > 0 && token),
	});
	const userNameQuery = useUserNamesBulkQuery([userId], token, {
		enabled: !!(show && userId && token)
	});

	const isLoading = tasksQuery.isLoading || userNameQuery.isLoading || companyNameQuery.isLoading;
	const fault = userNameQuery.error || companyNameQuery.error;
	const unassignedPublisher =
		(companyNameQuery.isSuccess &&
			companyNameQuery.data.data.filter(
				(company) => company.ndid_company_id === unassignedCompanyId,
			)) ||
		[];
	const assignedPublisher =
		(companyNameQuery.isSuccess &&
			companyNameQuery.data.data.filter(
				(company) => company.ndid_company_id === assignedCompanyId,
			)) ||
		[];


	return (
		<EventTaskModalView
			show={show}
			onClose={onClose}
			submitForm={null}
			isSubmitting={false}
			isLoading={isLoading}
			fault={fault}
			title="View Task"
			initialValues={task}
			assignableUsers={userNameQuery.isSuccess ? userNameQuery.data.data : []}
			assignableCompanies={assignedPublisher}
			schema={null}
			userPermissions={null}
			fieldPermissions={getFieldPermissions(task)}
			currentAttachment={currentAttachment}
			pastPublisherAssignee={unassignedPublisher.length ? unassignedPublisher[0].company_name : null}
		/>
	);
};
export default ReadOnlyContainer;
