import api from './baseService';


interface HelpTopic {
	help_topic_id: number;
	name: string;
	content_type: string;
}

export const getHelpTopics = () =>
	api().get<HelpTopic>('/pdb/help');

export const getAdminHelpTopics = () =>
	api().get<AdminHelpTopic[]>('/pdb/help_topics');

export const postHelpTopic = (payload: Record<string, any>) =>
	api().post('/pdb/help_topics', payload);

export const deleteHelpTopic = (id: number) =>
	api().delete(`/pdb/help_topics/${id}`);

export const putHelpTopic = (id: number, payload: Record<string, any>) =>
	api().put(`/pdb/help_topics/${id}`, payload);
