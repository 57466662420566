import React from 'react';
import { eventConstants } from '../../../constants/eventConstants';
import { displayString } from '../../../utils/stringUtils';


const EventTaskStatusCell = ({ value }) => {
	switch (value) {
		case eventConstants.TASKS.STATUS.OPEN:
			return <strong className="text-info">{displayString(value).toUpperCase()}</strong>;
		case eventConstants.TASKS.STATUS.IN_PROGRESS:
			return (
                <strong className="text-warning">{displayString(value).toUpperCase()}</strong>
			);
		default:
		case eventConstants.TASKS.STATUS.DONE:
			return (
                <strong className="text-secondary">{displayString(value).toUpperCase()}</strong>
			);
	}
};

export default EventTaskStatusCell;
