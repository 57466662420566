import { digitalCodesConstants } from '../../../constants/digitalCodesConstants';
import { permConst } from '../../../constants/permConst';
import { now } from '../../../utils/dateUtils';
import { getCurrentRequest } from '../../../utils/digitalCodesUtils';
import { isAuthorized } from '../../../utils/userUtils';


const { APPROVED } =
	digitalCodesConstants.priceRequestStatuses;

const { EXTENDED_LIMIT } =
	digitalCodesConstants.priceRequestActions;

const getValidPriceRequests = (requests: WholesalePriceRequestData[] | DeclaredPriceRequestData[]) => {
	return [...requests]
        .sort((a, b) => (new Date(b.submitted_datetime).valueOf()) - (new Date(a.submitted_datetime)).valueOf());
};

export const getInProcessPriceRequest = (
	requests: WholesalePriceRequestData[] | DeclaredPriceRequestData[],
) => {
	const validRequests = getValidPriceRequests(requests);
	return validRequests.find((request) => /IN[\s_]PROCESS/.test(request.rollup_status));
};

export const getActivePriceRequest = (
	requests: WholesalePriceRequestData[] | DeclaredPriceRequestData[],
): WholesalePriceRequestData | DeclaredPriceRequestData | undefined => {
	const validRequests = getValidPriceRequests(requests);
	return validRequests.find((request) => request.rollup_status === 'APPROVED');
};

export const getEffectiveDateForPriceRequest = (
	request: WholesalePriceRequestData | DeclaredPriceRequestData,
): string | undefined => {
	if (!request || request.actions?.some(
		(action) =>
			action.request_status === 'DECLINED' || action.request_status === 'RETRACTED',
	)) {
		return;
	}
	const approvalAction = request?.actions?.find(
		(action) =>
			action.request_status === 'APPROVED' || action.price_status === 'ACTIVE',
	);
	return approvalAction ? approvalAction.action_timestamp : undefined;
};

export const getWSPPermissions = (userProfile: UserProfile) => ({
	hasInternalPermission: isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.VIEW.ALL.THIRD,
	]),
	canReviewerApprove: isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.COMPONENT.WHOLESALE_PRICE.REQUEST.REVIEW.ALL,
	]),
	canApproverApprove: isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.COMPONENT.WHOLESALE_PRICE.REQUEST.APPROVE.ALL,
	]),
	canAgree: isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.COMPONENT.WHOLESALE_PRICE.REQUEST.ADD.COMPANY,
	]),
	canRetract: isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.COMPONENT.WHOLESALE_PRICE.REQUEST.RETRACT.COMPANY,
	]),
	canExtendLimit: isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.COMPONENT.WHOLESALE_PRICE.REQUEST.LIMIT.EDIT.ALL,
	]),
	canComment: isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.COMPONENT.WHOLESALE_PRICE.REQUEST.ADD.COMPANY,
		permConst.PRODUCT.COMPONENT.WHOLESALE_PRICE.REQUEST.APPROVE.ALL,
		permConst.PRODUCT.COMPONENT.WHOLESALE_PRICE.REQUEST.LIMIT.EDIT.ALL,
		permConst.PRODUCT.COMPONENT.WHOLESALE_PRICE.REQUEST.RETRACT.COMPANY,
		permConst.PRODUCT.COMPONENT.WHOLESALE_PRICE.REQUEST.REVIEW.ALL,
	]),
});

export const getDPPermissions = (userProfile: UserProfile) => ({
	hasInternalPermission: isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.VIEW.ALL.THIRD,
	]),
	canReviewerApprove: isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.COMPONENT.DECLARED_PRICE.REQUEST.REVIEW.ALL,
	]),
	canApproverApprove: isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.COMPONENT.DECLARED_PRICE.REQUEST.APPROVE.ALL,
	]),
	canAgree: isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.COMPONENT.DECLARED_PRICE.REQUEST.ADD.COMPANY,
	]),
	canRetract: isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.COMPONENT.DECLARED_PRICE.REQUEST.RETRACT.COMPANY,
	]),
	canExtendLimit: isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.COMPONENT.DECLARED_PRICE.REQUEST.LIMIT.EDIT.ALL,
	]),
	canComment: isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.COMPONENT.DECLARED_PRICE.REQUEST.ADD.COMPANY,
		permConst.PRODUCT.COMPONENT.DECLARED_PRICE.REQUEST.APPROVE.ALL,
		permConst.PRODUCT.COMPONENT.DECLARED_PRICE.REQUEST.LIMIT.EDIT.ALL,
		permConst.PRODUCT.COMPONENT.DECLARED_PRICE.REQUEST.RETRACT.COMPANY,
		permConst.PRODUCT.COMPONENT.DECLARED_PRICE.REQUEST.REVIEW.ALL,
	]),
});

export const isExtendLimitDisabled = (
	requests: (WholesalePriceRequestData | DeclaredPriceRequestData)[],
	rightsHolderId: string,
): boolean => {

	// Disable if no approved request on component
	if (!requests.length) {
		return true;
	}

	const approvedRequests = requests.filter((request) =>
		request.actions?.some(
			(action) => action.action_type === APPROVED && action.request_status === APPROVED,
		),
	);
	if (approvedRequests.length < 1) {
		return true;
	}

	const currentRequest = getCurrentRequest(
		requests,
		rightsHolderId,
	);

	// Disable if there is an extension on the most recently approved request
	if (
		currentRequest?.actions?.some(
			(action) => action.action_type === EXTENDED_LIMIT,
		)
	) {
		return true;
	}

	// Enable if most recently approved WSP was approved on the component within the last 6 mos ago
	if (currentRequest && 'wholesale_price_request_id' in currentRequest) {
		const approvalAction = currentRequest?.actions?.find(
			(action) => action.action_type === APPROVED && action.request_status === APPROVED,
		);
		const sixMonthsAgo = now().subtract(6, 'M');
		if (new Date(String(approvalAction?.action_timestamp)).valueOf() > sixMonthsAgo.valueOf()) {
			return false;
		}
	}

	// Enable if most recently approved declared price
	if (currentRequest && 'declared_price_request_id' in currentRequest && approvedRequests.length > 0) {
		return false;
	}

	// Enable if there is more than one approved request
	if (approvedRequests.length > 1) {
		return false;
	}

	// Disable in all other cases.
	return true;
};
