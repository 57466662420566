import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useQuery } from 'react-query';
import * as yup from 'yup';

import { eventConstants } from '../../../../constants/eventConstants';
import { roleConstants } from '../../../../constants/roleConstants';
import { getEventDetailPermissions } from '../../../../utils/eventUtils';
import { useUserProfile } from '../../../../hooks/reduxHooks';
import { putEventTask } from '../../../../services/eventsService';
import { getRoleAssignedUsers } from '../../../../services/usersService';
import { removeDuplicates, safeEval } from '../../../../utils/dataUtils';
import { deleteMarketingEventTaskAttachment } from '../../../../utils/eventUtils';
import { toasterNotify } from '../../../../utils/toaster';
import {
	useCompanyNamesBulkQuery,
	useEventDetailQuery,
	useEventProductsQuery,
	useTaskQuery,
	useUserNamesBulkQuery,
} from '../EventTaskModel.hooks';
import EventTaskModalView, { NONE, READ, WRITE } from '../views/EventTaskModalView';


const { INTERNAL, EXTERNAL } = eventConstants.TASKS.TYPE;
const { OPEN } = eventConstants.TASKS.STATUS;

export const schema = yup.object().shape({
	id: yup.mixed(),
	active: yup.boolean(),
	status: yup.string()
		.default(OPEN),
	assignment_type: yup
		.string(),
	name: yup
		.string()
		.nullable()
		.required('Name must be supplied'),
	comment: yup.string()
		.nullable()
		.default(''),
	due_date: yup.string()
		.default('')
		.nullable()
		.when('active', {
			is: (value) => value === true,
			then: yup.string().nullable().required('Due date must be supplied if task is active'),
		}),
	assigned_ndid_company_id: yup.mixed().nullable()
		.when(['assignment_type'], {
			is: (assignment_type) => assignment_type === eventConstants.TASKS.TYPE.EXTERNAL,
			then: yup.string()
				.when(['active'], {
					is: true,
					then: (schema) => schema
						.required('Publisher assignee must be supplied if task is active'),
					otherwise:(schema) => schema
						.default('')
						.nullable()
						.transform((value, originalValue) => !value ? '' : originalValue),
				}),
		}),
	assigned_ndid_user_id: yup.mixed().nullable()
		.when(['assignment_type'], {
			is: (assignment_type) => assignment_type === eventConstants.TASKS.TYPE.INTERNAL,
			then: yup.string()
				.default('')
				.nullable()
				.transform((value, originalValue) => !value ? '' : originalValue),
		}),
});

const EditContainer = ({ show, onClose, onChange, eventId, taskId, currentAttachment }) => {

	const [isSubmitting, setIsSubmitting] = useState(false);
	const userProfile = useUserProfile();

	// data load flow
	const userIds = [];
	const companyIds = [];

	const eventQuery = useEventDetailQuery(eventId, { enabled: !!show });
	const event = eventQuery.isSuccess && eventQuery.data.data;
	const accessType = eventQuery.isSuccess && eventQuery.data.headers['x-pdb-access-type'];

	const tasksQuery = useTaskQuery(taskId, { enabled: !!show });
	const task = tasksQuery.isSuccess && tasksQuery.data.data;
	const tasksToken = tasksQuery.isSuccess &&
		!tasksQuery.isFetching &&
		(Date.now() - tasksQuery.dataUpdatedAt < 60000) && // token expires in 1 min
		tasksQuery.data.headers['x-pdb-authorization'];

	const {
		canEditActiveOnTasks,
		canEditExternalAssigneeOnTasks,
		canEditInternalAssigneeOnTasks,
		canEditPublisherAssigneeOnTasks,
		canClaimExternalAssignmentOnTasks,
		canClaimInternalAssignmentOnTasks,
		canEditDetailOnTasks, // detail = task name, due date
		canEditDetailOnTasksIfAssigned,
		canEditWorkItemsOnTasks, // work items = status, comment, attachment
		canEditWorkItemsOnTasksIfAssigned
	}  = getEventDetailPermissions(userProfile, accessType);

	if (event && task) {
		// Add managers to user id lookup
		if (task.assignment_type !== EXTERNAL && event.managers && canEditInternalAssigneeOnTasks) {
			userIds.push(...event.managers);
		}
		// add unassigned publishers to company id lookup
		if (task.unassigned_ndid_company_id) {
			companyIds.push(task.unassigned_ndid_company_id);
		}
		// add assigned user to user id lookup
		if (task.assigned_ndid_user_id) {
			userIds.push(task.assigned_ndid_user_id);
		}
		// add self to user id lookup if self is eligible to claim
		// external users looking at external tasks will not receive a value for assignment_type, so
		// if they have the permission, the test will pass and their names will be added as an option
		// for assignment.
		if (
			task.assignment_type !== EXTERNAL &&
			(canClaimExternalAssignmentOnTasks ||
				canClaimInternalAssignmentOnTasks ||
				canEditExternalAssigneeOnTasks ||
				canEditInternalAssigneeOnTasks)
		) {
			userIds.push(userProfile.userId);
		}
		// add support users to user id lookup if able to assign external tasks
		if (canEditExternalAssigneeOnTasks && event.support) {
			userIds.push(...event.support);
		}
	}

	const productsQuery = useEventProductsQuery(eventId, {
		enabled: !!(
			show &&
			eventQuery.isSuccess &&
			tasksQuery.isSuccess &&
			event.active !== false &&
			(canEditExternalAssigneeOnTasks || canEditInternalAssigneeOnTasks || canEditPublisherAssigneeOnTasks)
		),
	});
	const products =
		productsQuery.isSuccess && !productsQuery.isFetching && productsQuery.data.data;
	const productsToken =
		productsQuery.isSuccess &&
		!productsQuery.isFetching &&
		(Date.now() - productsQuery.dataUpdatedAt < 60000) && // token expires in 1 min
		productsQuery.data.headers['x-pdb-authorization'];


	if (productsQuery.isSuccess && products) {
		products.forEach((product) => {
			if (product.active) {
				if (task.assignment_type !== EXTERNAL && canEditInternalAssigneeOnTasks) {
					userIds.push(...product.coordinators);
				}
				if (task.assignment_type !== INTERNAL && canEditPublisherAssigneeOnTasks) {
					companyIds.push(...product.companies);
				}

			}
		});
	}

	const externalUsersQuery = useQuery(
		['getRoleAssignedUsers', roleConstants.MARKETING_EVENTS_LEAD],
		() => getRoleAssignedUsers([roleConstants.MARKETING_EVENTS_LEAD, roleConstants.MARKETING_EVENTS_SUPPORT]),
		{ enabled: !!(show && canEditExternalAssigneeOnTasks) },
	);
	const externalUsers = externalUsersQuery.isSuccess && externalUsersQuery.data.data;

	const internalUsersQuery = useQuery(
		['getRoleAssignedUsers', roleConstants.MARKETING_EVENTS_TEAM_MEMBER],
		() => getRoleAssignedUsers(roleConstants.MARKETING_EVENTS_TEAM_MEMBER),
		{
			enabled: !!(
				show &&
				canEditInternalAssigneeOnTasks &&	
				task &&
				task.assignment_type === INTERNAL
			),
		},
	);

	const assignedUserQuery = useUserNamesBulkQuery(
		[],
		tasksToken,
		{ enabled: !!(
			show &&
			task &&
			tasksToken &&
			task.assigned_ndid_user_id &&
			task.assignment_type === EXTERNAL
		) }
	);
	const tokenForCompanyNameQuery = canEditPublisherAssigneeOnTasks ? productsToken : tasksToken;
	const companyNameQuery = useCompanyNamesBulkQuery(
		removeDuplicates(companyIds),
		null,
		{
			enabled: !!(
				show &&
				!eventQuery.isLoading &&
				!tasksQuery.isLoading &&
				!productsQuery.isLoading &&
				tokenForCompanyNameQuery &&
				companyIds.length > 0
			),
		},
	);

	const companies = companyNameQuery.isSuccess
		? companyNameQuery.data.data.filter((company) =>
			companyIds.includes(company.ndid_company_id),
		  )
		: [];

	const pastPublisherAssignee =
		companies &&
		(
			companies.find(
				(company) => company.ndid_company_id === task.unassigned_ndid_company_id,
			) || {}
		).company_name;


	const isLoading =
		tasksQuery.isLoading ||
		eventQuery.isLoading ||
		productsQuery.isLoading ||
		externalUsersQuery.isLoading ||
		internalUsersQuery.isLoading ||
		assignedUserQuery.isLoading ||
		companyNameQuery.isLoading;

	const fault = eventQuery.error;

	const getUsersForAssignmentList = () => {
		if (isLoading) {
			return [];
		}
		// Take output from internal users query or tokened query and filter out names that are not on the userIds list
		const unfilteredUsers = [];
		if (internalUsersQuery.isSuccess) {
			unfilteredUsers.push(...internalUsersQuery.data.data);
		}
		if (externalUsersQuery.isSuccess) {
			unfilteredUsers.push(...externalUsers);
		}
		if (assignedUserQuery.isSuccess) {
			unfilteredUsers.push(...assignedUserQuery.data.data);
		}
		const users = unfilteredUsers.filter((user) => userIds.includes(user.ndid_user_id));

		// if self is not already in the users list
		if (!users.some(user => user.ndid_user_id === userProfile.userId)) {
			// and user can claim self as an assigned user
			if ((canClaimExternalAssignmentOnTasks && task.assignment_type !== INTERNAL) || (canClaimInternalAssignmentOnTasks && task.assignment_type === INTERNAL))  {
				// add self to users list
				users.push({'ndid_user_id': userProfile.userId, 'user_name' : userProfile.fullName});
			}
		}
		// if company leads data was loaded
		if (canEditExternalAssigneeOnTasks && externalUsersQuery.isSuccess) {
			// for each lead user
			externalUsers
				.filter((user) => user.roles.split(',').includes(roleConstants.MARKETING_EVENTS_LEAD))
				.forEach(lead => {
					// if user isn't already in the list, then add that user.
					if (!users.some(user => user.ndid_user_id === lead.ndid_user_id)) {
						users.push(lead);
					}
				});
		}

		return users;
	};

	const getFieldPermissions = (formValues={}) => {
		const isAssigned = task && task.assigned_ndid_user_id === userProfile.userId;
		const assigned_ndid_company_id =
			formValues.assignment_type === EXTERNAL && canEditPublisherAssigneeOnTasks
				? WRITE
				: NONE;

		// if assignment_type === 'E', that means the user is a manager and the task is external. If both are true, this field is read-only.
		const assigned_ndid_user_id =
			formValues.assignment_type !== EXTERNAL &&
			(canEditInternalAssigneeOnTasks ||
				canClaimInternalAssignmentOnTasks ||
				canEditExternalAssigneeOnTasks)
				? WRITE
				: READ;

		const detailPerm = (canEditDetailOnTasks || (canEditDetailOnTasksIfAssigned && isAssigned)) ? WRITE : READ;
		const workItemPerm = (canEditWorkItemsOnTasks || (canEditWorkItemsOnTasksIfAssigned && isAssigned)) ? WRITE : READ;

		return {
			active: canEditActiveOnTasks ? WRITE : READ,
			status: workItemPerm,
			assignment_type: READ,
			name: detailPerm,
			comment: workItemPerm,
			assigned_ndid_company_id,
			assigned_ndid_user_id,
			due_date: detailPerm,
		};
	};

	useEffect(() => {
		if (!show) {
			setIsSubmitting(false);
			eventQuery.remove();
			externalUsersQuery.remove();
			companyNameQuery.remove();
			productsQuery.remove();
			tasksQuery.remove();
		}
	}, [show]);


	const submitForm = async (formValues, uploadCallback) => {
		const taskId = formValues.id;
		const castedFormValues = schema.cast(formValues, { stripUnknown: true });
		const fieldPermissions = getFieldPermissions(formValues);

		const payload = Object.keys(castedFormValues).reduce((reduction, key) => {
			if (fieldPermissions[key] === WRITE) {
				reduction[key] = castedFormValues[key];
			}
			return reduction;
		}, {});
		if (safeEval(() => payload.due_date) && payload.due_date === task.due_date) {
			delete payload.due_date;
		}
		setIsSubmitting(true);
		try {
			await putEventTask(taskId, payload);
			if (uploadCallback) {
				if (hasAttachment) {
					await deleteMarketingEventTaskAttachment(taskId, currentAttachment.s3_content_metadata_id);
				}
				await uploadCallback(taskId.toString());
			}
			onChange();
			toasterNotify(
                `Task "${castedFormValues.name}" has been changed`,
                'success',
			);
		} finally {
			setIsSubmitting(false);
		}
	};

	const hasAttachment = safeEval(() => currentAttachment.attachment_file_name) ? true : false;

	return (
		<EventTaskModalView
			show={show}
			onClose={onClose}
			submitForm={submitForm}
			isSubmitting={isSubmitting}
			isLoading={isLoading}
			fault={fault}
			title="Edit Task"
			initialValues={task}
			assignableUsers={getUsersForAssignmentList()}
			assignableCompanies={companies.filter(company => company.ndid_company_id !== task.unassigned_ndid_company_id)}
			schema={schema}
			fieldPermissions={getFieldPermissions}
			pastPublisherAssignee={pastPublisherAssignee}
			showNewAttachment={hasAttachment ? false : true}
			showReplacementAttachment={hasAttachment ? true : false}
			currentAttachment={currentAttachment}
		>
			{pastPublisherAssignee && (
				<Alert variant="warning">
					This task had a Publisher assignee who had all of their products removed from
					the event or went through transfers. Please select a new Publisher assignee.
				</Alert>
			)}
		</EventTaskModalView>
	);
};
export default EditContainer;
