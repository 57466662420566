import React from 'react';

import BaseTable from '../../../components/BaseTable/BaseTable';
import DateCell from '../../../components/cells/DateCell/DateCell';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { dateFormat, formatDate } from '../../../utils/dateUtils';
import { mapStatusCssClass } from '../CompanyProfile.helpers';

const AgreementsTab = ({ companyAgreements }) => {
	return (
		<>
			<SectionTitle>Agreements</SectionTitle>
			<BaseTable
				id="company-profile-agreements"
				data={companyAgreements}
				columns={[
					{ Header: 'Agreement Holder', accessor: 'agreementHolder' },
					{
						Header: 'Agreement Type',
						accessor: 'agreementType',
						Cell: ({ value }) =>
							value.charAt(0).toUpperCase() +
							value.toString().substring(1).toLowerCase(),
					},
					{ Header: 'Contract Date', accessor: 'contractDate', Cell: DateCell },
					{
						Header: 'Expiration Date',
						accessor: 'expirationDate',
						Cell: ({ value }) => {
							if (!value) {
								return null;
							}
							return <span>{formatDate(value, dateFormat.DATE, 'Pending')}</span>;
						},
					},
					{ Header: 'Platform', accessor: 'platform' },
					{
						Header: 'Status',
						accessor: 'status',
						Cell: ({ value }) => <b className={mapStatusCssClass(value)}>{value}</b>,
					},
				]}
				defaultSorted={[
					{ id: 'platform', desc: false },
					{ id: 'agreementType', desc: false },
				]}
				showPagination={false}
				defaultPageSize={companyAgreements.length}
			/>
		</>
	);
};
export default AgreementsTab;
