import React from 'react';
import { Tab } from 'react-bootstrap';

import Page from '../../components/Page/Page';
import StatefulTabs from '../../components/StatefulTabs/StatefulTabs';
import Title from '../../components/Title/Title';
import { useUserProfile } from '../../hooks/reduxHooks';
import { getPermissionsForAssets } from './ApprovalQueue.helpers';
import CodeInBoxAssetsTab from './views/CodeInBoxAssetsTab';
import DPReviewTab from './views/DPReviewTab';
import EventAssetsTab from './views/EventAssetsTab.js';
import MarketingAssetsTab from './views/MarketingAssetsTab.js';
import PackagingAssetsTab from './views/PackagingAssetsTab.js';
import WSPReviewTab from './views/WSPReviewTab.js';

import './ApprovalQueue.css';


const CODE_IN_BOX_TAB = 'code-in-box';
const DECLARED_PRICE_TAB = 'declared-price';
const MARKETING_EVENT_TAB = 'marketing-events';
const MARKETING_TAB = 'marketing';
const PACKAGING_TAB = 'packaging';
const WSP_REQUEST_TAB = 'wsp-requests';

export const ApprovalQueue = () => {
	const userProfile = useUserProfile();
	const {
		showMarketingTab,
		showPackagingTab,
		showCodeInBoxTab,
		showMarketingEventTab,
		showWSPRequestTab,
		showDeclaredPriceTab,
	} = getPermissionsForAssets(userProfile);

	return (
		<div className="ApprovalQueue">
			<Page>
				<Title title="Approval Queue" />
				<div className="Page__fill-space">
					<StatefulTabs
						className="ApprovalQueue__tabs"
						transition={false}
						retainPageState
					>
						{showMarketingTab && (
							<Tab
								eventKey={MARKETING_TAB}
								title="Marketing Assets"
								className="Page__fill-space"
							>
								<MarketingAssetsTab eventKey={MARKETING_TAB} />
							</Tab>
						)}
						{showPackagingTab && (
							<Tab
								eventKey={PACKAGING_TAB}
								title="Packaging Assets"
								className="Page__fill-space"
							>
								<PackagingAssetsTab eventKey={PACKAGING_TAB} />
							</Tab>
						)}
						{showCodeInBoxTab && (
							<Tab
								eventKey={CODE_IN_BOX_TAB}
								title="Code-In-Box Assets"
								className="Page__fill-space"
							>
								<CodeInBoxAssetsTab eventKey={CODE_IN_BOX_TAB} />
							</Tab>
						)}
						{showMarketingEventTab && (
							<Tab
								eventKey={MARKETING_EVENT_TAB}
								title="Marketing Event Assets"
								className="Page__fill-space"
							>
								<EventAssetsTab eventKey={MARKETING_EVENT_TAB} />
							</Tab>
						)}
						{showWSPRequestTab && (
							<Tab
								eventKey={WSP_REQUEST_TAB}
								title="Wholesale Price Requests"
								className="Page__fill-space"
							>
								<WSPReviewTab eventKey={WSP_REQUEST_TAB} />
							</Tab>
						)}
						{showDeclaredPriceTab && (
							<Tab
								eventKey={DECLARED_PRICE_TAB}
								title="Declared Price Requests"
								className="Page__fill-space"
							>
								<DPReviewTab eventKey={DECLARED_PRICE_TAB} />
							</Tab>
						)}
					</StatefulTabs>
				</div>
			</Page>
		</div>
	);
};

export default ApprovalQueue;
