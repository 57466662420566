import React, { Fragment } from 'react';

import { loadingConstants } from '../../../constants/loadingConstants';
import { formatLocale } from '../../../utils/localeUtils';
import LoadingText from '../../../components/Loading/LoadingText';
import Panel from '../../../components/Panel/Panel';

import './EventDetailsPanel.css';


const EventDetailsPanel = ({
	active,
	description,
	category,
	start_datetime,
	end_datetime,
	asset_languages = [],
	systemFamilyNames = [],
	managerNames,
	supportUserNames
}) => {
	return (<Panel>
        <Panel.Heading>
            <Panel.Title>Event Details</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
            <dl className="EventDetailsPanel__panel-list">
                { (active !== undefined) && <>
                    <dt>Active</dt>
                    <dd>{active ? <strong className="text-success">ACTIVE</strong> : <strong className="text-muted">INACTIVE</strong>}</dd>
                </>}

                <dt>Event Category</dt>
                <dd>{category}</dd>

                <dt>Start Date</dt>
                <dd>{start_datetime}</dd>

                <dt>End Date</dt>
                <dd>{end_datetime}</dd>

                <dt>Platform(s)</dt>
                <dd>{ systemFamilyNames.join(', ') }</dd>

                <dt>Description</dt>
                <dd>{description}</dd>

                <dt>Asset Language(s)</dt>
                <dd>
                    {asset_languages.map((lang, i) => {
                        return (
                            <Fragment key={i}>
                                <span>{formatLocale(lang)}</span>
                                <br />
                            </Fragment>
                        );
                    })}
                </dd>
                {managerNames === loadingConstants.LOADING && <>
                    <dt>Manager Access</dt>
                    <dd><LoadingText /></dd>
                </>}
                {Array.isArray(managerNames) && <>
                    <dt>Manager Access</dt>
                    <dd>
                        <ul>
                            { managerNames.map((item, index) => <li key={`${index}`}>{item}</li>) }
                        </ul>
                    </dd>
                </>}
                {supportUserNames === loadingConstants.LOADING && <>
                    <dt>Support Access</dt>
                    <dd><LoadingText /></dd>
                </>}
                {Array.isArray(supportUserNames) && <>
                    <dt>Support Access</dt>
                    <dd>
                        <ul>
                            { supportUserNames.map((item, index) => <li key={`${index}`}>{item}</li>) }
                        </ul>
                    </dd>
                </>}
            </dl>
        </Panel.Body>
    </Panel>);
};
export default EventDetailsPanel;
