export const orderConst = {
	STATUS: {
		NO_STATUS: '-',
		DRAFT: 'DRAFT',
		SUBMITTED_FOR_REVIEW: 'SUBMITTED_FOR_REVIEW',
		SUBMITTED_FOR_APPROVAL: 'SUBMITTED_FOR_APPROVAL',
		ORDER_APPROVED: 'ORDER_APPROVED',
		ERROR: 'ERROR',
		ORDER_PENDING: 'ORDER_PENDING',
		AWAITING_PAYMENT: 'AWAITING_PAYMENT',
		IN_MANUFACTURING: 'IN_MANUFACTURING',
		PRODUCTION_PLANNING: 'PRODUCTION_PLANNING',
		COMPLETE: 'COMPLETE',
		CANCELLED: 'CANCELLED',
		REJECTED: 'REJECTED',
		ENTERED: 'ENTERED',
		BOOKED: 'BOOKED',
		AWAITING_RECEIPT: 'AWAITING_RECEIPT',
		CLOSED: 'CLOSED',
	} as const,
	DETAIL_ITEM_STATUS: {
		DRAFT: 'DRAFT',
		ENTERED: 'ENTERED',
		BOOKED: 'BOOKED',
		AWAITING_RECEIPT: 'AWAITING_RECEIPT',
		CLOSED: 'CLOSED',
		CANCELLED: 'CANCELLED',
	} as const,
	COMMENT: {
		CANCEL: 'CANCEL',
		PUBLISHER: 'PUBLISHER',
		REVIEWER: 'REVIEWER',
		NOTES_TO_NCL: 'NOTES_TO_NCL',
		SHIPPING_INFO: 'SHIPPING_INFO',
		REJECTION: 'REJECTION',
	} as const,
	VALID_PACKOUT_FACILITY_STATUS_CHECK: [
		'DRAFT',
		'SUBMITTED_FOR_REVIEW',
		'SUBMITTED_FOR_APPROVAL',
	] as const,
} as const;
