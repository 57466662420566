import React, { Component } from 'react';
import { Alert, Col, FormCheck, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap';

import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import { assetConst } from '../../../constants/assetConstants';
import { allowedFileExtensions, fileTransferConst } from '../../../constants/fileTransferConstants';
import { permConst } from '../../../constants/permConst';
import { postAssetStatus } from '../../../services/productsService';
import {
	doAllFilesHaveExtension,
	formatAssetType,
	getDATokenForMarketingEventAsset
} from '../../../utils/assetUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { isAuthorized } from '../../../utils/userUtils';
import AsperaFileUpload from '../../AsperaFileUpload/AsperaFileUpload';

import '../../../containers/AssetDetails/AssetDetails.css';


const defaultState = {
	comment: '',
	isFilesSelected: false,
	revisionId: null,
	isUploadInitiated: false,
	isProcessing: false,
};

export default class UploadNewAssetModal extends Component {
	constructor(props) {
		super(props);
		this.state = { ...defaultState, asset: props.asset };
		this.updateFilesSelected = this.updateFilesSelected.bind(this);
		this.onComplete = this.onComplete.bind(this);
		this.validateFileType = this.validateFileType.bind(this);
	}

	static getDerivedStateFromProps(props, state) {
		if (props.show === true && props.asset !== state.asset) {
			return {
				...state,
				asset: props.asset,
			};
		}
		if (props.show !== true) {
			return { ...defaultState };
		}
		return null;
	}

	async updateAssetStatus() {
		const { comment, asset, selectedFiles } = this.state;

		const payload = {
			review_status: assetConst.STATUS.FILES_UPLOADING,
			file_upload_type: assetConst.UPLOAD.INITIAL,
			comment: comment,
		};

		this.setState({
			isProcessing: true,
		});

		try {
			const response = await postAssetStatus(
				asset.asset_id,
				{...payload, files: selectedFiles},
				await this.getMarketingEventToken('upload')
			);
			toasterNotify('Asset submitted', 'success');
			this.setState({
				revisionId: response.data.revision_id.toString(),
				isUploadInitiated: true,
				prefetchedTransferSpecs: response.data.transfer_specs && {
					transfer_specs: response.data.transfer_specs,
				}
			});
		} catch (error) {
			toasterNotify(
				createMessageForError(error, 'submitting asset'),
				'error',
				error
			);
		} finally {
			this.setState({ isProcessing: false });
		}
	}

	onComplete() {
		const { productId, closeModal, loadAssetDetails } = this.props;
		const { asset } = this.state;
		loadAssetDetails(productId, asset.asset_id);
		closeModal();
	}

	updateFilesSelected(isFilesSelected) {
		this.setState({
			isFilesSelected: isFilesSelected,
		});
	}

	needMarketingEventToken() {
		const { userProfile } = this.props;
		const { asset } = this.state;
		if (
			asset.id &&
			isAuthorized(userProfile.permissions, [permConst.MARKETING_EVENT.PRODUCT.ASSET.UPLOAD])
		) {
			return true;
		}
		return false;
	}

	getAllowedFileTypes() {
		const { asset } = this.state;
		let allowedExtensions;
		switch (asset && asset.asset_category) {
			case assetConst.CATEGORY.CODE_IN_BOX:
				allowedExtensions = allowedFileExtensions.CODE_IN_BOX_ASSET;
				break;
			case assetConst.CATEGORY.MARKETING:
			case assetConst.CATEGORY.MARKETING_EVENT:
				allowedExtensions = allowedFileExtensions.MARKETING_ASSET;
				break;
			case assetConst.CATEGORY.PACKAGING:
				allowedExtensions = allowedFileExtensions.PACKAGING_ASSET;
				break;
			default:
				allowedExtensions = null;
		}
		if (allowedExtensions == null) {
			return null;
		} else {
			return [
				{
					filter_name: 'Accepted Files',
					extensions: allowedExtensions,
				},
			];
		}
	}

	validateFileType(selectedFiles) {
		const allowedFileTypes = this.getAllowedFileTypes();
		const isValid = doAllFilesHaveExtension(
			selectedFiles,
			allowedFileTypes && allowedFileTypes[0].extensions,
		);
		this.setState({
			validFileSelected: isValid,
			selectedFiles: isValid && selectedFiles
		});
	}

	async getMarketingEventToken(operation) {
		const { asset } = this.state;
		return await getDATokenForMarketingEventAsset(asset, operation);
	}

	render() {
		const { closeModal, userProfile, show, title } = this.props;
		const {
			isProcessing,
			comment,
			revisionId,
			isUploadInitiated,
			isFilesSelected,
			asset,
			validFileSelected,
			prefetchedTransferSpecs
		} = this.state;

		const assetLanguages = (asset && asset.languages) || [];
		const isLabel = asset && asset.asset_type === assetConst.TYPE.LABEL;
		const isMarketingEvent = asset && asset.asset_category === assetConst.CATEGORY.MARKETING_EVENT;
		const showAssetLanguages = !isLabel && !isMarketingEvent && asset && asset.languages;
		const isTitleSheet = (asset && asset.asset_type === assetConst.TYPE.TITLE_SHEET);

		return (
			<BaseModal show={show} onCancel={closeModal} isSubmitting={isProcessing}>
				<BaseModal.Title>{title || 'Upload Asset'}</BaseModal.Title>
				<Alert variant='warning'>
					Please note that if you are replacing a previous version of this asset
					the review process will restart.
				</Alert>
				<div className="approve-asset-modal">
					<FormGroup as={Row}>
						<FormLabel id="title" className="col-sm-4 text-sm-right">
							Asset Type
						</FormLabel>
						<Col sm={8}>
							{asset && formatAssetType(asset.asset_type)}
						</Col>
					</FormGroup>
					{ showAssetLanguages && (
						<FormGroup as={Row}>
							<FormLabel id="title" className="col-sm-4 text-sm-right">
								Asset Language(s)
							</FormLabel>
							<Col sm={8}>
								<FormCheck
									type="checkbox"
									id="checkbox-en-language"
									checked={assetLanguages.includes('en')}
									disabled={true}
									label="English"
								/>
								<FormCheck
									type="checkbox"
									id="checkbox-fr-language"
									checked={assetLanguages.includes('fr')}
									disabled={true}
									label="French"
								/>
								<FormCheck
									type="checkbox"
									id="checkbox-pt-language"
									checked={assetLanguages.includes('pt')}
									disabled={true}
									label="Portuguese"
								/>
								<FormCheck
									type="checkbox"
									id="checkbox-es-language"
									checked={assetLanguages.includes('es')}
									disabled={true}
									label="Spanish"
								/>
							</Col>
						</FormGroup>
					)}
					<FormGroup>
						<Row>
							<FormLabel id="title" className="col-sm-4 text-right">
								Attach File(s)
							</FormLabel>
							<Col sm={6}>
								<AsperaFileUpload
									updateFilesSelected={this.updateFilesSelected}
									userProfile={userProfile}
									entityType={
										fileTransferConst.ENTITY_TYPE.PRODUCT_ASSET_REVISION
									}
									entityId={revisionId}
									isUploadInitiated={isUploadInitiated}
									onCompleteFunction={this.onComplete}
									allowedFileTypes={this.getAllowedFileTypes()}
									getToken={() => getDATokenForMarketingEventAsset(asset, 'upload')}
									validateFileType={this.validateFileType}
									prefetchedTransferSpecs={prefetchedTransferSpecs}
									singleFileOnly={isTitleSheet}
								/>
								{isTitleSheet && (
									<Forms.Help>Only one file is allowed for Title Sheet</Forms.Help>
								)}
							</Col>
						</Row>
					</FormGroup>
					<FormGroup>
						<Row>
							<FormLabel id="title" className="col-sm-4 text-right">
								Comments:
							</FormLabel>
							<Col sm={8}>
								<FormControl
									as="textarea"
									rows="6"
									value={comment}
									onChange={(e) => {
										this.setState({
											comment: e.target.value,
										});
									}}
									maxLength={2000}
								/>
							</Col>
						</Row>
					</FormGroup>
				</div>
				<BaseModal.Submit disabled={isProcessing || !isFilesSelected || !validFileSelected}
				onClick={() => {
					this.updateAssetStatus();
				}}>Confirm</BaseModal.Submit>
			</BaseModal>
		);
	}
}
