import React, { VFC } from 'react';
import { Alert, Button, ButtonToolbar, Table } from 'react-bootstrap';
import { useQuery } from 'react-query';

import ActionLink from '../../../components/ActionLink/ActionLink';
import FAIcon from '../../../components/FAIcon/FAIcon';
import Loading from '../../../components/Loading/Loading';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { permConst } from '../../../constants/permConst';
import { useUserProfile } from '../../../hooks/reduxHooks';
import { getOldOrderDetails, getOrderDetails } from '../../../services/ordersService';
import { formatCurrency, numberWithCommas } from '../../../utils/currencyUtils';
import { dateFormat, formatDate } from '../../../utils/dateUtils';
import { isAuthorized } from '../../../utils/userUtils';
import { companyHasRight } from '../../../utils/companyUtils';

interface OrdersTabProps {
	isSelected: boolean;
	productId: number;
	rightsHolders: ProductData['distribution'];
	showPhysicalOrderChecklist: boolean;
	toggleModal: Function;
}
const OrdersTab: VFC<OrdersTabProps>= ({ isSelected, productId, rightsHolders, showPhysicalOrderChecklist, toggleModal}) => {
	const userProfile = useUserProfile();
	const enableNewQuery = isAuthorized(userProfile.permissions, [
		permConst.PHYSICAL_ORDER.VIEW.ALL,
		permConst.PHYSICAL_ORDER.VIEW.COMPANY
	]);

	const showNOANumbers = isAuthorized(userProfile.permissions, [
		permConst.ORDER.DETAIL.PRODUCT.VIEW.ALL,
		permConst.PHYSICAL_ORDER.VIEW.ALL
	]);
	const showCompanyName =
		enableNewQuery &&
		(isAuthorized(userProfile.permissions, [permConst.PHYSICAL_ORDER.VIEW.ALL]) ||
			(rightsHolders && companyHasRight(userProfile.companyId, rightsHolders, 'OWNERSHIP')));

	const oldOrdersQuery = useQuery(
		['getOrderDetails', productId],
		() => getOldOrderDetails(String(productId)),
		{ enabled: isSelected && !enableNewQuery },
	);
	const newOrdersQuery = useQuery(
		['getOrderDetails', productId],
		() => getOrderDetails(String(productId)),
		{ enabled: isSelected && enableNewQuery },
	);

	const isLoading = enableNewQuery ? newOrdersQuery.isLoading : oldOrdersQuery.isLoading;
	const fault = enableNewQuery ? newOrdersQuery.error : oldOrdersQuery.error;
	const orders = enableNewQuery ? newOrdersQuery.data?.data : oldOrdersQuery.data?.data;
	const retryQuery = () => {
		enableNewQuery ? newOrdersQuery.refetch() : oldOrdersQuery.refetch();
	};
	return (
		<>
			{isLoading ? (
				<Loading />
			) : (
				<>
					{showPhysicalOrderChecklist && (
						<ButtonToolbar><Button
							variant="outline-secondary"
							className="btn btn-default"
							type="button"
							id="physicalOrderChecklist-button"
							onClick={(e) =>
								toggleModal('physicalOrderChecklist')
							}
						>
							Orderability Checklist
						</Button></ButtonToolbar>
					)}
					<SectionTitle>Orders</SectionTitle>
					{fault ? (
						<Alert variant="danger">
							There was an error while loading orders data, which is necessary for
							this tab. <ActionLink onClick={retryQuery}>Retry load</ActionLink>
							<FAIcon size="xs" name="chevron-right" />
						</Alert>
					) : !!orders && orders.length ? (
						<Table className="base-title-table" striped>
							<thead>
								<tr>
									<th>Publisher PO#</th>
									{showCompanyName ? <th>Company</th> : null}
									{showNOANumbers ? (
										<>
											<th>NOA Sales Order#</th>
											<th>NOA PO#</th>
										</>
									) : null}
									<th>Version</th>
									<th>Ship Date</th>
									<th>Quantity</th>
									<th>Price/Unit</th>
									<th>Price</th>
								</tr>
							</thead>
							<tbody>
								{orders.map((order, index) => (
									<tr key={index}>
										<td>{order.publisher_po_number}</td>
										{showCompanyName ? (
											<>
												<td>
													{'company_name' in order
														? order.company_name
														: null}
												</td>
											</>
										) : null}
										{showNOANumbers ? (
											<>
												<td>{order.noa_sales_order_number}</td>
												<td>{order.noa_po_number}</td>
											</>
										) : null}
										<td>
											{order.release_version && order.submission_version
												? `v${order.release_version}.${order.submission_version}`
												: ''}
										</td>
										<td>{formatDate(order.ship_date, dateFormat.DATE)}</td>
										<td>{numberWithCommas(order.quantity)}</td>
										<td>{formatCurrency(order.unit_price, 2)}</td>
										<td>{formatCurrency(order.unit_price * order.quantity)}</td>
									</tr>
								))}
								<tr>
									<td colSpan={2} style={{ backgroundColor: 'white' }}></td>
									{showCompanyName && (
										<td style={{ backgroundColor: 'white' }}></td>
									)}
									{showNOANumbers && (
										<td colSpan={2} style={{ backgroundColor: 'white' }}></td>
									)}
									<td style={{ backgroundColor: 'var(--nin-color-ltgray3)' }}>
										<strong>Totals:</strong>
									</td>
									<td style={{ backgroundColor: 'var(--nin-color-ltgray3)' }}>
										<strong>
											{numberWithCommas(
												(orders as { quantity: number }[]).reduce<number>(
													(reduction, order) => {
														return reduction + order.quantity;
													},
													0,
												),
											)}
										</strong>
									</td>
									<td style={{ backgroundColor: 'var(--nin-color-ltgray3)' }}>
										&nbsp;
									</td>
									<td style={{ backgroundColor: 'var(--nin-color-ltgray3)' }}>
										<strong>
											{formatCurrency(
												(
													orders as {
														quantity: number;
														unit_price: number;
													}[]
												).reduce<number>((reduction, order) => {
													return (
														reduction +
														order.quantity * order.unit_price
													);
												}, 0),
											)}
										</strong>
									</td>
								</tr>
							</tbody>
						</Table>
					) : (
						<Alert variant="warning">No orders were found.</Alert>
					)}
				</>
			)}
		</>
	);
};

export default OrdersTab;
