import React, { useEffect, useMemo, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { actionsColumn } from '../../../components/BaseTable/BaseTable';
import ActiveCell from '../../../components/cells/ActiveCell/ActiveCell';
import FAIcon from '../../../components/FAIcon/FAIcon';
import FilterableTable from '../../../components/FilterableTable/FilterableTable';
import LinkMenuItem from '../../../components/LinkMenuItem/LinkMenuItem';
import Loading from '../../../components/Loading/Loading';
import MeatballDropdown from '../../../components/MeatballDropdown/MeatballDropdown';
import RolloverList from '../../../components/RolloverList/RolloverList';
import { assetConst } from '../../../constants/assetConstants';
import { eventConstants } from '../../../constants/eventConstants';
import { useUserProfile } from '../../../hooks/reduxHooks';
import { getEventReportAssets, getEventSystemFamilies } from '../../../services/eventsService';
import { getAssetTypes } from '../../../services/productsService';
import { classForAssetStatus, formatAssetStatus, formatAssetType } from '../../../utils/assetUtils';
import { dateFormat, formatDate, parseDateString } from '../../../utils/dateUtils';
import { findEventStatus } from '../../../utils/eventUtils';
import { dateRangeEvaluation } from '../../../utils/filterUtils';
import { pullAllResults } from '../../../utils/serviceUtils';
import { toasterNotify } from '../../../utils/toaster';
import { getPermissions } from './AssetsTab.helpers';


const getTableStructure = ({
	showActiveStatus,
	canViewEventDetails,
	canViewProductDetails,
	canViewVisibility,
}) => {
	const visibleAccessor = (row) =>
		row.externally_visible && row.product_active && row.event_active && row.asset_active;

	const assetTypeAccessor = (row) => formatAssetType(row.asset_type_code);

	const fileNamesAccessor = (row) => {
		return (row.file_names && row.file_names[0]) || '';
	};

	const visibleCell = ({ value }) => (
		<OverlayTrigger
			placement={'bottom'}
			overlay={
				<Tooltip id="visibility-tooltip">
					{value ? 'Visible to publishers' : 'Not visible to publishers'}
				</Tooltip>
			}
		>
			{value ? (
				<span className="text-danger">
					<FAIcon name="eye" />
				</span>
			) : (
				<span className="text-muted">
					<FAIcon name="eye-slash" />
				</span>
			)}
		</OverlayTrigger>
	);

	const fileNamesCell = ({ original }) => (
		<Link
			to={`/marketing-events/products/${original.marketing_event_product_id}/assets/${original.marketing_event_asset_id}`}
		>
			{original.file_names && typeof original.file_names === 'string' ? (
				<div>
					<RolloverList
						tooltipId={'files-' + original.id}
						values={original.file_names.split('|')}
						dotted={false}
					/>
				</div>
			) : (
				'—'
			)}
		</Link>
	);

	const dateCell = ({ value }) =>
		(value && formatDate(parseDateString(value), dateFormat.DATE)) || '—';

	const assetStatusCell = ({ value }) => {
		value = value || assetConst.STATUS.REQUIRED;
		const textClass = classForAssetStatus(value, 'text');
		return <b className={textClass}>{value.replace(/_/g, ' ')}</b>;
	};

	const typeCell = ({ value }) => formatAssetType(value) || '—';

	const productCell = ({ original, value }) =>
		canViewProductDetails ? (
			<Link to={`/marketing-events/products/${original.marketing_event_product_id}`}>
				{value || '—'}
			</Link>
		) : (
			value || '—'
		);

	const eventNameCell = ({ original, value }) =>
		canViewEventDetails ? (
			<Link to={`/marketing-events/${original.marketing_event_id}`}>{value || '—'}</Link>
		) : (
			value || '—'
		);

	const eventStatusCell = ({ value }) => {
		switch (value) {
			case eventConstants.STATUS.PLANNED:
				return (
					<strong className="text-info">
						{eventConstants.STATUS_NAMES[value].toUpperCase()}
					</strong>
				);
			case eventConstants.STATUS.LIVE:
				return (
					<strong className="text-success">
						{eventConstants.STATUS_NAMES[value].toUpperCase()}
					</strong>
				);
			case eventConstants.STATUS.COMPLETED:
				return (
					<strong className="text-muted">
						{eventConstants.STATUS_NAMES[value].toUpperCase()}
					</strong>
				);
			case eventConstants.STATUS.DRAFT:
				return <strong>{eventConstants.STATUS_NAMES[value].toUpperCase()}</strong>;
			default:
				return null;
		}
	};

	const actionsCell = ({ original }) => (
		<MeatballDropdown id={`menu-${original.id}`}>
			<LinkMenuItem
				href={`/marketing-events/products/${original.marketing_event_product_id}/assets/${original.marketing_event_asset_id}`}
			>
				View Asset
			</LinkMenuItem>
		</MeatballDropdown>
	);
	return [
		{
			Header: '',
			id: 'visible',
			accessor: visibleAccessor,
			Cell: visibleCell,
			width: 36,
			show: !!canViewVisibility,
		},
		{ Header: 'Asset', id: 'fileNames', accessor: fileNamesAccessor, Cell: fileNamesCell },
		{ Header: 'Due Date', accessor: 'due_date', Cell: dateCell },
		{ Header: 'Type', id: 'asset_type', accessor: assetTypeAccessor, Cell: typeCell },
		{ Header: 'Description', accessor: 'description' },
		{ Header: 'Product', accessor: 'game_name', Cell: productCell },
		{ Header: 'Event Name', accessor: 'event_name', Cell: eventNameCell },
		{ Header: 'Event Status', accessor: 'eventStatus', Cell: eventStatusCell },
		{ Header: 'Asset Status', accessor: 'review_status', Cell: assetStatusCell },
		{
			Header: 'Active',
			accessor: 'allActive',
			Cell: ActiveCell,
			width: 64,
			show: showActiveStatus,
		},
		{
			...actionsColumn,
			Cell: actionsCell,
		},
	];
};

const getFilterProperties = (assetTypes, showActiveFilter=false, showDraftStatus=true) => {
	if (!assetTypes) return null;
	const filterProperties = new Map();
	filterProperties.set('Due Date', {
		filter: 'due_date',
		selectableFilters: 'datetime_range',
		customEvaluation: dateRangeEvaluation
	});
	filterProperties.set('Asset Type', {
		filter: 'asset_type_code',
		selectableFilters: new Set(Object.values(assetTypes).map((type) => formatAssetType(type))),
		customEvaluation: (value, filters) => {
			return filters.has(formatAssetType(value));
		}
	});

	filterProperties.set('Asset Status', {
		filter: 'review_status',
		selectableFilters: new Set(
			[
				'APPROVED',
				'APPROVED_WITH_CHANGES',
				'FILE_UPLOAD_FAILED',
				'FILES_UPLOADING',
				'PENDING_REVIEW',
				'REJECTED',
				'REQUIRED',
			].map((status) => formatAssetStatus(status)),
		),
		customEvaluation: (value, filters) => {
			return filters.has(formatAssetStatus(value));
		},
	});
	showActiveFilter && filterProperties.set('Active', {
		filter: 'allActive',
		selectableFilters: new Set(['Active', 'Inactive']),
		customEvaluation: (value, filters) => {
			if (filters.has('Inactive') && value === false) {
				return true;
			}
			if (filters.has('Active') && value === true) {
				return true;
			}
			return false;
		}
	});
	const eventStatusNames =  showDraftStatus ? (
		Object.values(eventConstants.STATUS_NAMES)
	) : (
		Object.values(eventConstants.STATUS_NAMES).filter(status => status !== 'Draft')
	) ;
	filterProperties.set('Event Status', {
		filter: 'eventStatus',
		selectedFilters: new Set(eventStatusNames.filter((status) => status !== 'Completed')),
		selectableFilters: new Set(Object.values(eventStatusNames)),
		customEvaluation: (value, filters) => {
			return filters.has(formatAssetStatus(eventConstants.STATUS_NAMES[value]));
		},
	});
	return filterProperties;
};

const AssetTabs = ({show}) => {
	const userProfile = useUserProfile();
	const [assets, setAssets] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [assetTypes, setAssetTypes] = useState();

	// show Active column and enable filter if data return by server exposes these values
	const showActiveStatus = assets && assets.some((asset) => 'asset_active' in asset);

	const { canViewEventDetails, canViewProductDetails, canSeeDraftEvents } = useMemo(
		() => getPermissions(userProfile),
		[userProfile],
	);
	const canViewVisibility = assets && assets.find(asset => 'externally_visible' in asset);

	const dataFormat = useMemo(
		() =>
			getTableStructure({
				showActiveStatus,
				canViewEventDetails,
				canViewProductDetails,
				canViewVisibility
			}),
		[showActiveStatus],
	);
	const filterProperties = useMemo(() => {
		return getFilterProperties(assetTypes, showActiveStatus, canSeeDraftEvents);
	}, [assets, assetTypes, showActiveStatus]);

	useEffect(() => {
		if (!assets && show) {
			loadData();
		}
	}, [show]);

	const loadAssetTypes = async () => {
		const platformCodes = (await getEventSystemFamilies()).data;
		const assetTypes = (await getAssetTypes(platformCodes)).data.map(
			(platform) => platform.asset_type,
		);
		assetTypes.sort((a, b) => {
			if (a.toLowerCase() === 'other') {
				return 1;
			} else if (b.toLowerCase() === 'other') {
				return -1;
			} else if (a < b) {
				return -1;
			} else if (b < a) {
				return 1;
			}
			return 0;
		});
		setAssetTypes(assetTypes);
	};

	const loadAssets = async () => {
		const results = await pullAllResults(getEventReportAssets, 500);
		setAssets(
			results.map((asset) => ({
				...asset,
				eventStatus: findEventStatus(asset),
				review_status: asset.review_status || 'REQUIRED',
				allActive: !!(asset.event_active && asset.product_active && asset.asset_active)
			})),
		);
	};

	const loadData = async () => {
		try {
			await Promise.all([
				loadAssets(),
				loadAssetTypes(),
			]);
		} catch(err) {
			if (!err.isAxiosError) {
				toasterNotify('There was an unexpected error that prevented page data from ' +
					'loading. Please contact site support if this error persists.', 'error');
			}
			throw err;
		} finally {
			setIsLoading(false);
		}
	};

	return isLoading || !filterProperties ? (
		<Loading />
	) : (
		<FilterableTable
			dataFormat={dataFormat}
			data={assets}
			filterProperties={filterProperties}
			searchableFields={['event_name']}
			searchableFieldPlaceHolder="Search by event name..."
			defaultSorted={[
				{ id: 'due_date', desc: false },
				{ id: 'event_name', desc: false },
			]}
		/>
	);
};
export default AssetTabs;
