import React, { Component } from 'react';

class DropdownCheckbox extends Component {

	toggleCheckboxChange = () => {
		const { handleCheckboxChange, label } = this.props;
		handleCheckboxChange(label);
	};

	render() {
		const { label, checked, className } = this.props;
		return (
			<label className={className}>
				<input
					type="checkbox"
					value={label}
					checked={checked}
					className="checkbox-input"
					onChange={this.toggleCheckboxChange}
				/>
				{label}
			</label>
		);
	}
}

export default DropdownCheckbox;
