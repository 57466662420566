import React from 'react';

import Panel from '../../../components/Panel/Panel';


const LinkedEventsPanel = () => {
	return (
        <Panel>
            <Panel.Heading>
                <Panel.Title>Linked Events</Panel.Title>
            </Panel.Heading>
            <Panel.Body>
            </Panel.Body>
        </Panel>
	);
};

export default LinkedEventsPanel;
