import React, { useEffect, useState, VFC } from 'react';
import dayjs from 'dayjs';
import { Alert, Badge, Button, Col, Row, Table } from 'react-bootstrap';
import { useQueries, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import FAIcon from '../../../components/FAIcon/FAIcon';
import Forms from '../../../components/Forms/Forms';
import HelpBlock from '../../../components/HelpBlock/HelpBlock';
import Loading from '../../../components/Loading/Loading';
import LoadingText from '../../../components/Loading/LoadingText';
import DeleteConfirmationModal from '../../../components/modals/DeleteConfirmationModal/DeleteConfirmationModal';
import Page from '../../../components/Page/Page';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { timeZoneConstants } from '../../../constants/timeZoneConstants';
import {
	getNewOrderProductPrice,
	getNewOrderProducts,
	getNewOrderResources,
	getOrderInfoAndResources,
	postNewOrderDetails,
	putOrderInfoDetails
} from '../../../services/ordersService';
import { voidIfEmpty } from '../../../utils/arrayUtils';
import { formatCurrency, numberWithCommas } from '../../../utils/currencyUtils';
import { getPossiblyInvalidValue, isEmptyObject, isInvalid } from '../../../utils/dataUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { validateToSchema } from '../../../utils/validationUtils';

import './Step1OrderDetail.css';


const quantityRequired = 'A quantity is required';
const step1Schema = yup.object().shape({
	platform_code: yup.string().default('').strict().required('A valid platform is required'),
	publisher_po_number: yup
		.string()
		.default('')
		.trim()
		.strict()
		.required('Publisher PO Number is required')
		.test(
			'platform_test',
			'Publisher PO Number has already been used',
			(value, { options }) =>
				!options.context?.publisherPONumberIsOk ||
				options.context?.publisherPONumberIsOk(value),
		)
		.transform((value) => String(value).toUpperCase()),
	comments: yup.string().trim().default(''),
});

const step1LineItemSchema = yup.object().shape({
	product_id: yup
		.number()
		.optional()
		.strict()
		.test(
			'invalid_check',
			'This product is not available for selection anymore',
			(value, { options }) =>
				!options.context?.invalidProductIds ||
				!options.context.invalidProductIds.some((id: number) => id === value),
		)
		.test(
			'part_num_check',
			"Product is missing Part/SKU Number. Enter one on the product's page on NPT to proceed.",
			(value, { options }) =>
				options.context?.showPartNumbers ?
					(!options.context?.productsWithPublisherPartNumbers ||
				options.context.productsWithPublisherPartNumbers.some((id: number) => id === value)) : true,
		)
		.notOneOf([undefined], 'A product is required'),
	submission_id: yup
		.number()
		.optional()
		.strict()
		.test(
			'invalid_check',
			'This version is not available for selection anymore',
			(value, { options }) =>
				!options.context?.invalidSubmissionIds ||
				!options.context.invalidSubmissionIds.some((id: number) => id === value),
		)
		.notOneOf([undefined], 'A version is required'),
	quantity: yup
		.number()
		.strict()
		.test(
			'min_quantity_check',
			'Minumum quantity was not met',
			(value, { options }) => value ? (value || 0) >= (options.context?.quantityMinimum || 0) : true,
		)
		.test(
			'case_quantity_check',
			'Quantity must be multiples of case count',
			(value, { options }) => (value || 0) % (options.context?.quantityPerCase || 1) === 0,
		)
		.required(quantityRequired),
	requested_ship_date: yup
		.string()
		.strict()
		.test(
			'lead_in_date_check',
			'Date must be past the lead-in period',
			(value, { options }) =>
				dayjs(value) >=
				dayjs()
					.tz(timeZoneConstants.PACIFIC, true)
					.startOf('day')
					.add((options.context?.leadInDays || 0) + 1, 'days'),
		)
		.test(
			'no_weekends_test',
			'Date must be on a week day',
			(value) => dayjs(value).day() !== 0 && dayjs(value).day() !== 6, // check pacific time
		)
		.required('A date is required'),
});

const getUniqueKey = () => 
	Number(new Date()).toString(36).substring(4);

interface Step1FormValues {
	platform_code?: string;
	publisher_po_number?: string;
	comments?: string;
}
interface LineItemInstance {
	product_id?: number;
	quantity?: number;
	submission_id?: number;
	requested_ship_date?: string;
}
interface Step1OrderDetailsProps {
	onNewId: (orderHeaderId: string) => void;
	onNext: () => void;
	onCancelOrder?: () => void;
	onDeleteOrder?: () => void;
	orderHeaderId?: string | null;
	status?: string;
	setSaveCall: (callback: () => void) => void;
	companyProfile: CompanyProfile;
}
interface OpenModalData {
	type?: 'CONFIRM_PLATFORM_CHANGE_MODAL' | null;
}
const Step1OrderDetails: VFC<Step1OrderDetailsProps> = ({
	onNewId,
	onNext,
	orderHeaderId,
	setSaveCall,
	companyProfile,
}) => {
	// states
	const [initialized, setInitialized] = useState<boolean>(orderHeaderId ? false : true);
	const [topFormValues, setTopFormValues] = useState<Step1FormValues>({});
	const [lineItemFormValues, setLineItemFormValues] = useState<LineItemInstance[]>([{}]);
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);
	const [selectedPlatform, setSelectedPlatform] = useState<string>();
	const [openModal, setOpenModal] = useState<OpenModalData>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [itemKeys] = useState<Map<object, string>>(new Map());

	const resourcesQuery = useQuery('getNewOrderResources', () => getNewOrderResources(), {
		enabled: !orderHeaderId,
	});
	const orderAndResourcesQuery = useQuery(
		['getOrderInfoAndResources', orderHeaderId],
		() => getOrderInfoAndResources(orderHeaderId as string),
		{
			enabled: !!(!initialized && orderHeaderId),
			onSuccess: (response) => {
				const orderData = response.data.physical_order;
				const { platform_code, publisher_po_number, order_details } = orderData;

				setTopFormValues({
					platform_code,
					publisher_po_number,
					comments: orderData.order_comments?.['PUBLISHER']?.comment,
				});
				setLineItemFormValues(
					voidIfEmpty(
						order_details.map((item, index) => {
							const newItem = {
								product_id:
									getPossiblyInvalidValue<number | null>(item.product_id) ||
									undefined,
								submission_id:
									getPossiblyInvalidValue<number | null>(item.submission_id) ||
									undefined,
								quantity:
									getPossiblyInvalidValue<number | null>(item.quantity) || undefined,
								requested_ship_date:
									getPossiblyInvalidValue<string | null>(item.requested_ship_date) ||
									undefined,
							};
							itemKeys.set(newItem, 'original' + index);
							return newItem;
						}),
					) || [{}],
				);
				platform_code && setSelectedPlatform(platform_code);
				setInitialized(true);
			},
		},
	);

	const orderData = orderAndResourcesQuery.data?.data.physical_order;
	const productsQuery = useQuery(
		['getNewOrderProducts', selectedPlatform],
		() => (selectedPlatform ? getNewOrderProducts(selectedPlatform) : undefined),
		{
			enabled:
				(!!orderHeaderId &&
					!!selectedPlatform &&
					orderData?.platform_code !== selectedPlatform) ||
				(!orderHeaderId && !!selectedPlatform),
		},
	);


	const availablePlatforms =
		orderAndResourcesQuery.data?.data.platforms || resourcesQuery.data?.data.platforms;
	const usedPONumbers =
		orderAndResourcesQuery.data?.data.publisher_po_numbers ||
		resourcesQuery.data?.data.publisher_po_numbers;
	const availableProducts =
		orderAndResourcesQuery.isSuccess && selectedPlatform === orderData?.platform_code
			? orderAndResourcesQuery.data?.data.products
			: productsQuery.data?.data;

	const productsWithPublisherPartNumbers = availableProducts
		?.filter((product) =>
			product.part_numbers.some((partNumber) => partNumber.part_number_owner === 'PUBLISHER'),
		)
		.map((product) => product.product_id);

	const showPartNumbers = !!companyProfile?.sku_number_required;
	const selectedProducts = lineItemFormValues
		?.map((item) => item.product_id)
		.filter((x, index, array) => !!x && array.indexOf(x) === index);
	const priceableProducts = selectedProducts
		.map((productId) => availableProducts?.find((product) => product.product_id === productId))
		.filter(
			(product) =>
				product &&
				product.part_numbers.some((partNumber) => partNumber.part_number_owner === 'NOA'),
		)
		.map((product) => product?.product_id);
 
	const priceQueries = useQueries(
		priceableProducts.map((productId) => ({
			queryKey: ['getNewOrderProductPrice', productId],
			queryFn: () => getNewOrderProductPrice(String(productId)),
		})),
	);

	const selectedPlatformData = availablePlatforms?.find(
		(platform) => topFormValues.platform_code === platform.platform_code,
	);

	const initialPublisherPO: string = // this is the PO number originally set in the order
		(orderAndResourcesQuery.isSuccess && orderData?.publisher_po_number) || '';

	const invalidProductIds = orderData?.order_details
		.filter((item) => !!isInvalid(item.product_id))
		.map((item) => getPossiblyInvalidValue(item.product_id));
	const invalidSubmissionIds = orderData?.order_details
		.filter((item) => !!isInvalid(item.submission_id))
		.map((item) => getPossiblyInvalidValue(item.submission_id));

	// business logic functions
	const publisherPONumberIsOk = (publisherPO: string) => {
		if (!publisherPO || !usedPONumbers) {
			return true;
		}
		return (
			publisherPO.toUpperCase() === initialPublisherPO.toUpperCase() ||
			!usedPONumbers.map((s) => s.toUpperCase()).includes(publisherPO.toUpperCase())
		);
	};

	const getUnitPrice = (
		productId?: number,
	): undefined | string | 'LOADING' | 'NOT_FOUND' | 'ERROR' => {
		const index = priceableProducts.indexOf(productId);
		if (index < 0) {
			return 'NOT_FOUND';
		}
		const query = priceQueries[index];
		if (query.isLoading) {
			return 'LOADING';
		} else if (query.isError) {
			const error: any = query.error;
			if (error?.response?.status === 409) {
				return 'ERROR';
			}
			return 'NOT_FOUND';
		}
		return query.data?.data as string;
	};
	const getMinimum = (productId?: number): number | undefined => {
		if (productId) {
			const product = availableProducts?.find((product) => product.product_id === productId);
			if (product?.initial_order) {
				return selectedPlatformData?.initial_order_minimum_qty;
			} else {
				return selectedPlatformData?.order_minimum_qty;
			}
		}
	};
	const getBatchCount = () => {
		if (selectedPlatformData?.order_increment_qty) {
			return selectedPlatformData?.order_increment_qty;
		}
	};
	const getTotalForItem = (productId?: number, quantity?: number) => {
		if (!productId || !quantity) {
			return;
		}
		const index = priceableProducts.indexOf(productId);
		if (index < 0) {
			return;
		}
		const query = priceQueries[index];
		if (query.isLoading) {
			return;
		} else if (query.isError) {
			return;
		}
		return Number(priceQueries[index].data?.data) * quantity;
	};
	const getTotalQuantity = () => {
		const total = lineItemFormValues.reduce(
			(reduction: number, item) => (item.quantity || 0) + reduction,
			0,
		);
		return (total && numberWithCommas(total)) || null;
	};
	const getGrandTotal = () => {
		const total = lineItemFormValues
			?.map((item) => getTotalForItem(item.product_id, item.quantity))
			.reduce(
				(reduction: number, value) =>
					typeof value === 'number' ? value + reduction : reduction,
				0,
			);
		return (total && formatCurrency(total)) || null;
	};
	const getDisplayedUnitPrice = (itemIndex: number) => {
		const productId = lineItemFormValues[itemIndex].product_id;
		if (!productId) {
			return;
		}
		const unitPrice = getUnitPrice(productId);
		if (!isNaN(Number(unitPrice))) {
			// check if unit price is standard. if not make it red
			const isMismatched = checkPriceMismatchOnItem(
				productId,
				lineItemFormValues[itemIndex].submission_id,
			);
			const priceString = formatCurrency(unitPrice);
			if (isMismatched) {
				return (
					<span className="text-danger">
						{priceString}
						{isMismatched && <sup>&dagger;</sup>}
					</span>
				);
			}
			const orderDetailUnitPrice = orderData?.order_details[itemIndex]?.unit_price;
			const showEDIPriceError = isEDIOrder && orderDetailUnitPrice && (orderDetailUnitPrice !== unitPrice);
			if (showEDIPriceError) {
				return (
					<span className="text-danger">
						{priceString}
						{showEDIPriceError && '*'}
					</span>
				);
			}
			return priceString;
		} else if (unitPrice === 'LOADING') {
			return <LoadingText inline className="m-0" />;
		} else if (unitPrice === 'ERROR') {
			return (
				<span className="text-danger">
					<FAIcon size="sm" name="exclamation-triangle" />
				</span>
			);
		} else if (unitPrice === 'NOT_FOUND') {
			return <>&mdash;</>;
		}
	};
	const getPartNumber = (productId?: number) => {
		const product = availableProducts?.find((product) => product.product_id === productId);
		return (
			product?.part_numbers.find((partNumber) => partNumber.part_number_owner === 'PUBLISHER')
				?.part_number || <>&mdash;</>
		);
	};
	const checkPriceMismatchOnItem = (productId?: number, submissionId?: number) => {
		const product = availableProducts?.find((product) => product.product_id === productId);
		if (product) {
			const submission = product.submissions.find(
				(submission) => submission.product_submission_id === submissionId,
			);
			if (
				submission &&
				selectedPlatformData &&
				selectedPlatformData.standard_rom_prices != null &&
				typeof selectedPlatformData.standard_rom_prices === 'object' &&
				submission.card_size in selectedPlatformData.standard_rom_prices
			) {
				const standardPrice =
					selectedPlatformData.standard_rom_prices[submission.card_size];
				const unitPrice = getUnitPrice(productId);
				if (isNaN(Number(unitPrice))) {
					return false;
				}
				if (standardPrice && unitPrice && standardPrice !== unitPrice) {
					return true;
				}
			}
		}
		return false;
	};
	const getInvalidProductFromOrder = (productId?: number) => {
		const item = orderData?.order_details.find(
			(line) => typeof line.product_id === 'object' && line.product_id.value === productId,
		);
		if (item) {
			return [item.game_code, item.product_name];
		}
		return ['Invalid product'];
	};
	const getAvailableProduct = (productId?: number) => {
		return availableProducts?.find((product) => product.product_id === productId);
	};
	const getInvalidSubmissionFromOrder = (submissionId?: number) => {
		const product = orderData?.order_details.find(
			(line) =>
				typeof line.submission_id === 'object' &&
				line.submission_id?.value === submissionId,
		);
		if (product) {
			return [
				`${product.product_release_version}.${product.submission_version}`,
				product.submission_status,
				product.card_size,
			];
		}
		return ['Invalid version'];
	};

	// validation checks
	const topFormValidationErrors = validateToSchema(step1Schema, topFormValues, {
		publisherPONumberIsOk,
	});
	const lineItemValidationErrors = lineItemFormValues.map((item, index) => {
		return validateToSchema(step1LineItemSchema, item, {
			leadInDays: selectedPlatformData?.lead_time_days_count,
			quantityMinimum: getMinimum(item.product_id),
			quantityPerCase: getBatchCount(),
			productsWithPublisherPartNumbers,
			invalidProductIds,
			invalidSubmissionIds,
			showPartNumbers,
		});
	});

	// controllers
	const changeTopFormValues = (newFormValues: Step1FormValues, changedField: string) => {
		if (changedField === 'platform_code') {
			if (
				topFormValues.platform_code &&
				newFormValues.platform_code !== topFormValues.platform_code
			) {
				setOpenModal({ type: 'CONFIRM_PLATFORM_CHANGE_MODAL' });
			} else {
				setSelectedPlatform(newFormValues['platform_code']);
			}
		}
		if (changedField === 'publisher_po_number') {
			newFormValues.publisher_po_number = newFormValues.publisher_po_number?.toUpperCase();
		}
		setTopFormValues(newFormValues);
	};
	const setItemValues = (values: LineItemInstance, index: number) => {
		const newline = {
			...values,
			submission_id:
				lineItemFormValues[index].product_id !== values.product_id
					? undefined
					: values.submission_id,
			quantity: lineItemFormValues[index].product_id !== values.product_id
				? undefined
				: values.quantity,
		};
		const newItems = [...lineItemFormValues];
		newItems[index] = newline;
		setLineItemFormValues(newItems);
	};
	const addItemRow = () => {
		const newItem = {};
		itemKeys.set(newItem, getUniqueKey());
		setLineItemFormValues([...lineItemFormValues, newItem]);
	};
	const removeItemRow = (index: number) => {
		const newValues = [...lineItemFormValues];
		newValues.splice(index, 1);
		if (newValues.length === 0) {
			const newItem = {};
			itemKeys.set(newItem, getUniqueKey());
			newValues.push(newItem);
		}
		setLineItemFormValues(newValues);
	};
	const submitForm = async (advanceToNext: boolean = false) => {
		// check validation
		if (!advanceToNext && foundEDIPriceChange) {
			toasterNotify(
				<div> Price has changed since a product was added to the order.&nbsp;
					<a
						className="text-underline text-danger"
						href="mailto:thirdpartypublisher@noa.nintendo.com"
					>
						Please contact NOA via email.
					</a>
				</div>,
				'error',
			);
			return;
		} else if (
			advanceToNext &&
			(!isEmptyObject(topFormValidationErrors) ||
				!lineItemValidationErrors.every((item) => isEmptyObject(item)))
		) {
			setShowAllErrors(true);
			toasterNotify(
				'Required fields must be filled with valid values to continue. See validation messages in the form for specific issues.',
				'error',
			);
			return;
		} else if (advanceToNext && foundPriceRetrievalError) {
			toasterNotify(
				<div> Price could not be retrieved.&nbsp;
					<a
						className="text-underline text-danger"
						href="mailto:thirdpartypublisher@noa.nintendo.com"
					>
						Please contact NOA via email.
					</a>
				</div>,
				'error',
			);
			return;
		} else if (!advanceToNext &&
			lineItemValidationErrors.some((item) => 'quantity' in item &&
				item.quantity !== quantityRequired)
		) {
			toasterNotify(
				'Quantity must be valid to save this order. See validation messages in the form for specific issues.',
				'error',
			);
			return;
		} else if ('platform_code' in topFormValidationErrors) {
			toasterNotify('A platform needs to be selected for the order to be saved', 'error');
			return;
		} else if ('publisher_po_number' in topFormValidationErrors) {
			toasterNotify(
				'A never used before value for Publisher PO # is necessary for the order to be saved.',
				'error',
			);
			return;
		}

		// format payload
		const castedTopFormValues = step1Schema.cast(topFormValues, { stripUnknown: true });
		const castedLineItemFormValues = lineItemFormValues.map((item) =>
			step1LineItemSchema.cast(item, { stripUnknown: true }),
		).filter((item) => !isEmptyObject(item));

		const payload = {
			...castedTopFormValues,
			details: [...castedLineItemFormValues],
			action: advanceToNext ? 'submit' : 'save',
		};

		// transactions
		setIsSubmitting(true);
		try {
			if (orderHeaderId) {
				await putOrderInfoDetails(orderHeaderId, payload);
			} else {
				const newOrderResponse = await postNewOrderDetails(payload);
				onNewId(String(newOrderResponse.data));
			}
			toasterNotify('Order was saved successfully', 'success');
			if (advanceToNext) {
				onNext();
			} else {
				setIsSubmitting(false);
			}
		} catch (error: any) {
			const ediPricingError = error.response?.status === 409 &&
				error.response?.data.message.error.message === 'Price mismatch - cannot submit';
			if (ediPricingError) {
				toasterNotify(
					<div> Price has changed since a product was added to the order.&nbsp;
						<a
							className="text-underline text-danger"
							href="mailto:thirdpartypublisher@noa.nintendo.com"
						>
							Please contact NOA via email.
						</a>
					</div>,
					'error',
				);
			} else {
				if (error instanceof Error) {
					toasterNotify(createMessageForError(error, 'saving the order'), 'error', error);
				}
			}
			setIsSubmitting(false);
		}
	};

	useEffect(() => {
		setSaveCall(() => {
			submitForm();
		});
	});

	// determine which lines in the error legend to show
	const foundPriceChange = false; // TODO: implement price check later
	const foundPriceMismatch = lineItemFormValues.find((item) =>
		checkPriceMismatchOnItem(item.product_id, item.submission_id),
	);
	const foundPriceRetrievalError = priceQueries.find(
		(query) => query.isError && (query.error as any)?.response?.status === 409,
	);

	const isLoading = !initialized || resourcesQuery.isLoading || orderAndResourcesQuery.isLoading;

	const isEDIOrder = orderData?.edi_order_flag || false;
	const foundEDIPriceChange = (isEDIOrder && orderData?.order_details.some((item) =>
		item.unit_price !== getUnitPrice(item.product_id as number)
	)) || false;

	return (
		<>
			<Page.FullPageCol>
				{isLoading ? (
					<Loading />
				) : (
					<>
						<SectionTitle>Order Details</SectionTitle>
						<Row>
							<Col sm={8}>
								<Forms
									values={topFormValues}
									onChange={changeTopFormValues}
									validationErrors={topFormValidationErrors}
									showAllErrors={showAllErrors}
									disabled={isSubmitting}
								>
									<Forms.Select id="platform_code" disabled={isEDIOrder}>
										<Forms.Heading>Platform</Forms.Heading>
										{isEDIOrder && !!orderData && (
											<Forms.Option
												value={orderData.platform_code}
												key={orderData.platform_code}
											>
												{orderData.platform_name}
											</Forms.Option>
										)}
										{!isEDIOrder &&
											availablePlatforms?.map((platform) => (
												<Forms.Option
													value={platform.platform_code}
													key={platform.platform_code}
												>
													{platform.platform_name}
												</Forms.Option>
											))}
									</Forms.Select>
									<Forms.Text
										id="publisher_po_number"
										disabled={isEDIOrder}
										maxLength={22}
									>
										<Forms.Heading>Publisher PO Number</Forms.Heading>
									</Forms.Text>
									<Forms.TextArea id="comments">
										<Forms.Heading>Order Comments</Forms.Heading>
										<Forms.Help>Optional</Forms.Help>
									</Forms.TextArea>
								</Forms>
							</Col>
						</Row>

						<SectionTitle>Product Details</SectionTitle>
						<Table>
							<thead>
								<tr>
									<th>Product</th>
									<th>Version / Card Size</th>
									{showPartNumbers && <th>Part/SKU</th>}
									<th style={{ width: '12rem' }}>Requested Ship Date</th>
									<th style={{ width: '8rem' }}>Quantity</th>
									<th style={{ width: '8rem', textAlign: 'right' }}>Total</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{!!selectedPlatform &&
									!productsQuery.isLoading &&
									lineItemFormValues.map((item, index) => (
										<Forms
											key={itemKeys.get(item)}
											values={item}
											onChange={(newItemValues, changedField) => {
												if (changedField === 'product_id') {
													newItemValues = {
														...newItemValues,
														submission_id: undefined,
													};
												}
												setItemValues(newItemValues, index);
											}}
											validationErrors={lineItemValidationErrors[index]}
											showAllErrors={
												/original/.test(itemKeys.get(item) || '') ||
												showAllErrors
											}
											disabled={!selectedPlatform || isSubmitting}
											as="tr"
											noPadding
											vertical
										>
											<td>
												<Forms.SearchSelect
													id="product_id"
													disabled={isEDIOrder}
												>
													{availableProducts?.map(
														(product) =>
															product && (
																<Forms.Option
																	value={product?.product_id}
																	key={String(
																		product?.product_id,
																	)}
																	disabled={
																		selectedProducts.indexOf(
																			product?.product_id,
																		) > -1
																	}
																>
																	{product?.initial_order && (
																		<>
																			<Badge variant="warning">
																				<FAIcon name="exclamation-triangle" />{' '}
																				New
																			</Badge>{' '}
																		</>
																	)}
																	[{product.game_code}]{' '}
																	{product.game_name}
																</Forms.Option>
															),
													)}
													{item.product_id &&
														invalidProductIds?.includes(
															item.product_id,
														) &&
														!getAvailableProduct(item.product_id) && (
															<Forms.Option
																value={item.product_id}
																disabled={true}
																key={`disabled-${item.product_id}`}
															>
																<s>
																	{getInvalidProductFromOrder(
																		item.product_id,
																	).map((part, index, array) =>
																		index < array.length - 1
																			? `[${part}]`
																			: part,
																	)}
																</s>
															</Forms.Option>
														)}
												</Forms.SearchSelect>
											</td>
											<td style={{ width: '13rem' }}>
												<Forms.Select
													id="submission_id"
													disabled={!item.product_id}
												>
													{getAvailableProduct(
														item.product_id,
													)?.submissions.map((submission) => (
														<Forms.Option
															value={submission.product_submission_id}
															key={submission.product_submission_id}
														>
															{submission.release_version}.
															{submission.submission_version}{' '}
															<Badge
																variant="light"
																className="border"
															>
																{submission.submission_status}
															</Badge>{' '}
															{submission.card_size && (
																<Badge
																	variant="light"
																	className="border"
																>
																	{submission.card_size}
																</Badge>
															)}
														</Forms.Option>
													))}
													{item.submission_id != null &&
														!getAvailableProduct(
															item.product_id,
														)?.submissions.some(
															(submission) =>
																submission.product_submission_id ===
																item.submission_id,
														) && (
															<Forms.Option
																value={item.submission_id}
																key={`disabled-${item.submission_id}`}
																disabled={true}
															>
																{getInvalidSubmissionFromOrder(
																	item.submission_id,
																)?.map((part, index) =>
																	index === 0 ? (
																		<s>{part}</s>
																	) : (
																		<>
																			{' '}
																			<Badge
																				variant="light"
																				className="border"
																			>
																				{part}
																			</Badge>
																		</>
																	),
																)}
															</Forms.Option>
														)}
												</Forms.Select>
											</td>
											{showPartNumbers && (
												<td style={{ width: '10rem' }}>
													<div className="d-flex Step1OrderDetails__padded-values">
														<div>{getPartNumber(item.product_id)}</div>
													</div>
												</td>
											)}
											<td>
												<Forms.DateSelect
													id="requested_ship_date"
													filterDate={(date) =>
														![0, 6].includes(dayjs(date).day())
													}
													minDate={dayjs()
														.tz(timeZoneConstants.PACIFIC, true)
														.startOf('day')
														.add(
															(selectedPlatformData?.lead_time_days_count ||
																0) + 2,
															'days',
														)
														.toDate()}
												/>
											</td>
											<td>
												<Forms.Number
													id="quantity"
													step={getBatchCount()}
													min={getMinimum(item.product_id)}
													disabled={isEDIOrder}
												>
													{item.product_id && (
														<HelpBlock>
															<div className="Step1OrderDetails__quantity-constants">
																Case quantity:{' '}
																{numberWithCommas(getBatchCount())}
																<br />
																Minimum quantity:{' '}
																{numberWithCommas(
																	getMinimum(item.product_id),
																)}
																<br />
																Unit price:{' '}
																{getDisplayedUnitPrice(index)}
																<br />
															</div>
														</HelpBlock>
													)}
												</Forms.Number>
											</td>
											<td>
												<div
													className="d-flex Step1OrderDetails__padded-values"
													style={{
														justifyContent: 'flex-end',
													}}
												>
													<div>
														{formatCurrency(
															getTotalForItem(
																item.product_id,
																item.quantity,
															),
														) || null}
													</div>
												</div>
											</td>
											<td style={{ width: '2.25rem', textAlign: 'right' }}>
												<div className="d-flex Step1OrderDetails__padded-values">
													<div>
														<Button
															size="sm"
															variant="danger"
															onClick={(e) => removeItemRow(index)}
															disabled={
																!selectedPlatform ||
																isSubmitting ||
																isEDIOrder
															}
														>
															<FAIcon
																className="text-white"
																name="times"
															/>
														</Button>
													</div>
												</div>
											</td>
										</Forms>
									))}
								{!!selectedPlatform &&
									!productsQuery.isLoading &&
									lineItemFormValues.length > 0 && (
										<tr>
											<td colSpan={showPartNumbers ? 4 : 3}></td>
											<td
												style={{
													position: 'relative',
													background: 'var(--nin-color-ltgray3)',
												}}
											>
												<div className="Step1OrderDetails__grand-total-heading">
													Grand Total:
												</div>
												<div className="ml-2">
													<b>{getTotalQuantity() || <>&nbsp;</>}</b>
												</div>
											</td>

											<td
												style={{
													position: 'relative',
													background: 'var(--nin-color-ltgray3)',
												}}
											>
												<div className="text-right">
													<b>{getGrandTotal() || <>&nbsp;</>}</b>
												</div>
											</td>
											<td
												style={{
													position: 'relative',
													background: 'var(--nin-color-ltgray3)',
												}}
											></td>
										</tr>
									)}
							</tbody>
						</Table>

						{!selectedPlatform && (
							<Alert variant="info">
								Select a platform before adding items to the list
							</Alert>
						)}
						{productsQuery.isLoading && <Loading slim />}
						{!!selectedPlatform && !productsQuery.isLoading && (
							<div>
								<Button
									variant="link"
									onClick={() => addItemRow()}
									disabled={
										!availableProducts ||
										availableProducts.length <= lineItemFormValues.length ||
										isEDIOrder
									}
								>
									<FAIcon
										name="plus-circle"
										style={{ textDecoration: 'none' }}
										className="mr-1"
									/>
									Add another item
								</Button>
							</div>
						)}
						{(foundPriceChange ||
							foundPriceMismatch ||
							foundPriceRetrievalError ||
							foundEDIPriceChange) && (
							<div className="font-italic text-danger text-right">
								<small>
									{(foundPriceChange || foundEDIPriceChange) && (
										<div>* Price changed since product was added to order</div>
									)}
									{foundPriceMismatch && (
										<div>
											<sup>&dagger;</sup> Current price does not match current
											pricing standard.{' '}
											<a
												className="text-danger text-underline"
												href="mailto:thirdpartypublisher@noa.nintendo.com"
											>
												Please contact NOA via email
											</a>
										</div>
									)}
									{foundPriceRetrievalError && (
										<div>
											<FAIcon name="exclamation-triangle" /> Price could not
											be retrieved.{' '}
											<a
												className="text-danger text-underline"
												href="mailto:thirdpartypublisher@noa.nintendo.com"
											>
												Please contact NOA via email
											</a>
										</div>
									)}
								</small>
							</div>
						)}
						<div className="btn-container">
							<div className="float-left">
								<Button
									as={Link}
									variant="link"
									to="/orders/physical"
									disabled={isSubmitting}
								>
									Return to Orders
								</Button>
							</div>
							<div className="float-right d-flex">
								{isSubmitting && <LoadingText inline className="m-a" />}
								<Button
									className="ml-3"
									variant="outline-secondary"
									type="button"
									onClick={() => submitForm()}
									disabled={isSubmitting}
								>
									Save
								</Button>
								<Button
									className="ml-3"
									variant="primary"
									onClick={() => submitForm(true)}
									disabled={isSubmitting}
								>
									Next (Shipping) <FAIcon name="chevron-right" />
								</Button>
							</div>
						</div>
					</>
				)}
			</Page.FullPageCol>
			<DeleteConfirmationModal
				show={openModal?.type === 'CONFIRM_PLATFORM_CHANGE_MODAL'}
				closeModal={() => {
					setOpenModal({ ...openModal, type: null });
					setTopFormValues({ ...topFormValues, platform_code: selectedPlatform });
				}}
				title="Change Platform"
				message="Selecting a new platform will remove your current order items. Do you wish to continue?"
				confirmDelete={() => {
					setOpenModal({ ...openModal, type: null });
					setSelectedPlatform(topFormValues?.platform_code);
					setLineItemFormValues([{}]);
				}}
			/>
		</>
	);
};
export default Step1OrderDetails;
