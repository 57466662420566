import { isDateInRange } from './dateUtils';


/**
 * Custom evaluation function for filters, matches dates with range
 * @param  {} dateValue Date object or date parsable string
 * @param  {Array} range array of two dates representing a range of values
 */
export const dateRangeEvaluation = (dateValue, range) => {
	if (range.size === 0) {
		return true;
	}
	if (dateValue) {
		dateValue = new Date(/^\d{4}-\d{2}-\d{2}$/.test(dateValue) ? dateValue+'T00:00:00' : dateValue);
		if (range.size === 1) {
			const start = Array.from(range)[0];
			return new Date(dateValue) >= start;
		} else if (range.size === 2) {
			const rangeArray = Array.from(range);
			const start = new Date(Math.min(rangeArray[0], rangeArray[1]));
			const end = new Date(Math.max(rangeArray[0], rangeArray[1]) + (1000*60*60*24) - 1); // push cutoff time 24 hrs-1 sec past inputted time
			if (isDateInRange(new Date(dateValue), start, end)) {
				return true;
			}
		}
	}
	return false;
};
