import React, { Component } from 'react';
import { Col, FormCheck, FormGroup, Row } from 'react-bootstrap';

import BaseModal from '../../../components/BaseModal/BaseModal';
import { assetConst } from '../../../constants/assetConstants';
import { postAssetStatus } from '../../../services/productsService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';


function getAlertMessage() {
	let alertMessage =
		'Please click below to accept that you are willing to forgo the approval of this label.';
	return (
		alertMessage && (
			<div className="alert alert-warning">{alertMessage}</div>
		)
	);
}

function getTitle() {
	return 'Accept Forgo Label';
}

export default class AcceptForgoFinalLabelApprovalModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			display: true,
			accept: null
		};
	}

	accept(accept) {
		this.setState({
			accept: accept
		});
	}

	determineStatus() {
		const { accept } = this.state;
		return accept
			? assetConst.FINAL_LABEL_FORGO_STATUS.ACCEPT_FORGO
			: assetConst.FINAL_LABEL_FORGO_STATUS.REJECT_FORGO;
	}

	acceptRejectForgo() {
		const {
			productId,
			assetId,
			closeModal,
			loadAssetDetails
		} = this.props;

		const payload = {
			final_label_forgo_status: this.determineStatus()
		};

		this.setState({
			isProcessing: true
		});

		postAssetStatus(assetId, payload)
			.then((response) => {
				toasterNotify('Sent forgo response', 'success');
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'sending forgo response'),
					'error',
					error
				);
			})
			.finally(() => {
				loadAssetDetails(productId, assetId);
				closeModal();
			});
	}

	render() {
		const { closeModal } = this.props;
		const { display, isProcessing } = this.state;

		return (
			<BaseModal show={display} onCancel={closeModal}>
				<BaseModal.Title>{getTitle()}</BaseModal.Title>
				{getAlertMessage()}
				<div className="approve-asset-modal">
					<FormGroup>
						<Row>
							<Col sm={2}></Col>
							<Col sm={3}>
								<strong>Forgo Label</strong>
							</Col>
							<Col sm={5}>
								<FormCheck
									type="radio"
									id="radio-accept"
									name="groupOptions"
									onChange={this.accept.bind(this, true)}
									label="I Accept"
								/>
								<FormCheck
									type="radio"
									id="radio-reject"
									name="groupOptions"
									onChange={this.accept.bind(this, false)}
									label="I Reject"
								/>
							</Col>
						</Row>
					</FormGroup>
				</div>
				<BaseModal.Submit
					disabled={isProcessing}
					onClick={() => {
						this.acceptRejectForgo();
					}}
				>
					Confirm
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}
