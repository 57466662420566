import React, { VFC, useEffect, useState } from 'react';
import * as yup from 'yup';
import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import AsperaFileUpload from '../../../components/AsperaFileUpload/AsperaFileUpload';
import { allowedFileExtensions, fileTransferConst } from '../../../constants/fileTransferConstants';
import { doAllFilesHaveExtension, fileNameHasGameCode } from '../../../utils/assetUtils';
import { assetConst } from '../../../constants/assetConstants';
import { postAssetStatus } from '../../../services/productsService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { validateToSchema } from '../../../utils/validationUtils';
import { isEmptyObject } from '../../../utils/dataUtils';

const GAME_CODE_PLACEHOLDER = '%GAME_CODE%';

const schema = yup.object().shape({
	comment: yup.string().required('Rejection Comments are required'),
	files: yup
		.array()
		.optional()
		.test(
			'game_code_check',
			'Selected file must include the game code (' +
				GAME_CODE_PLACEHOLDER +
				") in the file's name",
			(value, { options }) =>
				!value ||
				value.length < 1 ||
				value.every((item: AsperaFile) =>
					fileNameHasGameCode(item.file_name, options?.context?.gameCode),
				),
		),
});

interface RequestNewLabelModalProps {
    show: boolean;
    onClose: () => void;
	onSuccess: () => void;
	assetId?: number;
	gameCode?: string;
}
const RequestNewLabelModal:VFC<RequestNewLabelModalProps> = ({ show, onClose, assetId, gameCode, onSuccess }) => {
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [formValues, setFormValues] = useState<{ comment?: string }>({});
	const [isUploadInitiated, setIsUploadInitiated] = useState<boolean>(false);
	const [transferSpecs, setTransferSpecs] = useState<{ transfer_specs: AsperaUploadSpec[] }>();
	const [selectedFiles, setSelectedFiles] = useState<AsperaFile[]>();
	const [isFileSelected, setIsFileSelected] = useState<boolean>();
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);
	const [revisionId, setRevisionId] = useState<number>();

	useEffect(() => {
		if (show) {
			setFormValues({});
			setIsUploadInitiated(false);
			setIsFileSelected(undefined);
			setTransferSpecs(undefined);
			setRevisionId(undefined);
			setSelectedFiles(undefined);
			setShowAllErrors(false);
		}
	}, [show]);

	const validationErrors = validateToSchema(schema, { ...formValues, files: selectedFiles }, { gameCode });
	if (validationErrors.files && gameCode) {
		validationErrors.files = validationErrors.files.replace(GAME_CODE_PLACEHOLDER, gameCode);
	}

	const submitForm = async () => {
		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}
		setIsSubmitting(true);

		const payload = isFileSelected ? {
			review_status: assetConst.STATUS.NCL_PROOF_UPLOADING,
			file_upload_type: assetConst.UPLOAD.NCL_PROOF,
			comment: formValues.comment,
			files: selectedFiles,
		} : {
			review_status: assetConst.STATUS.FINAL_LABEL_REJECTED,
			comment: formValues.comment
		};
		try {
			const response = await postAssetStatus(String(assetId), payload);
			toasterNotify('Request submitted', 'success');
			if (isFileSelected) {
				setTransferSpecs({
					transfer_specs: response.data.transfer_specs,
				});
				setRevisionId(response.data.revision_id);
				setIsUploadInitiated(true);
			}
			onSuccess();
			onClose();
		} catch (error: unknown) {
			if (error instanceof Error && 'isAxiosError' in error && error.isAxiosError) {
				toasterNotify(
					createMessageForError(error, 'submitting file(s)'),
					'error',
					error
				);
			} else {
				throw error;
			}
		} finally {
			setIsSubmitting(false);
		}
	};


	return (
        <BaseModal show={show} onCancel={onClose} isSubmitting={isSubmitting}>
            <BaseModal.Title><span className="text-danger">Request New Label</span></BaseModal.Title>
                <Forms
                    values={formValues}
                    onChange={(newValues) => setFormValues(newValues)}
                    validationErrors={validationErrors}
                    showAllErrors={showAllErrors}
                >
                    <Forms.TextArea id="comment" rows={4}>
                        <Forms.Heading>Rejection Comments</Forms.Heading>
                    </Forms.TextArea>
                    <Forms.CustomArea id="files">
                        <Forms.Heading>Annotated Label File</Forms.Heading>
                        <AsperaFileUpload
							updateFilesSelected={(status: boolean) => setIsFileSelected(status)}
							entityType={fileTransferConst.ENTITY_TYPE.PRODUCT_ASSET_REVISION}
							entityId={revisionId}
							isUploadInitiated={isUploadInitiated}
							allowedFileTypes={[
								{
									filter_name: 'Accepted Files',
									extensions: allowedFileExtensions.FINAL_LABEL,
								},
							]}
							validateFileType={(files: AsperaFile[]) => {
								setSelectedFiles(files);
								const allowedFileTypes = allowedFileExtensions.PACKAGING_ASSET;
								if (doAllFilesHaveExtension(files, allowedFileTypes)) {
									return true;
								}
								return false;
							}}
							prefetchedTransferSpecs={transferSpecs}
						/>
                        <Forms.Help>Optional. Selected files must include game code ({gameCode}) in the file name.</Forms.Help>
                    </Forms.CustomArea>
                </Forms>
            <BaseModal.Submit variant="danger" onClick={() => submitForm()}>Submit</BaseModal.Submit>
        </BaseModal>
	);
};
export default RequestNewLabelModal;
