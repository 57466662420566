/**
 * This utility removes all non-digit characters except for dots and numeric sign.
 *
 * @param  {string} currencyString
 * @returns number
 */
export function parseCurrency(currencyString: string): number {
	return Number(currencyString.toString().replace(/[^0-9.-]+/g, ''));
}

/**
 * This utility will just add commas to larger numbers (Can be used for more than just currency.
 * https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
 * *
 * @param  {number} input number
 * @returns string | void
 */
export function numberWithCommas(input?: number): string | void {
	return input != null ? input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
}

/**
 * Format price from EBS to displayable string with 2 decimal places
 * @param  {any} priceFromEbs
 */
export const ebsRoundedPrice = (priceFromEbs: any): string =>
	Number.parseFloat(priceFromEbs).toFixed(2);

/**
 * Format unitPrice from EBS to q string with 5 decimal places
 * @param  {any} priceFromEbs
 */
export const ebsRoundedUnitPrice = (priceFromEbs: any): string =>
	Number.parseFloat(priceFromEbs).toFixed(5);

/**
 * Take a number and format as a displayable currency value, with fixed decimal places
 * 
 * @param  {number} numberToFormat
 * @param  {number=2} decimalCount
 * @returns string | void
 */
export const formatCurrency = (numberToFormat?: number | string, decimalCount: number = 2): string | undefined => {
	if (numberToFormat === undefined) { return numberToFormat; }
	numberToFormat = Number(numberToFormat);
	if (isNaN(numberToFormat)) {
		numberToFormat = 0;
	}
	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: decimalCount,
		maximumFractionDigits: decimalCount,
	}).format(numberToFormat);
};
