export const digitalCodesConstants = {
	status: {
		DRAFT: 'DRAFT',
		READY_FOR_REVIEW: 'READY_FOR_REVIEW',
		READY_FOR_APPROVAL: 'READY_FOR_APPROVAL',
		REJECTED: 'REJECTED',
		CANCELED: 'CANCELED',
		AWAITING_PAYMENT_INFO: 'AWAITING_PAYMENT_INFO',
		PAYMENT_PENDING: 'PAYMENT_PENDING',
		PAYMENT_RECEIVED: 'PAYMENT_RECEIVED',
		UPLOAD_IN_PROGRESS: 'UPLOAD_IN_PROGRESS',
		UPLOAD_FAILED: 'UPLOAD_FAILED',
		CODES_AVAILABLE: 'CODES_AVAILABLE',
		DOWNLOAD_EXPIRED: 'DOWNLOAD_EXPIRED',
		COMPLETE: 'COMPLETE'
	},
	contentTypes: {
		BUNDLE: 'BUNDLE',
		TITLE: 'TITLE'
	},
	priceRequestStatuses: { // all statuses used in WSP and DP, including request and price statuses
		// request status
		APPROVED: 'APPROVED',
		DECLINED: 'DECLINED',
		PENDING_REVIEW: 'PENDING_REVIEW',
		RETRACTED: 'RETRACTED',
		REVIEWED: 'REVIEWED',
		// additional presentation statuses
		APPROVED_AND_ACTIVATING: 'APPROVED_AND_ACTIVATING',
		APPROVED_AND_ACTIVE: 'APPROVED_AND_ACTIVE',
		APPROVED_AND_INACTIVE: 'APPROVED_AND_INACTIVE',
		// price status
		ACTIVATING: 'ACTIVATING',
		ACTIVE: 'ACTIVE',
		INACTIVE: 'INACTIVE',
	},
	priceRequestActions: { // for use with postWSPRequestAction() and postDPRequestAction()
		PRICED: 'PRICED',
		COMMENT: 'COMMENT',
		SUBMITTED: 'SUBMITTED',
		APPROVED: 'APPROVED',
		DECLINED: 'DECLINED',
		RETRACTED: 'RETRACTED',
		TRANSFERRED: 'TRANSFERRED',
		REINSTATED: 'REINSTATED',
		SUPERSEDED: 'SUPERSEDED',
		EXTENDED_LIMIT: 'EXTENDED_LIMIT',
		PERMANENT_PRICE: 'PERMANENT_PRICE',
	},
};
