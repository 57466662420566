import React, { Component } from 'react';
import { Alert, Table } from 'react-bootstrap';

import { deleteSubmissionFiles } from '../../../../services/submissionsService';
import BaseModal from '../../../../components/BaseModal/BaseModal';
import { createMessageForError, toasterNotify } from '../../../../utils/toaster';

import SubmissionFileRow from './SubmissionFileRow';

class RemoveSubmissionFilesModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			deletedItems: []
		};

		this.markToDelete = this.markToDelete.bind(this);
	}

	markToDelete(id) {
		let deletedItems = this.state.deletedItems;
		deletedItems.push(id);
		this.setState({ deletedItems: deletedItems });
	}

	saveChanges() {
		deleteSubmissionFiles(this.props.productSubmissionId, this.state.deletedItems)
			.then((response) => {
				toasterNotify('Submission file(s) removed', 'success');
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'removing submission file(s)'),
					'error',
					error,
				);
			})
			.finally(() => {
				this.props.closeModal();
				this.props.loadActivityLog();
				this.props.setSubmissionFiles();
			});
	}

	getSubmissionFiles() {
		return (
			<Table>
				<thead>
					<tr>
						<th>File Name</th>
						<th colSpan="2">Upload Date/Time</th>
					</tr>
				</thead>
				<tbody>
					{this.props.submissionFiles.map((item, i) => (
						<SubmissionFileRow
							asset={item}
							key={i}
							markToDelete={this.markToDelete}
						/>
					))}
				</tbody>
			</Table>
		);
	}

	render() {
		return (
			<BaseModal show={true} onCancel={this.props.closeModal}>
				<BaseModal.Title>Remove Submission File(s)</BaseModal.Title>
				<Alert variant="info">
					Please note that when files are deleted, they are still stored in this system.
				</Alert>
				{this.props.submissionFiles &&
					this.props.submissionFiles.length > 0 &&
					this.getSubmissionFiles()}
				<BaseModal.Submit
					onClick={() => {
						this.saveChanges();
					}}
				>
					Save
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}

export default RemoveSubmissionFilesModal;
