import api from './baseService';

export const createProductTransferRequest = (data: Record<string, any>) =>
	api().post('/pdb/products/rights-transfers', data);

export const getProductRightsTransferRequests = (transfer_type: string) =>
	api().get(`/pdb/products/rights-transfers/?transfer_type=${transfer_type}`);

export const postProductRightsTransferRequests = (productTransferId: string, data: Record<string, any>) =>
	api().post(`/pdb/products/rights-transfers/${productTransferId}/status`, data);

export const getProductRightsTransferRequest = (productTransferId: string) =>
	api().get(`/pdb/products/rights-transfers/${productTransferId}`);

export const getProductRightsTransferRequestByTransferToken = (
	productTransferToken: string,
	transfer_type: string,
) =>
	api().get(
		`/pdb/products/rights-transfers?transfer_token=${productTransferToken}&transfer_type=${transfer_type}`,
	);
