import React, { Component } from 'react';

import BaseModal from '../../../components/BaseModal/BaseModal';
import { orderConst } from '../../../constants/orderConstants';
import { putEditOrder } from '../../../services/ordersService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';


class ConfirmOrderModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			isSaving: false
		};
		this.toggleLoading = this.toggleLoading.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	confirmModal() {
		const { order, history } = this.props;
		this.setState({ isSaving: true });

		if (order.consignee_info) {
			order.consignee_info.consignee_company_id = this.props.userProfile.companyId;
		}

		order.order_status = orderConst.STATUS.SUBMITTED_FOR_REVIEW;

		this.toggleLoading();

		putEditOrder(order.order_header_id, order)
			.then((response) => {
				toasterNotify('Order confirmed', 'success');
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'submitting order'),
					'error',
					error
				);
			})
			.finally(() => {
				history.push('/orders');
				this.props.closeModal();
				this.setState({ isSaving: false });
			});
	}

	render() {
		const { isSaving } = this.state;
		return (
			<BaseModal
				show={true}
				onCancel={this.props.closeModal}
				isSubmitting={this.state.isLoading}
			>
				<BaseModal.Title>Confirm order</BaseModal.Title>

				<p>
					The price reflected in your Order Detail is an estimate of the total cost of
					your order.
				</p>

				<p>
					A pro-forma invoice will be available to download after your order is approved
					by Nintendo. Your pro-forma for this order will reflect any price difference.
				</p>

				<p>
					Note, you are responsible for all wire transfer fees. The final amount
					transferred to Nintendo of America must match the amount shown on the pro-forma
					invoice.
				</p>

				<p>
					Once your order is accepted by NOA, it shall be governed by, and subject to, the
					terms and conditions of your Nintendo Switch Content and Distribution License
					Agreement and/or Nintendo 3DS Content License Agreement (as applicable) with
					Nintendo.
				</p>

				<p>
					You cannot make any changes once you submit your order to NOA. Please confirm
					that you would like to proceed with this order by selecting Submit Order below.
				</p>
				<BaseModal.Submit
					disabled={isSaving}
					onClick={() => {
						this.confirmModal();
					}}
				>
					Submit Order
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}

export default ConfirmOrderModal;
