import React, { HTMLAttributes, PropsWithChildren, ReactNode, VFC } from 'react';
import { Dropdown } from 'react-bootstrap';
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem';

import FAIcon from '../FAIcon/FAIcon';

import './MeatballDropdown.css';


type MeatballMenuSubcomponents = {
	Item: typeof Dropdown.Item;
	Divider: typeof Dropdown.Divider;
	Header: typeof Dropdown.Header;
};

const filterMenuItems = (children: ReactNode) =>
	React.Children.toArray(children)
		// filter removes unnecessary headings
		.filter((node, index, array) => {
			if (typeof node === 'object' && 'type' in node && node.type === Dropdown.Header) {
				// remove heading if it is at the end of the menu
				if (index === array.length - 1) { return false; }
				// remove heading if it does not precede an item
				const nextItem = array[index + 1];
				if (typeof nextItem === 'object' && 'type' in nextItem && nextItem.type !== Dropdown.Item) { return false; }
			}
			return true;
		})
		// filter removes unnecessary dividers
		.filter((node, index, array) => {
			if (typeof node === 'object' && 'type' in node && node.type === Dropdown.Divider) {
				// remove dividers at the top or bottom of the array
				if (index === array.length - 1 || index === 0) { return false; }
				// remove dividers that directly follow another divider
				const previousItem = array[index - 1];
				if (
					typeof previousItem === 'object' &&
					'type' in previousItem &&
					previousItem.type === Dropdown.Divider
				) {
					return false;
				}
			}
			return true;
		})
		// one more filter to catch strays
		.filter((node, index, array) => {
			if (typeof node === 'object' && 'type' in node && node.type === Dropdown.Divider) {
				// remove dividers at the top or bottom of the array
				if (index === array.length - 1 || index === 0) { return false; }
			}
			return true;
		});

interface MeatballDropdownProps extends HTMLAttributes<HTMLElement>  {
	id?: string;
	toggleSize?: 'sm' | 'lg'; 
}
const MeatballDropdown: VFC<PropsWithChildren<MeatballDropdownProps>> &
	MeatballMenuSubcomponents = ({ id, children, toggleSize }) => {
		const items = filterMenuItems(children);
		if (!items.length) {
			return null;
		}
		return (
		<Dropdown alignRight className="MeatballDropdown">
			<Dropdown.Toggle
				className={
					'MeatballDropdown__toggle btn' +
					(toggleSize === 'lg' ? ' btn-default' : ' btn-sm')
				}
				variant="success"
				id={id}
				as={'div'}
			>
				<FAIcon name="ellipsis-h" aria-label="Actions menu" />
			</Dropdown.Toggle>
			<Dropdown.Menu>{items}</Dropdown.Menu>
		</Dropdown>
		);
	};

const Item = (props: DropdownItemProps & { variant?: string; }) => {
	return (
		<Dropdown.Item {...props}>
			{props.variant && !props.disabled ? (
				<span className={`text-${props.variant}`}>{props.children}</span>
			) : (
				props.children
			)}
		</Dropdown.Item>
	);
};

MeatballDropdown.Item = Item;
MeatballDropdown.Divider = Dropdown.Divider;
MeatballDropdown.Header = Dropdown.Header;

export default MeatballDropdown;
