export const fileTransferConst = {
	ENTITY_TYPE: {
		DIGITAL_CODES: 'digital_codes',
		HELP_TOPIC: 'help_topic',
		PRODUCT_ASSET_REVISION: 'product_asset_revision',
		PRODUCT_SUBMISSION: 'product_submission',
		PRODUCT_TRANSFER: 'product_transfer',
		NOTIFICATION_ATTACHMENT: 'notification_attachment',
		MARKETING_EVENT_TASK: 'marketing_event_task',
		ROM_VERSION_FILE: 'rom_version_file',
	} as const,
	TRANSFER_STATES: {
		COMPLETED: 'completed',
		DELETED: 'deleted',
		FAILED: 'failed',
		INFECTED: 'infected',
		WAITING: 'waiting'
	} as const,
};

export const contentTypesConst = {
	DOC: 'application/msword',
	DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	PDF: 'application/pdf'
} as const;

export const allowedFileExtensions = {
	CODE_IN_BOX_ASSET: [
		'pdf'
	],
	DIGITAL_CODES: [
		'zip'
	],
	FINAL_LABEL: [
		'pdf',
		'zip'
	],
	HELP_TOPIC: [
		'pdf',
		'zip'
	],
	MARKETING_ASSET: [
		'avi',
		'bmp',
		'doc',
		'docx',
		'flv',
		'gif',
		'jpeg',
		'jpg',
		'm4v',
		'mov',
		'mp4',
		'mpeg',
		'mpg',
		'pdf',
		'png',
		'ppt',
		'pptx',
		'psd',
		'rtf',
		'swf',
		'tif',
		'tiff',
		'txt',
		'wav',
		'webm',
		'wmv',
	],
	PACKAGING_ASSET: [
		'pdf'
	],
	PRODUCT_TRANSFER: [
		'doc',
		'docx',
		'pdf'
	],
	PLAIN_TEXT: [
		'txt'
	],
};
