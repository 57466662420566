import React, { Component } from 'react';

import BaseModal from '../../../components/BaseModal/BaseModal';
import { deleteOrder } from '../../../services/ordersService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';


class DeleteOrderModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false
		};
		this.toggleLoading = this.toggleLoading.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	onDelete() {

		const { orderHeaderId, history } = this.props;
		this.toggleLoading();

		deleteOrder(orderHeaderId)
			.then((response) => {
				toasterNotify('Draft order deleted', 'success');
				history.push('/orders');
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'deleting draft order'),
					'error',
					error
				);
				this.toggleLoading();
			});
	}

	render() {
		return (
			<BaseModal
				show={true}
				onCancel={this.props.closeModal}
				isSubmitting={this.state.isLoading}
			>
				<BaseModal.Title>Delete order</BaseModal.Title>
				<p>Are you sure you want to delete this order?</p>
				<BaseModal.Submit
					variant="danger"
					onClick={() => {
						this.onDelete();
					}}
				>
					Delete
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}

export default DeleteOrderModal;
