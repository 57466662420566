import { permConst } from '../../../constants/permConst';
import { isAuthorized } from '../../../utils/userUtils';


export const getPermissions = (permissions) => {
	return {
		canViewInternal: isAuthorized(permissions, [
			permConst.MARKETING_EVENT.REPORT.VIEW.MANAGER,
			permConst.MARKETING_EVENT.REPORT.VIEW.COORDINATOR,
		]),
		canViewExternal: isAuthorized(permissions, [
			permConst.MARKETING_EVENT.REPORT.VIEW.COMPANY,
			permConst.MARKETING_EVENT.REPORT.VIEW.COMPANY_SUPPORT,
		])
	};
};
