import React from 'react';
import { Alert, Form, FormCheck, FormGroup, Row } from 'react-bootstrap';

import AsperaFileUpload from '../../../components/AsperaFileUpload/AsperaFileUpload';
import BaseModal from '../../../components/BaseModal/BaseModal';
import HelpBlock from '../../../components/HelpBlock/HelpBlock';
import {
	allowedFileExtensions,
	contentTypesConst,
	fileTransferConst
} from '../../../constants/fileTransferConstants';
import { transferRightsConstants } from '../../../constants/transferRightsConstants';
import { createProductTransferRequest } from '../../../services/productTransfersService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';


export default class AssignDistributionRightsModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			display: true,
			isLoading: false,
			retailFlag: false,
			digitalFlag: false,
			isFilesSelected: false,
			isUploadInitiated: false,
			invalidFileType: false,
			productTransferId: null,
			selectedFiles: null,
			prefetchedTransferSpecs: null
		};

		this.toggleLoading = this.toggleLoading.bind(this);
		this.updateFilesSelected = this.updateFilesSelected.bind(this);
		this.validateFileType = this.validateFileType.bind(this);
		this.viewRequest = this.viewRequest.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	toggleRetailRight() {
		this.setState({
			retailFlag: !this.state.retailFlag
		});
	}

	toggleDigitalRight() {
		this.setState({
			digitalFlag: !this.state.digitalFlag
		});
	}

	updateFilesSelected(isFilesSelected) {
		this.setState({
			isFilesSelected: isFilesSelected
		});
	}

	viewRequest() {
		this.props.history.push(
			'/admin/product-distribution-transfer-requests/'
		);
	}

	createAssignmentRequest() {
		const { productSummary } = this.props;
		const { retailFlag, digitalFlag, selectedFiles } = this.state;

		const payload = {
			product_id: productSummary.product_id,
			retail_pub_flag: retailFlag,
			digital_pub_flag: digitalFlag,
			transfer_type: transferRightsConstants.TRANSFER_TYPE.DISTRIBUTION,
			files: selectedFiles
		};

		this.toggleLoading();
		createProductTransferRequest(payload)
			.then((response) => {
				const productTransferId = response.data.product_transfer_id.toString();
				this.setState({
					productTransferId: productTransferId,
				});
				this.setState({
					isUploadInitiated: true,
					prefetchedTransferSpecs: response.data.transfer_specs && {
						transfer_specs: response.data.transfer_specs,
					},
				});
				toasterNotify('Created assignment request', 'success');
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'creating assignment request'),
					'error',
					error
				);
				this.setState({
					isLoading: false,
				});
			});
	}

	validateFileType(selectedFiles) {
		const allowedContentTypes = [contentTypesConst.DOC, contentTypesConst.DOCX, contentTypesConst.PDF];
		let invalid = false;
		if (allowedContentTypes) {
			selectedFiles.forEach((file) => {
				if (!allowedContentTypes.includes(file.content_type)) {
					invalid = true;
				}
			});
		}
		this.setState({
			invalidFileType: invalid,
			selectedFiles: !invalid && selectedFiles
		});
	}

	render() {
		const {
			display,
			retailFlag,
			digitalFlag,
			isUploadInitiated,
			isFilesSelected,
			invalidFileType,
			productTransferId,
			prefetchedTransferSpecs
		} = this.state;
		const {
			closeModal,
			productSummary,
			userProfile,
			distRightAssignable
		} = this.props;
		const canAssignRetail =
			productSummary.eligible_transfer_flags.has_retail &&
			distRightAssignable.retail;
		const canAssignDigital =
			productSummary.eligible_transfer_flags.has_digital &&
			distRightAssignable.digital;
		const canGenerateCode =
			!this.state.isLoading && (retailFlag || digitalFlag);

		return (
			<BaseModal
				show={display}
				size="lg"
				onCancel={closeModal}
				isSubmitting={this.state.isLoading}
			>
				<BaseModal.Title>Assign Distribution Rights</BaseModal.Title>

				<Alert variant="info">
					Assigning distribution rights gives another publisher the rights to your product
					in this tool. A transfer in this tool doesn't apply to any other system.
					<br />
					You may transfer the distribution rights for the retail, digital or both
					versions of your product to another publisher. You may also transfer the
					distribution rights of the digital or retail version of your product to separate
					publishers.
					<br />
					During a distribution transfer the product will be locked. The transfer code
					doesn't expire but you may rescind the transfer which invalidates the code.
					After the transfer is completed your only access to the product in this tool is
					to rescind the distribution transfer.
					<br />
					Steps to complete a transfer:
					<br />
					Select which version to transfer - digital, retail, or both.
					<br />
					Generate a transfer code.
					<br />
					Add transfer documentation.
					<br />
					Supply the code to the publisher receiving the product.
					<br />
					The receiving publisher enters the code in the Admin Distribution Transfer menu.
					<br />
					Nintendo accepts the transfer.
				</Alert>

				<Form>
					<FormGroup as={Row}>
						<label className="col-sm-4 control-label text-sm-right">Platform</label>
						<div className="col-sm-7 control-value">{productSummary.platform_name}</div>
					</FormGroup>

					<FormGroup as={Row}>
						<label className="col-sm-4 control-label text-sm-right">Product Name</label>
						<div className="col-sm-7 control-value">{productSummary.game_name}</div>
					</FormGroup>

					<FormGroup as={Row}>
						<label className="col-sm-4 control-label text-sm-right pt-0">
							Assign Rights
						</label>
						<div className="col-sm-3 control-value">
							{canAssignRetail && (
								<FormCheck
									type="checkbox"
									id="checkbox-retail"
									checked={retailFlag ? 'checked' : ''}
									onChange={() => this.toggleRetailRight()}
									label='Retail (Physical)'
								/>
							)}
							{canAssignDigital && (
								<FormCheck
									type="checkbox"
									id='checkbox-digital'
									checked={digitalFlag ? 'checked' : ''}
									onChange={() => this.toggleDigitalRight()}
									label='Digital'
								/>
							)}
						</div>
					</FormGroup>

					<FormGroup as={Row}>
						<label className="col-sm-4 control-label text-sm-right">
							Assign Distribution Rights Agreement(s)
						</label>

						<div className="col-sm-7 control-value">
							<AsperaFileUpload
								updateFilesSelected={this.updateFilesSelected}
								userProfile={userProfile}
								entityType={fileTransferConst.ENTITY_TYPE.PRODUCT_TRANSFER}
								entityId={productTransferId}
								isUploadInitiated={isUploadInitiated}
								onCompleteFunction={this.viewRequest}
								validateFileType={this.validateFileType}
								allowedFileTypes={[
									{
										filter_name: 'Accepted Files',
										extensions: allowedFileExtensions.PRODUCT_TRANSFER,
									},
								]}
								prefetchedTransferSpecs={prefetchedTransferSpecs}
							/>
							<HelpBlock>PDF, DOC, DOCX Only</HelpBlock>
						</div>
					</FormGroup>
				</Form>

				<BaseModal.Submit
					disabled={
						!canGenerateCode || !isFilesSelected || invalidFileType || isUploadInitiated
					}
					onClick={() => this.createAssignmentRequest()}
				>
					Generate Assignment Code
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}
