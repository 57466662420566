import React, { useEffect, useState } from 'react';
import { Alert, FormGroup, FormLabel, Row, Col } from 'react-bootstrap';
import { useUserProfile } from '../../../../hooks/reduxHooks';
import { eventConstants } from '../../../../constants/eventConstants';
import { fileTransferConst } from '../../../../constants/fileTransferConstants';
import {isEmptyObject, safeEval} from '../../../../utils/dataUtils';
import { validateToSchema } from '../../../../utils/validationUtils';
import { getDATokenForMarketingEventTask } from '../../../../utils/eventUtils';
import EventTaskAttachmentLinkCell from '../../../cells/EventTaskAttachmentLinkCell/EventTaskAttachmentLinkCell';
import BaseModal from '../../../BaseModal/BaseModal';
import Forms from '../../../Forms/Forms';
import AsperaFileUpload from '../../../../components/AsperaFileUpload/AsperaFileUpload';


export const NONE = null;
export const READ = 1;
export const WRITE = 2;

const { OPEN, IN_PROGRESS, COMPLETED } = eventConstants.TASKS.STATUS;
const { INTERNAL, EXTERNAL } = eventConstants.TASKS.TYPE;

const EventTaskModalView = ({
	children,
	show,
	onClose,
	submitForm,
	isSubmitting,
	isLoading,
	fault,
	title,
	initialValues,
	assignableUsers,
	assignableCompanies,
	schema,
	fieldPermissions,
	pastPublisherAssignee,
	showNewAttachment,
	showReplacementAttachment,
	currentAttachment
}) => {
	const [formValues, setFormValues] = useState({});
	const [showAllErrors, setShowAllErrors] = useState(false);
	const [fileSelected, setFileSelected] = useState();
	const [uploadPromiseResolve, setUploadPromiseResolve ] = useState(null);
	const [attachmentTaskId, setAttachmentTaskId] = useState(null);
	const [isUploadInitiated, setIsUploadInitiated] = useState(false);

	const userProfile = useUserProfile();

	useEffect(() => {
		if (show && !isLoading) {
			setFormValues(initialValues);
			setShowAllErrors(false);
			setFileSelected(null);
			setUploadPromiseResolve(null);
			setAttachmentTaskId(null);
			setIsUploadInitiated(false);
		}
	}, [show, isLoading]);

	const readWritePerms =
		typeof fieldPermissions === 'function'
			? fieldPermissions(formValues)
			: {...fieldPermissions};

	if (initialValues) {
		Object.keys(readWritePerms).forEach((key) => {
			if (!(key in initialValues)) {
				delete readWritePerms[key];
			}
		});
	}

	const changedFormValue = (newValues) => {
		// reset the assigned person/company if it should be removed from view
		if (formValues.assignment_type !== newValues.assignment_type) {
			newValues = { ...newValues, active: false };
			delete newValues.assigned_ndid_user_id;
			delete newValues.assigned_ndid_company_id;
		}
		setFormValues(newValues);
	};

	const validationErrors = schema && formValues && validateToSchema(schema, formValues);

	const uploadCallback = (taskId) => {
		setAttachmentTaskId(taskId);

		let resolveFn;
		const promise = new Promise((resolve) => resolveFn = resolve);
		setUploadPromiseResolve (() => resolveFn);

		setIsUploadInitiated(true);

		return promise;
	};

	const onSubmit = () => {
		if (!isEmptyObject(validationErrors)) {
			return setShowAllErrors(true);
		}

		if (fileSelected) {
			submitForm(formValues, uploadCallback);
		} else {
			submitForm(formValues);
		}

	};

	return (
		<BaseModal
			show={show}
			onCancel={onClose}
			isSubmitting={isSubmitting}
			isLoading={isLoading}
			fault={fault}
		>
			<BaseModal.Title>{title}</BaseModal.Title>
			{children}
			{initialValues && !('id' in initialValues) && formValues.assignment_type === EXTERNAL && (
				<Alert variant="info">
					Selecting ACTIVE will require a Publisher Assignee and Due Date to be set.
				</Alert>
			)}
			<Forms
				onChange={(id, v) => changedFormValue(id, v)}
				values={formValues}
				validationErrors={validationErrors}
				showAllErrors={showAllErrors}
				onFormSubmit={() => onSubmit()}
			>
				{readWritePerms['active'] && (
					<Forms.SingleCheckbox id="active" disabled={readWritePerms['active'] !== WRITE}>
						<Forms.Heading>Active</Forms.Heading>
						<Forms.Label>YES</Forms.Label>
					</Forms.SingleCheckbox>
				)}
				{readWritePerms['status'] && (
					<Forms.BasicSelect id="status" disabled={readWritePerms['status'] !== WRITE}>
						<Forms.Heading>Status</Forms.Heading>
						<Forms.Option value={OPEN}>{OPEN}</Forms.Option>
						<Forms.Option value={IN_PROGRESS}>{IN_PROGRESS}</Forms.Option>
						<Forms.Option value={COMPLETED}>{COMPLETED}</Forms.Option>
					</Forms.BasicSelect>
				)}
				{readWritePerms['assignment_type'] === WRITE && (
					<Forms.RadioList id="assignment_type" inline={true}>
						<Forms.Heading>Type</Forms.Heading>
						<Forms.Option value={INTERNAL}>Internal</Forms.Option>
						<Forms.Option value={EXTERNAL}>External</Forms.Option>
					</Forms.RadioList>
				)}
				{readWritePerms['assignment_type'] === READ && (
					<Forms.BasicSelect id="assignment_type" inline={true} disabled={true}>
						<Forms.Heading>Type</Forms.Heading>
						<Forms.Option value={INTERNAL}>Internal</Forms.Option>
						<Forms.Option value={EXTERNAL}>External</Forms.Option>
					</Forms.BasicSelect>
				)}
				{readWritePerms['name'] && (
					<Forms.TextArea
						id="name"
						rows={2}
						maxLength="100"
						disabled={readWritePerms['name'] !== WRITE}
					>
						<Forms.Heading>Name</Forms.Heading>
					</Forms.TextArea>
				)}
				{readWritePerms['comment'] && (
					<Forms.TextArea
						id="comment"
						rows={2}
						maxLength="500"
						disabled={readWritePerms['comment'] !== WRITE}
					>
						<Forms.Heading>Comments</Forms.Heading>
					</Forms.TextArea>
				)}
				{readWritePerms['assigned_ndid_company_id'] && (
					<Forms.BasicSelect
						id="assigned_ndid_company_id"
						disabled={readWritePerms['assigned_ndid_company_id'] !== WRITE}
					>
						<Forms.Heading>Publisher Assignee</Forms.Heading>
						<Forms.Option value={''}></Forms.Option>
						{formValues.assignment_type === EXTERNAL &&
							assignableCompanies &&
							assignableCompanies.map((company) => (
								<Forms.Option
									value={company.ndid_company_id}
									key={company.ndid_company_id}
								>
									{company.company_name}
								</Forms.Option>
							))}
						{pastPublisherAssignee && (
							<Forms.Help>Previous: {pastPublisherAssignee} (Owner)</Forms.Help>
						)}
					</Forms.BasicSelect>
				)}
				{readWritePerms['assigned_ndid_user_id'] && (
					<Forms.BasicSelect
						id="assigned_ndid_user_id"
						disabled={readWritePerms['assigned_ndid_user_id'] !== WRITE}
					>
						<Forms.Heading>
							{formValues.assignment_type === INTERNAL
								? 'Internal Assignee'
								: 'Assignee'}
						</Forms.Heading>
						<Forms.Option value={''}></Forms.Option>
						{assignableUsers &&
							assignableUsers.map((user) => (
								<Forms.Option value={user.ndid_user_id} key={user.ndid_user_id}>
									{user.user_name}
								</Forms.Option>
							))}
					</Forms.BasicSelect>
				)}
				{safeEval(() => currentAttachment.attachment_file_name) && (
					<FormGroup>
						<Row>
							<FormLabel className="col-sm-4 text-sm-right" column>
								Current Attachment
							</FormLabel>
							<Col sm={8} className="py-2">
								<EventTaskAttachmentLinkCell original={currentAttachment} />
							</Col>
						</Row>
					</FormGroup>
				)}
				{(showNewAttachment || showReplacementAttachment) && (
					<FormGroup>
						<Row>
							<FormLabel className="col-sm-4 text-sm-right" column>
								{showNewAttachment ? 'Attachment' : 'Replace Attachment'}
							</FormLabel>
							<Col sm={8} className="py-2">
								{show && <AsperaFileUpload
									updateFilesSelected={(isFileSelected) =>
										setFileSelected(isFileSelected)
									}
									userProfile={userProfile}
									entityType={fileTransferConst.ENTITY_TYPE.MARKETING_EVENT_TASK}
									entityId={attachmentTaskId}
									isUploadInitiated={isUploadInitiated}
									onCompleteFunction={() => {uploadPromiseResolve && uploadPromiseResolve(); setUploadPromiseResolve(null);}}
									getToken={() => getDATokenForMarketingEventTask(attachmentTaskId)}
									singleFileOnly
								/>}
							</Col>
						</Row>
					</FormGroup>
				)}
				{readWritePerms['due_date'] && (
					<Forms.DateSelect
						id="due_date"
						showTimeSelect={false}
						minDate={new Date()}
						disabled={readWritePerms['due_date'] !== WRITE}
					>
						<Forms.Heading>Due Date</Forms.Heading>
					</Forms.DateSelect>
				)}
			</Forms>
			{submitForm && <BaseModal.Submit onClick={() => onSubmit()}>Save</BaseModal.Submit>}
		</BaseModal>
	);
};

export default EventTaskModalView;
