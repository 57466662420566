import React, { useState, VFC } from 'react';
import { useQuery } from 'react-query';
import { Button } from 'react-bootstrap';

import BaseTable, { actionsColumn } from '../../../components/BaseTable/BaseTable';
import Page from '../../../components/Page/Page';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import Loading from '../../../components/Loading/Loading';
import ActiveCell from '../../../components/cells/ActiveCell/ActiveCell';
import StatusText from '../../../components/StatusText/StatusText';
import FAIcon from '../../../components/FAIcon/FAIcon';
import DeleteConfirmationModal
	from '../../../components/modals/DeleteConfirmationModal/DeleteConfirmationModal';
import { useUserProfile } from '../../../hooks/reduxHooks';
import { isAuthorized } from '../../../utils/userUtils';
import { permConst } from '../../../constants/permConst';
import {
	getCustomFieldDefinitions,
	deleteCustomField,
} from '../../../services/customFieldsService';
import CustomFieldModal from '../modals/CustomFieldModal';
import MeatballDropdown from '../../../components/MeatballDropdown/MeatballDropdown';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';

const ADD_MODAL = 'ADD_MODAL';
const DELETE_MODAL = 'DELETE_MODAL';

export interface CustomFieldSubmit extends Omit<CustomField, 'type' | 'roles'> {
	type: string;
	roles: string[];
}

interface OpenModal {
	type?: 'ADD_MODAL' | 'DELETE_MODAL' | null;
	customField?: CustomFieldSubmit;
}

interface CustomFieldProp {
	entityName: 'company' | 'product';
	tableTitle: string;
}

export const FIELD_TYPES = new Map<string, any>([
	['textbox', {
		'name': 'Textbox',
		'id': 'textbox', 'max_length': 100,
	}],
	['checkbox', {
		'name': 'Checkbox Yes/No',
		'id': 'checkbox',
	}],
	['single_select_dropdown', {
		'name': 'Publisher Single Select Dropdown',
		'id': 'single_select_dropdown', 'source': 'company',
	}],
	['multi_select_dropdown', {
		'name': 'Publisher Multiselect Dropdown',
		'id': 'multi_select_dropdown', 'source': 'company',
	}],
]);

function getTableStructure({ canEdit, setOpenModal }: {
	canEdit: boolean,
	setOpenModal: React.Dispatch<OpenModal>
}) {
	const StatusCell = ({ value }: { value?: boolean }) => {
		return (<StatusText>{value ? 'active' : 'inactive'}</StatusText>);
	};

	const RolesCell = ({ value }: { value?: Record<string, any>[] }) => {
		return (<ul
			className='mb-0'>
			{value?.map((role: Record<string, any>) => (<li key={role.id}>{role.name}</li>))}
		</ul>);
	};

	const ActionsCell = ({ original }: { original: CustomField }) => {
		return (
			<MeatballDropdown id='custom-field-meatball' toggleSize='lg'>
				{canEdit && (
					<MeatballDropdown.Item
						key={`editCustomField-${original.id}`}
						onClick={() =>
							setOpenModal({
								type: ADD_MODAL,
								customField: {
									id: original.id,
									name: original.name,
									active: original.active,
									read_only: original.read_only,
									type: original.type.id,
									roles: original.roles.map(
										(item: Record<string, any>) => String(item.id)),
								},
							})
						}>
						Edit Custom Field
					</MeatballDropdown.Item>
				)}
				{canEdit && (
					<MeatballDropdown.Item
						key={`deleteCustomField-${original.id}`}
						onClick={() =>
							setOpenModal({
								type: DELETE_MODAL,
								customField: {
									id: original.id,
									name: original.name,
									active: original.active,
									read_only: original.read_only,
									type: original.type.id,
									roles: original.roles.map(
										(item: Record<string, any>) => String(item.id)),
								},
							})
						}
					>
						<span className='text-danger'>Delete</span>
					</MeatballDropdown.Item>
				)}
			</MeatballDropdown>
		);
	};


	return [
		{ Header: 'Name', accessor: 'name' },
		{
			Header: 'Type',
			id: 'type',
			accessor: (custom_field: Record<string, any>) =>
				FIELD_TYPES.get(custom_field?.type.id)?.name,
		},
		{ Header: 'Read Only', accessor: 'read_only', Cell: ActiveCell },
		{ Header: 'Status', accessor: 'active', Cell: StatusCell, enableSorting: false },
		{ Header: 'Role(s)', accessor: 'roles', Cell: RolesCell },
		{
			...actionsColumn,
			Cell: ActionsCell,
		},
	];
}

const CustomFieldIndexTab: VFC<CustomFieldProp> = ({ entityName, tableTitle }) => {
	const userProfile = useUserProfile();
	const canEdit: boolean = isAuthorized(userProfile.permissions,
		[permConst.CUSTOM_FIELD_DEFINITION.EDIT.ALL]);
	const [openModal, setOpenModal] = useState<OpenModal>({});

	const customFieldQuery = useQuery(
		['getCustomFieldDefinitions', entityName],
		() => getCustomFieldDefinitions(entityName));
	const dataFormat = getTableStructure({ canEdit, setOpenModal });
	const customFieldData = customFieldQuery.data?.data || [];
	const isLoading = customFieldQuery.isLoading;
	return (
		<Page.ContentContainer>
			{canEdit &&
				<Button
					variant='primary'
					id='addCustomFieldDefinition'
					onClick={() =>
						setOpenModal({
							type: ADD_MODAL,
						})
					}>
					<FAIcon className='mr-1' name='plus' />
					Add Custom Field
				</Button>
			}
			<SectionTitle> {tableTitle} </SectionTitle>
			{isLoading ? (
				<Loading />
			) : (
				<BaseTable
					data={customFieldData}
					columns={dataFormat}
					stateOnTab={entityName}
					defaultSorted={[
						{
							id: 'name',
							desc: false,
						},
					]}
					retainPageState
				/>
			)}
			<CustomFieldModal
				show={openModal && openModal.type === ADD_MODAL}
				onClose={() => setOpenModal({ ...openModal, type: null })}
				onCompletion={() => customFieldQuery.refetch()}
				entityName={entityName}
				excludeUserId={userProfile.roles[0].role_id}
				customField={openModal?.customField}
			/>
			<DeleteConfirmationModal
				show={openModal && openModal.type === DELETE_MODAL}
				closeModal={() => setOpenModal({ ...openModal, type: null })}
				confirmDelete={async () => {
					if (openModal?.customField?.id) {
						try {
							await deleteCustomField(openModal?.customField?.id);
							toasterNotify(`Custom field "${openModal?.customField?.name}" was deleted`, 'success');
							customFieldQuery.refetch();
						} catch (error: any) {
							toasterNotify(createMessageForError(error,
									'deleting custom field'),
								'error', error);
						}
					}
					setOpenModal({...openModal, type: null});
				}}
				title='Delete Custom Field'
				message={`This action will delete "${openModal?.customField?.name}" permanently 
				from the NPT database. Are you sure you want to delete this field?`}
				deleteLabel={'Confirm'}
			/>
		</Page.ContentContainer>
	);
};

export default CustomFieldIndexTab;
