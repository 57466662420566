import React, { Component } from 'react';
import { Col, FormGroup, FormLabel, Row } from 'react-bootstrap';

class ConsigneeFields extends Component {
	renderCountryCodes() {
		const { countryCodes } = this.props;
		return countryCodes.map((countryCode) => {
			return (
				<option
					key={countryCode.country_code}
					value={countryCode.country_code}
				>
					{countryCode.country_name}
				</option>
			);
		});
	}

	render() {
		const {
			japaneseContactName,
			japaneseEmail,
			consigneeCompanyName,
			japanesePhoneNumber,
			consigneeAddress,
			consigneeCity,
			consigneeState,
			consigneeZip,
			countryCode,
			serviceLevel,
			carrierAccountNumber,
			formElementChanged,
			ableToEdit,
			validationFlag,
			touchField,
			columnSize=4
		} = this.props;

		const classPrefix = this.props.classPrefix || 'order-review';

		return (
			<>
				{[
					{
						name: 'japaneseContactName',
						label: 'Japanese Contact Name',
						value: japaneseContactName,
					},
					{ name: 'japaneseEmail', label: 'Japanese Email', value: japaneseEmail },
					{
						name: 'japanesePhoneNumber',
						label: 'Japanese Phone Number',
						value: japanesePhoneNumber,
					},
					{
						name: 'consigneeCompanyName',
						label: 'Consignee Company Name',
						value: consigneeCompanyName,
					},
					{
						name: 'consigneeAddress',
						label: 'Consignee Address',
						value: consigneeAddress,
					},
					{ name: 'consigneeCity', label: 'Consignee City', value: consigneeCity },
					{ name: 'consigneeState', label: 'Consignee State', value: consigneeState },
					{ name: 'consigneeZip', label: 'Consignee Zip', value: consigneeZip },
					{ name: 'countryCode', label: 'Country', value: countryCode },
					{ name: 'serviceLevel', label: 'Service Level', value: serviceLevel },
					{
						name: 'carrierAccountNumber',
						label: 'Carrier Account Number',
						value: carrierAccountNumber,
					},
				].map((v) =>
					v.name === 'countryCode' ? (
						<FormGroup as={Row} className={`${classPrefix}-dl`} key={v.name}>
							<FormLabel column sm={4} className="text-sm-right">
								Country
							</FormLabel>
							<Col sm={columnSize} className={`${classPrefix}-details-dd-offset`}>
								<select
									id={v.name}
									onChange={formElementChanged}
									className={`form-control ${classPrefix}-input`}
									defaultValue={v.value || ''}
									disabled={!ableToEdit}
								>
									{this.renderCountryCodes()}
								</select>
							</Col>
						</FormGroup>
					) : (
						<FormGroup as={Row} className={`${classPrefix}-dl`} key={v.name}>
							<FormLabel column sm={4} className="text-sm-right">
								{v.label}
							</FormLabel>
							<Col sm={columnSize} className={`${classPrefix}-details-dd-offset`}>
								<input
									id={v.name}
									onChange={formElementChanged}
									onBlur={touchField && ((e) => touchField(v.name))}
									className={`form-control ${classPrefix}-input`}
									value={v.value || ''}
									disabled={!ableToEdit}
								/>
								{validationFlag && validationFlag(v.name)}
							</Col>
						</FormGroup>
					),
				)}
			</>
		);
	}
}

export default ConsigneeFields;
