import { combineReducers } from 'redux';
import authReducer from './authReducer';
import featureFlagReducer from './featureFlagReducer';
import referenceReducer from './referenceReducer';
import uiReducer from './uiReducer';

const rootReducer = combineReducers({
	 authReducer,
	 featureFlagReducer,
	 referenceReducer,
	 uiReducer,
});

export default rootReducer;
