import React from 'react';
import { Col, Form, FormGroup, Row } from 'react-bootstrap';
import dayjs from 'dayjs';

import DatePicker from '../../../components/DatePicker/DatePicker';
import BaseModal from '../../../components/BaseModal/BaseModal';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import {
	postProjectionsPeriod,
	sendProjectionNotifications,
} from '../../../services/projectionsService';
import { dateFormat } from '../../../utils/dateUtils';


export default class OpenProjectionPeriodModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			projectionsStartDate: dayjs().format(dateFormat.DATE_YMD),
			projectionsEndDate: dayjs().format(dateFormat.DATE_YMD)
		};

		this.createProjectionsPeriod = this.createProjectionsPeriod.bind(this);
		this.toggleLoading = this.toggleLoading.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	async createProjectionsPeriod() {
		const { projectionsStartDate, projectionsEndDate } = this.state;
		this.toggleLoading();
		let windowId;
		const formattedEndDate = dayjs(projectionsEndDate).set('hour', 15);
		const payload = {
			start_date: dayjs(projectionsStartDate)
				.utc()
				.toISOString(),
			end_date: formattedEndDate.utc().toISOString()
		};
		await postProjectionsPeriod(payload)
			.then((response) => {
				windowId = response.data.order_projection_submission_period_id;
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'opening projection period'),
					'error',
					error,
				);
			});

		if (windowId) {
			await sendProjectionNotifications(windowId)
				.catch((error) => {
					toasterNotify(
						createMessageForError(error, 'sending notification for projection period changes'),
						'warning',
						error,
					);
				});
		}
		this.props.toggleParentLoading();
		this.props.pageInit();
		this.props.toggleModal();
	}

	buttonDisabled() {
		const {
			projectionsStartDate,
			projectionsEndDate,
			isLoading
		} = this.state;
		const validForm = dayjs(projectionsStartDate).isBefore(
			projectionsEndDate
		);
		return !(!isLoading && validForm);
	}

	updateStateDates(val, stateValue) {
		this.setState({
			[stateValue]: val
		});
	}

	renderModalBody() {
		const { projectionsStartDate, projectionsEndDate } = this.state;
		const today = new Date();
		return (
			<Form>
				<FormGroup as={Row}>
					<label className="col-sm-4 col-form-label text-sm-right">
						Start Date
					</label>
					<Col sm={6}>
						<DatePicker
							className="form-control"
							dropdownMode="select"
							minDate={today}
							value={projectionsStartDate}
							onChange={(val) =>
								this.updateStateDates(
									val,
									'projectionsStartDate'
								)
							}
						/>
					</Col>
				</FormGroup>

				<FormGroup as={Row}>
					<label className="col-sm-4 col-form-label text-sm-right">
						End Date
					</label>
					<Col
						sm={6}
						className="request-date"
					>
						<DatePicker
							dropdownMode="select"
							minDate={today}
							value={projectionsEndDate}
							onChange={(val) =>
								this.updateStateDates(
									val,
									'projectionsEndDate'
								)
							}
						/>
					</Col>
				</FormGroup>
			</Form>
		);
	}

	render() {
		const { isLoading } = this.state;
		return (
			<BaseModal show={true} onCancel={this.props.toggleModal} isSubmiting={isLoading}>
				<BaseModal.Title>Open Projection Period</BaseModal.Title>
				{this.renderModalBody()}
				<BaseModal.Submit
					onClick={this.createProjectionsPeriod}
					disabled={this.buttonDisabled()}
				>Update</BaseModal.Submit>
			</BaseModal>
		);
	}
}
