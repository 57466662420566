import api from './baseService';

export const getLatestProjectionWindows = () =>
	api().get('/projections/windows/last');

export const getProjections = (companyId: string, submissionWindowId: string) =>
	api().get(
		`/projections/?ndid_company_id=${companyId}&submission_window_id=${submissionWindowId}`,
	);

export const getProjectionsByProductId = (productId: string, ndidCompanyId: string) =>
	api().get(`/projections/product/${productId}?ndid_company_id=${ndidCompanyId}`);

export const putProjections = (projections: Record<string, any>, ndidCompanyId: string) =>
	api().put(`/projections/?ndid_company_id=${ndidCompanyId}`, projections);

export const getProjectionsList = () =>
	api().get('/projections/report');


export const getProjectionReminderLog = (begin_date: string, end_date: string) => {
	const begin = encodeURIComponent(begin_date);
	const end = encodeURIComponent(end_date);
	return api().get(`/notifications/projection_reminder?begin=${begin}&end=${end}`);
};

export const postProjectionsPeriod = (projectionsDates: Record<string, any>) =>
	api().post('/projections/period', projectionsDates);


export const editProjectionsPeriod = (projectionsDates: Record<string, any>, periodId: string) =>
	api().post(`/projections/period/${periodId}`, projectionsDates);

export const getProjectableProducts = (companyId: string) =>
	api().get(`/pdb/products?projectable=True&ndid_company_id=${companyId}`);


export const sendProjectionNotifications = (windowId: string) => {
	return api().post(`/projections/notifications/${windowId}`);
};

export const sendProjectionsReminder = (payload: Record<string, any>) =>
	api().post('/projections/report/reminder', payload);
