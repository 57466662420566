import React, { useMemo, useState, VFC } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { CellInfo } from 'react-table-v6';

import { useUserProfile } from '../../hooks/reduxHooks';
import { actionsColumn } from '../../components/BaseTable/BaseTable';
import FilterableTable from '../../components/FilterableTable/FilterableTable';
import FAIcon from '../../components/FAIcon/FAIcon';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import { displayString } from '../../utils/stringUtils';
import { isAuthorized } from '../../utils/userUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { permConst } from '../../constants/permConst';
import { ncl_company_ndid } from '../../constants/generalConstants';
import { activateUser, deactivateUser, getRoles, getUsers, UserModel } from '../../services/usersService';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import ActionConfirmationModal from '../../components/modals/ActionConfirmationModal/ActionConfirmationModal';
import EditUserRolesModal from './modals/EditUserRolesModal';
import AddUserModal from './modals/AddUserModal';
import * as generalConstants from '../../constants/generalConstants';

type UserManagementSubcomponents = {
	NCL: typeof NCL;
};

interface OpenModal {
	type?: string | null;
	dropDownStatus?: string;
	userManagementData?: UserModel;
	isSubmitting?: boolean;
}

function getTableStructure({ userProfile, setOpenModal }: {userProfile: UserProfile, setOpenModal: React.Dispatch<OpenModal>}) {
	const getActivateButton = (userProfile: UserProfile,
							   original: UserModel,
							   colorClass: string | undefined,
							   dropDownStatus: string,
							   modalType: string,
							   setOpenModal: React.Dispatch<OpenModal>) => {
		let userSelf = userProfile.userId === original.ndid_user_id;

		let editInternalOnly = isAuthorized(userProfile.permissions, [
			permConst.USER_MANAGEMENT.EDIT.INTERNAL,
		]);

		let editAllStatus = isAuthorized(userProfile.permissions, [
			permConst.USER_MANAGEMENT.STATUS.EDIT.ALL,
		]);

		let rowInternal = original.ndid_company_id === generalConstants.nintendo_company_ndid;

		if (userSelf === false) {
			if (!editAllStatus && editInternalOnly && !rowInternal) {
				return;
			}
			return (
				<MeatballDropdown.Item
					id="userActivation"
					onClick={() => {
						setOpenModal({
							type: modalType,
							dropDownStatus: dropDownStatus,
							userManagementData: original,
						});
					}}
					className={colorClass}
				>
					{dropDownStatus}
				</MeatballDropdown.Item>
			);
		}
	};

	let tableConfiguration = [
		{ Header: 'Email', accessor: 'email_address' },
		{ Header: 'Name', accessor: 'user_name' },
		{ Header: 'NDID', accessor: 'login_id' },
		{
			Header: 'Roles',
			accessor: 'roles',
			Cell: (cell: { value: string }) => (
				<ul className="my-0">
					{cell.value &&
					cell.value
						.split(',')
						.sort()
						.map((value, index) => <li key={index}>{value}</li>)}
				</ul>
			),
		},
		{
			Header: 'Status',
			accessor: 'user_status_code',
			width: 90,
			Cell: (cell: { value: string }) => (
				<strong className={/^active/i.test(cell.value) ? 'text-success' : 'text-danger'}>
					{displayString(cell.value).toUpperCase()}
				</strong>
			),
		},
		{
			...actionsColumn,
			show: isAuthorized(userProfile.permissions, [
				permConst.USER_MANAGEMENT.STATUS.EDIT.ALL,
				permConst.USER_MANAGEMENT.EDIT.COMPANY,
				permConst.USER_MANAGEMENT.EDIT.INTERNAL,
			]),
			Cell: ({original}: CellInfo) => {
				let dropDownStatus = 'Activate User';
				let modalType = 'ACTIVATE_USER_MODAL';
				let colorClass;
				if (original.user_status_code.toUpperCase() === 'ACTIVE') {
					dropDownStatus = 'Inactivate User';
					modalType = 'INACTIVATE_USER_MODAL';
					colorClass = 'redItem';
				}

				const inCompany = userProfile.companyId === original.ndid_company_id;

				const displayActivateButton = getActivateButton(
					userProfile,
					original,
					colorClass,
					dropDownStatus,
					modalType,
					setOpenModal
				);

				return (
					<MeatballDropdown id={'context-menu-' + original.login_id}>
						{inCompany && (
							<MeatballDropdown.Item
								id="editRoles"
								eventKey="1"
								onClick={() => {
									setOpenModal({
										type: 'EDIT_USER_ROLES_MODAL',
										userManagementData: original,
									});
								}}
							>
								Edit Role(s)
							</MeatballDropdown.Item>
						)}
						{inCompany && displayActivateButton && <MeatballDropdown.Divider/>}
						{displayActivateButton}
					</MeatballDropdown>
				);
			}
		}
	];

	if (isAuthorized(userProfile.permissions, [permConst.USER_MANAGEMENT.VIEW.ALL])) {
		tableConfiguration.splice(2, 0, {
			Header: 'Company',
			accessor: 'company_name',
		});
	}
	return tableConfiguration;
}

interface UserManagementProps {
	nclManagement?: boolean;
}

const UserManagement: VFC<UserManagementProps> & UserManagementSubcomponents = ({ nclManagement = false}) => {
	const userProfile = useUserProfile();
	const [openModal, setOpenModal] = useState<OpenModal>({});
	const dataFormat = getTableStructure({ userProfile, setOpenModal });

	const rolesQuery = useQuery('getRoles()', () => getRoles());
	const roles = rolesQuery.data?.data ? [...rolesQuery.data.data] : [];
	roles && roles.sort((a: any,b: any) => (a > b) ? 1 : ((b > a) ? -1 : 0));

	const filterProperties = useMemo(() => {
		const filterProperties = new Map();
		if (!nclManagement) {
			filterProperties.set('Role', {
				filter: 'roles',
				selectableFilters: new Set(roles),
			});
		}
		filterProperties.set('Status', {
			filter: 'user_status_code',
			selectableFilters: new Set([
				displayString('ACTIVE'),
				displayString('INACTIVE'),
			]),
		});
		return filterProperties;
	}, [roles]);

	const nclUsersQuery = useQuery(['getUsers', {'company_id': ncl_company_ndid}], () => getUsers({'company_id': ncl_company_ndid}),
		{enabled: nclManagement});
	const usersQuery = useQuery('getUsers', () => getUsers(), {enabled: !nclManagement});
	const users = nclManagement ? nclUsersQuery.data?.data : usersQuery.data?.data;

	const isLoading = rolesQuery.isLoading || nclUsersQuery.isLoading || usersQuery.isLoading;
	const fault = (rolesQuery.error) || (nclUsersQuery.error) || (usersQuery.error);

	const onClose = () => {
		setOpenModal({});
	};

	const onComplete = () => {
		setOpenModal({});
		rolesQuery.refetch();
		if (nclManagement) {
			nclUsersQuery.refetch();
		} else {
			usersQuery.refetch();
		}
	};

	const pageTitle = nclManagement ? 'NCL User Management' : 'User Management';

	const confirmationBody = () => {
		return (<>{openModal && openModal.type === 'INACTIVATE_USER_MODAL' && (
				<Alert variant="danger">
					Once inactivated, the user below will no longer be able to log in to Nintendo Publisher Tool.
					They will still have access to other OMAS applications. Please confirm that you would like to
					inactivate this user.
				</Alert>)}
				<table className="ActivityModal__table">
					<tr>
						<td>NDID</td>
						<td>{openModal.userManagementData?.login_id}</td>
					</tr>
					<tr>
						<td>Name</td>
						<td>{openModal.userManagementData?.user_name}</td>
					</tr>
					<tr>
						<td>Email</td>
						<td>{openModal.userManagementData?.email_address}</td>
					</tr>
				</table></>
		);
	};

	return (
		<Page isLoading={isLoading} fault={fault}>
			<div className="usermanagement">
				<Title title={pageTitle}
					   button={(
						   <div>
							   {isAuthorized(userProfile.permissions, [
								   permConst.USER_MANAGEMENT.ADD.ALL,
								   permConst.USER_MANAGEMENT.ADD.COMPANY,
								   permConst.USER_MANAGEMENT.ADD.INTERNAL,
							   ]) && (
								   <Button variant="primary"
										   id="addUser"
										   onClick={() => {
											   setOpenModal({
												   type: 'ADD_USER_MODAL',
											   });
										   }}
								   >
									   <FAIcon name="plus" className="mr-1"/> Add User
								   </Button>
							   )}
						   </div>
					   )}/>
				<FilterableTable
					className="userManagement"
					data={users}
					dataFormat={dataFormat}
					filterProperties={filterProperties}
					searchableFields={isAuthorized(userProfile.permissions, [permConst.USER_MANAGEMENT.VIEW.ALL]) ?
						['login_id', 'user_name', 'company_name'] :
						['login_id', 'user_name']}
					searchableFieldPlaceHolder={isAuthorized(userProfile.permissions, [permConst.USER_MANAGEMENT.VIEW.ALL]) ?
						'Search by Name, NDID or Company...' :
						'Search by Name or NDID'}
					defaultSorted={[
						{
							id: 'user_name',
							desc: false,
						},
					]}
					retainPageState
				/>
			</div>
			<EditUserRolesModal
				show={openModal && openModal.type === 'EDIT_USER_ROLES_MODAL'}
				onCompletion={onComplete}
				onClose={onClose}
				userManagementData={openModal.userManagementData}
			/>
			<AddUserModal
				show={openModal && openModal.type === 'ADD_USER_MODAL'}
				onCompletion={onComplete}
				onClose={onClose}
				nclManagement={nclManagement}
			/>
			<ActionConfirmationModal
				show={openModal && (openModal.type === 'ACTIVATE_USER_MODAL' ||	openModal.type === 'INACTIVATE_USER_MODAL')}
				isSubmitting={openModal?.isSubmitting}
				onCancel={() => setOpenModal({})}
				onConfirm={async () => {
					const displayStatus = openModal.userManagementData?.user_status_code === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
					setOpenModal({...openModal, isSubmitting: true} as OpenModal);
					try {
						const userManagementData = openModal.userManagementData;
						if (openModal && openModal.type === 'ACTIVATE_USER_MODAL') {
							await activateUser(userManagementData?.ndid_user_id!);
						} else {
							await deactivateUser(userManagementData?.ndid_user_id!);
						}
						toasterNotify(
							`Updated ${userManagementData?.user_name} status to ${displayStatus}`,
							'success'
						);
					} catch (error: unknown) {
						error instanceof Error &&
						toasterNotify(
							createMessageForError(
								error,
								`${displayStatus} the user`,
							),
							'error',
							error,
						);
					} finally {
						setOpenModal({});
						rolesQuery.refetch();
						if (nclManagement) {
							nclUsersQuery.refetch();
						} else {
							usersQuery.refetch();
						}
					}
				}}
				title={openModal.dropDownStatus}
				confirmLabel={openModal.dropDownStatus}
				cancelLabel="Close"
			>
				{confirmationBody()}
			</ActionConfirmationModal>
		</Page>
	);
};

const NCL = () => <UserManagement nclManagement={true} />;
UserManagement.NCL = NCL;

export default UserManagement;
