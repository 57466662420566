import { useQuery } from 'react-query';

import {
	getDigitalCodePurposes,
	getProductsAndComponents,
} from '../../services/digitalCodesService';


export const usePurposeQuery = () =>
	useQuery('getDigitalCodePurposes', () => getDigitalCodePurposes(), { staleTime: 60000 });

export const useProductQuery = (purposeId) => useQuery(
	['getOrderComponents', purposeId],
	() => getProductsAndComponents(purposeId),
    { enabled: !!purposeId }
);
