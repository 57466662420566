import { isAuthorized } from '../utils/userUtils';
import { PermissionNode, permConst } from './permConst';

interface RouteCriteria {
	permissions: PermissionNode[];
	featureFlag?: string;
	disable?: (userProfile: UserProfile) => boolean;
}
export const routePermissions: Map<string, RouteCriteria> = new Map([
	['/admin/announcements', {
		permissions: [
			permConst.ANNOUNCEMENT.VIEW.ALL,
		]
	}],
	['/admin/users', {
		permissions: [
			permConst.USER_MANAGEMENT.VIEW.COMPANY,
			permConst.USER_MANAGEMENT.VIEW.ALL,
		]
	}],
	['/admin/ncl-users', {
		permissions: [
			permConst.NCL.USER_MANAGEMENT.ADD.ALL,
		]
	}],
	['/admin/publishers', {
		permissions: [
			permConst.PUBLISHER_MANAGEMENT.VIEW.ALL,
		]
	}],
	['/admin/publishers/:companyId', {
		permissions: [
			permConst.COMPANY.VIEW.ALL,
			permConst.COMPANY.VIEW.COMPANY,
		]
	}],
	['/admin/family-setup', {
		permissions: [
			permConst.PLATFORM.VIEW.ALL,
		]
	}],
	['/admin/custom-fields-management', {
		permissions: [
			permConst.CUSTOM_FIELD_DEFINITION.VIEW.ALL
		]
	}],
	['/admin/feature-management', {
		permissions: [
			permConst.FEATURE_FLAG.VIEW.ALL,
		]}],
	['/admin/help-topic-management', {
		permissions: [
			permConst.HELP.EDIT.ALL,
		]}],
	['/admin/ncl-rom-versions', {
		permissions: [
			permConst.ROM_VERSION_FILE.VIEW.ALL,
		]}],
	['/products/assets', {
		permissions: [
			permConst.PRODUCT.ASSET.MARKETING.VIEW.ALL,
			permConst.PRODUCT.ASSET.PACKAGING.VIEW.ALL,
			permConst.PRODUCT.ASSET.LABEL.REVIEW.ALL,
			permConst.PRODUCT.ASSET.MARKETING.APPROVE.ALL,
			permConst.PRODUCT.ASSET.PACKAGING.APPROVE.ALL,
			permConst.MARKETING_EVENT.PRODUCT.ASSET.REVIEW.MANAGER,
			permConst.MARKETING_EVENT.PRODUCT.ASSET.REVIEW.COORDINATOR,
			permConst.PRODUCT.ASSET.CODEINBOX.VIEW.ALL,
			permConst.PRODUCT.ASSET.CODEINBOX.APPROVE.ALL,
			permConst.PRODUCT.COMPONENT.WHOLESALE_PRICE.REQUEST.APPROVE.ALL,
			permConst.PRODUCT.COMPONENT.WHOLESALE_PRICE.REQUEST.REVIEW.ALL,
		]
	}],
	['/admin/components-without-products', {
		permissions: [
			permConst.PRODUCT.COMPONENT.PARENT.EDIT.ALL,
		]}],
	['/admin/product-distribution-transfer-requests', {
		permissions: [
			permConst.PRODUCT.RIGHT_TRANSFER.VIEW.ALL_DISTRIBUTION,
			permConst.PRODUCT.RIGHT_TRANSFER.VIEW.COMPANY,
		]
	}],
	['/admin/product-distribution-transfer-requests/:id', {
		permissions: [
			permConst.PRODUCT.RIGHT_TRANSFER.VIEW.ALL_DISTRIBUTION,
			permConst.PRODUCT.RIGHT_TRANSFER.VIEW.COMPANY,
		]
	}],
	['/admin/product-ownership-transfer-requests', {
		permissions: [
			permConst.PRODUCT.RIGHT_TRANSFER.VIEW.ALL_OWNERSHIP,
			permConst.PRODUCT.RIGHT_TRANSFER.VIEW.COMPANY,
		]
	}],
	['/admin/product-ownership-transfer-requests/:id', {
		permissions: [
			permConst.PRODUCT.RIGHT_TRANSFER.VIEW.ALL_OWNERSHIP,
			permConst.PRODUCT.RIGHT_TRANSFER.VIEW.COMPANY,
		]
	}],
	['/ncl', {
		permissions: [
			permConst.NCL.LABEL_QUEUE.EDIT,
			permConst.NCL.LABEL_QUEUE.VIEW,
		]
	}],
	['/products', {
		permissions: [
			permConst.NCL.LABEL_QUEUE.EDIT,
			permConst.PRODUCT.VIEW.ALL.CONFIDENTIAL,
			permConst.PRODUCT.VIEW.COMPANY,
			permConst.PRODUCT.VIEW.ALL.FIRST,
			permConst.PRODUCT.VIEW.ALL.NCL,
			permConst.PRODUCT.VIEW.ALL.THIRD,
		]
	}],
	['/products/:id', {
		permissions: [
			permConst.NCL.LABEL_QUEUE.EDIT,
			permConst.PRODUCT.VIEW.ALL.CONFIDENTIAL,
			permConst.PRODUCT.VIEW.ALL.FIRST,
			permConst.PRODUCT.VIEW.ALL.NCL,
			permConst.PRODUCT.VIEW.ALL.THIRD,
			permConst.PRODUCT.VIEW.COMPANY,
		]
	}],
	['/products/:productId/assets/:assetId', {
		permissions: [
			permConst.PRODUCT.ASSET.PACKAGING.VIEW.ALL,
			permConst.PRODUCT.ASSET.MARKETING.VIEW.ALL,
			permConst.PRODUCT.ASSET.LABEL.REVIEW.ALL,
			permConst.PRODUCT.ASSET.PACKAGING.VIEW.COMPANY,
			permConst.PRODUCT.ASSET.MARKETING.VIEW.COMPANY,
			permConst.PRODUCT.ASSET.CODEINBOX.VIEW.ALL,
			permConst.PRODUCT.ASSET.CODEINBOX.VIEW.COMPANY,
		]
	}],
	['/products/components/:nsUid', {
		permissions: [
			permConst.PRODUCT.COMPONENT.VIEW.ALL,
			permConst.PRODUCT.COMPONENT.VIEW.COMPANY,
		]
	}],
	['/submissions', {
		permissions: [
			permConst.SUBMISSION.LOTCHECK_QUEUE.VIEW.ALL,
		]
	}],
	['/submission-details/:productId/:submissionId', {
		permissions: [
			permConst.PRODUCT.VIEW.ALL.FIRST,
			permConst.PRODUCT.VIEW.ALL.THIRD,
			permConst.PRODUCT.VIEW.COMPANY,
		]
	}],
	['/orders', {
		permissions: [
			permConst.ORDER.VIEW.ALL,
			permConst.ORDER.VIEW.COMPANY,
		],
		disable: (userProfile: UserProfile) => isAuthorized(userProfile.permissions, [
			permConst.PHYSICAL_ORDER.VIEW.ALL,
			permConst.PHYSICAL_ORDER.VIEW.COMPANY,
		]),
	}],
	['/orders/associate-freight-forwarders', {
		permissions: [
			permConst.FREIGHT_FORWARDER.VIEW.COMPANY,
		]
	}],
	['/orders/create', {
		permissions: [
			permConst.ORDER.CREATE.ALL,
			permConst.ORDER.CREATE.COMPANY,
		],
		disable: (userProfile: UserProfile) => isAuthorized(userProfile.permissions, [
			permConst.PHYSICAL_ORDER.EDIT.COMPANY,
		]),
	}],
	['/orders/:orderHeaderId/create', {
		permissions: [
			permConst.ORDER.CREATE.ALL,
			permConst.ORDER.CREATE.COMPANY,
		]
	}],
	['/orders/consignees', {
		permissions: [
			permConst.CONSIGNEE.VIEW.COMPANY,
		],
	}],
	['/orders/digital-codes', {
		permissions: [
			permConst.DIGITAL_ORDER.VIEW.ALL,
			permConst.DIGITAL_ORDER.VIEW.COMPANY,
		]
	}],
	['/orders/digital-codes/create', {
		permissions: [
			permConst.DIGITAL_ORDER.SUBMIT.COMPANY,
		]
	}],
	['/orders/projections/management', {
		permissions: [
			permConst.PROJECTION.VIEW.ALL,
			permConst.ORDER.VIEW.ALL,
		]
	}],
	['/orders/manage-freight-forwarders', {
		permissions: [
			permConst.FREIGHT_FORWARDER.VIEW.ALL,
		]
	}],
	['/orders/manage-packout-facilities', {
		permissions: [
			permConst.PACKOUT_FACILITY.VIEW.ALL,
			permConst.PACKOUT_FACILITY.VIEW.COMPANY,
		]
	}],
	['/orders/digital-codes/:orderHeaderId/create', {
		permissions: [
			permConst.DIGITAL_ORDER.SUBMIT.COMPANY,
		]
	}],
	['/orders/digital-codes/:orderId', {
		permissions: [
			permConst.DIGITAL_ORDER.VIEW.ALL,
			permConst.DIGITAL_ORDER.VIEW.COMPANY,
		]
	}],
	['/orders/physical', {
		permissions: [
			permConst.PHYSICAL_ORDER.VIEW.ALL,
			permConst.PHYSICAL_ORDER.VIEW.COMPANY,
		],
	}],
	['/orders/physical/:orderId', {
		permissions: [
			permConst.PHYSICAL_ORDER.VIEW.ALL,
			permConst.PHYSICAL_ORDER.VIEW.COMPANY,
		],
	}],
	['/orders/physical/:orderId/create', {
		permissions: [
			permConst.PHYSICAL_ORDER.EDIT.COMPANY,
		],
	}],
	['/orders/physical/create"', {
		permissions: [
			permConst.PHYSICAL_ORDER.EDIT.COMPANY,
		],
	}],
	['/orders/projections', {
		permissions: [
			permConst.PROJECTION.VIEW.ALL,
			permConst.PROJECTION.VIEW.COMPANY,
		]
	}],
	['/orders/projections/:id', {
		permissions: [
			permConst.PROJECTION.VIEW.ALL,
			permConst.PROJECTION.VIEW.COMPANY,
		]
	}],
	['/orders/review/:orderHeaderId', {
		permissions: [
			permConst.ORDER.VIEW.ALL,
			permConst.ORDER.VIEW.COMPANY,
			permConst.DIGITAL_ORDER.VIEW.ALL,
			permConst.DIGITAL_ORDER.VIEW.COMPANY,
		]
	}],
	['/marketing-events', {
		permissions: [
			permConst.MARKETING_EVENT.VIEW,
		],
		featureFlag: 'me-dev'
	}],
	['/marketing-events/reports', {
		permissions: [
			permConst.MARKETING_EVENT.REPORT.VIEW,
		],
		featureFlag: 'me-dev'
	}],
	['/marketing-events/:eventId', {
		permissions: [
			permConst.MARKETING_EVENT.DETAILS.VIEW,
		],
		featureFlag: 'me-dev'
	}],
	['/marketing-events/products/:productId', {
		permissions: [
			permConst.MARKETING_EVENT.PRODUCT.VIEW,
		],
		featureFlag: 'me-dev'
	}],
	['/marketing-events/products/:eventProductId/assets/:eventAssetId', {
		permissions: [
			permConst.MARKETING_EVENT.PRODUCT.ASSET.VIEW,
		],
		featureFlag: 'me-dev'
	}],
]);
