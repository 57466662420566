import React from 'react';

import BaseModal from '../../BaseModal/BaseModal';


const ActionConfirmationModal = ({
	show,
	isSubmitting,
	onCancel,
	onConfirm,
	title,
	confirmLabel,
	cancelLabel = 'Cancel',
	children,
}) => {
	return (
		<BaseModal show={show} isSubmitting={isSubmitting} onCancel={onCancel}>
			<BaseModal.Title>{title}</BaseModal.Title>
			{children}
			<BaseModal.Submit variant='primary' onClick={onConfirm}>
				{confirmLabel}
			</BaseModal.Submit>
			<BaseModal.Cancel onClick={() => onCancel()}>
                {cancelLabel}
            </BaseModal.Cancel>
		</BaseModal>
	);
};
export default ActionConfirmationModal;
