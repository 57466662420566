import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { actionsColumn } from '../../components/BaseTable/BaseTable';
import FilterableTable from '../../components/FilterableTable/FilterableTable';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import FeaturePermissionsModal from '../../components/modals/FeaturePermissionsModal/FeaturePermissionsModal';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import { permConst } from '../../constants/permConst';
import { useUserProfile } from '../../hooks/reduxHooks';
import { getAllCompanies } from '../../services/companiesService';
import { getFeatureFlags, getFeatureStatuses } from '../../services/featuresService';
import { displayString } from '../../utils/stringUtils';
import { isAuthorized } from '../../utils/userUtils';


const EDIT_MODAL = 'EDIT_MODAL';

const FeatureManagement = () => {
	const userProfile = useUserProfile();
	const canEditFeaturePermission = isAuthorized(userProfile.permissions, [
		permConst.FEATURE_FLAG.EDIT.ALL,
	]);

	const featuresQuery = useQuery('getFeatureFlags', () => getFeatureFlags());

	const companiesQuery = useQuery(
		'getAllCompanies:company_name',
		() => getAllCompanies({ field: 'company_name' }),
		{ enabled: canEditFeaturePermission },
	);
	const companies = companiesQuery.data?.data;

	const featuresStatusesQuery = useQuery('getFeatureStatuses', () => getFeatureStatuses());

	const [openModal, setOpenModal] = useState({});

	const getCompanyIds = (names = []) => {
		if (!companies) return [];
		return companies.filter((company) => names.includes(company.company_name)).map((company => company.ndid_company_id));
	};

	const NameCell = ({ value, original }) => 
		<><div>{value}</div><div><small>{original.description}</small></div></>;

	const StatusCell = ({ value }) => {
		const className =
			value.toLowerCase() === 'active'
				? 'text-success'
				: value.toLowerCase() === 'inactive'
					? 'text-danger'
					: '';
		return <b className={className}>{displayString(value).toUpperCase()}</b>;
	};

	const ActionCell = ({ original }) => {
		return (
			canEditFeaturePermission && (
				<MeatballDropdown id='feature-permission-meatball'>
					<MeatballDropdown.Item
						key={`edit-${original.id}`}
						eventKey={original.id}
						onClick={() =>
							setOpenModal({
								type: 'EDIT_MODAL',
								featurePermission: {
									...original,
									ndid_company_ids: getCompanyIds(
										original.ndid_company_names || [],
									),
								},
							})
						}
					>
						Edit Feature
					</MeatballDropdown.Item>
				</MeatballDropdown>
			)
		);
	};

	const dataFormat = [
		{ Header: 'Feature', accessor: 'name', Cell: NameCell },
		{ Header: 'Status', accessor: 'status', Cell: StatusCell, width: 120 },
		{
			Header: 'Companies',
			accessor: 'ndid_company_names', 
			Cell: ({ value }) => (
				<ul>
					{value.map((name) => (
						<li key={name}>{name}</li>
					))}
				</ul>
			),
		},
		{
			...actionsColumn,
			Cell: ActionCell,
		},
	];

	const featuresStatusSet = featuresStatusesQuery.isSuccess
		? new Set(Object.values(featuresStatusesQuery.data.data))
		: null;

	// Filter Properties only filters for status in P1, will need to add publishers in P2
	const filterProperties = new Map();
	filterProperties.set('Status', {
		filter: 'status',
		selectableFilters: featuresStatusSet || null,
	});

	const features = featuresQuery.data?.data || [];

	const fault =
		(featuresQuery.isError && featuresQuery.error) ||
		(featuresStatusesQuery.isError && featuresStatusesQuery.error) ||
		null;

	return (
		<Page fault={fault} isLoading={featuresQuery.isLoading}>
			<Title title='Feature Management' />
				<FilterableTable
					data={features}
					dataFormat={dataFormat}
					filterProperties={filterProperties}
					searchableFieldPlaceHolder={'Search by Feature'}
					searchableFields={['name']}
					defaultSorted={[
						{
							id: 'name',
							desc: false,
						},
					]}
					retainPageState
				/>
				<FeaturePermissionsModal
					onClose={() => setOpenModal({ ...openModal, type: null })}
					onChange={() => {
						featuresQuery.refetch();
						setOpenModal({ ...openModal, type: null });
					}}
					statuses={featuresStatusSet}
					featurePermission={openModal.featurePermission || null}
					show={!!openModal && EDIT_MODAL === openModal.type}
					editPermission={canEditFeaturePermission}
					companies={companies}
				/>
		</Page>
	);
};

export default FeatureManagement;
