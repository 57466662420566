import React from 'react';

import Panel from '../../../components/Panel/Panel';

import './ProductDetailsPanel.css';


const ProductDetailsPanel = ({product,}) => {
	const {
		active,
	    game_code,
	    platform_name,
	    support_level,
	    companyNames,
	    coordinatorNames
	} = product || {};

	return (<Panel>
        <Panel.Heading>
            <Panel.Title>Product Details</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
	        <dl className="ProductDetailsPanel__panel-list">
                { (active !== undefined) && <>
                    <dt>Active</dt>
                    <dd>{active ? <strong className="text-success">ACTIVE</strong> : <strong className="text-muted">INACTIVE</strong>}</dd>
                </>}

                <dt>Game Code</dt>
                <dd>{ game_code }</dd>

                { companyNames && (<>
                    <dt>Publisher(s)</dt>
                    <dd>{ companyNames.join(', ') }</dd>
                </>) }

                <dt>Platform</dt>
                <dd>{ platform_name }</dd>

                { support_level && (<>
                    <dt>Support Level</dt>
                    <dd>{ support_level }</dd>
                </>) }

                {Array.isArray(coordinatorNames) && <>
                    <dt>Team Member Access</dt>
                    <dd>
                        <ul>
                            { coordinatorNames.map((item, index) => <li key={`${index}`}>{item}</li>) }
                        </ul>
                    </dd>
                </>}

            </dl>
        </Panel.Body>
    </Panel>);
};

export default ProductDetailsPanel;
