import React, { VFC, useEffect, useState } from 'react';
import { trim } from 'lodash';
import { Alert } from 'react-bootstrap';
import * as yup from 'yup';

import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import { deletePartNum, setPartNum } from '../../../services/productsService';
import { isEmptyObject } from '../../../utils/dataUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { validateToSchema } from '../../../utils/validationUtils';

export const schema = yup.object().shape({
	part_number: yup.string()
		.optional()
		.nullable()
		.test(
			'whitespace_check',
			'To delete the Part/SKU Number, submit a blank value without spaces',
			(value) => !/^\s+$/.test(value || '')
		),
});

interface EditPartSkuNumModalProps {
	show: boolean;
	productId: number;
	partNum?: string;
	updatePartNumber: (payload: { part_number: string | null; }) => void;
	closeModal: () => void;
}
const EditPartSkuNumModal: VFC<EditPartSkuNumModalProps> = ({
	show,
	productId,
	partNum,
	updatePartNumber,
	closeModal,
}) => {
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [formValues, setFormValues] = useState<{ part_number?: string | null; }>({});
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);

	useEffect(() => {
		if (show) {
			setFormValues({ part_number: partNum });
			setShowAllErrors(false);
		}
	}, [show]);

	const sendPartNum = () => {
		setShowAllErrors(true);
		if (!isEmptyObject(validationErrors)) {
			return;
		}
		if (trim(formValues.part_number || '') === partNum) {
			closeModal();
			toasterNotify('No changes were made to Part/SKU Number', 'info');
			return;
		}
		const payload = {
			part_number: (formValues.part_number && trim(formValues.part_number)) || null,
		};
		setIsSubmitting(true);
		if (!payload.part_number) {
			deletePartNum(String(productId))
				.then((response) => {
					updatePartNumber(payload);
					toasterNotify('Deleted Part/SKU number', 'success');
					closeModal();
				})
				.catch((error) => {
					toasterNotify(
						createMessageForError(error, 'deleting Part/SKU Number'),
						'error',
						error,
					);
				})
				.finally(() => setIsSubmitting(false));
		} else {
			setPartNum(String(productId), payload)
				.then((response) => {
					updatePartNumber(payload);
					toasterNotify('Updated Part/SKU number', 'success');
					closeModal();
				})
				.catch((error) => {
					toasterNotify(
						createMessageForError(error, 'updating Part/SKU Number'),
						'error',
						error,
					);
				})
				.finally(() => setIsSubmitting(false));
		}
	};

	const validationErrors = validateToSchema(schema, formValues);

	return (
		<BaseModal show={show} onCancel={closeModal} isSubmitting={isSubmitting}>
			<BaseModal.Title>Edit Part/SKU Number</BaseModal.Title>
			<Alert variant="info">Enter the Part/SKU Number below.</Alert>
			<Forms
				values={formValues}
				onChange={(newFormValues) => setFormValues(newFormValues)}
				validationErrors={validationErrors}
				showAllErrors={showAllErrors}
			>
				<Forms.Text id="part_number">
					<Forms.Heading>Part/SKU Number</Forms.Heading>
				</Forms.Text>
			</Forms>
			<BaseModal.Submit onClick={sendPartNum}>Save</BaseModal.Submit>
		</BaseModal>
	);
};
export default EditPartSkuNumModal;
