import React, { Component } from 'react';
import { Alert, Table } from 'react-bootstrap';

import BaseModal from '../../../components/BaseModal/BaseModal';
import PropertyDisplay from '../../../components/PropertyDisplay/PropertyDisplay';
import { getOrderDetailShipmentInfo } from '../../../services/ordersService';
import { numberWithCommas } from '../../../utils/currencyUtils';
import { dateFormat, formatDate, parseDateString } from '../../../utils/dateUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';

class ShipmentsModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			shipmentDetails: []
		};

		this.toggleLoading = this.toggleLoading.bind(this);
		this.getShipmentInfo = this.getShipmentInfo.bind(this);
		this.renderShipmentTable = this.renderShipmentTable.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	getShipmentInfo() {
		const { selectedProduct } = this.props;
		getOrderDetailShipmentInfo(
			selectedProduct.orderDetailId
		)
			.then((response) => {
				this.setState({
					shipmentDetails: response.data
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'loading shipment info'),
					'error',
					error
				);
			})
			.finally(() => {
				this.toggleLoading();
			});
	}

	renderShipmentTable() {
		let { shipmentDetails } = this.state;
		if (shipmentDetails.length > 0) {
			return (
				<Table>
					<thead>
						<tr>
							<th>Ship Date</th>
							<th>Quantity</th>
						</tr>
					</thead>
					<tbody>
						{shipmentDetails.map((detail, index) => {
							return (
								<tr key={'row' + index}>
									<td>
										{formatDate(
											parseDateString(detail.ship_date),
											dateFormat.DATE,
										)}
									</td>
									<td>{numberWithCommas(detail.quantity)}</td>
								</tr>
							);
						})}
						<tr>
							<td></td>
							<td className="BaseTable__totals-cell">
								<label className="BaseTable__totals-cell-label"><b>Total:</b></label>
								<div>
									{numberWithCommas(shipmentDetails
										.reduce((total, detail) => total + detail.quantity, 0)
									)}
								</div>
							</td>
						</tr>
					</tbody>
				</Table>
			);
		} else {
			return <Alert>Awaiting Shipping Plans</Alert>;
		}
	}

	componentDidMount() {
		this.getShipmentInfo();
	}

	render() {
		let { isLoading } = this.state;
		let product = this.props.selectedProduct;
		return (
			<BaseModal show={true} onCancel={this.props.toggleModal} isLoading={isLoading}>
				<BaseModal.Title>Shipping Details</BaseModal.Title>
				<PropertyDisplay label="Item:">{`[${product.gameCode}] ${product.productName}`}</PropertyDisplay>
				<PropertyDisplay label="Part/SKU:">{product.partSku}</PropertyDisplay>
				<PropertyDisplay label="Requested Ship Date:">{product.requestedShipDate}</PropertyDisplay>
				{!isLoading && this.renderShipmentTable()}
			</BaseModal>
		);
	}
}

export default ShipmentsModal;
