import React, { Fragment, ReactElement, VFC, useState } from 'react';
import { Badge, Button, Table } from 'react-bootstrap';

import { permConst } from '../../constants/permConst';
import { orderConst } from '../../constants/orderConstants';
import { useUserProfile } from '../../hooks/reduxHooks';
import { OrderDetailData } from '../../services/ordersService';
import { voidIfEmpty } from '../../utils/arrayUtils';
import { formatCurrency, numberWithCommas } from '../../utils/currencyUtils';
import { isInvalid } from '../../utils/dataUtils';
import { dateFormat, formatDate, parseDateString } from '../../utils/dateUtils';
import { getTotalFromOrder } from '../../utils/orderUtils';
import { isAuthorized } from '../../utils/userUtils';
import FAIcon from '../FAIcon/FAIcon';
import PropertyDisplay from '../PropertyDisplay/PropertyDisplay';
import SectionTitle from '../SectionTitle/SectionTitle';
import StatusText from '../StatusText/StatusText';
import { CommentType, commentLabels, getReasonForInvalidValue } from './PhysicalOrder.helpers';
import ShipmentsModal from './views/ShipmentsModal';

import './PhysicalOrder.css';

interface InvalidDataErrorDisplayProps {
	subject: string;
	value?: InvalidData | string | number | boolean | null;
}
const InvalidDataErrorDisplay: VFC<InvalidDataErrorDisplayProps> = ({ subject, value }) => {
	const invalidValueObject = isInvalid(value);
	if (invalidValueObject) {
		return (
			<div className="text-danger">
				<small>{getReasonForInvalidValue(subject, invalidValueObject) || null}</small>
			</div>
		);
	}
	return null;
};

interface InvalidableValueDisplayProps {
	transform?: (value: any) => string | ReactElement | void;
	value: { invalid: true; value: any } | string | number | boolean | null | void;
	symbol?: string | (() => string | undefined);
}
const InvalidableValueDisplay: VFC<InvalidableValueDisplayProps> = ({ value, transform, symbol }) => {
	if (value == null) { return null; }
	if (typeof value !== 'object') {
		return <>{transform ? transform(value) : value}</>;
	}
	if (typeof value === 'object' && value?.invalid === true) {
		const s = (typeof symbol === 'function' ? symbol() : symbol);
		return (
			<span className="text-danger">
				{transform ? transform(value?.value) : value?.value}{s && <sup>{s}</sup>}
			</span>
		);
	}
	return null;
};

interface PublisherDisplayProps {
	orderData: OrderDetailData | undefined;
	canShowMailTo: boolean;
}
const PublisherDisplay: VFC<PublisherDisplayProps> = ({ orderData, canShowMailTo }) => {
	if (canShowMailTo && orderData?.retail_order_management_users) {
		const emailAddresses = orderData?.retail_order_management_users?.map(user => user.email_address);
		const subject = encodeURIComponent(`Regarding order ${orderData?.publisher_po_number}`);
		return <>{orderData?.company_name} | <a href={`mailto:${emailAddresses.join(',')}?subject=${subject}`}>
			Email all order managers at publisher</a></>;
	} else {
		return <>{orderData?.company_name}</>;
	}
};

interface SubmittedByDisplayProps {
	orderData: OrderDetailData | undefined;
	canShowMailTo: boolean;
}
const SubmittedByDisplay: VFC<SubmittedByDisplayProps> = ({ orderData, canShowMailTo }) => {
	const emailAddress = (orderData?.retail_order_management_users?.find((user) => user.ndid_user_id === orderData?.created_by_ndid) || {}).email_address;

	let displayEmailAddress;
	if (emailAddress) {
		if (canShowMailTo) {
			const subject = encodeURIComponent(`Regarding order ${orderData?.publisher_po_number}`);
			displayEmailAddress = <> (<a href={`mailto:${emailAddress}?subject=${subject}`}>{emailAddress}</a>), </>;
		} else {
			displayEmailAddress = ' (' + emailAddress + '), ';
		}
	}

	return (<>
		{orderData?.created_by_user_name}
		{displayEmailAddress ? displayEmailAddress : ', '}
		{orderData?.created_by_company_name}
	</>
	);
};

interface PhysicalOrderProps {
	orderData: OrderDetailData | undefined;
	ebsPrices: (string | { error: true; message?: string; } | undefined)[];
	showPartNumbers?: boolean;
	selfConsignee?: {
		consignee_company_name?: string;
		consignee_address_street_line_1?: string;
		consignee_address_street_line_2?: string | null;
		consignee_address_city?: string;
		consignee_address_region?: string;
		consignee_address_postal_code?: string;
		consignee_address_country_name?: string;
		consignee_email_address?: string | null;
		consignee_phone_number?: string | null;
	};
	isInternalUser?: boolean;
}

const PhysicalOrder: VFC<PhysicalOrderProps> = ({
	orderData,
	ebsPrices,
	showPartNumbers,
	selfConsignee,
	isInternalUser,
}) => {
	const userProfile = useUserProfile();
	const [openModal, setOpenModal] = useState<
		| {
			type: 'SHIPMENTS_MODAL';
			data: ArrayElement<OrderDetailData['order_details']>;
		}
		| {
			type?: null;
			data?: any;
		}
	>({});
	let showPriceHasChanged = false;
	let showPriceIsNonStandard = false;
	let showUnitPriceHasChangedEDI = false;
	// create an array of symbols 0-9 then a-z
	const symbols = Array.apply(null, Array(35)).map((v, i) =>
		String.fromCharCode(i < 9 ? 49 + i : 88 + i),
	);
	const errorTable: string[] = [];
	const symbolForError = (error?: string) => {
		if (!error) {
			return undefined;
		}
		const index = errorTable.indexOf(error);
		if (index === -1) {
			errorTable.push(error);
			return symbols[errorTable.length - 1];
		}
		return symbols[index];
	};
	const totalQuantity = numberWithCommas(
		Number(
			orderData?.order_details?.reduce<number>(
				(reduction, line) =>
					(typeof line.quantity !== 'object'
						? line.quantity
						: Number(line.quantity?.value)) + reduction,
				0,
			),
		),
	);
	const totalPrice = orderData && getTotalFromOrder(orderData);
	const showShipments = orderData?.order_details.find(
		(lineItem) => !!lineItem.shipments && lineItem.shipments.length > 0,
	);
	const showAdditionalComments =
		isInternalUser &&
		orderData?.order_comments &&
		!![orderConst.COMMENT.NOTES_TO_NCL, orderConst.COMMENT.REVIEWER].filter((value) =>
			Object.keys(orderData.order_comments).includes(value),
		).length;

	const canShowMailTo = isAuthorized(userProfile.permissions, [
		permConst.PHYSICAL_ORDER.REVIEW.ALL,
		permConst.PHYSICAL_ORDER.APPROVE.ALL]);

	const grandTotalError =
		orderData?.order_details?.some(
			(line) =>
				(typeof line.quantity === 'object' && line.quantity?.invalid) ||
				line.unit_price === null,
		) || false;
	const consignee = selfConsignee || orderData?.shipping_info;

	return (
		<>
			<SectionTitle>Order Information</SectionTitle>
			<PropertyDisplay label="Publisher">
				<PublisherDisplay
					orderData={orderData}
					canShowMailTo={canShowMailTo}
				/>
			</PropertyDisplay>
			<PropertyDisplay label="Platform">{orderData?.platform_name}</PropertyDisplay>
			<PropertyDisplay label="Publisher PO Number">
				{orderData?.publisher_po_number}
			</PropertyDisplay>
			{isInternalUser && (
				<PropertyDisplay label="NOA PO Number">{orderData?.noa_po_number}</PropertyDisplay>
			)}
			{isInternalUser && (
				<PropertyDisplay label="Sales Order Number">
					{orderData?.noa_sales_order_number}
				</PropertyDisplay>
			)}
			{isInternalUser && (
				<PropertyDisplay label="Requisition Number">
					{orderData?.requisition_number}
				</PropertyDisplay>
			)}
			<PropertyDisplay label="Submitted By">
				<SubmittedByDisplay
					orderData={orderData}
					canShowMailTo={canShowMailTo}
				/>
				<br />
				<small>
					{formatDate(
						parseDateString(orderData?.creation_date),
						dateFormat.DATETIME_PT
					)}
				</small>
			</PropertyDisplay>
			{orderData?.modified_by_company_name && (
				<PropertyDisplay label="Modified By">
					{isInternalUser && orderData?.modified_by_user_name}{' '}
					{orderData?.modified_by_company_name}
					<br />
					<small>
						{formatDate(
							parseDateString(orderData?.modification_date),
							dateFormat.DATETIME_PT,
						)}
					</small>
				</PropertyDisplay>
			)}
			<PropertyDisplay label="Publisher Comments">
				{orderData?.order_comments['PUBLISHER']?.comment}
			</PropertyDisplay>
			<SectionTitle>Order Details</SectionTitle>
			<Table className="table-nin" style={{ borderBottom: 'none' }}>
				<thead>
					<tr>
						<th>Status</th>
						<th>Items</th>
						<th>Version / Card Size</th>
						<th>Price/Unit</th>
						<th>Requested Ship Date</th>
						{showPartNumbers && <th>Part / SKU</th>}
						<th className="text-right">Quantity</th>
						<th className="text-right">Price</th>
						{showShipments && <th></th>}
					</tr>
				</thead>
				<tbody>
					{orderData?.order_details?.map((line, index) => {
						const priceQueryData = ebsPrices?.[index];
						const ebsPrice = typeof priceQueryData === 'string' ? priceQueryData : null;

						const unitPriceHasChanged =
							ebsPrice && Number(ebsPrice) !== Number(line?.unit_price);
						showPriceHasChanged ||= !!unitPriceHasChanged;

						const unitPriceHasChangedEDI =
							orderData?.edi_order_flag &&
							ebsPrice &&
							orderData?.order_details?.some(
								(line, index) =>
									Number(ebsPrices[index]) !== Number(line?.unit_price),
							);
						showUnitPriceHasChangedEDI ||= !!unitPriceHasChangedEDI;

						const unitPriceIsNotStandard = !(
							!line.standard_rom_price ||
							Number(line.unit_price) === Number(line.standard_rom_price)
						);
						showPriceIsNonStandard ||= !!unitPriceIsNotStandard;

						const ebsPriceIssue =
							(typeof priceQueryData === 'object' && priceQueryData.message) || null;

						if (!line) {
							return null;
						}
						return (
							<tr key={`row-${index}`}>
								<td>
									{orderData?.order_status_history.some(
										(value) =>
											value.status === orderConst.STATUS.ORDER_APPROVED,
									) && line.status !== orderConst.DETAIL_ITEM_STATUS.DRAFT ? (
										<StatusText>{line.status}</StatusText>
									) : (
										<>&mdash;</>
									)}
								</td>
								<td>
									{isInternalUser && line.initial_order ? (
										<>
											<Badge variant="warning">
												<FAIcon name="exclamation-triangle" /> New
											</Badge>{' '}
										</>
									) : null}
									<InvalidableValueDisplay
										value={line.product_id}
										transform={() => `[${line.game_code}] ${line.product_name}`}
										symbol={symbolForError(
											getReasonForInvalidValue(
												'product',
												line.product_id as InvalidData,
											),
										)}
									/>
								</td>
								<td>
									<InvalidableValueDisplay
										value={line.submission_id}
										transform={() => (
											<>
												{line.product_release_version}.
												{line.submission_version}
											</>
										)}
										symbol={symbolForError(
											getReasonForInvalidValue(
												'version',
												line.submission_id as InvalidData,
											),
										)}
									/>{' '}
									{line.submission_status && (
										<>
											<Badge variant="light" className="border">
												{line.submission_status}
											</Badge>{' '}
										</>
									)}
									<Badge variant="light" className="border">
										{line.card_size}
									</Badge>
								</td>
								<td>
									<span
										className={
											unitPriceHasChanged || unitPriceIsNotStandard
												? 'text-danger'
												: ''
										}
									>
										{/* {formatCurrency(line.unit_price)} */}
										<InvalidableValueDisplay
											value={
												ebsPriceIssue
													? {
														invalid: true,
														value: formatCurrency(line.unit_price),
													}
													: formatCurrency(line.unit_price)
											}
											symbol={
												typeof ebsPriceIssue === 'string'
													? symbolForError(ebsPriceIssue)
													: undefined
											}
										/>
										{unitPriceHasChanged ? <sup>&dagger;</sup> : null}
										{unitPriceIsNotStandard ? '*' : null}
									</span>
								</td>
								<td>
									<InvalidableValueDisplay
										value={line.requested_ship_date}
										transform={(value) =>
											(value && formatDate(value, dateFormat.DATE)) || (
												<>&mdash;</>
											)
										}
										symbol={symbolForError(
											getReasonForInvalidValue(
												'requested ship date',
												line.requested_ship_date as InvalidData,
											),
										)}
									/>
								</td>
								{showPartNumbers && <td>{line.publisher_part_number}</td>}
								<td className="text-right">
									<InvalidableValueDisplay
										value={line.quantity}
										transform={(value) => numberWithCommas(value)}
										symbol={symbolForError(
											getReasonForInvalidValue(
												'quantity',
												line.quantity as InvalidData,
											),
										)}
									/>
								</td>
								<td className="text-right">
									<InvalidableValueDisplay
										value={line.quantity}
										transform={(value) =>
											formatCurrency(Number(value) * Number(line.unit_price))
										}
									/>
								</td>
								{showShipments && (
									<td>
										<Button
											title="Shipments"
											onClick={() =>
												setOpenModal({
													type: 'SHIPMENTS_MODAL',
													data: line,
												})
											}
										>
											<FAIcon name="truck-container" />
										</Button>
									</td>
								)}
							</tr>
						);
					})}
					<tr>
						<th colSpan={showPartNumbers ? 6 : 5}></th>
						<td
							className="text-right position-relative"
							style={{ backgroundColor: 'var(--nin-color-ltgray3)' }}
						>
							<div className="PhysicalOrder__grand-total-heading">Grand Total:</div>
							<span className={grandTotalError ? 'text-danger' : ''}>
								{totalQuantity || ''}
							</span>
						</td>
						<td
							className="text-right"
							style={{ backgroundColor: 'var(--nin-color-ltgray3)' }}
						>
							<span className={grandTotalError ? 'text-danger' : ''}>
								{totalPrice || ''}
							</span>
						</td>
						{showShipments && (
							<td style={{ backgroundColor: 'var(--nin-color-ltgray3)' }}></td>
						)}
					</tr>
				</tbody>
			</Table>
			<div className="text-right text-danger">
				{errorTable.map((error, index) => {
					let showEmailCTA;
					// auto replace this phrase with a email link version if it appears in the error
					if (/Please contact NOA via email\.$/.test(error)) {
						error = error.replace(/Please contact NOA via email\.$/, '');
						showEmailCTA = true;
					}
					return (
						<div key={'errorfootnote' + index}>
							<small>
								<sup>{symbols[index]}</sup>{' '}
								<i>
									{error}
									{showEmailCTA && (
										<a
											className="text-underline text-danger"
											href="mailto:thirdpartypublisher@noa.nintendo.com"
										>
											Please contact NOA via email.
										</a>
									)}
								</i>
							</small>
						</div>
					);
				})}
				{(showPriceHasChanged || showUnitPriceHasChangedEDI) && (
					<div>
						<small>
							<sup>&dagger;</sup>{' '}
							<i>Price changed since product was added to order</i>
						</small>
					</div>
				)}
				{showPriceIsNonStandard && (
					<div>
						<small>
							*{' '}
							<i>
								Current price does not match current pricing standard.{' '}
								<a
									className="text-underline text-danger"
									href="mailto:thirdpartypublisher@noa.nintendo.com"
								>
									Please contact NOA via email.
								</a>
							</i>
						</small>
					</div>
				)}
			</div>
			<SectionTitle>Shipping</SectionTitle>
			<PropertyDisplay label="Consignee">
				<div>
					{voidIfEmpty(
						[
							consignee?.consignee_company_name,
							consignee?.consignee_address_street_line_1,
							consignee?.consignee_address_street_line_2,
							[consignee?.consignee_address_city, consignee?.consignee_address_region]
								.filter((v: any) => v)
								.join(', '),
							consignee?.consignee_address_postal_code,
							consignee?.consignee_address_country_name,

							consignee?.consignee_email_address,
							consignee?.consignee_phone_number,
						]
							.map((value, index) =>
								value ? (
									<Fragment key={`key-${value}-${index}`}>
										{value}
										<br />
									</Fragment>
								) : null,
							)
							.filter((v: any) => v),
					) || <>&mdash;</>}
				</div>
				{/* not expected to see InvalidData for both of these properties */}
				<InvalidDataErrorDisplay
					subject="consignee"
					value={orderData?.consignee_company_id}
				/>
				<InvalidDataErrorDisplay
					subject="consignee"
					value={orderData?.self_consigned}
				/>
			</PropertyDisplay>
			<PropertyDisplay label="Port of Entry">
				{orderData?.shipping_info.port_of_entry_name || <>&mdash;</>}
				<InvalidDataErrorDisplay
					subject="port of entry"
					value={orderData?.port_of_entry_code}
				/>
			</PropertyDisplay>
			<PropertyDisplay label="Packout Facility">
				<div>{orderData?.shipping_info.packout_facility_name}</div>
				{voidIfEmpty(
					[
						orderData?.shipping_info.packout_facility_address_street_line_1,
						orderData?.shipping_info.packout_facility_address_street_line_2,
						[
							orderData?.shipping_info.packout_facility_address_city,
							orderData?.shipping_info.packout_facility_address_region,
						]
							.filter((v: any) => v)
							.join(', '),
						orderData?.shipping_info.packout_facility_address_postal_code,
						orderData?.shipping_info.packout_facility_address_country_name,
						orderData?.shipping_info.packout_facility_phone_number,
					]
						.map((value, index) =>
							value ? (
								<Fragment key={`key-${value}-${index}`}>
									{value}
									<br />
								</Fragment>
							) : null,
						)
						.filter((v: any) => v),
				) || <>&mdash;</>}
				{orderData?.packout_facility_contact_name && (
					<>
						<br />
						{[
							orderData?.packout_facility_contact_name,
							orderData?.packout_facility_contact_email && (
								<a href={`mailto:${orderData?.packout_facility_contact_email}`}>
									{orderData?.packout_facility_contact_email}
								</a>
							),
							orderData?.packout_facility_contact_phone_number,
						]
							.filter((v: any) => v)
							.map((value, index) =>
								value ? (
									<Fragment key={`key-${value}-${index}`}>
										{value}
										<br />
									</Fragment>
								) : null,
							)}
					</>
				)}
				<InvalidDataErrorDisplay
					subject="packout facility"
					value={orderData?.packout_facility_id}
				/>
			</PropertyDisplay>
			<PropertyDisplay label="Freight Forwarder">
				{orderData?.shipping_info.freight_forwarder_name || <>&mdash;</>}
				{orderData?.shipping_info.japanese_contact_name && (
					<>
						<br />
						<br />
						{[
							orderData?.shipping_info.japanese_contact_name,
							orderData?.shipping_info.japanese_contact_email && (
								<a
									href={`mailto:${orderData?.shipping_info.japanese_contact_email}`}
								>
									{orderData?.shipping_info.japanese_contact_email}
								</a>
							),
							orderData?.shipping_info.japanese_contact_phone_number,
						]
							.filter((v: any) => v)
							.map((value, index) =>
								value ? (
									<Fragment key={`key-${value}-${index}`}>
										{value}
										<br />
									</Fragment>
								) : null,
							)}
					</>
				)}
				{orderData?.shipping_info.freight_forwarder_carrier_account_number && (
					<>
						<br />
						Carrier Account Number:{' '}
						{orderData?.shipping_info.freight_forwarder_carrier_account_number}
					</>
				)}
				{orderData?.shipping_info.freight_forwarder_service_level && (
					<>
						<br />
						Service Level: {orderData?.shipping_info.freight_forwarder_service_level}
					</>
				)}
				<InvalidDataErrorDisplay
					subject="freight forwarder"
					value={orderData?.freight_forwarder_code}
				/>
			</PropertyDisplay>
			<PropertyDisplay label="Shipping Instructions">
				{orderData?.order_comments?.SHIPPING_INFO?.comment || null}
			</PropertyDisplay>
			{showAdditionalComments && <SectionTitle>Additional Comments</SectionTitle>}
			{showAdditionalComments &&
				orderData?.order_comments &&
				Object.keys(orderData.order_comments).map((key) => {
					if (
						key === orderConst.COMMENT.NOTES_TO_NCL ||
						key === orderConst.COMMENT.REVIEWER
					) {
						return (
							<PropertyDisplay key={key} label={commentLabels[key]}>
								{orderData.order_comments[key as CommentType]?.comment}
							</PropertyDisplay>
						);
					}
				})}
			<ShipmentsModal
				show={openModal.type === 'SHIPMENTS_MODAL'}
				onClose={() => setOpenModal({ ...openModal, type: null })}
				lineItem={openModal.data || {}}
				showPartNumbers={showPartNumbers}
			/>
		</>
	);
};
export default PhysicalOrder;
