import React from 'react';
import { Col, Form, FormGroup, Row } from 'react-bootstrap';

import {
	dateFormat,
	formatDate,
	parseDateString
} from '../../../utils/dateUtils';
import List from '../../../components/List/List';
import BaseModal from '../../../components/BaseModal/BaseModal';
import ISO6391 from 'iso-639-1';
import { postRequestGamecode } from '../../../services/productsService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';

function generateFormGroup(title, item) {
	return (
		<Row as={FormGroup}>
			<Col sm={4} className="text-sm-right">
				<strong>{title}</strong>
			</Col>
			<Col sm={8}>{item}</Col>
		</Row>
	);
}

export default class RequestGameCodeModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			display: true,
			isLoading: false
		};
		this.toggleLoading = this.toggleLoading.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	requestGameCode() {
		this.toggleLoading();
		const { productSummary } = this.props;
		postRequestGamecode(productSummary.product_id)
			.then((response) => {
				if (response.status === 200) {
					const updatedProductSummary = Object.assign(productSummary);
					updatedProductSummary.game_code = `(Requested ${formatDate(
						parseDateString(response.data),
						dateFormat.DATE
					)})`;
					this.props.updateGameCodeField(updatedProductSummary);
					const message = `Game Code request for ${
						productSummary.game_name
					} was sent to NCL at ${formatDate(
						parseDateString(response.data),
						dateFormat.DATE
					)}`;
					toasterNotify(message, 'success');
				}
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'requesting game code'),
					'error',
					error
				);
			})
			.finally(() => {
				this.props.closeModal();
			});
	}

	displayFullLanguageName() {
		return (
			this.props.productSummary &&
			this.props.productSummary.languages.map((shortLanguageName) => {
				return ISO6391.getName(shortLanguageName.trim());
			})
		);
	}

	render() {
		const { display } = this.state;
		const { closeModal } = this.props;
		return (
			<BaseModal show={display} onCancel={closeModal} isSubmitting={this.state.isLoading}>
				<BaseModal.Title>Request Game Code</BaseModal.Title>
				<Form className="gameCodeForm">
					{generateFormGroup(
						'Publisher',
						this.props.productSummary.company_name
					)}
					{generateFormGroup(
						'Product Name',
						this.props.productSummary.game_name
					)}
					{generateFormGroup(
						'Platform',
						this.props.productSummary.platform_name
					)}
					{generateFormGroup(
						'Other Region Game Codes',
						<List
							input={
								this.props.productSummary
									.region_game_code
							}
						/>
					)}
					{generateFormGroup(
						'Languages',
						<List input={this.displayFullLanguageName()} />
					)}
					{generateFormGroup(
						'Delivery Format',
						this.props.productSummary
							.product_distribution_type
					)}
					{generateFormGroup(
						'Card Type',
						this.props.productSummary.card_type
					)}
				</Form>
				<BaseModal.Submit
					onClick={() => {
						this.requestGameCode();
					}}
					disabled={this.state.isLoading}
				>Request Game Code</BaseModal.Submit>
			</BaseModal>
		);
	}
}
