import React, { useEffect, useState } from 'react';
import { Alert, Col, Form, FormCheck, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { useQuery, useQueryClient } from 'react-query';

import DatePicker from '../../../components/DatePicker/DatePicker';
import BaseModal from '../../BaseModal/BaseModal';
import HelpBlock from '../../../components/HelpBlock/HelpBlock';
import { parseDateString, formatDate, dateFormat, isDateInRange } from '../../../utils/dateUtils';
import {
	updateAnnouncement,
	createAnnouncement,
	getAnnouncements,
	getAnnouncementTemplates,
	getAnnouncementPriorities,
} from '../../../services/announcementService';
import { toasterNotify } from '../../../utils/toaster';

import './AnnouncementModal.css';

const formatMessage = (message, template, values) => {
	let output = message;
	template.template_parameters.forEach((p) => {
		const sub =
			formatDate(
				parseDateString(values[p.announcement_column_name]),
				dateFormat.DATETIME_PT,
			) || `[${p.form_label}]`;
		output = output.split(`{${p.announcement_column_name}}`, 2).join(sub);
	});
	return output;
};

const requiredFieldsForTemplate = (template) =>
	template?.template_parameters
		.filter((p) => !!p.required_flag)
		.map((p) => ({ name: p.announcement_column_name, type: p.parameter_data_type })) || null;

const initValuesForTemplate = (template) => {
	const params = template.template_parameters.map((p) => ({
		[p.announcement_column_name]: p.parameter_date_type === 'DATETIME' ? null : '',
	}));
	return {
		...params,
		message:
			template.message ||
			template.template_parameters.map((p) => `{${p.announcement_column_name}}`).join(' '),
	};
};

export const validation = (template, values, isUpdate) => {
	const required = [
		{ name: 'announcement_start_datetime', type: 'DATETIME' },
		{ name: 'announcement_end_datetime', type: 'DATETIME' },
		{ name: 'message', type: 'STRING' },
		...requiredFieldsForTemplate(template),
	];
	const flagged = []; // fields that need to be shown as an issue
	let validated = true;

	required.forEach((f) => {
		if (!values[f.name]) {
			validated = false;
			flagged.push({ name: f.name, reason: 'Field cannot be blank' });
		}
	});

	if (
		'event_start_datetime' in values &&
		values.event_start_datetime &&
		values.event_start_datetime < new Date() &&
		!isUpdate
	) {
		flagged.push({
			name: 'event_start_datetime',
			reason: 'Event start date cannot be in the past',
		});
		validated = false;
	}

	if (
		'event_start_datetime' in values &&
		'event_end_datetime' in values &&
		values.event_start_datetime &&
		values.event_end_datetime &&
		values.event_end_datetime <= values.event_start_datetime
	) {
		flagged.push({
			name: 'event_end_datetime',
			reason: 'Event end date must be after event start date',
		});
		validated = false;
	}

	if (
		values.announcement_start_datetime &&
		values.announcement_start_datetime < new Date() &&
		!isUpdate
	) {
		flagged.push({
			name: 'announcement_start_datetime',
			reason: 'Announcement start date cannot occur in the past',
		});
		validated = false;
	}

	if (
		'event_start_datetime' in values &&
		values.announcement_start_datetime &&
		values.event_start_datetime &&
		values.announcement_start_datetime > values.event_start_datetime
	) {
		flagged.push({
			name: 'announcement_start_datetime',
			reason: 'Announcement start date cannot be after event start date',
		});
		validated = false;
	} else if (
		'event_end_datetime' in values &&
		values.announcement_start_datetime &&
		values.event_end_datetime &&
		values.announcement_start_datetime > values.event_end_datetime
	) {
		flagged.push({
			name: 'announcement_start_datetime',
			reason: 'Announcement start date cannot be after event end date',
		});
		validated = false;
	}

	if (
		values.announcement_start_datetime &&
		values.announcement_end_datetime &&
		values.announcement_end_datetime <= values.announcement_start_datetime
	) {
		flagged.push({
			name: 'announcement_end_datetime',
			reason: 'Announcement end date must be after announcement start date',
		});
		validated = false;
	}

	if (
		'event_end_datetime' in values &&
		values.announcement_end_datetime &&
		values.event_end_datetime &&
		values.announcement_end_datetime < values.event_end_datetime
	) {
		flagged.push({
			name: 'announcement_end_datetime',
			reason: 'Announcement end date cannot be before event end date',
		});
		validated = false;
	} else if (
		'event_start_datetime' in values &&
		values.announcement_start_datetime &&
		values.event_start_datetime &&
		values.announcement_end_datetime < values.event_start_datetime
	) {
		flagged.push({
			name: 'announcement_end_datetime',
			reason: 'Announcement end date cannot be before event start date',
		});
		validated = false;
	}

	return [(field) => flagged.find((f) => f.name === field), validated];
};

const formatToValues = (announcement, templates) => {
	const template = templates.find((t) => t.template_id === announcement.template_id);
	if (template == null) return;

	const output = {};
	[
		'announcement_start_datetime',
		'announcement_end_datetime',
		'announcement_id',
		'message',
		'priority_code',
		'template_id',
	].forEach((k) => (output[k] = announcement[k]));
	output.announcement_start_datetime = new Date(announcement.announcement_start_datetime);
	output.announcement_end_datetime = new Date(announcement.announcement_end_datetime);
	template.template_parameters.forEach((p) => {
		if (p.parameter_data_type === 'DATETIME') {
			if (announcement[p.announcement_column_name] !== null) {
				output[p.announcement_column_name] = new Date(
					announcement[p.announcement_column_name],
				);
			} else {
				output[p.announcement_column_name] = '';
			}
		} else {
			output[p.announcement_column_name] = announcement[p.announcement_column_name];
		}
	});

	return output;
};

const AnnouncementModal = ({ show, announcementId, closeModal }) => {
	const datePickerFormat = 'MM/dd/yyyy h:mm aa';
	const queryClient = useQueryClient();

	const announcementsQuery = useQuery('getAnnouncements', () => getAnnouncements());
	const announcementTemplatesQuery = useQuery('getAnnouncementTemplates', () =>
		getAnnouncementTemplates(),
	);
	const announcementPrioritiesQuery = useQuery('getAnnouncementPriorities', () =>
		getAnnouncementPriorities(),
	);

	const isLoading =
		announcementsQuery.isLoading &&
		announcementTemplatesQuery.isLoading &&
		announcementPrioritiesQuery.isLoading;

	const announcement =
		announcementId &&
		announcementsQuery.data?.data?.find((a) => a.announcement_id === announcementId);
	const templates = announcementTemplatesQuery.data?.data
		?.concat() // make a copy to avoid messing with the original object
		.sort((a, b) => a.template_name > b.template_name);
	const priorities = announcementPrioritiesQuery.data?.data
		?.concat()
		.sort((a, b) => a.priority_sort_order > b.priority_sort_order);

	const [values, setValues] = useState();
	const [messageEditable, setMessageEditable] = useState();
	const [errorAlert, setErrorAlert] = useState(null);
	const [submitting, setSubmitting] = useState(false);

	useEffect(() => {
		if (!isLoading) {		
			if (show && announcementId) {
				setValues({
					...formatToValues(announcement, templates),
				});
			} else if (show) {
				setValues({
					template_id: templates[0].template_id,
					priority_code: priorities[0].priority_code,
					announcement_start_datetime: null,
					announcement_end_datetime: null,
					...initValuesForTemplate(templates[0]),
				});
			}

			if (show) {
				setMessageEditable(!templates[0].message);
			}
		}
	}, [isLoading, show]);

	// keeps track of fields that have been touched. We won't nag users to fill out form
	// fields unless they've selected and left that field
	const [touchedFields, setTouchedFields] = useState({});

	if (isLoading || !values) {
		return <BaseModal show={show} onCancel={closeModal} size="lg" isLoading={isLoading}></BaseModal>;
	}

	const isActive =
		announcement &&
		isDateInRange(
			new Date(),
			new Date(values.announcement_start_datetime),
			new Date(values.announcement_end_datetime),
		);

	const currentTemplate = templates?.find((t) => t.template_id === values?.template_id);

	const templateChange = (newTemplateId) => {
		if (submitting) {
			return false;
		}
		const newTemplate = templates.find(
			(t) => t.template_id.toString() === newTemplateId.toString(),
		);
		if (newTemplate) {
			setValues({
				template_id: newTemplate.template_id,
				priority_code: priorities[0].priority_code,
				announcement_start_datetime: null,
				announcement_end_datetime: null,
				...initValuesForTemplate(newTemplate),
			});
			setMessageEditable(!newTemplate.message);
		}
		setTouchedFields({});
	};
	const priorityChange = (newPriority) => {
		if (submitting) {
			return false;
		}
		setValues({ ...values, priority_code: newPriority });
	};
	const editCheckboxChange = (checked) => {
		if (submitting) {
			return false;
		}
		if (checked === false) {
			const currentTemplate = templates.find(
				(t) => t.template_id.toString() === values.template_id.toString(),
			);
			setValues({
				...values,
				message: currentTemplate.message,
			});
		}
		setMessageEditable(checked);
	};
	const dateChange = (fieldName, value) => {
		if (submitting) {
			return false;
		}
		value = value ? new Date(value) : value;
		setValues({
			...values,
			[fieldName]: value,
		});
		setTouchedFields({ ...touchedFields, [fieldName]: true });
	};
	const stringChange = (event, fieldName) => {
		if (submitting) {
			return false;
		}
		setValues({
			...values,
			[fieldName]: event.target.value,
		});
	};
	const blur = (fieldName) => setTouchedFields({ ...touchedFields, [fieldName]: true });

	const submit = async () => {
		if (submitting) {
			return false;
		}
		setSubmitting(true);

		const payload = { ...values };
		Object.keys(payload).forEach((k) => {
			if (payload[k] instanceof Date) {
				payload[k] = payload[k].toISOString();
			}
			if (payload[k] === '') {
				payload[k] = null;
			}
		});

		try {
			let response;
			if (announcement) {
				response = await updateAnnouncement(announcementId, payload);
			} else {
				response = await createAnnouncement(payload);
			}
			if (response.status !== 200) {
				setErrorAlert(response.toString());
			} else {
				toasterNotify(
					announcement
						? 'Your announcement was updated'
						: 'Your new announcement has been created',
					'success',
				);
				queryClient.invalidateQueries('getAnnouncements');
				closeModal();
			}
		} catch (err) {
			const status = err.response && err.response.status;
			if (status === 403 || Number(status) >= 500) {
				closeModal();
				toasterNotify(
					'There was an unexpected error while submitting the changes.',
					'error',
				);
			} else if (status >= 400) {
				if (
					'data' in err.response &&
					'message' in err.response.data &&
					'error' in err.response.data.message &&
					'message' in err.response.data.message.error
				) {
					setErrorAlert(
						err.response.data.message.error.message + ' (' + err.response.status + ')',
					);
				} else {
					setErrorAlert(err.toString());
				}
			} else if (err.response && err.response.message) {
				setErrorAlert(err.response.message);
			} else {
				setErrorAlert(err.toString());
			}
		} finally {
			setSubmitting(false);
		}
	};

	const [flagsForField, valid] = validation(currentTemplate, values, announcement ? true : false);

	const validationFlag = (field, isRequired) => {
		const flag = flagsForField(field);
		if (flag && touchedFields[field]) {
			return (
				<HelpBlock>
					<span className="text-danger">{flag.reason}</span>
				</HelpBlock>
			);
		} else {
			if (!isRequired) return <HelpBlock>Optional</HelpBlock>;
		}
		return null;
	};

	return (
		<BaseModal show={show} onCancel={closeModal} size="lg" isSubmitting={submitting}>
			<BaseModal.Title>
				{announcement != null ? 'Edit Announcement' : 'Create Announcement'}
			</BaseModal.Title>
			{errorAlert && (
				<Alert variant="danger" onDismiss={(e) => setErrorAlert(null)} closeLabel="Close">
					{errorAlert}
				</Alert>
			)}
			<Form autoComplete="off">
				<FormGroup className="row">
					<FormLabel className="col-sm-4 text-sm-right" column>
						Announcement Type
					</FormLabel>
					{announcement ? (
						<Col sm={7}>
							<FormControl
								as="input"
								value={currentTemplate.template_name}
								disabled={true}
							/>
						</Col>
					) : (
						<Col sm={7}>
							<FormControl
								as="select"
								placeholder="select"
								onChange={(e) => templateChange(e.target.value)}
							>
								{templates.map((t) => (
									<option value={t.template_id} key={`template_${t.template_id}`}>
										{t.template_name}
									</option>
								))}
							</FormControl>
						</Col>
					)}
				</FormGroup>
				<FormGroup className="row">
					<FormLabel className="col-sm-4 text-sm-right" column>
						Priority
					</FormLabel>
					<Col sm={7}>
						<FormControl
							as="select"
							placeholder="select"
							onChange={(e) => priorityChange(e.target.value)}
							value={values.priority_code}
						>
							{priorities.map((p) => (
								<option value={p.priority_code} key={`priority_${p.priority_code}`}>
									{p.priority_name}
								</option>
							))}
						</FormControl>
					</Col>
				</FormGroup>

				{currentTemplate.template_parameters.map((p) => (
					<FormGroup
						className="row"
						key={`param-${p.announcement_column_name}`}
						controlId={p.announcement_column_name}
					>
						<FormLabel className="col-sm-4 text-sm-right" column>
							{p.form_label}
						</FormLabel>
						<Col sm={7}>
							{p.parameter_data_type !== 'DATETIME' && (
								<FormControl
									type="text"
									value={values[p.announcement_column_name]}
									onChange={(e) => stringChange(e, p.announcement_column_name)}
									onBlur={(e) => blur(p.announcement_column_name)}
								/>
							)}
							{p.parameter_data_type === 'DATETIME' && (
								<>
									<DatePicker
										dropdownMode="select"
										value={values[p.announcement_column_name]}
										onChange={(value) =>
											dateChange(p.announcement_column_name, value)
										}
										showTimeSelect
										dateFormat={datePickerFormat}
										onBlur={(e) => blur(p.announcement_column_name)}
									/>
								</>
							)}
							{validationFlag(p.announcement_column_name, p.required_flag)}
						</Col>
					</FormGroup>
				))}
				<FormGroup className="row" controlId="announcement_start_datetime">
					<FormLabel className="col-sm-4 text-sm-right" column>
						Announcement Start Date
					</FormLabel>
					<Col sm={7}>
						{isActive ? (
							<FormControl
								as="input"
								value={formatDate(
									parseDateString(values.announcement_start_datetime),
									'MM/DD/YYYY hh:mm A',
								)}
								disabled={true}
							/>
						) : (
							<span>
								<DatePicker
									dropdownMode="select"
									value={values.announcement_start_datetime}
									onChange={(value) =>
										dateChange('announcement_start_datetime', value)
									}
									showTimeSelect
									dateFormat={datePickerFormat}
									onBlur={(e) => blur('announcement_start_datetime')}
								/>
							</span>
						)}
						{validationFlag('announcement_start_datetime', true)}
					</Col>
				</FormGroup>
				<FormGroup className="row" controlId="announcement_end_datetime">
					<FormLabel className="col-sm-4 text-sm-right" column>
						Announcement End Date
					</FormLabel>
					<Col sm={7}>
						<DatePicker
							dropdownMode="select"
							value={values.announcement_end_datetime}
							onChange={(value) => dateChange('announcement_end_datetime', value)}
							showTimeSelect
							dateFormat={datePickerFormat}
							onBlur={(e) => blur('announcement_end_datetime')}
						/>
						{validationFlag('announcement_end_datetime', true)}
					</Col>
				</FormGroup>
				<FormGroup className="row" controlId="message">
					<FormLabel className="col-sm-4 text-sm-right" column>
						Announcement
					</FormLabel>
					<Col sm={7}>
						<FormControl
							as="textarea"
							rows={5}
							disabled={!messageEditable}
							value={
								messageEditable
									? values.message
									: formatMessage(values.message, currentTemplate, values)
							}
							onChange={(e) => setValues({ ...values, message: e.target.value })}
							onBlur={(e) => blur('message')}
							maxLength={500}
						/>
						{validationFlag('message', true)}
					</Col>
				</FormGroup>
				{!!currentTemplate.message && (
					<FormGroup className="row">
						<Col sm={4} />
						<Col sm={7}>
							<FormCheck
								type="checkbox"
								id="checkbox-edit-announcement-message"
								checked={messageEditable}
								onChange={(e) => editCheckboxChange(e.target.checked)}
								label="Edit Announcement Message"
							/>
						</Col>
					</FormGroup>
				)}
			</Form>
			<BaseModal.Submit disabled={!valid || submitting} onClick={(e) => submit()}>
				Submit
			</BaseModal.Submit>
		</BaseModal>
	);
};

export default AnnouncementModal;
