import React, { useEffect, useState } from 'react';

import BaseModal from '../BaseModal/BaseModal';
import { getLoginUrl } from '../../utils/serviceUtils';
import FAIcon from '../FAIcon/FAIcon';

let stateSetter = null;

export const showAuthExpired = () => {
	if (stateSetter) {
		stateSetter(true);
	}
};

const AuthExpiredDisplay = () => {
	const [state, setState] = useState(null);

	useEffect(() => {
		stateSetter = stateSetter || setState;
	}, []);

	return state ? (
        <BaseModal show={true}>
			<div className="d-flex flex-row align-items-center">
				<FAIcon className="text-info m-5" name="user" size='4x' />
				<h3 className="mr-5">Your user session has expired. Sign in again to continue.</h3>
			</div>
            <BaseModal.Submit
                href={getLoginUrl()}
            >
                Sign In
            </BaseModal.Submit>
        </BaseModal>

	) : null;
};

export default AuthExpiredDisplay;
