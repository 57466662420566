import React, { VFC, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useUserProfile } from '../../../hooks/reduxHooks';
import { useQuery } from 'react-query';
import { getProductSubmissions } from '../../../services/productsService';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import FilterableTable from '../../../components/FilterableTable/FilterableTable';
import Loading from '../../../components/Loading/Loading';
import { isAuthorized } from '../../../utils/userUtils';
import { permConst } from '../../../constants/permConst';
import { compareDates, dateFormat, formatDate, parseDateString } from '../../../utils/dateUtils';
import FAIcon from '../../../components/FAIcon/FAIcon';
import { subConst } from '../../../constants/submissionsConstants';
import { linkColumn } from '../../../components/BaseTable/BaseTable';
import { useIsFeatureSunsetted } from '../../../hooks/reduxHooks';
import { displayString } from '../../../utils/stringUtils';
import ReceiveSubmissionModal from '../modals/ReceiveSubmissionModal';


const getTableFormat = (showCardSize: boolean, productId: string | number) => {
	const chevronLink = ({ original }: { original: Record<string, any> }) => {
		return <Link to={`/submission-details/${productId}/${original.product_submission_id}`}><FAIcon name="chevron-right" /></Link>;
	};
	const statusDisplay = ({ original }: { original: Record<string, any> }) => {
		let classAttrib = '';
		if ((original.distribution_type === subConst.DISTRIBUTION_TYPE.BOTH ||
			original.distribution_type === subConst.DISTRIBUTION_TYPE.PHYSICAL)
			&& (original.type === subConst.TYPE.LOTCHECK || original.type === subConst.TYPE.SKIP_LOTCHECK)) {
			if (original.status.toUpperCase() === subConst.STATUS.OK) {
				classAttrib = 'text-success';
			} else if ([subConst.STATUS.TESTING,
				        subConst.STATUS.SUBMITTING,
				        subConst.STATUS.SUBMITTED,
				        subConst.STATUS.INQUEUE].includes(original.status.toUpperCase())) {
				classAttrib = 'text-warning';
			}
		}
		return <b><span className={classAttrib}>{original.status}</span></b>;
	};
	let tableConfiguration = [
		{
			Header: 'Submission Date',
			accessor: 'submission_date',
			sortMethod: (a: string | number | Date, b: string | number | Date) => {
				return new Date(a) > new Date(b) ? 1 : -1;
			},
			Cell: (row: Record<string, any>) => formatDate(parseDateString(row.value), dateFormat.DATE)
		},
		{
			Header: 'Version',
			id: 'version',
			accessor: (d: Record<string, any>) =>
				`${d.product_release_version}.${d.product_submission_version}`,
		},
		{
			Header: 'Card Size',
			id: 'card_size',
			accessor: (d: Record<string, any>) => {
				if (d.distribution_type === subConst.DISTRIBUTION_TYPE.DIGITAL) {
					return 'Digital';
				} else {
					return d?.card_size;
				}
			},
			show: showCardSize
		},
		{ Header: 'Product Code', accessor: 'product_type' },
		{
			Header: 'Status',
			id: 'status',
			accessor: 'status',
			Cell: statusDisplay,
		},
		{
			Header: 'Approval Date',
			id: 'approval_date',
			accessor: (d: Record<string, any>) => {
				let x = d.approval_date;
				if (d.status &&
                    (d.status.toUpperCase() === subConst.STATUS.APPROVED ||
                        d.status.toUpperCase() === subConst.STATUS.OK)
				) {
					x = d.approval_date;
				} else {
					x = '';
				}
				return x;},
			sortMethod: (a: string | number | Date, b: string | number | Date) => {
				return new Date(a) > new Date(b) ? 1 : -1;
			},
			Cell: (row: Record<string, any>) => formatDate(parseDateString(row.value), dateFormat.DATE)
		},
		{
			...linkColumn,
			Cell: chevronLink
		}
	];
	return tableConfiguration;
};

const getFilterProperties = () => {
	const filterProperties = new Map();
	filterProperties.set('Status', {
		filter: 'status',
		selectableFilters: new Set(
            [
            	'APPROVED',
            	'CANCELLED',
            	'DRAFT',
            	'FAILED',
            	'INQUEUE',
            	'NG',
            	'NOA_APPROVED',
            	'NONE',
            	'OK',
            	'PRECHECK',
            	'SAMPLE_AP',
            	'SUBMITTED',
            	'SUBMITTING',
            	'SUSPENDED',
            	'TESTING',
            ].map((s) => displayString(s)),
		),
	});
	return filterProperties;
};

interface SubmissionModalModel {
    type?: 'RECEIVE_MODAL' | null;
}
interface SubmissionsTabProps {
	isSelected: boolean;
	product: ProductData;
}
const SubmissionsTab: VFC<SubmissionsTabProps> = ({
	isSelected,
	product,
}) => {
	const userProfile = useUserProfile();
	const [openModal, setOpenModal] = useState<SubmissionModalModel | null>(null);
	const submissionsQuery = useQuery(
		['getProductSubmissions', product.product_id],
		() => getProductSubmissions(String(product.product_id)),
		{ enabled: isSelected },
	);
	const submissions =
		submissionsQuery?.data?.data &&
		[...submissionsQuery.data.data].sort((a, b) => {
			if (a.submission_date && b.submission_date) {
				return -compareDates(a.submission_date, b.submission_date);
			}
			return 0;
		});
	const lotcheckDisabled = useIsFeatureSunsetted('lotcheck_features');

	const showReceiveSubmission =
        isAuthorized(userProfile.permissions, [
        	permConst.SUBMISSION.ADD.ALL
        ]) &&
        product.platform_code &&
        product.platform_code !== 'HAC';

	const showCardSize = !!(submissionsQuery.isSuccess && submissions?.length && 'card_size' in submissions[0]);
	const dataFormat = useMemo(() => getTableFormat(showCardSize, product.product_id), [showCardSize]);
	const filterProperties = useMemo(() => getFilterProperties(), []);

	if (!isSelected) {
		return <div />;
	}

	return (
		<>
			{
				!isSelected ? (
					null
				) : submissionsQuery.isLoading ? (
				    <Loading />
				) : (
					<>
						{showReceiveSubmission && !lotcheckDisabled && (
							<Button
								onClick={(e) => setOpenModal({type: 'RECEIVE_MODAL'})}
								variant="primary"
							>
								Receive Submission
							</Button>
						)}
                        <SectionTitle>Submission History</SectionTitle>
                        <FilterableTable
                            noBorder
                            data={submissions}
                            dataFormat={dataFormat}
                            filterProperties={filterProperties}
                            className="no-zebra"
                            showPagination={!!submissions && submissions.length < 25 ? false : true}
                            defaultPageSize={25}
                        />
                        {openModal?.type === 'RECEIVE_MODAL' && (
                            <ReceiveSubmissionModal
                                userProfile={userProfile}
                                closeModal={() => setOpenModal({})}
                                productSummary={product}
                                submissions={submissions}
                            />
                        )}
					</>
				)}
		</>
	);
};

export default SubmissionsTab;
