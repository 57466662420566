import React from 'react';
import './List.css';

const List = (props) => {
	const { input, className } = props;
	let listClass =
		input.length > 1 ? 'list-class-multiple' : 'list-class-single';

	return (
		<div>
			<ul className={[className, listClass].join(' ')}>
				{input && input.map((item, index) => {
					return <li key={index}>{item}</li>;
				})}
			</ul>
		</div>
	);
};

export default List;
