import React, { Component } from 'react';
import { connect } from 'react-redux';

import { SubmissionAsset } from './SubmissionAsset';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { dateFormat, formatDate, parseDateString } from '../../../utils/dateUtils';

function mapStateToProps(state) {
	return {
		userProfile: state.authReducer.userProfile
	};
}

class ActivityLog extends Component {
	renderActivityLogCommentString(comment) {
		if (!comment) return null;
		return (
			<div className="comment">
				{comment.split('\n').map((item, key) => {
					return <p key={key}>{item}</p>;
				})}
			</div>
		);
	}

	renderActivityLogCommentFileList(comment) {
		const { userProfile } = this.props;
		return (
			<div className="comment">
				<ul>
					{comment.map((item, i) => (
						<li>
							{
								<SubmissionAsset
									key={i}
									asset={item}
									userProfile={userProfile}
									hideDate={true}
								/>
							}{' '}
							-{' '}
							<span
								className={
									item.status === 'deleted'
										? 'text-danger'
										: 'text-success'
								}
							>
								{item.status}
							</span>
						</li>
					))}
				</ul>
			</div>
		);
	}

	renderActivityStatus(activityContent) {
		if (this.isNotEmptyString(activityContent)) {
			const statusClassName = `status-mini ${this.getActivityStatusClassName(
				activityContent
			)}`;
			return <span className={statusClassName}>{activityContent}</span>;
		}
	}

	isNotEmptyString(activityContent) {
		return (
			activityContent !== null &&
			typeof activityContent === 'string' &&
			activityContent !== ''
		);
	}

	getActivityStatusClassName(activityStatus) {
		const status = activityStatus.toLowerCase();
		if (status.includes('approved')) {
			return 'status-approved';
		}

		if (status.includes('failed')) {
			return 'status-rejected';
		}

		return 'status-pending';
	}

	render() {
		let { activityLog } = this.props;

		return (
			<div className="ActivityLog">
				<SectionTitle>Submission Activity Log</SectionTitle>
				{activityLog.map((item, i) => (
					<div key={i} className="comment-container">
						<p>
							<strong>{item.user}</strong>{' '}
							<span>{item.activity_description}</span>{' '}
							{item.content &&
								this.renderActivityStatus(item.content)}
							{' - '} {formatDate(parseDateString(item.timestamp), dateFormat.DATETIME_PT)}
						</p>
						{item.comment && Array.isArray(item.comment)
							? this.renderActivityLogCommentFileList(
									item.comment
							  )
							: this.renderActivityLogCommentString(item.comment)}
					</div>
				))}
			</div>
		);
	}
}

export default connect(mapStateToProps)(ActivityLog);
