import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { linkColumn } from '../../../components/BaseTable/BaseTable';
import FAIcon from '../../../components/FAIcon/FAIcon';
import FilterableTable from '../../../components/FilterableTable/FilterableTable';
import Loading from '../../../components/Loading/Loading';
import Page from '../../../components/Page/Page';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { TabState } from '../../../components/StatefulTabs/StatefulTabs';
import StatusText from '../../../components/StatusText/StatusText';
import DateCell from '../../../components/cells/DateCell/DateCell';
import { assetConst } from '../../../constants/assetConstants';
import { formatAssetType } from '../../../utils/assetUtils';
import { displayString } from '../../../utils/stringUtils';
import { useAssetQuery } from '../ApprovalQueue.helpers';

const { CODE_BOX, DRI_BUNDLE, LABEL, MANUAL, MANUAL_INTERIOR, OTHER, RETAIL_BUNDLE, TITLE_SHEET } = assetConst.TYPE;

// default filter selected statuses
const statusOptions = [
	assetConst.STATUS.PENDING_REVIEW,
	assetConst.STATUS.FINAL_LABEL_UPLOADED,
	assetConst.STATUS.NCL_ACCEPTED,
	assetConst.STATUS.SUBMITTED_TO_NCL,
	assetConst.STATUS.NCL_ACCEPTED_WITH_CHANGES,
	assetConst.STATUS.NCL_ACCEPTED_UPLOAD_FAILED,
	assetConst.STATUS.SUBMIT_TO_NCL_UPLOAD_FAILED,
	assetConst.STATUS.NCL_PROOF_UPLOAD_FAILED,
	assetConst.STATUS.PRINT_PROOF_UPLOAD_FAILED,
];

const PackagingAssetsTab = ({ eventKey }) => {
	const show = useContext(TabState).tab === eventKey;
	const assetQuery = useAssetQuery({ enabled: show });
	const assets = useMemo(() => assetQuery.data?.data.filter(
        ({ asset_category }) => asset_category === assetConst.CATEGORY.PACKAGING,
	), [assetQuery.data]);

	const isLoading = assetQuery.isLoading;

	const packagingDataFormat = useMemo(() => {
		const packagingChevronLink = ({ original }) => {
			return (
				<Link to={`/products/${original.product_id}/assets/${original.asset_id}`}>
					<FAIcon name="chevron-right" />
				</Link>
			);
		};
		return [
			{
				Header: 'Asset Type',
				id: 'asset_type',
				accessor: (d) => {
					let value = formatAssetType(d.asset_type);
					if (d.asset_type_other_name) {
						value = value + ' - ' + d.asset_type_other_name;
					}
					return value;
				},
			},
			{ Header: 'Title', accessor: 'game_name', width: 250 },
			{
				Header: 'Publisher',
				accessor: 'company_name',
			},
			{ Header: 'Game Code', accessor: 'game_code' },
			{
				Header: 'Date Modified',
				accessor: 'modified_date',
				sortMethod: (a, b) => {
					return new Date(a) > new Date(b) ? 1 : -1;
				},
				Cell: DateCell,
			},
			{
				Header: 'Status',
				accessor: 'review_status',
				width: 150,
				Cell: (cell) => <StatusText>{cell?.value || ''}</StatusText>,
			},
			{
				...linkColumn,
				Cell: packagingChevronLink,
			},
		];
	}, []);

	const filterProperties = useMemo(() => {
		return new Map([
			['Asset Type', {
				filter: 'asset_type',
				selectableFilters: new Set(
					[
						CODE_BOX,
						DRI_BUNDLE,
						LABEL,
						MANUAL,
						MANUAL_INTERIOR,
						OTHER,
						RETAIL_BUNDLE,
						TITLE_SHEET,
					].map((value) => formatAssetType(value)),
				),
			}],
			['Status', {
				filter: 'review_status',
				selectableFilters: new Set(
					statusOptions.map((value) => displayString(value)),
				),
			}],
		]);
	}, [assets]);

	return (
		<Page.ContentContainer>
			<SectionTitle>Packaging Assets</SectionTitle>
			{isLoading ? (
				<Loading />
			) : (
				<FilterableTable
					data={assets}
					dataFormat={packagingDataFormat}
					filterProperties={filterProperties}
					searchableFields={['game_name', 'game_code', 'company_name']}
					searchableFieldPlaceHolder="Search by Publisher, Title or Game Code"
					defaultSorted={[
						{
							id: 'modified_date',
							desc: true,
						},
					]}
					noBorder
					retainPageState
					stateOnTab={eventKey}
				/>
			)}
		</Page.ContentContainer>
	);
};

export default PackagingAssetsTab;
