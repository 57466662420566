import React, { Component } from 'react';
import trim from 'lodash/trim';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';

import ActionLink from '../../components/ActionLink/ActionLink';
import FAIcon from '../../components/FAIcon/FAIcon';
import Loading from '../../components/Loading/Loading';
import Page from '../../components/Page/Page';
import { orderConst } from '../../constants/orderConstants';
import { permConst } from '../../constants/permConst';
import {
	getOrder,
	putEditOrder,
	saveOrder
} from '../../services/ordersService';
import { isValidPhoneNumber } from '../../utils/stringUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { isAuthorized } from '../../utils/userUtils';
import { getSchemas } from '../../utils/orderUtils';
import DeleteOrderModal from './modals/DeleteOrderModal';
import { OrderRequirementsModal } from './modals/OrderRequirementsModal';
import OrderAndShipping from './OrderAndShipping';
import OrderConfirm from './OrderConfirm';
import ProductsSelection from './ProductsSelection';
import { connectQueryData, useCompanyAgreementsQuery } from '../../hooks/queryHooks';


const ComponentModes = {
	Loading: 'LOADING',
	Create: 'CREATE',
	Edit: 'EDIT'
};

function mapStateToProps(state) {
	return {
		userProfile: state.authReducer.userProfile
	};
}

export class OrderCreate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mode: ComponentModes.Loading,
			isSaving: false,
			isOrderLoading: false,
			showModal: false,
			activeView: 1,
			previousPublisherPOs: [],
			freightForwardersRequiringConsignees: [],
			selections: [],
			resources: {
				publisher_pos: []
			},
			isFormDirty: false,
			orderHeaderId: '',
			platform: 'default',
			publisherPO: '',
			orderComments: '',
			freightForwarder: '',
			japaneseContactName: '',
			japaneseEmail: '',
			japanesePhoneNumber: '',
			consigneeCompanyName: '',
			consigneeAddress: '',
			consigneeCity: '',
			consigneeState: '',
			consigneeZip: '',
			countryCode: '',
			serviceLevel: '',
			carrierAccountNumber: '',
			customsEntryPort: '',
			shipTo: '',
			destinationContact: '',
			destinationPhone: '',
			shippingInstructions: ''
		};

		this.nextClicked = this.nextClicked.bind(this);
		this.previousClicked = this.previousClicked.bind(this);
		this.saveClicked = this.saveClicked.bind(this);
		this.deleteClicked = this.deleteClicked.bind(this);
		this.populateFreightForwardersRequiringConsignee = this.populateFreightForwardersRequiringConsignee.bind(
			this
		);
		this.requiresConsignee = this.requiresConsignee.bind(this);
		this.isFormValid = this.isFormValid.bind(this);
		this.setActiveView = this.setActiveView.bind(this);
		this.initializeStateValue = this.initializeStateValue.bind(this);
		this.updateResources = this.updateResources.bind(this);
		this.formElementChanged = this.formElementChanged.bind(this);
		this.formSetDirty = this.formSetDirty.bind(this);
		this.onPlatformChanged = this.onPlatformChanged.bind(this);

		this.generateSavePayload = this.generateSavePayload.bind(this);
		this.generateUpdatePayload = this.generateUpdatePayload.bind(this);
		this.sendOrder = this.sendOrder.bind(this);
		this.getOrder = this.getOrder.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.validatePublisherPO = this.validatePublisherPO.bind(this);
	}

	formElementChanged(event) {
		this.setState({
			isFormDirty: true,
			[event.target.id]: event.target.value
		});
	}

	formSetDirty() {
		this.setState({
			isFormDirty: true
		});
	}

	onPlatformChanged(event) {
		this.formElementChanged(event);
		this.setState({
			isFormDirty: true,
			[event.target.id]: event.target.value,
			selections: []
		});
	}

	initializeStateValue(name, value) {
		this.setState({ [name]: value });
	}

	updateResources(resources) {
		this.setState({
			resources: resources
		});
	}

	setActiveView(activeView) {
		const { match } = this.props;
		if (
			activeView === 1 &&
			match.params &&
			match.params.orderHeaderId > 0
		) {
			this.getOrder(match.params.orderHeaderId);
		}
		this.setState({ activeView });
	}

	getActiveView() {
		const { mode, activeView } = this.state;
		const { history, companyAgreementsQuery } = this.props;

		if (mode === ComponentModes.Loading) {
			return null;
		}

		switch (activeView) {
			case 1:
				return (
					<OrderAndShipping
						isEditable={this.isEditable()}
						setActiveView={this.setActiveView}
						formElementChanged={this.formElementChanged}
						formSetDirty={this.formSetDirty}
						onPlatformChanged={this.onPlatformChanged}
						updateResources={this.updateResources}
						history={history}
						{...this.state}
						saveClicked={this.saveClicked}
						nextClicked={this.nextClicked}
						previousClicked={this.previousClicked}
						deleteClicked={this.deleteClicked}
						isFormValid={this.isFormValid}
						requiresConsignee={this.requiresConsignee}
						initializeStateValue={this.initializeStateValue}
						populateFreightForwardersRequiringConsignee={
							this.populateFreightForwardersRequiringConsignee
						}
						companyAgreements={companyAgreementsQuery}
					/>
				);
			case 2:
				return (
					<ProductsSelection
						isEditable={this.isEditable()}
						setActiveView={this.setActiveView}
						formElementChanged={this.formElementChanged}
						initializeStateValue={this.initializeStateValue}
						orderHeader={this.state}
						saveClicked={this.saveClicked}
						nextClicked={this.nextClicked}
						previousClicked={this.previousClicked}
						deleteClicked={this.deleteClicked}
						history={history}
					/>
				);
			case 3:
				return (
					<OrderConfirm
						isEditable={this.isEditable()}
						orderHeaderId={this.state.orderHeaderId}
						previousClicked={this.previousClicked}
						deleteClicked={this.deleteClicked}
						setActiveView={this.setActiveView}
						{...this.state}
						requiresConsignee={this.requiresConsignee}
						history={history}
					/>
				);
			default:
				return null;
		}
	}

	validateToSchema() {
		const [schema, consigneeSchema] = getSchemas();
		try {
			if (this.requiresConsignee(this.state.freightForwarder)) {
				consigneeSchema.validateSync(this.state, {abortEarly: false, validatePublisherPO: this.validatePublisherPO });
			} else {
				schema.validateSync(this.state, {abortEarly: false, validatePublisherPO: this.validatePublisherPO });
			}
		} catch (err) {
			if (err.inner) {
				return err.inner.map(e => ({name: e.path, reason: e.message}));
			}
			throw err;
		}
		return [];
	}

	isFormValid() {

		const {
			freightForwarder,
			publisherPO,
			destinationPhone,
			japanesePhoneNumber,
			platform
		} = this.state;
		const fields = [
			'publisherPO',
			'destinationContact',
			'destinationPhone'
		];

		const arrays = [
			'platform',
			'freightForwarder',
			'customsEntryPort',
			'shipTo'
		];

		const consigneeFields = [
			'japaneseContactName',
			'japaneseEmail',
			'japanesePhoneNumber',
			'consigneeCompanyName',
			'consigneeAddress',
			'consigneeCity',
			'consigneeState',
			'consigneeZip',
			'serviceLevel',
			'carrierAccountNumber'
		];

		// All fields are required.  Some freightforwarders require additional field validation.
		const fieldsToValidate = this.requiresConsignee(freightForwarder)
			? fields.concat(consigneeFields)
			: fields;

		// Filter for fields that have no value.
		const emptyFields = fieldsToValidate.filter((field) => {
			return trim(this.state[field]) === '';
		});

		const emptyArrays = arrays.filter((array) => {
			return this.state[array].length === 0;
		});

		const emptyRequiredFields = emptyFields.length || emptyArrays.length;
		const notPreviouslyUsedPublisherPO = this.validatePublisherPO(
			publisherPO
		);

		const validPhoneNumber = isValidPhoneNumber(destinationPhone);
		const validConsigneePhoneNumber = fieldsToValidate.includes(
			'japanesePhoneNumber'
		)
			? isValidPhoneNumber(japanesePhoneNumber)
			: true;

		const platformSelected = platform !== 'default';

		const flagged = this.validateToSchema();

		return [
			(field) => flagged.find(f => f.name === field),
			(
				!emptyRequiredFields &&
				notPreviouslyUsedPublisherPO &&
				validPhoneNumber &&
				validConsigneePhoneNumber &&
				platformSelected
			)
		];
	}

	requiresConsignee(freightForwarder) {
		const { freightForwardersRequiringConsignees } = this.state;

		return freightForwardersRequiringConsignees.includes(freightForwarder);
	}

	populateFreightForwardersRequiringConsignee(freightForwarders) {
		const freightForwardersRequiringConsignees = [];
		for (let i = 0; i < freightForwarders.length; i++) {
			if (freightForwarders[i].consignee_required) {
				freightForwardersRequiringConsignees.push(
					freightForwarders[i].value
				);
			}
		}
		this.setState({ freightForwardersRequiringConsignees });
	}

	nextClicked() {
		if (this.state.isFormDirty) {
			this.sendOrder(() => {
				// on success
				this.setActiveView(this.state.activeView + 1);
			});
		} else {
			this.setActiveView(this.state.activeView + 1);
		}
	}

	previousClicked() {
		this.setActiveView(this.state.activeView - 1);
	}

	validatePublisherPO(publisherPO) {
		const { previousPublisherPOs, initialPublisherPO } = this.state;
		const initPO =
			initialPublisherPO !== undefined
				? initialPublisherPO.toUpperCase()
				: null;
		const invalidPOs = previousPublisherPOs.filter((po) => {
			return po !== initPO;
		});
		return !invalidPOs.includes(publisherPO.toUpperCase());
	}

	saveClicked() {
		this.sendOrder();
	}

	deleteClicked() {
		this.toggleModal('deleteOrderModal');
	}

	isEditable() {
		const { userProfile } = this.props;

		return isAuthorized(userProfile.permissions, [
			permConst.ORDER.EDIT.COMPANY,
			permConst.ORDER.EDIT.ALL
		]);
	}

	sendOrder(onSuccess) {
		const { userProfile, history } = this.props;
		const { mode } = this.state;

		this.setState({ isSaving: true });

		let payload = null,
			promise = null,
			apiName = '';
		if (mode === ComponentModes.Create) {
			apiName = 'saveOrder';
			payload = this.generateSavePayload(userProfile.companyId);
			promise = saveOrder(payload);
		} else if (mode === ComponentModes.Edit) {
			apiName = 'putEditOrder';
			payload = this.generateUpdatePayload(userProfile.companyId);
			promise = putEditOrder(
				payload.order_header_id,
				payload
			);
		}
		promise
			.then((response) => {
				let orderHeaderId = null;
				let newState = {
					mode: ComponentModes.Edit,
					isFormDirty: false,
					isSaving: false
				};
				if (apiName === 'saveOrder' && response && response.data) {
					orderHeaderId = response.data;
				} else {
					orderHeaderId = this.state.orderHeaderId;
				}
				newState.orderHeaderId = orderHeaderId;
				this.setState(newState);
				history.replace(`/orders/${orderHeaderId}/create`);
				toasterNotify('Order successfully saved.', 'success');
				if (onSuccess) {
					onSuccess();
				}
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'saving order'),
					'error',
					error
				);
				this.setState({ isSaving: false });
			});
	}

	generateSavePayload(companyId) {
		const {
			platform,
			publisherPO,
			orderComments,
			freightForwarder,
			japaneseContactName,
			japaneseEmail,
			japanesePhoneNumber,
			consigneeCompanyName,
			consigneeAddress,
			consigneeCity,
			consigneeState,
			consigneeZip,
			countryCode,
			serviceLevel,
			carrierAccountNumber,
			customsEntryPort,
			shipTo,
			destinationContact,
			destinationPhone,
			shippingInstructions
		} = this.state;

		let payload = {
			ndid_company_id: companyId,
			platform_code: platform,
			publisher_po_number: publisherPO,
			order_comment: orderComments,
			freight_forwarder_code: freightForwarder,
			port_of_entry_code: customsEntryPort,
			packout_facility_id: shipTo,
			packout_facility_contact_name: destinationContact,
			packout_facility_contact_phone_number: destinationPhone,
			carrier_account_number: carrierAccountNumber,
			shipping_instructions: shippingInstructions,
			service_level: serviceLevel,
			country_code: countryCode
		};

		if (japaneseContactName) {
			const consignee_fields = {
				consignee_name: japaneseContactName,
				consignee_email: japaneseEmail,
				consignee_phone: japanesePhoneNumber,
				consignee_company_name: consigneeCompanyName,
				consignee_address: consigneeAddress,
				consignee_city: consigneeCity,
				consignee_state: consigneeState,
				consignee_zip: consigneeZip
			};

			payload = { ...payload, ...consignee_fields };
		}

		return payload;
	}

	generateUpdatePayload(companyId) {
		const {
			orderHeaderId,
			orderStatus,
			platform,
			publisherPO,
			orderComments,
			freightForwarder,
			japaneseContactName,
			japaneseEmail,
			japanesePhoneNumber,
			consigneeCompanyName,
			consigneeAddress,
			consigneeCity,
			consigneeState,
			consigneeZip,
			countryCode,
			serviceLevel,
			carrierAccountNumber,
			customsEntryPort,
			shipTo,
			destinationContact,
			destinationPhone,
			shippingInstructions
		} = this.state;

		let comments = [];
		if (orderComments) {
			comments.push({
				comment: orderComments,
				type: orderConst.COMMENT.PUBLISHER
			});
		}
		if (shippingInstructions) {
			comments.push({
				comment: shippingInstructions,
				type: orderConst.COMMENT.SHIPPING_INFO
			});
		}

		const payload = {
			order_header_id: orderHeaderId,
			ndid_company_id: companyId,
			order_status: orderStatus,
			platform_code: platform,
			publisher_po_number: publisherPO,
			order_comments: comments,
			freight_forwarder_code: freightForwarder,
			port_of_entry_code: customsEntryPort,
			packout_facility_id: `${shipTo}`,
			packout_facility_contact_name: destinationContact,
			packout_facility_contact_phone_number: destinationPhone
		};

		if (japaneseContactName) {
			payload.consignee_info = {
				consignee_carrier_account_number: carrierAccountNumber,
				consignee_company_name: consigneeCompanyName,
				consignee_contact_name: japaneseContactName,
				consignee_contact_email: japaneseEmail,
				consignee_contact_phone_number: japanesePhoneNumber,
				consignee_service_level: serviceLevel,
				consignee_address_street_line_1: consigneeAddress,
				consignee_address_street_line_2: null,
				consignee_address_street_line_3: null,
				consignee_address_city: consigneeCity,
				consignee_address_region: consigneeState,
				consignee_address_postal_code: consigneeZip,
				consignee_address_country_code: countryCode,
				consignee_company_id: this.props.userProfile.companyId
			};
		}

		return payload;
	}

	getOrder(orderHeaderId) {
		// isOrderLoading
		this.setState({ isOrderLoading: true });

		getOrder(orderHeaderId)
			.then((response) => {
				if (response && response.data) {
					let orderComments = '',
						shippingInstructions = '';

					response.data.order_comments.forEach((c) => {
						if (c.type === orderConst.COMMENT.PUBLISHER) {
							orderComments = c.comment;
						} else if (
							c.type === orderConst.COMMENT.SHIPPING_INFO
						) {
							shippingInstructions = c.comment;
						}
					});

					const selections = response.data.order_details.map(
						(detail) => {
							let version = null;
							if (detail.product_release_version && detail.submission_id && detail.submission_version) {
								version = {
									release_version: detail.product_release_version,
									submission_id: detail.submission_id,
									submission_version:	detail.submission_version
								};
								if ('card_size' in detail) {
									version['card_size'] = detail.card_size;
								}
							}
							return {
								id: detail.lineNumber,
								productId: detail.product_id,
								noaPartNumber: detail.noa_part_number,
								publisherPartNumber:
									detail.publisher_part_number,
								status: detail.status,
								quantity: detail.quantity,
								unitPrice: detail.unit_price,
								shipByDate: detail.requested_ship_date,
								gameName: detail.product_name,
								gameCode: detail.game_code,
								version: version
							};
						}
					);

					this.setState({
						mode: ComponentModes.Edit,
						orderHeaderId: response.data.order_header_id,
						orderStatus: response.data.order_status,
						isEdiOrder: response.data.edi_order_flag === 1,
						platform: response.data.platform_code || '',
						publisherPO: response.data.publisher_po_number || '',
						initialPublisherPO:
							response.data.publisher_po_number || '',
						orderComments: orderComments,
						freightForwarder:
							response.data.freight_forwarder_code || '',
						japaneseContactName:
							response.data.consignee_contact_name || '',
						japaneseEmail:
							response.data.consignee_contact_email || '',
						consigneeCompanyName:
							response.data.consignee_company_name || '',
						japanesePhoneNumber:
							response.data.consignee_contact_phone_number || '',
						consigneeAddress:
							response.data.consignee_address_street_line_1 || '',
						consigneeCity:
							response.data.consignee_address_city || '',
						consigneeState:
							response.data.consignee_address_region || '',
						consigneeZip:
							response.data.consignee_address_postal_code || '',
						countryCode:
							response.data.consignee_address_country_code || '',
						serviceLevel:
							response.data.consignee_service_level || '',
						carrierAccountNumber:
							response.data.consignee_carrier_account_number ||
							'',
						customsEntryPort:
							response.data.port_of_entry_code || '',
						shipTo: response.data.packout_facility_id || '',
						destinationContact:
							response.data.packout_facility_contact_name || '',
						destinationPhone:
							response.data
								.packout_facility_contact_phone_number || '',
						shippingInstructions: shippingInstructions,
						selections: selections,
						isOrderLoading: false
					});
				}
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'loading order'),
					'error',
					error
				);
				this.setState({ isOrderLoading: false });
			});
	}

	componentDidMount() {
		const { match } = this.props;
		// Use edit mode if we have an orderHeaderId
		if (match.params && match.params.orderHeaderId > 0) {
			this.getOrder(match.params.orderHeaderId);
		} else {
			this.setState({ mode: ComponentModes.Create });
		}
	}

	toggleModal(key) {
		let type = null;
		if (!this.state.showModal) {
			type = key;
		}
		this.setState({
			showModal: !this.state.showModal,
			modalType: type
		});
	}

	renderModal() {
		const { userProfile, history } = this.props;
		const { orderHeaderId } = this.state;

		switch (this.state.modalType) {
			case 'deleteOrderModal':
				return (
					<DeleteOrderModal
						userProfile={userProfile}
						history={history}
						orderHeaderId={orderHeaderId}
						closeModal={this.toggleModal}
					/>
				);
			case 'orderRequirementsModal':
				return <OrderRequirementsModal show={this.state.showModal} closeModal={this.toggleModal} />;
			default:
				return '';
		}
	}

	renderAlert() {
		return <>
			<Alert variant="warning" className="alert-icon">
				Please check that all requirements are met before ordering.{' '}
				<ActionLink
					onClick={(e) => {
						this.toggleModal('orderRequirementsModal');
					}}
				>
					View Requirements <FAIcon name="chevron-right" size="xs" />
				</ActionLink>
			</Alert>
		</>;
	}

	render() {
		const { isOrderLoading } = this.state;

		return (
			<Page>
				{isOrderLoading ? (
					<Loading />
				) : (
					<>
						{this.renderAlert()}
						{this.getActiveView()}
						{this.state.showModal && this.state.modalType
							? this.renderModal()
							: null}
					</>
				)}
			</Page>
		);
	}
}

export default connectQueryData(
	connect(mapStateToProps)(OrderCreate), 
	useCompanyAgreementsQuery, 
	'companyAgreementsQuery',
	true,
);
