import React from 'react';
import { Alert } from 'react-bootstrap';

import BaseModal from '../../../components/BaseModal/BaseModal';
import { orderConst } from '../../../constants/orderConstants';
import { approveOrderHeader, putEditOrder } from '../../../services/ordersService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';

export default class OrderReviewModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			display: true,
			isLoading: false,
			rejectionComment: ''
		};

		this.returnModalType = this.returnModalType.bind(this);
		this.toggleLoading = this.toggleLoading.bind(this);
		this.updateOrder = this.updateOrder.bind(this);
		this.generatePayload = this.generatePayload.bind(this);
		this.returnNewStatus = this.returnNewStatus.bind(this);
		this.formElementChanged = this.formElementChanged.bind(this);
		this.updateOrApprove = this.updateOrApprove.bind(this);
		this.determineButtonDisabled = this.determineButtonDisabled.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	formElementChanged(e) {
		this.setState({
			rejectionComment: e.target.value
		});
	}

	determineButtonDisabled() {
		const modalSettings = this.returnModalType();
		if (this.props.modalType === 'reject') {
			return modalSettings.validateRejectComment(
				this.state.rejectionComment
			);
		} else {
			return this.state.isLoading;
		}
	}

	returnNewStatus() {
		if (this.props.modalType === 'approve') {
			return this.props.orderData.order_status ===
				orderConst.STATUS.SUBMITTED_FOR_REVIEW
				? orderConst.STATUS.SUBMITTED_FOR_APPROVAL
				: orderConst.STATUS.ORDER_APPROVED;
		} else if (this.props.modalType === 'cancel') {
			return orderConst.STATUS.CANCELLED;
		} else {
			return orderConst.STATUS.REJECTED;
		}
	}

	returnModalType() {
		if (this.props.modalType === 'approve') {
			return {
				headerText: 'Approve Order',
				alertText: 'Approve Order?',
				alertVariant: 'info',
				buttonText: 'Confirm Approval',
				submitVariant: 'success',
				showCommentBox: false
			};
		} else if (this.props.modalType === 'cancel') {
			return {
				headerText: 'Cancel Order',
				alertText: 'Are you sure you want to Cancel this Order?',
				alertVariant: 'danger',
				buttonText: 'Confirm Cancellation',
				submitVariant: 'danger',
				showCommentBox: false
			};
		} else {
			return {
				headerText: 'Reject Order',
				alertText: 'Reject Order?',
				alertVariant: 'danger',
				buttonText: 'Confirm Rejection',
				submitVariant: 'danger',
				showCommentBox: true,

				// TODO: determine if a min string length is required and if so, add to consts

				validateRejectComment: (x) =>
					x.trim().length <= orderConst.rejectCommentLength ||
					x.trim().length < 1
			};
		}
	}

	populateFreightForwardersRequiringConsignee(freightForwarders) {
		const freightForwardersRequiringConsignees = [];

		for (let i = 0; i < freightForwarders.length; i++) {
			if (freightForwarders[i].consignee_required) {
				freightForwardersRequiringConsignees.push(
					freightForwarders[i].value
				);
			}
		}

		this.setState({ freightForwardersRequiringConsignees });
	}

	updateOrApprove(additionalComments = []) {
		this.toggleLoading();
		const payload = this.generatePayload(additionalComments);
		if (payload.order_status === orderConst.STATUS.ORDER_APPROVED) {
			this.approveOrder(payload.order_header_id);
		} else {
			this.updateOrder(additionalComments);
		}
	}

	approveOrder(orderHeaderId) {
		approveOrderHeader(orderHeaderId)
			.then((response) => {
				if (response.data.error_msg === '') {
					toasterNotify('Order Approved', 'success');
					this.props.getOrder();
					this.props.getCreateOrderPageResources();
				}
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'approving the order'),
					'error',
					error
				);
			})
			.finally(() => {
				this.props.toggleModal();
			});
	}

	updateOrder(additionalComments = []) {
		const { order_header_id } = this.state;
		const payload = this.generatePayload(additionalComments);
		putEditOrder(order_header_id, payload)
			.then((response) => {
				toasterNotify('Order updated', 'success');
				this.props.getOrder();
				this.props.getCreateOrderPageResources();
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'updating order'),
					'error',
					error
				);
			})
			.finally(() => {
				this.props.toggleModal();
			});
	}

	generatePayload(additionalComments = []) {
		const basePayload = {
			order_status: this.returnNewStatus(),
			platform_code: this.state.platform_code,
			publisher_po_number: this.state.publisher_po_number,
			ndid_company_id: this.state.ndid_company_id,
			order_header_id: this.state.order_header_id,
			order_comments: [
				{
					type: orderConst.COMMENT.REJECTION,
					comment: !this.state.rejectionComment
						? ''
						: this.state.rejectionComment
				}
			],
			freight_forwarder_code: this.state.freight_forwarder_code,
			port_of_entry_code: this.state.port_of_entry_code,
			packout_facility_id: this.state.packout_facility_id,
			packout_facility_contact_name: this.state
				.packout_facility_contact_name,
			packout_facility_contact_phone_number: this.state
				.packout_facility_contact_phone_number,
			country_code: !this.state.countryCode
				? 'US'
				: this.state.countryCode
		};

		if (this.props.requiresConsignee(this.state.freight_forwarder_code)) {
			basePayload['consignee_info'] = this.getPayloadConsignee();
		}

		if (additionalComments.length > 0) {
			basePayload['order_comments'] = basePayload[
				'order_comments'
			].concat(additionalComments);
		}

		return basePayload;
	}

	getPayloadConsignee() {
		const { userProfile } = this.props;

		const {
			japaneseContactName,
			japaneseEmail,
			consigneeCompanyName,
			consigneeAddress,
			japanesePhoneNumber,

			consigneeCity,
			consigneeState,
			consigneeZip,
			consigneeCountryCode,
			serviceLevel,
			carrierAccountNumber
		} = this.state;

		return {
			consignee_contact_name: japaneseContactName,
			consignee_contact_email: japaneseEmail,
			consignee_contact_phone_number: japanesePhoneNumber,
			consignee_company_name: consigneeCompanyName,
			consignee_service_level: serviceLevel,
			consignee_carrier_account_number: carrierAccountNumber,
			consignee_address_city: consigneeCity,
			consignee_address_region: consigneeState,
			consignee_company_id: userProfile.companyId,
			consignee_address_postal_code: consigneeZip,
			consignee_address_country_code: consigneeCountryCode,
			consignee_address_street_line_1: consigneeAddress
		};
	}

	componentDidMount() {
		this.setState({
			...this.props.orderData,

			rejectionComment: this.props.getCommentByType(
				orderConst.COMMENT.REJECTION
			)
		});
	}

	render() {
		const modalSettings = this.returnModalType();

		return (
			<BaseModal
				show={this.state.display}
				onCancel={this.props.toggleModal}
				isSubmitting={this.state.isLoading}
			>
				<BaseModal.Title>{modalSettings.headerText}</BaseModal.Title>
				<Alert variant={modalSettings.alertVariant}>{modalSettings.alertText}</Alert>

				{modalSettings.showCommentBox && (
					<div>
						<strong>Comments To Publisher</strong>

						<textarea
							id="rejectionComment"
							className="form-control"
							rows="5"
							onChange={this.formElementChanged}
							maxLength={2000}
						/>
					</div>
				)}

				<BaseModal.Submit
					variant={modalSettings.submitVariant}
					disabled={this.determineButtonDisabled()}
					onClick={this.updateOrApprove}
				>
					{modalSettings.buttonText}
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}
