import React, { ReactElement, useContext, useEffect, useRef, useState, VFC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ReactTable, { ResizedChangeFunction, SortingRule, TableProps } from 'react-table';

import { useUiStates } from '../../hooks/reduxHooks';
import eventBus from '../../utils/eventBus';
import { TabState } from '../StatefulTabs/StatefulTabs';
import { getLocation } from '../../utils/routeUtils';
import PaginationView from './views/PaginationView';
import NoData from './views/NoDataView';

import './react-table.css';
import './BaseTable.css';


const DEFAULT_PAGE_SIZE = 25;

export const actionsColumn = {
	Header: '',
	id: 'actions',
	width: 53,
	sortable: false,
	resizable: false,
	style: { overflow: 'visible' },
};

export const linkColumn = {
	Header: '',
	id: 'view-link',
	width: 40,
	sortable: false,
	resizable: false,
};

const serializedState = (
	initialState: string,
	{ page, rows, sort }: { page?: number; rows?: number; sort?: SortingRule[] },
	options: { defaultPageSize?: number } = {},
) => {
	const searchParams = new URLSearchParams(initialState);
	searchParams.delete('sort');
	if (page && page !== 0) {
		page && searchParams.set('page', String(page + 1));
	} else {
		searchParams.delete('page');
	}
	if (rows && rows !== (options.defaultPageSize || DEFAULT_PAGE_SIZE)) {
		searchParams.set('rows', String(rows));
	} else {
		searchParams.delete('rows');
	}
	if (sort && sort.length !== 0) {
		const sortValue = serializeSort(sort);
		sortValue && searchParams.set('sort', sortValue);
	}
	return searchParams.toString();
};

const unserializeSort = (string: string | null | undefined): SortingRule[] | undefined =>
	string ? [{ id: string.replace(/^desc-/, ''), desc: string.substring(0,5) === 'desc-' }] : undefined;

const serializeSort = (sort?: SortingRule[]): string | undefined =>
	sort ? `${sort[0].desc ? 'desc-' : ''}${sort[0].id}` : undefined;

interface BaseTableProps extends Partial<TableProps> {
	retainPageState?: boolean,
	stateOnTab?: string | false,
	allowOverflow?: boolean,
}
const BaseTable: VFC<BaseTableProps> = (props: BaseTableProps): ReactElement => {
	const {
		data,
		retainPageState,
		stateOnTab,
		defaultPageSize = DEFAULT_PAGE_SIZE,
		defaultSorted,
		allowOverflow,
	} = props;
	const uiStates = useUiStates(!!stateOnTab);
	const storedUiStates = uiStates.get();
	const currentTab = useContext(TabState)?.tab;
	const retainStateActive = (retainPageState && currentTab === stateOnTab) || (retainPageState && !stateOnTab);
	const history = useHistory();
	const location = useLocation();
	const initialSearch: URLSearchParams | null | undefined = retainPageState ? new URLSearchParams(location.search) : null;

	const [initialized, setInitialized] = useState<boolean>(false);

	// page numbers start at 0 at the data level but appears as 1 onscreen.
	const [page, setPage] = useState<number | undefined>();
	const [rows, setRows] = useState<number | undefined>();
	const [sort, setSort] = useState<SortingRule[] | undefined>();
	const [resized, setResized] = useState<any[]>([]);
	const container = useRef<HTMLDivElement>(null);

	const maxPageIndex = Math.floor((data?.length || 0) / (rows || defaultPageSize));

	// component should use these values for render so that we can override state values for the current render
	let currentPage: number | undefined = page,
		currentRows: number | undefined = rows,
		currentSort: SortingRule[] | undefined = sort;

	const initialize = () => {
		setInitialized(true);
		if (initialized) { return; }
		if (retainPageState) {
			if (location.search) {
				// use query string for values
				currentPage = (Number(initialSearch?.get('page')) - 1);
				currentRows = Number(initialSearch?.get('rows')) || defaultPageSize;
				currentSort = unserializeSort(initialSearch?.get('sort')) || undefined;
			} else {
				// use storedUIStates
				currentRows = Number(storedUiStates.rows) || defaultPageSize;
				currentSort = unserializeSort(storedUiStates.sort) || undefined;
			}
			onPageChange(currentPage || 0);
			setRows(currentRows);
			setSort(currentSort);
			!stateOnTab && updateSearch({ page: currentPage, rows: currentRows, sort: currentSort});
		}
	};

	const onResizedChange: ResizedChangeFunction = (newResized, event) => {
		// ignore resizes when mouse leaves the table area
		if (
			container.current &&
			event.pageX >
				container.current?.getBoundingClientRect()?.x +
					container.current?.offsetWidth
		) {
			return;
		}
		// min column width capped at 60
		const controlledResized = newResized.map((column: any) => ({
			...column,
			value: Math.max(column.value, 60),
		}));
		setResized(controlledResized);
	};

	const updateSearch = (change : { page?: number, rows?: number, sort?: SortingRule[] }) => {
		if (retainPageState) {
			const newValues = { page, rows, sort, ...change };
			const newHistory = serializedState(
				getLocation().search,
				newValues,
				{ defaultPageSize: props.defaultPageSize },
			);

			if (location.search !== newHistory) {
				history.replace(location.pathname + '?' + newHistory);
			}
		}
	};

	const onPageChange = (newPage: number) => {
		newPage = Math.max(newPage, 0);
		setPage(newPage);
		retainPageState && updateSearch({ page: newPage });
	};

	const onRowLimitChange = (newRowLimit: number) => {
		setRows(newRowLimit);

		if (retainPageState) {
			if (newRowLimit === DEFAULT_PAGE_SIZE) {
				uiStates.push('rows', null);
			} else {
				uiStates.push('rows', String(newRowLimit));
			}
			updateSearch({ rows: newRowLimit });
		}
	};

	const onSortedChange = (newSorted: SortingRule[]) => {
		setSort(newSorted);

		if (retainPageState) {
			uiStates.push( 'sort', serializeSort([...newSorted]));
			updateSearch({ sort: newSorted });
		}
	};

	const changedExternalPageState = () => {
		onPageChange(0);
	};

	useEffect(() => {
		const ref = React.createRef();
		eventBus.on(eventBus.FILTER_CHANGE, () => changedExternalPageState(), ref);
		return () => eventBus.off(ref);
	}, []);

	// auto-change page number if current page exceeds the last page
	useEffect(() => {
		if (data && data.length > 0 && rows && Math.ceil(data.length / rows) <= (page || 0)) {
			onPageChange(Math.ceil(data.length / rows) - 1);
		}
	}, [data, page, rows]);

	// update search string when things change
	useEffect(() => {
		if (!retainPageState) {
			return;
		}
		const currentSearch = new URLSearchParams(location.search);
		const searchPage = currentSearch.get('page');
		const searchRows = currentSearch.get('rows');
		const searchSort = currentSearch.get('sort');
		if (
			currentTab === stateOnTab &&
			((searchPage && searchPage !== String(page)) ||
				(searchRows && searchRows !== String(rows)) ||
				(searchSort && searchSort !== serializeSort(sort)))
		) {
			searchPage && setPage(Number(searchPage) - 1);
			searchRows && setRows(Number(searchRows));
			const newSort: SortingRule[] | undefined = unserializeSort(searchSort);
			newSort && setSort(newSort);
		}
	}, [retainPageState, location.search]);

	useEffect(() => {
		if (retainPageState && !retainStateActive) {
			// reset the state to blank. Rely on StatefulTabs repopulating the
			// query string after a tab change.
			setInitialized(false);
			setRows(undefined);
			setPage(undefined);
			setSort(undefined);
		} else if (!initialized) {
			initialize();
		}
	}, [retainStateActive]);

	// last minute check on page index
	if (currentPage && currentPage > maxPageIndex) {
		currentPage = maxPageIndex;
		setPage(maxPageIndex);	
		if (retainStateActive) {
			updateSearch({
				page: maxPageIndex,
			});
		}
	}

	return (
		<div
			className={`BaseTable ${
				allowOverflow ? 'BaseTable--allow-overflow' : 'Page__fill-space'
			}`}
			ref={container}
		>
			<ReactTable
				{...props}
				minRows={0}
				noDataText={'No items to display'}
				NoDataComponent={NoData}
				resized={resized}
				page={currentPage}
				pageSize={props.showPagination !== false ? (currentRows || defaultPageSize || DEFAULT_PAGE_SIZE) : props.data?.length}
				sorted={currentSort?.length ? currentSort : defaultSorted}
				onResizedChange={onResizedChange}
				onPageChange={onPageChange}
				onPageSizeChange={onRowLimitChange}
				onSortedChange={onSortedChange}
				showPagination={props.showPagination !== false && !!props.data?.length}
				PaginationComponent={PaginationView}
			/>
		</div>
	);
};

export default BaseTable;
