import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import FAIcon from '../../components/FAIcon/FAIcon';
import Page from '../../components/Page/Page';

const NotFound = () => {
	const history = useHistory();
	return (
		<Page>
			<div className="d-block m-auto">
				<Card>
					<Card.Body className="d-flex flex-row align-items-center">
						<div>
							<FAIcon className="text-danger m-5" name="ban" size='5x' />
						</div>
						<div className="mr-5">
							<h3>Ahhh!</h3>
							<h3>The content you are looking for could not be found.</h3>
						</div>
					</Card.Body>
					<Card.Body className="border-top text-right">
						<Button variant="outline-secondary" onClick={(e) => history.go(-1)}>
							Go Back
						</Button>
					</Card.Body>
				</Card>
			</div>
		</Page>
	);
};

export default NotFound;
