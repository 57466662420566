import React, { useEffect, useState } from 'react';

import AsperaFileUpload from '../../../components/AsperaFileUpload/AsperaFileUpload';
import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import { allowedFileExtensions, fileTransferConst } from '../../../constants/fileTransferConstants';
import { useUserProfile } from '../../../hooks/reduxHooks';
import { postROMVersionFileUpload } from '../../../services/fileUploadsService';


const ROMUploadModal = ({ show, onClose, onUploaded }) => {
	const userProfile = useUserProfile();
	const [formValues, setFormValues] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [transferSpec, setTransferSpec] = useState(null);
	const [isFileSelected, setIsFileSelected] = useState(false);
	const [fileTransferId, setFileTransferId] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState();
	const [showValidationError, setShowValidationError] = useState(false);

	const submitUpload = async () => {
		if (!isFileSelected) {
			setShowValidationError(true);
			return;
		}
		try {
			setIsSubmitting(true);
			const response = await postROMVersionFileUpload(selectedFiles[0], formValues.comments);
			setFileTransferId(response.data.id);
			setTransferSpec(
				response.data.transfer_specs && { transfer_specs: response.data.transfer_specs },
			);
		} catch (error) {
			setIsSubmitting(false);
		}
	};

	const onComplete = () => {
		onClose();
		onUploaded();
		setIsSubmitting(false);
		setTransferSpec(null);
	};

	useEffect(() => {
		if (show) {
			setFormValues({});
			setIsSubmitting(false);
			setIsFileSelected(false);
			setTransferSpec(null);
			setFileTransferId(false);
			setSelectedFiles(null);
			setShowValidationError(false);
		}
	}, [show]);

	return (
		<BaseModal show={show} onCancel={onClose} isSubmitting={isSubmitting}>
			<BaseModal.Title>Upload NCL ROM Versions File</BaseModal.Title>
			<Forms values={formValues} onChange={(values) => setFormValues(values)}>
				<Forms.CustomArea>
					<Forms.Heading>Select File</Forms.Heading>
					<AsperaFileUpload
						updateFilesSelected={(status) => {
							setIsFileSelected(status);
							setShowValidationError(false);
						}}
						userProfile={userProfile}
						entityType={fileTransferConst.ENTITY_TYPE.ROM_VERSION_FILE}
						entityId={fileTransferId}
						isUploadInitiated={!!show && !!transferSpec && !!fileTransferId}
						onCompleteFunction={() => onComplete()}
						allowedFileTypes={[
							{
								filter_name: 'Accepted Files',
								extensions: allowedFileExtensions.PLAIN_TEXT,
							},
						]}
						validateFileType={(files) => setSelectedFiles(files)}
						prefetchedTransferSpecs={transferSpec}
                        singleFileOnly
					/>

					{showValidationError && (
						<Forms.Help>
							<span className="text-danger">
								A .TXT file must be selected to continue
							</span>
						</Forms.Help>
					)}
				</Forms.CustomArea>
				<Forms.TextArea rows={4} id="comments">
					<Forms.Heading>Comments</Forms.Heading>
					<Forms.Help>Optional</Forms.Help>
				</Forms.TextArea>
			</Forms>
			<BaseModal.Submit onClick={() => submitUpload()}>Submit</BaseModal.Submit>
		</BaseModal>
	);
};

export default ROMUploadModal;
