import { permConst, accessTypeConst } from '../../constants/permConst';
import { assetConst } from '../../constants/assetConstants';
import { isAuthorized } from '../../utils/userUtils';
import { safeEval } from '../../utils/dataUtils';

const { MANAGER, COORDINATOR, LEAD, SUPPORT } = accessTypeConst;
const { FILES_UPLOADING, PENDING_REVIEW } = assetConst.STATUS;

export const getPermissions = (userProfile, accessLevel, productData) => {
	const isCoordinatorAffiliated = safeEval(() =>
		productData.coordinators.includes(userProfile.userId),
	);

	return {
		canViewAsset:
			(accessLevel === MANAGER &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.PRODUCT.ASSET.VIEW.MANAGER,
				])) ||
			(accessLevel === COORDINATOR &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.PRODUCT.ASSET.VIEW.COORDINATOR,
				]) &&
				isCoordinatorAffiliated) ||
			(accessLevel === LEAD &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.PRODUCT.ASSET.VIEW.COMPANY,
				])) ||
			(accessLevel === SUPPORT &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.PRODUCT.ASSET.VIEW.COMPANY_SUPPORT,
				])),
		canAddAssets:
			(accessLevel === MANAGER &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.PRODUCT.ASSET.ADD.MANAGER,
				])) ||
			(accessLevel === COORDINATOR &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.PRODUCT.ASSET.ADD.COORDINATOR,
				]) &&
				isCoordinatorAffiliated),
		canEditRequiredAssets:
			(accessLevel === MANAGER &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.PRODUCT.ASSET.EDIT.MANAGER,
				])) ||
			(accessLevel === COORDINATOR &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.PRODUCT.ASSET.EDIT.COORDINATOR,
				]) &&
				isCoordinatorAffiliated),
		canEditActiveOnRequiredAssets:
			accessLevel === MANAGER &&
			isAuthorized(userProfile.permissions, [
				permConst.MARKETING_EVENT.PRODUCT.ASSET.ACTIVATION.EDIT.MANAGER,
			]),
		canDeleteRequiredAssets:
			(accessLevel === MANAGER &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.PRODUCT.ASSET.DELETE.MANAGER,
				])) ||
			(accessLevel === COORDINATOR &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.PRODUCT.ASSET.DELETE.COORDINATOR,
				]) &&
				isCoordinatorAffiliated),
		canUploadAssets:
			(accessLevel === LEAD &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.PRODUCT.ASSET.UPLOAD.COMPANY,
				])) ||
			(accessLevel === SUPPORT &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.PRODUCT.ASSET.UPLOAD.COMPANY_SUPPORT,
				])),
	};
};

export const getAttributesForAsset = (asset) => {
	const uploadable =
		asset.review_status !== FILES_UPLOADING && asset.review_status !== PENDING_REVIEW;
	const noFilesUploaded = !asset.initial_files || asset.initial_files.length === 0;
	const showUploadAsEdit = uploadable && !noFilesUploaded;

	return { uploadable, showUploadAsEdit };
};
