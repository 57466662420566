import React from 'react';
import dayjs from 'dayjs';
import { Alert, Col, FormCheck, FormGroup, FormLabel, Row } from 'react-bootstrap';

import BaseModal from '../../../components/BaseModal/BaseModal';
import { platformCodes } from '../../../constants/platformConstants';
import { sendProjectionsReminder } from '../../../services/projectionsService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';


export default class ProjectionsNotificationsModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			companyList: [],
			selectAll: true
		};

		this.toggleLoading = this.toggleLoading.bind(this);
		this.sendNotifications = this.sendNotifications.bind(this);
		this.generatePayload = this.generatePayload.bind(this);
	}

	extractDates() {
		const { notificationData } = this.props;
		let projectionRange = {};
		let fromPDB = notificationData.projection_ranges.filter((r) => {
			return (
				r.platform_code === platformCodes['Nintendo Switch'] ||
				r.platform_code === platformCodes['Nintendo 3DS']
			);
		});
		if (!fromPDB.length) {
			// If we do not receive projection range info from PDB, calculate it here.
			projectionRange.start_year_month = dayjs()
				.add(1, 'month')
				.format();
			projectionRange.end_year_month = dayjs()
				.add(6, 'month')
				.format();
		} else {
			projectionRange = fromPDB[0];
		}
		return projectionRange;
	}

	generatePayload(companyList) {
		let payload = { 'company_id_list': companyList};
		return payload;
	}

	toggleSelectAll() {
		this.setState({
			selectAll: !this.state.selectAll,
			companyList: this.state.companyList.map((c) => {
				return { ...c, checked: !this.state.selectAll };
			})
		});
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	toggleCompanyToNotifyList(company) {
		const { ndid_company_id } = company;
		const checked = !company.checked;
		let updatedList = this.state.companyList.map((c) =>
			c.ndid_company_id === ndid_company_id ? { ...c, checked } : c
		);
		let allChecked = updatedList.filter((c) => {
			return c.checked === true;
		}).length;
		this.setState({
			companyList: updatedList,
			selectAll: allChecked === updatedList.length
		});
	}

	sendNotifications() {
		const { companyList } = this.state;
		this.toggleLoading();
		let allCompanies = companyList
			.filter((c) => {
				return c.checked === true;
			})
			.map((c) => c.ndid_company_id);
		const payload = this.generatePayload(allCompanies);
		sendProjectionsReminder(payload)
			.then((response) => {
				toasterNotify('Notifications sent', 'success');
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'sending notification'),
					'error',
					error
				);
			})
			.finally(() => {
				this.props.toggleParentLoading();
				this.props.pageInit();
				this.props.toggleModal();
			});
	}

	renderSelectAllCheckBox() {
		const { selectAll } = this.state;
		return (
			<FormCheck
				id='all-checkbox'
				type="checkbox"
				checked={selectAll}
				onChange={() => this.toggleSelectAll()}
				label='All'
			/>
		);
	}

	renderCompanyCheckBoxes(company) {
		return (
			<li key={company.ndid_company_id}>
				<FormCheck
					type="checkbox"
					id={'checkbox-' + company.name.replace(/\s/g, '-')}
					checked={company.checked}
					onChange={() => this.toggleCompanyToNotifyList(company)}
					label={company.name}
				/>
			</li>
		);
	}

	componentDidMount() {
		const { companyList } = this.props;
		let notificationList = [];
		companyList.forEach((c) => {
			if (c.projections_received === false) {
				c['checked'] = true;
				notificationList.push(c);
			}
		});
		this.setState({
			companyList: notificationList
		});
	}

	render() {
		const { isLoading, companyList } = this.state;
		return (
			<BaseModal show={true} onCancel={this.props.toggleModal} isSubmitting={isLoading}>
				<BaseModal.Title>Notify Publishers</BaseModal.Title>
				<Alert variant="info">
					Send notification reminders to the publishers listed
					below to complete their projections.
				</Alert>
				<FormGroup as={Row}>
					<FormLabel column sm={3} className="text-sm-right">
						<strong>Publishers</strong>
						<br />
						{this.renderSelectAllCheckBox()}

					</FormLabel>
					<Col
						sm={9}
					>
						<div className="pre-scrollable mt-2 notify-company-scrollbox">
							<ul
								className="notify-company-list p-3 mb-3"
							>
								{companyList.map((company) => {
									return this.renderCompanyCheckBoxes(
										company
									);
								})}
							</ul>
						</div>
					</Col>
				</FormGroup>
				<BaseModal.Submit
					disabled={isLoading}
					onClick={this.sendNotifications}
				>
					Send
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}
