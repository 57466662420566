import { AnySchema } from 'yup';

/**
 * Validates if a string is a valid product code. Returns a upper-cased
 * formatted code if valid, false if not.
 * @param {string} code - Product code to be validated.
 */
export const validateProductCode = (code: string) => {
	if (typeof code === 'string' && code.length <= 13 && /(^$|^[A-Za-z0-9-]+$)/.test(code)) {
		return code.toUpperCase();
	}
	return false;
};

/**
 * Validates if a string is a valid email address. Returns true if valid, false if blank or 
 * undefined
 * @param {string} email - Email addresss to be validated.
 */
export const validateEmailAddress = (email?: string) => 
	// https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
	email
		? /^[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*(\+[0-9a-zA-Z]+)?@(?:\w+[.-])+\w{2,10}$/
			.test(email)
		: false;

/**
 * Validates if a string is a valid phone number. Returns true if valid, false if blank or 
 * undefined
 * @param {string} phone - phone number to be validated.
 */
export const validatePhoneNumber = (phone?: string) => {
	if (!phone) return false;

	return (
		/^\+?\d[\d-]*\d$/.test(phone) &&
		phone.split('').filter((char) => /\d/.test(char)).length >= 6 && // min 6 digits
		phone.split('').filter((char) => /\d/.test(char)).length < 16 // max 15 digits
	);
};

/**
 * Validates if a hash and its values conform with a given Yup schema. 
 * @param {object} schema - Yup schema object
 * @param {object} values - Values hash
 * @param {object} context - object with context values
 */
export const validateToSchema = (
	schema: AnySchema,
	values?: Record<string, any> | null,
	context?: Record<string, any>,
): Record<string, string> => {
	if (values) {
		try {
			schema.validateSync(schema.cast(values, context ? { context } : undefined), {
				abortEarly: false,
				context,
			});
		} catch (err: unknown) {
			if (!!err && typeof err === 'object' && 'inner' in err && Array.isArray(err.inner)) {
				return err.inner.reduce(
					(r: Record<string, any>, e: { path: string; message: string }) =>
						(r[e.path] = e.message) && r,
					{},
				) || {};
			}
			throw err;
		}
	}
	return {};
};
