import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import * as yup from 'yup';

import Forms from '../../../components/Forms/Forms';
import BaseModal from '../../../components/BaseModal/BaseModal';
import { validateToSchema } from '../../../utils/validationUtils';
import { isEmptyObject } from '../../../utils/dataUtils';
import { toasterNotify, createMessageForError } from '../../../utils/toaster';
import {
	CustomFieldDefinitionPut,
	CustomFieldPost,
	getCustomFieldRoles,
	postCustomFieldDefinition,
	putCustomFieldDefinition,
}
	from '../../../services/customFieldsService';
import { CustomFieldSubmit, FIELD_TYPES } from '../views/CustomFieldIndexTab';

interface FormValues {
    name?: string;
    active?: boolean;
    read_only?: boolean;
    type?: string;
    roles?: string[];
}

export const baseSchema = yup.object({
	name: yup.string().default('').trim().min(1, 'Custom Field must have a name'),
	type: yup.string().default('').required('Custom Field must have type'),
	active: yup.boolean().default(false),
	read_only: yup.boolean().default(false),
	roles: yup.array().nullable().default([]),
});

const CustomFieldModal = ({
	show,
	onClose,
	onCompletion,
	entityName,
	excludeUserId,
	customField,
}: {
    show: boolean;
    onClose: () => void;
    onCompletion: () => void;
    entityName: string;
	excludeUserId: number;
	customField?: CustomFieldSubmit;
}) => {
	const [formValues, setFormValues] = useState<FormValues>({});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);
	const validationErrors = validateToSchema(baseSchema, formValues);

	const rolesQuery = useQuery('getCustomFieldRoles', () => getCustomFieldRoles());
	const roles: Record<string, any>[] = rolesQuery.data?.data.filter(
		(item : Record<string, any>) => item.id !== excludeUserId) || [];
	useEffect(() => {
		if (show) {
			setIsSubmitting(false);
			setShowAllErrors(false);
			if (customField) {
				setFormValues(customField);
			} else {
				setFormValues({});
			}
		}
	}, [show]);

	const submitForm = async () => {
		if (isSubmitting) {
			return;
		}

		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}

		setIsSubmitting(true);
		const castedFormValues = baseSchema.cast(formValues, {stripUnknown: true});

		const role_ids = castedFormValues.roles?.map(idString => parseInt(idString)) || [];
		const field_type: Record<string, any> = FIELD_TYPES.get(castedFormValues.type);
		const filtered_field_type = Object.fromEntries(
			Object.entries(field_type).filter(item => item[0] !== 'name'));

		try {
			if (customField) {
				const updatePayload : CustomFieldDefinitionPut = {
					'name': castedFormValues.name,
					'active': castedFormValues.active,
					'read_only': castedFormValues.read_only,
					'roles': role_ids
				};
				await putCustomFieldDefinition(customField.id, updatePayload);
			} else {
				const customFieldPayload: CustomFieldPost = {
					name: castedFormValues.name,
					entity_name: entityName,
					active: castedFormValues.active,
					read_only: castedFormValues.read_only,
					type: filtered_field_type,
					roles: role_ids
				};
				await postCustomFieldDefinition(customFieldPayload);
			}
			onCompletion();
			onClose();
			toasterNotify(
                `Custom field "${castedFormValues.name}" has been successfully 
                	${customField? 'updated' : 'added'}.`,
                'success'
			);
		} catch (error: any) {
			toasterNotify(
                createMessageForError(error, 'updating custom fields'),
				'error',
				error
			);
		} finally {
			setIsSubmitting(false);
		}
		setShowAllErrors(false);
	};

	return (
        <BaseModal show={show}
				   onCancel={onClose}
				   isLoading={rolesQuery.isLoading}
				   isSubmitting={isSubmitting}
		>
            <BaseModal.Title>{customField? 'Edit Custom Field' : 'Add Custom Field'}</BaseModal.Title>
            <Forms
                values={formValues}
                onChange={(v) =>
					setFormValues({
						...v
					})
				}
                validationErrors={validationErrors}
                showAllErrors={showAllErrors}
            >

                <Forms.Text id="name" maxLength={100}>
                    <Forms.Heading>Name</Forms.Heading>
                </Forms.Text>

                <Forms.Select id="type" disabled={!!customField}>
                    <Forms.Heading>Type</Forms.Heading>
                    {[...FIELD_TYPES.keys()].map((key: string) => (
                        <Forms.Option key={FIELD_TYPES.get(key).id} value={FIELD_TYPES.get(key).id}>
                            {FIELD_TYPES.get(key).name}
                        </Forms.Option>
                    ))}
                </Forms.Select>

                <Forms.SingleCheckbox id="active">
                    <Forms.Heading>Active</Forms.Heading>
                </Forms.SingleCheckbox>

                <Forms.SingleCheckbox id="read_only">
                    <Forms.Heading>Read Only</Forms.Heading>
                </Forms.SingleCheckbox>

                <Forms.CheckboxList id="roles">
                    <Forms.Heading>Role(s)</Forms.Heading>
                    {roles?.map((role: Record<string, any>) => (
                        <Forms.Option key={role.id} value={String(role.id)}>
                            {role.name}
                        </Forms.Option>
                    ))}
                </Forms.CheckboxList>

            </Forms>
			<BaseModal.Submit onClick={() => submitForm()}>
				{customField? 'Update' : 'Add'}
			</BaseModal.Submit>
        </BaseModal>
	);
};

export default CustomFieldModal;
