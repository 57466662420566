import React from 'react';

import MeatballDropdown from '../../../components/MeatballDropdown/MeatballDropdown';


const OrderCreateMenu = ({ onSave, onDelete, showSave }) => (
	<MeatballDropdown>
		{showSave && (
			<>
				<MeatballDropdown.Item onClick={() => onSave()}>
					Save Draft Order
				</MeatballDropdown.Item>
				<MeatballDropdown.Divider />
			</>
		)}
		<MeatballDropdown.Item onClick={() => onDelete()}>
			<span className='text-danger'>Delete Draft Order</span>
		</MeatballDropdown.Item>
	</MeatballDropdown>
);
export default OrderCreateMenu;
