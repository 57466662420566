import React from 'react';
import { log } from '../../services/baseService';
import FAIcon from '../FAIcon/FAIcon';

class ErrorCatcher extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		log({
			type: 'EXCEPTION',
			...(errorInfo),
			error: error?.stack,
			correlationId: error?.correlationId,
			client: {
				userAgent: global?.navigator?.userAgent,
				localStorage: global?.localStorage?.getItem('noaPDB')
			}
		});
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className="d-flex pt-5 justify-content-center">
                    <div className="card">
                        <div className="d-flex flex-row align-items-center card-body">
							<div className="d-flex flex-row align-items-center">
								<FAIcon className="text-danger m-5" name="exclamation-triangle" size='5x' />
								<div className="mr-5">
									<h3>Oh no!</h3>
									<h3>There was an error that broke the app.</h3>
								</div>
							</div>
                        </div>
                        <div className="card-body border-top text-right">
                            <a className="btn btn-outline-secondary" href="/login">
                                Return to home
                            </a>
                        </div>
                    </div>
				</div>
			);
		}
		return this.props.children;
	}
}

export default ErrorCatcher;
