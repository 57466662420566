import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { actionsColumn } from '../../../components/BaseTable/BaseTable';
import FAIcon from '../../../components/FAIcon/FAIcon';
import FilterableTable from '../../../components/FilterableTable/FilterableTable';
import LinkMenuItem from '../../../components/LinkMenuItem/LinkMenuItem';
import Loading from '../../../components/Loading/Loading';
import MeatballDropdown from '../../../components/MeatballDropdown/MeatballDropdown';
import { eventConstants } from '../../../constants/eventConstants';
import { useUserProfile } from '../../../hooks/reduxHooks';
import { getEventReportProducts, getEventSystemFamilies } from '../../../services/eventsService';
import { findEventStatus } from '../../../utils/eventUtils';
import { pullAllResults } from '../../../utils/serviceUtils';
import { toasterNotify } from '../../../utils/toaster';
import { getPermissions } from './ProductsTab.helpers';


const getTableStructure = ({ platforms, canViewInternal }) => {
	const statusCell = ({ value }) => {
		switch (value) {
			case eventConstants.STATUS.PLANNED:
				return (
					<strong className="text-info">
						{eventConstants.STATUS_NAMES[value].toUpperCase()}
					</strong>
				);
			case eventConstants.STATUS.LIVE:
				return (
					<strong className="text-success">
						{eventConstants.STATUS_NAMES[value].toUpperCase()}
					</strong>
				);
			case eventConstants.STATUS.COMPLETED:
				return (
					<strong className="text-muted">
						{eventConstants.STATUS_NAMES[value].toUpperCase()}
					</strong>
				);
			case eventConstants.STATUS.DRAFT:
				return <strong>{eventConstants.STATUS_NAMES[value].toUpperCase()}</strong>;
			default:
				return null;
		}
	};

	const platformCell = ({ original, value }) => {
		const platformName =
			platforms && platforms.find((x) => x.platform_code === value).platform_name;
		return platformName ? platformName : null;
	};

	const productActiveCell = ({ value }) =>
		value && <FAIcon className="text-success" name="check" />;

	const tableConfiguration = [
		{ Header: 'Start Date', accessor: 'start_datetime', show: false },  // for sorting purposes
		{ Header: 'Product Name', accessor: 'game_name' },
		{ Header: 'Event', accessor: 'event_name' },
		{ Header: 'Game Code', accessor: 'game_code' },
		{ Header: 'Publisher', accessor: 'company_name', show: canViewInternal },
		{ Header: 'Platform', accessor: 'platform_code', Cell: platformCell },
		{ Header: 'Support Level', accessor: 'support_level', show: canViewInternal },
		{ Header: 'Event Status', accessor: 'status', Cell: statusCell },
		{
			Header: 'Active',
			accessor: 'product_active',
			width: 80,
			Cell: productActiveCell,
			show: canViewInternal,
		},
	];

	const actionsCell = ({ original }) => (
		<MeatballDropdown
			id={`menu-${original.id}`}
		>
			<LinkMenuItem href={`/products/${original.product_id}`}>
				View Product Summary
			</LinkMenuItem>
			<LinkMenuItem
				href={`/marketing-events/products/${original.marketing_event_product_id}`}
			>
				View Product + Event Info
			</LinkMenuItem>
		</MeatballDropdown>
	);

	tableConfiguration.push({
		...actionsColumn,
		Cell: actionsCell,
	});

	return tableConfiguration;
};

const getFilterProperties = (platforms, isInternal) => {
	if (!platforms) return null;
	const filterProperties = new Map();

	filterProperties.set('Platform', {
		filter: 'platform_code',
		selectableFilters: new Set(platforms.map((platform) => platform.system_name)),
		customEvaluation: (value, filters) => {
			var gamePlatform = platforms.find(platform => platform.platform_code === value);
			return filters.has(gamePlatform && gamePlatform.system_name);
		}
	});

	isInternal && filterProperties.set('Active', {
		filter: 'product_active',
		selectableFilters: new Set(['Active', 'Inactive']),
		customEvaluation: (value, filters) => {
			if (filters.has('Inactive') && !value) {
				return true;
			}
			if (filters.has('Active') && value) {
				return true;
			}
			return false;
		}
	});
	const eventStatuses = isInternal
		? Object.values(eventConstants.STATUS_NAMES)
		: Object.values(eventConstants.STATUS_NAMES).filter((status) => status !== 'Draft');
	filterProperties.set('Event Status', {
		filter: 'status',
		selectedFilters: new Set(eventStatuses.filter((status) => status !== 'Completed')),
		selectableFilters: new Set(eventStatuses),
		customEvaluation: (value, filters) => {
			return filters.has(eventConstants.STATUS_NAMES[value]);
		},
	});
	return filterProperties;
};

const ProductsTab = ({ show }) => {
	const userProfile = useUserProfile();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(true);
	const [products, setProducts] = useState(null);
	const [eventSystemFamilies, setEventSystemFamilies] = useState(null);

	// permissions
	const permissions = useMemo(() => getPermissions(userProfile.permissions));
	const { canViewInternal } = permissions;

	const platforms = useSelector((store) => store.referenceReducer?.platforms.content);

	// load display data
	useEffect(() => {
		if (!products && show) {
			loadData();
		}
	}, [products, show]);

	const loadData = async () => {
		try {
			await Promise.all([
				loadProducts(),
				loadEventSystemFamilies()
			]);
		} finally {
			setIsLoading(false);
		}

	};
	const loadProducts = async () => {
		const rowLimit = 500;
		try {
			const results = await pullAllResults(getEventReportProducts, rowLimit);
			setProducts(results.map((event) => {
				return { ...event, status: findEventStatus(event) };
			}));
		} catch(err) {
			setProducts([]);
			if (!err.isAxiosError) {
				toasterNotify('There was an unexpected error that prevented page data from ' +
					'loading. Please contact site support if this error persists.', 'error');
			}
			throw err;
		}
	};
	const loadEventSystemFamilies = async () => {
		try {
			const response = await getEventSystemFamilies();
			setEventSystemFamilies(response.data);
		} catch(err) {
			if (!err.isAxiosError) {
				toasterNotify('There was an unexpected error that affected table filters. ' +
				'Please contact site support if this error persists.', 'error');
			}
			throw err;
		}
	};


	const filterProperties = useMemo(
		() => {
			if (!platforms || !eventSystemFamilies) {
				return null;
			}
			const eventPlatforms = platforms.filter(
				(platform) => eventSystemFamilies.includes(platform.system_family)
			);
			return getFilterProperties(eventPlatforms, canViewInternal);
		},
		[platforms, eventSystemFamilies, canViewInternal]
	);
	const dataFormat = useMemo(() => getTableStructure({ platforms, canViewInternal, history }));

	return (
		<>
			{isLoading ? (
				<Loading />
			) : (
				<FilterableTable
					data={products}
					dataFormat={dataFormat}
					defaultSorted={[
						{
							id: 'status',
							desc: false
						},
						{
							id: 'start_datetime',
							desc: false
						},
						{
							id: 'product_active',
							desc: true
						},
						{
							id: 'support_level',
							desc: false
						},
						{
							id: 'game_name',
							desc: false
						}
					]}
					filterProperties={filterProperties}
					searchableFields={['event_name', 'game_name', ...(canViewInternal ? ['company_name'] : [])]}
					searchableFieldPlaceHolder={canViewInternal ? 'Search by Event, Product Name or Publisher...' : 'Search by Event or Product Name...'}
				/>
			)}
		</>
	);
};

export default ProductsTab;
