import React, { ReactElement, useEffect, useState, VFC } from 'react';
import * as yup from 'yup';

import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import PropertyDisplay from '../../../components/PropertyDisplay/PropertyDisplay';
import { digitalCodesConstants } from '../../../constants/digitalCodesConstants';
import { postWSPRequestAction, postDPRequestAction } from '../../../services/digitalCodesService';
import { isEmptyObject } from '../../../utils/dataUtils';
import { getCurrentRequest } from '../../../utils/digitalCodesUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { validateToSchema } from '../../../utils/validationUtils';

const { APPROVED } = digitalCodesConstants.priceRequestStatuses;

const schema = yup.object().shape({
	comment: yup.string().trim().required('A reason must be supplied to continue')
});

interface ExtendWSPLimitModalProps {
	show?: boolean;
	onClose?: () => void;
	onChange?: () => void;
	component?: ProductComponentData;
	priceRequests?: (WholesalePriceRequestData | DeclaredPriceRequestData)[];
	rightsHolderId?: string;
}
const ExtendLimitModal: VFC<ExtendWSPLimitModalProps> = ({
	show,
	onClose,
	onChange,
	component,
	priceRequests,
	rightsHolderId,
}: ExtendWSPLimitModalProps): ReactElement => {
	const [formValues, setFormValues] = useState<Record<string, any>>({});
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);

	const currentRequest = getCurrentRequest(priceRequests);
	const requestType = currentRequest && (('wholesale_price_request_id' in currentRequest && 'Wholesale Price') || ('declared_price_request_id' in currentRequest && 'Declared Price'));

	useEffect(() => {
		if (show) {
			setShowAllErrors(false);
			setFormValues({});
		}
	}, [show]);

	const validationErrors = validateToSchema(schema, formValues);

	return (
		<BaseModal
			show={show || false}
			onCancel={onClose && (() => onClose())}
			isSubmitting={isSubmitting}
		>
			<BaseModal.Title>Allow For New {requestType} Agreement</BaseModal.Title>
			<PropertyDisplay noStripes label="NSUID">
				{component?.ns_uid}
			</PropertyDisplay>
			<PropertyDisplay noStripes label="Digital Item">
				{component?.game_name}
			</PropertyDisplay>
			<PropertyDisplay noStripes label="Number of Approved Requests">
				{priceRequests &&
					(priceRequests.filter(
						(request) =>
							request.ndid_company_id === rightsHolderId &&
							request.request_status === APPROVED,
					)?.length ||
						'0')}
			</PropertyDisplay>

			<Forms
				values={formValues || {}}
				onChange={(newFormValues) => setFormValues(newFormValues)}
				validationErrors={validationErrors}
				showAllErrors={showAllErrors}
				vertical
			>
				<Forms.TextArea id="comment" rows={4}>
					<Forms.Heading>Reason</Forms.Heading>
				</Forms.TextArea>
			</Forms>

			<BaseModal.Submit
				onClick={async () => {
					if (isSubmitting) {
						return;
					}
					if (!isEmptyObject(validationErrors)) {
						setShowAllErrors(true);
						return;
					}

					setIsSubmitting(true);
					try {
						const extendRequest = 'declared_price_request_id' in currentRequest! ? getCurrentRequest(priceRequests, rightsHolderId) : getCurrentRequest(priceRequests);
						if (extendRequest && 'wholesale_price_request_id' in extendRequest) {
							await postWSPRequestAction(extendRequest.wholesale_price_request_id, {
								action: digitalCodesConstants.priceRequestActions
									.EXTENDED_LIMIT,
								comment: formValues.comment,
							});
							toasterNotify(
								`The limit on new wholesale price requests was extended for "${component?.game_name}"`,
								'success',
							);
							onChange && onChange();
						} else if (extendRequest && 'declared_price_request_id' in extendRequest) {
							await postDPRequestAction(extendRequest.declared_price_request_id, {
								action: digitalCodesConstants.priceRequestActions
									.EXTENDED_LIMIT,
								comment: formValues.comment,
							});
							toasterNotify(
								`The limit on new declared price requests was extended for "${component?.game_name}"`,
								'success',
							);
							onChange && onChange();
						}
					} catch (error) {
						toasterNotify(
							createMessageForError(
								error as Error,
								`extending limits on ${requestType} requests`,
							),
							'error',
							error as Error,
						);
						setIsSubmitting(false);
					} finally {
						onClose && onClose();
					}
				}}
			>
				Confirm
			</BaseModal.Submit>
		</BaseModal>
	);
};

export default ExtendLimitModal;
