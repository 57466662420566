import React, { Component, Fragment } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import CommentBox from '../../components/Comment/CommentBox';
import FAIcon from '../../components/FAIcon/FAIcon';
import FileLink from '../../components/FileLink/FileLink';
import UploadNewAssetModal from '../../components/modals/UploadNewAssetModal/UploadNewAssetModal';
import Page from '../../components/Page/Page';
import Panel from '../../components/Panel/Panel';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import Title from '../../components/Title/Title';
import VirtualViewer from '../../components/VirtualViewer/VirtualViewer';
import { assetConst } from '../../constants/assetConstants';
import { accessTypeConst, permConst } from '../../constants/permConst';
import { transferRightsConstants } from '../../constants/transferRightsConstants';
import { getEventProduct, postGeneratePdbAccessToken } from '../../services/eventsService';
import { getAssetDetails, postAssetStatus } from '../../services/productsService';
import { classForAssetStatus, formatAssetType } from '../../utils/assetUtils';
import { companyHasRight } from '../../utils/companyUtils';
import { makeKey } from '../../utils/componentUtils';
import { safeEval } from '../../utils/dataUtils';
import { compareDates, dateFormat, formatDate, parseDateString } from '../../utils/dateUtils';
import { formatLocale } from '../../utils/localeUtils';
import { cancelToast, createMessageForError, toasterNotify } from '../../utils/toaster';
import { determineActiveRightTransfer, transferDomains } from '../../utils/transferRightsUtils';
import { isFeatureActiveForPlatform, isAuthorized } from '../../utils/userUtils';
import NotFound from '../NotFound/NotFound';
import AcceptForgoFinalLabelApprovalModal from './modals/AcceptForgoFinalLabelApprovalModal';
import ApproveAssetModal from './modals/ApproveAssetModal';
import ForgoFinalLabelApprovalModal from './modals/ForgoFinalLabelApprovalModal';
import NCLApproveModal from './modals/NCLApproveModal';
import RejectAssetModal from './modals/RejectAssetModal';
import UploadFinalLabelFilesModal from './modals/UploadFinalLabelFilesModal';
import UploadPrintProofModal from './modals/UploadPrintProofModal';
import FilesUploadedPanel from './panels/FilesUploadedPanel';

import './AssetDetails.css';

const { MANAGER, COORDINATOR, LEAD, SUPPORT } = accessTypeConst;
const {
	APPROVED,
	APPROVED_WITH_CHANGES,
	FILES_UPLOADING,
	FINAL_LABEL_REJECTED,
	FINAL_LABEL_UPLOADED,
	FINAL_LABEL_UPLOADING,
	NCL_ACCEPTED,
	NCL_ACCEPTED_WITH_CHANGES,
	NCL_PROOF_UPLOAD_FAILED,
	NCL_PROOF_UPLOADING,
	PENDING_PROOF_ACCEPT,
	PENDING_REVIEW,
	PRINT_PROOF_PENDING,
	PRINT_PROOF_REJECTED,
	PRINT_PROOF_UPLOADING,
	REJECTED,
	REVIEWED,
	REVIEWED_WITH_CHANGES,
	SUBMITTED_TO_NCL,
	SUBMIT_TO_NCL_FILE_UPLOADING,
	SUBMIT_TO_NCL_UPLOAD_FAILED,
} = assetConst.STATUS;
const {
	ACCEPT_FORGO,
	REQUEST_FORGO,
	FORGO_NOT_REQUESTED,
} = assetConst.FINAL_LABEL_FORGO_STATUS;

const VIEW = 'view';
const COMMENT = 'comment';

function SidebarItem(props) {
	return (
		<div className="mb-2">
			<strong>{props.title}</strong>
			<br />
			{props.children}
		</div>
	);
}

function Status(props) {
	const { status } = props;
	return status && <span className={`status-mini ${status.class}`}>{status.text}</span>;
}

function mapStateToProps(state) {
	return {
		userProfile: state.authReducer.userProfile,
	};
}

function sortRevisions(a, b) {
	let result;
	if (a.submitted_date && b.submitted_date) {
		result = compareDates(a.submitted_date, b.submitted_date);
		if (result === -1) return 1;
		if (result === 1) return -1;
	}

	return 0;
}

function extractMostRecentAnnotation(revisions) {
	let annotation = null;
	for (let i of revisions) {
		if (i.file_upload_type === assetConst.UPLOAD.ANNOTATION) {
			annotation = i.files[0];
			break;
		}
	}
	return annotation;
}

function generateName(name = null, company) {
	let result = '';

	if (company) {
		result = '[' + company + ']';
	}

	if (name) {
		result = name + ' ' + result;
	}

	return result;
}

function generateAsset(asset) {
	if (asset) {
		asset.languages = asset.languages && asset.languages.length > 0 ? asset.languages : [];

		asset.files =
			asset.initial_files && asset.initial_files.length > 0 ? asset.initial_files : [];

		asset.final_label_files =
			asset.final_label_files && asset.final_label_files.length > 0
				? asset.final_label_files
				: [];

		asset.proof_files =
			asset.proof_files && asset.proof_files.length > 0 ? asset.proof_files : [];

		asset.thumbnail = false;

		asset.submitted_by = generateName(asset.submitted_by_name, asset.submitted_by_company);
		asset.submitted_date = formatDate(parseDateString(asset.submitted_date), dateFormat.DATE);

		asset.modified_by = generateName(asset.modified_by_name, asset.modified_by_company);
		asset.modified_date = formatDate(parseDateString(asset.modified_date), dateFormat.DATE);
	}

	return asset;
}

function generateRevisions(revisions) {
	if (revisions && revisions.length > 0) {
		revisions = revisions.map((revision) => {
			revision.submitted_by = generateName(
				revision.submitted_by_name,
				revision.submitted_by_company,
			);
			return revision;
		});

		revisions.sort(sortRevisions);
	}
	return revisions;
}

export class AssetDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAssetLoaded: false,
			hasViewPermission: false,
			hasViewFilePermission: false,
			productId: null,
			product: null,
			assetId: null,
			asset: null,
			revisions: [],
			showViewer: false,
			viewerDocumentId: null,
			mostRecentAnnotation: null,
			eventAssetId: null,
			marketingEventProduct: null,
			marketingEventAccessType: null,
		};

		this.toggleViewer = this.toggleViewer.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.loadAssetDetails = this.loadAssetDetails.bind(this);
		this.postComment = this.postComment.bind(this);
		this.onSuccessfulFileClick = this.onSuccessfulFileClick.bind(this);
		this.hasUploadRetailRight = this.hasUploadRetailRight.bind(this);
		this.hasUploadDigitalRight = this.hasUploadDigitalRight.bind(this);
	}

	toggleViewer(documentId) {
		this.setState({
			viewerDocumentId: documentId,
			showViewer: !this.state.showViewer,
		});
	}

	toggleModal(e, transferId = null, loadResources = false) {
		let type;

		if (this.state.showModal === true) {
			type = null;
		} else {
			type = e.target.id;
		}
		this.setState(
			{
				showModal: !this.state.showModal,
				modalType: type,
				selectedTransferId: transferId,
			},
			() => {
				if (loadResources) {
					this.loadResources();
				}
			},
		);
	}

	hasUploadRetailRight() {
		const { userProfile } = this.props;
		const { product } = this.state;

		return companyHasRight(
			userProfile.companyId,
			product.distribution,
			transferRightsConstants.DISTRIBUTION_TYPE.RETAIL,
		);
	}

	hasUploadDigitalRight() {
		const { userProfile } = this.props;
		const { product } = this.state;

		return companyHasRight(
			userProfile.companyId,
			product.distribution,
			transferRightsConstants.DISTRIBUTION_TYPE.DIGITAL,
		);
	}

	checkActiveRightTransfer() {
		const { product, asset } = this.state;
		if (product === undefined || asset === undefined) {
			return false;
		}
		const assetCategory = asset?.asset_category;

		if (assetCategory === 'PACKAGING') {
			return determineActiveRightTransfer(product, transferDomains.ASSET_PACKAGING);
		} else if (assetCategory === 'MARKETING') {
			return determineActiveRightTransfer(product, transferDomains.ASSET_MARKETING);
		} else if (assetCategory === 'CODE_IN_BOX') {
			return determineActiveRightTransfer(product, transferDomains.CODE_IN_BOX);
		}
		return true;
	}

	checkPlatformHasFeatureEnabled() {
		const { userProfile } = this.props;
		const { asset, product } = this.state;

		const assetCategory = asset && asset.asset_category;
		if (assetCategory === 'PACKAGING') {
			return isFeatureActiveForPlatform(userProfile, 'packaging_assets', product.platform_code);
		} else if (assetCategory === 'MARKETING') {
			return isFeatureActiveForPlatform(userProfile, 'marketing_assets', product.platform_code);
		}
		return true;
	}

	determinePermissions() {
		const { userProfile } = this.props;
		const { marketingEventProduct, asset } = this.state;
		const assetCategory = asset && asset.asset_category;
		let result = {
			hasViewPermission: false,
			hasViewFilePermission: false,
			hasAnnotateFilePermission: false,
		};

		const hasViewPermission =
			(marketingEventProduct &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.PRODUCT.ASSET.VIEW,
				])) ||
			isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.ASSET.MARKETING.VIEW.ALL,
				permConst.PRODUCT.ASSET.MARKETING.VIEW.COMPANY,
				permConst.PRODUCT.ASSET.PACKAGING.VIEW.ALL,
				permConst.PRODUCT.ASSET.PACKAGING.VIEW.COMPANY,
				permConst.PRODUCT.ASSET.CODEINBOX.VIEW.ALL,
				permConst.PRODUCT.ASSET.CODEINBOX.VIEW.COMPANY,
			]);
		if (hasViewPermission) {
			result.hasViewPermission = hasViewPermission;
		}

		result.hasAnnotateFilePermission =
			(assetCategory === assetConst.CATEGORY.MARKETING &&
				isAuthorized(userProfile.permissions, [
					permConst.PRODUCT.ASSET.MARKETING.APPROVE.ALL,
				])) ||
			(assetCategory === assetConst.CATEGORY.PACKAGING &&
				isAuthorized(userProfile.permissions, [
					permConst.PRODUCT.ASSET.PACKAGING.APPROVE.ALL,
				])) ||
			(assetCategory === assetConst.CATEGORY.CODE_IN_BOX &&
				isAuthorized(userProfile.permissions, [
					permConst.PRODUCT.ASSET.CODEINBOX.APPROVE.ALL,
				])) ||
			(assetCategory === assetConst.CATEGORY.MARKETING_EVENT &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.PRODUCT.ASSET.REVIEW,
				]));

		result.hasViewFilePermission =
			(marketingEventProduct &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.PRODUCT.ASSET.VIEW,
				])) ||
			isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.ASSET.MARKETING.APPROVE.ALL,
				permConst.PRODUCT.ASSET.PACKAGING.APPROVE.ALL,
				permConst.PRODUCT.ASSET.CODEINBOX.APPROVE.ALL,
				permConst.PRODUCT.ASSET.LABEL.APPROVE.COMPANY,
				permConst.PRODUCT.ASSET.MARKETING.ADD.COMPANY,
				permConst.PRODUCT.ASSET.PACKAGING.ADD.COMPANY,
				permConst.PRODUCT.ASSET.CODEINBOX.ADD.COMPANY,
			]);
		return result;
	}

	canAddComment() {
		if (this.checkActiveRightTransfer() || !this.checkPlatformHasFeatureEnabled()) {
			return false;
		}
		const { asset } = this.state;
		if (asset == null) {
			return false;
		}
		const { userProfile } = this.props;
		const assetCategory = safeEval(() => asset.asset_category);

		if (assetCategory === assetConst.CATEGORY.PACKAGING) {
			return (
				isAuthorized(userProfile.permissions, [
					permConst.PRODUCT.ASSET.PACKAGING.COMMENT.ADD.ALL,
				]
				) || (
					isAuthorized(userProfile.permissions, [
						permConst.PRODUCT.ASSET.PACKAGING.COMMENT.ADD.COMPANY,
					]) && this.hasUploadRetailRight())
			);
		} else if (assetCategory === assetConst.CATEGORY.CODE_IN_BOX) {
			return (
				isAuthorized(userProfile.permissions, [
					permConst.PRODUCT.ASSET.CODEINBOX.COMMENT.ADD.ALL
				]
				) || (
					isAuthorized(userProfile.permissions, [
						permConst.PRODUCT.ASSET.CODEINBOX.COMMENT.ADD.COMPANY
					]) && this.hasUploadDigitalRight())
			);
		} else if (assetCategory === assetConst.CATEGORY.MARKETING) {
			return isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.ASSET.MARKETING.COMMENT.ADD.ALL,
				permConst.PRODUCT.ASSET.MARKETING.COMMENT.ADD.COMPANY,
			]);
		} else if (assetCategory === assetConst.CATEGORY.MARKETING_EVENT) {
			const { marketingEventAccessType } = this.state;
			if (
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.PRODUCT.ASSET.REVIEW.MANAGER,
					permConst.MARKETING_EVENT.PRODUCT.ASSET.UPLOAD.MANAGER,
				]) && marketingEventAccessType === MANAGER
			) {
				return true;
			}
			if (
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.PRODUCT.ASSET.REVIEW.COORDINATOR,
					permConst.MARKETING_EVENT.PRODUCT.ASSET.UPLOAD.COORDINATOR,
				]) && marketingEventAccessType === COORDINATOR
			) {
				return true;
			}
			if (
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.PRODUCT.ASSET.UPLOAD.COMPANY,
				]) && marketingEventAccessType === LEAD
			) {
				return true;
			}
			if (
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.PRODUCT.ASSET.UPLOAD.COMPANY_SUPPORT,
				]) && marketingEventAccessType === SUPPORT
			) {
				return true;
			}
		} else {
			return false;
		}
	}

	async getMarketingEventToken(operation) {
		const { marketingEventProduct } = this.state;
		if (!marketingEventProduct) {
			return null;
		}
		const response = await postGeneratePdbAccessToken(
			marketingEventProduct.asset.id,
			operation,
		);
		return response.headers['x-pdb-authorization'];
	}

	async loadEventAssetDetails(eventProductId, eventAssetId) {
		try {
			const [response, tokenResponse] = await Promise.all([
				getEventProduct(eventProductId),
				postGeneratePdbAccessToken(eventAssetId, 'view'),
			]);
			const token = tokenResponse.headers['x-pdb-authorization'];

			const pdbProductId = response.data.product_id;
			const pdbAsset = response.data.assets.find(
				(asset) => asset.id === parseInt(eventAssetId),
			);
			if (!pdbAsset) {
				throw new Error('Marketing event asset not found');
			}

			this.setState({
				eventAssetId,
				marketingEventProduct: {
					...response.data,
					asset: pdbAsset,
				},
				marketingEventAccessType: response.headers['x-pdb-access-type'],
				product: response.data.product
			});

			this.initAssetDetails(pdbProductId, pdbAsset.product_asset_id, token);
		} catch (error) {
			// this triggers error condition that renders The Luigi
			this.setState({ isAssetLoaded: true, asset: false });
			if (error.response.status === 404) {
				cancelToast(error);
			}
		}
	}

	async loadAssetDetails(productId, assetId, skipSetAsset, token = null) {
		if (!skipSetAsset) {
			this.setState({
				isAssetLoaded: false,
			});
		}
		const { marketingEventProduct } = this.state;

		try {
			// if token is blank and this is a marketing event, generate a token first
			if (marketingEventProduct) {
				token = token || (await this.getMarketingEventToken(VIEW));
			}
			const response = await getAssetDetails(assetId, token);
			if (!skipSetAsset) {
				this.setState({
					asset: {
						...(marketingEventProduct ? {id: marketingEventProduct.asset.id} : null),
						...(generateAsset(response.data.product_asset)),
					},
					product: response.data.product
				});
			}
			this.setRevisions(response.data.product_asset_revisions);
		} catch (error) {
			toasterNotify(createMessageForError(error, 'loading asset data'), 'error', error);
		} finally {
			if (!skipSetAsset) {
				this.setState({
					isAssetLoaded: true,
				});
			}
		}
	}

	determineVideo(file) {
		const videoTypes = ['mp4', 'mov', 'flv', 'wmv', 'avi'];
		return videoTypes.includes(file.file_name.split('.').pop().toLowerCase());
	}

	setRevisions(productAssetRevisions) {
		const revisions = generateRevisions(productAssetRevisions);
		this.setState({
			revisions: revisions,
			mostRecentAnnotation: extractMostRecentAnnotation(revisions),
		});
	}

	async postComment(commentBox) {
		const { assetId, productId } = this.state;
		const commentText = commentBox.getCommentText();
		if (commentText.trim().length === 0) {
			commentBox.toggleCommentBox();
			return;
		}
		const payload = {
			comment: commentText,
		};
		try {
			await postAssetStatus(
				assetId,
				payload,
				await this.getMarketingEventToken(COMMENT),
			);
			commentBox.toggleCommentBox();
			this.loadAssetDetails(productId, assetId, true);
		} catch (error) {
			toasterNotify(createMessageForError(error, 'posting your comment'), 'error', error);
		}
	}

	componentDidMount() {
		const { match } = this.props;
		const eventAssetId =
			match.params && match.params.eventAssetId > 0 ? match.params.eventAssetId : null;
		const eventProductId =
			match.params && match.params.eventProductId > 0 ? match.params.eventProductId : null;
		if (eventProductId && eventAssetId) {
			this.loadEventAssetDetails(eventProductId, eventAssetId);
		} else {
			const productId =
				match.params && match.params.productId > 0 ? match.params.productId : null;
			const assetId = match.params && match.params.assetId > 0 ? match.params.assetId : null;
			this.initAssetDetails(productId, assetId);
		}
	}

	async initAssetDetails(productId, assetId, token) {
		if (productId) {
			this.setState({
				productId: productId,
				assetId: assetId,
			});
			await this.loadAssetDetails(productId, assetId, undefined, token);
			let state = this.determinePermissions();
			this.setState(state);
		} else {
			// this triggers error condition that renders 404 error dialog
			this.setState({ isAssetLoaded: true, asset: false });
		}
	}

	renderStatus(status) {
		if (!status) {
			return null;
		}
		let statusClass = classForAssetStatus(status);

		status = status.toUpperCase();
		status = status.replace(/_/g, ' ');

		return {
			text: status,
			class: statusClass,
		};
	}

	getApprovalButtonName() {
		const { asset } = this.state;

		let approveButtonName = 'Approve';

		if (asset.review_status === PRINT_PROOF_PENDING) {
			approveButtonName = 'Approve Proof';
		} else if (asset.review_status === PENDING_PROOF_ACCEPT) {
			approveButtonName = 'Accept Final Approved Label';
		} else if (asset.review_status === FINAL_LABEL_UPLOADED) {
			approveButtonName = 'Approve & Send Final Label File to NCL';
		} else if (asset.review_status === SUBMIT_TO_NCL_UPLOAD_FAILED) {
			approveButtonName = 'Resubmit to NCL';
		}

		return approveButtonName;
	}

	getRejectButtonName() {
		const { asset } = this.state;

		let rejectButtonName = 'Reject';

		if (asset.review_status === PRINT_PROOF_PENDING) {
			rejectButtonName = 'Reject Proof';
		} else if ([SUBMITTED_TO_NCL, NCL_ACCEPTED, NCL_ACCEPTED_WITH_CHANGES, NCL_PROOF_UPLOAD_FAILED].includes(asset.review_status)) {
			rejectButtonName = 'NCL Rejection';
		}

		return rejectButtonName;
	}

	renderTitleButtons() {
		const { asset } = this.state;

		const activeRightTransfer = this.checkActiveRightTransfer();
		const platformEnabled = this.checkPlatformHasFeatureEnabled();
		const validNextStatuses = asset.valid_next_review_statuses_for_user || [];
		const validNextForgoStatuses = asset.valid_next_forgo_statuses_for_user;

		const canUploadNewVersion =
			platformEnabled &&
			validNextStatuses.includes(FILES_UPLOADING) &&
			asset.review_status !== NCL_PROOF_UPLOADING &&
			(asset.asset_category === assetConst.CATEGORY.PACKAGING ?
				this.hasUploadRetailRight() : true) &&
			(asset.asset_category === assetConst.CATEGORY.CODE_IN_BOX ?
				this.hasUploadDigitalRight() : true);

		const canNOADoNCLApprove =
			platformEnabled &&
			asset.asset_type === assetConst.TYPE.LABEL &&
			(validNextStatuses.includes(PRINT_PROOF_UPLOADING) ||
				validNextStatuses.includes(NCL_PROOF_UPLOAD_FAILED));

		const canApprove =
			platformEnabled &&
			(validNextStatuses.includes(APPROVED_WITH_CHANGES) ||
				validNextStatuses.includes(REVIEWED) ||
				validNextStatuses.includes(REVIEWED_WITH_CHANGES) ||
				validNextStatuses.includes(SUBMIT_TO_NCL_FILE_UPLOADING) ||
				(validNextStatuses.includes(APPROVED) &&
					(asset.asset_type === assetConst.TYPE.LABEL
						? this.hasUploadRetailRight()
						: true)
				)
			);

		const canReject =
			validNextStatuses.includes(REJECTED) ||
			validNextStatuses.includes(PRINT_PROOF_REJECTED) ||
			validNextStatuses.includes(FINAL_LABEL_REJECTED) ||
			validNextStatuses.includes(NCL_PROOF_UPLOADING);

		const canForgoFinalLabelApproval =
			platformEnabled &&
			validNextForgoStatuses.includes(REQUEST_FORGO) &&
			asset.review_status !== APPROVED;

		const canAcceptForgo =
			platformEnabled &&
			validNextForgoStatuses.includes(ACCEPT_FORGO) &&
			this.hasUploadRetailRight();

		const canUploadPrintProof =
			platformEnabled &&
			asset.asset_type !== assetConst.TYPE.LABEL &&
			validNextStatuses.includes(PRINT_PROOF_UPLOADING) &&
			asset.review_status !== NCL_PROOF_UPLOADING &&
			(asset.asset_category === assetConst.CATEGORY.PACKAGING ?
				this.hasUploadRetailRight() : true) &&
			(asset.asset_category === assetConst.CATEGORY.CODE_IN_BOX ?
				this.hasUploadDigitalRight() : true);

		const canUploadFinalLabelFiles =
			platformEnabled &&
			validNextStatuses.includes(FINAL_LABEL_UPLOADING) &&
			asset.review_status !== NCL_PROOF_UPLOADING &&
			this.hasUploadRetailRight();

		const approveButtonName = this.getApprovalButtonName();
		const rejectButtonName = this.getRejectButtonName();

		return (
			<>
				{canForgoFinalLabelApproval && (
					<Button
						type="button"
						variant="success"
						id="forgo-approval"
						disabled={activeRightTransfer}
						onClick={(e) => {
							this.toggleModal(e);
						}}
					>
						Forgo Label Approval
					</Button>
				)}
				{canAcceptForgo && (
					<Button
						type="button"
						variant="success"
						id="accept-forgo"
						disabled={activeRightTransfer}
						onClick={(e) => {
							this.toggleModal(e);
						}}
					>
						Accept Forgo Label
					</Button>
				)}
				{canApprove && (
					<Button
						type="button"
						variant="success"
						id="approve-asset"
						disabled={activeRightTransfer}
						onClick={(e) => {
							this.toggleModal(e);
						}}
					>
						{approveButtonName}
					</Button>
				)}
				{canNOADoNCLApprove && (
					<Button
						type="button"
						variant="success"
						id="ncl-approve"
						disabled={activeRightTransfer}
						onClick={(e) => {
							this.toggleModal(e);
						}}
					>
						NCL Approve
					</Button>
				)}
				{canReject && (
					<Button
						type="button"
						variant="danger"
						id="reject-asset"
						disabled={activeRightTransfer}
						onClick={(e) => {
							this.toggleModal(e);
						}}
					>
						{rejectButtonName}
					</Button>
				)}
				{canUploadFinalLabelFiles && (
					<Button
						type="button"
						variant="outline-secondary"
						id="upload-final-label-files"
						disabled={activeRightTransfer}
						onClick={(e) => {
							this.toggleModal(e);
						}}
					>
						<FAIcon name="upload" className="mr-1" />
						Upload Final Label File(s)
					</Button>
				)}
				{canUploadPrintProof && (
					<Button
						type="button"
						variant="outline-secondary"
						id="upload-print-proof"
						disabled={activeRightTransfer}
						onClick={(e) => {
							this.toggleModal(e);
						}}
					>
						<FAIcon name="upload" className="mr-1" />
						Upload Print Proof
					</Button>
				)}
				{canUploadNewVersion && (
					<Button
						type="button"
						variant="outline-secondary"
						id="upload-new-version"
						disabled={activeRightTransfer}
						onClick={(e) => {
							this.toggleModal(e);
						}}
					>
						<FAIcon name="upload" className="mr-1" />
						Upload New Version
					</Button>
				)}
			</>
		);
	}

	renderSidebar() {
		const { asset, eventAssetId, hasViewFilePermission, hasAnnotateFilePermission } =
			this.state;
		const activeRightTransfer = this.checkActiveRightTransfer();

		let proofFilesTitle = '';
		let showFinalLabelFiles = false;
		let showProofFiles = false;

		if (asset.asset_category === assetConst.CATEGORY.PACKAGING) {
			if (asset.asset_type !== assetConst.TYPE.LABEL) {
				showProofFiles = false;
			} else {
				showProofFiles = true;
				if (asset.asset_type === assetConst.TYPE.LABEL) {
					proofFilesTitle = 'Label Proof File(s):';
					showFinalLabelFiles = true;
				} else {
					proofFilesTitle = 'Print Proof File(s):';
				}
			}
		}

		return (
			<>
				<FilesUploadedPanel
					revisions={this.state.revisions}
					hasViewFilePermission={hasViewFilePermission}
					isAnnotatable={
						this.checkPlatformHasFeatureEnabled() && hasAnnotateFilePermission && asset.review_status === PENDING_REVIEW
					}
					activeRightTransfer={activeRightTransfer}
					toggleViewer={this.toggleViewer}
					eventAssetId={
						asset.asset_category === assetConst.CATEGORY.MARKETING_EVENT && eventAssetId
					}
				/>
				<Panel>
					<Panel.Heading>
						<Panel.Title>Asset Properties</Panel.Title>
					</Panel.Heading>
					<Panel.Body>
						{showFinalLabelFiles && (
							<SidebarItem title="Final Label File(s):">
								{asset.final_label_files.length
									? this.renderFileList(asset.final_label_files, false)
									: 'None'}
							</SidebarItem>
						)}

						{showProofFiles && (
							<SidebarItem title={proofFilesTitle}>
								{asset.proof_files.length
									? this.renderFileList(asset.proof_files, false)
									: 'None'}
							</SidebarItem>
						)}

						<SidebarItem title="Type:">
							{asset.asset_type === assetConst.TYPE.OTHER ? (
								<>Other &ndash; {asset.asset_type_other_name}</>
							) : (
								formatAssetType(asset.asset_type)
							)}
						</SidebarItem>

						{asset.languages.length > 0 && (
							<SidebarItem title="Asset Language(s):">
								{asset.languages.map((lang, i) => {
									return (
										<Fragment key={makeKey('lang-', i)}>
											<span>{formatLocale(lang)}</span>
											<br />
										</Fragment>
									);
								})}
							</SidebarItem>
						)}
						{(asset.submitted_by || asset.submitted_by_email) && (
							<SidebarItem title="Submitted by:">
								{asset.submitted_by}
								<br />
								{asset.submitted_by_email}
								<br />
								<span className="help-block">{asset.submitted_date}</span>
							</SidebarItem>
						)}
						{asset.modified_by && (
							<SidebarItem title="Updated by:">
								{asset.modified_by}
								<br />
								<span className="help-block">{asset.modified_date}</span>
							</SidebarItem>
						)}
						{asset.due_date && (
							<SidebarItem title="Due Date:">
								{formatDate(parseDateString(asset.due_date), dateFormat.DATE)}
							</SidebarItem>
						)}
						{asset.description && (
							<SidebarItem title="Description:">{asset.description}</SidebarItem>
						)}
					</Panel.Body>
				</Panel>
			</>
		);
	}

	onSuccessfulFileClick() {
		const { asset, assetId, productId } = this.state;
		let uploadingStatuses = [FILES_UPLOADING, PRINT_PROOF_UPLOADING, FINAL_LABEL_UPLOADING];

		if (uploadingStatuses.includes(asset.review_status)) {
			toasterNotify('Upload Complete', 'success');
			this.loadAssetDetails(productId, assetId);
		}
	}

	renderFile(file, label, downloadFile, showIcon=false) {
		const { hasViewFilePermission, asset, eventAssetId } = this.state;
		return hasViewFilePermission ? (
			<FileLink
				fileName={file.file_name}
				fileId={file.file_id}
				status={file.status}
				callback={this.onSuccessfulFileClick}
				saved={downloadFile}
				eventAssetId={
					asset.asset_category === assetConst.CATEGORY.MARKETING_EVENT && eventAssetId
				}
				displayIcon={showIcon}
			>
				{label}
			</FileLink>
		) : (
			label
		);
	}

	renderFileList(files, showIcon=false) {
		return files.map((file, i) => {
			return (
				<ul className="list-unstyled px-0 mx-0" key={i}>
					<li>{this.renderFile(file, file.file_name, this.determineVideo(file), showIcon)}</li>
				</ul>
			);
		});
	}

	renderAssetRevisions() {
		const { revisions } = this.state;

		return revisions.length > 0 ? (
			revisions.map((revision, i) => {
				let hasStatus = !!revision.review_status;
				let hasForgoStatus =
					!!revision.final_label_forgo_status &&
					revision.final_label_forgo_status !== FORGO_NOT_REQUESTED;
				let hasFiles = !!revision.files.length > 0;
				let hasComment = !!revision.comment;

				let statusActivity = '';
				let forgoActivity = '';
				if (revision.submitted_by) {
					if (hasStatus) {
						statusActivity = ' has changed the status to ';
					}
					if (hasStatus && hasForgoStatus) {
						forgoActivity = ' and has set the forgo final label status to ';
					} else if (hasForgoStatus) {
						forgoActivity = ' has set the forgo final label status to ';
					}
				} else {
					if (hasStatus) {
						statusActivity = 'Status changed to ';
					}
					if (hasStatus && hasForgoStatus) {
						forgoActivity = ' and forgo final label status set to ';
					} else if (hasForgoStatus) {
						forgoActivity = 'Forgo final label status set to ';
					}
				}

				return (
					<div key={i} className="comment-container">
						<p>
							<strong>{revision.submitted_by}</strong>
							{statusActivity}
							{hasStatus && (
								<Status status={this.renderStatus(revision.review_status)} />
							)}
							{forgoActivity}
							{hasForgoStatus && (
								<Status
									status={this.renderStatus(revision.final_label_forgo_status)}
								/>
							)}
							{' - '}
							{formatDate(
								parseDateString(revision.submitted_date),
								dateFormat.DATETIME_PT,
							)}
						</p>

						<div className="comment">
							{hasFiles && this.renderFileList(revision.files, true)}

							{hasComment && (
								<p className="asset-revision-comment">{revision.comment}</p>
							)}

							{revision.anticipated_proof_date && <>
								<p className="asset-revision-comment">Anticipated proof date: {formatDate(revision.anticipated_proof_date)}</p>
							</>}
						</div>
					</div>
				);
			})
		) : (
			<div className="comment-container">
				<p>No Revision History</p>
			</div>
		);
	}

	renderModal() {
		const { userProfile } = this.props;
		const { productId, assetId, asset, modalType, product } = this.state;

		switch (modalType) {
			case 'approve-asset':
				return (
					<ApproveAssetModal
						userProfile={userProfile}
						closeModal={this.toggleModal}
						productId={productId}
						asset={asset}
						currentStatus={asset.review_status}
						loadAssetDetails={this.loadAssetDetails}
						product={product}
					/>
				);
			case 'ncl-approve':
				return (
					<NCLApproveModal
						show={true}
						onClose={this.toggleModal}
						onSuccess={() => this.loadAssetDetails(product.product_id, asset.asset_id)}
						asset={asset}
						product={product}
					/>
				);
			case 'reject-asset':
				return (
					<RejectAssetModal
						userProfile={userProfile}
						closeModal={this.toggleModal}
						productId={productId}
						asset={asset}
						product={product}
						currentStatus={asset.review_status}
						validNextStatuses={asset.valid_next_review_statuses_for_user}
						loadAssetDetails={this.loadAssetDetails}
					/>
				);
			case 'forgo-approval':
				return (
					<ForgoFinalLabelApprovalModal
						userProfile={userProfile}
						closeModal={this.toggleModal}
						productId={productId}
						assetId={assetId}
						currentStatus={asset.review_status}
						validNextStatuses={asset.valid_next_review_statuses_for_user}
						loadAssetDetails={this.loadAssetDetails}
					/>
				);
			case 'accept-forgo':
				return (
					<AcceptForgoFinalLabelApprovalModal
						userProfile={userProfile}
						closeModal={this.toggleModal}
						productId={productId}
						assetId={assetId}
						currentStatus={asset.review_status}
						validNextStatuses={asset.valid_next_review_statuses_for_user}
						loadAssetDetails={this.loadAssetDetails}
					/>
				);
			case 'upload-print-proof':
				return (
					<UploadPrintProofModal
						userProfile={userProfile}
						closeModal={this.toggleModal}
						productId={productId}
						assetId={assetId}
						loadAssetDetails={this.loadAssetDetails}
					/>
				);
			case 'upload-new-version':
				return (
					<UploadNewAssetModal
						show={true}
						userProfile={userProfile}
						closeModal={this.toggleModal}
						productId={productId}
						asset={asset}
						loadAssetDetails={this.loadAssetDetails}
					/>
				);
			case 'upload-final-label-files':
				return (
					<UploadFinalLabelFilesModal
						userProfile={userProfile}
						closeModal={this.toggleModal}
						productId={productId}
						assetId={assetId}
						loadAssetDetails={this.loadAssetDetails}
					/>
				);
			default:
				toasterNotify(`An unexpected error occurred opening modal ${this.state.modalType}`);
		}
	}

	alertMessage() {
		const { asset } = this.state;
		let alertMessage = 'The publisher has agreed to forgo the Final Label Approval';

		return (
			asset.final_label_forgo_status === ACCEPT_FORGO && (
				<div className="alert alert-info">{alertMessage}</div>
			)
		);
	}

	render() {
		const {
			isAssetLoaded,
			asset,
			viewerDocumentId,
			showViewer,
			marketingEventProduct,
			product
		} = this.state;
		const { userProfile } = this.props;

		if (isAssetLoaded && !asset) {
			return <NotFound />;
		}

		const isLoading = !isAssetLoaded;
		const title = isAssetLoaded && formatAssetType(asset.asset_type);
		const activeRightTransfer = this.checkActiveRightTransfer();

		return isLoading ? (
			<Page isLoading={true} />
		) : (
			<Page>
				<VirtualViewer
					authorizer={
						asset.asset_category === assetConst.CATEGORY.MARKETING_EVENT
							? 'mkevent'
							: null
					}
					showViewer={showViewer}
					userProfile={userProfile}
					toggleViewer={this.toggleViewer}
					viewerDocumentId={viewerDocumentId}
				/>
					{activeRightTransfer && (
						<Alert variant="danger">
							The product is pending a rights transfer. Some actions are disabled until the transfer is completed or rescinded.
						</Alert>
					)}
					{asset.asset_category !== assetConst.CATEGORY.MARKETING_EVENT && (
						<Breadcrumb>
							<Breadcrumb.Item to="/products">Products</Breadcrumb.Item>
							<Breadcrumb.Item to={`/products/${product.product_id}`}>
								{product &&
									`${product.game_name}${product.game_code ? ` (${product.game_code})` : ''}`}
							</Breadcrumb.Item>
							<Breadcrumb.Item active>{title}</Breadcrumb.Item>
						</Breadcrumb>
					)}
					{asset.asset_category === assetConst.CATEGORY.MARKETING_EVENT && (
						<Breadcrumb>
							<Breadcrumb.Item to="/marketing-events">Marketing Events</Breadcrumb.Item>
							<Breadcrumb.Item to={`/marketing-events/${marketingEventProduct.event.id}`}>
								{marketingEventProduct.event.name}
							</Breadcrumb.Item>
							<Breadcrumb.Item to={`/marketing-events/products/${marketingEventProduct.id}`}>
								{product &&
									`${product.game_name}${product.game_code ? ` (${product.game_code})` : ''}`}
							</Breadcrumb.Item>
							<Breadcrumb.Item active>{title}</Breadcrumb.Item>
						</Breadcrumb>
					)}
					<Title
						title={title}
						subtitle={product.game_name}
						status={this.renderStatus(asset.review_status)}
						button={this.renderTitleButtons()}
					/>

				{this.alertMessage()}

				<Page.SplitPage>
					<Page.MainCol cols={8}>
						<Page.ContentContainer>
							<SectionTitle>Activity Log</SectionTitle>
							{this.renderAssetRevisions()}
							{this.canAddComment() && <CommentBox postComment={this.postComment} />}
						</Page.ContentContainer>
					</Page.MainCol>

					<Page.SidebarCol cols={4}>{this.renderSidebar()}</Page.SidebarCol>
				</Page.SplitPage>

				{this.state.showModal && this.state.modalType ? this.renderModal() : null}
			</Page>
		);
	}
}

export default connect(mapStateToProps)(AssetDetails);
