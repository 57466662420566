import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'react-bootstrap';
import * as yup from 'yup';

import BaseModal from '../../../components/BaseModal/BaseModal';
import FAIcon from '../../../components/FAIcon/FAIcon';
import Forms from '../../../components/Forms/Forms';
import { postNewEventProductAsset } from '../../../services/eventsService';
import { getAssetTypes } from '../../../services/productsService';
import { formatAssetType } from '../../../utils/assetUtils';
import { today } from '../../../utils/dateUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { validateToSchema } from '../../../utils/validationUtils';

import './AddAssetModal.css';


const defaultRow = () => ({ active: true });

const schema = yup.array().of(
	yup.object({
		active: yup.bool().default(true),
		asset_type_id: yup.number()
			.required('Assets require a selected type'),
		// Keep due_date as string, otherwise yup will cast into a date object that also tracks time
		due_date: yup.string().nullable().default(undefined),
		description: yup.string().default('')
	})
);


/*
TODO: This is a sample template. Feature to be implemented later. Uncomment
to continue implementation

const templates = {
	'Single Asset': [defaultRow()],
	// 'Template 1': [
	// 	{
	// 		active: true,
	// 		asset_type_id: assetConst.TYPE.BANNER_AD,
	// 		fixed: true,
	// 	},
	// 	{
	// 		active: true,
	// 		asset_type_id: assetConst.TYPE.DISPLAY_SIGNAGE,
	// 		fixed: true,
	// 	},
	// 	{
	// 		active: true,
	// 		asset_type_id: assetConst.TYPE.RELEASE_ALERT,
	// 		fixed: true,
	// 	},
	// 	{
	// 		active: true,
	// 		asset_type_id: assetConst.TYPE.TV_COMMERCIAL,
	// 		fixed: true,
	// 	},
	// 	{
	// 		active: true,
	// 		asset_type_id: assetConst.TYPE.WEBSITE,
	// 		fixed: true,
	// 	},
	// ],
};
*/

const AddAssetModal = ({ show, product, onClose, onComplete, canSetActive }) => {
	// TODO: For templates
	// const [template, setTemplate] = useState([]);
	const [formRows, setFormRows] = useState([]);
	const [showAllErrors, setShowAllErrors] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [assetTypes, setAssetTypes] = useState([]);
	const [fault, setFault] = useState();

	const addRow = () => setFormRows((v) => [...v, defaultRow()]);
	const removeRow = (index) => setFormRows((v) => v.filter((unused, i) => i !== index));
	const formUpdate = (values, index) => {
		setFormRows((v) => {
			v[index] = values;
			return [...v];
		});
	};

	/* TODO: For templates
	const templateChange = (index) => {
		setTemplate(index);
		setFormRows([...templates[index]]);
	};
	*/
	useEffect(() => {
		if (show) {
			setFault(false);
			setShowAllErrors(false);
			setFormRows([defaultRow()]);
			loadAssetTypes();
			// TODO: For templates
			// setTemplate(Object.keys(templates)[0]);
		}
	}, [show]);

	const validationErrors = validateToSchema(schema, formRows);

	const groupedValidationErrors = Object.keys(validationErrors).reduce((reduction, key) => {
		const [, indexString, field] = key.match(/^\[(\d+)\]\.(.+)$/);
		const index = Number(indexString);
		reduction[index] = reduction[index] || {};
		reduction[index][field] = validationErrors[key];
		return reduction;
	}, []);

	const loadAssetTypes = async () => {
		try {
			setIsLoading(true);
			const response = await getAssetTypes(product.platform_code, 'MARKETING_EVENT');
			const types = [...response.data];
			types.sort((a, b) => {
				if (a.asset_type === 'OTHER') {
					return 1;
				}
				if (b.asset_type === 'OTHER') {
					return -1;
				}
				return 0;
			});
			setAssetTypes(types);
		} catch (error) {
			toasterNotify(
				createMessageForError(error, 'retrieving asset types'),
				'error',
				error
			);
			setAssetTypes([]);
			setFault(error);
		} finally {
			setIsLoading(false);
		}
	};

	const submit = async () => {
		if (groupedValidationErrors.length > 0) {
			return setShowAllErrors(true);
		}
		setIsSubmitting(true);
		const removedRows = [];
		const castedValues = schema.cast(formRows);
		const calls = [];
		castedValues.forEach((payload, index) => {
			const newCall = postNewEventProductAsset(product.id, payload)
				.then((response) => {
					removedRows.push(index);
				})
				.catch((err) => null); // swallow the error
			calls.push(newCall);
		});
		await Promise.all(calls);

		const newFormRows = formRows.filter((unused, index) => !removedRows.includes(index));
		if (newFormRows.length === 0) {
			toasterNotify('Asset(s) have been added to this product successfully', 'success');
			onComplete();
			onClose();
		} else {
			setFormRows(newFormRows);
			toasterNotify(
				'Some assets were not created, and they remain in the form. Please try submitting them again.',
				'error',
			);
			onComplete();
		}
		setIsSubmitting(false);
	};

	return (
		<BaseModal show={show} size="lg" onCancel={onClose} isSubmitting={isSubmitting} fault={fault}>
			<BaseModal.Title>Add Asset</BaseModal.Title>
			{/* TODO: For templates
			<Row className="AddAssetModal__template-select">
				<Col sm={9}>
					<Forms
						values={{ template }}
						onChange={({ template }) => templateChange(template)}
					>
						<Forms.BasicSelect id="template">
							<Forms.Heading>Required Asset Template</Forms.Heading>
							{Object.keys(templates).map((key) => (
								<Forms.Option value={key} key={'templates-' + key}>
									{key}
								</Forms.Option>
							))}
						</Forms.BasicSelect>
					</Forms>
				</Col>
			</Row>
			*/}
			<Table className="AddAssetModal__table">
				<thead>
					<tr>
						{canSetActive && <th style={{ minWidth: 107 }}>State</th>}
						<th style={{ width: '33%' }}>Required Types</th>
						<th style={{ width: '33%' }}>Due Date</th>
						<th style={{ width: '33%' }}>Description</th>
						<th style={{ minWidth: 38 }}></th>
					</tr>
				</thead>
				<tbody>
					{formRows.map((row, index) => (
						<Forms
							id={'add-asset-row-' + index}
							values={row}
							onChange={(values) => formUpdate(values, index)}
							key={'add-asset-row-' + index}
							showAllErrors={showAllErrors}
							validationErrors={groupedValidationErrors[index]}
							as="tr"
							noPadding
							vertical
						>
							{canSetActive && (
								<td>
									<Forms.SingleCheckbox id="active">
										<Forms.Label>Active</Forms.Label>
									</Forms.SingleCheckbox>
								</td>
							)}
							<td>
								<Forms.BasicSelect
									id="asset_type_id"
									placeholder="Select type"
									disabled={row.fixed}
									isLoading={isLoading}
								>
									{assetTypes.map((type) => (
										<Forms.Option
											value={type.asset_type_id}
											key={'types-' + type.asset_type_id}
										>
											{formatAssetType(type['asset_type'])}
										</Forms.Option>
									))}
								</Forms.BasicSelect>
							</td>
							<td>
								<Forms.DateSelect
									id="due_date"
									minDate={new Date(today() + 'T00:00:00')}
									maxDate={product ? new Date(product.event.end_datetime) : null}
								/>
							</td>
							<td>
								<Forms.TextArea rows="2" id="description" maxLength="50" />
							</td>
							<td>
								<Button
									size="sm"
									variant="danger"
									onClick={(e) => removeRow(index)}
									disabled={row.fixed || formRows.length < 2}
								>
									<FAIcon className="text-white" name="times" />
								</Button>
							</td>
						</Forms>
					))}
				</tbody>
			</Table>
			<Button variant="link" onClick={(e) => addRow()}>
				<FAIcon name="plus-circle" style={{ textDecoration: 'none' }} className="mr-1"/>
				Add another asset
			</Button>
			<BaseModal.Submit onClick={(e) => submit()}>Save</BaseModal.Submit>
		</BaseModal>
	);
};
AddAssetModal.propTypes = {
	show: PropTypes.bool.isRequired,
	product: PropTypes.any,
};

export default AddAssetModal;
