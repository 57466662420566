import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
	return (
		<footer className="container-fluid py-4" style={{'maxWidth': '1400px'}}>
			<div className="row">
				<div className="col-12 d-flex">
					<div className="mr-auto">
						<a href="mailto:thirdpartypublisher@noa.nintendo.com">
							{' '}
							CONTACT SITE SUPPORT
						</a>
						<span className="text-muted px-2">
							|
						</span>
						<Link to="/privacy-policy">PRIVACY NOTICE</Link>
					</div>
					<div className="text-muted">
						© { __BUILD_INFO__.COPYRIGHT_YEAR } Nintendo
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
