import React from 'react';
import dayjs from 'dayjs';
import { Col, Form, FormGroup, Row } from 'react-bootstrap';

import DatePicker from '../../../components/DatePicker/DatePicker';
import BaseModal from '../../../components/BaseModal/BaseModal';
import {
	editProjectionsPeriod,
	sendProjectionNotifications
} from '../../../services/projectionsService';
import { dateFormat } from '../../../utils/dateUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';

import '../ProjectionsManagement.css';


export default class EditProjectionsPeriodModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			projectionsStartDate: dayjs(
				props.projectionRange[0],
				'DD/MM/YYYY'
			).format(dateFormat.DATE_YMD),
			projectionsEndDate: dayjs(
				props.projectionRange[1],
				'DD/MM/YYYY'
			).format(dateFormat.DATE_YMD)
		};

		this.updateProjectionsPeriod = this.updateProjectionsPeriod.bind(this);
		this.toggleLoading = this.toggleLoading.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	async updateProjectionsPeriod() {
		const { submissionPeriodId } = this.props;
		const { projectionsStartDate, projectionsEndDate } = this.state;
		this.toggleLoading();
		const formattedEndDate = dayjs(projectionsEndDate).set('hour', 15);
		const payload = {
			start_date: dayjs(projectionsStartDate)
				.utc()
				.toISOString(),
			end_date: formattedEndDate.utc().toISOString()
		};
		await editProjectionsPeriod(payload, submissionPeriodId)
			.then((response) => {
				sendProjectionNotifications(submissionPeriodId)
					.catch((error) => {
						toasterNotify(
							createMessageForError(error, 'sending notification for projection period changes'),
							'warning',
							error,
						);
					});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'submitting projection changes'),
					'error',
					error,
				);
			});
		this.props.toggleParentLoading();
		this.props.pageInit();
		this.props.toggleModal();
	}

	buttonDisabled() {
		const {
			projectionsStartDate,
			projectionsEndDate,
			isLoading
		} = this.state;

		const today = dayjs().startOf('day');

		const startDateIsValid = dayjs(projectionsStartDate).isSameOrAfter(
			today
		);

		const endDateAfterStartDate = dayjs(projectionsStartDate).isBefore(
			projectionsEndDate
		);

		return !(!isLoading && endDateAfterStartDate && startDateIsValid);
	}

	updateStateDates(val, stateValue) {
		this.setState({
			[stateValue]: dayjs(val).format(dateFormat.DATE_YMD)
		});
	}

	renderModalBody() {
		const { projectionsStartDate, projectionsEndDate } = this.state;
		const today = new Date();
		return (<>
			<Form>
				<FormGroup as={Row}>
					<label className="col-sm-4 control-label text-sm-right">
						Start Date
					</label>
					<Col sm={8} className="date-picker-form-container">
						<DatePicker
							className="form-control"
							dropdownMode="select"
							minDate={today}
							value={projectionsStartDate || undefined}
							onChange={(val) =>
								this.updateStateDates(
									val,
									'projectionsStartDate'
								)
							}
						/>
					</Col>
				</FormGroup>

				<FormGroup as={Row}>
					<label className="col-sm-4 control-label text-sm-right">
						End Date
					</label>
					<Col
						sm={8}
						className="request-date"
					>
						<DatePicker
							className="form-control"
							dropdownMode="select"
							minDate={today}
							value={projectionsEndDate}
							onChange={(val) =>
								this.updateStateDates(
									val,
									'projectionsEndDate'
								)
							}
						/>
					</Col>
				</FormGroup>
			</Form>
		</>);
	}

	render() {
		const { isLoading } = this.state;

		return (
			<BaseModal show={true} onCancel={this.props.toggleModal} isSubmitting={isLoading}>
				<BaseModal.Title>Edit Projection Period</BaseModal.Title>
				{this.renderModalBody()}
				<BaseModal.Submit
					onClick={this.updateProjectionsPeriod}
					disabled={this.buttonDisabled()}
				>
					Update
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}
