import React, { PropsWithChildren, VFC } from 'react';
import { dateFormat, formatDate, parseDateString } from '../../utils/dateUtils';

import './LogEntry.css';
import { timeZoneConstants } from '../../constants/timeZoneConstants';

interface LogEntrySubcomponents {
	Heading: typeof Heading;
	Comment: typeof Comment;
	List: typeof List;
}
const LogEntry:VFC<PropsWithChildren<unknown>> & LogEntrySubcomponents= ({ children }) => {
	return (
		<div className="comment-container">
			{children}
		</div>
	);
};

interface HeadingProps {
	timestamp: string;
	timezone?: string;
	dateFormat?: string;
}
const Heading:VFC<PropsWithChildren<HeadingProps>> = ({ children, timestamp, dateFormat: format, timezone }) => {
	timezone = timezone || timeZoneConstants.PACIFIC;
	format = format || dateFormat.DATETIME_PT;
	return (
		<h5 className="LogEntry__heading">
			{children}{' '}
			{timestamp && (
				<>&ndash; {formatDate(parseDateString(timestamp, undefined, timezone), format)}</>
			)}
		</h5>
	);
};

const Comment:VFC<PropsWithChildren<unknown>> = ({ children }) => {
	return (
		<div className="comment">{children}</div>
	);
};

const List:VFC<PropsWithChildren<unknown>> = ({ children }) => {
	return (
		<ul className="comment list-unstyled pl-3 mx-3">
			{children}
		</ul>
	);
};

LogEntry.Heading = Heading;
LogEntry.Comment = Comment;
LogEntry.List = List;

export default LogEntry;
