import React, { useState, forwardRef, useMemo } from 'react';
import dayjs from 'dayjs';
import ReactDatePicker from 'react-datepicker';
import { FormControl } from 'react-bootstrap';

import { parseDateString, formatDate } from '../../utils/dateUtils';
import { timeZoneConstants } from '../../constants/timeZoneConstants';
import FAIcon from '../FAIcon/FAIcon';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

const DatePicker = ({
	id,
	disabled,
	value,
	onChange,
	showTimeSelect,
	onBlur,
	minDate,
	maxDate,
	filterDate,
	timezone = timeZoneConstants.PACIFIC,
	dateFormat
}) => {
	const [lastChangedValue, setLastChangedValue] = useState();
	const formatString = showTimeSelect ? 'YYYY-MM-DDTHH:mm:ss' : 'YYYY-MM-DD';

	const changeHandler = (d) => {
		// receive a date object and output via the onChange callback as in ISO8601 format with offset
		onBlur && onBlur();
		setLastChangedValue(d);
		if (d == null) {
			onChange(undefined);
		}
		if (!d) {
			return;
		}
		const p = dayjs(d);
		const fd = p.format(formatString);
		if (showTimeSelect) {
			const dateTimeConvert = dayjs.tz(fd, timezone).format();
			onChange(dateTimeConvert || undefined);
		} else {
			onChange(fd || undefined);
		}
	};

	const valueAdapter = (value) => {
		if (!showTimeSelect) {
			if (!value) {
				return value;
			}
			if (value instanceof Date) {
				return new Date(value.getFullYear(), value.getMonth(), value.getDate());
			}
			const valueAsMoment = value.isMoment ? value : parseDateString(value);
			return new Date(valueAsMoment.year(), valueAsMoment.month(), valueAsMoment.date());
		}
		const fs = formatDate(parseDateString(value)?.tz(timezone), formatString);
		return value && new Date(fs);
	};

	const getAbbreviationForTimeZone = () => {
		if (timezone === timeZoneConstants.PACIFIC) {
			return 'PT';
		} else if (timezone === timeZoneConstants.JAPAN) {
			return 'JST';
		}
		return '';
	};

	const CustomInputBase = useMemo(() => {
		const DateFieldInput = ({ value, onClick, onChange, onBlur }, ref) => (
			<div className="position-relative">
				<FormControl
					className="DatePicker__input"
					onClick={onClick}
					onChange={onChange}
					ref={ref}
					value={value || ''}
					onBlur={onBlur}
					id={id}
					autoComplete="off"
				/>
				<FAIcon name="calendar" className="DatePicker__date-glyph form-control-feedback" />
			</div>
		);
		return DateFieldInput;
	}, []);
	const CustomInput = forwardRef(CustomInputBase);
	const currentValue = value === undefined ? undefined : (valueAdapter(value) || lastChangedValue);

	const abbreviation = getAbbreviationForTimeZone(timezone);

	const staticValue = (value, abbreviation = 'PT') => {
		if (!value) {
			return '';
		}
		return dateFormat || (showTimeSelect
			? formatDate(parseDateString(value), 'MM/DD/YYYY hh:mm A') + ' ' + abbreviation
			: formatDate(value, 'MM/DD/YYYY'));
	};

	return (
		<div className="DatePicker">
			{disabled ? (
				<div className="react-datepicker-wrapper">
					<div className="react-datepicker__input-container">
						<FormControl
							className="DatePicker__input"
							as="input"
							value={staticValue(value, abbreviation, dateFormat)}
							disabled={true}
						/>
						<FAIcon
							name="calendar"
							className="DatePicker__date-glyph form-control-feedback"
						/>
					</div>
				</div>
			) : (
				<>
					<ReactDatePicker
						dropdownMode="select"
						selected={currentValue}
						onChange={(newValue) => changeHandler(newValue)}
						showTimeSelect={showTimeSelect}
						dateFormat={
							dateFormat || (showTimeSelect ? `MM/dd/yyyy h:mm aa '${abbreviation}'` : 'MM/dd/yyyy')
						}
						onBlur={(e) => onBlur && onBlur(e)}
						minDate={minDate}
						maxDate={maxDate}
						customInput={<CustomInput />}
						filterDate={filterDate}
						openToDate={
							currentValue < new Date(minDate) || currentValue > new Date(maxDate)
								? new Date()
								: null
						}
					/>
				</>
			)}
		</div>
	);
};

export default DatePicker;
