import React, { Component } from 'react';
import { PopupboxContainer, PopupboxManager } from 'react-popupbox';

import './VirtualViewer.css';


class VirtualViewer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			srcUrl: this.generateUrl(this.props)
		};

		this.iframeRef = React.createRef();
	}

	generateUrl(props) {
		const { viewerDocumentId, authorizer } = props;
		const encodedDocument = encodeURIComponent(viewerDocumentId);
		return `${global.config.apiUrl}/virtualviewer?documentId=${encodedDocument}${authorizer ? '&authorizer=' + authorizer : ''}`;
	}

	refreshIframe() {
		const { srcUrl } = this.state;
		const iFrame = this.iframeRef.current;
		if (iFrame) {
			iFrame.src = srcUrl;
		}
	}

	renderViewer() {
		const { srcUrl } = this.state;
		const content = (
			<div className="div-virtual-viewer-content">
				<div className="div-virtual-viewer">
					<iframe
						src={srcUrl}
						key="virtualViewer"
						className="iframe-virtual-viewer"
						ref={this.iframeRef}
						title="VirtualViewer"
					>
						{' '}
					</iframe>
				</div>
			</div>
		);
		PopupboxManager.open({
			content,
			config: {
				titleBar: {
					enable: true
				},
				style: {
					width: '90%',
					height: '90%'
				},
				onOpen: () => {
					this.refreshIframe();
				},
				onClosed: () => {
					this.props.toggleViewer();
				}
			}
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.viewerDocumentId) {
			this.setState(
				{
					srcUrl: this.generateUrl(nextProps)
				},
				() => {
					if (nextProps.showViewer === true) {
						this.renderViewer();
					}
				}
			);
		}
	}

	render() {
		return (
			<div className="div-virtual-viewer">
				<PopupboxContainer />
			</div>
		);
	}
}

export default VirtualViewer;
