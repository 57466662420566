import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useQueryClient } from 'react-query';

import BaseModal from '../../../components/BaseModal/BaseModal';
import { useUserProfile } from '../../../hooks/reduxHooks';
import { getCompanyProfile } from '../../../services/companiesService';
import {
	getCancelUploadToken,
	getDigitalOrderComponents,
	postOrderApproverApprove,
	postOrderCancel,
	postOrderComplete,
	postOrderReviewerApprove,
	postOrderReviewerResendOrder,
	postRetractDigitalCodes
} from '../../../services/digitalCodesService';
import { putCancelDigitalCodesFileTransfer } from '../../../services/fileUploadsService';
import { getDigitalOrderableProducts } from '../../../services/ordersService';
import { safeEval } from '../../../utils/dataUtils';
import { toasterNotify } from '../../../utils/toaster';
import { modalIdentifiers } from '../DigitalCodesDetail.helpers';
import ManageActivationModal from './ManageActivationModal';
import PaymentModal from './PaymentModal';
import RejectOrderModal from './RejectOrderModal';
import UploadDigitalCodesModal from './UploadDigitalCodesModal';


// modal identifiers
const {
	CANCEL_ORDER_MODAL,
	REVIEWER_APPROVAL_MODAL,
	APPROVER_CONFIRM_APPROVAL_MODAL,
	CANCEL_UPLOAD_MODAL,
	COMPLETE_ORDER_MODAL,
	REJECT_REASON_MODAL,
	RESEND_ORDER_MODAL,
	ASPERA_UPLOAD_CODES_MODAL,
	PAYMENT_MODAL,
	MANAGE_ACTIVATION_MODAL,
	RETRACT_CODES_MODAL,
} = modalIdentifiers;
const [CONFIRM, DELETE] = ['CONFIRM', 'DELETE'];

const DigitalCodesModals = ({ openModal, closeModal, order, isCodeInBox, product, onSubmit }) => {
	const { orderID } = order;
	const userProfile = useUserProfile();
	const queryClient = useQueryClient();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [asyncLastUpdate, setAsyncLastUpdate] = useState(undefined);
	const modalSpecs = [
		{
			type: CANCEL_ORDER_MODAL,
			variant: DELETE,
			title: 'Cancel Order Confirmation',
			confirmAction: async () => {
				await postOrderCancel(orderID);
				toasterNotify('Digital code order was cancelled');
			},
			confirmActionCopy: 'Confirm Cancel Order',
			cancelActionCopy: 'Abort Cancel',
			body: 'Please confirm that you want to cancel the order. The operation cannot be undone.'
		},
		{
			type: REVIEWER_APPROVAL_MODAL,
			variant: CONFIRM,
			title: 'Review Order Confirmation',
			confirmAction: async () => {
				await postOrderReviewerApprove(orderID);
				toasterNotify(
					'Order was approved by a reviewer and is ready to be confirmed by a digital code order approver',
				);
			},
			confirmActionCopy: 'Confirm Accept Order',
			body: 'Please confirm that you want to accept the order. The operation cannot be undone.'
		},
		{
			type: APPROVER_CONFIRM_APPROVAL_MODAL,
			variant: CONFIRM,
			title: 'Approve Order Confirmation',
			confirmAction: async () => {
				const queryOptions = { staleTime: 60000 }; // use cached data if it is under 1 min old

				const [publisherQuery, payTo, productsQuery] = await Promise.all([
					queryClient.fetchQuery(
						['getCompanyProfile', order.NDID],
						() => getCompanyProfile(order.NDID),
						queryOptions,
					),
					queryClient.fetchQuery(
						['getCompanyProfile', userProfile.companyId],
						() => getCompanyProfile(userProfile.companyId),
						queryOptions,
					),
					queryClient.fetchQuery(
						['getDigitalOrderableProducts', order.NDID],
						() => getDigitalOrderableProducts(order.NDID),
						queryOptions,
					),
				]);
				const productData =
					productsQuery.data.find((product) => product.product_id === order.productID);

				if (!productData) {
					throw new Error('The system cannot find the product specified in the order');
				}
				const componentQuery = await queryClient.fetchQuery(
					['getDigitalOrderComponents', productData['game_code'], productData['platform_code']],
					() => getDigitalOrderComponents(productData['game_code'], productData['platform_code']),
					queryOptions,
				);

				const componentData = componentQuery.data.find(
					(component) => component.partNumber === order.componentID,
				);

				await postOrderApproverApprove(orderID, {
					billToAddress: publisherQuery.data.company_information,
					payToAddress: payTo.data.company_information,
					publisherId: order.NDID,
					component: { ...componentData, productId: productData.product_id },
					product: {
						...productData,
						game_code:
							safeEval(() => productData.game_code.substring(0, 4)) ||
							productData.game_code,
					},
				});
				toasterNotify('Order has been approved and is now awaiting payment');


			},
			confirmActionCopy: 'Confirm Approve Order',
			body: 'Please confirm that you want to approve the order. The operation cannot be undone.'
		},
		{
			type: CANCEL_UPLOAD_MODAL,
			variant: CONFIRM,
			title: 'Cancel Code Upload?',
			confirmAction: async () => {
				const tokenRes = await getCancelUploadToken(orderID);
				const authToken = tokenRes.headers['x-pdb-authorization'];
				await putCancelDigitalCodesFileTransfer(orderID, authToken);
				toasterNotify('The code upload is in the process of aborting. When it’s done, the order status will change.', 'success');
			},
			body: 'Please confirm you wish to abort the code upload process.',
			confirmActionCopy: 'Yes – Cancel Upload',
			cancelActionCopy: 'Cancel',
		},
		{
			type: COMPLETE_ORDER_MODAL,
			variant: CONFIRM,
			title: 'Mark This Order as Complete?',
			confirmAction: async () => {
				await postOrderComplete(orderID);
				toasterNotify('The status of the order is set to Complete', 'success');
			},
			body: 'Publisher Tool cannot accept uploaded codes for this order. Once you have delivered codes to the publisher by alternate means, you can mark this order as Complete. This operation cannot be undone.',
			confirmActionCopy: 'Complete',
			cancelActionCopy: 'Cancel',
		},
		{
			type: RESEND_ORDER_MODAL,
			variant: CONFIRM,
			title: 'Resend Order',
			body: 'Are you sure you want to resend this order to EBS?',
			confirmAction: async () => {
				await postOrderReviewerResendOrder(orderID);
				toasterNotify('The order was sent again to EBS', 'success');
			},
			confirmActionCopy: 'Yes',
			cancelActionCopy: 'No'
		},
		{
			type: RETRACT_CODES_MODAL,
			variant: DELETE,
			title: 'Retract Codes',
			body: 'The code batch for this order will be deleted from NPT and the order will be reverted to Payment Received status. Are you sure you would like to proceed?',
			confirmAction: async () => {
				await postRetractDigitalCodes(orderID, (timestamp) => { setAsyncLastUpdate(timestamp); });
				setAsyncLastUpdate(undefined);
				toasterNotify('Downloadable codes have been retracted', 'success');
			},
			confirmActionCopy: 'Yes',
			cancelActionCopy: 'No'
		},
		{
			type: ASPERA_UPLOAD_CODES_MODAL
		},
		{
			type: REJECT_REASON_MODAL
		},
		{
			type: PAYMENT_MODAL
		},
		{
			type: MANAGE_ACTIVATION_MODAL
		},
	];
	return modalSpecs.map(spec => {
		if (spec.variant === CONFIRM || spec.variant === DELETE) {
			const submitVariant = spec.variant === DELETE ? 'danger' : 'primary';
			return (
				<BaseModal
					key={`${spec.type}-modal`}
					map={spec.type}
					show={openModal && spec.type === openModal.type}
					isSubmitting={isSubmitting}
					onCancel={() => closeModal()}
				>
					<BaseModal.Title>{ spec.variant === DELETE
						? <span className="text-danger">{spec.title}</span>
						: spec.title
					}</BaseModal.Title>
					<Alert className="mb-0" variant={submitVariant}>{spec.body}</Alert>
					<BaseModal.SubmissionStatus lastUpdated={asyncLastUpdate || undefined}>
						Processing your request
					</BaseModal.SubmissionStatus>
					<BaseModal.Submit variant={submitVariant} onClick={async () => {
						setIsSubmitting(true);
						try {
							await spec.confirmAction();
							setIsSubmitting(false);
							closeModal();
						} catch (error) {
							setIsSubmitting(false);
							if (!error.isAxiosError) {
								toasterNotify('An error was encountered: ' + error.message + '. Please contact site support.', 'error');
							}
						} finally {
							queryClient.invalidateQueries('getDigitalOrder', { refetchInactive: true });
							queryClient.invalidateQueries('getDigitalOrders', { refetchInactive: true });
						}
					}}>{spec.confirmActionCopy}</BaseModal.Submit>
					<BaseModal.Cancel onClick={() => closeModal()}>{spec.cancelActionCopy}</BaseModal.Cancel>
				</BaseModal>
			);
		} else if (spec.type === ASPERA_UPLOAD_CODES_MODAL) {
			return <UploadDigitalCodesModal
				key={`${spec.type}-modal`}
				show={openModal && spec.type === openModal.type}
				order={order}
				onClose={() => closeModal()}
			/>;
		} else if (spec.type === REJECT_REASON_MODAL) {
			return <RejectOrderModal
				key={`${spec.type}-modal`}
				show={openModal && spec.type === openModal.type}
				order={order}
				onClose={() => closeModal()}
			/>;
		} else if (spec.type === PAYMENT_MODAL) {
			return <PaymentModal
				key={`${spec.type}-modal`}
				show={openModal && spec.type === openModal.type}
				order={order}
				onClose={() => closeModal()}
			/>;
		} else if (spec.type === MANAGE_ACTIVATION_MODAL) {
			return <ManageActivationModal
				key={`${spec.type}-modal`}
				show={openModal && spec.type === openModal.type}
				onClose={() => closeModal()}
				order={order}
				product={product}
				isCodeInBox={isCodeInBox}
				onSubmit={onSubmit}
			/>;
		}
		return null;
	});

};

export default DigitalCodesModals;
