export function getIntegerArrayStartOne(arrayLength: number) {
	return Array.from({ length: arrayLength }, (v, k) => k + 1);
}

export function getIntegerArrayStartZero(arrayLength: number) {
	return Array.from({ length: arrayLength }, (v, k) => k);
}

export function getIntegerArray(arrayLength: number) {
	return Array.from({ length: arrayLength });
}

export function returnArrayItem(array: Array<any>, attr: string) {
	if (array.length > 0) {
		return array[0][attr];
	}
	return [];
}

export function removeItem(array: Array<any>, item: any): Array<any> {
	return array.filter(function(i) {
		return i !== item;
	});
}

/**
 * Creates an array of elements split into groups the length of size. 
 * If array can't be split evenly, the final chunk will be the remaining elements.
 * Similar to lodash's chunk
 * @param  {} array Array source array to be chunked
 * @param  {} maxItems max entries per chunked array
 */
export function chunk(array: Array<any>, maxItems: number = 30): Array<any> {
	const arrayOfArrays: Array<any> = [];
	for (let i = 0; i < Math.ceil(array.length / maxItems); i++) {
		arrayOfArrays.push(array.slice(i * maxItems, (i + 1) * maxItems));
	}
	return arrayOfArrays;
}

export function voidIfEmpty(value: any[]) {
	if (value.length === 0) {
		return;
	}
	return value;
}
