import React, { Component } from 'react';
import { Alert, Col, Form, FormGroup, Row } from 'react-bootstrap';
import * as componentUtils from '../../../../utils/componentUtils';
import * as arrayUtils from '../../../../utils/arrayUtils';
import SectionTitle from '../../../../components/SectionTitle/SectionTitle';

class GameConfigurationWiiU extends Component {
	constructor(props) {
		super(props);
		this.state = {
			payload: this.props.gameConfiguration
		};
	}

	getBody() {
		const { submissionDetails } = this.props;
		const WUP = this.state.payload;
		const numberOfPlayers = componentUtils.generateOptions(
			arrayUtils.getIntegerArrayStartOne(32)
		);
		const numberOfDiscs = componentUtils.generateOptions(
			arrayUtils.getIntegerArrayStartZero(5)
		);

		//Validate the current game configuration and update if invalid value
		if (
			!arrayUtils
				.getIntegerArrayStartOne(32)
				.includes(WUP.Features.NumberOfPlayers)
		) {
			this.props.handleInputChange(['Features', 'NumberOfPlayers'], 1);
		}

		if (
			!arrayUtils
				.getIntegerArrayStartZero(5)
				.includes(WUP.Features.NumberOfDiscs)
		) {
			this.props.handleInputChange(['Features', 'NumberOfDiscs'], 1);
		}

		return (
			<div>
				<Form className="edit_game_configuration_wii_u_form">
					<FormGroup as={Row}>
						<label
							id="submissionVersion"
							className="col-sm-4 text-sm-right"
						>
							Submission Version
						</label>
						<Col sm={6}>
							{'v' +
								submissionDetails.product_release_version +
								'.' +
								submissionDetails.product_submission_version}
						</Col>
					</FormGroup>
					{componentUtils.getSelector(
						['Features', 'NumberOfPlayers'],
						'Number of Players',
						numberOfPlayers,
						this.props.handleInputChange,
						WUP.Features.NumberOfPlayers
					)}
					{componentUtils.getSelector(
						['Features', 'NumberOfDiscs'],
						'Number of Discs',
						numberOfDiscs,
						this.props.handleInputChange,
						WUP.Features.NumberOfDiscs
					)}
					{componentUtils.getCheckbox(
						['Features', 'SDCardSupport'],
						'SD Card Support',
						this.props.handleInputChange,
						WUP.Features.SDCardSupport
					)}
					{componentUtils.getCheckbox(
						['Features', 'DataCapacityExceed32MB'],
						'Data Capacity Exceeds 32 MB?',
						this.props.handleInputChange,
						WUP.Features.DataCapacityExceed32MB
					)}
					{componentUtils.getCheckbox(
						['Features', 'PrincipalStorageSizeExceeds10MB'],
						'Storage Size (Principal Size) exceeds 10 MB?',
						this.props.handleInputChange,
						WUP.Features.PrincipalStorageSizeExceeds10MB
					)}
					{componentUtils.getCheckbox(
						['Features', 'TotalVolumeStorageSizeExceeds100MB'],
						'Storage Size (Total Volume) exceeds 100MB?',
						this.props.handleInputChange,
						WUP.Features.TotalVolumeStorageSizeExceeds100MB
					)}
					{componentUtils.getCheckbox(
						['Features', 'DownloadTotalSizeExceeds512MB'],
						'Download Total Size Exceed 512MB?',
						this.props.handleInputChange,
						WUP.Features.DownloadTotalSizeExceeds512MB
					)}
					{componentUtils.getCheckbox(
						['Features', 'SupportSurroundSound'],
						'Support Surround Sound?',
						this.props.handleInputChange,
						WUP.Features.SupportSurroundSound
					)}
					{componentUtils.getCheckbox(
						['Features', 'UtilizeHavoklibrary'],
						'Utilize Havok library?',
						this.props.handleInputChange,
						WUP.Features.UtilizeHavoklibrary
					)}
					{componentUtils.getCheckbox(
						['Features', 'UtilizeAutodesklibrary'],
						'Utilize Autodesk library?',
						this.props.handleInputChange,
						WUP.Features.UtilizeAutodesklibrary
					)}
					{componentUtils.getCheckbox(
						['Features', 'UtilizeQRCodelibraries'],
						'Utilize QR Code libraries?',
						this.props.handleInputChange,
						WUP.Features.UtilizeQRCodelibraries
					)}
					{componentUtils.getCheckbox(
						['Features', 'UtilizePanasonicRecognitionlibraries'],
						'Utilize Panasonic Recognition libraries?',
						this.props.handleInputChange,
						WUP.Features.UtilizePanasonicRecognitionlibraries
					)}
					{componentUtils.getCheckbox(
						['Features', 'WiiUPedometer'],
						'Wii U Pedometer',
						this.props.handleInputChange,
						WUP.Features.WiiUPedometer
					)}
					<SectionTitle>Wii Controller Features</SectionTitle>
					{componentUtils.getYesNoRequired(
						['WiiControllerFeatures', 'WiiClassicController'],
						'Wii Classic Controller',
						this.props.handleInputChange,
						WUP.WiiControllerFeatures.WiiClassicController
					)}
					{componentUtils.getSelector(
						['WiiControllerFeatures', 'NumberOfRemotesUsed'],
						'Number of Remotes Used',
						componentUtils.generateOptions([
							'None',
							'1',
							'2',
							'3',
							'4'
						]),
						this.props.handleInputChange,
						WUP.WiiControllerFeatures.NumberOfRemotesUsed
					)}
					{componentUtils.getYesNoRequired(
						['WiiControllerFeatures', 'NunchukUse'],
						'Nunchuk Use',
						this.props.handleInputChange,
						WUP.WiiControllerFeatures.NunchukUse
					)}
					{componentUtils.getSelector(
						['WiiControllerFeatures', 'Pointer'],
						'Pointer',
						componentUtils.generateOptions([
							'Yes',
							'No',
							'Home menu only'
						]),
						this.props.handleInputChange,
						WUP.WiiControllerFeatures.Pointer
					)}
					{componentUtils.getYesNoRequired(
						['WiiControllerFeatures', 'MotionSensor'],
						'Motion Sensor',
						this.props.handleInputChange,
						WUP.WiiControllerFeatures.MotionSensor
					)}
					{componentUtils.getSelector(
						['WiiControllerFeatures', 'MotionSensorUse'],
						'Motion Sensor Use',
						componentUtils.generateOptions([
							'(None)',
							'Remote',
							'Nunchuck',
							'Both'
						]),
						this.props.handleInputChange,
						WUP.WiiControllerFeatures.MotionSensorUse
					)}
					{componentUtils.getYesNoRequired(
						['WiiControllerFeatures', 'Rumble'],
						'Rumble',
						this.props.handleInputChange,
						WUP.WiiControllerFeatures.Rumble
					)}
					{componentUtils.getYesNoRequired(
						['WiiControllerFeatures', 'WiiRemoteMemory'],
						'Wii Remote Memory',
						this.props.handleInputChange,
						WUP.WiiControllerFeatures.WiiRemoteMemory
					)}
					{componentUtils.getYesNoRequired(
						['WiiControllerFeatures', 'WiiRemoteSpeaker'],
						'Wii Remote Speaker',
						this.props.handleInputChange,
						WUP.WiiControllerFeatures.WiiRemoteSpeaker
					)}
					{componentUtils.getCheckbox(
						['WiiControllerFeatures', 'WiiBalanceBoardUse'],
						'Wii Balance Board Use',
						this.props.handleInputChange,
						WUP.WiiControllerFeatures.WiiBalanceBoardUse
					)}
					{componentUtils.getSelector(
						['WiiControllerFeatures', 'BalanceBoardPercentPlay'],
						'Balance Board Pct Play',
						componentUtils.generateOptions(['< 20%', '> 20%']),
						this.props.handleInputChange,
						WUP.WiiControllerFeatures.BalanceBoardPercentPlay
					)}
					{componentUtils.getCheckbox(
						['WiiControllerFeatures', 'WiiMotionPlusUse'],
						'Wii MotionPlus Use',
						this.props.handleInputChange,
						WUP.WiiControllerFeatures.WiiMotionPlusUse
					)}
					{componentUtils.getYesNoRequired(
						['WiiControllerFeatures', 'Microphone'],
						'Microphone',
						this.props.handleInputChange,
						WUP.WiiControllerFeatures.Microphone
					)}
					{componentUtils.getCheckbox(
						['WiiControllerFeatures', 'Headset'],
						'Headset',
						this.props.handleInputChange,
						WUP.WiiControllerFeatures.Headset
					)}
					{componentUtils.getSelector(
						['WiiControllerFeatures', 'HeadsetLibrary'],
						'Headset Library',
						componentUtils.generateOptions(['Nintendo', 'Other']),
						this.props.handleInputChange,
						WUP.WiiControllerFeatures.HeadsetLibrary
					)}
					{componentUtils.getTextBox(
						['WiiControllerFeatures', 'OtherHeadsetLibrary'],
						'Other Headset Library',
						this.props.handleInputChange,
						WUP.WiiControllerFeatures.OtherHeadsetLibrary
					)}
					<SectionTitle>Wii U GamePad Features</SectionTitle>
					<Alert>
						Consult Wii U safety guidelines prior to starting
						development
					</Alert>
					{componentUtils.getSelector(
						['GamePadInfo', 'WiiUGamePadSupport'],
						'Wii U GamePad Support',
						componentUtils.generateOptions([
							{ value: 1, label: '1' },
							{ value: 2, label: '2' },
							{ value: 0, label: 'No' }
						]),
						this.props.handleInputChange,
						WUP.GamePadInfo.WiiUGamePadSupport
					)}
					{componentUtils.getTextBox(
						['GamePadInfo', 'WiiUGamePadSupportDescription'],
						'Wii U GamePad Support Description',
						this.props.handleInputChange,
						WUP.GamePadInfo.WiiUGamePadSupportDescription
					)}
					{componentUtils.getCheckbox(
						['GamePadInfo', 'TouchScreen'],
						'Touch Screen',
						this.props.handleInputChange,
						WUP.GamePadInfo.TouchScreen
					)}
					{componentUtils.getTextBox(
						['GamePadInfo', 'TouchScreenDescription'],
						'Touch Screen Description',
						this.props.handleInputChange,
						WUP.GamePadInfo.TouchScreenDescription
					)}
					{componentUtils.getCheckbox(
						['GamePadInfo', 'GamePadMicrophone'],
						'GamePad Microphone',
						this.props.handleInputChange,
						WUP.GamePadInfo.GamePadMicrophone
					)}
					{componentUtils.getTextBox(
						['GamePadInfo', 'MicrophoneDescription'],
						'Microphone Description',
						this.props.handleInputChange,
						WUP.GamePadInfo.MicrophoneDescription
					)}
					{componentUtils.getCheckbox(
						['GamePadInfo', 'GamePadCamera'],
						'GamePad Camera',
						this.props.handleInputChange,
						WUP.GamePadInfo.GamePadCamera
					)}
					{componentUtils.getTextBox(
						['GamePadInfo', 'CameraDescription'],
						'Camera Description',
						this.props.handleInputChange,
						WUP.GamePadInfo.CameraDescription
					)}
					{componentUtils.getCheckbox(
						['GamePadInfo', 'AccelerometerGyro'],
						'Accelerometer / Gyro',
						this.props.handleInputChange,
						WUP.GamePadInfo.AccelerometerGyro
					)}
					{componentUtils.getTextBox(
						['GamePadInfo', 'AccelerometerGyroDescription'],
						'Accelerometer / Gyro Description',
						this.props.handleInputChange,
						WUP.GamePadInfo.AccelerometerGyroDescription
					)}
					<SectionTitle>
						Please provide detailed explanation for the areas listed
						below:
					</SectionTitle>
					{componentUtils.getCheckbox(
						[
							'GamePadUsageStyle',
							'UserTurnsAroundQuicklyWhileHoldingTheGamePad'
						],
						'User turns around quickly while holding the GamePad',
						this.props.handleInputChange,
						WUP.GamePadUsageStyle
							.UserTurnsAroundQuicklyWhileHoldingTheGamePad
					)}
					{componentUtils.getTextBox(
						[
							'GamePadUsageStyle',
							'UserTurnsAroundQuicklyWhileHoldingTheGamePadDescription'
						],
						'User turns around quickly while holding the GamePad Description',
						this.props.handleInputChange,
						WUP.GamePadUsageStyle
							.UserTurnsAroundQuicklyWhileHoldingTheGamePadDescription
					)}
					{componentUtils.getCheckbox(
						[
							'GamePadUsageStyle',
							'UserHoldsOrMovesTheGamePadAboveTheirHead'
						],
						'User holds or moves the GamePad above their head',
						this.props.handleInputChange,
						WUP.GamePadUsageStyle
							.UserHoldsOrMovesTheGamePadAboveTheirHead
					)}
					{componentUtils.getTextBox(
						[
							'GamePadUsageStyle',
							'UserHoldsOrMovesTheGamePadAboveTheirHeadDescription'
						],
						'User holds or moves the GamePad above their head Description',
						this.props.handleInputChange,
						WUP.GamePadUsageStyle
							.UserHoldsOrMovesTheGamePadAboveTheirHeadDescription
					)}
					{componentUtils.getCheckbox(
						[
							'GamePadUsageStyle',
							'UserUsesGamePadAndWiiBalanceBoardAtTheSameTime'
						],
						'User uses GamePad and Wii Balance Board at the same time?',
						this.props.handleInputChange,
						WUP.GamePadUsageStyle
							.UserUsesGamePadAndWiiBalanceBoardAtTheSameTime
					)}
					{componentUtils.getTextBox(
						[
							'GamePadUsageStyle',
							'UserUsesGamePadAndWiiBalanceBoardAtTheSameTimeDescription'
						],
						'User uses GamePad and Wii Balance Board at the same time Description',
						this.props.handleInputChange,
						WUP.GamePadUsageStyle
							.UserUsesGamePadAndWiiBalanceBoardAtTheSameTimeDescription
					)}
					{componentUtils.getCheckbox(
						[
							'GamePadUsageStyle',
							'UserPlacesTheGamePadOnTheFloorAndUsesItInThatPosition'
						],
						'User places the GamePad on the floor and uses it in that position?',
						this.props.handleInputChange,
						WUP.GamePadUsageStyle
							.UserPlacesTheGamePadOnTheFloorAndUsesItInThatPosition
					)}
					{componentUtils.getTextBox(
						[
							'GamePadUsageStyle',
							'UserPlacesTheGamePadOnTheFloorDescription'
						],
						'User places the GamePad on the floor Description',
						this.props.handleInputChange,
						WUP.GamePadUsageStyle
							.UserPlacesTheGamePadOnTheFloorDescription
					)}
					{componentUtils.getCheckbox(
						[
							'GamePadUsageStyle',
							'OtherUsageThatMayIndicateSafetyConcernsSuchAsStyleAndMovementOrRapidMovementsInGeneral'
						],
						'Other usage that may indicate safety concerns such as style and movement or rapid movements in general',
						this.props.handleInputChange,
						WUP.GamePadUsageStyle
							.OtherUsageThatMayIndicateSafetyConcernsSuchAsStyleAndMovementOrRapidMovementsInGeneral
					)}
					{componentUtils.getTextBox(
						[
							'GamePadUsageStyle',
							'OtherUsageThatMayIndicateSafetyConcernsDescription'
						],
						'Other usage that may indicate safety concerns Description',
						this.props.handleInputChange,
						WUP.GamePadUsageStyle
							.OtherUsageThatMayIndicateSafetyConcernsDescription
					)}
					<SectionTitle>Nintendo 3DS Communication</SectionTitle>
					{componentUtils.getCheckbox(
						[
							'Nintendo3DSCommunication',
							'WiiUToNintendo3DSDownload'
						],
						'Wii U to Nintendo 3DS Download',
						this.props.handleInputChange,
						WUP.Nintendo3DSCommunication.WiiUToNintendo3DSDownload
					)}
					{componentUtils.getCheckbox(
						[
							'Nintendo3DSCommunication',
							'CommunicationWithNintendo3DSTitle'
						],
						'Communication with Nintendo 3DS Title',
						this.props.handleInputChange,
						WUP.Nintendo3DSCommunication
							.CommunicationWithNintendo3DSTitle
					)}
					{componentUtils.getTextBox(
						[
							'Nintendo3DSCommunication',
							'CommunicationWithNintendo3DSTitleDescription'
						],
						'Communication with Nintendo 3DS Title Description',
						this.props.handleInputChange,
						WUP.Nintendo3DSCommunication
							.CommunicationWithNintendo3DSTitleDescription
					)}
					{componentUtils.getTextBox(
						[
							'Nintendo3DSCommunication',
							'AssociatedNintendo3DSTitleAndGameCode'
						],
						'Associated Nintendo 3DS Title and Game Code (if available)',
						this.props.handleInputChange,
						WUP.Nintendo3DSCommunication
							.AssociatedNintendo3DSTitleAndGameCode
					)}
					<SectionTitle>Online Features</SectionTitle>
					{componentUtils.getCheckbox(
						['OnlineFeatures', 'OnlineMultiPlay'],
						'Online Multi Play',
						this.props.handleInputChange,
						WUP.OnlineFeatures.OnlineMultiPlay
					)}
					{componentUtils.getTextBox(
						['OnlineFeatures', 'OnlineMultiPlayDescription'],
						'Online Multi Play Description',
						this.props.handleInputChange,
						WUP.OnlineFeatures.OnlineMultiPlayDescription
					)}
					{componentUtils.getCheckbox(
						[
							'OnlineFeatures',
							'OnlineCommerceAdditionalOnlineContentsMicrotransactions'
						],
						'Online Commerce (Additional Online Contents/Microtransactions)',
						this.props.handleInputChange,
						WUP.OnlineFeatures
							.OnlineCommerceAdditionalOnlineContentsMicrotransactions
					)}
					{componentUtils.getTextBox(
						['OnlineFeatures', 'OnlineCommerceDescription'],
						'Online Commerce Description',
						this.props.handleInputChange,
						WUP.OnlineFeatures.OnlineCommerceDescription
					)}
					{componentUtils.getCheckbox(
						['OnlineFeatures', 'ConsumableAdditionalOnlineContent'],
						'Consumable AOC',
						this.props.handleInputChange,
						WUP.OnlineFeatures.ConsumableAdditionalOnlineContent
					)}
					{componentUtils.getTextBox(
						[
							'OnlineFeatures',
							'ConsumableAdditionalOnlineContentDescription'
						],
						'Consumable AOC Description',
						this.props.handleInputChange,
						WUP.OnlineFeatures
							.ConsumableAdditionalOnlineContentDescription
					)}
					{componentUtils.getCheckbox(
						['OnlineFeatures', 'UserGeneratedContentSimple'],
						'UGC (User Generated Content) - Simple',
						this.props.handleInputChange,
						WUP.OnlineFeatures.UserGeneratedContentSimple
					)}
					{componentUtils.getTextBox(
						[
							'OnlineFeatures',
							'UserGeneratedContentSimpleDescription'
						],
						'UGC Simple Description',
						this.props.handleInputChange,
						WUP.OnlineFeatures.UserGeneratedContentSimpleDescription
					)}
					{componentUtils.getCheckbox(
						['OnlineFeatures', 'RichUserGeneratedContent'],
						'Rich UGC',
						this.props.handleInputChange,
						WUP.OnlineFeatures.RichUserGeneratedContent
					)}
					{componentUtils.getTextBox(
						[
							'OnlineFeatures',
							'RichUserGeneratedContentDescription'
						],
						'Rich UGC Description',
						this.props.handleInputChange,
						WUP.OnlineFeatures.RichUserGeneratedContentDescription
					)}
					{componentUtils.getCheckbox(
						['OnlineFeatures', 'OtherOnlineFunctionality'],
						'Other Online Functionality',
						this.props.handleInputChange,
						WUP.OnlineFeatures.OtherOnlineFunctionality
					)}
					{componentUtils.getTextBox(
						[
							'OnlineFeatures',
							'OtherOnlineFunctionalityDescription'
						],
						'Other Online Functionality Description',
						this.props.handleInputChange,
						WUP.OnlineFeatures.OtherOnlineFunctionalityDescription
					)}
					{componentUtils.getCheckbox(
						['OnlineFeatures', 'PlanForPatching'],
						'Plan for Patching',
						this.props.handleInputChange,
						WUP.OnlineFeatures.PlanForPatching
					)}
					{componentUtils.getTextBox(
						['OnlineFeatures', 'PlanForPatchingDescription'],
						'Plan for Patching Description',
						this.props.handleInputChange,
						WUP.OnlineFeatures.PlanForPatchingDescription
					)}
					{componentUtils.getCheckbox(
						['OnlineFeatures', 'MiiUsage'],
						'Mii Usage',
						this.props.handleInputChange,
						WUP.OnlineFeatures.MiiUsage
					)}
					{componentUtils.getCheckbox(
						['OnlineFeatures', 'MiiApproval'],
						'Mii Approval',
						this.props.handleInputChange,
						WUP.OnlineFeatures.MiiApproval
					)}
					<SectionTitle>Miiverse Features</SectionTitle>
					{componentUtils.getCheckbox(
						['MiiverseFeatures', 'MiiverseIntegration'],
						'Miiverse Integration',
						this.props.handleInputChange,
						WUP.MiiverseFeatures.MiiverseIntegration
					)}
					{componentUtils.getTextBox(
						['MiiverseFeatures', 'MiiverseIntegrationDescription'],
						'Miiverse Integration Description',
						this.props.handleInputChange,
						WUP.MiiverseFeatures.MiiverseIntegrationDescription
					)}
					{componentUtils.getCheckbox(
						['MiiverseFeatures', 'MiiverseMessageBoardSupport'],
						'Miiverse Message Board Support',
						this.props.handleInputChange,
						WUP.MiiverseFeatures.MiiverseMessageBoardSupport
					)}
					{componentUtils.getTextBox(
						[
							'MiiverseFeatures',
							'MiiverseMessageBoardSupportDescription'
						],
						'Miiverse Message Board Support Description',
						this.props.handleInputChange,
						WUP.MiiverseFeatures
							.MiiverseMessageBoardSupportDescription
					)}
					{componentUtils.getCheckbox(
						['MiiverseFeatures', 'MessageBoardPlayerToPlayer'],
						'Message Board Player to Player',
						this.props.handleInputChange,
						WUP.MiiverseFeatures.MessageBoardPlayerToPlayer
					)}
					{componentUtils.getTextBox(
						[
							'MiiverseFeatures',
							'MessageBoardPlayerToPlayerDescription'
						],
						'Message Board Player to Player Description',
						this.props.handleInputChange,
						WUP.MiiverseFeatures
							.MessageBoardPlayerToPlayerDescription
					)}
					{componentUtils.getCheckbox(
						['MiiverseFeatures', 'MessageBoardPlayerToCommunity'],
						'Message Board Player to Community',
						this.props.handleInputChange,
						WUP.MiiverseFeatures.MessageBoardPlayerToCommunity
					)}
					{componentUtils.getTextBox(
						[
							'MiiverseFeatures',
							'MessageBoardPlayerToCommunityDescription'
						],
						'Message Board Player to Community Description',
						this.props.handleInputChange,
						WUP.MiiverseFeatures
							.MessageBoardPlayerToCommunityDescription
					)}
					{componentUtils.getTextBox(
						[
							'MiiverseFeatures',
							'PrivateCommunitySupportDescription'
						],
						'Private Community Support Description',
						this.props.handleInputChange,
						WUP.MiiverseFeatures.PrivateCommunitySupportDescription
					)}
					{componentUtils.getCheckbox(
						[
							'MiiverseFeatures',
							'SharingOfUserGeneratedContentInTheMiiverse'
						],
						'Sharing of UGC in the Miiverse',
						this.props.handleInputChange,
						WUP.MiiverseFeatures
							.SharingOfUserGeneratedContentInTheMiiverse
					)}
					{componentUtils.getTextBox(
						[
							'MiiverseFeatures',
							'SharingOfUserGeneratedContentInTheMiiverseDescription'
						],
						'Sharing of UGC in the Miiverse Description',
						this.props.handleInputChange,
						WUP.MiiverseFeatures
							.SharingOfUserGeneratedContentInTheMiiverseDescription
					)}
				</Form>
			</div>
		);
	}

	render() {
		return <div>{this.getBody()}</div>;
	}
}

export default GameConfigurationWiiU;
