import React, { useEffect, useState } from 'react';

import { roleConstants } from '../../../constants/roleConstants';
import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import { getRoleAssignedUsers } from '../../../services/usersService';
import { getEventDetails, putUpdateEventSupport } from '../../../services/eventsService';
import { toasterNotify } from '../../../utils/toaster';


const ManageSupportModal = ({eventId, onClose, canEdit, show}) => {
	const [formValues, setFormValues] = useState({});
	const [supportUsers, setSupportUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [fault, setFault] = useState(null);

	useEffect(() => {
		if (show) {
			loadData();
		}
	}, [show]);

	const loadData = async () => {
		setIsLoading(true);
		try {
			const calls = [];
			calls.push(
                getRoleAssignedUsers(roleConstants.MARKETING_EVENTS_SUPPORT).then(results => setSupportUsers(results.data))
			);
			calls.push(
                getEventDetails(eventId).then(results => setFormValues({support: results.data.support}))
			);
			await Promise.all(calls);
		} catch (err) {
			setFault(err.toString());
		} finally {
			setIsLoading(false);
		}
	};

	const submitForm = async () => {
		setIsSubmitting(true);
		try {
			await putUpdateEventSupport(eventId, formValues['support']);
			toasterNotify('Support Access members have been changed.', 'success');
			onClose();
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
        <BaseModal
            show={show}
            onCancel={e => onClose()}
            fault={fault}
            isLoading={isLoading}
            isSubmitting={isSubmitting}
        >
            <BaseModal.Title>Manage Support</BaseModal.Title>

            <Forms
                vertical
                values={formValues}
                onChange={values => setFormValues(values)}
                isSubmitting={isSubmitting}
            >
                <Forms.MultiSelect
                    id={'support'}
					disabled={!canEdit}
					placeholder="Select Support Member(s)"
					clearable
                >
                    <Forms.Heading>Support Access</Forms.Heading>
                    { supportUsers.map(user => <Forms.Option value={user.ndid_user_id}>{user.user_name}</Forms.Option>) }
                </Forms.MultiSelect>
            </Forms>

            <BaseModal.Submit
                onClick={e => submitForm()}
            >
                Save
            </BaseModal.Submit>
        </BaseModal>
	);
};

export default ManageSupportModal;
