import React, { useState } from 'react';

import Page from '../../components/Page/Page';

import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
	const [cookieEnabled, setCookieEnabled] = useState();
	function updateTrackingCookie() {
		if (cookieEnabled === 1) {
			setCookie('trackEssentialCookie', 'enabled', 99999);
		} else {
			setCookie('trackEssentialCookie', 'disabled', 99999);
		}
		if (window.location.href.includes('#googleAnalytics')) {
			window.location.reload(true);
		} else {
			window.location.href += '#googleAnalytics';
			window.location.reload(true);
		}
	}

	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
		var expires = 'expires=' + d.toUTCString();
		document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
	}

	return (
		<Page documentTitle="Nintendo Developer Privacy Notice">
			<Page.FullPageCol>
				<div className="nin-section-content">
					<h2 className="mb-4">Nintendo Developer Privacy Notice</h2>
					<p>
						<strong>Last updated:</strong> December 2022
					</p>

					<p>
						Nintendo knows that privacy is important to you. This Nintendo Developer
						Privacy Notice ("<strong>Privacy Notice</strong>") describes how Nintendo
						Co., Ltd. ("<strong>Nintendo</strong>", "<strong>we</strong>", "
						<strong>us</strong>" or "<strong>our</strong>") as the controller and with
						registered offices at 11-1 Hokotate-cho, Kamitoba, Minami-ku, Kyoto
						601-8501, Japan, collects, uses, discloses, stores, processes and protects
						your information when you visit our website at{' '}
						<a href="https://developer.nintendo.com/">https://developer.nintendo.com</a>{' '}
						or use any of the services available through, or ancillary to, such websites
						(our "<strong>Services</strong>").
					</p>
					<p>The Services include</p>

					<ul>
						<li>
							Provision, access to and usage of development tools, middleware and SDK
							(collectively, the “Development Tools”);
						</li>
						<li>Provision of online documentation;</li>
						<li>Technical support;</li>
						<li>Support for Submission of a game or an application;</li>
						<li>
							Support for sales of a game or an application which was approved by us;
						</li>
						<li>
							Support for provision of service of a game or an application which was
							approved by us.
						</li>
					</ul>

					<p>
						This Privacy Notice also addresses requirements under General Data
						Protection Regulation (Regulation (EU) 2016/679 of the European Parliament
						and of the Council of 27 April 2016 – “GDPR”), Lei Geral de Proteção de
						Dados (“LGPD”), Act on Protection of Personal Information ("APPI"), the
						California Privacy Rights Act (“CPRA”) or any other applicable data
						protection laws in the jurisdiction in which you reside (collectively
						referred to hereinafter as “Applicable Data Protection Law”).
					</p>

					<p>
						<strong>1.&nbsp; YOUR INFORMATION</strong>
					</p>

					<div>
						<p>
							We collect personal data and other information that you provide to us
							when you use our Services, including when you complete our registration
							forms, use our Development Tools and anytime you are in contact with us.
							The types or categories of information we collect include:
						</p>

						<ul>
							<li>
								<strong>Registration information</strong> that you provide when
								signing up to use our Services, including your name, username,
								screen name, e-mail address, postal address, professional details
								(such as organizational name and job title) and other contact
								details you provide. The provision of the registration information
								is necessary to sign up to use our Services;
							</li>
							<li>
								<strong>Login information</strong> that you use to sign in to our
								Services, such as your username and password;
							</li>
							<li>
								<strong>Public profile information</strong> displayed about you when
								you use parts of our Services that support the communication between
								users and the sharing of information with others, such as your
								screen name and any other profile information that you voluntarily
								choose to share;
							</li>
							<li>
								<strong>Project information</strong> about proposed game or
								application developments and details of developments that you are
								currently working on;
							</li>
							<li>
								<strong>Legal information</strong> concerning any legal agreements
								that you execute in order to use the website and the Services (such
								as the Nintendo Developer Portal&rsquo;s Terms of Service and
								Non-Disclosure Agreement);
							</li>
							<li>
								<strong>Payment Information</strong> such as your bank account
								information that you provide to us through the Services in order for
								us to issue payments to you;
							</li>
							<li>
								<strong>Usage Information</strong> related to your use of our
								Services, such as crash and error reports, technical information
								about the devices you use to develop applications, log files and
								other usage information generated by your interactions with the
								Services;
							</li>
							<li>
								<strong>Correspondence</strong> you send directly to us and that we
								send to you;
							</li>
							<li>
								<strong>Any other information</strong> you choose to provide
								directly to us.
								<p>
									For the legal basis of the collection of this information under
									General Data Protection Regulation (Regulation (EU) 2016/679 of
									the European Parliament and of the Council of 27 April 2016 – “
									<strong>GDPR</strong>”) if you are located in the European Union
									or under Lei Geral de Proteção de Dados (“<strong>LGPD</strong>
									”) if you are located in Brazil (GDPR and LGPD together “
									<strong>Applicable Data Protection Law</strong>”), please see
									Section 3. below.
								</p>
							</li>
						</ul>
					</div>

					<p id="cookie">
						<strong>2.&nbsp; COOKIES &amp; LOG FILES</strong>
					</p>

					<p>
						<strong>
							<u>Cookies:</u>
						</strong>{' '}
						Cookies are small files downloaded to your computer to track movements
						within websites, to keep track of the fact that you have signed in so you do
						not need to continually enter your username and password, and to store the
						language in which you choose to view the website. Most browsers are
						automatically set to accept cookies.
					</p>

					<p>
						Most of the cookies that we serve are strictly necessary to provide you with
						the Services you have requested. For example, we serve cookies that enable
						us to authenticate you when you use our Services and to maintain your
						website session. We also serve cookies that enable us to remember any
						language preferences you specify so that we present the Services in your
						preferred language. If you are located in a country of the European Union or
						in Brazil, the legal basis for using these cookies is the provision of the
						Service you have requested pursuant to Applicable Data Protection Law (i.e.,
						Art. 6 para. 1 (b) GDPR or Art. 8, §5 LGPD). We do not serve any cookies for
						advertising purposes.
					</p>

					<p>
						You can often set your browser to notify you when you are sent a cookie, and
						you may also be able to elect not to accept cookies (including the cookies
						described above) by changing the designated settings on your web browser.
						However, not utilizing cookies may prevent you from using certain functions
						and features of the Services.
					</p>

					<p>
						<strong>
							<u>Log Files:</u>
						</strong>{' '}
						Certain information is transmitted to us by your internet browser each time
						you visit our website, including the date and time of your visit, the name
						of the pages and files that you accessed, the website you came from, the
						type of internet browser and operating system you used and the IP address.
						For the legal basis of the collection of Log Files under Applicable Data
						Protection Law, please see Section 3. below.
					</p>

					<p>
						<strong>3.&nbsp; PROCESS OF INFORMATION</strong>
					</p>

					<p>(a) We process the collected information in order to:</p>

					<ul>
						<li>Register you with, and provide you with access to, the Services;</li>
						<li>Review your application to become an approved Nintendo developer;</li>
						<li>Review any projects that you submit through the Services;</li>
						<li>
							Provide you with access to the Development Tools through the Services;
						</li>
						<li>Issue payments to you;</li>
						<li>
							Contact you to answer your inquiries or forward any requested
							information;
						</li>
						<li>Provide you with technical support including repair services.</li>
					</ul>

					<p style={{ marginLeft: '25px' }}>
						If you are located in a country of the European Union or in Brazil, the
						legal basis for the processing pursuant to this Section 3 (a) is the
						performance or execution of a contract as provided under Applicable Data
						Protection Law (i.e., Art. 6 para. 1 (b) GDPR or Art. 8, §5 LGPD).
					</p>

					<p>
						(b) We also process the collected information as far as this is necessary
						to:
					</p>

					<ul>
						<li>Prevent potentially illegal or offensive activities;</li>
						<li>Enforce our rights or the rights of other users of the Services;</li>
					</ul>

					<p style={{ marginLeft: '25px' }}>
						If you are located in a country of the European Union or in Brazil, the
						legal basis for the processing pursuant to this Section 3 (b) is legitimate
						interest as provided under Applicable Data Protection Law (i.e., Art. 6
						para. 1 (f) GDPR or Art. 8, §9 LGPD). The legitimate interest we pursue is
						the prevention of fraudulent and illegal activities, the protection of our
						systems and of our users and the enforcement of our legitimate rights and
						the rights of our users.
					</p>

					<p style={{ marginLeft: '25px' }}>
						<strong>
							If you are located in a country of the European Union or in Brazil, you
							may have the right to object, on grounds relating to your particular
							situation, at any time to the processing pursuant to this Section 3 (b).
							To exercise this right you may at any time contact our European or
							Brazilian representative as provided for in Section 11 (b) below.{' '}
						</strong>
					</p>

					<p>
						(c) We further process the collected information (excluding, however, your
						login information, your payment information, your legal information and your
						correspondence) to perform analytics and statistics of the usage of our
						Services in order to improve and optimize the Services and in order to
						improve and optimize the environment of our Development Tools we provide you
						through the Services.
					</p>

					<p style={{ marginLeft: '25px' }}>
						If you are located in a country of the European Union or in Brazil, the
						legal basis for the processing pursuant to this Section 3 (c) is legitimate
						interest as provided under Applicable Data Protection Law (i.e., Art. 6
						para. 1 (f) GDPR or Art. 8, §9 LGPD). The legitimate interest we pursue is
						the improvement and optimization of the Services which is to the benefit of
						the users.
					</p>

					<p style={{ marginLeft: '25px' }}>
						<strong>
							If you are located in a country of the European Union or in Brazil, you
							may have the right to object, on grounds relating to your particular
							situation, at any time to the processing pursuant to this Section 3 (c).
							To exercise this right you may at any time contact our European or
							Brazilian representative as provided for in Section 11 (b) below.
						</strong>
					</p>

					<p>
						(d) We generally process the collected information for the purpose of
						ensuring the security and proper functioning of our Services.
					</p>

					<p style={{ marginLeft: '25px' }}>
						If you are located in a country of the European Union or in Brazil, the
						legal basis for the processing pursuant to this Section 3 (d) is legitimate
						interest as provided under Applicable Data Protection Law (i.e., Art. 6
						para. 1 (f) GDPR or Art. 8, §9 LGPD). The legitimate interest we pursue is
						the protection of our systems.
					</p>

					<p style={{ marginLeft: '25px' }}>
						<strong>
							If you are located in a country of the European Union or in Brazil, you
							may have the right to object, on grounds relating to your particular
							situation, at any time to the processing pursuant to this Section 3 (d).
							To exercise this right you may at any time contact our European or
							Brazilian representative as provided for in Section 11 (b) below.
						</strong>
					</p>

					<p>
						(e) We may process the registration information collected, specifically your
						e-mail address and name, or other contact information you shared with us for
						such purpose (and with your consent, where applicable), to contact you to
						solicit your feedback in connection with your use of our Services. We will
						process the feedback you provide to improve our Services. In some cases, we
						might contact you again to follow-up on your particular feedback.
					</p>

					<p style={{ marginLeft: '25px' }}>
						If you are located in a country of the European Union, the legal basis for
						the processing pursuant to this Section 3 (e) is your consent as provided
						under Art. 6 para. 1 (a) of the GDPR. If you are located in Brazil, the
						legal basis for the processing pursuant to this Section 3 (e) is the
						performance of a contract as provided under Art. 8, §5 of the LGPD.
					</p>

					<p>
						<strong>4.&nbsp; DISCLOSURE OF INFORMATION</strong>
					</p>

					<p>
						<strong>Nintendo Affiliates.</strong> At times we may make certain
						information that we collect available to our affiliated companies as
						follows:
					</p>

					<p>
						We may share information with other Nintendo affiliated companies (including
						but not limited to Nintendo of Europe GmbH, Nintendo of America Inc.,
						Nintendo Australia Pty Limited, Nintendo of Korea Co., Ltd., Nintendo
						Technology Development Inc. and Nintendo Sales Co., Ltd.) to process for
						purposes that are consistent with those disclosed in this Privacy Notice. We
						are responsible for managing and handling your information in accordance
						with this Privacy Notice and the companies with which we share your
						information.
					</p>

					<p style={{ marginLeft: '25px' }}>
						If you are located in a country of the European Union or in Brazil, the
						legal basis for sharing your information with our affiliated companies is
						legitimate interest as provided under Applicable Data Protection Law (i.e.,
						Art. 6 para. 1 (f) GDPR or Art. 8, §9 LGPD). We have a legitimate interest
						in sharing your information with our affiliated companies for internal
						administrative purposes, in particular to offer the Services to users and to
						deal with inquiries in an efficient and high-quality manner. Where we share
						your information with an affiliated company located outside the European
						Economic Area or outside of Brazil, respectively, appropriate safeguards for
						an adequate level of data protection are provided for by standard data
						protection clauses that have been adopted by the European Commission or the
						Brazilian data protection authority and that have been entered into between
						Nintendo and its affiliated company.
					</p>

					<p style={{ marginLeft: '25px' }}>
						<strong>
							If you are located in a country of the European Union or in Brazil, you
							may have the right to object, on grounds relating to your particular
							situation, at any time to such sharing. To exercise this right you may
							at any time contact our European or Brazilian representative as provided
							for in Section 11 (b) below.
						</strong>
					</p>

					<p>
						<strong>Legal and business purposes.</strong> We may disclose your
						information in order to comply with legal process, to respond to claims or
						inquiries, or to protect the rights, property or personal safety of
						Nintendo, our users, or the public. Your information may also be disclosed
						as part of any sale or transfer of company assets, if legally permitted.
					</p>

					<p style={{ marginLeft: '25px' }}>
						If you are located in a country of the European Union or in Brazil, as far
						as we are required by law to disclose your information, the legal basis is
						our compliance with a legal obligation as provided under Applicable Data
						Protection Law (i.e., Art. 6 para. 1 (c) GDPR or Art. 8, §2 LGPD). If you
						are located in a country of the European Union or in Brazil, as far as the
						disclosure is necessary for the purpose of responding to claims or inquiries
						or protecting the rights, property or personal safety of Nintendo, our
						users, or the public, or for the sale or transfer of company assets, the
						legal basis for the processing is legitimate interest as provided under
						Applicable Data Protection Law (i.e., Art. 6 para. 1 (f) GDPR or Art. 8, §9
						LGPD). The legitimate interest we pursue is the effective enforcement of
						legal claims and exercise of our rights and the rights of our users,
						safeguarding our security and the security of our users and the commercial
						exploitation of our business assets via sale or transfer.
					</p>

					<p style={{ marginLeft: '25px' }}>
						<strong>
							If you are located in a country of the European Union or in Brazil, you
							may have the right to object, on grounds relating to your particular
							situation, at any time to the processing pursuant to this Section as far
							as it is based on our legitimate interests. To exercise this right you
							may at any time contact our European or Brazilian representative as
							provided for in Section 11 (b) below.
						</strong>
					</p>

					<p>
						<strong>Third Party Data Processors.</strong> We do not, and will not, sell
						your personal data to third parties. However, in compliance with applicable
						data protection requirements, we may share information with third party data
						processors acting on our behalf that either provide us with services in
						connection with the development, operation and delivery of the Services, or
						that are authorized to sell on our behalf Development Tools to you.
					</p>

					<p style={{ marginLeft: '25px' }}>
						If you are located in a country of the European Union or in Brazil, where
						such third party processors are located outside the European Economic Area
						or outside of Brazil, respectively, such third party processors are either
						located in a third country where the European Commission or the Brazilian
						data protection authority, respectively, has decided that this country
						ensures an adequate level of data protection or appropriate safeguards for
						an adequate level of data protection are provided for by standard data
						protection clauses that have been adopted by the European Commission or the
						Brazilian data protection authority and that have been entered into between
						Nintendo and the third party data processor.
					</p>

					<p>
						You are responsible for any information that you share with others through
						use of our Services.
					</p>

					<p>
						<strong>5.&nbsp; GOOGLE ANALYTICS</strong>
					</p>

					<p>
						Our website uses Google Analytics, a web analysis service provided by Google
						Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland
						(“Google Ireland”), within the European Economic Area and Switzerland and
						otherwise provided by of Google LLC (1600 Amphitheatre Parkway, Mountain
						View, CA 94043, USA; “Google”). Google Analytics uses cookies that are
						stored on your computer and facilitate the analysis of your use of our
						website. The information generated by the cookie about your use of our
						website is transmitted to Google Ireland / Google and may be stored by
						Google on servers located in the United States. IP anonymization has been
						activated on our website and as a result, your IP address will be shortened.
						Only in exceptional cases will a complete IP address be transferred to a
						server by Google in the United States and shortened there. On our behalf,
						Google Ireland / Google will use this information for the purpose of
						evaluating the website for us, in order to compile reports on website
						activity, and to provide other services relating to website activity and
						internet usage for us. Google Ireland / Google will not associate the IP
						address transferred in the context of Google Analytics with any other data
						held by Google Ireland / Google. Google Analytics will only be enabled if
						you consent to its use. If you permitted the use of Google Analytics, you
						can disable Google Analytics at any time by downloading and installing a
						browser plug-in through the following link:{' '}
						<a href="http://tools.google.com/dlpage/gaoptout?hl=en">
							http://tools.google.com/dlpage/gaoptout?hl=en
						</a>
						.
					</p>

					<p>
						For the avoidance of doubt, if you are located in a country of the European
						Union or in Brazil, the legal basis for enabling Google Analytics is your
						consent as provided under Applicable Data Protection Law (i.e., Art. 6 para.
						1 (a) GDPR or Art. 8, §1 LGPD). Further, if you are located in a country of
						the European Union or in Brazil, the legal basis for the subsequent
						processing of the data collected via Google Analytics such as usage,
						analysis and storage is legitimate interest under Applicable Data Protection
						Law (i.e., Art. 6 para. 1 (f) GDPR or Art. 8, §9 LGPD). The legitimate
						interest Nintendo pursues is the improvement and optimization of the website
						for the benefit of the website visitors.
					</p>

					<p>
						<strong>
							If you are located in a country of the European Union or in Brazil, you
							may have the right to object, on grounds relating to your particular
							situation, at any time to the processing pursuant to this Section. To
							exercise this right you may download and install the aforementioned
							browser plug-in or you may at any time deactivate the use of
							non-essential cookies in the cookie settings below.
						</strong>
					</p>

					<p>
						For further information please visit{' '}
						<a href="https://www.google.com/analytics/terms/">
							https://www.google.com/analytics/terms/
						</a>{' '}
						or{' '}
						<a href="https://support.google.com/analytics/answer/6004245?hl=en">
							https://support.google.com/analytics/answer/6004245?hl=en
						</a>
					</p>

					<div
						className="col-xs-12 col-sm-9 offset-sm-1 mb-3 pt-2"
						id="cookieOptions"
						style={{
							border: '1px solid var(--nin-color-ltgray1)',
							borderRadius: '.25rem',
						}}
					>
						<p>
							Please select one of the two options below and press the "Save" button
							to confirm your settings.
						</p>

						<div className="form_field radio">
							<label htmlFor="tCookieOff">
								<input
									id="tCookieOff"
									name="tCookie"
									type="radio"
									value="0"
									checked={cookieEnabled === 0}
									onChange={() => setCookieEnabled(0)}
								/>{' '}
								<strong>Non-essential cookies deactivated</strong>
							</label>
						</div>

						<div className="form_field radio">
							<label htmlFor="tCookieOn">
								<input
									id="tCookieOn"
									name="tCookie"
									type="radio"
									value="1"
									checked={cookieEnabled === 1}
									onChange={() => setCookieEnabled(1)}
								/>{' '}
								<strong>Non-essential cookies activated</strong>
							</label>
						</div>
						<button
							className="btn btn-primary"
							onClick={() => updateTrackingCookie()}
							style={{ margin: '10px auto' }}
						>
							Save
						</button>
					</div>

					<p>
						<strong>6.&nbsp; GOOGLE RECAPTCHA</strong>
					</p>

					<p>
						Our website also uses the Google reCAPTCHA service on its website. Within
						the European Economic Area and Switzerland, this service is provided by
						Google Ireland. Outside of these regions, Google offers the reCAPTCHA
						service. reCAPTCHA is used to determine whether the data entered on the
						website, in particular in any forms provided on the website, has been
						entered by a human or by an automated program. reCAPTCHA analyzes the user’s
						behavior based on various characteristics, e.g. IP address, duration of the
						visit of the website or the user’s mouse movements. The analysis starts
						automatically as soon as the user enters the respective subpage of the
						website where reCAPTCHA is used.
					</p>

					<p>
						Google Ireland may transfer the data collected via reCAPTCHA to Google in
						the United States.
					</p>

					<p>
						If you are located in a country of the European Union or in Brazil, the
						legal basis for using reCAPTCHA and for analyzing user behavior on the
						website is legitimate interest as provided under Applicable Data Protection
						Law (i.e., Art. 6 para. 1 (f) GDPR or Art. 8, §9 LGPD). The legitimate
						interest Nintendo pursues is to prevent the abuse of its website by
						automated programs.
					</p>

					<p>
						<strong>
							If you are located in a country of the European Union or in Brazil, you
							may have the right to object, on grounds relating to your particular
							situation, at any time to the processing pursuant to this Section 6. To
							exercise this right you may at any time contact our European or
							Brazilian representative as provided for in Section 11 (b) below.
						</strong>
					</p>

					<p>
						For further information about reCAPTCHA please visit{' '}
						<a href="https://www.google.com/recaptcha/about/">
							https://www.google.com/recaptcha/about/
						</a>
						,{' '}
						<a href="https://policies.google.com/privacy">
							https://policies.google.com/privacy
						</a>{' '}
						and{' '}
						<a href="https://policies.google.com/terms">
							https://policies.google.com/terms
						</a>
						.
					</p>

					<p>
						<strong>7.&nbsp; SECURITY</strong>
					</p>

					<p>
						Nintendo uses appropriate technical, administrative and organizational
						measures to protect your information from loss, theft, misuse, and
						unauthorized access, disclosure, alteration and destruction. You should
						understand that no data storage system or transmission of data over the
						Internet or any other public network can be guaranteed to be 100% secure.
					</p>

					<p>
						<strong>8.&nbsp; CHANGES TO THE PRIVACY NOTICE</strong>
					</p>

					<p>
						We may update this Privacy Notice from time to time. When we update this
						Privacy Notice, we will revise the "Last Updated" date above. We will
						provide a prominent notice of material changes affecting the type of
						information we collect from you and/or our use of your information prior to
						any such change being applicable to you.
					</p>

					<p>
						<strong>9.&nbsp; STORAGE TIME</strong>
					</p>

					<p>
						We will only store your information as long as necessary to fulfill the
						purposes for which the information is collected and processed or – where the
						applicable law provides for longer storage and retention period – for the
						storage and retention period required by law. After that your personal data
						will be deleted.
					</p>

					<p style={{ marginLeft: '25px' }}>
						If you are located in a country of the European Union or in Brazil and if
						the publisher agreement with Nintendo is terminated, your personal data will
						be deleted. Please note that we are required to retain certain transactional
						data from the Services you used under statutory commercial and tax law for a
						period of up to ten (10) years.
					</p>

					<p style={{ marginLeft: '25px' }}>
						If you are located in a country of the European Union or in Brazil and if
						you exercise a right to object to the processing or withdraw your consent to
						the processing, we will suspend the processing for the purpose to which you
						objected or to the extent you withdrew your consent, unless another legal
						basis for processing and retaining this data exists or unless applicable law
						requires us to retain the data.
					</p>

					<p>
						<strong>10.&nbsp; YOUR RIGHTS UNDER APPLICABLE DATA PROTECTION LAW</strong>
					</p>

					<p>
						If you are located in a country of the European Union, you may have the
						following rights under European data protection law, respectively under the
						GDPR:
					</p>

					<ul>
						<li>The right to request access to personal data stored by us;</li>
						<li>
							The right to request rectification or erasure of your personal data or
							restriction of processing concerning your personal data.
						</li>
						<li>
							The right to withdraw any consent you have given at any time, without
							affecting the lawfulness of processing based on consent before its
							withdrawal;
						</li>
						<li>The right to data portability;</li>
						<li>
							<strong>
								The right to object to the processing of your personal data.
							</strong>
						</li>
					</ul>

					<p>
						If you are located in Brazil, you may have the following rights under the
						LGPD:
					</p>

					<ul>
						<li>The right to confirm the existence of data processing;</li>
						<li>The right to request access to personal data stored by us;</li>
						<li>
							The right to correct incomplete, inaccurate or out-of-date personal
							data;
						</li>
						<li>
							The right to request anonymization or deletion of your personal data;
						</li>
						<li>
							The right to revoke any consent you have given at any time, and to have
							deleted the personal data processed with such consent, without affecting
							the lawfulness of the processing that was based on the consent before
							its revocation;
						</li>
						<li>
							The right to know the entities with which Nintendo has shared your
							personal data;
						</li>
						<li>The right to data portability;</li>
						<li>The right to object to the processing of your personal data.</li>
					</ul>

					<p>
						If you are located in California, you may have the following rights under
						the CPRA:
					</p>

					<ul>
						<li>The right to confirm the existence of data processing;</li>
						<li>The right to request access to personal data stored by us;</li>
						<li>
							The right to correct or update inaccurate or out-of-date personal data;
						</li>
						<li>
							The right to request anonymization or deletion of your personal data;
						</li>
						<li>The right to data portability;</li>
						<li>
							The right to know the personal data that may be shared with third
							parties for a business purpose and categories of third parties with whom
							Nintendo has shared such personal data.
						</li>
					</ul>

					<p>
						To exercise any of the aforementioned rights you may at any time contact our
						European, Brazilian, or United States representative as provided in Section
						11 (b) below.
					</p>

					<p>
						You also have the right to lodge a complaint with a data protection
						supervisory authority located in the European Union, if you are located in
						the European Union, or a data protection supervisory authority located in
						Brazil, if you are located in Brazil.
					</p>

					<p>
						<strong>
							11.&nbsp; CONTACT US WITH YOUR PRIVACY QUESTIONS, COMMENTS OR CONCERNS
						</strong>
					</p>

					<p>
						(a) If you have any questions, complaints or comments regarding this Privacy
						Notice, or any of our privacy practices, please contact:
					</p>

					<ul>
						<li>
							Our Japanese headquarters at{' '}
							<a href="mailto:contact_publish@nintendo.co.jp">
								contact_publish@nintendo.co.jp
							</a>
							, via phone at 81-75-662-9600, or via regular mail at Nintendo Co., Ltd,
							Attn: Licensing Department, 11-1 Hokotate-cho, Kamitoba, Minami-ku,
							Kyoto 601-8501, Japan.
							<br />
							<a href="https://www.nintendo.co.jp/">https://www.nintendo.co.jp/</a>
						</li>
						<li>
							Our Brazilian Representative Nintendo of America Inc. at{' '}
							<a href="mailto:privacypolicy@noa.nintendo.com">
								privacypolicy@noa.nintendo.com
							</a>
							, via phone at 1-888-977-7627, or via regular mail at Nintendo of
							America Inc., Attn: Privacy Administrator, 4600 150th Avenue NE,
							Redmond, WA 98052, U.S.A.
						</li>
						<li>
							Our European representative Nintendo of Europe GmbH at{' '}
							<a href="mailto:privacyinquiry.ndp@nintendo.de">
								privacyinquiry.ndp@nintendo.de
							</a>
							, or via regular mail at Nintendo of Europe GmbH, Attn.: Legal
							Department, Goldsteinstrasse 235, 60528 Frankfurt am Main, Germany.
						</li>
					</ul>

					<p>
						(b) If you would like to exercise any of your rights under Applicable Data
						Protection Law, please contact:
					</p>

					<ul>
						<li>
							for APPI requests: Our Japanese headquarters Nintendo Co., Ltd, via
							email at{' '}
							<a href="mailto:contact_publish@nintendo.co.jp">
								contact_publish@nintendo.co.jp
							</a>
							.
						</li>
						<li>
							for GDPR requests: Our European representative Nintendo of Europe GmbH,
							Attn.: Legal Department, Goldsteinstrasse 235, 60528 Frankfurt am Main,
							Germany via regular mail, or via email at{' '}
							<a href="mailto:privacyinquiry.ndp@nintendo.de">
								privacyinquiry.ndp@nintendo.de
							</a>
							.
						</li>
						<li>
							for LGPD requests: Our Brazilian Representative Nintendo of America
							Inc., Attn: Data Protection Officer, 4600 150th Avenue NE, Redmond, WA
							98052, U.S.A. via regular mail or via email at{' '}
							<a href="mailto:dataprotectionofficer@noa.nintendo.com">
								dataprotectionofficer@noa.nintendo.com
							</a>
							.
						</li>
						<li>
							for CPRA requests: Our United States representative Nintendo of America
							Inc., Attn: Data Protection Officer, 4600 150th Avenue NE, Redmond, WA
							98052, U.S.A. via regular mail or via email at{' '}
							<a href="mailto:dataprotectionofficer@noa.nintendo.com">
								dataprotectionofficer@noa.nintendo.com
							</a>
							.
						</li>
					</ul>

					<p>
						<strong>
							12.&nbsp; NINTENDO&rsquo;S DATA PROTECTION OFFICER AS PER EUROPEAN
							REGULATION:
						</strong>
					</p>

					<ul>
						<li>
							Nintendo Co., Ltd., Attn.: Data Protection Officer, 11-1 Hokotate-cho,
							Kamitoba, Minami-ku, Kyoto, 601-8501, Japan,{' '}
							<a href="mailto:dataprotectionofficer@nintendo.co.jp">
								dataprotectionofficer@nintendo.co.jp
							</a>
							.
						</li>
					</ul>
				</div>
			</Page.FullPageCol>
		</Page>
	);
};

export default PrivacyPolicy;
