export function localConfig() {
	const apiHost = `${process.env.REACT_APP_API_HOST || ''}`;
	if (apiHost !== '') {
		global.config.apiHost = apiHost;
	}
	const sfsHost = `${process.env.REACT_APP_SFS_HOST || ''}`;
	if (sfsHost !== '') {
		global.config.sfsHost = sfsHost;
	}
	const apiBasePath = `${process.env.REACT_APP_API_BASE_PATH || ''}`;
	if (apiBasePath !== '') {
		global.config.apiBasePath = apiBasePath;
	}
	const authEnv = `${process.env.REACT_APP_AUTH_ENV || ''}`;
	if (authEnv !== '') {
		global.config.authEnv = authEnv;
	}
	const paypalScriptUrl = `${process.env.REACT_APP_PAYPAL_SCRIPT_SRC || ''}`;
	if (paypalScriptUrl !== '') {
		global.config.paypalScriptUrl = paypalScriptUrl;
	}
	const paypalProdClientId = `${process.env.REACT_APP_PAYPAL_PROD_CLIENT_ID || ''}`;
	if (paypalProdClientId !== '') {
		global.config.paypalProdClientId = paypalProdClientId;
	}
	global.config.apiUrl = `https://${global.config.apiHost}${global.config.apiBasePath}`;
	global.config.sfsUrl = `https://${global.config.sfsHost}${global.config.apiBasePath}`;
}
