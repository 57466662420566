import React, { useState } from 'react';
import { Alert, Button, ButtonToolbar } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import DigitalCodesOrderV1 from '../../components/DigitalCodesOrder/DigitalCodesOrder__V1';
import FAIcon from '../../components/FAIcon/FAIcon';
import Page from '../../components/Page/Page';
import ProFormaInvoice from '../../components/ProFormaInvoice/ProFormaInvoice';
import PropertyDisplay from '../../components/PropertyDisplay/PropertyDisplay';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import StatusText from '../../components/StatusText/StatusText';
import Title from '../../components/Title/Title';
import { digitalCodesConstants } from '../../constants/digitalCodesConstants';
import { useCompanyAgreementsQuery } from '../../hooks/queryHooks';
import { useUserProfile } from '../../hooks/reduxHooks';
import { getCompanyProfile } from '../../services/companiesService';
import {
	getDigitalOrder,
	getDigitalOrdersPlatformLegend
} from '../../services/digitalCodesService';
import { formatCurrency } from '../../utils/currencyUtils';
import { safeEval } from '../../utils/dataUtils';
import { dateFormat, formatDate } from '../../utils/dateUtils';
import {
	calculateTotalBill,
	getCommentByType,
	getPermissions
} from '../../utils/digitalCodesUtils';
import { modalIdentifiers } from './DigitalCodesDetail.helpers';
import DigitalCodesModals from './modals/DigitalCodesModals__V1';
import DigitalCodesButtons from './views/DigitalCodesButtons__V1';

const {
	AWAITING_PAYMENT_INFO,
	CODES_AVAILABLE,
	COMPLETE,
	DOWNLOAD_EXPIRED,
	DRAFT,
	PAYMENT_PENDING,
	PAYMENT_RECEIVED,
	REJECTED,
	UPLOAD_FAILED,
	UPLOAD_IN_PROGRESS,
} = digitalCodesConstants.status;
const { PAYMENT_MODAL } = modalIdentifiers;

export const getPurposeNameFromId = (id, purposeOutput) =>
	safeEval(() =>
		purposeOutput
			.find((entry) => entry.platform === 'HAC')
			.digital_orders_purpose.find(
				// eslint-disable-next-line eqeqeq
				(entry) => entry.digital_orders_purpose_id == id,
			).purpose,
	);

const DigitalCodesDetail = () => {
	const { orderId } = useParams();
	const history = useHistory();
	const [openModal, setOpenModal] = useState();

	const userProfile = useUserProfile();
	const {
		canApprove,
		canReview,
		canDownloadCodes,
		canViewInvoice,
		canMakePayment,
		canCreateOrder,
	} = getPermissions(userProfile);

	const purposesQuery = useQuery('getDigitalOrdersPlatformLegend', getDigitalOrdersPlatformLegend);

	const orderQuery = useQuery(
		['getDigitalOrder', String(orderId)],
		() => getDigitalOrder(orderId),
		{ refetchOnMount: false },
	);
	const order = orderQuery.isSuccess && orderQuery.data.data.body;
	
	const {
		address1,
		address2,
		city,
		component,
		componentID,
		country,
		NDID,
		order_comments,
		postalCode,
		product,
		publisherPO,
		purposeDescription,
		purposeID,
		quantity,
		serviceFee,
		stateProvince,
		status,
		submittedDate,
		tax,
		totalPrice,
		unitPrice,
	} = order || {};

	const publisherQuery = useQuery(
		['getCompanyProfile', NDID],
		() => getCompanyProfile(NDID), // crosslinked from orderQuery data
		{ enabled: NDID != null },
	);
	const companyData = publisherQuery.data?.data?.company_information;
	const { company_name } = companyData || {};
	const { game_code, game_name } = product || {};
	const {
		game_name: componentGameName,
		game_code: componentGameCode,
	} = component || {};

	 const agreementsQuery = useCompanyAgreementsQuery({ enabled: 
		canCreateOrder &&
		order.status === DRAFT
	});

	const [taxAmountFloat, totalWithTax] = calculateTotalBill({ totalPrice, tax });

	const purposeName = (purposesQuery.isSuccess &&
		getPurposeNameFromId(purposeID, purposesQuery.data.data));
	const rejectComment = getCommentByType(order_comments, 'REJECTION');

	return (
		<Page
			isLoading={orderQuery.isLoading || purposesQuery.isLoading || agreementsQuery.isLoading}
			fault={orderQuery.isError && orderQuery.error}
		>
			{canDownloadCodes && [CODES_AVAILABLE, COMPLETE, DOWNLOAD_EXPIRED].includes(status) && (
				<Alert variant="info">
					Code batches are delivered with encryption. Please use 7Zip or a tool that
					supports AES-256 encryption to decrypt and unpack the batch.
				</Alert>
			)}
			{status === REJECTED && rejectComment && (
				<Alert id="unit-price-error-alert" variant="danger">
					This order was rejected with the following comment: "{rejectComment}"
				</Alert>
			)}
			<Breadcrumb>
				<Breadcrumb.Item to="/orders/digital-codes">Digital Code Orders</Breadcrumb.Item>
				<Breadcrumb.Item active>View Digital Code Order</Breadcrumb.Item>
			</Breadcrumb>
			<Title
				button={
					<DigitalCodesButtons
						agreements={agreementsQuery.data?.data}
						showModal={(type) => setOpenModal({ type })}
						orderData={order}
					/>
				}
				status={
					<StatusText badge>{status}</StatusText>
				}
			>
				View Digital Code Order
			</Title>
			<Page.ContentContainer>
				<DigitalCodesOrderV1
					publisherIsLoading={publisherQuery.isLoading}
					publisher={(canReview || canApprove) && company_name}
					purposeName={purposeName || purposeID}
					permissions={{
						canReview,
						canApprove,
					}}
					order={order}
					productsComponentsAreLoading={false}
					productsComponents={{
						game_code,
						game_name,
						componentGameName,
						componentID,
					}}
					totalPrice={order.totalPrice}
					unitPrice={order.unitPrice}
				/>
				{canViewInvoice &&
					[
						AWAITING_PAYMENT_INFO,
						CODES_AVAILABLE,
						COMPLETE,
						DOWNLOAD_EXPIRED,
						PAYMENT_PENDING,
						PAYMENT_RECEIVED,
						UPLOAD_FAILED,
						UPLOAD_IN_PROGRESS,
					].includes(status) && (
						<>
							<SectionTitle>Pro-Forma Invoice</SectionTitle>
							<ButtonToolbar className="mb-3">
								<ProFormaInvoice
									disabled={purposesQuery.isLoading || orderQuery.isLoading || publisherQuery.isLoading }
									companyData={companyData}
									invoiceDate={formatDate(submittedDate, dateFormat.DATE)}
									orderNumber={orderId}
									poNumber={publisherPO}
									itemFields={[null, null, 'NOA Part No.']}
									orderDetails={[{
										game_code,
										itemName: `${game_name} ${componentGameName} (${componentGameCode}) ${quantity} Codes for ${purposeName}`,
										nsuid: componentID,
										quantity,
										tax,
										unit_price: unitPrice,
									}]}
									isDigitalOrder={true}
									subtotal={formatCurrency(totalPrice)}
									taxable={tax && formatCurrency(totalPrice)}
									tax={tax && (Number(tax).toFixed(2).toString() + '%')}
									taxAmount={tax && formatCurrency(taxAmountFloat)}
									grandTotal={formatCurrency(totalWithTax)}
									fileName={`Pro-Forma Invoice for order ${orderId}.pdf`}
								/>
							</ButtonToolbar>
							<div>
								<PropertyDisplay label="Date">
									{formatDate(submittedDate, dateFormat.DATE)}
								</PropertyDisplay>
								<PropertyDisplay label="Invoice #">{orderId}</PropertyDisplay>
								<PropertyDisplay label="Customer">{company_name}</PropertyDisplay>
								<PropertyDisplay label="Legal Address">
									{[address1, address2, city, stateProvince, postalCode, country]
										.filter((item) => item)
										.join(', ')}
								</PropertyDisplay>
								<PropertyDisplay label="Publisher PO Number">
									{publisherPO}
								</PropertyDisplay>
								<PropertyDisplay label="Product">
									{game_name}
								</PropertyDisplay>
								<PropertyDisplay label="Component">
									{`${componentGameName} (${componentGameCode})`}
								</PropertyDisplay>
								<PropertyDisplay label="Description">
									{purposeDescription}
								</PropertyDisplay>
								<PropertyDisplay label="Notes &amp; Other Comments">
									{getCommentByType(order_comments, 'PUBLISHER')}
								</PropertyDisplay>
								<PropertyDisplay label="Quantity">{quantity}</PropertyDisplay>
								<PropertyDisplay label="Price/Unit">
									{formatCurrency(unitPrice, 4)}
								</PropertyDisplay>
								<PropertyDisplay label="Service Fee">
									{serviceFee ? formatCurrency(serviceFee) : '(not implemented)'}
								</PropertyDisplay>
								<PropertyDisplay label="Tax">
									{formatCurrency(taxAmountFloat)}
								</PropertyDisplay>
							</div>
						</>
					)}
				{canMakePayment && status === AWAITING_PAYMENT_INFO && (
					<div
						className="my-4 p-4 d-inline-block"
						style={{ background: 'var(--nin-color-ltgray1)' }}
					>
						<h3>Total Amount: {formatCurrency(totalWithTax)}</h3>
					</div>
				)}
				<div className="btn-container">
					<div className="float-left">
						<Button
							variant="outline-secondary"
							onClick={() => history.push('/orders/digital-codes')}
						>
							<FAIcon name="chevron-left" className="mr-1" />
							Back to Digital Code Orders
						</Button>
					</div>
					{canMakePayment && status === AWAITING_PAYMENT_INFO && (
						<div className="float-right">
							<Button
								variant="primary"
								onClick={() => setOpenModal({ type: PAYMENT_MODAL })}
							>
								Process Order Payment
							</Button>
						</div>
					)}
				</div>
			</Page.ContentContainer>
			<DigitalCodesModals
				openModal={openModal}
				setSubmitting={(submitting) =>
					setOpenModal({ ...openModal, isSubmitting: !!submitting })
				}
				closeModal={() => setOpenModal(null)}
				order={order}
				publisher={publisherQuery.isSuccess && publisherQuery.data.data.company_information}
				product={product}
				isCodeInBox={purposeID === 5}
				component={component}
				onSubmit={() => orderQuery.refetch()}
			/>
		</Page>
	);
};
export default DigitalCodesDetail;
