import React, { Component } from 'react';
import { Alert, Col, Form, FormCheck, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';

import { fetchPlatforms } from '../../../store/actions/referenceActions';
import BaseModal from '../../../components/BaseModal/BaseModal';
import DatePicker from '../../../components/DatePicker/DatePicker';
import FAIcon from '../../../components/FAIcon/FAIcon';
import HelpBlock from '../../../components/HelpBlock/HelpBlock';
import Loading from '../../../components/Loading/Loading';
import { companyAgreementConstants } from '../../../constants/companyAgreementConstants';
import { loadingConstants } from '../../../constants/loadingConstants';
import { permConst } from '../../../constants/permConst';
import { platformCodes } from '../../../constants/platformConstants';
import { createProduct } from '../../../services/productsService';
import {
	validateActiveAgreementType,
	validatePlatformAgreement
} from '../../../utils/companyUtils';
import { isSunsettingFeatureStillValid } from '../../../utils/sunsetFeaturesUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { isAuthorized } from '../../../utils/userUtils';
import { validateProductCode } from '../../../utils/validationUtils';

import '../ProductManagement.css';


export class CreateProductModal extends Component {
	constructor(props) {
		super(props);
		const { companyAgreements } = this.props;
		const product = {
			platform_code: '',
			name: '',
			languages: [],
			other_product_codes: [''],
			delivery_format: [],
			card_type: '',
			demo_only: false,
			release_date: null,
			comments: '',
			companyAgreements: companyAgreements,
			platformPermissions: null
		};

		this.state = {
			product: product,
			isSubmitting: false,
			untouchedFields: new Set(['platform_code', 'name', 'languages', 'delivery_format', 'release_date', 'card_type'])
		};

		this.schema = Yup.object().shape({
			platform_code: Yup.string().required('Platform cannot be blank'),
			name: Yup.string().required('Product Name cannot be blank'),
			languages: Yup.array().test('language_test', 'Languages must have a selected value', (value) => this.isOptionSelected(value)),
			delivery_format: Yup.array().test(
				'language_test',
				'Delivery format must have a selected value',
				(value) => this.isOptionSelected(value)
			),
			release_date: Yup.mixed().test(
				'release_date_test',
				'Release date must be entered',
				(value) => value instanceof Date
			),
			card_type: Yup.mixed().test(
				'card_type_test',
				'Card type needs to have a value',
				value => (!this.showCardType() || (this.showCardType() && value))
			)
		});

	}

	componentDidMount() {
		this.props.fetchPlatforms();
		this.componentDidUpdate();
	}

	componentDidUpdate(nextProps) {
		const { companyAgreements, userProfile, platforms } = this.props;
		if (!this.state.platformPermissions) {
			const isInternal = isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.ADD.FIRST
			]);

			const platformPermissions = platforms && platforms.reduce((table, platform) => {
				const row = {
					platform_code: platform.platform_code,
					platform_name: platform.platform_name
				};
				row.validAgreement = (platform.platform_code === platformCodes['Wii'])
					? validateActiveAgreementType(
						companyAgreements,
						companyAgreementConstants.TYPE.RETAIL,
						platform.agreement_family)
					: validatePlatformAgreement(
						companyAgreements,
						platform.agreement_family);
				row.canCreateProducts = platform.platform_code !== platformCodes['Nintendo Switch'] &&
					(isInternal || row.validAgreement);

				table.push(row);
				return table;
			}, []);

			if (platformPermissions) {
				this.setState({
					platformPermissions
				});
			}
		}
	}

	createProduct() {
		this.setState({
			isSubmitting: true
		});

		const { product } = this.state;

		let dualDistribution =
			product.delivery_format.includes('DIGITAL') &&
			product.delivery_format.includes('PHYSICAL');

		let payload = {
			platform_code: product.platform_code,
			name: product.name,
			languages: product.languages,
			other_product_codes: product.other_product_codes.filter(
				function(el) {
					return el !== '';
				}
			),
			delivery_format: dualDistribution
				? 'BOTH'
				: product.delivery_format[0],
			card_type: dualDistribution ? product.card_type : '',
			demo_only: product.demo_only,
			release_date: new Date(product.release_date),
			comments: product.comments
		};

		createProduct(payload)
			.then((response) => {
				this.props.history.push(`/products/${response.data.product_id}`);
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'creating new product'),
					'error',
					error
				);
				this.setState({isSubmitting: false});
			});
	}

	addOtherProductCode(e) {
		const { product } = this.state;
		if (e.detail === 0) {
			return;
		}
		e.preventDefault();
		product.other_product_codes.push('');
		this.setState({product});
	}

	onListChange(name, event) {
		const { product } = this.state;
		let toRemove =
			event.target.type === 'checkbox' && event.target.checked === false;
		let value = event.currentTarget.value;
		if (toRemove) {
			let index = product[name].indexOf(value);
			product[name].splice(index, 1);
		} else {
			product[name].push(value);
		}
		this.setState({product});
	}

	onArrayItemChange(name, index, event) {
		const { product } = this.state;
		const value = validateProductCode(this.getValue(event));
		if (value !== false) {
			product[name][index] = value;
			this.setState({product});
			return;
		}
		event.preventDefault();
		return false;
	}

	onInputChange(name, event) {
		const { product } = this.state;
		product[name] = this.getValue(event);
		this.setState({product});
	}

	onInputChangeCheckbox(name, event) {
		const { product } = this.state;
		product[name] = event.target.checked;
		this.setState({product});
	}

	dateChange(date) {
		const { product } = this.state;
		product.release_date = new Date(date+'T00:00:00');
		this.setState({product});
	}

	getValue(event) {
		let value = event.currentTarget.value;

		if (value === 'true') {
			value = true;
		} else if (value === 'false') {
			value = false;
		}

		return value;
	}

	showCardType() {
		const { product } = this.state;
		return (
			(product.platform_code === 'CTR' ||
				product.platform_code === 'KTR') &&
			product.delivery_format.includes('PHYSICAL')
		);
	}

	isOptionSelected(arr) {
		return arr.some(function(el) {
			return el !== '';
		});
	}

	getModalBody({ flagsForField }) {
		const { companyAgreements, platforms, platformsStatus, sunsetFeatures, userProfile } =
			this.props;
		const { untouchedFields, product, isSubmitting, platformPermissions } = this.state;

		// display alt content if platforms data is not available
		if (platformsStatus === loadingConstants.LOADING) {
			return <Loading />;
		} else if (platformsStatus !== loadingConstants.COMPLETED) {
			return <Alert variant='danger'>
				There was an error while loading platforms data, which is necessary for this form.{' '}
				<a onClick={(e) => this.props.fetchPlatforms()}>Retry load</a>
				<FAIcon size="xs" name="chevron-right" />
			</Alert>;
		}

		const touchField = (field) => {
			untouchedFields.delete(field);
			this.setState({untouchedFields});
		};

		const validationFlag = (field, defaultMessage=null) => {
			const flag = flagsForField(field);
			if (flag && !untouchedFields.has(field)) {
				return (<HelpBlock><span className='text-danger'>{flag.reason}</span></HelpBlock>);
			}  else {
				if (!defaultMessage) return (<HelpBlock>{defaultMessage}</HelpBlock>);
			}
			return null;
		};

		const isInternal = isAuthorized(userProfile.permissions, [
			permConst.PRODUCT.ADD.FIRST
		]);
		const agreementFamily = (platforms.find(p => p.platform_code === product.platform_code) || {}).agreement_family;

		const canCreateDigitalProduct = isInternal || validateActiveAgreementType(
			companyAgreements,
			companyAgreementConstants.TYPE.DIGITAL,
			agreementFamily
		);
		const canCreateRetailProduct = isInternal || validateActiveAgreementType(
			companyAgreements,
			companyAgreementConstants.TYPE.RETAIL,
			agreementFamily
		);

		return (
			<div>
				{(product.platform_code === 'CTR' || product.platform_code === 'KTR') && (
					<div className="alert alert-warning">
						Only titles targeted to an audience demographic that is at least 70% seven
						years of age or older can use stereoscopic 3D.
					</div>
				)}
				{product.platform_code === 'WUP' && (
					<div className="alert alert-warning">
						Game titles with the name format 'GAME NAME U' are prohibited.
					</div>
				)}
				<Form onSubmit={(e) => {
					const [, formComplete] = this.validation();
					if (formComplete) {
						this.createProduct();
					}
					e.preventDefault();
					return false;
				}}>
					<Form.Group as={Row}>
						<Form.Label className="text-sm-right" column sm={4}>Platform</Form.Label>
						{this.props.mode === 'CREATE' && (
							<Col sm={7}>
								<select
									onChange={this.onInputChange.bind(this, 'platform_code')}
									onBlur={() => touchField('platform_code')}
									className="form-control"
									defaultValue=""
									disabled={isSubmitting}
								>
									<option value="" />
									{platformPermissions &&
										platformPermissions.map(
											(platform, index) =>
												platform.canCreateProducts && (
													<option
														value={platform.platform_code}
														key={`platform-${index}`}
													>
														{platform.platform_name}
													</option>
												),
										)}
								</select>
								{validationFlag(
									'platform_code',
									'Nintendo Switch titles must be created in the Nintendo Developer Portal.',
								)}
							</Col>
						)}
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label className="text-sm-right" sm={4} column>Product Name</Form.Label>
						<Col sm={7}>
							<input
								onChange={this.onInputChange.bind(this, 'name')}
								onBlur={() => touchField('name')}
								className="form-control"
								value={product.name}
								disabled={isSubmitting}
							/>
							{validationFlag('name')}
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label className="text-sm-right" sm={4} column>Game Language(s)</Form.Label>
						<Col className="col-form-label" sm={7}>
							<FormCheck
								id="en-game-language"
								type="checkbox"
								onChange={this.onListChange.bind(this, 'languages')}
								onBlur={() => touchField('languages')}
								value="en"
								defaultChecked={product.languages.includes('en')}
								disabled={isSubmitting}
								label='English'
							/>
							<FormCheck
								id="fr-game-language"
								type="checkbox"
								onChange={this.onListChange.bind(this, 'languages')}
								onBlur={() => touchField('languages')}
								value="fr"
								defaultChecked={product.languages.includes('fr')}
								disabled={isSubmitting}
								label="French"
							/>
							<FormCheck
								id="es-game-language"
								type="checkbox"
								onChange={this.onListChange.bind(this, 'languages')}
								onBlur={() => touchField('languages')}
								value="es"
								defaultChecked={product.languages.includes('es')}
								disabled={isSubmitting}
								label="Spanish"
							/>
							{validationFlag('languages')}
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label className="text-sm-right" sm={4} column>Other Region Product Codes</Form.Label>
						<Col sm={4}>
							{product.other_product_codes.map((item, index) => (
								<Form.Control
									type="text"
									value={item}
									key={`${index}-${'item'}`}
									placeholder="e.g. CTRPAMEC"
									onChange={this.onArrayItemChange.bind(
										this,
										'other_product_codes',
										index,
									)}
									disabled={isSubmitting}
								/>
							))}
							<button
								onClick={this.addOtherProductCode.bind(this)}
								className="addProductCode"
								disabled={isSubmitting}
							>
								<FAIcon name="plus" className="mr-1"/> Add More Product
								Code(s)
							</button>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label className="text-sm-right" sm={4} column>Delivery Format</Form.Label>
						{this.props.mode === 'CREATE' && (
							<Col className="col-form-label" sm={7}>
								{canCreateDigitalProduct && (
									<FormCheck
										id="digital-product"
										type="checkbox"
										onChange={this.onListChange.bind(
											this,
											'delivery_format',
										)}
										onBlur={() => touchField('delivery_format')}
										value={companyAgreementConstants.TYPE.DIGITAL}
										defaultChecked={product.delivery_format.includes(
											companyAgreementConstants.TYPE.DIGITAL,
										)}
										disabled={isSubmitting}
										label="Digital"
									/>
								)}
								{canCreateRetailProduct && isSunsettingFeatureStillValid(sunsetFeatures.content['create_physical_product']) && (
									<FormCheck
										id="physical-product"
										type="checkbox"
										onChange={this.onListChange.bind(
											this,
											'delivery_format',
										)}
										onBlur={() => touchField('delivery_format')}
										value="PHYSICAL"
										defaultChecked={product.delivery_format.includes(
											'PHYSICAL',
										)}
										disabled={isSubmitting}
										label="Physical"
									/>
								)}
								{validationFlag('delivery_format', 'blah')}
							</Col>
						)}
					</Form.Group>
					{this.showCardType() && (
						<Form.Group as={Row}>
							<Form.Label className="text-sm-right" sm={4} column>Card Type</Form.Label>
							{this.props.mode === 'CREATE' && (
								<Col className="col-form-label" sm={7}>
									<FormCheck
										id="card-type-card1"
										type="radio"
										name="cardType"
										defaultChecked={product.card_type === 'Card1'}
										onChange={this.onInputChange.bind(
											this,
											'card_type',
										)}
										onBlur={() => touchField('card_type')}
										value="Card1"
										disabled={isSubmitting}
										label="Card1"
									/>
									<FormCheck
										id="card-type-card2"
										type="radio"
										name="cardType"
										defaultChecked={product.card_type === 'Card2'}
										onChange={this.onInputChange.bind(
											this,
											'card_type',
										)}
										onBlur={() => touchField('card_type')}
										value="Card2"
										disabled={isSubmitting}
										label="Card2"
									/>
									{validationFlag('card_type')}
								</Col>
							)}
						</Form.Group>
					)}
					<Form.Group as={Row}>
						<Form.Label className="text-sm-right" sm={4} column>Demo Only</Form.Label>
						<Col className="col-form-label" sm={3}>
							<FormCheck
								id="checkbox-is-demo"
								type="checkbox"
								onChange={this.onInputChangeCheckbox.bind(
									this,
									'demo_only',
								)}
								value="true"
								defaultChecked={product.demo_only}
								disabled={isSubmitting}
								label="YES"
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label className="text-sm-right" sm={4} column>Estimated Release Date</Form.Label>
						<Col sm={3}>
							<DatePicker
								dropdownMode="select"
								value={product.release_date}
								onChange={this.dateChange.bind(this)}
								onBlur={() => touchField('release_date')}
								disabled={isSubmitting}
							/>
							{validationFlag('release_date')}
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label className="text-sm-right" sm={4} column>Additional Comments</Form.Label>
						<Col className="col-form-label" sm={7}>
							<textarea
								className="form-control"
								rows="5"
								onChange={this.onInputChange.bind(this, 'comments')}
								value={product.comments}
								disabled={isSubmitting}
							/>
							<HelpBlock>Optional</HelpBlock>
						</Col>
					</Form.Group>
				</Form>
			</div>
		);
	}

	validation() {
		const { product } = this.state;
		let flagged = [], isValid;

		try {
			this.schema.validateSync(product, {abortEarly: false});
			isValid = true;
		} catch (err) {
			flagged = err.inner.map(e => ({name: e.path, reason: e.message}));
			isValid = false;
		}
		return [
			(field) => flagged.find(f => f.name === field),
			isValid
		];
	}

	render() {
		const { closeModal, mode } = this.props;
		const { isSubmitting } = this.state;

		const [flagsForField, formComplete] = this.validation();

		return (
			<BaseModal size="lg" show={true} onCancel={closeModal} isSubmitting={isSubmitting}>
				<BaseModal.Title>
					{mode === 'CREATE' ? 'Create Product' : 'Update Product'}
				</BaseModal.Title>
				{this.getModalBody({ flagsForField })}
				<BaseModal.Submit onClick={() => this.createProduct()} disabled={!formComplete}>
					{mode === 'CREATE' ? 'Submit & Request Game Code' : 'Update'}
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}

export default connect(
	// mapStateToProps
	(state) => ({
		userProfile: state.authReducer.userProfile,
		platforms: state.referenceReducer.platforms?.content,
		platformsStatus: state.referenceReducer.platforms?.meta.status,
	}),
	// mapDispatchToProps
	(dispatch) => ({
		fetchPlatforms: bindActionCreators(fetchPlatforms, dispatch)
	})
)(CreateProductModal);
