import React, { PropsWithChildren, ReactElement, VFC } from 'react';

import './MiniBadge.css';

type MiniBadgeProps = PropsWithChildren<{ variant?: string }>;
const MiniBadge: VFC<MiniBadgeProps> = ({
	variant = 'info',
	children,
}: MiniBadgeProps): ReactElement => {
	return <span className={`status-mini ${variant && 'status-' + variant}`}>{children}</span>;
};
export default MiniBadge;
