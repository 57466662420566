import React, { Component } from 'react';
import {
	dateFormat,
	formatDate,
	parseDateString
} from '../../../utils/dateUtils';
import { isFailedFileTransfer } from '../../../services/fileUploadsService';
import FileLink from '../../../components/FileLink/FileLink';

export class SubmissionAsset extends Component {
	render() {
		const downloadFileTypes = ['xml'];
		const hideDate = this.props.hideDate && this.props.hideDate === true;
		const downloadFile = downloadFileTypes.includes(
			this.props.asset.file_name
				.split('.')
				.pop()
				.toLowerCase()
		);
		return (
			<span>
				<FileLink
					fileName={this.props.asset.file_name}
					fileId={this.props.asset.file_id}
					status={this.props.asset.status}
					saved={downloadFile}
				>
					{`${this.props.asset.file_name}`}
				</FileLink>
				{!hideDate && (
					<div className="text-muted">
						{this.props.asset.uploaded_date &&
							formatDate(
								parseDateString(this.props.asset.uploaded_date),
								dateFormat.DATE
							)}
						{isFailedFileTransfer(this.props.asset.status) && ' FAILED'}
					</div>
				)}
			</span>
		);
	}
}
