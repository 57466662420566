import uniq from 'lodash/uniq';

import { digitalCodesConstants } from '../constants/digitalCodesConstants';
import { permConst } from '../constants/permConst';
import { ComponentPriceData, DigitalOrderData } from '../services/digitalCodesService';
import { ebsRoundedPrice, ebsRoundedUnitPrice } from './currencyUtils';
import { isInvalid } from './dataUtils';
import { isAuthorized } from './userUtils';


const {
	DRAFT,
	READY_FOR_REVIEW,
	READY_FOR_APPROVAL,
	REJECTED,
	CANCELED,
	AWAITING_PAYMENT_INFO,
	PAYMENT_PENDING,
	PAYMENT_RECEIVED,
	UPLOAD_IN_PROGRESS,
	UPLOAD_FAILED,
	CODES_AVAILABLE,
	DOWNLOAD_EXPIRED,
	COMPLETE,
} = digitalCodesConstants.status;
const {
	DECLINED,
	APPROVED,
	APPROVED_AND_ACTIVATING,
	APPROVED_AND_ACTIVE,
	APPROVED_AND_INACTIVE,
	PENDING_REVIEW,
	REVIEWED,
	ACTIVATING,
	ACTIVE,
	INACTIVE,
} = digitalCodesConstants.priceRequestStatuses;
const {
	RETRACTED,
} = digitalCodesConstants.priceRequestActions;
const CODES_EXPIRATION_DAYS = 30;

export const variantForDigitalCodesStatus = (status: string) => {
	switch (status) {
		case DRAFT:
		case DOWNLOAD_EXPIRED:
		default:
			return 'secondary';
		case READY_FOR_REVIEW:
			return 'info';
		case READY_FOR_APPROVAL:
		case PAYMENT_PENDING:
		case PAYMENT_RECEIVED:
		case UPLOAD_IN_PROGRESS:
		case CODES_AVAILABLE:
		case COMPLETE:
			return 'success';
		case REJECTED:
		case CANCELED:
		case UPLOAD_FAILED:
			return 'danger';
		case AWAITING_PAYMENT_INFO:
			return 'warning';
	}
};

export const displayStringForStatus = (value: string) =>
	({
		DRAFT: 'Draft',
		READY_FOR_REVIEW: 'Ready for Review',
		READY_FOR_APPROVAL: 'Ready for Approval',
		REJECTED: 'Rejected',
		CANCELED: 'Canceled',
		AWAITING_PAYMENT_INFO: 'Awaiting Payment Info',
		PAYMENT_PENDING: 'Payment Pending',
		PAYMENT_RECEIVED: 'Payment Received',
		UPLOAD_IN_PROGRESS: 'Upload in Progress',
		UPLOAD_FAILED: 'Upload Failed',
		CODES_AVAILABLE: 'Codes Available',
		DOWNLOAD_EXPIRED: 'Download Expired',
		COMPLETE: 'Complete',
	}[value] || '');

export const getExpirationDate = (startDate: Date | string | number) => {
	const date = startDate ? new Date(startDate) : new Date();
	const uploadDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
	return new Date(uploadDate.valueOf() + (1000*60*60*24 * CODES_EXPIRATION_DAYS));
};

export const getPermissions = (userProfile: UserProfile) => ({
	canApprove: isAuthorized(userProfile.permissions, [permConst.DIGITAL_ORDER.APPROVE.ALL]),
	canReview: isAuthorized(userProfile.permissions, [permConst.DIGITAL_ORDER.REVIEW.ALL]),
	canDownloadCodes: isAuthorized(userProfile.permissions, [
		permConst.DIGITAL_ORDER.CODES.VIEW.COMPANY,
	]),
	canUploadCodes: isAuthorized(userProfile.permissions, [
		permConst.DIGITAL_ORDER.CODES.UPLOAD.ALL,
	]),
	isInternal: isAuthorized(userProfile.permissions, [permConst.DIGITAL_ORDER.VIEW.ALL]),
	isPublisher: isAuthorized(userProfile.permissions, [permConst.DIGITAL_ORDER.VIEW.COMPANY]),
	canCancelOrder: isAuthorized(userProfile.permissions, [permConst.DIGITAL_ORDER.SUBMIT.COMPANY]),
	canDeleteOrder: isAuthorized(userProfile.permissions, [permConst.DIGITAL_ORDER.SUBMIT.COMPANY]),
	canCompleteOrder: isAuthorized(userProfile.permissions, [permConst.DIGITAL_ORDER.COMPLETE.ALL]),
	canViewCompanies: isAuthorized(userProfile.permissions, [permConst.COMPANY.VIEW.ALL]),
	canCreateOrder: isAuthorized(userProfile.permissions, [permConst.DIGITAL_ORDER.SUBMIT.COMPANY]),
	canViewInvoice: isAuthorized(userProfile.permissions, [
		permConst.DIGITAL_ORDER.INVOICE.VIEW.ALL,
		permConst.DIGITAL_ORDER.INVOICE.VIEW.COMPANY,
	]),
	canMakePayment: isAuthorized(userProfile.permissions, [permConst.DIGITAL_ORDER.SUBMIT.COMPANY]),
	canManageActivation: isAuthorized(userProfile.permissions, [
		permConst.DIGITAL_ORDER.CODES.ACTIVATE.ALL,
		permConst.DIGITAL_ORDER.CODES.SEND_REMINDER.ALL,
	]),
	canViewActivation: isAuthorized(userProfile.permissions, [
		permConst.DIGITAL_ORDER.CODES.ACTIVATE.ALL,
		permConst.DIGITAL_ORDER.CODES.SEND_REMINDER.ALL,
	]),
	canRetractCodes: isAuthorized(userProfile.permissions, [permConst.DIGITAL_ORDER.CODES.RETRACT.ALL]),
});

/**
 * Returns the current WSP or declared price request from an array of available requests. The current
 * request is the latest request that has not been rejected (declined by internal or retracted
 * by publisher) or is the only request available. If a rights holder ID is given, then only
 * requests submitted by that company are considered.
 * @param  {} requests
 * @param  {string} rightsHolderId
 */
export const getCurrentRequest = (requests?: (WholesalePriceRequestData | DeclaredPriceRequestData)[], rightsHolderId?: string) => {
	const validRequests = requests
		?.filter(request => !rightsHolderId || request.ndid_company_id === rightsHolderId)
		.sort((a, b) => new Date(b.submitted_datetime).valueOf() - new Date(a.submitted_datetime).valueOf());
	return (
		validRequests?.find(
			(request) =>
				/IN[\s_]PROCESS/.test(request?.rollup_status?.toUpperCase()),
		) || validRequests?.find(
			(request) =>
				request?.rollup_status?.toUpperCase() === APPROVED
		) || validRequests?.find(
			(request) =>
				isInactiveApprovedDeclaredPrice(request)
		) || undefined
	);
} ;


/**
 * Returns the current declared price request from an array of available declared price requests. The current
 * request is the latest request that has not been rejected (declined by internal or retracted
 * by publisher) or is the only request available. If a rights holder ID is given, then only
 * requests submitted by that company are considered.
 * @param  {} requests
 * @param  {string} rightsHolderId
 */
export const getCurrentDeclaredPriceRequest = (requests?: DeclaredPriceRequestData[], rightsHolderId?: string) => {
	const validRequests = requests
		?.filter(request => !rightsHolderId || request.ndid_company_id === rightsHolderId)
		.sort((a, b) => new Date(b.submitted_datetime).valueOf() - new Date(a.submitted_datetime).valueOf());
	return (
		validRequests?.find(
			(request) =>
				/IN[\s_]PROCESS/.test(request?.rollup_status?.toUpperCase()),
		) || validRequests?.find(
			(request) =>
				request?.rollup_status?.toUpperCase() === APPROVED
		) || undefined
	);
} ;

/**
 * For a given action within WSP request object, derive the status from that action
 * @param  {} action as defined in WholesalePriceRequestData
 */
export const getStatusForPriceRequestAction = (action?: { price_status: string | null; request_status: string; }, isInternal?: boolean) => {
	if (!action) { return; }
	const requestStatus = action?.request_status || null;

	if (requestStatus !== APPROVED) {
		return requestStatus;
	}
	// if user is not internal, don't say approved unless price status is also active
	if (!isInternal && action.price_status !== ACTIVE) {
		return REVIEWED;
	}

	switch (action.price_status) {
		case ACTIVATING:
			return APPROVED_AND_ACTIVATING;
		case ACTIVE:
			return APPROVED_AND_ACTIVE;
		case INACTIVE:
			return APPROVED_AND_INACTIVE;
		default:
			return APPROVED;
	}
};

/**
 * Validates new passwords before they can be submitted. There are six criteria;
 * function returns an array of boolean results, one for each criterium.
 *
 * @param {*} password Proposed password to validate
 */

export const validatePassword = (password: string = '') => {
	// 12 - 50 characters;
	const lengthCheck = () => password.length >= 12 && password.length < 51;
	// At least 1 Number;
	const numberCheck = () => /[0-9]+/.test(password);
	// At least 1 Special character (https://owasp.org/www-community/password-special-characters);
	const specialCheck = () => /[!"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~]+/.test(password);
	// At least 1 upper case char;
	const upperCaseCheck = () => /[A-Z]+/.test(password);
	// At least 1 lower case char;
	const lowerCaseCheck = () => /[a-z]+/.test(password);
	// only valid characters: ie non-control ASCII characters, at 32-127
	const validOnlyCheck = () => password.length > 0 && !/[^\x20-\x7E]/.test(password);
	// no whitespace
	const noWhiteSpace = () => ! /\s/.test(password);

	return [lengthCheck(), numberCheck(), specialCheck(), upperCaseCheck(), lowerCaseCheck(), validOnlyCheck(), noWhiteSpace()];
};

export const calculateTotalBill = ({totalPrice, tax}: {totalPrice?: string , tax?: string | null}) => {
	const taxAmountFloat =
		(tax != null && totalPrice != null && parseFloat(tax) * 0.01 * parseFloat(totalPrice)) || 0;
	const totalWithTax =
		(totalPrice != null && Math.round((taxAmountFloat + parseFloat(totalPrice)) * 100) / 100) ||
		undefined;
	return [taxAmountFloat, totalWithTax];
};

export const getVariantForWSPStatus = (status: string) => {
	switch (status) {
		case RETRACTED:
		case DECLINED:
			return 'danger';
		case PENDING_REVIEW:
		case REVIEWED:
		case APPROVED_AND_ACTIVATING:
			return 'warning';
		case APPROVED:
		case APPROVED_AND_ACTIVE:
			return 'success';
		case APPROVED_AND_INACTIVE:
			return 'secondary';
		default:
			return '';
	}
};

export const getCommentByType = (
	orderComments?:
		| {
				comment: string;
				type: string;
		  }[]
		| null,
	type?: 'PUBLISHER' | 'REVIEWER' | 'REJECTION',
) => orderComments?.find((comment) => comment.type === type)?.comment || undefined;


export const getProductParams = (items?: { game_code: string; device_type: string; parent_component_game_code: string; }[]): [string[], URLSearchParams] => {
	const gameCodes: string[] = [];
	const platformCodes: string[] = [];

	items?.forEach((item) => {
		const trimmedGameCode = getProductGameCode(item);
		gameCodes.push(trimmedGameCode);
		platformCodes.push(item.device_type);
	});
	const platformCodeParams = new URLSearchParams();
	uniq(platformCodes).forEach((code) => {
		platformCodeParams.append('platform_code', code);
	});
	return [uniq(gameCodes), platformCodeParams];
};

const isInactiveApprovedDeclaredPrice = (request?: (WholesalePriceRequestData | DeclaredPriceRequestData)) => {
	return !!(
		request &&
		'declared_price_request_id' in request &&
		request?.rollup_status === null &&
		request?.action_type?.toUpperCase() === 'TRANSFERRED' &&
		request?.request_status?.toUpperCase() === APPROVED &&
		request?.price_status?.toUpperCase() === INACTIVE
	);
};

export const getProductGameCode = (item: Record<string, any>): string => {
	return item.parent_component_game_code ? (
		item.parent_component_game_code.substring(item.parent_component_game_code.length - 5)
	) : (
		item.game_code.substring(item.game_code.length - 5)
	);
};

export const doesOrderHaveInvalidValues = (orderData?: Partial<DigitalOrderData>) => {
	const orderHasInvalidValues = !!orderData && [
		...Object.values(orderData), 
	].some((value) => !!isInvalid(value));
	return orderHasInvalidValues;
};

export const formatTaxPercentageDisplay = (taxPercent: string) =>
	Number(taxPercent) !== 0
		? new Intl.NumberFormat('en-US', {
			style: 'unit',
			unit: 'percent',
			minimumFractionDigits: 1,
		}).format(Number(taxPercent))
		: null;


export const getReasonForInvalidValue = (
	subject: string,
	invalidData?: InvalidData | null,
) => {
	if (!invalidData?.invalid) {
		return undefined;
	}
	switch (invalidData.code) {
		// product and component errors
		case 'COMPONENT_WITHOUT_WHOLESALE_PRICE':
		case 'INVALID_PLATFORM_FEATURE':
		case 'NO_DIGITAL_RIGHTS':
			return `The order placer does not have the rights to order this ${subject}`;
		case 'DIGITAL_TRANSFER_IN_PROGRESS':
			return `The previously selected ${subject} is undergoing a rights transfer that is preventing the order from progressing`;

		// generic errors
		case 'INVALID_DATE':
		case '':
		default:
	}
	return `The previously entered ${subject} is no longer valid and will need to be replaced`;
};

export const checkUnitPriceFeeMismatch = (
	ebsPrices: Partial<ComponentPriceData>,
	orderPrices: Partial<DigitalOrderData>,
) => !!(((ebsPrices?.unit_price &&
		(Number(orderPrices.unitPrice) !== Number(ebsRoundedUnitPrice(ebsPrices?.unit_price)))))
	|| ((ebsPrices?.service_fee &&
		(Number(orderPrices.serviceFee) !== Number(ebsRoundedPrice(ebsPrices?.service_fee))))))
;


export const checkTotalPriceMismatch = (
	ebsTotalPrice: string,
	orderTotalPrice: string,
) => ebsTotalPrice && orderTotalPrice && (Number(orderTotalPrice) !== Number(ebsRoundedPrice(ebsTotalPrice)));
