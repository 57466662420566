import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import intersection from 'lodash/intersection';
import pick from 'lodash/pick';
import { Alert } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import ActionLink from '../../components/ActionLink/ActionLink';
import BaseModal from '../../components/BaseModal/BaseModal';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FAIcon from '../../components/FAIcon/FAIcon';
import Page from '../../components/Page/Page';
import { orderConst } from '../../constants/orderConstants';
import ActionConfirmationModal from '../../components/modals/ActionConfirmationModal/ActionConfirmationModal';
import SimpleModal from '../../components/modals/SimpleModal/SimpleModal';
import {
	deleteDigitalCodeOrder,
	getDigitalOrder,
	getUsedPONums,
	postNewOrder,
	postRequestForQuote,
	putUpdateNewOrder,
} from '../../services/digitalCodesService';
import { isEmptyObject } from '../../utils/dataUtils';
import { getCommentByType } from '../../utils/digitalCodesUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { validateToSchema } from '../../utils/validationUtils';
import { useProductQuery, usePurposeQuery } from './DigitalCodesOrderCreate.helper';
import Step1OrderInfo from './views/Step1OrderInfo';
import Step2ProductsAndComponents from './views/Step2ProductsAndComponents';
import Step3Confirmation from './views/Step3Confirmation';


const STEP_1 = 'step-1-order-info';
const STEP_2 = 'step-2-products-and-components';
const STEP_3 = 'step-3-confirmation';

const CONFIRM_MODAL = 'CONFIRM_MODAL';
const DELETE_MODAL = 'DELETE_MODAL';
const INFO_MODAL = 'INFO_MODAL';
const CHANGE_PURPOSE_MODAL = 'CHANGE_PURPOSE_MODAL';

// sets the minimum lead time from today (or time order is submitted) to the earliest
// possible date for requested delivery or activation dates
const MINIMUM_DAYS = global.config.dcOrderMinimumDays || 7;
const getEarliestDay = () => dayjs().add(7, 'days').startOf('day').toDate();

const saveSchema = yup.object({
	orderComments: yup.string().nullable().default(null),
	publisherPO: yup.string().trim().nullable()
		.required('Publisher PO # must be provided')
		.test(
			'po_format_test',
			'Publisher PO # only allows these characters: A-Z, a-z, 0-9, - . # /',
			(value) => !/[^a-z0-9-/.#]/i.test(value),
		)
		.test(
			'po_reuse_test',
			'Cannot reuse a Publisher PO # from another order',
			(value, { options }) => !options.context.usedPONumbers || !options.context.usedPONumbers.includes(value),
		),
	purposeID: yup.number().required('Purpose must be selected'),
	purposeDescription: yup.string().nullable(),
	reqDeliveryDate: yup.string().nullable()
		.test(
			'min_date_test',
			`Requested Delivery Date must be at earliest ${MINIMUM_DAYS} days from today or be blank`,
			(value) => dayjs(value).startOf('day').toDate() >= getEarliestDay()
		),
	reqActivationDate: yup.string().nullable()
		.test(
			'min_date_test',
			`Requested Activation Date must be at earliest ${MINIMUM_DAYS} days from today or be blank`,
			(value) => dayjs(value).startOf('day').toDate() >= getEarliestDay()
		),
	productID: yup.number().nullable(),
	componentID: yup.string().nullable(),
	quantity: yup.mixed().nullable(),
});

const step1Schema = yup.object({
	orderComments: yup.string().nullable().default(null),
	publisherPO: yup.string().trim().nullable()
		.required('Publisher PO # must be provided')
		.test(
			'po_format_test',
			'Publisher PO # only allows these characters: A-Z, a-z, 0-9, - . # /',
			(value) => !/[^a-z0-9-/.#]/i.test(value),
		)
		.test(
			'po_reuse_test',
			'Cannot reuse a Publisher PO # from another order',
			(value, { options }) => !options.context.usedPONumbers || !options.context.usedPONumbers.includes(value),
		),
	purposeDescription: yup.string().nullable(),
	purposeID: yup.number().nullable().required('Purpose must be selected'),
	reqDeliveryDate: yup.string().nullable()
		.test(
			'min_date_test',
			`Must be at earliest ${MINIMUM_DAYS} days from today`,
			(value) => dayjs(value).startOf('day').toDate() >= getEarliestDay()
		)
		.required('Date cannot be blank'),
	reqActivationDate: yup.string().nullable()
		.test(
			'min_date_test',
			`Must be at earliest ${MINIMUM_DAYS} days from today`,
			(value) => dayjs(value).startOf('day').toDate() >= getEarliestDay()
		)
		.required('Date cannot be blank'),
});

const step2Schema = yup.object({
	productID: yup.number()
		.nullable()
		.required('A product must be selected for a price to be generated'),
	componentID: yup.string()
		.nullable()
		.required('A component must be selected for a price to be generated'),
	quantity: yup.mixed()
		.transform((current) => current ? Number(current) : current)
		.nullable()
		.test(
			'quantity_batches_test',
			'Quantity must be in multiples of the batch amount for the selected purpose.',
			(value, { options }) => !options.context.selectedPurpose || value % options.context.selectedPurpose.code_batch_increments === 0
		)
		.test(
			'quantity_range_test',
			'Quantity must within the allowed range for the selected purpose',
			(value, { options }) => !options.context.selectedPurpose || (value >= options.context.selectedPurpose.min_num_of_codes &&  value <= options.context.selectedPurpose.max_num_of_codes)
		)
		.required('A quantity must be provided for a price to be generated'),
});

const DigitalCodesOrderCreate = () => {
	const [step, setStep] = useState(STEP_1);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [openModal, setOpenModal] = useState();
	const [showAllErrors, setShowAllErrors] = useState(false);
	const [lastProgressPing, setLastProgressPing] = useState();
	const { orderHeaderId } = useParams();
	const history = useHistory();
	useEffect(() => {
		(async () => {
			await usedPOsQuery.refetch();
		})();
	}, []);

	const orderQuery = useQuery(['orderQuery', orderHeaderId], () => getDigitalOrder(orderHeaderId), { enabled: !!orderHeaderId });
	const orderData = orderQuery.isSuccess && orderQuery.data.data.body;
	const purposeQuery = usePurposeQuery();
	const purposes = purposeQuery.data?.data;

	const productQuery = useProductQuery();
	const isLoading = orderQuery.isLoading || purposeQuery.isLoading || productQuery.isLoading;
	const fault = (orderQuery.isError && orderQuery.error) ||
		(purposeQuery.isError && purposeQuery.error) ||
		(productQuery.isError && productQuery.error);

	// since the data for this query is only used for validation, we can tolerate some staleness.
	const usedPOsQuery = useQuery(['getUsedPONums', orderHeaderId], () => getUsedPONums(orderHeaderId), { enabled: false });
	const usedPONumbers = usedPOsQuery?.data?.data.filter((num) => !orderData || num !== orderData.publisherPO) || [];

	const goBackToPage = (step) => {
		setStep(step);
	};

	const gotoNextPage = async (step, newValues) => {
		if (step === STEP_2) {
			// only prevent next page if rule fails for purposeID or invalid delivery dates
			const validationResults = validateStep1(newValues);
			const overlap = intersection(Object.keys(validationResults), ['purposeID', 'reqDeliveryDate', 'reqActivationDate']);
			if (overlap.length > 0) {
				setShowAllErrors(true);
				return;
			}
		} else if (step === STEP_3) {
			// prevent next page if any rule fails
			if (!isEmptyObject(validateStep2(newValues))) {
				setShowAllErrors(true);
				return;
			}
		}

		const saved = await submitForSaving(newValues);
		if (saved) {
			setStep(step);
			setShowAllErrors(false);
		}
	};

	const submitForSaving = async (newValues) => {
		const orderTemplate = {
			'reqDeliveryDate': null,
			'reqActivationDate': null,
			'purposeID': null,
			'purposeDescription': '',
			'publisherPO': null,
			'productID': null,
			'componentID': null,
			'quantity': null,
			'orderComments': null,
			'purposeText': null,

		};

		// if there was a change in purposeID, confirm with user that
		// we will blank certain values
		if (orderData && newValues.purposeID && newValues.purposeID !== orderData.purposeID) {
			const result = await new Promise((resolve) => {
				const result = (proceed) => { resolve(proceed); setOpenModal(null); };
				setOpenModal({ type: CHANGE_PURPOSE_MODAL, continue: () => result(true), cancel: () => result(false) });
			});
			if (!result) {
				return result;
			}
			newValues = {...newValues, productID: null, componentID: null, quantity: null};
		}

		setIsSaving(true);
		const purposeID = newValues?.purposeID || orderData?.purposeID;
		if (purposeID) {
			newValues = {
				...newValues,
				purposeText:
				(
					purposes.find(
							(purpose) =>
								purpose.digital_orders_purpose_id === purposeID,
					) || {}
				).purpose
			};
		}
		let validation = null;
		try {
			const castedNewValues = saveSchema.cast(newValues);
			if (orderData) {
				const existingOrder = pick(orderData, Object.keys(orderTemplate));
				const payload = {...orderTemplate, ...existingOrder, ...castedNewValues};
				if (step === STEP_2) {
					const existingComments = getCommentByType(orderData.order_comments, orderConst.COMMENT.PUBLISHER);
					payload.orderComments = existingComments ? existingComments : null;
				}
				validation = validateToSchema(saveSchema, payload, { usedPONumbers });
				if (!isEmptyObject(validation)) {
					throw new Error('Validation error');
				}
				await putUpdateNewOrder(orderData.orderID, payload);
				await orderQuery.refetch();
			} else {
				const payload = {orderComments: null, ...orderTemplate, ...castedNewValues};
				validation = validateToSchema(saveSchema, payload, { usedPONumbers });
				if (!isEmptyObject(validation)) {
					throw new Error('Validation error');
				}
				const response = await postNewOrder(payload);
				history.replace(`/orders/digital-codes/${response.data.orderID}/create`);
			}
			toasterNotify('Draft order saved', 'success');
			setIsSaving(false);
			return true;
		} catch (err) {
			if (!isEmptyObject(validation)) {
				const list = Object.values(validation).map((item) => <li>{item}</li>);
				toasterNotify(<>There are problems with the current order: <ul className="mb-0">{list}</ul></>, 'error');
			}

			toasterNotify('Draft order was not saved because of the error', 'error');
			setIsSaving(false);
			return false;
		}
	};

	const onCancel = () => history.push('/orders/digital-codes');

	const onDelete = () => setOpenModal({type: DELETE_MODAL});
	const onDeleteConfirmed = async () => {
		setIsSubmitting(true);
		if (!orderHeaderId) {
			onCancel();
			toasterNotify('Your draft order has been abandoned', 'success');
			return;
		}
		try {
			await deleteDigitalCodeOrder(orderHeaderId);
			onCancel();
			toasterNotify('Your draft order has been deleted', 'success');
		} catch (error) {
			toasterNotify(createMessageForError(error, 'deleting the order'), 'error', error);
		} finally {
			setOpenModal(null);
			setIsSubmitting(false);
		}

	};

	const selectedPurpose =
		orderData &&
		purposeQuery.isSuccess &&
		orderData.purposeID != null &&
		purposes.find(
			(purpose) => purpose.digital_orders_purpose_id === orderData.purposeID,
		);
	const validateStep1 = (values) => validateToSchema(step1Schema, values, { usedPONumbers });
	const validateStep2 = (values) =>
		validateToSchema(step2Schema, values, {
			selectedPurpose,
		});

	const submitForReview = async (totalPrice, unitPrice) => {
		// validate order data so far before proceeding
		const values = pick(orderData, [
			'componentID',
			'orderComments',
			'orderID',
			'productID',
			'publisherPO',
			'purposeDescription',
			'purposeID',
			'purposeText',
			'quantity',
			'reqActivationDate',
			'reqDeliveryDate',
		]);
		if (!isEmptyObject(validateStep1(values))) {
			setStep(STEP_1);
			setShowAllErrors(true);
			return;
		} else if (!isEmptyObject(validateStep2(values))) {
			setStep(STEP_2);
			setShowAllErrors(true);
			return;
		}
		// open confirm modal
		const pricesForSubmission = { totalPrice, unitPrice };
		setOpenModal({ type: CONFIRM_MODAL, pricesForSubmission });
	};
	const onSubmitConfirmed = async () => {
		const { totalPrice, unitPrice } = openModal.pricesForSubmission;
		try {
			setIsSubmitting(true);
			await postRequestForQuote(
				orderHeaderId,
				{
					'price': totalPrice,
					'unitPrice': unitPrice
				},
				() => {
					setLastProgressPing(new Date());
				}
			);
			history.push(`/orders/digital-codes/${orderHeaderId}`);
			toasterNotify('Order was submitted for review', 'success');
			setOpenModal(null);
		} catch(error) {
			toasterNotify(createMessageForError(error, 'submitting the order'), 'error', error);
		} finally {
			usedPOsQuery.refetch();
			setIsSubmitting(false);
			setLastProgressPing();
		}
	};

	return (
		<Page fault={fault} isLoading={isLoading}>
			<Alert variant="warning" className="alert-icon">
				Please check that all requirements are met before ordering.{' '}
				<ActionLink
					onClick={() => setOpenModal({ type: INFO_MODAL })}
				>
					View Requirements <FAIcon name="chevron-right" size="xs" />
				</ActionLink>
			</Alert>
			<Breadcrumb>
				<li className="breadcrumb-item">
					<Link to="/orders/digital-codes">Digital Code Orders</Link>
				</li>
				<Breadcrumb.Item active>Create New Digital Code Order</Breadcrumb.Item>
			</Breadcrumb>

			{step === STEP_2 ? (
				<Step2ProductsAndComponents
                    orderData={orderData}
                    isSubmitting={isSubmitting}
                    isSaving={isSaving}
                    onSave={(newValues) => submitForSaving(newValues)}
                    onNext={(newValues) => gotoNextPage(STEP_3, newValues)}
                    onPrevious={() => goBackToPage(STEP_1)}
					onCancel={() => onCancel()}
					onDelete={() => onDelete()}
					validate={(values) => validateStep2(values)}
					showAllErrors={showAllErrors}

					products={productQuery.isSuccess && productQuery.data.data}
				/>
			) : step === STEP_3 ? (
				<Step3Confirmation
                    orderData={orderData}
                    isSubmitting={isSubmitting}
					onCancel={() => onCancel()}
					onDelete={() => onDelete()}
                    onPrevious={() => goBackToPage(STEP_2)}
                    onSubmit={(price, unitPrice) => submitForReview(price, unitPrice)}
					lastProgressPing={lastProgressPing}
                />
			) : <Step1OrderInfo
                    orderData={orderData}
                    onSave={(newValues) => submitForSaving(newValues)}
					onCancel={() => onCancel()}
					onDelete={() => onDelete()}
                    isSubmitting={isSubmitting}
                    isSaving={isSaving}
                    onNext={(newValues) => gotoNextPage(STEP_2, newValues)}
					validate={(values) => validateStep1(values)}
					minimumDays={MINIMUM_DAYS}
					showAllErrors={showAllErrors}
                />
			}
			<BaseModal
				show={openModal && openModal.type === CONFIRM_MODAL}
				isSubmitting={isSubmitting}
				onCancel={() => setOpenModal(null)}
			>
				<BaseModal.Title>
					Confirm Submission
				</BaseModal.Title>
				<Alert className="mb-0" variant="info">Are you sure you want to submit this order for review?</Alert>
				<BaseModal.Submit variant='primary' onClick={async () => onSubmitConfirmed()}>
					Yes
				</BaseModal.Submit>
				<BaseModal.Cancel onClick={() => setOpenModal(null)}>Cancel</BaseModal.Cancel>
				<BaseModal.SubmissionStatus lastUpdated={lastProgressPing}>
					Submission may take a moment
				</BaseModal.SubmissionStatus>
			</BaseModal>
			<BaseModal
				show={openModal && openModal.type === DELETE_MODAL}
				onCancel={() => setOpenModal(null)}
				isSubmitting={isSubmitting}
			>
				<BaseModal.Title>
					<span className="text-danger">Confirm Delete</span>
				</BaseModal.Title>
				<Alert className="mb-0" variant="danger">Are you sure you want to delete this order?</Alert>
				<BaseModal.Submit variant='danger' onClick={async () => onDeleteConfirmed()}>
					Yes
				</BaseModal.Submit>
				<BaseModal.Cancel onClick={() => setOpenModal(null)}>Cancel</BaseModal.Cancel>
			</BaseModal>
			<SimpleModal
				size="lg"
				title="Information about the Digital Code order process"
				show={openModal && openModal.type === INFO_MODAL}
				closeModal={() => setOpenModal(null)}
			>
				<div>
					<b>Payments:</b>
					<br />
					<p>
						Digital code orders can be completed by PayPal for orders less than $10,000.
						Orders in excess of $10,000 require a wire transfer for payment.
					</p>
					<p>
						Note, you are responsible for all wire transfer fees. The final amount
						transferred to Nintendo of America must match the amount shown on the
						pro-forma invoice.
					</p>
					<b>Requirements to order:</b>
					<br />
					<ul>
						<li>Your game must have an NCMS entry with a permanent price.</li>
						<li>
							The price must be &lsquo;live&rsquo; but the game page can be hidden.
						</li>
						<li>
							The game/component must have a price greater than $0. Free items are not
							included.
						</li>
						<li>Codes are for use in the Americas only.</li>
					</ul>
					<b>Digital Code order process:</b>
					<br />
					<ul>
						<li>Complete the order information in Step 1.</li>
						<li>
							Select the game and component in Step 2. Individual components (AOC,
							consumables, subscriptions) are included.
						</li>
						<li>A price for the order will be provided.</li>
						<li>Submit the order for review by Nintendo of America.</li>
						<li>
							After review, the order will be returned with a status Awaiting Payment
							Info. A pro-forma invoice is included.
						</li>
						<li>
							Select your payment type. PayPal payments are completed through the
							PayPal interface. The codes will be generated once the payment has been
							received.
						</li>
						<li>
							Once payment is complete Nintendo will attach the codes to the order.
						</li>
						<li>
							To retrieve the codes, access the order to download the code batch. The
							code batch is available to download for 30 days.
						</li>
					</ul>
				</div>
			</SimpleModal>
			<ActionConfirmationModal
				show={openModal && openModal.type === CHANGE_PURPOSE_MODAL}
				onCancel={() => { openModal.cancel(); }}
				onConfirm={() => { openModal.continue(); }}
				title="Purpose Changed"
				confirmLabel="Proceed"
				cancelLabel="Cancel"
			>
				<Alert variant="warning">
					Because Purpose was changed, previously entered Product, Component and Quantity values will be reset. Would you like to proceed?
				</Alert>
			</ActionConfirmationModal>
		</Page>
	);
};
export default DigitalCodesOrderCreate;
