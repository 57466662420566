import React from 'react';
import dayjs from 'dayjs';
import { Component } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import ActionLink from '../../components/ActionLink/ActionLink';
import { linkColumn } from '../../components/BaseTable/BaseTable';
import FAIcon from '../../components/FAIcon/FAIcon';
import FilterableTable from '../../components/FilterableTable/FilterableTable';
import Loading from '../../components/Loading/Loading';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import { permConst } from '../../constants/permConst';
import { projectionStatus } from '../../constants/projectionsConstants';
import { timeZoneConstants } from '../../constants/timeZoneConstants';
import { getCompanies } from '../../services/companiesService';
import {
	getLatestProjectionWindows,
	getProjectionReminderLog,
	getProjectionsList
} from '../../services/projectionsService';
import { dateFormat, formatDate, parseDateString } from '../../utils/dateUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { isAuthorized } from '../../utils/userUtils';
import EditProjectionsPeriodModal from './modals/EditProjectionsPeriodModal';
import OpenProjectionPeriodModal from './modals/OpenProjectionPeriodModal';
import ProjectionsNotificationsModal from './modals/ProjectionsNotificationsModal';

import './ProjectionsManagement.css';


function mapStateToProps(state) {
	return {
		userProfile: state.authReducer.userProfile
	};
}

function mapCompanyList(companiesList) {
	let companyNameMap = {};
	companiesList.forEach((company) => {
		companyNameMap[company.ndid_company_id] = {
			name: company.company_name
		};
	});
	return companyNameMap;
}

function determineStatusClass(status) {
	let statusClass;
	switch (status) {
		case projectionStatus.NO_PROJECTIONS:
		case projectionStatus.NOTIFICATION_FAILED:
			statusClass = 'text-danger';
			break;
		case projectionStatus.PROJECTIONS_SENT:
			statusClass = 'text-success';
			break;
		case projectionStatus.NOTIFICATION_SENT:
			statusClass = 'text-info';
			break;
		case projectionStatus.NOTIFICATION_PENDING:
			statusClass = 'text-muted';
			break;
		default:
			statusClass = '';
			break;
	}
	return statusClass;
}

function renderMutedDateText(projItem) {
	let mutedText = '';
	let projReceivedDate = projItem.projections_received_date;
	if (projReceivedDate) {
		mutedText = formatDate(
			parseDateString(projItem.projections_received_date),
			dateFormat.DATETIME_PT
		);
	} else if (projItem.notification_sent_date) {
		mutedText = formatDate(
			parseDateString(projItem.notification_sent_date),
			dateFormat.DATETIME_PT
		);
	}
	return mutedText;
}

function combineResponses(projections, reminder_log) {
	const allData = [];
	projections.forEach((projection) => {
		const log_entry = reminder_log[projection.ndid_company_id];
		if (log_entry != null) {
			projection['notification_status'] = log_entry['status'];
			const date_sent = log_entry['date_sent'];
			if (date_sent != null) {
				projection['notification_sent_date'] = parseDateString(
					date_sent,
					timeZoneConstants.UTC,
					timeZoneConstants.LOCAL
				).toISOString();
			}
		}
		allData.push(projection);
	});
	return allData;
}

function mapProjectionsList(projections, companies) {
	let projectionData = [];
	projections.forEach((projection) => {
		let companyNdid = projection.ndid_company_id;
		if (companyNdid in companies) {
			projection['name'] = companies[companyNdid]['name'];
			let status;
			let projectionsReceived = projection['projections_received_date'] != null;
			if (projectionsReceived) {
				projection['projections_received_date'] = parseDateString(
					projection['projections_received_date'],
					timeZoneConstants.UTC,
					timeZoneConstants.LOCAL
				).toISOString();
				status = projectionStatus.PROJECTIONS_SENT;
			} else if (projection['notification_status'] === 'pending') {
				status = projectionStatus.NOTIFICATION_PENDING;
			} else if (projection['notification_status'] === 'sent') {
				status = projectionStatus.NOTIFICATION_SENT;
			} else if (projection['notification_status'] === 'failed') {
				status = projectionStatus.NOTIFICATION_FAILED;
			} else {
				status = projectionStatus.NO_PROJECTIONS;
			}
			projection['projection_status'] = status;
			projection['projections_received'] = projectionsReceived;
			projectionData.push(projection);
		}
	});
	return projectionData;
}

export class ProjectionsManagement extends Component {
	constructor(props) {
		super(props);
		let dataFormat = this.getTableFormat();
		let filterProperties = new Map();
		filterProperties.set('Status', {
			filter: 'projection_status',
			selectableFilters: new Set()
		});
		this.state = {
			dataFormat: dataFormat,
			filterProperties: filterProperties,
			projectionsInfo: null,
			projectionsInfoFormatted: [],
			projectionsRange: [],
			projectionRangeHuman: '---',
			projectionMonth: '---',
			companyInfo: {},
			isLoading: true,
			showModal: false,
			modalType: null
		};

		this.pageInit = this.pageInit.bind(this);
		this.toggleLoading = this.toggleLoading.bind(this);
		this.getProjectionInfo = this.getProjectionInfo.bind(this);
		this.getCompanyInfo = this.getCompanyInfo.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	toggleModal(key) {
		const { showModal } = this.state;
		let type = null;
		if (!showModal) {
			type = key;
		}
		this.setState({
			showModal: !this.state.showModal,
			modalType: type
		});
	}

	renderModal() {
		const {
			modalType,
			projectionsInfoFormatted,
			projectionsRange,
			submissionPeriodId,
			notificationData
		} = this.state;
		const { userProfile } = this.props;
		switch (modalType) {
			case 'openProjectionPeriod':
				return (
					<OpenProjectionPeriodModal
						toggleModal={this.toggleModal}
						userProfile={userProfile}
						pageInit={this.pageInit}
						projectionRange={projectionsRange}
						submissionPeriodId={submissionPeriodId}
						toggleParentLoading={this.toggleLoading}
					/>
				);
			case 'editProjectionPeriod':
				return (
					<EditProjectionsPeriodModal
						toggleModal={this.toggleModal}
						userProfile={userProfile}
						pageInit={this.pageInit}
						projectionRange={projectionsRange}
						submissionPeriodId={submissionPeriodId}
						toggleParentLoading={this.toggleLoading}
					/>
				);
			case 'notifyPublishersButton':
				return (
					<ProjectionsNotificationsModal
						toggleModal={this.toggleModal}
						userProfile={userProfile}
						pageInit={this.pageInit}
						toggleParentLoading={this.toggleLoading}
						companyList={projectionsInfoFormatted}
						notificationData={notificationData}
					/>
				);
			default:
				return '';
		}
	}

	pageInit() {
		this.getProjectionRangeInfo();
		this.getCompanyInfo();
	}

	getCompanyInfo() {
		getCompanies('projectable=true')
			.then((response) => {
				this.setState({
					companyInfo: mapCompanyList(response.data)
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving companies'),
					'error',
					error
				);
			})
			.finally(() => {
				this.getProjectionInfo();
			});
	}

	getProjectionInfo() {
		getProjectionsList()
			.then((response) => {
				let responseData = response.data;
				if (responseData) {
					this.setState(
						{
							projectionsInfo: responseData,
						},
						() => {
							this.getNotificationInfo();
						},
					);
				} else {
					this.toggleLoading();
				}
			})
			.catch((error) => {
				if (!error.response || error.response.status !== 404) {
					toasterNotify(
						createMessageForError(error, 'loading projections'),
						'error',
						error
					);
				}
				this.toggleLoading();
			});
	}

	getNotificationInfo() {
		const { companyInfo, projectionsInfo, notificationData } = this.state;
		const begin_date = notificationData.submission_start_date;
		const end_date = notificationData.submission_end_date;
		getProjectionReminderLog(begin_date, end_date)
			.then((response) => {
				const allData = combineResponses(
					projectionsInfo,
					response.data
				);
				this.setState({
					projectionsInfoFormatted: mapProjectionsList(
						allData,
						companyInfo
					)
				});

			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving projection reminders'),
					'error',
					error
				);
			})
			.finally(() => {
				this.toggleLoading();
			});
	}

	getProjectionRangeInfo() {
		getLatestProjectionWindows()
			.then((response) => {
				let startHuman = dayjs(
					response.data.submission_start_date
				).format(dateFormat.DATE);
				let endHuman = dayjs(
					response.data.submission_end_date
				).format(dateFormat.DATE);
				let projectionsMonth = dayjs(
					response.data.submission_start_date
				).format('MMMM YYYY');
				let start = dayjs(
					response.data.submission_start_date
				).format(dateFormat.DATE_YMD); 
				let end = dayjs(response.data.submission_end_date).format(
					dateFormat.DATE_YMD
				);
				this.setState({
					notificationData: response.data,
					projectionRangeHuman: [startHuman, endHuman],
					projectionMonth: projectionsMonth,
					projectionsRange: [start, end],
					submissionPeriodId:
						response.data.order_projection_submission_period_id
				});
			})
			.catch((error) => {
				if (!error.response || error.response.status !== 404) {
					toasterNotify(
						createMessageForError(error, 'loading projection range'),
						'error',
						error
					);
				}
			});
	}

	getStatusFilters() {
		const { filterProperties } = this.state;
		[
			projectionStatus.NO_PROJECTIONS,
			projectionStatus.PROJECTIONS_SENT,
			projectionStatus.NOTIFICATION_SENT,
			projectionStatus.NOTIFICATION_PENDING,
			projectionStatus.NOTIFICATION_FAILED,
		].forEach((filter) => {
			filterProperties.get('Status').selectableFilters.add(filter);
		});
	}

	getTableFormat() {
		return [
			{
				Header: 'Publisher',
				id: 'publisher',
				accessor: 'name',
			},
			{
				Header: 'Status',
				accessor: 'projection_status',
				Cell: (props) => (
					<div>
						<strong className={determineStatusClass(props.original.projection_status)}>
							{props.original.projection_status}
						</strong>
						<p className="text-muted small">
							{renderMutedDateText(props.original)}
						</p>
					</div>
				)
			},
			{
				...linkColumn,
				Cell: (props) => {
					return (
							<Link to={`/orders/projections/${props.original.ndid_company_id}`}>
								<FAIcon name="chevron-right" />
							</Link>
					);
				}
			}
		];
	}

	isProjectionPeriodOpen() {
		const { isLoading, notificationData } = this.state;

		if (!isLoading && !notificationData) {
			return false;
		}

		return notificationData.is_open;
	}

	isProjectionPeriodCreatedInFuture() {
		const { isLoading, projectionsRange } = this.state;

		if (!isLoading && projectionsRange) {
			const startDate = dayjs(projectionsRange[0], 'DD/MM/YYYY');
			const currentDate = dayjs();

			return currentDate.isBefore(startDate);
		}
		return false;
	}

	renderProjectionsButtons() {
		const { isLoading } = this.state;

		if (!isLoading) {
			return (
				<div>
					{this.displayOpenPeriodButton() && (
						<Button
							className="btn btn-primary btn"
							id="openProjectionPeriod"
							onClick={(e) => this.toggleModal(e.target.id)}
							disabled={isLoading}
						>
							Open Projection Period
						</Button>
					)}
					{this.displayNotifyButton() && (
						<Button
							className="btn btn-primary btn"
							id="notifyPublishersButton"
							onClick={(e) => this.toggleModal(e.target.id)}
							disabled={isLoading}
						>
							Notify Publishers
						</Button>
					)}
				</div>
			);
		}
	}

	componentDidMount() {
		this.pageInit();
		this.getStatusFilters();
	}

	displayRangeEditLink() {
		const { isLoading } = this.state;

		return (
			!isLoading &&
			isAuthorized(this.props.userProfile.permissions, [permConst.PROJECTION.WINDOW.EDIT]) &&
			(this.isProjectionPeriodOpen() ||
				this.isProjectionPeriodCreatedInFuture())
		);
	}

	displayOpenPeriodButton() {
		const { isLoading } = this.state;

		return (
			!isLoading &&
			isAuthorized(this.props.userProfile.permissions, [permConst.PROJECTION.WINDOW.EDIT]) &&
			!this.isProjectionPeriodOpen() &&
			!this.isProjectionPeriodCreatedInFuture()
		);
	}

	displayNotifyButton() {
		const { isLoading } = this.state;

		return (
			!isLoading &&
			isAuthorized(this.props.userProfile.permissions, [permConst.PROJECTION.NOTIFY.ALL]) &&
			(this.isProjectionPeriodOpen() ||
				this.isProjectionPeriodCreatedInFuture())
		);
	}

	render() {
		const {
			dataFormat,
			filterProperties,
			projectionsInfoFormatted,
			isLoading,
			projectionRangeHuman,
			projectionMonth
		} = this.state;
		const searchableFields = ['name'];
		const searchableFieldPlaceHolder = 'Search by Publisher';
		const title = `Projections Report - ${projectionMonth}`;
		return (
			<Page>
				{this.displayRangeEditLink() && (
					<Alert variant="info">
						Projections Period:{' '}
						<strong>
							{`${projectionRangeHuman[0]} - ${
								projectionRangeHuman[1]
							}`}{' '}
						</strong>
						<ActionLink
							className="float-right"
							id="editProjectionPeriod"
							onClick={(e) => this.toggleModal(e.target.id)}
						>
							Edit
						</ActionLink>
					</Alert>
				)}
				<div>
					<Title
						title={title}
						button={this.renderProjectionsButtons()}
					/>
					{isLoading ? (
						<Loading />
					) : (
						<FilterableTable
							id='projectionsTable'
							data={projectionsInfoFormatted}
							dataFormat={dataFormat}
							filterProperties={filterProperties}
							searchableFields={searchableFields}
							searchableFieldPlaceHolder={
								searchableFieldPlaceHolder
							}
						/>
					)}
				</div>
				{this.renderModal()}
			</Page>
		);
	}
}

export default connect(mapStateToProps)(ProjectionsManagement);
