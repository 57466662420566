import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';

import DropdownCheckbox from './views/DropdownCheckbox';

import './DropdownFilter.css';


class DropdownFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isActive: false,
		};
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	toggleDropdown() {
		this.setState((state) => ({
			isActive: !state.isActive,
		}));
	}

	setAllCheckboxes(toChecked = true) {
		const { setFilters, clearFilter, filterName } = this.props;

		if (!toChecked) {
			clearFilter(filterName);
		} else {
			setFilters();
		}
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({ isActive: false });
		}
	}

	formattedSelectedValues() {
		const { filterValues, selectedValues } = this.props;
		if (selectedValues.size === filterValues.size) {
			return <strong>All</strong>;
		}
		if (selectedValues.size > 2) {
			return <>
				<strong>{Array.from(selectedValues).slice(0, 2).join(', ')} </strong>
				<Badge pill variant="primary">
					+{selectedValues.size - 2} more
				</Badge>
			</>;
		}
		return <strong>{Array.from(selectedValues).slice(0, 2).join(', ')}</strong>;
	}

	render() {
		const { isActive } = this.state;
		const { filterValues, addFilter, filterName, selectedValues } = this.props;
		const filterArray = Array.from(filterValues);
		const selectedArray = Array.from(selectedValues);

		const filterDisplay =
			selectedValues.size === 0 ? (
				filterName
			) : (
				<>
					{filterName}: <b>{this.formattedSelectedValues()}</b>
				</>
			);
		return (
			<li
				role="presentation"
				className={
					isActive
						? 'DropdownFilter__dropdown DropdownFilter__dropdown--open dropdown open'
						: 'DropdownFilter__dropdown dropdown'
				}
				ref={this.setWrapperRef}
			>
				<a
					className="dropdown-toggle dropdown-checklist-toggle"
					onClick={() => this.toggleDropdown()}
				>
					{filterDisplay}
				</a>
				<ul
					className={
						isActive
							? 'DropdownFilter__scrollable dropdown-menu show'
							: 'DropdownFilter__scrollable dropdown-menu'
					}
				>
					<header className="dropdown-menu-header">
						<div
							className="dropdown-menu-header-link"
							onClick={() => this.setAllCheckboxes(true) && false}
						>
							Select All
						</div>
						{' / '}
						<div
							className="dropdown-menu-header-link red"
							onClick={() => this.setAllCheckboxes(false) && false}
						>
							Clear All
						</div>
					</header>
					<div className={filterArray.length > 8 ? 'DropdownFilter__columns' : ''}>
						{filterArray.map((filter, index) => (
							<DropdownCheckbox
								className="DropdownFilter__checkbox"
								key={filter+index}
								label={filter}
								checked={selectedArray.includes(filter)}
								handleCheckboxChange={() => {
									addFilter(this.props.filterName, filter.trim());
								}}
							/>
						))}
					</div>
				</ul>
			</li>
		);
	}
}

DropdownFilter.propTypes = {
	filterValues: PropTypes.instanceOf(Set),
	selectedValues: PropTypes.instanceOf(Set),
};

export default DropdownFilter;
