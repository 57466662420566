import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import FAIcon from '../../components/FAIcon/FAIcon';
import { permConst } from '../../constants/permConst';
import { isAuthorized } from '../../utils/userUtils';

import './ManagePackoutFacilities.css';

class TableRow extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		this.removeLocation = this.removeLocation.bind(this);
	}

	getName() {
		const { name, use_type } = this.props.row;

		return (
			<Fragment>
				<span>{name}</span>
				{use_type === 'PRIVATE' ? (
					<div className="text-success">
						<FAIcon name="star" className="mr-1" />
						Publisher Owned
					</div>
				) : null}
			</Fragment>
		);
	}

	getAddress() {
		const {
			address_1,
			address_2,
			city,
			state,
			postal_code,
			country_name
		} = this.props.row;
		return (
			<span className="address-info">
				<span>{address_1}</span>
				<span>{address_2 !== '' && address_2}</span>
				<span>{`${city}, ${state} ${postal_code}`}</span>
				<span>{country_name}</span>
			</span>
		);
	}

	removeLocation(id) {
		this.props.onInactivate(id, 'dissociate');
	}

	render() {
		const { row } = this.props;

		return (
			<tr>
				<td>{this.getName()}</td>
				<td>{this.getAddress()}</td>
				<td>{row.phone}</td>
				<td>
					{isAuthorized(this.props.userProfile.permissions, [
						permConst.COMPANY.PACKOUT_FACILITY.EDIT.COMPANY
					]) && (
						<span
							className="text-danger packout-removal"
							onClick={(e) =>
								this.removeLocation(row.packout_facility_id)
							}
						>
							Remove Facility
						</span>
					)}
				</td>
			</tr>
		);
	}
}

TableRow.propTypes = {
	oninactive: PropTypes.func,
	editFacility: PropTypes.func,
	admin: PropTypes.bool,
	row: PropTypes.object
};

export default TableRow;
