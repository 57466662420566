import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import * as yup from 'yup';

import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import { isEmptyObject } from '../../../utils/dataUtils';
import { validateToSchema } from '../../../utils/validationUtils';
import { toasterNotify } from '../../../utils/toaster';
import { getFreightForwarder, createFreightForwarder, updateFreightForwarder } from '../../../services/ordersService';


interface FormValues {
	code?: string;
	name?: string;
	active?: boolean;
	has_additional_fields?: boolean;
}

export const baseSchema = yup.object({
	code: yup.string().trim().default('').required('Code is required'),
	name: yup.string().trim().default('').required('Name is required'),
	active: yup.boolean().default(false),
	has_additional_fields: yup.boolean().default(false),
});

const AddEditFreightForwardModal = ({
	show,
	onCompletion,
	onClose,
	editCode,
}: {
	show: boolean;
	onCompletion: () => void;
	onClose: () => void;
	editCode?: string;
}) => {
	const [formValues, setFormValues] = useState<FormValues | null>(null);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);
	const validationErrors = validateToSchema(baseSchema, formValues);

	const freightForwarderQuery = useQuery(
		['getFreightForwarder', editCode],
		() => getFreightForwarder(String(editCode)),
		{
			enabled: !!(show && editCode),
			onSuccess: (response) => {
				if (formValues === null) {
					setFormValues({
						...response.data,
						active: response.data && response.data['status'] === 'ACTIVE' ? true : false
					});
				}
			},
		},
	);
	const fault = (freightForwarderQuery.isError && freightForwarderQuery.error);

	useEffect(() => {
		if (show) {
			setFormValues(editCode ? null : {});
			setIsSubmitting(false);
			setShowAllErrors(false);
		}
	}, [show]);

	const submitForm = async () => {
		if (isSubmitting) {
			return;
		}
		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}

		const castedFormValues = {...baseSchema.cast(formValues,
				{ stripUnknown: true }), status: formValues && formValues['active'] ? 'ACTIVE': 'INACTIVE'};

		setIsSubmitting(true);

		try {
			if (editCode) {
				await updateFreightForwarder(editCode, castedFormValues);
			} else {
				await createFreightForwarder(castedFormValues);
			}
			onCompletion();
			onClose();
			toasterNotify(
				`Freight Forwarder "${castedFormValues.name}" has been successfully saved.`,
				'success',
			);
		} catch (error: any) {
			toasterNotify(
				'There was an unexpected error while saving the freight forwarder.',
				'error',
				error,
			);
		} finally {
			setIsSubmitting(false);
		}
		setShowAllErrors(false);
	};

	const changedFormValue = (values: FormValues, formId: string) => {
		if (formId === 'name') {
			values['name'] = values['name']?.toUpperCase();
		}
		if (formId === 'code') {
			values['code'] = values['code']?.toUpperCase();
		}
		setFormValues(values);
	};

	return (
		<BaseModal show={show} onCancel={onClose} isLoading={formValues == null} isSubmitting={isSubmitting} fault={fault}>
			<BaseModal.Title>
				{editCode ? 'Edit Freight Forwarder' : 'Add Freight Forwarder'}
			</BaseModal.Title>
			<Forms
				onChange={(id, v) => changedFormValue(id, v)}
				values={formValues ? formValues : {}}
				validationErrors={validationErrors}
				showAllErrors={showAllErrors}
			>
				<Forms.Text id="code" disabled={!!editCode} maxLength={10}>
					<Forms.Heading>Code</Forms.Heading>
				</Forms.Text>
				<Forms.Text id="name" maxLength={75}>
					<Forms.Heading>Name</Forms.Heading>
				</Forms.Text>
				<Forms.SingleCheckbox id="active">
					<Forms.Heading>Active</Forms.Heading>
				</Forms.SingleCheckbox>
				<Forms.SingleCheckbox id="has_additional_fields">
					<Forms.Heading>Has Additional Fields</Forms.Heading>
				</Forms.SingleCheckbox>
			</Forms>

			<BaseModal.Submit
				onClick={() => {
					submitForm();
				}}
			>
				{editCode ? 'Update' : 'Add'}
			</BaseModal.Submit>
		</BaseModal>
	);
};

export default AddEditFreightForwardModal;
