import React, { VFC } from 'react';

import PropertyDisplay from '../PropertyDisplay/PropertyDisplay';


const CustomFieldEntry: VFC<Omit<CustomFieldEntryData, 'field_definition_id' | 'read_only'>> = ({
	name,
	value,
	type,
}) => {

	let resultingValue = value;
	if (type?.id === 'checkbox') {
		resultingValue = value ? 'Yes' : 'No';
	} else if (type?.id === 'textbox') {
		resultingValue = value && value !== '' ? value : null;
	} else if (value != null) {
		if (type?.id === 'multi_select_dropdown' || type?.id === 'single_select_dropdown') {
			if (!Array.isArray(value) || !value.length) {
				resultingValue = null;
			} else if (type?.id === 'single_select_dropdown') {
				resultingValue = value.length === 1 ? value[0].name : null;
			} else {
				const companyNames = value.map((item: Record<string, any>) => item.name);
				resultingValue = companyNames.join(', ');
			}
		}
	}

	return (
		<PropertyDisplay
			label={name}
			key={name + (resultingValue ? resultingValue : '')}
		>
			{resultingValue}
		</PropertyDisplay>
	);
};

export default CustomFieldEntry;
