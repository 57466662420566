import React, { ReactNode, VFC } from 'react';

export const findChild = (children: ReactNode, component?: VFC<any>): ReactNode | undefined => {
	return React.Children.toArray(children).find(
        (child: any) => {
        	return typeof child ==='object' && 'type' in child && child.type === component;}
	);
};

export const filterChildren = (children: ReactNode, component: VFC<any>): ReactNode[] =>
	React.Children.toArray(children).filter(
        (child) => 
        	(typeof child !== 'object') || 
            !('type' in child) || 
            (child.type === component)
	);
