import api from './baseService';
import { fileTransferConst } from '../constants/fileTransferConstants';

export const getFileUploadSpec = (
	fileUploadPayload: { entity_id: string; entity_type: string; files: AsperaFile[] },
	token?: string,
) => {
	return api().post<{ transfer_specs: AsperaUploadSpec }[]>(
		'/pdb/file_transfer',
		fileUploadPayload,
		(token && { headers: { 'X-PDB-Authorization': token } }) || undefined,
	);
};

// TODO: this seems to belong in a utils or helper class or module
export const isFailedFileTransfer = (transferState: string) => {
	return transferState === fileTransferConst.TRANSFER_STATES.FAILED ||
		transferState === fileTransferConst.TRANSFER_STATES.INFECTED;
};

export const putCancelDigitalCodesFileTransfer = (orderId: string | number, token: string) =>
	api().put(
		'/pdb/file_transfer',
		{
			action: 'cancel_upload',
			entity_type: 'digital_codes',
			entity_id: typeof orderId === 'string' ? parseInt(orderId) : orderId,
		},
		{ headers: {'X-PDB-Authorization': token} },
	);

export const getUploadedFileData = (entityIds: string | string[], eventTypes: string | string[], token?: string) => {
	entityIds = Array.isArray(entityIds) ? entityIds : [entityIds];
	eventTypes = Array.isArray(eventTypes) ? eventTypes : [eventTypes];

	const params = new URLSearchParams();
	entityIds.forEach((v) => params.append('entity_id', v));
	eventTypes.forEach((v) => params.append('entity_type', v));
	return api().get('/pdb/files', {
		params,
		...(token && { headers: { 'X-PDB-Authorization': token } }),
	});
};

export const deleteFiles = (s3_content_metadata_id: string, token?: string) =>
	api().delete(`/pdb/files/${s3_content_metadata_id}`, {
		...(token && { headers: { 'X-PDB-Authorization': token } }),
	});

export const getROMUploadRecords = (limit: number = 10) =>
	api().get('/pdb/rom_version_files', { params: new URLSearchParams({ limit: String(limit) }) });

export const postROMVersionFileUpload = (selectedFile: string, comment: string) =>
	api().post('/pdb/rom_version_files', {
		'file': selectedFile,
		'comment': comment
	  });

