export function getAllUserAndCompanyIDs(
	componentDetails: {
		wholesale_price_requests?: WholesalePriceRequestData[],
		declared_price_requests?: DeclaredPriceRequestData[]
	}
): [string[], string[]] {
	const users = [];
	const companies = [];
	const allRequests: (WholesalePriceRequestData | DeclaredPriceRequestData)[] = [
		...(componentDetails?.wholesale_price_requests || []), 
		...(componentDetails?.declared_price_requests || []),
	];
	for (let request of allRequests) {
		companies.push(request.ndid_company_id);
		if (request.actions) {
			for (let action of request.actions) {
				companies.push(action.actor_ndid_company_id);
				users.push(action.actor_ndid_user_id);
			}
		}
	}

	const dupeIdFilter = (value: any, index: number, array: any[]) => !!value && array.indexOf(value) === index;
	return [users.filter(dupeIdFilter), companies.filter(dupeIdFilter)];
}
