import React, { Component } from 'react';
import { Col, Form, FormCheck, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import BaseModal from '../../../components/BaseModal/BaseModal';
import DatePicker from '../../../components/DatePicker/DatePicker';
import HelpBlock from '../../../components/HelpBlock/HelpBlock';
import { permConst } from '../../../constants/permConst';
import {
	gameRatingESRB,
	gameRatingIARC,
	gameRatings,
	ratingsDescriptorsESRB,
	ratingsDescriptorsIARC,
} from '../../../constants/submissionsConstants';
import { timeZoneConstants } from '../../../constants/timeZoneConstants';
import { updateProduct } from '../../../services/productsService';
import * as componentUtils from '../../../utils/componentUtils';
import { dateFormat, ensureDate, formatDate, parseDateString } from '../../../utils/dateUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { isAuthorized } from '../../../utils/userUtils';
import { validateProductCode } from '../../../utils/validationUtils';

import DropdownSelect from '../../../components/DropdownSelect/DropdownSelect';
import FAIcon from '../../../components/FAIcon/FAIcon';
import '../ProductProfile.css';


function mapStateToProps(state) {
	return {
		userProfile: state.authReducer.userProfile
	};
}

export class EditProductModal extends Component {
	constructor(props) {
		super(props);
		let product;

		if (this.props.productSummary) {
			let summary = this.props.productSummary;

			product = {
				id: summary.product_id,
				platform_name: summary.platform_name,
				name: summary.game_name,
				languages: summary.languages.slice(0),
				other_product_codes: summary.region_game_code.slice(0),
				delivery_format: [summary.product_distribution_type],
				card_type: summary.card_type,
				demo_only: summary.demo_only,
				release_date: ensureDate(
					formatDate(parseDateString(summary.product_release_date, timeZoneConstants.UTC, null)),
					dateFormat.ISO8601
				),
				comments: summary.comment,
				age_ratings: summary.age_ratings
			};
		}

		this.state = {
			product: product,
			isLoading: false,
			untouchedFields: new Set(['name', 'languages', 'release_date', 'card_type'])
		};

		this.schema = Yup.object().shape({
			name: Yup.string().required('Product Name cannot be blank'),
			age_ratings: Yup.array().test(
				'age_ratings',
				'Age ratings are missing descriptors',
				(value) => !value.find(ar => ar.descriptors_json?.length === 0)
			),
			languages: Yup.array().test(
				'language_test',
				'Languages must have a selected value',
				(value) => this.isOptionSelected(value)
			),
			release_date: Yup.mixed().test(
				'release_date_test',
				'Release date must be entered',
				(value) => value instanceof Date && !isNaN(value.valueOf())
			),
			card_type: Yup.mixed().test(
				'card_type_test',
				'Card type needs to have a value',
				value => (!this.showCardType() || (this.showCardType() && value))
			)
		});
	}

	updateProduct() {
		this.setState({
			isLoading: true
		});

		let product = this.getProduct();

		let dualDistributon =
			product.delivery_format.includes('DIGITAL') &&
			product.delivery_format.includes('PHYSICAL');

		const other_product_codes = Array.isArray(
			product.other_product_codes
		)
			? product.other_product_codes.filter(function(el) {
				return el !== '';
			})
			: [];

		let payload = {
			platform_code: product.platform_code,
			name: product.name,
			languages: product.languages,
			other_product_codes: other_product_codes,
			delivery_format: dualDistributon
				? 'BOTH'
				: product.delivery_format[0],
			card_type: dualDistributon ? product.card_type : '',
			demo_only: product.demo_only,
			release_date: new Date(product.release_date),
			comments: product.comments,
			age_ratings: product.age_ratings
		};

		updateProduct(product.id, payload)
			.then((response) => {
				toasterNotify('Updated product', 'success');
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'updating product'),
					'error',
					error
				);
			})
			.finally(() => {
				this.props.closeModal();
				this.props.updateState(product.id);
			});
	}

	hasAgreementCode(code) {
		const { companyAgreements } = this.state.product;

		if (
			isAuthorized(this.props.userProfile.permissions, [
				permConst.PRODUCT.ADD.FIRST
			])
		) {
			return true;
		}
		for (let i of companyAgreements) {
			if (i.platformDevelopmentCode === code) {
				return true;
			}
		}
		return false;
	}

	hasAgreementType(type) {
		const { companyAgreements } = this.state.product;

		if (
			isAuthorized(this.props.userProfile.permissions, [
				permConst.PRODUCT.ADD.FIRST
			])
		) {
			return true;
		}
		for (let i of companyAgreements) {
			if (i.agreementType === type) {
				return true;
			}
		}
		return false;
	}

	updateState(product) {
		this.setState({
			product: product
		});
	}

	addOtherProductCode(e) {
		if (e.detail === 0) {
			return;
		}
		e.preventDefault();
		let product = this.getProduct();
		product.other_product_codes.push('');
		this.updateState(product);
	}

	onListChange(name, event) {
		let product = this.getProduct();
		let toRemove =
			event.target.type === 'checkbox' && event.target.checked === false;
		let value = event.currentTarget.value;
		if (toRemove) {
			let index = product[name].indexOf(value);
			product[name].splice(index, 1);
		} else {
			product[name].push(value);
		}
		this.updateState(product);
	}

	onArrayItemChange(name, index, event) {
		const product = this.getProduct();
		const value = validateProductCode(this.getValue(event));
		if (value !== false) {
			product[name][index] = value;
			this.updateState(product);
			return;
		}
		event.preventDefault();
		return false;
	}

	onInputChange(name, event) {
		let product = this.getProduct();
		product[name] = this.getValue(event);
		this.updateState(product);
	}

	onUpdateAgeRatings(event) {
		const product = this.getProduct();

		const ageRating = product.age_ratings && product.age_ratings[0]
			? {...product.age_ratings[0]}
			: {'descriptors_json': ''};

		const newAgeRating = this.getValue(event);
		const ageRatingAgency = newAgeRating.includes(gameRatingESRB)
			? gameRatingESRB
			: gameRatingIARC;

		ageRating['rating'] = newAgeRating;
		ageRating['age_rating_agency'] = ageRatingAgency;

		product.age_ratings = [ageRating];

		this.setState({
			product: product
		});
	}

	onUpdateAgeRatingDescriptors(name, agency, event, { value }) {
		let product = this.getProduct();
		let ageRatings = product.age_ratings;

		const ageRating = ageRatings.find((r) => {
			return r.age_rating_agency === agency;
		});

		ageRating[name] = value;

		this.setState({
			product: product
		});
	}

	dateChange(date) {
		let product = this.getProduct();
		product.release_date = new Date(date+'T00:00:00');
		this.updateState(product);
	}

	getProduct() {
		return this.state.product;
	}

	getValue(event) {
		let value =
			event.target.type === 'checkbox' && event.target.checked === false
				? ''
				: event.currentTarget.value;
		if (value === 'true') {
			value = true;
		}
		return value;
	}

	showCardType() {
		let product = this.getProduct();
		return (
			(product.platform_code === 'CTR' ||
				product.platform_code === 'KTR') &&
			product.delivery_format.includes('DIGITAL') &&
			product.delivery_format.includes('PHYSICAL')
		);
	}

	isOptionSelected(arr) {
		return arr.some(function(el) {
			return el !== '';
		});
	}

	getModalBody({flagsForField}) {
		const { untouchedFields, product, isLoading } = this.state;
		const other_product_codes = product.other_product_codes;
		const displaySelectedProductCodes = Array.isArray(other_product_codes);

		const touchField = (field) => {
			untouchedFields.delete(field);
			this.setState({untouchedFields});
		};

		const validationFlag = (field, defaultMessage=null) => {
			const flag = flagsForField(field);
			if (flag && !untouchedFields.has(field)) {
				return (<HelpBlock><span className='text-danger'>{flag.reason}</span></HelpBlock>);
			}  else {
				if (!defaultMessage) return (<HelpBlock>{defaultMessage}</HelpBlock>);
			}
			return null;
		};

		return (
			<div>
				{(product.platform_code === 'CTR' ||
					product.platform_code === 'KTR') && (
					<div className="alert alert-warning">
						Only titles targeted to an audience demographic that is
						at least 70% seven years of age or older can use
						stereoscopic 3D.
					</div>
				)}
				{product.platform_code === 'WUP' && (
					<div className="alert alert-warning">
						Game titles with the name format 'GAME NAME U' are
						prohibited.
					</div>
				)}
				<Form onSubmit={(e) => {
					const [, formComplete] = this.validation();
					if (formComplete && !this.loading) {
						this.updateProduct();
					}
					e.preventDefault();
					return false;
				}}>
					<FormGroup as={Row}>
						<FormLabel className="col-sm-4 text-sm-right" column>
							Platform
						</FormLabel>
						<Col sm={8}><FormControl as='div' readOnly plaintext>{product.platform_name}</FormControl></Col>
					</FormGroup>

					<FormGroup as={Row}>
						<FormLabel className="col-sm-4 text-sm-right" column>
							Product Name
						</FormLabel>
						<Col sm={8}>
							<input
								onChange={this.onInputChange.bind(this, 'name')}
								onBlur={() => touchField('name')}
								className="form-control"
								value={product.name}
								disabled={isLoading}
							/>
							{validationFlag('name')}
						</Col>
					</FormGroup>

					<FormGroup as={Row}>
						<FormLabel className="col-sm-4 text-sm-right">
							Game Language(s)
						</FormLabel>
						<Col sm={8}>
							<div className="checkbox">
								<FormCheck
									id="en-language"
									type="checkbox"
									onChange={this.onListChange.bind(
										this,
										'languages'
									)}
									onBlur={() => touchField('languages')}
									value="en"
									defaultChecked={product.languages.includes(
										'en'
									)}
									disabled={isLoading}
									label="English"
								/>
							</div>
							<div className="checkbox">
								<FormCheck
									id="fr-language"
									type="checkbox"
									onChange={this.onListChange.bind(
										this,
										'languages'
									)}
									onBlur={() => touchField('languages')}
									value="fr"
									defaultChecked={product.languages.includes(
										'fr'
									)}
									disabled={isLoading}
									label="French"
								/>
							</div>
							<div className="checkbox">
								<FormCheck
									id="es-language"
									type="checkbox"
									onChange={this.onListChange.bind(
										this,
										'languages'
									)}
									onBlur={() => touchField('languages')}
									value="es"
									defaultChecked={product.languages.includes(
										'es'
									)}
									disabled={isLoading}
									label="Spanish"
								/>
							</div>
							{validationFlag('languages')}
						</Col>
					</FormGroup>

					<FormGroup as={Row}>
						<FormLabel className="col-sm-4 text-sm-right" column>
							Other Region Product Codes
						</FormLabel>
						<Col sm={6}>
							{displaySelectedProductCodes &&
								other_product_codes.map((item, index) => (
									<input
										value={item}
										key={`${index}-${'item'}`}
										className="form-control"
										placeholder="e.g. CTRPAMEC"
										onChange={this.onArrayItemChange.bind(
											this,
											'other_product_codes',
											index
										)}
										disabled={isLoading}
									/>
								))}
							<button
								onClick={this.addOtherProductCode.bind(this)}
								className="addProductCode"
								disabled={isLoading}
							>
								<FAIcon name="plus" className="mr-1"/>
								Add More Product Code(s)
							</button>
						</Col>
					</FormGroup>

					<FormGroup as={Row}>
						<FormLabel className="col-sm-4 text-sm-right" column>
							Delivery Format
						</FormLabel>
						<Col sm={7}><FormControl as='div' readOnly plaintext>{product.delivery_format}</FormControl></Col>
					</FormGroup>

					{this.showCardType() && (
						<FormGroup as={Row}>
							<FormLabel className="col-sm-4 text-sm-right" column>
								Card Type
							</FormLabel>
							<Col sm={7}>{product.card_type}</Col>
						</FormGroup>
					)}

					<FormGroup as={Row}>
						<FormLabel className="col-sm-4 text-sm-right">
							Demo Only
						</FormLabel>
						<Col sm={8}>
							<div className="checkbox">
								<FormCheck
									type="checkbox"
									id="checkbox-yes"
									disabled={true}
									onChange={this.onInputChange.bind(
										this,
										'demo_only'
									)}
									value="true"
									defaultChecked={product.demo_only}
									label="YES"
								/>
							</div>
						</Col>
					</FormGroup>

					<FormGroup as={Row} className="has-feedback">
						<FormLabel className="col-sm-4 text-sm-right" column>
							Estimated Release Date
						</FormLabel>
						<Col sm={6}>
							<DatePicker
								dropdownMode="select"
								value={formatDate(product.release_date, dateFormat.DATE_YMD)}
								onChange={this.dateChange.bind(this)}
								onBlur={() => touchField('release_date')}
								disabled={isLoading}
							/>
							{validationFlag('release_date')}
						</Col>
					</FormGroup>

					<FormGroup as={Row}>
						<FormLabel className="col-sm-4 text-sm-right" column>
							Additional Comments
						</FormLabel>
						<Col sm={8}>
							<FormControl
								as='textarea'
								className="form-control"
								rows="5"
								onChange={this.onInputChange.bind(
									this,
									'comments'
								)}
								value={product.comments}
								disabled={isLoading}
							/>
							<HelpBlock>Optional</HelpBlock>
						</Col>
					</FormGroup>
					{this.getAgeRatingDescriptors({isLoading, validationFlag})}
				</Form>
			</div>
		);
	}

	getAgeRatingDescriptors({isLoading, validationFlag}) {
		const { product } = this.state;

		const htmlAgeRatings = [];

		const ageRatings = product.age_ratings;

		ageRatings.forEach((ageRating, index) => {
			const ageRatingAgency = ageRating.age_rating_agency;
			const rating = ageRating.rating;
			const descriptors = ageRating.descriptors_json;

			const ratingsDescriptorsChoices =
				ageRatingAgency === gameRatingESRB
					? ratingsDescriptorsESRB
					: ratingsDescriptorsIARC;

			const ratings = componentUtils.generateOptions(gameRatings);
			const validCurrentGameRating = ratings.includes(rating);

			const htmlRating = (
				<FormGroup as={Row} key={'rating' + index}>
					<FormLabel id="gameRating" column className="col-sm-4 text-sm-right">
						Game Rating
					</FormLabel>
					<Col sm={8}>
						<FormControl
							as="select"
							placeholder=""
							defaultValue={validCurrentGameRating ? rating : ''}
							onChange={this.onUpdateAgeRatings.bind(this)}
							disabled={isLoading}
						>
							{ratings}
						</FormControl>
					</Col>
				</FormGroup>
			);

			const options = ratingsDescriptorsChoices.map(o => ({value: o.value, label: o.text}));
			const value = options.filter(o => Array.isArray(descriptors) && descriptors?.includes(o.value));
			const htmlDescriptors = (
				<FormGroup as={Row}  key={'descriptors' + index}>
					<FormLabel id="ratingDescriptors" column className="col-sm-4 text-sm-right">
						{ageRatingAgency} Rating Descriptors
					</FormLabel>
					<Col sm={8}>
						<DropdownSelect
							isSearchable
							options={options}
							isMulti
							value={value}
							disabled={isLoading}
							onChange={(newValue) => 
								this.onUpdateAgeRatingDescriptors(
									'descriptors_json', 
									ageRatingAgency, 
									null, 
									{ value: newValue.map(v => v.value)}
								)
							}
						/>
						{index === ageRatings.length - 1 && validationFlag('age_ratings')}
					</Col>
				</FormGroup>
			);

			htmlAgeRatings.push(htmlRating);
			htmlAgeRatings.push(htmlDescriptors);
		});

		return htmlAgeRatings;
	}

	validation() {
		const { product } = this.state;
		let flagged = [], isValid;
		try {
			this.schema.validateSync(product, {abortEarly: false});
			isValid = true;
		} catch (err) {
			flagged = err.inner.map(e => ({name: e.path, reason: e.message}));
			isValid = false;
		}
		return [
			(field) => flagged.find(f => f.name === field),
			isValid
		];
	}

	render() {
		const { isLoading } = this.state;
		const [flagsForField, formComplete] = this.validation();

		return (
			<BaseModal show={true} size="lg" onCancel={this.props.closeModal} isSubmitting={isLoading}>
				<BaseModal.Title>Update Product</BaseModal.Title>
				{this.getModalBody({flagsForField})}
				<BaseModal.Submit onClick={() => this.updateProduct()} disabled={!formComplete || isLoading}>
					Update
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}

export default connect(mapStateToProps)(EditProductModal);
