import React, { VFC } from 'react';
import { useQuery } from 'react-query';

import Loading from '../../../components/Loading/Loading';
import PropertyDisplay from '../../../components/PropertyDisplay/PropertyDisplay';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { platformCodes, platformConstants } from '../../../constants/platformConstants';
import { gameConfigFields } from '../../../constants/gameConfigFields';
import { getProductGameConfiguration } from '../../../services/productsService';
import { Alert } from 'react-bootstrap';
import FAIcon from '../../../components/FAIcon/FAIcon';


function cleanGameConfigurationValue(value: unknown) {
	return (value === 'true' || value === true) ? 'Yes' :
		(value === 'false' || value === false || value === 'no') ? 'No' :
			(value === null) ? '' : 
				value;
}

interface GameConfigTabProps {
	isSelected?: boolean;
	product?: ProductData;
}
const GameConfigTab: VFC<GameConfigTabProps> = ({ isSelected, product }) => {
	const platformName = product?.platform_name;
	const configQuery = useQuery(
		['getProductGameConfiguration', product?.product_id],
		() => getProductGameConfiguration(String(product?.product_id)),
		{ enabled: !!isSelected && !!product?.product_id },
	);
	const gameConfiguration = configQuery.isSuccess && configQuery.data.data;

	return !isSelected || !product || !platformName ? (
		null
	) : configQuery.isLoading  ? (
		<Loading />
	) : configQuery.isError ? (
		<Alert variant="danger">
			There was an issue loading game configuration data.{' '}
			<a onClick={(e) => configQuery.refetch()}>Retry load</a>
			<FAIcon size="xs" name="chevron-right" />
		</Alert>
	) : product.platform_code === platformCodes['Nintendo Switch'] ? (
		<SwitchGameConfiguration gameConfiguration={gameConfiguration} />
	) : platformName in gameConfigFields || platformName === platformConstants.NEW_3DS_EXCLUSIVE ? (
		<GameConfiguration
			gameConfiguration={gameConfiguration}
			platformName={
				platformName === platformConstants.NEW_3DS_EXCLUSIVE
					? platformConstants.NINTENDO_3DS
					: platformName
			}
		/>
	) : null;
};
interface SwitchGameConfigurationProps {
	gameConfiguration: any; 
}
const SwitchGameConfiguration: VFC<SwitchGameConfigurationProps> = ({ gameConfiguration }) => {
	if (gameConfiguration) {
		const containsFrench = cleanGameConfigurationValue(
			(gameConfiguration[gameConfigFields.SWITCH.CONTAINS_FRENCH] !== undefined &&
				cleanGameConfigurationValue(
					gameConfiguration[gameConfigFields.SWITCH.CONTAINS_FRENCH].FieldValue,
				) === 'Yes') ||
				(gameConfiguration[gameConfigFields.SWITCH.CONTAINS_NORTH_AMERICAN_FRENCH] !==
					undefined &&
					cleanGameConfigurationValue(
						gameConfiguration[gameConfigFields.SWITCH.CONTAINS_NORTH_AMERICAN_FRENCH]
							.FieldValue,
					) === 'Yes'),
		);

		const containsSpanish = cleanGameConfigurationValue(
			(gameConfiguration[gameConfigFields.SWITCH.CONTAINS_SPANISH] !== undefined &&
				cleanGameConfigurationValue(
					gameConfiguration[gameConfigFields.SWITCH.CONTAINS_SPANISH].FieldValue,
				) === 'Yes') ||
				(gameConfiguration[gameConfigFields.SWITCH.CONTAINS_NORTH_AMIERICA_SPANISH] !==
					undefined &&
					cleanGameConfigurationValue(
						gameConfiguration[gameConfigFields.SWITCH.CONTAINS_NORTH_AMIERICA_SPANISH]
							.FieldValue,
					) === 'Yes'),
		);

		const containsEnglish = cleanGameConfigurationValue(
			(gameConfiguration[gameConfigFields.SWITCH.CONTAINS_UK_ENGLISH] !== undefined &&
				cleanGameConfigurationValue(
					gameConfiguration[gameConfigFields.SWITCH.CONTAINS_UK_ENGLISH].FieldValue,
				) === 'Yes') ||
				(gameConfiguration[gameConfigFields.SWITCH.CONTAINS_NORTH_AMERICA_ENGLISH] !==
					undefined &&
					cleanGameConfigurationValue(
						gameConfiguration[gameConfigFields.SWITCH.CONTAINS_NORTH_AMERICA_ENGLISH]
							.FieldValue,
					) === 'Yes'),
		);
		return (
			<>
				<SectionTitle>Program Specifications</SectionTitle>
				<PropertyDisplay label="Contains French">{containsFrench}</PropertyDisplay>
				<PropertyDisplay label="Contains Spanish">{containsSpanish}</PropertyDisplay>
				<PropertyDisplay label="Contains English">{containsEnglish}</PropertyDisplay>
				<PropertyDisplay label="Amiibo Compatible">
					{cleanGameConfigurationValue(
						(gameConfiguration[gameConfigFields.SWITCH.AMIIBO_COMPATIBLE] || {})
							.FieldValue,
					)}
				</PropertyDisplay>
				<PropertyDisplay label="Save data Cloud back up settings">
					{cleanGameConfigurationValue(
						(
							gameConfiguration[
								gameConfigFields.SWITCH.SAVE_DATA_CLOUD_BACK_UP_SETTINGS
							] || {}
						).FieldValue,
					)}
				</PropertyDisplay>
				<SectionTitle>Input Devices</SectionTitle>
				<PropertyDisplay label="Nintendo Switch Pro Controller">
					{cleanGameConfigurationValue(
						(
							gameConfiguration[
								gameConfigFields.SWITCH.NINTENDO_SWITCH_PRO_CONTROLLER
							] || {}
						).FieldValue,
					)}
				</PropertyDisplay>
				<SectionTitle>Play Modes</SectionTitle>
				<PropertyDisplay label="TV Mode">
					{cleanGameConfigurationValue(
						(gameConfiguration[gameConfigFields.SWITCH.TV_MODE] || {}).FieldValue,
					)}
				</PropertyDisplay>

				<PropertyDisplay label="Tabletop Mode">
					{cleanGameConfigurationValue(
						(gameConfiguration[gameConfigFields.SWITCH.TABLETOP_MODE] || {}).FieldValue,
					)}
				</PropertyDisplay>

				<PropertyDisplay label="Handlheld Mode">
					{cleanGameConfigurationValue(
						(gameConfiguration[gameConfigFields.SWITCH.HANDHELD_MODE] || {}).FieldValue,
					)}
				</PropertyDisplay>
				<SectionTitle>NEX</SectionTitle>
				<PropertyDisplay label="Matchmaking">
					{cleanGameConfigurationValue(
						(gameConfiguration[gameConfigFields.SWITCH.NEX_MATCHMAKING] || {})
							.FieldValue,
					)}
				</PropertyDisplay>
				<SectionTitle>E-commerce</SectionTitle>
				<PropertyDisplay label="In-game purchases">
					{cleanGameConfigurationValue(
						(gameConfiguration[gameConfigFields.SWITCH.IN_GAME_PURCHASES] || {})
							.FieldValue,
					)}
				</PropertyDisplay>
				<SectionTitle>Independent Server</SectionTitle>
				<PropertyDisplay label="Matchmaking">
					{cleanGameConfigurationValue(
						(
							gameConfiguration[
								gameConfigFields.SWITCH.INDEPENDENT_SERVER_MATCHMAKING
							] || {}
						).FieldValue,
					)}
				</PropertyDisplay>
			</>
		);
	}
	return null;
};

interface GameConfigurationProps {
	gameConfiguration: any;
	platformName?: string;
}
const GameConfiguration: VFC<GameConfigurationProps> = ({
	gameConfiguration,
	platformName,
}) => {
	let panelBody: any[] = [];
	if (gameConfiguration && platformName && platformName in gameConfigFields) {
		const gameConfigFieldsForPlatform = (gameConfigFields as Record<string, any>)[platformName];
		const headerNames = Object.keys(gameConfigFieldsForPlatform);
		headerNames.forEach((headerName) => {
			panelBody.push(
					<SectionTitle key={'game-config-' + headerName}>
						{gameConfigFieldsForPlatform[headerName]['title']}
					</SectionTitle>,
			);
			Object.keys(gameConfigFieldsForPlatform[headerName]['fields']).forEach(
				(gameConfigurationFeature, index) => {
					const apiReturnedFeatureName =
						gameConfigFieldsForPlatform[headerName]['fields'][
							gameConfigurationFeature
						];
					const value = 
						cleanGameConfigurationValue(
							gameConfiguration[headerName]
								? gameConfiguration[headerName][apiReturnedFeatureName]
								: '',
						);
					panelBody.push(
						<PropertyDisplay label={gameConfigurationFeature} key={gameConfigurationFeature + index}>
							{value != null ? value.toString() : ''}
						</PropertyDisplay>
					);
				},
			);
		});
		return <>{panelBody}</>;
	}
	return null;
};


export default GameConfigTab;
