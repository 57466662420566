import React, { PropsWithChildren, ReactElement, VFC } from 'react';

import MiniBadge from '../MiniBadge/MiniBadge';

interface StatusTextProps extends PropsWithChildren<unknown> {
	variant?: TextVariant;
	badge?: boolean;
}
const StatusText: VFC<StatusTextProps> = ({
	variant,
	children,
	badge,
}: StatusTextProps): ReactElement | null => {
	if (children == null) {
		return null;
	}
	if (!variant) {
		switch (String(children).toUpperCase().replace(/\s/g, '_')) {
			case 'CANCELED': 
			case 'CANCELLED':
			case 'DECLINED':
			case 'ERROR':
			case 'FILE_UPLOAD_FAILED':
			case 'FINAL_LABEL_REJECTED':
			case 'FINAL_LABEL_UPLOAD_FAILED':
			case 'NCL_ACCEPTED_UPLOAD_FAILED':
			case 'NCL_FINAL_LABEL_REJECTED':
			case 'NCL_PROOF_UPLOAD_FAILED':
			case 'PRINT_PROOF_REJECTED':
			case 'PRINT_PROOF_UPLOAD_FAILED':
			case 'REJECTED':
			case 'REJECT_FORGO':
			case 'REQUIRED':
			case 'RESCINDED':
			case 'RETRACTED':
			case 'UPLOAD_FAILED':
				variant = 'danger'; // red
				break;
			case 'ACCEPT_FORGO':
			case 'ACTIVE':
			case 'APPROVED_AND_ACTIVE':
			case 'APPROVED_WITH_CHANGES':
			case 'APPROVED':
			case 'CODES_AVAILABLE':
			case 'COMPLETE':
			case 'NCL_ACCEPTED_WITH_CHANGES':
			case 'NCL_ACCEPTED':
			case 'ORDER_APPROVED':
			case 'ORDER_PENDING':
			case 'PAYMENT_PENDING':
			case 'PAYMENT_RECEIVED':
			case 'READY_FOR_APPROVAL':
			case 'UPLOAD_IN_PROGRESS':
				variant = 'success'; // green
				break;
			case 'AWAITING_FINAL_LABEL':
			case 'DECLARED':
			case 'FINAL_LABEL_UPLOADED':
			case 'IN_MANUFACTURING':
			case 'IN_PROGRESS':
			case 'PENDING_PROOF_ACCEPT':
			case 'PENDING_REVIEW':
			case 'PRINT_PROOF_PENDING':
			case 'PRODUCTION_PLANNING':
			case 'READY_FOR_REVIEW':
			case 'REQUEST_FORGO':
			case 'REVIEWED_WITH_CHANGES':
			case 'REVIEWED':
			case 'SUBMITTED_FOR_APPROVAL':
			case 'SUBMITTED_FOR_REVIEW':
			case 'SUBMITTED_TO_NCL':
				variant = 'info'; // blue
				break;
			case 'APPROVED_AND_INACTIVE':
			case 'DOWNLOAD_EXPIRED':
			case 'DRAFT':
			case 'INACTIVE':
			case 'NOT_REQUESTED':
				variant = 'secondary'; // gray
				break;
			case 'APPROVED_AND_ACTIVATING':
			case 'AWAITING_PAYMENT_INFO':
			case 'AWAITING_PAYMENT':
			case 'FILES_UPLOADING':
			case 'FINAL_LABEL_UPLOADING':
			case 'IN_PROCESS':
			case 'NCL_ACCEPTED_FILE_UPLOADING':
			case 'NCL_PROOF_UPLOADING':
			case 'PRINT_PROOF_UPLOADING':
			case 'SUBMIT_TO_NCL_FILE_UPLOADING':
				variant = 'warning'; // yellow
				break;
			default:
		}
	}
	const outputString = String(children).toUpperCase().replace(/_/g, ' ');

	return badge ? (
		<MiniBadge variant={variant}>{outputString}</MiniBadge>
	) : (
		<b className={variant ? 'text-' + variant : undefined}>{outputString}</b>
	);
};
export default StatusText;
