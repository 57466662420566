import React, { VFC } from 'react';
import BaseModal from '../../../components/BaseModal/BaseModal';
import PropertyDisplay from '../../../components/PropertyDisplay/PropertyDisplay';
import { Table } from 'react-bootstrap';
import { OrderDetailData } from '../../../services/ordersService';
import { dateFormat, formatDate } from '../../../utils/dateUtils';
import { numberWithCommas } from '../../../utils/currencyUtils';

interface ShipmentsModalProps {
    show: boolean;
    onClose: () => void;
    lineItem: ArrayElement<OrderDetailData['order_details']>;
	showPartNumbers?: boolean;
}
const ShipmentsModal:VFC<ShipmentsModalProps> = ({ show, onClose, lineItem, showPartNumbers }) => {
	return (
		<BaseModal show={show} onCancel={() => onClose()}>
			<BaseModal.Title>Shipping Details</BaseModal.Title>
			<PropertyDisplay label="Item">{`[${lineItem.game_code}] ${lineItem.product_name}`}</PropertyDisplay>
			{showPartNumbers && (
				<PropertyDisplay label="Part / SKU">
					{lineItem.publisher_part_number}
				</PropertyDisplay>
			)}

			<Table>
				<thead>
					<tr>
						<th>Ship Date</th>
						<th>Quantity</th>
					</tr>
				</thead>
				<tbody>
					{lineItem.shipments?.map((detail, index) => {
						return (
							<tr key={'row' + index}>
								<td>{formatDate(detail.ship_date, dateFormat.DATE)}</td>
								<td>{numberWithCommas(detail.quantity)}</td>
							</tr>
						);
					})}
					<tr>
						<td></td>
						<td className="BaseTable__totals-cell">
							<label className="BaseTable__totals-cell-label">
								<b>Total:</b>
							</label>
							<div>
								{numberWithCommas(
									lineItem.shipments?.reduce(
										(total, detail) => total + detail.quantity,
										0,
									),
								)}
							</div>
						</td>
					</tr>
				</tbody>
			</Table>
		</BaseModal>
	);
};

export default ShipmentsModal;
