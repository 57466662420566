import React, { useMemo, useRef } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { linkColumn } from '../../../components/BaseTable/BaseTable';
import DateCell from '../../../components/cells/DateCell/DateCell';
import FAIcon from '../../../components/FAIcon/FAIcon';
import FilterableTable from '../../../components/FilterableTable/FilterableTable';
import Loading from '../../../components/Loading/Loading';
import Page from '../../../components/Page/Page';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { assetConst } from '../../../constants/assetConstants';
import { getEventAssetsApprovalList } from '../../../services/eventsService';
import { getAssetApprovalListByCategory } from '../../../services/productsService';
import { formatAssetType } from '../../../utils/assetUtils';
import { assetTypeCustomEvaluation } from '../ApprovalQueue.helpers';


const {
	BANNER_AD,
	BOX_PACK_SHOT,
	DISPLAY_SIGNAGE,
	OTHER,
	RELEASE_ALERT,
	TRAILER,
	TV_COMMERCIAL,
	WEBSITE,
} = assetConst.TYPE;

const EventAssetsTab = ({ eventKey }) => {
	const tokenHolder = useRef();
	const eventAssetsQuery = useQuery(
		'getEventAssetsApprovalList',
		() => getEventAssetsApprovalList(),
		{
			onSuccess: (response) => {
				const token = response?.headers['x-pdb-authorization'];
				if (token) {
					tokenHolder.current = token;
					approvalListQuery.refetch();
				}
			},
		},
	);

	const approvalListQuery = useQuery(
		['getAssetApprovalListByCategory', 'MARKETING_EVENT'],
		() => getAssetApprovalListByCategory('MARKETING_EVENT', tokenHolder.current),
		{ enabled: false },
	);

	const eventAssetData = useMemo(
        () => eventAssetsQuery.data?.data.events.reduce((reduction, event) => {
        	event.products.forEach((product) =>
        		product.assets.forEach((asset) => {
        			reduction[asset.product_asset_id] = {
        				eventName: event.name,
        				eventId: event.id,
        				eventProductId: product.id,
        				eventAssetId: asset.id,
        			};
        		}),
        	);
        	return reduction;
	    }, {}),
        [eventAssetsQuery.data],
	);

	const marketingEventDataFormat = useMemo(() => {
		const marketingEventChevronLink = ({ original }) => {
			return (
                <Link
                    to={`/marketing-events/products/${original.eventProductId}/assets/${original.eventAssetId}`}
                >
                    <FAIcon name="chevron-right" />
                </Link>
			);
		};
		return  [
			{
				Header: 'Asset Type',
				id: 'asset_type',
				accessor: (d) => {
					let value = formatAssetType(d.asset_type);
					if (d.asset_type_other_name) {
						value = value + ' - ' + d.asset_type_other_name;
					}
					return value;
				},
			},
			{ Header: 'Event Name', accessor: 'eventName', width: 250 },
			{ Header: 'Title', accessor: 'game_name', width: 250 },
			{
				Header: 'Publisher',
				accessor: 'company_name',
			},
			{ Header: 'Game Code', accessor: 'game_code' },
			{
				Header: 'Date Modified',
				accessor: 'modified_date',
				sortMethod: (a, b) => {
					return new Date(a) > new Date(b) ? 1 : -1;
				},
				Cell: DateCell
			},
			{
				...linkColumn,
				Cell: marketingEventChevronLink,
			},
		];
	}, []);

	const marketingEventAssets = approvalListQuery.isSuccess
		? approvalListQuery.data?.data.map((item) => ({
			...item,
			...(eventAssetData[item.asset_id]),
		  }))
		: [];

	const filterProperties = useMemo(() => new Map([
		[
			'Asset Type',
			{
				filter: 'asset_type',
				customEvaluation: assetTypeCustomEvaluation,
				selectableFilters: new Set(
					[
						BANNER_AD,
						BOX_PACK_SHOT,
						DISPLAY_SIGNAGE,
						OTHER,
						RELEASE_ALERT,
						TRAILER,
						TV_COMMERCIAL,
						WEBSITE,
					].map((value) => formatAssetType(value)),
				),
			},
		],
	]), []);

	const isLoading = eventAssetsQuery.isLoading || approvalListQuery.isLoading;

	return (
        <Page.ContentContainer>
            <SectionTitle>Marketing Event Assets</SectionTitle>
            {isLoading ? (
                <Loading />
            ) : (
                <FilterableTable
                    data={marketingEventAssets}
                    dataFormat={marketingEventDataFormat}
                    filterProperties={filterProperties}
                    searchableFields={ [
                        'game_name',
                        'game_code',
                        'company_name',
                        'eventName',
                    ]}
                    searchableFieldPlaceHolder={
                        'Search by Publisher, Title, Event Name, Game Code'
                    }
                    defaultSorted={[
                        {
                            id: 'modified_date',
                            desc: true,
                        },
                    ]}
                    noBorder
                    retainPageState
                    stateOnTab={eventKey}
                />
            )}
        </Page.ContentContainer>
	);
};
export default EventAssetsTab;
