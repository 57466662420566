export const companyAgreementConstants = {
	STATUS: {
		EXECUTED: 'EXECUTED',
		IN_NEGOTIATION: 'IN_NEGOTIATION',
		PENDING: 'PENDING',
		DECLINED: 'DECLINED'
	},
	TYPE: {
		RETAIL: 'RETAIL',
		DIGITAL_RETAIL: 'DIGITAL_AT_RETAIL',
		DIGITAL: 'DIGITAL'
	}
};
