import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createMessageForError, toasterNotify } from '../../../../utils/toaster';
import GameConfiguration3ds from './GameConfiguration3ds';
import GameConfigurationWii from './GameConfigurationWii';
import GameConfigurationWiiU from './GameConfigurationWiiU';
import BaseModal from '../../../../components/BaseModal/BaseModal';
import {
	getSubmissionGameConfiguration,
	putSubmissionGameConfiguration
} from '../../../../services/submissionsService';

function mapStateToProps(state) {
	return {
		userProfile: state.authReducer.userProfile
	};
}

export class EditGameConfigurationModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			system_blocks_used: '',
			gameConfiguration: false,
			isLoading: false
		};
		this.toggleLoading = this.toggleLoading.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.decideSystemType = this.decideSystemType.bind(this);
	}

	decideSystemType() {
		let type;
		switch (this.state.gameConfiguration.Platform) {
			case 'WUP':
				type = 'wup';
				break;
			case 'CTR':
			case 'KTR':
			case 'CTR_KTR':
				type = 'ctrktr';
				break;
			case 'RVL':
				type = 'rvl';
				break;
			default:
				break;
		}
		return type;
	}

	updateSubmissionGameConfiguration() {
		this.toggleLoading();
		const { submissionDetails } = this.props;
		const { gameConfiguration } = this.state;
		let systemType = this.decideSystemType();
		putSubmissionGameConfiguration(
			submissionDetails.submission_id,
			systemType,
			gameConfiguration
		)
			.then((response) => {
				toasterNotify('Updated game configuration', 'success');
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'updating game configuration'),
					'error',
					error
				);
			})
			.finally(() => {
				this.props.reloadActivity();
				this.props.closeModal();
			});
	}

	handleInputChange(name, value) {
		let payload = this.state.gameConfiguration;
		payload[name[0]][name[1]] = value;
		this.setState({
			gameConfiguration: payload
		});
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	getGameConfiguration() {
		this.toggleLoading();
		let gameConfiguration;
		getSubmissionGameConfiguration(
			this.props.submissionDetails.submission_id,
			this.props.productInfo.platform_name
		)
			.then((response) => {
				gameConfiguration = response.data;
				this.setState({
					gameConfiguration: gameConfiguration
				});
				this.toggleLoading();
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'loading summary'),
					'error',
					error
				);
				this.props.closeModal();
			});
	}

	componentDidMount() {
		this.getGameConfiguration();
	}

	getModalBody() {
		const { productInfo } = this.props;
		switch (productInfo.platform_name) {
			case 'New 3DS Exclusive':
			case 'Nintendo 3DS':
				return (
					<GameConfiguration3ds
						submissionDetails={this.props.submissionDetails}
						gameConfiguration={this.state.gameConfiguration}
						userProfile={this.props.userProfile}
						productInfo={productInfo}
						handleInputChange={this.handleInputChange}
					/>
				);
			case 'Wii':
				return (
					<GameConfigurationWii
						submissionDetails={this.props.submissionDetails}
						gameConfiguration={this.state.gameConfiguration}
						userProfile={this.props.userProfile}
						productInfo={productInfo}
						handleInputChange={this.handleInputChange}
					/>
				);
			case 'Wii U':
				return (
					<GameConfigurationWiiU
						submissionDetails={this.props.submissionDetails}
						gameConfiguration={this.state.gameConfiguration}
						userProfile={this.props.userProfile}
						productInfo={productInfo}
						handleInputChange={this.handleInputChange}
					/>
				);
			default:
				return null;
		}
	}

	render() {
		return (
			<BaseModal
				show={true}
				size="lg"
				onCancel={this.props.closeModal}
				isLoading={this.state.isLoading}
			>
				<BaseModal.Title>Edit Game Configuration</BaseModal.Title>
				{this.state.gameConfiguration && this.getModalBody()}
				<BaseModal.Submit
					disabled={this.state.isLoading}
					onClick={() => {
						this.updateSubmissionGameConfiguration();
					}}
				>
					Save
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}

export default connect(mapStateToProps)(EditGameConfigurationModal);
