import React from 'react';
import PropertyDisplay from '../../../components/PropertyDisplay/PropertyDisplay';
import { filterInternalOnly } from '../CompanyProfile.helpers';


const RenderAdditionalInfo = ({ companyData, userProfile }) => {
	if (companyData) {
		let skuRequired = 'Yes';
		if (companyData.company_information.sku_number_required === 0) {
			skuRequired = 'No';
		}

		let canMakeProjections = 'No';
		if (companyData.company_information.projection_eligible_flag === 1) {
			canMakeProjections = 'Yes';
		}

		return (
			<span>
				<PropertyDisplay label="NDID Company ID">
					{companyData.company_information.ndid_company_id}
				</PropertyDisplay>
				{filterInternalOnly(companyData, userProfile, 'bms-global-code')}
				<PropertyDisplay label="Part/SKU Number Required">{skuRequired}</PropertyDisplay>
				<PropertyDisplay label="Can make projections">{canMakeProjections}</PropertyDisplay>
				{filterInternalOnly(companyData, userProfile, 'maker-code')}

				{filterInternalOnly(companyData, userProfile, 'edi-internal-id')}

				{filterInternalOnly(companyData, userProfile, 'ebs-account-num')}
			</span>
		);
	} else {
		return null;
	}
};
export default RenderAdditionalInfo;
