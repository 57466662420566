import React, { VFC } from 'react';
import ReactSelect, { Props, StylesConfig } from 'react-select';

import './DropdownSelect.css';


const defaultStyles: StylesConfig = {
	multiValue: (styles, { data }: { data: any }) => {
		return {
			...styles,
			backgroundColor: data.color ? data.color : 'white',
			color: data.color ? data.color : 'black',
			borderWidth: data.color ? 0 : 1,
			borderColor: 'hsl(0, 0%, 80%)',
			borderStyle: 'solid',
		};
	},
	multiValueLabel: (styles, { data }: { data: any }) => ({
		...styles,
		color: data.color ? data.color : undefined,
		whiteSpace: 'normal',
	}),
	multiValueRemove: (styles) => ({
		...styles,
		color: 'hsl(0, 0%, 80%)',
	}),
};


const DropdownSelect: VFC<Props> = (props: Props) => {
	return (
		<ReactSelect
			className="DropdownSelect"
			classNamePrefix="DropdownSelect"
			styles={defaultStyles}
			{...props}
		/>
	);
    
};
export default DropdownSelect;