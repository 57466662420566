import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import ActionLink from '../ActionLink/ActionLink';

import './RolloverList.css';


const RolloverList = ({values, dotted=true, placement='right', tooltipId}) => {
	if (!Array.isArray(values) || values.length < 2) {
		return values;
	}
	return (
		<OverlayTrigger
			placement={placement}
			overlay={
				<Tooltip id={tooltipId}>
					<ul className="RolloverList__list">
						{values.map((v) => (
							<li key={v}>{v}</li>
						))}
					</ul>
				</Tooltip>
			}
		>
            { dotted
				? <ActionLink dotted={dotted ? true : null}>
					{values[0]} {`(${values.length})`}
				</ActionLink>
				: <span>
					{values[0]} {`(${values.length})`}
				</span>
			}
		</OverlayTrigger>
	);
};
export default RolloverList;
