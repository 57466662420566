import React, { Component } from 'react';
import { Button, Col, Form, FormGroup, FormLabel, Row } from 'react-bootstrap';

import BaseModal from '../../../components/BaseModal/BaseModal';
import { orderConst } from '../../../constants/orderConstants';
import { putEditOrder } from '../../../services/ordersService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import ConsigneeFields from '../../OrderCreate/ConsigneeFields';
import HelpBlock from '../../../components/HelpBlock/HelpBlock';
import { getSchemas } from '../../../utils/orderUtils';

class EditOrderModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			freightForwarders: [],
			customsEntryPorts: [],
			display: true
		};
		this.toggleLoading = this.toggleLoading.bind(this);
		this.formChangeUpdate = this.formChangeUpdate.bind(this);
		this.renderFreightForwardersOptions = this.renderFreightForwardersOptions.bind(
			this
		);
		this.updateOrder = this.updateOrder.bind(this);
		this.generatePayload = this.generatePayload.bind(this);
		this.getPayloadConsignee = this.getPayloadConsignee.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	getModalFooter() {
		return (
			<div>
				<Button className="pull-left" onClick={this.props.toggleModal}>
					Cancel
				</Button>
				<Button
					variant="primary"
					onClick={() => {
						this.updateOrder();
					}}
				>
					Update
				</Button>
			</div>
		);
	}

	formChangeUpdate(event) {
		const element = event.target;
		let key = element.id;
		if (element.id === 'countryCode') {
			key = 'consigneeCountryCode';
		}
		this.setState({
			[key]: element.value
		});
	}

	renderFreightForwardersOptions() {
		const { freightForwarders } = this.state;
		return freightForwarders.map((freightForwarder) => {
			return (
				<option
					key={freightForwarder.value}
					value={freightForwarder.value}
				>
					{freightForwarder.name}
				</option>
			);
		});
	}

	renderCustomsEntryPorts() {
		const { customsEntryPorts } = this.state;
		return customsEntryPorts.map((customsEntryPort) => {
			return (
				<option
					key={customsEntryPort.value}
					value={customsEntryPort.value}
				>
					{customsEntryPort.name}
				</option>
			);
		});
	}

	updateOrder() {
		this.toggleLoading();
		const { order_header_id } = this.state;
		const payload = this.generatePayload();
		putEditOrder(order_header_id, payload)
			.then((response) => {
				toasterNotify('Order Updated', 'success');
				this.props.getOrder();
				this.props.getCreateOrderPageResources();
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'updating order'),
					'error',
					error
				);
			})
			.finally(() => {
				this.props.changePageMode('');
				this.props.toggleModal();
			});
	}

	generatePayload() {
		const basePayload = {
			order_status: this.props.getPayloadStatus(),
			platform_code: this.state.platform,
			publisher_po_number: this.state.publisherPO,
			ndid_company_id: this.state.ndid_company_id,
			order_header_id: this.state.order_header_id,

			order_comments: [
				{
					type: orderConst.COMMENT.REVIEWER,
					comment: !this.state.commentsPoApprover
						? ''
						: this.state.commentsPoApprover
				},
				{
					type: orderConst.COMMENT.NOTES_TO_NCL,
					comment: !this.state.commentsNcl
						? ''
						: this.state.commentsNcl
				}
			],
			freight_forwarder_code: this.state.freightForwarder,
			port_of_entry_code: this.state.customsEntryPort,
			packout_facility_id: this.state.shipTo,
			packout_facility_contact_name: this.state.destinationContact,
			packout_facility_contact_phone_number: this.state.destinationPhone,
			country_code: this.state.countryCode || 'US',
		};
		if (this.props.requiresConsignee(this.state.freightForwarder)) {
			basePayload['consignee_info'] = this.getPayloadConsignee();
		}
		return basePayload;
	}

	getPayloadConsignee() {
		const {
			japaneseContactName,
			japaneseEmail,
			ndid_company_id,
			consigneeAddress,
			japanesePhoneNumber,
			consigneeCity,
			consigneeState,
			consigneeZip,
			serviceLevel,
			carrierAccountNumber,
			consigneeCountryCode,
			consigneeCompanyName
		} = this.state;
		return {
			consignee_contact_name: japaneseContactName,
			consignee_contact_email: japaneseEmail,
			consignee_contact_phone_number: japanesePhoneNumber,
			consignee_company_name: consigneeCompanyName,
			consignee_service_level: serviceLevel,
			consignee_carrier_account_number: carrierAccountNumber,
			consignee_address_city: consigneeCity,
			consignee_address_region: consigneeState,
			consignee_address_postal_code: consigneeZip,
			consignee_address_country_code: consigneeCountryCode,
			consignee_address_street_line_1: consigneeAddress,
			consignee_company_id: ndid_company_id
		};
	}

	componentDidMount() {
		const { orderData, getCommentByType } = this.props;
		const defaultCustomsEntryPortsValue = orderData.customsEntryPorts[0]?.port_of_entry_code || '';
		const defaultConsigneeCountryCodeValue = orderData.countryCodes[0]?.country_code || '';
		this.setState({
			ndid_company_id: orderData.ndid_company_id,
			order_header_id: orderData.order_header_id,

			freightForwarder: orderData.freight_forwarder_code, // this is the value entered in the form
			freightForwarders: orderData.freightForwarders, // this is the list of options that can be selected

			japaneseContactName: orderData.japaneseContactName,
			japaneseEmail: orderData.japaneseEmail,
			japanesePhoneNumber: orderData.japanesePhoneNumber,
			consigneeCompanyName: orderData.consigneeCompanyName,
			consigneeAddress: orderData.consigneeAddress,
			consigneeCity: orderData.consigneeCity,
			consigneeState: orderData.consigneeState,
			consigneeZip: orderData.consigneeZip,
			serviceLevel: orderData.serviceLevel,
			carrierAccountNumber: orderData.carrierAccountNumber,
			customsEntryPorts: orderData.customsEntryPorts || defaultCustomsEntryPortsValue, // this is the list of options that can be selected
			customsEntryPort: orderData.port_of_entry_code, // this is the value entered in the form

			packout_facility_address: orderData.packout_facility_address,

			platform: orderData.platform_code,
			publisherPO: orderData.publisher_po_number,
			shipTo: orderData.packout_facility_id,
			destinationContact: orderData.packout_facility_contact_name,
			destinationPhone: orderData.packout_facility_contact_phone_number,

			countryCodes: orderData.countryCodes,
			consigneeCountryCode: orderData.consigneeCountryCode || defaultConsigneeCountryCodeValue,
			commentsPoApprover: getCommentByType(
				orderConst.COMMENT.REVIEWER
			),
			commentsNcl: getCommentByType(
				orderConst.COMMENT.NOTES_TO_NCL
			)
		});
	}

	validateToSchema() {
		const [schema, consigneeSchema] = getSchemas();
		try {
			if (this.props.requiresConsignee(this.state.freightForwarder)) {
				consigneeSchema.validateSync(this.state, {abortEarly: false, validatePublisherPO: null });
			} else {
				schema.validateSync(this.state, {abortEarly: false, validatePublisherPO: null });
			}
		} catch (err) {
			if (err.inner) {
				return err.inner.map(e => ({name: e.path, reason: e.message}));
			}
			throw err;
		}
		return [];
	}

	render() {
		const ableToEdit = this.props.ableToEdit();

		const flagged = this.validateToSchema();
		const formIsValid = flagged.length === 0;
		const flagsForField = (field) => flagged.find(f => f.name === field);

		const validationFlag = (field, defaultMessage=null) => {
			const flag = flagsForField(field);
			if (flag) {
				return (<HelpBlock><span className='text-danger'>{flag.reason}</span></HelpBlock>);
			} else {
				if (!defaultMessage) return (<HelpBlock>{defaultMessage}</HelpBlock>);
			}
			return null;
		};

		return (
			<BaseModal
				size="lg"
				show={this.state.display}
				onCancel={this.props.toggleModal}
				isSubmitting={this.state.isLoading}
			>
				<BaseModal.Title>Edit Order Details</BaseModal.Title>
				<Form>
					<FormGroup as={Row} className={'order-review-dl'}>
						<FormLabel column sm={4} className="text-sm-right">
							Freight Forwarder
						</FormLabel>
						<Col sm={8}>
							<select
								id="freightForwarder"
								onChange={this.formChangeUpdate}
								className="form-control order-review-input"
								value={this.state.freightForwarder}
								disabled={!ableToEdit}
							>
								{this.renderFreightForwardersOptions()}
							</select>
							{validationFlag('freightForwarder')}
						</Col>
					</FormGroup>

					{this.props.requiresConsignee(this.state.freightForwarder) && (
						<ConsigneeFields
							japaneseContactName={this.state.japaneseContactName}
							japaneseEmail={this.state.japaneseEmail}
							consigneeCompanyName={this.state.consigneeCompanyName}
							japanesePhoneNumber={this.state.japanesePhoneNumber}
							consigneeAddress={this.state.consigneeAddress}
							consigneeCity={this.state.consigneeCity}
							consigneeState={this.state.consigneeState}
							consigneeZip={this.state.consigneeZip}
							countryCode={this.state.consigneeCountryCode}
							serviceLevel={this.state.serviceLevel}
							carrierAccountNumber={this.state.carrierAccountNumber}
							countryCodes={this.state.countryCodes}

							columnSize={8}
							classPrefix={'order-review'}
							validationFlag={validationFlag}
							formElementChanged={this.formChangeUpdate}
							ableToEdit={ableToEdit}
						/>
					)}

					<FormGroup as={Row} className={'order-review-dl'}>
						<FormLabel column sm={4} className="text-sm-right">
							Customs Entry Port
						</FormLabel>
						<Col sm={8}>
							<select
								id="customsEntryPort"
								onChange={this.formChangeUpdate}
								className="form-control order-review-input"
								value={this.state.customsEntryPort}
								disabled={!ableToEdit}
							>
								{this.renderCustomsEntryPorts()}
							</select>
							{validationFlag('customsEntryPort')}
						</Col>
					</FormGroup>

					<FormGroup as={Row} className={'order-review-dl'}>
						<FormLabel column sm={4} className="text-sm-right">
							Packout Facility
						</FormLabel>
						<Col sm={8}>
							<input
								className="form-control order-review-input"
								value={this.state.packout_facility_address || ''}
								disabled={true}
							/>
						</Col>
					</FormGroup>
					{this.props.orderData.order_status !==
						orderConst.STATUS.SUBMITTED_FOR_APPROVAL && (
						<FormGroup as={Row} className={'order-review-dl'}>
							<FormLabel column sm={4} className="text-sm-right">
								Comments to PO Approver
							</FormLabel>
							<Col sm={8}>
								<textarea
									id="commentsPoApprover"
									onChange={this.formChangeUpdate}
									className="form-control order-review-input"
									value={this.state.commentsPoApprover}
								/>
							</Col>
						</FormGroup>
					)}
					<FormGroup as={Row} className={'order-review-dl'}>
						<FormLabel column sm={4} className="text-sm-right">
							Comments to NCL
						</FormLabel>
						<Col sm={8}>
							<textarea
								id="commentsNcl"
								onChange={this.formChangeUpdate}
								className="form-control order-review-input"
								value={this.state.commentsNcl}
							/>
						</Col>
					</FormGroup>
				</Form>
				<BaseModal.Submit
					onClick={ formIsValid ? () => {
						this.updateOrder(formIsValid);
					} : null }
				>
					Update
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}

export default EditOrderModal;
