import React, { Component } from 'react';
import dayjs from 'dayjs';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import BaseModal from '../../../components/BaseModal/BaseModal';
import DatePicker from '../../../components/DatePicker/DatePicker';
import DropdownSelect from '../../../components/DropdownSelect/DropdownSelect';
import { subConst } from '../../../constants/submissionsConstants';
import {
	getFailureTypes,
	postLotcheckApproval
} from '../../../services/productsService';
import { getRoleAssignedUsers } from '../../../services/usersService';
import { dateFormat, parseDateString } from '../../../utils/dateUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { SubmissionAsset } from '../utilComponents/SubmissionAsset';


function validateDate(value) {
	return (
		value.isValid() &&
		value.isBetween(
			dayjs().subtract('1', 'day'),
			dayjs().add('100', 'year')
		)
	);
}


function mapStateToProps(state) {
	return {
		userProfile: state.authReducer.userProfile
	};
}

export class LotcheckApprovalModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			failureTypes: [],
			testerUsers: [],
			selectedTester: '',
			lotcheckApproval: this.getLotcheckApproval()
		};
		this.testingStaffChanged = this.testingStaffChanged.bind(this);
		this.failureTypesChanged = this.failureTypesChanged.bind(this);
		this.toggleLoading = this.toggleLoading.bind(this);
	}

	// TODO: set loading state to false or true, not the inverse of the current state.
	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	requiredFieldsSet() {
		const { lotcheckApproval } = this.state;

		const validJudgeDate = validateDate(
			dayjs(lotcheckApproval.judgement_date)
		);
		const validEmanDate = validateDate(
			dayjs(lotcheckApproval.emanual_date)
		);

		if (
			lotcheckApproval.testing_staff !== null &&
			lotcheckApproval.testing_hours !== null &&
			lotcheckApproval.testing_hours.length !== 0 &&
			!isNaN(Number(lotcheckApproval.testing_hours)) &&
			validEmanDate &&
			(this.props.submissionStatus.toUpperCase() === 'PRECHECK' ||
				lotcheckApproval.judgement) &&
			validJudgeDate
		) {
			return true;
		}
	}

	getLotcheckApproval() {
		let { testResult } = this.props;
		if (testResult && testResult !== '') {
			return {
				judgement: testResult.judgement,
				testing_staff: testResult.testing_staff,
				failure_types: testResult.failure_types,
				judgement_date: new Date(testResult.judgement_date),
				emanual_date: new Date(testResult.emanual_date),
				testing_hours: testResult.testing_hours,
				attached_files: [],
				publisher_comment: testResult.comment || '',
				ncl_comment: testResult.comment || ''
			};
		}

		return {
			judgement: null,
			testing_staff: '',
			failure_types: [],
			judgement_date: new Date(),
			emanual_date: new Date(),
			testing_hours: '',
			attached_files: [],
			publisher_comment: null,
			ncl_comment: null
		};
	}

	componentDidMount() {
		this.toggleLoading();
		let { systemFamily } = this.props;

		const failureTypescall = getFailureTypes(systemFamily)
			.then((response) => {
				if (response.data) {
					let failureTypes = [];
					response.data.forEach((failureType) => {
						failureTypes.push({
							key: failureType['code'],
							text: failureType['name'],
							value: failureType['code']
						});
					});
					this.setState({
						failureTypes: failureTypes
					});
				}
			});

		const usersCall = getRoleAssignedUsers('Coordinator')
			.then((response) => {
				if (response.data) {
					let users = [];
					response.data.forEach((user) => {
						users.push({
							key: user['ndid_user_id'],
							text: user['user_name'],
							value: user['user_name']
						});
					});
					this.setState({
						testerUsers: users,
						selectedTester: this.getPreselectedTestingStaff(
							users
						)
					});
				}
			});


		Promise.all([failureTypescall, usersCall])
			.then(() => {
				this.toggleLoading();
			})
			.catch((error) => {
				this.setState({fault: error});
				toasterNotify(
					createMessageForError(error, 'retrieving approval data'),
					'error',
					error
				);
			});

	}

	getPreselectedTestingStaff(testerUsers) {
		let preSelected = testerUsers.filter((user) => {
			return user.key === this.state.lotcheckApproval.testing_staff;
		});

		if (preSelected.length > 0) {
			return preSelected[0].value;
		}
	}

	inputChanged(field, event) {
		let lotcheckApproval = this.state.lotcheckApproval;
		lotcheckApproval[field] = event.currentTarget.value;
		this.setState({lotcheckApproval});
	}

	dateChange(field, date) {
		let lotcheckApproval = this.state.lotcheckApproval;
		lotcheckApproval[field] = date && parseDateString(date).toDate();
		this.setState({lotcheckApproval});
	}

	testingStaffChanged(name, data) {
		const { value } = data;
		const { key } = data.options.find((o) => o.value === value);
		let lotcheckApproval = this.state.lotcheckApproval;
		lotcheckApproval.testing_staff = key;
		this.setState(
			{
				selectedTester: value,
				lotcheckApproval
			}
		);
	}

	failureTypesChanged(name, { value }) {
		let lotcheckApproval = this.state.lotcheckApproval;
		lotcheckApproval.failure_types = value;
		this.setState({lotcheckApproval});
	}

	submitApproval() {
		this.toggleLoading();
		postLotcheckApproval(this.getPayload())
			.then((response) => {
				this.setState(() => {
					toasterNotify(
						'Lotcheck approval submitted',
						'success'
					);
				});
				this.props.closeModal();
				this.props.loadSubmissionTestResult();
				this.props.loadActivityLog();
			})
			.catch((error) => {
				this.setState(() => {
					toasterNotify(
						createMessageForError(error, 'submitting lotcheck approval'),
						'error',
						error
					);
				});
				this.props.closeModal();
			});
	}

	getPayload() {
		let lotcheckApproval = this.state.lotcheckApproval;
		const { productSubmissionId, submissionStatus } = this.props;
		const lotcheck_region = 'NOA';
		return {
			judgement: LotcheckApprovalModal.getJudgement(
				submissionStatus,
				lotcheckApproval.judgement
			),
			lotcheck_region: lotcheck_region,
			product_submission_id: productSubmissionId,
			testing_staff: lotcheckApproval.testing_staff,
			failure_types: lotcheckApproval.failure_types,
			judgement_date: LotcheckApprovalModal.formatDate(
				lotcheckApproval.judgement_date
			),
			emanual_date: LotcheckApprovalModal.formatDate(
				lotcheckApproval.emanual_date
			),
			testing_hours: lotcheckApproval.testing_hours,
			comments: lotcheckApproval.ncl_comment
				? lotcheckApproval.ncl_comment
				: lotcheckApproval.publisher_comment
		};
	}

	static getJudgement(submissionStatus, judgement) {
		submissionStatus = submissionStatus.toUpperCase();
		return submissionStatus === 'PRECHECK' ? submissionStatus : judgement;
	}

	static formatDate(dateTime) {
		if (!dateTime) return;
		return dayjs(dateTime).format(dateFormat.DATE_YMD);
	}

	getModalBody() {
		const judgement_date = this.state.lotcheckApproval.judgement_date
			? this.state.lotcheckApproval.judgement_date
			: new Date();
		const emanual_date = this.state.lotcheckApproval.emanual_date
			? this.state.lotcheckApproval.emanual_date
			: new Date();

		const staffOptions = this.state.testerUsers.map(o => ({value: o.value, label: o.text, key: o.key}));
		const staffValue = staffOptions.filter(o => this.state.selectedTester === o.value);
		const failureOptions = this.state.failureTypes.map(o => ({value: o.value, label: o.text}));
		const failureValue = failureOptions.filter(o => Array.isArray(this.state.lotcheckApproval.failure_types) && this.state.lotcheckApproval.failure_types?.includes(o.value));

		return (
			<div>
				<div className="alert alert-warning">
					Please note that once this has been submitted, notifications
					will be sent to the appropriate parties.
				</div>

				<form className="form-horizontal">
					{this.props.submissionStatus.toUpperCase() !==
						subConst.STATUS.PRECHECK && (
						<div className="form-group row">
							<label
								htmlFor="platform"
								className="control-label col-sm-4 text-sm-right"
							>
								Judgement
							</label>
							<div className="col-sm-8">
								<select
									onChange={this.inputChanged.bind(
										this,
										'judgement'
									)}
									className="form-control"
									defaultValue={
										this.state.lotcheckApproval.judgement
									}
								>
									<option />
									<option value={subConst.STATUS.APPROVED}>
										Approved
									</option>
									<option value={subConst.STATUS.FAILED}>
										Failed
									</option>
									{[
										subConst.TYPE.PRECHECK,
										subConst.TYPE.E3
									].includes(this.props.submissionType) && (
										<option
											value={subConst.STATUS.PRECHECK}
										>
											Precheck
										</option>
									)}
								</select>
							</div>
						</div>
					)}
					<div className="form-group row">
						<label
							htmlFor="platform"
							className="control-label col-sm-4 text-sm-right"
						>
							Testing Staff
						</label>
						<div className="col-sm-8">
							<DropdownSelect 
								isSearchable
								options={staffOptions}
								value={staffValue}
								onChange={(newValue) => this.testingStaffChanged(
									null, 
									{ value: newValue.value, options: staffOptions }
								)}
							/>
						</div>
					</div>

					<div className="form-group row fail-type">
						<label
							htmlFor="platform"
							className="control-label col-sm-4 text-sm-right"
						>
							Failure Type
							<p>
								<small
									id="passwordHelpBlock"
									className="form-text text-muted"
								>
									*Optional
								</small>
							</p>
						</label>
						<div className="col-sm-8">
							<DropdownSelect 
								isSearchable
								isMulti
								options={failureOptions}
								value={failureValue}
								onChange={(newValue) => this.failureTypesChanged(null, { value: newValue.map(v => v.value) })}
							/>
						</div>
					</div>

					<FormGroup as={Row}>
						<label className="col-sm-4 control-label text-sm-right">
							Judgement Date
						</label>
						<Col sm={8}>
							<DatePicker
								value={judgement_date}
								onChange={this.dateChange.bind(
									this,
									'judgement_date'
								)}
							/>
						</Col>
					</FormGroup>

					<FormGroup as={Row}>
						<label className="col-sm-4 control-label text-sm-right">
							eManual Date
						</label>
						<Col sm={8}>
							<DatePicker
								value={emanual_date}
								onChange={this.dateChange.bind(
									this,
									'emanual_date'
								)}
							/>
						</Col>
					</FormGroup>

					<div className="form-group row">
						<label className="control-label col-sm-4 text-sm-right">
							Testing Hours
						</label>
						<div className="col-sm-3">
							<input
								type="text"
								className="form-control"
								defaultValue={
									this.state.lotcheckApproval.testing_hours
								}
								onChange={this.inputChanged.bind(
									this,
									'testing_hours'
								)}
							/>
						</div>
					</div>

					<div className="form-group row">
						<label className="col-sm-4 text-sm-right">
							Submission Files
						</label>
						{this.props.submissionFiles.length ? (
							<div className="col-sm-8">
								{this.props.submissionFiles.map((item, i) => (
									<div key={'submission-file-'+ i}>
										{
											<SubmissionAsset
												key={i}
												asset={item}
												userProfile={
													this.props.userProfile
												}
											/>
										}
									</div>
								))}
							</div>
						) : (
							<div className="col-sm-3">None</div>
						)}
					</div>

					{this.loadCommentField()}
				</form>
			</div>
		);
	}

	loadCommentField() {
		let { submissionStatus } = this.props;

		submissionStatus = submissionStatus.toUpperCase();

		if (
			this.state.lotcheckApproval.judgement === 'FAILED' ||
			submissionStatus === 'PRECHECK'
		) {
			return this.getPublisherCommentFiled();
		}

		if (this.state.lotcheckApproval.judgement === 'APPROVED') {
			return this.getNCLCommentFiled();
		}
	}

	getPublisherCommentFiled() {
		return (
			<div className="form-group row comment-publisher">
				<label htmlFor="platform" className="control-label col-sm-4">
					Comments to Publisher
					<p>
						<small
							id="passwordHelpBlock"
							className="form-text text-muted"
						>
							*Optional
						</small>
					</p>
				</label>
				<div className="col-sm-8">
					<textarea
						className="form-control"
						rows="5"
						placeholder="Enter additional comments to publisher on what failed"
						value={this.state.lotcheckApproval.publisher_comment}
						onChange={this.inputChanged.bind(
							this,
							'publisher_comment'
						)}
					/>
				</div>
			</div>
		);
	}

	getNCLCommentFiled() {
		return (
			<div className="form-group row comment-ncl">
				<label htmlFor="platform" className="control-label col-sm-4">
					Comments to NCL
					<p>
						<small
							id="passwordHelpBlock"
							className="form-text text-muted"
						>
							*Optional
						</small>
					</p>
				</label>
				<div className="col-sm-8">
					<textarea
						className="form-control"
						rows="5"
						placeholder="Enter additional comments to NCL for their review"
						value={this.state.lotcheckApproval.ncl_comment}
						onChange={this.inputChanged.bind(this, 'ncl_comment')}
					/>
				</div>
			</div>
		);
	}

	render() {
		return (
			<BaseModal
				show={true}
				onCancel={this.props.closeModal}
				isLoading={this.state.isLoading}
				fault={this.state.fault}
			>
				<BaseModal.Title>Lotcheck Approval</BaseModal.Title>
				{this.getModalBody()}
				<BaseModal.Submit
					disabled={this.state.isLoading || !this.requiredFieldsSet()}
					onClick={() => {
						this.submitApproval();
					}}
				>
					Submit
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}

export default connect(mapStateToProps)(LotcheckApprovalModal);
