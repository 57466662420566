import React from 'react';
import { Table } from 'react-bootstrap';

import SectionTitle from '../../components/SectionTitle/SectionTitle';
import PropertyDisplay from '../../components/PropertyDisplay/PropertyDisplay';
import { numberWithCommas } from '../../utils/currencyUtils';

const PlatformTab = ({ platform, digitalCodes }) => {
	const platformDetails = [
		{ label: 'Platform', key: 'platform_name' },
		{ label: 'Status', key: 'status' },
		{ label: 'Family Code', key: 'platform_code' },
		{ label: 'Agreement Family', key: 'agreement_family' }
	];

	const retailDetails = [
		{ label: 'Min Initial Order Qty', key: 'minimumOrderQty' },
		{ label: 'Min Bulk Qty', key: 'minimumBulkQty' },
		{ label: 'Case Qty', key: 'orderIncrementQty' },
		{ label: 'Ship Days', key: 'leadTimeDaysCount' },
		{ label: 'Projections Time Period', key: 'order_projection_range' },
		{...(platform?.standard_rom_prices ? { label: 'Standard ROM Prices', key: 'standard_rom_prices' } : null)}
	];

	const digitalDetails = [
		{ label: 'Service Fee', key: 'Service Fee' },
		{ label: 'Service Fee Qty', key: 'Service Fee Qty' },
		{ label: 'MSRP%', key: 'MSRP%' }
	];

	const filteredCodes = digitalCodes.filter((x) => {
		return x.platform === platform.platform_code;
	});

	function formatDigitalOrders(digitalCodes) {
		if (digitalCodes.length > 0) {
			let formatted = {
				'Service Fee Qty': 'N/A',
				'MSRP%': 'N/A',
				'Service Fee': 'N/A'
			};
			const legend = digitalCodes[0].digital_orders_legend;
			legend.forEach((type) => {
				formatted[type.price_type] = numberWithCommas(
					type.cost_breakdown
				);
			});
			return formatted;
		}
		return null;
	}

	function renderDigitalOrdersPurposeTable(digitalCodes) {
		if (digitalCodes.length === 0) {
			return null;
		}
		return (
			<>
				<SectionTitle>Digital Code Purpose</SectionTitle>
				<Table>
					<thead>
						<tr>
							<th>Purpose</th>
							<th>Price</th>
							<th>Minimum # of codes</th>
							<th>Maximum # of codes</th>
						</tr>
					</thead>
					<tbody>
						{digitalCodes.length > 0 &&
							digitalCodes[0].digital_orders_purpose.map((p) => {
								return (
									<tr
										key={`${p.digital_orders_purpose_id}-tr`}
									>
										<td>{p.purpose}</td>
										<td>{p.price_type}</td>
										<td>{p.min_num_of_codes}</td>
										<td>{p.max_num_of_codes}</td>
									</tr>
								);
							})}
					</tbody>
				</Table>
			</>
		);
	}

	const objectToList = (object) => {
		const output = [];
		for (const [k, v] of Object.entries(object)) {
			output.push(<tr><td>{k}</td><td>{v}</td></tr>);
		}
		return <table className="my-2 table table-bordered w-auto">{output}</table>;
	};

	const renderDetails = (title, platform, details) => {
		if (platform == null) {
			return null;
		}
		return (
			<>
				<SectionTitle>{title}</SectionTitle>
				{details.map((detail) => {
					const label = detail.label;
					let value = platform[detail.key] || '';
					if (typeof value === 'object') {
						value = objectToList(value);
					}
					return (
						<PropertyDisplay key={label+value} label={label}>
							{value}
						</PropertyDisplay>
					);
				})}
			</>
		);
	};

	return (
		<div className="PlatformTab">
			{renderDetails('Platform Details', platform, platformDetails)}
			{renderDetails('Retail Orders', platform, retailDetails)}
			{renderDetails(
				'Digital Orders',
				formatDigitalOrders(filteredCodes),
				digitalDetails
			)}

			{renderDigitalOrdersPurposeTable(filteredCodes)}
		</div>
	);
};

export default PlatformTab;
