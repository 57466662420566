import React, { ReactElement, Component } from 'react';
import { Column, ComponentPropsGetter0, ComponentPropsGetterR, SortingRule } from 'react-table';

import BaseTable from '../BaseTable/BaseTable';
import Page from '../Page/Page';
import FilterBar, { TFilterProperties } from '../FilterBar/FilterBar';

import './filterableTable.css';


interface FilterableTableProps {
	data?: Record<string, any>[];
	dataFormat: Array<Column<any>>;
	componentsBelowTable?: ReactElement;
	searchableFields?: string[];
	searchableFieldPlaceHolder?: string;
	defaultSorted?: SortingRule[];
	filterProperties?: TFilterProperties;
	getTrProps?: ComponentPropsGetterR | ComponentPropsGetter0;
	pages?: number;
	className?: string;
	showPagination?: boolean;
	defaultPageSize?: number;
	noBorder?: boolean;
	retainPageState?: boolean;
	stateOnTab?: string | false;
}

interface FilterableTableState {
	filteredData?: Record<string, any>[],
}

class FilterableTable extends Component<FilterableTableProps, FilterableTableState> {
	constructor(props: FilterableTableProps) {
		super(props);
		this.state = {
			filteredData: props.data
		};
	}

	render(): ReactElement {
		const {
			componentsBelowTable,
			searchableFields,
			searchableFieldPlaceHolder,
			defaultSorted,
			dataFormat,
			filterProperties,
			getTrProps,
			pages,
			className = '',
			showPagination,
			defaultPageSize,
			noBorder,
			data,
			retainPageState,
			stateOnTab,
		} = this.props;
		const { filteredData } = this.state;
		const showFilters = (filterProperties || searchableFields);

		const table = (
			<BaseTable
				className={!noBorder ? 'FilterableTable__full-column ' + className : className}
				showPagination={showPagination}
				defaultSorted={defaultSorted}
				columns={dataFormat}
				data={(showFilters ? filteredData : data) || []}
				defaultPageSize={defaultPageSize}
				minRows={0}
				getTrProps={getTrProps}
				pages={pages}
				retainPageState={retainPageState}
				stateOnTab={stateOnTab}
			/>
		);
		return (
			<div className='FilterableTable Page__fill-space'>
				{showFilters && (
					<FilterBar
						searchableFields={searchableFields}
						searchableFieldPlaceHolder={searchableFieldPlaceHolder}
						filterProperties={filterProperties}
						data={data}
						onFilter={(filteredData) => this.setState({filteredData})}
						retainPageState={retainPageState || false}
						stateOnTab={stateOnTab || false}
					/>
				)}
				{noBorder ? (
					<>
						{table}
						{componentsBelowTable}
					</>
				) : (
					<Page.FullPageCol>
						{table}
						{componentsBelowTable}
					</Page.FullPageCol>
				)}
			</div>
		);
	}
}

export default FilterableTable;
