import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import { useUserProfile } from '../../hooks/reduxHooks';
import { ping } from '../../services/baseService';
import { setFeatureFlags } from '../../store/actions/actions';
import { fetchHelpTopics, fetchPlatforms, fetchSunsetFeatures } from '../../store/actions/referenceActions';
import { initFeatureFlags } from '../../utils/featureFlagUtils';
import { pathForUserAction } from '../../utils/routeUtils';
import ProfileManager from '../ProfileManager/ProfileManager';
import { LoggedOutRouter, MainRouter, MaintenanceRoute } from '../Routes/Routes';
import { toMS } from '../../utils/dateUtils';


const App = () => {
	const [loggedIn, setLoggedIn] = useState<boolean>(false);
	const userProfile = useUserProfile();
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const params = new URLSearchParams(location.search);

	useQuery('ping', () => ping(), { 
		enabled: !!userProfile,
		staleTime: toMS('minute') * 10,
		refetchOnWindowFocus: true,
		refetchOnMount: false,
		refetchOnReconnect: true,
	});
	
	const resolveLoggedIn = () => {
		const isLoggedIn = !!userProfile;
		if (isLoggedIn !== loggedIn) {
			setLoggedIn(!!userProfile);

			const featureFlags = initFeatureFlags({userProfile, history});
			dispatch(setFeatureFlags(featureFlags));

			dispatch(fetchPlatforms());
			
			// just in case its missing or stale
			dispatch(fetchSunsetFeatures());
			dispatch(fetchHelpTopics()); 
		}
	};

	const checkActions = () => {
		const params = new URLSearchParams(location.search);
		if (location.pathname === '/' && params.has('action')) {
			const action = params.get('action');
			action && history.push(pathForUserAction(action, Object.fromEntries(params)) || '/');
		}
	};

	resolveLoggedIn();

	useEffect(() => {
		checkActions();
	}, []);
	
	return params.has('maintenance') ? (
		<MaintenanceRoute />
	) : (
		<div id="app">
			<ProfileManager>
				{loggedIn ? <MainRouter userProfile={userProfile} /> : <LoggedOutRouter />}
			</ProfileManager>
		</div>
	);
};

export default App;
