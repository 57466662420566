import React, { useState, useEffect } from 'react';
import * as yup from 'yup';

import BaseModal from '../../BaseModal/BaseModal';
import Forms from '../../Forms/Forms';
import { isEmptyObject } from '../../../utils/dataUtils';
import { validateToSchema } from '../../../utils/validationUtils';
import { toasterNotify } from '../../../utils/toaster';
import { putFeatureFlag } from '../../../services/featuresService';


const LIMITED_ACTIVE = 'Limited Active';

export const baseSchema = yup.object({
	name: yup.string().trim().default('').required('Feature must have a name'),
	description: yup.string().default('').transform((value) => value || ''),
	status: yup.string().default('Inactive').required('A status must be selected'),
	ndid_company_ids: yup.array().nullable().when('status', {
		is: LIMITED_ACTIVE,
		then: (schema) => schema
			.default([]),
		otherwise:(schema) => schema
			.transform((value, originalValue) => []),
	}),
});

const FeaturePermissionsModal = ({
	show,
	onClose,
	onChange,
	statuses = [],
	featurePermission,
	editPermission,
	companies,
}) => {
	const [formValues, setFormValues] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showAllErrors, setShowAllErrors] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const validationErrors = validateToSchema(baseSchema, formValues);


	useEffect(() => {
		if (show) {
			setFormValues(featurePermission);
			setIsLoading(false);
		}
	}, [show]);

	const submitForm = async () => {
		if (isSubmitting) {
			return;
		}

		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}

		const castedFormValues = baseSchema.cast(formValues, { stripUnknown: true });
		setIsSubmitting(true);

		if (featurePermission) {
			putFeatureFlag(featurePermission.feature_id, castedFormValues)
				.then(() => {
					onChange();
					toasterNotify(
						`Your feature "${featurePermission.name}" has been successfully updated.`,
						'success',
					);
				})
				.catch((err) => {
					toasterNotify(
						'There was an unexpected error while updating the feature.',
						'error',
						err,
					);
				})
				.finally(() => {
					setIsSubmitting(false);
				});
		}

		setShowAllErrors(false);
	};

	return (
		<BaseModal show={show} onCancel={onClose} isLoading={isLoading} isSubmitting={isSubmitting}>
			<BaseModal.Title> Edit {featurePermission?.name || 'Feature'}</BaseModal.Title>
			<Forms
				onChange={(v) =>
					setFormValues({
						...v,
						ndid_company_ids: v.status === LIMITED_ACTIVE ? v.ndid_company_ids : [],
					})
				}
				values={formValues}
				validationErrors={validationErrors}
				showAllErrors={showAllErrors}
				vertical
			>
				<Forms.Heading>Status</Forms.Heading>
				<Forms.Select id="status" placeholder="Select a value" disabled={!editPermission}>
					{statuses &&
						Array.from(statuses).map((key) => (
							<Forms.Option value={key} key={key}>
								{key}
							</Forms.Option>
						))}
				</Forms.Select>
				<Forms.Heading>Description</Forms.Heading>
				<Forms.TextArea
					id="description"
					maxLength="255"
					rows={5}
					disabled={!editPermission}
				/>
				<Forms.MultiSelect id="ndid_company_ids" disabled={formValues.status !== LIMITED_ACTIVE} searchable>
					<Forms.Heading>Companies</Forms.Heading>
					{companies &&
						companies.map((company) => (
							<Forms.Option
								value={company.ndid_company_id}
								key={company.ndid_company_id}
							>
								{company.company_name}
							</Forms.Option>
						))}
				</Forms.MultiSelect>
			</Forms>
			{editPermission && (
				<BaseModal.Submit onClick={() => submitForm()}>Update</BaseModal.Submit>
			)}
		</BaseModal>
	);
};

export default FeaturePermissionsModal;
