import React, { useMemo, useState, VFC } from 'react';
import { Button } from 'react-bootstrap';
import { useQuery } from 'react-query';

import FAIcon from '../../components/FAIcon/FAIcon';
import FilterableTable from '../../components/FilterableTable/FilterableTable';
import { actionsColumn } from '../../components/BaseTable/BaseTable';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import { permConst } from '../../constants/permConst';
import { useUserProfile } from '../../hooks/reduxHooks';
import { isAuthorized } from '../../utils/userUtils';
import ActiveCell from '../../components/cells/ActiveCell/ActiveCell';
import StatusText from '../../components/StatusText/StatusText';
import { getFreightForwardersList } from '../../services/ordersService';
import { displayString } from '../../utils/stringUtils';
import AddEditFreightForwarderModal from './modals/AddEditFreightForwarderModal';


interface OpenModal {
	type?: 'ADD_MODAL' | 'EDIT_MODAL';
	code?: string;
}

const ManageFreightForwarders: VFC = () => {
	const [openModal, setOpenModal] = useState<OpenModal>();
	const userProfile = useUserProfile();
	const freightForwardersListQuery = useQuery('getFreightForwardersList',
		() => getFreightForwardersList());
	const isLoading = freightForwardersListQuery.isLoading;
	const fault = freightForwardersListQuery.isError;

	const ActionCell = ({ original }: { original: Record<string, any> }) => {
		return (
			isAuthorized(userProfile.permissions, [permConst.FREIGHT_FORWARDER.EDIT.ALL]) && (
				<MeatballDropdown id="help-topic-meatball" toggleSize={'lg'}>
					<MeatballDropdown.Item
						key={`edit-${original.id}`}
						eventKey={original.id}
						onClick={() =>
							setOpenModal({
								type: 'EDIT_MODAL',
								code: original.code,
							})
						}
					>
						Edit Freight Forwarder
					</MeatballDropdown.Item>
				</MeatballDropdown>
			)
		);
	};

	const dataFormat = [
		{Header: 'Name', accessor: 'name', width: 800,},
		{Header: 'Has Additional Fields', accessor: 'has_additional_fields', Cell: ActiveCell},
		{Header: 'Status', accessor: 'status',
			Cell: ({ value }: { value: string }) => <StatusText>{value}</StatusText>},
		{
			...actionsColumn,
			Cell: ActionCell,
		},
	];

	const filterProperties = useMemo(() => new Map(
		Object.entries({
			'Status' : {
				filter: 'status',
				selectableFilters: new Set([
					displayString('ACTIVE'),
					displayString('INACTIVE'),
				])
			},
			'Has Additional Fields' : {
				filter: 'has_additional_fields',
				selectableFilters: new Set([
					'Yes',
					'No',
				]),
				customEvaluation: (value: boolean, filters: Set<string>) => {
					const boolValue = value === true ? 'Yes' : value === false ? 'No' : '';
					return filters.has(boolValue);
				}
			},
		})
	), []);

	const onClose = () => {
		setOpenModal({});
	};

	return (
		<Page fault={fault} isLoading={isLoading}>
			<Title title="Manage Freight Forwarders"
				   button={<>
					   {isAuthorized(userProfile.permissions, [permConst.FREIGHT_FORWARDER.ADD.ALL]) && (
						   <Button
							   variant="primary"
							   onClick={(e) =>
								   setOpenModal({
									   type: 'ADD_MODAL',
								   })
							   }
						   >
							   <FAIcon className="mr-1" name="plus"/>
							   Add Freight Forwarder
						   </Button>
					   )}
				   </>}
			/>
			<FilterableTable
				data={freightForwardersListQuery.data?.data}
				dataFormat={dataFormat}
				filterProperties={filterProperties}
				searchableFields={['name']}
				searchableFieldPlaceHolder="Search name..."
				defaultSorted={[
					{
						id: 'status',
						desc: false
					},
					{
						id: 'name',
						desc: false
					}
				]}
			/>
			<AddEditFreightForwarderModal
				show={!!openModal && (openModal?.type === 'ADD_MODAL' || openModal?.type === 'EDIT_MODAL')}
				onCompletion={() => freightForwardersListQuery.refetch()}
				onClose={onClose}
				editCode={openModal?.code}
			/>
		</Page>
	);
};

export default ManageFreightForwarders;
