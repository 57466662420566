import React, { Component } from 'react';
import { Alert, Col, FormControl, Row } from 'react-bootstrap';

import BaseModal from '../../../../components/BaseModal/BaseModal';
import HelpBlock from '../../../../components/HelpBlock/HelpBlock';
import { postProductRightsTransferRequests } from '../../../../services/productTransfersService';
import { createMessageForError, toasterNotify } from '../../../../utils/toaster';


export default class AcceptDistributionTransferModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			display: true,
			acceptComment: '',
			isLoading: false
		};
	}

	acceptTransfer() {
		const {
			closeModal,
			updateTransferStatus,
			productRightsTransferId,
			newTransferStatus
		} = this.props;
		const { acceptComment } = this.state;
		const payload = {
			transfer_status: newTransferStatus,
			comment: acceptComment
		};

		this.setState({ isLoading: true });
		postProductRightsTransferRequests(
			productRightsTransferId,
			payload
		)
			.then((response) => {
				updateTransferStatus(newTransferStatus);
				toasterNotify('Product rights transfer executed', 'success');
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'accepting a distribution rights transfer', error),
					'error',
					error
				);
			})
			.finally(() => {
				closeModal();
			});
	}

	render() {
		const { closeModal } = this.props;
		const { display, acceptComment, isLoading } = this.state;

		return (
			<BaseModal show={display} onCancel={closeModal} isSubmitting={isLoading}>
				<BaseModal.Title>Accept Transfer</BaseModal.Title>
				<Alert variant="info">
					This action will approve the distribution transfer.
					The owner will no longer have the specified distribution
					rights to this product. The receiving publisher will now
					have these rights.
				</Alert>
				<Row>
					<Col sm={3}>
						<strong>Comments:</strong>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<FormControl
							as="textarea"
							rows="6"
							value={acceptComment}
							onChange={(e) => {
								this.setState({
									acceptComment: e.target.value
								});
							}}
						/>
						<HelpBlock>
							*Optional
						</HelpBlock>
					</Col>
				</Row>

				<BaseModal.Submit
					onClick={() => {
						this.acceptTransfer();
					}}
					disabled={isLoading}
				>
					Accept Transfer
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}
