import React, { PropsWithChildren, useEffect, useState } from 'react';
import * as yup from 'yup';

import BaseModal from '../../BaseModal/BaseModal';
import Forms from '../../Forms/Forms';
import { validateToSchema } from '../../../utils/validationUtils';
import { isEmptyObject } from '../../../utils/dataUtils';


const schema = yup.object({
	comment: yup.string().trim().required('Comment cannot be empty'),
});

interface CommentModalProps {
	show?: boolean;
	title?: string;
	prompt?: string;
	onClose?: () => void;
	onChange?: (comment: string) => Promise<unknown>;
	maxLength?: number;
}
const CommentModal = ({ show, title, prompt, onClose, onChange, maxLength, children }: PropsWithChildren<CommentModalProps>) => {
	const [formValues, setFormValues] = useState<{ comment?: string }>({});
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	useEffect(() => {
		if (show) {
			setFormValues({});
			setShowAllErrors(false);
			setIsSubmitting(false);
		}
	}, [show]);
	return (
		<BaseModal show={show || false} isSubmitting={isSubmitting} onCancel={() => onClose && onClose()}>
			<BaseModal.Title>{title}</BaseModal.Title>
			{children}
			<Forms
				values={formValues}
				onChange={(newValues) => setFormValues(newValues)}
				validationErrors={validateToSchema(schema, formValues)}
				showAllErrors={showAllErrors}
                vertical
			>
				<Forms.TextArea id="comment" rows={4} maxLength={maxLength || 2000}>
                    <Forms.Heading>{prompt || 'Comment'}</Forms.Heading>
                </Forms.TextArea>
			</Forms>
			<BaseModal.Submit
				onClick={async () => {
					if (isEmptyObject(validateToSchema(schema, formValues))) {
						setIsSubmitting(true);
						onChange && await onChange(formValues?.comment || '');
						setIsSubmitting(false);
					} else {
						setShowAllErrors(true);
					}
				}}
			></BaseModal.Submit>
		</BaseModal>
	);
};
export default CommentModal;
