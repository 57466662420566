import React, { Component } from 'react';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import AutoComplete from '../../components/AutoComplete/AutoComplete';
import FAIcon from '../../components/FAIcon/FAIcon';
import LoadingText from '../../components/Loading/LoadingText';
import { getPrice } from '../../services/ordersService';
import { formatCurrency, parseCurrency } from '../../utils/currencyUtils';
import { ensureDate } from '../../utils/dateUtils';
import { getSubmissionFullVersion, sortVersions } from '../../utils/submissionVersionUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { determineActiveRightTransfer, transferDomains } from '../../utils/transferRightsUtils';

import './ProductsSelectionRow.css';


function extractPartNumbers(partNumbers, owner) {
	let value = partNumbers.filter((pn) => {
		return pn.part_number_owner === owner;
	});
	if (value.length) {
		return value[0].part_number;
	}
	return null;
}

class ProductsSelectionRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isInitialized: false,
			isLoadingPrice: false,
			selection: this.props.selection,
			selectedProduct: null,
			selectedVersion: null,

			requestedQuantity: this.props.selection.quantity || 0,
			requestedShipByDate: ensureDate(this.props.selection.shipByDate),
			unitPrice: this.props.selection.unitPrice || '',
			priceError: false
		};

		this.quantityInput = React.createRef();

		this.onUpdateSelection = this.onUpdateSelection.bind(this);
		this.onResetSelection = this.onResetSelection.bind(this);
		this.userClicked = this.userClicked.bind(this);
		this.resetForm = this.resetForm.bind(this);
		this.onVersionChanged = this.onVersionChanged.bind(this);
		this.debouncedQuantityChanged = debounce(
			this.debouncedQuantityChanged.bind(this),
			250
		);
		this.onQuantityChanged = this.onQuantityChanged.bind(this);
		this.onRequestedShipDateChanged = this.onRequestedShipDateChanged.bind(
			this
		);
		this.onRemoveClicked = this.onRemoveClicked.bind(this);
		this.getEbsPrice = this.getEbsPrice.bind(this);

		this.filterAvailableProducts = this.filterAvailableProducts.bind(this);
		this.formatProducts = this.formatProducts.bind(this);
		this.getProductVersions = this.getProductVersions.bind(this);
		this.getProductInfo = this.getProductInfo.bind(this);

		this.getSelectedProduct = this.getSelectedProduct.bind(this);
		this.getSelectedVersion = this.getSelectedVersion.bind(this);
		this.resolveSelection = this.resolveSelection.bind(this);
		this.checkRightTransfer = this.checkRightTransfer.bind(this);
	}

	onUpdateSelection(newSelection) {
		const { updateSelection } = this.props;
		let { selection } = this.state;

		selection = Object.assign({}, selection, newSelection);
		this.setState({ selection: selection });
		if (updateSelection) {
			updateSelection(selection);
		}
	}

	onResetSelection() {
		const { updateSelection } = this.props;
		this.setState({ selection: {} });

		if (updateSelection) {
			updateSelection({});
		}
	}

	userClicked(event, productId) {
		let { selectedProduct } = this.state;

		if (selectedProduct && selectedProduct.product_id === productId) {
			return;
		}

		selectedProduct = this.getSelectedProduct(null, productId);
		const versions = this.getProductVersions(selectedProduct);
		const selection = this.getProductInfo(selectedProduct);

		//LDBR-1876: Only call get price if selection has a NOA part number.
		const shouldCallEbs = selection.noaPartNumber;

		if (shouldCallEbs) {
			this.getEbsPrice(selection.productId, 1);
		}

		let isInitial = this.isInitialProductOrder(selectedProduct);
		//****************************************
		// Initial Order Min Qty: minimumOrderQty
		// Non-Initial Min Qty  : minimumBulkQty
		//*****************************************
		let minimumOrderQty = isInitial
			? selectedProduct.minimumOrderQty
			: selectedProduct.minimumBulkQty;

		if (this.quantityInput.current) {
			this.quantityInput.current.value = minimumOrderQty;
		}

		this.setState({
			selectedProduct: selectedProduct,
			selectedVersion: versions[0],
			requestedQuantity: minimumOrderQty,
			requestedShipByDate: null
		});
		this.onUpdateSelection({
			...selection,
			gameName: selectedProduct.game_name,
			gameCode: selectedProduct.game_code,
			unitPrice: '',
			version: versions[0],
			quantity: minimumOrderQty,
			shipByDate: null,
			isLoadingPrice: shouldCallEbs // Force loading state to true
		});
	}

	resetForm() {
		this.setState({
			selectedProduct: null,
			selectedVersion: null
		});
		this.onResetSelection();
	}

	onVersionChanged(event) {
		const selectedVersion = this.getSelectedVersion(
			event.currentTarget.value
		);

		this.setState({
			selectedVersion: selectedVersion
		});
		this.onUpdateSelection({ version: selectedVersion });
	}

	getEbsPrice(productId, quantity) {
		const { orderHeader } = this.props;

		this.setState({ isLoadingPrice: true, priceError: false });
		this.onUpdateSelection({ isLoadingPrice: true });
		let price;

		if (productId && quantity > 0) {
			getPrice(productId, orderHeader.orderHeaderId, quantity)
				.then((response) => {
					price = response.data.price;
					const unitPrice = parseCurrency(price);
					this.setState({
						unitPrice: unitPrice,
						isLoadingPrice: false,
					});
					this.onUpdateSelection({
						unitPrice: unitPrice,
						isLoadingPrice: false,
					});
				})
				.catch((error) => {
					toasterNotify(
						createMessageForError(error, 'retrieving prices'),
						'error',
						error,
					);
					this.setState({
						isLoadingPrice: false,
						priceError: true,
					});
					this.onUpdateSelection({ isLoadingPrice: false });
				});
		}
	}

	debouncedQuantityChanged(quantity) {
		if (quantity > 0) {
			const { productId, noaPartNumber } = this.getProductInfo();

			//LDBR-1876: Only call get price if selection has a NOA part number.
			if (noaPartNumber) {
				this.getEbsPrice(productId, quantity);
			}

			this.setState({ requestedQuantity: quantity });
			this.onUpdateSelection({ quantity: quantity });
		}
	}

	onQuantityChanged(event) {
		this.debouncedQuantityChanged(event.currentTarget.value);
	}

	onRequestedShipDateChanged(date) {
		let isoDate = null;
		date = ensureDate(date);
		if (date && date.toISOString) {
			isoDate = date.toISOString();
		} else {
			date = null;
			isoDate = null;
		}

		this.setState({ requestedShipByDate: date });
		this.onUpdateSelection({ shipByDate: isoDate });
	}

	onRemoveClicked() {
		const { id, remove } = this.props;
		if (remove) {
			remove(id);
		}
	}

	filterAvailableProducts(availableProducts, gameCodes, selectedGameCode) {
		if (
			availableProducts &&
			availableProducts.length &&
			gameCodes &&
			gameCodes.length
		) {
			let excludedCodes = gameCodes;

			if (selectedGameCode) {
				excludedCodes = gameCodes.filter((d) => {
					return d !== selectedGameCode;
				});
			}

			return availableProducts.filter((d) => {
				return excludedCodes.indexOf(d.game_code) < 0;
			});
		}

		return availableProducts;
	}

	// Create a map key: [game code] product name value: product id
	formatProducts() {
		let { availableProducts, gameCodes } = this.props;

		let selectedGameCode = this.state.selection
			? this.state.selection.gameCode
			: null;

		let filteredProducts = this.filterAvailableProducts(
			availableProducts,
			gameCodes,
			selectedGameCode
		);

		const products = new Map();

		if (filteredProducts && filteredProducts.length > 0) {
			filteredProducts.reduce(function(map, obj) {
				products.set(
					obj.product_id,
					`[${obj.game_code}] ${obj.game_name}`
				);
				return map;
			}, {});
			return products;
		}
		return [];
	}

	getProductVersions(selectedProduct) {
		if (!selectedProduct) {
			selectedProduct = this.state.selectedProduct;
		}
		let productVersions = [];
		if (
			selectedProduct &&
			selectedProduct.versions &&
			selectedProduct.versions.length > 0
		) {
			productVersions = selectedProduct.versions
				.filter((version) => {
					return !version.newer_complete_order_date;
				})
				.map((version) => {
					return {
						name: getSubmissionFullVersion(
							version.release_version,
							version.submission_version
						),
						...version
					};
				});
			productVersions = productVersions.sort((a, b) =>
				sortVersions(a.name, b.name, true)
			);
		}

		return productVersions;
	}

	isInitialProductOrder(selectedProduct) {
		const { orderHeader } = this.props;
		const { existing_orders } = selectedProduct;
		if (!existing_orders) {
			return false;
		}

		return (
			existing_orders.filter((o) => {
				return o !== orderHeader.orderHeaderId;
			}).length === 0
		);
	}

	getProductInfo(selectedProduct) {
		const { skuRequired } = this.props;

		if (selectedProduct === undefined) {
			selectedProduct = this.state.selectedProduct;
		}

		if (selectedProduct) {
			let isInitial = this.isInitialProductOrder(selectedProduct);
			let publisherPartNumber = extractPartNumbers(
				selectedProduct.part_numbers,
				'PUBLISHER'
			);
			let noaPartNumber = extractPartNumbers(
				selectedProduct.part_numbers,
				'NOA'
			);
			//****************************************
			// Initial Order Min Qty: minimumOrderQty
			// Non-Initial Min Qty  : minimumBulkQty
			//*****************************************
			let minimumOrderQty = isInitial
				? selectedProduct.minimumOrderQty
				: selectedProduct.minimumBulkQty;
			return {
				productId: selectedProduct.product_id,
				platformName: selectedProduct.platform_name,
				platformCode: selectedProduct.platform_code,
				releaseDate: ensureDate(selectedProduct.product_release_date),
				minimumOrderQty: minimumOrderQty,
				orderIncrementQty: selectedProduct.order_increment_qty,
				noaPartNumber: noaPartNumber,
				publisherPartNumber: publisherPartNumber,
				hasPartNumberError: skuRequired !== 0 && !publisherPartNumber,
				leadTimeDays: selectedProduct.lead_time_days,
				ebsAccountNumber: selectedProduct.ebs_account_number,
				isInitialProductOrder: isInitial
			};
		}
		return {};
	}

	getSelectedProduct(gameCodeString, productId) {
		const { availableProducts } = this.props;
		let selectedProduct;

		if (gameCodeString) {
			selectedProduct = availableProducts.find((product) => {
				return product.game_code.indexOf(gameCodeString) > -1;
			});
		} else if (productId > 0) {
			selectedProduct = availableProducts.find((product) => {
				return product.product_id === productId;
			});
		}

		return selectedProduct;
	}

	getSelectedVersion(inputString) {
		const submission_id = inputString ? parseInt(inputString) : null;
		if (submission_id) {
			const versions = this.getProductVersions();
			const selectedVersion = versions.find((version) => {
				return version.submission_id === submission_id;
			});
			return selectedVersion;
		}
		return null;
	}

	resolveSelection() {
		const { availableProducts } = this.props;
		const { isInitialized, selection } = this.state;

		if (
			!isInitialized &&
			availableProducts &&
			availableProducts.length > 0
		) {
			if (selection && selection.gameCode && selection.version) {
				let selectionVersion = selection.version
					? selection.version
					: this.getSelectedVersion(selection.version.submission_id);

				let selectionUnitPrice = selection.productId
					? this.getEbsPrice(selection.productId, selection.quantity)
					: '';

				this.setState({
					isInitialized: true,
					selectedProduct: this.getSelectedProduct(
						selection.gameCode
					),
					selectedVersion: selectionVersion,
					requestedQuantity: selection.quantity,
					requestedShipByDate: ensureDate(selection.shipByDate),
					unitPrice: selectionUnitPrice
				});
			} else if (selection && selection.productId && selection.quantity) {
				let selectionUnitPrice = selection.productId
					? this.getEbsPrice(selection.productId, selection.quantity)
					: '';

				const selectedProduct = this.getSelectedProduct(
					null,
					selection.productId
				);

				const versions = this.getProductVersions(selectedProduct);
				const selectedVersion = versions[0];

				this.setState({
					isInitialized: true,
					selectedProduct: selectedProduct,
					selectedVersion: versions[0],
					requestedQuantity: selection.quantity,
					requestedShipByDate: ensureDate(selection.shipByDate),
					unitPrice: selectionUnitPrice
				});

				this.onUpdateSelection({ version: selectedVersion });
			} else {
				this.setState({
					isInitialized: true
				});
			}
		}
	}

	checkRightTransfer() {
		const { selectedProduct } = this.state;
		if (selectedProduct) {
			return determineActiveRightTransfer(
				selectedProduct,
				transferDomains.ORDER
			);
		}
		return false;
	}

	componentDidMount() {
		this.resolveSelection();
	}

	componentDidUpdate() {
		this.resolveSelection();
	}

	render() {
		const { orderHeader, isEditable, leadTimeDays, availableProducts } = this.props;

		//Return map key:
		const names = this.formatProducts();
		const versions = this.getProductVersions();

		const {
			releaseDate,
			orderIncrementQty,
			publisherPartNumber,
			hasPartNumberError,
			minimumOrderQty
		} = this.getProductInfo();

		const {
			selection,
			selectedProduct,
			selectedVersion,
			requestedQuantity,
			requestedShipByDate,
			unitPrice,
			priceError,
			isInitialized,
			isLoadingPrice
		} = this.state;
		const showCardSize = !!(selectedVersion && 'card_size' in selectedVersion);
		const isEdiOrder = orderHeader.isEdiOrder;

		const selectedProductName =
			selection && selection.gameCode
				? `[${selection.gameCode}] ${selection.gameName}`
				: '';

		const activeRightTransfer = this.checkRightTransfer();

		const formattedUnitPrice = unitPrice ? formatCurrency(unitPrice).substring(1) : '';

		const formattedTotalPrice = unitPrice
			? formatCurrency(unitPrice * requestedQuantity)
			: '';

		const invalidQuantity =
			requestedQuantity !== 0 &&
			(requestedQuantity - minimumOrderQty) % orderIncrementQty !== 0;

		const noAvailableProducts = !selectedProductName && availableProducts && availableProducts.length === 0;

		const priceIsNull = selectedProductName && !unitPrice && !isLoadingPrice;

		return (
			<>
				<tr>
					<td className="col-xs-auto">
						{!isEdiOrder && names && names.size > 0 ? (
							<div className="AutoComplete">
								<AutoComplete
									suggestions={names}
									userClicked={this.userClicked}
									resetForm={this.resetForm}
									defaultValue={selectedProductName}
									placeholderText={'Search products by name'}
									noSuggestionsText={'No products found.'}
									disabled={!isEditable}
								/>
							</div>
						) : (
							<div className="AutoComplete">
								<input
									className="form-control"
									type="text"
									placeholder="Search products by name"
									value={selectedProductName}
									disabled={
										!isInitialized ||
										isEdiOrder ||
										!isEditable
									}
								/>
							</div>
						)}
					</td>
					<td className="request-version">
						{versions && versions.length > 0 ? (
							<select
								className="form-control"
								value={
									selectedVersion
										? selectedVersion.submission_id
										: ''
								}
								onChange={this.onVersionChanged}
								disabled={activeRightTransfer || !isEditable}
							>
								{versions.map((version) => {
									return (
										<option
											key={version.submission_id}
											value={version.submission_id}
										>
											{version.name}
										</option>
									);
								})}
							</select>
						) : (
							<select className="form-control" disabled />
						)}
					</td>
					{showCardSize ? (
						<td className="col-xs-1">
							<div className="request-part-sku">
									{selectedVersion.card_size}
							</div>
						</td>
					) : null}
					<td className="col-xs-1">
						{publisherPartNumber ? (
							<div className="request-part-sku">
								{publisherPartNumber}
							</div>
						) : (
							<div className="request-part-sku">
								<i>---</i>
							</div>
						)}
					</td>
					<td className="col-xs-1">
						{selectedProduct ? (
							<input
								className="form-control"
								type="number"
								size="10"
								step={orderIncrementQty}
								min={minimumOrderQty}
								ref={this.quantityInput}
								defaultValue={invalidQuantity ? minimumOrderQty : requestedQuantity}
								onChange={this.onQuantityChanged}
								disabled={
									isEdiOrder ||
									activeRightTransfer ||
									!isEditable
								}
							/>
						) : (
							<input
								className="form-control"
								type="number"
								size="10"
								disabled
							/>
						)}
						{!!orderIncrementQty && (
							<div className="ProductsSelectionRow__order-increments-info">
								<div className="help-block">
									Price/Unit: {formattedUnitPrice}
								</div>
								<div className="help-block">
									Case Quantity : {orderIncrementQty}
								</div>
								<div className="help-block">
									Minimum Quantity : {minimumOrderQty}
								</div>
							</div>
						)}
					</td>
					<td className="col-xs-2 request-date">
						{selectedProduct ? (
							<DatePicker
								className="form-control"
								dropdownMode="select"
								minDate={dayjs()
									.add(leadTimeDays, 'day')
									.toDate()}
								selected={requestedShipByDate}
								onChange={this.onRequestedShipDateChanged}
								disabled={
									isEdiOrder ||
									activeRightTransfer ||
									!isEditable
								}
								filterDate={(date) => {
									//Disable saturday and sundays
									const day = dayjs(date).day();
									return day !== 0 && day !== 6;
								}}
							/>
						) : (
							<DatePicker
								className="form-control"
								dropdownMode="select"
								minDate={releaseDate}
								selected={requestedShipByDate}
								onChange={this.onRequestedShipDateChanged}
								disabled
							/>
						)}
					</td>
					<td className="col-xs-2">
						{isLoadingPrice ? (
							<div className="loading-product-total-price" style={{marginTop: '9px'}}>
								<LoadingText />
							</div>
						) : (
							<div className="total">{formattedTotalPrice}</div>
						)}
					</td>
					<td>
						{!isEdiOrder && isEditable && (
							<Button
								variant="danger"
								size="sm"
								className="my-1 remove-row"
								type="button"
								onClick={this.onRemoveClicked}
							>
								<FAIcon className="text-white" name="times" />
							</Button>
						)}
					</td>
				</tr>
				{activeRightTransfer && (
					<tr className="line-error">
						<td colSpan="7">
							<div className="alert alert-danger">
								A request to transfer this product has been
								initiated and it cannot be ordered at this time.
							</div>
						</td>
					</tr>
				)}
				{hasPartNumberError && (
					<tr className="line-error">
						<td colSpan="7">
							<div className="alert alert-danger">
								This product requires a Part SKU # and does not
								have one set. Please set it on the product page.
							</div>
						</td>
					</tr>
				)}
				{requestedQuantity < minimumOrderQty && (
					<tr className="line-error">
						<td colSpan="7">
							<div className="alert alert-danger">
								Please enter a quantity that meets the criteria
								for the minimum amount.
							</div>
						</td>
					</tr>
				)}
				{invalidQuantity && (
					<tr className="line-error">
						<td colSpan="7">
							<div className="alert alert-danger">
								The Quantity field for this product must be an
								increment of minimum and case quantities before
								you can proceed.
							</div>
						</td>
					</tr>
				)}
				{(priceError || priceIsNull) && (
					<tr className="line-error">
						<td colSpan="7">
							<div className="alert alert-danger">
								The price for this item can't be found. Please
								contact thirdpartypublisher@noa.nintendo.com.
							</div>
						</td>
					</tr>
				)}
				{this.props.errorDisplay &&  (
					<tr className="line-error">
						<td colSpan="7">
							<div className="alert alert-danger">
								{this.props.errorDisplay}
							</div>
						</td>
					</tr>
				)}
				{noAvailableProducts &&  (
					<tr className="line-error">
						<td colSpan="7">
							<div className="alert alert-danger">
								No products are available for selection.
							</div>
						</td>
					</tr>
				)}
			</>
		);
	}
}

export { ProductsSelectionRow };
