import React, { useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';

import Forms from '../../../components/Forms/Forms';
import BaseModal from '../../../components/BaseModal/BaseModal';
import CustomFieldUpdate from '../../CustomField/CustomFieldUpdate';
import { useQuery } from 'react-query';
import { getAllCompanies } from '../../../services/companiesService';
import { CustomFieldPut, putCustomField } from '../../../services/customFieldsService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';


function formatCustomFields(customFields: CustomFieldEntryData[]) {
	const formattedCustomFields: Record<string, any> = {};
	customFields.forEach((customField) => {
		if (customField.value == null) {
			return;
		}

		if (Array.isArray(customField.value)) {
			if (customField.type.id === 'multi_select_dropdown') {
				formattedCustomFields[customField.field_definition_id] =
					customField.value.map((item: Record<string, string>) => item.ndid_company_id);
			} else if (customField.type.id === 'single_select_dropdown') {
			// To denote that single-select must return a list to the API, included
			// 'single_select_dropdown' to its key
				formattedCustomFields[customField.field_definition_id + ' single_select_dropdown'] =
					customField.value[0].ndid_company_id;
			}
		} else {
			formattedCustomFields[customField.field_definition_id] = customField.value;
			
		}
	});
	return formattedCustomFields;
}

export const EditCustomFieldsModal = ({
  	show, 
	onClose, 
	onCompletion, 
	customFields,
	entityId,
}: {
	show: boolean;
	onClose: () => void;
	onCompletion: (entityId: string) => void;
	customFields: CustomFieldEntryData[];
	entityId: string;
}) => {
	const [formValues, setFormValues] = useState<Record<string, any>>({});
	const [isSubmitting, setIsSubmitting] = useState(false);

	const sortedCustomFields = customFields ? [...customFields].sort((a, b) =>
		(a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)) : [];

	const companiesQuery = useQuery(
		'getAllCompanies:company_name',
		() => getAllCompanies({ field: 'company_name' }),
	);
	const companies = companiesQuery.data?.data;

	const formattedCustomFields: Record<string, any> = formatCustomFields(sortedCustomFields);

	useEffect(() => {
		if (show) {
			setIsSubmitting(false);
			setFormValues(formattedCustomFields);
		}
	}, [show]);

	const submitForm = async () => {
		if (isSubmitting) {
			return;
		}
		setIsSubmitting(true);

		let payload: CustomFieldPut[] = [];
		Object.entries(formValues).map((value) => {
			if (isEqual(formattedCustomFields[value[0]], value[1])) {
				return;
			}

			const key = value[0].split(' ');
			let fieldValue = value[1];

			// Ensures that select type values are arrays before passing it into payload
			if (key.length > 1 && key[1] === 'single_select_dropdown' &&
				!Array.isArray(fieldValue)) {
				fieldValue = [fieldValue];
			}

			payload.push(
				{
					'field_definition_id': parseInt(key[0]),
					'value': fieldValue,
				}
			);
		});

		try {
			await putCustomField(entityId, payload);
			onCompletion(entityId);
			onClose();
			toasterNotify(
				'Custom fields has been updated',
				'success',
			);
		} catch (error: any) {
			toasterNotify(
				createMessageForError(error, 'updating custom fields'),
				'error',
				error,
			);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<BaseModal show={show}
				   onCancel={onClose}
				   isLoading={companiesQuery.isLoading}
				   isSubmitting={isSubmitting}
		>
			<BaseModal.Title>Edit Custom Fields (Internal Only)</BaseModal.Title>
			<Forms
				values={formValues}
				onChange={(v) => setFormValues({
					...v,
				})
				}
				validationErrors={{}}
			>
				{sortedCustomFields.map((customField) => (
					<CustomFieldUpdate {...customField} companies={companies} />
				))}
			</Forms>
			<BaseModal.Submit onClick={() => submitForm()}>Update</BaseModal.Submit>
		</BaseModal>
	);
};
