import React, { PropsWithChildren, ReactElement, ReactNode, VFC, useState } from 'react';
import { Button } from 'react-bootstrap';


interface PageNavButtonProps {
	disabled: boolean;
	children: ReactNode;
	onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}
const PageNavButton: VFC<PropsWithChildren<PageNavButtonProps>> = ({
	disabled,
	children,
	onClick,
}: PageNavButtonProps): ReactElement => {
	return (
		<Button
			variant="outline-secondary"
			style={{ width: '100%' }}
			disabled={disabled}
			onClick={onClick}
		>
			{children}
		</Button>
	);
};

interface PaginationViewProps{
	canNext: boolean;
	canPrevious: boolean;
	NextComponent?: VFC,
	nextText?: string,
	page?: number,
	pages: number,
	pageSize?: number,
	pageSizeOptions?: number[],
	PreviousComponent?: VFC,
	previousText?: string,
	rowsSelectorText?: string;
	rowsText?: string,
	showPageJump?: boolean;
	showPageSizeOptions?: boolean,

	onPageChange: (page: number) => void;
	onPageSizeChange: (newPageSize: number, newPage: number) => void;
}
const PaginationView: VFC<PaginationViewProps> = (props) => {
	const {
		// Computed
		pages,
		// Props
		canNext,
		canPrevious,
		NextComponent = PageNavButton,
		nextText = 'Next',
		onPageChange,
		onPageSizeChange,
		page = 0,
		pageSize = 5,
		pageSizeOptions = [5, 10, 20, 25, 50, 100],
		PreviousComponent = PageNavButton,
		previousText = 'Previous',        
		rowsSelectorText = 'jump to page',
		rowsText = 'rows',
		showPageJump = true,
		showPageSizeOptions = true,
	} = props;


	const [pageField, setPageField] = useState<string | null>(null);

	const getSafePage = (newPage: number)  => {
		if (Number.isNaN(newPage)) { 
			return page;
		} else {
			return Math.min(Math.max(newPage, 0), (pages || 1) - 1);
		}
	};

	const changePage = (newPage: number) => {
		newPage = getSafePage(newPage);
        
		if (pageField == null || page !== Number(pageField) - 1) {
			onPageChange(newPage);
			setPageField(null);
		}
	};

	return (
		<div className="-pagination">
			<div className="-previous">
				<PreviousComponent
					onClick={() => {
						if (!canPrevious) return;
						changePage(page - 1);
					}}
					disabled={!canPrevious}
				>
					{previousText}
				</PreviousComponent>
			</div>
			<div className="-center">
				{showPageJump && (
					<span className="-pageInfo">
						Page{' '}
						<div className="-pageJump">
							<form
								onSubmit={(e) => {
									e.preventDefault();
									changePage(Number(pageField) - 1);
									return false;
								}}
							>
								<input
									aria-label="jump to page"
									type="number"
									value={pageField != null ? pageField : getSafePage(page) + 1}
									onChange={(e) => setPageField(e.target.value)}
                                    onBlur={() => {if (pageField === '') { setPageField(null); }}}
								/>
							</form>
						</div>{' '}
						of <span className="-totalPages">{pages || 1}</span>
					</span>
				)}
				{showPageSizeOptions && (
					<span className="select-wrap -pageSizeOptions">
						<select
							aria-label={rowsSelectorText}
							onChange={(e) =>  onPageSizeChange(Number(e.target.value), page)}
							value={pageSize}
						>
							{pageSizeOptions.map((option, i) => (
								// eslint-disable-next-line react/no-array-index-key
								<option key={i} value={option}>
									{`${option} ${rowsText}`}
								</option>
							))}
						</select>
					</span>
				)}
			</div>
			<div className="-next">
				<NextComponent
					onClick={() => {
						changePage(page + 1);
					}}
					disabled={!canNext}
				>
					{nextText}
				</NextComponent>
			</div>
		</div>
	);
};
export default PaginationView;
