import React, { useState, useEffect, useMemo } from 'react';
import * as yup from 'yup';

import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import { validateToSchema } from '../../../utils/validationUtils';
import { isEmptyObject } from '../../../utils/dataUtils';
import { formatAssetType } from '../../../utils/assetUtils';
import { putUpdateMarketingEventProductAsset } from '../../../services/eventsService';
import { toasterNotify } from '../../../utils/toaster';
import { formatDate, dateFormat, parseDateString, today, compareDates } from '../../../utils/dateUtils';


const EditRequiredAssetModal = ({
	show,
	onClose,
	onChange,
	assetValues,
	canEditActiveOnRequiredAssets,
	latestDueDate,
}) => {
	const [formValues, setFormValues] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showAllErrors, setShowAllErrors] = useState(false);

	// init value set
	useEffect(() => {
		if (show) {
			let myFormValues = {
				id: assetValues.id,
				active: assetValues.active,
				asset_type: assetValues.asset_type,
			};
			if (assetValues.description) {
				myFormValues.description = assetValues.description;
			}
			if (assetValues.due_date) {
				myFormValues.due_date = assetValues.due_date;
			}
			setFormValues(myFormValues);
		}
	}, [show]);

	const disabledDueDate = today() > formatDate(parseDateString(latestDueDate), dateFormat.DATE_YMD);

	const schema = useMemo(
		() =>
			yup.object().shape({
				active: yup.boolean().default(false),
				description: yup.string().default(''),
				...(!disabledDueDate
					? {
						due_date: yup
								.string()
								.nullable()
								.default(null)
								.test(
									'due_date_test',
									"Due date cannot be set after the event's end date, " +
										formatDate(parseDateString(latestDueDate), dateFormat.DATE),
									(value) =>
										value == null || new Date(value) <= new Date(latestDueDate),
								)
								.test(
									'due_date_past_test',
									'Due date cannot be in the past',
									(value) => value == null || compareDates(value, new Date(), true) >= 0,
								),
					  }
					: null),
			}),
		[latestDueDate],
	);

	const validationErrors = validateToSchema(schema, formValues);

	const submitForm = () => {
		if (isSubmitting) {
			return;
		}
		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}

		const castedFormValues = schema.cast(formValues);
		setIsSubmitting(true);

		 if (!canEditActiveOnRequiredAssets) {
			delete castedFormValues.active;
		}

		putUpdateMarketingEventProductAsset(castedFormValues.id, castedFormValues)
			.then(() => {
				onChange();
				toasterNotify('This required asset has been successfully updated', 'success');
			})
			.finally(() => {
				setIsSubmitting(false);
			});
	};

	return (
		<BaseModal show={show} onCancel={onClose} isSubmitting={isSubmitting}>
			<BaseModal.Title>Edit Required Asset</BaseModal.Title>
			<Forms
				onChange={(values) => setFormValues(values)}
				values={formValues}
				validationErrors={validationErrors}
				showAllErrors={showAllErrors}
				onFormSubmit={(e) => submitForm()}
			>
				{canEditActiveOnRequiredAssets && (
					<Forms.SingleCheckbox id="active">
						<Forms.Heading>Active</Forms.Heading>
						<Forms.Label>YES</Forms.Label>
					</Forms.SingleCheckbox>
				)}
				<Forms.BasicSelect id="asset_type" disabled={true} placeholder="Select a value">
					<Forms.Heading>Asset Type</Forms.Heading>
					<Forms.Option value="123" key="abc">
						{formatAssetType(formValues.asset_type)}
					</Forms.Option>
				</Forms.BasicSelect>

				<Forms.DateSelect
					id="due_date"
					showTimeSelect={false}
					minDate={new Date(today() + 'T00:00:00')}
					maxDate={new Date(latestDueDate)}
				>
					<Forms.Heading>Due Date</Forms.Heading>
				</Forms.DateSelect>

				<Forms.TextArea id="description" rows={2} maxLength="50">
					<Forms.Heading>Description</Forms.Heading>
				</Forms.TextArea>
			</Forms>
			<BaseModal.Submit onClick={() => submitForm()}>Save</BaseModal.Submit>
		</BaseModal>
	);
};

export default EditRequiredAssetModal;
