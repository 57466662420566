import React, { VFC, useEffect, useState } from 'react';
import trim from 'lodash/trim';
import { Alert, Col, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import * as yup from 'yup';

import AsperaFileUpload from '../../../components/AsperaFileUpload/AsperaFileUpload';
import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import { assetConst } from '../../../constants/assetConstants';
import { allowedFileExtensions, fileTransferConst } from '../../../constants/fileTransferConstants';
import { permConst } from '../../../constants/permConst';
import { useUserProfile } from '../../../hooks/reduxHooks';
import { getProductAssetTypes, postProductAsset } from '../../../services/productsService';
import { formatAssetType } from '../../../utils/assetUtils';
import { isEmptyObject } from '../../../utils/dataUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { isAuthorized } from '../../../utils/userUtils';
import { validateToSchema } from '../../../utils/validationUtils';

const schema = yup.object().shape({
	asset_type_id: yup.number().required('Asset Type must be selected'),
	// we have to use .mixed() if we need to cast the value as undefined, even when optional() is specified
	asset_type_other_name: yup.mixed()
		.when('$showAssetNameField', {
			is: true,
			then: (schema) => schema
				.test('noBlankField', 'Asset Name required for Other', (value) => value && !!trim(String(value))),
			otherwise: (schema) => schema.optional().transform(() => undefined)
		}),
	languages: yup.mixed()
		.when('$showLanguageSelect', {
			is: true,
			then:(schema) => schema
				.test('minSelectedArray', 'Asset Language(s) must be selected', (value) => Array.isArray(value) && value.length > 0),
			otherwise: (schema) => schema.optional().transform(() => undefined)
		}),
	comment: yup.string().optional(),
	files: yup.array()
		.when('$isTitleSheet', {
			is: true,
			then: yup.array()
				.max(
					1,
					'Only one file is allowed for Title Sheet, please select only one file for upload'
				)
		})
		.min(1, 'At least one file must be selected'),
});

const generateAssetTypes = (assetTypes: ProductAssetType[]) => {
	if (assetTypes && assetTypes.length > 0) {
		let newAssetTypes = [];
		newAssetTypes = assetTypes.filter(assetType => assetType.active)
			.map((type) => {
				return {
					name: formatAssetType(type.asset_type),
					value: type.asset_type,
					id: type.asset_type_id
				};
			});

		newAssetTypes.sort((a, b) => {
			return a.name.localeCompare(b.name);
		});

		return newAssetTypes;
	}
	return [];
};

const isAssetTypeConst = (
	assetTypes: ReturnType<typeof generateAssetTypes> | null,
	id: number,
	typeConst: string,
) => {
	let result: Record<string, any> = {};
	if (assetTypes && assetTypes.length > 0) {
		assetTypes.some((type) => {
			if (Number(type.id) === Number(id)) {
				result = type;
				return true;
			}
			return false;
		});

		return result.value === typeConst;
	}
	return false;
};

interface AssetUploadModalProps {
	productId?: number;
	closeModal?: () => void;
	onSubmitComplete?: () => void;
	show: boolean;
	category?: typeof assetConst.CATEGORY[keyof typeof assetConst.CATEGORY];
}
type AssetUploadModalFormValues = Partial<yup.Asserts<typeof schema>>;
const AssetUploadModal: VFC<AssetUploadModalProps> = ({ productId, onSubmitComplete, closeModal, show, category }) => {
	const userProfile = useUserProfile();
	const typesQuery = useQuery(['getProductAssetTypes', productId, category], () =>
		category && getProductAssetTypes(String(productId), category), { enabled: show && !!category });
	const isLoading = typesQuery.isLoading;
	const fault = typesQuery.isError && typesQuery.error;
	const assetTypes = typesQuery.data?.data ?
		((category === assetConst.CATEGORY.PACKAGING &&
		isAuthorized(userProfile.permissions, [permConst.PRODUCT.ASSET.CODEINBOX.ADD.COMPANY])) ?
			generateAssetTypes(typesQuery.data?.data).filter(assetType => assetType.value !== assetConst.TYPE.CODE_BOX) :
			generateAssetTypes(typesQuery.data?.data)) :
		null;

	const [formValues, setFormValues] = useState<AssetUploadModalFormValues>({});
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);
	const [selectedFiles, setSelectedFiles] = useState<AsperaFile[] | null>([]);
	const [transferSpec, setTransferSpec] = useState<{ transfer_specs: AsperaUploadSpec[] } | null>(null);

	useEffect(() => {
		if (show && !isLoading) {
			const formValues = { asset_type_id: assetTypes?.[0]?.id };
			setFormValues(formValues as AssetUploadModalFormValues);
			setShowAllErrors(false);
			setIsSubmitting(false);
			setSelectedFiles([]);
			setTransferSpec(null);
		}
	}, [show, isLoading]);

	const onComplete = () => {
		onSubmitComplete && onSubmitComplete();
		closeModal && closeModal();
		setIsSubmitting(false);
		setTransferSpec(null);
	};

	const changedFormValue = (values: AssetUploadModalFormValues, formId: string) => {
		setFormValues(values);
	};

	const selectedType = Number(formValues.asset_type_id);
	const showAssetNameField =
		!!assetTypes && !!isAssetTypeConst(assetTypes, selectedType, assetConst.TYPE.OTHER);
	const showLanguageSelect =
		category !== assetConst.CATEGORY.PACKAGING ||
		!isAssetTypeConst(assetTypes, selectedType, assetConst.TYPE.LABEL);

	const isTitleSheet = (isAssetTypeConst(assetTypes, selectedType, assetConst.TYPE.TITLE_SHEET));

	const validationErrors = show ? validateToSchema(schema, {...formValues, files: selectedFiles}, { showAssetNameField, showLanguageSelect, isTitleSheet }) : {};

	const submitForm = async () => {
		if (isSubmitting) {
			return;
		}
		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}
		setIsSubmitting(true);
		const castedValues = schema.cast(formValues, { context: { showAssetNameField, showLanguageSelect } });
		const payload = {
			...castedValues,
			files: selectedFiles,
		};
		try {
			const response = await postProductAsset(String(productId), payload);
			setTransferSpec(
				response.data.transfer_specs && { transfer_specs: response.data.transfer_specs },
			);
		} catch (error) {
			error instanceof Error && toasterNotify(
				createMessageForError(error, 'uploading asset'),
				'error',
				error,
			);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<BaseModal
			show={show}
			onCancel={closeModal}
			isLoading={isLoading}
			isSubmitting={isSubmitting}
			fault={fault}
		>
			<BaseModal.Title>
				{category === assetConst.CATEGORY.MARKETING && 'Upload New Marketing Asset'}
				{category === assetConst.CATEGORY.PACKAGING && 'Upload New Packaging Asset'}
				{category === assetConst.CATEGORY.CODE_IN_BOX && 'Upload New Code-In-Box Asset'}
			</BaseModal.Title>
			<Forms
				onChange={(v, id) => changedFormValue(v, id)}
				values={formValues}
				validationErrors={validationErrors}
				showAllErrors={showAllErrors}
			>
				<div>
					<Alert variant="info">
						<ul className="mb-0">
							{category === assetConst.CATEGORY.MARKETING && (
								<li>
									Marketing assets submitted as part of the product NCMS entry do
									not require an additional approval.
								</li>
							)}
							{category === assetConst.CATEGORY.PACKAGING && (
								<li>All product packaging must be reviewed and approved.</li>
							)}
							{category === assetConst.CATEGORY.CODE_IN_BOX && (
								<li>All Code-In-Box assets must be reviewed and approved.</li>
							)}
							<li>Please allow 7-10 calendar days for a reviews.</li>
						</ul>
					</Alert>
				</div>
				<Forms.BasicSelect id="asset_type_id">
					<Forms.Heading>Asset Type</Forms.Heading>
					{assetTypes &&
						assetTypes.map((assetType) => (
							<Forms.Option value={assetType.id} key={assetType.id}>
								{assetType.name}
							</Forms.Option>
						))}
				</Forms.BasicSelect>
				{showAssetNameField && (
					<Forms.Text id="asset_type_other_name" maxLength={150}>
						<Forms.Heading>Asset Name</Forms.Heading>
					</Forms.Text>
				)}
				{showLanguageSelect && (
					<Forms.CheckboxList id="languages">
						<Forms.Heading>Asset Language(s)</Forms.Heading>
						<Forms.Option value="en">
							English
						</Forms.Option>
						<Forms.Option value="fr">
							French
						</Forms.Option>
						<Forms.Option value="pt">
							Portuguese
						</Forms.Option>
						<Forms.Option value="es">
							Spanish
						</Forms.Option>
					</Forms.CheckboxList>
				)}
				<FormGroup id="attach_files">
					<Row>
						<FormLabel className="col-sm-4 text-right">Attach File(s)</FormLabel>
						<Col sm={6}>
							<Forms.CustomArea id="files">
								<AsperaFileUpload
									updateFilesSelected={() => null}
									userProfile={userProfile}
									entityType={fileTransferConst.ENTITY_TYPE.PRODUCT_ASSET_REVISION}
									entityId={productId}
									isUploadInitiated={!!show && !!transferSpec}
									onCompleteFunction={() => onComplete()}
									allowedFileTypes={[
										{
											filter_name: 'Accepted Files',
											extensions: category === assetConst.CATEGORY.MARKETING
												? allowedFileExtensions.MARKETING_ASSET
												: category === assetConst.CATEGORY.PACKAGING
													? allowedFileExtensions.PACKAGING_ASSET
													: allowedFileExtensions.CODE_IN_BOX_ASSET
										},
									]
									}
									validateFileType={(files: AsperaFile[]) => setSelectedFiles(files)}
									prefetchedTransferSpecs={transferSpec}
									singleFileOnly={isTitleSheet}
								/>
								{isTitleSheet && (
									<Forms.Help>Only one file is allowed for Title Sheet</Forms.Help>
								)}
							</Forms.CustomArea>
						</Col>
					</Row>
				</FormGroup>
				<Forms.TextArea id="comment" rows={4} maxLength={2000}>
					<Forms.Heading>Additional Comments</Forms.Heading>
					<Forms.Help>Optional</Forms.Help>
				</Forms.TextArea>
			</Forms>
			<BaseModal.Submit onClick={() => submitForm()}>Upload</BaseModal.Submit>
		</BaseModal>
	);
};

export default AssetUploadModal;
