import React from 'react';

import { dateFormat, formatDate } from '../../../utils/dateUtils';

const DateCell = ({ value }) => {
	if (!value) { return null; }
	return <span>{formatDate(value, dateFormat.DATE)}</span>;
};

export default DateCell;
