/* eslint-disable no-control-regex */
import React, { useEffect, useState, VFC } from 'react';
import { useQuery } from 'react-query';
import * as yup from 'yup';

import {
	getConsignees,
	getCountries,
	postConsignees,
	putConsignee,
} from '../../../services/ordersService';
import { isEmptyObject } from '../../../utils/dataUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import {
	validateEmailAddress,
	validatePhoneNumber,
	validateToSchema
} from '../../../utils/validationUtils';
import BaseModal from '../../BaseModal/BaseModal';
import Forms from '../../Forms/Forms';


const schema = yup.object().shape({
	name: yup
		.string()
		.max(60)
		.trim()
		.required('Consignee Name is required')
		.transform((value) => String(value).toUpperCase())
		.test('ascii_only', 'Consignee Name must contain only ASCII characters', (value) =>
			/^[\u0020-\u007e]*$/.test(value!),
		),
	default_flag: yup.boolean().default(false),
	street_line_1: yup
		.string()
		.trim()
		.max(100)
		.required('Address is required')
		.test('ascii_only', 'Address must contain only ASCII characters', (value) =>
			/^[\u0020-\u007e]*$/.test(value!),
		),
	street_line_2: yup
		.string()
		.trim()
		.max(100)
		.nullable()
		.test('ascii_only', 'Address must contain only ASCII characters', (value) =>
			/^[\u0020-\u007e]*$/.test(value!),
		),
	city: yup
		.string()
		.trim()
		.max(60)
		.required('City is required')
		.test(
			'ascii_only',
			'City must contain only ASCII characters',
			(value) => /^[\u0020-\u007e]*$/.test(value!),
		),
	region: yup
		.string()
		.trim()
		.max(60)
		.nullable()
		.test(
			'ascii_only',
			'Region (State) must contain only ASCII characters',
			(value) => /^[\u0020-\u007e]*$/.test(value!),
		),
	postal_code: yup
		.string()
		.trim()
		.max(20)
		.nullable()
		.test(
			'ascii_only',
			'Postal Code must contain only ASCII characters',
			(value) => /^[\u0020-\u007e]*$/.test(value!),
		),
	country_code: yup
		.string()
		.trim()
		.required('Country is required'),
	consignee_email_address: yup
		.string()
		.nullable()
		.trim()
		.required('Email Address is required')
		.test('validEmailAddress', 'Email address is invalid', (value) =>
			validateEmailAddress(value!),
		),
	consignee_phone_number: yup
		.string()
		.nullable()
		.trim()
		.required('Phone Number is required')
		.test('validPhoneNumber', 'Please only enter numbers, dash/minus ("-") or plus ("+") as a prefix. Must have 6-15 numerals.', (value) =>
			validatePhoneNumber(value!),
		),
});

interface ConsigneeModalProps {
    show: boolean;
    onClose: () => void;
    onChange: (consigneeId: number) => void;
	consigneeId?: number;
}
const ConsigneeModal: VFC<ConsigneeModalProps> = ({ show, onClose, onChange, consigneeId }) => {
	const [formValues, setFormValues] = useState<Record<string, any>>({});
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);

	const countriesQuery = useQuery('getCountries', () => getCountries(), {
		enabled: show,
		staleTime: Infinity,
	});
	const countries = [...(countriesQuery.data?.data || [])].sort((a, b) =>
		a.country_name < b.country_name ? -1 : a.country_name > b.country_name ? 1 : 0,
	);
	const consigneeQuery = useQuery('getConsignees', () => getConsignees(), {
		enabled: !!consigneeId,
		staleTime: 60000, // 1 minute
		onSettled: (response) => {
			const consignee =
				!!consigneeId && response?.data.find((c) => c.consignee_company_id === consigneeId);
			setFormValues(consignee || {});
		},
	});

	useEffect(() => {
		if (show) {
			setFormValues(
				consigneeQuery.data?.data.find((c) => c.consignee_company_id === consigneeId) || {},
			);
			setShowAllErrors(false);
		}
	}, [show]);

	const submitValues = async () => {
		if (isSubmitting) {
			return;
		}

		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}

		setIsSubmitting(true);
		const castedFormValues = schema.cast(formValues, { stripUnknown: true });
		try {
			if (consigneeId) {
				await putConsignee(String(consigneeId), castedFormValues);
				onClose();
				onChange(consigneeId);
			} else {
				const response = await postConsignees(castedFormValues);
				onClose();
				onChange(response.data);
			}

			toasterNotify(
				`Consignee "${castedFormValues.name}" has been successfully 
                	saved.`,
				'success',
			);
		} catch (error: any) {
			toasterNotify(
				createMessageForError(error, 'updating consignee information'),
				'error',
				error,
			);
		} finally {
			setIsSubmitting(false);
		}
		setShowAllErrors(false);
	};

	const validationErrors = validateToSchema(schema, formValues);

	return (
		<BaseModal
			show={show}
			isLoading={
				consigneeQuery.isLoading || consigneeQuery.isRefetching || countriesQuery.isLoading
			}
			isSubmitting={isSubmitting}
			onCancel={() => onClose()}
		>
			<BaseModal.Title>
				{consigneeId ? 'Edit Consignee' : 'Create New Consignee'}
			</BaseModal.Title>
			<Forms
				values={formValues}
				onChange={(newFormValues) =>
					setFormValues({ ...newFormValues, name: newFormValues.name?.toUpperCase() })
				}
				validationErrors={validationErrors}
				showAllErrors={showAllErrors}
			>
				<Forms.SingleCheckbox id="default_flag">
					<Forms.Heading>Set as Default</Forms.Heading>
					<Forms.Label>Yes</Forms.Label>
				</Forms.SingleCheckbox>
				<Forms.Text id="name" maxLength={60}>
					<Forms.Heading>Consignee Name</Forms.Heading>
				</Forms.Text>
				<Forms.Text id="street_line_1" maxLength={100}>
					<Forms.Heading>Address Line 1</Forms.Heading>
				</Forms.Text>
				<Forms.Text id="street_line_2" maxLength={100}>
					<Forms.Heading>Address Line 2</Forms.Heading>
				</Forms.Text>

				<Forms.Text id="city" maxLength={60}>
					<Forms.Heading>City</Forms.Heading>
				</Forms.Text>

				<Forms.Text id="region" maxLength={60}>
					<Forms.Heading>Region (State)</Forms.Heading>
				</Forms.Text>
				<Forms.Text id="postal_code" maxLength={20}>
					<Forms.Heading>Postal Code</Forms.Heading>
				</Forms.Text>
				<Forms.Select id="country_code">
					<Forms.Heading>Country</Forms.Heading>
					{countries.map((record) => (
						<Forms.Option key={record.country_code} value={record.country_code}>
							{record.country_name}
						</Forms.Option>
					))}
				</Forms.Select>
				<Forms.Text id="consignee_email_address" maxLength={100}>
					<Forms.Heading>Email Address</Forms.Heading>
				</Forms.Text>
				<Forms.Text id="consignee_phone_number" maxLength={25}>
					<Forms.Heading>Phone Number</Forms.Heading>
				</Forms.Text>
			</Forms>
			<BaseModal.Submit onClick={() => submitValues()}></BaseModal.Submit>
		</BaseModal>
	);
};

export default ConsigneeModal;
