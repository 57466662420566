import React from 'react';
import { Card } from 'react-bootstrap';

import './Panel.css';

const Panel = ({className='', children}) =>
    <Card className={`Panel ${className || ''}`}>
        {children}
    </Card>;

const Heading = ({children}) =>
    <Card.Header>{children}</Card.Header>;

const Title = ({children}) =>
    <strong>{children}</strong>;

const Body = ({children}) =>
    <Card.Body>{children}</Card.Body>;


Panel.Heading = Heading;
Panel.Title = Title;
Panel.Body = Body;

export default Panel;
