import React from 'react';

import { digitalCodesConstants } from '../../constants/digitalCodesConstants';
import { formatCurrency, numberWithCommas } from '../../utils/currencyUtils';
import { dateFormat, formatDate, now, parseDateString } from '../../utils/dateUtils';
import { getCommentByType, getExpirationDate } from '../../utils/digitalCodesUtils';
import BaseTable from '../BaseTable/BaseTable';
import LoadingText from '../Loading/LoadingText';
import PropertyDisplay from '../PropertyDisplay/PropertyDisplay';
import SectionTitle from '../SectionTitle/SectionTitle';


const {
	CODES_AVAILABLE,
	COMPLETE,
} = digitalCodesConstants.status;

const DigitalCodesOrderV1 = ({
	publisherIsLoading,
	publisher,
	purposeName,
	productsComponentsAreLoading,
	order,
	productsComponents,
	totalPrice,
	unitPrice,
	pricesAreLoading,
}) => {
	const {
		codes_downloaded_by_userName,
		codes_downloaded_date,
		codes_upload_date,
		ebsSalesOrderNum,
		order_comments,
		paymentTransID,
		paymentType,
		publisherPO,
		purposeDescription,
		quantity,
		reqActivationDate,
		reqDeliveryDate,
		status,
	} = order;
	const { game_code, game_name, componentGameName, componentID } = productsComponents;

	const rejectComment = getCommentByType(order_comments, 'REJECTION');
	const reviewerComment = getCommentByType(order_comments, 'REVIEWER');
	const publisherComments = getCommentByType(order_comments, 'PUBLISHER');

	return (
		<>
			<SectionTitle>Order Details</SectionTitle>
			<div>
				{(publisherIsLoading || publisher) && (
					<PropertyDisplay label="Publisher">
						{publisherIsLoading ? <LoadingText /> : publisher}
					</PropertyDisplay>
				)}
				<PropertyDisplay label="Sales Order Number">{ebsSalesOrderNum}</PropertyDisplay>
				<PropertyDisplay label="Purpose">{purposeName}</PropertyDisplay>
				<PropertyDisplay label="Description of Purpose">
					{purposeDescription}
				</PropertyDisplay>
				<PropertyDisplay label="Publisher PO Number">{publisherPO}</PropertyDisplay>
				{paymentType && (
					<>
						<PropertyDisplay label="Payment Type">
							{paymentType === 'PAYPAL'
								? 'PayPal'
								: paymentType === 'WIRE'
								? 'Wire Transfer'
								: paymentType}
						</PropertyDisplay>
						<PropertyDisplay label="Payment Transaction ID">
							{paymentTransID}
						</PropertyDisplay>
					</>
				)}
				<PropertyDisplay label="Requested Delivery Date">
					{formatDate(reqDeliveryDate, dateFormat.DATE)}
				</PropertyDisplay>
				<PropertyDisplay label="Requested Activation Date">
					{formatDate(reqActivationDate, dateFormat.DATE)}
				</PropertyDisplay>
				{codes_upload_date && [CODES_AVAILABLE, COMPLETE].includes(status) ? (
					<>
						{codes_downloaded_by_userName && codes_downloaded_date && (
							<PropertyDisplay label="Downloaded By">
								{`${codes_downloaded_by_userName} on ${formatDate(
									parseDateString(codes_downloaded_date),
									dateFormat.DATE,
								)}`}
							</PropertyDisplay>
						)}
						<PropertyDisplay label="Expiration Date">
							{formatDate(getExpirationDate(codes_upload_date), dateFormat.DATE)}
						</PropertyDisplay>
						<PropertyDisplay label="Days Remaining">
							{Math.max(
								parseDateString(getExpirationDate(codes_upload_date)).diff(
									now(),
									'days',
								) + 1,
								'0',
							)}
						</PropertyDisplay>
					</>
				) : null}
				<PropertyDisplay label="Order Comments">{publisherComments}</PropertyDisplay>
			</div>
			<SectionTitle>Product</SectionTitle>
			<BaseTable
				minRows={1}
				showPagination={false}
				data={[
					{
						game_name:
							game_code && game_name ? (
								`[${game_code}] ${game_name}`
							) : productsComponentsAreLoading ? (
								<LoadingText />
							) : (
								'—'
							),
						component:
							componentGameName ? (
								`[${componentID}] ${componentGameName}`
							) : productsComponentsAreLoading ? (
								<LoadingText />
							) : (
								'—'
							),
						quantity: quantity ? numberWithCommas(quantity) : '—',
						unitPrice: pricesAreLoading ? (
							<LoadingText />
						) : unitPrice ? (
							formatCurrency(unitPrice, 4) + ' / unit'
						) : (
							'—'
						),
						totalPrice: pricesAreLoading ? (
							<LoadingText />
						) : totalPrice ? (
							formatCurrency(totalPrice)
						) : (
							'—'
						),
					},
				]}
				columns={[
					{ Header: 'Product', accessor: 'game_name' },
					{ Header: 'Component', accessor: 'component' },
					{ Header: 'Quantity', accessor: 'quantity' },
					{ Header: 'Est. Price', accessor: 'unitPrice' },
					{ Header: 'Est. Total Price', accessor: 'totalPrice' },
				]}
				allowOverflow
			/>
			{(rejectComment || reviewerComment ) && (
				<>
					<SectionTitle>Additional Comments</SectionTitle>
					{rejectComment && (
						<PropertyDisplay label="Comments on Rejection">
							{rejectComment}
						</PropertyDisplay>
					)}
					{reviewerComment && (
						<PropertyDisplay label="Comments to PO Approver">
							{reviewerComment}
						</PropertyDisplay>
					)}
				</>
			)}
		</>
	);
};
export default DigitalCodesOrderV1;
