import React, { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Alert } from 'react-bootstrap';
import * as yup from 'yup';

import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import { validateToSchema } from '../../../utils/validationUtils';
import { isEmptyObject } from '../../../utils/dataUtils';
import { postOrderReject } from '../../../services/digitalCodesService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';

const RejectOrderModal = ({show, order, onClose}) => {
	const [formValues, setFormValues] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showAllErrors, setShowAllErrors] = useState(false);
	const queryClient = useQueryClient();

	useEffect(() => {
		if (show) {
			setFormValues({});
			setIsSubmitting(false);
			setShowAllErrors(false);
		}
	}, [show]);

	const schema = useMemo(
		() =>
			yup.object().shape({
				reason: yup.string().trim().required('A reason for the rejection must be given')
			}),
		[],
	);

	const validationErrors = validateToSchema(schema, formValues);

	return (
		<BaseModal
			isSubmitting={isSubmitting}
			onCancel={() => onClose()}
			closeText="Cancel"
			show={show}
		>
			<BaseModal.Title>
				<span className="text-danger">Reject Digital Order?</span>
			</BaseModal.Title>
			<Alert variant='danger'>
				Please confirm that you want to reject the order. The operation cannot be undone.
			</Alert>
			<Forms
				vertical
				onChange={(values) => setFormValues(values)}
				values={formValues}
				validationErrors={validationErrors}
				showAllErrors={showAllErrors}
			>
				<Forms.TextArea id="reason" rows={4} maxLength={2000}>
					<Forms.Heading>Reason (required)</Forms.Heading>
				</Forms.TextArea>
			</Forms>

			<BaseModal.Submit variant='danger' onClick={async () => {
				try {
					if (!isEmptyObject(validationErrors)) {
						setShowAllErrors(true);
					} else {
						setIsSubmitting(true);
						await postOrderReject(order.orderID, {
							rejectionReason: formValues.reason,
							publisherId: order.NDID,
						});
						toasterNotify('The order has been rejected', 'success');
						onClose();
					}
				} catch (error) {
					toasterNotify(createMessageForError(error, 'submitting the rejection'), 'error', error);
					setIsSubmitting(false);
				} finally {
					queryClient.invalidateQueries('getDigitalOrder', { refetchInactive: true });
					queryClient.invalidateQueries('getDigitalOrders', { refetchInactive: true });
				}
			}}>
				Submit Rejection
			</BaseModal.Submit>
		</BaseModal>
	);
};
export default RejectOrderModal;
