import React, { Component } from 'react';
import Loading from '../../components/Loading/Loading';
import { clearReduxStorage } from '../../utils/reduxUtils';
import { logout } from '../../services/usersService';

export default class Logout extends Component {
	userLogout() {
		let redirect;
		logout()
			.then((response) => {
				redirect = response.data;
			})
			.catch((e) => {
				redirect = '/';
			})
			.finally(() => {
				clearReduxStorage();
				window.location.href = redirect;
			});
	}

	componentDidMount() {
		this.userLogout();
	}

	render() {
		return <div className="d-flex vw-100 vh-100"><Loading /></div>;
	}
}
