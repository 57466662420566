import cookies from 'js-cookie';

import { getStore } from '../store/store';
import { getLocation } from './routeUtils';

const FF_COOKIE = 'ff';
const COOKIE_DOMAIN = /^localhost/.test(getLocation().hostname) ? getLocation().hostname : '.'+ getLocation().hostname;

const defaultSchema = [
	{
		name: 'me-dev',
		description: 'Marketing events',
		default_on: true,
	},
	{
		name: 'new-table-component',
		description: 'Enable new table component',
	},
];

const wrap = (value) => Array.isArray(value) ? value : [value];

const digestParameters = ({history}) => {
	const params = new URLSearchParams(history.location.search);
	const clear = params.has('clearflags');
	const on = !clear && params.has('on') ? params.get('on').split(',') : [];
	const off = (!clear && params.has('off') ? params.get('off').split(',') : []).filter(flag => !on.includes(flag));
	if (clear || on.length || off.length) {
		params.delete('on');
		params.delete('off');
		params.delete('clearflags');
		history.replace({ pathname: history.location.pathname, search: params.toString() });
	}
	return [on, off, clear];
};

export const getCookieData = () => {
	const flags = cookies.get(FF_COOKIE);
	if (flags) {
		return flags.split(',').map(pair => {
			const [name, value] = pair.split('=', 2);
			return {name, status: value === '1', saveToCookie: true};
		}).reduce((reduction, entry) => {
			if (entry) {
				reduction[entry.name] = entry;
			}
			return reduction;
		}, {});
	}
	return null;
};

function defaultCriteria(criteria, userProfile) {
	if (!criteria) {
		return false;
	}
	const envTest = !criteria.env ||
		wrap(criteria.env).includes(global.config.authEnv);
	const companyTest = !criteria.company_id ||
		(userProfile && wrap(criteria.company_id).includes(userProfile.companyId));
	const userTest = !criteria.user_id ||
		(userProfile && wrap(criteria.user_id).includes(userProfile.userId));
	const permissionsTest = !criteria.permissions ||
		(userProfile && userProfile.permissions.find(p => wrap(criteria.permissions).includes(p)));

	return !!(envTest && companyTest && userTest && permissionsTest);
}

export function initFeatureFlags({userProfile, history, schema=defaultSchema}) {
	const [on, off, clearCookies] = digestParameters({history});
	

	let overrides;
	if (clearCookies) {
		overrides = null;
		cookies.remove(FF_COOKIE, { path: '/', domain: COOKIE_DOMAIN});
	} else {
		const cookieHash = getCookieData();

		if (cookieHash || on.length + off.length > 0) {
			overrides = schema.reduce((reduction, exp) => {
				// always_on will always disqualify overrides
				if (exp.always_on === true) {
					return reduction;
				}

				const name = exp.name;
				if (on.includes(name) || off.includes(name) || (cookieHash && name in cookieHash)) {
					const status = !off.includes(name) && (on.includes(name) || (cookieHash && cookieHash[name].status));
					reduction[name] = !!status;
				}
				return reduction;
			}, {});
			const newCookie = Object.keys(overrides).reduce((reduction, key) => {
				return reduction +
					(reduction === '' ? '' : ',') +
					key +
					(overrides[key] ? '=1' : '=0');

			}, '');

			if (newCookie) {
				cookies.set(FF_COOKIE, newCookie, { path: '/', domain: COOKIE_DOMAIN, expires: 7, sameSite: 'Strict'});
			} else {
				cookies.remove(FF_COOKIE, { path: '/', domain: COOKIE_DOMAIN});
			}
		}
	}

	const flagStatus = schema.map(exp => {
		const expname = exp.name;
		const description = exp.description;
		// check the always_on flag
		const alwaysOn = !!exp.always_on;

		const defaultOn = !!(alwaysOn || exp.default_on || defaultCriteria(exp.default_criteria, userProfile));

		// check default_criteria
		const override = (overrides && expname in overrides) ? !!overrides[expname] : null;

		return {name: expname, description, alwaysOn, defaultOn, override};
	});

	return {status: flagStatus};
}

export function refreshOverrideCookie(flags) {
	if (flags.find(flag => flag.override !== null)) {
		const newCookie = flags.reduce((reduction, flag) => {
			if (flag.override !== null){
				return reduction +
					(reduction === '' ? '' : ',') +
					flag.name +
					(flag.override ? '=1' : '=0');
			}
			return reduction;
		}, '');
		cookies.set(FF_COOKIE, newCookie, { path: '/', domain: COOKIE_DOMAIN, expires: 7, sameSite: 'Strict'});
	} else {
		// remove the cookies
		cookies.remove(FF_COOKIE, { path: '/', domain: COOKIE_DOMAIN});
	}
}

export function isFeatureFlagEnabled(flagName, flagSchema) {
	if (!flagName) {
		throw new Error('Must specify a flag name');
	}
	if (!flagSchema) {
		const state = getStore().getState();
		flagSchema = state.featureFlagReducer.flags.status;
	}
	const flag = flagSchema?.find(flag => flag.name === flagName);
	if (!flag) {
		return false;
	}
	if (flag.alwaysOn){
		return true;
	}
	if (flag.override != null) {
		return flag.override;
	}
	return flag.defaultOn;
}
