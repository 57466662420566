import React from 'react';

import CustomFieldEntry from '../../../components/CustomField/CustomFieldEntry';
import PropertyDisplay from '../../../components/PropertyDisplay/PropertyDisplay';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { permConst } from '../../../constants/permConst';
import { useUserProfile } from '../../../hooks/reduxHooks';
import { isAuthorized } from '../../../utils/userUtils';
import { filterInternalOnly } from '../CompanyProfile.helpers';
import RenderAdditionalInfo from './RenderAdditionalInfo';
import RenderCompanyInfo from './RenderCompanyInfo';

const CompanyInfoTab = ({ companyData, customFields }) => {
	const userProfile = useUserProfile();
	const canViewCustomFields = isAuthorized(userProfile.permissions,
		[permConst.CUSTOM_FIELD.VIEW.ALL]);
	const sortedCustomFields = customFields ? [...customFields].sort((a, b) =>
		(a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)) : [];

	return (
		<>
			<SectionTitle>Company Information</SectionTitle>
			<RenderCompanyInfo companyData={companyData} />

			<SectionTitle>Additional</SectionTitle>
			<RenderAdditionalInfo companyData={companyData} userProfile={userProfile} />

			{companyData?.company_information &&
				('consignee_email_address' in companyData.company_information ||
					'consignee_phone_number' in companyData.company_information) && (
				<>
						<SectionTitle>Consignee Contact</SectionTitle>
						<PropertyDisplay label="Email Address">
							{companyData.company_information.consignee_email_address || (
								<>&mdash;</>
							)}
						</PropertyDisplay>
						<PropertyDisplay label="Phone Number">
							{companyData.company_information.consignee_phone_number || <>&mdash;</>}
						</PropertyDisplay>
				</>
			)}
			{filterInternalOnly(companyData, userProfile, 'internal-notes')}
			{canViewCustomFields && (
				<SectionTitle>
					Custom Fields <span className="text-danger">(Internal Only)</span>
				</SectionTitle>
			)}
			{canViewCustomFields &&
				sortedCustomFields?.map((customField) => <CustomFieldEntry {...customField} />)}
		</>
	);
};
export default CompanyInfoTab;
