import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Navbar, { NavbarDropdownMenu } from './Navbar';
import ActionLink from '../../ActionLink/ActionLink';
import { redirectToLogin } from '../../../utils/routeUtils';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loggedIn: false
		};
	}

	renderLoggedInNav(userProfile, featureFlagStatus) {
		return (
			<nav className="navbar navbar-expand navbar-dark navbar-red flex-wrap">
				<Link to="/" className="navbar-brand nin-logo">
					Publisher Tool
				</Link>
				<Navbar featureFlagStatus={featureFlagStatus}/>
				<div className='d-flex flex-grow-1 justify-content-end'>
					<ul className="navbar-nav">
						<NavbarDropdownMenu
							name={<><strong>{userProfile.fullName},</strong> {userProfile.companyName}</>
							}
							right
						>
							<li>
								<Link className="dropdown-item" to="/logout">Log Out</Link>
							</li>
						</NavbarDropdownMenu>
					</ul>
				</div>
			</nav>
		);
	}

	renderLoggedOutNav() {
		return (
			<nav className="navbar navbar-expand navbar-dark navbar-red">
				<Link to="/" className="navbar-brand nin-logo mr-auto">
					Publisher Tool
				</Link>
				<div className="navbar-nav">
					<ActionLink className="nav-link" onClick={e => redirectToLogin(false)}>Sign In</ActionLink>
				</div>

			</nav>
		);
	}

	render() {
		const { userProfile, featureFlagStatus } = this.props;

		return (
			<header>
				{userProfile
					? this.renderLoggedInNav(userProfile, featureFlagStatus)
					: this.renderLoggedOutNav()}
			</header>
		);
	}
}

function mapStateToProps(state, prop) {
	return {
		userProfile: state.authReducer.userProfile,
		featureFlagStatus: state.featureFlagReducer && state.featureFlagReducer.flags.status
	};
}

export default connect(mapStateToProps)(Header);
