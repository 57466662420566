import React, { Fragment, VFC } from 'react';

import { useQuery } from 'react-query';
import { getLabelHistory } from '../../../services/productsService';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import LogEntry from '../../../components/LogEntry/LogEntry';
import Loading from '../../../components/Loading/Loading';
import { timeZoneConstants } from '../../../constants/timeZoneConstants';
import { assetConst } from '../../../constants/assetConstants';
import StatusText from '../../../components/StatusText/StatusText';
import { compareDates, dateFormat, formatDate } from '../../../utils/dateUtils';
import FileLink from '../../../components/FileLink/FileLink';
import { Alert, Col } from 'react-bootstrap';
import PropertyDisplay from '../../../components/PropertyDisplay/PropertyDisplay';

interface LabelHistoryTabProps {
	isSelected?: boolean;
	productId: number;
}
const LabelHistoryTab: VFC<LabelHistoryTabProps> = ({ isSelected, productId }) => {
	const labelHistoryQuery = useQuery(
		['getLabelHistory', productId],
		() => getLabelHistory(String(productId)),
		{ enabled: isSelected },
	);
	const labelHistory = labelHistoryQuery.data?.data.product_asset_revisions;
	const isLoading = labelHistoryQuery.isLoading;
	const labelsByVersion = labelHistory?.reduce((reduction, item) => {
		if (!reduction[item.version]) {
			reduction[item.version] = [];
		}
		reduction[item.version].push(item);
		return reduction;
	}, [] as (typeof labelHistory)[]).reverse();
	labelsByVersion?.forEach((group) => {
		group.sort((a, b) => {
			return compareDates(b.submitted_date, a.submitted_date);
		});
	});
	return (
		<>
			{isLoading ? (
				<Loading />
			) : !(labelHistoryQuery.data?.data.ncl_status) ? (
				<Alert variant="warning">No history for label assets available for this product</Alert>
			) : (
				<>
					<SectionTitle>Current Version</SectionTitle>
					<div>
						<span/>
						<PropertyDisplay label="Status">
							<StatusText>{labelHistoryQuery.data?.data.ncl_status}</StatusText>
						</PropertyDisplay>
						<PropertyDisplay label="Version">
							<StatusText>{labelHistoryQuery.data?.data.current_version}</StatusText>
						</PropertyDisplay>
						<PropertyDisplay label="Forgo Status">
							<StatusText>{labelHistoryQuery.data?.data.current_forgo_status}</StatusText>
						</PropertyDisplay>
					</div>
					{labelsByVersion?.map((group, index) => {
						if (!group[0]) {
							return null;
						}
						return (
							<Fragment key={'group' + index}>
								<SectionTitle>Version {group[0].version}</SectionTitle>
								<Col sm={12} md={9}>
									{group.map((revision, i) => {
										const hasStatus = !!revision.review_status;
										const hasForgoStatus =
											!!revision.final_label_forgo_status &&
											revision.final_label_forgo_status !==
												assetConst.FINAL_LABEL_FORGO_STATUS
													.FORGO_NOT_REQUESTED;

										let statusActivity = '';
										let forgoActivity = '';
										if (revision.submitted_by_name) {
											if (hasStatus) {
												statusActivity = ' has changed the status to ';
											}
											if (hasStatus && hasForgoStatus) {
												forgoActivity =
													' and has set the forgo final label status to ';
											} else if (hasForgoStatus) {
												forgoActivity =
													' has set the forgo final label status to ';
											}
										} else {
											if (hasStatus) {
												statusActivity = 'Status changed to ';
											}
											if (hasStatus && hasForgoStatus) {
												forgoActivity =
													' and forgo final label status set to ';
											} else if (hasForgoStatus) {
												forgoActivity = 'Forgo final label status set to ';
											}
										}

										return (
											<Fragment key={'entry' + i}>
												<LogEntry>
													<LogEntry.Heading
														timestamp={revision.submitted_date}
														dateFormat={dateFormat.DATETIME_JP}
														timezone={timeZoneConstants.JAPAN}
													>
														<strong>
															{revision.submitted_by_name}
														</strong>
														{statusActivity}
														{hasStatus && (
															<StatusText badge>
																{revision.review_status}
															</StatusText>
														)}
														{forgoActivity}
														{hasForgoStatus && (
															<StatusText badge>
																{revision.final_label_forgo_status}
															</StatusText>
														)}
													</LogEntry.Heading>
													{!!(revision.files.length > 0) && (
														<LogEntry.List>
															{revision.files.map((file, i) => {
																return file ? (
																	<li key={'log' + i}>
																		<FileLink
																			fileName={
																				file.file_name
																			}
																			fileId={file.file_id}
																			status={file.status}
																			saved={/(mp4|mov|flv|wmv|avi)$/.test(
																				file.file_name,
																			)}
																			displayIcon
																		>
																			{file.file_name}
																		</FileLink>
																	</li>
																) : null;
															})}
														</LogEntry.List>
													)}
													{revision.anticipated_proof_date && (
														<>
															<LogEntry.Comment>
																Anticipated proof date:{' '}
																{formatDate(
																	revision.anticipated_proof_date,
																	dateFormat.DATE_JP,
																)}
															</LogEntry.Comment>
														</>
													)}
													{revision.comment && (
														<LogEntry.Comment>
															{revision.comment}
														</LogEntry.Comment>
													)}
												</LogEntry>
											</Fragment>
										);
									})}
								</Col>
							</Fragment>
						);
					})}
				</>
			)}
		</>
	);
};

export default LabelHistoryTab;
