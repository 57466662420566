import { assetConst } from '../../constants/assetConstants';
import { companyAgreementConstants } from '../../constants/companyAgreementConstants';
import { permConst } from '../../constants/permConst';
import { transferRightsConstants } from '../../constants/transferRightsConstants';
import { companyHasRight } from '../../utils/companyUtils';
import { isAuthorized, isFeatureActiveForPlatform } from '../../utils/userUtils';


export function canShowProjections(userProfile, summary) {
	const { platforms } = this.props;
	const { companyProfile } = this.state;

	// hide if product's platform is not projections enabled
	const platform = platforms.find((p) => p.platform_code === summary.platform_code);
	if (!platform || !platform.projections_enabled_flag) {
		return false;
	}

	// hide if digital product
	if (summary.product_distribution_type === companyAgreementConstants.TYPE.DIGITAL) {
		return false;
	}

	// hide if user does not have authorization
	if (!isAuthorized(userProfile.permissions, [permConst.PRODUCT.VIEW.DETAIL.PROJECTIONS])) {
		return false;
	}

	// hide if user is not internal AND user's company cannot make projections
	const isInternal = isAuthorized(userProfile.permissions, [
		permConst.PROJECTION.VIEW.ALL
	]);
	const canMakeProjections =
        companyProfile.company_information &&
        companyProfile.company_information.projection_eligible_flag === 1;
	if (!isInternal && !canMakeProjections) {
		return false;
	}

	// show if user is authorized with product.view.all.third perm
	let companyCheck = true;
	if (!isAuthorized(userProfile.permissions, [permConst.PRODUCT.VIEW.ALL.THIRD])) {
		// show if retail dist company for product matches user's company
		const retailDistributionCompany = summary.distribution.find(
            (distribution) => {
            	return distribution.right_type ===
                    transferRightsConstants.DISTRIBUTION_TYPE.RETAIL;
            }
		);
		companyCheck =
            retailDistributionCompany &&
            userProfile.companyName ===
                retailDistributionCompany.company_name;
	}

	return companyCheck;
}

export function canShowCodeInBoxAssets(userProfile, summary) {
	return !!summary && (
		!['WUP', 'RVL', 'CTR', 'KTR'].includes(summary.platform_code) &&
		(isAuthorized(userProfile.permissions, [permConst.PRODUCT.ASSET.CODEINBOX.VIEW.ALL])
			||
			(isAuthorized(userProfile.permissions, [permConst.PRODUCT.ASSET.CODEINBOX.VIEW.COMPANY]) &&
				(companyHasRight(userProfile.companyId, summary.distribution, transferRightsConstants.DISTRIBUTION_TYPE.DIGITAL) ||
					companyHasRight(userProfile.companyId, summary.distribution, transferRightsConstants.DISTRIBUTION_TYPE.OWNERSHIP))
			)
		)
	);
}
export function canShowPackagingAssets(userProfile, product) {
	const isPhysical =
		product &&
		product.distribution.find(
			(o) =>
				o.right_type ===
				transferRightsConstants.DISTRIBUTION_TYPE.RETAIL
		);
	const isExternal = isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.ASSET.PACKAGING.VIEW.COMPANY
	]);
	const isInternal = isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.ASSET.PACKAGING.VIEW.ALL
	]);

	return isPhysical && (isInternal || isExternal);
}

export function canShowMarketingAssets(userProfile, product) {
	const isProductOwner =
		product &&
		product.eligible_transfer_flags &&
		(product.eligible_transfer_flags.has_ownership ||
			product.eligible_transfer_flags.has_retail ||
			product.eligible_transfer_flags.has_digital);
	const isExternal = isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.ASSET.MARKETING.VIEW.COMPANY
	]);
	const isInternal = isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.ASSET.MARKETING.VIEW.ALL
	]);

	return isInternal || (isProductOwner && isExternal);
}


export function canAddAssets(userProfile, product, category) {
	if (userProfile && product && category) {
		switch(category) {
			case assetConst.CATEGORY.MARKETING:
				return canAddMarketingAssets(userProfile, product);
			case assetConst.CATEGORY.PACKAGING:
				return canAddPackagingAssets(userProfile, product);
			case assetConst.CATEGORY.CODE_IN_BOX:
				return canAddCodeInBoxAssets(userProfile, product);
			default:
		}

	}
	return false;
}

function canAddMarketingAssets(userProfile, product) {
	return (
		isFeatureActiveForPlatform(userProfile, 'marketing_assets', product.platform_code) &&
		isAuthorized(userProfile.permissions, [permConst.PRODUCT.ASSET.MARKETING.ADD.COMPANY])
	);
}

function canAddPackagingAssets(userProfile, product) {
	return (
		isFeatureActiveForPlatform(userProfile, 'packaging_assets', product.platform_code) &&
		isAuthorized(userProfile.permissions, [permConst.PRODUCT.ASSET.PACKAGING.ADD.COMPANY]) &&
		companyHasRight(
			userProfile.companyId,
			product.distribution,
			transferRightsConstants.DISTRIBUTION_TYPE.RETAIL,
		)
	);
}

function canAddCodeInBoxAssets(userProfile, product) {
	return (
		!['WUP', 'RVL', 'CTR', 'KTR'].includes(product.platform_code) &&
		isAuthorized(userProfile.permissions, [permConst.PRODUCT.ASSET.CODEINBOX.ADD.COMPANY]) &&
		companyHasRight(
			userProfile.companyId,
			product.distribution,
			transferRightsConstants.DISTRIBUTION_TYPE.DIGITAL,
		)
	);
}

export function canShowPhysicalOrderChecklist(userProfile, summary) {
	// hide if digital product
	if (summary.product_distribution_type === companyAgreementConstants.TYPE.DIGITAL) {
		return false;
	}

	return isFeatureActiveForPlatform(userProfile, 'physical_order', summary.platform_code);
}
