import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { linkColumn } from '../../components/BaseTable/BaseTable';
import FAIcon from '../../components/FAIcon/FAIcon';
import FilterableTable from '../../components/FilterableTable/FilterableTable';
import Loading from '../../components/Loading/Loading';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import { getCompanies } from '../../services/companiesService';
import {
	generateOffsetBatches,
	paginationLimits
} from '../../utils/paginationUtil';
import { displayString } from '../../utils/stringUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';


function mapStateToProps(state) {
	return {
		userProfile: state.authReducer.userProfile
	};
}

function getFilterProperties() {
	let filterProperties = new Map();

	filterProperties.set('Status', {
		filter: 'company_status_code',
		selectableFilters: new Set()
	});
	filterProperties
		.get('Status')
		.selectableFilters.add(displayString('ACTIVE'));
	filterProperties
		.get('Status')
		.selectableFilters.add(displayString('PENDING'));

	return filterProperties;
}

const searchableFields = ['company_name', 'alternate_company_name'];
const searchableFieldPlaceHolder = 'Search by Company Name or DBA';
const title = 'Publisher Management';

export class PublisherManagement extends Component {
	constructor(props) {
		super(props);
		let dataFormat = this.getBasicTableData();

		this.state = {
			dataFormat,
			searchableFields,
			searchableFieldPlaceHolder,
			title,
			filterProperties: getFilterProperties(),
			isLoading: true,
			companyCount: false,
			companies: []
		};

		this.getCompanyData = this.getCompanyData.bind(this);
		this.saveTotalCompanyCount = this.saveTotalCompanyCount.bind(this);
		this.appendCompaniesToState = this.appendCompaniesToState.bind(this);
	}

	async componentDidMount() {
		await this.getCompanyData('count=true', this.saveTotalCompanyCount);
		this.batchGetCompanyCalls();
	}

	getBasicTableData() {
		const chevronLink = ({original}) => {
			return <Link to={`/admin/publishers/${original.ndid_company_id}`}><FAIcon name="chevron-right" /></Link>;
		};
		return [
			{ Header: 'Company', accessor: 'company_name' },
			{ Header: 'DBA', accessor: 'alternate_company_name' },
			{ Header: 'Maker Code', accessor: 'maker_code' },
			{ Header: 'BMS Code', accessor: 'global_bms_code' },
			{
				Header: 'Status',
				accessor: 'company_status_code',
				width: 100,
				Cell: ({value}) =>
					<strong className={value === 'ACTIVE' ? 'text-success' : 'text-danger'}>{value}</strong>
			},
			{
				...linkColumn,
				Cell: chevronLink
			}
		];
	}

	getCompanyData(q_string, callback) {
		return getCompanies(q_string)
			.then((response) => {
				if (response.status === 200) {
					callback(response.data);
				}
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'loading products'),
					'error',
					error
				);
			});
	}

	saveTotalCompanyCount(data) {
		this.setState({
			companyCount: data.total
		});
	}

	appendCompaniesToState(data) {
		// TODO: Use an updater function as first parameter for setState. This setup
		// risks losing data if setState does not immediately change the state.
		// See: https://reactjs.org/docs/react-component.html#setstate
		let currentCompanies = this.state.companies;
		this.setState({
			companies: currentCompanies.concat(data)
		});
	}

	batchGetCompanyCalls() {
		const { companyCount } = this.state;
		const limit = paginationLimits.companyLimit;
		let batches = generateOffsetBatches(companyCount, limit);
		batches.forEach((offset) => {
			let q_string = `limit=${limit}&offset=${offset}`;
			this.getCompanyData(q_string, this.appendCompaniesToState);
		});
	}

	render() {
		const {
			dataFormat,
			searchableFields,
			searchableFieldPlaceHolder,
			title,
			filterProperties,
			companies,
			companyCount
		} = this.state;

		let isLoading = !companyCount || companyCount !== companies.length;

		return (
			<Page>
				<div>
					<Title title={title} />
					{isLoading ? (
						<Loading />
					) : (
						<FilterableTable
							data={companies}
							dataFormat={dataFormat}
							filterProperties={filterProperties}
							searchableFields={searchableFields}
							searchableFieldPlaceHolder={
								searchableFieldPlaceHolder
							}
							defaultSorted={[
								{
									id: 'company_name',
									desc: false
								}
							]}
							retainPageState
						/>
					)}
				</div>
			</Page>
		);
	}
}

export default connect(mapStateToProps)(PublisherManagement);
