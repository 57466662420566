import React, { useEffect, useState } from 'react';
import * as yup from 'yup';

import { eventConstants } from '../../../../constants/eventConstants';
import { accessTypeConst } from '../../../../constants/permConst';
import { postEventTask } from '../../../../services/eventsService';
import { removeDuplicates } from '../../../../utils/dataUtils';
import { toasterNotify } from '../../../../utils/toaster';
import {
	useCompanyNamesBulkQuery,
	useEventDetailQuery,
	useEventProductsQuery,
	useUserNamesBulkQuery
} from '../EventTaskModel.hooks';
import EventTaskModalView, { NONE, WRITE } from '../views/EventTaskModalView';


const { INTERNAL, EXTERNAL } = eventConstants.TASKS.TYPE;
const { OPEN } = eventConstants.TASKS.STATUS;

export const schema = yup.object().shape({
	active: yup.boolean().default(false),
	status: yup.string()
		.default(OPEN).required(),
	assignment_type: yup
		.string()
		.default('')
		.required('Type must be supplied'),
	name: yup
		.string()
		.required('Name must be supplied'),
	comment: yup.string().default(''),
	due_date: yup
		.string()
		.nullable()
		.default(undefined)
		.transform((value, originalValue) => !value ? null : originalValue)
		.when('active', {
			is: true,
			then: yup.string()
				.nullable()
				.required('Due date must be supplied if task is active')
		}),
	assigned_ndid_company_id: yup.mixed()
		.nullable()
		.when(['assignment_type'], {
			is: (assignment_type) => assignment_type === eventConstants.TASKS.TYPE.EXTERNAL,
			then: yup.string()
				.when(['active'], {
					is: true,
					then: (schema) => schema
						.required('Publisher assignee must be supplied if task is active'),
					otherwise:(schema) => schema
						.default('')
						.nullable()
						.transform((value, originalValue) => !value ? '' : originalValue),
				}),
			otherwise: (schema) => schema.transform(() => undefined),
		}),
	assigned_ndid_user_id: yup.mixed()
		.nullable()
		.when(['assignment_type'], {
			is: (assignment_type) => assignment_type === eventConstants.TASKS.TYPE.INTERNAL,
			then: yup.string()
				.default('')
				.nullable()
				.transform((value, originalValue) => !value ? '' : originalValue),
			otherwise: (schema) => schema.transform(() => undefined),
		}),
});

const initialValues = {
	active: false,
	status: OPEN,
	assignment_type: '',
	name: '',
	comment: '',
	assigned_ndid_company_id: null,
	assigned_ndid_user_id: null,
	due_date: ''
};

const getFieldPermissions = (formValues) => ({
	active: WRITE,
	status: WRITE,
	assignment_type: WRITE,
	name: WRITE,
	comment: WRITE,
	assigned_ndid_company_id: formValues.assignment_type === EXTERNAL ? WRITE : NONE,
	assigned_ndid_user_id: formValues.assignment_type === INTERNAL ? WRITE : NONE,
	due_date: WRITE,
});

const AddContainer = ({ show, onClose, onChange, eventId }) => {
	const [isSubmitting, setIsSubmitting] = useState(false);

	const userIds = [];
	const companyIds = [];

	const eventQuery = useEventDetailQuery(eventId, { enabled: !!show, onSuccess: (data) => {
		if (data.headers['x-pdb-access-type'] === accessTypeConst.MANAGER) {
			if (data.data.active) {
				productsQuery.refetch();
			}
		}
	} });
	const event = eventQuery.isSuccess && eventQuery.data.data;

	// add managers to user id lookup
	if (event && event.managers) {
		userIds.push(...event.managers);
	}

	const productsQuery = useEventProductsQuery(eventId, {
		enabled: !!(show && eventQuery.isSuccess && event.active),
	});
	const products =
		productsQuery.isSuccess && !productsQuery.isFetching && productsQuery.data.data;
	const productsToken =
		productsQuery.isSuccess &&
		!productsQuery.isFetching &&
		(Date.now() - productsQuery.dataUpdatedAt < 60000) && // token expires in 1 min
		productsQuery.data.headers['x-pdb-authorization'];

	if (productsQuery.isSuccess && products) {
		products.forEach(product => {
			if (product.active) {
				userIds.push(...product.coordinators);
				companyIds.push(...product.companies);
			}
		});
	}
	const okToLoadUsers = !!(show && !eventQuery.isLoading && !productsQuery.isLoading && userIds.length > 0);
	const okToLoadCompanies = !!(show && !eventQuery.isLoading && !productsQuery.isLoading && productsToken && companyIds.length > 0);

	const userNameQuery = useUserNamesBulkQuery(removeDuplicates(userIds), null, { enabled: okToLoadUsers });
	const companyNameQuery = useCompanyNamesBulkQuery(removeDuplicates(companyIds), null, { enabled: okToLoadCompanies });

	const users = (userNameQuery.isSuccess && userNameQuery.data.data) || [];
	const companies = (companyNameQuery.isSuccess && companyNameQuery.data.data) || [];


	const isLoading = eventQuery.isLoading || productsQuery.isLoading || userNameQuery.isLoading || companyNameQuery.isLoading;

	const fault = false;

	const submitForm = async (formValues, uploadCallback) => {
		const castedFormValues = schema.cast(formValues, { stripUnknown: true });
		setIsSubmitting(true);
		try {
			const response =  await postEventTask(eventId, castedFormValues);
			if (uploadCallback) {
				await uploadCallback(response.data.id.toString());
			}

			onChange();

			toasterNotify(
                `Task "${castedFormValues.name}" has been successfully added to the event`,
                'success',
			);
		} finally {
			setIsSubmitting(false);
		}
	};

	useEffect(() => {
		if (!show) {
			setIsSubmitting(false);
			eventQuery.remove();
			userNameQuery.remove();
			companyNameQuery.remove();
			productsQuery.remove();
		}
	}, [show]);

	return (
		<EventTaskModalView
			show={show}
			onClose={onClose}
			submitForm={submitForm}
			isSubmitting={isSubmitting}
			isLoading={isLoading}
			fault={fault}
			title="Add Task"
			initialValues={initialValues}
			assignableUsers={users}
			assignableCompanies={companies}
			schema={schema}
			fieldPermissions={getFieldPermissions}
			showNewAttachment={true}
		/>
	);
};
export default AddContainer;
