import React, { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { linkColumn } from '../../../components/BaseTable/BaseTable';
import FAIcon from '../../../components/FAIcon/FAIcon';
import FilterableTable from '../../../components/FilterableTable/FilterableTable';
import Loading from '../../../components/Loading/Loading';
import Page from '../../../components/Page/Page';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { TabState } from '../../../components/StatefulTabs/StatefulTabs';
import StatusText from '../../../components/StatusText/StatusText';
import DateCell from '../../../components/cells/DateCell/DateCell';
import { assetConst } from '../../../constants/assetConstants';
import { getAssetTypes } from '../../../services/productsService';
import { formatAssetType } from '../../../utils/assetUtils';
import { assetTypeCustomEvaluation, useAssetQuery } from '../ApprovalQueue.helpers';


const CodeInBoxAssetsTab = ({ eventKey }) => {
	const show = useContext(TabState).tab === eventKey;
	const assetQuery = useAssetQuery({ enabled: show });
	const assets = useMemo(() => assetQuery.data?.data.filter(
        ({ asset_category }) => asset_category === 'CODE_IN_BOX',
	), [assetQuery.data]);

	const assetTypeQuery = useQuery(
		['getAssetTypes', 'HAC'],
		() => getAssetTypes(['HAC'], assetConst.CATEGORY.CODE_IN_BOX),
		{ enabled: show },
	);

	const isLoading = assetQuery.isLoading && assetTypeQuery.isLoading;

	const codeInBoxDataFormat = useMemo(() => {
		const codeInBoxChevronLink = ({ original }) => {
			return (
            <Link to={`/products/${original.product_id}/assets/${original.asset_id}`}>
                <FAIcon name="chevron-right" />
            </Link>
			);
		};
		let tableConfiguration = [
			{
				Header: 'Asset Type',
				id: 'asset_type',
				accessor: (d) => {
					let value = formatAssetType(d.asset_type);
					if (d.asset_type_other_name) {
						value = value + ' - ' + d.asset_type_other_name;
					}
					return value;
				},
			},
			{ Header: 'Title', accessor: 'game_name', width: 250 },
			{
				Header: 'Publisher',
				accessor: 'company_name',
			},
			{ Header: 'Game Code', accessor: 'game_code' },
			{
				Header: 'Date Modified',
				accessor: 'modified_date',
				sortMethod: (a, b) => {
					return new Date(a) > new Date(b) ? 1 : -1;
				},
				Cell: DateCell,
			},
			{
				Header: 'Status',
				accessor: 'review_status',
				width: 150,
				Cell: (cell) => <StatusText>{cell?.value || ''}</StatusText>,
			},
			{
				...linkColumn,
				Cell: codeInBoxChevronLink,
			},
		];
		return tableConfiguration;
	}, []);

	const filterProperties = useMemo(() => assetTypeQuery.isSuccess && new Map([
		[
			'Asset Type',
			{
				filter: 'asset_type',
				customEvaluation: assetTypeCustomEvaluation,
				selectableFilters: new Set(
					assetTypeQuery.data?.data.map((value) => formatAssetType(value.asset_type)),
				),
			},
		],
	]), [assetTypeQuery.isSuccess, assetTypeQuery.data]);

	return (
        <Page.ContentContainer>
            <SectionTitle>Code-In-Box Assets</SectionTitle>
            { isLoading ? (
                <Loading />
            ) : (
                <FilterableTable
                    data={assets}
                    dataFormat={codeInBoxDataFormat}
                    filterProperties={filterProperties}
                    searchableFields={['game_name', 'game_code', 'company_name']}
                    searchableFieldPlaceHolder='Search by Publisher, Title or Game Code'
                    defaultSorted={[
                        {
                            id: 'modified_date',
                            desc: true,
                        },
                    ]}
                    noBorder
                    retainPageState
                    stateOnTab={eventKey}
                />
            )}
        </Page.ContentContainer>
	);
};
export default CodeInBoxAssetsTab;
