import React, { VFC } from 'react';
import ReactLoading from 'react-loading';

import './Loading.css';

interface LoadingProps {
	className?: string;
	slim?: boolean;
}
const Loading: VFC<LoadingProps> = ({ className, slim }) => 
	<div className={'Loading' + (slim ? ' Loading--slim' : '') + (className ? ` ${className}` : '')} aria-label="loading">
		<ReactLoading type="balls" width="110" color="var(--nin-color-red)" />
	</div>;

export default Loading;

