import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Button, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import ActionLink from '../../../components/ActionLink/ActionLink';
import { actionsColumn } from '../../../components/BaseTable/BaseTable';
import ActiveCell from '../../../components/cells/ActiveCell/ActiveCell';
import FAIcon from '../../../components/FAIcon/FAIcon';
import FilterableTable from '../../../components/FilterableTable/FilterableTable';
import Loading from '../../../components/Loading/Loading';
import MeatballDropdown from '../../../components/MeatballDropdown/MeatballDropdown';
import DeleteConfirmationModal from '../../../components/modals/DeleteConfirmationModal/DeleteConfirmationModal';
import SimpleModal from '../../../components/modals/SimpleModal/SimpleModal';
import RolloverList from '../../../components/RolloverList/RolloverList';
import { eventConstants } from '../../../constants/eventConstants';
import { transferRightsConstants } from '../../../constants/transferRightsConstants';
import { deleteEventProduct, getEventProducts } from '../../../services/eventsService';
import { getProductsInBulk } from '../../../services/productsService';
import { getUsersInBulk } from '../../../services/usersService';
import { chunk } from '../../../utils/arrayUtils';
import { safeEval } from '../../../utils/dataUtils';
import { dateFormat, formatDate } from '../../../utils/dateUtils';
import { toasterNotify } from '../../../utils/toaster';
import { userHasInternalRole } from '../../../utils/userUtils';
import EventsProductModal from '../modals/EventsProductModal';
import { allowViewEventProduct, formatRightType, getEventProductRoute, getPermissionsForEvent } from './ProductsTab.helpers';


const ADD_PRODUCT_MODAL = 'ADD_PRODUCT_MODAL';
const EDIT_PRODUCT_MODAL = 'EDIT_PRODUCT_MODAL';
const DELETE_PRODUCT_MODAL = 'DELETE_PRODUCT_MODAL';
const CANT_DELETE_PRODUCT_MODAL = 'CANT_DELETE_PRODUCT_MODAL';
const { OWNERSHIP, DISTRIBUTION } = transferRightsConstants.TRANSFER_TYPE;
const { AWAITING_RECEIVING_PUBLISHER, PENDING, REVIEWED, PROCESSED, REVOKED } = transferRightsConstants.REQUEST_STATUS;
const DISPLAY_STATUSES = [AWAITING_RECEIVING_PUBLISHER, PENDING, REVIEWED, PROCESSED];

const getTableStructure = ({userProfile, productsData, history, canEditProductOnEvent, setOpenModal, coordinatorNames, accessType, showTransferStatus}) => {
	const {
		// showTransfersColumn, // TODO: For transfer status icon display
		showPublisherColumn
	} = getPermissionsForEvent(userProfile);

	const showActiveColumn = productsData.some((product) => product.active !== undefined);
	const showSupportLevelColumn = productsData.some((product) => product.support_level !== undefined);

	const transferStatusCell = ({original, value}) => {
		if (!value || !value.length) {
			return '';
		}

		if (value[0].icon === OWNERSHIP) {
			return (<div className="text-center">
				<OverlayTrigger
					placement="top"
					overlay={
						<Tooltip id={'transferDisplay-' + original.id}>
							{`Ownership has been transferred to ${value[0].transferToCompanyName}.`}<br/>
							{`Transfer Date: ${formatDate(value[0].transferDate, dateFormat.DATE)}`}
						</Tooltip>
					}
				>
					<FAIcon name="handshake-alt" className="text-success" />
				</OverlayTrigger>
			</div>);
		} else if (value[0].icon === DISTRIBUTION) {
			return (<div className="text-center">
				<OverlayTrigger
					placement="top"
					overlay={
						<Tooltip id={'transferDisplay-' + original.id} >
							{value.map((val, index) => (
								<p key={index}>Initiating: {val.transferFromCompanyName}<br/>
								   Receiving: {val.transferToCompanyName}<br/>
								   Transfer Date: {formatDate(val.transferDate, dateFormat.DATE)}
								</p>
							))}
						</Tooltip>
					}
				>
					<FAIcon name="handshake-alt" className="text-info" />
				</OverlayTrigger>
			</div>);
		} else {
			return (<div className="text-center">
				<OverlayTrigger
					placement="top"
					overlay={
						<Tooltip id={'transferDisplay-' + original.id} >
							{'Transfer Revoked, please assign a new publisher partner.'}<br/>
							{`Transfer Date: ${formatDate(value[0].transferDate, dateFormat.DATE)}`}
						</Tooltip>
					}
				>
					<FAIcon name="undo" className="text-danger" />
				</OverlayTrigger>
			</div>);
		}
	};

	const linkedNameCell = ({original, value}) =>
		allowViewEventProduct(userProfile, accessType, original) ? <Link to={getEventProductRoute(original.id)}>{value}</Link> : value;

	const publisherCell = ({original, value}) => {
		const companies = value && value.reduce((reduction, item) => {
			if (!original.companies.includes(item.ndid_company_id)) {
				return reduction;
			}
			reduction[item.ndid_company_id] = reduction[item.ndid_company_id] || {name: item.company_name, types: []};
			reduction[item.ndid_company_id]['types'].push(formatRightType(item.right_type));
			return reduction;
		}, {});

		return <ul>
			{companies && Object.keys(companies).map((v, i) => <li key={'publisherCell' + i}>
				{companies[v].name} ({companies[v].types.join(', ')})
			</li>)}
		</ul>;
	};

	const internalUserCell = ({original, value}) => {
		let internalUsers = [];

		coordinatorNames && coordinatorNames.length > 0 && value && value.forEach((coordinator) => {
			const result = coordinatorNames.filter(obj => {
				return obj.ndid_user_id === coordinator;
			});
			result[0] && internalUsers.push(result[0].user_name);
		});
		return <div><RolloverList tooltipId={'internal-users-' + original.id} values={internalUsers} /></div>;
	};



	const actionsCell = ({original}) => {
		const menuItems = [];
		if (allowViewEventProduct(userProfile, accessType, original)) {
			menuItems.push(
				<MeatballDropdown.Item
					key={`view-${original.product_id}`}
					onClick={(e) => history.push(getEventProductRoute(original.id))}
				>
					View Event Product
				</MeatballDropdown.Item>,
			);
		}
		if (canEditProductOnEvent) {
			menuItems.push(
				<MeatballDropdown.Item
					key={`edit-${original.product_id}`}
					onClick={(e) =>
						setOpenModal({
							type: EDIT_PRODUCT_MODAL,
							id: original.id,
						})
					}
				>
					Edit Event Product
				</MeatballDropdown.Item>,
			);
			menuItems.push(<MeatballDropdown.Divider key={`divider-${original.product_id}`}/>);
			menuItems.push(
				<MeatballDropdown.Item
					key={`delete-${original.product_id}`}
					onClick={(e) =>  {
						    if (original.active) {
						        setOpenModal({type: CANT_DELETE_PRODUCT_MODAL});
					        } else {
								setOpenModal({
									type: DELETE_PRODUCT_MODAL,
									id: original.id,
								});
					        }
					}}
				>
					<span className="text-danger">Delete Event Product</span>
				</MeatballDropdown.Item>,
			);
		}
		return menuItems.length > 0 && (
			<MeatballDropdown
				id={`dropdown-${original.product_id}`}
			>
				{menuItems}
			</MeatballDropdown>
		);
	};
	return [
		{
			Header: '',
			accessor: 'transferDisplay',
			width: 36,
			Cell: transferStatusCell,
			show: showTransferStatus,
		},
		{ Header: 'Product Name', accessor: 'game_name', Cell: linkedNameCell },
		{ Header: 'Game Code', accessor: 'game_code', width: 80 },
		{
			Header: 'Publisher',
			accessor: 'product_rights',
			Cell: publisherCell,
			show: showPublisherColumn,
		},
		{ Header: 'Platform', accessor: 'platform_name' },
		{ Header: 'Support Level', accessor: 'support_level', show: showSupportLevelColumn },
		{ Header: 'Internal Users', accessor: 'coordinators', Cell: internalUserCell },
		{
			Header: 'Active',
			accessor: 'active',
			width: 64,
			Cell: ActiveCell,
			show: showActiveColumn,
		},
		{ ...actionsColumn, Cell: actionsCell },
	];
};

const ProductsTab = ({
	id,
	system_families,
	canAddProductToEvent,
	canEditProductOnEvent,
	userProfile,
	accessType,
	status,
	show
}) => {
	const showTransferStatus = (accessType === 'MANAGER' && status !== eventConstants.STATUS.COMPLETED);
	const [fault, setFault] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [productsData, setProductsData] = useState(null);
	const [coordinatorNames, setCoordinatorNames] = useState(null);
	const [openModal, setOpenModal] = useState(null);
	const [unreachableProducts, setUnreachableProducts] = useState([]);
	const history = useHistory();

	const loadAllData = async () => {
		try {
			setIsLoading(true);
			setFault(null);

			const products = [];
			const productResponse = await getEventProducts(id);
			const token = productResponse.headers['x-pdb-authorization'];
			const eventProducts = productResponse.data;
			const fields = ['platform_name', 'product_rights', 'retail_status', 'game_code', 'game_name'];
			if (showTransferStatus) {
				fields.push('product_transfers');
			}
			const subsequentCalls = chunk(eventProducts.map(ep => ep.product_id), 20).map(ids => getProductsInBulk(ids, fields)
				.then(response => response.data.forEach(item => products.push(item)))
			);

			//Get distinct coordinators
			let distinctCoordinators = [];
			eventProducts.forEach((product) => {
				if ('coordinators' in product) {
					product.coordinators.forEach((coordinator) => {
						if (!distinctCoordinators.includes(coordinator)) {
							distinctCoordinators.push(coordinator);
						}
					});
				}
			});
			if (distinctCoordinators.length > 0) {
				setCoordinatorNames(
					(await getUsersInBulk(distinctCoordinators, ['user_name'], token)).data,
				);
			}

			await Promise.all(subsequentCalls);

			let displayData = eventProducts.map(ep => {
				const matchingProduct = products.find(mp => mp && ep.product_id === mp.product_id);
				if (matchingProduct && showTransferStatus) {
					if (ep.product_transfer_id && !ep.companies.length) {
						const transferItem = matchingProduct.product_transfers.find((entry) => entry.product_transfer_id === ep.product_transfer_id);
						if (transferItem &&	transferItem.transfer_type === OWNERSHIP && transferItem.transfer_status === PROCESSED) {
							matchingProduct.transferDisplay = [getTransferDisplayItem(OWNERSHIP, transferItem)];
						} else if (transferItem && transferItem.transfer_type === DISTRIBUTION && transferItem.transfer_status === REVOKED) {
							matchingProduct.transferDisplay = [getTransferDisplayItem(REVOKED, transferItem)];
						}
					}
					if (!matchingProduct.transferDisplay) {
						matchingProduct.transferDisplay = matchingProduct.product_transfers.map((transferItem) => {
							if (transferItem &&	transferItem.transfer_type === DISTRIBUTION && DISPLAY_STATUSES.includes(transferItem.transfer_status)) {
								return getTransferDisplayItem(DISTRIBUTION, transferItem);
							} else {
								return null;
							}
						}).filter(x => !!x);
					}
				}
				return (matchingProduct && {...ep, ...matchingProduct}) || ep.product_id;
			});
			setProductsData(displayData.filter(item => typeof item === 'object'));
			setUnreachableProducts(displayData.filter(item => typeof item !== 'object'));
		} catch (err) {
			setFault(`There was an error while loading data for this display. (${err.toString()})`);
		} finally {
			setIsLoading(false);
		}
	};

	const getTransferDisplayItem = (icon, transferItem) => {
		return {icon: icon,
			transferFromCompanyName:	transferItem.transfer_from_company_name,
			transferToCompanyName: transferItem.transfer_to_company_name,
			transferDate: transferItem.transfer_date
		};
	};

	const deleteProgressCallback = ({data}) => {
		const newOpenModal = {...openModal, lastUpdated: Date.now()};
		setOpenModal(newOpenModal);
	};

	const deleteProduct = async (assetId) => {
		try {
			await deleteEventProduct(assetId, deleteProgressCallback);
			toasterNotify('Successfully deleted the product from this event.', 'success');
		} catch (err) {
			if (/^Poll limit reached/.test(err.message)) {
				toasterNotify(
					'The product delete operation timed out as the operation took longer than expected. ' +
						'Please verify that product was deleted.',
					'warning'
				);
			} else {
				const details = safeEval(() => err.response.data.result.statusCode) === 404
					? 'the product was not found. Please reload the page and try again'
					: 'of an unexpected error. Please try again';
				toasterNotify(
					`Could not delete the product because ${details}.`,
					'error',
					err,
				);
			}

		} finally {
			loadAllData();
			setOpenModal();
		}
	};

	const dataFormat = useMemo(() => {
		return (
			productsData &&
			getTableStructure({
				userProfile,
				productsData,
				history,
				canEditProductOnEvent,
				setOpenModal,
				coordinatorNames,
				accessType,
				showTransferStatus
			})
		);
	}, [productsData]);

	useEffect(() => {
		show && !productsData && id && loadAllData();
	}, [show, id, productsData]);

	if (isLoading) { return <Loading />; }
	if (fault) {
		return <><Alert variant='danger'>{fault}<br/>
			<ActionLink onClick={() => loadAllData()}>Retry load</ActionLink> <FAIcon size="xs" name="chevron-right" /></Alert>
		</>;
	}

	const addProductButton = canAddProductToEvent && (
		<ButtonToolbar className="mb-4">
			<Button
				variant="primary"
				id="addProduct"
				onClick={e => setOpenModal({type: ADD_PRODUCT_MODAL})}
			>
				Add Product
			</Button>
		</ButtonToolbar>
	);

	const marketingEventProductModal = (canAddProductToEvent || canEditProductOnEvent) && (
		<EventsProductModal
			systemFamilies={system_families}
			onClose={() => setOpenModal(null)}
			onChange={() => {setOpenModal(null); loadAllData();}}
			show={openModal && (openModal.type === ADD_PRODUCT_MODAL || openModal.type === EDIT_PRODUCT_MODAL)}
			eventId={id}
			productIds={Array.from(productsData, ({product_id}) => product_id)}
			eventProductId={(openModal && openModal.id) || null}
		/>
	);

	return (<>
		{ (unreachableProducts.length && userHasInternalRole(userProfile))
			? <Alert variant="danger">{unreachableProducts.length > 1 ? 'Products' : 'A product'} attached to this event could not be found in the database: <b>{unreachableProducts.join(', ')}</b>. Please contact <a href="mailto:thirdpartypublisher@noa.nintendo.com">thirdpartypublisher@noa.nintendo.com</a>.</Alert>
			: null }
		{ addProductButton }
		{(!productsData || productsData.length === 0)
			?<Alert variant='warning'>No products are attached to this event</Alert>
			:<FilterableTable
			className=""
			data={productsData}
			dataFormat={dataFormat}
			defaultSorted={[
				{
					id: 'active',
					desc: true
				},
				{
					id: 'support_level',
					desc: false
				},
				{
					id: 'game_name',
					desc: false
				},
			]}
			noBorder
		/>}
		{ marketingEventProductModal }
		{ canEditProductOnEvent && <>
			<DeleteConfirmationModal
				show={openModal && openModal.type === DELETE_PRODUCT_MODAL}
				closeModal={() => setOpenModal({...openModal, type: null})}
				confirmDelete={e => deleteProduct(openModal.id)}
				title="Remove Product"
				message="Please confirm that you would like to remove this product and delete all of its associated assets."
				submissionMessage={openModal && openModal.lastUpdated && 'Products may take time to fully delete'}
				lastUpdated={openModal && openModal.lastUpdated}
			/>
			<SimpleModal
				show={openModal && openModal.type === CANT_DELETE_PRODUCT_MODAL}
				closeModal={() => setOpenModal({...openModal, type: null})}
				title="Delete Product"
			>
				Please deactivate the Product before deleting.
			</SimpleModal>
		</>}
	</>);
};

export default ProductsTab;
