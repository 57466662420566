import React, { useEffect, useMemo, useState, VFC } from 'react';
import { Alert, Button, Col, Row, Table } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import FAIcon from '../../../components/FAIcon/FAIcon';
import Forms from '../../../components/Forms/Forms';
import Loading from '../../../components/Loading/Loading';
import LoadingText from '../../../components/Loading/LoadingText';
import DeleteConfirmationModal from '../../../components/modals/DeleteConfirmationModal/DeleteConfirmationModal';
import SimpleModal from '../../../components/modals/SimpleModal/SimpleModal';
import Page from '../../../components/Page/Page';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { platformCodes, platformNames } from '../../../constants/platformConstants';
import {
	DigitalOrderResourcesData,
	getComponentOrderPrice,
	getDigitalOrderDetailAndResources,
	getDigitalOrderResources,
	postNewOrder,
	putUpdateOrderDetails
} from '../../../services/digitalCodesService';
import { numberWithCommas } from '../../../utils/currencyUtils';
import { getPossiblyInvalidValue, isEmptyObject, isInvalid } from '../../../utils/dataUtils';
import {
	checkUnitPriceFeeMismatch,
	formatTaxPercentageDisplay,
	getReasonForInvalidValue
} from '../../../utils/digitalCodesUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { validateToSchema } from '../../../utils/validationUtils';
import { useProductQuery } from '../DigitalCodesOrderCreate.helper';

import './Step1OrderAndProducts.css';

export const step1Schema = yup.object().shape({
	platform_code: yup.string().required('A Platform is required'),
	purposeID: yup.number()
		.nullable()
		.default(null)
		.required('A Purpose is required')
		.test(
			'not_null',
			'The selected purpose is no longer valid, please select a different one',
			(value) => value !== null,
		),
	purposeDescription: yup.string()
		.nullable()
		.default(null)
		.required('A Description of Purpose for the order is required')
		.max(500),
	publisherPO: yup.string()
		.nullable()
		.default(null)
		.trim()
		.required('Publisher PO Number must be provided')
		.test(
			'po_reuse_test',
			'Cannot reuse a Publisher PO Number from another order',
			(value, { options }) =>
				!options?.context?.usedPONumbers || !options.context.usedPONumbers.includes(value),
		),
	productID: yup.number()
		.nullable()
		.default(null)
		.required('A Product is required')
		.test(
			'not_disqualified',
			(value, { createError, options }) => {
				const invalidProduct = options?.context?.invalidProduct;
				if (invalidProduct && invalidProduct.value === value) {
					return createError({
						message: getReasonForInvalidValue('product', options?.context?.invalidProduct)
					});
				} else return true;
			}
		),
	componentID: yup.string()
		.nullable()
		.default(null)
		.required('A Component is required')
		.test(
			'not_disqualified',
			(value, { createError, options }) => {
				const invalidComponent = options?.context?.invalidComponent;
				if (invalidComponent && invalidComponent.value === value) {
					return createError({
						message: getReasonForInvalidValue('component', options?.context?.invalidComponent)
					});
				} else return true;
			}
		),
	quantity: yup.number()
		.nullable()
		.default(null)
		.test(
			'quantity_batches',
			'Quantity must be in multiples of the batch amount for the selected purpose.',
			(value, { options }) =>
				!options?.context?.selectedPurpose ||
				Number(value) % (options.context?.selectedPurpose.code_batch_incremements || 1) ===
				0,
		)
		.test(
			'quantity_maximums',
			'Quantity must not exceed the max limit of the selected purpose',
			(value, { options }) =>
				!options?.context?.selectedPurpose ||
				Number(value) <= options.context?.selectedPurpose.max_num_of_codes,
		)
		.test(
			'quantity_minimums',
			'Quantity must meet the minimum of the selected purpose',
			(value, { options }) =>
				!options?.context?.selectedPurpose ||
				Number(value) >= options.context?.selectedPurpose.min_num_of_codes,
		),
});

interface Step1OrderAndProductsProps {
	orderHeaderId?: number;
	onNext: () => void;
	onNewId: (orderHeaderId: number) => void;
	setSaveCall: (fn: () => void) => void;
}
const Step1OrderAndProducts: VFC<Step1OrderAndProductsProps> = ({
	orderHeaderId,
	onNext,
	onNewId,
	setSaveCall,
}) => {
	const [formValues, setFormValues] = useState<{
		platform_code?: PlatformCode;
		purposeID?: number | null;
		productID?: number | null;
		componentID?: string | null;
		quantity?: number;
		publisherPO?: string;
		purposeDescription?: string;
	} | null>({});
	const [selectedPlatform, setSelectedPlatform] = useState<PlatformCode | undefined>();
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [openModal, setOpenModal] = useState<
		{ type?: 'CONFIRM_PLATFORM_CHANGE_MODAL' | 'PURPOSES_MODAL' | null } | undefined
	>();

	const resourcesQuery = useQuery(
		'getDigitalOrderResources',
		() => getDigitalOrderResources(),
		{ enabled: !orderHeaderId },
	);
	const orderAndResourcesQuery = useQuery(
		['getDigitalOrderDetailAndResources', orderHeaderId],
		() => getDigitalOrderDetailAndResources(String(orderHeaderId)),
		{
			enabled: !!orderHeaderId,
			cacheTime: 0,
			onSuccess: (response) => {
				const orderDetails = response.data?.order_details;
				if (
					isInvalid(orderDetails.purposeID) ||
					isInvalid(orderDetails.productID) ||
					isInvalid(orderDetails.componentID)
				) {
					setShowAllErrors(true);
				}
				setFormValues({
					platform_code: orderDetails.platform_code,
					purposeID: getPossiblyInvalidValue(orderDetails.purposeID),
					productID: getPossiblyInvalidValue(orderDetails.productID),
					componentID: getPossiblyInvalidValue(orderDetails.componentID),
					quantity: orderDetails.quantity,
					publisherPO: orderDetails.publisherPO,
					purposeDescription: orderDetails.purposeDescription,
				});
			}
		},
	);

	const orderData = orderAndResourcesQuery.data?.data?.order_details;
	const activeQuery = orderHeaderId ? orderAndResourcesQuery : resourcesQuery;
	const usedPONumbers = activeQuery.data?.data.publisher_po_numbers;
	const taxPercent = activeQuery.data?.data.tax_percent;
	const purposeList = [...activeQuery.data?.data.purposes || []]
		.sort((a, b) => a.sort_order - b.sort_order);

	const productQuery = useProductQuery(formValues?.purposeID);
	const availableProducts = useMemo(() => productQuery.data?.data ? [...productQuery.data.data].sort((a, b) =>
		a.game_name < b.game_name ? -1 : a.game_name > b.game_name ? 1 : 0
	) : undefined,
		[productQuery.data?.data]
	);
	const availablePurposesPerPlatform = purposeList.reduce<
		Record<string, ArrayElement<DigitalOrderResourcesData['purposes'][]>>
	>((reduction, purpose) => {
		if (Object.values(platformCodes).includes(purpose.platform_code)) {
			if (reduction[purpose.platform_code as string]) {
				reduction[purpose.platform_code as string].push(purpose);
			} else {
				reduction[purpose.platform_code as string] = [purpose];
			}
		}
		return reduction;
	}, {});

	const selectedPurpose =
		(formValues && formValues.purposeID &&
			activeQuery.isSuccess &&
			activeQuery.data?.data?.purposes?.find(
				(purpose) => purpose.purpose_id === formValues.purposeID,
			)) || undefined;

	const componentPriceQuery = useQuery(
		['getComponentOrderPrice', formValues?.componentID, selectedPurpose?.purpose_id],
		() => getComponentOrderPrice(String(formValues?.componentID), selectedPurpose?.purpose_id as number),
		{ enabled: !!formValues?.componentID && !!selectedPurpose?.purpose_id },
	);

	const [quantityMin, quantityMax, quantityIncrements] =
		(selectedPurpose
			? [
				selectedPurpose.min_num_of_codes,
				selectedPurpose.max_num_of_codes,
				selectedPurpose.code_batch_increments || 1,
			]
			: []);

	const submitForm = async (advanceToNext: boolean = false) => {
		// check validation
		if (advanceToNext && !isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			toasterNotify(
				'Required fields must be filled with valid values to continue. See validation messages in the form for specific issues.',
				'error',
			);
			return;
		} else if ('purposeID' in validationErrors) {
			toasterNotify('A Platform and a Purpose need to be selected for the order to be saved', 'error');
			return;
		} else if (formValues?.publisherPO && 'publisherPO' in validationErrors) {
			toasterNotify(
				'A blank or never used before value for Publisher PO # is necessary for the order to be saved.',
				'error',
			);
			return;
		}

		// format payload
		const castedFormValues = step1Schema.cast(formValues, { stripUnknown: true });

		const payload = {
			...castedFormValues,
			action: advanceToNext ? 'submit' : 'save',
		};

		// transactions
		setIsSubmitting(true);
		try {
			if (orderHeaderId) {
				await putUpdateOrderDetails(String(orderHeaderId), payload);
			} else {
				const newOrderResponse = await postNewOrder(payload);
				onNewId(Number(newOrderResponse.data.orderID));
			}
			toasterNotify('Order was saved successfully', 'success');
			if (advanceToNext) {
				onNext();
			} else {
				setIsSubmitting(false);
			}
		} catch (error: any) {
			const ediPricingError =
				error.response?.status === 409 &&
				error.response?.data.message.error.message === 'Price mismatch - cannot submit';
			if (ediPricingError) {
				toasterNotify(
					<div>
						{' '}
						Price has changed since a product was added to the order.&nbsp;
						<a
							className="text-underline text-danger"
							href="mailto:thirdpartypublisher@noa.nintendo.com"
						>
							Please contact NOA via email.
						</a>
					</div>,
					'error',
				);
			} else {
				if (error instanceof Error) {
					toasterNotify(createMessageForError(error, 'saving the order'), 'error', error);
				}
			}
			setIsSubmitting(false);
		}
	};

	const availableComponents = useMemo(() => {
		const components = formValues && formValues.productID
			? availableProducts?.find(
				(product) => product.product_id === formValues.productID,
			)?.components
			: undefined;
		return (
			components &&
			[...components].sort((a, b) =>
				a.game_name > b.game_name ? 1 : a.game_name < b.game_name ? -1 : 0,
			)
		);
	}, [availableProducts, formValues?.productID]);

	const isLoading = activeQuery.isLoading;
	const areProductsLoading = productQuery.isLoading;

	const validationErrors = validateToSchema(step1Schema, formValues, {
		usedPONumbers,
		selectedPurpose,
		invalidProduct: isInvalid(orderData?.productID) ? orderData?.productID : null,
		invalidComponent: isInvalid(orderData?.componentID) ? orderData?.componentID : null,
	});

	useEffect(() => {
		setSaveCall(() => {
			submitForm();
		});
	});

	const estimatedPriceDisplay = useMemo(() => {
		if (!componentPriceQuery?.data?.data) {
			return;
		}
		if (componentPriceQuery.data.data.service_fee) {
			return <i>Fee</i>;
		}
		if (componentPriceQuery.data.data.unit_price) {
			return new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 4,
			}).format(Number(componentPriceQuery.data.data.unit_price)) + ' / unit';
		}
		return ' ';
	}, [componentPriceQuery.status, selectedPurpose]);

	const subtotalDisplay = useMemo(() => {
		if (componentPriceQuery.isError) {
			return <span className="text-danger"><FAIcon name="circle-exclamation" /></span>;
		}
		if (componentPriceQuery.isLoading) {
			return null;
		}
		if (componentPriceQuery.data?.data.service_fee) {
			return new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			}).format(Number(componentPriceQuery.data?.data.service_fee));
		}
		if (componentPriceQuery.data?.data.unit_price && formValues?.quantity) {
			return new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			}).format(Number(componentPriceQuery.data?.data.unit_price) * formValues.quantity);
		}
		return '';
	}, [componentPriceQuery.status, formValues?.quantity, selectedPurpose]);

	const taxPercentDisplay = useMemo(
		() => taxPercent && formatTaxPercentageDisplay(taxPercent),
		[taxPercent],
	);

	const [taxDisplay, grandTotalDisplay] = useMemo(() => {
		const subtotal = componentPriceQuery.data?.data.service_fee
			? Number(componentPriceQuery.data.data.service_fee)
			: componentPriceQuery.data?.data.unit_price && formValues?.quantity
				? Number(componentPriceQuery.data.data.unit_price) * formValues.quantity
				: 0;

		if (!subtotal) {
			return [null, null];
		}

		const taxRate = Number(taxPercent || 0) / 100;
		const taxAmount = (subtotal * taxRate).toFixed(2);

		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});

		return [formatter.format(Number(taxAmount)), formatter.format(Number(subtotal.toFixed(2)) + Number(taxAmount))];
	}, [taxPercent, componentPriceQuery.status, formValues?.quantity]);

	const hasEstimatedPriceMismatch = useMemo(() => {
		if (
			orderData?.componentID !== formValues?.componentID ||
			orderData?.platform_code !== formValues?.platform_code ||
			orderData?.productID !== formValues?.productID ||
			orderData?.purposeID !== formValues?.purposeID
		) { return false; }
		if (componentPriceQuery.isSuccess && (orderData?.unitPrice || orderData?.serviceFee)) {
			return checkUnitPriceFeeMismatch(
				componentPriceQuery?.data?.data,
				{
					unitPrice: orderData?.unitPrice,
					serviceFee: orderData?.serviceFee,
				}
			);
		}
		return false;
	}, [
		componentPriceQuery.status,
		orderAndResourcesQuery.status,
		formValues?.componentID,
		formValues?.platform_code,
		formValues?.productID,
		formValues?.purposeID,
	]);
	
	return (
		<>
			<Page.FullPageCol>
				{isLoading || formValues == null ? (
					<Loading />
				) : (
					<>
						<SectionTitle>Order Details</SectionTitle>
						<Row>
							<Col sm={8}>
								<Forms
									values={formValues}
									onChange={(newFormValues) => {
										const newSet = { ...newFormValues };
										if (newFormValues.productID !== formValues.productID) {
											newSet['componentID'] = undefined;
										}
										if (
											newFormValues.platform_code !== formValues.platform_code
										) {
											newSet['purposeID'] = undefined;
										}
										if (newFormValues.purposeID !== formValues.purposeID) {
											newSet['productID'] = undefined;
											newSet['componentID'] = undefined;
											newSet['quantity'] = undefined;
										}
										setFormValues(newSet);
									}}
									validationErrors={validationErrors}
									showAllErrors={showAllErrors}
									disabled={isSubmitting}
								>
									<Forms.Select id="platform_code">
										<Forms.Heading>Platform</Forms.Heading>
										{availablePurposesPerPlatform &&
											Object.keys(availablePurposesPerPlatform).map(
												(code) => (
													<Forms.Option key={code} value={code}>
														{platformNames[code as PlatformCode]}
													</Forms.Option>
												),
											)}
									</Forms.Select>
									<Forms.Select
										id="purposeID"
										placeholder={
											!formValues.platform_code
												? 'Select a Platform...'
												: undefined
										}
										disabled={!formValues.platform_code}
									>
										<Forms.Heading>Purpose</Forms.Heading>
										{(formValues.platform_code &&
											availablePurposesPerPlatform &&
											availablePurposesPerPlatform[
												formValues.platform_code
											].map((purpose) => (
												<Forms.Option
													key={'key-' + purpose.purpose_id}
													value={purpose.purpose_id}
												>
													{purpose.purpose}
												</Forms.Option>
											))) ||
											null}
										<Forms.Postscript>
											<Button
												variant="link"
												onClick={() =>
													setOpenModal({ type: 'PURPOSES_MODAL' })
												}
											>
												See Purpose Details
											</Button>
										</Forms.Postscript>
									</Forms.Select>

									<Forms.TextArea
										id="purposeDescription"
										rows={4}
										maxLength={500}
									>
										<Forms.Heading>Description of Purpose</Forms.Heading>
										<Forms.Help>500 character limit.</Forms.Help>
									</Forms.TextArea>
									<Forms.Text id="publisherPO" maxLength={50}>
										<Forms.Heading>Publisher PO Number</Forms.Heading>
									</Forms.Text>
								</Forms>
							</Col>
						</Row>

						<SectionTitle>Product Details</SectionTitle>
						{(!formValues.platform_code || !formValues.purposeID) && (
							<Alert variant="info">
								Select a platform and purpose before adding items to the list
							</Alert>
						)}
						{formValues.platform_code &&
							formValues.purposeID &&
							productQuery.isLoading && <Loading slim />}
						{formValues.platform_code &&
							formValues.purposeID &&
							!productQuery.isLoading && (
								<Table className="table-nin Step1OrderAndProducts__table">
									<thead>
										<tr>
											<th style={{ width: '50%' }}>
												<label className="mb-0" htmlFor="input-productID">
													Product
												</label>
											</th>
											<th style={{ width: '50%' }}>
												<label className="mb-0" htmlFor="input-componentID">
													Component
												</label>
											</th>
											<th style={{ minWidth: '8rem' }}>
												<label className="mb-0" htmlFor="input-quantity">
													Quantity
												</label>
											</th>
											<th className="text-right" style={{ minWidth: '8em' }}>
												Est. Price
											</th>
											<th style={{ minWidth: '8rem', textAlign: 'right' }}>
												Total
											</th>
										</tr>
									</thead>
									<tbody>
										<Forms
											values={formValues}
											onChange={(newFormValues) =>
												setFormValues(newFormValues)
											}
											validationErrors={validationErrors}
											showAllErrors={showAllErrors}
											disabled={isSubmitting}
											as="tr"
											noPadding
											vertical
										>
											<td style={{ width: '50%' }}>
												<Forms.SearchSelect
													id="productID"
													isLoading={areProductsLoading}
													placeholder={'Select a Product...'}
													disabled={!formValues.purposeID}
												>
													{availableProducts?.map(
														(product) =>
															product && (
																<Forms.Option
																	value={product?.product_id}
																	key={String(
																		product?.product_id,
																	)}
																	disabled={
																		isInvalid(
																			orderData?.productID,
																		)?.value ===
																		product?.product_id
																	}
																>
																	[{product.game_code}]{' '}
																	{product.game_name}
																</Forms.Option>
															),
													)}
													{formValues.productID &&
														isInvalid(orderData?.productID) &&
														!availableProducts?.some(
															(product) =>
																product.product_id ===
																isInvalid(orderData?.productID)
																	?.value,
														) &&
														formValues.productID ===
															getPossiblyInvalidValue(
																orderData?.productID,
															) && (
															<Forms.Option
																value={getPossiblyInvalidValue(
																	orderData?.productID,
																)}
																disabled={true}
																key="disabled-value"
															>
																Invalid Product
															</Forms.Option>
														)}
												</Forms.SearchSelect>
											</td>
											<td style={{ width: '50%' }}>
												<Forms.SearchSelect
													id="componentID"
													placeholder={
														!formValues.productID
															? 'Select a Product...'
															: 'Select a Component...'
													}
													isLoading={productQuery.isLoading}
													disabled={
														productQuery.isLoading ||
														!formValues.productID
													}
												>
													{availableComponents?.some(
														(component) =>
															component.content_type === 'TITLE',
													) && (
														<Forms.Group label="Main Title">
															{availableComponents.map((component) =>
																component.content_type ===
																'TITLE' ? (
																	<Forms.Option
																		value={
																			component.part_number
																		}
																		key={
																			'key-' +
																			component.part_number
																		}
																	>
																		(
																		{component.ns_uid ||
																			component.product_code}
																		) {component.game_name}
																	</Forms.Option>
																) : null,
															)}
														</Forms.Group>
													)}
													{availableComponents?.some(
														(component) =>
															component.content_type !== 'TITLE',
													) && (
														<Forms.Group label="Component">
															{availableComponents.map(
																(component, index) =>
																	component.content_type !==
																	'TITLE' ? (
																		<Forms.Option
																			value={
																				component.part_number
																			}
																			key={
																				'key-' +
																				component.part_number
																			}
																		>
																			(
																			{component.ns_uid ||
																				component.product_code}
																			) {component.game_name}
																		</Forms.Option>
																	) : null,
															)}
														</Forms.Group>
													)}
													{formValues.componentID &&
														isInvalid(orderData?.componentID)?.value ===
															formValues.componentID &&
														availableComponents?.every(
															(component) =>
																component.part_number !==
																formValues.componentID,
														) && (
															<Forms.Group label="Unavailable">
																<Forms.Option
																	value={formValues.componentID}
																	disabled
																>
																	({formValues.componentID})
																</Forms.Option>
															</Forms.Group>
														)}
												</Forms.SearchSelect>
											</td>

											<td>
												<Forms.Number
													id="quantity"
													maxLength={50}
													min={quantityMin || undefined}
													max={quantityMax || undefined}
													step={quantityIncrements}
													onBlur={() => {
														if (!formValues.quantity) {
															setFormValues({
																...formValues,
																quantity: quantityMin || undefined,
															});
														}
													}}
												>
													<Forms.Help>
														<div
															style={{
																whiteSpace: 'nowrap',
																position: 'relative',
																width: 0,
																maxWidth: '12rem',
															}}
														>
															{selectedPurpose &&
																`Min: ${
																	selectedPurpose.min_num_of_codes ? (
																		numberWithCommas(
																			selectedPurpose.min_num_of_codes,
																		)
																	) : (
																		<>&mdash;</>
																	)
																}, Max: ${
																	selectedPurpose.max_num_of_codes ? (
																		numberWithCommas(
																			selectedPurpose.max_num_of_codes,
																		)
																	) : (
																		<>&mdash;</>
																	)
																}`}
															{selectedPurpose &&
																(selectedPurpose.code_batch_increments >
																1 ? (
																	<>
																		<br />
																		Batch amount:{' '}
																		{numberWithCommas(
																			selectedPurpose.code_batch_increments,
																		)}
																	</>
																) : null)}
														</div>
													</Forms.Help>
												</Forms.Number>
											</td>
											<td>
												<div className="text-right Step1OrderAndProducts__padded-cell">
													{hasEstimatedPriceMismatch ? (
														<span className="text-danger">
															{estimatedPriceDisplay}
															<sup>&dagger;</sup>
														</span>
													) : (
														estimatedPriceDisplay
													)}
												</div>
											</td>
											<td>
												<div
													className="d-flex Step1OrderAndProducts__padded-cell"
													style={{
														justifyContent: 'flex-end',
													}}
												>
													<div>{componentPriceQuery.isLoading ? <LoadingText inline className="m-0" /> : subtotalDisplay}</div>
												</div>
											</td>
										</Forms>
										{taxPercentDisplay && (
											<tr>
												<td colSpan={4}></td>
												<td className="Step1OrderAndProducts__total-cell position-relative">
													<div className="d-flex Step1OrderAndProducts__total-heading">
														Tax ({taxPercentDisplay}):
													</div>
													&nbsp;
													{taxDisplay}
												</td>
											</tr>
										)}
										<tr>
											<td colSpan={4}></td>
											<td
												className="Step1OrderAndProducts__total-cell position-relative"
												style={{
													background: 'var(--nin-color-ltgray3',
													fontWeight: 'bold',
												}}
											>
												<div className="d-flex Step1OrderAndProducts__total-heading">
													Estimated Total:
												</div>
												&nbsp;
												{grandTotalDisplay}
											</td>
										</tr>
									</tbody>
								</Table>
							)}
						{hasEstimatedPriceMismatch && (
							<div className="font-italic text-danger text-right">
								<small>
									<sup>&dagger;</sup>{' '}
									<i>Price changed since product was added to order</i>
								</small>
							</div>
						)}
						<div className="btn-container">
							<div className="float-left">
								<Button
									as={Link}
									variant="link"
									to="/orders/digital-codes"
									disabled={isSubmitting}
								>
									Return to Orders
								</Button>
							</div>
							<div className="float-right d-flex">
								{isSubmitting && <LoadingText inline className="m-a" />}
								<Button
									className="ml-3"
									variant="outline-secondary"
									type="button"
									onClick={() => submitForm()}
									disabled={isSubmitting}
								>
									Save
								</Button>
								<Button
									className="ml-3"
									variant="primary"
									onClick={() => submitForm(true)}
									disabled={
										activeQuery.isLoading ||
										productQuery.isLoading ||
										isSubmitting
									}
								>
									Next (Delivery) <FAIcon name="chevron-right" />
								</Button>
							</div>
						</div>
					</>
				)}
			</Page.FullPageCol>
			<SimpleModal
				show={openModal && openModal.type === 'PURPOSES_MODAL'}
				size="lg"
				title="Purpose Descriptions"
				closeModal={() => setOpenModal({ ...openModal, type: null })}
			>
				{purposeList &&
					purposeList.map((purpose) => {
						const priceType = purpose.price_type;
						return (
							<div key={purpose.purpose_id} className="mb-4">
								<h4>{purpose.purpose}</h4>
								<div>
									<p> {purpose.purpose_description} </p>
									<Row>
										<Col xs={4} md={3}>
											Minimum order:{' '}
											{purpose.min_num_of_codes ? (
												numberWithCommas(purpose.min_num_of_codes)
											) : (
												<>&mdash;</>
											)}
										</Col>
										<Col xs={4} md={4}>
											Maximum order:{' '}
											{purpose.max_num_of_codes ? (
												numberWithCommas(purpose.max_num_of_codes)
											) : (
												<>&mdash;</>
											)}
										</Col>
										<Col xs={4} md={4}>
											<p>
												{' '}
												{(purpose.code_batch_increments > 1 &&
													`Code quantity in batches of ${numberWithCommas(
														purpose.code_batch_increments,
													)}`) || <span>&nbsp;</span>}{' '}
											</p>
										</Col>
									</Row>
									<Row className="show-grid">
										<Col xs={12}>
											<p> Price type: {priceType} </p>
										</Col>
									</Row>
								</div>
							</div>
						);
					})}
			</SimpleModal>
			<DeleteConfirmationModal
				show={openModal?.type === 'CONFIRM_PLATFORM_CHANGE_MODAL'}
				closeModal={() => {
					setOpenModal({ ...openModal, type: null });
					setFormValues({ ...formValues, platform_code: selectedPlatform });
				}}
				title="Change Platform"
				message="Selecting a new platform will remove your current order items. Do you wish to continue?"
				confirmDelete={() => {
					setOpenModal({ ...openModal, type: null });
					setSelectedPlatform(formValues?.platform_code);
					setFormValues({});
				}}
			/>
		</>
	);
};

export default Step1OrderAndProducts;
