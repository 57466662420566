import React, { HTMLAttributes } from 'react';

import './ActionLink.css';

interface ActionLinkProps extends HTMLAttributes<HTMLButtonElement> {
	dotted?: boolean;
}

const ActionLink = (props: ActionLinkProps) => {
	const newProps ={...props, dotted: undefined} as HTMLAttributes<HTMLButtonElement>;
	newProps.className =
        (props.dotted ? 'ActionLink--dotted' : 'ActionLink') + (props.className ? ` ${props.className}` : '');
	return <button
        {...newProps}
    />;
};

export default ActionLink;
