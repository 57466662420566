import React, { ReactElement, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import * as yup from 'yup';

import {
	getDeclaredPriceAgreement,
	getDeclaredPriceRequestAgreementTemplates,
	postDeclaredPriceRequests,
} from '../../../services/digitalCodesService';
import { isEmptyObject } from '../../../utils/dataUtils';
import { getSanitizedHTML } from '../../../utils/stringUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { validateToSchema } from '../../../utils/validationUtils';
import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import WSPRequestView from '../../../components/modals/RequestWSPAgreementModal/views/WSPRequestView';

const schema = () => {
	return yup.object().shape({
		certified_price: yup
			.string()
			.test(
				'validDollar',
				'Valid price is required, no amounts over 999.99 or fractions of cents',
				(value) => /^([1-9]\d?\d?|0)(\.\d{1,2})?$/.test(value!),
			)
			.test(
				'type',
				'Only include numbers and one period in the price, no currency signs',
				(value) => !isNaN(value as any),
			)
			.required('Price must be provided'),
		certified_flag: yup
			.boolean()
			.default(false)
			.required('Box must be checked')
			.test(
				'must_be_true',
				'Box must be checked',
				(value) => value === null || value === true,
			),
		user_signature: yup.string().trim().required('Signature is required'),
	});
};

interface RequestModalProps {
	show: boolean;
	closeModal: () => void;
	onSubmitted: () => void;
	defaultValues: Record<string, any>;
	nsUid?: string;
	productPlatformCode?: string;
	componentGameName?: string;
	readOnly?: boolean;
	requestId?: number;
}

const RequestDeclaredPriceAgreementModal = ({
	show,
	closeModal,

	onSubmitted,
	defaultValues = {},
	nsUid = '',
	productPlatformCode = '',
	componentGameName = '',

	readOnly = false,
	requestId,
}: RequestModalProps): ReactElement => {
	const [formValues, setFormValues] = useState<Record<string, any>>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);
	const [lastUpdated, setLastUpdated] = useState<number | null >();

	const existingDataQuery = useQuery(
		['getDeclaredPriceAgreement', requestId],
		() => getDeclaredPriceAgreement(requestId!),
		{
			enabled: !!(show && requestId && readOnly),
		},
	);

	const templateQuery = useQuery(
		['getDeclaredPriceRequestAgreementTemplates', productPlatformCode],
		() => getDeclaredPriceRequestAgreementTemplates(productPlatformCode),
		{ enabled: !!(show && !readOnly) },
	);
	const isLoading = existingDataQuery.isLoading || templateQuery.isLoading;
	const fault =
		!!(templateQuery.isError && templateQuery.error) ||
		!!(existingDataQuery.isError && existingDataQuery.error);
	const declaredPriceTemplate: any = readOnly
		? existingDataQuery.isSuccess &&
		  existingDataQuery.data.data.declared_price_agreement_template
		: templateQuery.isSuccess && templateQuery.data.data;

	const declaredPriceTemplateBody =
		(declaredPriceTemplate?.template && declaredPriceTemplate?.template) ||
		declaredPriceTemplate?.template_body;

	useEffect(() => {
		if (show && !readOnly) {
			setFormValues({ ...defaultValues });
			setShowAllErrors(false);
			setIsSubmitting(false);
			setLastUpdated(null);
		}
	}, [show]);

	const changedFormValue = (values: Record<string, any>) => {
		setFormValues(values);
	};

	const validationErrors = formValues && validateToSchema(schema(), formValues);

	const submitForm = async () => {
		if (isSubmitting) {
			return;
		}
		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}

		setIsSubmitting(true);

		postDeclaredPriceRequests(
			nsUid,
			{
				declared_price_agreement_template_id:
					declaredPriceTemplate.declared_price_agreement_template_id,
				certified_flag: formValues?.certified_flag,
				certified_price: formValues?.certified_price,
				user_signature: formValues?.user_signature,
			},
			() => setLastUpdated(new Date().valueOf()),
		)
			.then(() => {
				onSubmitted();
				closeModal();
				toasterNotify(
					`Your declared price agreement for "${componentGameName}" has been successfully submitted.`,
					'success',
				);
			})
			.catch((err) => {
				toasterNotify(
					createMessageForError(err, 'submitting your declared price agreement'),
					'error',
					err,
				);
			})
			.finally(() => {
				setLastUpdated(null);
				setIsSubmitting(false);
			});
	};

	return (
		<BaseModal
			size="xl"
			show={show}
			onCancel={closeModal}
			isLoading={isLoading}
			isSubmitting={isSubmitting}
			fault={fault}
		>
			<BaseModal.Title>
				{declaredPriceTemplateBody?.Title ? (
					<span
						dangerouslySetInnerHTML={{
							__html: getSanitizedHTML(declaredPriceTemplateBody?.Title),
						}}
					/>
				) : (
					'Loading Agreement...'
				)}
			</BaseModal.Title>
			<Row className="justify-content-center">
				<Col sm={12} lg={10}>
					<Forms
						onChange={(v) => changedFormValue(v)}
						values={
							(readOnly
								? existingDataQuery.data?.data.declared_price_request
								: formValues) || {}
						}
						validationErrors={validationErrors}
						showAllErrors={showAllErrors}
					>
						<WSPRequestView template={declaredPriceTemplateBody} readOnly={readOnly} />

						<small
							className="float-right"
							dangerouslySetInnerHTML={{
								__html: getSanitizedHTML(
									`Legal: ${declaredPriceTemplate?.version}`,
								),
							}}
						/>
					</Forms>
				</Col>
			</Row>
			{!readOnly && <BaseModal.Submit onClick={() => submitForm()}>Submit</BaseModal.Submit>}
			{!readOnly && (
				<BaseModal.SubmissionStatus lastUpdated={lastUpdated!}>
					Request submission may take time to complete
				</BaseModal.SubmissionStatus>
			)}
		</BaseModal>
	);
};

export default RequestDeclaredPriceAgreementModal;
