export function getSubmissionFullVersion(productVersion, submissionVersion) {
	if (!(productVersion || submissionVersion)) {
		return '';
	}

	let outputVersion = `${productVersion}.${submissionVersion}`;
	return outputVersion.startsWith('v') ? outputVersion : `v${outputVersion}`;
}

export function sortVersions(a, b, reversed = false) {
	if (reversed) {
		return b.localeCompare(a, undefined, { numeric: true });
	}
	return a.localeCompare(b, undefined, { numeric: true });
}
