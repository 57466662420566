import React from 'react';
import { ButtonToolbar } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import BaseTable from '../../../components/BaseTable/BaseTable';
import FAIcon from '../../../components/FAIcon/FAIcon';
import Loading from '../../../components/Loading/Loading';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { getFeaturesForCompany } from '../../../services/featuresService';

const ActiveFeaturesTab = ({ show, companyData }) => {
	const companyId = companyData.company_information?.ndid_company_id;
	const companyFeaturesQuery = useQuery(['getFeaturesForCompany', companyId], () => getFeaturesForCompany(companyId));

	return (
		<>
			<SectionTitle>Active Features</SectionTitle>
			<ButtonToolbar className="mb-3">
				<Link to="/admin/feature-management" className="btn btn-outline-secondary">
					Feature Management <FAIcon name="chevron-right" />
				</Link>
			</ButtonToolbar>

			{companyFeaturesQuery.isLoading ? (
				<Loading />
			) : (
				<BaseTable
					data={companyFeaturesQuery.data?.data.filter(item => item.status !== 'Inactive')}
					columns={[
						{
							id: 'enabled',
							Header: '',
							accessor: (row) =>
								row.status === 'Active' || row.enabled_for_this_company,
							Cell: ({ value }) => (
								<div className="text-center">
									{value ? (
										<FAIcon name="check" className="text-success" />
									) : (
										<FAIcon name="times" className="text-danger" />
									)}
								</div>
							),
							width: 40,
							enableResizing: false,
						},
						{
							Header: 'Feature',
							accessor: 'name',
							Cell: ({ value, original }) => (
								<>
									{value}
									<br />
									<small className="text-secondary">{original.description}</small>
								</>
							),
						},
						{
							Header: 'Status',
							accessor: 'status',
							Cell: ({ value }) => <b className={value === 'Active' ? 'text-success' : ''}>{value.toUpperCase()}</b>
						},
					]}
					showPagination={false}
				/>
			)}
		</>
	);
};
export default ActiveFeaturesTab;
