import React from 'react';

const InfoRow = (props) => {
	return (
		<p>
			<strong>{props.title}</strong>
			<br />
			{props.value}
		</p>
	);
};

export default InfoRow;
