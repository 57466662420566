import React from 'react';
import { Tab } from 'react-bootstrap';

import Page from '../../components/Page/Page';
import StatefulTabs from '../../components/StatefulTabs/StatefulTabs';
import Title from '../../components/Title/Title';
import CustomFieldDetailTab from './views/CustomFieldIndexTab';

const COMPANY_ENTITY = 'company';
const PRODUCT_ENTITY = 'product';

export const CustomFieldsManagement = () => {
	return (
        <Page>
            <Title title="Custom Field Management"/>
            <div className="Page__fill-space">
                <StatefulTabs
                    transition={false}
                    retainPageState
                >
                    <Tab
                        eventKey={PRODUCT_ENTITY}
                        title="Product Details"
                        className="Page__fill-space"
                    >
                        <CustomFieldDetailTab
                            tableTitle="Product Details"
                            entityName={PRODUCT_ENTITY}/>
                    </Tab>

                    <Tab
                        eventKey={COMPANY_ENTITY}
                        title="Publisher Details"
                        className="Page__fill-space"
                    >
                        <CustomFieldDetailTab
                            tableTitle="Publisher Details"
                            entityName={COMPANY_ENTITY}/>
                    </Tab>
                </StatefulTabs>
            </div>
        </Page>
	);
};

export default CustomFieldsManagement;
