import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import queryString from 'query-string';
import * as yup from 'yup';

import { roleConstants } from '../../../constants/roleConstants';
import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import { getEventProduct, getSupportLevels, postEventProduct, putEventProduct } from '../../../services/eventsService';
import { getProducts,getProduct } from '../../../services/productsService';
import { getRoleAssignedUsers } from '../../../services/usersService';
import { validateToSchema } from '../../../utils/validationUtils';
import { toasterNotify } from '../../../utils/toaster';
import { isEmptyObject } from '../../../utils/dataUtils';


const schema = yup.object().shape({
	active: yup.boolean().default(false),
	product_id: yup.number().required('A product must be selected'),
	support_level: yup.string().default(''),
	coordinators: yup.array()
		.of(yup.string())
		.default([])
		.when('active', {
			is: true,
			then: yup.array()
				.min(
					1,
					'At least one user must be selected if product is active'
				)
		}),
	companies: yup.array()
		.of(yup.string())
		.default([])
		.when('active', {
			is: true,
			then: yup.array()
				.min(1, 'You must select a Publisher if product is active')
		})
});

const EventsProductModal = ({
	show,
	onClose,
	onChange,
	systemFamilies,
	eventId,
	eventProductId,
	productIds,
}) => {
	const productParms = {
		limit: 50000,
		offset: 0,
		field: ['product_id', 'game_code', 'platform_code', 'game_name', 'company_name'],
		system_family: systemFamilies,
	};
	const [formValues, setFormValues] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isLoadingCompanies, setIsLoadingCompanies] = useState(null);
	const [showAllErrors, setShowAllErrors] = useState(false);
	const [supportLevels, setSupportLevels] = useState(null);
	const [products, setProducts] = useState(null);
	const [productUniqueDistribution, setProductUniqueDistribution] = useState(null);
	const [currentProductId, setCurrentProductId] = useState(null);
	const [usersWithFilters, setUsersWithFilters] = useState(null);
	const [fault, setFault] = useState(null);

	const rollupDistribution = (distributions) => {
		let myMap = new Map();
		distributions.forEach((distribution) => {
			if (myMap.has(distribution.ndid_company_id)) {
				let currentValue = myMap.get(distribution.ndid_company_id);
				myMap.set(distribution.ndid_company_id, {
					company_name: distribution.company_name,
					right_types: `${currentValue.right_types}, ${distribution.right_type}`,
				});
			} else {
				//Don't have this ndid_company_id in the map?  Set a new key and value
				myMap.set(distribution.ndid_company_id, {
					company_name: distribution.company_name,
					right_types: distribution.right_type,
				});
			}
		});
		const distArray = Array.from(myMap).map(([name, value]) => ({ name, value }));
		setProductUniqueDistribution(distArray);
	};

	const changedFormValue = (values, formId) => {
		if (formId === 'companies') {
			if (values.companies && values.companies.length > 1) {
				values.companies.shift();
			}
		}
		if (formId === 'product_id') {
			values.companies = [];
		}
		setFormValues(values);
	};

	// data load
	useEffect(() => {
		if (show) {
			loadData();
		}
	}, [show]);

	useEffect(() => {
		if (!formValues.product_id || currentProductId === formValues.product_id) return;
		setIsLoadingCompanies(true);
		getProduct(formValues.product_id)
			.then((response) => {
				if (formValues.product_id == null) {
					return;
				}
				setCurrentProductId(formValues.product_id);
				rollupDistribution(response.data.distribution);
			})
			.catch((error) => {
				setFault(error);
			})
			.finally(() => {
				setIsLoadingCompanies(false);
			});
	}, [formValues.product_id]);

	const validationErrors = validateToSchema(schema, formValues);

	const submitForm = () => {
		if (isSubmitting) {
			return;
		}
		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}

		const castedFormValues = schema.cast(formValues);
		setIsSubmitting(true);

		if (eventProductId) {
			putEventProduct(eventProductId, castedFormValues)
				.then(() => {
					onChange();
					toasterNotify('This event product has been successfully updated', 'success');
				})
				.finally(() => {
					setIsSubmitting(false);
				});
		} else {
			postEventProduct(eventId, castedFormValues)
				.then(() => {
					onChange();
					toasterNotify(
						'This product has been successfully added to the event',
						'success',
					);
				})
				.finally(() => {
					setIsSubmitting(false);
				});
		}
	};

	const loadData = () => {
		setIsLoading(true);
		setShowAllErrors(false);
		setFault(null);
		setCurrentProductId(null);
		setProductUniqueDistribution(null);
		setFormValues({});

		const calls = [];

		if (!supportLevels) {
			const levelsCall = getSupportLevels().then((response) => {
				setSupportLevels(response.data);
			});
			calls.push(levelsCall);
		}
		if (!products) {
			const productsCall = getProducts(queryString.stringify(productParms)).then(
				(response) => {
					setProducts(response.data);
				},
			);
			calls.push(productsCall);
		}
		if (!usersWithFilters) {
			const usersCall = getRoleAssignedUsers(roleConstants.MARKETING_EVENTS_TEAM_MEMBER).then(
				(response) => {
					setUsersWithFilters(response.data);
				},
			);
			calls.push(usersCall);
		}
		if (eventProductId) {
			setIsLoadingCompanies(true);
			const eventProductCall = getEventProduct(eventProductId).then((response) => {
				setFormValues(response.data);
			});
			calls.push(eventProductCall);
		}

		Promise.all(calls)
			.catch((error) => {
				setFault(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<BaseModal
			show={show}
			onCancel={onClose}
			isLoading={isLoading}
			isSubmitting={isSubmitting}
			fault={fault}
		>
			<BaseModal.Title>
				{eventProductId ? 'Edit Product' : 'Add Product'}
			</BaseModal.Title>
			<Forms
				onChange={(id, v) => changedFormValue(id, v)}
				values={formValues}
				validationErrors={validationErrors}
				showAllErrors={showAllErrors}
			>
				<div>
					<Alert variant="info">
						Please note this product will only be viewable to internal users if they're
						added below or if the event is set to <b>Viewable Internally.</b>
					</Alert>
				</div>
				<div>
					<Alert variant="info">
						You will be able to set this product to ACTIVE once User and Publisher
						Access have been set.
					</Alert>
				</div>
				<Forms.SingleCheckbox id="active">
					<Forms.Heading>Active</Forms.Heading>
					<Forms.Label>YES</Forms.Label>
				</Forms.SingleCheckbox>
				<Forms.SearchSelect
					id="product_id"
					placeholder="Search products by name"
					disabled={eventProductId ? true : false}
				>
					<Forms.Heading>Product</Forms.Heading>
					{products &&
						products.map(
							(myProduct) =>
								(!!eventProductId ||
									!productIds.includes(myProduct.product_id)) && (
									<Forms.Option
										value={myProduct.product_id}
										key={myProduct.product_id}
									>
										[{myProduct.game_code}] {myProduct.game_name}
									</Forms.Option>
								),
						)}
				</Forms.SearchSelect>
				<Forms.BasicSelect id="support_level" placeholder="Select a support level">
					<Forms.Heading>Support Level</Forms.Heading>
					{supportLevels &&
						supportLevels.map((myLevel) => (
							<Forms.Option value={myLevel} key={myLevel}>
								{myLevel}
							</Forms.Option>
						))}
				</Forms.BasicSelect>
				<Forms.MultiSelect id="coordinators" placeholder="Select users" clearable>
					<Forms.Heading>User Access</Forms.Heading>
					{usersWithFilters &&
						usersWithFilters.map((myUser) => (
							<Forms.Option value={myUser.ndid_user_id} key={myUser.ndid_user_id}>
								{myUser.user_name}
							</Forms.Option>
						))}
				</Forms.MultiSelect>
				<Forms.MultiSelect
					id="companies"
					placeholder="Select publishers"
					isLoading={isLoadingCompanies}
					clearable
				>
					<Forms.Heading>Publisher Access</Forms.Heading>
					{productUniqueDistribution &&
						productUniqueDistribution.map((myDist) => (
							<Forms.Option value={myDist.name} key={myDist.name}>
								{myDist.value.company_name} [{myDist.value.right_types}]
							</Forms.Option>
						))}
				</Forms.MultiSelect>
			</Forms>
			<BaseModal.Submit onClick={() => submitForm()}>Save</BaseModal.Submit>
		</BaseModal>
	);
};

export default EventsProductModal;
