import React, { HTMLAttributes, ReactElement, useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import urlon from 'urlon';

import { useUiStates } from '../../hooks/reduxHooks';
import eventBus from '../../utils/eventBus';
import { getLocation } from '../../utils/routeUtils';
import FAIcon from '../FAIcon/FAIcon';
import DropdownDateFilter from '../Filters/DropdownDateFilter';
import DropdownFilter from '../Filters/DropdownFilter';
import { TabState } from '../StatefulTabs/StatefulTabs';
import { areSameFilters, convertHashToMap, filterData, serializeFilter } from './FilterBar.helpers';
import SearchableTextBox from './views/SearchableTextBox';

import './FilterBar.css';

export type TFilterProperties = Map<string, IFilterDefinition>;

interface IFilterDefinition {
	filter: string;
	selectedFilters?: Set<string> | undefined;
	selectableFilters: 'datetime_range' | Set<string>;
	customEvaluation?: (value: any, activeFilters: Set<string>) => boolean;
}

export type TActiveFilters = Map<string, Set<string>>;



interface FilterBarProps extends HTMLAttributes<HTMLSpanElement> {
	data?: Record<string, any>[];
	filterProperties: TFilterProperties | undefined;
	searchableFields?: string[];
	searchableFieldPlaceHolder?: string;
	onFilter(data?: Record<string, any>[]): void;
	retainPageState?: boolean;
	stateOnTab?: string | false;
}

const FilterBar = ({
	className,
	data,
	filterProperties,
	searchableFields,
	searchableFieldPlaceHolder,
	onFilter,
	retainPageState,
	stateOnTab,
}: FilterBarProps): ReactElement => {
	const uiStates = useUiStates(!!stateOnTab);
	const storedUiStates = uiStates.get();
	const history = useHistory();
	const [activeFilters, setActiveFilters] = useState<TActiveFilters | undefined>(); 
	const [searchString, setSearchString] = useState<string | undefined>('');
	const [filtersTouched, setFiltersTouched] = useState<boolean>(false);
	const [initialized, setInitialized] = useState<boolean>(false);
	const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

	const tabState = useContext(TabState);
	const statefulTabsReady = !tabState || tabState.initialized;
	const currentTab = tabState?.tab;
	const retainStateActive = (retainPageState && currentTab === stateOnTab) || (retainPageState && !stateOnTab);

	const getDefaultFilters = (): TActiveFilters => {
		if (!filterProperties) {
			return new Map();
		}
		const defaultFilters = new Map();
		filterProperties.forEach((instance: IFilterDefinition, name) => {
			if (instance.selectedFilters) {
				defaultFilters.set(name, new Set(instance.selectedFilters));
			}
		});
		return defaultFilters;
	};

	const initialize = () => {
		setInitialized(true);
		let disableQueryStringUpdate = !!stateOnTab;
		const locationSearch = getLocation().search;
		const searchParams = new URLSearchParams(locationSearch);
		let filterParamsSet: boolean = false,
			newActiveFilters: TActiveFilters = new Map(activeFilters),
			newSearchString: string | undefined;

		// if retain page state is on and not tab-disabled
		if (retainStateActive) {
			if (locationSearch) {
				newSearchString = searchParams.get('search') || undefined;
				disableQueryStringUpdate = true;
				setSearchString(newSearchString);

				disableQueryStringUpdate = true;
				const newFilters = processQueryStringFilter(searchParams);
				if (newFilters) {
					newActiveFilters = newFilters;
					setActiveFilters(newActiveFilters);
					setFiltersTouched(true);
					filterParamsSet = true;
				} else {
					searchParams.delete('filters');
					newActiveFilters = getDefaultFilters();
					history.replace(getLocation().pathname + '?' + searchParams.toString());
					filterParamsSet = true;
					setFiltersTouched(false);
				}
				setActiveFilters(newActiveFilters);
			} else {
				newSearchString = storedUiStates.search;
				setSearchString(newSearchString);
				if (storedUiStates.filters) {
					filterParamsSet = true;
					newActiveFilters = convertHashToMap(
						urlon.parse(storedUiStates.filters),
						filterProperties,
					);
					setActiveFilters(newActiveFilters);
					setFiltersTouched(true);
				}
			}
		}
		if (!filterParamsSet) {
			// populate filters based on default values
			newActiveFilters = getDefaultFilters();
			setActiveFilters(newActiveFilters);
			setFiltersTouched(false);
		}

		runFilters(newActiveFilters, newSearchString, !disableQueryStringUpdate);
	};

	const processQueryStringFilter = (searchParams: URLSearchParams) => {
		const filterString = searchParams.get('filters');
		// populate filters based on url search params contents
		let hash;
		try {
			hash = urlon.parse(filterString || '');
			return convertHashToMap(hash, filterProperties);
		} catch (error) {
			// eslint-disable-next-line
			filterString && console.warn('Warning: Could not parse value for filters.');
			return null;
		}
	};

	const runFilters = (
		filters: TActiveFilters | undefined,
		search: string = '',
		updateQuery: boolean = true,
	) => {
		if (onFilter && typeof onFilter === 'function') {
			onFilter(
				filterData(
					data,
					filterProperties,
					filters,
					search != null ? search : searchString,
					searchableFields,
				),
			);
		}
		if (updateQuery) {
			updateQueryString(filters, search);
		}
		eventBus.emit(eventBus.FILTER_SET); // used for testing
	};

	const updateQueryString = (
		filters?: TActiveFilters,
		search?: string,
	) => {
		const filtersString = serializeFilter(filters);
		if (retainStateActive) {
			const params = new URLSearchParams(getLocation().search);
			if (search) {
				params.set('search', search);
			} else {
				params.delete('search');
			}
			const defaultFilters = getDefaultFilters();
			if (filters && !areSameFilters(filters, defaultFilters)) {
				params.set('filters', filters?.size && filtersString ? filtersString : '');
			} else {
				params.delete('filters');
			}
			history.replace(getLocation().pathname + '?' + params.toString());
		}
	};

	const changeFilter = (filterType: string, filter: string) => {
		setActiveFilters((activeFilters) => {
			const newActiveFilters: TActiveFilters = new Map(activeFilters);

			if (!newActiveFilters.has(filterType)) {
				newActiveFilters.set(filterType, new Set());
			}
			if (!newActiveFilters.get(filterType)?.has(filter)) {
				newActiveFilters.get(filterType)?.add(filter);
			} else {
				newActiveFilters.get(filterType)?.delete(filter);
				if (!newActiveFilters.get(filterType)?.size) {
					newActiveFilters.delete(filterType);
				}
			}

			if (retainPageState) {
				const defaultFilters = getDefaultFilters();
				if (newActiveFilters && !areSameFilters(newActiveFilters, defaultFilters)) {
					uiStates.push(
						'filters',
						newActiveFilters?.size ? serializeFilter(newActiveFilters) : '',
					);
				} else {
					uiStates.push('filters', undefined);
				}
			}
			runFilters(newActiveFilters, searchString);

			return newActiveFilters;
		});
		eventBus.emit(eventBus.FILTER_CHANGE);
		setFiltersTouched(true);
	};

	const selectAllInFilter = (filterType: string) => {
		setActiveFilters((activeFilters) => {
			const newActiveFilters: TActiveFilters = new Map(activeFilters);
			newActiveFilters.set(
				filterType,
				new Set(filterProperties?.get(filterType)?.selectableFilters),
			);
			if (retainPageState) {
				uiStates.push('filters', serializeFilter(newActiveFilters));
			}
			runFilters(newActiveFilters, searchString);
			return newActiveFilters;
		});
		setFiltersTouched(true);
	};

	// handle "Clear All" on dropdown filters
	const clearFilter = (filterType: string) => {
		setFiltersTouched(true);
		if (activeFilters?.has(filterType)) {
			activeFilters?.set(filterType, new Set());
			const newActiveFilters = new Map(activeFilters);
			setActiveFilters(newActiveFilters);
			uiStates.push('filters', serializeFilter(activeFilters));
			runFilters(newActiveFilters, searchString);
		}
	};

	const onSearch = (value: string) => {
		setSearchString(value);

		if (retainPageState) {
			uiStates.push('search', value || undefined);
		}
		// init new filter with a 200 ms delay
		if (onFilter && typeof onFilter === 'function') {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
			const newId = setTimeout(() => {
				runFilters(activeFilters, value);
				setTimeoutId(null);
			}, 200);
			setTimeoutId(newId);
		}
	};

	// handle the reset filters button at the end of the filter bar
	const resetFiltersToDefault = () => {
		const newActiveFilters = new Map(filterProperties ? activeFilters : null);
		filterProperties?.forEach((instance, name) => {
			if (instance.selectedFilters) {
				newActiveFilters.set(name, new Set(instance.selectedFilters));
			} else {
				newActiveFilters.delete(name);
			}
		});
		setActiveFilters(newActiveFilters);
		setFiltersTouched(false);
		setSearchString('');
		runFilters(newActiveFilters, '', false);
		updateQueryString();
		uiStates.push('filters', undefined);
		uiStates.push('search', undefined);
		eventBus.emit(eventBus.FILTER_CHANGE);
	};

	// run the filter if filter properites or data set is changed
	useEffect(() => {
		if (!initialized) {
			return;
		}
		runFilters(activeFilters, searchString, false);
	}, [data]);

	useEffect(() => {
		if (
			!initialized &&
			statefulTabsReady &&
			(!retainPageState || (retainPageState && retainStateActive))
		) {
			initialize();
		}
	}, [filterProperties, statefulTabsReady, retainStateActive]);

	useEffect(() => {
		if (retainPageState && !retainStateActive) {
			// reset the state to blank. Rely on StatefulTabs repopulating the
			// query string after a tab change.
			initialized && setInitialized(false);
			return;
		} 
	}, [retainPageState, retainStateActive]);

	return (
		<ul
			className={`FilterBar__criteria-list nav${className ? ' ' + className : ''}`}
			role="tablist"
		>
			{filterProperties &&
				Array.from(filterProperties.keys()).map((key, index) => {
					const value = filterProperties.get(key);
					return value?.selectableFilters instanceof Set ? (
						<DropdownFilter
							key={key + index}
							filterValues={filterProperties.get(key)?.selectableFilters}
							selectedValues={activeFilters?.get(key) || new Set()}
							addFilter={changeFilter}
							clearFilter={clearFilter}
							setFilters={() => selectAllInFilter(key)}
							filterName={key}
							columnName={value.filter}
						/>
					) : value?.selectableFilters === 'datetime_range' ? (
						<DropdownDateFilter
							key={key}
							selectedValues={activeFilters?.get(key) || new Set()}
							addFilter={changeFilter}
							clearFilter={clearFilter}
							filterName={key}
							columnName={value.filter}
						/>
					) : null;
				})}
			{searchableFields && (
				<SearchableTextBox
					placeHolder={searchableFieldPlaceHolder}
					searchFieldChanged={(e) => onSearch(e.target.value)}
					value={searchString}
				/>
			)}
			<li className="FilterBar__reset-button">
				<Button
					size="sm"
					variant="outline-secondary"
					onClick={() => resetFiltersToDefault()}
					disabled={!filtersTouched && !searchString}
					title="Reset filters"
				>
					<FAIcon variant="regular" name="align-slash" />
				</Button>
			</li>
		</ul>
	);
};
export default FilterBar;
