import React, { useEffect, useMemo, useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { fetchEventCategories } from '../../store/actions/referenceActions';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import EventActiveModal from '../../components/modals/EventActiveModal/EventActiveModal';
import EventModal from '../../components/modals/EventModal/EventModal';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import { eventConstants } from '../../constants/eventConstants';
import { loadingConstants } from '../../constants/loadingConstants';
import NotFound from '../../containers/NotFound/NotFound';
import { useUserProfile } from '../../hooks/reduxHooks';
import { getEventDetails } from '../../services/eventsService';
import { getUsersInBulk } from '../../services/usersService';
import { dateFormat, formatDate, parseDateString } from '../../utils/dateUtils';
import { findEventStatus, getEventDetailPermissions } from '../../utils/eventUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import EventDetailsPanel from './panels/EventDetailsPanel';
import LinkedEventsPanel from './panels/LinkedEventsPanel';
import ProductsTab from './tabs/ProductsTab.js';
import TasksTab from './tabs/TasksTab.js';


const EDIT_EVENT_MODAL = 'EDIT_EVENT_MODAL';
const SET_EVENT_ACTIVE_MODAL = 'SET_EVENT_ACTIVE_MODAL';

const PRODUCTS_TAB = 'products';
const TASKS_TAB = 'tasks';

const statusBadge = status => {
	switch (status) {
		case eventConstants.STATUS.PLANNED:
			return <span className="status-mini status-info">PLANNED</span>;
		case eventConstants.STATUS.LIVE:
			return <span className="status-mini status-success">LIVE</span>;
		case eventConstants.STATUS.COMPLETED:
			return <span className="status-mini status-muted">COMPLETED</span>;
		case eventConstants.STATUS.DRAFT:
			return <span className="status-mini status-muted">DRAFT</span>;
		default:
			return null;
	}
};

const EventDetail = () => {
	const history = useHistory();
	const location = useLocation();
	const params = useParams();
	const dispatch  = useDispatch();
	const userProfile = useUserProfile();
	const eventCategories = useSelector(state => state.referenceReducer.eventCategories?.content);
	const eventCategoriesMeta = useSelector(state => state.referenceReducer.eventCategories?.meta);
	const platforms = useSelector(state => state.referenceReducer.platforms?.content);
	const platformsMeta = useSelector(state => state.referenceReducer.platforms?.meta);

	const [isLoading, setIsLoading] = useState(false);
	const [eventData, setEventData] = useState(null);
	const [fault, setFault] = useState(null);
	const [managerNames, setManagerNames] = useState(null);
	const [supportUserNames, setSupportUserNames] = useState(null);
	const [openModal, setOpenModal] = useState(null);
	const [accessType, setAccessType] = useState(null);
	const [selectedTab, setSelectedTab] = useState(
		(new URLSearchParams(location.search).get('tab') === TASKS_TAB && TASKS_TAB) || PRODUCTS_TAB
	);

	// spur reference data load
	useEffect(() => {
		dispatch(fetchEventCategories());
	}, []);

	// initial data load
	useEffect(() => {
		loadEvent();
	}, [params.eventId]);

	const loadEvent = () => {
		setAccessType(null);
		setIsLoading(true);
		getEventDetails(params.eventId)
			.then((result) => {
				setEventData(result.data);
				setAccessType(result.headers['x-pdb-access-type']);
				if (result.data.managers || result.data.support) {
					const managers = result.data.managers || [];
					const supportUsers = result.data.support || [];
					const allUsers = [...managers, ...supportUsers];
					if (managers.length) { setManagerNames(loadingConstants.LOADING); }
					if (supportUsers.length) { setSupportUserNames(loadingConstants.LOADING); }

					allUsers.length && getUsersInBulk(allUsers)
						.then(result => {
							if (managers.length) {
								setManagerNames(result.data.filter(d => managers.includes(d.ndid_user_id)).map(d => d.user_name));
							}
							if (supportUsers.length) {
								setSupportUserNames(result.data.filter(d => supportUsers.includes(d.ndid_user_id)).map(d => d.user_name));
							}
						});
				}
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving event details'),
					'error',
					error
				);
				setFault(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	// permissions
	const permissions = useMemo(() => (
		(eventData && getEventDetailPermissions(userProfile, accessType)) || {}
	), [userProfile, eventData, accessType]);
	const {
		canSetActive,
		canAddProductToEvent,
		canEditProductOnEvent,
		canAccessTasks,
		canEditEvent
	} = permissions;

	const refDataReady = eventCategoriesMeta.status !== loadingConstants.LOADING
		&& platformsMeta.status !== loadingConstants.LOADING;

	const event = refDataReady && eventData && {
		...eventData,
		status: findEventStatus(eventData),
		systemFamilyNames: eventData.system_families.map(sf => platforms.find(p => p.platform_code === sf).platform_name),
		category: eventCategories[eventData.category] || eventData.category,
		start_datetime: eventData.start_datetime ? formatDate(parseDateString(eventData.start_datetime || '0'), dateFormat.DATE) : '',
		end_datetime: eventData.end_datetime ? formatDate(parseDateString(eventData.end_datetime || '0'), dateFormat.DATE) : '',
		managerNames,
		supportUserNames,
		userProfile,
		canAddProductToEvent,
		canEditProductOnEvent,
		accessType
	};

	if (fault) {
		return <NotFound />;
	}
	const title = event && event.name;
	const badge = event && statusBadge(event.status);
	const titleButtons =  isLoading ? null : <>
		{ canEditEvent &&
		<Button
			variant="outline-primary"
			onClick={e => setOpenModal({type: EDIT_EVENT_MODAL})}
		>
			Edit event
		</Button>
		}
		{ canSetActive &&
		<MeatballDropdown
			id='dropdown-title'
			size="lg"
		>
			{ canSetActive &&
			<MeatballDropdown.Item
				onClick={e => setOpenModal({type: SET_EVENT_ACTIVE_MODAL})}
			>
				Set {event.active ? 'Inactive' : 'Active'}
			</MeatballDropdown.Item>
			}
		</MeatballDropdown>
		}
	</>;

	const changeTab = (tab) => {
		setSelectedTab(tab);
		const newParams = new URLSearchParams(location.search);
		newParams.delete('tab');
		newParams.set('tab', tab);
		history.replace({search: newParams.toString()});
	};

	return (
		<Page>
			{isLoading || !refDataReady ? (
				<Loading />
			) : (
				<>
					<Breadcrumb>
						<Breadcrumb.Item to="/marketing-events">Marketing Events</Breadcrumb.Item>
						<Breadcrumb.Item active>{title}</Breadcrumb.Item>
					</Breadcrumb>
					<Title title={title} status={badge} button={titleButtons} />
					<Page.SplitPage>
						<Page.MainCol>
							<Tabs
								className=""
								transition={false}
								id="event-tabs"
								onSelect={(tab) => changeTab(tab)}
								activeKey={selectedTab}
							>
								<Tab
									eventKey={PRODUCTS_TAB}
									title="Products"
									className="tab-container"
								>
									<Page.ContentContainer>
										<ProductsTab
											{...event}
											show={selectedTab === PRODUCTS_TAB}
										/>
									</Page.ContentContainer>
								</Tab>
								{canAccessTasks && (
									<Tab
										eventKey={TASKS_TAB}
										title="Tasks"
										className="tab-container"
									>
										<Page.ContentContainer>
											<TasksTab
												{...event}
												permissions={permissions}
												show={selectedTab === TASKS_TAB}
											/>
										</Page.ContentContainer>
									</Tab>
								)}
							</Tabs>
						</Page.MainCol>
						<Page.SidebarCol>
							<EventDetailsPanel {...event} />
							<LinkedEventsPanel {...event} />
						</Page.SidebarCol>
					</Page.SplitPage>
				</>
			)}
			{canEditEvent && (
				<EventModal
					show={openModal && openModal.type === EDIT_EVENT_MODAL}
					eventId={openModal && eventData.id}
					eventCategories={eventCategories}
					platforms={platformsMeta.status === loadingConstants.COMPLETED && platforms}
					onClose={(e) => setOpenModal({ ...openModal, type: null })}
					onChange={(e) => {
						setOpenModal({ ...openModal, type: null });
						loadEvent();
					}}
					permissions={permissions}
				/>
			)}
			{canSetActive && (
				<EventActiveModal
					show={openModal && openModal.type === SET_EVENT_ACTIVE_MODAL}
					eventData={openModal && eventData}
					onCancel={(e) => setOpenModal({ ...openModal, type: null })}
					onComplete={(e) => {
						setOpenModal({ ...openModal, type: null });
						loadEvent();
					}}
				/>
			)}
		</Page>
	);
};

export default EventDetail;
