import React from 'react';
import { Button, Card } from 'react-bootstrap';

import FAIcon from '../../components/FAIcon/FAIcon';
import Page from '../../components/Page/Page';
import { redirectToLogin } from '../../utils/routeUtils';


const NotSignedIn = ({message}) => (
	<Page>
		<div className="d-block m-auto">
			<Card>
				<Card.Body>
					<div className="d-flex flex-row align-items-center">
						<FAIcon className="text-info m-5" name="user" size='5x' />
						<h3 className="mr-5">You are not currently signed in.<br/>
						Please sign in for site access.</h3>
					</div>
				</Card.Body>
				<Card.Body className="border-top text-right">
					<Button variant="primary" onClick={(e) => redirectToLogin(false)}>
						Sign In
					</Button>
				</Card.Body>
			</Card>
		</div>
	</Page>
);

export default NotSignedIn;
