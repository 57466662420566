import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';

import BaseModal from '../../../components/BaseModal/BaseModal';
import { assetConst } from '../../../constants/assetConstants';
import { postAssetStatus } from '../../../services/productsService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';

import '../AssetDetails.css';


function getAlertMessage() {
	return (
		<Alert variant="info">
			<p>
				It has been determined that there isn’t enough time to complete an approval of your
				final label and maintain the initial pickup date. By accepting the request to forgo,
				you are providing your approval to start cartridge production without a review of
				the label proof.
			</p>
			<p>
				Please contact your account administrator if you have any questions about this
				request.
			</p>
		</Alert>
	);
}

function getTitle() {
	return 'Request Forgo Label Approval';
}

function determineStatus() {
	return assetConst.FINAL_LABEL_FORGO_STATUS.REQUEST_FORGO;
}
export default class ForgoFinalLabelApprovalModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			display: true
		};
	}

	rejectAsset() {
		const {
			productId,
			assetId,
			closeModal,
			loadAssetDetails
		} = this.props;

		const payload = {
			final_label_forgo_status: determineStatus()
		};

		this.setState({
			isProcessing: true
		});

		postAssetStatus(assetId, payload)
			.then((response) => {
				if (response.status === 200) {
					toasterNotify('Request to forgo label approval sent', 'success');
				}
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'requesting to forgo approval label'),
					'error',
					error
				);
			})
			.finally(() => {
				loadAssetDetails(productId, assetId);
				closeModal();
			});
	}

	render() {
		const { closeModal } = this.props;
		const { display, isProcessing } = this.state;

		return (
			<BaseModal show={display} onCancel={closeModal} isSubmitting={isProcessing}>
				<BaseModal.Title>{getTitle()}</BaseModal.Title>
				{getAlertMessage()}
				<BaseModal.Submit
					disabled={isProcessing}
					onClick={() => {
						this.rejectAsset();
					}}
				>
					Confirm
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}
