import React, { useEffect, useState, VFC } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';


import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import { isEmptyObject } from '../../../utils/dataUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { validateToSchema } from '../../../utils/validationUtils';
import { postOrderHeaderAction } from '../../../services/ordersService';

const schema = yup.object().shape({
	comment: yup.string().trim().required('Comments To Publisher is required'),
});

interface RejectModalProps {
	show?: boolean;
	orderHeaderId: string;
	closeModal?: () => void;
}

const RejectModal: VFC<RejectModalProps> = ({ show, orderHeaderId, closeModal }) => {
	const history = useHistory();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [formValues, setFormValues] = useState<Record<string, any>>({});
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);

	useEffect(() => {
		if (show) {
			setFormValues({});
			setIsSubmitting(false);
			setShowAllErrors(false);
		}
	}, [show]);

	const submitReject = async () => {
		if (!isEmptyObject(validationErrors)) {
			return setShowAllErrors(true);
		}
		setIsSubmitting(true);

		try {
			const payload = {
				action: 'REJECT' as const,
				comment: formValues.comment,
			};
			await postOrderHeaderAction(orderHeaderId, payload);
			toasterNotify('Order rejected', 'success');
		} catch (error) {
			toasterNotify(
				createMessageForError(error as Error, 'rejecting order'),
				'error',
				error as Error,
			);
			setIsSubmitting(false);
		} finally {
			closeModal && closeModal();
			history.go(0);
		}
	};

	const validationErrors = validateToSchema(schema, formValues);

	return (
		<BaseModal show={show || false} onCancel={closeModal} isSubmitting={isSubmitting}>
			<BaseModal.Title>
				<span className="text-danger">Reject Order</span>
			</BaseModal.Title>
			<Forms
				onChange={(newValues) => setFormValues(newValues)}
				values={formValues}
				validationErrors={validationErrors}
				showAllErrors={showAllErrors}
				vertical
			>
				<Forms.TextArea id='comment' rows={4} maxLength={2000}>
					<Forms.Heading>Comments To Publisher</Forms.Heading>
				</Forms.TextArea>
			</Forms>
			<BaseModal.Submit variant='danger' onClick={() => submitReject()}>
				Confirm Rejection
			</BaseModal.Submit>
			<BaseModal.Cancel onClick={closeModal && (() => closeModal())}>Close</BaseModal.Cancel>
		</BaseModal>
	);
};

export default RejectModal;
