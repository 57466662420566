import React, { PropsWithChildren, ReactElement, ReactNode, useEffect, VFC } from 'react';
import { ToastContainer } from 'react-toastify';

import Header from './views/Header';
import Footer from './views/Footer';
import Loading from '../Loading/Loading';
import Fault from '../Fault/Fault';
import Title from '../Title/Title';

import './Page.css';


const createTitle = (children: ReactNode): ReactNode | null => {
	const getTitleElement = (children: ReactNode, level: number = 0): ReactNode => {
		let title;
		if (!children) { return null; }

		for (let node of React.Children.toArray(children)) {
			if (node && typeof node === 'object' && 'type' in node) {
				if (node.type === Title) {
					return node;
					// break;
				}
				if (node.props?.children != null && level < 2) {
					const title = getTitleElement(node.props.children, level + 1);
					if (title) { return title;}
				}
			}
		}
		return title;
	};
	const titleElement = getTitleElement(children);

	return (
		(titleElement &&
			typeof titleElement === 'object' &&
			'props' in titleElement &&
			(titleElement?.props.title || titleElement?.props.children)) ||
		null
	);
};

type PageSubcomponents = {
	FullPageCol: typeof FullPageCol;
	SplitPage: typeof SplitPage;
	MainCol: typeof MainCol;
	ContentContainer: typeof Content;
	SidebarCol: typeof SidebarCol;
	FillSpace: typeof FillSpace;
}
interface PageProps {
	isLoading?: boolean;
	fault?: unknown;
	documentTitle?: string;
	className?: string;
	containerClass?: string;
}
const Page: VFC<PropsWithChildren<PageProps>> & PageSubcomponents = ({
	children,
	isLoading = false,
	fault,
	documentTitle = '',
	className = '',
	containerClass = 'Page__width-limit container-fluid mt-5',
}: PropsWithChildren<PageProps>): ReactElement => {
	useEffect(() => {
		const title = (documentTitle != null && (documentTitle || '')) || createTitle(children);

		if (title != null) {
			document.title = title + ' - Nintendo Publisher Tool';
		}
	}, [documentTitle, children]);
	return (
		<div className={`Page${className ? ' ' + className : ''}`}>
			<Header />
			<ToastContainer />
			<main className={`Page__main-container Page__fill-space ${containerClass}`}>
				{isLoading ? <Loading /> : fault ? <Fault errorObject={fault} /> : children}
			</main>
			<Footer />
		</div>
	);
};

const FullPageCol = ({ children }: PropsWithChildren<unknown>): ReactElement =>
	<div className="Page__content Page__fill-space col-12">{children}</div>;

const SplitPage = ({ children }: PropsWithChildren<unknown>): ReactElement =>
	<div className="Page__split-page row mt-2">{children}</div>;

const MainCol = ({ children, cols = 9 }: PropsWithChildren<{cols: number}>): ReactElement =>
	<section className={`Page__fill-space col-12 col-md-${cols} mb-4 mb-md-0`}>{children}</section>;

const Content = ({ children }: PropsWithChildren<unknown>): ReactElement =>
	<div className="Page__content Page__fill-space">{children}</div>;

const SidebarCol = ({ children, cols = 3 }: PropsWithChildren<{cols: number}>): ReactElement =>
	<aside className={`Page__sidebar-column col-12 col-md-${cols}`}>{children}</aside>;

const FillSpace = ({
	as: Component = 'div',
	children,
	className,
}: PropsWithChildren<{ as?: keyof JSX.IntrinsicElements; className?: string }>) => {
	return (
		<Component className={'Page__fill-space' + (className ? ' ' + className : '')}>
			{children}
		</Component>
	);
};

Page.FullPageCol = FullPageCol;
Page.SplitPage = SplitPage;
Page.MainCol = MainCol;
Page.ContentContainer = Content;
Page.SidebarCol = SidebarCol;
Page.FillSpace = FillSpace;

export default Page;
