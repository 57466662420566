import { isAuthorized } from '../../utils/userUtils';
import { permConst } from '../../constants/permConst';

export const getPermissions = (permissions=[]) => ({
	viewVisibilityStatus: isAuthorized(permissions, [
		permConst.MARKETING_EVENT.VISIBILITY.EDIT.MANAGER,
		permConst.MARKETING_EVENT.VISIBILITY.VIEW.MANAGER,
		permConst.MARKETING_EVENT.VISIBILITY.VIEW.COORDINATOR
	]),
	allowViewSupportToCompanyAffiliated: isAuthorized(permissions, [
		permConst.MARKETING_EVENT.SUPPORT.VIEW.COMPANY
	]),
	allowEditSupportToCompanyAffiliated: isAuthorized(permissions, [
		permConst.MARKETING_EVENT.SUPPORT.EDIT.COMPANY
	]),
	allowNavigationToAny: isAuthorized(permissions, [
		permConst.MARKETING_EVENT.DETAILS.VIEW
	]),
	allowCreate: isAuthorized(permissions, [
		permConst.MARKETING_EVENT.ADD.ALL
	]),
	allowDeleteToManagerAffiliation: isAuthorized(permissions, [
		permConst.MARKETING_EVENT.DELETE.MANAGER
	]),
	allowEditToAffiliated: isAuthorized(permissions, [
		permConst.MARKETING_EVENT.EDIT.MANAGER,
		permConst.MARKETING_EVENT.DETAILS.EDIT.MANAGER,
		permConst.MARKETING_EVENT.VISIBILITY.EDIT.MANAGER,
		permConst.MARKETING_EVENT.MANAGER.EDIT.MANAGER
	]),
	allowEditToAny: isAuthorized(permissions, [
		permConst.MARKETING_EVENT.MANAGER.EDIT.ALL
	]),
	canSetActiveToAffiliated: isAuthorized(permissions, [
		permConst.MARKETING_EVENT.ACTIVATION.EDIT.MANAGER
	]),
	canSetVisibilityToAffiliated: isAuthorized(permissions, [
		permConst.MARKETING_EVENT.VISIBILITY.EDIT.MANAGER
	]),
	canSetEventDetailsToAffiliated: isAuthorized(permissions, [
		permConst.MARKETING_EVENT.DETAILS.EDIT.MANAGER
	]),
	canSetManagersToAffiliated: isAuthorized(permissions, [
		permConst.MARKETING_EVENT.MANAGER.EDIT.MANAGER
	]),
	canSetManagersToAny: isAuthorized(permissions, [
		permConst.MARKETING_EVENT.MANAGER.EDIT.ALL
	]),
});

export const getPermissionsForEvent = (userProfile, event) => {
	const {
		allowEditToAffiliated,
		allowEditToAny,
		allowDeleteToManagerAffiliation,
		allowViewSupportToCompanyAffiliated,
		allowEditSupportToCompanyAffiliated,
		canSetActiveToAffiliated,
		canSetVisibilityToAffiliated,
		canSetEventDetailsToAffiliated,
		canSetManagersToAffiliated,
		canSetManagersToAny
	} = getPermissions(userProfile.permissions);
	return {
		canEdit: allowEditToAny || (allowEditToAffiliated && !!event.manager_affiliation),
		canDelete: allowDeleteToManagerAffiliation && !!event.manager_affiliation,
		canSetActive: canSetActiveToAffiliated && !!event.manager_affiliation,
		canSetVisibility: canSetVisibilityToAffiliated && !!event.manager_affiliation,
		canSetEventDetails: canSetEventDetailsToAffiliated && !!event.manager_affiliation,
		canSetManagers: canSetManagersToAny || (canSetManagersToAffiliated && !!event.manager_affiliation),
		canViewSupport: allowViewSupportToCompanyAffiliated,
		canEditSupport: allowEditSupportToCompanyAffiliated
	};
};
