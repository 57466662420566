import React, { Component } from 'react';
import { FormControl, FormGroup, Row } from 'react-bootstrap';

import AsperaFileUpload from '../../../components/AsperaFileUpload/AsperaFileUpload';
import BaseModal from '../../../components/BaseModal/BaseModal';
import HelpBlock from '../../../components/HelpBlock/HelpBlock';
import { assetConst } from '../../../constants/assetConstants';
import { fileTransferConst } from '../../../constants/fileTransferConstants';
import { postAssetStatus } from '../../../services/productsService';
import { fileNameHasGameCode, getDATokenForMarketingEventAsset } from '../../../utils/assetUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';

import '../AssetDetails.css';


const {
	FINAL_LABEL_REJECTED,
	FINAL_LABEL_UPLOADED,
	NCL_ACCEPTED,
	NCL_ACCEPTED_WITH_CHANGES,
	NCL_PROOF_UPLOADING,
	NCL_PROOF_UPLOAD_FAILED,
	PRINT_PROOF_PENDING,
	PRINT_PROOF_REJECTED,
	REJECTED,
	SUBMITTED_TO_NCL,
} = assetConst.STATUS;

export default class RejectAssetModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			display: true,
			rejectionComment: '',
		};
		this.onComplete = this.onComplete.bind(this);
		this.updateFilesSelected = this.updateFilesSelected.bind(this);
		this.validateFileType = this.validateFileType.bind(this);
	}

	async submitRejectAsset() {
		const { asset } = this.props;
		const { rejectionComment, isFilesSelected, selectedFiles, validFileSelected } = this.state;
	
		if (rejectionComment === '') {
			this.setState({validation: 'Comments cannot be blank'});
			return;
		}
		
		if (isFilesSelected && !validFileSelected) {
			 return;
		}

		this.setState(
			{
				isProcessing: true
			});

		try {
			const payload = {
				review_status: this.determineStatus(),
				comment: rejectionComment
			};
			const response = await postAssetStatus(
				asset.asset_id,
				isFilesSelected ? {
					comment: rejectionComment,
					file_upload_type: assetConst.UPLOAD.NCL_PROOF,
					files: selectedFiles,
					review_status: NCL_PROOF_UPLOADING,
				} : payload,
				await getDATokenForMarketingEventAsset(asset, 'review'),
			);

			if (isFilesSelected) {
				this.setState({
					revisionId: response.data.revision_id.toString(),
					isUploadInitiated: true,
					prefetchedTransferSpecs: response.data.transfer_specs && {
						transfer_specs: response.data.transfer_specs
					},
				});
			}

			let message;
			switch (payload.review_status) {
				case PRINT_PROOF_REJECTED:
					message = 'Print proof rejected';
					break;
				case FINAL_LABEL_REJECTED:
					message = 'Final label rejected';
					break;
				case REJECTED:
				default:
					message = 'Asset rejected';
			}
			toasterNotify(message, 'success');
		} catch (error) {
			toasterNotify(
				createMessageForError(error, 'submitting your rejection'),
				'error',
				error
			);
			this.setState({isProcessing: false});
		} finally {
			if (!isFilesSelected) {
				this.onComplete();
			}
		}
	}

	updateFilesSelected(isFilesSelected) {
		this.setState({
			isFilesSelected: isFilesSelected,
		});
	}

	determineStatus() {
		const { validNextStatuses } = this.props;

		let status = REJECTED;
		if (
			validNextStatuses.includes(PRINT_PROOF_REJECTED)
		) {
			status = PRINT_PROOF_REJECTED;
		} else if (
			validNextStatuses.includes(FINAL_LABEL_REJECTED)
		) {
			status = FINAL_LABEL_REJECTED;
		}

		return status;
	}

	getAlertMessage() {
		const { asset, currentStatus } = this.props;

		let alertMessage =
			'By rejecting this Asset, a notification will be sent to the publisher that Asset has been rejected.';

		if (asset.asset_category === assetConst.CATEGORY.MARKETING_EVENT) {
			alertMessage =
				"Currently Marketing Events doesn't support notifications and none will be sent. " +
				'In the near future this will become a part of this feature.';
		} else if (currentStatus === PRINT_PROOF_PENDING) {
			alertMessage = 'Please enter comments around reasons for rejecting the Print Proof.';
		} else if (currentStatus === FINAL_LABEL_UPLOADED) {
			alertMessage =
				'You are about to reject the final label files uploaded which will allow the publisher to upload a new set of final label files.';
		} else if ([SUBMITTED_TO_NCL, NCL_ACCEPTED, NCL_ACCEPTED_WITH_CHANGES, NCL_PROOF_UPLOAD_FAILED].includes(currentStatus)) {
			alertMessage = '';
		}

		return (
			alertMessage && (
				<div className="alert alert-danger">{alertMessage}</div>
			)
		);
	}

	getTitle() {
		const { currentStatus } = this.props;

		let title = 'Reject Asset';

		if (currentStatus === PRINT_PROOF_PENDING) {
			title = 'Reject for Production';
		} else if (currentStatus === FINAL_LABEL_UPLOADED) {
			title = 'Reject Final Label';
		} else if ([SUBMITTED_TO_NCL, NCL_ACCEPTED, NCL_ACCEPTED_WITH_CHANGES, NCL_PROOF_UPLOAD_FAILED].includes(currentStatus)) {
			title = 'NCL Reject';
		}

		return title;
	}

	validateFileType(selectedFiles) {
		const { product } = this.props;
		const isValid =
			selectedFiles &&
			selectedFiles.length > 0 &&
			selectedFiles.every((item) => fileNameHasGameCode(item.file_name, product.game_code));
		this.setState({
			validFileSelected: isValid,
			selectedFiles: isValid && selectedFiles
		});
	}

	onComplete() {
		const { productId, asset, closeModal, loadAssetDetails } = this.props;

		loadAssetDetails(productId, asset.asset_id);
		closeModal();
	}

	render() {
		const { asset, closeModal, userProfile, product } = this.props;
		const {
			display,
			rejectionComment,
			isFilesSelected,
			isProcessing,
			isUploadInitiated,
			prefetchedTransferSpecs,
			revisionId,
			validation,
			validFileSelected,
		} = this.state;

		return (
			<BaseModal show={display} onCancel={closeModal} isSubmitting={isProcessing}>
				<BaseModal.Title>{this.getTitle()}</BaseModal.Title>
				{this.getAlertMessage()}
				<div className="reject-asset-modal">
					<FormGroup as={Row}>
						<div className="form-label reject-asset-label col-4 text-sm-right">
							<strong>Rejection Comments</strong>
						</div>
						<div className="col-8">
							<FormControl
								as="textarea"
								rows="8"
								value={rejectionComment}
								onChange={(e) => {
									this.setState({
										rejectionComment: e.target.value,
										validation: null,
									});
								}}
								maxLength={2000}
							/>
							{validation && <HelpBlock variant="danger">{validation}</HelpBlock>}
						</div>
					</FormGroup>
					{[
						SUBMITTED_TO_NCL,
						NCL_ACCEPTED,
						NCL_ACCEPTED_WITH_CHANGES,
						NCL_PROOF_UPLOAD_FAILED,
					].includes(asset.review_status) && (
						<FormGroup as={Row}>
							<div className="form-label reject-asset-label col-4 text-sm-right">
								<strong>Proof file</strong>
							</div>
							<div className="col-8">
								<AsperaFileUpload
									updateFilesSelected={this.updateFilesSelected}
									userProfile={userProfile}
									entityType={
										fileTransferConst.ENTITY_TYPE.PRODUCT_ASSET_REVISION
									}
									entityId={revisionId}
									isUploadInitiated={isUploadInitiated}
									onCompleteFunction={this.onComplete}
									validateFileType={this.validateFileType}
									prefetchedTransferSpecs={prefetchedTransferSpecs}
									singleFileOnly
								/>

								{isFilesSelected && !validFileSelected ? (
									<HelpBlock variant="danger">
										Selected file must include game code ({product.game_code})
										in the file name.{' '}
									</HelpBlock>
								) : (
									<HelpBlock>
										Optional. Selected file must include game code (
										{product.game_code}) in the file name.
									</HelpBlock>
								)}
							</div>
						</FormGroup>
					)}
				</div>
				<BaseModal.Submit
					disabled={isProcessing}
					onClick={() => {
						this.submitRejectAsset();
					}}
				>
					Confirm
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}
