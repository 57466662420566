import React from 'react';
import { Col, FormLabel, Form, FormControl, FormGroup, Row } from 'react-bootstrap';

import BaseModal from '../../../components/BaseModal/BaseModal';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { isValidGameCode } from '../../../utils/stringUtils';
import { putGameCode } from '../../../services/productsService';

import '../ProductProfile.css';

export default class EditGameCodeModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			display: true,
			editedGameCode: this.props.productSummary.game_code,
			editedUniqueId: this.props.productSummary.unique_id,
			isLoading: false
		};
		this.updateValue = this.updateValue.bind(this);
		this.toggleLoading = this.toggleLoading.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	isFormValid() {
		const { editedGameCode } = this.state;
		if (editedGameCode) {
			return isValidGameCode(editedGameCode);
		} else return false;
	}

	updateGameCode() {
		this.toggleLoading();
		const { productSummary } = this.props;
		const payload = {
			gamecode: this.state.editedGameCode,
			unique_id: this.state.editedUniqueId
		};
		putGameCode(
			productSummary.product_id,
			JSON.stringify(payload)
		)
			.then((response) => {
				if (response.status === 200) {
					const updatedProductSummary = Object.assign(productSummary);
					updatedProductSummary.game_code = this.state.editedGameCode;
					this.props.updateGameCodeField(updatedProductSummary);
					this.props.loadProductSummary(productSummary.product_id);
					toasterNotify('Game Code Updated', 'success');
				}
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'updating product'),
					'error',
					error
				);
			})
			.finally(() => {
				this.props.closeModal();
			});
	}

	updateValue(e) {
		this.setState({ [e.target.id]: e.target.value });
	}

	render() {
		const { display } = this.state;
		const { closeModal } = this.props;
		return (
			<BaseModal show={display} onCancel={closeModal} isSubmitting={this.state.isLoading}>
				<BaseModal.Title>Edit Game Code</BaseModal.Title>
				<Form className="gameCodeForm">
					<FormGroup as={Row}>
						<FormLabel column id="title" className="col-sm-4 text-sm-right">
							Product Name
						</FormLabel>
						<Col sm={8}>
							<FormControl as="div" readOnly plaintext>
								{this.props.productSummary.game_name}
							</FormControl>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<FormLabel column id="platform" className="col-sm-4 text-sm-right">
							Platform
						</FormLabel>
						<Col sm={8}>
							<FormControl as="div" readOnly plaintext>
								{this.props.productSummary.platform_name}
							</FormControl>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<FormLabel column id="deliveryFormat" className="col-sm-4 text-sm-right">
							Delivery Format
						</FormLabel>
						<Col sm={8}>
						<FormControl as="div" readOnly plaintext>
								{this.props.productSummary.product_distribution_type}
							</FormControl>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<FormLabel column id="gameCode" className="col-sm-4 text-sm-right">
							Game Code
						</FormLabel>
						<Col sm={8}>
							<FormControl
								type="text"
								id="editedGameCode"
								defaultValue={this.props.productSummary.game_code}
								onChange={(e) => {
									this.updateValue(e);
								}}
							/>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<FormLabel column id="uniqueId" className="col-sm-4 text-sm-right">
							Unique ID
						</FormLabel>
						<Col sm={8}>
							<FormControl
								type="text"
								id="editedUniqueId"
								defaultValue={this.props.productSummary.unique_id}
								onChange={(e) => {
									this.updateValue(e);
								}}
							/>
						</Col>
					</FormGroup>
					{this.props.productSummary && this.props.productSummary.card_type && (
						<FormGroup as={Row}>
							<FormLabel column id="cardType" className="col-sm-4 text-sm-right">
								Card Type
							</FormLabel>
							<Col sm={8}>
								<FormControl as="div" readOnly plaintext>
									{this.props.productSummary.card_type}
								</FormControl>
							</Col>
						</FormGroup>
					)}
				</Form>
				<BaseModal.Submit
					onClick={() => this.updateGameCode()}
					disabled={!this.isFormValid()}
				>
					Save
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}
