import React from 'react';
import { Alert, Col, Form, FormLabel, FormCheck, FormGroup, Row } from 'react-bootstrap';

import BaseModal from '../../../components/BaseModal/BaseModal';
import { putSkuRequired } from '../../../services/companiesService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { connectQueryClient } from '../../../hooks/queryHooks';

import '../CompanyProfile.css';

export class SkuRequiredModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			skuRequiredChecked: this.props.skuCheck,
			isLoading: false
		};
		this.toggleSkuRequired = this.toggleSkuRequired.bind(this);
		this.updateSkuRequired = this.updateSkuRequired.bind(this);
		this.toggleLoading = this.toggleLoading.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	toggleSkuRequired() {
		this.setState({
			skuRequiredChecked: !this.state.skuRequiredChecked
		});
	}

	updateSkuRequired() {
		this.toggleLoading();
		const { companyProfile } = this.props;
		const companyId = companyProfile.company_information.ndid_company_id;
		let newValue = 0;
		if (this.state.skuRequiredChecked) {
			newValue = 1;
		}

		putSkuRequired(companyId, newValue)
			.then((response) => {
				if (response.status === 200) {
					this.props.updateCompanyInfo(
						'sku_number_required',
						newValue
					);
					toasterNotify('Updated Part/SKU Required', 'success');
				}
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'updating part/SKU required'),
					'error',
					error,
				);
			})
			.finally(() => {
				this.props.closeFunction();
				this.props.queryClient.invalidateQueries(['getCompanyProfile', companyId]);
			});

	}

	render() {
		return (
			<BaseModal
				show={true}
				onCancel={this.props.closeFunction}
				isSubmitting={this.state.isLoading}
			>
				<BaseModal.Title>Edit Part/SKU Number Required</BaseModal.Title>
				<Alert variant="info">
					Selecting YES will make your part number a required entry for each product
					before orders can be entered. Part numbers are added in the Product Summary on
					an individual products basis.
				</Alert>
				<Form className="sku-form">
					<FormGroup as={Row}>
						<FormLabel column sm={6} className="text-sm-right pt-0">
							<strong>Part/SKU Number Required</strong>
						</FormLabel>
						<Col sm={6}>
							<FormCheck
								type="checkbox"
								id="skuCheckBox"
								defaultChecked={this.state.skuRequiredChecked}
								onChange={this.toggleSkuRequired}
								label="YES"
							/>
						</Col>
					</FormGroup>
				</Form>
				<BaseModal.Submit onClick={this.updateSkuRequired}>Update</BaseModal.Submit>
			</BaseModal>
		);
	}
}

export default connectQueryClient(
	SkuRequiredModal
);
