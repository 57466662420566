import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import rootReducer from './reducers/rootReducer';
import { loadReduxStorage, saveReduxStorage } from '../utils/reduxUtils';

export interface RootState {
	authReducer: { userProfile: UserProfile };
	featureFlagReducer: {
		flags: {
			status: {
				name: string;
				alwaysOn: boolean;
				defaultOn: boolean;
				override: boolean | null;
			}[];
		};
	};
	referenceReducer: Record<string, {
		content: any;
		meta: {
			status?: 'COMPLETED' | 'LOADING';
			correlationId?: string;
			response?: Record<string, any>;
			timestamp?: string;
		};
	}>;
	uiReducer: {
		uiStates: Record<string, Record<string, string>>;
		tabStates: Record<string, string>;
	}
}

let store: any = null;

export default function configureStore() {
	const initialState = loadReduxStorage();
	let middleware = [thunk];

	// add logger to middleware mix on lower environments
	if (/^(?!prod|preprod)/.test((global as any).config.authEnv)) {
		const logger = createLogger({
			collapsed: (getState, action, logEntry) => !(logEntry?.error)
		});
		middleware.push(logger as any);
	}
	store = createStore(rootReducer, initialState, applyMiddleware.apply(null, middleware));

	// set up subscribers
	store.subscribe(() => {
		const state = store.getState();
		saveReduxStorage({ authReducer: state.authReducer, uiReducer: state.uiReducer, referenceReducer: state.referenceReducer });
	});

	return store;
}

export function getStore() {
	return store;
}
