import React, { Component } from 'react';
import { Col, Form, FormGroup, Row } from 'react-bootstrap';

import * as componentUtils from '../../../../utils/componentUtils';
import * as arrayUtils from '../../../../utils/arrayUtils';
import * as editGameConfigurationModalConstants from './EditGameConfigurationModalConstants';
import SectionTitle from '../../../../components/SectionTitle/SectionTitle';

class GameConfiguration3ds extends Component {
	constructor(props) {
		super(props);
		this.state = {
			payload: this.props.gameConfiguration
		};
	}

	getBody() {
		const { submissionDetails } = this.props;
		const ctrktr = this.state.payload;
		const programSizeInBytes = componentUtils.generateOptions(
			editGameConfigurationModalConstants.programSizeInBytes
		);

		//if payloads value is not in the list of options call handleinput change with default value in list
		const backupMemorySizeInBytes = componentUtils.generateOptions(
			editGameConfigurationModalConstants.backupMemorySizeInBytes
		);
		const nintendoNetwork = componentUtils.generateOptions(
			editGameConfigurationModalConstants.nintendoNetwork
		);
		const cameraSupport = componentUtils.generateOptions(
			editGameConfigurationModalConstants.cameraSupport
		);
		const multiCardPlayers = componentUtils.generateOptions(
			arrayUtils.getIntegerArrayStartOne(65)
		);
		const singleCardPlayers = componentUtils.generateOptions(
			arrayUtils.getIntegerArrayStartOne(4)
		);
		const microphoneSupport = componentUtils.generateOptions(
			editGameConfigurationModalConstants.microphoneSupport
		);
		const ggid = componentUtils.generateOptions(
			editGameConfigurationModalConstants.ggid
		);

		//Validate the current game configuration and update if invalid value
		if (
			!editGameConfigurationModalConstants.backupMemorySizeInBytes.includes(
				ctrktr.StorageSpecifications.BackupMemorySize
			)
		) {
			this.props.handleInputChange(
				['StorageSpecifications', 'BackupMemorySize'],
				editGameConfigurationModalConstants.backupMemorySizeInBytes[0]
			);
		}

		if (
			!editGameConfigurationModalConstants.nintendoNetwork.includes(
				ctrktr.FeaturesUsed.NintendoNetwork
			)
		) {
			this.props.handleInputChange(
				['FeaturesUsed', 'NintendoNetwork'],
				editGameConfigurationModalConstants.nintendoNetwork[0]
			);
		}

		if (
			!editGameConfigurationModalConstants.cameraSupport.includes(
				ctrktr.FeaturesUsed.CameraSupport
			)
		) {
			this.props.handleInputChange(
				['FeaturesUsed', 'CameraSupport'],
				editGameConfigurationModalConstants.cameraSupport[0]
			);
		}

		if (
			!arrayUtils
				.getIntegerArrayStartOne(65)
				.includes(ctrktr.FeaturesUsed.MultiCardPlayers)
		) {
			this.props.handleInputChange(
				['FeaturesUsed', 'MultiCardPlayers'],
				1
			);
		}

		if (
			!arrayUtils
				.getIntegerArrayStartOne(4)
				.includes(ctrktr.FeaturesUsed.SingleCardPlayers)
		) {
			this.props.handleInputChange(
				['FeaturesUsed', 'SingleCardPlayers'],
				1
			);
		}

		if (
			!editGameConfigurationModalConstants.microphoneSupport.includes(
				ctrktr.FeaturesUsed.MicrophoneSupport
			)
		) {
			this.props.handleInputChange(
				['FeaturesUsed', 'MicrophoneSupport'],
				editGameConfigurationModalConstants.microphoneSupport[0]
			);
		}

		if (
			!editGameConfigurationModalConstants.ggid.includes(
				ctrktr.FeaturesUsed.GGID
			)
		) {
			this.props.handleInputChange(
				['FeaturesUsed', 'GGID'],
				editGameConfigurationModalConstants.ggid[0]
			);
		}

		return (
			<div>
				<Form className="edit_game_configuration_3ds_form">
					<FormGroup as={Row}>
						<label
							id="submissionVersion"
							className="col-sm-4 control-label text-sm-right"
						>
							Submission Version
						</label>
						<Col sm={6}>
							{'v' +
								submissionDetails.product_release_version +
								'.' +
								submissionDetails.product_submission_version}
						</Col>
					</FormGroup>
					{componentUtils.getSelector(
						['StorageSpecifications', 'ProgramSizeInBytes'],
						'Program Size in Bytes',
						programSizeInBytes,
						this.props.handleInputChange,
						ctrktr.StorageSpecifications.ProgramSizeInBytes
					)}
					{componentUtils.getCheckbox(
						['StorageSpecifications', 'BackupMemoryTypeIsFlash'],
						'Backup Memory Type Is Flash',
						this.props.handleInputChange,
						ctrktr.StorageSpecifications.BackupMemoryTypeIsFlash
					)}
					{componentUtils.getSelector(
						['StorageSpecifications', 'BackupMemorySize'],
						'Backup Memory Size in Bytes',
						backupMemorySizeInBytes,
						this.props.handleInputChange,
						ctrktr.StorageSpecifications.BackupMemorySize
					)}
					{componentUtils.getTextBox(
						['StorageSpecifications', 'SystemBlocksUsed'],
						'System Blocks Used',
						this.props.handleInputChange,
						ctrktr.StorageSpecifications.SystemBlocksUsed
					)}
					<SectionTitle>Features Used</SectionTitle>
					{componentUtils.getSelector(
						['FeaturesUsed', 'NintendoNetwork'],
						'Nintendo Network',
						nintendoNetwork,
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.NintendoNetwork
					)}
					{componentUtils.getSelector(
						['FeaturesUsed', 'CameraSupport'],
						'Camera Support',
						cameraSupport,
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.CameraSupport
					)}
					{componentUtils.getCheckbox(
						['FeaturesUsed', 'OneTimeROM'],
						'One-Time ROM',
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.OneTimeROM
					)}
					{componentUtils.getCheckbox(
						['FeaturesUsed', 'SDCardSupport'],
						'SD Card Support',
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.SDCardSupport
					)}
					{componentUtils.getCheckbox(
						['FeaturesUsed', 'MultiCardSupport'],
						'Multi Card Support',
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.MultiCardSupport
					)}
					{componentUtils.getSelector(
						['FeaturesUsed', 'MultiCardPlayers'],
						'Multi Card Players',
						multiCardPlayers,
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.MultiCardPlayers
					)}
					{componentUtils.getCheckbox(
						['FeaturesUsed', 'SingleCardSupport'],
						'Single Card Support',
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.SingleCardSupport
					)}
					{componentUtils.getSelector(
						['FeaturesUsed', 'SingleCardPlayers'],
						'Single Card Players',
						singleCardPlayers,
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.SingleCardPlayers
					)}
					{componentUtils.getSelector(
						['FeaturesUsed', 'MicrophoneSupport'],
						'Microphone Support',
						microphoneSupport,
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.MicrophoneSupport
					)}
					{componentUtils.getCheckbox(
						['FeaturesUsed', 'NetFrontBrowser'],
						'NetFront Browser',
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.NetFrontBrowser
					)}
					{componentUtils.getCheckbox(
						['FeaturesUsed', 'LCFont'],
						'LC Font',
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.LCFont
					)}
					{componentUtils.getCheckbox(
						['FeaturesUsed', 'MobiclipSupport'],
						'Mobiclip Support',
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.MobiclipSupport
					)}
					{componentUtils.getCheckbox(
						['FeaturesUsed', 'LiteSpeech'],
						'Lite Speech',
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.LiteSpeech
					)}
					{componentUtils.getCheckbox(
						['FeaturesUsed', 'DecumaHandwriting'],
						'Decuma Handwriting',
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.DecumaHandwriting
					)}
					{componentUtils.getCheckbox(
						['FeaturesUsed', 'TouchScreen'],
						'Touch Screen',
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.TouchScreen
					)}
					{componentUtils.getCheckbox(
						['FeaturesUsed', 'Stereoscopic3D'],
						'Stereoscopic 3D',
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.Stereoscopic3D
					)}
					{componentUtils.getCheckbox(
						['FeaturesUsed', 'AccelerometerUse'],
						'Acceleromater User',
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.AccelerometerUse
					)}
					{componentUtils.getCheckbox(
						['FeaturesUsed', 'CEPD'],
						'CEPD',
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.CEPD
					)}
					{componentUtils.getCheckbox(
						['FeaturesUsed', 'StreetPass'],
						'Street Pass',
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.StreetPass
					)}
					{componentUtils.getTextBox(
						['FeaturesUsed', 'StreetPassDescription'],
						'Street Pass Description',
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.StreetPassDescription
					)}
					{componentUtils.getCheckbox(
						['FeaturesUsed', 'IRPortUsage'],
						'IR Port Usage',
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.IRPortUsage
					)}
					{componentUtils.getSelector(
						['FeaturesUsed', 'GGID'],
						'GGID',
						ggid,
						this.props.handleInputChange,
						ctrktr.FeaturesUsed.GGID
					)}
					<SectionTitle>Wii U Communication</SectionTitle>
					{componentUtils.getCheckbox(
						['WiiUCommunication', 'CommunicationWithWiiUTitle'],
						'Communication with Wii U Title',
						this.props.handleInputChange,
						ctrktr.WiiUCommunication.CommunicationWithWiiUTitle
					)}
					{componentUtils.getTextBox(
						[
							'WiiUCommunication',
							'CommunicationWithWiiUTitleDescription'
						],
						'Communication with Wii U Title Description',
						this.props.handleInputChange,
						ctrktr.WiiUCommunication
							.CommunicationWithWiiUTitleDescription
					)}
					{componentUtils.getTextBox(
						['WiiUCommunication', 'AssociatedWiiUTitleAndGameCode'],
						'Associated Wii U Title and Game Code (if available)',
						this.props.handleInputChange,
						ctrktr.WiiUCommunication.AssociatedWiiUTitleAndGameCode
					)}
					<SectionTitle>Online Features</SectionTitle>
					{componentUtils.getCheckbox(
						['OnlineFeatures', 'SpotPass'],
						'Spot Pass',
						this.props.handleInputChange,
						ctrktr.OnlineFeatures.SpotPass
					)}
					{componentUtils.getTextBox(
						['OnlineFeatures', 'SpotPassDescription'],
						'Spot Pass Description',
						this.props.handleInputChange,
						ctrktr.OnlineFeatures.SpotPassDescription
					)}
					{componentUtils.getCheckbox(
						['OnlineFeatures', 'OnlineMultiPlay'],
						'Online Multi Play',
						this.props.handleInputChange,
						ctrktr.OnlineFeatures.OnlineMultiPlay
					)}
					{componentUtils.getTextBox(
						['OnlineFeatures', 'OnlineMultiPlayDescription'],
						'Online Multi Play Description',
						this.props.handleInputChange,
						ctrktr.OnlineFeatures.OnlineMultiPlayDescription
					)}
					{componentUtils.getCheckbox(
						[
							'OnlineFeatures',
							'OnlineCommerceAdditionalOnlineContentsMicrotransactions'
						],
						'Online Commerce (Additional Online Contents/Microtransactions)',
						this.props.handleInputChange,
						ctrktr.OnlineFeatures
							.OnlineCommerceAdditionalOnlineContentsMicrotransactions
					)}
					{componentUtils.getTextBox(
						['OnlineFeatures', 'OnlineCommerceDescription'],
						'Online Commerce Description',
						this.props.handleInputChange,
						ctrktr.OnlineFeatures.OnlineCommerceDescription
					)}
					{componentUtils.getCheckbox(
						[
							'OnlineFeatures',
							'ConsumableAdditionalOnlineContents'
						],
						'Consumable AOC',
						this.props.handleInputChange,
						ctrktr.OnlineFeatures.ConsumableAdditionalOnlineContents
					)}
					{componentUtils.getTextBox(
						[
							'OnlineFeatures',
							'ConsumableAdditionalOnlineContentsDescription'
						],
						'Consumable AOC Description',
						this.props.handleInputChange,
						ctrktr.OnlineFeatures
							.ConsumableAdditionalOnlineContentsDescription
					)}
					{componentUtils.getCheckbox(
						['OnlineFeatures', 'UserGeneratedContentSimple'],
						'UGC (User Generated Content) - Simple',
						this.props.handleInputChange,
						ctrktr.OnlineFeatures.UserGeneratedContentSimple
					)}
					{componentUtils.getTextBox(
						[
							'OnlineFeatures',
							'UserGeneratedContentSimpleDescription'
						],
						'UGC Simple Description',
						this.props.handleInputChange,
						ctrktr.OnlineFeatures
							.UserGeneratedContentSimpleDescription
					)}
					{componentUtils.getCheckbox(
						['OnlineFeatures', 'RichUserGeneratedContent'],
						'Rich UGC',
						this.props.handleInputChange,
						ctrktr.OnlineFeatures.RichUserGeneratedContent
					)}
					{componentUtils.getTextBox(
						[
							'OnlineFeatures',
							'RichUserGeneratedContentDescription'
						],
						'Rich UGC Description',
						this.props.handleInputChange,
						ctrktr.OnlineFeatures
							.RichUserGeneratedContentDescription
					)}
					{componentUtils.getCheckbox(
						['OnlineFeatures', 'OtherOnlineFunctionality'],
						'Other Online Functionality',
						this.props.handleInputChange,
						ctrktr.OnlineFeatures.OtherOnlineFunctionality
					)}
					{componentUtils.getTextBox(
						[
							'OnlineFeatures',
							'OtherOnlineFunctionalityDescription'
						],
						'Other Online Functionality Description',
						this.props.handleInputChange,
						ctrktr.OnlineFeatures
							.OtherOnlineFunctionalityDescription
					)}
					{componentUtils.getCheckbox(
						['OnlineFeatures', 'PlanforPatching'],
						'Plan for Patching',
						this.props.handleInputChange,
						ctrktr.OnlineFeatures.PlanforPatching
					)}
					{componentUtils.getTextBox(
						['OnlineFeatures', 'PlanforPatchingDescription'],
						'Plan for Patching Description',
						this.props.handleInputChange,
						ctrktr.OnlineFeatures.PlanforPatchingDescription
					)}
					{componentUtils.getCheckbox(
						['OnlineFeatures', 'MiiUsage'],
						'Mii Usage',
						this.props.handleInputChange,
						ctrktr.OnlineFeatures.MiiUsage
					)}
				</Form>
			</div>
		);
	}

	render() {
		return <div>{this.getBody()}</div>;
	}
}

export default GameConfiguration3ds;
