import { RootState, getStore } from '../store/store';

const LOCAL_STORAGE_KEY = 'noaPDB';

export const loadReduxStorage = () => {
	try {
		const state = localStorage.getItem(LOCAL_STORAGE_KEY);
		if (!state) {
			return undefined;
		}
		const { redirectAfterLogin, date, ...filteredState} = JSON.parse(state);
		return filteredState;
	} catch (err) {
		return undefined;
	}
};

interface StoredRootState extends Partial<RootState> {
	date?: Date | string;
}

export const cleanStateForStorage = (state: StoredRootState): StoredRootState => {
	const cleanReferences: Record<string, any> = {};
	for (let type in state.referenceReducer) {
		if (state?.referenceReducer[type]?.meta?.status === 'COMPLETED') {
			cleanReferences[type] = state.referenceReducer[type];
		}
	}
	return {...state,
		referenceReducer: cleanReferences};
};

export const saveReduxStorage = (state: StoredRootState) => {
	try {
		const storedState: StoredRootState = { ...cleanStateForStorage(state), date: new Date() };
		localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(storedState));
	} catch (err) {
		/* eslint no-console: 0 */
		console.log('Error when writing state to local storage', err);
	}
};

export const clearReduxStorage = () => {
	localStorage.removeItem(LOCAL_STORAGE_KEY);
};

export const hasUserProfile = () => {
	const state = getStore().getState();
	return state && 'authReducer' in state;
};


export const setPostLoginPath = (path: string) => {
	localStorage.setItem(
		LOCAL_STORAGE_KEY,
		JSON.stringify({ redirectAfterLogin: path, date: new Date() }),
	);
};

export const getPostLoginPath = () => {
	const storageObject = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
	return storageObject &&
		storageObject.date &&
		Date.now() < new Date(storageObject.date).valueOf() + 1000 * 60 * 15
		? storageObject.redirectAfterLogin
		: null;
};
