import React, { Component, Fragment } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';

import FAIcon from '../../components/FAIcon/FAIcon';
import Loading from '../../components/Loading/Loading';
import LoadingText from '../../components/Loading/LoadingText';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import Page from '../../components/Page/Page';
import Panel from '../../components/Panel/Panel';
import PropertyDisplay from '../../components/PropertyDisplay/PropertyDisplay';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import Title from '../../components/Title/Title';
import { orderConst } from '../../constants/orderConstants';
import { permConst } from '../../constants/permConst';
import { getCreateOrderPageResources, getOrder, getPrice } from '../../services/ordersService';
import { numberWithCommas } from '../../utils/currencyUtils';
import { safeEval } from '../../utils/dataUtils';
import { dateFormat, formatDate, parseDateString } from '../../utils/dateUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { isAuthorized } from '../../utils/userUtils';
import ConfirmOrderModal from './modals/ConfirmOrderModal';


function mapStateToProps(state) {
	return {
		userProfile: state.authReducer.userProfile
	};
}

function generateDataField(header, value) {
	return (
		<PropertyDisplay label={header}>{value}</PropertyDisplay>
	);
}

function formatShipTo(shipTo) {
	let formattedShipTo = '';
	formattedShipTo += shipTo.name + ', ';
	formattedShipTo += shipTo.street_line_1 + ', ';
	formattedShipTo += shipTo.city + ', ';
	formattedShipTo += shipTo.region + ', ';
	formattedShipTo += shipTo.postal_code;
	return formattedShipTo;
}

export class OrderConfirm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoadingOrder: false,
			isLoadingResources: false,
			showModal: false,
			prices: {},
			pricesLoadedCount: 0,
			order: {
				order_details: [],
				consignee_info: []
			}
		};
		this.toggleModal = this.toggleModal.bind(this);
	}

	componentDidMount() {
		this.getOrder();
		this.getResources();
	}

	getEbsPrice(productId, quantity) {
		const { order_header_id } = this.state.order;
		if (productId && quantity > 0) {
			getPrice(productId, order_header_id, quantity)
				.then((response) => {
					let prices = this.state.prices;
					prices[`${productId}`] = response.data.price;
					this.setState({
						prices: prices,
						pricesLoadedCount: this.state.pricesLoadedCount + 1
					});
				})
				.catch((error) => {
					toasterNotify(
						createMessageForError(error, 'retrieving prices'),
						'error',
						error
					);
				});
		}
	}

	bundlePriceCalls() {
		let priceCalls = [];
		this.state.order.order_details.forEach((detail) => {
			if (detail.product_id && detail.quantity > 0) {
				priceCalls.push(
					this.getEbsPrice(detail.product_id, detail.quantity)
				);
			}
		});
		if (priceCalls.length > 0) {
			Promise.all(priceCalls);
		} else {
			/* this terminates LoadModal */
			this.setState({
				pricesLoadedCount: this.state.order_details.length
			});
		}
	}

	getResources() {
		const { updateResources } = this.props;

		if (this.props.resources && this.props.resources.length < 1) {
			this.setState({ isLoadingResources: true });

			getCreateOrderPageResources()
				.then((response) => {
					updateResources(response.data);
					this.setState({ isLoadingResources: false });
				})
				.catch((error) => {
					toasterNotify(
						createMessageForError(error, 'retrieving ordering resources'),
						'error',
						error
					);
					this.setState({ isLoadingResources: false });
				});
		}
	}

	getOrder() {
		const orderHeaderId = this.props.orderHeaderId;

		this.setState({ isLoadingOrder: true });

		getOrder(orderHeaderId)
			.then((response) => {
				this.setState(
					{
						order: this.formatOrder(response.data),
						isLoadingOrder: false,
						getOrderApiPending: false
					},
					() => {
						this.bundlePriceCalls();
					}
				);
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'loading order'),
					'error',
					error
				);
				this.setState({
					isLoadingOrder: false,
					getOrderApiPending: false,
					getPageResourcesApiPending: false
				});

			});
	}

	formatOrder(order) {
		let orderComments = '',
			shippingInstructions = '';

		order.order_comments.forEach((c) => {
			if (c.type === orderConst.COMMENT.PUBLISHER) {
				orderComments = c.comment;
			} else if (c.type === orderConst.COMMENT.SHIPPING_INFO) {
				shippingInstructions = c.comment;
			}
		});

		const formattedOrder = {
			order_header_id: order.order_header_id,
			company_name: order.company_name,
			platform_code: order.platform_code,
			publisher_po_number: order.publisher_po_number,
			order_comment: orderComments,
			order_status: order.order_status,
			ndid_company_id: order.ndid_company_id,
			order_details: order.order_details,
			created_by_ndid: order.created_by_ndid,
			created_by_user_name: order.created_by_user_name,
			created_by_company_name: order.created_by_company_name,
			created_by_date: order.created_by_date,
			modified_by_ndid: order.modified_by_ndid,
			modified_by_user_name: order.modified_by_user_name,
			modified_by_company_name: order.modified_by_company_name,
			modified_by_date: order.modified_by_date,
			port_of_entry_code: order.port_of_entry_code,
			freight_forwarder_code: order.freight_forwarder_code,
			packout_facility_id: order.packout_facility_id,
			packout_facility_contact_name: order.packout_facility_contact_name,
			packout_facility_contact_phone_number:
				order.packout_facility_contact_phone_number,
			shipping_instructions: shippingInstructions
		};

		if (order.consignee_id) {
			formattedOrder.consignee_info = {
				consignee_contact_name: order.consignee_contact_name,
				consignee_id: order.consignee_id,
				consignee_company_id: order.consignee_company_id,
				consignee_company_name: order.consignee_company_name,
				consignee_contact_email: order.consignee_contact_email,
				consignee_contact_phone_number:
					order.consignee_contact_phone_number,
				consignee_address_street_line_1:
					order.consignee_address_street_line_1,
				consignee_address_city: order.consignee_address_city,
				consignee_address_region: order.consignee_address_region,
				consignee_address_postal_code:
					order.consignee_address_postal_code,
				consignee_address_country_code:
					order.consignee_address_country_code,
				consignee_service_level: order.consignee_service_level,
				consignee_carrier_account_number:
					order.consignee_carrier_account_number
			};
		}

		return formattedOrder;
	}

	lookupResources(collection, searchName, searchValue, fieldName) {
		let result = null;
		if (collection && collection.length > 0 && searchValue) {
			collection.some((d) => {
				if (d && d[searchName] === searchValue) {
					if (fieldName) {
						result = d[fieldName];
					} else {
						result = d;
					}
					return true;
				}
				return false;
			});
		}
		return result;
	}

	lookupReadableResources() {
		const { resources } = this.props;
		const {
			platform_code,
			freight_forwarder_code,
			port_of_entry_code,
			packout_facility_id,
			consignee_info
		} = this.state.order;

		let consigneeCountryName = consignee_info
			? consignee_info.consignee_address_country_code
			: '';

		let platformName = platform_code,
			freightForwarderName = freight_forwarder_code,
			portOfEntryName = port_of_entry_code,
			packoutFacility = null;

		if (resources) {
			platformName = this.lookupResources(
				resources.orderable_platforms,
				'platform_code',
				platform_code,
				'platform_name'
			);
			freightForwarderName = this.lookupResources(
				resources.freight_forwarders,
				'value',
				freight_forwarder_code,
				'name'
			);
			portOfEntryName = this.lookupResources(
				resources.customs_entry_ports,
				'value',
				port_of_entry_code,
				'name'
			);
			packoutFacility = this.lookupResources(
				resources.ship_tos,
				'packout_facility_id',
				packout_facility_id
			);
			consigneeCountryName = this.lookupResources(
				resources.country_codes,
				'country_code',
				consigneeCountryName,
				'country_name'
			);
		}

		return {
			platformName,
			freightForwarderName,
			portOfEntryName,
			packoutFacility,
			consigneeCountryName
		};
	}

	toggleModal(key) {
		let type = null;
		if (!this.state.showModal) {
			type = key;
		}
		this.setState({
			showModal: !this.state.showModal,
			modalType: type
		});
	}

	renderModal() {
		switch (this.state.modalType) {
			case 'confirmOrderModal':
				return (
					<ConfirmOrderModal
						userProfile={this.props.userProfile}
						history={this.props.history}
						order={this.state.order}
						closeModal={this.toggleModal}
					/>
				);
			default:
				console.log('[OrderConfirm] Invalid Modal Type'); // eslint-disable-line no-console
		}
	}

	renderTitleButton() {
		const {
			userProfile,
			orderHeaderId,
			deleteClicked,
			isEditable
		} = this.props;
		const canDeleteOrder =
			orderHeaderId > 0 &&
			isAuthorized(userProfile.permissions, [
				permConst.ORDER.DELETE.COMPANY
			]);

		if (isEditable) {
			return (
				<div className={'titleButton'}>
					{
						<MeatballDropdown
							toggleSize="lg"
							id='order-confirm-context-menu'
						>
							{canDeleteOrder && (
								<MeatballDropdown.Item
									onSelect={() => deleteClicked()}
								>
									Delete Draft Order
								</MeatballDropdown.Item>
							)}
						</MeatballDropdown>
					}
				</div>
			);
		}
	}

	renderFooter() {
		const { history, previousClicked, isEditable } = this.props;
		return (
			<div className="btn-container">
				<div className="float-left d-flex">
					<button
						className="btn btn-outline-secondary"
						type="button"
						onClick={previousClicked}
					>
						<FAIcon name="chevron-left" />
						Previous (Products Selection)
					</button>
					<button
						className="btn btn-link no-outline"
						type="button"
						onClick={() => history.push('/orders')}
					>
						Cancel
					</button>
				</div>
				<div className="float-right">
					{isEditable && (
						<button
							className="btn btn-primary"
							type="button"
							id="confirmOrderModal"
							onClick={() =>
								this.toggleModal('confirmOrderModal')
							}
						>
							Submit Order
						</button>
					)}
				</div>
			</div>
		);
	}

	render() {
		const { requiresConsignee } = this.props;
		const { order, isLoadingOrder, isLoadingResources } = this.state;
		let totalsQuant = 0,
			totalsPrice = 0;

		const isLoading = isLoadingOrder || isLoadingResources;

		const showCardSize = !!safeEval(() => 'card_size' in order.order_details[0]);

		const {
			platformName,
			freightForwarderName,
			portOfEntryName,
			packoutFacility,
			consigneeCountryName
		} = this.lookupReadableResources();
		const formattedPackoutFacility = packoutFacility
			? formatShipTo(packoutFacility)
			: order.packout_facility_id;

		const loadingBody = () => (<Panel.Body><Loading /></Panel.Body>);
		const contentBody = () => (<Panel.Body>
			<SectionTitle>Order Info</SectionTitle>
			{generateDataField(
				'Platform',
				platformName
			) /* platform_code */}
			{generateDataField(
				'Publisher PO #',
				order.publisher_po_number
			)}
			{generateDataField(
				'Order Comments',
				order.order_comment
			)}

			<SectionTitle>Shipping</SectionTitle>
			{generateDataField(
				'Freight Forwarder',
				freightForwarderName
			) /* freight_forwarder_code */}

			{requiresConsignee(
				order.freight_forwarder_code
			) && (
				<Fragment>
					{generateDataField(
						'Japanese Contact Name',
						order.consignee_info
							.consignee_contact_name
					)}
					{generateDataField(
						'Japanese Email',
						order.consignee_info
							.consignee_contact_email
					)}
					{generateDataField(
						'Japanese Phone Number',
						order.consignee_info
							.consignee_contact_phone_number
					)}
					{generateDataField(
						'Consignee Company Name',
						order.consignee_info
							.consignee_company_name
					)}
					{generateDataField(
						'Consignee Address',
						order.consignee_info
							.consignee_address_street_line_1
					)}
					{generateDataField(
						'Consignee City',
						order.consignee_info
							.consignee_address_city
					)}
					{generateDataField(
						'Consignee State',
						order.consignee_info
							.consignee_address_region
					)}
					{generateDataField(
						'Consignee Zip',
						order.consignee_info
							.consignee_address_postal_code
					)}
					{generateDataField(
						'Country',
						consigneeCountryName
					)}
					{generateDataField(
						'Service Level',
						order.consignee_info
							.consignee_service_level
					)}
					{generateDataField(
						'Carrier Account Number',
						order.consignee_info
							.consignee_carrier_account_number
					)}
				</Fragment>
			)}

			{generateDataField(
				'Customs Entry Port',
				portOfEntryName
			) /* port_of_entry_code */}
			{generateDataField(
				'Packout Facility',
				formattedPackoutFacility
			)}
			{generateDataField(
				'Destination Contact',
				order.packout_facility_contact_name
			)}
			{generateDataField(
				'Destination Phone',
				order.packout_facility_contact_phone_number
			)}
			{generateDataField(
				'Shipping Instructions',
				order.shipping_instructions
			)}

			<SectionTitle>Product(s)</SectionTitle>
			<Table>
				<thead>
					<tr>
						<th>Status</th>
						<th>Items</th>
						<th>Version</th>
						{showCardSize ? (<th>Card Size</th>): null}
						<th>Price/Unit</th>
						<th>Requested Ship Date</th>
						<th>Part/SKU</th>
						<th>Quantity</th>
						<th>Price</th>
					</tr>
				</thead>
				<tbody>
					{this.state.order.order_details.length !==
					this.state.pricesLoadedCount ? (
						<tr>
							<td colSpan={8}>
								<LoadingText />
							</td>
						</tr>
					) : (
						this.state.order.order_details.map(
							(detail) => {
								const detailPrice = this.state
									.prices[detail.product_id];
								totalsPrice +=
									detailPrice *
									detail.quantity;
								totalsQuant += detail.quantity;
								return (
									<tr key={detail.game_code}>
										<td>{detail.status}</td>
										<td>
											{`[${detail.game_code}] ${detail.product_name}`}
										</td>
										<td>
											{`${detail.product_release_version}.${detail.submission_version}`}
										</td>
										{showCardSize ? (
											<td>
												{detail.card_size}
											</td>
										) : null}
										<td>
											{detailPrice > 0
												? `$${numberWithCommas(
														detailPrice
													)}`
												: ''}
										</td>
										<td>
											{formatDate(
												parseDateString(detail.requested_ship_date),
												dateFormat.DATE
											)}
										</td>
										<td>
											{
												detail.publisher_part_number
											}
										</td>
										<td>
											{numberWithCommas(
												detail.quantity
											)}
										</td>
										<td>
											{`$${numberWithCommas(
												detailPrice *
													detail.quantity
											)}`}
										</td>
									</tr>
								);
							}
						)
					)}
					<tr>
						<td></td>
						<td></td>
						{showCardSize && <td></td>}
						<td></td>
						<td></td>
						<td></td>
						<th className={'order-details-totals'}>
							Totals:
						</th>
						<td className={'order-details-totals'}>
							{numberWithCommas(totalsQuant)}
						</td>
						<td className={'order-details-totals'}>
							{totalsPrice > 0
								? `$${numberWithCommas(
										totalsPrice
									)}`
								: ''}
						</td>
						<td className={'order-details-totals'}>
							{' '}
						</td>
					</tr>
				</tbody>
			</Table>

			{this.renderFooter()}
		</Panel.Body>);

		return (
			<div className="page-orders-items">
				<Title
					title="Create New Physical Order"
					subtitle="Step 3: Confirm order"
					button={!isLoading && this.renderTitleButton()}
				/>
				<Page.FullPageCol>{isLoading ? loadingBody() : contentBody()}</Page.FullPageCol>
				{this.state.showModal && this.state.modalType ? this.renderModal() : null}
			</div>
		);
	}
}

export default connect(mapStateToProps)(OrderConfirm);
