import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useQuery, useQueryClient } from 'react-query';

import Announcement from '../../components/Announcement/Announcement';
import { actionsColumn } from '../../components/BaseTable/BaseTable';
import FAIcon from '../../components/FAIcon/FAIcon';
import FilterableTable from '../../components/FilterableTable/FilterableTable';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import AnnouncementModal from '../../components/modals/AnnouncementModal/AnnouncementModal';
import DeleteConfirmationModal from '../../components/modals/DeleteConfirmationModal/DeleteConfirmationModal';
import SimpleModal from '../../components/modals/SimpleModal/SimpleModal';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import { announcementConstants } from '../../constants/announcementConstants';
import { permConst } from '../../constants/permConst';
import {
	deleteAnnouncement,
	getAnnouncementPriorities,
	getAnnouncements,
	getAnnouncementTemplates,
} from '../../services/announcementService';
import { dateFormat, formatDate, isDateInRange, parseDateString } from '../../utils/dateUtils';
import { dateRangeEvaluation } from '../../utils/filterUtils';
import { toasterNotify } from '../../utils/toaster';
import { isAuthorized } from '../../utils/userUtils';

import './AnnouncementManagement.css';

const title = 'Announcement Management';
const DELETE_MODAL = 'DELETE_MODAL';
const EDIT_MODAL = 'EDIT_MODAL';
const PREVIEW_MODAL = 'PREVIEW_MODAL';

function getTableStructure({ userProfile, showPreviewModal, showEditModal, showDeleteModal }) {
	const userPermissions = userProfile.permissions;
	const canPreviewAnnouncements = isAuthorized(userPermissions, [
		permConst.ANNOUNCEMENT.VIEW.ALL,
	]);
	const canDeleteAnnouncements = isAuthorized(userPermissions, [
		permConst.ANNOUNCEMENT.DELETE.ALL,
	]);
	const canEditAnnouncements = isAuthorized(userPermissions, [permConst.ANNOUNCEMENT.EDIT.ALL]);

	const priorityCell = ({ original }) => {
		switch (original.priority_code && original.priority_code.toUpperCase()) {
			case announcementConstants.PRIORITY.CRITICAL:
				return (
					<strong
						className="text-danger AnnouncementManagement__priority-icon"
						title={original.priority_name}
					>
						<FAIcon name="exclamation-circle" />
					</strong>
				);
			case announcementConstants.PRIORITY.MAJOR:
				return (
					<strong
						className="text-warning AnnouncementManagement__priority-icon"
						title={original.priority_name}
					>
						<FAIcon name="exclamation-triangle" />
					</strong>
				);
			default:
				return (
					<strong
						className="text-info AnnouncementManagement__priority-icon"
						title={original.priority_name}
					>
						<FAIcon name="info-circle" />
					</strong>
				);
		}
	};

	const timeFormatCell = ({ value }) => (
		<div>{formatDate(parseDateString(value), dateFormat.DATETIME_PT)}</div>
	);

	const messageCell = ({ value }) => (
		<div title={value} className="Announcement__sample-text">
			{value.length > 70 ? value.substr(0, 70) + '...' : value}
		</div>
	);

	const statusCell = ({ value }) => {
		switch (value) {
			case announcementConstants.STATUS.SCHEDULED:
				return (
					<strong className="text-info" data-value={value}>
						SCHEDULED
					</strong>
				);
			case announcementConstants.STATUS.ACTIVE:
				return (
					<strong className="text-success" data-value={value}>
						ACTIVE
					</strong>
				);
			case announcementConstants.STATUS.EXPIRED:
				return (
					<strong className="text-muted" data-value={value}>
						EXPIRED
					</strong>
				);
			default:
				return null;
		}
	};

	const tableConfiguration = [
		{ Header: 'Priority', accessor: 'sort_order', Cell: priorityCell, maxWidth: 72 },
		{ Header: 'Type', accessor: 'template_name' },
		{
			Header: 'Announcement Start Date',
			accessor: 'announcement_start_datetime',
			Cell: timeFormatCell,
		},
		{
			Header: 'Announcement End Date',
			accessor: 'announcement_end_datetime',
			Cell: timeFormatCell,
		},
		{ Header: 'Event Start Date', accessor: 'event_start_datetime', Cell: timeFormatCell },
		{ Header: 'Event End Date', accessor: 'event_end_datetime', Cell: timeFormatCell },
		{ Header: 'Announcement', accessor: 'message', Cell: messageCell },
		{ Header: 'Status', accessor: 'status', Cell: statusCell },
	];

	const actions = {
		...actionsColumn,
		Cell: ({ row, original }) => {
			const topItemGroup = [];
			const bottomItemGroup = [];

			if (canPreviewAnnouncements) {
				topItemGroup.push(
					<MeatballDropdown.Item
						id="preview-announcement"
						key={`preview-${original.announcement_id}`}
						eventKey={original.announcement_id}
						onClick={(e) => {
							showPreviewModal(original.announcement_id);
						}}
					>
						Preview Announcement
					</MeatballDropdown.Item>,
				);
			}

			if (
				canEditAnnouncements &&
				[
					announcementConstants.STATUS.ACTIVE,
					announcementConstants.STATUS.SCHEDULED,
				].includes(original.status)
			) {
				bottomItemGroup.push(
					<MeatballDropdown.Item
						id="edit-announcement"
						key={`edit-${original.announcement_id}`}
						eventKey={original.announcement_id}
						onClick={(e) => {
							showEditModal(original.announcement_id);
						}}
					>
						Edit Announcement
					</MeatballDropdown.Item>,
				);
			}

			if (canDeleteAnnouncements && original.status === announcementConstants.STATUS.SCHEDULED) {
				bottomItemGroup.push(
					<MeatballDropdown.Item
						id="delete-announcement"
						key={`delete-${original.announcement_id}`}
						eventKey={original.announcement_id}
						onClick={(e) => {
							showDeleteModal(original.announcement_id);
						}}
					>
						<span className="text-danger">Delete Announcement</span>
					</MeatballDropdown.Item>,
				);
			}

			if (bottomItemGroup.length > 0) {
				bottomItemGroup.unshift(<MeatballDropdown.Divider key={'divider'} />);
			}

			if (topItemGroup.length + bottomItemGroup.length > 0) {
				return (
					<MeatballDropdown id={`dropdown-basic-${original.announcement_id}`}>
						{topItemGroup}
						{bottomItemGroup}
					</MeatballDropdown>
				);
			}
		},
	};
	if (canPreviewAnnouncements || canEditAnnouncements || canDeleteAnnouncements) {
		tableConfiguration.push(actions);
	}

	return tableConfiguration;
}

function getFilterProperties({ templates }) {
	const filterProperties = new Map();
	filterProperties.set('Type', {
		filter: 'template_name',
		selectableFilters: new Set(templates.map((t) => t.template_name)),
	});
	filterProperties.set('Announcement Start Date', {
		filter: 'announcement_start_datetime',
		selectableFilters: 'datetime_range',
		customEvaluation: dateRangeEvaluation,
	});
	filterProperties.set('Event Start Date', {
		filter: 'event_start_datetime',
		selectableFilters: 'datetime_range',
		customEvaluation: dateRangeEvaluation,
	});
	filterProperties.set('Status', {
		filter: 'status',
		selectableFilters: new Set(['Scheduled', 'Active', 'Expired']),
		customEvaluation: (value, filters) => {
			switch (Number(value)) {
				case announcementConstants.STATUS.SCHEDULED:
					return filters.has('Scheduled');
				case announcementConstants.STATUS.ACTIVE:
					return filters.has('Active');
				case announcementConstants.STATUS.EXPIRED:
					return filters.has('Expired');
				default:
					return false;
			}
		},
	});
	return filterProperties;
}

function getStatus(announcement) {
	const now = new Date();
	const start = new Date(announcement.announcement_start_datetime);
	const end = new Date(announcement.announcement_end_datetime);

	if (isDateInRange(now, start, end)) {
		return announcementConstants.STATUS.ACTIVE;
	} else if (now < start) {
		return announcementConstants.STATUS.SCHEDULED;
	}
	return announcementConstants.STATUS.EXPIRED;
}

function announcementSplicer({ announcements, priorities, templates }) {
	return announcements?.map((announcement) => {
		const priority = priorities?.find((p) => p.priority_code === announcement.priority_code);
		const template = templates?.find((t) => t.template_id === announcement.template_id);

		return {
			status: getStatus(announcement),
			...priority,
			...template,
			...announcement,
		};
	});
}

function renderModal({ modalState, closeModal, confirmDelete }) {
	const { showModal, modalType, announcement, announcementId } = modalState;
	switch (modalType) {
		case PREVIEW_MODAL:
			return (
				<SimpleModal show={showModal} title="Preview Announcement" closeModal={closeModal}>
					<Announcement announcement={announcement} dismissible={false} />
				</SimpleModal>
			);
		case EDIT_MODAL:
			return (
				<AnnouncementModal
					announcementId={announcementId}
					show={showModal}
					closeModal={closeModal}
				/>
			);
		case DELETE_MODAL:
			return (
				<DeleteConfirmationModal
					show={showModal}
					closeModal={closeModal}
					confirmDelete={(e) => confirmDelete(announcementId)}
					title="Delete Announcement"
					message="Please confirm that you would like to permanently delete this announcement."
				/>
			);
		default:
			throw new Error('Invalid modal type specified');
	}
}

const AnnouncementManagement = ({ userProfile }) => {
	const [state, setState] = useState({});

	const [filterProperties, setFilterProperties] = useState(null);

	const queryClient = useQueryClient();
	const announcementsQuery = useQuery('getAnnouncements', () => getAnnouncements());
	const announcementTemplatesQuery = useQuery('getAnnouncementTemplates', () =>
		getAnnouncementTemplates(),
	);
	const announcementPrioritiesQuery = useQuery('getAnnouncementPriorities', () =>
		getAnnouncementPriorities(),
	);

	const templates = announcementTemplatesQuery.data?.data;

	const announcements = announcementSplicer({
		announcements: announcementsQuery.data?.data,
		templates,
		priorities: announcementPrioritiesQuery.data?.data,
	});

	if (filterProperties == null && announcementTemplatesQuery.isSuccess) {
		setFilterProperties(getFilterProperties({ templates }));
	}

	const addAnnouncmentModal = () => {
		return (
			<div>
				{isAuthorized(userProfile.permissions, [permConst.ANNOUNCEMENT.ADD.ALL]) && (
					<Button
						variant="primary"
						id="addAnnouncement"
						onClick={(e) => showCreateModal()}
					>
						<FAIcon className="mr-1" name="plus" />
						Create Announcement
					</Button>
				)}
			</div>
		);
	};

	const showDeleteModal = (id) => {
		setState({
			modalType: DELETE_MODAL,
			announcementId: id,
			showModal: true,
		});
	};
	const showEditModal = (id) => {
		setState({
			modalType: EDIT_MODAL,
			announcementId: id,
			showModal: true,
		});
	};
	const showCreateModal = () => {
		if (state.isLoading) {
			return;
		}
		setState({
			modalType: EDIT_MODAL,
			announcementId: null,
			showModal: true,
		});
	};
	const showPreviewModal = (id) => {
		setState({
			modalType: PREVIEW_MODAL,
			announcement: announcements.find((a) => a.announcement_id === id),
			showModal: true,
		});
	};
	const closeModal = () => {
		setState({ ...state, showModal: false });
		setTimeout(() => setState({}), 300);
	};
	const confirmDelete = async (announcementId) => {
		try {
			const response = await deleteAnnouncement(announcementId);
			closeModal();
			if (response.status >= 400) {
				toasterNotify(response.toString());
			} else {
				toasterNotify('Announcement was deleted', 'success');
				queryClient.invalidateQueries('getAnnouncements');
			}
		} catch (err) {
			const status = err.response && err.response.status;
			closeModal();
			if (status === 403 || Number(status) >= 500) {
				toasterNotify(
					`There was an unexpected error while deleting the announcement. (${status})`,
					'error',
				);
			} else if (err.response && err.response.data && err.response.data.message) {
				toasterNotify(
					`Returned error from service: ${err.response.data.message} (${status})`,
					'error',
				);
			} else {
				toasterNotify(err.toString(), 'error');
			}
		}
	};

	const dataFormat = getTableStructure({
		userProfile,
		showDeleteModal,
		showEditModal,
		showPreviewModal,
	});

	const isLoading =
		announcementsQuery.isLoading ||
		announcementTemplatesQuery.isLoading ||
		announcementPrioritiesQuery.isLoading;

	return (
		<div className="AnnouncementManagement">
			<Page isLoading={isLoading}>
				<Title title={title} button={addAnnouncmentModal()} />
				<FilterableTable
					className="AnnouncementManagement__table"
					data={announcements}
					dataFormat={dataFormat}
					filterProperties={filterProperties}
					searchableFields={['message']}
					searchableFieldPlaceHolder="Search announcements..."
					defaultSorted={[]}
				/>
				{!isLoading && state.modalType
					? renderModal({ modalState: state, closeModal, confirmDelete })
					: null}
			</Page>
		</div>
	);
};

export default AnnouncementManagement;
