import React, { Component } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { actionsColumn } from '../../../components/BaseTable/BaseTable';
import FAIcon from '../../../components/FAIcon/FAIcon';
import FilterableTable from '../../../components/FilterableTable/FilterableTable';
import Loading from '../../../components/Loading/Loading';
import MeatballDropdown from '../../../components/MeatballDropdown/MeatballDropdown';
import Page from '../../../components/Page/Page';
import StatusText from '../../../components/StatusText/StatusText';
import Title from '../../../components/Title/Title';
import { loadingConstants } from '../../../constants/loadingConstants';
import { permConst } from '../../../constants/permConst';
import { roleConstants } from '../../../constants/roleConstants';
import { transferRightsConstants } from '../../../constants/transferRightsConstants';
import { getProductRightsTransferRequests } from '../../../services/productTransfersService';
import { dateFormat, formatDate, parseDateString } from '../../../utils/dateUtils';
import { displayString } from '../../../utils/stringUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { isAuthorized } from '../../../utils/userUtils';
import EnterDistributionTransferCodeModal from './modals/EnterDistributionTransferCodeModal';
import RescindDistributionTransferModal from './modals/RescindDistributionTransferModal';
import RevokeDistributionTransferModal from './modals/RevokeDistributionTransferModal';


const title = 'Transfer Distribution';

function mapStateToProps(state, prop) {
	return {
		userProfile: state.authReducer.userProfile,
		platforms: state.referenceReducer.platforms?.content,
		platformsReady: state.referenceReducer.platforms?.meta.status === loadingConstants.COMPLETED
	};
}

function displayStatusExternalUser(status) {
	switch (status) {
		case transferRightsConstants.REQUEST_STATUS.REVIEWED:
			return transferRightsConstants.REQUEST_STATUS.PENDING;
		case transferRightsConstants.REQUEST_STATUS.REJECTED_BY_APPROVER:
		case transferRightsConstants.REQUEST_STATUS.REJECTED_BY_REVIEWER:
			return 'REJECTED';
		default:
			return status;
	}
}

export function getDistribution(value) {
	if (value.digital_pub_flag && value.retail_pub_flag) {
		return 'Both';
	}
	if (value.digital_pub_flag) {
		return 'Digital';
	}
	return value.retail_pub_flag ? 'Retail' : '';
}

export class ProductDistributionTransferTable extends Component {
	constructor(props) {
		super(props);

		const isOrderReviewer = props.userProfile.roles.some(
			(role) => role.name === roleConstants.ORDER_REVIEW
		);
		const isPurchaseOrderApprover = props.userProfile.roles.some(
			(role) => role.name === roleConstants.PURCHASE_ORDER_APPROVER
		);

		this.state = {
			distributionRightsRequests: '',
			filterProperties: this.filters,
			isLoading: true,
			isOrderReviewer,
			isPurchaseOrderApprover,
			modalType: null,
			selectedTransferId: null,
			showModal: false
		};

		this.toggleModal = this.toggleModal.bind(this);
		this.loadResources = this.loadResources.bind(this);
		this.updateTransferStatus = this.updateTransferStatus.bind(this);
		this.addRightsRequest = this.addRightsRequest.bind(this);
		this.addPlatformData = this.addPlatformData.bind(this);
	}

	filters = new Map([
		[
			'Platform',
			{
				filter: 'product_system_name',
				selectableFilters: new Set()
			}
		],
		[
			'Status',
			{
				filter: 'transfer_status',
				selectedFilters: new Set(),
				selectableFilters: new Set(),
				customEvaluation: (status, filters) => {
					const tempFilters = new Set(filters);
					if (tempFilters.has('Rejected')) {
						tempFilters.add(displayString(transferRightsConstants.REQUEST_STATUS.REJECTED_BY_APPROVER));
						tempFilters.add(displayString(transferRightsConstants.REQUEST_STATUS.REJECTED_BY_REVIEWER));
					}
					return tempFilters.has(displayString(status));
				}
			}
		]
	]);

	componentDidMount() {
		this.componentDidUpdate();
	}

	componentDidUpdate() {
		if (!this.props.platformsReady) {
			return;
		}

		this.loadResources();
		this.componentDidUpdate = null;
	}

	loadResources() {
		this.getPlatforms();
		this.getStatus();
		this.getDistributionRightsRequests();
	}

	updateTransferStatus(status, id) {
		const { distributionRightsRequests } = this.state;
		for (let request of distributionRightsRequests) {
			if (request.product_transfer_id === id) {
				request.transfer_status = status;
				break;
			}
		}
		this.setState({
			distributionRightsRequests: distributionRightsRequests
		});
	}

	addPlatformData(transferRight) {
		const { platforms } = this.props;
		const platformData = platforms.find(p => p.platform_code === transferRight.product_platform_code);
		return {
			...transferRight,
			product_platform_name: platformData.platform_name,
			product_system_name: platformData.system_name,
		};
	}

	addRightsRequest(transferRequest) {
		const { distributionRightsRequests } = this.state;

		distributionRightsRequests.push(this.addPlatformData(transferRequest));
		this.setState({
			distributionRightsRequests: [...distributionRightsRequests]
		});
	}

	getDistributionRightsRequests() {
		const { userProfile } = this.props;
		const transfer_type = 'DISTRIBUTION';

		getProductRightsTransferRequests(transfer_type)
			.then((response) => {
				let distributionRights = null;
				distributionRights = response.data;
				const canViewAllRightTransfers = isAuthorized(
					userProfile.permissions,
					[permConst.PRODUCT.RIGHT_TRANSFER.VIEW.ALL_DISTRIBUTION]
				);

				if (!canViewAllRightTransfers) {
					distributionRights.forEach((transferRight) => {
						if (
							transferRight.transfer_status ===
								transferRightsConstants.REQUEST_STATUS.REVIEWED
						) {
							transferRight.transfer_status =
								transferRightsConstants.REQUEST_STATUS.PENDING;
						}
					});
				}

				distributionRights = distributionRights.map(transferRight => {
					return this.addPlatformData(transferRight);
				});
				this.setState({
					distributionRightsRequests: distributionRights,
					isLoading: false
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'loading publishers data'),
					'error',
					error
				);
			});
	}

	getTableStructure() {
		const { userProfile } = this.props;
		const canViewAllRightTransfers = isAuthorized(userProfile.permissions, [
			permConst.PRODUCT.RIGHT_TRANSFER.VIEW.ALL_DISTRIBUTION
		]);

		let tableConfiguration = [
			{
				Header: 'Request Date',
				accessor: 'creation_date',
				minWidth: 110,
				Cell: (cell) => formatDate(parseDateString(cell.value), dateFormat.DATE)
			},
			{
				Header: 'Title',
				accessor: 'product_game_name'
			},
			{
				Header: 'Platform',
				accessor: 'product_platform_name'
			},
			{
				Header: 'Game Code',
				accessor: 'product_game_code'
			},
			{
				Header: 'Right(s)',
				id: 'distribution',
				minWidth: 110,
				accessor: (d) => getDistribution(d)
			},
			{
				Header: 'Initiating',
				accessor: 'transfer_from_company'
			},
			{
				Header: 'Receiving',
				accessor: 'transfer_to_company'
			},
			{
				Header: 'Date Assigned',
				accessor: 'transfer_date',
				minWidth: 110,
				Cell: (cell) => formatDate(parseDateString(cell.value), dateFormat.DATE)
			},
			{
				Header: 'Transfer Code',
				accessor: 'transfer_token'
			},
			{
				Header: 'Request Status',
				accessor: 'transfer_status',
				minWidth: 110,
				Cell: (cell) => {
					let variant =
						cell.original.transfer_status === 'PROCESSED'
							? 'success'
							: 'secondary';
					const regex = new RegExp(
						`Rejected|${transferRightsConstants.REQUEST_STATUS.RESCINDED}|${transferRightsConstants.REQUEST_STATUS.REJECTED_BY_APPROVER}|${transferRightsConstants.REQUEST_STATUS.REJECTED_BY_REVIEWER}`,
					);
					variant = regex.test(cell.original.transfer_status)
						? 'danger'
						: variant;

					return (
						<StatusText variant={variant}>
							{canViewAllRightTransfers
								? cell.value
								: displayStatusExternalUser(cell.value).toUpperCase()}
						</StatusText>
					);
				},
			},
			{
				...actionsColumn,
				maxWidth: canViewAllRightTransfers ? 40 : 48,
				Cell: ({ original }) => {
					if (canViewAllRightTransfers) {
						return (
							<Link to={`/admin/product-distribution-transfer-requests/${original.product_transfer_id}`}><FAIcon name="chevron-right" /></Link>
						);
					}
					const displayEllipses =
						original.transfer_from_company ===
						userProfile.companyName;
					return displayEllipses && this.generateOptions(original);
				}
			}
		];

		return tableConfiguration;
	}

	generateOptions(row) {
		const displayRescindRequest =
			row.transfer_status ===
				transferRightsConstants.REQUEST_STATUS
					.AWAITING_RECEIVING_PUBLISHER ||
			row.transfer_status ===
				transferRightsConstants.REQUEST_STATUS.PENDING ||
			row.transfer_status ===
				transferRightsConstants.REQUEST_STATUS.REVIEWED;
		const displayRevokeRights =
			row.transfer_status ===
			transferRightsConstants.REQUEST_STATUS.PROCESSED;

		return (
			(displayRescindRequest || displayRevokeRights) && (
				<MeatballDropdown
					id='dropdown-basic-distributionrights'
				>
					{displayRescindRequest && (
						<MeatballDropdown.Item
							className="text-danger"
							onSelect={() =>
								this.toggleModal(
									'rescindModal',
									row.product_transfer_id
								)
							}
						>
							Rescind Request
						</MeatballDropdown.Item>
					)}
					{displayRevokeRights && (
						<MeatballDropdown.Item
							className="text-danger"
							onSelect={() =>
								this.toggleModal(
									'revokeModal',
									row.product_transfer_id
								)
							}
						>
							Revoke Rights
						</MeatballDropdown.Item>
					)}
				</MeatballDropdown>
			)
		);
	}

	renderModal() {
		const { userProfile, platforms } = this.props;
		switch (this.state.modalType) {
			case 'rescindModal':
				return (
					<RescindDistributionTransferModal
						userProfile={userProfile}
						toggleModal={this.toggleModal}
						updateTransferStatus={this.updateTransferStatus}
						transferId={this.state.selectedTransferId}
						getPlatforms={this.getPlatforms}
						getDistributionRightsRequests={
							this.getDistributionRightsRequests
						}
					/>
				);
			case 'revokeModal':
				return (
					<RevokeDistributionTransferModal
						userProfile={userProfile}
						toggleModal={this.toggleModal}
						updateTransferStatus={this.updateTransferStatus}
						transferId={this.state.selectedTransferId}
						getPlatforms={this.getPlatforms}
						getDistributionRightsRequests={
							this.getDistributionRightsRequests
						}
					/>
				);
			case 'enter-assignment-code-modal':
				return (
					<EnterDistributionTransferCodeModal
						closeModal={this.toggleModal}
						userProfile={userProfile}
						addRightsRequest={this.addRightsRequest}
						platforms={platforms}
					/>
				);
			default:
				toasterNotify(
					`An unexpected error occurred opening modal ${this.state.modalType}`
				);
		}
	}

	toggleModal(key, transferId = null, loadResources = false) {
		let type = null;
		if (!this.state.showModal) {
			type = key;
		}
		this.setState(
			{
				showModal: !this.state.showModal,
				modalType: type,
				selectedTransferId: transferId
			},
			() => {
				if (loadResources) {
					this.loadResources();
				}
			}
		);
	}

	getPlatforms() {
		const { platforms } = this.props;
		const { filterProperties } = this.state;
		platforms.forEach(p => {
			filterProperties.get('Platform').selectableFilters.add(p.system_name);
		});
	}

	getStatus() {
		const {
			filterProperties,
			isOrderReviewer,
			isPurchaseOrderApprover
		} = this.state;

		filterProperties
			.get('Status')
			.selectableFilters.add(
				displayString(
					transferRightsConstants.REQUEST_STATUS
						.AWAITING_RECEIVING_PUBLISHER
				)
			);
		filterProperties
			.get('Status')
			.selectableFilters.add(
				displayString(transferRightsConstants.REQUEST_STATUS.PENDING)
			);

		if (isOrderReviewer || isPurchaseOrderApprover) {
			filterProperties
				.get('Status')
				.selectableFilters.add(
					displayString(
						transferRightsConstants.REQUEST_STATUS.REVIEWED
					)
				);
		}

		filterProperties
			.get('Status')
			.selectableFilters.add(
				displayString(transferRightsConstants.REQUEST_STATUS.PROCESSED)
			);
		filterProperties
			.get('Status')
			.selectableFilters.add(
				displayString(transferRightsConstants.REQUEST_STATUS.RESCINDED)
			);
		filterProperties
			.get('Status')
			.selectableFilters.add(
				displayString(transferRightsConstants.REQUEST_STATUS.REVOKED)
			);

		if (isOrderReviewer || isPurchaseOrderApprover) {
			filterProperties
				.get('Status')
				.selectableFilters.add(
					displayString(
						transferRightsConstants.REQUEST_STATUS
							.REJECTED_BY_REVIEWER
					)
				);
			filterProperties
				.get('Status')
				.selectableFilters.add(
					displayString(
						transferRightsConstants.REQUEST_STATUS
							.REJECTED_BY_APPROVER
					)
				);
		}

		// Determine preset filters
		if (isOrderReviewer) {
			filterProperties
				.get('Status')
				.selectedFilters.add(
					displayString(
						transferRightsConstants.REQUEST_STATUS.PENDING
					)
				);
		}

		if (isPurchaseOrderApprover) {
			filterProperties
				.get('Status')
				.selectedFilters.add(
					displayString(
						transferRightsConstants.REQUEST_STATUS.REVIEWED
					)
				);
		}

		if (!isOrderReviewer && !isPurchaseOrderApprover) {
			filterProperties.get('Status').selectableFilters.add('Rejected');
		}

		filterProperties
			.get('Status')
			.selectableFilters.add(
				displayString(transferRightsConstants.REQUEST_STATUS.OWNERSHIP_TRANSFERRED)
			);
	}

	isExternalUser() {
		const { userProfile } = this.props;

		return isAuthorized(userProfile.permissions, [
			permConst.PRODUCT.RIGHT_TRANSFER.VIEW.COMPANY
		]);
	}

	handleRowClick(event, distributionRights) {
		const { userProfile } = this.props;
		if (
			isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.RIGHT_TRANSFER.VIEW.ALL_DISTRIBUTION
			])
		) {
			if (event.target.closest('[aria-haspopup=true], a')) {
				// Just the ellipsis menu was clicked, don't follow link.
				event.preventDefault();
			} else {
				let url = `/admin/product-distribution-transfer-requests/${distributionRights.original.product_transfer_id}`;
				this.props.history.push(url);
			}
		}
	}

	titleButton() {
		return (
			this.isExternalUser() && (
				<Button
					variant="primary"
					id="enter-assignment-code-modal"
					onClick={() => {
						this.toggleModal('enter-assignment-code-modal');
					}}
				>
					<FAIcon name="plus" className="mr-1" />
					Enter Assignment Code
				</Button>
			)
		);
	}

	render() {
		const { distributionRightsRequests, isLoading } = this.state;

		const { userProfile } = this.props;

		const isInternal = isAuthorized(userProfile.permissions, [
			permConst.PRODUCT.RIGHT_TRANSFER.VIEW.ALL_DISTRIBUTION
		]);

		const dataFormat = this.getTableStructure();

		return (
			<Page>
				{!isInternal && (
					<Alert variant="info">
						Both products transferred to you and products that
						you have transferred to other publishers are listed.
						<br />
						If you have been provided an assignment code by
						another publisher, select 'Enter Assignment Code' to
						accept a distribution transfer.
					</Alert>
				)}
				<Title title={title} button={this.titleButton()} />
				{isLoading ? (
					<Loading />
				) : (
					<FilterableTable
						className="distribution-rights-requests-table"
						data={distributionRightsRequests}
						dataFormat={dataFormat}
						filterProperties={this.state.filterProperties}
						searchableFields={[
							'product_game_name',
							'transfer_from_company',
							'transfer_to_company',
							'product_game_code'
						]}
						searchableFieldPlaceHolder={
							'Search by Title, Game Code, or Publisher...'
						}
						defaultSorted={[
							{
								id: 'creation_date',
								desc: true
							}
						]}
						retainPageState
					/>
				)}
				{this.state.showModal && this.state.modalType
					? this.renderModal()
					: null}
			</Page>
		);
	}
}

export default connect(mapStateToProps)(ProductDistributionTransferTable);
