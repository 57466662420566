import React from 'react';

import AddContainer from './containers/AddContainer';
import EditContainer from './containers/EditContainer';
import ReadOnlyContainer from './containers/ReadOnlyContainer';


export const VIEW_TASK_MODAL = 'VIEW_TASK_MODAL';
export const EDIT_TASK_MODAL = 'EDIT_TASK_MODAL';
export const ADD_TASK_MODAL = 'ADD_TASK_MODAL';

const EventTaskModal = (props) => {
	if (props.mode === ADD_TASK_MODAL) {
		return <AddContainer {...props} />;
	}
	if (props.mode === EDIT_TASK_MODAL) {
		return <EditContainer {...props} />;
	}
	return <ReadOnlyContainer {...props} />;
};

export default EventTaskModal;
