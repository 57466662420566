import api from './baseService';

export interface CustomFieldPost extends Omit<CustomField, 'roles' | 'id'> {
	roles: number[];
}

export interface CustomFieldPut {
	field_definition_id: number;
	value: any;
}

export interface CustomFieldDefinitionPut {
	name: string;
	active: boolean;
	read_only: boolean;
	roles: number[];
}

export const getCustomFieldDefinitions = (entityName: string) =>
	api().get<CustomField[]>(`/pdb/custom_fields/definitions?entity_name=${entityName}`);

export const postCustomFieldDefinition = (payload: CustomFieldPost) =>
	api().post('pdb/custom_fields/definitions', payload);

export const getCustomFieldRoles = () =>
	api().get('/pdb/custom_fields/roles');

export const putCustomFieldDefinition = (id: number, payload: CustomFieldDefinitionPut) =>
	api().put(`/pdb/custom_fields/definitions/${id}`, payload);

export const getCustomFields = (entityName: string, entityId: string) =>
	api().get<CustomFieldEntryData[]>(`/pdb/custom_fields?entity_name=${entityName}&entity_id=${entityId}`);

export const putCustomField = (entityId: string, payload: CustomFieldPut[]) =>
	api().put(`/pdb/custom_fields?entity_id=${entityId}`, JSON.stringify(payload));

export const deleteCustomField = (id: number) =>
	api().delete(`/pdb/custom_fields/definitions/${id}`);
