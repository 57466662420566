import intersection from 'lodash/intersection';

import { getStore } from '../store/store';
import { expandHashes } from './dataUtils';
import { routePermissions } from '../constants/routePermissions';
import { isFeatureFlagEnabled } from './featureFlagUtils';


/**
 * Evaluates if a user has a required permission within a list of permissions
 *
 * @param userPermissions array of permissions in posession
 * @param requiredPermissions array of permissions to be satisfied
 * If a hash of permissions is found within the input array, values inside that
 * hash will be added to the array.
 *
 * @returns Boolean, true if any required permission is found in user permissions
 */
export const isAuthorized = (userPermissions, requiredPermissions) => {
	requiredPermissions = expandHashes(requiredPermissions);
	return intersection(userPermissions, requiredPermissions).length > 0;
};

export const isAuthorizedForUrl = (path) => {
	const state = getStore().getState();
	const userProfile = state.authReducer.userProfile;
	const routePermission = routePermissions.get(path);
	if (!routePermission) {
		return true;
	}
	if (routePermission.featureFlag && !isFeatureFlagEnabled(routePermission.featureFlag)) {
		return false;
	}
	if (routePermission.disable && routePermission.disable(userProfile)) { return false; }
	return isAuthorized(userProfile.permissions, routePermission.permissions);
};

/**
 * Determines if a user is an internal user
 * @param  {} userProfile
 */
export const userHasInternalRole = (userProfile) =>
	userProfile.roles.some(role => role.role_type_code === 'INTERNAL');

/**
 * Tests to see if user is a member of a group, defined by an array of
 * NDID user IDs.
 * @param  {} userProfile
 * @param  {Array} idList
 */
export const isAffiliated = (userProfile, idList) => {
	return idList.includes(userProfile.userId);
};

export const isFeatureActiveForPlatform = (userProfile, feature, platform, privilege = 'edit' ) => {
	const capabilities = userProfile.platform_features?.[feature]?.[platform];
	return capabilities == null || capabilities.includes(privilege);
};
