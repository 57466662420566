import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { setFeatureFlags } from '../../store/actions/actions';
import BaseTable, { actionsColumn } from '../../components/BaseTable/BaseTable';
import FAIcon from '../../components/FAIcon/FAIcon';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import { permConst } from '../../constants/permConst';
import NotFound from '../../containers/NotFound/NotFound';
import { isFeatureFlagEnabled, refreshOverrideCookie } from '../../utils/featureFlagUtils';
import { isAuthorized } from '../../utils/userUtils';


const NOT_AVAILABLE = 'NOT_AVAILABLE';
const ON = 'ON';
const OFF = 'OFF';

const getTableStructure = ({flagsStatus, dispatch}) => {
	const setOverride = (name, isOn) => {
		const newFlagStatus = flagsStatus.map((flag) =>
			flag.name === name ? { ...flag, override: !!isOn } : { ...flag },
		);
		dispatch(setFeatureFlags({ status: newFlagStatus }));
		refreshOverrideCookie(newFlagStatus);
		global.location.reload();
	};

	const clearOverride = (name) => {
		const newFlagStatus = flagsStatus.map((flag) =>
			flag.name === name ? { ...flag, override: null } : { ...flag },
		);
		dispatch(setFeatureFlags({ status: newFlagStatus }));
		refreshOverrideCookie(newFlagStatus);
		global.location.reload();
	};

	return [
		{ Header: 'Name', accessor: 'name' },
		{ Header: 'Description', accessor: 'description' },
		{
			Header: 'Active',
			id: 'activeAccessor',
			accessor: (row) => isFeatureFlagEnabled(row.name),
			Cell: function ActiveCell({ value }) {
				return (value && <FAIcon className="text-success" name="check" />) || null;
			},
		},
		{
			Header: 'Default State',
			accessor: 'defaultOn',
			Cell: function DefaultStateCell({ value }) {
				switch (value) {
					case true:
						return <strong className="text-success">ON</strong>;
					case false:
						return <strong className="text-muted">OFF</strong>;
					default:
						return null;
				}
			},
		},
		{
			Header: 'Override',
			id: 'override',
			accessor: (row) => {
				if (row.alwaysOn) return NOT_AVAILABLE;
				if (row.override == null) return null;
				return row.override ? ON : OFF;
			},
			Cell: function OverrideCell({ value }) {
				switch (value) {
					case NOT_AVAILABLE:
						return <strong className="text-danger">CANNOT BE OVERRIDDEN</strong>;
					case ON:
						return <strong className="text-success">ON</strong>;
					case OFF:
						return <strong className="text-muted">OFF</strong>;
					default:
						return null;
				}
			},
		},
		{
			...actionsColumn,
			Cell: function ActionsCell({ original }) {
				return (
					!original.alwaysOn && (
						<MeatballDropdown id={`dropdown-flag-${original.name}`.replace(/\s/g, '-')}>
							<MeatballDropdown.Item
								onClick={(e) => setOverride(original.name, true)}
							>
								Set Override to ON
							</MeatballDropdown.Item>
							<MeatballDropdown.Item
								onClick={(e) => setOverride(original.name, false)}
							>
								Set Override to OFF
							</MeatballDropdown.Item>
							<MeatballDropdown.Divider />
							<MeatballDropdown.Item onClick={(e) => clearOverride(original.name)}>
								<span className="text-danger">Clear Override</span>
							</MeatballDropdown.Item>
						</MeatballDropdown>
					)
				);
			},
		},
	];
};


const Features = ({userProfile}) => {
	const flagsStatus = useSelector(store => store.featureFlagReducer.flags.status);
	const buttons = <Button variant="danger" onClick={e => clearAllOverrides()}>Clear all overrides</Button>;
	const dispatch = useDispatch();
	const dataFormat = useMemo(() => getTableStructure({flagsStatus, dispatch}), [flagsStatus, dispatch]);

	const clearAllOverrides = () => {
		const newFlagStatus = flagsStatus.map((flag) => ({ ...flag, override: null }));
		dispatch(setFeatureFlags({ status: newFlagStatus }));
		refreshOverrideCookie(newFlagStatus);
	};

	if (
		global.config.authEnv === 'prod1' &&
		!isAuthorized(userProfile.permissions, [permConst.FEATURES.EDIT.ALL])
	) {
		return <NotFound />;
	}
	return <Page>
		<Title title="Experimental Features" button={buttons} />
		<Page.FullPageCol>
			<BaseTable
				columns={dataFormat}
				data={flagsStatus}
			/>
		</Page.FullPageCol>
    </Page>;
};
export default Features;
