import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { linkColumn } from '../../components/BaseTable/BaseTable';

import FAIcon from '../../components/FAIcon/FAIcon';
import FilterableTable from '../../components/FilterableTable/FilterableTable';
import Loading from '../../components/Loading/Loading';
import Page from '../../components/Page/Page';
import StatusText from '../../components/StatusText/StatusText';
import Title from '../../components/Title/Title';
import { loadingConstants } from '../../constants/loadingConstants';
import { orderConst } from '../../constants/orderConstants';
import { permConst } from '../../constants/permConst';
import { roleConstants } from '../../constants/roleConstants';
import { getOrders } from '../../services/ordersService';
import { formatCurrency } from '../../utils/currencyUtils';
import { dateFormat, formatDate, parseDateString } from '../../utils/dateUtils';
import { displayOrderStatus } from '../../utils/orderUtils';
import { pullAllResults } from '../../utils/serviceUtils';
import { displayString } from '../../utils/stringUtils';
import { getSunsetMessage } from '../../utils/sunsetFeaturesUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { isAuthorized } from '../../utils/userUtils';

import './orders.css';


function mapStateToProps(state) {
	return {
		userProfile: state.authReducer.userProfile,
		sunsetFeatures: state.referenceReducer.sunsetFeatures,
		sunsetFeaturesReady: state.referenceReducer.sunsetFeatures.meta.status === loadingConstants.COMPLETED
	};
}

class OrderList extends Component {
	constructor(props) {
		super(props);
		const title = 'Physical Orders';
		let dataFormat = this.getTableFormat();
		let filterProperties = new Map();
		filterProperties.set('Platform', {
			filter: 'platform',
			selectableFilters: new Set()
		});
		filterProperties.set('Status', {
			filter: 'order_status',
			selectedFilters: new Set(),
			selectableFilters: new Set(),
			customEvaluation: (status, filters) => {
				let userCanViewAllOrders = isAuthorized(
						this.props.userProfile.permissions,
						[permConst.ORDER.VIEW.ALL]
					),
					userHasAttribute = false,
					statusValue = displayString(status);
				filters.forEach((filter) => {
					if (
						!userCanViewAllOrders &&
						filter === orderConst.STATUS.ORDER_PENDING
					) {
						userHasAttribute =
							userHasAttribute ||
							statusValue === orderConst.STATUS.ERROR ||
							statusValue === orderConst.STATUS.ORDER_APPROVED ||
							statusValue === orderConst.STATUS.AWAITING_PAYMENT;
					} else if (
						!userCanViewAllOrders &&
						filter === orderConst.STATUS.SUBMITTED_FOR_REVIEW
					) {
						userHasAttribute =
							userHasAttribute ||
							statusValue ===
								orderConst.STATUS.SUBMITTED_FOR_APPROVAL;
					} else {
						userHasAttribute =
							userHasAttribute || statusValue === filter;
					}
				});
				return userHasAttribute;
			}
		});
		this.state = {
			dataFormat,
			searchableFields: [],
			searchableFieldPlaceHolder: '',
			title,
			filterProperties,
			isLoading: true
		};
		this.toggleLoading = this.toggleLoading.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	componentDidMount() {
		this.getTableData();
		this.getOrderData();
		this.getPlatforms();
		this.getStatus();
	}

	async componentDidUpdate() {
		if (!this.props.sunsetFeaturesReady) {
			return;
		}
		this.componentDidUpdate = null;
	}

	historyPush(url) {
		this.props.history.push(url);
	}

	canViewRVL() {
		const { userProfile } = this.props;
		return userProfile.platform_features?.['physical_order']?.['RVL']?.includes('view');
	}

	getTableData() {
		const { userProfile } = this.props;
		let searchableFields = ['publisher_po'];
		let searchableFieldPlaceHolder = 'Search by Publisher PO';
		if (isAuthorized(userProfile.permissions, [permConst.ORDER.VIEW.ALL])) {
			searchableFields = [
				'noa_po',
				'publisher_po',
				'publisher',
				'req_number',
				'noa_sales_order_number'
			];
			searchableFieldPlaceHolder = 'Search Within';
		}
		this.setState({
			searchableFields,
			searchableFieldPlaceHolder
		});
	}

	getTableFormat() {
		const chevronLink = ({original}) => {
			const { userProfile } = this.props;
			const orderStatus = original.order_status.toUpperCase();
			const reviewOrder =
				(orderStatus === orderConst.STATUS.DRAFT.toUpperCase() &&
					!isAuthorized(userProfile.permissions, [
						permConst.ORDER.CREATE.ALL,
						permConst.ORDER.CREATE.COMPANY
					])) ||
				orderStatus !== orderConst.STATUS.DRAFT.toUpperCase();

			if (reviewOrder) {
				return <Link to={`/orders/review/${original.order_header_id}`}><FAIcon name="chevron-right" className="text-primary" /></Link>;
			} else {
				return <Link to={`/orders/${original.order_header_id}/create`}><FAIcon name="chevron-right" className="text-primary" /></Link>;
			}
		};

		const { userProfile } = this.props;

		let userCanViewAllOrders = isAuthorized(userProfile.permissions, [
				permConst.ORDER.VIEW.ALL,
			]),
			tableConfiguration = [
				{ Header: 'Platform', accessor: 'platform' },
				{ Header: 'Publisher PO', accessor: 'publisher_po' },
				{
					Header: 'Date Submitted',
					accessor: 'submission_date',
					sortMethod: (a, b) => {
						return new Date(a) > new Date(b) ? 1 : -1;
					},
					Cell: (cell) => formatDate(parseDateString(cell.value), dateFormat.DATE),
				},
				{
					Header: 'Quoted Price',
					id: 'total_price',
					width: 116,
					className: 'text-right',
					headerClassName: 'text-right',
					accessor: (row) => parseInt(row.total_price, 10),
					Cell: (cell) => (cell.value ? formatCurrency(cell.value, 0) : ''),
				},
				{
					Header: 'Status',
					accessor: 'order_status',
					width: 160,
					Cell: (cell) =>
						cell.value ? (
							<StatusText>
								{displayOrderStatus(cell.value, userCanViewAllOrders)}
							</StatusText>
						) : (
							''
						),
				},
				{
					...linkColumn,
					Cell: chevronLink,
				},
			];
		if (userCanViewAllOrders) {
			tableConfiguration.splice(0, 0, {
				Header: 'Publisher',
				accessor: 'publisher',
				width: 120
			});
			tableConfiguration.splice(3, 0, {
				Header: 'Sales Order',
				accessor: 'noa_sales_order_number'
			});
			tableConfiguration.splice(4, 0, {
				Header: 'Req Number',
				accessor: 'req_number',
				width: 80
			});
			tableConfiguration.splice(5, 0, {
				Header: 'NOA PO',
				accessor: 'noa_po',
				width: 80
			});

			tableConfiguration.splice(7, 0, {
				Header: 'Last Modified',
				accessor: 'modification_date',
				width: 110,
				sortMethod: (a, b) => {
					return new Date(a) > new Date(b) ? 1 : -1;
				},
				Cell: (cell) => formatDate(parseDateString(cell.value), dateFormat.DATE)
			});
		}
		return tableConfiguration;
	}

	getStatus() {
		const { filterProperties } = this.state;
		const { userProfile } = this.props;
		const userRoles = userProfile.roles.map((r) => {
			return r.name;
		});
		let userCanViewAllOrders = isAuthorized(
			this.props.userProfile.permissions,
			[permConst.ORDER.VIEW.ALL]
		);

		filterProperties
			.get('Status')
			.selectableFilters.add(displayString(orderConst.STATUS.DRAFT));
		filterProperties
			.get('Status')
			.selectableFilters.add(
				displayString(orderConst.STATUS.SUBMITTED_FOR_REVIEW)
			);
		if (userCanViewAllOrders) {
			filterProperties
				.get('Status')
				.selectableFilters.add(
					displayString(orderConst.STATUS.SUBMITTED_FOR_APPROVAL)
				);
		}
		filterProperties
			.get('Status')
			.selectableFilters.add(
				displayString(orderConst.STATUS.ORDER_PENDING)
			);
		if (userCanViewAllOrders) {
			filterProperties
				.get('Status')
				.selectableFilters.add(
					displayString(orderConst.STATUS.ORDER_APPROVED)
				);
		}
		filterProperties
			.get('Status')
			.selectableFilters.add(
				displayString(orderConst.STATUS.AWAITING_PAYMENT)
			);
		filterProperties
			.get('Status')
			.selectableFilters.add(
				displayString(orderConst.STATUS.IN_MANUFACTURING)
			);
		filterProperties
			.get('Status')
			.selectableFilters.add(
				displayString(orderConst.STATUS.PRODUCTION_PLANNING)
			);
		filterProperties
			.get('Status')
			.selectableFilters.add(displayString(orderConst.STATUS.COMPLETE));
		filterProperties
			.get('Status')
			.selectableFilters.add(displayString(orderConst.STATUS.REJECTED));
		filterProperties
			.get('Status')
			.selectableFilters.add(displayString(orderConst.STATUS.CANCELLED));
		if (userCanViewAllOrders) {
			filterProperties
				.get('Status')
				.selectableFilters.add(displayString(orderConst.STATUS.ERROR));
		}
		if (userRoles.includes(roleConstants.ORDER_REVIEW)) {
			filterProperties
				.get('Status')
				.selectedFilters.add(
					displayString(orderConst.STATUS.SUBMITTED_FOR_REVIEW)
				);
		}
		if (userRoles.includes(roleConstants.PURCHASE_ORDER_APPROVER)) {
			filterProperties
				.get('Status')
				.selectedFilters.add(
					displayString(orderConst.STATUS.SUBMITTED_FOR_APPROVAL)
				);
			filterProperties
				.get('Status')
				.selectedFilters.add(
					displayString(orderConst.STATUS.ORDER_APPROVED)
				);
			filterProperties
				.get('Status')
				.selectedFilters.add(displayString(orderConst.STATUS.ERROR));
		}
	}

	getPlatforms() {
		const { filterProperties } = this.state;
		filterProperties
			.get('Platform')
			.selectableFilters.add('Nintendo Switch');
		filterProperties
			.get('Platform')
			.selectableFilters.add('New 3DS Exclusive');
		filterProperties.get('Platform').selectableFilters.add('Nintendo 3DS');
		filterProperties.get('Platform').selectableFilters.add('Wii U');
		if (this.canViewRVL()) {
			filterProperties.get('Platform').selectableFilters.add('Wii');
		}
	}

	createOrderButton() {
		if (
			isAuthorized(this.props.userProfile.permissions, [
				permConst.ORDER.CREATE.COMPANY
			])
		) {
			return (
				<div>
					<button
						className="btn btn-primary btn"
						onClick={() => this.historyPush('/orders/create')}
					>
						<FAIcon name="plus" className="mr-1"/>
						Create Physical Order
					</button>
				</div>
			);
		}
	}

	checkForErrors(ordersArr) {
		const { userProfile } = this.props;
		if (isAuthorized(userProfile.permissions, [permConst.ORDER.VIEW.ALL])) {
			ordersArr.forEach((order) => {
				if (order.errors) {
					order.order_status = orderConst.STATUS.ERROR;
				}
			});
		}
		return ordersArr;
	}

	getOrderData = async () => {
		const rowLimit = 500;
		const { userProfile } = this.props;
		let userCanViewAllOrders = isAuthorized(userProfile.permissions, [
			permConst.ORDER.VIEW.ALL
		]);
		try {
			const orders = await pullAllResults(getOrders, rowLimit);
			this.checkForErrors(orders);
			for (let o of orders) {
				o.order_status = displayOrderStatus(
					o.order_status,
					userCanViewAllOrders
				);
			}
			this.setState({
				orders: orders
			});
		} catch(error) {
			this.setState({
				orders: []
			});
			toasterNotify(
				createMessageForError(error, 'retrieving orders'),
				'error',
				error
			);
		} finally {
			this.toggleLoading();
		}
	};

	render() {
		const { orders } = this.state;
		const {
			dataFormat,
			searchableFields,
			searchableFieldPlaceHolder,
			title,
			filterProperties
		} = this.state;
		let sunsetMessage = getSunsetMessage(this.props.sunsetFeatures, 'display_order_message');
		return (
			<Page>
				<div className="orderlist">
					<div>
						{sunsetMessage ? (
							<Alert variant="danger" className="alert-icon">
								{sunsetMessage}
							</Alert>
						) : null}
						{!this.canViewRVL() ? (
							<Alert variant="danger" className="alert-icon">
								Detailed information about orders for Wii discs is no longer available. A summary of Wii orders can be found in the Orders tab for individual products.
							</Alert>
						) : null}
						<Title
							title={title}
							button={!this.state.isLoading && this.createOrderButton()}
						/>
					</div>
					{this.state.isLoading ? (
						<Loading />
					) : (
						<FilterableTable
							data={orders}
							dataFormat={dataFormat}
							filterProperties={filterProperties}
							searchableFields={searchableFields}
							searchableFieldPlaceHolder={searchableFieldPlaceHolder}
							defaultSorted={[
								{
									id: 'submission_date',
									desc: true,
								},
							]}
							retainPageState
						/>
					)}
				</div>
			</Page>
		);
	}
}

export default connect(mapStateToProps)(OrderList);
