import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';

import BaseModal from '../../BaseModal/BaseModal';

interface DeleteConfirmationModalProps {
    title: string;
    message: string;
    show: boolean;
    closeModal: () => void;
    confirmDelete: () => void;
    submissionMessage?: string;
    lastUpdated?: number;
    deleteLabel?: string;
}
const DeleteConfirmationModal = ({
	title,
	message,
	show,
	closeModal,
	confirmDelete,
	submissionMessage,
	lastUpdated,
	deleteLabel = 'Delete',
}: DeleteConfirmationModalProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const onDelete = async () => {
		setIsLoading(true);
		await confirmDelete();
		setIsLoading(false);
	};

	return (
		<BaseModal show={show} onCancel={closeModal} isSubmitting={isLoading}>
			<BaseModal.Title>
				<span className="text-danger">{title}</span>
			</BaseModal.Title>
			<Alert variant="danger">{message}</Alert>
			<BaseModal.Submit variant="danger" onClick={onDelete}>
				{deleteLabel}
			</BaseModal.Submit>
			{submissionMessage && (
				<BaseModal.SubmissionStatus lastUpdated={lastUpdated}>
					{submissionMessage}
				</BaseModal.SubmissionStatus>
			)}
		</BaseModal>
	);
};

export default DeleteConfirmationModal;
