import React, { useEffect, useState, VFC } from 'react';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';

import { putConsigneeContact } from '../../../services/companiesService';
import { isEmptyObject } from '../../../utils/dataUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { validateEmailAddress, validatePhoneNumber, validateToSchema } from '../../../utils/validationUtils';
import BaseModal from '../../BaseModal/BaseModal';
import Forms from '../../Forms/Forms';

const schema = yup.object().shape({
	consignee_email_address: yup
		.string()
		.nullable()
		.trim()
		.required('Email Address is required')
		.test('validEmailAddress', 'Email address is invalid', (value) =>
			validateEmailAddress(value!),
		),
	consignee_phone_number: yup
		.string()
		.nullable()
		.trim()
		.required('Phone Number is required')
		.test('validPhoneNumber', 'Please only enter numbers, dash/minus ("-") or plus ("+") as a prefix. Must have 6-15 numerals.', (value) =>
			validatePhoneNumber(value!),
		),
});

interface ConsigneeContactModalProps {
	initialValues: {
		consignee_email_address: string | null,
		consignee_phone_number: string | null,
	}
	companyId?: string;
    show: boolean;
    onClose: () => void;
    onChanged: () => Promise<void>;
}
const ConsigneeContactModal: VFC<ConsigneeContactModalProps> = ({
	show,
	onClose,
	onChanged,
	companyId,
	initialValues,
}) => {
	const [formValues, setFormValues] =
		useState<Record<'consignee_email_address' | 'consignee_phone_number', string | null>>(
			initialValues,
		);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);
	const queryClient = useQueryClient();

	useEffect(() => {
		if (show) {
			setShowAllErrors(false);
			setFormValues(initialValues);
		}
	}, [show]);

	const submitValues = async () => {
		if (isSubmitting || !companyId) {
			return;
		}

		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}

		setIsSubmitting(true);
		
		try {
			const castedFormValues = schema.cast(formValues, { stripUnknown: true });
			await putConsigneeContact(
				companyId,
				castedFormValues.consignee_email_address!,
				castedFormValues.consignee_phone_number!,
			);
			queryClient.invalidateQueries(['getCompanyProfile', companyId]);
			await onChanged();

			toasterNotify(
				'Consignee contact information has been successfully saved.',
				'success',
			);
		} catch (error: any) {
			toasterNotify(
				createMessageForError(error, 'updating company consignee contact information'),
				'error',
				error,
			);
		} finally {
			setIsSubmitting(false);
		}
		setShowAllErrors(false);
	};

	const validationErrors = validateToSchema(schema, formValues);

	return (
		<BaseModal show={show} isSubmitting={isSubmitting} onCancel={() => onClose()}>
			<BaseModal.Title>Edit Consignee Contact Information</BaseModal.Title>
			<Forms
				values={formValues}
				onChange={(newFormValues) => setFormValues(newFormValues)}
				validationErrors={validationErrors}
				showAllErrors={showAllErrors}
			>
				<Forms.Text id="consignee_email_address" maxLength={100}>
					<Forms.Heading>Email Address</Forms.Heading>
				</Forms.Text>
				<Forms.Text id="consignee_phone_number" maxLength={25}>
					<Forms.Heading>Phone Number</Forms.Heading>
				</Forms.Text>
			</Forms>
			<BaseModal.Submit onClick={() => submitValues()}></BaseModal.Submit>
		</BaseModal>
	);
};

export default ConsigneeContactModal;
