import React, { PropsWithChildren, VFC } from 'react';
import { Col, Row } from 'react-bootstrap';

import './PropertyDisplay.css';

interface PropertyDisplayProps {
	label?: string;
	labelCol?: number;
	noStripes?: boolean;
	compact?: boolean;
}
const PropertyDisplay: VFC<PropsWithChildren<PropertyDisplayProps>> = ({
	label = '',
	children,
	labelCol = 5,
	noStripes = false,
	compact = false,
}) => (
	<Row
		as={'dl'}
		className={`PropertyDisplay${noStripes ? ' PropertyDisplay--no-stripes' : ''}${
			compact ? ' PropertyDisplay--compact' : ''
		}`}
	>
		{compact ? (
			<>
				<dt>{label}</dt>
				<dd>{children != null || String(children) === '0' ? children : '—'}</dd>
			</>
		) : (
			<>
				<Col as={'dt'} xs={12} md={labelCol} className="">
					{label}
				</Col>
				<Col as={'dd'} xs={12} md={11 - labelCol} className="mb-0">
					{(children != null &&
						(!Array.isArray(children) || (children as any[]).length > 0)) ||
					String(children) === '0'
						? children
						: '—'}
				</Col>
			</>
		)}
	</Row>
);

export default PropertyDisplay;
