import React, { PropsWithChildren, ReactElement, VFC } from 'react';

interface HelpBlockProps {
    variant?: TextVariant;
}
const HelpBlock: VFC<PropsWithChildren<HelpBlockProps>> = ({
	children,
	variant,
}: PropsWithChildren<HelpBlockProps>): ReactElement => (
	<div className={`help-block${variant ? ' text-' + variant : ' '}`}>{children}</div>
);
export default HelpBlock;
