import { SET_USER_PROFILE } from '../actions/action-types';

const initialState = {};

function authReducer(state = initialState, action: { type: string, payload: UserProfile }) {
	switch (action.type) {
		case SET_USER_PROFILE:
			return { ...state, userProfile: action.payload };
		default:
			return state;
	}
}

export default authReducer;
