import { assetConst } from '../constants/assetConstants';
import { subConst, productTypes } from '../constants/submissionsConstants';
import api from './baseService';

const pollingUrlPath = '/pdb/executions/';
type ProductSummaryData = Pick<ProductData, 'product_id' | 'game_code' | 'game_name' | 'platform_code'> & {
	release_date?: string;
	retail_status?: string;
	system_name?: string;
	company_name?: string;
}

// this function is deprecated, use getProductsInBulk instead
export const getProducts =
	(q_string: string) => api().get<ProductSummaryData[]>(`/pdb/products?${q_string}`);

export const createProduct = (product: Record<string, any>) => api().post('/pdb/products', product);

export const updateProduct = (productId: string, product: Record<string, any>) =>
	api().put(`/pdb/products/${productId}`, product);

export const getProduct = (productId: string) => api().get<ProductData>(`/pdb/products/${productId}`);

export const getProductsInBulk = (
	productIdsOrGameCodes: (string | number)[],
	fields: string[] = [],
	otherParams?: Record<string, any> | URLSearchParams
) => {
	const params = new URLSearchParams(otherParams);
	productIdsOrGameCodes.forEach((v) => {
		// eslint-disable-next-line eqeqeq
		if (v == Number(v)) {
			params.append('product_id', String(v));
		} else if (typeof v === 'string') {
			params.append('game_code', String(v));
		}
	});
	fields.forEach((v) => params.append('field', v));
	return api().get<ProductSummaryData[]>('/pdb/products/', { params });
};

export interface PhysicalOrderChecklistData {
	agreement: boolean,
	no_transfer: boolean,
	noa_part_number: boolean,
	standard_rating: boolean,
	valid_submission: boolean,
	priced: boolean,
	publisher_part_number: boolean,
	distribution_rights: boolean,
	initial_label: boolean
}
export const getPhysicalOrderChecklist = (productId: string) =>
	api().get<PhysicalOrderChecklistData>(`/pdb/physicalorders/products/${productId}/checklist`, {
		pollingUrl: pollingUrlPath,
		pollingTimeLimit: 120000,
	});

export const getProductGameConfiguration = (productId: string) =>
	api().get(`/pdb/products/${productId}/gameconfiguration`);

export const postRequestGamecode = (productId: string) =>
	api().post(`/pdb/products/${productId}/requestgamecode`);

export const putGameCode = (productId: string, payload: Record<string, any>) =>
	api().put(`/pdb/products/${productId}/gamecode`, payload);

export const getProductSummary = (productId: string) => api().get(`/pdb/products/${productId}`);

export const getProductRetailPrice = (productId: string) =>
	api().get(`/pdb/products/${productId}/retail_price`);

export const getAssetApprovalList = () => api().get('/pdb/products/assets/review');

export const getAssetApprovalListByCategory = (category: string, token?: string) =>
	api().get(
		`/pdb/products/assets/review?asset_category=${category}`,
		token ? { headers: { 'X-PDB-Authorization': token } } : undefined,
	);

interface GetProductAssetData {
	asset_id: number;
	asset_type: (typeof assetConst.TYPE)[keyof typeof assetConst.TYPE];
	asset_type_other_name: null;
	languages: ('en' | 'fr' | 'es' | 'pt')[];
	review_status: (typeof assetConst.STATUS)[keyof typeof assetConst.STATUS];
	submitted_date: string;
	description: string | null;
	due_date: string | null;
	final_label_forgo_status: ValuesOf<typeof assetConst.FINAL_LABEL_FORGO_STATUS> | null;
	asset_thumbnail_file_id: number | null;
	most_recent_files: {
		file_name: string;
		file_id: number;
		s3_key: string;
		uploaded_date: string;
		status: string;
	}[];
}
export const getProductAssets = (productId: string, category: string, token?: string) =>
	api().get<GetProductAssetData[]>(
		`/pdb/products/${productId}/assets?asset_category=${category}`,
		token ? { headers: { 'X-PDB-Authorization': token } } : undefined,
	);

interface AssetDetailsData {
	product: Pick<	
		ProductData,
		'product_id' | 'game_name' | 'platform_code' | 'distribution' | 'active_rights_transfer'
	>;
	product_asset: ProductAssetData;
	product_asset_revisions: {
		review_status: ProductAssetData['review_status'];
		final_label_forgo_status: ProductAssetData['final_label_forgo_status'];
		files: SFSFileData[];
		file_upload_type: string | null;
		comment: string | null;
		submitted_by_name: string;
		submitted_by_company: string;
		submitted_date: string;
		submitted_by_company_ndid: string;
		anticipated_proof_date: string | null;
	}[];
}

export const getAssetDetails = (assetId: string, token?: string) =>
	api().get<AssetDetailsData>(
		`/pdb/products/assets/${assetId}`,
		token ? { headers: { 'X-PDB-Authorization': token } } : undefined,
	);

interface PostProductAssetData {
	asset_id: number,
    revision_id: number,
	transfer_specs: AsperaUploadSpec[];
}
export const postProductAsset = (productId: string, asset: Record<string, any>) =>
	api().post<PostProductAssetData>(`/pdb/products/${productId}/assets`, asset);

export const getProductAssetTypes = (productId: string, category: string) =>
	api().get<ProductAssetType[]>(`/pdb/products/${productId}/asset-types?asset_category=${category}`);

export const getSubmissionsList = (queryString: string) =>
	api().get(`/pdb/submissions?${queryString}`);

interface ProductSubmissionsData {
	product_submission_id: number;
	submission_date: string;
	product_release_version: string;
	product_submission_version: string;
	product_type: typeof productTypes;
	status: typeof subConst.STATUS[keyof typeof subConst.STATUS];
	type: typeof subConst.TYPE[keyof typeof subConst.TYPE];
	distribution_type: 'DIGITAL' | 'PHYSICAL';
	approval_date: string | null;
}
export const getProductSubmissions = (productId: string) =>
	api().get<ProductSubmissionsData[]>(`/pdb/products/${productId}/submissions`);

export const setPartNum = (productId: string, value: Record<string, any>) =>
	api().put(`/pdb/products/${productId}/partnumber`, value);

export const deletePartNum = (productId: string) =>
	api().delete(`/pdb/products/${productId}/partnumber`);

export const checkPartNumSet = (productId: string) =>
	api().get(`/pdb/products/${productId}/partnumber`);

export const postProductSubmission = (productId: string, submission: Record<string, any>) =>
	api().post(`/pdb/products/${productId}/submission`, submission);

export const getFailureTypes = (familyCode: string) =>
	api().get(`/pdb/products/failuretypes/${familyCode}`);

export const postLotcheckApproval = (approval: Record<string, any>) =>
	api().post('/pdb/products/lotcheck/approval', approval);

export const postROMApproval = (approval: Record<string, any>) =>
	api().post('/pdb/products/rom/approval', approval);

export const setProductStatus = (productId: string, status: string) => {
	return api().put(`/pdb/products/${productId}/status`, {
		status: status,
	});
};

interface AssetData {
	revision_id: number;
	transfer_specs: AsperaUploadSpec[],
}
export const postAssetStatus = (
	assetId: string,
	payload: Record<string, any>,
	token?: string,
) =>
	api().post<AssetData>(
		`/pdb/products/assets/${assetId}/revisions`,
		payload,
		token ? { headers: { 'X-PDB-Authorization': token } } : undefined,
	);

export const getAssetTypes = (
	platformCodes: string | string[],
	assetCategory: string = 'MARKETING_EVENT',
	token?: string,
) => {
	platformCodes = Array.isArray(platformCodes) ? platformCodes : [platformCodes];

	const params = new URLSearchParams();
	params.append('asset_category', assetCategory);
	platformCodes.forEach((v) => params.append('platform_code', v));
	return api().get('/pdb/products/assets/asset-types', {
		params,
		...(token && { headers: { 'X-PDB-Authorization': token } }),
	});
};

export interface NCLLabelQueueData {
	product_id: number;
	game_name: string;
	game_code: string;
	company_name: string;
	ndid_company_id: string;
	asset_id: number;
	submitted_date: string;
	final_label_forgo_status: ValuesOf<typeof assetConst.FINAL_LABEL_FORGO_STATUS>;
	ncl_status: ValuesOf<typeof assetConst.NCL_FINAL_LABEL_STATUS>;
	version: number;
	final_label_files: {
		file_id: number;
		file_name: string;
		uploaded_date: string;
		status: string;
	}[];
}
export const getNCLLabelQueue = () =>
	api().get<NCLLabelQueueData[]>('/pdb/products/assets/ncl-review');

interface LabelHistoryData {
	version: number;
	review_status: ValuesOf<typeof assetConst.NCL_FINAL_LABEL_STATUS>;
	final_label_forgo_status: ValuesOf<typeof assetConst.FINAL_LABEL_FORGO_STATUS>;
	files: {
		file_name: string;
		file_id: number;
		s3_key: string;
		uploaded_date: string;
		status: string;
	}[];
	comment: string | null;
	submitted_by_name: string | null;
	submitted_by_company: string | null;
	submitted_date: string;
	anticipated_proof_date: string | null;
}
export const getLabelHistory = (productId: string) =>
	api().get<{
		ncl_status: ValuesOf<typeof assetConst.NCL_FINAL_LABEL_STATUS>;
		current_version: number;
		current_forgo_status: ValuesOf<typeof assetConst.FINAL_LABEL_FORGO_STATUS>;
		product_asset_revisions: LabelHistoryData[];
	}>(`pdb/products/${productId}/labels`);
