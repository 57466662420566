import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Badge, Button } from 'react-bootstrap';

import FilterableTable from '../../components/FilterableTable/FilterableTable';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import {
	deleteFreightForwarderAssociation,
	getFreightForwarderAssociations,
} from '../../services/ordersService';
import StatusText from '../../components/StatusText/StatusText';
import AssociateFreightForwarderModal from '../../components/modals/FreightForwarderModal/AssociateFreightForwarderModal';
import { useUserProfile } from '../../hooks/reduxHooks';
import { isAuthorized } from '../../utils/userUtils';
import { permConst } from '../../constants/permConst';
import FAIcon from '../../components/FAIcon/FAIcon';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import { actionsColumn } from '../../components/BaseTable/BaseTable';
import DeleteConfirmationModal from '../../components/modals/DeleteConfirmationModal/DeleteConfirmationModal';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { Column } from 'react-table-v6';

interface OpenModal {
	type?: 'SAVE_MODAL' | 'REMOVE_MODAL' | null;
	code?: string;
	name?: string;
	freightForwarder?: {
		name?: string;
		freight_forwarder_code?: string;
		carrier_account_number?: string;
		default_flag: boolean;
		japanese_contact_email?: string;
		japanese_contact_name?: string;
		japanese_contact_phone_number?: string;
		service_level?: string;
	};
}

const FreightForwarderAssociation = () => {
	const [openModal, setOpenModal] = useState<OpenModal>();
	const userProfile = useUserProfile();
	const canEditFreightForwarderAssociation = isAuthorized(userProfile.permissions, [
		permConst.FREIGHT_FORWARDER.EDIT.COMPANY,
	]);

	const freightForwarderAssociationsQuery = useQuery('getFreightForwarderAssociations', () =>
		getFreightForwarderAssociations(),
	);
	const freightForwarderAssociations = freightForwarderAssociationsQuery.data?.data || [];

	const isLoading = freightForwarderAssociationsQuery.isLoading;
	const fault = freightForwarderAssociationsQuery.isError;

	const AdditionalFieldCell = ({ value }: { value?: string }) => {
		return !value ? <> &mdash; </> : <div>{value}</div>;
	};

	const dataFormat = useMemo(() => {
		const format: Column<ArrayElement<typeof freightForwarderAssociations>>[] = [
			{
				Header: 'Freight Forwarder',
				accessor: 'name',
				Cell: ({ original }: { original: ArrayElement<typeof freightForwarderAssociations>; }) => {
					return original.company_freight_forwarder.default_flag ? (
						<div>
							{' '}
							{original.name} <Badge variant={'info'}>Default</Badge>
						</div>
					) : (
						original.name
					);
				},
			},
			{
				Header: 'Default Flag',
				id: 'default_flag',
				accessor: (row) => {
					return String(row.company_freight_forwarder.default_flag);
				},
				show: false,
			},
			{
				Header: 'JP Contact',
				accessor: 'company_freight_forwarder.japanese_contact_name',
				Cell: AdditionalFieldCell,
			},
			{
				Header: 'JP Email',
				accessor: 'company_freight_forwarder.japanese_contact_email',
				Cell: AdditionalFieldCell,
			},
			{
				Header: 'JP Phone Number',
				accessor: 'company_freight_forwarder.japanese_contact_phone_number',
				Cell: AdditionalFieldCell,
			},
			{
				Header: 'Service Level',
				accessor: 'company_freight_forwarder.service_level',
				Cell: AdditionalFieldCell,
			},
			{
				Header: 'Carrier Account #',
				accessor: 'company_freight_forwarder.carrier_account_number',
				Cell: AdditionalFieldCell,
			},
			{
				Header: 'Status',
				accessor: 'status',
				Cell: (cell: Record<string, any>) =>
					cell.value ? <StatusText>{cell.value}</StatusText> : '',
			}
		];
		if (canEditFreightForwarderAssociation) {
			format.push(
				{
					...actionsColumn,
					Cell: ({ original }: { original: ArrayElement<typeof freightForwarderAssociations>; }) => (
						<MeatballDropdown>
							{original.status === 'ACTIVE' && <MeatballDropdown.Item
								onClick={() =>
									setOpenModal({
										...openModal,
										type: 'SAVE_MODAL',
										freightForwarder: {
											name: original.name,
											freight_forwarder_code: original.code,
											carrier_account_number: original.company_freight_forwarder.carrier_account_number,
											default_flag: original.company_freight_forwarder.default_flag,
											japanese_contact_email: original.company_freight_forwarder.japanese_contact_email,
											japanese_contact_name: original.company_freight_forwarder.japanese_contact_name,
											japanese_contact_phone_number: original.company_freight_forwarder.japanese_contact_phone_number,
											service_level: original.company_freight_forwarder.service_level,
										}
									})
								}
							>
								Manage Freight Forwarder
							</MeatballDropdown.Item>}
							<MeatballDropdown.Item
								onClick={() =>
									setOpenModal({
										...openModal,
										type: 'REMOVE_MODAL',
										code: original.code,
										name: original.name,
									})
								}
							>
								<span className="text-danger">Remove Freight Forwarder</span>
							</MeatballDropdown.Item>
						</MeatballDropdown>
					),
				}
			);
		}
		return format;
	}, []);

	const onClose = () => {
		setOpenModal({ ...openModal, type: null });
	};

	return (
		<Page fault={fault} isLoading={isLoading}>
			<Title
				title="Manage Freight Forwarders"
				button={
				<div>
					{canEditFreightForwarderAssociation && (
						<Button
							variant="primary"
							id="addFFAssociation"
							onClick={() =>
								setOpenModal({
									type: 'SAVE_MODAL',
								})
							}
						>
							<FAIcon className="mr-1" name="plus" />
							Add Freight Forwarder
						</Button>
					)}
			</div>
			} />
			<FilterableTable
				data={freightForwarderAssociations}
				dataFormat={dataFormat}
				searchableFields={['name']}
				searchableFieldPlaceHolder={'Search by Freight Forwarder'}
				defaultSorted={[
					{
						id: 'default_flag',
						desc: true,
					},
					{
						id: 'status',
						desc: false,
					},
					{
						id: 'name',
						desc: false,
					},
				]}
			/>
			<AssociateFreightForwarderModal
				show={openModal?.type === 'SAVE_MODAL'}
				onCompletion={(freightForwarderCode) => {
					freightForwarderAssociationsQuery.refetch();
					onClose();
				}}
				onClose={onClose}
				freightForwarderAssociation={openModal?.freightForwarder}
			/>
			<DeleteConfirmationModal
				show={openModal?.type === 'REMOVE_MODAL'}	
				title="Remove Freight Forwarder"
				closeModal={onClose}
				confirmDelete={async () => {
					try {
						await deleteFreightForwarderAssociation(String(openModal?.code));
						setOpenModal({});
						freightForwarderAssociationsQuery.refetch();
					} catch (error: any) {
						toasterNotify(
							createMessageForError(error, 'removing the freight forwarder'),
							'error',
							error
						);
					}
				}}
				message={`Are you sure you want to remove "${openModal?.name}?"`}
				deleteLabel="Remove"
			/>
		</Page>
	);
};

export default FreightForwarderAssociation;
