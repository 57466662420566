import React, { Component } from 'react';
import dayjs from 'dayjs';
import { Alert, FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';

import FilterableTable from '../../components/FilterableTable/FilterableTable';
import Loading from '../../components/Loading/Loading';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import { permConst } from '../../constants/permConst';
import { platformNames } from '../../constants/platformConstants';
import { getCompanyProfile } from '../../services/companiesService';
import {
	getLatestProjectionWindows,
	getProjectableProducts,
	getProjections,
	getProjectionsList,
	putProjections
} from '../../services/projectionsService';
import { dateFormat, formatDate, parseDateString } from '../../utils/dateUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { determineActiveRightTransfer, transferDomains } from '../../utils/transferRightsUtils';
import { isAuthorized } from '../../utils/userUtils';
import { connectQueryData, useCompanyAgreementsQuery } from '../../hooks/queryHooks';
import { companyAgreementConstants } from '../../constants/companyAgreementConstants';


const TITLE = 'Order Projections';
const ALERT_TEXT =
	'Please enter your projections for the product below. All active retail products are included in the list. To set a product as Active/Inactive, please see the Product Summary.\n' +
	"Projections aren't considered complete until Submit is selected.";
const RECEIVED_TEXT =
	'Your projections have been completed for this projection cycle. You may modify the projections until the cycle closes.';
const ALERT_TEST_SUBMISSION_WINDOW_CLOSED = '';
const INVALID_ALERT_TEXT = 'Entries must be in case quantities.';
const RETAIL_AGREEMENT_MISSING_TEXT =
	"Projections can't be submitted because an active/in negotiation agreement is missing.";

const PREVIOUS_MONTHS_TO_DISPLAY = 2;
const FUTURE_MONTHS_TO_DISPLAY = 6;

function mapStateToProps(state, prop) {
	return {
		userProfile: state.authReducer.userProfile
	};
}

function generateProducts(products) {
	if (products && products.length > 0) {
		return products.reduce((map, product) => {
			if (product && product.product_id) {
				map[product.product_id] = product;
			}
			return map;
		}, {});
	}
	return {};
}

function generateProjections(projections, state) {
	const { products } = state;

	const productIds = Object.keys(products);
	productIds.forEach((product_id) => {
		if (
			!projections.some(
				(projection) => projection.product_id === parseInt(product_id)
			)
		) {
			const newProjections = {};

			const product = products[product_id];
			newProjections.platform_code = product.platform_code;
			newProjections.product_name = product.game_name;
			newProjections.minimum_order_qty = product.minimumOrderQty;
			newProjections.minimum_bulk_qty = product.minimumBulkQty;
			newProjections.order_increment_qty = product.order_increment_qty;
			newProjections.product_id = product_id;
			newProjections.projections = [];
			newProjections.initial_order = true;
			projections.push(newProjections);
		}
	});

	if (projections && projections.length > 0) {
		return projections.map((projection) => {
			projection.order_increment_qty =
				products[projection.product_id].order_increment_qty;
			projection.initial_order = !!(
				products[projection.product_id] &&
				products[projection.product_id].initial_order === 1
			);
			return projection;
		});
	}

	return [];
}

export class OrderProjections extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoadingProjections: false,
			isLoadingCompany: true,
			isLoadingProjWindow: true,
			isLoadingProducts: false,
			updatingProjections: false,
			projectionsReceived: false,
			projections: []
		};
		this.sendUpdates = this.sendUpdates.bind(this);
		this.renderEnabledCell = this.renderCell.bind(this, false);
		this.renderDisabledCell = this.renderCell.bind(this, true);
		this.resolveLoading = this.resolveLoading.bind(this);
		this.determineProjectionsReceived = this.determineProjectionsReceived.bind(this);
	}

	resolveLoading() {
		const state = this.state;
		return (
			state.isLoadingProjections ||
			state.isLoadingCompany ||
			state.isLoadingProjWindow ||
			state.isLoadingProducts
		);
	}

	componentDidMount() {
		this.getCompanyProfile();
		this.determineProjectionsReceived();
	}

	getCompanyProfile() {
		const { userProfile, match } = this.props;
		const companyId =
			match.params && match.params.id != null
				? match.params.id
				: userProfile.companyId;

		let canMakeProjections = false;

		getCompanyProfile(companyId)
			.then((response) => {
				this.setState({
					companyProfile: response.data,
				});

				canMakeProjections =
					response.data.company_information.projection_eligible_flag === 1;
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving company profile'),
					'error',
					error
				);
			})
			.finally(() => {
				let needsToloadProducts = false;

				if (canMakeProjections) {
					this.getProjectionWindow(companyId);

					needsToloadProducts = true;
				}

				this.setState({
					isLoadingCompany: false,
					isLoadingProducts: needsToloadProducts
				});
			});
	}

	isEditable() {
		const { projectionWindow } = this.state;
		return projectionWindow && projectionWindow.is_open && this.userCanEdit();
	}

	userCanEdit() {
		return isAuthorized(this.props.userProfile.permissions, [permConst.PROJECTION.EDIT.COMPANY]);
	}

	renderWindowRange() {
		const { projectionWindow } = this.state;

		if (!projectionWindow) {
			return '';
		}

		return (
			<>
				{'Projection Period: '}
				{formatDate(
					parseDateString(projectionWindow.submission_start_date),
					dateFormat.DATETIME_PT,
				)}
				{' '}&ndash;{' '}
				{formatDate(
					parseDateString(projectionWindow.submission_end_date),
					dateFormat.DATETIME_PT,
				)}
			</>
		);
	}

	renderCell(disabled, cell) {
		const { projections, projectionRanges } = this.state;

		const productId = cell.original?.product_id || // react table 6
			cell.row?.original?.product_id; // tanstack table 8
		const month = cell.column?.meta?.month || // react table 6
			cell.column?.columnDef?.meta?.month; // tanstack table 8

		const projectionIndex = projections.findIndex(projection => projection.product_id === productId);
		const projectionRange = projectionRanges.filter((obj) => {
			return (
				obj.platform_code === projections[projectionIndex]['platform_code']
			);
		});
		const activeRightTransfer = determineActiveRightTransfer(
			projections[projectionIndex],
			transferDomains.PROJECTION
		);

		const value = projections[projectionIndex]['projections'].filter((obj) => {
			const projectionDate = dayjs(obj.projection_year_month).format(
				'MMM YY'
			);
			const columnDate = dayjs(month).format(
				'MMM YY'
			);
			return projectionDate === columnDate;
		});

		const quantity = value[0] ? value[0].projected_quantity : null;
		const minimumBulkQuantity = projections[projectionIndex].minimum_bulk_qty;
		const isNumber = !isNaN(quantity);
		const isValidProjection =
			isNumber && quantity % minimumBulkQuantity === 0;

		const projectionDate = dayjs(month);

		const isEditableProjection =
			projectionDate.isSameOrAfter(
				dayjs(projectionRange[0].start_year_month).startOf('month')
			) &&
			projectionDate.isSameOrBefore(
				dayjs(projectionRange[0].end_year_month).endOf('month')
			);

		const cellDisabled =
			disabled ||
			!this.isEditable() ||
			!isEditableProjection ||
			activeRightTransfer;

		const cellValue = value[0] ? value[0].projected_quantity : '';

		return (
			<FormControl
				type="text"
				disabled={cellDisabled}
				className="projectionCell"
				style={
					isValidProjection ? { color: 'black' } : { color: 'red' }
				}
				value={cellValue}
				onChange={(e) => this.updateProjection(cell, e)}
			/>
		);
	}

	determineProjectionsReceived() {
		getProjectionsList()
			.then((response) => {
				this.setState({
					projectionsReceived: response.data[0].projections_received_date
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving projections'),
					'error',
					error
				);
			});
	}

	updateProjection(cell, e) {
		const { projections } = this.state;
		
		const productId = cell.original?.product_id || // react table 6
			cell.row?.original?.product_id; // tanstack table 8
		const month = cell.column?.meta?.month || // react table 6
			cell.column?.columnDef?.meta?.month; // tanstack table 8

		const projectionIndex = projections.findIndex(projection => projection.product_id === productId);

		const value = projections[projectionIndex]['projections'].filter((obj) => {
			return (
				dayjs(obj.projection_year_month).format('MMM YY') ===
				dayjs(month).format('MMM YY')
			);
		});

		if (value[0]) {
			value[0].projected_quantity = e.currentTarget.value;
		} else {
			projections[projectionIndex]['projections'].push({
				projection_year_month: dayjs(month).format('YYYY-MM-DD HH:mm:ss'),
				projected_quantity: e.currentTarget.value,
			});
		}

		this.setState({
			projections: projections
		});
	}

	getTableStructure() {
		const showNewIndicator = isAuthorized(
			this.props.userProfile.permissions,
			[permConst.PROJECTION.VIEW.ALL]
		);

		let tableConfiguration = [
			{
				Header: 'Title',
				accessor: 'product_name',
				Cell: (cell) => {
					return (
						<div>
							{showNewIndicator &&
								cell.original.initial_order && (
									<span className="badge badge-warning">
										NEW
									</span>
								)}
							{' '}
							{cell.value}
							<p>
								<span className="text-muted small">
									Case Qty:{' '}
									{cell.original.order_increment_qty}
								</span>
							</p>
						</div>
					);
				}
			},
			{
				Header: 'Platform',
				accessor: 'platform_code',
				maxWidth: 135,
				Cell: (cell) => (cell.value ? platformNames[cell.value] : '')
			}
		];

		this.addDateColumns(tableConfiguration);
		return tableConfiguration;
	}

	addDateColumns(tableConfiguration) {
		const currentDate = dayjs();
		let tempDate = dayjs().subtract(
			PREVIOUS_MONTHS_TO_DISPLAY,
			'months'
		);

		while (tempDate.isSameOrBefore(currentDate)) {
			tableConfiguration.push({
				Header: tempDate.format('MMM YY'),
				maxWidth: 90,
				id: 'projections',
				Cell: this.renderDisabledCell,
				cell: this.renderDisabledCell,
				meta: { month: tempDate.startOf('month') },
			});
			tempDate = tempDate.add(1, 'months');
		}
		tempDate = currentDate;

		for (let i = 0; i < FUTURE_MONTHS_TO_DISPLAY; i++) {
			tempDate = tempDate.add(1, 'months');
			tableConfiguration.push({
				Header: tempDate.format('MMM YY'),
				maxWidth: 90,
				id: 'projections',
				Cell: this.renderEnabledCell,
				cell: this.renderEnabledCell,
				meta: { month: tempDate.startOf('month') },
			});
		}
	}

	getProjectionWindow(companyId) {
		getLatestProjectionWindows()
			.then((response) => {
				this.setState({
					projectionWindow: response.data
				});
				this.getProjectableProducts(companyId);
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving the latest submission window'),
					'error',
					error
				);
			})
			.finally(() => {
				this.setState({ isLoadingProjWindow: false });
			});
	}

	getProjections(companyId) {
		this.setState({ isLoadingProjections: true });
		const { userProfile } = this.props;
		const { projectionWindow } = this.state;

		const id = companyId ? companyId : userProfile.companyId;

		getProjections(
			id,
			projectionWindow.order_projection_submission_period_id
		)
			.then((response) => {
				const projections = generateProjections(
					response.data.projections,
					this.state
				);
				this.setState({
					projections,
					projectionRanges: response.data.projection_ranges
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving projections'),
					'error',
					error
				);
			})
			.finally(() => {
				this.setState({
					isLoadingProjections: false
				});
			});
	}

	getProjectableProducts(companyId) {
		this.setState({ isLoadingProducts: true });
		const { userProfile } = this.props;

		const id = companyId ? companyId : userProfile.companyId;

		getProjectableProducts(id)
			.then((response) => {
				this.setState({
					products: generateProducts(response.data)
				});
				this.getProjections(companyId);
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving projectable products'),
					'error',
					error
				);
			})
			.finally(() => {
				this.setState({ isLoadingProducts: false });
			});
	}

	packageProjections(projections, projectionRanges) {
		return projections.map((obj) => {
			let temp = {};
			temp.product_id = obj.product_id;

			const projectionRange = projectionRanges.find((element) => {
				return element['platform_code'] === obj.platform_code;
			});

			temp.projections = obj.projections
				.filter((projection) => {
					const projectionMonth = dayjs(
						projection.projection_year_month
					);

					return (
						dayjs(projectionMonth).isSameOrAfter(
							dayjs(projectionRange.start_year_month)
						) &&
						dayjs(projectionMonth).isSameOrBefore(
							dayjs(projectionRange.end_year_month)
						)
					);
				})
				.map((projection) => {
					return {
						order_projection_id: projection.order_projection_id,
						projection_year_month: projection.projection_year_month,
						projected_quantity: projection.projected_quantity
					};
				});

			return temp;
		});
	}

	validateProjections() {
		const { projections, projectionRanges } = this.state;
		let isValid = true;

		if (!projections || !projectionRanges) return false;

		projections.forEach((product) => {
			const projectionRange = projectionRanges.filter((obj) => {
				return obj.platform_code === product.platform_code;
			});

			product.projections.forEach((projection) => {
				const projectionDate = dayjs(projection.projection_year_month);

				const isEditableProjection =
					projectionDate.isSameOrAfter(
						dayjs(projectionRange[0].start_year_month).startOf(
							'month'
						)
					) &&
					projectionDate.isSameOrBefore(
						dayjs(projectionRange[0].end_year_month).endOf('month')
					);

				const minimum_bulk_qty = product.minimum_bulk_qty;
				const quantity = projection.projected_quantity;
				if (
					(isNaN(projection.projected_quantity) ||
						quantity % minimum_bulk_qty !== 0) &&
					isEditableProjection
				) {
					isValid = false;
				}
			});
		});
		return isValid;
	}

	sendBatch(projectionBatch) {
		const { userProfile } = this.props;
		const { projectionRanges } = this.state;
		return new Promise((resolve, reject) => {
			putProjections(
				this.packageProjections(projectionBatch, projectionRanges),
				userProfile.companyId
			)
				.catch((error) => {
					toasterNotify(
						createMessageForError(error, 'updating a projection'),
						'error',
						error
					);
				})
				.finally(() => {
					resolve();
				});
		});
	}

	generateBatches() {
		const { projections } = this.state;
		let batches = [];
		let limit = 10;
		let batch = [];
		for (let p in projections) {
			batch.push(projections[p]);
			if (
				batch.length === limit ||
				parseInt(p) + 1 === projections.length
			) {
				batches.push(batch);
				batch = [];
			}
		}
		return batches;
	}

	sendUpdates() {
		const { userProfile, match } = this.props;
		const companyId =
			match.params && match.params.id != null
				? match.params.id
				: userProfile.companyId;
		this.setState({
			updatingProjections: true
		});
		let batches = this.generateBatches();
		let calls = [];
		for (let b in batches) {
			calls.push(this.sendBatch(batches[b]));
		}
		Promise.all(calls)
			.then((response) => {
				toasterNotify('Projections updated', 'success');
			})
			.finally(() => {
				this.determineProjectionsReceived();
				// Call to get the projection window again.  maybe the windows is_open flag has changed?
				this.getProjectionWindow(companyId);
				// Call to get projections needs to happen to get back the projection id
				// of any newly created projections
				this.getProjections(companyId);
				this.setState({
					updatingProjections: false
				});
			});
	}

	getSubmitProjectionsButton(validProjections) {
		return (
			<div className="btn-container">
				<div className="float-right">
					<button
						className="btn btn-primary"
						disabled={
							!validProjections || !this.isEditable() || !this.hasRetailAgreements()
						}
						onClick={() => {
							this.sendUpdates();
						}}
					>
						Update Projections
					</button>
				</div>
			</div>
		);
	}

	hasRetailAgreements() {
		const { companyProfile, products } = this.state;
		const { companyAgreementsQuery } = this.props;

		if (companyProfile && products && products.size) {
			products.forEach((product) => {
				if (
					!companyAgreementsQuery.some((agreement) => {
						return (
							agreement.platform === product.system_name &&
							(agreement.status === companyAgreementConstants.STATUS.IN_NEGOTIATION ||
								agreement.status === companyAgreementConstants.STATUS.EXECUTED)
						);
					})
				) {
					return false;
				}
			});
		}

		return true;
	}

	render() {
		const {
			projections,
			updatingProjections,
			projectionsReceived
		} = this.state;

		const isLoading = this.resolveLoading();

		const validProjections = this.validateProjections();

		const windowOpenAlertText = projectionsReceived
			? RECEIVED_TEXT
			: ALERT_TEXT;

		const AlertText = this.isEditable()
			? windowOpenAlertText
			: ALERT_TEST_SUBMISSION_WINDOW_CLOSED;

		let hasRetailAgreements = this.hasRetailAgreements();

		const invalidAlertText = hasRetailAgreements
			? INVALID_ALERT_TEXT
			: RETAIL_AGREEMENT_MISSING_TEXT;

		const button = this.getSubmitProjectionsButton(validProjections);

		return (
			<Page>
				<div className="order-projections">
					{!isLoading && (AlertText || !hasRetailAgreements) && (
						<Alert
							variant={validProjections && hasRetailAgreements ? 'warning' : 'danger'}
						>
							{validProjections && hasRetailAgreements ? AlertText : invalidAlertText}
							<br />
							{this.renderWindowRange()}
						</Alert>
					)}
					{!isLoading && !this.userCanEdit() && (
						<Alert variant="info">
							The Projections role is required to enter projections for retail
							products. To add the role contact your Company Admin.
						</Alert>
					)}
					<Title title={TITLE} />
					{isLoading || updatingProjections ? (
						<Loading />
					) : (
						<FilterableTable
							key={'filterable-table'}
							data={projections}
							dataFormat={this.getTableStructure()}
							searchableFields={['product_name']}
							searchableFieldPlaceHolder={'Search by title...'}
							defaultSorted={[
								{
									id: 'product_name',
									desc: true,
								},
							]}
							componentsBelowTable={this.isEditable() && button}
						/>
					)}
				</div>
			</Page>
		);
	}
}

export default connectQueryData(
	connect(mapStateToProps)(OrderProjections), 
	useCompanyAgreementsQuery,
	'companyAgreementsQuery',
	true,
);
