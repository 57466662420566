import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useQuery } from 'react-query';

import BaseTable from '../../components/BaseTable/BaseTable';
import DateTimeCell from '../../components/cells/DateTimeCell/DateTimeCell';
import FAIcon from '../../components/FAIcon/FAIcon';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import { permConst } from '../../constants/permConst';
import { useUserProfile } from '../../hooks/reduxHooks';
import { getROMUploadRecords } from '../../services/fileUploadsService';
import { displayString } from '../../utils/stringUtils';
import { isAuthorized } from '../../utils/userUtils';
import ROMUploadModal from './modals/ROMUploadModal';


const UPLOAD_MODAL = 'UPLOAD_MODAL';

const StatusCell = ({ value }) => {
	const className =
		value === 'success' ? 'text-success' : value === 'failed' ? 'text-danger' : '';
	return <b className={className}>{displayString(value).toUpperCase()}</b>;
};

const CommentsCell = ({ original }) => (
	<>
		{original.user_comment ? original.user_comment : <>&mdash;</>}
		{original.system_comment ? (
			<>
				<br />[{original.system_comment}]
			</>
		) : null}
	</>
);


const ROMUploadManagement = () => {
	const userProfile = useUserProfile();
	const [openModal, setOpenModal] = useState();
	const uploadQuery = useQuery('getROMUploadRecords', () => getROMUploadRecords());

	const columns = [
		{ Header: 'Date Uploaded', accessor: 'creation_datetime', Cell: DateTimeCell },
		{ Header: 'File Name', accessor: 'filename' },
		{
			Header: 'Comments',
			id: 'comments',
			accessor: (row) => row.user_comment + '\n' + row.system_comment,
			Cell: CommentsCell,
		},
		{ Header: 'Status', accessor: 'status', Cell: StatusCell },
	];

	const canUploadROMFile = isAuthorized(userProfile.permissions, [permConst.ROM_VERSION_FILE.UPLOAD.ALL]);

	return (
		<Page isLoading={uploadQuery.isLoading}>
			<Title
				button={ canUploadROMFile &&
					<Button onClick={() => setOpenModal({ type: UPLOAD_MODAL })}>
						<FAIcon name="upload" className="mr-1" />
						Upload NCL ROM Versions File
					</Button>
				}
			>
				NCL ROM Versions Files
			</Title>
            <Page.FullPageCol>
                <BaseTable
                    data={uploadQuery.isSuccess && uploadQuery.data.data}
                    columns={columns}
                />
            </Page.FullPageCol>
            <ROMUploadModal
                show={openModal && openModal.type === UPLOAD_MODAL}
                onClose={() => setOpenModal({})}
                onUploaded={() => uploadQuery.refetch()}
            />
		</Page>
	);
};

export default ROMUploadManagement;
