import React from 'react';

import SimpleModal from '../../../components/modals/SimpleModal/SimpleModal';

export const OrderRequirementsModal = (props) => {
	return (
		<SimpleModal show={props.show} title="Retail Order Requirements" closeModal={props.closeModal}>
			Retail products must meet these requirements to be ordered:
			<br />
			<ul>
				<li>
					A retail Publisher Licensing Agreement for the platform must be completed or in
					negotiation.
				</li>
				<li>A production version of the ROM must be submitted for certification.</li>
				<li>The price must be entered by Nintendo of America.</li>
				<li>Final label files must be submitted.</li>
			</ul>
		</SimpleModal>
	);
};
