import api from './baseService';

export const getFeatureFlags = () =>
	api().get('/pdb/feature_flags');

export const putFeatureFlag = (featurePermissionID: string, payload: Record<string, any>) =>
	api().put(`/pdb/feature_flags/${featurePermissionID}`, payload);

export const getFeatureStatuses = () =>
	api().get('/pdb/feature_flags/statuses');

export const getFeaturesForCompany = (companyId: string) =>
	api().get(`/pdb/companies/${companyId}/feature_flags`);
