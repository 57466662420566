import React, { useMemo, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';

import FilterableTable from '../../../components/FilterableTable/FilterableTable';
import MeatballDropdown from '../../../components/MeatballDropdown/MeatballDropdown';
import Loading from '../../../components/Loading/Loading';
import { findEventStatus, getTaskPermissions, completeTask, claimTask, deleteTask } from '../../../utils/eventUtils';
import { getEventReportTasks } from '../../../services/eventsService';
import { permConst } from '../../../constants/permConst';
import { pullAllResults } from '../../../utils/serviceUtils';
import { dateRangeEvaluation } from '../../../utils/filterUtils';
import { isAuthorized } from '../../../utils/userUtils';
import { useUserProfile } from '../../../hooks/reduxHooks';
import ActiveCell from '../../../components/cells/ActiveCell/ActiveCell';
import DateCell from '../../../components/cells/DateCell/DateCell';
import EventTaskStatusCell from '../../../components/cells/EventTaskStatusCell/EventTaskStatusCell';
import VisibleCell from '../../../components/cells/VisibleCell/VisibleCell';
import { eventConstants } from '../../../constants/eventConstants';
import DeleteConfirmationModal from '../../../components/modals/DeleteConfirmationModal/DeleteConfirmationModal';
import EventTaskModal, { EDIT_TASK_MODAL, VIEW_TASK_MODAL } from '../../../components/modals/EventTaskModal/EventTaskModal';
import ActionConfirmationModal from '../../../components/modals/ActionConfirmationModal/ActionConfirmationModal';
import EventTaskAttachmentLinkCell from '../../../components/cells/EventTaskAttachmentLinkCell/EventTaskAttachmentLinkCell';
import { actionsColumn } from '../../../components/BaseTable/BaseTable';


const COMPLETE_MODAL = 'COMPLETE_MODAL';
const DELETE_MODAL = 'DELETE_MODAL';
const CLAIM_MODAL = 'CLAIM_MODAL';

const { INTERNAL } = eventConstants.TASKS.TYPE;


const getTableStructure = ({
	history,
	userProfile,
	showActiveStatus,
	canViewEventDetails,
	canViewVisibility,
	setOpenModal
}) => {
	const visibleAccessor = (row) =>
		row.externally_visible && row.assignment_type === 'E' && row.active && row.event_active;

	const ActionsCell = ({ original }) => {
		const {
			canEditInternalAssigneeOnTasks,
			canEditExternalAssigneeOnTasks,
			canClaimInternalAssignmentOnTasks,
			canClaimExternalAssignmentOnTasks,
			canEditTask,
			canDeleteTask,
			canEditTaskIfAssigned
		}  = getTaskPermissions(userProfile, original.affiliation.toUpperCase());
		const isAssigned = original.assigned_ndid_user_id === userProfile.userId;
		const allowClaim = (
			(canClaimInternalAssignmentOnTasks || canEditInternalAssigneeOnTasks) &&
			original.assignment_type === INTERNAL
		) || (
			(canClaimExternalAssignmentOnTasks || canEditExternalAssigneeOnTasks) &&
			original.assignment_type !== INTERNAL
		);
		return (
			<MeatballDropdown>
				<MeatballDropdown.Item
					onClick={() => {
						setOpenModal({
							type: VIEW_TASK_MODAL,
							taskId: original.marketing_event_task_id,
							currentAttachment: original,
						});
					}}
				>
					View Task
				</MeatballDropdown.Item>
				{allowClaim && (
					<MeatballDropdown.Item
						disabled={isAssigned}
						onClick={() => {
							setOpenModal({
								type: CLAIM_MODAL,
								taskId: original.marketing_event_task_id,
								name: original.name,
							});
						}}
					>
						Claim Task
					</MeatballDropdown.Item>
				)}
				{(canEditTask || (canEditTaskIfAssigned && isAssigned)) && (
					<>
						<MeatballDropdown.Divider />
						<MeatballDropdown.Item
							disabled={original.status === eventConstants.TASKS.STATUS.COMPLETED}
							onClick={() => {
								setOpenModal({
									type: COMPLETE_MODAL,
									taskId: original.marketing_event_task_id,
									name: original.name,
								});
							}}
						>
							Complete Task
						</MeatballDropdown.Item>
						<MeatballDropdown.Item
							onClick={() => {
								setOpenModal({
									type: EDIT_TASK_MODAL,
									taskId: original.marketing_event_task_id,
									currentAttachment: original,
									eventId: original.marketing_event_id
								});
							}}
						>
							Edit Task
						</MeatballDropdown.Item>
					</>
				)}
				{canDeleteTask && (
					<>
						<MeatballDropdown.Divider />
						<MeatballDropdown.Item
							onClick={() => {
								setOpenModal({
									type: DELETE_MODAL,
									taskId: original.marketing_event_task_id,
									name: original.name,
								});
							}}
						>
							<span className="text-danger">Delete Task</span>
						</MeatballDropdown.Item>
					</>
				)}
			</MeatballDropdown>
		);
	};

	const EventNameCell = ({ original, value }) =>
		canViewEventDetails ? (
			<Link to={`/marketing-events/${original.marketing_event_id}`}>{value || '—'}</Link>
		) : (
			value || '—'
		);

	const eventStatusCell = ({ original }) => {
		const value = findEventStatus({start_datetime: original.event_start_datetime, end_datetime: original.event_end_datetime});
		switch (value) {
			case eventConstants.STATUS.PLANNED:
				return (
					<strong className="text-info">
						{eventConstants.STATUS_NAMES[value].toUpperCase()}
					</strong>
				);
			case eventConstants.STATUS.LIVE:
				return (
					<strong className="text-success">
						{eventConstants.STATUS_NAMES[value].toUpperCase()}
					</strong>
				);
			case eventConstants.STATUS.COMPLETED:
				return (
					<strong className="text-muted">
						{eventConstants.STATUS_NAMES[value].toUpperCase()}
					</strong>
				);
			case eventConstants.STATUS.DRAFT:
				return <strong>{eventConstants.STATUS_NAMES[value].toUpperCase()}</strong>;
			default:
				return null;
		}
	};

	return [
		{
			Header: '',
			id: 'visible',
			accessor: visibleAccessor,
			Cell: VisibleCell,
			width: 36,
			show: !!canViewVisibility,
		},
		{ Header: 'Task', accessor: 'name' },
		{ Header: 'Attachment', accessor: 'attachment_file_name', Cell: EventTaskAttachmentLinkCell },
		{ Header: 'Due Date', accessor: 'due_date', Cell: DateCell },
		{ Header: 'Event Name', accessor: 'event_name', Cell: EventNameCell },
		{ Header: 'Event Status', accessor: 'eventStatus', Cell: eventStatusCell },
		{ Header: 'Task Status', accessor: 'status', Cell: EventTaskStatusCell },
		{
			Header: 'Active',
			accessor: 'active',
			Cell: ActiveCell,
			width: 64,
			show: showActiveStatus,
		},
		{
			...actionsColumn,
			Cell: ActionsCell,
		},
	];
};

const getFilterProperties = (showActiveFilter=false) => {
	const filterProperties = new Map();
	filterProperties.set('Due Date', {
		filter: 'due_date',
		selectableFilters: 'datetime_range',
		customEvaluation: dateRangeEvaluation
	});

	filterProperties.set('Task Status', {
		filter: 'status',
		selectableFilters: new Set(
			[
				'Open',
				'In Progress',
				'Completed',
			].map((status) => (status)),
		),
	});
	showActiveFilter && filterProperties.set('Active', {
		filter: 'active',
		selectableFilters: new Set(['Active', 'Inactive']),
		customEvaluation: (value, filters) => {
			if (filters.has('Inactive') && value === false) {
				return true;
			}
			if (filters.has('Active') && value === true) {
				return true;
			}
			return false;
		}
	});

	filterProperties.set('Has Attachment', {
		filter: 'attachment_file_name',
		selectableFilters: new Set(['Yes', 'No']),
		customEvaluation: (value, filters) => {
			if (filters.has('Yes') && !!value) {
				return true;
			}
			if (filters.has('No') && !value) {
				return true;
			}
			return false;
		}
	});

	const eventStatuses = showActiveFilter
		? Object.values(eventConstants.STATUS_NAMES)
		: Object.values(eventConstants.STATUS_NAMES).filter((status) => status !== 'Draft');
	filterProperties.set('Event Status', {
		filter: 'eventStatus',
		selectedFilters: new Set(eventStatuses.filter((status) => status !== 'Completed')),
		selectableFilters: new Set(eventStatuses),
		customEvaluation: (value, filters) => {
			return filters.has(eventConstants.STATUS_NAMES[value]);
		},
	});
	return filterProperties;
};

const TasksTab = ({show}) => {
	const userProfile = useUserProfile();
	const queryClient = useQueryClient();
	const [openModal, setOpenModal] = useState();
	const [isSubmitting, setIsSubmitting] = useState();

	const tasksQuery = useQuery('pullAllResults:getEventReportTasks', () => pullAllResults(getEventReportTasks, 500));
	const tasks = tasksQuery.isSuccess && tasksQuery.data.map((task) => ({...task,
		eventStatus: findEventStatus({start_datetime: task.event_start_datetime, end_datetime: task.event_end_datetime})}));
	const isLoading = tasksQuery.isLoading;

	const showActiveStatus = tasks && tasks.some((task) => 'active' in task);
	const canViewVisibility = tasks && tasks.some((task) => 'externally_visible' in task);
	const history = useHistory();

	const canViewEventDetails = isAuthorized(userProfile.permissions, [
		permConst.MARKETING_EVENT.DETAILS.VIEW
	]);

	const dataFormat = useMemo(
		() =>
			getTableStructure({
				history,
				userProfile,
				showActiveStatus,
				canViewEventDetails,
				canViewVisibility,
				setOpenModal
			}),
		[showActiveStatus, canViewVisibility],
	);

	const filterProperties = useMemo(() => {
		return getFilterProperties(showActiveStatus);
	}, [showActiveStatus]);

	return isLoading || !filterProperties ? (
		<Loading />
	) : (
		<>
			<FilterableTable
				dataFormat={dataFormat}
				data={tasks}
				filterProperties={filterProperties}
				searchableFields={['event_name', 'name']}
				searchableFieldPlaceHolder="Search by Event or Task"
				defaultSorted={[
					{
						id: 'active',
						desc: true,
					},
					{
						id: 'status',
						desc: false,
					},
					{
						id: 'due_date',
						desc: false,
					},
				]}
			/>
			<EventTaskModal
				mode={EDIT_TASK_MODAL}
				show={openModal && openModal.type === EDIT_TASK_MODAL}
				eventId={openModal && openModal.eventId}
				onClose={(e) => setOpenModal({ ...openModal, type: null })}
				taskId={openModal && openModal.taskId}
				currentAttachment={openModal && openModal.currentAttachment}
				onChange={(e) => {
					setOpenModal({ ...openModal, type: null });
					queryClient.invalidateQueries('pullAllResults:getEventReportTasks');
				}}
			/>
			<EventTaskModal
				mode={VIEW_TASK_MODAL}
				show={openModal && openModal.type === VIEW_TASK_MODAL}
				onClose={(e) => setOpenModal({ ...openModal, type: null })}
				taskId={openModal && openModal.taskId}
				currentAttachment={openModal && openModal.currentAttachment}
			/>
			<ActionConfirmationModal
				show={openModal && openModal.type === COMPLETE_MODAL}
				onCancel={() => setOpenModal(null)}
				onConfirm={() => {
					setIsSubmitting(true);
					completeTask(openModal.taskId,
						openModal.name,
						() => {
							setOpenModal(null);
							queryClient.invalidateQueries('pullAllResults:getEventReportTasks');
						},
						() => {
							setIsSubmitting(false);
						}
					);
				}}
				isSubmitting={isSubmitting}
				title="Complete Task"
				confirmLabel="Complete"
			>
				<Alert className="mb-0" variant="info">
					Do you want to mark this task "{openModal && openModal.name}" as Complete?
				</Alert>
			</ActionConfirmationModal>
			<ActionConfirmationModal
				show={openModal && openModal.type === CLAIM_MODAL}
				onCancel={() => setOpenModal(null)}
				onConfirm={() => {
					setIsSubmitting(true);
					claimTask(openModal.taskId,
						openModal.name,
						userProfile,
						() => {
							setOpenModal(null);
							queryClient.invalidateQueries('pullAllResults:getEventReportTasks');
						},
						() => {
							setIsSubmitting(false);
						}
					);
				}}
				isSubmitting={isSubmitting}
				title="Claim Task"
				confirmLabel="Claim"
			>
				<Alert className="mb-0" variant="info">
					Do you want claim this task "{openModal && openModal.name}" as your assignment?
				</Alert>
			</ActionConfirmationModal>
			<DeleteConfirmationModal
				show={openModal && openModal.type === DELETE_MODAL}
				closeModal={() => setOpenModal(null)}
				confirmDelete={() =>
					deleteTask(openModal.taskId,
						openModal.name,
						() => {
							setOpenModal(null);
							queryClient.invalidateQueries('pullAllResults:getEventReportTasks');
						},
						() => {
							setIsSubmitting(false);
						}
					)
				}
				isSubmitting={isSubmitting}
				title="Delete Task"
				confirmLabel="Delete"
				message={`Please confirm that you would like to delete this task "${openModal &&
				openModal.name}". If there are any attachments, they will be deleted.`}
			/>
		</>
	);
};
export default TasksTab;
