import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import * as yup from 'yup';

import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import { permConst } from '../../../constants/permConst';
import { useUserProfile } from '../../../hooks/reduxHooks';
import {
	postActivateDigitalCodes,
	postNotificationRequest,
} from '../../../services/digitalCodesService';
import { dateFormat, formatDate } from '../../../utils/dateUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { isAuthorized } from '../../../utils/userUtils';
import { validateToSchema } from '../../../utils/validationUtils';
import { isEmptyObject } from '../../../utils/dataUtils';


const schema = yup.object().shape({
	date: yup.string().nullable().required('Activation Date is required')
});

const ManageActivationModal = ({ show, order, onClose, product, isCodeInBox, onSubmit }) => {
	const userProfile = useUserProfile();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [formValues, setFormValues] = useState({});
	const [lastUpdated, setLastUpdated] = useState();
	const [showAllErrors, setShowAllErrors] = useState(false);


	const submitNotificationRequest = async () => {
		setIsSubmitting(true);
		try {
			await postNotificationRequest(order.orderID);
			toasterNotify('Reminder has been sent', 'success');
			onClose();
		} catch (error) {
			toasterNotify(createMessageForError(error, 'the notification request'), 'error', error);
		} finally {
			setIsSubmitting(false);
			setLastUpdated(null);
		}
	};
	const submitActivation = async () => {
		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}
		setIsSubmitting(true);
		try {
			const date = formValues.date;
			const callback = () => setLastUpdated(new Date());
			await postActivateDigitalCodes(order.orderID, date, callback);
			toasterNotify('Code activation date has been set', 'success');
			onClose();
		} catch (error) {
			toasterNotify(createMessageForError(error,'code activation'), 'error', error);
		} finally {
			setIsSubmitting(false);
			onSubmit && onSubmit();
			setLastUpdated(null);
		}
	};

	const romOk = order.rom_submission?.approved;
	const rating = product
		? product.age_ratings?.some(
				(rating) =>
					rating?.age_rating_agency === 'ESRB' &&
					rating?.age_rating_method === 'STANDARD',
		  )
			? 'Standard'
			: product.age_ratings?.some(
					(rating) =>
						rating?.age_rating_agency === 'ESRB' &&
						rating?.age_rating_method === 'IARC',
			  )
				? 'ESRB IARC'
				: product.age_ratings?.length === 0
					? 'No Rating'
					: null
		: 'Not Available';
	const ratingOk = !!order.age_rating_submitted;
	const insertOk = !isCodeInBox || order.product_insert?.reviewed;

	const activationIsNotAvailable = order.activation_date || !romOk || !ratingOk || !insertOk;
	const validationErrors = !activationIsNotAvailable ? validateToSchema(schema, formValues) : {};

	useEffect(() => {
		if (show) {
			setFormValues({date: order.activation_date});
			setLastUpdated(null);
			setIsSubmitting(null);
			setShowAllErrors(false);
		}
	}, [show]);


	return (
		<BaseModal show={show} isSubmitting={isSubmitting} onCancel={onClose}>
			<BaseModal.Title>Manage Activation Date</BaseModal.Title>

			<Alert variant="info">
				Note: Code-in-box orders requires an ESRB Standard rating. All other digital orders
				require at least an ESRB IARC rating.
			</Alert>
			<Forms 
				values={formValues} 
				onChange={(newValues) => setFormValues(newValues)}
				validationErrors={validationErrors}
				showAllErrors={showAllErrors}>
				<Forms.CustomArea>
					<Forms.Heading>Requested Delivery</Forms.Heading>
					{formatDate(order.reqDeliveryDate, dateFormat.DATE)}
				</Forms.CustomArea>
				<Forms.CustomArea>
					<Forms.Heading>Requested Activation</Forms.Heading>
					{formatDate(order.reqActivationDate, dateFormat.DATE)}
				</Forms.CustomArea>
				<Forms.CustomArea>
					<Forms.Heading>Codes Uploaded</Forms.Heading>
					{formatDate(order.codes_upload_date, dateFormat.DATE) || <>&mdash;</>}
				</Forms.CustomArea>
				<Forms.CustomArea>
					<Forms.Heading>ROM</Forms.Heading>
					{romOk ? (
						<span className="text-success">Approved</span>
					) : (
						<span className="text-danger">Not Approved</span>
					)}
				</Forms.CustomArea>
				<Forms.CustomArea>
					<Forms.Heading>Rating</Forms.Heading>
					{ratingOk ? (
						<span className="text-success">{rating}</span>
					) : (
						<span className="text-danger">{rating}</span>
					)}
				</Forms.CustomArea>
				<Forms.CustomArea>
					<Forms.Heading>Product Insert</Forms.Heading>
					{order.product_insert?.reviewed ? (
						<span className={isCodeInBox ? 'text-success' : null}>Yes</span>
					) : (
						<span className={isCodeInBox ? 'text-danger' : null}>No</span>
					)}
				</Forms.CustomArea>

				<Forms.DateSelect
                    id="date"
                    disabled={activationIsNotAvailable}
                >
					<Forms.Heading>Activation Date</Forms.Heading>
				</Forms.DateSelect>
			</Forms>
            { lastUpdated &&
                <BaseModal.SubmissionStatus lastUpdated={lastUpdated}>
                    Please wait
                </BaseModal.SubmissionStatus>
            }
			{ !order.activation_date && isAuthorized(userProfile.permissions, [
				permConst.DIGITAL_ORDER.CODES.SEND_REMINDER.ALL,
			]) && (
				<BaseModal.Submit
					variant="outline-secondary"
					onClick={() => submitNotificationRequest()}
					disabled={!activationIsNotAvailable}
				>
					Send Reminder
				</BaseModal.Submit>
			)}
			{ !order.activation_date && isAuthorized(userProfile.permissions, [
				permConst.DIGITAL_ORDER.CODES.ACTIVATE.ALL,
			]) && (
				<BaseModal.Submit
					onClick={() => submitActivation()}
					disabled={activationIsNotAvailable}
				>
					Apply
				</BaseModal.Submit>
			)}
		</BaseModal>
	);
};

export default ManageActivationModal;
