import React, { Component } from 'react';
import { Button, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import CommentBox from '../../components/Comment/CommentBox';
import Loading from '../../components/Loading/Loading';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import { permConst } from '../../constants/permConst';
import { platformCodes } from '../../constants/platformConstants';
import { subConst } from '../../constants/submissionsConstants';
import { getProduct } from '../../services/productsService';
import {
	getSubmissionActivityLog,
	getSubmissionAssets,
	getSubmissionDetails,
	getSubmissionTestResult,
	postSubmissionComment,
} from '../../services/submissionsService';
import { getSubmissionFullVersion } from '../../utils/submissionVersionUtils';
import { isFeatureSunsetted } from '../../utils/sunsetFeaturesUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { isAuthorized } from '../../utils/userUtils';
import AddSubmissionFilesModal from './modals/AddSubmissionFilesModal';
import EditGameConfigurationModal from './modals/EditGameConfigurationModal/EditGameConfigurationModal';
import EditSubmissionModal from './modals/EditSubmissionModal';
import LotcheckApprovalModal from './modals/LotcheckApprovalModal';
import ROMApprovalModal from './modals/ROMApprovalModal';
import RemoveSubmissionFilesModal from './modals/RemoveSubmissionFilesModal/RemoveSubmissionFilesModal';
import ResetSubmissionStatusModal from './modals/ResetSubmissionStatusModal';
import ActivityLog from './utilComponents/ActivityLog';
import SubmissionSidebar from './utilComponents/SubmissionSidebar';

function mapStateToProps(state) {
	return {
		userProfile: state.authReducer.userProfile,
		sunsetFeatures: state.referenceReducer?.sunsetFeatures?.content,
	};
}

function parseFeature(features, inputKey) {
	if (features) {
		for (let feature of features) {
			if (feature.name === inputKey) {
				let returnValue = feature.values[0].value;
				if (typeof feature.values[0].value !== 'boolean') {
					returnValue =
						feature.values[0].value === 'YES' ||
						feature.values[0].value === 'Y';
				}
				return returnValue;
			}
		}
	}
}

export class SubmissionDetails extends Component {
	constructor(props) {
		super(props);
		this.productId = props.match.params.productId;
		this.submissionId = props.match.params.submissionId;

		this.state = {
			showModal: false,
			showAlertHeader: false,
			productInfo: null,
			submissionDetails: null,
			activityLog: null,
			productId: '',
			titleStatus: [],
			gameConfiguration: '',
			assets: null,
			testResult: null,
		};
		this.toggleModal = this.toggleModal.bind(this);
		this.loadSubmissionTestResult = this.loadSubmissionTestResult.bind(this);
		this.loadActivityLog = this.loadActivityLog.bind(this);
		this.postComment = this.postComment.bind(this);
		this.setProductSummary = this.setProductSummary.bind(this);
		this.setProductSubmissions = this.setProductSubmissions.bind(this);
		this.setSubmissionFiles = this.setSubmissionFiles.bind(this);
	}

	generateSubData(data) {
		let submission = data;
		if (submission) {
			submission['online_mp'] = parseFeature(
				submission.submission_features.features,
				'Online Multi Play',
			);
			submission['online_commerce'] = parseFeature(
				submission.submission_features.features,
				'Online Commerce',
			);
			submission['consumable_aoc'] = parseFeature(
				submission.submission_features.features,
				'Consumable AOC',
			);
			submission['ugc'] = parseFeature(submission.submission_features.features, 'UGC');
			submission['wifi'] = parseFeature(
				submission.submission_features.features,
				'Wifi Connect',
			);
			this.setState({
				submissionDetails: submission,
			});
		}
	}

	setProductSummary() {
		getProduct(this.productId)
			.then((response) => {
				this.setState({
					productInfo: response.data,
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving submissions'),
					'error',
					error,
				);
				this.setState({
					productInfo: {},
				});
			});
	}

	setProductSubmissions() {
		getSubmissionDetails(this.submissionId)
			.then((response) => {
				this.generateSubData(response.data);
			})
			.catch((error) => {
				this.setState({
					submissionDetails: {},
				});
				toasterNotify(createMessageForError(error, 'loading submissions'), 'error', error);
			});
	}

	loadActivityLog() {
		getSubmissionActivityLog(this.submissionId)
			.then((response) => {
				this.setState({
					activityLog: response.data,
				});
			})
			.catch((error) => {
				this.setState({
					activityLog: [],
				});
				toasterNotify(createMessageForError(error, 'loading activity log'), 'error', error);
			});
	}

	postComment(commentBox) {
		postSubmissionComment(this.submissionId, commentBox.getCommentText())
			.then((response) => {
				commentBox.toggleCommentBox();
				this.loadActivityLog();
			})
			.catch((error) => {
				toasterNotify(createMessageForError(error, 'submitting comment'), 'error', error);
			});
	}

	setSubmissionFiles() {
		getSubmissionAssets(this.submissionId)
			.then((response) => {
				this.setState({
					assets: response.data,
				});
			})
			.catch((error) => {
				this.setState({
					assets: [],
				});
				toasterNotify(createMessageForError(error, 'loading assets'), 'error', error);
			});
	}

	loadSubmissionTestResult() {
		getSubmissionTestResult(this.submissionId)
			.then((response) => {
				this.setState({
					testResult: response.data,
				});
			})
			.catch((error) => {
				toasterNotify(createMessageForError(error, 'loading test results'), 'error', error);
			})
			.finally(() => {
				this.setState({
					testResult: '',
				});
			});
	}

	getPageTitle(productInfo) {
		if (!productInfo) {
			return '';
		}

		const version = this.getFullSubmissionVersion();
		return `${productInfo.game_name}${version && ` [${version}]`}`;
	}

	getFullSubmissionVersion() {
		const { submissionDetails } = this.state;

		return getSubmissionFullVersion(
			submissionDetails.product_release_version,
			submissionDetails.product_submission_version,
		);
	}

	componentDidMount() {
		this.setProductSubmissions();
		this.setProductSummary();
		this.loadSubmissionTestResult();
		this.loadActivityLog();
		this.setSubmissionFiles();
	}

	toggleModal(e) {
		let type;
		if (this.state.showModal === true) {
			this.setProductSubmissions();
			this.setProductSummary();
			this.setSubmissionFiles();
			type = null;
		} else {
			type = e.target.id;
		}
		this.setState({
			showModal: !this.state.showModal,
			modalType: type,
		});
	}

	setTitleStatusState(status) {
		const titleStatus = {};
		titleStatus['class'] = this.getActivityStatusClassName(status);
		titleStatus['text'] = status;

		this.setState({
			titleStatus: titleStatus,
		});
	}

	getActivityStatusClassName(activityStatus) {
		const status = activityStatus.toUpperCase();
		if (status.includes(subConst.STATUS.APPROVED)) {
			return 'status-approved';
		}

		if (status.includes(subConst.STATUS.FAILED)) {
			return 'status-rejected';
		}

		return 'status-pending';
	}

	renderModal() {
		const { userProfile } = this.props;

		const { productInfo, submissionDetails, testResult, assets } = this.state;

		const isWUP = productInfo.system_family === platformCodes['Wii U'];

		switch (this.state.modalType) {
			case 'lotcheckApprovalModal':
				return (
					<LotcheckApprovalModal
						userProfile={userProfile}
						closeModal={this.toggleModal}
						systemFamily={productInfo.system_family}
						productSubmissionId={this.submissionId}
						submissionStatus={submissionDetails.status}
						submissionType={submissionDetails.type}
						submissionFiles={assets}
						loadSubmissionTestResult={this.loadSubmissionTestResult}
						loadActivityLog={this.loadActivityLog}
						testResult={testResult}
					/>
				);
			case 'removeSubmissionFilesModal':
				return (
					<RemoveSubmissionFilesModal
						submissionFiles={this.state.assets}
						userProfile={userProfile}
						productSubmissionId={this.submissionId}
						closeModal={this.toggleModal}
						loadActivityLog={this.loadActivityLog}
						setSubmissionFiles={this.setSubmissionFiles}
					/>
				);
			case 'editGameConfiguration':
				return (
					<EditGameConfigurationModal
						userProfile={userProfile}
						closeModal={this.toggleModal}
						productInfo={productInfo}
						productSubmissionId={this.submissionId}
						submissionDetails={submissionDetails}
						gameConfiguration={this.state.gameConfiguration}
						reloadActivity={this.loadActivityLog}
					/>
				);
			case 'romApprovalModal':
				return (
					<ROMApprovalModal
						userProfile={userProfile}
						closeModal={this.toggleModal}
						productSubmissionId={this.submissionId}
						pageRefresh={this.loadActivityLog}
					/>
				);
			case 'editSubmissionModal':
				return (
					<EditSubmissionModal
						userProfile={userProfile}
						closeModal={this.toggleModal}
						reloadActivity={this.loadActivityLog}
						reloadDetails={this.setProductSubmissions}
						submission={this.state.submissionDetails}
						isWUP={isWUP}
					/>
				);
			case 'resetSubmissionStatusModal':
				return (
					<ResetSubmissionStatusModal
						userProfile={userProfile}
						closeModal={this.toggleModal}
						submissionId={this.submissionId}
						loadSubmissionTestResult={this.loadSubmissionTestResult}
						loadActivityLog={this.loadActivityLog}
					/>
				);
			case 'addSubmissionFilesModal':
				return (
					<AddSubmissionFilesModal
						userProfile={userProfile}
						productId={this.productId}
						closeModal={this.toggleModal}
						productSubmissionId={this.submissionId}
						loadActivityLog={this.loadActivityLog}
					/>
				);
			default:
				console.warn('[SubmissionDetails] Invalid Modal Type'); // eslint-disable-line no-console
		}
	}

	renderTitleButton() {
		const { submissionDetails, productInfo } = this.state;
		const { userProfile, sunsetFeatures } = this.props;

		const canEditGameConfiguration = isAuthorized(
			userProfile.permissions,
			[permConst.SUBMISSION.EDIT.ALL]
		);

		const canApproveLotchecks =
			submissionDetails.status &&
			submissionDetails.status.toUpperCase() ===
				subConst.STATUS.TESTING &&
			isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.LOTCHECK.APPROVAL
			]);

		const canResetLotchecks =
			submissionDetails.status &&
			submissionDetails.status.toUpperCase() !== subConst.STATUS.TESTING &&
			isAuthorized(userProfile.permissions, [permConst.PRODUCT.LOTCHECK.RESET]);

		const canApproveRoms =
			submissionDetails.status &&
			submissionDetails.status === subConst.STATUS.NOA_APPROVED &&
			isAuthorized(userProfile.permissions, [permConst.SUBMISSION.ROM.APPROVAL]);

		const isSwitchProduct = productInfo.system_family === platformCodes['Nintendo Switch'];

		return (
			<>
				{!isSwitchProduct &&
					canEditGameConfiguration &&
					!isFeatureSunsetted(sunsetFeatures, 'lotcheck_features') && (
						<Button
							type="button"
							variant="primary"
							id="editGameConfiguration"
							onClick={(e) => this.toggleModal(e)}
						>
							Edit Game Configuration
						</Button>
				)}
				{!isSwitchProduct &&
					canApproveLotchecks &&
					!isFeatureSunsetted(sunsetFeatures, 'lotcheck_features') && (
						<Button
							type="button"
							variant="success"
							id="lotcheckApprovalModal"
							onClick={(e) => this.toggleModal(e)}
						>
							Lotcheck Approval
						</Button>
				)}
				{!isSwitchProduct && canApproveRoms && !isFeatureSunsetted(sunsetFeatures, 'lotcheck_features') && (
					<Button
						type="button"
						variant="success"
						id="romApprovalModal"
						onClick={(e) => this.toggleModal(e)}
					>
						ROM Approval
					</Button>
				)}
				{!isSwitchProduct &&
					isAuthorized(userProfile.permissions, [permConst.SUBMISSION.EDIT.ALL]) && (
						<MeatballDropdown id="submission-context-menu" toggleSize="lg">
							{canResetLotchecks && !isFeatureSunsetted(sunsetFeatures, 'lotcheck_features') && (
								<MeatballDropdown.Item
									id="resetSubmissionStatusModal"
									eventKey="resetSubmissionStatusModal"
									onClick={(e) => this.toggleModal(e)}
								>
									Reset Submission Status
								</MeatballDropdown.Item>
							)}
							{!isFeatureSunsetted(sunsetFeatures, 'lotcheck_features') && (
								<MeatballDropdown.Item
									id="editSubmissionModal"
									eventKey="editSubmissionModal"
									onClick={(e) => this.toggleModal(e)}
								>
									Edit Submission
								</MeatballDropdown.Item>
							)}
							<MeatballDropdown.Divider />
							{!isFeatureSunsetted(sunsetFeatures, 'lotcheck_features') && (
								<MeatballDropdown.Item
									id="addSubmissionFilesModal"
									eventKey="addSubmissionFilesModal"
									onClick={(e) => this.toggleModal(e)}
								>
									Add Submission File(s)
								</MeatballDropdown.Item>
							)}
							{!isFeatureSunsetted(sunsetFeatures, 'lotcheck_features') && (
								<MeatballDropdown.Item
									id="removeSubmissionFilesModal"
									eventKey="removeSubmissionFilesModal"
									onClick={(e) => this.toggleModal(e)}
								>
									Remove Submission File(s)
								</MeatballDropdown.Item>
							)}
							<MeatballDropdown.Divider />
							<MeatballDropdown.Item href={`/products/${this.productId}`}>
								View Product Details
							</MeatballDropdown.Item>
						</MeatballDropdown>
				)}
			</>
		);
	}

	renderSideBar() {
		const { productInfo, submissionDetails, assets } = this.state;
		const { userProfile } = this.props;
		const isWUP = productInfo.system_family === platformCodes['Wii U'];

		return (
			<SubmissionSidebar
				submission={submissionDetails}
				submissionFiles={assets}
				productId={this.productId}
				submissionVersion={this.getFullSubmissionVersion()}
				productInfo={productInfo}
				userProfile={userProfile}
				isWUP={isWUP}
			/>
		);
	}

	decideTitleStatusClass(status) {
		let statusClass;
		switch (status) {
			case subConst.TYPE.LOTCHECK:
				statusClass = 'status-approved';
				break;
			case subConst.TYPE.PRECHECK:
				statusClass = 'status-info';
				break;
			case subConst.TYPE.FAILED:
				statusClass = 'status-danger';
				break;
			default:
				statusClass = 'status-info';
				break;
		}
		return statusClass;
	}
	render() {
		const { productInfo, showAlertHeader, submissionDetails, activityLog, assets, testResult } =
			this.state;
		const { userProfile, sunsetFeatures } = this.props;
		const isLoading =
			productInfo == null ||
			submissionDetails == null ||
			activityLog == null ||
			assets == null ||
			testResult == null;

		const title = !isLoading && this.getPageTitle(productInfo);
		const titleStatus = {
			text: submissionDetails ? submissionDetails.status : '',
			class: submissionDetails ? this.decideTitleStatusClass(submissionDetails.status) : '',
		};

		if (
			!isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.VIEW.ALL.FIRST,
				permConst.PRODUCT.VIEW.ALL.THIRD,
				permConst.PRODUCT.VIEW.COMPANY,
			])
		) {
			return <Redirect to="/" />;
		}
		return (
			<Page>
				{isLoading ? (
					<Loading />
				) : (
					<>
						{showAlertHeader && this.renderAlertHeader()}
						<Breadcrumb>
							<Breadcrumb.Item to="/products">Products</Breadcrumb.Item>
							<Breadcrumb.Item to={`/products/${this.productId}`}>
								{productInfo &&
									`${productInfo.game_name}${
										productInfo.game_code ? ` (${productInfo.game_code})` : ''
									}`}
							</Breadcrumb.Item>
							<Breadcrumb.Item active>{title}</Breadcrumb.Item>
						</Breadcrumb>
						<Title
							title={title}
							subtitle={productInfo.platform_name}
							status={titleStatus}
							button={this.renderTitleButton()}
						/>
						<Row className="mt-3">
							<Page.MainCol>
								<Page.ContentContainer>
									<ActivityLog
										productId={this.productId}
										submissionId={this.submissionId}
										loadActivityLog={this.loadActivityLog}
										activityLog={this.state.activityLog}
									/>
									{isAuthorized(userProfile.permissions, [
										permConst.SUBMISSION.COMMENT.ADD.ALL,
									]) && !isFeatureSunsetted(sunsetFeatures, 'lotcheck_features') && (
										<CommentBox
											commentAreaId="submissionCommentArea"
											postComment={this.postComment}
										/>
									)}
								</Page.ContentContainer>
							</Page.MainCol>
							<Page.SidebarCol>{this.renderSideBar()}</Page.SidebarCol>
						</Row>
						{this.state.showModal && this.state.modalType ? this.renderModal() : null}
					</>
				)}
			</Page>
		);
	}
}

export default connect(mapStateToProps)(SubmissionDetails);
