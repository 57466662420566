import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';

import BaseModal from '../../../components/BaseModal/BaseModal';
import { setSubmissionStatus } from '../../../services/submissionsService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';

class ResetSubmissionStatusModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			deletedItems: [],
			isLoading: false
		};
		this.toggleLoading = this.toggleLoading.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	resetSubmissionStatus() {
		this.toggleLoading();
		setSubmissionStatus(
			this.props.submissionId,
			'TESTING'
		)
			.then((response) => {
				toasterNotify('Submission Reset', 'success');
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'resetting submission'),
					'error',
					error
				);
			})
			.finally(() => {
				this.props.closeModal();
				this.props.loadSubmissionTestResult();
				this.props.loadActivityLog();
			});
	}

	render() {
		return (
			<BaseModal
				show={true}
				onCancel={this.props.closeModal}
				isSubmitting={this.state.isLoading}
			>
				<BaseModal.Title>Reset submission status</BaseModal.Title>
				<Alert variant="warning">
					Do you want to reset the submission status to "Testing"?
				</Alert>
				<BaseModal.Submit
					onClick={() => {
						this.resetSubmissionStatus();
					}}
				>
					Yes
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}

export default ResetSubmissionStatusModal;
