import React, { useState } from 'react';
import { Dropdown, DropdownButton, Tab } from 'react-bootstrap';

import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import AssetsTab from './tabs/AssetsTab';
import ProductsTab from './tabs/ProductsTab';
import TasksTab from './tabs/TasksTab';

const title = 'Marketing Event Reports';

const TASKS_VIEW = 'Tasks View';
const ASSETS_VIEW = 'Assets View';
const PRODUCTS_VIEW = 'Products View';

const EventReports = () => {
	const [selectedView, setSelectedView] = useState(ASSETS_VIEW);

	const dropdown = (
		<DropdownButton variant="outline-primary" id="dropdown-view-select" title={selectedView}>
			<Dropdown.Item onSelect={() => setSelectedView(ASSETS_VIEW)}>{ASSETS_VIEW}</Dropdown.Item>
			<Dropdown.Item onSelect={() => setSelectedView(PRODUCTS_VIEW)}>{PRODUCTS_VIEW}</Dropdown.Item>
			<Dropdown.Item onSelect={() => setSelectedView(TASKS_VIEW)}>{TASKS_VIEW}</Dropdown.Item>
		</DropdownButton>
	);
	return (
		<Page>
			<div className="EventManagement">
				<Title title={title} button={dropdown} />
				<div className="Page__fill-space" >
				<Tab.Container id="event-reports-tab-container" activeKey={selectedView} onSelect={(eventKey) => 0} transition={false}>
					<Tab.Content>
						<Tab.Pane eventKey={TASKS_VIEW}>
							<TasksTab show={selectedView === TASKS_VIEW}/>
						</Tab.Pane>
						<Tab.Pane eventKey={ASSETS_VIEW}>
							<AssetsTab show={selectedView === ASSETS_VIEW}/>
						</Tab.Pane>
						<Tab.Pane eventKey={PRODUCTS_VIEW}>
							<ProductsTab show={selectedView === PRODUCTS_VIEW}/>
						</Tab.Pane>
					</Tab.Content>
				</Tab.Container>
				</div>
			</div>
		</Page>
	);
};

export default EventReports;
