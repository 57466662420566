import React, { useState, useEffect } from 'react';
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import { useQuery } from 'react-query';
import * as yup from 'yup';
import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import { validateToSchema } from '../../../utils/validationUtils';
import { isEmptyObject, safeEval } from '../../../utils/dataUtils';
import { cancelToast, toasterNotify } from '../../../utils/toaster';
import { getComponentsTitlesForCommonCode, getComponentsTitlesForNsUid, putComponentParent } from '../../../services/digitalCodesService';


const ENTER_OTHER_NSUID = '0';

const schema = yup.object().shape({
	selectedNsUid: yup.string().nullable().required('Game Code must be selected'),
	enteredNsUid: yup.string().nullable(),
});

const SetParentModal = ({orphanComponent, closeModal, onSubmitted, show}) => {
	const commonCode = orphanComponent && orphanComponent.game_code.substring(4,8);
	const commonQuery = useQuery(['getComponentTitlesForCommonCode', commonCode], () =>
		getComponentsTitlesForCommonCode(commonCode), { enabled: !!(show) });
	const isLoading = commonQuery.isLoading;
	const fault = commonQuery.isError && commonQuery.error;
	const commonCodes = commonQuery.isSuccess && commonQuery.data.data.filter((x) => x.ns_uid !== orphanComponent.ns_uid);

	const [formValues, setFormValues] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showAllErrors, setShowAllErrors] = useState(false);
	const [verifiedNsUid, setVerifiedNsUid] = useState(null);
	const [enteredNsUidErrorMsg, setEnteredNsUidErrorMsg] = useState(null);

	useEffect(() => {
		if (show && !isLoading) {
			setFormValues({});
			setEnteredNsUidErrorMsg(null);
			setVerifiedNsUid(null);
			setShowAllErrors(false);
			setIsSubmitting(false);
		}
	}, [show, isLoading]);

	const changedFormValue = (values, formId) => {
		setFormValues(values);

		if (formId === 'enteredNsUid') {
			setVerifiedNsUid(null);
		}

		if (formId === 'selectedNsUid') {
			if (values.selectedNsUid === ENTER_OTHER_NSUID) {
				setVerifiedNsUid(null);
			}
		}
	};

	const selectedCommonCode = commonCodes && formValues.selectedNsUid
		? commonCodes.find((x) => x.ns_uid === formValues.selectedNsUid)
		: null;

	const verifyEnteredNsUid = () => {
		if (isSubmitting) {
			return;
		}

		if (!formValues.enteredNsUid) {
			setEnteredNsUidErrorMsg('Enter NSUID to verify');
			return;
		}

		if (formValues.enteredNsUid && formValues.enteredNsUid === orphanComponent.ns_uid) {
			setEnteredNsUidErrorMsg('NSUID can not be the same as parent');
			setVerifiedNsUid(null);
			return;
		}

		setIsSubmitting(true);
		setVerifiedNsUid(null);
		setEnteredNsUidErrorMsg(null);

		getComponentsTitlesForNsUid(formValues.enteredNsUid)
			.then((response) => {
				setVerifiedNsUid(response.data);
				setEnteredNsUidErrorMsg(null);
			})
			.catch((err) => {
				if (safeEval(() => err.response.status === 400 || err.response.status === 404)) {
					cancelToast(err);
					if (safeEval(() => err.response.data.message.error.message)) {
						setEnteredNsUidErrorMsg(err.response.data.message.error.message);
					} else {
						setEnteredNsUidErrorMsg('Error verifying NSUID');
					}
				} else {
					setEnteredNsUidErrorMsg('Error verifying NSUID');
				}
				setVerifiedNsUid(null);
			})
			.finally(() => {
				setIsSubmitting(false);
			});
	};

	const validationErrors = validateToSchema(schema, formValues);

	const submitForm = () => {
		if (isSubmitting) {
			return;
		}

		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}

		if (formValues.selectedNsUid === ENTER_OTHER_NSUID && !verifiedNsUid) {
			if (!enteredNsUidErrorMsg) {
				setEnteredNsUidErrorMsg('NSUID is required');
			}
			return;
		}

		setIsSubmitting(true);

		const payload = {
			parent_ns_uid:
				formValues.selectedNsUid === ENTER_OTHER_NSUID
					? formValues.enteredNsUid
					: formValues.selectedNsUid,
		};

		putComponentParent(orphanComponent.ns_uid, payload)
			.then(() => {
				onSubmitted();
				closeModal();
				toasterNotify(
					`Parent set for [${orphanComponent.game_code.substring(4,9)}] ${orphanComponent.game_name}`,
					'success',
				);
			})
			.catch((err) => {
				toasterNotify(
					'There was an unexpected error while setting the parent.',
					'error',
					err,
				);
			})
			.finally(() => {
				setIsSubmitting(false);
			});
	};

	return (
		<BaseModal
			show={show}
			onCancel={closeModal}
			isLoading={isLoading}
			isSubmitting={isSubmitting}
			fault={fault}
		>
			<BaseModal.Title>
				Set Parent Title Component
			</BaseModal.Title>
			<Forms
				onChange={(id, v) => {changedFormValue(id, v); setEnteredNsUidErrorMsg(null);}}
				values={formValues}
				validationErrors={validationErrors}
				showAllErrors={showAllErrors}
			>
				{orphanComponent && (
					<h4>[{orphanComponent.game_code.substring(4,9)}] {orphanComponent.game_name}</h4>
				)}
				<Forms.Select
					id="selectedNsUid"
					placeholder="Select a Game Code"
				>
					<Forms.Heading>Game Code</Forms.Heading>
					{commonCodes &&
					commonCodes.map((commonCode) => (
						<Forms.Option
							value={commonCode.ns_uid}
							key={commonCode.ns_uid}
						>
							{commonCode.game_code}
						</Forms.Option>
					))}
					<Forms.Option value={ENTER_OTHER_NSUID}>Other (Enter NSUID)</Forms.Option>
				</Forms.Select>
				{formValues.selectedNsUid !== ENTER_OTHER_NSUID && (
					<>
						<Forms.CustomArea>
							<Forms.Heading>NSUID</Forms.Heading>
							<label>{formValues.selectedNsUid || <>&mdash;</>}</label>
						</Forms.CustomArea>
						<Forms.CustomArea>
							<Forms.Heading>Parent Product</Forms.Heading>
							{selectedCommonCode ? <label>[{selectedCommonCode.game_code.substring(4, 9)}] {selectedCommonCode.game_name}</label> : <>&mdash;</>}
						</Forms.CustomArea>
					</>
				)}
				{formValues.selectedNsUid === ENTER_OTHER_NSUID && (
					<>
						<Forms.CustomArea>
							<Forms.Heading addPadding>NSUID</Forms.Heading>
							<InputGroup className="mb-2">
								<FormControl
									type="text"
									placeholder="Enter NSUID"
									onChange={(e) => {
										changedFormValue({
											...formValues,
											enteredNsUid: e.currentTarget.value
										}, 'enteredNsUid');
									}}
									autoComplete="off"
									value={formValues.enteredNsUid || ''}
								/>
								{!verifiedNsUid && (
									<InputGroup.Append>
										<Button
											variant="outline-secondary"
											onClick={(e) => verifyEnteredNsUid()}
											disabled={isSubmitting}
										>
											Verify
										</Button>
									</InputGroup.Append>
								)}
							</InputGroup>
							{enteredNsUidErrorMsg && (
								<Forms.Help>
									<span className="text-danger">
										{enteredNsUidErrorMsg}
									</span>
								</Forms.Help>
							)}
						</Forms.CustomArea>
						<Forms.CustomArea>
							<Forms.Heading>Parent Product</Forms.Heading>
							{!enteredNsUidErrorMsg && verifiedNsUid && (
								<label>[{verifiedNsUid.game_code.substring(4, 9)}] {verifiedNsUid.game_name}</label>
							)}
						</Forms.CustomArea>
					</>
				)}
			</Forms>
			<BaseModal.Submit onClick={() => submitForm()}>Save</BaseModal.Submit>
		</BaseModal>
	);
};

export default SetParentModal;
