import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';

import BaseModal from '../../../../components/BaseModal/BaseModal';
import { transferRightsConstants } from '../../../../constants/transferRightsConstants';
import { postProductRightsTransferRequests } from '../../../../services/productTransfersService';
import { toasterNotify, createMessageForError } from '../../../../utils/toaster';


class RescindOwnershipTransferModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			display: true
		};
		this.toggleLoading = this.toggleLoading.bind(this);
		this.postProductRightsTransfer = this.postProductRightsTransfer.bind(
			this
		);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	postProductRightsTransfer() {
		const { transferId } = this.props;
		this.toggleLoading();

		let data = {
			transfer_status: transferRightsConstants.REQUEST_STATUS.RESCINDED
		};
		postProductRightsTransferRequests(transferId, data)
			.then((response) => {
				toasterNotify('Product rights transfer rescinded.', 'success');
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'rescinding a product rights transfer', error),
					'error',
					error
				);
			})
			.finally(() => {
				this.props.toggleModal(null, null, true);
			});
	}

	render() {
		return (
			<BaseModal
				show={true}
				onCancel={this.props.toggleModal}
				isSubmitting={this.state.isLoading}
			>
				<BaseModal.Title>Rescind Request</BaseModal.Title>
				<Alert variant="danger" className="mb-0">
					Please confirm that you want to rescind your request to transfer ownership
					rights to another publisher. Doing so will result in the current code attached
					to this request to be invalid.
				</Alert>
				<BaseModal.Submit
					variant='danger'
					onClick={this.postProductRightsTransfer}
				>
					Confirm
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}

export default RescindOwnershipTransferModal;
