import React, { VFC } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import DigitalCodesDetailNew from './DigitalCodesDetail__NEW';
import DigitalCodesDetailOld from './DigitalCodesDetail__OLD';
import { getDigitalOrder } from '../../services/digitalCodesService';
import Page from '../../components/Page/Page';

const DigitalCodesDetail: VFC = () => {
	const { orderId } = useParams<{ orderId?: string }>();
	const orderQuery = useQuery(['getDigitalOrder', orderId], () =>
		getDigitalOrder(String(orderId)),
	);
	
	return orderQuery.isLoading || orderQuery.isError ? (
		<Page isLoading={orderQuery.isLoading} fault={orderQuery.error} />
	) : orderQuery.data?.data.body.sales_flow_version === 2 ? (
		<DigitalCodesDetailNew />
	) : (
		<DigitalCodesDetailOld />
	);
};

export default DigitalCodesDetail;
