import React, { VFC, useContext, useMemo, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useQuery } from 'react-query';

import { CellInfo } from 'react-table-v6';
import { actionsColumn } from '../../../components/BaseTable/BaseTable';
import FileLink from '../../../components/FileLink/FileLink';
import FilterableTable from '../../../components/FilterableTable/FilterableTable';
import Loading from '../../../components/Loading/Loading';
import MeatballDropdown from '../../../components/MeatballDropdown/MeatballDropdown';
import Page from '../../../components/Page/Page';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { TabState } from '../../../components/StatefulTabs/StatefulTabs';
import StatusText from '../../../components/StatusText/StatusText';
import ActionConfirmationModal from '../../../components/modals/ActionConfirmationModal/ActionConfirmationModal';
import { assetConst } from '../../../constants/assetConstants';
import { permConst } from '../../../constants/permConst';
import { useUserProfile } from '../../../hooks/reduxHooks';
import { NCLLabelQueueData, getNCLLabelQueue, postAssetStatus } from '../../../services/productsService';
import { dateFormat, formatDate, parseDateString } from '../../../utils/dateUtils';
import { displayString } from '../../../utils/stringUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { isAuthorized } from '../../../utils/userUtils';
import RequestNewLabelModal from './RequestNewLabelModal';
import SubmitForApprovalModal from './SubmitForApprovalModal';
import NCLAcceptModal from './NCLAcceptModal';
import { timeZoneConstants } from '../../../constants/timeZoneConstants';


enum ModalTypes {
	NCL_ACCEPT,
	SUBMIT_FOR_APPROVAL,
	REQUEST_NEW_LABEL,
	REQUEST_FORGO,
}

type OpenModalModel = {
	type: ModalTypes | null;
	assetId?: number;
	isSubmitting?: boolean;
	productName?: string;
	gameCode?: string
}

interface LabelQueueTabProps {
    eventKey: string;
}
const LabelQueueTab:VFC<LabelQueueTabProps> = ({ eventKey }) => {
	const userProfile = useUserProfile();
	const show = useContext(TabState)?.tab === eventKey;
	const [openModal, setOpenModal] = useState<OpenModalModel>({ type: null });
	const labelQueueQuery = useQuery('getLabelQueueItems', () => getNCLLabelQueue(), { enabled: show });
	const labelQueue = labelQueueQuery.data?.data;
	const filterProperties = useMemo(() => new Map([
		[
			'Status',
			{
				filter: 'ncl_status',
				selectableFilters: new Set(
					Object.keys(assetConst.NCL_FINAL_LABEL_STATUS).map((value) => displayString(value)),
				),
			},
		],
	]), []);

	const canEditLabels = isAuthorized(userProfile.permissions, [permConst.NCL.LABEL_QUEUE.EDIT]);

	const dataFormat = useMemo(() => {
		const ActionsCell = ({ original }: { original: NCLLabelQueueData }) => {
			const {
				APPROVED,
				NCL_ACCEPTED_UPLOAD_FAILED,
				NCL_ACCEPTED_WITH_CHANGES,
				NCL_ACCEPTED,
				NCL_PROOF_UPLOAD_FAILED,
				PRINT_PROOF_UPLOAD_FAILED,
				SUBMITTED_TO_NCL,
			} = assetConst.NCL_FINAL_LABEL_STATUS as Record<string, string>;
			const {
				FORGO_NOT_REQUESTED,
				REJECT_FORGO,
			} = assetConst.FINAL_LABEL_FORGO_STATUS as Record<string, string>;
			return (
				<MeatballDropdown>
					{canEditLabels && (
						<>
							<MeatballDropdown.Item
								disabled={
									![SUBMITTED_TO_NCL, NCL_ACCEPTED_UPLOAD_FAILED].includes(
										original.ncl_status,
									)
								}
								onClick={() =>
									setOpenModal({
										type: ModalTypes.NCL_ACCEPT,
										assetId: original.asset_id,
										productName: original.game_name,
										gameCode: original.game_code
									})
								}
							>
								NCL Accept
							</MeatballDropdown.Item>
							<MeatballDropdown.Item
								disabled={
									![
										NCL_ACCEPTED,
										NCL_ACCEPTED_WITH_CHANGES,
										PRINT_PROOF_UPLOAD_FAILED,
									].includes(original.ncl_status)
								}
								onClick={() =>
									setOpenModal({
										type: ModalTypes.SUBMIT_FOR_APPROVAL,
										assetId: original.asset_id,
										productName: original.game_name,
										gameCode: original.game_code,
									})
								}
							>
								Submit For Approval
							</MeatballDropdown.Item>
							<MeatballDropdown.Item
								disabled={
									original.ncl_status === APPROVED ||
									![FORGO_NOT_REQUESTED, REJECT_FORGO].includes(
										original.final_label_forgo_status,
									)
								}
								onClick={() =>
									setOpenModal({
										type: ModalTypes.REQUEST_FORGO,
										assetId: original.asset_id,
										productName: original.game_name,
									})
								}
							>
								Request Forgo Label Approval
							</MeatballDropdown.Item>
							<MeatballDropdown.Divider />
							<MeatballDropdown.Item
								disabled={
									![
										SUBMITTED_TO_NCL,
										NCL_ACCEPTED,
										NCL_ACCEPTED_WITH_CHANGES,
										NCL_PROOF_UPLOAD_FAILED,
										NCL_ACCEPTED_UPLOAD_FAILED,
									].includes(original.ncl_status)
								}
								onClick={() =>
									setOpenModal({
										type: ModalTypes.REQUEST_NEW_LABEL,
										assetId: original.asset_id,
										gameCode: original.game_code,
									})
								}
								variant="danger"
							>
								Request New Label
							</MeatballDropdown.Item>
							<MeatballDropdown.Divider />
						</>
					)}
					<MeatballDropdown.Item href={`/products/${original.product_id}?tab=label-history`}>
						View Product/Label History
					</MeatballDropdown.Item>
				</MeatballDropdown>
			);
		};
		const TitleCell = ({ original, value }: CellInfo) => {
			return `[${original.game_code}] ${value}`;
		};
		const JapanDateCell = ({ value }: CellInfo) => {
			return (
				<>
					{formatDate(
						parseDateString(value, undefined, timeZoneConstants.JAPAN),
						dateFormat.DATE_JP,
					)}
					<br />
					<small>
						{formatDate(
							parseDateString(value, undefined, timeZoneConstants.JAPAN),
							dateFormat.TIME_JP,
						)}
					</small>
				</>
			);
		};
		const StatusCell = ({ value }: CellInfo) => {
			return <StatusText>{value}</StatusText>;
		};
		const ForgoStatusCell = ({ value }: CellInfo) => {
			return <StatusText>{value && value !== 'NOT_REQUESTED' ? value : '' }</StatusText>;
		};
		const FileLinkCell = ({ value }: CellInfo) => {
			if (labelQueue) {
				const files = (value as ArrayElement<typeof labelQueue>['final_label_files']).map(
					(file, index) => (
						<li className="overflow-hidden" key={`file-${index}`}>
							<FileLink fileId={file.file_id} status={file.status} displayIcon saved>{file.file_name}</FileLink>
						</li>
					),
				);
				if (files.length) {
					return <ul className="list-unstyled p-0 mb-0">{files}</ul>;
				}
			}
			return null;
		};
		return [
			{ Header: 'Product', accessor: 'game_name', Cell: TitleCell },
			{ Header: 'Forgo Status', accessor: 'final_label_forgo_status', Cell: ForgoStatusCell },
			{
				Header: 'Publisher',
				accessor: 'company_name',
			},
			{
				Header: 'Date Modified',
				accessor: 'submitted_date',
				sortMethod: (a: any, b: any) => {
					return new Date(a) > new Date(b) ? 1 : -1;
				},
				Cell: JapanDateCell,
			},
			{ Header: 'Uploaded Final Label', accessor: 'final_label_files', Cell: FileLinkCell },
			{ Header: 'Version', accessor: 'version', width: 75 },
			{ Header: 'Status', accessor: 'ncl_status', Cell: StatusCell },
			{
				...actionsColumn,
				Cell: ActionsCell,
			},
		];
	}, [labelQueue]);
	const isLoading = labelQueueQuery.isLoading;
	return (
		<Page.ContentContainer>
			<SectionTitle>Label Approval Queue</SectionTitle>
			{isLoading ? (
				<Loading />
			) : (
				<>
					<FilterableTable
						data={labelQueue}
						dataFormat={dataFormat}
						filterProperties={filterProperties}
						searchableFields={['game_name', 'game_code', 'company_name']}
						searchableFieldPlaceHolder="Search by Publisher, Title or Game Code"
						noBorder
						retainPageState
						stateOnTab={eventKey}
					/>
					<NCLAcceptModal
						show={openModal.type === ModalTypes.NCL_ACCEPT}
						onClose={() => setOpenModal({ ...openModal, type: null })}
						assetId={openModal.assetId}
						onSuccess={() => labelQueueQuery.refetch()}
						gameCode={openModal?.gameCode}
					/>
					<ActionConfirmationModal
						show={openModal.type === ModalTypes.REQUEST_FORGO}
						title="Request Forgo Label Approval"
						onCancel={() => setOpenModal({ ...openModal, type: null })}
						onConfirm={async () => {
							setOpenModal({ ...openModal, isSubmitting: true });
							try {
								setOpenModal({ ...openModal, isSubmitting: false, type: null });
								await postAssetStatus(String(openModal.assetId), {
									final_label_forgo_status: assetConst.FINAL_LABEL_FORGO_STATUS.REQUEST_FORGO,
								});
								labelQueueQuery.refetch();
								toasterNotify('Request to Forgo Label Approval sent', 'success');
							} catch (err: unknown) {
								setOpenModal({ ...openModal, isSubmitting: false });
								if (
									err instanceof Error &&
									'isAxiosError' in err &&
									err.isAxiosError
								) {
									toasterNotify(
										createMessageForError(err, 'submitting a request to forgo'),
										'error',
										err,
									);
								} else {
									throw err;
								}
							}
						}}
						isSubmitting={openModal.isSubmitting}
						confirmLabel="Request Forgo"
					>
						<Alert variant="info" className="mb-0">
							By clicking confirm, you are requesting to forgo a label approval, which
							if accepted by the Publisher will allow cartridges to begin
							manufacturing without final label approval. This will generate a request
							directly to the publisher for them to forgo the label approval. Approval
							of a final label is still expected after the publisher has agreed to
							forgo.
						</Alert>
					</ActionConfirmationModal>
					<SubmitForApprovalModal
						show={openModal.type === ModalTypes.SUBMIT_FOR_APPROVAL}
						onClose={() => setOpenModal({ ...openModal, type: null })}
						assetId={openModal.assetId}
						onSuccess={() => labelQueueQuery.refetch()}
						gameCode={openModal?.gameCode}
					/>
					<RequestNewLabelModal
						show={openModal.type === ModalTypes.REQUEST_NEW_LABEL}
						onClose={() => setOpenModal({ ...openModal, type: null })}
						assetId={openModal.assetId}
						onSuccess={() => labelQueueQuery.refetch()}
						gameCode={openModal?.gameCode}
					/>
				</>
			)}
		</Page.ContentContainer>
	);
};
export default LabelQueueTab;
