import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, FormGroup, FormLabel, Row } from 'react-bootstrap';

import { fileTransferConst } from '../../../constants/fileTransferConstants';
import AsperaFileUpload from '../../../components/AsperaFileUpload/AsperaFileUpload';
import BaseModal from '../../../components/BaseModal/BaseModal';
import { toasterNotify } from '../../../utils/toaster';


function mapStateToProps(state) {
	return {
		userProfile: state.authReducer.userProfile
	};
}

class AddSubmissionFilesModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isFilesSelected: false,
			isUploadInitiated: false
		};
		this.updateFilesSelected = this.updateFilesSelected.bind(this);
		this.onComplete = this.onComplete.bind(this);
	}

	updateFilesSelected(isFilesSelected) {
		this.setState({
			isFilesSelected: isFilesSelected
		});
	}

	submitFiles() {
		if (this.state.isFilesSelected) {
			this.setState({
				isUploadInitiated: true
			});
		} else {
			toasterNotify('No files selected', 'info');
		}
	}

	onComplete() {
		this.props.loadActivityLog();
		this.props.closeModal();
	}

	getModalBody() {
		const { userProfile, productSubmissionId } = this.props;
		const { isUploadInitiated } = this.state;
		const onComplete = this.onComplete;
		return (
			<div>
				<Alert variant="info">
					Please note that when files are deleted or replaced, the
					original version is still stored in this system and
					accessible via the Submission activity logs.
				</Alert>

				<form>
					<FormGroup as={Row}>
						<FormLabel className="col-sm-4 text-sm-right">
							Attach File(s)
						</FormLabel>
						<div className="col-sm-6">
							<AsperaFileUpload
								updateFilesSelected={this.updateFilesSelected}
								userProfile={userProfile}
								entityType={
									fileTransferConst.ENTITY_TYPE
										.PRODUCT_SUBMISSION
								}
								entityId={productSubmissionId}
								isUploadInitiated={isUploadInitiated}
								onCompleteFunction={onComplete}
							/>
						</div>
					</FormGroup>
				</form>
			</div>
		);
	}

	render() {
		return (
			<BaseModal show={true} onCancel={this.props.closeModal}>
				<BaseModal.Title>
						Add Submission File(s)
				</BaseModal.Title>
				{this.getModalBody()}
				<BaseModal.Submit
					onClick={() => {
						this.submitFiles();
					}}
				>
					Upload
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}

export default connect(mapStateToProps)(AddSubmissionFilesModal);
