import React, { ReactNode } from 'react';

import ActionLink from '../../../components/ActionLink/ActionLink';
import LogEntry from '../../../components/LogEntry/LogEntry';
import { digitalCodesConstants } from '../../../constants/digitalCodesConstants';
import { getStatusForPriceRequestAction } from '../../../utils/digitalCodesUtils';
import { displayString } from '../../../utils/stringUtils';
import StatusText from '../../../components/StatusText/StatusText';

const {
	APPROVED,
	ACTIVATING,
	ACTIVE,
	PENDING_REVIEW,
	REVIEWED,
	DECLINED,
	RETRACTED,
} = digitalCodesConstants.priceRequestStatuses;
const {
	PRICED,
	COMMENT,
	TRANSFERRED,
	REINSTATED,
	SUPERSEDED,
	EXTENDED_LIMIT,
	PERMANENT_PRICE,
} = digitalCodesConstants.priceRequestActions;


function getLogMessage(noun: string, inActionType: string, inRequestStatus: string, priceStatus: string) {
	const changedStatusMsg = (status: ReactNode) => <>has changed the status to {status}</>;

	if (inActionType === COMMENT) {
		 return {
			showUserCompany: true,
			msg: () => 'has entered a comment',
			showDetailsLink: true,
		 };
	} else if (inActionType === EXTENDED_LIMIT) {
		return {
			showUserCompany: true,
			msg: () => `has extended the ${noun} request limit`,
			showDetailsLink: false,
		};
	} else if (inRequestStatus === PENDING_REVIEW) {
		return {
			showUserCompany: true,
			msg: (status: ReactNode) => <>has created a {noun} request and is {status}</>,
			showDetailsLink: true,
		};
	} else if (inRequestStatus === REVIEWED) {
		return {                                                                                                           
			showUserCompany: true,
			msg: changedStatusMsg,
			showDetailsLink: true,
		};
	} else if (
		(inActionType === APPROVED && inRequestStatus === APPROVED && priceStatus === ACTIVATING) || 
		(inActionType === PRICED && inRequestStatus === APPROVED && priceStatus === ACTIVE)
	) {
		return {
			showUserCompany: false,
			msg: (status: ReactNode) => <>The {noun} agreement is {status}</>,
			showDetailsLink: true,
		};
	} else if (inActionType === APPROVED && inRequestStatus === APPROVED) {
		return {
			showUserCompany: true,
			msg: changedStatusMsg,
			showDetailsLink: true,
		};
	} else if (inActionType === DECLINED) {
		return {
			showUserCompany: true,
			msg: changedStatusMsg,
			showDetailsLink: false,
		};
	} else if (inActionType === RETRACTED) {
		return {
			showUserCompany: true,
			msg: changedStatusMsg,
			showDetailsLink: false,
		};
	} else if (inActionType === TRANSFERRED && inRequestStatus === APPROVED) {
		return {
			showUserCompany: false,
			msg: () => `The ${noun} agreement was set to inactive due to a product transfer`,
			showDetailsLink: false,
		};
	} else if (inActionType === REINSTATED) {
		return {
			showUserCompany: false,
			msg: () => `The ${noun} agreement was reinstated due to product transfer`,
			showDetailsLink: true,
		};
	} else if (inActionType === SUPERSEDED) {
		return {
			showUserCompany: false,
			msg: () => `The ${noun} agreement was superseded by another ${noun} agreement`,
			showDetailsLink: true,
		};
	} else if (inActionType === TRANSFERRED && inRequestStatus === DECLINED) {
		return {
			showUserCompany: false,
			msg: () => `The ${noun} agreement was declined due to a product transfer`,
			showDetailsLink: false,
		};
	} else if (inActionType === PERMANENT_PRICE && inRequestStatus === DECLINED) {
		return {
			showUserCompany: false,
			msg: () => 'Declared Price request declined due to permanent price found',
			showDetailsLink: false,
		};
	} else if (inActionType === PERMANENT_PRICE && inRequestStatus === APPROVED) {
		return {
			showUserCompany: false,
			msg: () => 'Declared Price set to Inactive due to permanent price found',
			showDetailsLink: false,
		};
	} else {
		return {
			showUserCompany: false,
			msg: () => 'unknown message type',
			showDetailsLink: false,
		};
	}
}

interface RequestActivityLogProps {
	requests?: WholesalePriceRequestData[] | DeclaredPriceRequestData[];
	showDetailsCallBack?: (requestId: number) => void;
	users?: Record<string, any>[];
	companies?: Record<string, any>[];
}
const RequestActivityLog = ({ requests, showDetailsCallBack, users, companies }: RequestActivityLogProps) => {
	const requestNoun = (request: any): string =>
		request &&
		(('wholesale_price_request_id' in request && 'Wholesale Price') ||
			('declared_price_request_id' in request && 'Declared Price') ||
			'');

	const allActions = requests?.map((request) => request?.actions).flat().sort((a, b) => new Date(b.action_timestamp).valueOf() - new Date(a.action_timestamp).valueOf());
	const getUserAndCompany = (action: { actor_ndid_user_id: string, actor_ndid_company_id: string }) => {
		const who = users?.find((user: Record<string, any>) => user.ndid_user_id === action.actor_ndid_user_id);
		const where = companies?.find(
			(company: Record<string, any>) => company.ndid_company_id === action.actor_ndid_company_id,
		);
		return `${who ? who.user_name : ''}${where ? ' [' + where.company_name + ']' : ''}`;
	};

	return (
		<>
			{
				allActions?.map((action, index) => {
					const noun = requestNoun(action);
					const status = getStatusForPriceRequestAction(action);
					const statusBadge = status && (
						<StatusText
							variant={
								['PENDING_REVIEW', 'REVIEWED'].includes(status as string)
									? 'warning'
									: undefined
							}
							badge
						>
							{displayString(status)}
						</StatusText>
					);
					const message = getLogMessage(
						noun,
						action.action_type,
						action.request_status,
						action.price_status,
					);
					return (
						<LogEntry key={index + '.key'}>
							<LogEntry.Heading timestamp={action.action_timestamp}>
								{message.showUserCompany && (
									<>
										<b>{getUserAndCompany(action)}</b>{' '}
									</>
								)}
								{message.msg(statusBadge)}
							</LogEntry.Heading>
							<LogEntry.Comment>
								{action.comment && <p>{action.comment}</p>}
							</LogEntry.Comment>
							{showDetailsCallBack && message.showDetailsLink && (
								<ActionLink
									onClick={() =>
										showDetailsCallBack(
											'wholesale_price_request_id' in action
												? action.wholesale_price_request_id
												: action.declared_price_request_id,
										)
									}
								>
									View {noun} Agreement
								</ActionLink>
							)}
						</LogEntry>
					);
				})
			}
		</>
	);
};

export default RequestActivityLog;
