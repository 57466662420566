import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import * as yup from 'yup';

import { getWSPRequestAgreementTemplates, postWSPRequests, getWSPRequestAgreement } from '../../../services/digitalCodesService';
import { isEmptyObject } from '../../../utils/dataUtils';
import { getSanitizedHTML } from '../../../utils/stringUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { validateToSchema } from '../../../utils/validationUtils';
import BaseModal from '../../BaseModal/BaseModal';
import Forms from '../../Forms/Forms';
import WSPRequestView from './views/WSPRequestView';


const schema = (retailPrice) => {
	return yup.object().shape({
		certified_price: yup.string()
			.test(
				'below_retail',
				`Wholesale price must be the same or less than the retail price of ${retailPrice}.`,
				(value) => !retailPrice || parseFloat(value) <= retailPrice,
			)
			.test(
				'validDollar',
				'Valid price is required, no amounts over 999.99 or fractions of cents',
				(value) => /^([1-9]\d?\d?|0)(\.\d{1,2})?$/.test(value)
			)
			.test(
				'type',
				'Only include numbers and one period in the price, no currency signs',
				(value) => !isNaN(value))
			.required('Price must be provided'),
		certified_flag: yup.boolean()
			.default(false)
			.required('Box must be checked')
			.test(
				'must_be_true',
				'Box must be checked',
				(value) => value === null || value === true,
			),
		user_signature: yup.string().trim()
			.required('Signature is required'),
	});
};

const RequestWSPAgreementModal = ({
	show,
	closeModal,

	onSubmitted,
	defaultValues={},
	nsUid='',
	productPlatformCode='',
	componentGameName='',
	retailPrice,

	readOnly=false,
	requestId,
}) => {
	const [formValues, setFormValues] = useState();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showAllErrors, setShowAllErrors] = useState(false);
	const [lastUpdated, setLastUpdated] = useState();

	const existingDataQuery = useQuery(
		['getWSPRequestAgreement', requestId],
		() => getWSPRequestAgreement(requestId),
		{
			enabled: !!(show && requestId && readOnly),
		}
	);

	const templateQuery = useQuery(
		['getWSPRequestAgreementTemplates', productPlatformCode],
		() => getWSPRequestAgreementTemplates(productPlatformCode),
		{ enabled: !!(show && !readOnly) },
	);
	const isLoading = existingDataQuery.isLoading || templateQuery.isLoading;
	const fault = (templateQuery.isError && templateQuery.error) || (existingDataQuery.isError && existingDataQuery.error);
	const wspTemplate = readOnly
		? existingDataQuery.isSuccess && existingDataQuery.data.data.wsp_agreement_template
		: templateQuery.isSuccess && templateQuery.data.data;

	const wspTemplateBody = wspTemplate.template || wspTemplate.template_body;

	useEffect(() => {
		if (show && !readOnly) {
			setFormValues({...defaultValues});
			setShowAllErrors(false);
			setIsSubmitting(false);
		}
	}, [show]);

	const changedFormValue = (values) => {
		setFormValues(values);
	};

	const validationErrors = formValues && validateToSchema(schema(retailPrice), formValues);

	const submitForm = async () => {
		if (isSubmitting) {
			return;
		}
		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}

		setIsSubmitting(true);

		postWSPRequests(nsUid, {
			wholesale_price_agreement_template_id: wspTemplate.wholesale_price_agreement_template_id,
			certified_flag: formValues.certified_flag,
			certified_price: formValues.certified_price,
			user_signature: formValues.user_signature,
		}, () => setLastUpdated(new Date()))
			.then(() => {
				onSubmitted();
				closeModal();
				toasterNotify(
					`Your wholesale price agreement for "${componentGameName}" has been successfully submitted.`,
					'success',
				);
			})
			.catch((err) => {
				toasterNotify(
					createMessageForError(err, 'submitting your wholesale price agreement'),
					'error',
					err,
				);
			})
			.finally(() => {
				setLastUpdated(null);
				setIsSubmitting(false);
			});
	};

	return (
		<BaseModal
			size="xl"
			show={show}
			onCancel={closeModal}
			isLoading={isLoading}
			isSubmitting={isSubmitting}
			fault={fault}
		>
			<BaseModal.Title>
				{wspTemplateBody?.Title ? <span
					dangerouslySetInnerHTML={{
						__html: getSanitizedHTML(wspTemplateBody?.Title),
					}}
				/> : 'Loading Agreement...'}
			</BaseModal.Title>
			<Row className="justify-content-center">
				<Col sm={12} lg={10}>
					<Forms
						onChange={(id, v) => changedFormValue(id, v)}
						values={(readOnly ? existingDataQuery.data?.data.wholesale_price_request : formValues) || {}}
						validationErrors={validationErrors}
						showAllErrors={showAllErrors}
					>
						<WSPRequestView template={wspTemplateBody} readOnly={readOnly} />

						<small
							className="float-right"
							dangerouslySetInnerHTML={{
								__html: getSanitizedHTML(`Legal: ${wspTemplate?.version}`),
							}}
						/>
					</Forms>
				</Col>
			</Row>
			{!readOnly && <BaseModal.Submit onClick={() => submitForm()}>Submit</BaseModal.Submit>}
			{!readOnly && (
				<BaseModal.SubmissionStatus lastUpdated={lastUpdated}>
					Request submission may take time to complete
				</BaseModal.SubmissionStatus>
			)}
		</BaseModal>
	);
};

export default RequestWSPAgreementModal;
