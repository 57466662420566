import React, { Component } from 'react';
import dayjs from 'dayjs';
import { Button, Form, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as yup from 'yup';

import FAIcon from '../../components/FAIcon/FAIcon';
import Loading from '../../components/Loading/Loading';
import LoadingText from '../../components/Loading/LoadingText';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import { orderConst } from '../../constants/orderConstants';
import { permConst } from '../../constants/permConst';
import { getCompanyProfile } from '../../services/companiesService';
import { getOrderableProducts, postOrderDetails } from '../../services/ordersService';
import { formatCurrency, parseCurrency } from '../../utils/currencyUtils';
import { safeEval } from '../../utils/dataUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { determineActiveRightTransfer, transferDomains } from '../../utils/transferRightsUtils';
import { isAuthorized } from '../../utils/userUtils';
import { checkValidProductQuantity } from '../../utils/orderUtils';
import { ProductsSelectionRow } from './ProductsSelectionRow';


function mapStateToProps(state) {
	return {
		userProfile: state.authReducer.userProfile,
	};
}

export class ProductsSelection extends Component {
	constructor(props) {
		super(props);

		let maxSelectionId = 0;
		let orderTotal = 0;
		let gameCodes = [];
		if (
			props.orderHeader &&
			props.orderHeader.selections &&
			props.orderHeader.selections.length > 0
		) {
			maxSelectionId = props.orderHeader.selections.reduce((id, item) => {
				return item.id > id ? item.id : id;
			}, 0);
			orderTotal = this.calculateOrderTotal(props.orderHeader.selections);

			gameCodes = props.orderHeader.selections.map((d) => {
				return d.gameCode;
			});
		}

		this.state = {
			currentAutoId: maxSelectionId + 1,
			selections: maxSelectionId > 0 ? props.orderHeader.selections : [{ id: 1 }],
			availableProducts: null,
			selectedProductCodes: gameCodes,
			orderTotal: orderTotal,
			skuRequired: false,
			isFormDirty: false,
			isLoadingCompany: false,
			isLoadingProducts: false,
			isSaving: false,
		};

		this.getAvailableProducts = this.getAvailableProducts.bind(this);
		this.getCompanyProfile = this.getCompanyProfile.bind(this);
		this.addRow = this.addRow.bind(this);
		this.removeRow = this.removeRow.bind(this);
		this.calculateOrderTotal = this.calculateOrderTotal.bind(this);
		this.updateSelection = this.updateSelection.bind(this);
		this.generateUpdatePayload = this.generateUpdatePayload.bind(this);
		this.previousClicked = this.previousClicked.bind(this);
		this.nextClicked = this.nextClicked.bind(this);
		this.saveClicked = this.saveClicked.bind(this);
		this.isFormValid = this.isFormValid.bind(this);
		this.saveEditOrder = this.saveEditOrder.bind(this);
		this.setLeadTime = this.setLeadTime.bind(this);
		this.activeRightTransferCheck = this.activeRightTransferCheck.bind(this);
	}

	getAvailableProducts() {
		const { userProfile, orderHeader } = this.props;
		const platform = orderHeader ? orderHeader.platform : '';

		this.setState({ isLoadingProducts: true });
		getOrderableProducts(userProfile.companyId, platform)
			.then((response) => {
				let availableProducts = [];
				if (response && response.data) {
					availableProducts = response.data.filter((d) => {
						return d.platform_code === platform;
					});
				}

				this.setState({
					availableProducts,
					isLoadingProducts: false,
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving available products'),
					'error',
					error,
				);
				this.setState({ isLoadingProducts: false });
			});
	}

	getCompanyProfile() {
		const { userProfile } = this.props;

		this.setState({ isLoadingCompany: true });

		getCompanyProfile(userProfile.companyId)
			.then((response) => {
				let skuRequired = safeEval(() => response.data.company_information.sku_number_required);
				this.setState({
					skuRequired: skuRequired
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving company profiles'),
					'error',
					error);
			})
			.finally(() => {
				this.setState({ isLoadingCompany: false });
			});
	}

	setLeadTime() {
		const { orderHeader } = this.props;
		const platform = orderHeader.platform;
		const all_platforms = orderHeader.resources.orderable_platforms;
		const leadTimeDays = all_platforms.find((p) => p.platform_code === platform)
			.leadTimeDaysCount;
		this.setState({
			leadTimeDays,
		});
	}

	addRow() {
		let { currentAutoId, selections } = this.state;
		currentAutoId = currentAutoId + 1;
		selections.push({ id: currentAutoId });
		this.setState({
			currentAutoId: currentAutoId,
			selections: selections,
			isFormDirty: true,
		});
	}

	removeRow(id) {
		let { selections, selectedProductCodes } = this.state;
		let removed = {};
		let _selections = [];
		let _selectedProductCodes = [];
		if (id) {
			for (let i = 0; i < selections.length; i++) {
				const item = selections[i];
				if (item.id !== id) {
					_selections.push(item);
				} else {
					removed = item;
				}
			}
			for (let i = 0; i < selectedProductCodes.length; i++) {
				if (removed.gameCode !== selectedProductCodes[i]) {
					_selectedProductCodes.push(selectedProductCodes[i]);
				}
			}
			this.setState({
				orderTotal: this.calculateOrderTotal(_selections),
				selections: _selections,
				isFormDirty: true,
				selectedProductCodes: _selectedProductCodes,
			});
		}
	}

	calculateOrderTotal(selections) {
		let total = 0;

		selections.forEach((item) => {
			let price = 0;
			if (item && item.id && item.unitPrice && item.quantity) {
				price = parseCurrency(item.unitPrice) * item.quantity;
			}
			total = total + price;
		});

		return total;
	}

	updateSelection(selection) {
		if (selection && selection.id) {
			let { selections } = this.state;
			let newSelections = selections.map((item) => {
				if (item && item.id === selection.id) {
					return Object.assign({}, item, selection);
				} else {
					return item;
				}
			});

			let selectedProductCodes = selections.map((d) => {
				return d.gameCode;
			});

			this.setState({
				selectedProductCodes: selectedProductCodes,
				orderTotal: this.calculateOrderTotal(newSelections),
				selections: newSelections,
				isFormDirty: true,
			});
		}
	}

	generateUpdatePayload() {
		const { orderHeader } = this.props;
		const { selections } = this.state;

		let payload = {
			order_header_id: orderHeader.orderHeaderId,
			platform_code: orderHeader.platform,
		};
		payload.order_details = selections.map((item) => {
			return {
				lineNumber: item.id, // required, for now passing in the client-generated line id
				product_id: item.productId,
				noa_part_number: item.noaPartNumber,
				publisher_part_number: item.publisherPartNumber,
				status: orderConst.STATUS.NO_STATUS, // required but currently not used.
				quantity: parseInt(item.quantity),
				unit_price: item.unitPrice,
				requested_ship_date: dayjs(item.shipByDate).format('YYYY-MM-DDT00:00:00'),
				submission_id: item.version.submission_id,
				product_name: item.gameName,
				game_code: item.gameCode,
			};
		});
		return payload;
	}

	previousClicked() {
		const { setActiveView } = this.props;
		setActiveView(1);
	}

	nextClicked() {
		const { setActiveView } = this.props;

		if (this.state.isFormDirty) {
			this.saveEditOrder(() => {
				// on success
				setActiveView(3);
			});
		} else {
			setActiveView(3);
		}
	}

	// TODO: whyyyy???
	saveClicked() {
		this.saveEditOrder();
	}

	saveEditOrder(onSuccess) {
		const { initializeStateValue } = this.props;

		this.setState({ isSaving: true });

		const payload = this.generateUpdatePayload();

		postOrderDetails(payload)
			.then((response) => {
				let message = 'Order successfully edited';
				toasterNotify(message, 'success');

				initializeStateValue('selections', this.state.selections);
				this.setState({ isFormDirty: false, isSaving: false });

				if (onSuccess) {
					onSuccess(message);
				}
			})
			.catch((error) => {
				toasterNotify(createMessageForError(error, 'saving changes'), 'error', error);
				this.setState({ isSaving: false });
			});
	}

	activeRightTransferCheck(item) {
		const { availableProducts } = this.state;
		if (!availableProducts) {
			return;
		}
		let activeTranfer = false;
		let selectedProduct = availableProducts.find((d) => {
			return d.product_id === item.productId;
		});
		if (selectedProduct) {
			activeTranfer = determineActiveRightTransfer(selectedProduct, transferDomains.ORDER);
		}
		return activeTranfer;
	}

	validateToSchema() {
		this.schema =
			this.schema ||
			yup.object().shape({
				orderTotal: yup
					.number()
					.min(1)
					.required(),
				selections: yup
					.array()
					.required()
					.of(
						yup.object().shape({
							publisherPartNumber: yup
								.mixed()
								.test(
									'publisher_part_number',
									'Selected product does not have a part number/SKU. Please add the part number to the product.',
									function(value) {
										const { skuRequired } = this.options.context;
										return !this.parent.productId || !skuRequired || !!value;
									},
								),
							shipByDate: yup
								.mixed()
								.test(
									'ship_by_date',
									'Requested Ship Date must be a valid date at or after the earliest allowable order date.',
									function(date) {
										const { leadTimeDays } = this.options.context;
										if (leadTimeDays == null || !date) {
											return true;
										}
										const inputShipByDate = dayjs(date);
										if (inputShipByDate.isValid()) {
											const earliestOrderDate = dayjs()
												.startOf('day')
												.add(leadTimeDays, 'days');
											return inputShipByDate.isSameOrAfter(earliestOrderDate);
										}
										return false;
									},
								),
						}),
					),
			});

		try {
			this.schema.validateSync(this.state, {
				abortEarly: false,
				context: {
					leadTimeDays: this.state.leadTimeDays,
					skuRequired: this.state.skuRequired,
				},
			});
		} catch (err) {
			if (err && err.inner) {
				return err.inner.map((e) => ({ name: e.path, reason: e.message }));
			} else {
				throw err;
			}
		}
		return [];
	}

	isFormValid() {
		const { selections, skuRequired, orderTotal, leadTimeDays } = this.state;

		if (!selections || selections.length === 0) {
			return false;
		}

		for (const selection of selections) {
			const selectedProduct = selection.gameCode && selection.gameName && selection.productId;
			if (!selectedProduct) return false;

			let validQuantity = checkValidProductQuantity(selection);
			if (!validQuantity) return false;

			const nonZeroTotalPrice = orderTotal !== 0;
			if (!nonZeroTotalPrice) return false;

			const hasRightTransferInProgress = this.activeRightTransferCheck(selection);
			if (hasRightTransferInProgress) return false;

			const hasPriceGreaterThanZero = selection.unitPrice > 0;
			if (!hasPriceGreaterThanZero) return false;

			if (skuRequired) {
				const hasPublisherPartNumber = selection.publisherPartNumber;
				if (!hasPublisherPartNumber) return false;
			}

			const hasShipByDate = selection.shipByDate !== null;
			if (!hasShipByDate) return false;

			const inputShipByDate = dayjs(selection.shipByDate);

			const validShipByDate = inputShipByDate.isValid();
			if (!validShipByDate) return false;

			const earliestOrderDate = dayjs()
				.startOf('day')
				.add(leadTimeDays, 'days');

			const shipByDateIsAfterEarliestOrderDate = inputShipByDate.isSameOrAfter(
				earliestOrderDate,
			);
			if (!shipByDateIsAfterEarliestOrderDate) return false;
		}

		return true;
	}

	componentDidMount() {
		this.getCompanyProfile();
		this.getAvailableProducts();
		this.setLeadTime();
	}

	renderTitleButton() {
		const { userProfile, orderHeader, deleteClicked, isEditable } = this.props;

		const formIsValid = this.isFormValid();

		const canDeleteOrder =
			orderHeader.orderHeaderId > 0 &&
			isAuthorized(userProfile.permissions, [permConst.ORDER.DELETE.COMPANY]);

		return (
			<>
				{isEditable && (
					<MeatballDropdown
						toggleSize="lg"
						id='products-selection-context-menu'
					>
						<MeatballDropdown.Item
							disabled={!formIsValid}
							onSelect={this.saveClicked}
						>
							Save Draft Order
						</MeatballDropdown.Item>
						{canDeleteOrder && (
							<MeatballDropdown.Item
								onSelect={() => deleteClicked()}
							>
								Delete Draft Order
							</MeatballDropdown.Item>
						)}
					</MeatballDropdown>
				)}
			</>
		);
	}

	renderProductRows() {
		const {
			availableProducts,
			selectedProductCodes,
			skuRequired,
			isSaving,
			leadTimeDays,
		} = this.state;

		const { initializeStateValue, userProfile, orderHeader, isEditable } = this.props;

		const errors = this.validateToSchema();

		return this.state.selections.map((selection, index) => {
			let element = null;
			const errorToDisplay = errors.find((e) =>
				new RegExp(`selections\\[${index}`).test(e.name),
			);

			if (selection) {
				element = (
					<ProductsSelectionRow
						key={selection.id}
						id={selection.id}
						orderHeader={orderHeader}
						skuRequired={skuRequired}
						availableProducts={availableProducts}
						selection={selection}
						gameCodes={selectedProductCodes}
						leadTimeDays={leadTimeDays}
						remove={this.removeRow}
						updateSelection={this.updateSelection}
						initializeStateValue={initializeStateValue}
						userProfile={userProfile}
						formTransferCheck={this.activeRightTransferCheck}
						isEditable={isEditable && !isSaving}
						errorDisplay={errorToDisplay && errorToDisplay.reason}
					/>
				);
			}
			return element;
		});
	}

	renderFooter(isLoadingPrice) {
		const { history, isEditable } = this.props;
		const { isSaving } = this.state;

		const formIsValid = this.isFormValid();

		return (
			<div className="btn-container">
				<div className="float-left d-flex">
					<button
						className="btn btn-outline-secondary"
						type="button"
						onClick={this.previousClicked}
						disabled={isSaving || isLoadingPrice}
					>
						<FAIcon name="chevron-left" className="mr-1" />
						Previous (Order Info)
					</button>
					<button
						className="btn btn-link no-outline"
						type="button"
						onClick={() => history.push('/orders')}
						disabled={isSaving || isLoadingPrice}
					>
						Cancel
					</button>
				</div>
				<div className="float-right d-flex">
					{isSaving && <LoadingText inline />}
					{isEditable && (
						<Button
							variant="outline-secondary"
							onClick={this.saveClicked}
							disabled={!formIsValid || isSaving || isLoadingPrice}
						>
							Save
						</Button>
					)}

					<Button
						variant="primary"
						onClick={this.nextClicked}
						disabled={!formIsValid || isSaving || isLoadingPrice}
					>
						Next (Confirm Order)
						<FAIcon name="chevron-right" />
					</Button>
				</div>
			</div>
		);
	}

	render() {
		const { orderHeader, isEditable } = this.props;

		const {
			orderTotal,
			availableProducts,
			selections,
			isLoadingCompany,
			isLoadingProducts,
		} = this.state;
		const showCardSize = !!safeEval(() => 'card_size' in selections[0]['version']);
		const isEdiOrder = orderHeader.isEdiOrder;
		const isLoading = isLoadingCompany || isLoadingProducts;

		const isLoadingPrice = selections.reduce((isLoadingPrice, selection) => {
			return isLoadingPrice || selection.isLoadingPrice;
		}, false);

		const loadingBody = () => <Loading />;
		const contentBody = () => (
			<>
				<Form className="form-fields">
					<Table>
						<thead>
							<tr>
								<th>Product</th>
								<th>Versions</th>
								{showCardSize ? (<th>Card Size</th>): null}
								<th>Part SKU</th>
								<th>Quantity</th>
								<th>Requested Ship Date</th>
								<th className="text-right">Price</th>
								<th />
							</tr>
						</thead>
						<tbody>
							{this.renderProductRows()}
							<tr className="row-total">
								<td colSpan={showCardSize ? 6: 5} align="right">
									Total
								</td>
								<td className="col-xs-2">
									{isLoadingPrice ? (
										<div className="loading-product-total-price">
											<LoadingText />
										</div>
									) : (
										<div className="total">{formatCurrency(orderTotal)}</div>
									)}
								</td>
								<td />
							</tr>
						</tbody>
					</Table>
					<p>
						{!isEdiOrder &&
							availableProducts &&
							availableProducts.length > 0 &&
							selections.length >= 0 &&
							availableProducts.length !== selections.length &&
							isEditable && (
								<button
									className="btn btn-link no-outline"
									type="button"
									onClick={() => {
										this.addRow();
									}}
								>
									+ Add More Items
								</button>
							)}
					</p>
				</Form>
				{this.renderFooter(isLoadingPrice)}
			</>
		);

		return (
			<div className="page-orders-items">
				<Title
					title="Create New Physical Order"
					subtitle="Step 2: Product & Version Selection"
					button={!isLoading && this.renderTitleButton()}
				/>
				<Page.FullPageCol>{isLoading ? loadingBody() : contentBody()}</Page.FullPageCol>
			</div>
		);
	}
}

export default connect(mapStateToProps)(ProductsSelection);
