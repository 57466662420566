import React, { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { linkColumn } from '../../../components/BaseTable/BaseTable';
import FAIcon from '../../../components/FAIcon/FAIcon';
import FilterableTable from '../../../components/FilterableTable/FilterableTable';
import Loading from '../../../components/Loading/Loading';
import Page from '../../../components/Page/Page';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { TabState } from '../../../components/StatefulTabs/StatefulTabs';
import StatusText from '../../../components/StatusText/StatusText';
import CurrencyCell from '../../../components/cells/CurrencyCell/CurrencyCell';
import DateCell from '../../../components/cells/DateCell/DateCell';
import { digitalCodesConstants } from '../../../constants/digitalCodesConstants';
import { getWSPRequestQueue } from '../../../services/digitalCodesService';
import { displayString } from '../../../utils/stringUtils';
import { getProductsInBulk } from '../../../services/productsService';
import { getProductParams } from '../../../utils/digitalCodesUtils';


const {
	PENDING_REVIEW,
	REVIEWED,
} = digitalCodesConstants.priceRequestStatuses;

const WSPReviewTab = ({ eventKey }) => {
	const show = useContext(TabState).tab === eventKey;
	const requestsQuery = useQuery('getWSPReviewQueue', () => getWSPRequestQueue(), { enabled: show });
	const [gameCodes, platformParams] = getProductParams(requestsQuery.data?.data);
	const productsQuery = useQuery(
		['getProductsInBulk', gameCodes, ['game_name', 'game_code'], platformParams.toString()],
		() => getProductsInBulk(gameCodes, ['game_name', 'game_code'], platformParams),
		{ enabled: requestsQuery.isSuccess }
	);
	const requests = requestsQuery.data?.data;

	requests?.map((request) => {
		request.product_name = productsQuery.data?.data.find(
			(product) =>
				product.game_code === request.game_code.substring(request.game_code.length - 5),
		)?.game_name;
	});
	const isLoading = requestsQuery.isLoading || productsQuery.isLoading;

	const columns = useMemo(() => [
		{ Header: 'Publisher', accessor: 'company_name', minWidth: 150 },
		{
			Header: 'Product',
			id: 'product_column',
			accessor: ({ product_name, game_code }) =>
				`[${game_code.substring(game_code.length - 5)}] ${product_name}`,
			minWidth: 200
		},
		{
			Header: 'Component',
			id: 'component_column',
			accessor: ({ ns_uid, game_name }) =>
				`[${ns_uid}] ${game_name}`,
			minWidth: 200
		},
		{ Header: 'Certified WSP', accessor: 'certified_price', Cell: CurrencyCell },
		{ Header: 'Received', accessor: 'submitted_datetime', Cell: DateCell },
		{ Header: 'Status', accessor: 'request_status', Cell: ({ value }) => (
			<StatusText>{value}</StatusText>
		), },
		{
			...linkColumn,
			Cell: ({ original }) => {
				const newPath = `/products/components/${original.ns_uid}?tab=wholesale-price-requests`;
				return (
                    <Link to={newPath}>
                        <FAIcon name="chevron-right" />
                    </Link> 
				);
			}
		}
	], [productsQuery.data?.data]);

	const filterProperties = useMemo(() => new Map(
		Object.entries({
			'Status' : {
				filter: 'request_status',
				selectableFilters: new Set([
					displayString(PENDING_REVIEW),
					displayString(REVIEWED),
				])
			}
		})
	), []);

	return (
		<Page.ContentContainer>
			<SectionTitle>Wholesale Price Requests</SectionTitle>
			{isLoading ? (
				<Loading />
			) : (
				<FilterableTable
					data={requests}
					dataFormat={columns}
					filterProperties={filterProperties}
					searchableFields={['company_name', 'ns_uid', 'game_name', 'game_code', 'product_name']}
					searchableFieldPlaceHolder={'Search by Publisher, Product, Component'}
					defaultSorted={[
						{
							id: 'modified_date',
							desc: true,
						},
					]}
					noBorder
					retainPageState
					stateOnTab={eventKey}
				/>
			)}
		</Page.ContentContainer>
	);
};
export default WSPReviewTab;
