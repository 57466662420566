import React, { ReactElement } from 'react';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast, cssTransition, ToastOptions, TypeOptions } from 'react-toastify';

import HelpBlock from '../components/HelpBlock/HelpBlock';
import { sleep } from './serviceUtils';

import '../css/toaster.css';

const pastToasts = new WeakMap();

const transition = cssTransition({
	enter: 'Toastify--animate Toastify__slide-enter--top-right',
	exit: 'Toastify--animate Toastify__slide-exit--top-right',
	collapseDuration: 100
});

/**
 * Post a toast notification. Posting a second toast for a previously passed-in error
 * will update that toast instead of posting a new one.
 * @param  {string | JSX.Element} message string or React node to display as the message
 * @param  {string} status Toast styling
 * @param  {Error} error Optional caught error message that necessitated this toast
 */
export async function toasterNotify(
	message: ReactElement | string = '',
	status: TypeOptions,
	error?: unknown,
	options: ToastOptions = {},
) {
	if (typeof error === 'object' && error instanceof Error) {
		if (
			'response' in error &&
			!!error.response &&
			typeof error.response === 'object' &&
			'status' in error.response &&
			[401, 404].includes(error.response?.status as number) &&
			!message
		) {
			return;
		}

		if (
			!message &&
			!!error &&
			typeof error === 'object' &&
			'response' in error
		) {
			message = createMessageForError(error);
		}

		// append error details in footer
		const errorDetails = [];
		if ('response' in error) {
			const response = error.response as AxiosResponse<any>;
			const serverMessage =
				response.data != null &&
				(typeof response.data === 'object'
					? response?.data?.message?.error?.message || response?.data?.error?.message
					: response.data);
			serverMessage && errorDetails.push(`Server response: ${serverMessage}`);
			errorDetails.push(`Status: ${response.status}`);
			const correlationId = response.headers['X-Correlation-Id'];
			correlationId && errorDetails.push(`Correlation ID: ${correlationId}`);
		} else {
			errorDetails.push(error.toString());
		}

		message = (
			<>
				{message}
				<HelpBlock>{errorDetails.join('; ')}</HelpBlock>
			</>
		);
	}

	const settings: ToastOptions = {
		type: status,
		position: 'top-right',
		autoClose: status !== toast.TYPE.ERROR && status !== toast.TYPE.WARNING ? 10000 : false,
		hideProgressBar: true,
		closeOnClick: !error,
		pauseOnHover: true,
		draggable: !error,
		closeButton: false,
		transition,
		...options,
	};

	// There's potential for a toast to be attached to a ToastContainer instance and then that
	// ToastContainer would be replaced by a new instance. This would happen if a view were to
	// switch from the loading screen to the screaming Luigi Not Found view -- it would appear
	// as if the toast never appeared. Here we add a slight delay to prevent the race condition.
	await sleep(1); // it's a hack but it works
	if (error) {
		const existingId = pastToasts.get(error);
		if (existingId) {
			toast.update(existingId, { ...settings, render: <Toast>{message}</Toast> });
		} else {
			const newToastId = toast(<Toast>{message}</Toast>, settings);
			// only record instance of toast if the value of error is an object
			typeof error === 'object' && pastToasts.set(error, newToastId);
		}
	} else {
		toast(<Toast>{message}</Toast>, settings);
	}
}

export async function cancelToast(error: Error) {
	await sleep(1); // hack because of that above hack
	const existingId = pastToasts.get(error);
	if (existingId) {
		toast.update(existingId, {style: {display: 'none'}}); // prevents blip where toast animates in and out
		toast.dismiss(existingId);
	}
}
/**
 * @param  {Error} error error object to eval for the message
 * @param  {string} object='a data request' the data object being retrieved (as a noun)
 */
export function createMessageForError(error: unknown, object = '') {
	if (!!error && typeof error === 'object') {
		const response =
			('response' in error &&
				!!error.response &&
				typeof error.response === 'object' &&
				(error.response as AxiosResponse<unknown>)) ||
			undefined;
		const config =
			(response &&
				'config' in response &&
				!!response.config &&
				typeof response.config === 'object' &&
				(response.config as AxiosRequestConfig)) ||
			undefined;

		if (response?.status === 404 && config?.method === 'get') {
			return '';
		}
		object =
			object ||
			config?.description ||
			(config?.method !== 'get' ? 'a data submission' : 'a data request');
		const code = response?.status || -1;
		switch (code) {
			case 400:
			case 404:
			case 405:
			case 409:
			case 501:
				return `Server could not complete ${object} because of an error in the request. Please retry, or contact site support for assistance.`;
			case 403:
				return `You do not have sufficient access for ${object}. Contact site support for assistance.`;
			case 401:
				return '';
			case 418:
				return 'The server claims to be a teapot. Contact site support for assistance.';
			case 502:
			case 504:
				return `Networking issues have caused ${object} to fail. Try reloading, or contact site support for assistance.`;
			case 503:
				return 'Servers are not available at the moment. Try again later or contact site support for assistance.';
			default:
				if (code >= 500) {
					return `There was an unexpected server error that caused ${object} to fail. Try reloading, or contact site support for assistance.`;
				}
				if (code >= 411) {
					return `Server could not complete ${object} because of an error in the request. Please retry, or contact site support for assistance.`;
				}
		}

	}
	// errors without a response
	if (!global.navigator.onLine) {
		return 'It seems you are not online or the server is unreachable. Please check your connection and try again.';
	}

	// catch-all
	return 'There was an unexpected error. Please contact site support for assistance.';
}

interface Props {
	children: ReactElement | string;
	closeToast?: () => void; 
	toastProps?: ToastOptions;
}
function Toast({children, closeToast, toastProps}: Props) {
	const variant = toastProps?.type && {
		[toast.TYPE.WARNING]: 'toast-warning',
		[toast.TYPE.INFO]: 'toast-info',
		[toast.TYPE.ERROR]: 'toast-danger',
		[toast.TYPE.SUCCESS]: 'toast-success',
	}[toastProps?.type];
	return (
		<div className={`toast ${variant || 'toast-info'}`} role="alert" aria-live="assertive" aria-atomic="true" style={{opacity:1}}>
			<div className="toast-body">
				<button type="button" className="ml-2 mb-2 close toast-close" data-dismiss="toast" aria-label="Close" onClick={closeToast}>
					<span aria-hidden="true">×</span>
				</button>
				{children}
			</div>
		</div>
	) as ReactElement;
}
