import React, { useEffect, useState, VFC } from 'react';
import { useQuery } from 'react-query';
import AssociatePackoutFacilityModal from '../../../components/modals/AssociatePackoutFacilityModal/AssociatePackoutFacilityModal';
import { getPackoutFacilitiesList } from '../../../services/ordersService';

interface AssociatePackoutFacilityModalAdapterProps {
    show: boolean;
    onChange: (returnValue: number) => void;
    onClose: () =>  void;
}
const AssociatePackoutFacilityModalAdapter: VFC<AssociatePackoutFacilityModalAdapterProps> = ({ show, onChange, onClose }) => {
	const packoutFacilitiesQuery = useQuery('getPackoutFacilitiesList', () => getPackoutFacilitiesList('company'), { enabled: show });
	const [key, setKey] = useState<number>(0);

	useEffect(() => {
		if (show) {
			setKey(key+1);
		}
	}, 
        [show],
	);
	return (!!key && <AssociatePackoutFacilityModal 
        key={key}    
        show={show}
        isLoading={packoutFacilitiesQuery.isLoading}
        closeModal={() => onClose()}
        linkedFacilities={packoutFacilitiesQuery.data?.data}
        
        loadPackoutFacilities={(returnValue: number) => onChange(returnValue)}
        singleSelect={true}
    />) || null;
};

export default AssociatePackoutFacilityModalAdapter;