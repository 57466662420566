import * as yup from 'yup';

import { orderConst } from '../constants/orderConstants';
import { permConst } from '../constants/permConst';
import { OrderDetailData, PhysicalOrderIndexData } from '../services/ordersService';
import { formatCurrency } from './currencyUtils';
import { isInvalid } from './dataUtils';
import { formatDate, parseDateString } from './dateUtils';
import { isValidPhoneNumber } from './stringUtils';
import { isAuthorized } from './userUtils';

/*  Possible status values.  See LDBR-171 and LDBR-545
 *
 *  a) DRAFT
 *  b) SUBMITTED_FOR_REVIEW
 *  c) SUBMITTED_FOR_APPROVAL
 *  d) ORDER_APPROVED
 *  e) ORDER_PENDING
 *  f) AWAITING_PAYMENT
 *  g) IN_MANUFACTURING 
 *  g2) PRODUCTION_PLANNING
 *  h) COMPLETE
 *  i) REJECTED
 *  j) CANCELLED
 *  k) ERROR
 *
 *  For unauthorized users, per LDBR-545 & 5539, these statuses should display as
 *  ORDER_PENDING:
 *  ERROR, ORDER_APPROVED
 *  SUBMITTED_FOR_REVIEW:
 *  SUBMITTED_FOR_APPROVAL
 */
// For use with sales flow version 1. See mapToDisplayedStatus for version 2.
export function displayOrderStatus(orderStatus: string, isAuthorized: boolean) {
	if (!isAuthorized) {
		switch (orderStatus) {
			case orderConst.STATUS.SUBMITTED_FOR_APPROVAL:
				return orderConst.STATUS.SUBMITTED_FOR_REVIEW;
			case orderConst.STATUS.ERROR:
			case orderConst.STATUS.ORDER_APPROVED:
				return orderConst.STATUS.ORDER_PENDING;
			default:
				return orderStatus;
		}
	}
	return orderStatus;
}

export function checkValidProductQuantity(product: {
	quantity: number;
	minimumOrderQty: number;
	orderIncrementQty: number;
}) {
	const validQuantity = /^[0-9]*$/.test(String(product.quantity));
	const quantityGreaterThanZero = product.quantity > 0;
	const lessThanMinimumOrderQuantity = product.quantity < product.minimumOrderQty;

	if (lessThanMinimumOrderQuantity || !validQuantity || !quantityGreaterThanZero) {
		return false;
	}

	// Formula = Minimum Quantity + N * Case Quantity (LDBR-2449)
	let quantityMinusMinimum = product.quantity - product.minimumOrderQty;

	if (product.orderIncrementQty > 0) {
		const hasRightOrderIncrementQuantity =
			quantityMinusMinimum % product.orderIncrementQty === 0;
		if (!hasRightOrderIncrementQuantity) return false;
	}
	return true;
}

let schema: yup.AnyObjectSchema, consigneeSchema: yup.AnyObjectSchema;

export function getSchemas() {
	schema =
		schema ||
		yup.object().shape({
			platform: yup
				.string()
				.test(
					'platform_test',
					'A valid platform needs to be selected',
					(value) => !!(value !== 'default'),
				),
			publisherPO: yup
				.string()
				.trim()
				.required('Publisher PO # is required')
				.test(
					'platform_test',
					'Publisher PO # has already been used',
					(value, context: any) =>
						!context.options?.validatePublisherPO ||
						context.options?.validatePublisherPO(value),
				),
			freightForwarder: yup.string()
				.required('Freight Forwarder is required'),
			customsEntryPort: yup.string()
				.required('Customs Entry Port is required'),
			shipTo: yup.string()
				.required('Packout Facility is required'),
			destinationContact: yup.string()
				.trim()
				.required('Destination Contact is required'),
			destinationPhone: yup.string()
				.trim()
				.required('Destination Phone is required'),
		});

	consigneeSchema =
		consigneeSchema ||
		schema.concat(
			yup.object().shape({
				japaneseContactName: yup.string()
					.trim()
					.nullable()
					.required('Japanese Contact Name is required'),
				japaneseEmail: yup.string()
					.trim()
					.nullable()
					.email('Japanese Email must be a valid email address')
					.required('Japanese Email is required'),
				japanesePhoneNumber: yup.string()
					.trim()
					.nullable()
					.required('Japanese Phone Number is required')
					.test('japanese_phone_test', 'Japanese Phone Number is not valid', (value) =>
						isValidPhoneNumber(value),
					),
				consigneeCompanyName: yup.string()
					.trim()
					.nullable()
					.required('Consignee Company Name is required'),
				consigneeAddress: yup.string()
					.trim()
					.nullable()
					.required('Consignee Address is required'),
				consigneeCity: yup.string()
					.trim()
					.nullable()
					.required('Consignee City is required'),
				consigneeState: yup.string()
					.trim()
					.nullable()
					.required('Consignee State is required'),
				consigneeZip: yup.string()
					.trim()
					.nullable()
					.required('Consignee Zip is required'),
				serviceLevel: yup.string()
					.trim()
					.nullable()
					.required('Service Level is required'),
				carrierAccountNumber: yup.string()
					.trim()
					.nullable()
					.required('Carrier Account Number is required'),
			}),
		);

	return [schema, consigneeSchema];
}

export const makeInvoiceFileName = (invoiceDate: string, salesOrderNumber: string) =>
	'Proforma  ' +
	formatDate(parseDateString(invoiceDate), 'YYYYMMDD') +
	' ' +
	salesOrderNumber +
	'.pdf';

export const getTotalFromOrder = (orderData: Pick<OrderDetailData, 'order_details'>) => {
	return formatCurrency(
		Number(
			orderData?.order_details?.reduce<number>((reduction, line) => {
				const quantity =
					typeof line.quantity !== 'object' ? line.quantity : Number(line.quantity?.value);
				return quantity * Number(line?.unit_price) + reduction;
			}, 0),
		),
	);
};	

// For use with sales flow version 2. See displayOrderStatus for version 1.
export const mapToDisplayedStatus = (
	orderData?: Partial<
		Pick<OrderDetailData, 'order_errors' | 'order_status'> &
			Pick<PhysicalOrderIndexData, 'has_error' | 'order_status'>
	>,
	userProfile?: UserProfile,
) => {
	if (!orderData || !userProfile) {
		return;
	}
	if (!!orderData.order_errors?.length || orderData?.has_error || !orderData.order_status) {
		return orderConst.STATUS.ERROR;
	}
	if (!isAuthorized(userProfile.permissions, [permConst.PHYSICAL_ORDER.VIEW.ALL])) {
		switch (orderData.order_status) {
			case orderConst.STATUS.SUBMITTED_FOR_APPROVAL:
				return orderConst.STATUS.SUBMITTED_FOR_REVIEW;
			case orderConst.STATUS.ERROR:
			case orderConst.STATUS.ORDER_APPROVED:
				return orderConst.STATUS.ORDER_PENDING;
		}
	}
	return orderData.order_status;
};

export const doesOrderHaveInvalidValues = (orderData?: Partial<OrderDetailData>) => {
	const orderHasInvalidValues = !!orderData && [
		...Object.values(orderData), 
		...orderData.order_details?.map((details) => Object.values(details)).flat() || [],
	].find((value) => !!isInvalid(value));
	return orderHasInvalidValues;
};
