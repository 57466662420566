import React, { Component } from 'react';
import { Alert, Col, FormControl, Row } from 'react-bootstrap';

import BaseModal from '../../../../components/BaseModal/BaseModal';
import { permConst } from '../../../../constants/permConst';
import { transferRightsConstants } from '../../../../constants/transferRightsConstants';
import { postProductRightsTransferRequests } from '../../../../services/productTransfersService';
import { toasterNotify, createMessageForError } from '../../../../utils/toaster';
import { isAuthorized } from '../../../../utils/userUtils';


export default class RejectDistributionTransferModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			display: true,
			rejectComment: ''
		};
	}

	getTransferStatus() {
		const { userProfile } = this.props;

		const userReviewer = isAuthorized(userProfile.permissions, [
			permConst.PRODUCT.RIGHT_TRANSFER.REVIEW_REJECT.ALL_DISTRIBUTION
		]);

		// Order Reviewer and Business Developer are the only two roles that can reject a distribution rights transfer.
		return userReviewer
			? transferRightsConstants.REQUEST_STATUS.REJECTED_BY_REVIEWER
			: transferRightsConstants.REQUEST_STATUS.REJECTED_BY_APPROVER;
	}

	rejectTransfer() {
		const {
			closeModal,
			updateTransferStatus,
			productRightsTransferId
		} = this.props;

		const { rejectComment } = this.state;

		const payload = {
			transfer_status: this.getTransferStatus(),
			comment: rejectComment
		};

		postProductRightsTransferRequests(
			productRightsTransferId,
			payload
		)
			.then((response) => {
				updateTransferStatus(payload.transfer_status);
				toasterNotify('Product rights transfer rejected', 'success');
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'rejecting a distribution rights transfer', error),
					'error',
					error
				);
			})
			.finally(() => {
				closeModal();
			});
	}

	render() {
		const { closeModal } = this.props;
		const { display, rejectComment } = this.state;

		return (
			<BaseModal show={display} onCancel={closeModal}>
				<BaseModal.Title>Reject Transfer</BaseModal.Title>
				<Alert variant="danger">
					This action will reject the distribution rights
					assignment request and will notify the company admin
					of the initiating publisher.{' '}
				</Alert>
				<Row>
					<Col sm={3}>
						<strong>Comments:</strong>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<FormControl
							as="textarea"
							rows="6"
							value={rejectComment}
							onChange={(e) => {
								this.setState({
									rejectComment: e.target.value
								});
							}}
						/>
					</Col>
				</Row>
				<BaseModal.Submit
					variant="danger"
					disabled={rejectComment === ''}
					onClick={() => {
						this.rejectTransfer();
					}}
				>
					Reject Transfer
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}
