import api from './baseService';

export interface CategoryModel {
	customer_feedback_category_id: number;
	category_name: string;
	sort_order: number;
}

export const getCustomerFeedbackCategories = () =>
	api().get<CategoryModel[]>('/customer_feedback/categories');

export const postCustomerFeedbackComment = (payload: Record<string, any>) =>
	api().post('/customer_feedback/comments', payload);
