import React from 'react';
import { Spinner } from 'react-bootstrap';

import './Loading.css';


class LoadingText extends React.Component {
	render() {
		if (this.props.inline) {
			return (
				<div className={'LoadingText__inline ' + (this.props.className || '')} style={this.props.style} aria-label="loading">
					<Spinner
						as="span"
						size="sm"
						variant="primary"
						animation="border"
						role="status"
						aria-hidden="true"
					/>
				</div>
			);
		}
		return (
			<div className={'LoadingText__block ' + (this.props.className || '')} style={this.props.style} aria-label="loading">
				<Spinner
					as="div"
					size="custom"
					variant="primary"
					animation="border"
					role="status"
					aria-hidden="true"
				/>
			</div>
		);
	}
}

export default LoadingText;
