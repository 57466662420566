import React, { useEffect, useState } from 'react';
import pick from 'lodash/pick';
import { Button, Col, Row } from 'react-bootstrap';

import Forms from '../../../components/Forms/Forms';
import LoadingText from '../../../components/Loading/LoadingText';
import SimpleModal from '../../../components/modals/SimpleModal/SimpleModal';
import Page from '../../../components/Page/Page';
import { orderConst } from '../../../constants/orderConstants';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import Title from '../../../components/Title/Title';
import { numberWithCommas } from '../../../utils/currencyUtils';
import { getCommentByType } from '../../../utils/digitalCodesUtils';
import { now } from '../../../utils/dateUtils';
import { usePurposeQuery } from '../DigitalCodesOrderCreate.helper';
import OrderCreateMenu from './OrderCreateMenu';
import FAIcon from '../../../components/FAIcon/FAIcon';


const PURPOSES_MODAL = 'PURPOSES_MODAL';

const Step1OrderInfo = ({
	orderData,
	onSave,
	onNext,
	onCancel,
	onDelete,
	isSubmitting,
	isSaving,
	minimumDays,
	validate,
	showAllErrors,
}) => {
	const getInitialValues = () => {
		if (orderData) {
			const initial = pick(orderData, [
				'purposeID',
				'purposeDescription',
				'publisherPO',
				'reqDeliveryDate',
				'reqActivationDate',
			]);
			initial.orderComments = getCommentByType(orderData.order_comments, orderConst.COMMENT.PUBLISHER);
			return initial;
		}
		return {
			purposeID: purposeList[0]?.digital_orders_purpose_id
		};
	};
	const [formValues, setFormValues] = useState({});
	const [openModal, setOpenModal] = useState();
	const formIsValid = true;

	const purposeQuery = usePurposeQuery();
	const purposeList = purposeQuery.data?.data
		?.filter((x) => x.active)
		.sort((a, b) => a.sort_order - b.sort_order);

	const earliestDate = now().add(minimumDays, 'days').startOf('day').toDate();
	const filterDate = (date) => {
		if (date.getDay() > 0 && date.getDay() < 6) {
			return date.valueOf() >= earliestDate.valueOf();
		}
		return false;
	};

	const validationErrors = validate(formValues);

	useEffect(() => {
		if (!purposeQuery.isLoading) {
			setFormValues(getInitialValues);
		}
	}, [purposeQuery.isLoading]);

	return (
		<>
			<Title
				subtitle="Step 1: Order Info"
				button={<OrderCreateMenu showSave={true} onSave={() => onSave(formValues)} onDelete={onDelete} />}
			>
				Create New Digital Code Order
			</Title>
			<Page.FullPageCol>
				<SectionTitle>Order Details</SectionTitle>
				<Row>
					<Col sm={8}>
						<Forms
							values={formValues}
							onChange={(values) => setFormValues(values)}
							isSubmitting={isSubmitting || isSaving}
							validationErrors={validationErrors}
							showAllErrors={showAllErrors}
						>
							<Forms.Select id="purposeID">
								<Forms.Heading>Purpose</Forms.Heading>
								{(purposeList &&
									purposeList.map((item) => (
										<Forms.Option
											key={'key-' + item.value}
											value={item.digital_orders_purpose_id}
										>
											{item.purpose}
										</Forms.Option>
									))) ||
									null}
								<Forms.Postscript>
									<Button
										variant="link"
										onClick={() => setOpenModal({ type: PURPOSES_MODAL })}
									>
										See Purpose Details
									</Button>
								</Forms.Postscript>
							</Forms.Select>

							<Forms.TextArea id="purposeDescription" rows={2} maxLength={500}>
								<Forms.Heading>Description of Purpose</Forms.Heading>
								<Forms.Help>Optional field. 500 character limit.</Forms.Help>
							</Forms.TextArea>
							<Forms.Text id="publisherPO" maxLength={50}>
								<Forms.Heading>Publisher PO #</Forms.Heading>
								<Forms.Help>50 character limit.</Forms.Help>
							</Forms.Text>
							<Forms.DateSelect
								id="reqDeliveryDate"
								filterDate={filterDate}
								minDate={earliestDate}
							>
								<Forms.Heading>Requested Delivery Date</Forms.Heading>
							</Forms.DateSelect>
							<Forms.DateSelect
								id="reqActivationDate"
								filterDate={filterDate}
								minDate={earliestDate}
							>
								<Forms.Heading>Requested Activation Date</Forms.Heading>
								<Forms.Help>
									Note: codes will be delivered during normal business day.
								</Forms.Help>
							</Forms.DateSelect>
							<Forms.TextArea id="orderComments" rows={2} maxLength={500}>
								<Forms.Heading>Order Comments</Forms.Heading>
								<Forms.Help>Optional field. 500 character limit.</Forms.Help>
							</Forms.TextArea>
						</Forms>
					</Col>
				</Row>

				<div className="btn-container">
					<div className="float-left">
						<Button variant="link" onClick={() => onCancel()} disabled={isSubmitting || isSaving}>
							Cancel
						</Button>
					</div>
					<div className="float-right d-flex">
						{isSaving && <LoadingText inline className="m-a" />}
						<Button
							className="ml-3"
							variant="outline-secondary"
							type="button"
							onClick={() => onSave(formValues)}
							disabled={!formIsValid || isSubmitting || isSaving}
						>
							Save
						</Button>
						<Button
							className="ml-3"
							variant="primary"
							onClick={() => onNext(formValues)}
							disabled={!formIsValid || isSubmitting || isSaving}
						>
							Next (Items) <FAIcon name="chevron-right" />
						</Button>
					</div>
				</div>
			</Page.FullPageCol>
			<SimpleModal
				show={openModal && openModal.type === PURPOSES_MODAL}
				size="lg"
				title="Purpose Description"
				closeModal={() => setOpenModal(null)}
			>
				{purposeList &&
					purposeList.map((purpose) => {
						const priceType = purpose.price_type;
						return (
							<div key={purpose.digital_orders_purpose_id} className="mb-4">
								<h4>{purpose.purpose}</h4>
								<div>
									<p> {purpose.purpose_description} </p>
									<Row>
										<Col xs={4} md={3}>
											Minimum order:{' '}
											{numberWithCommas(purpose.min_num_of_codes)}
										</Col>
										<Col xs={4} md={4}>
											Maximum order:{' '}
											{numberWithCommas(purpose.max_num_of_codes)}
										</Col>
										<Col xs={4} md={4}>
											<p>
												{' '}
												{(purpose.code_batch_increments > 1 &&
													`Code quantity in batches of ${numberWithCommas(
														purpose.code_batch_increments,
													)}`) || <span>&nbsp;</span>}{' '}
											</p>
										</Col>
									</Row>
									<Row className="show-grid">
										<Col xs={12} offset={0}>
											<p> Price type: {priceType} </p>
										</Col>
									</Row>
								</div>
							</div>
						);
					})}
			</SimpleModal>
		</>
	);
};

export default Step1OrderInfo;
