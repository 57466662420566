import React, { ChangeEventHandler, ReactElement } from 'react';

const searchBoxLength = 40;

interface SearchableTextBoxProps {
	searchFieldChanged: ChangeEventHandler<HTMLInputElement>,
	placeHolder?: string | undefined,
	value: string | undefined | null,
}

const SearchableTextBox = (props: SearchableTextBoxProps): ReactElement => {
	const {
		searchFieldChanged,
		placeHolder,
		value,
	} = props;

	return (
		<li className="SearchableTextBox">
			<input
				type="text"
				className="form-control"
				onChange={searchFieldChanged}
				placeholder={placeHolder}
				size={searchBoxLength}
				value={value || ''}
			/>
		</li>
	);
};

export default SearchableTextBox;
