import React, { useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';

import MeatballDropdown from '../../../components/MeatballDropdown/MeatballDropdown';
import FileLink from '../../../components/FileLink/FileLink';
import Panel from '../../../components/Panel/Panel';
import { assetConst } from '../../../constants/assetConstants';
import { makeKey } from '../../../utils/componentUtils';

import './FilesUploadedPanel.css';


function determineVideo(file) {
	const videoTypes = ['mp4', 'mov', 'flv', 'wmv', 'avi'];
	return videoTypes.includes(
        file.file_name.split('.').pop().toLowerCase()
	);
}

function getBucketFromS3Key(key) {
	return (key.match(/^[a-z0-9\-.]{3,63}/) || [])[0];
}

function getLatestAssetFileList(revisions) {
	revisions = [...revisions];
	revisions.sort((a, b) => a.submitted_date > b.submitted_date ? -1 : 1);

	const selectedRevision =
        revisions.find(rev =>
        	Array.isArray(rev.files) &&
                rev.files.length > 0 &&
                ![assetConst.UPLOAD.ANNOTATION, assetConst.UPLOAD.NCL_PROOF].includes(rev.file_upload_type)
        );
	if (!selectedRevision || !selectedRevision.files) {
		return null;
	}
	const annotationRevision = revisions.find(rev =>
		rev.file_upload_type === assetConst.UPLOAD.ANNOTATION &&
            rev.submitted_date > selectedRevision.submitted_date &&
            Array.isArray(rev.files)
	);

	return selectedRevision.files.map(file => {
		const annotation = annotationRevision &&
            annotationRevision.files.find(annotatedFile =>
            	getBucketFromS3Key(annotatedFile.s3_key) === getBucketFromS3Key(file.s3_key));
		return {
			...file,
			annotation
		};
	});
}

const FilesUploadedPanel = ({
	revisions,
	isAnnotatable,
	activeRightTransfer,
	hasViewFilePermission,
	onSuccessfulFileClick,
	toggleViewer,
	eventAssetId
}) => {
	const [showAllFiles, setShowAllFiles] = useState(false);
	const fileList = useMemo(() => getLatestAssetFileList(revisions), [revisions]);
	return (
        <Panel>
            <Panel.Heading>
                <Panel.Title>Uploaded Assets</Panel.Title>
            </Panel.Heading>
            <Panel.Body>
                <ul className="FilesUploadedPanel__asset-details">
                    {
                        fileList ? (
                            fileList.slice(0, (showAllFiles ? fileList.length : 5)).map((file, i) => {
                                return (
                                    <li className="FilesUploadedPanel__asset-item d-flex align-items-center" key={makeKey('tr-', i)}>
                                        {hasViewFilePermission ? (<>
                                            <div className="FilesUploadedPanel__asset-item-interior">
                                                <FileLink
                                                    className="viewOriginalLink"
                                                    fileName={file.file_name}
                                                    fileId={file.file_id}
                                                    status={file.status}
                                                    callback={onSuccessfulFileClick}
                                                    saved={determineVideo(file)}
                                                    eventAssetId={eventAssetId}
                                                >
                                                    {file.file_name}
                                                </FileLink>
                                            </div>
                                            { (isAnnotatable || file.annotation) &&
                                                <div className="FilesUploadedPanel__asset-item-actions ml-auto">
                                                    <MeatballDropdown id="asset-item-context-menu">
                                                        {isAnnotatable && (
                                                            <MeatballDropdown.Item
                                                                eventKey="1"
                                                                className="annotateButton"
                                                                onClick={() => toggleViewer(file.s3_key)}
                                                                disabled={activeRightTransfer}
                                                            >
                                                                Annotate
                                                            </MeatballDropdown.Item>
                                                        )}
                                                        { file.annotation && (
                                                            <FileLink
                                                                eventKey="2"
                                                                className="viewAnnotationButton"
                                                                fileName={file.annotation.file_name}
                                                                fileId={file.annotation.file_id}
                                                                status={file.annotation.status}
                                                                callback={onSuccessfulFileClick}
                                                                saved={determineVideo(file.annotation)}
				                                                eventAssetId={eventAssetId}
                                                                as={MeatballDropdown.Item}
                                                            >
                                                                View Annotation
                                                            </FileLink>
                                                        ) }
                                                    </MeatballDropdown>
                                                </div>
                                            }
                                         </>) : <>
                                            <div className="FilesUploadedPanel__asset-item-interior">{file.file_name}</div>
                                        </>}
                                    </li>
                                );
                            })
                        ) : (
                            <div style={{ textAlign: 'center' }}>
                                <span>No files submitted</span>
                            </div>
                        )
                    }
                </ul>
                { showAllFiles !== true && fileList && fileList.length > 5 &&
                    <Button className="FilesUploadedPanel__more-assets-button" variant="link" onClick={e => setShowAllFiles(true)}>
                        Show {fileList.length - 5} more {(fileList.length > 6) ? 'files' : 'file'}...
                    </Button>
                }
            </Panel.Body>
        </Panel>
	);
};

export default FilesUploadedPanel;
