import React, { isValidElement, PropsWithChildren, ReactElement, VFC } from 'react';
import { ButtonToolbar, Col, Row } from 'react-bootstrap';

import './title.css';

interface TitleProps {
	title?: ReactElement | string;
	button?: ReactElement;
	subtitle?: ReactElement | string;
	status?: ReactElement | { text: string; class: string };
}
const Title: VFC<PropsWithChildren<TitleProps>> = ({
	children,
	title,
	button,
	subtitle,
	status,
}: PropsWithChildren<TitleProps>): ReactElement => {
	if (status && !isValidElement(status) && 'text' in status && 'class' in status) {
		status = <span className={`status-mini ${status.class}`}>{status.text}</span>;
	} else if (!isValidElement(status)) {
		status = undefined;
	}
	return (
		<Row className="Title">
			<Col sm={12}>
				<div className="Title__container d-flex justify-content-start">
					<div className="d-flex flex-wrap">
						<h1 className="Title__main-title my-0 mr-3 mt-auto">{children || title}</h1>
						{subtitle && <h2 className="Title__subtitle my-0 mt-2 mr-3">{subtitle}</h2>}
						<div className="Title__status-container mr-3">{status}</div>
					</div>
					<ButtonToolbar className="Title__buttons mt-auto ml-auto flex-nowrap">
						{button}
					</ButtonToolbar>
				</div>
			</Col>
		</Row>
	);
};

export default Title;
