import React, {
	PropsWithChildren,
	ReactElement,
	ReactNode,
	useContext,
	useState,
	VFC,
} from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';

import HelpBlock from '../HelpBlock/HelpBlock';
import LoadingText from '../Loading/LoadingText';
import Loading from '../Loading/Loading';
import { objectIsSubset } from '../../utils/dataUtils';

import './BaseModal.css';

interface BaseModalContextProps {
	isSubmitting: boolean;
}

interface SubcomponentProps {
	onClick?: () => void;
	variant?:
		| 'primary'
		| 'secondary'
		| 'outline-primary'
		| 'outline-secondary'
		| 'danger'
		| 'warning'
		| 'info';
	children?: ReactNode;
	disabled?: boolean;
	className?: string;
	href?: string;
}

interface BaseModalProps {
	show: boolean;
	size?: 'sm' | 'lg' | 'xl';
	children: ReactNode;
	closeText?: string;
	onCancel?(): void;
	isLoading?: boolean;
	isSubmitting?: boolean;
	fault?: unknown;
}

type BaseModalSubcomponents = {
	Title: typeof Title;
	Submit: typeof Submit;
	Cancel: typeof Cancel;
	SubmissionStatus: typeof SubmissionStatus;
};

const BaseModalContext = React.createContext<BaseModalContextProps>({ isSubmitting: false });

const Title: VFC<PropsWithChildren<unknown>> = ({ children }: PropsWithChildren<{}>): ReactElement => (
	<Modal.Title>{children}</Modal.Title>
);

const Submit: VFC<PropsWithChildren<SubcomponentProps>> = ({
	onClick,
	variant,
	children,
	disabled = false,
	className,
	href,
}) => {
	const { isSubmitting } = useContext<BaseModalContextProps>(BaseModalContext);
	return (
		<Button
			href={href}
			className={className}
			variant={variant || 'primary'}
			onClick={onClick}
			disabled={isSubmitting || disabled}
		>
			{children || 'Submit'}
		</Button>
	);
};

const Cancel: VFC<PropsWithChildren<SubcomponentProps>> = ({
	onClick,
	variant,
	children,
	disabled,
	className,
	href,
}): ReactElement => {
	const { isSubmitting } = useContext<BaseModalContextProps>(BaseModalContext);
	return (
		<Button
			href={href}
			className={className}
			variant={variant || 'outline-secondary'}
			onClick={onClick}
			disabled={isSubmitting || disabled}
		>
			{children || 'Cancel'}
		</Button>
	);
};

interface PulsingDotProps {
	out?: boolean;
}
const PulsingDot: VFC<PulsingDotProps> = ({ out }: PulsingDotProps): ReactElement => (
	<span
		style={{
			transition: 'opacity 200ms',
			color: 'var(--nin-color-red)',
			opacity: out ? '0' : '1',
		}}
		className="mr-1"
	>
		&#x2B24;
	</span>
);

interface SubmissionStatusProps {
	children: ReactNode;
	lastUpdated?: number;
}
const SubmissionStatus: VFC<PropsWithChildren<SubmissionStatusProps>> = ({
	children,
	lastUpdated,
}: PropsWithChildren<SubmissionStatusProps>): ReactElement => {
	const [out, setOut] = useState<number | undefined>();
	const [updated, setUpdated] = useState<number | undefined | Date>();

	// make the dot blink every time lastUpdated is changed
	// but don't make it blink too often or it gets janky
	if (updated !== lastUpdated) {
		setUpdated(lastUpdated);
		if (out) {
			clearTimeout(out);
		}
		setOut(
			setTimeout((prevState) => {
				setOut(undefined);
			}, 210),
		);
	}

	return lastUpdated ? (
		<div>
			<HelpBlock>
				{lastUpdated && <PulsingDot out={!!out} />} {children}
			</HelpBlock>
		</div>
	) : (
		<div />
	);
};
const BaseModal: VFC<BaseModalProps> & BaseModalSubcomponents = ({
	show = false,
	size,
	children,
	closeText = 'Close',
	onCancel,
	isLoading = false,
	isSubmitting = false,
	fault,
}: BaseModalProps): ReactElement => {
	const findChild = (
		children: ReactNode,
		component: VFC<any>,
		props?: Record<string, any>,
	) =>
		React.Children.toArray(children).find(
			(child) =>
				child instanceof Object &&
				'type' in child &&
				child.type === component &&
				(!props || objectIsSubset(child.props, props)),
		);
	const filterChildren = (children: ReactNode, component: any, props?: Record<string, any>) =>
		React.Children.toArray(children).filter(
			(child) =>
				child instanceof Object &&
				'type' in child &&
				child.type === component &&
				(!props || objectIsSubset(child.props, props)),
		);
	const findBody = (children: ReactNode) =>
		React.Children.toArray(children).filter(
			// for some reason the ts linter is having problems remembering child is not a string if
			// there is a type check earlier in this function
			(child: any) => !child.type || ![Title, Submit, Cancel, SubmissionStatus].includes(child.type),
		);

	const closeHandler = () => {
		if (!isSubmitting) {
			onCancel && onCancel();
		}
	};
	const titleElement = findChild(children, Title);
	const submissionStatusElement = findChild(children, SubmissionStatus);

	return (
		<BaseModalContext.Provider value={{ isSubmitting }}>
			<Modal show={show} size={size} backdrop="static" onHide={closeHandler}>
				{titleElement && (
					<Modal.Header closeButton={!!onCancel}>{titleElement}</Modal.Header>
				)}
				<Modal.Body>
					{fault ? (
						<Alert variant="danger">
							There was an error while loading data necessary for this view. Reopen
							this view to try again.
						</Alert>
					) : isLoading ? (
						<Loading />
					) : (
						findBody(children)
					)}
				</Modal.Body>
				<Modal.Footer className="flex-nowrap">
					{!isLoading && !!onCancel && !findChild(children, Cancel) && (
						<Button
							variant="outline-secondary"
							className="BaseModal__close-button"
							onClick={closeHandler}
							aria-label="Close"
						>
							{closeText}
						</Button>
					)}
					{submissionStatusElement}
					{isSubmitting && (
						<>
							<LoadingText inline />
						</>
					)}
					{!isLoading && !fault && filterChildren(children, Submit)}
					{!isLoading && !fault && findChild(children, Cancel)}
				</Modal.Footer>
			</Modal>
		</BaseModalContext.Provider>
	);
};

BaseModal.Title = Title;
BaseModal.Submit = Submit;
BaseModal.Cancel = Cancel;
BaseModal.SubmissionStatus = SubmissionStatus;

export default BaseModal;
