import React, { ReactElement, useEffect, useState, VFC } from 'react';

import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import { permConst } from '../../../constants/permConst';
import { useUserProfile } from '../../../hooks/reduxHooks';
import { OrderDetailData, putOrderComments } from '../../../services/ordersService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { isAuthorized } from '../../../utils/userUtils';


interface PhysicalOrderCommentModalProps {
	show: boolean;
    orderHeaderId: string;
    orderComments?: OrderDetailData['order_comments'];
	onClose: () => void;
	onChange: () => void;
}
const PhysicalOrderCommentModal: VFC<PhysicalOrderCommentModalProps> = ({
	show,
	orderHeaderId,
	orderComments = {},
	onClose,
	onChange,
}: PhysicalOrderCommentModalProps): ReactElement => {
	const userProfile = useUserProfile();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [formValues, setFormValues] = useState<{'NOTES_TO_NCL'?: string; 'REVIEWER'?: string; }>({});
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);

	const canEditNotesToNCL = isAuthorized(userProfile.permissions, [
		permConst.PHYSICAL_ORDER.REVIEW.ALL,
		permConst.PHYSICAL_ORDER.APPROVE.ALL,
	]);
	const canEditReviewerNotes = isAuthorized(userProfile.permissions, [
		permConst.PHYSICAL_ORDER.REVIEW.ALL,
	]);

	useEffect(() => {
		if (show) {
			setFormValues(
				Object.keys(orderComments).reduce((reduction: typeof formValues, commentType) => {
					if (
						(canEditNotesToNCL && commentType === 'NOTES_TO_NCL') ||
						(canEditReviewerNotes && commentType === 'REVIEWER')
					) {
						reduction[commentType] = orderComments[commentType]?.comment;
					}
					return reduction;
				}, {}),
			);
			setIsSubmitting(false);
			setShowAllErrors(false);
		}
	}, [show]);

	const submitComment = async () => {
		setIsSubmitting(true);
		const payload = {
			...(canEditNotesToNCL ? { 'NOTES_TO_NCL': formValues['NOTES_TO_NCL'] || null } : null),
			...(canEditReviewerNotes ? { 'REVIEWER': formValues['REVIEWER'] || null } : null)
		};
		try {
			await putOrderComments(orderHeaderId, payload);
			toasterNotify('Comment edits have been submitted', 'info');
			onChange();
			onClose();
		} catch (error) {
			toasterNotify(
				createMessageForError(error as Error, 'submitting the comment'),
				'error',
				error as Error,
			);
			setIsSubmitting(false);
		}
	};

	return (
		<BaseModal show={show} onCancel={onClose} isSubmitting={isSubmitting}>
			<BaseModal.Title>Edit Comments</BaseModal.Title>
			<Forms
				onChange={(newValues) => setFormValues(newValues)}
				values={formValues}
				validationErrors={{}}
				showAllErrors={showAllErrors}
				vertical
			>
				{canEditReviewerNotes && (
					<Forms.TextArea id="REVIEWER" rows={4} maxLength={2000}>
						<Forms.Heading>Comments to PO Approver</Forms.Heading>
					</Forms.TextArea>
				)}
				{canEditNotesToNCL && (
					<Forms.TextArea id="NOTES_TO_NCL" rows={4} maxLength={335}>
						<Forms.Heading>Comments to NCL</Forms.Heading>
					</Forms.TextArea>
				)}
			</Forms>
			<BaseModal.Submit onClick={() => submitComment()}>
				Submit
			</BaseModal.Submit>
		</BaseModal>
	);
};
export default PhysicalOrderCommentModal;
