import React, { Component } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import FAIcon from '../../components/FAIcon/FAIcon';
import Loading from '../../components/Loading/Loading';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import { permConst } from '../../constants/permConst';
import { getAllCompanies } from '../../services/companiesService';
import {
	createPackoutFacility,
	deletePackoutFacilityAssociation,
	getCreateOrderPageResources,
	getPackoutFacilitiesList,
	updatePackoutFacility,
} from '../../services/ordersService';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { isAuthorized } from '../../utils/userUtils';
import FacilitiesTableExternal from './FacilitiesTableExternal';
import FacilitiesTableInternal from './FacilitiesTableInternal';
import EditFacilityModal from './modals/EditFacilityModal';
import AssociatePackoutFacilityModal from '../../components/modals/AssociatePackoutFacilityModal/AssociatePackoutFacilityModal';
import TableRow from './TableRow';

import './ManagePackoutFacilities.css';



function mapStateToProps(state) {
	return {
		userProfile: state.authReducer.userProfile
	};
}

export class ManagePackoutFacilities extends Component {
	constructor(props) {
		super(props);

		this.state = {
			title: 'Manage Packout Facilities',
			facilitiesList: [],
			companies: [],
			countryCodes: [],
			editFacility: {},
			showModal: false,
			isLoadingFacilities: false,
			isLoadingCompanies: false,
			isLoadingCountryCodes: false,
			isSavingFacility: false
		};

		this.toggleModal = this.toggleModal.bind(this);
		this.renderModal = this.renderModal.bind(this);
		this.loadPackoutFacilities = this.loadPackoutFacilities.bind(this);
		this.renderEditFacilityModal = this.renderEditFacilityModal.bind(this);
		this.setFacilityAssociation = this.setFacilityAssociation.bind(this);
		this.saveCreateFacility = this.saveCreateFacility.bind(this);
		this.saveEditFacility = this.saveEditFacility.bind(this);
		this.getIsModalLoading = this.getIsModalLoading.bind(this);
		this.getIsPageLoading = this.getIsPageLoading.bind(this);
	}

	getIsModalLoading() {
		return (
			this.state.isLoadingFacilities ||
			this.state.isLoadingCompanies ||
			this.state.isLoadingCountryCodes ||
			this.state.isSavingFacility
		);
	}

	getIsPageLoading() {
		return (
			this.state.isLoadingFacilities ||
			this.state.isLoadingCompanies ||
			this.state.isLoadingCountryCodes
		);
	}

	loadCompanies() {
		const { userProfile } = this.props;
		if (
			isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.VIEW.COMPANY
			])
		) {
			return;
		}

		this.setState({
			isLoadingCompanies: true
		});
		getAllCompanies()
			.then((response) => {
				this.setState({
					companies: response.data
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'loading publishers'),
					'error',
					error
				);
			})
			.finally(() => {
				this.setState({
					isLoadingCompanies: false
				});
			});
	}

	loadCountries() {
		const { userProfile } = this.props;
		if (isAuthorized(userProfile.permissions, [permConst.PRODUCT.VIEW.COMPANY])) {
			return;
		}

		this.setState({
			isLoadingCountryCodes: true
		});
		getCreateOrderPageResources()
			.then((response) => {
				const countryCodes = response.data.country_codes;
				this.setState({
					countryCodes
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving resources'),
					'error',
					error
				);
			})
			.finally(() => {
				this.setState({
					isLoadingCountryCodes: false
				});
			});
	}

	loadPackoutFacilities() {
		const { userProfile } = this.props;
		let mode = 'company';
		if (isAuthorized(userProfile.permissions, [permConst.PACKOUT_FACILITY.VIEW.ALL])) {
			mode = 'all';
		}

		this.setState({
			isLoadingFacilities: true
		});

		getPackoutFacilitiesList(mode)
			.then((response) => {
				if (response.status === 200) {
					this.setState({
						facilitiesList: response.data
					});
				}
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving facilities'),
					'error',
					error
				);
			})
			.finally(() => {
				this.setState({
					isLoadingFacilities: false
				});
			});
	}

	setFacilityAssociation(facilityId) {
		deletePackoutFacilityAssociation(facilityId)
			.then(() => {
				toasterNotify('Updated facility associations', 'success');
				this.loadPackoutFacilities();
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'updating facility associations'),
					'error',
					error
				);
			})
			.finally(() => {
				if (this.state.showModal) {
					this.setState({
						showModal: false
					});
				}
			});
	}

	saveCreateFacility(e, facility) {
		this.setState({
			isSavingFacility: true
		});
		createPackoutFacility(facility)
			.then((response) => {
				toasterNotify('Created packout facility record', 'success');
				this.loadPackoutFacilities();
				if (this.state.showModal) {
					this.setState({
						showModal: false,
						isSavingFacility: false
					});
				}
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'creating facility record'),
					'error',
					error
				);
				this.setState({
					isSavingFacility: false
				});
			});
	}

	saveEditFacility(facilityId, data) {
		data = {
			name: data.name,
			phone: data.phone,
			status: data.status,
			allowed_companies: data.allowed_companies
		};

		this.setState({
			isSavingFacility: true
		});
		updatePackoutFacility(facilityId, data)
			.then((response) => {
				toasterNotify(
						'Updated packout facility',
						'success'
				);
				this.loadPackoutFacilities();
				let newState = {
					isSavingFacility: false
				};
				if (this.state.showModal) {
					newState.showModal = false;
				}
				this.setState(newState);
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'updating packout facility'),
					'error',
					error
				);
			});
	}

	addPackoutFacility() {
		const { permissions } = this.props.userProfile;
		if (isAuthorized(permissions, [permConst.COMPANY.PACKOUT_FACILITY.EDIT.COMPANY])) {
			return (
				<Button
					variant="primary"
					onClick={() => this.toggleModal('inactiveFacilitiesModal')}
				>
					<FAIcon name="plus" className="mr-1"/>
					Associate Packout Facility
				</Button>
			);
		}
		if (isAuthorized(permissions, [permConst.PACKOUT_FACILITY.ADD.ALL])) {
			return (
				<Button
					variant="primary"
					onClick={() => this.toggleModal('newFacilityModal')}
				>
					<FAIcon name="plus" className="mr-1"/>
					Add New Facility
				</Button>
			);
		}
	}

	getFacilities(packoutFacilites, isAdmin) {
		let facilities = this.state.facilitiesList && this.state.facilitiesList;
		if (packoutFacilites !== undefined && !isAdmin) {
			facilities = packoutFacilites.filter(
				(facility) => facility.status === true
			);
		}
		return facilities;
	}

	componentDidMount() {
		this.loadPackoutFacilities();
		this.loadCountries();
		this.loadCompanies();
	}

	toggleModal(type) {
		if (this.state.showModal) {
			type = null;
		}
		this.setState({
			showModal: !this.state.showModal,
			modalType: type
		});
	}

	renderModal() {
		const isLoading = this.getIsModalLoading();
		switch (this.state.modalType) {
			case 'newFacilityModal':
				return (
					<EditFacilityModal
						isLoading={false}
						closeModal={this.toggleModal}
						onFacilityCreate={this.saveCreateFacility}
						countryCodes={this.state.countryCodes}
						companies={this.state.companies}
					/>
				);
			case 'editFacilityModal':
				return (
					<EditFacilityModal
						edit={true}
						isLoading={false}
						onFacilityUpdate={this.saveEditFacility}
						facility={this.state.editFacility}
						closeModal={this.toggleModal}
						countryCodes={this.state.countryCodes}
						companies={this.state.companies}
					/>
				);
			case 'inactiveFacilitiesModal':
				return (
					<AssociatePackoutFacilityModal
						show={true}
						isLoading={isLoading}
						closeModal={this.toggleModal}
						linkedFacilities={this.state.facilitiesList}
						loadPackoutFacilities={this.loadPackoutFacilities}
					/>
				);
			default:
				console.warn('[PackoutFacilites] Invalid Modal Type'); // eslint-disable-line no-console
		}
	}

	renderEditFacilityModal(facility) {
		if (
			isAuthorized(this.props.userProfile.permissions, [
				permConst.PACKOUT_FACILITY.EDIT.ALL,
				permConst.PACKOUT_FACILITY.VIEW.COMPANY
			])
		) {
			this.setState({
				showModal: true,
				modalType: 'editFacilityModal',
				editFacility: facility
			});
		}
	}

	render() {
		const { userProfile } = this.props;
		const isAdmin = isAuthorized(userProfile.permissions, [
			permConst.PACKOUT_FACILITY.VIEW.ALL
		]);
		const { packoutFacilites } =
			this.state.facilitiesList && this.state.facilitiesList;
		const isLoading = this.getIsPageLoading();

		let facilities = this.getFacilities(packoutFacilites, isAdmin);

		return (
			<Page>
				{!isAdmin && (
					<Alert variant="info">
						<p>The packout facilities listed below can be
						selected during the order process. To add a
						packout facility from the pre-approved list
						select Associate New Facilities.</p>
					</Alert>
				)}
				<Title
					title={this.state.title}
					button={this.addPackoutFacility()}
				/>
				{isLoading ? (
					<Loading />
				) : (
					<>
						{isAuthorized(userProfile.permissions, [
							permConst.PACKOUT_FACILITY.EDIT.ALL
						]) ? (
							<FacilitiesTableInternal
								facilities={this.state.facilitiesList}
								saveEditFacility={this.saveEditFacility}
								userProfile={userProfile}
								showEditFacilityModal={
									this.renderEditFacilityModal
								}
							/>
						) : (
							<Page.FullPageCol>
								<FacilitiesTableExternal
									admin={isAdmin}
									facilities={facilities}
									dataRowCallback={(
										facility,
										key
									) => {
										return (
											<TableRow
												key={key}
												row={facility}
												editFacility={this.renderEditFacilityModal}
												onInactivate={this.setFacilityAssociation}
												userProfile={this.props.userProfile}
											/>
										);
									}}
								/>
							</Page.FullPageCol>
						)}
					</>
				)}
				{this.state.showModal && this.state.modalType
					? this.renderModal()
					: null}
			</Page>
		);
	}
}

export default connect(mapStateToProps)(ManagePackoutFacilities);
