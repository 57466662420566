import { createBrowserHistory } from 'history';

import { clearReduxStorage, setPostLoginPath } from './reduxUtils';
import { showAuthExpired } from '../components/AuthExpiredDisplay/AuthExpiredDisplay';
import { getLoginUrl } from './serviceUtils';


let history = null;

const actionsTable = [
	{
		action: 'view-product-distribution-transfer',
		destination: '/admin/product-distribution-transfer-requests/[:id]',
	},
	{
		action: 'view-marketing-event',
		destination: '/marketing-events/[:id]',
	},
	{
		action: 'view-marketing-event-product',
		destination: '/marketing-events/products/[:id]',
	},
	{
		action: 'view-marketing-event-tasks',
		destination: '/marketing-events/[:id]?tab=tasks',
	},
	{
		action: 'view-ncl-label-queue',
		destination: '/ncl?tab=label-queue',
	},
	{
		action: 'view-packout-facilities',
		destination: '/orders/manage-packout-facilities',
	},
	{
		action: 'view-dc-order',
		destination: '/orders/digital-codes/[:id]',
	},
	{
		action: 'view-physical-order',
		destination: '/orders/physical/[:id]',
	},
	{
		action: 'view-product',
		destination: '/products/[:id]',
	},
	{
		action: 'view-declared-price-requests',
		destination: '/products/components/[:id]?tab=declared-price-requests',
	},
	{
		action: 'view-wholesale-price-requests',
		destination: '/products/components/[:id]?tab=wholesale-price-requests',
	},
	{
		action: 'view-product-asset',
		destination: '/products/[:product_id]/assets/[:id]',
	},
	{
		action: 'view-projections',
		destination: '/orders/projections',
	},
	{
		action: 'view-retail-order',
		destination: '/orders/review/[:id]',
	},
];

export const getHistory = () => {
	if (!history) {
		history = createBrowserHistory();
	}
	return history;
};

export const pathForUserAction = (action, params) => {
	let path = (actionsTable.find((item) => item.action === action) || {}).destination;
	if (path) {
		params && Object.keys(params).forEach((key) => (path = path.replace(`[:${key}]`, params[key])));
		path = path.replace(/\[:.*\]/, '');
	}
	return path;
};

export const redirectToLogin = (showAlert = true) => {
	clearReduxStorage();
	setPostLoginPath(
		history.location.pathname + (history.location.search ? history.location.search : ''),
	);

	if (showAlert) {
		showAuthExpired();
	} else {
		global.location.href = getLoginUrl();
	}
};

export const getLocation = () => global.location;
