export const assetConst = {
	CATEGORY: {
		PACKAGING: 'PACKAGING',
		MARKETING: 'MARKETING',
		MARKETING_EVENT: 'MARKETING_EVENT',
		CODE_IN_BOX: 'CODE_IN_BOX'
	} as const,
	FINAL_LABEL_FORGO_STATUS: {
		ACCEPT_FORGO: 'ACCEPT_FORGO',
		FORGO_NOT_REQUESTED: 'NOT_REQUESTED',
		REJECT_FORGO: 'REJECT_FORGO',
		REQUEST_FORGO: 'REQUEST_FORGO',
	} as const,
	STATUS: { 
		APPROVED: 'APPROVED',
		APPROVED_FOR_PRODUCTION: 'APPROVED_FOR_PRODUCTION',
		APPROVED_WITH_CHANGES: 'APPROVED_WITH_CHANGES',
		FILE_UPLOAD_FAILED: 'FILE_UPLOAD_FAILED',
		FILES_UPLOADING: 'FILES_UPLOADING',
		FINAL_LABEL_REJECTED: 'FINAL_LABEL_REJECTED',
		FINAL_LABEL_UPLOAD_FAILED: 'FINAL_LABEL_UPLOAD_FAILED',
		FINAL_LABEL_UPLOADED: 'FINAL_LABEL_UPLOADED',
		FINAL_LABEL_UPLOADING: 'FINAL_LABEL_UPLOADING',
		NCL_ACCEPTED: 'NCL_ACCEPTED',
		NCL_ACCEPTED_UPLOAD_FAILED: 'NCL_ACCEPTED_UPLOAD_FAILED',
		NCL_ACCEPTED_WITH_CHANGES: 'NCL_ACCEPTED_WITH_CHANGES',
		NCL_PROOF_UPLOAD_FAILED: 'NCL_PROOF_UPLOAD_FAILED',
		NCL_PROOF_UPLOADING: 'NCL_PROOF_UPLOADING',
		PENDING_PROOF_ACCEPT: 'PENDING_PROOF_ACCEPT',
		PENDING_REVIEW: 'PENDING_REVIEW',
		PRINT_PROOF_PENDING: 'PRINT_PROOF_PENDING',
		PRINT_PROOF_REJECTED: 'PRINT_PROOF_REJECTED',
		PRINT_PROOF_UPLOAD_FAILED: 'PRINT_PROOF_UPLOAD_FAILED',
		PRINT_PROOF_UPLOADING: 'PRINT_PROOF_UPLOADING',
		REJECTED: 'REJECTED',
		REQUIRED: 'REQUIRED',
		REVIEWED: 'REVIEWED',
		REVIEWED_WITH_CHANGES: 'REVIEWED_WITH_CHANGES',
		SUBMITTED_TO_NCL: 'SUBMITTED_TO_NCL',
		SUBMIT_TO_NCL_FILE_UPLOADING: 'SUBMIT_TO_NCL_FILE_UPLOADING',
		SUBMIT_TO_NCL_UPLOAD_FAILED: 'SUBMIT_TO_NCL_UPLOAD_FAILED',
	} as const,
	TYPE: {
		BANNER_AD: 'BANNER_AD',
		BOX_PACK_SHOT: 'BOX_PACK_SHOT',
		BUNDLE: 'BUNDLE',
		CODE_BOX: 'CODE_BOX',
		DISPLAY_SIGNAGE: 'DISPLAY_SIGNAGE',
		DRI_BUNDLE: 'DRI_BUNDLE',
		LABEL: 'LABEL',
		MANUAL: 'MANUAL',
		MANUAL_INTERIOR: 'MANUAL_INTERIOR',
		OTHER: 'OTHER',
		RELEASE_ALERT: 'RELEASE_ALERT',
		RETAIL_BUNDLE: 'RETAIL_BUNDLE',
		SWITCH_DLC_INSERT: 'SWITCH_DLC_INSERT',
		TITLE_SHEET: 'TITLE_SHEET',
		TRAILER: 'TRAILER',
		TV_COMMERCIAL: 'TV_COMMERCIAL',
		WEBSITE: 'WEBSITE'
	} as const,
	UPLOAD: {
		INITIAL: 'INITIAL',
		PROOF: 'PROOF',
		FINAL_LABEL: 'FINAL_LABEL',
		ANNOTATION: 'ANNOTATION',
		NCL_PROOF: 'NCL_PROOF',
		MODIFIED_FINAL_LABEL: 'MODIFIED_FINAL_LABEL',
		NCL_MODIFIED_FINAL_LABEL: 'NCL_MODIFIED_FINAL_LABEL',
	} as const,
	NCL_FINAL_LABEL_STATUS: {
		IN_PROGRESS: 'IN_PROGRESS',
		AWAITING_FINAL_LABEL: 'AWAITING_FINAL_LABEL',
		SUBMITTED_TO_NCL: 'SUBMITTED_TO_NCL',
		NCL_ACCEPTED: 'NCL_ACCEPTED',
		NCL_ACCEPTED_WITH_CHANGES: 'NCL_ACCEPTED_WITH_CHANGES',
		NCL_ACCEPTED_FILE_UPLOADING: 'NCL_ACCEPTED_FILE_UPLOADING',
		NCL_ACCEPTED_UPLOAD_FAILED: 'NCL_ACCEPTED_UPLOAD_FAILED',
		NCL_FINAL_LABEL_REJECTED: 'NCL_FINAL_LABEL_REJECTED',
		NCL_PROOF_UPLOADING: 'NCL_PROOF_UPLOADING',
		NCL_PROOF_UPLOAD_FAILED: 'NCL_PROOF_UPLOAD_FAILED',
		PRINT_PROOF_UPLOADING: 'PRINT_PROOF_UPLOADING',
		PRINT_PROOF_UPLOAD_FAILED: 'PRINT_PROOF_UPLOAD_FAILED',
		PENDING_PROOF_ACCEPT: 'PENDING_PROOF_ACCEPT',
		APPROVED: 'APPROVED',
	} as const,
} as const;
