import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useQuery } from 'react-query';
import * as yup from 'yup';

import { permConst } from '../../../constants/permConst';
import { useUserProfile } from '../../../hooks/reduxHooks';
import {
	getFreightForwardersListExternal,
	postFreightForwarderAssociation,
	putFreightForwarderAssociation
} from '../../../services/ordersService';
import { isEmptyObject } from '../../../utils/dataUtils';
import { toasterNotify } from '../../../utils/toaster';
import { isAuthorized } from '../../../utils/userUtils';
import { validateToSchema } from '../../../utils/validationUtils';
import BaseModal from '../../BaseModal/BaseModal';
import Forms from '../../Forms/Forms';


interface FormValues {
	freightForwarderName?: string;
	default_flag?: boolean;
	carrier_account_number?: string;
	service_level?: string;
	japanese_contact_name?: string;
	japanese_contact_email?: string;
	japanese_contact_phone_number?: string;
}

export const baseSchema = yup.object().shape({
	freightForwarderName: yup
		.string()
		.when('freight_forwarder_code', {
			is: (value: string) => !value,
			then: (schema) => schema.required('A freight forwarder must be selected'),
		}),
	default_flag: yup.boolean().default(false),
});
export const requiredFieldsSchema = baseSchema.concat(
	yup.object().shape({
		carrier_account_number: yup.string().nullable().trim(),
		service_level: yup.string().nullable().trim(),
		japanese_contact_name: yup.string()
			.nullable()
			.trim()
			.when('$enforceAdditionalFields', {
				is: true,
				then: (schema) =>
					schema.required('Japanese Contact is required for this freight forwarder.'),
			}),
		japanese_contact_email: yup.string()
			.nullable()
			.trim()
			.when('$enforceAdditionalFields', {
				is: true,
				then: (schema) =>
					schema.required('Japanese Email is required for this freight forwarder.'),
			}),
		japanese_contact_phone_number: yup.string()
			.nullable()
			.trim()
			.when('$enforceAdditionalFields', {
				is: true,
				then: (schema) =>
					schema.required(
						'Japanese Phone Number is required for this freight forwarder.',
					),
			}),
	}),
);


const AssociateFreightForwarderModal = ({
	show,
	onCompletion,
	onClose,
	freightForwarderAssociation,
}: {
	show: boolean;
	onCompletion: (freightForwarderCode: string) => void;
	onClose: () => void;
	freightForwarderAssociation?: Record<string, any>;
}) => {
	const userProfile = useUserProfile();
	const [formValues, setFormValues] = useState<FormValues>({});
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);

	const canEditFreightForwarderAssociation = isAuthorized(userProfile.permissions, [
		permConst.FREIGHT_FORWARDER.EDIT.COMPANY,
	]);

	const allFreightForwardersQuery = useQuery(
		'getFreightForwarders',
		() => getFreightForwardersListExternal(),
		{ enabled: show },
	);
	const freightForwardersList = [...(allFreightForwardersQuery?.data?.data || [])].sort((a, b) =>
		a.name === b.name ? 0 : a.name < b.name ? -1 : 1,
	);
	const getCodeFromName = (name?: string) => 
		freightForwardersList?.find((freightForwarder) => freightForwarder.name === name)?.code;

	useEffect(() => {
		if (show) {
			setIsSubmitting(false);
			setShowAllErrors(false);
			if (freightForwarderAssociation) {
				setFormValues({
					...freightForwarderAssociation,
				});
			} else {
				setFormValues({});
			}
		}
	}, [show]);

	const getHasAdditionalFields = (freightForwarderCode?: string) =>
		freightForwardersList?.find(
			(freightForwarder) => freightForwarder.code === freightForwarderCode,
		)?.has_additional_fields || false;

	const showAdditionalFields = getHasAdditionalFields(
		freightForwarderAssociation?.freight_forwarder_code || getCodeFromName(formValues.freightForwarderName),
	);
	const validationErrors = validateToSchema(requiredFieldsSchema, formValues, {
		enforceAdditionalFields: showAdditionalFields,
	});

	const submitForm = async () => {
		if (isSubmitting || !freightForwardersList) {
			return;
		}
		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}

		const castedFormValues = showAdditionalFields
			? requiredFieldsSchema.cast(formValues, { stripUnknown: true })
			: baseSchema.cast(formValues, { stripUnknown: true });
		setIsSubmitting(true);
		
		const freightForwarderCode = castedFormValues.freight_forwarder_code || getCodeFromName(formValues?.freightForwarderName) || getCodeFromName(freightForwarderAssociation?.name);

		if (!freightForwarderCode) {
			setIsSubmitting(false);
			setShowAllErrors(true);
			throw new Error('Missing freight_forwarder_code in payload');
		}
		const payload = {
			...castedFormValues,
			freightForwarderName: undefined,
			freight_forwarder_code: freightForwarderCode,
		};

		const freightForwarderName = freightForwardersList?.find((freightForwarder) => freightForwarder.code === freightForwarderCode)?.name;

		try {
			if (freightForwarderAssociation) {
				await putFreightForwarderAssociation(
					payload.freight_forwarder_code,
					payload,
				);
				onCompletion(payload.freight_forwarder_code);
				toasterNotify(
					`Changes for "${freightForwarderName}" have been successfully saved.`,
					'success',
				);
			} else {
				await postFreightForwarderAssociation(
					payload.freight_forwarder_code,
					payload,
				);
				onCompletion(payload.freight_forwarder_code);
				toasterNotify(
					`The new freight forwarder "${freightForwarderName}" has been successfully saved.`,
					'success',
				);
			}
		} catch (error: any) {
			toasterNotify(
				'There was an unexpected error while saving the freight forwarder.',
				'error',
				error,
			);
		} finally {
			setIsSubmitting(false);
		}
		setShowAllErrors(false);
	};

	const isLoading = allFreightForwardersQuery.isLoading;

	return (
		<BaseModal show={show} onCancel={onClose} isLoading={isLoading} isSubmitting={isSubmitting}>
			<BaseModal.Title>
				{freightForwarderAssociation ? 'Manage Freight Forwarder' : 'Add Freight Forwarder'}
			</BaseModal.Title>
			{showAdditionalFields && freightForwarderAssociation && (
				<Alert variant="info">
					Any changes to the freight forwarder data will be reflected on orders that have
					been submitted for review but are not yet in process.
				</Alert>
			)}
			<Forms
				onChange={(v) => {
					setFormValues({ ...v });
				}}
				values={formValues}
				validationErrors={validationErrors}
				showAllErrors={showAllErrors}
			>
				<Forms.SingleCheckbox id="default_flag">
					<Forms.Heading>Set as Default</Forms.Heading>
					<Forms.Label>Yes</Forms.Label>
				</Forms.SingleCheckbox>

				{freightForwarderAssociation && (
					<Forms.CustomArea id="freightForwarderName">
						<Forms.Heading>Freight Forwarder</Forms.Heading>
						<Forms.Label>{freightForwarderAssociation.name}</Forms.Label>
					</Forms.CustomArea>
				)}
				{!freightForwarderAssociation && (
					<Forms.Select
						id="freightForwarderName"
						disabled={!canEditFreightForwarderAssociation}
					>
						<Forms.Heading>Freight Forwarder</Forms.Heading>
						{freightForwardersList?.map((freightForwarder) =>
							!freightForwarder.company_freight_forwarder ? (
								<Forms.Option
									key={freightForwarder.name}
									value={freightForwarder.name}
								>
									{freightForwarder.name}
								</Forms.Option>
							) : null,
						)}
					</Forms.Select>
				)}
				{showAdditionalFields && (
					<div>
						<Forms.Text
							id="japanese_contact_name"
							disabled={!canEditFreightForwarderAssociation}
							maxLength={100}
						>
							<Forms.Heading>Japanese Contact</Forms.Heading>
						</Forms.Text>
						<Forms.Text
							id="japanese_contact_email"
							disabled={!canEditFreightForwarderAssociation}
							maxLength={255}
						>
							<Forms.Heading>Japanese Email</Forms.Heading>
						</Forms.Text>
						<Forms.Text
							id="japanese_contact_phone_number"
							disabled={!canEditFreightForwarderAssociation}
							maxLength={60}
						>
							<Forms.Heading>Japanese Phone #</Forms.Heading>
						</Forms.Text>
						<Forms.Text
							id="service_level"
							disabled={!canEditFreightForwarderAssociation}
							maxLength={40}
						>
							<Forms.Heading>Service Level</Forms.Heading>
						</Forms.Text>
						<Forms.Text
							id="carrier_account_number"
							disabled={!canEditFreightForwarderAssociation}
							maxLength={20}
						>
							<Forms.Heading>Carrier Account #</Forms.Heading>
						</Forms.Text>
					</div>
				)}
			</Forms>

			{canEditFreightForwarderAssociation && (
				<BaseModal.Submit onClick={() => submitForm()}>Save</BaseModal.Submit>
			)}
		</BaseModal>
	);
};

export default AssociateFreightForwarderModal;
