import isFunction from 'lodash/isFunction';


let listeners = [];
const eventBus = {
	// event types
	FILTER_CHANGE: 'FILTER_CHANGE',
	FILTER_SET: 'FILTER_SET',
	UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',

	// api
	emit: (type, payload) => {
		listeners.forEach((listener) => {
			if (type === listener.type) {
				listener.callback(payload);
			}
		});
	},
	on: (type, callback, ref) => {
		if (type && isFunction(callback)) {
			listeners.push({type, callback, ref});
		}
	},
	off: (ref) => {
		if (ref) {
			listeners = listeners.filter(
				(listener) => listener.ref !== ref,
			);
		}
	}
};

export default eventBus;
