import React from 'react';

import { getSanitizedHTML } from '../../../../utils/stringUtils';
import Forms from '../../../Forms/Forms';

import './WSPRequestView.css';


const TEXT_FIELD = 'text';
const DATA_FIELD = 'field';
const ACKNOWLEDGE_CHECKBOX_FIELD = 'acknowledgement-checkbox';

const WSPRequestView = ({ template, readOnly }) => {
	return template?.Sections.map((section, index) => {
		if (section.type === TEXT_FIELD) {
			return (
				<div
					key={'wsp-template' + index}
					className="pb-3"
					dangerouslySetInnerHTML={
						{__html: getSanitizedHTML(section?.html)}
					}
				/>
			);
		}
		if (section.type === DATA_FIELD) {
			return (
				<Forms.Text
					key={'wsp-template' + index}
					id={section.id}
					disabled={readOnly || section?.disabled}
					placeholder={section?.placeholder}
				>
					<Forms.Heading>{section?.label}</Forms.Heading>
				</Forms.Text>
			);
		}
		if (section.type === ACKNOWLEDGE_CHECKBOX_FIELD) {

			return (
				<div
					key={'wsp-template' + index}
					className={`WSPRequestView__acknowledge-checkbox${readOnly ? ' WSPRequestView__acknowledge-checkbox--disabled' : ''}`}
				>
					<Forms.SingleCheckbox
						id={section.id}
						disabled={readOnly}
						vertical
					>
						<Forms.Label>{section?.label}</Forms.Label>
					</Forms.SingleCheckbox>
				</div>
			);
		}
	}) || null;
};

export default WSPRequestView;
