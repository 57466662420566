import api from './baseService';

export const logout = () => {
	const params = new URLSearchParams();
	params.append('env', global.config.authEnv);
	return api().get('/logout', { params });
};

export const getRoles = (roleTypeCode?: string) =>
	api().get('/pdb/roles', roleTypeCode ? { params: { role_type_code: roleTypeCode } } : undefined);

export interface UserModel {
	user_name: string;
	email_address: string;
	company_name: string;
	roles: string;
	user_status_code: string;
	ndid_user_id: string;
	ndid_company_id: string;
	login_id: string;
}

export const getUser = (userId: string) =>
	api().get<UserModel>(`/pdb/users/${userId}`);

export const getUsers = (searchParams?: Record<string, any>) =>
	api().get<UserModel[]>('/pdb/users', { params: searchParams });

export const getUsersInBulk = (userIds: string | string[], fields: string | string[] = ['user_name'], token?: string) => {
	userIds = [userIds].flat();
	fields = [fields].flat();

	const params = new URLSearchParams();
	userIds.forEach((v) => params.append('user_id', v));
	fields.forEach((v) => params.append('field', v));
	return api().get<UserModel[]>('/pdb/users', {
		params,
		...(token && { headers: { 'X-PDB-Authorization': token } }),
	});
};

export const getRoleAssignedUsers = (roles: string) => {
	return api().get('/pdb/users', { params: { role_name: roles, field: ['user_name', 'roles'] } });
};

export interface RoleModel {
	role_id: number;
	name: string;
	hasRole: boolean;
	editable?: boolean;
}

export const getAssignableRoles = (searchParams?: Record<string, any>) => {
	return api().get<RoleModel[]>('/pdb/roles/assignable', { params: searchParams });
};

export interface UserRoleModel {
	role_id: number;
	name: string;
}

export const getUsersRoles = (ndid: string) => {
	return api().get<UserRoleModel[]>(`/pdb/users/${ndid}/roles`);
};

export const putUserRoles = (ndid: string, roles: {role_id: number, has_role: boolean}[]) => {
	return api().put(`/pdb/users/${ndid}/roles`, roles);
};

export const activateUser = (ndid: string) => {
	return api().put(`/pdb/users/${ndid}/activate`);
};

export const deactivateUser = (ndid: string) => {
	return api().put(`/pdb/users/${ndid}/inactivate`);
};

export interface SearchNDIDUserModel {
	loginid: string;
	user_name: string;
	ndid_id: string;
	is_admin: boolean;
	is_imported: boolean;
}
export const getNDIDUsers = (searchParams?: Record<string, any>) => {
	return api().get<SearchNDIDUserModel[]>('/pdb/users/import_list', { params: searchParams});
};

export interface AdminUserModel {
	emailAddress: string;
	loginId: string;
	ndidCompanyId: string;
	ndidUserId: string;
	userName: string;
}
export const getNDIDAdmins = () => {
	return api().get<AdminUserModel[]>('/pdb/users/import_list/admin');
};

export const addPDBuser = (ndid_id: string,
						   requestBody: {roles: {role_id: number; has_role: boolean;}[]; initial_query: string;},
						   ndid_company_id?: string) => {
	let urlStr = `/pdb/users/${ndid_id}/import`;
	if (ndid_company_id) {
		urlStr += `?ndid_company_id=${ndid_company_id}`;
	}
	return api().post(urlStr, requestBody);
};
