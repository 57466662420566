import React from 'react';
import { Link } from 'react-router-dom';

import FAIcon from '../../../components/FAIcon/FAIcon';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';


const PackoutFacilitiesTab = ({ facilitiesData }) => {

	let facilities = [];
	if (facilitiesData && facilitiesData.length > 0) {
		for (let key = 0; key < facilitiesData.length; key++) {
			facilities.push(
                <div
                    key={key}
                    className="row"
                    style={{ paddingRight: '15px', paddingLeft: '15px' }}
                >
                    <div className="row-packout">
                        {facilitiesData[key].name}
                    </div>
                </div>
			);
		}
	}

	if (facilities.length > 0) {
		return (<>
                    <SectionTitle>Packout Facilities</SectionTitle>
                    {facilities}
                    <div className="mt-3">
                        <Link to="/orders/manage-packout-facilities" className="btn btn-outline-primary">
                            Manage Packout Facilities <FAIcon name="chevron-right" />
                        </Link>
                    </div>
		</>);
	} else {
		return null;
	}


};

export default PackoutFacilitiesTab;
