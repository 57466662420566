import React, { ReactElement, useEffect, useState, VFC } from 'react';
import { Alert } from 'react-bootstrap';
import * as yup from 'yup';

import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import { digitalCodesConstants } from '../../../constants/digitalCodesConstants';
import { postDPRequestAction, postWSPRequestAction } from '../../../services/digitalCodesService';
import { isEmptyObject } from '../../../utils/dataUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { validateToSchema } from '../../../utils/validationUtils';


const { DECLINED } = digitalCodesConstants.priceRequestActions;

const schema = yup.object().shape({
	comment: yup.string().trim().required('A reason must be supplied to continue'),
});

interface DeclineModalProps {
	show?: boolean;
	component?: ProductComponentData;
	gameName?: string;
	currentRequest?: WholesalePriceRequestData | DeclaredPriceRequestData;
	onClose?: () => void;
	onChange?: () => void;
}

const DeclineModal: VFC<DeclineModalProps> = ({
	show,
	component,
	gameName,
	currentRequest,
	onClose,
	onChange,
}: DeclineModalProps): ReactElement => {
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [formValues, setFormValues] = useState<Record<string, any>>({});
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);

	const requestType = currentRequest && (('wholesale_price_request_id' in currentRequest && 'wholesale price') || ('declared_price_request_id' in currentRequest && 'declared price'));	

	useEffect(() => {
		if (show) {
			setFormValues({});
			setIsSubmitting(false);
			setShowAllErrors(false);
		}
	}, [show]);

	const submitDeclined = async () => {
		if (!currentRequest) { return; }
		if (!isEmptyObject(validationErrors)) {
			return setShowAllErrors(true);
		}
		setIsSubmitting(true);

		try {
			const payload = {
				action: DECLINED,
				comment: formValues.comment,
			};
			if ('wholesale_price_request_id' in currentRequest) {
				await postWSPRequestAction(currentRequest.wholesale_price_request_id, payload);
				toasterNotify(
				`Status has been updated to the wholesale price request for "${
					gameName || component?.game_name
				}"`,
				'info',
				);
				onChange && onChange();
			} else if ('declared_price_request_id' in currentRequest) {
				await postDPRequestAction(currentRequest.declared_price_request_id, payload);
				toasterNotify(
				`Status has been updated to the declared price request for "${
					gameName || component?.game_name
				}"`,
				'info',
				);
				onChange && onChange();
			}
		} catch (error) {
			toasterNotify(
				createMessageForError(error as Error, `rejecting the ${requestType} price request`),
				'error',
				error as Error,
			);
			setIsSubmitting(false);
		} finally {
			onClose && onClose();
		}
	};

	const validationErrors = validateToSchema(schema, formValues);
	const priceType = requestType === 'wholesale price' ? 'Wholesale' : 'Declared';

	return (
		<BaseModal show={show || false} onCancel={onClose} isSubmitting={isSubmitting}>
			<BaseModal.Title>Decline {priceType} Price Request</BaseModal.Title>
			<Alert className='mb-0' variant='danger'>
				Do you wish to decline this {priceType.toLowerCase()} price request for
				"{gameName || component?.game_name}"? The publisher will be
				notified of the reason below.
			</Alert>
			<Forms
				onChange={(newValues) => setFormValues(newValues)}
				values={formValues}
				validationErrors={validationErrors}
				showAllErrors={showAllErrors}
				vertical
			>
				<Forms.TextArea id='comment' rows={4} maxLength={2000}>
					<Forms.Heading>Reason for decline</Forms.Heading>
				</Forms.TextArea>
			</Forms>
			<BaseModal.Submit variant='danger' onClick={() => submitDeclined()}>
				Decline
			</BaseModal.Submit>
			<BaseModal.Cancel onClick={onClose && (() => onClose())}>Cancel</BaseModal.Cancel>
		</BaseModal>
	);
};
export default DeclineModal;
