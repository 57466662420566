import React from 'react';

import { formatCurrency } from '../../../utils/currencyUtils';

const CurrencyCell = ({ value }) => {
	if (!value) { return null; }
	return <span>{formatCurrency(value)}</span>;
};

export default CurrencyCell;
