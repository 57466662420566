import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import * as PropTypes from 'prop-types';

export class FacilitiesTableExternal extends Component {
	render() {
		return (
			<Table striped>
				<thead>
					<tr>
						<th>Name</th>
						<th>Address</th>
						<th>Phone Number</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{this.props.facilities.map((facility, key) => {
						return this.props.dataRowCallback(facility, key);
					})}
				</tbody>
			</Table>
		);
	}
}

FacilitiesTableExternal.propTypes = {
	admin: PropTypes.bool,
	dataRowCallback: PropTypes.func,
	facilities: PropTypes.arrayOf(PropTypes.any)
};

export default FacilitiesTableExternal;
