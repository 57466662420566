export function generateOffsetBatches(total, batchLimit) {
	let batches = [];
	let currentOffset = 0;
	while (currentOffset < total) {
		batches.push(currentOffset);
		currentOffset += batchLimit;
	}
	return batches;
}

export const paginationLimits = {
	lotcheckQueue: 2000,
	productsList: 5000,
	companyLimit: 500
};
