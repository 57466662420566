import { eventConstants } from '../constants/eventConstants';
import { permConst } from '../constants/permConst';
import { compareDates } from './dateUtils';
import { isAuthorized } from './userUtils';
import { postEventTaskToken, putClaimTask, putCompleteTask, deleteEventTask} from '../services/eventsService';
import { deleteFiles } from '../services/fileUploadsService';
import { createMessageForError, toasterNotify } from './toaster';


export const findEventStatus = ({start_datetime, end_datetime}) => {
	if (!start_datetime || !end_datetime) {
		return eventConstants.STATUS.DRAFT;
	}
	if (compareDates(start_datetime) === 1) {
		return eventConstants.STATUS.PLANNED;
	}
	if (compareDates(end_datetime) > -1) {
		return eventConstants.STATUS.LIVE;
	}
	return eventConstants.STATUS.COMPLETED;
};


/**
 * Retrieve a DA token for a marketing event task for use with pdb-core endpoints.
 * @param  {} taskId Marketing event task id
 */
export async function getDATokenForMarketingEventTask(taskId) {
	return (await postEventTaskToken(taskId)).headers['x-pdb-authorization'];
}

/**
 * Delete the attachment for the given s3_content_metadata_id
 * @param  {} taskId Marketing event task id
 */
export async function deleteMarketingEventTaskAttachment(taskId, s3_content_metadata_id) {
	const token = await getDATokenForMarketingEventTask(taskId);
	await deleteFiles(s3_content_metadata_id, token);
}

export const getTaskPermissions = (userProfile, accessType) => {
	const isManager = accessType === 'MANAGER';
	const isCoordinator = accessType === 'COORDINATOR';
	const isSupport = accessType === 'SUPPORT';
	const isLead = accessType === 'LEAD';

	return {
		canAccessTasks:
			(isManager &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.TASK.VIEW.MANAGER,
				])) ||
			(isCoordinator &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.TASK.VIEW.COORDINATOR,
				])) ||
			(isLead &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.TASK.VIEW.COMPANY,
				])) ||

			(isSupport &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.TASK.VIEW.COMPANY_SUPPORT,
				])),
		canEditActiveOnTasks:
			isManager &&
			isAuthorized(userProfile.permissions, [
				permConst.MARKETING_EVENT.TASK.ACTIVATION.EDIT.MANAGER,
			]),
		canViewInactiveTasks:
			isManager &&
			isAuthorized(userProfile.permissions, [
				permConst.MARKETING_EVENT.TASK.ACTIVATION.VIEW.MANAGER,
			]),
		canViewTypeOnTasks:
			(isManager &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.TASK.TYPE.VIEW.MANAGER,
				])) ||
			(isCoordinator &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.TASK.TYPE.VIEW.COORDINATOR,
				])),
		canViewPublisherOnTasks:
			(isManager &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.TASK.PUBLISHER.VIEW.MANAGER,
				])) ||
			(isCoordinator &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.TASK.PUBLISHER.VIEW.COORDINATOR,
				])),
		canViewUnassignedOnTasks:
			isManager &&
			isAuthorized(userProfile.permissions, [
				permConst.MARKETING_EVENT.TASK.UNASSIGNED.VIEW.MANAGER,
			]),
		canAddTask:
			isManager &&
			isAuthorized(userProfile.permissions, [permConst.MARKETING_EVENT.TASK.ADD.MANAGER]),
		canDeleteTask:
			isManager &&
			isAuthorized(userProfile.permissions, [permConst.MARKETING_EVENT.TASK.DELETE.MANAGER]),
		canEditTask:
			(isManager &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.TASK.DETAIL.EDIT.MANAGER,
					permConst.MARKETING_EVENT.TASK.WORK.EDIT.MANAGER
				])) ||
			(isLead &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.TASK.WORK.EDIT.COMPANY
				])),
		canEditTaskIfAssigned:
			isAuthorized(userProfile.permissions, [
				permConst.MARKETING_EVENT.TASK.DETAIL.EDIT.ASSIGNED,
				permConst.MARKETING_EVENT.TASK.WORK.EDIT.ASSIGNED
			]),
		canEditWorkItemsOnTasks:
			(isManager &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.TASK.WORK.EDIT.MANAGER
				])) ||
			(isLead &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.TASK.WORK.EDIT.COMPANY
				])),
		canEditWorkItemsOnTasksIfAssigned:
			isAuthorized(userProfile.permissions, [
				permConst.MARKETING_EVENT.TASK.WORK.EDIT.ASSIGNED
			]),
		canEditDetailOnTasks:
			(isManager &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.TASK.DETAIL.EDIT.MANAGER,
				])),
		canEditDetailOnTasksIfAssigned:
			isAuthorized(userProfile.permissions, [
				permConst.MARKETING_EVENT.TASK.DETAIL.EDIT.ASSIGNED,
			]),
		canEditPublisherAssigneeOnTasks:
			(isManager &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.TASK.ASSIGN.MANAGER,
				])),
		canEditInternalAssigneeOnTasks:
			(isManager &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.TASK.ASSIGN.MANAGER,
				])),
		canEditExternalAssigneeOnTasks:
			(isLead &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.TASK.ASSIGN.COMPANY,
				])),
		canClaimInternalAssignmentOnTasks:
			(isCoordinator &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.TASK.CLAIM.COORDINATOR,
				])),
		canClaimExternalAssignmentOnTasks:
			(isSupport &&
				isAuthorized(userProfile.permissions, [
					permConst.MARKETING_EVENT.TASK.CLAIM.COMPANY_SUPPORT,
				])),
	};
};

export const getEventDetailPermissions = (userProfile, accessType) => {

	const isManager = accessType === 'MANAGER';

	const taskPermissions = getTaskPermissions(userProfile, accessType);

	const detailPermissions = {
		canEditEvent:
			isManager &&
			isAuthorized(userProfile.permissions, [
				permConst.MARKETING_EVENT.EDIT.MANAGER,
				permConst.MARKETING_EVENT.DETAILS.EDIT.MANAGER,
				permConst.MARKETING_EVENT.VISIBILITY.EDIT.MANAGER,
				permConst.MARKETING_EVENT.MANAGER.EDIT.MANAGER,
			]),
		canSetVisibility:
			isManager &&
			isAuthorized(userProfile.permissions, [
				permConst.MARKETING_EVENT.VISIBILITY.EDIT.MANAGER,
			]),
		canSetEventDetails:
			isManager &&
			isAuthorized(userProfile.permissions, [permConst.MARKETING_EVENT.DETAILS.EDIT.MANAGER]),
		canSetManagers:
			isManager &&
			isAuthorized(userProfile.permissions, [permConst.MARKETING_EVENT.MANAGER.EDIT.MANAGER]),
		canSetActive:
			isManager &&
			isAuthorized(userProfile.permissions, [
				permConst.MARKETING_EVENT.ACTIVATION.EDIT.MANAGER,
			]),
		canAddProductToEvent:
			isManager &&
			isAuthorized(userProfile.permissions, [permConst.MARKETING_EVENT.PRODUCT.ADD.MANAGER]),
		canEditProductOnEvent:
			isManager &&
			isAuthorized(userProfile.permissions, [permConst.MARKETING_EVENT.PRODUCT.EDIT.MANAGER]),
	};

	return {
		...detailPermissions,
		...taskPermissions
	};
};

export const completeTask = async (taskId, taskName, onSuccess, onFinish) => {
	try {
		await putCompleteTask(taskId);
		toasterNotify(`Task "${taskName}" marked as complete` ,'success');
		onSuccess && onSuccess();
	} catch (error) {
		toasterNotify(createMessageForError(error, 'changing task status'), 'error', error);
	} finally {
		onFinish && onFinish();
	}
};

export const claimTask = async (taskId, taskName, userProfile, onSuccess, onFinish) => {
	try {
		await putClaimTask(taskId, userProfile.userId);
		toasterNotify(`Task "${taskName}" is now assigned to you` ,'success');
		onSuccess && onSuccess();
	} catch (error) {
		toasterNotify(createMessageForError(error, 'claiming task status'), 'error', error);
	} finally {
		onFinish && onFinish();
	}
};

export const deleteTask = async (taskId, taskName, onSuccess, onFinish) => {
	try {
		await deleteEventTask(taskId);
		toasterNotify(`Task "${taskName}" was deleted` ,'success');
		onSuccess && onSuccess();
	} catch (error) {
		toasterNotify(createMessageForError(error, 'deleting a task'), 'error', error);
	} finally {
		onFinish && onFinish();
	}
};


