import React, { Component, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { permConst } from '../../../constants/permConst';
import { isAuthorized, isAuthorizedForUrl } from '../../../utils/userUtils';
import { isFeatureSunsetted } from '../../../utils/sunsetFeaturesUtils';
import { toasterNotify } from '../../../utils/toaster';
import { useCompanyProfileQuery } from '../../../hooks/queryHooks';
import { useUserProfile } from '../../../hooks/reduxHooks';
import SubmitCustomerFeedback from '../../../components/modals/SubmitCustomerFeedback/SubmitCustomerFeedback';

const PDF = 'application/pdf';


class NavbarMenuItem extends Component {
	render() {
		const {
			url,
			name,
			externalUrl,
			dropdown,
			openModal,
		} = this.props;

		if (url) {
			return (
				<>
					{isAuthorizedForUrl(url) ? (
						<li className={dropdown ? '' : 'nav-item'}>
							<Link className={dropdown ? 'dropdown-item' : 'nav-link'} to={url}>{name}</Link>
						</li>) : null}
				</>
			);
		}
		if (externalUrl) {
			return (
				<li className={dropdown ? '' : 'nav-item'}>
					<a className={dropdown ? 'dropdown-item' : 'nav-link'} href={externalUrl}>{name}</a>
				</li>
			);
		}

		if (openModal) {
			return (
				<li className={dropdown ? '' : 'nav-item'}>
					<Button
						className={dropdown ? 'dropdown-item' : 'nav-link'}
						onClick={openModal}
					>
						{name}
					</Button>
				</li>
			);
		}
		return null;
	}
}

class HelpTopicMenuItem extends Component {
	render() {
		const {
			helpTopicName,
			helpTopicId,
			contentType,
		} = this.props;

		if (contentType === null){
			return null;
		}
		else if (contentType === PDF) {
			return (
				<li key={helpTopicId}>
					<a href={`https://${global.config.apiHost}${global.config.apiBasePath}/pdb/help/${helpTopicId}/file`}
					   target='_blank'
					   rel='noreferrer'
					   className='dropdown-item'
					>
						{helpTopicName}
					</a>
				</li>
			);
		}
		else {
			return (
				<li key={helpTopicId}>
					<a href={`https://${global.config.apiHost}${global.config.apiBasePath}/pdb/help/${helpTopicId}/file`}
					   className='dropdown-item'
					   onClick={() => toasterNotify(`Downloading help topic: ${helpTopicName}`, 'info')}
					   download
					>
						{helpTopicName}
					</a>
				</li>
			);
		}
	}
}

class NavbarDropdownMenu extends Component {
	constructor(props) {
		super(props);
		let current =
			this.props.path &&
			window.location.href.indexOf(this.props.path) >= 0;
		this.state = {
			collapsed: true,
			enabled: true,
			current: current,
		};

		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	toggle = (el) => {
		this.setState({ collapsed: !this.state.collapsed });
	};

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({ collapsed: true });
		}
	}

	filterMenuItems(children) {
		return React.Children.toArray(children)
			// first filter removes items not permitted to the user
			.filter((node) => {
				if (!node.props?.skipAuth) {
					return isAuthorizedForUrl((node.props.url || node.props.externalUrl));
				}
				return true;
			})
			// second filter removes unnecessary dividers
			.filter((node, index, array) => {
				if (node.type === NavbarDivider) {
					// remove dividers at the top or bottom of the array
					if (index === array.length - 1 || index === 0) {
						return false;
					}
					// remove dividers that directly precede another divider
					if (array[index + 1].type === NavbarDivider) {
						return false;
					}
				}
				return true;
			});
	}

	render() {
		const { name, children, url, right } = this.props;
		const { collapsed, current } = this.state;
		const className =
			'nav-item dropdown' +
			(!collapsed ? ' show' : '') +
			(current ? ' active' : '');
		const filteredMenuItems = this.filterMenuItems(children);
		return (
			<>
				{filteredMenuItems?.length ? (
					<li
						className={className}
						onClick={this.toggle}
						ref={this.setWrapperRef}
					>
						<a href={url} className='nav-link dropdown-toggle'>
							{name}
						</a>
						<ul className={`dropdown-menu${right ? ' dropdown-menu-right' : ''}${!collapsed ? ' show' : ''}`}>{filteredMenuItems}</ul>
					</li>
				) : null}
			</>
		);
	}
}

const NavbarDivider = () => <Dropdown.Divider />;


const Navbar = (props) => {
	const [openModal, setOpenModal] = useState({});
	const userProfile = useUserProfile();
	const sunsetFeatures = useSelector((state) => state.referenceReducer.sunsetFeatures?.content);
	const helpTopics = useSelector((state) => state.referenceReducer.helpTopics?.content);
	const companyProfileQuery = useCompanyProfileQuery();

	const userPermissions = userProfile?.permissions;
	const canMakeProjections = companyProfileQuery.data?.data.company_information.projection_eligible_flag === 1;
	const canViewProjections = canMakeProjections &&
		isAuthorized(userPermissions, [permConst.PROJECTION.VIEW.COMPANY]);
	const canSubmitFeedback = isAuthorized(userPermissions, [permConst.CUSTOMER_FEEDBACK.SUBMIT.COMPANY]);
	const companyUrl = `/admin/publishers/${userProfile.companyId}`;
	const displayHelpMenu = !!(canSubmitFeedback || helpTopics?.length > 0);

	const onClose = () => setOpenModal({ ...openModal, type: null });

	return (
		<ul className='navbar-nav mr-auto'>
			<NavbarMenuItem
				name='Products'
				url='/products'
			/>
			{!isFeatureSunsetted(sunsetFeatures, 'lotcheck_features') &&
				<NavbarMenuItem
					name='Lotcheck Queue'
					url='/submissions'
				/>
			}
			<NavbarDropdownMenu name='Orders' path='/orders'>
				<NavbarMenuItem
					name='Physical Orders'
					url='/orders'
					dropdown
				/>
				<NavbarMenuItem
					name='Physical Orders'
					url='/orders/physical'
					dropdown
				/>
				<NavbarMenuItem
					name='Projections Report'
					url='/orders/projections/management'
					dropdown
				/>
				{canViewProjections && (
					<NavbarMenuItem
						name='Projections'
						url='/orders/projections'
						dropdown
					/>
				)}
				<NavbarMenuItem
					name='Manage Freight Forwarders'
					url='/orders/manage-freight-forwarders'
					dropdown
				/>
				<NavbarMenuItem
					name='Manage Freight Forwarders'
					url='/orders/associate-freight-forwarders'
					dropdown
				/>
				<NavbarMenuItem
					name='Manage Packout Facilities'
					url='/orders/manage-packout-facilities'
					dropdown
				/>
				<NavbarMenuItem
					name='Manage Consignees'
					url='/orders/consignees'
					dropdown
				/>
				<NavbarMenuItem
					name='Digital Code Orders'
					url='/orders/digital-codes'
					dropdown
				/>
			</NavbarDropdownMenu>

			<NavbarDropdownMenu name='Events' path='/marketing-events'>
				<NavbarMenuItem
					name='Marketing Events'
					url='/marketing-events'
					dropdown
				/>
				<NavbarMenuItem
					name='Marketing Event Reports'
					url='/marketing-events/reports'
					dropdown
				/>
			</NavbarDropdownMenu>

			<NavbarMenuItem 
				name="NCL"
				url="/ncl"
			/>

			<NavbarDropdownMenu name='Admin' path='/admin'>
				<NavbarMenuItem
					name='User Management'
					url='/admin/users'
					dropdown
				/>
				<NavbarMenuItem
					name='NCL User Management'
					url='/admin/ncl-users'
					dropdown
				/>
				<NavbarMenuItem
					name='Publisher Management'
					url='/admin/publishers'
					dropdown
				/>
				{isAuthorizedForUrl('/admin/publishers/:companyId') && (
					<NavbarMenuItem
						name='Company Information'
						url={companyUrl}
						dropdown
						skipAuth
					/>)
				}

				<NavbarDivider />
				<NavbarMenuItem
					name='Family Setup'
					url='/admin/family-setup'
					dropdown
				/>
				<NavbarMenuItem
					name='NCL ROM Versions Files'
					url='/admin/ncl-rom-versions'
					dropdown
				/>
				<NavbarMenuItem
					name='Components Without Products'
					url='/admin/components-without-products'
					dropdown
				/>
				<NavbarDivider />
				<NavbarMenuItem
					name='Approval Queue'
					url='/products/assets'
					dropdown
				/>
				<NavbarMenuItem
					name='Transfer Distribution'
					url='/admin/product-distribution-transfer-requests'
					dropdown
				/>
				<NavbarMenuItem
					name='Transfer Ownership'
					url='/admin/product-ownership-transfer-requests'
					dropdown
				/>
				<NavbarDivider />
				<NavbarMenuItem
					name='Custom Field Management'
					url='/admin/custom-fields-management'
					dropdown
				/>
				<NavbarMenuItem
					name='Announcement Management'
					url='/admin/announcements'
					dropdown
				/>
				<NavbarMenuItem
					name='Feature Management'
					url='/admin/feature-management'
					dropdown
				/>
				<NavbarMenuItem
					name="Help Topic Management"
					url="/admin/help-topic-management"
					dropdown
				/>
			</NavbarDropdownMenu>
			
			{displayHelpMenu && (
				<NavbarDropdownMenu name='Help'>
					{helpTopics?.length > 0 && helpTopics.map((helpTopic) =>
						<HelpTopicMenuItem
							helpTopicName={helpTopic.name}
							helpTopicId={helpTopic.help_topic_id}
							contentType={helpTopic.content_type}
							key={helpTopic.help_topic_id}
						/>
					)}
					<NavbarDivider/>
					{canSubmitFeedback && (
						<NavbarMenuItem
							name='Publisher Tool Feedback'
							openModal={() =>
								setOpenModal({
									type: 'CUSTOMER_FEEDBACK_MODAL',
								})}
							dropdown
						/>
					)}
				</NavbarDropdownMenu>
			)}
			<SubmitCustomerFeedback
				show={openModal?.type === 'CUSTOMER_FEEDBACK_MODAL'}
				onCompletion={onClose}
				onClose={onClose}
			 	submitPermission={canSubmitFeedback}
			/>
		</ul>
	);
};

Navbar.DropdownMenu = NavbarDropdownMenu;
Navbar.MenuItem = NavbarMenuItem;

export default Navbar;
export { NavbarDropdownMenu };
