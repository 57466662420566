import React, { VFC, useMemo, useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { useQuery } from 'react-query';

import { actionsColumn } from '../../components/BaseTable/BaseTable';
import FAIcon from '../../components/FAIcon/FAIcon';
import FilterableTable from '../../components/FilterableTable/FilterableTable';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import { permConst } from '../../constants/permConst';
import { useUserProfile } from '../../hooks/reduxHooks';
import { ConsigneeData, deleteConsignee, getConsignees } from '../../services/ordersService';
import { isAuthorized } from '../../utils/userUtils';
import ConsigneeModal from '../../components/modals/ConsigneeModal/ConsigneeModal';
import DeleteConfirmationModal from '../../components/modals/DeleteConfirmationModal/DeleteConfirmationModal';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';

interface OpenModalDelete {
	type: 'DELETE_CONSIGNEE_MODAL';
	consignee_company_id: number;
	name: string;
} 
interface OpenModalSave{
	type: 'SAVE_CONSIGNEE_MODAL';
	consignee_company_id?: number;
}
type OpenModal = OpenModalDelete | OpenModalSave | { type?: null };

const ConsigneeManagement: VFC = () => {
	const userProfile = useUserProfile();
	const [openModal, setOpenModal] = useState<OpenModal>({});
	const consigneeQuery = useQuery('getConsignees', () => getConsignees());

	const consignees = consigneeQuery.data?.data && [...consigneeQuery.data.data].sort((a, b) => {
		if (a.default_flag) {
			return -1;
		} else if (b.default_flag) {
			return 1;
		}
		return 0;
	});

	const hasEditPermission = isAuthorized(userProfile?.permissions, [
		permConst.CONSIGNEE.EDIT.COMPANY,
	]);

	const getDataFormat = () => {
		const ConsigneeModal = ({ original }: { original: ConsigneeData }) => {
			if (hasEditPermission) {
				return (
					<MeatballDropdown>
						<MeatballDropdown.Item
							onClick={() =>
								setOpenModal({
									...openModal,
									type: 'SAVE_CONSIGNEE_MODAL',
									consignee_company_id: original.consignee_company_id,
								})
							}
						>
							Edit Consignee
						</MeatballDropdown.Item>
						<MeatballDropdown.Item
							onClick={() =>
								setOpenModal({
									...openModal,
									type: 'DELETE_CONSIGNEE_MODAL',
									consignee_company_id: original.consignee_company_id,
									name: original.name,
								})
							}
						>
							<span className="text-danger">Delete</span>
						</MeatballDropdown.Item>
					</MeatballDropdown>
				);
			} else {
				return null;
			}
		};

		return [
			{
				Header: 'Consignee',
				accessor: 'name',
				Cell: ({ original, value }: { original: ConsigneeData; value: string }) => (
					<>
						{value}
						{original.default_flag ? (
							<div className="inline-block">
								{' '}
								<Badge variant="info">Default</Badge>
							</div>
						) : null}
					</>
				),
			},
			{
				Header: 'Address',
				id: 'street_line',
				accessor: (row: ConsigneeData) =>
					[row.street_line_1, row.street_line_2].filter((v) => !!v).join(', '),
			},
			{
				Header: 'City',
				accessor: 'city',
			},
			{
				Header: 'Region',
				accessor: 'region',
			},
			{
				Header: 'Postal Code',
				accessor: 'postal_code',
			},
			{
				Header: 'Country',
				accessor: 'country_name',
			},
			{
				Header: 'Email Address',
				accessor: 'consignee_email_address',
			},
			{
				Header: 'Phone Number',
				accessor: 'consignee_phone_number',
			},
			{
				...actionsColumn,
				Header: '',
				Cell: ConsigneeModal,
			},
		];
	};
	const dataFormat = useMemo(() => getDataFormat(), []);

	return (
		<Page isLoading={consigneeQuery.isLoading} fault={consigneeQuery.error}>
			<Title
				button={
					hasEditPermission ? (
						<Button
							variant="primary"
							onClick={() => setOpenModal({ type: 'SAVE_CONSIGNEE_MODAL' })}
						>
							<FAIcon name="plus" /> Add Consignee
						</Button>
					) : undefined
				}
			>
				Manage Consignees
			</Title>
			<FilterableTable
				dataFormat={dataFormat}
				data={consignees}
				searchableFieldPlaceHolder="Search consignees..."
				searchableFields={['name']}
				showPagination={false}
			/>
			<ConsigneeModal
				show={openModal.type === 'SAVE_CONSIGNEE_MODAL'}
				onClose={() => setOpenModal({ ...openModal, type: null })}
				onChange={(consigneeId) => consigneeQuery.refetch()}
				consigneeId={((openModal as OpenModalSave).consignee_company_id) || undefined}
			/>
			<DeleteConfirmationModal
				title='Delete Consignee'
				message={`Orders under NOA review that use this consignee will be rejected. 
				Are you sure you want to DELETE consignee ${(openModal as OpenModalDelete)?.name}?`}
				show={openModal?.type === 'DELETE_CONSIGNEE_MODAL'}
				closeModal={() => setOpenModal({})}
				confirmDelete={async () => {
					try {
						await deleteConsignee(String((openModal as OpenModalDelete)?.consignee_company_id));
						setOpenModal({});
						consigneeQuery.refetch();
					} catch (error: any) {
						toasterNotify(
							createMessageForError(error, 'deleting the consignee'),
							'error',
							error
						);
					}
				}}
			/>
		</Page>
	);
};
export default ConsigneeManagement;
