// TODO: All functions that return XML are components. They should be moved from
// here to the Components folder
import { Col, FormCheck, FormControl, FormGroup, Row } from 'react-bootstrap';
import React from 'react';

export function makeKey() {
	return Array.prototype.join.call(arguments, ',');
}

export function getCheckbox(
	stateVariableName,
	title,
	handleInputChange,
	defaultValue
) {
	return (
		<FormGroup as={Row}>
			<label className="col-sm-4 control-label text-sm-right">{title}</label>
			<Col sm={3}>
				<div className="checkbox">
					<label>
						<FormCheck
							type="checkbox"
							id={title.replace(/\s+/g, '-').toLowerCase()}
							defaultChecked={defaultValue}
							onChange={(e) => {
								handleInputChange(
									stateVariableName,
									e.target.checked
								);
							}}
							label="Yes"
						/>
					</label>
				</div>
			</Col>
		</FormGroup>
	);
}

export function getSelector(
	stateVariableName,
	title,
	values,
	handleInputChange,
	defaultValue
) {
	return (
		<FormGroup as={Row}>
			<label
				id={title.replace(/\s+/g, '-').toLowerCase()}
				className="col-sm-4 control-label text-sm-right col-form-label"
			>
				{title}
			</label>
			<Col sm={7}>
				<select
					placeholder=""
					defaultValue={defaultValue}
					className={'form-control'}
					onChange={(e) => {
						const selectedValue = !isNaN(e.currentTarget.value)
							? parseInt(e.currentTarget.value)
							: e.currentTarget.value;
						handleInputChange(stateVariableName, selectedValue);
					}}
				>
					{values}
				</select>
			</Col>
		</FormGroup>
	);
}

export function getTextBox(
	stateVariableName,
	title,
	handleInputChange,
	defaultValue
) {
	return (
		<FormGroup as={Row}>
			<label
				id={title.replace(/\s+/g, '-').toLowerCase()}
				className="col-sm-4 control-label text-sm-right col-form-label"
			>
				{title}
			</label>
			<Col sm={7}>
				<FormControl
					type="text"
					defaultValue={defaultValue}
					placeholder=""
					onChange={(e) => {
						handleInputChange(
							stateVariableName,
							e.currentTarget.value
						);
					}}
				/>
			</Col>
		</FormGroup>
	);
}

export function getYesNoRequired(
	stateVariableName,
	title,
	handleInputChange,
	defaultValue
) {
	return (
		<FormGroup as={Row}>
			<label
				id={title.replace(/\s+/g, '-').toLowerCase()}
				className="col-sm-4 control-label text-sm-right"
			>
				{title}
			</label>
			<Col sm={7}>
				<select
					placeholder=""
					defaultValue={defaultValue}
					className={'form-control'}
					onChange={(e) => {
						const selectedValue = !isNaN(e.currentTarget.value)
							? parseInt(e.currentTarget.value)
							: e.currentTarget.value;
						handleInputChange(stateVariableName, selectedValue);
					}}
				>
					{generateOptions(['Yes', 'No', 'Required'])}
				</select>
			</Col>
		</FormGroup>
	);
}

export function generateOptions(options) {
	const optionList = [];
	options.forEach((option, index) => {
		if (option.label) {
			optionList.push(
				<option key={index} value={option.value}>
					{option.label}
				</option>
			);
		} else {
			optionList.push(
				<option key={index} value={option}>
					{option}
				</option>
			);
		}
	});
	return optionList;
}

export function generateDataField(
	header,
	value,
	dlClass = 'order-review-dl',
	headerClass = 'heading',
	ddClass = 'col-md-8'
) {
	return (
		<dl className={dlClass}>
			<dt className={headerClass}>{header}</dt>
			<dd className={ddClass}>{value}</dd>
		</dl>
	);
}
