import React from 'react';
import { Alert, Col, Form, FormGroup, FormLabel, Row } from 'react-bootstrap';

import BaseModal from '../../../components/BaseModal/BaseModal';
import { postROMApproval } from '../../../services/productsService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';

export default class ROMApprovalModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			display: true,
			judgement: '',
			comments: '',
			isLoading: false
		};

		this.toggleLoading = this.toggleLoading.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	updateRomApproval = () => {
		this.toggleLoading();
		const {
			closeModal,
			pageRefresh
		} = this.props;
		postROMApproval(this.getPayload())
			.then((response) => {
				toasterNotify('Updated ROM Approval', 'success');
				pageRefresh();
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'submitting ROM approval'),
					'error',
					error
				);
			})
			.finally(() => {
				closeModal();
			});
	};

	getPayload = () => {
		const { productSubmissionId } = this.props;
		const { judgement, comments } = this.state;
		return {
			product_submission_id: productSubmissionId,
			judgement: judgement,
			lotcheck_region: 'NCL',
			comments: comments
		};
	};

	commentChanged = (event) => {
		this.setState({ comments: event.target.value });
	};

	judgementSelected = (event) => {
		this.setState({ judgement: event.target.value });
	};

	render() {
		const { display, judgement, comments } = this.state;
		const { closeModal } = this.props;
		return (
			<BaseModal show={display} onCancel={closeModal} isSubmitting={this.state.isLoading}>
				<BaseModal.Title>ROM Approval</BaseModal.Title>
				<div>
					<Alert variant="info">
						Completing this form will automate ROM Approval
						messaging to set parties.
					</Alert>
					<Form className="makercode-form">
						<FormGroup as={Row}>
							<FormLabel column sm={3} className="text-sm-right">
								<strong>Judgement</strong>
							</FormLabel>
							<Col sm={8}>
								<select
									className="form-control"
									onChange={this.judgementSelected}
									defaultValue=""
								>
									<option />
									<option value="NCL_APPROVAL">
										NCL Approval
									</option>
									<option value="NCL_NON_APPROVAL">
										NCL Non-Approval
									</option>
								</select>
							</Col>
						</FormGroup>
						<FormGroup as={Row}>
							<FormLabel column sm={3} className="text-sm-right">
								<strong>Additional Comments</strong>
							</FormLabel>
							<Col sm={8}>
								<textarea
									className="form-control"
									rows="5"
									onChange={this.commentChanged}
									value={comments}
								/>
								<div className="help-block">Optional</div>
							</Col>
						</FormGroup>
					</Form>
				</div>
				<BaseModal.Submit
					disabled={judgement === ''}
					onClick={this.updateRomApproval}
				>
					Update
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}
