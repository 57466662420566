import React, { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { linkColumn } from '../../../components/BaseTable/BaseTable';
import FAIcon from '../../../components/FAIcon/FAIcon';
import FilterableTable from '../../../components/FilterableTable/FilterableTable';
import Loading from '../../../components/Loading/Loading';
import Page from '../../../components/Page/Page';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { TabState } from '../../../components/StatefulTabs/StatefulTabs';
import StatusText from '../../../components/StatusText/StatusText';
import CurrencyCell from '../../../components/cells/CurrencyCell/CurrencyCell';
import DateCell from '../../../components/cells/DateCell/DateCell';
import { digitalCodesConstants } from '../../../constants/digitalCodesConstants';
import { getDPReviewQueue } from '../../../services/digitalCodesService';
import { getProductsInBulk } from '../../../services/productsService';
import { getProductGameCode, getProductParams } from '../../../utils/digitalCodesUtils';
import { displayString } from '../../../utils/stringUtils';


const {
	PENDING_REVIEW,
	REVIEWED,
} = digitalCodesConstants.priceRequestStatuses;

interface DPReviewTabProps {
	eventKey: string;
}
const DPReviewTab = ({ eventKey }: DPReviewTabProps) => {
	const show = useContext(TabState)?.tab === eventKey;
	const requestsQuery = useQuery('getDPReviewQueue', () => getDPReviewQueue(), { enabled: show });
	const [gameCodes, platformParams] = getProductParams(requestsQuery.data?.data);
	const productsQuery = useQuery(
		['getProductsInBulk', gameCodes, ['game_name', 'game_code'], platformParams.toString()], 
		() => getProductsInBulk(gameCodes, ['game_name', 'game_code'], platformParams), 
		{ enabled: requestsQuery.isSuccess }
	);
	const requests = requestsQuery.data?.data;

	requests?.map((request: Record<string, any>) => {
		request.product_name = productsQuery.data?.data.find(
			(product: ProductData) =>
				product.game_code === getProductGameCode(request)
		)?.game_name;
	});
	const isLoading = requestsQuery.isLoading || productsQuery.isLoading;

	const columns = useMemo(() => [
		{ Header: 'Publisher', accessor: 'company_name', minWidth: 150 },
		{
			Header: 'Product',
			id: 'product_column',
			accessor: (row: Record<string, any>) => {
				const game_code = getProductGameCode(row);
				return `[${game_code.substring(game_code.length - 5)}] ${row.product_name}`;
			},
			minWidth: 200,
		},
		{
			Header: 'Component',
			id: 'component_column',
			accessor: ({ ns_uid, game_name }: { ns_uid: string, game_name: string }) =>
				`[${ns_uid}] ${game_name}`,
			minWidth: 200,
		},
		{ Header: 'Certified DP', accessor: 'certified_price', Cell: CurrencyCell },
		{ Header: 'Received', accessor: 'submitted_datetime', Cell: DateCell },
		{
			Header: 'Status',
			accessor: 'request_status',
			Cell: ({ value }: { value: string }) => <StatusText>{value}</StatusText>,
		},
		{
			...linkColumn,
			Cell: ({ original }: { original: Record<string, any> }) => {
				const newPath = `/products/components/${original.ns_uid}?tab=declared-price-requests`;
				return (
					<Link to={newPath}>
						<FAIcon name="chevron-right" />
					</Link>
				);
			},
		},
	], [productsQuery.data?.data]);

	const filterProperties = useMemo(() => new Map(
		Object.entries({
			'Status' : {
				filter: 'request_status',
				selectableFilters: new Set([
					displayString(PENDING_REVIEW),
					displayString(REVIEWED),
				])
			}
		})
	), []);

	return (
		<Page.ContentContainer>
			<SectionTitle>Declared Price Requests</SectionTitle>
			{isLoading ? (
				<Loading />
			) : (
				<FilterableTable
					data={requests}
					dataFormat={columns}
					filterProperties={filterProperties}
					searchableFields={['company_name', 'ns_uid', 'game_name', 'game_code', 'product_name', 'parent_component_game_code']}
					searchableFieldPlaceHolder={'Search by Publisher, Product, Component'}
					defaultSorted={[
						{
							id: 'modified_date',
							desc: true,
						},
					]}
					noBorder
					retainPageState
					stateOnTab={eventKey}
				/>
			)}
		</Page.ContentContainer>
	);
};
export default DPReviewTab;
