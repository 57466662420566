import dayjs, { Dayjs } from 'dayjs';
import { timeZoneConstants } from '../constants/timeZoneConstants';

export const dateFormat = {
	DATE: 'MM/DD/YYYY',
	DATE_JP: 'YYYY/MM/DD',
	DATE_YMD: 'YYYY-MM-DD',
	DATETIME_PT: 'MM/DD/YYYY hh:mm A [PT]',
	DATETIME_JP: 'YYYY/MM/DD hh:mm A [JST]',
	TIME_JP: 'hh:mm A [JST]',
	ISO8601: 'YYYY-MM-DDTHH:mm:ssZ',
} as const;

/**
 * Get the current time value.
 *
 * @returns current datetime as moment object
 */
export function now(): Dayjs {
	return dayjs();
}

/**
 * Get the current date as an ISO date string.
 *
 * @param timezone specify a timezone locale, defaults to America/Los_Angeles
 * @returns current date as ISO date string
 */
export function today(timezone: string = timeZoneConstants.PACIFIC): string {
	return dayjs().tz(timezone).startOf('day').format(dateFormat.DATE_YMD);
}

/**
 * Formats a date.
 *
 * @param date the date (a moment)
 * @param dateFormat the format string, defaults to ISO 8601 if unspecified
 * @param onInvalid the value to return when the date is invalid
 * @param defaultValue the value return when the date is null
 * @returns the formatted date
 */
export function formatDate(
	date: string | Dayjs | Date | undefined, 
	dateFormat: string = 'MM/DD/YYYY', 
	onInvalid: any = '', 
	defaultValue: any = ''
) {
	if (date) {
		if (typeof date === 'string') {
			date = parseDateString(date);
		} else {
			date = dayjs(date);
		}
		if (typeof date === 'object' && date.isValid()) {
			return date.format(dateFormat);
		} else {
			return onInvalid;
		}
	} else {
		return defaultValue;
	}
}

/**
 * Parses a date string into a Dayjs object, converting the time zone as specified.
 *
 * @param dateString the date string
 * @param fromTZ the source time zone
 * @param toTZ the target time zone (if <code>null</code>, no time zone conversion is performed)
 * @returns the date, converted to the target time zone
 */
export function parseDateString(
	dateString: string = '', 
	fromTZ: string = '', 
	toTZ: string = timeZoneConstants.PACIFIC
): Dayjs | undefined {
	if (!dateString) {
		return;
	}
	let date;
	// set default time zone to UTC if string is not an ISO date-only string
	fromTZ = fromTZ || (/^\d{4}-\d{2}-\d{2}$/.test(dateString) ? toTZ : timeZoneConstants.UTC);
	date = dayjs.tz(dateString, fromTZ);
	toTZ && (date = date.tz(toTZ));
	return date;
}

/**
 * Compares two dates, and returns a value  of -1, 0 or 1, suitable for array
 * sort functions
 *
 * @param a The first date.
 * @param b The second date. Default value (when b == null) is now or today
 * @param ignoreTime Control if time values are considered in comparison
 * @returns -1 if a is before b, 0 if a and b are equivalent, 1 if a is after b
 */
export function compareDates(
	a: string | number | Dayjs | Date, 
	b?: string | number | Dayjs | Date, 
	ignoreTime: boolean = false
): 0 | 1 | -1 {
	let d1 = dayjs(a);
	let d2 = dayjs(b);

	if (ignoreTime) {
		d1 = d1.startOf('day');
		d2 = d2.startOf('day');
	}

	if (d1.isSame(d2)) {
		return 0;
	} else if (d1.isAfter(d2)) {
		return 1;
	}

	return -1;
}

export function ensureDate(value: string | Dayjs | Date): null | Date | Dayjs {
	if (value == null) {
		return null;
	} else if (value && typeof value === 'object' && 'toISOString' in value) {
		return value;
	} else if (!isNaN(Date.parse(value))) {
		return new Date(value);
	}
	return null;
}

export function isDateInRange(
	inDate: Dayjs | Date, 
	startDate?: Dayjs | Date | null, 
	endDate?: Dayjs | Date | null
): boolean {
	const endOk = !(endDate && inDate >= endDate);
	const startOk = !(startDate && inDate < startDate);

	return endOk && startOk;
}

/**
 * Tests if two time periods overlap. Values to be parsed by new Date()
 * @param  {} a start time for first time segment
 * @param  {} b end time for first time segment
 * @param  {} c start time for second time segment
 * @param  {} d end time for second time segment
 */
export function doPeriodsIntersect(
	a: string | number | Date | Dayjs, 
	b: string | number | Date | Dayjs, 
	c: string | number | Date | Dayjs, 
	d: string | number | Date | Dayjs
): boolean {
	[a, b, c, d] = [a, b, c, d].map((x) => typeof x === 'object' && 'valueOf' in x ? x : new Date(x));
	const sortfunc = (a: Date | Dayjs, b: Date | Dayjs) => a.valueOf() - b.valueOf();
	const periodA = [a, b].sort(sortfunc);
	const periodB = [c, d].sort(sortfunc);
	return Math.max(periodA[0].valueOf(), periodB[0].valueOf()) <= Math.min(periodA[1].valueOf(), periodB[1].valueOf());
}

/**
 * Returns the milliseconds for the time unit inputted
 * @param  {'second'|'minute'|'hour'|'day'} unit 
 */
export function toMS(unit: 'second' | 'minute' | 'hour' | 'day') {
	switch (unit) {
		case 'second':
			return 1000;
		case 'minute':
			return 1000 * 60;
		case 'hour':
			return 1000 * 60 * 60;
		case 'day':
			return 1000 * 60 * 60 * 24;
		default:
	} 
	return 0;
}
