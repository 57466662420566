import React, { Component } from 'react';
import { Col, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap';

import AsperaFileUpload from '../../../components/AsperaFileUpload/AsperaFileUpload';
import BaseModal from '../../../components/BaseModal/BaseModal';
import { assetConst } from '../../../constants/assetConstants';
import { allowedFileExtensions, fileTransferConst } from '../../../constants/fileTransferConstants';
import { postAssetStatus } from '../../../services/productsService';
import { doAllFilesHaveExtension } from '../../../utils/assetUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';

import '../AssetDetails.css';


function getTitle() {
	return 'Upload Print Proof';
}

function determineStatus() {
	return assetConst.STATUS.PRINT_PROOF_UPLOADING;
}

export default class UploadPrintProofModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			display: true,
			comment: '',
			isFilesSelected: false,
			revisionId: null,
			isUploadInitiated: false
		};
		this.updateFilesSelected = this.updateFilesSelected.bind(this);
		this.onComplete = this.onComplete.bind(this);
		this.validateFileType = this.validateFileType.bind(this);
	}

	updateAssetStatus() {
		const { assetId } = this.props;
		const { comment, selectedFiles } = this.state;
		const payload = {
			review_status: determineStatus(),
			file_upload_type: assetConst.UPLOAD.PROOF,
			comment: comment,
			files: selectedFiles,
		};

		this.setState({
			isProcessing: true
		});

		postAssetStatus(assetId, payload)
			.then((response) => {
				if (response.status === 200) {
					toasterNotify('Print Proof Submitted', 'success');

					this.setState({
						revisionId: response.data.revision_id.toString(),
						isUploadInitiated: true,
						prefetchedTransferSpecs: response.data.transfer_specs && {
							transfer_specs: response.data.transfer_specs,
						},
					});
				}
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'submitting proof'),
					'error',
					error
				);
			});
	}

	onComplete() {
		const { productId, assetId, closeModal, loadAssetDetails } = this.props;

		loadAssetDetails(productId, assetId);
		closeModal();
	}

	updateFilesSelected(isFilesSelected) {
		this.setState({
			isFilesSelected: isFilesSelected
		});
	}

	validateFileType(selectedFiles) {
		const allowedFileTypes = allowedFileExtensions.PACKAGING_ASSET;
		const isValid = doAllFilesHaveExtension(selectedFiles, allowedFileTypes);
		this.setState({
			validFileSelected: isValid,
			selectedFiles: isValid && selectedFiles,
		});
	}

	render() {
		const { closeModal, userProfile } = this.props;
		const {
			display,
			isProcessing,
			comment,
			revisionId,
			isUploadInitiated,
			isFilesSelected,
			validFileSelected,
			prefetchedTransferSpecs,
		} = this.state;

		return (
			<BaseModal show={display} onCancel={closeModal} isSubmitting={isProcessing}>
				<BaseModal.Title>{getTitle()}</BaseModal.Title>
				<div className="approve-asset-modal">
					<FormGroup as={Row}>
						<FormLabel id="title" className="col-sm-4 text-sm-right">
							<div className="approve-asset-label">Attach File(s)</div>
						</FormLabel>
						<Col sm={6}>
							<AsperaFileUpload
								updateFilesSelected={this.updateFilesSelected}
								userProfile={userProfile}
								entityType={fileTransferConst.ENTITY_TYPE.PRODUCT_ASSET_REVISION}
								entityId={revisionId}
								isUploadInitiated={isUploadInitiated}
								onCompleteFunction={this.onComplete}
								allowedFileTypes={[
									{
										filter_name: 'Accepted Files',
										extensions: allowedFileExtensions.PACKAGING_ASSET,
									},
								]}
								validateFileType={this.validateFileType}
								prefetchedTransferSpecs={prefetchedTransferSpecs}
							/>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<FormLabel id="title" className="col-sm-4 text-sm-right">
							<div className="approve-asset-label">Comments:</div>
						</FormLabel>
						<Col sm={8}>
							<FormControl
								as="textarea"
								rows="6"
								value={comment}
								onChange={(e) => {
									this.setState({
										comment: e.target.value,
									});
								}}
								maxLength={2000}
							/>
						</Col>
					</FormGroup>
				</div>
				<BaseModal.Submit
					disabled={isProcessing || !isFilesSelected || !validFileSelected}
					onClick={() => {
						this.updateAssetStatus();
					}}
				>
					Confirm
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}
