import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { digitalCodesConstants } from '../../../constants/digitalCodesConstants';
import { useUserProfile } from '../../../hooks/reduxHooks';
import { getPermissions } from '../../../utils/digitalCodesUtils';
import { isUploadEligible, modalIdentifiers } from '../DigitalCodesDetail.helpers';
import { companyAgreementConstants } from '../../../constants/companyAgreementConstants';
import { platformCodes } from '../../../constants/platformConstants';
import { validateActiveAgreementType } from '../../../utils/companyUtils';

const {
	DRAFT,
	READY_FOR_REVIEW,
	READY_FOR_APPROVAL,
	PAYMENT_PENDING,
	PAYMENT_RECEIVED,
	UPLOAD_IN_PROGRESS,
	UPLOAD_FAILED,
	CODES_AVAILABLE,
	COMPLETE,
	AWAITING_PAYMENT_INFO,
} = digitalCodesConstants.status;

// modal identifiers
const {
	CANCEL_ORDER_MODAL,
	REVIEWER_APPROVAL_MODAL,
	APPROVER_CONFIRM_APPROVAL_MODAL,
	CANCEL_UPLOAD_MODAL,
	COMPLETE_ORDER_MODAL,
	REJECT_REASON_MODAL,
	RESEND_ORDER_MODAL,
	ASPERA_UPLOAD_CODES_MODAL,
	MANAGE_ACTIVATION_MODAL,
	RETRACT_CODES_MODAL,
} = modalIdentifiers;

const DigitalCodesButtons = ({ showModal, orderData, agreements }) => {
	const { status, orderID, isCodesFileAvailable } = orderData;
	const userProfile = useUserProfile();
	const {
		canReview,
		canUploadCodes,
		canDownloadCodes,
		canApprove,
		canCancelOrder,
		canCreateOrder,
		canCompleteOrder,
		canManageActivation,
		canRetractCodes,
	} = getPermissions(userProfile);

	const buttons = [];

	// Reviewer approval button
	canReview &&
		[READY_FOR_REVIEW].includes(status) &&
		buttons.push(
			<Button
				key="approve-reviewer-button"
				data-testid="approve-reviewer"
				variant="success"
				onClick={() => showModal(REVIEWER_APPROVAL_MODAL)}
			>
				Approve
			</Button>,
		);

	// Approver approval button
	canApprove &&
		[READY_FOR_APPROVAL].includes(status) &&
		buttons.push(
			<Button
				key="approve-approver-button"
				data-testid="approve-approver"
				variant="success"
				onClick={() => showModal(APPROVER_CONFIRM_APPROVAL_MODAL)}
			>
				Approve
			</Button>,
		);

	// Reviewer/Approver reject button
	((canReview && status === READY_FOR_REVIEW) || (canApprove && status === READY_FOR_APPROVAL)) &&
		buttons.push(
			<Button
				key="reject-button"
				variant="danger"
				onClick={() => showModal(REJECT_REASON_MODAL)}
			>
				Reject
			</Button>,
		);

	// Resend order to EBS button
	canReview &&
		[PAYMENT_PENDING].includes(status) &&
		!orderData.ebsSalesOrderNum &&
		buttons.push(
			<Button
				key="resend-button"
				variant="primary"
				onClick={() => showModal(RESEND_ORDER_MODAL)}
			>
				Resend Order
			</Button>,
		);

	// cancel in progress upload
	canUploadCodes &&
		[UPLOAD_IN_PROGRESS].includes(status) &&
		buttons.push(
			<Button
				key="cancel-upload-button"
				variant="danger"
				onClick={() => showModal(CANCEL_UPLOAD_MODAL)}
			>
				Cancel Upload
			</Button>,
		);

	// retract code that have been uploaded
	canRetractCodes &&
		[CODES_AVAILABLE, COMPLETE].includes(status) &&
		!!isCodesFileAvailable &&
		buttons.push(
			<Button
				key="retract-upload-button"
				variant="danger"
				onClick={() => showModal(RETRACT_CODES_MODAL)}
			>
				Retract Upload
			</Button>,
		);

	// cancel order
	canCancelOrder &&
		[READY_FOR_REVIEW, READY_FOR_APPROVAL, AWAITING_PAYMENT_INFO].includes(status) &&
		buttons.push(
			<Button
				key="cancel-order-button"
				variant="danger"
				onClick={() => showModal(CANCEL_ORDER_MODAL)}
			>
				Cancel Order
			</Button>,
		);

	// complete
	canCompleteOrder &&
		[PAYMENT_RECEIVED].includes(status) &&
		!orderData.is_upload_eligible &&
		buttons.push(
			<Button
				key="complete-order-button"
				variant="primary"
				onClick={() => showModal(COMPLETE_ORDER_MODAL)}
			>
				Complete Order
			</Button>,
		);

	// manage activation
	canManageActivation &&
		!!isCodesFileAvailable &&
		buttons.push(
			<Button
				key="manage-activation-button"
				variant={orderData.activation_date ? 'outline-secondary' : 'primary'}
				onClick={() => showModal(MANAGE_ACTIVATION_MODAL)}
			>
				Manage Activation
			</Button>,
		);

	// upload codes
	canUploadCodes &&
		[PAYMENT_RECEIVED, UPLOAD_FAILED].includes(status) &&
		isUploadEligible(orderData) &&
		buttons.push(
			<Button
				key="upload-codes-button"
				variant="primary"
				onClick={() => showModal(ASPERA_UPLOAD_CODES_MODAL)}
			>
				Upload Codes
			</Button>,
		);

	// download codes
	canDownloadCodes &&
		[CODES_AVAILABLE, COMPLETE].includes(status) &&
		!!isCodesFileAvailable &&
		buttons.push(
			<Button
				key="download-codes-button"
				variant="primary"
				href={`https://${global.config.apiHost}${global.config.apiBasePath}/dc/v1_1/orders/${orderID}/codes`}
			>
				Download Codes
			</Button>,
		);

	// edit order
	canCreateOrder &&
		status === DRAFT &&
		validateActiveAgreementType(
			agreements || [],
			companyAgreementConstants.TYPE.DIGITAL,
			platformCodes['Nintendo Switch'],
			true,
		) &&
		buttons.push(
			<Button
				as={Link}
				to={`/orders/digital-codes/${orderID}/create`}
				key="edit-order-button"
				variant="primary"
			>
				Edit Order
			</Button>,
		);

	return buttons;
};

export default DigitalCodesButtons;
