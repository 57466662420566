import React, { Component } from 'react';
import { Col, Form, FormGroup, FormLabel, Row } from 'react-bootstrap';

import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import * as componentUtils from '../../../utils/componentUtils';
import { getOrderableProducts, postOrderDetails } from '../../../services/ordersService';
import BaseModal from '../../../components/BaseModal/BaseModal';

class EditOrderItemVersionModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			display: true,
			orderDetails: props.orderDetails
		};
		this.updateOrderDetailVersions = this.updateOrderDetailVersions.bind(
			this
		);
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	static createItemVersionFormGroup(
		gameTitle,
		options,
		handleInputChange,
		defaultValue
	) {
		return (
			<FormGroup as={Row}>
				<FormLabel column sm={4} className="text-sm-right">{gameTitle}</FormLabel>
				<Col>
					<select
						value={defaultValue}
						className={'form-control'}
						onChange={(e) => {
							handleInputChange('submissions_id', e, gameTitle);
						}}
					>
						{options}
					</select>
				</Col>
			</FormGroup>
		);
	}

	componentDidMount() {
		this.getAvailableProducts();
	}

	handleInputChange(name, event, gameTitle) {
		let payload = this.state.orderDetails;

		const orderDetail = payload.find((x) => x.product_name === gameTitle);

		const version = event.currentTarget[
			event.currentTarget.selectedIndex
		].label.replace('v', '');
		let versions = version.split('.');

		orderDetail.submission_id = event.currentTarget.value;
		orderDetail.product_release_version = versions[0];
		orderDetail.submission_version = versions[1];
		this.setState({
			orderDetails: payload
		});
	}

	getAvailableProducts() {
		const { ndid_company_id } = this.props;
		getOrderableProducts(ndid_company_id)
			.then((response) => {
				this.setState({
					availableProducts: response.data
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving available products'),
					'error',
					error
				);
			})
			.finally(() => {
				this.setState({
					isLoading: false
				});
			});
	}

	updateOrderDetailVersions() {
		this.setState({ isSubmitting: true });
		const { orderHeaderId, platformCode } = this.props;
		const payload = {
			order_details: this.state.orderDetails,
			order_header_id: orderHeaderId,
			platform_code: platformCode
		};
		postOrderDetails(payload)
			.then((response) => {
				toasterNotify('Order Updated', 'success');
				this.props.getOrder();
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'updating order'),
					'error',
					error
				);
			})
			.finally(() => {
				this.props.toggleModal();
				this.props.updateOrderDetailVersions(this.state.orderDetails);
			});
	}

	render() {
		const orderDetails = [];
		if (!this.state.isLoading) {
			this.state.orderDetails.forEach((orderDetail) => {
				const productVersions = this.state.availableProducts.find(
					(x) => x.game_name === orderDetail.product_name
				).versions;
				let versions = [];
				productVersions.forEach((productVersion) => {
					let version = [];
					version.label = `v${productVersion.release_version}.${productVersion.submission_version}`;
					version.value = productVersion.submission_id;
					versions.push(version);
				});
				let options = componentUtils.generateOptions(versions);
				orderDetails.push(
					EditOrderItemVersionModal.createItemVersionFormGroup(
						orderDetail.product_name,
						options,
						this.handleInputChange,
						orderDetail.submission_id
					)
				);
			});
		}
		return (
			<BaseModal
				show={this.state.display}
				onCancel={this.props.toggleModal}
				isLoading={this.state.isLoading}
				isSubmitting={this.state.isSubmitting}
			>
				<BaseModal.Title>Edit Item Version</BaseModal.Title>
				<Form>{orderDetails}</Form>
				<BaseModal.Submit onClick={this.updateOrderDetailVersions}>Update</BaseModal.Submit>
			</BaseModal>
		);
	}
}

export default EditOrderItemVersionModal;
