import React, { VFC, useEffect, useState } from 'react';
import { FormCheck } from 'react-bootstrap';
import * as yup from 'yup';

import AsperaFileUpload from '../../../components/AsperaFileUpload/AsperaFileUpload';
import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import StatusText from '../../../components/StatusText/StatusText';
import { assetConst } from '../../../constants/assetConstants';
import { allowedFileExtensions, fileTransferConst } from '../../../constants/fileTransferConstants';
import { timeZoneConstants } from '../../../constants/timeZoneConstants';
import { postAssetStatus } from '../../../services/productsService';
import { doAllFilesHaveExtension, fileNameHasGameCode } from '../../../utils/assetUtils';
import { isEmptyObject } from '../../../utils/dataUtils';
import { today } from '../../../utils/dateUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { validateToSchema } from '../../../utils/validationUtils';

const GAME_CODE_PLACEHOLDER = '%GAME_CODE%';

const schema = yup.object().shape({
	anticipated_proof_date: yup.string().required('An Anticipated Proof Date is required'),
	files: yup.array().when('$withChanges', {
		is: true,
		then: (schema) =>
			schema
				.test(
					'game_code_check',
					`Selected file must include the game code (${GAME_CODE_PLACEHOLDER}) in the file's name`,
					(value: any, { options }: yup.TestContext<any>) =>
						value?.every(
							(item: AsperaFile) =>
								fileNameHasGameCode(item.file_name, options?.context?.gameCode)
						),
				)

				.test(
					'length_check',
					'A changed label file is required if NCL accepting with changes',
					(value) => !!value && value.length > 0,
				),
	}),
});

interface NCLAcceptModalProps {
    show: boolean;
    onClose: () => void;
    onSuccess: () => void;
    assetId?: number;
	gameCode?: string;
}
const NCLAcceptModal: VFC<NCLAcceptModalProps> = ({ show, onClose, onSuccess, assetId, gameCode}) => {
	const [formValues, setFormValues] = useState<Record<string,any>>({});
	const [withChanges, setWithChanges] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);

	const [isUploadInitiated, setIsUploadInitiated] = useState<boolean>();
	const [selectedFiles, setSelectedFiles] = useState<AsperaFile[]>();
	const [transferSpecs, setTransferSpecs] = useState<{ transfer_specs: AsperaUploadSpec[] }>();
	const [revisionId, setRevisionId] = useState<number>();

	useEffect(() => {
		if (show) {
			setWithChanges(false);
			setFormValues({});
			setIsSubmitting(false);
			setIsUploadInitiated(false);
			setTransferSpecs(undefined);
			
			setSelectedFiles(undefined);
			setShowAllErrors(false);
			setRevisionId(undefined);
		}
	}, [show]);

	const validationErrors = validateToSchema(
		schema,
		{ ...formValues, files: selectedFiles },
		{ withChanges, gameCode },
	);
	if (validationErrors.files && gameCode) {
		validationErrors.files = validationErrors.files.replace(GAME_CODE_PLACEHOLDER, gameCode);
	}

	const submitApproval = async () => {
		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}
		setIsSubmitting(true);
		const payload = withChanges ? {
			review_status: assetConst.NCL_FINAL_LABEL_STATUS.NCL_ACCEPTED_FILE_UPLOADING,
			file_upload_type: assetConst.UPLOAD.NCL_MODIFIED_FINAL_LABEL,
			files: selectedFiles,
			anticipated_proof_date: formValues.anticipated_proof_date,
			content_type :'application/pdf',

		} : {
			review_status: assetConst.NCL_FINAL_LABEL_STATUS.NCL_ACCEPTED,
			anticipated_proof_date: formValues.anticipated_proof_date,
		};
		try {
			const response = await postAssetStatus(String(assetId), payload);
			toasterNotify(
				response.data.transfer_specs ? (
					<>
						Status will be <StatusText>NCL ACCEPTED WITH CHANGES</StatusText> after file
						transfer completes
					</>
				) : (
					<>
						Status has been changed to <StatusText>NCL ACCEPTED</StatusText>
					</>
				),
				'success',
			);
			if (response.data.transfer_specs) {
				setTransferSpecs({
					transfer_specs: response.data.transfer_specs,
				});
				setRevisionId(response.data.revision_id);
				setIsUploadInitiated(true);
			}
			onSuccess();
			onClose();
		} catch (error: unknown) {
			if (error instanceof Error && 'isAxiosError' in error && error.isAxiosError) {
				toasterNotify(
					createMessageForError(error, 'submitting file(s)'),
					'error',
					error
				);
			} else {
				throw error;
			}
		} finally {
			setIsSubmitting(false);
		}
	};
	
	return (
		<BaseModal show={show} onCancel={onClose} isSubmitting={isSubmitting}>
			<BaseModal.Title>NCL Accept</BaseModal.Title>
			<Forms
				values={formValues}
				onChange={(newValues) => setFormValues(newValues)}
				showAllErrors={showAllErrors}
				validationErrors={validationErrors}
			>
				<Forms.DateSelect
					id="anticipated_proof_date"
					// current date on server is keyed to UTC
					minDate={new Date(today(timeZoneConstants.UTC) + 'T00:00:00')}
					dateFormat={'yyyy/MM/dd'}
				>
					<Forms.Heading>Anticipated Proof Date</Forms.Heading>
				</Forms.DateSelect>

				<Forms.CustomArea id="files">
					<Forms.Heading>Action</Forms.Heading>
					<FormCheck id="no-change-option">
						<FormCheck.Input
							name="changes"
							type="radio"
							onChange={() => setWithChanges(false)}
							checked={!withChanges}
						/>
						<FormCheck.Label>NCL Accept as received</FormCheck.Label>
					</FormCheck>
					<FormCheck id="add-change-option">
						<FormCheck.Input
							name="changes"
							type="radio"
							onChange={() => setWithChanges(true)}
							checked={withChanges}
						/>
						<FormCheck.Label>NCL Accept with changes (attach file)</FormCheck.Label>
					</FormCheck>
					<div className="form-check mt-2">
						<AsperaFileUpload
							disabled={!withChanges}
							updateFilesSelected={() => null}
							entityType={fileTransferConst.ENTITY_TYPE.PRODUCT_ASSET_REVISION}
							entityId={revisionId}
							isUploadInitiated={isUploadInitiated}
							allowedFileTypes={[
								{
									filter_name: 'Accepted Files',
									extensions: allowedFileExtensions.PACKAGING_ASSET,
								},
							]}
							validateFileType={(files: AsperaFile[]) => {
								setSelectedFiles(files);
								const allowedFileTypes = allowedFileExtensions.PACKAGING_ASSET;
								if (doAllFilesHaveExtension(files, allowedFileTypes)) {
									return true;
								}
								return false;
							}}
							prefetchedTransferSpecs={transferSpecs}
							singleFileOnly
						/>
					</div>
					{withChanges && <Forms.Help>Selected file must include game code ({gameCode}) in the file name.</Forms.Help>}
				</Forms.CustomArea>
			</Forms>
			<BaseModal.Submit onClick={() => submitApproval()}></BaseModal.Submit>
		</BaseModal>
	);
};
export default NCLAcceptModal;
