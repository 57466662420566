import { permConst } from '../../../constants/permConst';
import { isAuthorized } from '../../../utils/userUtils';

export const getPermissions = (userProfile) => ({
	canSeeDraftEvents: isAuthorized(userProfile.permissions, [
		permConst.MARKETING_EVENT.VIEW.MANAGER,
		permConst.MARKETING_EVENT.VIEW.COORDINATOR,
	]),
	canViewEventDetails: isAuthorized(userProfile.permissions, [
		permConst.MARKETING_EVENT.DETAILS.VIEW
	]),
	canViewProductDetails: isAuthorized(userProfile.permissions, [
		permConst.MARKETING_EVENT.PRODUCT.VIEW
	])
});
