import React, {useState, useEffect, VFC, useMemo} from 'react';
import { useQuery, useQueryClient } from 'react-query';
import * as yup from 'yup';

import BaseModal from '../../../components/BaseModal/BaseModal';
import { getAssignableRoles, getUsersRoles, putUserRoles, UserModel } from '../../../services/usersService';
import EditUserNotice from './EditUserNotice';
import { toasterNotify } from '../../../utils/toaster';
import Forms from '../../../components/Forms/Forms';
import { isEmptyObject } from '../../../utils/dataUtils';
import { validateToSchema } from '../../../utils/validationUtils';

const schema = yup.object().shape({
	checkedRoles: yup.array().min(1, 'At least one role needs to be selected').required('At least one role needs to be selected'),
});

interface formValuesModel {
	checkedRoles?: string[];
}

interface EditUserRolesModalProps {
	show: boolean;
	onCompletion: () => void;
	onClose: () => void;
	userManagementData?: UserModel;
}

const EditUserRolesModal: VFC<EditUserRolesModalProps> = ({show, onCompletion, onClose, userManagementData}) => {
	const queryClient = useQueryClient();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [formValues, setFormValues] = useState<formValuesModel>({});
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);
	const validationErrors = validateToSchema(schema, formValues);
	const ndidUserId = userManagementData && userManagementData.ndid_user_id;
	const rolesQuery = useQuery('getAssignableRoles', () => getAssignableRoles({}),
		{ enabled: !!(show && ndidUserId) });
	const assignableRoles = rolesQuery.data?.data;
	const userQuery = useQuery(['getUsersRoles', ndidUserId], () => getUsersRoles(ndidUserId!),
		{ enabled: !!(show && ndidUserId) });
	const userRoles = userQuery.data?.data;

	useEffect(() => {
		if (show) {
			setIsSubmitting(false);
			setFormValues({checkedRoles: roles?.filter((role) => role.hasRole).map((role) => (String(role.role_id)))});
		}
	}, [show]);

	const roles = useMemo(() => {
		if (assignableRoles && userRoles) {
			const roles = assignableRoles.map(obj => ({ ...obj, hasRole: false, editable: true }));
			userRoles.forEach((userRole) => {
				let foundRole = roles.find(x => x.name === userRole.name);
				if (foundRole) {
					foundRole.hasRole = true;
				} else {
					roles.push({
						'role_id': userRole.role_id,
						'name': userRole.name,
						'hasRole': true,
						'editable': false
					});
				}
			});
			roles.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
			setFormValues({checkedRoles: roles?.filter((role) => role.hasRole).map((role) => (String(role.role_id)))});
			return roles;
		}
	}, [assignableRoles, userRoles]);

	const submitForm = () => {
		if (isSubmitting) {
			return;
		}

		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}
		if (!roles) {
			return;
		}
		setIsSubmitting(true);
		const updateRoles = roles.filter((role) => role.editable).map((role) => {
			return {
				role_id: role.role_id,
				has_role: formValues?.checkedRoles?.includes(String(role.role_id)) ? true : false
			};
		});
		putUserRoles(
			ndidUserId!,
			updateRoles
		)
			.then((response) => {
				if (response.status === 200) {
					toasterNotify('User roles updated', 'success');
				}
			})
			.catch((error) => {
				toasterNotify('An unexpected error occurred updating user roles', 'error', error);
			})
			.finally(() => {
				queryClient.invalidateQueries('getAssignableRoles');
				queryClient.invalidateQueries(['getUsersRoles', ndidUserId]);
				setIsSubmitting(false);
				onCompletion();
			});
	};

	const isLoading = roles ? false : true;

	return (
		<BaseModal
			show={show}
			onCancel={onClose}
			isLoading={isLoading}
			isSubmitting={isSubmitting}
		>
			<BaseModal.Title>Edit User</BaseModal.Title>

			<EditUserNotice />
			<Forms
				onChange={(v) => {
					setFormValues({ ...v });
				}}
				values={formValues}
				validationErrors={validationErrors}
				showAllErrors={showAllErrors}
				>
				<Forms.CustomArea>
					<Forms.Heading>NDID</Forms.Heading>
					{userManagementData?.login_id}
				</Forms.CustomArea>
				<Forms.CustomArea>
					<Forms.Heading>Name</Forms.Heading>
					{userManagementData?.user_name}
				</Forms.CustomArea>
				<Forms.CustomArea>
					<Forms.Heading>Email</Forms.Heading>
					{userManagementData?.email_address}
				</Forms.CustomArea>
				<Forms.CheckboxList id="checkedRoles">
					<Forms.Heading>Role(s)</Forms.Heading>
					{roles?.map((role) => (
						<Forms.Option
							value={String(role.role_id)}
							disabled={!role.editable}
							key={role.role_id}
						>
							{role.name}
						</Forms.Option>
					))}
				</Forms.CheckboxList>
			</Forms>
			<BaseModal.Submit
				onClick={() => {
					submitForm();
				}}
			>
				Update Role(s)
			</BaseModal.Submit>
		</BaseModal>
	);
};

export default EditUserRolesModal;
