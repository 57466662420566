import { AxiosResponse } from 'axios';

/**
 * @param  {} getter Async function (or a function that returns a promise) that retrieves a batch
 * of results. Called with this signature: (count, rowlimit, offset). count: true to request total
 * result count from server, rowlimit: results per query, offset: starting point for the current batch
 * @param  {} rowLimit=500 results per query
 */
type PaginatedResults<T = any> = {
	count: number;
	results: T[];
};
export type PaginatedCall<T = any> = (
	count: boolean,
	rowlimit: number,
	offset: number,
	otherParams?: Record<string, string>,
) => Promise<AxiosResponse<PaginatedResults<T>>>;

export async function pullAllResults<T = any>(getter: PaginatedCall, rowLimit: number = 500, otherParams: Record<string, string>): Promise<T[]> {
	const results = [];
	const initialResponse = await getter(true, rowLimit, 0, otherParams);
	results.push(...initialResponse.data.results);
	const totalCount = initialResponse.data.count;

	const moreTimesToCall = Math.ceil(totalCount / rowLimit) - 1;
	if (moreTimesToCall > 0) {
		const subsequentCalls = new Array(moreTimesToCall)
			.fill(undefined)
			.map((val, idx) => {
				const offset = idx * rowLimit + rowLimit;
				return getter(false, rowLimit, offset, otherParams);
			});
		const responses = await Promise.all(subsequentCalls);

		responses.forEach((response) => {
			results.push(...response.data.results);
		});
	}

	return results;
}

/**
 * Returns a promise that resolves after the specified time in MS. Useful for suspending execution
 * within an async function.
 * @param  {} interval time in milliseconds
 */
export const sleep = (interval: number): Promise<number> =>
	new Promise(resolve => setTimeout(resolve, interval));

export const getLoginUrl = ():string => {
	return `${global.config.apiUrl}/login?env=${global.config.authEnv}`;
};
