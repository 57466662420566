export const transferRightsConstants = {
	REQUEST_STATUS: {
		AWAITING_RECEIVING_PUBLISHER: 'AWAITING_RECEIVING_PUBLISHER',
		PENDING: 'PENDING',
		PROCESSED: 'PROCESSED',
		REJECTED_BY_APPROVER: 'REJECTED_BY_APPROVER',
		REJECTED_BY_REVIEWER: 'REJECTED_BY_REVIEWER',
		RESCINDED: 'RESCINDED',
		REVIEWED: 'REVIEWED',
		REVOKED: 'REVOKED',
		OWNERSHIP_TRANSFERRED: 'OWNERSHIP_TRANSFERRED'
	} as const,
	TRANSFER_TYPE: {
		DISTRIBUTION: 'DISTRIBUTION',
		OWNERSHIP: 'OWNERSHIP',
	} as const,
	DISTRIBUTION_TYPE: {
		OWNERSHIP: 'OWNERSHIP',
		RETAIL: 'RETAIL_PUB',
		DIGITAL: 'DIGITAL_PUB',
	} as const,
} as const;
