import React from 'react';
import { Alert, Col, Form, FormControl, FormGroup } from 'react-bootstrap';

import BaseModal from '../../../components/BaseModal/BaseModal';
import { setMakerCode } from '../../../services/companiesService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';

import '../CompanyProfile.css';

export default class MakerCodeModal extends React.Component {
	constructor(props) {
		super(props);
		this.makerCodePass = 'Enter an alphanumeric maker code:';
		this.makerCodeFail = 'The entered maker code is not alphanumeric.';
		this.regExpAlphaNumeric = new RegExp('^[a-zA-Z0-9]*$');
		this.state = {
			display: true,
			skuRequiredChecked: this.props.skuChecked,
			makerCode: this.props.companyProfile.company_information.maker_code,
			alertMessage: this.makerCodePass,
			validMakerCode: false,
			isLoading: false
		};

		this.makerCodeChanged = this.makerCodeChanged.bind(this);
		this.setMakerCode = this.setMakerCode.bind(this);
		this.toggleLoading = this.toggleLoading.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	makerCodeChanged(event) {
		const makerCode = event.target.value;
		const validMakerCode = this.regExpAlphaNumeric.test(makerCode);
		const alertMessage = validMakerCode
			? this.makerCodePass
			: this.makerCodeFail;
		this.setState({
			makerCode,
			alertMessage,
			validMakerCode
		});
	}

	setMakerCode() {
		this.toggleLoading();
		const { companyProfile, updateMakerCode } = this.props;
		const { makerCode } = this.state;
		const companyId = companyProfile.company_information.ndid_company_id;
		setMakerCode(companyId, makerCode)
			.then(() => {
				updateMakerCode(makerCode);
				toasterNotify('Updated Maker Code', 'success');
			})
			.catch((error) => {
				toasterNotify(createMessageForError(error, 'updating maker code'), 'error', error);
			})
			.finally(() => {
				this.props.closeFunction();
			});
	}

	render() {
		const { makerCode, alertMessage, display, validMakerCode } = this.state;
		const { closeFunction } = this.props;
		return (
			<div>
				<BaseModal show={display} onCancel={closeFunction} isSubmitting={this.state.isLoading}>
					<BaseModal.Title>Maker code</BaseModal.Title>
					<div>
						<Alert variant="info">{alertMessage}</Alert>
						<Form
							className="makercode-form"
							onSubmit={(e) => {
								if (validMakerCode) {
									this.setMakerCode();
								}
								e.preventDefault();
								return false;
							}}>
							<FormGroup>
								<Col sm={12}>
									<strong>Maker Code:</strong>
								</Col>
								<Col sm={12}>
									<FormControl
										type="text"
										value={makerCode}
										placeholder="Enter alphanumeric maker code."
										onChange={this.makerCodeChanged}
									/>
								</Col>
							</FormGroup>
						</Form>
					</div>
					<BaseModal.Submit
						onClick={() => this.setMakerCode()}
						disabled={!validMakerCode}
					>
						Update
					</BaseModal.Submit>
				</BaseModal>
			</div>
		);
	}
}
