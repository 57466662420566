import React from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';


const LinkMenuItem = (props) => {
	const history = useHistory();
	return <Dropdown.Item
        onClick={(e) => {
            e.preventDefault();
            history.push(props.href);
        }}
        {...props}
    />;
};
export default LinkMenuItem;
