import { transferRightsConstants } from '../constants/transferRightsConstants';

export const transferDomains = {
	SUBMISSION: 'submissions',
	PRODUCT_SUMMARY: 'productSummary',
	ASSET_PACKAGING: 'packagingPackaging',
	ASSET_MARKETING: 'packagingMarketing',
	CODE_IN_BOX: 'codeInBox',
	PROJECTION: 'projections',
	ORDER: 'orders',
	PRICE_REQUEST: 'priceRequest',
	RETAIL_ACTIVATION: 'retailActivation',
} as const;

const { AWAITING_RECEIVING_PUBLISHER, PENDING, REVIEWED } = transferRightsConstants.REQUEST_STATUS;

export function determineActiveRightTransfer(
	product: { active_rights_transfer: ProductData['active_rights_transfer'] },
	domain: typeof transferDomains[keyof typeof transferDomains],
) {
	const activeTransfer = product.active_rights_transfer;

	if (
		!activeTransfer ||
		activeTransfer?.product_transfers?.length === 0 ||
		(activeTransfer.retail_transfer_flag !== 1 && activeTransfer.digital_transfer_flag !== 1)
	) {
		return false;
	} 
	switch (domain) {
		case transferDomains.PRODUCT_SUMMARY:
		case transferDomains.ASSET_MARKETING:
			return activeTransfer.product_transfers?.some(
				(transfer) =>
					transfer.transfer_type === transferRightsConstants.TRANSFER_TYPE.OWNERSHIP,
			);
		case transferDomains.SUBMISSION:
			return true;
		case transferDomains.RETAIL_ACTIVATION:
		case transferDomains.ASSET_PACKAGING:
		case transferDomains.ORDER:
		case transferDomains.PROJECTION:
			return !!activeTransfer.retail_transfer_flag;
		case transferDomains.CODE_IN_BOX:
			return !!activeTransfer.digital_transfer_flag;
		case transferDomains.PRICE_REQUEST:
			return activeTransfer.product_transfers.some(
				(transfer) =>
					transfer.transfer_type ===
						transferRightsConstants.TRANSFER_TYPE.DISTRIBUTION &&
					!!transfer.digital_transfer_flag &&
					[AWAITING_RECEIVING_PUBLISHER, PENDING, REVIEWED].some(
						(v) => v === transfer.transfer_status
					)
			);
		default:
	}
	return false;
}

