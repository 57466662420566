import {
	CHANGE_PLATFORM_LOAD_STATUS,
	SET_PLATFORMS,
	CHANGE_EVENT_CATEGORY_LOAD_STATUS,
	SET_EVENT_CATEGORIES,
	CHANGE_SUNSET_FEATURES_LOAD_STATUS,
	SET_SUNSET_FEATURES,
	CHANGE_HELP_TOPICS_LOAD_STATUS,
	SET_HELP_TOPICS,
} from './action-types';
import { loadingConstants } from '../../constants/loadingConstants';
import { getEventCategories } from '../../services/eventsService';
import { getPlatforms, getSunsetFeatures } from '../../services/platformsService';
import { dateFormat, formatDate, now, toMS } from '../../utils/dateUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { getHelpTopics } from '../../services/helpTopicsService';

export const setData = (type: string, payload: any) => ({
	type,
	payload: payload
});

export const changeLoadStatus = (type: string, status: string, correlationId?: string) => ({
	type,
	payload: {
		status,
		timestamp: formatDate(now(), dateFormat.ISO8601),
		correlationId
	}
});

const retrieveDataConstructor = (
	namespace: string, 
	getCall: () => Promise<AxiosResponse>, 
	setType: string, 
	changeLoadType: string, 
	description: string,
) => () => (
	async (dispatch: Dispatch<any>, getState: () => Record<string, any>) => {
		const referenceItem = getState().referenceReducer[namespace];
		const status = referenceItem?.meta?.status;
		const timestamp = referenceItem?.meta?.timestamp;
		const { LOADING, COMPLETED, INVALID_RESPONSE, NO_RESPONSE } = loadingConstants;

		const payloadValidator =  (data: any) => (data != null && typeof data === 'object');
		// data is already present, just resolve
		if (status === LOADING) {
			return;
		}
		// data is not considered stale, just resolve
		if (
			status === COMPLETED &&
			new Date(timestamp).valueOf() > new Date().valueOf() - 4 * toMS('hour')
		) {
			return;
		}

		dispatch(changeLoadStatus(changeLoadType, LOADING));
		try {
			const response = await getCall();
			const { data, headers } = response;
			if (payloadValidator(data)) {
				// validity check
				dispatch(setData(setType, data));
				dispatch(changeLoadStatus(changeLoadType, COMPLETED, headers['x-correlation-id']));
			} else {
				dispatch(setData(setType, null));
				dispatch(
					changeLoadStatus(changeLoadType, INVALID_RESPONSE, headers['x-correlation-id']),
				);
				const error: Error & { response?: AxiosResponse } = new Error(
					'Invalid data received for platforms. Try reloading, or contact site support for assistance.',
				);
				error.response = response;
				throw error;
			}
		} catch (error: any) {
			if (error.response) {
				const { headers } = error.response;
				dispatch(
					changeLoadStatus(changeLoadType, NO_RESPONSE, headers['x-correlation-id']),
				);
				toasterNotify(
					createMessageForError(error, `retrieving ${description}`),
					'error',
					error,
				);
			} else {
				dispatch(changeLoadStatus(changeLoadType, NO_RESPONSE));
				toasterNotify(
					createMessageForError(error, `retrieving ${description}`),
					'error',
					error,
				);
			}
		}
	}
);

export const fetchPlatforms = retrieveDataConstructor(
    'platforms',
    getPlatforms,
    SET_PLATFORMS,
    CHANGE_PLATFORM_LOAD_STATUS,
    'platform data'
);

export const fetchEventCategories = retrieveDataConstructor(
    'eventCategories',
    getEventCategories,
    SET_EVENT_CATEGORIES,
    CHANGE_EVENT_CATEGORY_LOAD_STATUS,
    'event category data'
);


export const fetchSunsetFeatures = retrieveDataConstructor(
	'sunsetFeatures',
	getSunsetFeatures,
	SET_SUNSET_FEATURES,
	CHANGE_SUNSET_FEATURES_LOAD_STATUS,
	'sunset features data'
);


export const fetchHelpTopics = retrieveDataConstructor(
	'helpTopics',
	getHelpTopics,
	SET_HELP_TOPICS,
	CHANGE_HELP_TOPICS_LOAD_STATUS,
	'help topics data'
);
