import {
	SET_USER_PROFILE,
	SET_FEATURE_FLAGS,
	SAVE_TAB_STATE,
	SAVE_UI_CONTROL_STATE,
} from './action-types';

export const setUserProfile = (userProfile: UserProfile) => ({
	type: SET_USER_PROFILE,
	payload: userProfile
});

interface FeatureFlags {
	status: {
		name: string;
		description?: string;
		alwaysOn?: boolean;
		defaultOn?: boolean;
		override?: boolean | null;
	}[]
}

export const setFeatureFlags = (flags: FeatureFlags) => ({
	type: SET_FEATURE_FLAGS,
	payload: flags
});

export const setUIState = (pathId: string, name: string, value: any) => ({
	type: SAVE_UI_CONTROL_STATE,
	pathId,
	name,
	value,
});

export const setTabState = (pathId: string, tab: string) => ({
	type: SAVE_TAB_STATE,
	pathId,
	tab,
});
