import React, { Component } from 'react';
import { Col, Form, FormGroup, Row } from 'react-bootstrap';
import * as editGameConfigurationModalConstants from './EditGameConfigurationModalConstants';
import * as componentUtils from '../../../../utils/componentUtils';
import * as arrayUtils from '../../../../utils/arrayUtils';
import SectionTitle from '../../../../components/SectionTitle/SectionTitle';

class GameConfigurationWii extends Component {
	constructor(props) {
		super(props);
		this.state = {
			payload: this.props.gameConfiguration
		};
	}

	getBody() {
		const { submissionDetails } = this.props;
		const RVL = this.state.payload;
		const numberOfPlayers = componentUtils.generateOptions(
			arrayUtils.getIntegerArrayStartOne(32)
		);
		const numberOfDiscs = componentUtils.generateOptions(
			arrayUtils.getIntegerArrayStartOne(4)
		);
		const wiiSystemMemoryUserBlocks = componentUtils.generateOptions(
			arrayUtils.getIntegerArrayStartOne(20)
		);
		const numberOfRemotesUsed = componentUtils.generateOptions(
			arrayUtils.getIntegerArrayStartOne(4)
		);
		const discTypes = componentUtils.generateOptions(
			editGameConfigurationModalConstants.discType
		);
		const wifiConnectionsTypes = componentUtils.generateOptions(
			editGameConfigurationModalConstants.nintendoWifiConnection
		);

		//Validate the current game configuration and update if invalid value
		if (
			!arrayUtils
				.getIntegerArrayStartOne(32)
				.includes(RVL.Features.NumberOfPlayers)
		) {
			this.props.handleInputChange(['Features', 'NumberOfPlayers'], 1);
		}

		if (
			!arrayUtils
				.getIntegerArrayStartOne(4)
				.includes(RVL.Features.NumberOfDiscs)
		) {
			this.props.handleInputChange(['Features', 'NumberOfDiscs'], 1);
		}

		if (
			!arrayUtils
				.getIntegerArrayStartOne(20)
				.includes(RVL.Features.WiiSystemMemoryUserBlocks)
		) {
			this.props.handleInputChange(
				['Features', 'WiiSystemMemoryUserBlocks'],
				1
			);
		}

		if (
			!arrayUtils
				.getIntegerArrayStartOne(4)
				.includes(RVL.ControllerConfiguration.NumberOfRemotesUsed)
		) {
			this.props.handleInputChange(
				['ControllerConfiguration', 'NumberOfRemotesUsed'],
				1
			);
		}

		if (
			!editGameConfigurationModalConstants.discType.includes(
				RVL.Features.DiscType
			)
		) {
			this.props.handleInputChange(
				['Features', 'DiscType'],
				editGameConfigurationModalConstants.discType[0]
			);
		}

		if (
			!editGameConfigurationModalConstants.nintendoWifiConnection.includes(
				RVL.Features.NintendoWiFiConnection
			)
		) {
			this.props.handleInputChange(
				['FeaturesUsed', 'NintendoWiFiConnection'],
				editGameConfigurationModalConstants.nintendoWifiConnection[0]
			);
		}

		return (
			<div>
				<Form className="edit_game_configuration_wii_form">
					<FormGroup as={Row}>
						<label
							id="submissionVersion"
							className="col-sm-4 control-label"
						>
							Submission Version
						</label>
						<Col sm={6}>
							{'v' +
								submissionDetails.product_release_version +
								'.' +
								submissionDetails.product_submission_version}
						</Col>
					</FormGroup>
					{componentUtils.getSelector(
						['Features', 'NumberOfPlayers'],
						'No of Players',
						numberOfPlayers,
						this.props.handleInputChange,
						RVL.Features.NumberOfPlayers
					)}
					{componentUtils.getSelector(
						['Features', 'NumberOfDiscs'],
						'No of Discs',
						numberOfDiscs,
						this.props.handleInputChange,
						RVL.Features.NumberOfDiscs
					)}
					{componentUtils.getSelector(
						['Features', 'DiscType'],
						'Disc Type',
						discTypes,
						this.props.handleInputChange,
						RVL.Features.DiscType
					)}
					{componentUtils.getCheckbox(
						['Features', 'SDCardSupport'],
						'SD Card Support',
						this.props.handleInputChange,
						RVL.Features.SDCardSupport
					)}
					{componentUtils.getCheckbox(
						['Features', 'ProgressiveScan'],
						'Progressive Scan',
						this.props.handleInputChange,
						RVL.Features.ProgressiveScan
					)}
					{componentUtils.getSelector(
						['Features', 'NintendoWiFiConnection'],
						'Nintendo Wi-Fi Connection',
						wifiConnectionsTypes,
						this.props.handleInputChange,
						RVL.Features.NintendoWiFiConnection
					)}
					{componentUtils.getCheckbox(
						['Features', 'WiiConnect24'],
						'Wii Connect24',
						this.props.handleInputChange,
						RVL.Features.WiiConnect24
					)}
					{componentUtils.getCheckbox(
						['Features', 'AddOnContent'],
						'Add On Content',
						this.props.handleInputChange,
						RVL.Features.AddOnContent
					)}
					{componentUtils.getTextBox(
						['Features', 'AddOnContentDescription'],
						'AOC Description',
						this.props.handleInputChange,
						RVL.Features.AddOnContentDescription
					)}
					{componentUtils.getSelector(
						['Features', 'WiiSystemMemoryUserBlocks'],
						'Wii System Memory User Blocks',
						wiiSystemMemoryUserBlocks,
						this.props.handleInputChange,
						RVL.Features.WiiSystemMemoryUserBlocks
					)}
					{componentUtils.getCheckbox(
						['Features', 'Dolby'],
						'Dolby',
						this.props.handleInputChange,
						RVL.Features.Dolby
					)}
					{componentUtils.getCheckbox(
						['Features', 'MobiclipSupport'],
						'Mobiclip Support',
						this.props.handleInputChange,
						RVL.Features.MobiclipSupport
					)}
					{componentUtils.getCheckbox(
						['Features', 'MessageBoardSupport'],
						'Message Board Support',
						this.props.handleInputChange,
						RVL.Features.MessageBoardSupport
					)}
					<SectionTitle>Controller Configuration</SectionTitle>
					{componentUtils.getYesNoRequired(
						['ControllerConfiguration', 'WiiRemoteUse'],
						'Wii Remote Use',
						this.props.handleInputChange,
						RVL.ControllerConfiguration.WiiRemoteUse
					)}
					{componentUtils.getSelector(
						['ControllerConfiguration', 'NumberOfRemotesUsed'],
						'NumberOfRemotesUsed',
						numberOfRemotesUsed,
						this.props.handleInputChange,
						RVL.ControllerConfiguration.NumberOfRemotesUsed
					)}
					{componentUtils.getYesNoRequired(
						['ControllerConfiguration', 'NunchukUse'],
						'Nunchuk Use',
						this.props.handleInputChange,
						RVL.ControllerConfiguration.NunchukUse
					)}
					{componentUtils.getYesNoRequired(
						['ControllerConfiguration', 'ClassicController'],
						'Classic Controller',
						this.props.handleInputChange,
						RVL.ControllerConfiguration.ClassicController
					)}
					{componentUtils.getSelector(
						['ControllerConfiguration', 'Pointer'],
						'Pointer',
						componentUtils.generateOptions([
							'Yes',
							'No',
							'Home menu only'
						]),
						this.props.handleInputChange,
						RVL.ControllerConfiguration.Pointer
					)}
					{componentUtils.getYesNoRequired(
						['ControllerConfiguration', 'MotionSensor'],
						'Motion Sensor',
						this.props.handleInputChange,
						RVL.ControllerConfiguration.MotionSensor
					)}
					{componentUtils.getSelector(
						['ControllerConfiguration', 'MotionSensorUse'],
						'Motion Sensor Use',
						componentUtils.generateOptions([
							'Remote',
							'Nunchuk',
							'Both'
						]),
						this.props.handleInputChange,
						RVL.ControllerConfiguration.MotionSensorUse
					)}
					{componentUtils.getYesNoRequired(
						['ControllerConfiguration', 'Rumble'],
						'Rumble',
						this.props.handleInputChange,
						RVL.ControllerConfiguration.Rumble
					)}
					{componentUtils.getYesNoRequired(
						['ControllerConfiguration', 'WiiRemoteMemory'],
						'Wii Remote Memory',
						this.props.handleInputChange,
						RVL.ControllerConfiguration.WiiRemoteMemory
					)}
					{componentUtils.getYesNoRequired(
						['ControllerConfiguration', 'WiiRemoteSpeaker'],
						'Wii Remote Speaker',
						this.props.handleInputChange,
						RVL.ControllerConfiguration.WiiRemoteSpeaker
					)}
					{componentUtils.getCheckbox(
						['ControllerConfiguration', 'MiiApproval'],
						'Mii Approval',
						this.props.handleInputChange,
						RVL.ControllerConfiguration.MiiApproval
					)}
					{componentUtils.getCheckbox(
						['ControllerConfiguration', 'WiiToDSDownload'],
						'Wii to DS Download',
						this.props.handleInputChange,
						RVL.ControllerConfiguration.WiiToDSDownload
					)}
					{componentUtils.getCheckbox(
						['ControllerConfiguration', 'GameCubeController'],
						'GameCube Controller',
						this.props.handleInputChange,
						RVL.ControllerConfiguration.GameCubeController
					)}
					{componentUtils.getCheckbox(
						['ControllerConfiguration', 'MiiUsage'],
						'Mii Usage',
						this.props.handleInputChange,
						RVL.ControllerConfiguration.MiiUsage
					)}
					{componentUtils.getCheckbox(
						['ControllerConfiguration', 'WiiBalanceBoardUse'],
						'Wii Balance Board Use',
						this.props.handleInputChange,
						RVL.ControllerConfiguration.WiiBalanceBoardUse
					)}
					{componentUtils.getSelector(
						['ControllerConfiguration', 'BalanceBoardPercentPlay'],
						'Balance Board Pct Play',
						componentUtils.generateOptions(['< 20%', '> 20%']),
						this.props.handleInputChange,
						RVL.ControllerConfiguration.BalanceBoardPercentPlay
					)}
					{componentUtils.getCheckbox(
						['ControllerConfiguration', 'WiiMotionPlusUse'],
						'Wii MotionPlus Use',
						this.props.handleInputChange,
						RVL.ControllerConfiguration.WiiMotionPlusUse
					)}
					{componentUtils.getYesNoRequired(
						['ControllerConfiguration', 'Microphone'],
						'Microphone',
						this.props.handleInputChange,
						RVL.ControllerConfiguration.Microphone
					)}
					{componentUtils.getCheckbox(
						['ControllerConfiguration', 'Headset'],
						'Headset',
						this.props.handleInputChange,
						RVL.ControllerConfiguration.Headset
					)}
					{componentUtils.getSelector(
						['ControllerConfiguration', 'HeadsetLibrary'],
						'Headset Library',
						componentUtils.generateOptions(['Nintendo', 'Other']),
						this.props.handleInputChange,
						RVL.ControllerConfiguration.HeadsetLibrary
					)}
					{componentUtils.getTextBox(
						['ControllerConfiguration', 'OtherHeadsetLibrary'],
						'Other Headset Library',
						this.props.handleInputChange,
						RVL.ControllerConfiguration.OtherHeadsetLibrary
					)}
					{componentUtils.getCheckbox(
						['ControllerConfiguration', 'UserGeneratedContent'],
						'User Generated Content',
						this.props.handleInputChange,
						RVL.ControllerConfiguration.UserGeneratedContent
					)}
				</Form>
			</div>
		);
	}

	render() {
		return <div>{this.getBody()}</div>;
	}
}

export default GameConfigurationWii;
