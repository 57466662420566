import React, { VFC } from 'react';

import Forms from '../Forms/Forms';
import { FIELD_TYPES } from '../../containers/CustomFieldManagement/views/CustomFieldIndexTab';


interface CustomFieldInput extends CustomFieldEntryData {
	companies?: any[];
}

const CustomFieldUpdate: VFC<CustomFieldInput> =
	({
		 field_definition_id,
		 name,
		 value,
		 type,
		 read_only,
		 companies,
	 }) => {
		switch (type.id) {
			case 'textbox':
				return (
					<Forms.Text id={String(field_definition_id)}
								maxLength={FIELD_TYPES.get(type.id).max_length}
								disabled={read_only}
								value={value}
					>
						<Forms.Heading>{name}</Forms.Heading>
					</Forms.Text>
				);
			case 'checkbox':
				return (
					<Forms.SingleCheckbox id={String(field_definition_id)}
										  value={value}
										  disabled={read_only}
					>
						<Forms.Heading>{name}</Forms.Heading>
					</Forms.SingleCheckbox>
				);
			case 'single_select_dropdown':
				return (
					<Forms.Select
						id={String(field_definition_id) + ' single_select_dropdown'}
						disabled={read_only}
						value={value}
					>
						<Forms.Heading>{name}</Forms.Heading>
						{companies?.map((company) => (
							<Forms.Option key={company.ndid_company_id}
										  value={company.ndid_company_id}
							>
								{company.company_name}
							</Forms.Option>
						))}
					</Forms.Select>
				);
			case 'multi_select_dropdown':
				return (
					<Forms.MultiSelect
						id={String(field_definition_id)}
						value={value}
						disabled={read_only}
					>
						<Forms.Heading>{name}</Forms.Heading>
						{companies?.map((company) => (
							<Forms.Option key={company.ndid_company_id}
										  value={company.ndid_company_id}
							>
								{company.company_name}
							</Forms.Option>

						))}
					</Forms.MultiSelect>

				);
			default:
				return null;
		}
	};


export default CustomFieldUpdate;
