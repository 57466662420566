import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Button, ButtonToolbar } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';

import { actionsColumn } from '../../components/BaseTable/BaseTable';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ActiveCell from '../../components/cells/ActiveCell/ActiveCell';
import FilterableTable from '../../components/FilterableTable/FilterableTable';
import Loading from '../../components/Loading/Loading';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import DeleteConfirmationModal from '../../components/modals/DeleteConfirmationModal/DeleteConfirmationModal';
import UploadNewAssetModal from '../../components/modals/UploadNewAssetModal/UploadNewAssetModal';
import Page from '../../components/Page/Page';
import Panel from '../../components/Panel/Panel';
import RolloverList from '../../components/RolloverList/RolloverList';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import Title from '../../components/Title/Title';
import { assetConst } from '../../constants/assetConstants';
import { useUserProfile } from '../../hooks/reduxHooks';
import { deleteProductAsset, getEventProduct } from '../../services/eventsService';
import { getProduct, getProductAssets } from '../../services/productsService';
import { getUsersInBulk } from '../../services/usersService';
import { classForAssetStatus, formatAssetType } from '../../utils/assetUtils';
import { removeConsecutiveNulls } from '../../utils/dataUtils';
import { dateFormat, formatDate, parseDateString } from '../../utils/dateUtils';
import { toasterNotify } from '../../utils/toaster';
import NotFound from '../NotFound/NotFound';
import { getAttributesForAsset, getPermissions } from './EventProductDetail.helpers';
import AddAssetModal from './modals/AddAssetModal';
import EditRequiredAssetModal from './modals/EditRequiredAssetModal';
import ProductDetailsPanel from './panels/ProductDetailsPanel';


const ADD_ASSETS_MODAL = 'ADD_ASSETS_MODAL';
const EDIT_REQUIRED_ASSET_MODAL = 'EDIT_REQUIRED_ASSET_MODAL';
const DELETE_REQUIRED_ASSET_MODAL = 'DELETE_REQUIRED_ASSET_MODAL';
const UPLOAD_ASSET_MODAL = 'UPLOAD_ASSET_MODAL';

const getTableStructure = ({ assets, userProfile, accessType, product, setOpenModal, history }) => {
	const showActiveColumn = assets.some((asset) => asset.active !== undefined);
	const getViewUrl = (productData, assetData) =>
		`/marketing-events/products/${productData.id}/assets/${assetData.id}`;

	const statusAccessor = (row) => {
		const statusOrder = [
			assetConst.STATUS.REJECTED,
			assetConst.STATUS.APPROVED,
			assetConst.STATUS.PENDING_REVIEW,
			assetConst.STATUS.REQUIRED,
		];
		return statusOrder.findIndex((status) => status === row.review_status);
	};

	const dateCell = ({ value }) =>
		(value && formatDate(parseDateString(value), dateFormat.DATE)) || '—';

	const statusCell = ({ original }) => {
		const value = original.review_status || assetConst.STATUS.REQUIRED;
		const textClass = classForAssetStatus(value, 'text');
		return <b className={textClass}>{value.replace(/_/g, ' ')}</b>;
	};

	const typeCell = ({ value }) => formatAssetType(value) || null;

	const assetNameAccessor = (row) => {
		const files = row.most_recent_files;
		if (!Array.isArray(files) || files.length === 0) {
			return '—';
		}
		files.sort((a, b) => b.uploaded_date - a.uploaded_date);
		return files.map((file) => file.file_name);
	};

	const assetCell = ({ value, original }) => {
		const { canViewAsset } = getPermissions(userProfile, accessType, product);
		return (canViewAsset
			? <Link to={getViewUrl(product, original)}><RolloverList tooltipId={'assets-' + original.id} values={value} dotted={false}/></Link>
			: <div><RolloverList tooltipId={'assets-' + original.id} values={value} /></div>);
	};

	const tableConfiguration = [
		{ Header: 'Asset', accessor: assetNameAccessor, id: 'file_name', Cell: assetCell },
		{ Header: 'Due Date', accessor: 'due_date', Cell: dateCell },
		{ Header: 'Type', accessor: 'asset_type', Cell: typeCell },
		{ Header: 'Description', accessor: 'description' },
		{ Header: 'Status', accessor: statusAccessor, id: 'review_status', Cell: statusCell },
		{
			Header: 'Active',
			accessor: 'active',
			Cell: ActiveCell,
			width: 64,
			show: showActiveColumn,
		},
	];
	const showActions = true;
	if (showActions) {
		const actionsCell = ({ original }) => {
			const {
				uploadable,
				showUploadAsEdit
			} = getAttributesForAsset(original);
			const {
				canViewAsset,
				canEditRequiredAssets,
				canDeleteRequiredAssets,
				canUploadAssets,
			} = getPermissions(userProfile, accessType, product);
			const menuItems = [];
			canViewAsset &&
				menuItems.push(
					<MeatballDropdown.Item
						key={`action-view-${original.asset_id}`}
						onClick={(e) => history.push(getViewUrl(product, original))}
					>
						View Asset
					</MeatballDropdown.Item>,
				);
			canUploadAssets &&
				menuItems.push(
					<MeatballDropdown.Item
						key={`action-upload-${original.asset_id}`}
						onClick={
							uploadable ? (() =>
								setOpenModal({
									type: UPLOAD_ASSET_MODAL,
									asset: original,
									productId: product.product_id,
									title: showUploadAsEdit ? 'Edit Asset' : 'Upload Asset',
								})
							) : null
						}
						disabled={!uploadable}
					>
						{showUploadAsEdit ? 'Edit Asset' : 'Upload Asset'}
					</MeatballDropdown.Item>,
				);
			menuItems.push(null);
			canEditRequiredAssets &&
				menuItems.push(
					<MeatballDropdown.Item
						key={`action-edit-required-${original.asset_id}`}
						onClick={(e) =>
							setOpenModal({ type: EDIT_REQUIRED_ASSET_MODAL, data: original })
						}
					>
						Edit Required Asset
					</MeatballDropdown.Item>,
				);
			menuItems.push(null);
			canDeleteRequiredAssets &&
				menuItems.push(
					<MeatballDropdown.Item
						key={`action-delete-required-${original.asset_id}`}
						onClick={(e) =>
							setOpenModal({ type: DELETE_REQUIRED_ASSET_MODAL, id: original.id })
						}
					>
						<span className="text-danger">Delete Required Asset</span>
					</MeatballDropdown.Item>,
				);

			const menuItemsWithSeparators = removeConsecutiveNulls(menuItems).map((item, index) =>
				item === null ? <MeatballDropdown.Divider key={'divider' + index} /> : item,
			);
			return (
				(menuItemsWithSeparators.length && (
					<MeatballDropdown
						id={`meatball-menu-${original.id}`}
					>
						{menuItemsWithSeparators}
					</MeatballDropdown>
				)) ||
				null
			);
		};
		tableConfiguration.push({
			...actionsColumn,
			Cell: actionsCell,
		});
	}
	return tableConfiguration;
};

const EventProductDetail = () => {
	const params = useParams();
	const history = useHistory();
	const userProfile = useUserProfile();
	const [isLoading, setIsLoading] = useState(false);
	const [product, setProduct] = useState(null);
	const [fault, setFault] = useState(null);
	const [openModal, setOpenModal] = useState(null);
	const [assets, setAssets] = useState([]);
	const [accessType, setAccessType] = useState(null);

	const permissions = useMemo(() => {
		return getPermissions(userProfile, accessType, product);
	}, [userProfile, accessType, product]);
	const {
		canAddAssets,
		canEditRequiredAssets,
		canEditActiveOnRequiredAssets,
		canDeleteRequiredAssets,
		canUploadAssets,
	} = permissions;

	const tableStructure = useMemo(() => {
		return (
			userProfile &&
			accessType &&
			product &&
			assets &&
			getTableStructure({ assets, userProfile, accessType, product, setOpenModal, history })
		);
	}, [userProfile, accessType, product, assets]);

	const loadProduct = async () => {
		try {
			setIsLoading(true);
			setProduct(null);
			setAssets([]);

			const eventProduct = await getEventProduct(params.productId);
			setAccessType(eventProduct.headers['x-pdb-access-type']);
			const token = eventProduct.headers['x-pdb-authorization'];

			const [pdbProduct, names, pdbAssets] = await Promise.all([
				getProduct(eventProduct.data.product_id),
				Array.isArray(eventProduct.data.coordinators) && getUsersInBulk(eventProduct.data.coordinators, undefined, token),
				eventProduct.data.assets && eventProduct.data.assets.length && token
					? getProductAssets(eventProduct.data.product_id, 'MARKETING_EVENT', token)
					: null
			]);

			const coordinatorNames = (names && names.data.map(name => name.user_name)) || [];
			let companyNames = eventProduct.data.companies && eventProduct.data.companies.map(
				id => (pdbProduct.data.distribution.find(
					item => item.ndid_company_id === id) || {}
				).company_name || '—'
			);

			setProduct({
				...eventProduct.data,
				...pdbProduct.data,
				coordinatorNames,
				companyNames
			});
			if (pdbAssets) {
				const combinedAssetsData = eventProduct.data.assets.map((a) => ({
					asset_category: assetConst.CATEGORY.MARKETING_EVENT, // pdb endpoint is not returning asset_category for bulk asset calls, but we can assume all assets here are marketing event assets
					...a,
					...(pdbAssets.data.find((pdb) => a.product_asset_id === pdb.asset_id) || { missing: true }),
				}));
				if (combinedAssetsData.some((asset) => asset.missing)) {
					toasterNotify(
						'Parts of asset data were missing and could not be retrieved. Assets table may display incomplete data. Please alert site support if this is an issue.',
						'warning',
					);
				}
				setAssets(combinedAssetsData);
			}
		} catch(error) {
			setAccessType(null);
			setFault(error);
		} finally {
			setIsLoading(false);
		}
	};

	const deleteRequiredAsset = async (assetId) => {
		try {
			await deleteProductAsset(assetId);
			toasterNotify('Successfully deleted the asset from this product.', 'success');
			loadProduct();
		} catch(err) {
			toasterNotify('Could not delete the asset because of an error. Please try again.', 'error', err);
		} finally {
			setOpenModal();
		}
	};

	// initial data load
	useEffect(() => {
		loadProduct();
	}, []);

	const eventName = product && product.event.name;
	const eventId = product && product.event.id;
	const title = product && product.game_name;

	if (fault) {
		return <NotFound />;
	}
	return (
        <Page>
            <div className="EventProductDetail">
                { isLoading
                    ? <Loading />
                    : <>
					<Breadcrumb>
						<Breadcrumb.Item to="/marketing-events">Marketing Events</Breadcrumb.Item>
						<Breadcrumb.Item to={`/marketing-events/${eventId}`}>{eventName}</Breadcrumb.Item>
						<Breadcrumb.Item active>{title}</Breadcrumb.Item>
					</Breadcrumb>
                    <Title title={title}/>
                    <Page.SplitPage>
						<Page.MainCol>
							<Page.ContentContainer>
								<SectionTitle>Asset(s)</SectionTitle>
								{ canAddAssets &&
									<ButtonToolbar className="mb-4">
										<Button
											variant="primary"
											onClick={e => setOpenModal({type: ADD_ASSETS_MODAL})}
										>
											Add Assets
										</Button>
									</ButtonToolbar>
								}
								{ assets.length
									?	<FilterableTable
											data={assets}
											dataFormat={tableStructure}
											defaultSorted={[
												{id: 'active', desc: true},
												{id: 'review_status', desc: true},
												{id: 'due_date', desc: false}
											]}
											noBorder
										/>
									:	<Alert variant='warning'>No assets are attached to this product.</Alert>
								}
							</Page.ContentContainer>
                        </Page.MainCol>
                        <Page.SidebarCol className="col-md-3">
                            <ProductDetailsPanel product={product} />
							<Panel>
								<Panel.Heading>
									<Panel.Title>Linked Events</Panel.Title>
								</Panel.Heading>
								<Panel.Body>
								</Panel.Body>
							</Panel>
                        </Page.SidebarCol>
                    </Page.SplitPage>
                </>}
            </div>
			{ canAddAssets &&
				<AddAssetModal
					show={(openModal && openModal.type === ADD_ASSETS_MODAL) || false}
					product={product}
					onClose={e => setOpenModal({})}
					onComplete={e => loadProduct()}
					canSetActive={canEditActiveOnRequiredAssets}
				/>
			}
			{ canEditRequiredAssets &&
			    <EditRequiredAssetModal
					onClose={() => setOpenModal()}
					onChange={() => {setOpenModal(); loadProduct();}}
					show={(openModal && openModal.type === EDIT_REQUIRED_ASSET_MODAL) || false}
					assetValues={openModal && openModal.data}
					canEditActiveOnRequiredAssets={canEditActiveOnRequiredAssets}
					latestDueDate={product && product.event.end_datetime}
				/>
			}
			{ canDeleteRequiredAssets &&
				<DeleteConfirmationModal
					show={(openModal && openModal.type === DELETE_REQUIRED_ASSET_MODAL) || false}
					closeModal={() => setOpenModal()}
					confirmDelete={e => deleteRequiredAsset(openModal.id)}
					title="Delete Required Asset"
					message="Please confirm that you would like to permanently delete this asset."
				/>
			}
			{ canUploadAssets &&
				<UploadNewAssetModal
					show={(openModal && openModal.type === UPLOAD_ASSET_MODAL) || false}
					userProfile={userProfile}
					closeModal={() => setOpenModal()}
					productId={openModal && openModal.productId}
					asset={openModal && openModal.asset}
					loadAssetDetails={() => {setOpenModal(); loadProduct();}}
					title={openModal && openModal.title}
				/>
			}
        </Page>
	);
};

export default EventProductDetail;
