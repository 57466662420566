import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Loading from '../../components/Loading/Loading';
import { useCompanyProfileQuery } from '../../hooks/queryHooks';
import { useUserProfile } from '../../hooks/reduxHooks';
import { getUser } from '../../services/usersService';
import { setUserProfile } from '../../store/actions/actions';
import { fetchHelpTopics, fetchSunsetFeatures } from '../../store/actions/referenceActions';
import { getStore } from '../../store/store';
import { safeEval } from '../../utils/dataUtils';
import eventBus from '../../utils/eventBus';
import { getPostLoginPath } from '../../utils/reduxUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';


const ProfileManager = ({ children }) => {
	const lastLocationPathname = useRef();
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const isLoggingIn = /^\/login(\/)?$/.test(location.pathname);
	const [authRequired, setAuthRequired] = useState(isLoggingIn);
	const [isLoading, setIsLoading] = useState(false);
	const userProfile = useUserProfile();

	const companyProfileQuery = useCompanyProfileQuery({ enabled: !!userProfile });

	function formatUserJSON(userJson) {
		return {
			companyId: userJson.ndid_company_id,
			userId: userJson.ndid_user_id,
			companyName: userJson.company_name,
			fullName: userJson.user_name,
			roles: userJson.roles,
			permissions: userJson.permissions,
			timestamp: userJson.timestamp,
			platform_features: userJson.platform_features,
		};
	}

	async function getUserProfile() {
		let user = null;
		await getUser('self')
			.then((response) => {
				user = {
					...response.data,
					timestamp: Number(response.headers?.['x-pdb-user-details-timestamp'])
				};
				
				const formattedUser = formatUserJSON(user);
				dispatch(setUserProfile(formattedUser));
			})
			.catch((error) => {
				toasterNotify(
					safeEval(() => error.response.status) === 401
						? 'Your authorization was not made available to Publisher Tool. Please try logging in again or contact site support for assistance.'
						: createMessageForError(error, 'retrieving user data'),
					'error',
					error,
				);
				history.replace('/');
			});
			
		if (user) {
			await Promise.allSettled([dispatch(fetchHelpTopics()), dispatch(fetchSunsetFeatures())]);
		}
		return user;
	}

	async function initializeLogin() {
		setAuthRequired(false);
		setIsLoading(true);
		const urlParams = new URLSearchParams(location.search);
		const redirectUrl = urlParams.get('redirect_url') || getPostLoginPath() || '/';
		if (urlParams.has('error')) {
			history.replace('/' + location.search);
		} else {
			const user = await getUserProfile();
			if (user == null) {
				history.push('/');
			} else if (isLoggingIn) {
				history.push(redirectUrl);
			}
		}
		setIsLoading(false);
	}

	useEffect(() => {
		const ref = React.createRef();
		const receiveNewTimestamp = (newTimestamp) => {
			const state = getStore().getState();
			const timestamp = state.authReducer?.userProfile?.timestamp;
			if (timestamp && timestamp < Number(newTimestamp)) {
				setAuthRequired(true);

			} else {
				setAuthRequired(false);
			}
		};
		eventBus.on(eventBus.UPDATE_USER_PROFILE, (payload) => receiveNewTimestamp(payload), ref);

		return () => {
			eventBus.off(ref);
		};
	}, []);

	if (location?.pathname !== lastLocationPathname.current) {
		lastLocationPathname.current = location.pathname;
		if (authRequired) {
			initializeLogin();
			return <div className="d-flex vw-100 vh-100">
				<Loading />
			</div>;
		}
	}

	return (isLoading || companyProfileQuery.isLoading) ? (
		<div className="d-flex vw-100 vh-100">
			<Loading />
		</div>
	) : (
		children
	);
};

export default ProfileManager;
