import React, { useEffect, useState } from 'react';
import pick from 'lodash/pick';
import { Button, Table } from 'react-bootstrap';

import FAIcon from '../../../components/FAIcon/FAIcon';
import Forms from '../../../components/Forms/Forms';
import LoadingText from '../../../components/Loading/LoadingText';
import Page from '../../../components/Page/Page';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import Title from '../../../components/Title/Title';
import { digitalCodesConstants } from '../../../constants/digitalCodesConstants';
import { numberWithCommas } from '../../../utils/currencyUtils';
import { dateFormat, formatDate } from '../../../utils/dateUtils';
import { useProductQuery, usePurposeQuery } from '../DigitalCodesOrderCreate.helper';
import OrderCreateMenu from './OrderCreateMenu';


const { TITLE } = digitalCodesConstants.contentTypes;

const Step2ProductsAndComponents = ({
	orderData,
	onPrevious,
	onNext,
	onCancel,
	onSave,
	onDelete,
	isSubmitting,
	isSaving,
	showAllErrors,
	validate,
}) => {
	const [formValues, setFormValues] = useState(
		pick(orderData, ['productID', 'componentID', 'quantity']),
	);

	const purposeQuery = usePurposeQuery();

	const selectedPurpose =
		orderData.purposeID &&
		purposeQuery.isSuccess &&
		purposeQuery.data?.data?.find((purpose) => purpose.digital_orders_purpose_id === orderData.purposeID);

	const [quantityMin, quantityMax, quantityIncrements] =
		(selectedPurpose ? [selectedPurpose.min_num_of_codes, selectedPurpose.max_num_of_codes, selectedPurpose.code_batch_increments] : []);

	const productQuery = useProductQuery(orderData.purposeID);
	const products = (productQuery.isSuccess && productQuery.data.data) || undefined;

	const quantityRequirements =
		selectedPurpose &&
		`Min: ${numberWithCommas(selectedPurpose.min_num_of_codes)}, Max: ${numberWithCommas(
			selectedPurpose.max_num_of_codes,
		)}` +
			(selectedPurpose.code_batch_increments > 1
				? `, Batch amount: ${numberWithCommas(selectedPurpose.code_batch_increments)}`
				: '');

	const availableComponents =
		formValues.productID &&
		products?.find((product) => product.product_id === formValues.productID)?.components;

	const getReleaseDate = () => {
		const product =
			products?.find((product) => product.product_id === formValues.productID) || {};
		const date = product.product_release_date;
		return date ? formatDate(date, dateFormat.DATE) : null;
	};

	const handleChanges = (newValues) => {
		newValues = { ...newValues };
		// reset the component if new product is selected
		if (formValues.productID !== newValues.productID) {
			newValues.componentID = null;
		}
		setFormValues(newValues);
	};

	useEffect(() => {
		if (!formValues.quantity && quantityMin) {
			setFormValues({ ...formValues, quantity: quantityMin });
		}
	}, [quantityMin]);

	const validationErrors = validate(formValues);

	return (
		<>
			<Title
				subtitle="Step 2: Product &amp; Component Selection"
				button={
					<OrderCreateMenu
						showSave={true}
						onSave={() => onSave(formValues)}
						onDelete={onDelete}
					/>
				}
			>
				Create New Digital Code Order
			</Title>
			<Page.FullPageCol>
				<SectionTitle>Order Details</SectionTitle>

				<Table>
					<thead>
						<tr>
							<th style={{ width: '40%' }}>Product</th>
							<th style={{ width: '40%' }}>Component</th>
							<th style={{ width: '20%' }}>Quantity</th>
						</tr>
					</thead>
					<tbody>
						<Forms
							values={formValues}
							onChange={(values) => handleChanges(values)}
							showAllErrors={showAllErrors}
							validationErrors={validationErrors}
							as="tr"
							noPadding
							vertical
						>
							<td>
								<Forms.SearchSelect
									id="productID"
									placeholder="Select product..."
									isLoading={productQuery.isLoading}
									disabled={productQuery.isLoading}
								>
									{products &&
										[...products]
											.filter(
												(product, index, array) =>
													array.findIndex(
														(firstProduct) =>
															firstProduct.product_id ===
															product.product_id,
													) === index,
											)
											.sort((a, b) =>
												a.game_name > b.game_name
													? 1
													: a.game_name < b.game_name
													? -1
													: 0,
											)
											.map((product) => (
												<Forms.Option
													value={product.product_id}
													key={'product-' + product.product_id}
												>
													[{product.game_code}] {product.game_name}
												</Forms.Option>
											))}
								</Forms.SearchSelect>
								{formValues.productID && (
									<Forms.Help>
										Est. Release Date: {getReleaseDate() || <>&mdash;</>}
									</Forms.Help>
								)}
							</td>
							<td>
								<Forms.SearchSelect
									id="componentID"
									placeholder="Select component..."
									isLoading={productQuery.isLoading}
									disabled={productQuery.isLoading || !formValues.productID}
								>
									{availableComponents &&
										availableComponents.some(
											(component) => component.content_type === TITLE,
										) && (
											<Forms.Group label="Main Title">
												{availableComponents.map((component) =>
													component.content_type === TITLE ? (
														<Forms.Option
															value={component.part_number}
															key={'key-' + component.part_number}
														>
															({component.ns_uid ||
																component.product_code}
															) {component.game_name} 
														</Forms.Option>
													) : null,
												)}
											</Forms.Group>
										)}
									{availableComponents &&
										availableComponents.some(
											(component) => component.content_type !== TITLE,
										) && (
											<Forms.Group label="Component">
												{[...availableComponents]
													.sort((a, b) =>
														a.game_name > b.game_name 
															? 1
															: a.game_name < b.game_name 
															? -1
															: 0,
													)
													.map((component, index) =>
														component.content_type !== TITLE ? (
															<Forms.Option
																value={component.part_number}
																key={'key-' + component.part_number}
															>
																({component.ns_uid ||
																	component.product_code}
																) {component.game_name} 
															</Forms.Option>
														) : null,
													)}
											</Forms.Group>
										)}
								</Forms.SearchSelect>
							</td>
							<td>
								<Forms.Number
									rows="2"
									id="quantity"
									maxLength="50"
									type="number"
									min={quantityMin}
									max={quantityMax}
									step={quantityIncrements}
									onBlur={() => {
										if (!formValues.quantity) {
											setFormValues({
												...formValues,
												quantity: quantityMin,
											});
										}
									}}
								>
									<Forms.Postscript>
										<div className="help-block">{quantityRequirements}</div>
									</Forms.Postscript>
								</Forms.Number>
							</td>
						</Forms>
					</tbody>
				</Table>

				<div className="btn-container">
					<div className="float-left">
						<Button
							variant="outline-secondary"
							disabled={isSubmitting || isSaving}
							onClick={() => onPrevious()}
						>
							<FAIcon className="mr-1" name="chevron-left" />
							Previous (Order Info)
						</Button>
						<Button
							variant="link"
							disabled={isSubmitting || isSaving}
							onClick={() => onCancel()}
						>
							Cancel
						</Button>
					</div>
					<div className="float-right d-flex">
						{isSaving && <LoadingText inline className="m-a" />}
						<Button
							className="ml-3"
							variant="outline-secondary"
							onClick={() => onSave(formValues)}
							disabled={isSubmitting || isSaving}
						>
							Save
						</Button>
						<Button
							className="ml-3"
							onClick={() => onNext(formValues)}
							disabled={isSubmitting || isSaving}
						>
							Next (Get Price and Confirm Order) <FAIcon name="chevron-right" />
						</Button>
					</div>
				</div>
			</Page.FullPageCol>
		</>
	);
};

export default Step2ProductsAndComponents;
