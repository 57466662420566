import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import FAIcon from '../../FAIcon/FAIcon';


const VisibleCell = ({ value }) => (
	<OverlayTrigger
		placement={'bottom'}
		overlay={
			<Tooltip id="visibility-tooltip">
				{value ? 'Visible to publishers' : 'Not visible to publishers'}
			</Tooltip>
		}
	>
		{value ? (
			<span className="text-danger">
				<FAIcon name="eye" />
			</span>
		) : (
			<span className="text-muted">
				<FAIcon name="eye-slash" />
			</span>
		)}
	</OverlayTrigger>
);

export default VisibleCell;
