import React, { Component } from 'react';
import { Alert, Button, FormControl, FormGroup, FormLabel, InputGroup } from 'react-bootstrap';

import BaseModal from '../../../../components/BaseModal/BaseModal';
import { transferRightsConstants } from '../../../../constants/transferRightsConstants';
import {
	getProductRightsTransferRequestByTransferToken,
	postProductRightsTransferRequests
} from '../../../../services/productTransfersService';
import { toasterNotify, createMessageForError } from '../../../../utils/toaster';

export default class EnterOwnershipTransferCodeModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			assignmentCode: '',
			display: true,
			isLoading: false,
			invalidAlertText: 'Please enter a valid code',
			isInvalid: false,
			isValid: false
		};
	}

	verifyAssignmentCode() {
		const { assignmentCode } = this.state;
		const transfer_type = 'OWNERSHIP';
		this.setState({ isLoading: true });
		getProductRightsTransferRequestByTransferToken(assignmentCode, transfer_type)
			.then((response) => {
				this.setState({
					transferRequest: response.data,
					isLoading: false,
					isValid: true,
					isInvalid: false,
					invalidAlertText: null,
				});
			})
			.catch((error) => {
				this.setState({
					isLoading: false,
					isValid: false,
					isInvalid: true,
					invalidAlertText: error.response ? 'Invalid Code' : 'Please enter a valid code',
				});
			});
	}

	acceptAssignmentCode() {
		const { userProfile, closeModal, addRightsRequest } = this.props;
		const { transferRequest } = this.state;
		const payload = {
			transfer_status: transferRightsConstants.REQUEST_STATUS.PENDING,
		};

		this.setState({ isLoading: true });
		postProductRightsTransferRequests(transferRequest.product_transfer_id, payload)
			.then((response) => {
				transferRequest.transfer_to_company = userProfile.companyId;
				transferRequest.transfer_status =
						transferRightsConstants.REQUEST_STATUS.PENDING;

				addRightsRequest(transferRequest);
				this.setState({
					transferRequest: response.data,
				});
				toasterNotify('Product rights transfer accepted.', 'success');
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'accepting an ownership transfer', error),
					'error',
					error
				);
			})
			.finally(() => {
				closeModal();
			});
	}

	generateTransferRequestFieldsToDisplay() {
		const { platforms } = this.props;
		const { transferRequest } = this.state;
		const platformName =
			platforms &&
			transferRequest &&
			(platforms.find((p) => transferRequest.product_platform_code === p.platform_code) || {})
				.platform_name;

		if (transferRequest) {
			let fields = [];
			fields.push(
				<FormGroup>
					<FormLabel>
						Platform
					</FormLabel>
					<FormControl type="text" readOnly value={platformName} />
				</FormGroup>,
			);
			fields.push(
				<FormGroup>
					<FormLabel>
						Product Name
					</FormLabel>
					<FormControl type="text" readOnly value={transferRequest.product_game_name} />
				</FormGroup>,
			);
			return fields;
		}
		return null;
	}

	getAlertText() {
		const { isInvalid, invalidAlertText } = this.state;
		let alertText = 'Please enter a transfer code to receive ownership of a product';
		if (isInvalid) {
			alertText = invalidAlertText;
		}
		return alertText;
	}

	render() {
		const { display, isValid, isInvalid, isLoading } = this.state;
		const { closeModal } = this.props;

		return (
			<BaseModal show={display} onCancel={closeModal} isSubmitting={isLoading}>
				<BaseModal.Title>Enter Assignment Code</BaseModal.Title>
				{isValid !== true && (
					<Alert variant={isInvalid ? 'danger' : 'info'}>{this.getAlertText()}</Alert>
				)}
				<FormGroup>
					<FormLabel>Transfer Code</FormLabel>
					<InputGroup>
						<FormControl
							type="text"
							placeholder=""
							onChange={(e) => {
								this.setState({
									assignmentCode: e.currentTarget.value,
									isValid: false,
								});
							}}
							isValid={isValid}
						/>
						{!isValid && (
							<InputGroup.Append>
								<Button
									variant="outline-secondary"
									onClick={() => {
										this.verifyAssignmentCode();
									}}
								>
									Verify
								</Button>
							</InputGroup.Append>
						)}
					</InputGroup>
					<div className="help-block">e.g. xxxx-xxxx-xxxx</div>
				</FormGroup>

				{this.state.isValid
					? this.generateTransferRequestFieldsToDisplay()
					: null}
				<BaseModal.Submit
					onClick={() => {
						this.acceptAssignmentCode();
					}}
					disabled={this.state.isLoading || !this.state.isValid}
				>
					Accept
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}
