import api from './baseService';

export const putSubmissionGameConfiguration = (
	submissionId: string,
	systemType: string,
	payload: Record<string, any>,
) => api().put(`/pdb/submissions/${submissionId}/${systemType}/gameconfig`, payload);

export const getSubmissionGameConfiguration = (
	submissionId: string,
	gameType: 'New 3DS Exclusive' | 'Nintendo 3DS' | 'Wii' | 'Wii U',
) => {
	switch (gameType) {
		case 'New 3DS Exclusive':
		case 'Nintendo 3DS':
			return api().get(`/pdb/submissions/${submissionId}/ctrktr/gameconfig`);
		case 'Wii':
			return api().get(`/pdb/submissions/${submissionId}/rvl/gameconfig`);
		case 'Wii U':
			return api().get(`/pdb/submissions/${submissionId}/wup/gameconfig`);
		default:
			return Promise.reject(new Error('Unknown game type requested'));
	}
};

export const getSubmissionAssets = (submissionId: string) =>
	api().get(`/pdb/products/submissions/${submissionId}/assets`);

export const getSubmissionDetails = (submissionId: string) =>
	api().get(`/pdb/submissions/${submissionId}`);

export const putEditSubmission = (submissionId: string, payload: Record<string, any>) =>
	api().put(`/pdb/submissions/${submissionId}`, payload);

export const getSubmissionActivityLog = (submissionId: string) =>
	api().get(`/pdb/submissions/${submissionId}/activities`);

export const postSubmissionComment = (submissionId: string, comment: string) =>
	api().post(`/pdb/submissions/${submissionId}/comment`, { comment });

export const deleteSubmissionFiles = (submissionId: string, fileIds: string[]) =>
	api().delete(`/pdb/submissions/${submissionId}/files?file_ids=${fileIds.join(',')}`);

export const setSubmissionStatus = (submissionId: string, status: string) =>
	api().put(`/pdb/submissions/${submissionId}/status`, { status });

export const getSubmissionTestResult = (submissionId: string) => {
	return api().get(`/pdb/submissions/${submissionId}/test_result`);
};

export const setLotcheckPriority = (submissionId: string, priority: string) => {
	let body = { lotcheck_priority: parseInt(priority) };
	return api().put(`/pdb/submissions/${submissionId}/lotcheckpriority`, body);
};
