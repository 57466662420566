import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { routerGate } from './Routes.helpers.js';

import AnnouncementManagement from '../../containers/AnnouncementManagement/AnnouncementManagement';
import ApprovalQueue from '../../containers/ApprovalQueue/ApprovalQueue';
import AssetDetails from '../../containers/AssetDetails/AssetDetails';
import CompanyProfile from '../../containers/CompanyProfile/CompanyProfile';
import ComponentDetail from '../../containers/ComponentDetail/ComponentDetail';
import ComponentsWithoutProducts from '../../containers/ComponentsWithoutProducts/ComponentsWithoutProducts';
import ConsigneeManagement from '../../containers/ConsigneeManagement/ConsigneeManagement';
import CustomFieldsManagement from '../../containers/CustomFieldManagement/CustomFieldsManagement';
import Demo from '../../containers/Demo/Demo';
import DigitalCodesDetail from '../../containers/DigitalCodesDetail/DigitalCodesDetail';
import DigitalCodesManagement from '../../containers/DigitalCodesManagement/DigitalCodesManagement';
import DigitalCodesOrderCreate from '../../containers/DigitalCodesOrderCreate/DigitalCodesOrderCreate';
import EventDetail from '../../containers/EventDetail/EventDetail';
import EventManagement from '../../containers/EventManagement/EventManagement';
import EventProductDetail from '../../containers/EventProductDetail/EventProductDetail';
import EventReports from '../../containers/EventReports/EventReports';
import FamilySetup from '../../containers/FamilySetup/FamilySetup';
import FeatureManagement from '../../containers/FeatureManagement/FeatureManagement';
import Features from '../../containers/Features/Features';
import FreightForwarderAssociation
	from '../../containers/FreightForwarderAssociation/FreightForwarderAssociation';
import ManageFreightForwarders from '../../containers/FreightForwarderManagement/ManageFreightForwarders';
import HelpTopic from '../../containers/HelpTopics/HelpTopics';
import Home from '../../containers/Home/Home';
import Logout from '../../containers/Login/Logout';
import LotcheckQueue from '../../containers/LotcheckQueue/LotcheckQueue';
import MaintenancePage from '../../containers/Maintenance/MaintenancePage';
import ManagePackoutFacilities from '../../containers/ManagePackoutFacilities/ManagePackoutFacilities';
import NCLPortal from '../../containers/NCLPortal/NCLPortal';
import NotFound from '../../containers/NotFound/NotFound';
import NotSignedIn from '../../containers/NotSignedIn/NotSignedIn';
import OrderCreate from '../../containers/OrderCreate/OrderCreate';
import OrderList from '../../containers/Orders/OrderList';
import OrderReview from '../../containers/Orders/OrderReview';
import PhysicalOrdersCreate from '../../containers/PhysicalOrdersCreate/PhysicalOrdersCreate';
import PhysicalOrdersDetail from '../../containers/PhysicalOrdersDetail/PhysicalOrdersDetail';
import PhysicalOrdersManagement from '../../containers/PhysicalOrdersManagement/PhysicalOrdersManagement';
import PrivacyPolicy from '../../containers/PrivacyPolicy/PrivacyPolicy';
import ProductManagement from '../../containers/ProductManagement/ProductManagement';
import ProductProfile from '../../containers/ProductProfile/ProductProfile';
import DistributionRightsRequestDetails from '../../containers/ProductRights/Distribution/ProductDistributionTransfer';
import DistributionRightsRequestsTable from '../../containers/ProductRights/Distribution/ProductDistributionTransferTable';
import ProductOwnershipTransfer from '../../containers/ProductRights/Ownership/ProductOwnershipTransfer';
import ProductOwnershipTransferTable from '../../containers/ProductRights/Ownership/ProductOwnershipTransferTable';
import OrderProjections from '../../containers/Projections/OrderProjections';
import ProjectionsManagement from '../../containers/ProjectionsManagement/ProjectionsManagement';
import PublisherManagement from '../../containers/PublisherManagement/PublisherManagement';
import ROMUploadManagement from '../../containers/ROMUploadManagement/ROMUploadManagement';
import SubmissionDetails from '../../containers/SubmissionDetails/SubmissionDetails';
import UserManagement from '../../containers/UserManagement/UserManagement';

import { useIsFeatureSunsetted } from '../../hooks/reduxHooks';
import AuthExpiredDisplay from '../AuthExpiredDisplay/AuthExpiredDisplay';
import { permConst } from '../../constants/permConst';
import { isAuthorized } from '../../utils/userUtils';

export function MaintenanceRoute() {
	return (
		<Switch>
			<Route exact path="*" component={MaintenancePage} />
		</Switch>
	);
}

export function LoggedOutRouter() {
	return (
		<Switch>
			<Route exact path="/" component={Home} />
			<Route exact path="/logout" component={Logout} />
			<Route exact path="/privacy-policy" component={PrivacyPolicy} />
			<Route path="*" component={NotSignedIn} />
			<Redirect from="*" to="/login" />
		</Switch>
	);
}

export function MainRouter({ userProfile }) {
	const lotcheckSunset = useIsFeatureSunsetted('lotcheck_features');
	return (
		<>
			<Switch>
				<Route
					path="/logout"
					render={() => {
						return <Logout />;
					}}
				/>
				<Route exact path={['/', '/version']} component={Home} />

				{/* Routes sorted by path */}
				<Route
					exact
					path="/admin/announcements"
					render={(props) => {
						return routerGate(
							props,
							<AnnouncementManagement {...props} userProfile={userProfile} />,
						);
					}}
				/>
				<Route
					exact
					path="/admin/appovals"
					render={(props) => {
						return routerGate(props, <ApprovalQueue {...props} />);
					}}
				/>
				<Route
					exact
					path="/admin/users"
					render={(props) => {
						return routerGate(props, <UserManagement {...props} />);
					}}
				/>
				<Route
					exact
					path="/admin/family-setup"
					render={(props) => {
						return routerGate(props, <FamilySetup {...props} />);
					}}
				/>
				<Route
					exact
					path="/admin/feature-management"
					render={(props) => {
						return routerGate(props, <FeatureManagement {...props} />);
					}}
				/>
				<Route
					exact
					path="/admin/help-topic-management"
					render={(props) => {
						return routerGate(props, <HelpTopic {...props} />);
					}}
				/>
				<Route
					exact
					path="/admin/components-without-products"
					render={(props) => {
						return routerGate(
							props,
							<ComponentsWithoutProducts {...props} userProfile={userProfile} />,
						);
					}}
				/>
				<Route
					exact
					path="/admin/product-distribution-transfer-requests"
					render={(props) => {
						return routerGate(props, <DistributionRightsRequestsTable {...props} />);
					}}
				/>
				<Route
					exact
					path="/admin/product-distribution-transfer-requests/:id"
					render={(props) => {
						return routerGate(props, <DistributionRightsRequestDetails {...props} />);
					}}
				/>
				<Route
					exact
					path="/admin/product-ownership-transfer-requests"
					render={(props) => {
						return routerGate(props, <ProductOwnershipTransferTable {...props} />);
					}}
				/>
				<Route
					exact
					path="/admin/product-ownership-transfer-requests/:id"
					render={(props) => {
						return routerGate(props, <ProductOwnershipTransfer {...props} />);
					}}
				/>
				<Route
					exact
					path="/admin/ncl-users"
					render={(props) => {
						return routerGate(props, <UserManagement.NCL {...props}/>);
					}}
				/>
				<Route
					exact
					path="/admin/publishers"
					render={(props) => {
						return routerGate(props, <PublisherManagement {...props} />);
					}}
				/>
				<Route
					exact
					path="/admin/publishers/:companyId"
					render={(props) => {
						return routerGate(props, <CompanyProfile {...props} />);
					}}
				/>
				<Route
					exact
					path="/admin/ncl-rom-versions"
					render={(props) => {
						return routerGate(props, <ROMUploadManagement {...props} />);
					}}
				/>
				<Route
					exact
					path="/admin/custom-fields-management"
					render={(props) => {
						return routerGate(props, <CustomFieldsManagement {...props} />);
					}}
				/>
				<Route
					exact
					path="/demo"
					render={(props) => <Demo {...props} userProfile={userProfile} />}
				/>
				<Route
					exact
					path="/features"
					render={(props) => <Features {...props} userProfile={userProfile} />}
				/>
				<Route
					exact
					path="/marketing-events"
					render={(props) => {
						return routerGate(props, <EventManagement {...props} />);
					}}
				/>
				<Route
					exact
					path="/marketing-events/reports"
					render={(props) => {
						return routerGate(props, <EventReports {...props} />);
					}}
				/>
				<Route
					exact
					path="/marketing-events/:eventId"
					render={(props) => {
						return routerGate(props, <EventDetail {...props} />);
					}}
				/>
				<Route
					exact
					path="/marketing-events/products/:productId"
					render={(props) => {
						return routerGate(props, <EventProductDetail {...props} />);
					}}
				/>
				<Route
					exact
					path="/marketing-events/products/:eventProductId/assets/:eventAssetId"
					render={(props) => {
						return routerGate(props, <AssetDetails {...props} />);
					}}
				/>
				<Route
					exact
					path="/ncl"
					render={(props) => {
						return routerGate(props, <NCLPortal {...props} />);
					}}
				/>
				{!isAuthorized(userProfile.permissions, [permConst.PHYSICAL_ORDER.VIEW]) ? (
					<Route
						exact
						path="/orders"
						render={(props) => {
							return routerGate(props, <OrderList {...props} />);
						}}
					/>
				) : (
					<Redirect exact path="/orders" to="/orders/physical" />
				)}
				{!isAuthorized(userProfile.permissions, [permConst.PHYSICAL_ORDER.EDIT]) ? (
					<Route
						exact
						path="/orders/create"
						render={(props) => {
							return routerGate(props, <OrderCreate {...props} />);
						}}
					/>
				) : (
					<Redirect exact path="/orders/create" to="/orders/physical/create" />
				)}
				<Route
					exact
					path="/orders/consignees"
					render={(props) => {
						return routerGate(props, <ConsigneeManagement {...props} />);
					}}
				/>
				<Route
					exact
					path="/orders/digital-codes"
					render={(props) => {
						return routerGate(props, <DigitalCodesManagement {...props} />);
					}}
				/>
				<Route
					exact
					path="/orders/digital-codes/create"
					render={(props) => {
						return routerGate(props, <DigitalCodesOrderCreate {...props} />);
					}}
				/>
				<Route
					exact
					path="/orders/digital-codes/:orderHeaderId/create"
					render={(props) => {
						return routerGate(props, <DigitalCodesOrderCreate {...props} />);
					}}
				/>
				<Route
					exact
					path="/orders/digital-codes/:orderId"
					render={(props) => {
						return routerGate(props, <DigitalCodesDetail {...props} />);
					}}
				/>
				<Route
					exact
					path="/orders/manage-freight-forwarders"
					render={(props) => {
						return routerGate(props, <ManageFreightForwarders {...props} />);
					}}
				/>
				<Route
					exact
					path="/orders/associate-freight-forwarders"
					render={(props) => {
						return routerGate(props, <FreightForwarderAssociation {...props} />);
					}}
				/>
				<Route
					exact
					path="/orders/manage-packout-facilities"
					render={(props) => {
						return routerGate(props, <ManagePackoutFacilities {...props} />);
					}}
				/>
				<Route
					exact
					path="/orders/physical"
					render={(props) => {
						return routerGate(props, <PhysicalOrdersManagement {...props} />);
					}}
				/>
				<Route
					exact
					path="/orders/physical/create"
					render={(props) => {
						return routerGate(props, <PhysicalOrdersCreate {...props} />);
					}}
				/>
				<Route
					exact
					path="/orders/physical/:orderHeaderId/create"
					render={(props) => {
						return routerGate(props, <PhysicalOrdersCreate {...props} />);
					}}
				/>
				<Route
					exact
					path="/orders/physical/:orderHeaderId"
					render={(props) => {
						return routerGate(props, <PhysicalOrdersDetail {...props} />);
					}}
				/>
				<Route
					exact
					path="/orders/review/:orderHeaderId"
					component={OrderReview}
					render={(props) => {
						return routerGate(props, <OrderReview {...props} />);
					}}
				/>
				<Route
					exact
					path="/orders/projections"
					render={(props) => {
						return routerGate(props, <OrderProjections {...props} />);
					}}
				/>
				<Route
					exact
					path="/orders/projections/management"
					render={(props) => {
						return routerGate(props, <ProjectionsManagement {...props} />);
					}}
				/>
				<Route
					exact
					path="/orders/projections/:id"
					render={(props) => {
						return routerGate(props, <OrderProjections {...props} />);
					}}
				/>
				<Route
					exact
					path="/orders/:orderHeaderId/create"
					render={(props) => {
						return routerGate(props, <OrderCreate {...props} />);
					}}
				/>
				<Route
					exact
					path="/products"
					render={(props) => {
						return routerGate(props, <ProductManagement {...props} />);
					}}
				/>
				<Redirect exact path="/products/assets" to="/admin/appovals" />
				<Route
					exact
					path="/products/:id"
					render={(props) => {
						return routerGate(props, <ProductProfile {...props} />);
					}}
				/>
				<Route
					exact
					path="/products/:productId/assets/:assetId"
					render={(props) => {
						return routerGate(props, <AssetDetails {...props} />);
					}}
				/>
				<Route
					exact
					path="/products/components/:nsUid"
					render={(props) => {
						return routerGate(props, <ComponentDetail {...props} />);
					}}
				/>
				{!lotcheckSunset && (
					<Route
						exact
						path="/submissions"
						render={(props) => {
							return routerGate(props, <LotcheckQueue {...props} />);
						}}
					/>
				)}
				<Route
					exact
					path="/submission-details/:productId/:submissionId"
					render={(props) => {
						return routerGate(props, <SubmissionDetails {...props} />);
					}}
				/>
				<Route exact path="/privacy-policy" component={PrivacyPolicy} />
				<Route exact path="*" component={NotFound} />
			</Switch>
			<AuthExpiredDisplay />
		</>
	);
}
