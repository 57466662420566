import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { useQuery } from 'react-query';
import * as yup from 'yup';

import BaseModal from '../../BaseModal/BaseModal';
import Forms from '../../Forms/Forms';
import {
	getCustomerFeedbackCategories,
	postCustomerFeedbackComment
} from '../../../services/customerFeedbackService';
import { isEmptyObject } from '../../../utils/dataUtils';
import { toasterNotify } from '../../../utils/toaster';
import { validateToSchema } from '../../../utils/validationUtils';

const GENERAL = 'General';

interface FormValues {
	customer_feedback_category_id: number;
	subject: string;
	comment: string;
	anonymous: boolean;
}

export const baseSchema = yup.object().shape({
	customer_feedback_category_id: yup.number().required('Category is required'),
	comment: yup.string().trim().required('Comment is required'),
	anonymous: yup.boolean().default(false),
});
export const generalCategorySchema = baseSchema.concat(
	yup.object().shape({
		subject: yup
			.string()
			.trim()
			.when('$subjectRequired', {
				is: true,
				then: (schema) => schema.required('Subject must be specified for the General category'),
			}),
	}),
);

const SubmitCustomerFeedbackModal = ({
	show,
	onCompletion,
	onClose,
	submitPermission,
}: {
	show: boolean;
	onCompletion: () => void;
	onClose: () => void;
	submitPermission: boolean;
}) => {
	const [formValues, setFormValues] = useState<Partial<FormValues>>({});
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);

	const categoriesQuery = useQuery(
		'getCustomerFeedbackCategories',
		() => getCustomerFeedbackCategories(),
		{
			enabled: show && submitPermission,
		},
	);
	const isLoading = categoriesQuery.isLoading;
	const categories = categoriesQuery?.data?.data || [];
	const generalCategorySelected =
		formValues.customer_feedback_category_id ===
		categories.find((category) => category.category_name === GENERAL)
			?.customer_feedback_category_id;
	const validationErrors = validateToSchema(generalCategorySchema, formValues, {
		subjectRequired: generalCategorySelected,
	});

	useEffect(() => {
		if (show) {
			setFormValues({});
			setIsSubmitting(false);
			setShowAllErrors(false);
		}
	}, [show]);

	const submitForm = async () => {
		if (isSubmitting) {
			return;
		}
		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}

		const castedFormValues = generalCategorySelected
			? generalCategorySchema.cast(formValues, { stripUnknown: true })
			: baseSchema.cast(formValues, { stripUnknown: true });

		setIsSubmitting(true);

		try {
			await postCustomerFeedbackComment(
				castedFormValues,
			);
			onCompletion();
			toasterNotify(
				'Thank you for your feedback. The form has been submitted successfully.',
				'success',
			);
		} catch (error: any) {
			toasterNotify(
				'There was an unexpected error while submitting feedback.',
				'error',
				error,
			);
		} finally {
			setIsSubmitting(false);
		}

		setShowAllErrors(false);
	};

	return (
		<BaseModal
			show={show}
			onCancel={() => onClose()}
			isLoading={isLoading}
			isSubmitting={isSubmitting}
		>
			<BaseModal.Title>Publisher Tool Feedback</BaseModal.Title>
			<Alert variant="warning">
				Do not enter issues that require immediate attention. Use{' '}
				<a href="mailto:thirdpartypublisher@noa.nintendo.com">
					thirdpartypublisher@noa.nintendo.com
				</a>{' '}
				to resolve issues.{' '}
			</Alert>
			<Forms
				onChange={(v) =>
					setFormValues({
						...v,
					})
				}
				values={formValues}
				validationErrors={validationErrors}
				showAllErrors={showAllErrors}
			>
				<Forms.Select id={'customer_feedback_category_id'}>
					<Forms.Heading>Category</Forms.Heading>
					{categories &&
						categories.map((category) => (
							<Forms.Option
								value={category.customer_feedback_category_id}
								key={category.customer_feedback_category_id}
							>
								{category.category_name}
							</Forms.Option>
						))}
				</Forms.Select>

				{generalCategorySelected && (
					<Forms.Text id={'subject'} maxLength={150}>
						<Forms.Heading>Subject</Forms.Heading>
					</Forms.Text>
				)}

				<Forms.TextArea id={'comment'} rows={5} maxLength={500}>
					<Forms.Heading>Comment</Forms.Heading>
					<Forms.Help>500 Character Limit</Forms.Help>
				</Forms.TextArea>

				<Forms.SingleCheckbox id={'anonymous'}>
					<Forms.Heading>Anonymous</Forms.Heading>
					<Forms.Label>
						Send feedback anonymously
					</Forms.Label>
					<Forms.Help>
						Nintendo Publisher Tool will not include your user ID with this comment when 
						this box is checked. See our <a href="/privacy-policy" target="_blank">privacy
						notice</a>.
					</Forms.Help>
				</Forms.SingleCheckbox>
			</Forms>

			{submitPermission && (
				<BaseModal.Submit onClick={() => submitForm()}> Submit </BaseModal.Submit>
			)}
		</BaseModal>
	);
};

export default SubmitCustomerFeedbackModal;
