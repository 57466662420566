import { getCookieData } from '../../utils/featureFlagUtils';
import BaseTableNEW, {
	actionsColumn as actionsColumnNEW,
	linkColumn as linkColumnNEW,
} from './BaseTable__NEW';
import BaseTableOLD, {
	actionsColumn as actionsColumnOLD,
	linkColumn as linkColumnOLD,
} from './BaseTable__OLD';

const useNew = !process?.env?.JEST_WORKER_ID && // jest env test; serve old table in jest for now
	(getCookieData() || {})['new-table-component']?.status === true;

function getTable() {
	if (useNew) {
		return BaseTableNEW;
	} else {
		return BaseTableOLD;
	}
}

function getActionsColumn() {
	if (useNew) {
		return actionsColumnNEW;
	} else {
		return actionsColumnOLD;
	}
}

function getLinkColumn() {
	if (useNew) {
		return linkColumnNEW;
	} else {
		return linkColumnOLD;
	}
}

export default getTable();
export let actionsColumn = getActionsColumn();
export let linkColumn = getLinkColumn();
