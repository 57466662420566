//Nintendo 3DS game configuration options

export const programSizeInBytes = [
	'128M',
	'256M',
	'512M',
	'1.0G',
	'2.0G',
	'4.0G'
];

export const backupMemorySizeInBytes = ['128Kb(1Mb)', '512Kb(4Mb)'];

export const nintendoNetwork = [
	'(None)',
	'Yes',
	'No',
	'Sent',
	'Own Server',
	'Download Only',
	'Approved'
];

export const cameraSupport = ['None', 'Inside', 'Outside', 'Both'];

export const microphoneSupport = ['Yes', 'No', 'Voice'];

export const ggid = ['No Local Wireless', 'Previously Received', 'Needed'];

//Wii game configuration options

export const discType = ['Single Layer', 'Dual Layer'];

export const nintendoWifiConnection = [
	'None',
	'Yes',
	'No',
	'Sent',
	'Own Server',
	'Download Only',
	'Approved'
];
