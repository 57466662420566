import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Button, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useQuery, useQueryClient } from 'react-query';

import ActionLink from '../../../components/ActionLink/ActionLink';
import BaseTable, { actionsColumn } from '../../../components/BaseTable/BaseTable';
import ActiveCell from '../../../components/cells/ActiveCell/ActiveCell';
import DateCell from '../../../components/cells/DateCell/DateCell';
import EventTaskAttachmentLinkCell from '../../../components/cells/EventTaskAttachmentLinkCell/EventTaskAttachmentLinkCell';
import Loading from '../../../components/Loading/Loading';
import MeatballDropdown from '../../../components/MeatballDropdown/MeatballDropdown';
import ActionConfirmationModal from '../../../components/modals/ActionConfirmationModal/ActionConfirmationModal';
import DeleteConfirmationModal from '../../../components/modals/DeleteConfirmationModal/DeleteConfirmationModal';
import EventTaskModal, { ADD_TASK_MODAL, EDIT_TASK_MODAL, VIEW_TASK_MODAL } from '../../../components/modals/EventTaskModal/EventTaskModal';
import { eventConstants } from '../../../constants/eventConstants';
import { useUserProfile } from '../../../hooks/reduxHooks';
import { getCompaniesInBulk } from '../../../services/companiesService';
import { getEventTasks } from '../../../services/eventsService';
import { getUploadedFileData } from '../../../services/fileUploadsService';
import { getUsersInBulk } from '../../../services/usersService';
import { completeTask, claimTask, deleteTask } from '../../../utils/eventUtils';
import { safeEval } from '../../../utils/dataUtils';
import { displayString, truncatePhrase } from '../../../utils/stringUtils';
import FAIcon from '../../../components/FAIcon/FAIcon';


const COMPLETE_MODAL = 'COMPLETE_MODAL';
const DELETE_MODAL = 'DELETE_MODAL';
const CLAIM_MODAL = 'CLAIM_MODAL';

const { OPEN, IN_PROGRESS, COMPLETED } = eventConstants.TASKS.STATUS;
const { INTERNAL, EXTERNAL } = eventConstants.TASKS.TYPE;

const STATUS_ORDER = [OPEN, IN_PROGRESS, COMPLETED];

const getTableFormat = ({ userProfile, permissions, users, companies, files, setOpenModal }) => {
	const {
		canViewInactiveTasks,
		canViewTypeOnTasks,
		canEditTask,
		canDeleteTask,
		canEditTaskIfAssigned,
		canClaimInternalAssignmentOnTasks,
		canEditInternalAssigneeOnTasks,
		canClaimExternalAssignmentOnTasks,
		canEditExternalAssigneeOnTasks,
	} = permissions;

	const statusAccessor = (row) => {
		return STATUS_ORDER.indexOf(row.status);
	};

	const userAccessor = (row) => {
		return (
			(users &&
				(users.find((user) => user.ndid_user_id === row.assigned_ndid_user_id) || {})
					.user_name) ||
			null
		);
	};

	const NameCell = ({ value }) => truncatePhrase(value, 100);

	const ActionsCell = ({ original }) => {
		const isAssigned = original.assigned_ndid_user_id === userProfile.userId;
		const allowClaim = (
			(canClaimInternalAssignmentOnTasks || canEditInternalAssigneeOnTasks) &&
				original.assignment_type === INTERNAL
		) || (
			(canClaimExternalAssignmentOnTasks || canEditExternalAssigneeOnTasks) &&
				original.assignment_type !== INTERNAL
		);

		return (
			<MeatballDropdown>
				<MeatballDropdown.Item
					onClick={() => {
						setOpenModal({
							type: VIEW_TASK_MODAL,
							taskId: original.id,
							currentAttachment: original,
						});
					}}
				>
					View Task
				</MeatballDropdown.Item>
				{allowClaim && (
					<MeatballDropdown.Item
						disabled={isAssigned}
						onClick={() => {
							setOpenModal({
								type: CLAIM_MODAL,
								taskId: original.id,
								name: original.name,
							});
						}}
					>
						Claim Task
					</MeatballDropdown.Item>
				)}
				{(canEditTask || (canEditTaskIfAssigned && isAssigned)) && (
					<>
						<MeatballDropdown.Divider />
						<MeatballDropdown.Item
							disabled={original.status === eventConstants.TASKS.STATUS.COMPLETED}
							onClick={() => {
								setOpenModal({
									type: COMPLETE_MODAL,
									taskId: original.id,
									name: original.name,
								});
							}}
						>
							Complete Task
						</MeatballDropdown.Item>
						<MeatballDropdown.Item
							onClick={() => {
								setOpenModal({
									type: EDIT_TASK_MODAL,
									taskId: original.id,
									currentAttachment: original,
								});
							}}
						>
							Edit Task
						</MeatballDropdown.Item>
					</>
				)}
				{canDeleteTask && (
					<>
						<MeatballDropdown.Divider />
						<MeatballDropdown.Item
							onClick={() => {
								setOpenModal({
									type: DELETE_MODAL,
									taskId: original.id,
									name: original.name,
								});
							}}
						>
							<span className="text-danger">Delete Task</span>
						</MeatballDropdown.Item>
					</>
				)}
			</MeatballDropdown>
		);};

	const AssignedUserCell = ({ original, value }) => {
		const assignedCompanyName =
			(original.assignment_type === EXTERNAL &&
				(
					companies.find(
						(company) => company.ndid_company_id === original.assigned_ndid_company_id,
					) || {}
				).company_name) ||
			null;
		return value ? (
			<>
				{value}
				{assignedCompanyName && (
					<>
						<br />
						<small className="text-secondary">{assignedCompanyName}</small>
					</>
				)}
			</>
		) : (
			<>
				<span className="text-danger">
					<em>Unassigned</em>
				</span>
				{assignedCompanyName ? (
					<small className="text-secondary">{assignedCompanyName} (Owner)</small>
				) : null}
			</>
		);
	};

	const CommentsCell = ({ original, value }) => {
		return (
			(value && (
				<div className="text-center">
					<OverlayTrigger
						placement="right"
						overlay={
							<Tooltip id={'comment-' + original.id}>
								{truncatePhrase(value, 50)}
							</Tooltip>
						}
					>
						<ActionLink dotted>
							<FAIcon name="comment" />
						</ActionLink>
					</OverlayTrigger>
				</div>
			)) ||
			null
		);
	};

	const StatusCell = ({ original }) => {
		switch (original.status) {
			case eventConstants.TASKS.STATUS.OPEN:
				return (
					<strong className="text-info">
						{displayString(original.status).toUpperCase()}
					</strong>
				);
			case eventConstants.TASKS.STATUS.IN_PROGRESS:
				return (
					<strong className="text-warning">
						{displayString(original.status).toUpperCase()}
					</strong>
				);
			case eventConstants.TASKS.STATUS.COMPLETED:
				return (
					<strong className="text-secondary">
						{displayString(original.status).toUpperCase()}
					</strong>
				);
			default:
				return null;
		}
	};

	const TypeCell = ({ value }) => {
		return (value === EXTERNAL && 'External') || (value === INTERNAL && 'Internal') || null;
	};

	return [
		{ Header: 'Task', accessor: 'name', Cell: NameCell },
		{ Header: 'Comments', accessor: 'comment', maxWidth: 100, Cell: CommentsCell },
		{ Header: 'Assignee', id: 'assignee user', accessor: userAccessor, Cell: AssignedUserCell },
		{ Header: 'Due Date', accessor: 'due_date', Cell: DateCell },
		{ Header: 'Attachment', accessor: 'id', Cell: EventTaskAttachmentLinkCell },
		{ Header: 'Status', id: 'status', accessor: statusAccessor, Cell: StatusCell },
		{ Header: 'Type', accessor: 'assignment_type', Cell: TypeCell, show: canViewTypeOnTasks },
		{
			Header: 'Active',
			accessor: 'active',
			Cell: ActiveCell,
			width: 64,
			show: canViewInactiveTasks,
		},
		{
			...actionsColumn,
			Cell: ActionsCell,
		},
	];
};

const TasksTab = ({ id, show, permissions }) => {
	const userProfile = useUserProfile();
	const { canAddTask } = permissions;
	const queryClient = useQueryClient();
	const [openModal, setOpenModal] = useState();
	const [isSubmitting, setIsSubmitting] = useState();
	const [delegateCalls, setDelegateCalls] = useState(null);

	const tasksQuery = useQuery(['getEventTasks', id], () => getEventTasks(id), {
		enabled: !!show,
		onSuccess: (response) => {
			const token = response.headers?.['x-pdb-authorization'];
			const userIDs =
				response.data?.tasks
					.map((task) => task.assigned_ndid_user_id)
					.filter((ids) => ids) || null;
			const taskIDs =
				response.data?.tasks
					.map((task) => task.id)
					.filter((ids, index, array) => !!ids && array.indexOf(ids) === index) || null;
			const companyIDs =
				response.data?.tasks
					.map((task) => task.assigned_ndid_company_id)
					.filter((ids, index, array) => !!ids && array.indexOf(ids) === index) || null;
			setDelegateCalls({ token, userIDs, taskIDs, companyIDs });
		},
	});

	let tasks = (tasksQuery.isSuccess && tasksQuery.data.data.tasks) || [];

	const usersQuery = useQuery(
		['getUsersInBulk', delegateCalls?.token],
		() => getUsersInBulk(null, null, delegateCalls?.token),
		{ enabled: false },
	);
	const users = usersQuery.isSuccess && usersQuery.data.data;

	const companiesQuery = useQuery(
		['getCompanyInBulk', ...(delegateCalls?.companyIDs || [])],
		() => getCompaniesInBulk(delegateCalls?.companyIDs, ['company_name']),
		{ enabled: false },
	);
	const companies = (companiesQuery.isSuccess && companiesQuery.data.data) || [];

	const filesQuery = useQuery(
		['getUploadedFileData', ...(delegateCalls?.taskIDs || [])],
		() => getUploadedFileData(delegateCalls?.taskIDs, 'marketing_event_task', delegateCalls?.token),
		{ enabled: false },
	);
	const files = filesQuery.isSuccess && filesQuery.data.data;

	if (files) {
		tasks = tasks.map((task) => {
			const fileEntry = files.find((entry) => entry.entity_id === task.id);
			if (safeEval(() => fileEntry.files.length > 0)) {
				fileEntry.files.sort((a, b) => (new Date(a.upload_start_time) < new Date(b.upload_start_time)) ? 1 : -1);
				return {
					...task, marketing_event_task_id: task.id,
					attachment_file_name: fileEntry.files[0].name,
					attachment_upload_status: fileEntry.files[0].status,
					attachment_created_by_name: fileEntry.files[0].created_by_user_name,
					s3_content_metadata_id: fileEntry.files[0].id
				};
			} else {
				return {
					...task, marketing_event_task_id: task.id,
					attachment_file_name: null,
					attachment_upload_status: null,
					attachment_created_by_name: null,
					s3_content_metadata_id: null
				};
			}
		});
	}

	useEffect(() => {
		if (delegateCalls) {
			delegateCalls.userIDs && usersQuery.refetch();
			delegateCalls.companyIDs && companiesQuery.refetch();
			delegateCalls.taskIDs && filesQuery.refetch();
		}
	}, [delegateCalls]);

	const isLoading =
		tasksQuery.isLoading ||
		usersQuery.isLoading ||
		companiesQuery.isLoading ||
		filesQuery.isLoading;

	const fault = tasksQuery.isError && tasksQuery.error.toString();

	const dataFormat = useMemo(
		() => tasksQuery.isSuccess && getTableFormat({ userProfile, permissions, users, companies, files, setOpenModal }),
		[userProfile, tasksQuery, permissions, users],
	);

	const retryLoad = () => queryClient.invalidateQueries('getEventTasks');

	return (
		<>
			{canAddTask && !isLoading && (
				<ButtonToolbar className="mb-4">
					<Button onClick={e => setOpenModal({type: ADD_TASK_MODAL})}
					>Add Task</Button>
				</ButtonToolbar>
			)}
			{isLoading ? (
				<Loading />
			) : fault ? (
				<>
					<Alert variant="danger">
						{fault}
						<br />
						<ActionLink onClick={() => retryLoad()}>Retry load</ActionLink>{' '}
						<FAIcon size="xs" name="chevron-right" />
					</Alert>
				</>
			) : tasksQuery.isSuccess && tasks.length > 0 ? (
				<BaseTable
					data={tasks}
					columns={dataFormat}
					defaultSorted={[
						{
							id: 'active',
							desc: true,
						},
						{
							id: 'status',
							desc: false,
						},
						{
							id: 'due_date',
							desc: false,
						},
					]}
					noBorder
					retainPageState={false}
				/>
			) : tasksQuery.isSuccess && tasks.length === 0 ? (
				<Alert variant="warning">No tasks are attached to this event</Alert>
			) : (
				<span />
			)}
			<EventTaskModal
				mode={ADD_TASK_MODAL}
				show={openModal && openModal.type === ADD_TASK_MODAL}
				eventId={id}
				onClose={(e) => setOpenModal({ ...openModal, type: null })}
				onChange={(e) => {
					setOpenModal({ ...openModal, type: null });
					queryClient.invalidateQueries('getEventTasks');
				}}
			/>
			<EventTaskModal
				mode={EDIT_TASK_MODAL}
				show={openModal && openModal.type === EDIT_TASK_MODAL}
				eventId={id}
				onClose={(e) => setOpenModal({ ...openModal, type: null })}
				taskId={openModal && openModal.taskId}
				currentAttachment={openModal && openModal.currentAttachment}
				onChange={(e) => {
					setOpenModal({ ...openModal, type: null });
					queryClient.invalidateQueries('getEventTasks');
				}}
			/>
			<EventTaskModal
				mode={VIEW_TASK_MODAL}
				show={openModal && openModal.type === VIEW_TASK_MODAL}
				onClose={(e) => setOpenModal({ ...openModal, type: null })}
				taskId={openModal && openModal.taskId}
				currentAttachment={openModal && openModal.currentAttachment}
			/>
			<ActionConfirmationModal
				show={openModal && openModal.type === COMPLETE_MODAL}
				onCancel={() => setOpenModal(null)}
				onConfirm={() => {
					setIsSubmitting(true);
					completeTask(openModal.taskId,
						openModal.name,
						() => {
							setOpenModal(null);
							queryClient.invalidateQueries('getEventTasks');
						},
						() => {
							setIsSubmitting(false);
						}
					);
				}}
				isSubmitting={isSubmitting}
				title="Complete Task"
				confirmLabel="Complete"
			>
				<Alert className="mb-0" variant="info">
					Do you want to mark this task "{openModal && openModal.name}" as Complete?
				</Alert>
			</ActionConfirmationModal>
			<ActionConfirmationModal
				show={openModal && openModal.type === CLAIM_MODAL}
				onCancel={() => setOpenModal(null)}
				onConfirm={() => {
					setIsSubmitting(true);
					claimTask(openModal.taskId,
						openModal.name,
						userProfile,
						() => {
							setOpenModal(null);
							queryClient.invalidateQueries('getEventTasks');
						},
						() => {
							setIsSubmitting(false);
						}
					);
				}}
				isSubmitting={isSubmitting}
				title="Claim Task"
				confirmLabel="Claim"
			>
				<Alert className="mb-0" variant="info">
					Do you want claim this task "{openModal && openModal.name}" as your assignment?
				</Alert>
			</ActionConfirmationModal>
			<DeleteConfirmationModal
				show={openModal && openModal.type === DELETE_MODAL}
				closeModal={() => setOpenModal(null)}
				confirmDelete={() =>
					deleteTask(openModal.taskId,
						openModal.name,
						() => {
							setOpenModal(null);
							queryClient.invalidateQueries('getEventTasks');
						},
						() => {
							setIsSubmitting(false);
						}
					)
				}
				isSubmitting={isSubmitting}
				title="Delete Task"
				confirmLabel="Delete"
				message={`Please confirm that you would like to delete this task "${openModal &&
				openModal.name}". If there are any attachments, they will be deleted.`}
			/>
		</>
	);
};
export default TasksTab;
