import sanitizeHtml from 'sanitize-html';

/**
 * Remove underscores and capitalize only the first letter of words.
 * i.e. DIGITAL_AT_RETAIL -> Digital At Retail
 * @param  {string} str
 */
export function toCamelCase(str) {
	str = str.toLowerCase();
	return str.replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, function(match) {
		return match.toUpperCase();
	});
}

/**
 * Capitalize the first character of every word in a string.
 * @param  {string} str
 */
export function toProperCase(str) {
	var splitStr = str.toLowerCase().split(' ');
	for (var i = 0; i < splitStr.length; i++) {
		splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
	}
	return splitStr.join(' ');
}

// keepCaps are the words we want to keep capitalized
export function displayString(str, keepCaps = ['NOA', '3DS', 'PDR', 'NCL', 'TV', 'OEM']) {
	if (!str) { return ''; }
	const strArr = str.replace(/_/g, ' ').split(' ');
	let humanStr = '';
	strArr.forEach((word) => {
		let formattedWord = word;
		if (!keepCaps.includes(word)) {
			formattedWord = word
				.toLowerCase()
				.replace(/(?: |\b)(\w)/g, function(key) {
					return key.toUpperCase();
				});
		}
		humanStr += ` ${formattedWord}`;
	});
	// return the new string except the first char which will always be ' '
	return humanStr.substr(humanStr.length - (humanStr.length - 1));
}

export function toKebabCase(str) {
	return str.replace(/ /g, '-').toLowerCase();
}

export function toScreamingSnakeCase(str) {
	return str.replace(/ /g, '_').toUpperCase();
}

export function isValidPhoneNumber(phoneNum) {
	if (!phoneNum) return false;
	phoneNum = phoneNum.replace(new RegExp('\\.', 'g'), '');
	phoneNum = phoneNum.replace(new RegExp('\\+', 'g'), '');
	phoneNum = phoneNum.replace(new RegExp(' ', 'g'), '');
	phoneNum = phoneNum.replace(new RegExp('-', 'g'), '');
	phoneNum = phoneNum.replace(new RegExp('\\(', 'g'), '');
	phoneNum = phoneNum.replace(new RegExp('\\)', 'g'), '');
	return /^[0-9]*$/.test(phoneNum);
}

export function isValidGameCode(gameCode) {
	return /^[0-9A-Za-z]*$/.test(gameCode) && gameCode.length <= 5;
}

export function isValidEmailAddresss(emailAddress) {
	return /^\S+@\S+\.\S+$/.test(emailAddress);
}

/**
 * Truncate a phrase without cutting off words. Appends an ellipses if phrase is shortened.
 * @param  {string} phrase
 * @param  {number} charactersMax
 */
export function truncatePhrase(phrase, charactersMax) {
	if (phrase.length <= charactersMax) return phrase;
	const cutPhrase = phrase.match(/([\s?!.]*[^\s?!.]*)/g);
	const segmentTotal = cutPhrase.length - 1;
	for (let i = 0; i < segmentTotal; i++) {
		const candidatePhrase = cutPhrase.join('');
		if (candidatePhrase.length <= charactersMax) {
			return cutPhrase.join('') + (candidatePhrase !== phrase ? '…' : '');
		}
		cutPhrase.pop();
	}
	return cutPhrase[0]+'…';
}

export const getSanitizedHTML = (inHTML) => {
	return sanitizeHtml(inHTML, {
		allowedTags: ['b', 'i', 's', 'u', 'br', 'sup', 'sub', 'em', 'strong'],
	});
};
