import { accessTypeConst } from '../../../constants/permConst';
import { userHasInternalRole } from '../../../utils/userUtils';
import { transferRightsConstants } from '../../../constants/transferRightsConstants';

const { MANAGER, COORDINATOR, LEAD, SUPPORT } = accessTypeConst;

export const formatRightType = (type) => {
	const { OWNERSHIP, RETAIL, DIGITAL } = transferRightsConstants.DISTRIBUTION_TYPE;
	switch (type) {
		case OWNERSHIP:
			return 'Owner';
		case RETAIL:
			return 'Retail';
		case DIGITAL:
			return 'Digital';
		default:
			return type;
	}
};

export const getPermissionsForEvent = (userProfile) => {
	const isInternal =  userHasInternalRole(userProfile);

	return {
		showTransfersColumn: false, // TODO later
		showPublisherColumn: isInternal,
	};
};

export const allowViewEventProduct = (userProfile, accessType, product) =>  {
	return (accessType === MANAGER || accessType === LEAD || accessType === SUPPORT) ||
		(accessType === COORDINATOR && product.coordinators && product.coordinators.includes(userProfile.userId)) ;
};

export const getEventProductRoute = (id) =>
	`/marketing-events/products/${id}`;
