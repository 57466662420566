import React, { Suspense, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, FormCheck, FormControl, FormGroup, FormLabel, Row, Tab, Tabs } from 'react-bootstrap';
import { useQueryClient } from 'react-query';

import BaseModal from '../../../components/BaseModal/BaseModal';
import FAIcon from '../../../components/FAIcon/FAIcon';
import HelpBlock from '../../../components/HelpBlock/HelpBlock';
import { permConst } from '../../../constants/permConst';
import { useUserProfile } from '../../../hooks/reduxHooks';
import { postRecordPayPalPayment, postSetPasswordTnc, postSubmitWirePayment } from '../../../services/digitalCodesService';
import { safeEval } from '../../../utils/dataUtils';
import { calculateTotalBill, validatePassword } from '../../../utils/digitalCodesUtils';
import { cancelToast, createMessageForError, toasterNotify } from '../../../utils/toaster';
import { isAuthorized } from '../../../utils/userUtils';
import PasswordPrompt from '../views/PasswordPrompt';

import './PaymentModal.css';


const PaypalButton = React.lazy(() => import('../views/PaypalButton'));
const PaypalButtonNew = React.lazy(() => import('../views/PaypalButton__NEW'));

const PaymentModal = ({show, order, onClose}) => {
	const userProfile = useUserProfile();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [inPaymentPhase, setInPaymentPhase] = useState(false);
	const [paymentCompleted, setPaymentCompleted] = useState(false);
	const [disqualifiedPasswords, setDisqualifiedPasswords] = useState([]);
	const [password, setPassword] = useState('');
	const [tncChecked, setTncChecked] = useState(false);
	const [wireId, setWireId] = useState('');
	const [showAllErrors, setShowAllErrors] = useState(false);
	const queryClient = useQueryClient();

	const [, paymentAmount] = order ? calculateTotalBill(order) : {};

	const isPaypalEligible = paymentAmount && (paymentAmount < 10000);

	const PaypalButtonComponent = isAuthorized(userProfile.permissions, [
		permConst.DIGITAL_ORDER.PAYPAL.ORDER_API.COMPANY,
	])
		? PaypalButtonNew
		: PaypalButton;

	const submitPasswordTnc = async () => {
		if (
			!tncChecked ||
            !validatePassword(password).every((v) => !!v) ||
            (disqualifiedPasswords && disqualifiedPasswords.find(dq => dq.password === password))
		) {
			setShowAllErrors(true);
			return;
		}
		setIsSubmitting(true);
		try {
			const response = await postSetPasswordTnc(order.orderID, password);
			if (response.data?.payment_captured === true) {
				setPaymentCompleted(true); 
			} else {
				setInPaymentPhase(true);
			}
		} catch (error) {
			if (
				error.response &&
				error.response.status === 400 &&
				(safeEval(() => error.response.data.message.error.message) || '').includes(
					"file_password can't utilize any of the last 3 order passwords for this user",
				)
			) {
				cancelToast(error);
				toasterNotify(
					'Server has rejected your submitted password for having been used before. Please try another password.',
					'error',
				);
				setDisqualifiedPasswords([
					...disqualifiedPasswords,
					{ password, rejectedForReuse: true },
				]);
				setShowAllErrors(true);
			}
		} finally {
			setIsSubmitting(false);
			setShowAllErrors(false);
		}
	};

	const submitWirePayment = async () => {
		if (!wireId) {
			setShowAllErrors(true);
			return;
		}
		setIsSubmitting(true);
		try {
			await postSubmitWirePayment(order.orderID, wireId);
			onClose();
			toasterNotify('Payment submitted', 'success');
			queryClient.invalidateQueries('getDigitalOrder', { refetchInactive: true });
			queryClient.invalidateQueries('getDigitalOrders', { refetchInactive: true });
		} catch (error) {
			toasterNotify(
                createMessageForError(error, 'submitting payment'),
                'error',
                error
			);
		} finally {
			setIsSubmitting(false);
		}
	};

	const onPaypalSuccess = async (payment) => {
		// TODO: this block is not invoked with the new paypal button, remove this along with the old paypal button
		if (payment) {
			setIsSubmitting(true);
			try {
				await postRecordPayPalPayment(order.orderID, payment.paymentID);
				onClose();
				toasterNotify('Your PayPal payment was submitted', 'success');
				queryClient.invalidateQueries('getDigitalOrder', { refetchInactive: true });
				queryClient.invalidateQueries('getDigitalOrders', { refetchInactive: true });
			} catch (error) {
				toasterNotify(
					createMessageForError(error, 'submitting payment'),
					'error',
					error
				);
			} finally {
				setIsSubmitting(false);
			} 
		} else {
			onClose();
			toasterNotify('Your PayPal payment was submitted', 'success');
			queryClient.invalidateQueries('getDigitalOrder', { refetchInactive: true });
			queryClient.invalidateQueries('getDigitalOrders', { refetchInactive: true });
		}
	};

	// TODO: this is not used in new paypal button, remove this along with the old paypal button
	const onPaypalFailure = (error) => {
		toasterNotify(
            'The PayPal payment interface returned an error. Please contact site support for help.',
            'error',
            error?.message?.split('\n\n')[0]
		);
	};

	useEffect(() => {
		if (show) {
			setIsSubmitting(false);
			setShowAllErrors(false);
			setPassword('');
			setTncChecked(false);
			setInPaymentPhase(false);
			setWireId('');
			setDisqualifiedPasswords([]);
		}
	}, [show]);

	return (
		<BaseModal
			isSubmitting={isSubmitting}
			show={show}
			onCancel={() => {
				if (paymentCompleted) {
					queryClient.invalidateQueries('getDigitalOrder', { refetchInactive: true });
					queryClient.invalidateQueries('getDigitalOrders', { refetchInactive: true });
				}
				onClose();
			}}
			closeText={paymentCompleted ? 'Close' : 'Cancel'}
		>
			<BaseModal.Title>Order Payment Processing</BaseModal.Title>
			<section
				className={
					inPaymentPhase || paymentCompleted ? 'PaymentModal__disabled-section' : ''
				}
			>
				<h4>1. Create a Password and Accept Terms and Conditions</h4>
				<PasswordPrompt
					value={password}
					onChange={(newPassword) => setPassword(newPassword)}
					disabled={inPaymentPhase || paymentCompleted || isSubmitting}
					disqualifiedPasswords={disqualifiedPasswords}
					showFormErrors={!inPaymentPhase && showAllErrors}
				/>
				<FormGroup as={Row} className="mb-0">
					<Col sm={12}>
						<FormCheck type="checkbox" id="accept-checkbox">
							<FormCheck.Input
								type="checkbox"
								onClick={(e) => setTncChecked(!!e.target.checked)}
								disabled={inPaymentPhase || isSubmitting}
							/>
							<FormCheck.Label>
								Accept&nbsp;
								<a
									href={`${process.env.PUBLIC_URL}/digital_codes_tnc.html`}
									target="_blank"
									rel="noopener noreferrer"
								>
									Terms &amp; Conditions
								</a>
								&nbsp;for Digital Code Orders
							</FormCheck.Label>
						</FormCheck>
						{showAllErrors && tncChecked === false && (
							<HelpBlock>
								<span className="text-danger">
									Please accept Terms &amp; Conditions to continue
								</span>
							</HelpBlock>
						)}
					</Col>
				</FormGroup>
			</section>
			<section className={paymentCompleted ? null : 'd-none'} aria-hidden={!paymentCompleted}>
				<h4>Payment not necessary</h4>
				<Alert variant="info">
					PayPal is reporting a payment for this order was made previously. The order
					status will be adjusted and no further payment is necessary. The password
					entered above will be used for the digital code delivery. Contact site support
					for more information.
				</Alert>
			</section>
			<section className={inPaymentPhase ? null : 'd-none'} aria-hidden={!inPaymentPhase}>
				<h4>2. Select a Payment Option</h4>
				<Tabs defaultActiveKey="wire" className="justify-content-center">
					<Tab eventKey="wire" title="Wire Payment">
						<Form className="my-3">
							<FormGroup as={Row}>
								<FormLabel as={Col} column sm={6} htmlFor="wire-transfer-input">
									Wire Transfer/LOC number
								</FormLabel>
								<Col sm={6}>
									<FormControl
										id="wire-transfer-input"
										as={'input'}
										type="text"
										value={wireId}
										onChange={(e) => setWireId(e.target.value)}
										maxLength={50}
									/>
									{!wireId && showAllErrors && (
										<HelpBlock variant="danger">
											This value cannot be blank. Max length 50 characters.
										</HelpBlock>
									)}
									{wireId.length === 50 && (
										<HelpBlock>
											This field is at the 50 character limit.
										</HelpBlock>
									)}
									<Button
										className="mt-2"
										onClick={submitWirePayment}
										style={{ width: '100%' }}
									>
										Submit Wire Payment
									</Button>
								</Col>
							</FormGroup>
							<div className="text-right"></div>
						</Form>
					</Tab>
					<Tab
						eventKey="paypal"
						title={
							<>
								PayPal <FAIcon variant="brands" name="paypal" />
							</>
						}
					>
						<Row>
							<Col className="text-center mx-auto" sm={12}>
								{isPaypalEligible ? (
									<>
										<div className="my-4" style={{ minHeight: '45px' }}>
											<Suspense fallback={null}>
												<PaypalButtonComponent
													commit={true}
													currency={'USD'}
													orderId={order.orderID}
													total={paymentAmount}
													onSuccess={onPaypalSuccess}
													onError={onPaypalFailure}
													onCancel={() => 0}
												/>
											</Suspense>
										</div>
										<HelpBlock variant="danger">
											PayPal payment is not available for orders larger than
											$10,000
										</HelpBlock>
									</>
								) : (
									<Button className="mt-3" disabled>
										PayPal cannot be used for orders larger than $10,000
									</Button>
								)}
							</Col>
						</Row>
					</Tab>
				</Tabs>
			</section>
			{!inPaymentPhase && !paymentCompleted ? (
				<BaseModal.Submit onClick={submitPasswordTnc}>
					Next <FAIcon name="chevron-right" />
				</BaseModal.Submit>
			) : null}
		</BaseModal>
	);
};
export default PaymentModal;
