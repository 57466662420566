import React, { Component, Fragment } from 'react';
import { FormControl } from 'react-bootstrap';

import './styles.css';

// https://alligator.io/react/react-autocomplete/
class AutoComplete extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// The active selection's index
			activeSuggestion: -1,
			// The suggestions that match the user's input
			filteredSuggestions: [],
			// Whether or not the suggestion list is shown
			showSuggestions: false,
			// What the user has entered
			userInput: this.props.defaultValue ? this.props.defaultValue : ''
		};

		this.handleOutsideClick = this.handleOutsideClick.bind(this);
	}

	// Close the suggestion box if user clicks outside the suggestion box.
	handleOutsideClick(e) {
		if (
			e.target.className !== 'suggestion-active' &&
			e.target.className !== 'form-control' &&
			this.state.showSuggestions
		) {
			this.setState({
				activeSuggestion: -1,
				filteredSuggestions: [],
				showSuggestions: false
			});
		}
	}

	filterSuggestions(userInput) {
		const { suggestions } = this.props;

		return new Map(
			[...suggestions].filter(
				([k, v]) =>
					v.toLowerCase().indexOf(userInput.toLowerCase()) > -1
			)
		);
	}

	// Event fired when the input value is changed
	onChange = (e) => {
		const userInput = e.currentTarget.value;

		// Filter our suggestions that don't contain the user's input
		const filteredSuggestions = this.filterSuggestions(userInput);

		this.setState({
			activeSuggestion: -1,
			filteredSuggestions,
			showSuggestions: true,
			userInput: e.currentTarget.value
		});
	};

	// Event fired when the user clicks on a suggestion
	onClick = (key, event) => {
		const { userClicked } = this.props;
		// Update the user input and reset the rest of the state
		const userInput = event.currentTarget.innerText;

		this.setState({
			activeSuggestion: -1,
			filteredSuggestions: [],
			showSuggestions: false,
			userInput
		});
		userClicked(event, key);
	};

	// Called when user clicks in the auto complete text box
	onInputClick = () => {
		const { suggestions } = this.props;
		const { userInput } = this.state;

		let filteredSuggestions = this.state.filteredSuggestions;

		if (filteredSuggestions.length < 1 || userInput.length < 1) {
			filteredSuggestions = suggestions;
		} else {
			filteredSuggestions = this.filterSuggestions(userInput);
		}
		this.setState({
			activeSuggestion: -1,
			filteredSuggestions: filteredSuggestions,
			showSuggestions: true
		});
	};

	// Event fired when the user presses a key down
	onKeyDown = (e) => {
		const { activeSuggestion, filteredSuggestions } = this.state;

		// User pressed the enter key, update the input and close the
		// suggestions
		if (e.keyCode === 13 && activeSuggestion !== -1) {
			this.setState({
				activeSuggestion: -1,
				showSuggestions: false,
				userInput: filteredSuggestions[activeSuggestion]
			});
		}
		// User pressed the up arrow, decrement the index
		else if (e.keyCode === 38) {
			if (activeSuggestion === -1) {
				return;
			}
			this.setState({ activeSuggestion: activeSuggestion - 1 });
		}
		// User pressed the down arrow, increment the index
		else if (e.keyCode === 40) {
			if (activeSuggestion - 1 === filteredSuggestions.length) {
				return;
			}

			this.setState({ activeSuggestion: activeSuggestion + 1 });
		}
	};

	componentDidMount() {
		document.addEventListener('click', this.handleOutsideClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleOutsideClick);
	}

	createElementListOfFilteredValues() {
		const { filteredSuggestions } = this.state;

		const returnArray = [];

		filteredSuggestions.forEach((value, key) => {
			let onClick = this.onClick.bind(this, key);

			returnArray.push(
				<li key={key} onClick={onClick}>
					{value}
				</li>
			);
		});

		return returnArray;
	}

	render() {
		const {
			onChange,
			onInputClick,
			onKeyDown,
			state: { filteredSuggestions, showSuggestions, userInput }
		} = this;

		const { disabled } = this.props;

		let suggestionsListComponent;

		if (showSuggestions) {
			if (filteredSuggestions.size) {
				suggestionsListComponent = (
					<ul
						className="suggestions"
						ref={(node) => {
							this.node = node;
						}}
					>
						{this.createElementListOfFilteredValues()}
					</ul>
				);
			} else {
				suggestionsListComponent = (
					<div className="no-suggestions" id="no-suggestions">
						<em>
							{this.props.noSuggestionsText ||
								'No matches found.'}
						</em>
					</div>
				);
			}
		}

		return (
			<Fragment>
				<FormControl
					type="text"
					onClick={onInputClick}
					onChange={onChange}
					onKeyDown={onKeyDown}
					value={userInput}
					placeholder={this.props.placeholderText}
					disabled={disabled}
				/>
				{suggestionsListComponent}
			</Fragment>
		);
	}
}

export default AutoComplete;
