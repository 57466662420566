import React, { useEffect, useState, VFC } from 'react';
import AsyncSelect from 'react-select/async';
import { Alert } from 'react-bootstrap';
import * as yup from 'yup';
import { useQuery, useQueryClient } from 'react-query';
import { useUserProfile } from '../../../hooks/reduxHooks';
import Forms from '../../../components/Forms/Forms';
import BaseModal from '../../../components/BaseModal/BaseModal';
import {
	addPDBuser,
	getAssignableRoles,
	getNDIDAdmins,
	getNDIDUsers
} from '../../../services/usersService';
import { ncl_company_ndid } from '../../../constants/generalConstants';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import EditUserNotice from './EditUserNotice';
import { isEmptyObject } from '../../../utils/dataUtils';
import { validateToSchema } from '../../../utils/validationUtils';


const schema = yup.object().shape({
	asyncUserSelect: yup.object().required('User is required'),
	checkedRoles: yup.array().min(1, 'At least one role needs to be selected').required('At least one role needs to be selected'),
});

const COMPANY_ADMIN_ROLE = {
	role_id: 18,
	name: 'Company Admin',
	editable: false,
	hasRole: true,
};

interface AddUserModalProps {
	show: boolean;
	onCompletion: () => void;
	onClose: () => void;
	nclManagement: boolean;
}

const AddUserModal: VFC<AddUserModalProps> = ({show, onCompletion, onClose, nclManagement}) => {
	const queryClient = useQueryClient();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [formValues, setFormValues] = useState<Record<string, any>>({});
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);
	const validationErrors = validateToSchema(schema, formValues);
	const userProfile = useUserProfile();

	const ndidAdminsQuery = useQuery(['getNDIDAdmins',userProfile.companyId],() => getNDIDAdmins(),
		{ enabled: !nclManagement && show });
	const ndidAdmins = ndidAdminsQuery.data?.data;
	const admins = ndidAdmins ? ndidAdmins.map((a) => a.ndidUserId) : [];

	const nclRolesQuery = useQuery(['getAssignableRoles',{'ndid_company_id': ncl_company_ndid}], () => getAssignableRoles({'ndid_company_id': ncl_company_ndid}),
		{ enabled: (show && nclManagement) });
	const rolesQuery = useQuery('getAssignableRoles', () => getAssignableRoles(),
		{ enabled: (show && !nclManagement) });
	const displayedAssignableRoles = () => {
		if (!nclRolesQuery.data?.data && !rolesQuery.data?.data) {
			return;
		}
		const assignableRolesQryResult = nclManagement ? nclRolesQuery.data?.data : rolesQuery.data?.data;
		const roles = assignableRolesQryResult?.map(obj => ({ ...obj, editable: true }));

		if (formValues?.asyncUserSelect?.is_admin) {
			roles?.push(COMPANY_ADMIN_ROLE);
		}
		return roles?.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
	};

	useEffect(() => {
		if (show) {
			setIsSubmitting(false);
			setFormValues({});
		}
	}, [show]);

	const submitForm = () => {
		if (isSubmitting) {
			return;
		}
		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}
		setIsSubmitting(true);
		const roles = convertMapToAPIFormat();
		let requestBody = {roles, initial_query: formValues.asyncUserSelect.user_name};
		const nclParm = nclManagement ? ncl_company_ndid : undefined;
		addPDBuser(formValues.asyncUserSelect.ndid_id, requestBody, nclParm)
			.then(() => {
				toasterNotify('User added', 'success');
			})
			.catch((error) => {
				if (error?.response?.status === 409) {
					toasterNotify('User is already in the system', 'warning');
				} else {
					toasterNotify(
						createMessageForError(error, 'adding user'),
						'error',
						error
					);
				}
			})
			.finally(() => {
				queryClient.invalidateQueries(['getAssignableRoles', userProfile.companyId]);
				onCompletion();
			});
	};

	const convertMapToAPIFormat = (): Array<{role_id: number, has_role: boolean}> => {
		const roles: Array<{role_id: number, has_role: boolean}> = [];
		formValues.checkedRoles.map((checkRole: string) => {
			roles.push({
				role_id: parseInt(checkRole),
				has_role: true
			});
		});
		return roles;
	};

	const formValueChanged = (newValues: Record<string, any>) => {
		if (formValues?.asyncUserSelect?.value !== newValues?.asyncUserSelect?.value) {
			if (newValues?.asyncUserSelect?.is_admin) {
				newValues['checkedRoles'] = [String(COMPANY_ADMIN_ROLE.role_id)];
			} else {
				newValues['checkedRoles'] = [];
			}
		}

		setFormValues(newValues);
	};

	const loadOptions = async (input: string) => {
		setFormValues({});

		if (input?.length < 2 || /^\s+$/.test(input)) return [];

		try {
			const inputParm: {q: string, ndid_company_id?: string|null} = {'q': input};
			if (nclManagement) {
				inputParm.ndid_company_id = ncl_company_ndid;
			}
			const response = await getNDIDUsers(inputParm);
			const users = response.data;
			const usersNotImported = users.filter((user) => !user.is_imported);
			setFormValues({});
			return usersNotImported.map((user) => {
				return {value: user?.loginid,
					label: `${user.user_name} [${user.loginid}]`,
					is_admin: !!admins.includes(user.ndid_id),
					user_name: user?.user_name,
					ndid_id: user?.ndid_id};
			});
		} catch(error: any) {
			toasterNotify(createMessageForError(error, 'obtaining NDID users'), 'warning', error);
			return [];
		}
	};

	return (
		<BaseModal
			show={show}
			onCancel={onClose}
			isSubmitting={isSubmitting}
		>
			<BaseModal.Title>Add User</BaseModal.Title>
			<EditUserNotice/>
			<Forms
				onChange={(newValues) => formValueChanged(newValues)}
				values={formValues}
				validationErrors={validationErrors}
				showAllErrors={showAllErrors}
			>
				{formValues && formValues?.userSelect && (
					<Alert variant="warning">'This user is eligible to be added. A minimum of one role must be assigned for a user.'</Alert>
				)}
				<Forms.CustomArea>
					<Forms.Heading>Select User</Forms.Heading>
					<AsyncSelect cacheOptions
								 loadOptions={loadOptions}
								 defaultOptions
								 placeholder='Search for users'
								 onChange={(newValue: any) => formValueChanged({
									 ...formValues,
									 asyncUserSelect: newValue
								 })}
					/>
					{validationErrors?.asyncUserSelect && (
						<Forms.Help>
							<span className="text-danger">
								{validationErrors?.asyncUserSelect}
							</span>
						</Forms.Help>
					)}
				</Forms.CustomArea>
				<Forms.CheckboxList id="checkedRoles">
					<Forms.Heading>Role(s)</Forms.Heading>
					{displayedAssignableRoles()?.map((role) => (
						<Forms.Option
							value={String(role.role_id)}
							disabled={!role.editable}
						>
							{role.name}
						</Forms.Option>
					))}
				</Forms.CheckboxList>
			</Forms>
			<BaseModal.Submit
				onClick={() => submitForm()}
			>
				Add User
			</BaseModal.Submit>
		</BaseModal>
	);
};

export default AddUserModal;
