import React from 'react';
import {
	Alert,
	Col,
	Form,
	FormCheck,
	FormControl,
	FormGroup,
	FormLabel,
	Row,
} from 'react-bootstrap';

import AsperaFileUpload from '../../../components/AsperaFileUpload/AsperaFileUpload';
import BaseModal from '../../../components/BaseModal/BaseModal';
import DropdownSelect from '../../../components/DropdownSelect/DropdownSelect';
import { fileTransferConst } from '../../../constants/fileTransferConstants';
import { platformCodes, platformTypeRelations } from '../../../constants/platformConstants';
import {
	defaultNoRating,
	gameRatingIARC,
	gameRatings,
	productTypes,
	ratingsDescriptorsESRB,
	ratingsDescriptorsIARC,
	subConst,
	submissionTypes,
} from '../../../constants/submissionsConstants';
import { getCompanyAgreements } from '../../../services/companiesService';
import { postProductSubmission } from '../../../services/productsService';
import { validateActiveAgreementType } from '../../../utils/companyUtils';
import * as componentUtils from '../../../utils/componentUtils';
import { safeEval } from '../../../utils/dataUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';

import '../ProductProfile.css';
import { withRouter } from 'react-router-dom';

function extractProductType(submission) {
	return submission.product_type.substring(0, submission.product_type.indexOf('-', 5));
}

function verifyVersions(submission, productType) {
	let validReleaseVersion = /^[0-9]{2}$/.test(submission.version.split('.')[0]);

	// Patches can not have a release of 00
	if (productType.slice(productType.indexOf('-' + 1)) === 'U' && validReleaseVersion) {
		validReleaseVersion = parseInt(submission.version.split('.')[0]) > 0;
	}

	let submissionVersion = submission.version.split('.')[1];

	// Precheck need to have an 'E' in the submission version
	let validSubmissionVersion =
		submission.type === subConst.TYPE.PRECHECK
			? /^[0-9]{2}E$/.test(submissionVersion)
			: /^[0-9]{2}$/.test(submissionVersion);

	return [validReleaseVersion, validSubmissionVersion];
}

export class ReceiveSubmissionModal extends React.Component {
	constructor(props) {
		super(props);
		const { submissions, productSummary } = this.props;
		const alreadyCreatedSubmissions = [];

		submissions.forEach((submission) => {
			alreadyCreatedSubmissions.push({
				releaseVersion: submission.product_release_version,
				productType: extractProductType(submission),
				submissionType: submission.type,
			});
		});

		const platformCode = platformCodes[productSummary.platform_name];

		const productReleaseVersions = submissions.map((s) => {
			return {
				releaseVersion: s.product_release_version,
				productType: extractProductType(s),
				submissionVersion: s.product_submission_version,
				submissionType: s.type,
			};
		});
		this.state = {
			alreadyCreatedSubmissions: alreadyCreatedSubmissions,
			display: true,
			isFilesSelected: false,
			isUploadInitiated: false,
			submissionId: null,
			selectableProductTypes: [],
			productReleaseVersions: productReleaseVersions,
			ratingsDescriptorsChoices: ratingsDescriptorsESRB,
			isWUP: platformCode === platformCodes['Wii U'],
			isLoading: true,
			submission: {
				version: '',
				type: submissionTypes[0],
				onlineMultiPlay: false,
				onlineCommerce: false,
				productType: '',
				wifi: false,
				ugc: false,
				aoc: false,
				header_crc: '',
				data_crc: '',
				fileName: '',
				rom_cxi: '',
				e_manual_cfa: '',
				master_data_info: '',
				gameRating: defaultNoRating,
				ratingsDescriptors: [],
				iarcEsrbRatingFile: '',
				masterDataSubmissionSheet: '',
				receivingComments: '',
				isLoading: false,
			},
		};

		this.updateFilesSelected = this.updateFilesSelected.bind(this);
		this.toggleLoading = this.toggleLoading.bind(this);
		this.viewSubmission = this.viewSubmission.bind(this);
		this.updateGameRating = this.updateGameRating.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading,
		});
	}

	async getAvailableProductTypes() {
		const { productSummary } = this.props;
		const platformCode = platformCodes[productSummary.platform_name];

		let availableTypes = productTypes.filter(
			(type) => !/-[NP]{1}$/.test(type) && type.includes(platformCode),
		);
		

		const owner = productSummary.distribution.filter((d) => {
			return d.right_type === 'OWNERSHIP';
		});
		if (productSummary.party === 'THIRD' && owner.length) {
			try {
				const agreements = await getCompanyAgreements(owner[0].ndid_company_id);
				availableTypes = this.filterProductTypesByCompanyAgreements(
					availableTypes,
					platformCode,
					agreements.data,
				);
			} catch (error) {
				toasterNotify(
					createMessageForError(error, 'retrieving product types'),
					'warning',
					error,
				);
				this.setState({ isLoading: false });
			}
		}

		this.setState({
			selectableProductTypes: availableTypes,
			isLoading: false,
		});
	}

	componentDidMount() {
		this.getAvailableProductTypes();
	}

	filterProductTypesByCompanyAgreements(productTypes, platformCode, companyAgreements) {
		let filteredTypes = [];
		for (let type of productTypes) {
			let distType = platformTypeRelations[type].distribution_type;
			if (distType === 'PHYSICAL') {
				distType = 'RETAIL';
			}
			if (
				validateActiveAgreementType(
					companyAgreements,
					distType,
					platformCode === platformCodes['New 3DS Exclusive']
						? platformCodes['Nintendo 3DS']
						: platformCode, // LDBR-3082 quick fix
					//platformCode,
				)
			) {
				filteredTypes.push(type);
			}
		}
		return filteredTypes;
	}

	checkProductReleaseExisting() {
		const { submission, productReleaseVersions } = this.state;
		const version = submission.version;
		let dotIdx = version.indexOf('.');
		if (dotIdx === -1) {
			return false;
		}

		let newSubmission = {
			releaseVersion: version.split('.')[0],
			submissionVersion: version.split('.')[1],
			productType: submission.productType,
			submissionType: submission.type,
		};
		return (
			productReleaseVersions.filter((s) => {
				return (
					s.productType === newSubmission.productType &&
					s.releaseVersion === newSubmission.releaseVersion &&
					s.submissionVersion === newSubmission.submissionVersion &&
					s.submissionType === newSubmission.submissionType
				);
			}).length > 0
		);
	}

	receiveSubmission() {
		this.setState({ isSubmitting: true });
		const { productSummary } = this.props;
		const { submission } = this.state;

		let payload = this.getSubmissionPayload(submission);
		let submissionId;
		postProductSubmission(productSummary.product_id, payload)
			.then((response) => {
				submissionId = response.data.product_submission_id.toString();
				this.setState({
					submissionId: submissionId,
				});
				if (this.state.isFilesSelected) {
					this.setState({
						isUploadInitiated: true,
					});
				}
				toasterNotify(`Received Submission: ${submissionId}`, 'success');
			})
			.catch((error) => {
				let errorMessage;
				if (safeEval(() => error.response.status) === 409) {
					errorMessage = safeEval(() => error.response.data.message.error.message);
				} else {
					errorMessage = createMessageForError(error, 'receiving submissions');
				}
				toasterNotify(errorMessage, 'error', error);
				this.props.closeModal();
			})
			.finally(() => {
				if (!this.state.isFilesSelected) {
					this.viewSubmission();
				}
			});
	}

	viewSubmission() {
		const { submissionId } = this.state;
		this.props.history.replace(
			`/submission-details/${this.props.productSummary.product_id}/${submissionId}`,
		);
	}

	getSubmissionPayload(submission) {
		const { isWUP } = this.state;
		const game_rating =
			submission.gameRating === defaultNoRating ? null : submission.gameRating;

		const payload = {
			version: submission.version,
			submission_type: submission.type,
			product_type: submission.productType,
			onlineMultiPlay: submission.onlineMultiPlay,
			online_commerce: submission.onlineCommerce,
			wifi: submission.wifi,
			ugc: submission.ugc,
			aoc: submission.aoc,
			receiving_comments: submission.receivingComments,
			file_name: submission.fileName,
		};
		if (isWUP) {
			payload.data_crc = submission.data_crc;
			payload.header_crc = submission.header_crc;
		} else {
			payload.rom_cxi = submission.rom_cxi;
			payload.e_manual_cfa = submission.e_manual_cfa;
			payload.master_data_info = submission.master_data_info;
		}
		if (game_rating) {
			payload.game_rating = game_rating;
			payload.ratings_descriptors = submission.ratingsDescriptors;
		}

		return payload;
	}

	updateFilesSelected(isFilesSelected) {
		this.setState({
			isFilesSelected: isFilesSelected,
		});
	}

	canReceiveSubmission() {
		const { submission, isWUP } = this.state;

		const containsVersion = submission.version;
		const productType = submission.productType;

		const containsRomCXI = submission.rom_cxi;
		const containsEManualCFA = submission.e_manual_cfa;
		const containsMasterDataInfo = submission.master_data_info;

		const containsDataCRC = submission.data_crc;
		const containsHeaderCRC = submission.header_crc;

		const validFeatures = isWUP
			? containsDataCRC && containsHeaderCRC
			: containsRomCXI && containsEManualCFA && containsMasterDataInfo;

		// Needs rating descriptors if submission has a game rating
		const validRatingsDescriptors =
			submission.gameRating === defaultNoRating || submission.ratingsDescriptors.length;

		let validReleaseVersion = false;
		let validSubmissionVersion = false;
		if (submission.version && submission.version.includes('.')) {
			let valid = verifyVersions(submission, productType);
			validReleaseVersion = valid[0];
			validSubmissionVersion = valid[1];
		}

		return (
			containsVersion &&
			productType &&
			validFeatures &&
			validRatingsDescriptors &&
			validSubmissionVersion &&
			validReleaseVersion
		);
	}

	canCreateSubmission() {
		const { alreadyCreatedSubmissions, submission } = this.state;
		const submissionVersion = submission.version.split('.', 2);
		const userInputSubmission = submissionVersion.length > 1;

		const releaseExists =
			alreadyCreatedSubmissions.filter((s) => {
				return (
					s.releaseVersion === submission.version.split('.')[0] &&
					s.submissionVersion === submission.version.split('.')[1] &&
					s.productType === submission.productType
				);
			}).length > 0;
		return userInputSubmission && !releaseExists;
	}

	onInputChange(name, event) {
		let submission = this.state.submission;
		submission[name] = ReceiveSubmissionModal.getValue(event);
		this.setState({
			submission: submission,
		});
	}

	static getValue(event) {
		switch (event.target.type) {
			case 'checkbox':
				return event.target.checked;
			default:
				return event.currentTarget.value;
		}
	}

	updateSubmission(name, event, { value }) {
		let submission = this.state.submission;
		submission[name] = value;
		this.setState({
			submission: submission,
		});
	}

	updateGameRating(event) {
		const { submission } = this.state;

		const gameRating = event.currentTarget.value;
		submission.ratingsDescriptors = [];
		submission.gameRating = gameRating;

		this.setState({
			ratingsDescriptorsChoices: gameRating.includes(gameRatingIARC)
				? ratingsDescriptorsIARC
				: ratingsDescriptorsESRB,
			submission: submission,
		});
	}

	renderWiiUForm() {
		return (
			<div>
				{' '}
				<FormGroup as={Row}>
					<FormLabel id="header_crc" className="col-sm-4 text-sm-right">
						Header CRC
					</FormLabel>
					<Col sm={4}>
						<input
							onChange={this.onInputChange.bind(this, 'header_crc')}
							className="form-control"
							value={this.state.submission.header_crc}
						/>
					</Col>
				</FormGroup>
				<FormGroup as={Row}>
					<FormLabel id="data_crc" className="col-sm-4 text-sm-right">
						Data CRC
					</FormLabel>
					<Col sm={4}>
						<input
							onChange={this.onInputChange.bind(this, 'data_crc')}
							className="form-control"
							value={this.state.submission.data_crc}
						/>
					</Col>
				</FormGroup>
			</div>
		);
	}

	renderGenericForm() {
		return (
			<div>
				<FormGroup as={Row}>
					<FormLabel id="romcxi" className="col-sm-4 text-sm-right" column>
						ROM CXI (CRC32)
					</FormLabel>
					<Col sm={4}>
						<input
							onChange={this.onInputChange.bind(this, 'rom_cxi')}
							className="form-control"
							value={this.state.submission.rom_cxi}
						/>
					</Col>
				</FormGroup>
				<FormGroup as={Row}>
					<FormLabel id="emanualcfa" className="col-sm-4 text-sm-right" column>
						E-Manual CFA (CRC32)
					</FormLabel>
					<Col sm={4}>
						<input
							onChange={this.onInputChange.bind(this, 'e_manual_cfa')}
							className="form-control"
							value={this.state.submission.e_manual_cfa}
						/>
					</Col>
				</FormGroup>
				<FormGroup as={Row}>
					<FormLabel id="masterdatainfo" className="col-sm-4 text-sm-right" column>
						Master Data Info (CRC32)
					</FormLabel>
					<Col sm={4}>
						<input
							onChange={this.onInputChange.bind(this, 'master_data_info')}
							className="form-control"
							value={this.state.submission.master_data_info}
						/>
					</Col>
				</FormGroup>
			</div>
		);
	}

	render() {
		const {
			display,
			selectableProductTypes,
			isUploadInitiated,
			submissionId,
			ratingsDescriptorsChoices,
			submission,
			isWUP,
		} = this.state;
		const { userProfile, closeModal } = this.props;
		const submissions = componentUtils.generateOptions(submissionTypes);
		const products = componentUtils.generateOptions(selectableProductTypes);

		//LDBR-1698: Add 'No descriptor' to list of game rating options
		const gameRatingOptions = gameRatings;

		if (!gameRatingOptions.includes(defaultNoRating)) {
			gameRatingOptions.unshift(defaultNoRating);
		}

		const ratings = componentUtils.generateOptions(gameRatingOptions);

		const viewSubmission = this.viewSubmission;
		const productTypeDisabled = this.checkProductReleaseExisting();

		const descriptorOptions = ratingsDescriptorsChoices.map((o) => ({
			value: o.value,
			label: o.text,
		}));
		const descriptorValues = descriptorOptions.filter(
			(o) =>
				Array.isArray(submission.ratingsDescriptors) &&
				submission.ratingsDescriptors?.includes(o.value),
		);

		return (
			<BaseModal
				size="lg"
				show={display}
				onCancel={closeModal}
				isSubmitting={this.state.isSubmitting}
				isLoading={this.state.isLoading}
			>
				<BaseModal.Title>Receive Submission</BaseModal.Title>
				<Form className="gameCodeForm">
					<Alert variant="info">
						Please note that once this has been submitted, ‘Submission Received’
						notifications will be sent to the appropriate parties
					</Alert>
					<FormGroup as={Row}>
						<FormLabel id="submissionVersion" className="col-sm-4 text-sm-right" column>
							Submission Version
						</FormLabel>
						<Col sm={4}>
							<FormControl
								type="text"
								value={this.state.submission.version}
								onChange={this.onInputChange.bind(this, 'version')}
							/>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<FormLabel id="submissionType" className="col-sm-4 text-sm-right" column>
							Submission Type
						</FormLabel>
						<Col sm={6}>
							<FormControl
								as="select"
								placeholder=""
								onChange={this.onInputChange.bind(this, 'type')}
							>
								{submissions}
							</FormControl>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<FormLabel id="productType" className="col-sm-4 text-sm-right" column>
							Product Type
						</FormLabel>
						{this.state.isLoading ? (
							<Col sm={6}>
								<FormControl as="text" disabled={true}>
									Loading...
								</FormControl>
							</Col>
						) : (
							<Col sm={6}>
								<FormControl
									as="select"
									placeholder=""
									onChange={this.onInputChange.bind(this, 'productType')}
								>
									<option value="">Select a product type</option>
									{products}
								</FormControl>
								{productTypeDisabled ? (
									<div className="help-block">
										Duplicate product type and version
									</div>
								) : (
									<div className="help-block">
										Nintendo Switch titles should be created in the Nintendo
										Developer Portal
									</div>
								)}
							</Col>
						)}
					</FormGroup>
					<FormGroup as={Row}>
						<FormLabel id="onlineMultiPlay" className="col-sm-4 text-sm-right">
							Online Multi Play
						</FormLabel>
						<Col sm={6}>
							<FormCheck
								id="checkbox-online-multi-play"
								type="checkbox"
								onChange={this.onInputChange.bind(this, 'onlineMultiPlay')}
								label="Yes"
							/>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<FormLabel id="onlineCommerce" className="col-sm-4 text-sm-right">
							Online Commerce
						</FormLabel>
						<Col sm={6}>
							<FormCheck
								id="checkbox-online-commerce"
								type="checkbox"
								onChange={this.onInputChange.bind(this, 'onlineCommerce')}
								label="Yes"
							/>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<FormLabel id="wifi" className="col-sm-4 text-sm-right">
							WiFi
						</FormLabel>
						<Col sm={6}>
							<FormCheck
								id="checkbox-wifi"
								type="checkbox"
								onChange={this.onInputChange.bind(this, 'wifi')}
								label="Yes"
							/>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<FormLabel id="ugc" className="col-sm-4 text-sm-right">
							UGC
						</FormLabel>
						<Col sm={6}>
							<FormCheck
								id="checkbox-ugc"
								type="checkbox"
								onChange={this.onInputChange.bind(this, 'ugc')}
								label="Yes"
							/>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<FormLabel id="aoc" className="col-sm-4 text-sm-right">
							AOC
						</FormLabel>
						<Col sm={6}>
							<FormCheck
								id="checkbox-aoc"
								type="checkbox"
								onChange={this.onInputChange.bind(this, 'aoc')}
								label="Yes"
							/>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<FormLabel id="fileName" className="col-sm-4 text-sm-right">
							File Name
						</FormLabel>
						<Col sm={4}>
							<input
								onChange={this.onInputChange.bind(this, 'fileName')}
								className="form-control"
								value={this.state.submission.fileName}
							/>
						</Col>
					</FormGroup>

					{isWUP ? this.renderWiiUForm() : this.renderGenericForm()}

					<FormGroup as={Row}>
						<FormLabel id="gameRating" className="col-sm-4 text-sm-right" column>
							Game Rating
						</FormLabel>
						<Col sm={6}>
							<FormControl
								as="select"
								placeholder=""
								onChange={this.updateGameRating}
							>
								{ratings}
							</FormControl>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<FormLabel id="ratingDescriptors" className="col-sm-4 text-sm-right" column>
							Rating Descriptors
						</FormLabel>
						<Col sm={7}>
							<DropdownSelect
								isSearchable
								isMulti
								options={descriptorOptions}
								value={descriptorValues}
								onChange={(newValue) =>
									this.updateSubmission('ratingsDescriptors', null, {
										value: newValue.map((v) => v.value),
									})
								}
							/>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<FormLabel id="attachedFiles" className="col-sm-4 text-sm-right">
							Attach file(s)
						</FormLabel>
						<Col sm={8}>
							<AsperaFileUpload
								updateFilesSelected={this.updateFilesSelected}
								userProfile={userProfile}
								entityType={fileTransferConst.ENTITY_TYPE.PRODUCT_SUBMISSION}
								entityId={submissionId}
								isUploadInitiated={isUploadInitiated}
								onCompleteFunction={viewSubmission}
							/>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<FormLabel id="receivingComments" className="col-sm-4 text-sm-right" column>
							Receiving Comments
						</FormLabel>
						<Col sm={7}>
							<FormControl
								as="textarea"
								rows="5"
								onChange={this.onInputChange.bind(this, 'receivingComments')}
								value={this.state.submission.receivingComments}
							/>
						</Col>
					</FormGroup>
				</Form>
				<BaseModal.Submit
					disabled={!(this.canReceiveSubmission() && this.canCreateSubmission())}
					onClick={() => {
						this.receiveSubmission();
					}}
				>
					Receive Submission
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}

export default withRouter(ReceiveSubmissionModal);
