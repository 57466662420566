import React from 'react';

import PropertyDisplay from '../../../components/PropertyDisplay/PropertyDisplay';
import { mapStatusCssClass } from '../CompanyProfile.helpers';


const RenderCompanyInfo = ({ companyData }) => {
	if (companyData) {
		//Don't display country code if US
		const countryCode =
			'US' === companyData.company_information.country_code
				? ''
				: companyData.company_information.country_code + ' ';
		return (
			<span>
				<PropertyDisplay label="Company Status">
					<strong className={mapStatusCssClass(companyData.company_information.status)}>
						{companyData.company_information.status}
					</strong>
				</PropertyDisplay>
				<PropertyDisplay label="Company Name">
					{companyData.company_information.company_name}
				</PropertyDisplay>
				<PropertyDisplay label="Doing Business As (DBA)">
					{companyData.company_information.dba}
				</PropertyDisplay>
				<PropertyDisplay label="Address">
					{companyData.company_information.address_1} <br />
					{companyData.company_information.address_2 ? <>{companyData.company_information.address_2}<br /></> : null}
					{companyData.company_information.city ? <>{companyData.company_information.city} </> : null}
					{companyData.company_information.region}{' '}
					{countryCode}{' '}
					{companyData.company_information.postal_code}
				</PropertyDisplay>
			</span>
		);
	} else {
		return null;
	}
};
export default RenderCompanyInfo;
