import React, { Component } from 'react';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';

import { actionsColumn } from '../../components/BaseTable/BaseTable';
import FilterableTable from '../../components/FilterableTable/FilterableTable';
import { permConst } from '../../constants/permConst';
import { isAuthorized } from '../../utils/userUtils';

function formatAddress(row) {
	const {
		address_1,
		address_2,
		city,
		state,
		postal_code,
		country_name
	} = row;
	return (
		<span className="address-info">
			<span>{address_1}</span>
			<span>{address_2 !== '' && address_2}</span>
			<span>{`${city}, ${state} ${postal_code}`}</span>
			<span>{country_name}</span>
		</span>
	);
}

class FacilitiesTableInternal extends Component {
	constructor(props) {
		super(props);
		const title = 'Packout Facilities';
		let dataFormat = this.getTableFormat();
		let filterProperties = new Map();
		filterProperties.set('Status', {
			filter: 'status',
			selectableFilters: new Set(['Active', 'Inactive'])
		});
		this.state = {
			facilites: [],
			dataFormat,
			searchableFields: ['name'],
			searchableFieldPlaceHolder: 'Search by Facility Name',
			title,
			filterProperties,
			showModal: false,
			isLoading: true
		};
	}

	getTableFormat() {
		let tableFormat = [
			{ Header: 'Name', accessor: 'name' },
			{ Header: 'Address', accessor: 'address' },
			{ Header: 'Phone Number', accessor: 'phone' },

			{
				Header: 'Publisher Owned',
				accessor: 'allowed_companies',
				Cell: (cell) => {
					if (!cell.value.length) {
						return '—';
					}
					return (
						<ul>
							{cell.value.map((company, key) => {
								return <li key={key}>{company}</li>;
							})}
						</ul>
					);
				}
			},
			{
				Header: 'Status',
				accessor: 'status',
				Cell: (cell) => {
					let fontColor =
						cell.value === 'ACTIVE'
							? 'text-success'
							: 'text-danger';
					return <strong className={fontColor}>{cell.value}</strong>;
				}
			}
		];
		if (
			isAuthorized(this.props.userProfile.permissions, [
				permConst.PACKOUT_FACILITY.EDIT.ALL
			])
		) {
			tableFormat.push({
				...actionsColumn,
				className: 'facility-activation-pointer',
				Cell: (props) => {
					const activateDisplay =
						props.original.status === 'ACTIVE' ? (
							<span className={'text-danger'}>Inactivate</span>
						) : (
							'Activate'
						);
					return (
						<MeatballDropdown
							id={`dropdown-${props.original.name}`.replace(/\s/g, '-')}
						>
							<MeatballDropdown.Item
								onClick={(e) =>
									this.setActivation(props.original)
								}
							>
								{activateDisplay}
							</MeatballDropdown.Item>
							<MeatballDropdown.Item
								onClick={() =>
									this.props.showEditFacilityModal(
										props.original
									)
								}
							>
								Edit Information
							</MeatballDropdown.Item>
						</MeatballDropdown>
					);
				}
			});
		}
		return tableFormat;
	}

	setActivation(facility) {
		const id = facility.packout_facility_id;
		const status = facility.status;

		let data = {
			status: 'ACTIVE'
		};
		if (status === 'ACTIVE') {
			data['status'] = 'INACTIVE';
		}
		this.props.saveEditFacility(id, data);
	}

	componentDidMount() {
		this.props.facilities.forEach((facility) => {
			facility['address'] = formatAddress(facility);
		});
		this.setState({
			facilities: this.props.facilities
		});
	}

	// https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-controlled-component
	static getDerivedStateFromProps(props) {
		props.facilities.forEach((facility) => {
			facility['address'] = formatAddress(facility);
		});
		return {
			facilities: props.facilities
		};
	}

	render() {
		return (
			<FilterableTable
				data={this.state.facilities}
				dataFormat={this.state.dataFormat}
				filterProperties={this.state.filterProperties}
				searchableFields={this.state.searchableFields}
				searchableFieldPlaceHolder={
					this.state.searchableFieldPlaceHolder
				}
				defaultSorted={[
					{
						id: 'name',
						desc: false
					}
				]}
			/>
		);
	}
}

export default FacilitiesTableInternal;
