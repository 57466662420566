import React, { VFC, useState, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import Page from '../../../components/Page/Page';
import Loading from '../../../components/Loading/Loading';
import Forms from '../../../components/Forms/Forms';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import LoadingText from '../../../components/Loading/LoadingText';
import FAIcon from '../../../components/FAIcon/FAIcon';
import { getOrderDelivery, putOrderDelivery, OrderDeliveryPayload } from '../../../services/digitalCodesService';
import { now } from '../../../utils/dateUtils';
import { getCommentByType } from '../../../utils/digitalCodesUtils';
import { isEmptyObject } from '../../../utils/dataUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { validateToSchema } from '../../../utils/validationUtils';

const MINIMUM_DAYS = 7;
const getEarliestDay = () => dayjs().add(7, 'days').startOf('day').toDate();

export const step2Schema = yup.object({
	reqDeliveryDate: yup
		.string()
		.nullable()
		.default(null)
		.test(
			'min_date_test',
			`Requested Delivery Date must be at earliest ${MINIMUM_DAYS} days from today or be blank`,
			(value) => dayjs(value).startOf('day').toDate() >= getEarliestDay()
		)
		.required('Date cannot be blank'),
	reqActivationDate: yup
		.string()
		.nullable()
		.default(null)
		.test(
			'after_delivery_date',
			'Requested Activation Date must be on or after Requested Delivery Date',
			(value, { parent }) => {
				const reqDeliveryDate = parent.reqDeliveryDate;
				return dayjs(reqDeliveryDate) <= dayjs(value);
			},)
		.required('Date cannot be blank'),
	comments: yup.string().trim().nullable().default(null),
});

interface Step2DeliveryProps {
	orderHeaderId: number;
	onPrevious: () => void;
	onNext: () => void;
	setSaveCall: (fn: () => void) => void;
}
const Step2Delivery: VFC<Step2DeliveryProps> = ({
	orderHeaderId,
	onPrevious,
	onNext,
	setSaveCall,
}) => {
	const [formValues, setFormValues] = useState<{
		reqDeliveryDate?: String,
		reqActivationDate?: String,
		comments?: String
	}>({});
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const deliveryQuery = useQuery(
		['getDelivery', orderHeaderId],
		() => getOrderDelivery(String(orderHeaderId)),
		{
			enabled: !!orderHeaderId,
			onSuccess: (response) => {
				const orderDeliveryData = response.data;
				const publisherComments = getCommentByType(orderDeliveryData?.comments, 'PUBLISHER') || '';

				setFormValues({
					reqDeliveryDate: orderDeliveryData?.reqDeliveryDate,
					reqActivationDate: orderDeliveryData?.reqActivationDate,
					comments: publisherComments,
				});
				setShowAllErrors(false);
			}
		},
	);

	const saveAndBacktrack = async () => {
		await submitForm();
		onPrevious();
	};

	useEffect(() => {
		setSaveCall(() => {
			submitForm();
		});
	});

	const earliestDate = now().add(MINIMUM_DAYS, 'days').startOf('day').toDate();
	const filterDate = (date: Date) => {
		if (date.getDay() > 0 && date.getDay() < 6) {
			return date.valueOf() >= earliestDate.valueOf();
		}
		return false;
	};

	const submitForm = async (advanceToNext: boolean = false) => {
		//If either date has a value, then show validation messages, if exists, for the dates, in save or submit.
		//Otherwise dates can be blank for save
		const validateTheDates = !!(formValues.reqDeliveryDate || formValues.reqActivationDate);

		// check validation
		if ((advanceToNext || validateTheDates) && !isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			toasterNotify(
				'Required fields must be filled with valid values to continue. See validation messages in the form for specific issues.',
				'error',
			);
			return;
		}

		// format payload
		const castedFormValues = step2Schema.cast(formValues, { stripUnknown: true });

		const payload: OrderDeliveryPayload = {
			...(castedFormValues),
			action: advanceToNext ? 'submit' : 'save',
		} as OrderDeliveryPayload;

		// transactions
		setIsSubmitting(true);
		if (!orderHeaderId) {
			return;
		}
		try {
			await putOrderDelivery(orderHeaderId, payload);
			toasterNotify('Order was saved successfully', 'success');
			if (advanceToNext) {
				onNext();
			}
		} catch (error: unknown) {
			if (error instanceof Error) {
				toasterNotify(createMessageForError(error, 'saving the order'), 'error', error);
			}
		}

		setIsSubmitting(false);
	};

	const isLoading = deliveryQuery.isLoading || deliveryQuery.isRefetching;
	const validationErrors = validateToSchema(step2Schema, formValues, {});
	return (
		<>
			<Page.FullPageCol>
				{isLoading ? (
					<Loading />
				) : (
					<>
						<Forms
							values={formValues}
							onChange={(newFormValues) => setFormValues(newFormValues)}
							showAllErrors={showAllErrors}
							validationErrors={validationErrors}
							disabled={isSubmitting}
						>
							<SectionTitle>Delivery Information</SectionTitle>
							<Row>
								<Col sm={8}>
									<Forms.DateSelect
										id="reqDeliveryDate"
										filterDate={filterDate}
										minDate={earliestDate}>
										<Forms.Heading>Requested Delivery Date</Forms.Heading>
									</Forms.DateSelect>
									<Forms.DateSelect
										id="reqActivationDate"
										minDate={earliestDate}>
										<Forms.Heading>Requested Activation Date</Forms.Heading>
									</Forms.DateSelect>
									<Forms.TextArea id="comments" rows={4} maxLength={500} >
										<Forms.Heading>Comments</Forms.Heading>
										<Forms.Help>
											Optional field. 500 character limit.
										</Forms.Help>
									</Forms.TextArea>
								</Col>
							</Row>
						</Forms>
						<div className="btn-container">
							<div className="float-left">
								<Button
									variant="outline-secondary"
									disabled={isSubmitting}
									onClick={() => saveAndBacktrack()}
								>
									<FAIcon className="mr-1" name="chevron-left" />
									Previous (Info and Products)
								</Button>
								<Button
									as={Link}
									variant="link"
									to="/orders/digital-codes"
									disabled={isSubmitting}
								>
									Return to Orders
								</Button>
							</div>
							<div className="float-right d-flex">
								{isSubmitting && <LoadingText inline className="m-a" />}
								<Button
									className="ml-3"
									variant="outline-secondary"
									type="button"
									onClick={() => submitForm()}
									disabled={isSubmitting}
								>
									Save
								</Button>
								<Button
									className="ml-3"
									variant="primary"
									onClick={() => submitForm(true)}
									disabled={isSubmitting}
								>
									Next (Confirm Order) <FAIcon name="chevron-right" />
								</Button>
							</div>
						</div>
					</>
				)}
			</Page.FullPageCol>
		</>
	);
};

export default Step2Delivery;
