import { SET_FEATURE_FLAGS } from '../actions/action-types';

const initialState = {
	flags: []
};
interface FeatureFlagStore {
	flags: Record<string, any>[]
}

function featureFlagReducer(state: FeatureFlagStore=initialState, action: { type: string, payload: Record<string, any>[] }) {
	switch (action.type) {
		case SET_FEATURE_FLAGS:
			return { ...state, flags: action.payload };
		default:
			return state;
	}
}

export default featureFlagReducer;
