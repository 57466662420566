import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

import { isFailedFileTransfer } from '../../../../services/fileUploadsService';
import FAIcon from '../../../../components/FAIcon/FAIcon';
import { dateFormat, formatDate } from '../../../../utils/dateUtils';

class SubmissionFileRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showIcon: true
		};

		this.currentRow = React.createRef();
		this.forDelete = this.forDelete.bind(this);
	}

	forDelete() {
		this.currentRow.current.classList.add('text-danger');
		this.setState({
			showIcon: false
		});
		this.props.markToDelete(this.props.asset.file_id);
	}

	render() {
		const { asset } = this.props;
		let deletedButton;
		if (this.state.showIcon) {
			deletedButton = (
				<Button onClick={this.forDelete} variant="danger" size="sm">
					<FAIcon className="text-white" name="times" />
				</Button>
			);
		} else {
			deletedButton = 'Delete on Save';
		}

		return (
			<tr ref={this.currentRow}>
				<td>{asset.file_name}</td>
				<td>
					{this.props.asset.uploaded_date && formatDate(this.props.asset.uploaded_date, dateFormat.DATE)}
					{isFailedFileTransfer(this.props.asset.status) && ' FAILED'}
				</td>
				<td className="text-center">{deletedButton} </td>
			</tr>
		);
	}
}

export default SubmissionFileRow;
