import React, { VFC } from 'react';

import { dateFormat, formatDate, parseDateString } from '../../utils/dateUtils';
import PropertyDisplay from '../PropertyDisplay/PropertyDisplay';

interface ActorDisplayProps {
	label: string;
	name?: string | null;
	company?: string | null;
	date?: string | null;
	emailAddress?: string | null;
	showEmailAddressAsLink?: boolean;
	orderId?: string | null;
}
const ActorDisplay: VFC<ActorDisplayProps> = ({
	label,
	name,
	company,
	emailAddress,
	date,
	orderId,
	showEmailAddressAsLink = false,
}) => (
	<PropertyDisplay label={label}>
		{name}
		{emailAddress &&
			(showEmailAddressAsLink ? (
				<>
					{' '}
					(
					<a href={`mailto:${emailAddress}?subject=Regarding%20order%20${orderId}`}>
						{emailAddress}
					</a>
					)
				</>
			) : (
				<> ({emailAddress})</>
			))}
		{name && company && ', '}
		{company}
		{date && (
			<>
				<br />
				<small>{formatDate(parseDateString(date), dateFormat.DATETIME_PT)}</small>
			</>
		)}
	</PropertyDisplay>
);

export default ActorDisplay;
