import React, { useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import './MaintenancePage.css';
import FAIcon from '../../components/FAIcon/FAIcon';


const MaintenancePage = () => {
	const history = useHistory();

	useEffect(() => {
		axios.get(`${global.config.apiUrl}/auth/ping`, {
			withCredentials: true,
			validateStatus: (status) => status !== 503
		})
		.then((response) => {
			const search = new URLSearchParams(history.location.search);
			search.delete('maintenance');
			history.push(history.location.pathname + '?' + search.toString());
		})
		.catch((error) => {
			return; // swallow errors, 503 responses are status quo
		});
	}, []);

	return (
		<div className="maintenance-page">
			<div className="top-bkg"></div>
			<div className="maintenance-page-container">
				<section className="nin-section">
					<h5 className="nin-section-title">
						The Nintendo Publisher Tool is Down for Maintenance
					</h5>
					<div className="nin-section-content">
						<div className='maintenance-page-toad'><FAIcon className="text-danger" name="octagon" size='6x' /></div>
						<p className="maintenance-page-text">
							We are currently performing maintenance on the
							Nintendo Publisher Tool. If you need assistance
							right away, please contact{' '}
							<a href="mailto:support@noa.com">
								support@noa.com
							</a>
							.
						</p>
					</div>
				</section>
			</div>
		</div>
	);
};

export default MaintenancePage;
