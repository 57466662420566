import React from 'react';
import { Alert, Col, Form, FormCheck, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap';

import BaseModal from '../../../components/BaseModal/BaseModal';
import { setLotcheckPriority } from '../../../services/submissionsService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';

import '../LotcheckQueue.css';


export default class LotcheckPriorityModal extends React.Component {
	constructor(props) {
		super(props);
		this.alertMessageOK =
			"When a submission is set to 'HOLD', then it will be placed at the bottom of the submission priority list.";
		this.alertMessagePriorityInvalid =
			'Priority must be a positive, whole number.';
		this.regExpNumeric = new RegExp('^[0-9]*$');
		this.state = {
			display: true,
			holdChecked: false,
			lotcheckPriority: '',
			alertMessage: this.alertMessageOK,
			validLotcheckPriority: false,
			isLoading: false
		};

		this.holdChecked = this.holdChecked.bind(this);
		this.lotcheckPriorityChanged = this.lotcheckPriorityChanged.bind(this);
		this.isFormValid = this.isFormValid.bind(this);
		this.updateLotcheckPriority = this.updateLotcheckPriority.bind(this);
		this.generateAlertMessage = this.generateAlertMessage.bind(this);
		this.verifyNumeric = this.verifyNumeric.bind(this);
		this.toggleLoading = this.toggleLoading.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	holdChecked() {
		const { holdChecked } = this.state;
		this.setState({
			holdChecked: !holdChecked,
			lotcheckPriority: ''
		});
	}

	lotcheckPriorityChanged(event) {
		const lotcheckPriority = event.target.value;
		const validLotcheckPriority =
			this.verifyNumeric(lotcheckPriority) && lotcheckPriority !== '';
		this.setState({
			lotcheckPriority,
			validLotcheckPriority
		});
	}

	isFormValid() {
		const { holdChecked, validLotcheckPriority } = this.state;
		return holdChecked || validLotcheckPriority;
	}

	updateLotcheckPriority() {
		const { closeFunction, submissionId, pageRefresh } = this.props;
		const { lotcheckPriority, holdChecked } = this.state;
		const newPriority = holdChecked ? 999 : lotcheckPriority;

		this.toggleLoading();
		setLotcheckPriority(submissionId, newPriority)
			.then((response) => {
				pageRefresh();
				toasterNotify('Updated priority', 'success');
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'updating priority'),
					'error',
					error
				);
			})
			.finally(() => {
				closeFunction();
			});
	}

	generateAlertMessage() {
		const { holdChecked, lotcheckPriority } = this.state;
		return this.verifyNumeric(lotcheckPriority) || holdChecked
			? this.alertMessageOK
			: this.alertMessagePriorityInvalid;
	}

	verifyNumeric(priorityValue) {
		return this.regExpNumeric.test(priorityValue);
	}

	render() {
		const { display, lotcheckPriority, holdChecked, isLoading } = this.state;
		const {
			closeFunction,
			submissionId,
			title,
			gameCode,
			releaseVersion,
			submissionVersion
		} = this.props;
		return (
			<BaseModal show={display} onCancel={closeFunction} isSubmitting={isLoading}>
				<BaseModal.Title>Update Priority / Hold Submission</BaseModal.Title>

				<div className={'lotcheck-pri-form-div'}>
					<Alert variant="info">{this.generateAlertMessage()}</Alert>
					<Form onSubmit={(e) => {
						if (this.isFormValid()) {
							this.updateLotcheckPriority();
						}
						e.preventDefault();
						return false;
					}}>
						<FormGroup as={Row}>
							<FormLabel className="text-sm-right col-sm-4">
								<strong>Product Name</strong>
							</FormLabel>
							<Col sm={8}>{title}</Col>
						</FormGroup>

						<FormGroup as={Row}>
							<FormLabel className="text-sm-right col-sm-4">
								<strong>Version</strong>
							</FormLabel>
							<Col sm={8}>{`${releaseVersion}.${submissionVersion}`}</Col>
						</FormGroup>

						<FormGroup as={Row}>
							<FormLabel className="text-sm-right col-sm-4">
								<strong>Game Code</strong>
							</FormLabel>
							<Col sm={8}>{gameCode}</Col>
						</FormGroup>

						<FormGroup as={Row}>
							<FormLabel className="text-sm-right col-sm-4">
								<strong>Hold Submission</strong>
							</FormLabel>
							<Col sm={8}>
								<FormCheck
									type="checkbox"
									id="holdCheckBox"
									className="holdCheckBox"
									checked={holdChecked}
									onChange={this.holdChecked}
									label='YES'
								/>
							</Col>
						</FormGroup>

						<FormGroup as={Row}>
							<FormLabel sm={4} className="text-sm-right" column>
								<strong>Priority</strong>
							</FormLabel>
							<Col sm={4}>
								<FormControl
									type="text"
									restrict="0-9"
									value={lotcheckPriority}
									disabled={holdChecked}
									onChange={this.lotcheckPriorityChanged}
								/>
							</Col>
							<input type="hidden" name="submissionId" value={submissionId} />
						</FormGroup>
					</Form>
				</div>

				<BaseModal.Submit
					disabled={!this.isFormValid()}
					onClick={() => {
						this.updateLotcheckPriority();
					}}
				>
					Update</BaseModal.Submit>
			</BaseModal>
		);
	}
}
