import React, {
	FocusEvent,
	FormEvent,
	PropsWithChildren,
	ReactElement,
	ReactNode,
	SyntheticEvent,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
	VFC,
} from 'react';
import { Col, Form, FormCheck, FormControl, FormLabel, Row } from 'react-bootstrap';

import DatePicker from '../../components/DatePicker/DatePicker';
import HelpBlock from '../../components/HelpBlock/HelpBlock';
import LoadingText from '../../components/Loading/LoadingText';
import DropdownSelect from '../DropdownSelect/DropdownSelect';
import { filterChildren, findChild } from './Forms.helper';

import './Forms.css';

interface FormsProps {
	as?: any;
	id?: string;
	values: Record<string, any>;
	onChange: (newValues: Record<string, any>, id: string) => void;
	onFormSubmit?: (e: FormEvent<HTMLFormElement>) => void;
	validationErrors?: Record<string, string>;
	showAllErrors?: boolean;
	vertical?: boolean;
	noPadding?: boolean;
	className?: string;
	disabled?: boolean;
}

interface FormsContextProps {
	formId?: string;
	changeHandler: (id?: string, newValue?: any) => void;
	values?: Record<string, any>;
	showAllErrors?: boolean;
	validationErrors?: Record<string, string>;
	vertical?: boolean;
	noPadding?: boolean;
	disableAll?: boolean;
}

interface FormsTopLevelComponentMinimalProps {
	vertical?: boolean;
	disabled?: boolean;
	alwaysShowErrors?: boolean;
}

interface FormsTopLevelComponentCommonProps extends FormsTopLevelComponentMinimalProps {
	id: string;
	placeholder?: string;
	setNewValue?: (value: any) => void;
	disabled?: boolean;
	setTouched?: (value: boolean) => void;
	onBlur?: (event: SyntheticEvent) => void;
	disableAll?: boolean;
}

type FormSubcomponents = {
	Text: typeof Text;
	TextArea: typeof Text;
	Number: typeof Number;
	SingleCheckbox: typeof SingleCheckbox;
	CheckboxList: typeof CheckboxList;
	RadioList: typeof RadioList;
	BasicSelect: typeof BasicSelect;
	Select: typeof Select;
	MultiSelect: typeof Select;
	DateSelect: typeof DateSelect;
	SearchSelect: typeof Select;
	CustomArea: typeof CustomArea;
	Preface: typeof Preface;
	Postscript: typeof Postscript;
	Label: typeof Label;
	Option: typeof Option;
	Group: typeof Group;
	Heading: typeof Heading;
	Help: typeof Help;
	ErrorBlock: typeof ErrorBlock;
};
const FormContext = React.createContext<FormsContextProps>({ changeHandler: () => null });

const Forms: VFC<PropsWithChildren<FormsProps>> & FormSubcomponents = ({
	as: componentClass,
	id: formId,
	children,
	values,
	onChange,
	validationErrors = {},
	showAllErrors,
	vertical,
	noPadding,
	onFormSubmit,
	className,
	disabled,
}: PropsWithChildren<FormsProps>): ReactElement => {
	const changeHandler = (id?: string, newValue?: any) => {
		if (!id) {
			return;
		}
		const formValues = { ...values };
		formValues[id] = newValue;
		onChange(formValues, id);
	};

	return (
		<FormContext.Provider
			value={{
				changeHandler,
				values,
				validationErrors,
				showAllErrors,
				vertical,
				noPadding,
				formId,
				disableAll: disabled,
			}}
		>
			<Form
				className={className}
				id={formId}
				as={componentClass}
				onSubmit={(e) => {
					onFormSubmit && onFormSubmit(e);
					e.preventDefault();
					return false;
				}}
			>
				{children}
			</Form>
		</FormContext.Provider>
	);
};

// Non-top level subcomponents. Used within subcomponents.
// Not going to call these subsubcomponents.
const Heading: VFC<PropsWithChildren<{ addPadding?: boolean }>> = ({
	children,
	addPadding = false,
}: PropsWithChildren<{ addPadding?: boolean }>): ReactElement =>
	addPadding ? <div className="col-form-label">{children}</div> : <>{children}</>;

const Label: VFC<PropsWithChildren<unknown>> = ({ children }: PropsWithChildren<unknown>) => (
	<>{children}</>
);

const Help: VFC<PropsWithChildren<unknown>> = ({ children }: PropsWithChildren<unknown>) => (
	<HelpBlock>{children}</HelpBlock>
);

const ErrorBlock: VFC<PropsWithChildren<unknown>> = ({ children }: PropsWithChildren<unknown>) => (
	<HelpBlock variant="danger">{children}</HelpBlock>
);

const Option: VFC<PropsWithChildren<{ value: any; disabled?: boolean }>> = ({
	children,
}: PropsWithChildren<{ value: any; disabled?: boolean }>) => <>{children}</>;

const Group: VFC<PropsWithChildren<{ label: string }>> = ({
	children,
}: PropsWithChildren<{ label: string }>) => <>{children}</>;

const Preface = ({ children }: PropsWithChildren<unknown>) => <div className="Forms__preface">{children}</div>;

const Postscript = ({ children }: PropsWithChildren<unknown>) => <>{children}</>;

// This function implements the higher-order component concept for top level components,
// handling several functions common to all sections of a form

function isCommonTopLevelComponentProps(
	props: FormsTopLevelComponentCommonProps | FormsTopLevelComponentMinimalProps,
): props is FormsTopLevelComponentCommonProps {
	return (props as FormsTopLevelComponentCommonProps).id !== undefined;
}

const createTopLevelSubcomponent = <
	T extends
		| FormsTopLevelComponentMinimalProps
		| FormsTopLevelComponentCommonProps = FormsTopLevelComponentCommonProps,
>(
		labelPadding: boolean,
		Component: VFC<any>,
	): VFC<T> => {
	const GroupHeading = ({
		children,
		vertical,
		htmlFor,
	}: {
		children: ReactNode;
		vertical?: boolean;
		htmlFor?: string;
	}): ReactElement =>
		vertical ? (
			<FormLabel htmlFor={htmlFor}>{children}</FormLabel>
		) : (
			<FormLabel
				htmlFor={htmlFor}
				column
				sm={4}
				className={[!vertical ? 'text-sm-right' : null, !labelPadding ? 'pt-0' : null].join(
					' ',
				)}
			>
				{children}
			</FormLabel>
		);
	const GroupBody = ({
		children,
		vertical,
	}: {
		children: ReactNode;
		vertical?: boolean;
	}): ReactElement =>
		vertical ? (
			<>{children}</>
		) : (
			<Col sm={8} className="ml-sm-0">
				{children}
			</Col>
		);

	const FormComponent: VFC<T> = (props: T): ReactElement => {
		const children: ReactNode | undefined = (props as Record<string, any>).children;
		const id: string | undefined =
			(isCommonTopLevelComponentProps(props) && props.id) || undefined;
		const [touched, setTouched] = useState((props.alwaysShowErrors) || false);

		const context = useContext(FormContext);
		const { formId, changeHandler, values, showAllErrors, validationErrors, noPadding } =
			context;

		const vertical = props.vertical || context.vertical;
		const disabled = props.disabled || context.disableAll;
		const fieldError = id && validationErrors && validationErrors[id];
		const setNewValue = id && ((value: any) => changeHandler(id, value));

		const headingNode = children && findChild(children, Heading);
		const prefaceNode = children && findChild(children, Preface);
		const errorNode = children && findChild(children, ErrorBlock);
		const helpNode = children && findChild(children, Help);
		const postscriptNode = children && findChild(children, Postscript);
		const otherPermittedNodes =
			children &&
			React.Children.toArray(children).find(
				(child: any) =>
					typeof child === 'object' &&
					'type' in child &&
					[HelpBlock].includes(child.type),
			);

		return (
			<Form.Group
				as={vertical ? 'div' : Row}
				className={noPadding ? 'mb-0' : ''}
				key={'form-item-' + id}
			>
				{headingNode && (
					<GroupHeading vertical={vertical} htmlFor={id && `input-${id}`}>
						{headingNode}
					</GroupHeading>
				)}
				<GroupBody vertical={vertical}>
					{prefaceNode}
					<Component
						{...{
							...props,
							formId,
							value: id && (values ? values[id] : null),
							setTouched: id && setTouched,
							setNewValue,
							disabled,
						}}
					/>
					{otherPermittedNodes}
					{touched || showAllErrors
						? (fieldError && <ErrorBlock>{fieldError}</ErrorBlock>) ||
						  errorNode ||
						  helpNode
						: helpNode}
					{postscriptNode}
				</GroupBody>
			</Form.Group>
		);
	};

	return FormComponent;
};

// Top-level subcomponents. Each correspond to a header/content row and a data field within a form.

interface TextProps extends PropsWithChildren<FormsTopLevelComponentCommonProps> {
	area?: boolean;
	rows?: number;
	minLength?: number;
	maxLength?: number;
	value?: string;
}
const Text: VFC<TextProps> = createTopLevelSubcomponent<TextProps>(
	true,
	({
		id,
		placeholder,
		area,
		value,
		setNewValue,
		setTouched,
		disabled,
		rows,
		minLength,
		maxLength,
		onBlur,
	}: TextProps): ReactElement => {
		const InputWrapper = useCallback((props: any) => {
			const { onChange, value, ...rest } = props;
			const ref = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
			const [cursorPosition, setCursorPosition] = useState<number | null>(null);

			const handleChange = (event: {
				target: HTMLInputElement | HTMLTextAreaElement;
				selectionStart: number;
			}) => {
				setCursorPosition(event.target?.selectionStart || null);
				props.onChange(event);
			};
			useEffect(() => {
				ref.current?.setSelectionRange(cursorPosition, cursorPosition);
			}, [ref, cursorPosition, value]);
			return area ? (
				<textarea
					{...rest}
					ref={ref}
					rows={rows}
					onChange={handleChange}
					value={value}
				/>
			) : (
				<input
					{...rest}
					ref={ref}
					rows={rows}
					onChange={handleChange}
					value={value}
				/>
			);
		}, []);
		return (
			<Form.Control
				as={InputWrapper}
				className="Forms__text-input"
				value={value || ''}
				placeholder={placeholder}
				onChange={
					setNewValue &&
					((e: SyntheticEvent) => setNewValue('value' in e.target && e.target.value))
				}
				onBlur={(e: FocusEvent) => {
					setTouched && setTouched(true);
					onBlur && onBlur(e);
				}}
				disabled={disabled}
				minLength={minLength}
				maxLength={maxLength}
				id={`input-${id}`}
			/>
		);
	},
);

interface NumberProps extends PropsWithChildren<FormsTopLevelComponentCommonProps> {
	minLength?: number;
	maxLength?: number;
	min?: number;
	max?: number;
	step?: number;
	value?: number;
}
const Number: VFC<NumberProps> = createTopLevelSubcomponent<NumberProps>(
	true,
	({
		id,
		placeholder,
		value,
		setNewValue,
		setTouched,
		disabled,
		minLength,
		maxLength,
		min,
		max,
		step,
		onBlur,
	}: NumberProps): ReactElement => (
		<FormControl
			as="input"
			type="number"
			className="Forms__text-input"
			value={value || ''}
			placeholder={placeholder}
			onChange={
				((e) => {
					if (!setNewValue || !e.target) {
						return;
					}
					const newValue = parseInt(e.target?.value, 10);
					if (
						step &&
						typeof newValue === 'number' &&
						newValue % step === 0 &&
						newValue < (min || 0)
					) {
						setNewValue && setNewValue(min);
					}
					setNewValue(newValue || undefined);
				})
			}
			onBlur={(e: FocusEvent<HTMLInputElement>) => {
				setTouched && setTouched(true);
				onBlur && onBlur(e);
			}}
			disabled={disabled}
			minLength={minLength}
			maxLength={maxLength}
			id={`input-${id}`}
			min={min}
			max={max}
			step={step}
		/>
	),
);

interface SingleCheckboxProps extends PropsWithChildren<FormsTopLevelComponentCommonProps> {
	value?: boolean;
	formId?: string;
}
const SingleCheckbox = createTopLevelSubcomponent<SingleCheckboxProps>(
	false,
	({ formId = '', id, value, setNewValue, setTouched, disabled, children }: SingleCheckboxProps) => {
		return (
			<FormCheck
				id={[formId, id, 'checkbox'].filter(i => i).join('-')}
				type="checkbox"
				checked={value || false}
				onChange={setNewValue && ((e) => setNewValue(e.target.checked))}
				onBlur={() => setTouched && setTouched(true)}
				disabled={disabled}
				label={findChild(children, Label)}
			/>
		);
	},
);

interface DateSelectProps extends PropsWithChildren<FormsTopLevelComponentCommonProps> {
	value?: string;
	showTimeSelect?: boolean;
    maxDate?: Date | null;
    minDate?: Date | null;
	filterDate?(date: Date): boolean;
	timezone?: string;
	dateFormat?: string;
}
const DateSelect: VFC<DateSelectProps> = createTopLevelSubcomponent<DateSelectProps>(
	true,
	({
		id,
		disabled,
		value,
		setNewValue,
		showTimeSelect,
		setTouched,
		minDate,
		maxDate,
		filterDate,
		timezone,
		dateFormat,
	}: DateSelectProps) => (
		<DatePicker
			disabled={disabled}
			value={value}
			onChange={(v: string) => setNewValue && setNewValue(v)}
			onBlur={() => setTouched && setTouched(true)}
			showTimeSelect={showTimeSelect}
			minDate={minDate}
			maxDate={maxDate}
			filterDate={filterDate}
			id={`input-${id}`}
			timezone={timezone}
			dateFormat={dateFormat}
		/>
	),
);

interface BasicSelectProps extends PropsWithChildren<FormsTopLevelComponentCommonProps> {
	isLoading?: boolean;
	value?: any;
}
const BasicSelect: VFC<BasicSelectProps> = createTopLevelSubcomponent<BasicSelectProps>(
	true,
	({
		id,
		value,
		placeholder,
		setNewValue,
		setTouched,
		children,
		disabled,
		isLoading,
	}: BasicSelectProps) => {
		return (
			<>
				{isLoading && (
					<div className="Forms__control_loading">
						<LoadingText />
					</div>
				)}
				<FormControl
					className="Forms__select"
					as="select"
					disabled={disabled || isLoading}
					value={value || ''}
					onChange={(e) => setNewValue && setNewValue(e.target.value)}
					onBlur={() => setTouched && setTouched(true)}
				>
					{!isLoading && placeholder && !value && (
						<option style={{ fontStyle: 'italic' }} disabled={true} value="">
							{placeholder}
						</option>
					)}
					{!isLoading &&
						filterChildren(children, Option).map((option) => {
							if (option && typeof option === 'object' && 'props' in option) {
								return (
									<option
										key={id + option.props.value}
										value={option.props.value}
									>
										{option.props.children}
									</option>
								);
							}
							return null;
						})}
				</FormControl>
			</>
		);
	},
);

interface SelectProps extends PropsWithChildren<FormsTopLevelComponentCommonProps> {
	value?: any;
	isLoading?: boolean;
	searchable?: boolean;
	isMulti?: boolean;
}
const Select: VFC<SelectProps> = createTopLevelSubcomponent<SelectProps>(
	true,
	({
		id,
		value,
		placeholder,
		setNewValue,
		isLoading,
		setTouched,
		children,
		disabled,
		searchable,
		isMulti,
	}: SelectProps) => {
		const allOptions: Record<string, any>[] = [];
		const getOptions = (node: ReactNode) =>
			children && filterChildren(node, Option)?.map((option) => toOption(option));
		const toGroup = (node: ReactNode) =>
			node &&
			typeof node === 'object' &&
			'props' in node && {
				label: node.props.label,
				options: getOptions(node.props.children),
			};
		const toOption = (node: ReactNode) => {
			if (node && typeof node === 'object' && 'props' in node) {
				const option = {
					value: node?.props?.value,
					label: node?.props?.children,
					// eslint-disable-next-line
					isDisabled: node?.props?.disabled == true,
				};
				allOptions.push(option);

				return option;
			}
		};
		const options = React.Children.toArray(children)
			.map((node) => {
				if (node && typeof node === 'object' && 'type' in node) {
					return node.type === Group
						? toGroup(node)
						: node.type === Option
							? toOption(node)
							: null;
				}
			})
			.filter((item) => !!item);

		value =
			isMulti && Array.isArray(value)
				? allOptions.filter((o) => Array.isArray(value) && value.includes(o.value))
				: allOptions.find((o) => o.value === value) || null;

		return (
			<DropdownSelect
				value={value}
				isDisabled={disabled}
				isLoading={isLoading}
				isSearchable={searchable}
				options={options}
				onChange={(newValue: unknown) => {
					if (!setNewValue) { return; }
					newValue &&
					typeof newValue === 'object' &&
					'value' in newValue &&
					newValue.value
						? setNewValue(newValue.value)
						: Array.isArray(newValue) &&
						  setNewValue((newValue as Array<Record<string, any>>).map((p) => p.value));
				}}
				onBlur={() => setTouched && setTouched(true)}
				placeholder={placeholder}
				isMulti={isMulti}
				inputId={`input-${id}`}
				instanceId={`forms-input-${id}`}
			/>
		);
	},
);

// Since these top-level component are the same as other components with an altered prop, we won't need to run this through
// the higher-order function.
const TextArea: typeof Text = (props: TextProps): ReactElement => (
	<Text {...{ ...props, area: true }} />
);

const MultiSelect: typeof Select = (props: SelectProps): ReactElement => (
	<Select {...{ ...props, isMulti: true }} />
);

const SearchSelect: typeof Select = (props: SelectProps): ReactElement => (
	<Select {...{ ...props, searchable: true }} />
);

interface CheckboxListProps extends PropsWithChildren<FormsTopLevelComponentCommonProps> {
	value?: unknown[];
}
const CheckboxList: VFC<CheckboxListProps> = createTopLevelSubcomponent<CheckboxListProps>(
	false,
	({ formId, id, value, setNewValue, setTouched, children, disabled }) => {
		value = value || [];
		const checkChanged = (newValue: any, checked: boolean) => {
			let newSet = [...value];
			if (checked) {
				if (!newSet.includes(newValue)) {
					newSet.push(newValue);
				}
			} else {
				newSet = newSet.filter((i) => i !== newValue);
			}
			setNewValue(newSet);
		};
		const options = filterChildren(children, Option);
		return (
			<>
				{options.map((option: ReactNode) => {
					if (
						option &&
						typeof option === 'object' &&
						'props' in option
					) {
						const htmlId = [formId, id, option?.props.value.replace(/\s/g, '-')]
							.filter((i) => i)
							.join('-');
						const optionDisabled = option?.props.disabled;
						return (
							<FormCheck
								type="checkbox"
								id={htmlId}
								key={htmlId}
								onChange={(e) => checkChanged(option.props.value, e.target.checked)}
								onBlur={() => setTouched(true)}
								checked={value.includes(option.props.value)}
								disabled={disabled || optionDisabled}
								label={option.props.children}
							/>
						);
					}
					return null;
				})}
			</>
		);
	},
);

interface RadioListProps extends PropsWithChildren<FormsTopLevelComponentCommonProps> {
	inline?: boolean;
	value?: unknown;
}
const RadioList: VFC<RadioListProps> = createTopLevelSubcomponent<RadioListProps>(
	false,
	({ id, value, setNewValue, setTouched, children, disabled, inline }) => {
		value = value || '';
		const radioChanged = (newValue: unknown) => {
			setNewValue(newValue);
		};
		const options = filterChildren(children, Option);
		return (
			<>
				{options.map((option: ReactNode) => {
					if (option && typeof option === 'object' && 'props' in option) {
						const htmlId = [id, option.props.value.replace(/\s/g, '-')]
							.filter((i) => i)
							.join('-');
						return (
							<FormCheck
								type="radio"
								inline={inline}
								id={htmlId}
								key={htmlId}
								onChange={(e) => radioChanged(option.props.value)}
								onBlur={() => setTouched(true)}
								checked={value.includes(option.props.value)}
								disabled={disabled}
								label={option.props.children}
							/>
						);
					}
					return null;
				})}
			</>
		);
	},
);

interface CustomAreaProps extends FormsTopLevelComponentMinimalProps {
	id?: string;
	alwaysShowErrors?: boolean;
}
const CustomArea = createTopLevelSubcomponent<PropsWithChildren<CustomAreaProps>>(
	false, 
	({ children, alwaysShowErrors }: PropsWithChildren<CustomAreaProps>) => (
		<div>
			{React.Children.toArray(children).filter(
				(node: any) => ![Preface, Postscript, Heading, Help].includes(node?.type),
			)}
		</div>
	)
);


// top level subcomponents
Forms.Text = Text;
Forms.TextArea = TextArea;
Forms.Number = Number;
Forms.SingleCheckbox = SingleCheckbox;
Forms.CheckboxList = CheckboxList;
Forms.RadioList = RadioList;
Forms.BasicSelect = BasicSelect;
Forms.Select = Select;
Forms.MultiSelect = MultiSelect;
Forms.DateSelect = DateSelect;
Forms.SearchSelect = SearchSelect;
Forms.CustomArea = CustomArea;

// other subcomponents
Forms.Preface = Preface;
Forms.Postscript = Postscript;
Forms.Label = Label;
Forms.Option = Option;
Forms.Group = Group;
Forms.Heading = Heading;
Forms.Help = Help;
Forms.ErrorBlock = ErrorBlock;

export default Forms;
