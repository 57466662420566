import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';

import BaseModal from '../../../../components/BaseModal/BaseModal';
import { transferRightsConstants } from '../../../../constants/transferRightsConstants';
import { postProductRightsTransferRequests } from '../../../../services/productTransfersService';
import { toasterNotify, createMessageForError } from '../../../../utils/toaster';


export default class RevokeDistributionTransferModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			display: true
		};
		this.toggleLoading = this.toggleLoading.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	revokeRights() {
		const { transferId } = this.props;
		this.toggleLoading();

		const data = {
			transfer_status: transferRightsConstants.REQUEST_STATUS.REVOKED
		};
		postProductRightsTransferRequests(transferId, data)
			.then((response) => {
				toasterNotify('Product rights transfer revoked.', 'success');
			}).catch((error) => {
				toasterNotify(
					createMessageForError(error, 'revoking a product rights transfer'),
					'error',
					error
				);
			})
			.finally(() => {
				this.props.toggleModal(null, null, true);
			});
	}

	render() {
		const { isLoading } = this.state;
		return (
			<BaseModal show={true} onCancel={this.props.toggleModal} isSubmitting={isLoading}>
				<BaseModal.Title>Revoke Distribution Rights</BaseModal.Title>
				<Alert variant='danger' className="mb-0">
					Please confirm that you want to revoke the assigned
					distribution rights from this publisher. Doing so will
					return Retail/Digital distribution rights to you.
				</Alert>
				<BaseModal.Submit
					variant="danger"
					onClick={() => {
						this.revokeRights();
					}}
				>
					Confirm
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}
