import React, { VFC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { permConst } from '../../constants/permConst';
import { useUserProfile } from '../../hooks/reduxHooks';
import { isAuthorized } from '../../utils/userUtils';
import DigitalCodesOrderCreateNew from './DigitalCodesOrderCreate__NEW';
import DigitalCodesOrderCreateOld from './DigitalCodesOrderCreate__OLD';
import { getOrderSummary } from '../../services/digitalCodesService';
import Page from '../../components/Page/Page';

// This is a temporary switchboard component that is in effect while both original sales flow and sales force 2 are supported.
const DigitalCodesOrderCreate: VFC = () => {
	const userProfile = useUserProfile();
	const hasNewFlowPermission = isAuthorized(userProfile.permissions, [
		permConst.DIGITAL_ORDER.SALES_FLOW_2.COMPANY,
	]);
	const { orderHeaderId } = useParams<{ orderHeaderId?: string }>();
	const [flowVersion, setFlowVersion] = useState<number | null>(orderHeaderId ? null : hasNewFlowPermission ? 2 : 1);

	const summaryQuery = useQuery(
		['getOrderSummary', orderHeaderId],
		() => getOrderSummary(String(orderHeaderId)),
		{
			retry: false,
			enabled: !!orderHeaderId && !flowVersion,
			onSuccess: (response) => {
				setFlowVersion(response.data.sales_flow_version);
			}
		},
	);

	return (summaryQuery.isError || flowVersion === 2) ? (
		<DigitalCodesOrderCreateNew />
	) : !flowVersion ? (
		<Page isLoading={true} />
	) : (
		<DigitalCodesOrderCreateOld />
	);
};

export default DigitalCodesOrderCreate;
