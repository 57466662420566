import React, { useState } from 'react';
import { useQuery } from 'react-query';

import BaseTable, { actionsColumn } from '../../components/BaseTable/BaseTable';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import { permConst } from '../../constants/permConst';
import { useUserProfile } from '../../hooks/reduxHooks';
import { displayString } from '../../utils/stringUtils';
import { isAuthorized } from '../../utils/userUtils';
import { getAdminHelpTopics, deleteHelpTopic } from '../../services/helpTopicsService';
import { Button } from 'react-bootstrap';
import FAIcon from '../../components/FAIcon/FAIcon';
import DeleteConfirmationModal from '../../components/modals/DeleteConfirmationModal/DeleteConfirmationModal';
import { toasterNotify } from '../../utils/toaster';
import { getRoles } from '../../services/usersService';
import SaveHelpTopicModal from './modals/SaveHelpTopicModal';

const EXTERNAL = 'EXTERNAL';

interface OpenModal {
	type?: 'SAVE_MODAL' | 'DELETE_MODAL';
	helpTopic?: AdminHelpTopic;
}

const HelpTopics = () => {
	const [openModal, setOpenModal] = useState<OpenModal>();
	const userProfile = useUserProfile();
	const canEditHelpTopic = isAuthorized(userProfile.permissions, [permConst.HELP.EDIT.ALL]);

	const adminHelpTopicsQuery = useQuery('getAdminHelpTopics', () => getAdminHelpTopics());
	const externalRolesQuery = useQuery('getRoles', () => getRoles(EXTERNAL));
	const isLoading = adminHelpTopicsQuery.isLoading || externalRolesQuery.isLoading;
	const fault = adminHelpTopicsQuery.isError || externalRolesQuery.isError;
	const roleNames =
		(externalRolesQuery?.data?.data && [...externalRolesQuery.data.data].sort()) || [];
	let adminHelpTopics: AdminHelpTopic[] | [] = adminHelpTopicsQuery.data?.data || [];

	const StatusCell = ({ value }: { value?: boolean }) => {
		const className = value ? 'text-success' : !value ? 'text-danger' : '';
		const status = value ? 'ACTIVE' : !value ? 'INACTIVE' : '';
		return <b className={className}>{displayString(status).toUpperCase()}</b>;
	};

	adminHelpTopics.map((helpTopic: Record<string, any>) => {
		helpTopic.role_names = helpTopic?.roles?.sort();
	});

	const RolesCell = ({ value }: { value: string[] }) => {
		return (
			<ul>
				{JSON.stringify(value) === JSON.stringify(roleNames) ? (
					<li>All</li>
				) : (
					value.map((name: string) => <li key={name}>{name}</li>)
				)}
			</ul>
		);
	};

	const ActionCell = ({ original }: { original: Record<string, any> }) => {
		return (
			canEditHelpTopic && (
				<MeatballDropdown id="help-topic-meatball" toggleSize={'lg'}>
					<MeatballDropdown.Item
						key={`edit-${original.id}`}
						eventKey={original.id}
						onClick={() =>
							setOpenModal({
								type: 'SAVE_MODAL',
								helpTopic: {
									help_topic_id: original.help_topic_id,
									name: original.name,
									active: original.active,
									role_names: original.role_names,
								},
							})
						}
					>
						Edit Help Topic
					</MeatballDropdown.Item>
					<MeatballDropdown.Item
						key={`delete-${original.id}`}
						eventKey={original.id}
						onClick={() =>
							setOpenModal({
								type: 'DELETE_MODAL',
								helpTopic: {
									help_topic_id: original.help_topic_id,
									name: original.name,
									active: original.active,
									role_names: original.role_names,
								},
							})
						}
					>
						Delete
					</MeatballDropdown.Item>
				</MeatballDropdown>
			)
		);
	};

	const dataFormat = [
		{ Header: 'Name', accessor: 'name' },
		{ Header: 'Role(s)', accessor: 'role_names', Cell: RolesCell },
		{ Header: 'Status', accessor: 'active', Cell: StatusCell },
		{ Header: 'File Name', accessor: 'file_name' },
		{
			...actionsColumn,
			Cell: ActionCell,
		},
	];

	const onClose = () => {
		setOpenModal({});
	};

	return (
		<Page fault={fault} isLoading={isLoading}>
			<Title title="Help Topic Management" />
			<Page.FullPageCol>
				{canEditHelpTopic && (
					<div className="helpTopicButton">
						<Button
							variant="primary"
							id="addHelpTopic"
							onClick={(e) =>
								setOpenModal({
									type: 'SAVE_MODAL',
									helpTopic: undefined,
								})
							}
						>
							<FAIcon className="mr-1" name="plus" />
							Add Topic
						</Button>
					</div>
				)}
				<BaseTable data={adminHelpTopics} columns={dataFormat} retainPageState />
			</Page.FullPageCol>
			<SaveHelpTopicModal
				show={!!openModal && openModal?.type === 'SAVE_MODAL'}
				onCompletion={() => adminHelpTopicsQuery.refetch()}
				onClose={onClose}
				editPermission={canEditHelpTopic}
				roleNames={roleNames}
				helpTopic={openModal?.helpTopic}
			/>
			<DeleteConfirmationModal
				show={!!openModal && openModal.type === 'DELETE_MODAL'}
				closeModal={() => onClose()}
				title={
					(openModal?.helpTopic?.name && `Delete ${openModal?.helpTopic?.name}`) ||
					'Delete Help Topic'
				}
				message={'Are you sure you want to DELETE this help topic?'}
				confirmDelete={async () => {
					if (openModal && openModal?.helpTopic?.help_topic_id) {
						try {
							await deleteHelpTopic(openModal.helpTopic.help_topic_id);
							await adminHelpTopicsQuery.refetch();
							toasterNotify(
								`Help Topic ${openModal.helpTopic.name} has been deleted.`,
								'success',
							);
						} catch (error: any) {
							toasterNotify(
								`Help Topic ${openModal.helpTopic.name} could not be deleted.`,
								'error',
								error,
							);
						}
					}
					onClose();
				}}
				submissionMessage={undefined}
				lastUpdated={undefined}
			/>
		</Page>
	);
};

export default HelpTopics;
