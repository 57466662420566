import React, { Component } from 'react';
import { Alert, Col, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap';

import AsperaFileUpload from '../../../components/AsperaFileUpload/AsperaFileUpload';
import BaseModal from '../../../components/BaseModal/BaseModal';
import { assetConst } from '../../../constants/assetConstants';
import { allowedFileExtensions, fileTransferConst } from '../../../constants/fileTransferConstants';
import { postAssetStatus } from '../../../services/productsService';
import { doAllFilesHaveExtension } from '../../../utils/assetUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';

import '../AssetDetails.css';


function getTitle() {
	return 'Final Label File(s)';
}

function determineStatus() {
	return assetConst.STATUS.FINAL_LABEL_UPLOADING;
}

function alertMessage() {
	return 'Please upload all final label files for production';
}

export default class UploadFinalLabelFilesModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			display: true,
			comment: '',
			isFilesSelected: false,
			revisionId: null,
			isUploadInitiated: false
		};
		this.updateFilesSelected = this.updateFilesSelected.bind(this);
		this.onComplete = this.onComplete.bind(this);
		this.validateFileType = this.validateFileType.bind(this);
	}

	updateAssetStatus() {
		const { assetId } = this.props;
		const { comment, selectedFiles } = this.state;
		const payload = {
			review_status: determineStatus(),
			file_upload_type: assetConst.UPLOAD.FINAL_LABEL,
			comment: comment,
			files: selectedFiles,
		};

		this.setState({
			isProcessing: true
		});

		postAssetStatus(assetId, payload)
			.then((response) => {
				toasterNotify('Final label file(s) submitted', 'success');
				this.setState({
					revisionId: response.data.revision_id.toString(),
					isUploadInitiated: true,
					prefetchedTransferSpecs: response.data.transfer_specs && {
						transfer_specs: response.data.transfer_specs,
					},
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'uploading final label files'),
					'error',
					error
				);
			})
			.finally(() => {
			});
	}

	onComplete() {
		const { productId, assetId, closeModal, loadAssetDetails } = this.props;

		loadAssetDetails(productId, assetId);
		closeModal();
	}

	updateFilesSelected(isFilesSelected) {
		this.setState({
			isFilesSelected: isFilesSelected
		});
	}

	validateFileType(selectedFiles) {
		const allowedFileTypes = allowedFileExtensions.FINAL_LABEL;
		const isValid = doAllFilesHaveExtension(selectedFiles, allowedFileTypes);
		this.setState({
			validFileSelected: isValid,
			selectedFiles: isValid && selectedFiles,
		});
	}

	render() {
		const { closeModal, userProfile } = this.props;
		const {
			display,
			isProcessing,
			comment,
			revisionId,
			isUploadInitiated,
			isFilesSelected,
			validFileSelected,
			prefetchedTransferSpecs,
		} = this.state;

		return (
			<BaseModal show={display} onCancel={closeModal}>
				<BaseModal.Title>{getTitle()}</BaseModal.Title>
				<div className="approve-asset-modal">
					<Alert variant="info">{alertMessage()}</Alert>
					<FormGroup as={Row}>
						<FormLabel
							id="title"
							className="col-sm-4 text-sm-right"
						>
							<div className="approve-asset-label">Attach File(s)</div>
						</FormLabel>

						<Col sm={6}>
							<AsperaFileUpload
								updateFilesSelected={this.updateFilesSelected}
								userProfile={userProfile}
								entityType={fileTransferConst.ENTITY_TYPE.PRODUCT_ASSET_REVISION}
								entityId={revisionId}
								isUploadInitiated={isUploadInitiated}
								onCompleteFunction={this.onComplete}
								allowedFileTypes={[{
									filter_name: 'Accepted Files',
									extensions: allowedFileExtensions.FINAL_LABEL
								}]}
								validateFileType={this.validateFileType}
								prefetchedTransferSpecs={prefetchedTransferSpecs}
							/>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<FormLabel
							id="title"
							className="col-sm-4 text-sm-right"
						>
							<div className="approve-asset-label">Comments:</div>
						</FormLabel>
						<Col sm={8}>
							<FormControl
								as="textarea"
								rows="6"
								value={comment}
								onChange={(e) => {
									this.setState({
										comment: e.target.value
									});
								}}
								maxLength={2000}
							/>
						</Col>
					</FormGroup>
				</div>
				<BaseModal.Submit
					disabled={isProcessing || !isFilesSelected || !validFileSelected}
					onClick={() => {
						this.updateAssetStatus();
					}}
				>
					Upload File(s)
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}
