import React, { Fragment, VFC } from 'react';
import { Button, ButtonGroup, ButtonToolbar, Table } from 'react-bootstrap';

import FAIcon from '../../../components/FAIcon/FAIcon';
import List from '../../../components/List/List';
import PropertyDisplay from '../../../components/PropertyDisplay/PropertyDisplay';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { permConst } from '../../../constants/permConst';
import { platformCodes } from '../../../constants/platformConstants';
import { dateFormat, formatDate, parseDateString } from '../../../utils/dateUtils';
import { isFeatureSunsetted } from '../../../utils/sunsetFeaturesUtils';
import { isAuthorized } from '../../../utils/userUtils';
import CustomFieldEntry from '../../../components/CustomField/CustomFieldEntry';
import { transferRightsConstants } from '../../../constants/transferRightsConstants';


export const getFirstUniqueAgeRating = (ageRatings: ProductData['age_ratings']) => {
	if (ageRatings) {
		ageRatings = [...ageRatings].sort((a, b) => {
			if (a.age_rating_agency > b.age_rating_agency) {
				return -1;
			}
			if (a.age_rating_agency < b.age_rating_agency) {
				return 1;
			}
			const methodA = a.age_rating_method ? a.age_rating_method : 'AAA';
			const methodB = b.age_rating_method ? b.age_rating_method : 'AAA';
			if (methodA > methodB) {	
				return -1;
			}
			if (methodA < methodB) {
				return 1;
			}
			return 0;
		});


		const firstUniqueAgency: (typeof ageRatings) = [];

		ageRatings.forEach(ageRating =>
			!firstUniqueAgency.some(a => a.age_rating_agency === ageRating.age_rating_agency) &&
			firstUniqueAgency.push(ageRating)
		);
		return firstUniqueAgency;
	}
};

interface DistributionTableProps {
	userProfile: UserProfile;
	productRetailPrice: string | null;
	product: ProductData;
}
const DistributionTable: VFC<DistributionTableProps> = ({ userProfile, productRetailPrice, product }) => {
	const canViewNOAPartNumbers = isAuthorized(userProfile.permissions, [permConst.COMPANY.VIEW.ALL]);
	function deriveRightType(rightType: string) {
		switch (rightType) {
			case transferRightsConstants.DISTRIBUTION_TYPE.RETAIL:
				return 'Retail';
			case transferRightsConstants.DISTRIBUTION_TYPE.DIGITAL:
				return 'Digital';
			case transferRightsConstants.DISTRIBUTION_TYPE.OWNERSHIP:
				return 'Owner';
			default:
				return '';
		}
	}
	return product.distribution?.length ? (
		<Table bordered className="table-distribution my-2">
			<thead>
				<tr>
					<th className="active">Right Type</th>
					{canViewNOAPartNumbers&& (
						<th className="active">NOA Part#</th>
					)}
					<th className="active">Publisher</th>
					{productRetailPrice != null && <th className="active">Price/Unit</th>}
				</tr>
			</thead>
			<tbody>
				{product?.distribution?.map((dist, i) => {
					const rightType = deriveRightType(dist.right_type);
					return (
						<tr key={i}>
							<td key={`td-${rightType}-rightType`}>{rightType}</td>
							{canViewNOAPartNumbers && (
								<td key={`td-${rightType}-part`}>
									{dist.right_type === 'RETAIL_PUB'
										? product.noa_part_number
										: null}
								</td>
							)}
							<td key={`td-${rightType}-companyName`}>{dist.company_name}</td>
							{productRetailPrice != null && (
								<td key={`td-${rightType}-price`}>
									{dist.right_type === 'RETAIL_PUB'
										? '$' + productRetailPrice
										: null}
								</td>
							)}
						</tr>
					);
				})}
			</tbody>
		</Table>
	) : null;
};

interface ProductSummaryTabProps {
	customFields: CustomFieldEntryData[];
	product: ProductData;
	productRetailPrice: string;
	readOnly: boolean;
	showPhysicalOrderChecklist: boolean;
	sunsetFeatures: SunsetTable;
	toggleModal: (type: string) => void;
	userProfile: UserProfile;
}
const ProductSummaryTab: VFC<ProductSummaryTabProps> = ({
	customFields,
	product,
	productRetailPrice,
	readOnly,
	showPhysicalOrderChecklist,
	sunsetFeatures,
	toggleModal,
	userProfile,
}) => {
	const canEditProductInfo = isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.ADD.COMPANY,
		permConst.PRODUCT.ADD.FIRST,
	]); 
	const canEditGameCode =
		product?.platform_name !== 'Nintendo Switch' &&
		isAuthorized(userProfile.permissions, [permConst.PRODUCT.EDIT.GAME_CODE]);

	let gameCode = '';
	if (product.game_code) {
		gameCode = product.game_code;
	} else if (
		isAuthorized(userProfile.permissions, [
			permConst.PRODUCT.VIEW.ALL.FIRST,
			permConst.PRODUCT.VIEW.ALL.THIRD,
		]) &&
		product.game_code_requested_date
	) {
		gameCode = `(Requested  ${formatDate(
			parseDateString(product.game_code_requested_date),
			dateFormat.DATE,
		)})`;
	}

	const ageRatings =
		product.platform_code === platformCodes['Nintendo Switch']
			? getFirstUniqueAgeRating(product.age_ratings)
			: product.age_ratings;

	const canViewCustomFields = isAuthorized(userProfile.permissions, [
		permConst.CUSTOM_FIELD.VIEW.ALL,
	]);
	const canViewComments = isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.VIEW.ALL.FIRST,
		permConst.PRODUCT.VIEW.ALL.THIRD,
	]);
	const sortedCustomFields = customFields
		? [...customFields].sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
		: [];

	return (
		<>
			{showPhysicalOrderChecklist && (
				<ButtonToolbar>
					<Button
						variant="outline-secondary"
						className="btn btn-default"
						type="button"
						id="physicalOrderChecklist-button"
						onClick={(e) => toggleModal('physicalOrderChecklist')}
					>
						Orderability Checklist
					</Button>
				</ButtonToolbar>
			)}
			{product.platform_name && product.platform_name !== 'Nintendo Switch' &&
				canEditProductInfo &&
				!isFeatureSunsetted(sunsetFeatures, 'lotcheck_features') && (
					<ButtonGroup>
						<Button
							variant="outline-secondary"
							type="button"
							id="edit-product-detail-button"
							disabled={readOnly}
							onClick={(e) => toggleModal('editProductDetailButton')}
						>
							<FAIcon name="edit" className="mr-1" />
							Edit Product Info
						</Button>

						{canEditGameCode && (
							<Button
								variant="outline-secondary"
								id="editGameCodeButton"
								onClick={(e) => toggleModal('editGameCodeButton')}
								type="button"
								disabled={readOnly}
							>
								<FAIcon name="edit" className="mr-1" />
								Edit Game Code
							</Button>
						)}
					</ButtonGroup>
			)}

			<SectionTitle>Product Information</SectionTitle>

			<PropertyDisplay label={'Game Code'}>{gameCode}</PropertyDisplay>
			{product.platform_name !== 'Nintendo Switch' && (
				<PropertyDisplay label={'Unique ID'}>{product.unique_id}</PropertyDisplay>
			)}
			{product.region_game_code && (
				<PropertyDisplay
					label={'Other Region Product Codes'}
					key={
						'Other Region Product Codes' +
						<List className="mb-0" input={product.region_game_code} />
					}
				>
					{<List className="mb-0" input={product.region_game_code} />}
				</PropertyDisplay>
			)}
			{product.creation_date && (
				<PropertyDisplay label={'Game Creation Date'}>
					{formatDate(parseDateString(product.creation_date), dateFormat.DATETIME_PT)}
				</PropertyDisplay>
			)}
			{product.card_type && (
				<PropertyDisplay label={'Card Type'}>{product.card_type}</PropertyDisplay>
			)}
			<PropertyDisplay label={'Part/SKU Number Required'}>
				{product.part_number_required_flag ? 'YES' : 'NO'}
			</PropertyDisplay>
			<PropertyDisplay label={'Part/SKU Number'}>
				{product.publisher_part_number}
			</PropertyDisplay>
			<PropertyDisplay label={'Distribution'}>
				<DistributionTable
					userProfile={userProfile}
					productRetailPrice={productRetailPrice}
					product={product}
				/>
			</PropertyDisplay>

			{canViewComments && product.comment && (
				<div>
					<SectionTitle>Additional Comments</SectionTitle>

					<div>
						<p>{product.comment}</p>
					</div>
				</div>
			)}

			{ageRatings?.map((rating, index) => {
				const displayMethod = rating.age_rating_method ? ` (${rating.age_rating_method})` : '';
				return (
					<Fragment key={'age-rating-' + index}>
						<PropertyDisplay
							label={`${rating.age_rating_agency} Age Rating${displayMethod}`}
							key={`${rating.age_rating_agency} Age Rating${displayMethod}` + rating.rating}
						>
							{rating.rating}
						</PropertyDisplay>
						<PropertyDisplay
							label={rating.age_rating_agency + ' Age Rating Descriptors'}
							key={rating.age_rating_agency + ' Age Rating Descriptors'}
						>
							<ul style={{'listStyle': 'inside'}} className="px-0 mb-0">
								{Array.isArray(rating.descriptors_json) ? rating.descriptors_json.map((value, index) => <li key={value+index}>{value}</li>) : String(rating.descriptors_json)}
							</ul>
						</PropertyDisplay>
					</Fragment>
				);
			})}

			{canViewCustomFields && sortedCustomFields.length && (
				<>
					<SectionTitle>
						Custom Fields <span className="text-danger">(Internal Only)</span>
					</SectionTitle>
					{sortedCustomFields?.map((customField, index) => (
						<CustomFieldEntry {...customField} key={index}></CustomFieldEntry>
					))}
				</>
			)}
		</>
	);
};

export default ProductSummaryTab;
