import React from 'react';
import { Alert } from 'react-bootstrap';

import BaseModal from '../../../components/BaseModal/BaseModal';
import { setCompanyNote } from '../../../services/companiesService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';

import '../CompanyProfile.css';

export default class EditNoteModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			note: '',
			display: true,
			isLoading: false
		};

		this.noteChanged = this.noteChanged.bind(this);
		this.setNote = this.setNote.bind(this);
		this.toggleLoading = this.toggleLoading.bind(this);
	}

	componentDidMount() {
		this.setState({
			note: this.props.companyProfile.company_information.comment
		});
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	noteChanged(event) {
		const note = event.target.value;
		this.setState({
			note: note
		});
	}

	setNote() {
		this.toggleLoading();
		const { companyProfile, updateNote } = this.props;
		const { note } = this.state;
		const companyId = companyProfile.company_information.ndid_company_id;

		setCompanyNote(companyId, note)
			.then(() => {
				updateNote(note);
				toasterNotify('Updated company note', 'success');
			}).catch((error) => {
				toasterNotify(
					createMessageForError(error, 'updating note'),
					'error',
					error
				);
			})
			.finally(() => {
				this.props.closeFunction();
			});
	}

	render() {
		const { display } = this.state;
		const { closeFunction } = this.props;
		return (
			<BaseModal show={display} onCancel={closeFunction} isSubmitting={this.state.isLoading}>
				<BaseModal.Title>Edit Note</BaseModal.Title>
				<div>
					<Alert variant="warning">
						There is only one publisher note so please
						append to the below to add more to the existing
						note.
					</Alert>

					<strong>Note</strong>

					<textarea
						className="form-control"
						rows="5"
						value={this.state.note}
						onChange={this.noteChanged}
						/>
				</div>
				<BaseModal.Submit onClick={() => this.setNote()}>Update</BaseModal.Submit>
			</BaseModal>
		);
	}
}
