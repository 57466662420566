import React, { Component } from 'react';
import { Alert, Button, FormControl, FormGroup, FormLabel, InputGroup } from 'react-bootstrap';

import BaseModal from '../../../../components/BaseModal/BaseModal';
import { transferRightsConstants } from '../../../../constants/transferRightsConstants';
import {
	getProductRightsTransferRequestByTransferToken,
	postProductRightsTransferRequests
} from '../../../../services/productTransfersService';
import { createMessageForError, toasterNotify } from '../../../../utils/toaster';



function getDistribution(value) {
	if (value.digital_pub_flag && value.retail_pub_flag) {
		const fields = [];
		fields.push(
			<FormGroup>
				<FormLabel id="title">
					Distribution Rights Assigned
				</FormLabel>
				<FormControl type="text" readOnly value='Digital' />
			</FormGroup>
		);
		fields.push(
			<FormGroup>
				<FormLabel id="title" />
				<FormControl type="text" readOnly value="Retail" />
			</FormGroup>
		);
		return fields;
	}
	if (value.digital_pub_flag) {
		return (
			<FormGroup>
				<FormLabel id="title">
					Distribution Rights Assigned
				</FormLabel>
				<FormControl type="text" readOnly value='Digital' />
			</FormGroup>
		);
	}
	return (
		<FormGroup>
			<FormLabel id="title">
				Distribution Rights Assigned
			</FormLabel>
			<FormControl type="text" readOnly value='Retail' />
		</FormGroup>
	);
}

export default class EnterDistributionTransferCodeModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			assignmentCode: '',
			display: true,
			isLoading: false,
			invalidAlertText: 'Please enter a valid code',
			isInvalid: false,
			isValid: false
		};
	}

	verifyAssignmentCode() {
		const { assignmentCode } = this.state;
		const transfer_type = 'DISTRIBUTION';
		this.setState({ isLoading: true });
		getProductRightsTransferRequestByTransferToken(assignmentCode, transfer_type)
			.then((response) => {
				this.setState({
					transferRequest: response.data,
					isLoading: false,
					isValid: true,
					isInvalid: false,
					invalidAlertText: null,
				});
			})
			.catch((error) => {
				this.setState({
					isLoading: false,
					isValid: false,
					isInvalid: true,
					invalidAlertText: error.response ? 'Invalid Code' : 'Please enter a valid code',
				});
			});
	}

	acceptAssignmentCode() {
		const { userProfile, closeModal, addRightsRequest } = this.props;
		const { transferRequest } = this.state;
		const payload = {
			transfer_status: transferRightsConstants.REQUEST_STATUS.PENDING
		};

		this.setState({ isLoading: true });
		postProductRightsTransferRequests(
			transferRequest.product_transfer_id,
			payload
		)
			.then((response) => {
				transferRequest.transfer_to_company =
						userProfile.companyName;
				transferRequest.transfer_status =
						transferRightsConstants.REQUEST_STATUS.PENDING;

				addRightsRequest(transferRequest);
				this.setState({
					transferRequest: response.data
				});

				toasterNotify('Product rights transfer accepted.', 'success');
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'accepting a product rights transfer', error),
					'error',
					error
				);
			})
			.finally(() => {
				closeModal();
			});
	}

	generateTransferRequestFieldsToDisplay() {
		const { platforms } = this.props;
		const { transferRequest } = this.state;


		const platformName = platforms && transferRequest &&
			platforms.find(p => transferRequest.product_platform_code === p.platform_code).platform_name;


		if (transferRequest) {
			let fields = [];
			fields.push(
				<FormGroup>
					<FormLabel id="title">
						Platform
					</FormLabel>
					<FormControl type="plaintext" readOnly value={platformName} />
				</FormGroup>
			);
			fields.push(
				<FormGroup>
					<FormLabel id="title">
						Product Name
					</FormLabel>
					<FormControl type="plaintext" readOnly value={transferRequest.product_game_name} />
				</FormGroup>
			);
			fields.push(getDistribution(transferRequest));
			// return 'ok!';
			return <>{fields}</>;
		}
		return null;
	}

	getAlertText() {
		const { isInvalid, invalidAlertText } = this.state;
		let alertText =
			'To receive distribution rights for a product enter the Assignment Code provided by the publisher that owns the product. Select verify and Accept the transfer. Nintendo of America will need to review and accept to complete the transfer.';
		if (isInvalid) {
			alertText = invalidAlertText;
		}
		return alertText;
	}

	render() {
		const { display, isValid, isInvalid, isLoading } = this.state;
		const { closeModal } = this.props;

		return (
			<BaseModal show={display} onCancel={closeModal} isSubmitting={isLoading}>
				<BaseModal.Title>Enter Assignment Code</BaseModal.Title>
				{isValid !== true && (
					<Alert variant={isInvalid ? 'danger' : 'info'}>{this.getAlertText()}</Alert>
				)}
				<FormGroup>
					<FormLabel>
						Transfer Code
					</FormLabel>
					<InputGroup>
						<FormControl
							type="text"
							placeholder=""
							onChange={(e) => {
								this.setState({
									assignmentCode: e.currentTarget.value,
									isValid: false,
								});
							}}
							isValid={isValid}
						/>
						{!isValid && <InputGroup.Append>
							<Button
								variant="outline-secondary"
								onClick={() => {
									this.verifyAssignmentCode();
								}}
							>
								Verify
							</Button>
						</InputGroup.Append>}
					</InputGroup>
					<div className="help-block">e.g. xxxx-xxxx-xxxx</div>
				</FormGroup>

				{this.state.isValid
					? this.generateTransferRequestFieldsToDisplay()
					: null}
				<BaseModal.Submit
					onClick={() => {
						this.acceptAssignmentCode();
					}}
					disabled={isLoading || !this.state.isValid}
				>
					Accept
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}
