/**
 * Tests to see if a given bit of data is an object with no props,
 * ie, an empty hash
 *
 * @param  {any} input Data to test
 */
export const isEmptyObject = (input: any) =>
	input instanceof Object && Object.keys(input).length === 0;

/**
 * Flattens an array of mixed values by, for every array or object in an array,
 * replace with the values within that object. Work recursively to flatten all
 * nesting within.
 *
 * @param  {Array} array of mixed items
 */
export const expandHashes = (array: Array<any>) => {
	if (array == null || typeof array !== 'object') {
		return array;
	}
	const subbed: any = Object.values(array).map((item) => {
		if (typeof item === 'object' && Object.keys(item).length) {
			return expandHashes(item);
		}
		return item;
	});
	// this replicates array.flat(), which is not available in our build env
	return [].concat(...subbed);
};

/**
 * This function executes an inputted function and will return the value of the function or
 * return undefined if a TypeError exception was thrown. The argument must be a function.
 *
 * @param  {Function} func to be executed
 */
export const safeEval = (func: Function): any => {
	if (typeof func !== 'function') {
		throw new Error('Argument is not a function');
	}
	try {
		return func();
	} catch (e) {
		if (e instanceof TypeError) {
			return undefined;
		} else {
			throw e;
		}
	}
};

/**
 * Returns new array without the duplicates in the inputted array
 *
 * @param  {} array
 */
export const removeDuplicates = (array: Array<any>): Array<any> => {
	return array.filter((item, index) => array.indexOf(item) === index);
};

/**
 * Removes consecutive null values in an array, also removes initial
 * and trailing nulls. Useful for making a list with separators
 *
 * @param  {Array} array
 */
export const removeConsecutiveNulls = (array: Array<any>): Array<any> => {
	const firstValid: number = array.findIndex(v => v != null);
	return array.filter((item: any, index: number, array: Array<any>) => {
		if (item != null) return true;
		if (index < firstValid) return false;
		if (index === array.length - 1) return false;
		if (array[index + 1] == null) return false;
		return true;
	});
};

/**
 * Returns an error object with additional properties defined in the props parameter
 *
 * @param  {string} message Human readable string that defines this error
 * @param  {object} props Object hash with properties to be added to the error object
 * @param  {object} options Object hash to be passed into the Error constructor method
 */
export const newErrorWithProps = (
	message: string,
	props: Record<string, any>,
	options: any,
): any => {
	const newError: Record<string, any> = new Error(message, options);
	props &&
		Object.keys(props).forEach((key: string): void => {
			newError[key] = props[key];
		});
	return newError;
};

/**
 * Tests if the keys and values of one object can be found in another object. Returns true if
 * the contents of the subset object are entirely within the control object.
 *
 * @param  {object} original The control object
 * @param  {object} subset The test object that may be a subset of the control object
 */
export const objectIsSubset = (
	original: Record<string, any>,
	subset: Record<string, any>,
): any => {
	return !Object.keys(subset).find((key) => !original[key] || subset[key] !== original[key]);
};

/**
 * Takes a value that possibly could be an InvalidData object and returns that object if it is
 * indeed so, or void if not.
 *
 * @param  {unknown} value?
 */
export const isInvalid = <T=unknown>(
	value?: unknown,
) => {
	if (
		value != null &&
		typeof value === 'object' &&
		!Array.isArray(value) &&
		'invalid' in value &&
		value.invalid === true &&
		'value' in value
	) {
		return value as InvalidData<T>; // any random object with invalid and value props will be cast as InvalidData and that's good enough for us
	}
	return;
};
/**
 * For a value that may be of a basic JSON data type or is an InvalidData object, will return that
 * normal value or the value stored in the InvalidData object. Use the type parameter to declare
 * the expected type of the value.
 *
 * @param  {InvalidData<T>|T} value
 */
export const getPossiblyInvalidValue = <T = unknown>(value?: InvalidData<T> | T) => {
	if (
		value != null &&
		typeof value === 'object' &&
		'invalid' in value &&
		value.invalid === true
	) {
		return value?.value;
	}
	return value as T;
};

/**
 * Injests a simple object and returns a copy of that object with undefined values
 * replaced by nulls.
 *
 * @param  {Record<string, any>} object
 * @returns Record
 */

export const transformUndefinedToNull = (object: Record<string, any>) => {
	const returnObject = {...object};
	Object.keys(returnObject).forEach((key) => {if (returnObject[key] === undefined) {
		returnObject[key] = null;
	}});
	return returnObject;
};
