import React, { VFC, useState } from 'react';
import { useQuery } from 'react-query';
import { Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import Page from '../../../components/Page/Page';
import Loading from '../../../components/Loading/Loading';
import DigitalCodesOrder from '../../../components/DigitalCodesOrder/DigitalCodesOrder';
import { usePurposeQuery } from '../DigitalCodesOrderCreate.helper';
import ActionConfirmationModal from '../../../components/modals/ActionConfirmationModal/ActionConfirmationModal';
import FAIcon from '../../../components/FAIcon/FAIcon';
import {
	OrderQuoteData,
	getComponentOrderPrice,
	getOrderQuote,
	postRequestForQuote
} from '../../../services/digitalCodesService';
import { permConst } from '../../../constants/permConst';
import { useUserProfile } from '../../../hooks/reduxHooks';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { getPossiblyInvalidValue, isInvalid } from '../../../utils/dataUtils';
import {
	checkTotalPriceMismatch,
	checkUnitPriceFeeMismatch
} from '../../../utils/digitalCodesUtils';
import { isAuthorized } from '../../../utils/userUtils';


export const getPurposeNameFromId = (id: number, purposeOutput: any[]) =>
	purposeOutput?.find(
		(entry) => entry.digital_orders_purpose_id === id,
	)?.purpose;

interface Step3DigitalCodesOrderConfirmationProps {
	orderHeaderId: number;
	onPrevious: () => void;
}

const Step3DigitalCodesOrderConfirmation: VFC<Step3DigitalCodesOrderConfirmationProps> = ({ orderHeaderId, onPrevious }) => {
	const history = useHistory();
	const userProfile = useUserProfile();
	const [openModal, setOpenModal] = useState<{ type?: string | null }>({});
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [orderHasInvalidValues, setOrderHasInvalidValues] = useState<boolean>(false);

	const orderQuery = useQuery(
		['getOrderQuote', orderHeaderId],
		() => getOrderQuote(orderHeaderId),
		{
			onSuccess: (response) => {
				const orderData = response.data;
				if (
					isInvalid(orderData.purposeID) ||
					isInvalid(orderData.reqDeliveryDate) ||
					isInvalid(orderData.reqActivationDate) ||
					isInvalid(orderData.productID) ||
					isInvalid(orderData.componentID)
				) {
					setOrderHasInvalidValues(true);
				}
			}
		}
	);
	const orderData = orderQuery.data?.data || {} as OrderQuoteData;
	const { comments, ...order } = { ...orderData, order_comments: orderData?.comments };
	const purposeID = getPossiblyInvalidValue(orderData?.purposeID);
	const purposeQuery = usePurposeQuery();
	const purposeName = (purposeQuery.isSuccess && purposeID
		&& getPurposeNameFromId(getPossiblyInvalidValue(purposeID), purposeQuery.data?.data)) || '-';

	const componentID = getPossiblyInvalidValue(orderData.componentID);

	const componentPriceQuery = useQuery(
		['getComponentOrderPrice', componentID, purposeID, orderData?.quantity],
		() => getComponentOrderPrice(componentID, purposeID!, orderData?.quantity),
		{
			enabled: !!(orderQuery.isSuccess && componentID && orderData?.quantity && purposeID),
			onError: (error) =>
				toasterNotify(
					'There was a problem retrieving the price for this order. Please note the game title and component and contact site support.',
					'error',
					error,
				),
		},
	);
	const ebsPrices = componentPriceQuery?.data?.data;
	const unitPrice = ebsPrices?.service_fee ? 'Fee' : ebsPrices?.unit_price;
	const totalPrice = ebsPrices?.total_price;

	const hasUnitPriceFeeMismatch = (ebsPrices && orderData &&
		checkUnitPriceFeeMismatch(ebsPrices, {
			unitPrice: orderData.unitPrice,
			serviceFee: orderData.serviceFee,
		})) || false;
	const hasTotalPriceMismatch = (ebsPrices && orderData && ebsPrices?.total_price &&
		checkTotalPriceMismatch(
			ebsPrices?.total_price, orderData.totalPrice
		)) || false;

	const priceInformation = {
		taxPercent: orderData.tax,
		taxAmount: orderData.tax_amount,
		grandTotal: orderData.grand_total,
		hasTotalPriceMismatch: hasTotalPriceMismatch,
		hasUnitPriceFeeMismatch: hasUnitPriceFeeMismatch,
	};

	const canSubmit = ['DRAFT', 'REJECTED'].includes(String(orderData?.status)) &&
		isAuthorized(userProfile.permissions, [permConst.DIGITAL_ORDER.SUBMIT.COMPANY]);
	const isLoading = orderQuery.isLoading || purposeQuery.isLoading;

	return (
		<>
			<Page.FullPageCol>
				{isLoading ? (
					<Loading />
				) : (
					<>
						<DigitalCodesOrder
							purposeName={purposeName}
							productsComponentsAreLoading={
								orderQuery.isLoading || componentPriceQuery.isLoading
							}
							productsComponents={{
								game_code: orderData.productGameCode,
								game_name: orderData.product_game_name,
								componentGameName: orderData.component_game_name,
								componentID: orderData.componentID,
							}}
							pricesAreLoading={componentPriceQuery.isLoading && orderQuery.isLoading}
							unitPrice={unitPrice}
							totalPrice={totalPrice}
							order={order}
							showErrors={true}
							priceInformation={priceInformation}
						/>
						<div className="btn-container">
							<div className="float-left">
								<Button
									variant="outline-secondary"
									disabled={isSubmitting}
									onClick={() => onPrevious()}
								>
									<FAIcon className="mr-1" name="chevron-left" />
									Previous (Delivery)
								</Button>
								<Button
									as={Link}
									variant="link"
									to="/orders/digital-codes/"
									disabled={isSubmitting}
								>
									Return To Orders
								</Button>
							</div>
							{canSubmit && (
								<div className="float-right d-flex">
									<Button
										className="ml-3"
										variant="primary"
										onClick={() => {
											if (orderHasInvalidValues) {
												toasterNotify(
													'Order cannot be submitted with invalid values. See order details for errors.',
													'error',
												);
											} else {
												setOpenModal({ type: 'CONFIRM_SUBMIT_MODAL' });
											}
										}}
										disabled={isSubmitting}
									>
										Submit Order
									</Button>
								</div>
							)}
						</div>
					</>
				)}
				<ActionConfirmationModal
					show={openModal && openModal.type === 'CONFIRM_SUBMIT_MODAL'}
					isSubmitting={isSubmitting}
					onCancel={() => setOpenModal({})}
					onConfirm={async () => {
						if (orderHeaderId) {
							try {
								setIsSubmitting(true);
								await postRequestForQuote(String(orderHeaderId), {});
								toasterNotify('Order confirmed', 'success');
							} catch (error: unknown) {
								error instanceof Error &&
									toasterNotify(
										createMessageForError(error, 'submitting order'),
										'error',
										error,
									);
							} finally {
								setOpenModal({});
								setIsSubmitting(false);
								history.push(`/orders/digital-codes/${orderHeaderId}`);
							}
						}
					}}
					title="Confirm order"
					confirmLabel="Submit Order"
					cancelLabel="Cancel"
				>
					<p>
						The price reflected in your Order Detail is an estimate of the total cost of
						your order.
					</p>
					<p>
						A pro-forma invoice will be available to download after your order is
						approved by Nintendo. Your pro-forma for this order will reflect any price
						difference.
					</p>
					<p>
						<span style={{ backgroundColor: '#FFFF33' }}>
							Please include the PO number in your wire transfer. Note that you are
							responsible for all wire transfer fees.
						</span>{' '}
						The final amount transferred to Nintendo of America must match the amount
						shown on the pro-forma invoice.
					</p>
					<p>
						Once your order is accepted by NOA, it shall be governed by, and subject to,
						the terms and conditions of your Content and Distribution License Agreement
						for the "Platform" selected when placing the order.
					</p>
					<p>
						Please contact{' '}
						<a href="mailto:thirdpartypublisher@noa.nintendo.com">
							thirdpartypublisher@noa.nintendo.com
						</a>{' '}
						to change delivery or activation dates after the order is approved.
						Please confirm that you would like to proceed with this order by selecting
						Submit Order below.
					</p>
				</ActionConfirmationModal>
			</Page.FullPageCol>
		</>
	);
};

export default Step3DigitalCodesOrderConfirmation;
