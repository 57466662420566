import React from 'react';

import PropertyDisplay from '../../components/PropertyDisplay/PropertyDisplay';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import { permConst } from '../../constants/permConst';
import { isAuthorized } from '../../utils/userUtils';


export function mapStatusCssClass(status) {
	switch (status) {
		case 'ACTIVE':
			return 'text-success';
		case 'EXECUTED':
			return 'text-success';
		case 'IN_NEGOTIATION':
			return 'text-warning';
		default:
			return 'text-danger';
	}
}

export function filterInternalOnly(companyProfile, userProfile, info) {
	let element;
	if (isAuthorized(userProfile.permissions, [permConst.COMPANY.VIEW.ALL])) {
		switch (info) {
			case 'bms-global-code':
				element = (
					<PropertyDisplay label="BMS Global Code">
						{companyProfile.company_information.bms_code}
					</PropertyDisplay>
				);
				break;
			case 'maker-code':
				element = (
					<PropertyDisplay label="Maker Code">
						{companyProfile.company_information.maker_code}
					</PropertyDisplay>
				);
				break;
			case 'edi-internal-id':
				element = (
					<PropertyDisplay label="EDI Internal ID">
						{companyProfile.company_information.edi_internal_id}
					</PropertyDisplay>
				);
				break;
			case 'ebs-account-num':
				element = (
					<PropertyDisplay label="EBS Account Number">
						{companyProfile.company_information.ebs_account_number}
					</PropertyDisplay>
				);
				break;
			case 'internal-notes':
				if (companyProfile) {
					element = (
						<>
							<SectionTitle>
								Notes <span className="text-danger">(Internal Only)</span>
							</SectionTitle>
							<div className="company-profile-comment">
								{companyProfile.company_information.comment}
							</div>
						</>
					);
				}
				break;
			default:
				return null;
		}
	}
	return element;
}
