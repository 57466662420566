import React, { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchPlatforms } from '../../store/actions/referenceActions';
import Loading from '../../components/Loading/Loading';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import { loadingConstants } from '../../constants/loadingConstants';
import { getDigitalOrdersPlatformLegend } from '../../services/digitalCodesService';
import { toKebabCase } from '../../utils/stringUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import PlatformTab from './PlatformTab';


export class FamilySetup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			platforms: [],
			digitalOrdersPurpose: [],
			isLoading: true
		};
	}

	loadDigitalOrdersPurpose() {
		return getDigitalOrdersPlatformLegend()
			.then((response) => {
				this.setState({
					digitalOrdersPurpose: response.data
				});
			})
			.catch((error) => {
				if (error) {
					toasterNotify(
						createMessageForError(error, 'retrieving distribution rights requests'),
						'error',
						error
					);
				}
			})
			.finally(() => {
				this.setState({
					isLoading: false
				});
			});
	}

	renderTabs(platforms=[]) {
		const { digitalOrdersPurpose } = this.state;
		return platforms.map((platform) => {
			let name = platform.platform_name;
			let key = toKebabCase(name);
			return (
				<Tab title={name} key={key} eventKey={key}>
					<Page.ContentContainer><PlatformTab
						platform={platform}
						digitalCodes={digitalOrdersPurpose}
					/></Page.ContentContainer>
				</Tab>
			);
		});
	}

	componentDidMount() {
		const { fetchPlatforms } = this.props;
		fetchPlatforms();
		this.loadDigitalOrdersPurpose();
	}

	render() {
		const { digitalOrdersPurpose, isLoading } = this.state;
		const { platforms, platformsStatus } = this.props;
		const defaultTab =
			platforms && platforms.length > 0 ? toKebabCase(platforms[0].platform_name) : '';
		const allLoaded = !isLoading && platformsStatus === loadingConstants.COMPLETED;
		return (
			<Page>
				{!allLoaded ? (
					<>
						<Title
							title="Family Setup"
						/>
						<Loading />
					</>
				) : (
					<>
						<Title
							title="Family Setup"
						/>

						<Tabs
							className="platform-tabs"
							defaultActiveKey={defaultTab}
							transition={false}
							id="platform-tabs"
						>
							{this.renderTabs(
								platforms,
								digitalOrdersPurpose
							)}
						</Tabs>
					</>
				)}
			</Page>
		);
	}
}

export default connect(
	// mapStateToProps
	(state) => ({
		platforms: state.referenceReducer.platforms?.content,
		platformsStatus: state.referenceReducer.platforms?.meta.status,
	}),
	// mapDispatchToProps
	(dispatch) => ({
		fetchPlatforms: bindActionCreators(fetchPlatforms, dispatch)
	})
)(FamilySetup);
