import { useQuery } from 'react-query';

import { getCompaniesInBulk } from '../../../services/companiesService';
import { getEventDetails, getEventProducts, getEventTaskDetails } from '../../../services/eventsService';
import { getUsersInBulk } from '../../../services/usersService';


export const useTaskQuery = (taskId, options = {}) =>
	useQuery(['getEventTaskDetails', taskId], () => getEventTaskDetails(taskId), options);

export const useEventDetailQuery = (eventId, options = {}) =>
	useQuery(['getEventDetails', eventId], () => getEventDetails(eventId), options);

export const useEventProductsQuery = (eventId, options = {}) =>
	useQuery(['getEventProductsQuery', eventId], () => getEventProducts(eventId), options);

export const useUserNamesBulkQuery = (userIds = [], token, options = {}) =>
	useQuery(
		['getUsersInBulk:user_name', token, ...userIds],
		() => getUsersInBulk(userIds, ['user_name'], token),
		options,
	);

export const useCompanyNamesBulkQuery = (companyIds = [], token, options = {}) =>
	useQuery(
		['getCompaniesInBulk', token, ...companyIds],
		() => getCompaniesInBulk(companyIds, ['company_name'], token),
		options,
	);
