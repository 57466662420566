import React, { Component } from 'react';
import { Button, Col, Form, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import FAIcon from '../../components/FAIcon/FAIcon';
import HelpBlock from '../../components/HelpBlock/HelpBlock';
import Loading from '../../components/Loading/Loading';
import LoadingText from '../../components/Loading/LoadingText';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import Page from '../../components/Page/Page';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import Title from '../../components/Title/Title';
import { companyAgreementConstants } from '../../constants/companyAgreementConstants';
import { permConst } from '../../constants/permConst';
import { getCreateOrderPageResources } from '../../services/ordersService';
import { returnArrayItem } from '../../utils/arrayUtils';
import { validateActiveAgreementType } from '../../utils/companyUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { isAuthorized } from '../../utils/userUtils';
import ConsigneeFields from './ConsigneeFields';


function mapStateToProps(state) {
	return {
		userProfile: state.authReducer.userProfile
	};
}

function filterPlatformsByAgreement(companyAgreements, data) {
	let platforms = [];
	data.forEach((p) => {
		if (
			companyAgreements && validateActiveAgreementType(
				companyAgreements,
				companyAgreementConstants.TYPE.RETAIL,
				p.agreement_family
			)
		) {
			platforms.push(p);
		}
	});
	return platforms;
}

export class OrderAndShipping extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			platforms: [],
			customsEntryPorts: [],
			shipTos: [],
			freightForwarders: [],
			countryCodes: [],
			untouchedFields: new Set([
				'platform',
				'publisherPO',
				'destinationContact',
				'destinationPhone',

				'japaneseContactName',
				'japaneseEmail',
				'japanesePhoneNumber',
				'consigneeCompanyName',
				'consigneeAddress',
				'consigneeCity',
				'consigneeState',
				'consigneeZip',
				'serviceLevel',
				'carrierAccountNumber'
			])
		};
	}

	componentDidMount() {
		this.getCreateOrderPageResources();
	}

	getCreateOrderPageResources() {
		let {
			initializeStateValue,
			updateResources,
			customsEntryPort,
			shipTo,
			freightForwarder,
			countryCode,
			platform,
			populateFreightForwardersRequiringConsignee,
			companyAgreements,
		} = this.props;
		this.setState({ isLoading: true });

		getCreateOrderPageResources()
			.then((response) => {
				const customsEntryPorts = response.data.customs_entry_ports;
				const shipTos = response.data.ship_tos;
				const freightForwarders = response.data.freight_forwarders;
				const countryCodes = response.data.country_codes;
				const platforms = companyAgreements ? filterPlatformsByAgreement(
					companyAgreements,
					response.data.orderable_platforms
				) : [];
				const last_order_shipping_info = response.data.last_order_shipping_info;
				const publisherPOs = response.data.publisher_pos.map((x) => {
					return x.toUpperCase();
				});
				this.setState({
					customsEntryPorts,
					shipTos,
					freightForwarders,
					countryCodes,
					platforms,
					last_order_shipping_info,
					isLoading: false
				});

				initializeStateValue('previousPublisherPOs', publisherPOs);
				updateResources(response.data);

				if (!customsEntryPort) {
					if (this.getLastOrderValue('customsEntryPort')) {
						customsEntryPort = this.getLastOrderValue('customsEntryPort');
					} else {
						customsEntryPort = returnArrayItem(customsEntryPorts, 'value');
					}
				}
				if (!shipTo) {
					if (this.getLastOrderValue('shipTo')) {
						shipTo = this.getLastOrderValue('shipTo');
					}
				} else {
					if (shipTos && !shipTos.find( st => st['packout_facility_id'] === shipTo)) {
						shipTo = '';
						this.props.formSetDirty();
					}
				}

				if (!freightForwarder) {
					if (this.getLastOrderValue('freightForwarder')) {
						freightForwarder = this.getLastOrderValue('freightForwarder');
					} else {
						freightForwarder = returnArrayItem(freightForwarders, 'value');
					}
				}

				if (!countryCode) {
					if (this.getLastOrderValue('countryCode')) {
						countryCode = this.getLastOrderValue('countryCode');
					} else {
						countryCode = returnArrayItem(countryCodes, 'country_code');
					}
				}
				if (!platform && platforms.length) {
					platform = platforms[0].platform_code;
				}

				['destinationContact','destinationPhone','japaneseContactName','japaneseEmail','japanesePhoneNumber','consigneeCompanyName','consigneeAddress','consigneeCity','consigneeState','consigneeZip','serviceLevel','carrierAccountNumber'].forEach((key) => {
					if (!this.props[key]) {
						initializeStateValue(key, this.getLastOrderValue(key));
						this.props.formSetDirty();
					}
				});

				initializeStateValue('customsEntryPort', customsEntryPort);
				initializeStateValue('shipTo', shipTo);
				initializeStateValue('freightForwarder', freightForwarder);
				initializeStateValue('countryCode', countryCode);
				initializeStateValue('platform', platform);

				populateFreightForwardersRequiringConsignee(freightForwarders);
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving resources'),
					'error',
					error
				);
				this.setState({ isLoading: false });
			});
	}

	renderFreightForwardersOptions() {
		const { freightForwarders } = this.state;
		return freightForwarders.map((freightForwarders, idx) => {
			return (
				<option
					key={`${idx}-${freightForwarders.value}`}
					value={freightForwarders.value}
				>
					{freightForwarders.name}
				</option>
			);
		});
	}

	renderPlatforms() {
		const { platforms } = this.state;
		if (!platforms.length) {
			return <option value="default" disabled>No Valid Platforms</option>;
		}
		let selectablePlatforms = [
			<option value="default" key="default" disabled>Select a Platform</option>
		];
		platforms.map((platform, idx) => {
			selectablePlatforms.push(
				<option
					key={`${idx}-${platform.platform_code}`}
					value={platform.platform_code}
				>
					{platform.platform_name}
				</option>
			);
		});
		return selectablePlatforms;
	}

	renderCustomsEntryPorts() {
		const { customsEntryPorts } = this.state;
		return customsEntryPorts.map((customsEntryPort, idx) => {
			return (
				<option
					key={`${idx}-${customsEntryPort.value}`}
					value={customsEntryPort.value}
				>
					{customsEntryPort.name}
				</option>
			);
		});
	}

	renderShipTos() {
		const { shipTos } = this.state;
		if (!shipTos.length) {
			return <option key="null" value="default" disabled>No Valid Packout Facility</option>;
		}
		let selectableShipTos = [
			<option key="default" value="" disabled>Select a Packout Facility</option>
		];
		shipTos.map((shipTo, idx) => {
			selectableShipTos.push(
				<option
					key={`${idx}-${shipTo.packout_facility_id}`}
					value={shipTo.packout_facility_id}
				>
					{this.formatShipTo(shipTo)}
				</option>
			);
		});
		return selectableShipTos;
	}

	formatShipTo(shipTo) {
		let formattedShipTo = '';
		formattedShipTo += shipTo.name + ', ';
		formattedShipTo += shipTo.street_line_1 + ', ';
		formattedShipTo += shipTo.city + ', ';
		formattedShipTo += shipTo.region + ', ';
		formattedShipTo += shipTo.postal_code;
		return formattedShipTo;
	}

	getLastOrderValue(lastOrderField) {
		if (!this.state.last_order_shipping_info) {
			return '';
		}
		const { last_order_shipping_info } = this.state;
		switch (lastOrderField) {
			case 'freightForwarder':
				if (last_order_shipping_info.freight_forwarder_code) {
					// only return the item if it's in the list
					const { freightForwarders } = this.state;
					const result = freightForwarders.find(
						freightForwarder =>
							freightForwarder.value === last_order_shipping_info.freight_forwarder_code
					);
					if (result) {
						return last_order_shipping_info.freight_forwarder_code;
					}
				}
				break;
			case 'customsEntryPort':
				if (last_order_shipping_info.port_of_entry_code) {
					// only return the item if it's in the list
					const { customsEntryPorts } = this.state;
					const result = customsEntryPorts.find(
						customsEntryPort =>
							customsEntryPort.value === last_order_shipping_info.port_of_entry_code
					);
					if (result) {
						return last_order_shipping_info.port_of_entry_code;
					}
				}
				break;
			case 'shipTo':
				if (last_order_shipping_info.packout_facility_id) {
					// only return the item if it's in the list
					const { shipTos } = this.state;
					const result = shipTos.find(
						shipTo =>
							shipTo.packout_facility_id === last_order_shipping_info.packout_facility_id
					);
					if (result) {
						return last_order_shipping_info.packout_facility_id;
					}
				}
				break;
			case 'destinationContact':
				if (last_order_shipping_info.packout_facility_contact_name) {
					return last_order_shipping_info.packout_facility_contact_name;
				}
				break;
			case 'destinationPhone':
				if (last_order_shipping_info.packout_facility_contact_phone_number) {
					return last_order_shipping_info.packout_facility_contact_phone_number;
				}
				break;
			case 'japaneseContactName':
				if (last_order_shipping_info.consignee_contact_name) {
					return last_order_shipping_info.consignee_contact_name;
				}
				break;
			case 'japaneseEmail':
				if (last_order_shipping_info.consignee_contact_email) {
					return last_order_shipping_info.consignee_contact_email;
				}
				break;
			case 'japanesePhoneNumber':
				if (last_order_shipping_info.consignee_contact_phone_number) {
					return last_order_shipping_info.consignee_contact_phone_number;
				}
				break;
			case 'consigneeCompanyName':
				if (last_order_shipping_info.consignee_company_name) {
					return last_order_shipping_info.consignee_company_name;
				}
				break;
			case 'consigneeAddress':
				if (last_order_shipping_info.consignee_address_street_line_1) {
					return last_order_shipping_info.consignee_address_street_line_1;
				}
				break;
			case 'consigneeCity':
				if (last_order_shipping_info.consignee_address_city) {
					return last_order_shipping_info.consignee_address_city;
				}
				break;
			case 'consigneeState':
				if (last_order_shipping_info.consignee_address_region) {
					return last_order_shipping_info.consignee_address_region;
				}
				break;
			case 'consigneeZip':
				if (last_order_shipping_info.consignee_address_postal_code) {
					return last_order_shipping_info.consignee_address_postal_code;
				}
				break;
			case 'countryCode':
				if (last_order_shipping_info.consignee_address_country_code) {
					return last_order_shipping_info.consignee_address_country_code;
				}
				break;
			case 'serviceLevel':
				if (last_order_shipping_info.consignee_service_level) {
					return last_order_shipping_info.consignee_service_level;
				}
				break;
			case 'carrierAccountNumber':
				if (last_order_shipping_info.consignee_carrier_account_number) {
					return last_order_shipping_info.consignee_carrier_account_number;
				}
				break;

			default:
				return '';
		}

		return '';
	}

	renderTitleButton(formIsValid) {
		const {
			userProfile,
			orderHeaderId,
			saveClicked,
			deleteClicked,
			isEditable
		} = this.props;
		const canDeleteOrder =
			orderHeaderId > 0 &&
			isAuthorized(userProfile.permissions, [
				permConst.ORDER.DELETE.COMPANY
			]);

		return (
			<>
				{isEditable && (
					<MeatballDropdown
						size="lg"
						id='order-shipping-context-menu'
					>
						<MeatballDropdown.Item
							disabled={!formIsValid}
							onSelect={() => saveClicked()}
						>
							Save Draft Order
						</MeatballDropdown.Item>
						{canDeleteOrder && (
							<MeatballDropdown.Item
								onSelect={() => deleteClicked()}
							>
								Delete Draft Order
							</MeatballDropdown.Item>
						)}
					</MeatballDropdown>
				)}
			</>
		);
	}

	render() {
		const {
			formElementChanged,
			onPlatformChanged,
			platform,
			publisherPO,
			orderComments,
			freightForwarder,
			customsEntryPort,
			shipTo,
			destinationContact,
			destinationPhone,
			shippingInstructions,
			requiresConsignee,
			isEdiOrder,
			isEditable,
			isSaving,
		} = this.props;
		const {
			isLoading,
			untouchedFields
		} = this.state;
		const [flagsForField, formIsValid] = this.props.isFormValid();

		const validationFlag = (field, defaultMessage=null) => {
			const flag = flagsForField(field);
			if (flag && !untouchedFields.has(field)) {
				return (<HelpBlock><span className='text-danger'>{flag.reason}</span></HelpBlock>);
			} else {
				if (!defaultMessage) return (<HelpBlock>{defaultMessage}</HelpBlock>);
			}
			return null;
		};

		const touchField = (field) => {
			untouchedFields.delete(field);
			this.setState({untouchedFields});
		};

		const loadingBody = () => (<Loading />);
		const contentBody = () => (<>
			<Form>
				<SectionTitle>Order Information</SectionTitle>
				<FormGroup as={Row}>
					<FormLabel column sm={4} className="text-sm-right">
						Platform
					</FormLabel>
					<Col sm={4}>
						<select
							id="platform"
							onChange={onPlatformChanged}
							onBlur={e => {touchField('platform');}}
							className="form-control"
							value={platform}
							disabled={
								isEdiOrder ||
								isSaving ||
								!isEditable
							}
						>
							{this.renderPlatforms()}
						</select>
						{validationFlag('platform')}
					</Col>
				</FormGroup>

				<FormGroup as={Row}>
					<FormLabel column sm={4} className="text-sm-right">
						Publisher PO #
					</FormLabel>
					<Col sm={4}>
						<input
							id="publisherPO"
							onChange={formElementChanged}
							onBlur={e => touchField('publisherPO')}
							className="form-control"
							value={publisherPO}
							disabled={
								isEdiOrder ||
								isSaving ||
								!isEditable
							}
							maxLength="20"
						/>
						{ validationFlag('publisherPO', '20 character limit')}
					</Col>
				</FormGroup>

				<FormGroup as={Row}>
					<FormLabel column sm={4} className="text-sm-right">
						Order Comments
					</FormLabel>
					<Col sm={4}>
						<textarea
							id="orderComments"
							onChange={formElementChanged}
							className="form-control"
							rows="5"
							value={orderComments}
							disabled={isSaving || !isEditable}
						/>
						<HelpBlock>Optional</HelpBlock>
					</Col>
				</FormGroup>

				<SectionTitle>Shipping Information</SectionTitle>
				<FormGroup as={Row}>
					<FormLabel column sm={4} className="text-sm-right">
						Freight Forwarder
					</FormLabel>
					<Col sm={4}>
						<select
							id="freightForwarder"
							onChange={formElementChanged}
							className="form-control"
							value={freightForwarder}
							disabled={isSaving || !isEditable}
						>
							{this.renderFreightForwardersOptions()}
						</select>
					</Col>
				</FormGroup>

				{requiresConsignee(freightForwarder) && (
					<ConsigneeFields
						columnSize={4}
						classPrefix={'order-create'}
						{...this.props}
						countryCodes={this.state.countryCodes}
						ableToEdit={!isSaving && isEditable}
						validationFlag={validationFlag}
						touchField={touchField}
					/>
				)}

				<FormGroup as={Row}>
					<FormLabel column sm={4} className="text-sm-right">
						Customs Entry Port
					</FormLabel>
					<Col sm={4}>
						<select
							id="customsEntryPort"
							onChange={formElementChanged}
							className="form-control"
							value={customsEntryPort}
							disabled={isSaving || !isEditable}
						>
							{this.renderCustomsEntryPorts()}
						</select>
					</Col>
				</FormGroup>

				<FormGroup as={Row}>
					<FormLabel column sm={4} className="text-sm-right">
						Packout Facility
					</FormLabel>
					<Col sm={4}>
						<select
							id="shipTo"
							onChange={formElementChanged}
							className="form-control"
							value={shipTo}
							disabled={isSaving || !isEditable}
							onBlur={e => touchField('shipTo')}
						>
							{this.renderShipTos()}
						</select>
						{validationFlag('shipTo')}
					</Col>
				</FormGroup>

				<FormGroup as={Row}>
					<FormLabel column sm={4} className="text-sm-right">
						Destination Contact
					</FormLabel>
					<Col sm={4}>
						<input
							id="destinationContact"
							onChange={formElementChanged}
							className="form-control"
							value={destinationContact}
							disabled={isSaving || !isEditable}
							onBlur={e => touchField('destinationContact')}
						/>
						{validationFlag('destinationContact')}
					</Col>

				</FormGroup>

				<FormGroup as={Row}>
					<FormLabel column sm={4} className="text-sm-right">
						Destination Phone
					</FormLabel>
					<Col sm={4}>
						<input
							id="destinationPhone"
							onChange={formElementChanged}
							className="form-control"
							value={destinationPhone}
							disabled={isSaving || !isEditable}
							onBlur={e => touchField('destinationPhone')}
						/>
						{validationFlag('destinationPhone')}
					</Col>
				</FormGroup>

				<FormGroup as={Row}>
					<FormLabel column sm={4} className="text-sm-right">
						Shipping Instructions
					</FormLabel>
					<Col sm={4}>
						<textarea
							id="shippingInstructions"
							onChange={formElementChanged}
							className="form-control"
							rows="5"
							value={shippingInstructions}
							disabled={isSaving || !isEditable}
						/>
						<HelpBlock>Optional</HelpBlock>
					</Col>
				</FormGroup>
			</Form>
			{this.makeFooter(formIsValid)}
		</>);

		return (
			<div className="page-orders-items">
				<Title
					title="Create New Physical Order"
					subtitle="Step 1: Order Info"
					button={this.renderTitleButton(formIsValid)}
				/>
				<Page.FullPageCol>
				{ isLoading
					? loadingBody()
					: contentBody()
				}
				</Page.FullPageCol>
			</div>
		);
	}

	makeFooter(formIsValid) {
		const {
			history,
			isSaving,
			saveClicked,
			nextClicked,
			isEditable
		} = this.props;

		return (
			<div className="btn-container">
				<div className="float-left">
					<button
						className="btn btn-link no-outline"
						type="button"
						disabled={isSaving}
						onClick={() => history.push('/orders')}
					>
						Cancel
					</button>
				</div>
				<div className="float-right d-flex">
					{isSaving && <LoadingText inline className="m-a" />}
					{isEditable && (
						<Button
							variant="outline-secondary"
							onClick={() => saveClicked()}
							disabled={!formIsValid || isSaving}
						>
							Save
						</Button>
					)}
					<Button
						variant="primary"
						onClick={nextClicked}
						disabled={!formIsValid || isSaving}
					>
						Next (Items){' '}
						<FAIcon name="chevron-right" />
					</Button>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(OrderAndShipping);
