import 'core-js/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import dayjs from 'dayjs';
import inBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { Store } from 'redux';

import App from './components/App/App';
import ErrorCatcher from './components/ErrorCatcher/ErrorCatcher';
import { localConfig } from './config';
import { ping } from './services/baseService';
import configureStore from './store/store';
import { getHistory } from './utils/routeUtils';

import './css/normalize.css';
import './css/popup.css';
import './css/sitestyles.css';

localConfig();

// setup dayjs module
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(inBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

// set up react query
const queryClient:QueryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		},
	},
});

// initial ping
queryClient.prefetchQuery({ queryKey: 'ping', queryFn: () => ping() });

// set up redux
const store:Store = configureStore();

ReactDOM.render(
	<ErrorCatcher>
		<Provider store={store}>
			<QueryClientProvider client={queryClient}>
				<Router history={getHistory()}>
					<App />
				</Router>
			</QueryClientProvider>
		</Provider>
	</ErrorCatcher>,
	document.getElementById('root')
);
