import { parseDateString, isDateInRange, now } from './dateUtils';

export function getSunsetMessage(
	sunsetFeaturesData: { content: SunsetTable },
	sunsetProperty: string,
) {
	if (
		!sunsetFeaturesData ||
		!sunsetFeaturesData.content ||
		!(sunsetProperty in sunsetFeaturesData.content)
	) {
		return null;
	}

	if (
		sunsetFeaturesData.content &&
		'from' in sunsetFeaturesData.content[sunsetProperty] &&
		'until' in sunsetFeaturesData.content[sunsetProperty]
	) {
		let sunsetFeature = sunsetFeaturesData.content[sunsetProperty] as SunsetDefinition;
		if (
			isDateInRange(
				now(),
				parseDateString(sunsetFeature.from),
				parseDateString(sunsetFeature.until),
			)
		) {
			return 'message_text' in sunsetFeature ? sunsetFeature.message_text : null;
		}
	}
}

export function isSunsettingFeatureStillValid(sunsetDefinition: SunsetDefinition) {
	return isDateInRange(
		now(), 
		null, 
		parseDateString(sunsetDefinition.until)	
	);
}

export function isFeatureSunsetted(
	sunsetFeatureData: SunsetTable | undefined,
	featureName: string,
	platform?: PlatformCode,
) {
	const sunsetDefinition:SunsetDefinition = (platform
		? (sunsetFeatureData?.[featureName] as Record<string, any>)?.[platform]
		: sunsetFeatureData?.[featureName]) as SunsetDefinition;

	if (sunsetDefinition && 'until' in sunsetDefinition) {
		return !isSunsettingFeatureStillValid(sunsetDefinition);
	}
	return false;
}
