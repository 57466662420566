export const eventConstants = {
	STATUS: {
		PLANNED: '0',
		LIVE: '1',
		DRAFT: '2',
		COMPLETED: '3',
	},
	STATUS_NAMES: {
		'0': 'Planned',
		'1': 'Live',
		'2': 'Draft',
		'3': 'Completed',
	},
	TASKS: {
		STATUS: {
			OPEN: 'Open',
			IN_PROGRESS: 'In Progress',
			COMPLETED: 'Completed',
		},
		TYPE: {
			INTERNAL: 'I',
			EXTERNAL: 'E'
		}
	}
};
