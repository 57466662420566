import React from 'react';

import './SectionTitle.css';


const SectionTitle = ({className='', children}) =>
    <h4 className={'SectionTitle border-bottom pb-2'+(className ? ' '+className: '')}>
        {children}
    </h4>;

export default SectionTitle;
