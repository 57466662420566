import React from 'react';
import { Button } from 'react-bootstrap';
import { useQuery } from 'react-query';

import DigitalCodesOrderV1 from '../../../components/DigitalCodesOrder/DigitalCodesOrder__V1';
import FAIcon from '../../../components/FAIcon/FAIcon';
import Page from '../../../components/Page/Page';
import Title from '../../../components/Title/Title';
import { getEstimatedPrice } from '../../../services/digitalCodesService';
import { safeEval } from '../../../utils/dataUtils';
import { toasterNotify } from '../../../utils/toaster';
import {
	useProductQuery,
	usePurposeQuery,
} from '../DigitalCodesOrderCreate.helper';
import OrderCreateMenu from './OrderCreateMenu';


export const getPurposeNameFromId = (id, purposeOutput) =>
	safeEval(() =>
		purposeOutput.find(
			(entry) => entry.digital_orders_purpose_id === id,
		).purpose,
	);

const Step3Confirmation = ({ orderData, onPrevious, onCancel, onDelete, onSubmit, isSubmitting }) => {
	const { productID, purposeID  } = orderData;

	const productQuery = useProductQuery(purposeID);
	const products = productQuery.data?.data;
	const { game_code, game_name, components } =
		(products && products.find((product) => product.product_id === productID)) || {};

	const componentData =
		components?.find((component) => component.part_number === orderData.componentID);

	const {
		game_name: componentGameName,
	} = componentData || {};

	const purposeQuery = usePurposeQuery();
	const purpose =
		purposeQuery.data?.data.find(
			(purpose) => purpose.digital_orders_purpose_id === purposeID,
		) || {};

	const [ componentIDForEstimate, quantityForEstimate ] = purpose.price_type_code === 'FEE'
		? ['DIGITALCODES', '1']
		: [orderData.componentID, orderData.quantity];

	const estimatedPriceQuery = useQuery(
		['getEstimatedPrice', componentIDForEstimate, purpose.purpose, quantityForEstimate],
		() => getEstimatedPrice(componentIDForEstimate, purpose.purpose, quantityForEstimate),
		{
			enabled: !!(componentIDForEstimate && purposeQuery.isSuccess && productQuery.isSuccess),
			onError: (error) =>
				toasterNotify(
					'There was a problem retrieving the price estimate for this order. Please note the game title and component and contact site support.',
					'error',
					error,
				),
		},
	);
	const estimatedPriceData = estimatedPriceQuery.isSuccess && estimatedPriceQuery.data.data;

	const totalPrice = estimatedPriceQuery.isSuccess && Number(estimatedPriceData.adjustedPrice);
	const unitPrice =
		estimatedPriceQuery.isSuccess &&
		Number(estimatedPriceData.adjustedPrice) / Number(orderData.quantity);

	return (
		<>
			<Title
				subtitle="Step 3: Submit for Review"
				button={<OrderCreateMenu showSave={false} onDelete={onDelete} />}
			>
				Create New Digital Code Order
			</Title>
			<Page.FullPageCol>
				<DigitalCodesOrderV1
					order={orderData}
					purposeName={
						(purposeQuery.isSuccess &&
							getPurposeNameFromId(purposeID, purposeQuery.data.data)) ||
						purposeID
					}
					productsComponentsAreLoading={
						productQuery.isLoading || estimatedPriceQuery.isLoading
					}
					productsComponents={{
						game_code,
						game_name,
						componentGameName,
						componentID: orderData.componentID,
					}}
					prices={estimatedPriceQuery.isSuccess && { unitPrice, totalPrice }}
					pricesAreLoading={estimatedPriceQuery.isLoading}
					unitPrice={unitPrice}
					totalPrice={totalPrice}
				/>

				<div className="btn-container">
					<div className="float-left">
						<Button
							variant="outline-secondary"
							disabled={isSubmitting}
							onClick={() => onPrevious()}
						>
							<FAIcon className="mr-1" name="chevron-left" />
							Previous (Items)
						</Button>
						<Button variant="link" disabled={isSubmitting} onClick={() => onCancel()}>
							Cancel
						</Button>
					</div>
					<div className="float-right d-flex">
						<Button
							className="ml-3"
							onClick={() => onSubmit(totalPrice, unitPrice)}
							disabled={isSubmitting || !estimatedPriceQuery.isSuccess}
						>
							Submit For Review
						</Button>
					</div>
				</div>
			</Page.FullPageCol>
		</>
	);
};

export default Step3Confirmation;
