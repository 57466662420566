import React, { Component } from 'react';
import { Col, Form, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap';

import BaseModal from '../../../components/BaseModal/BaseModal';
import { productTypes, submissionTypes } from '../../../constants/submissionsConstants';
import { putEditSubmission } from '../../../services/submissionsService';
import {
	generateOptions,
	getCheckbox,
	getSelector,
	getTextBox,
} from '../../../utils/componentUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';


function parseProductType(productType) {
	let parsedType;
	if (productTypes.includes(productType)) {
		parsedType = productType;
	} else {
		let typeArr = productType.split('-');
		typeArr.pop();
		parsedType = typeArr.join().replace(',', '-');
	}
	return parsedType;
}

function setFeatureForPut(feature) {
	let returnValue = feature;
	if (typeof feature !== 'boolean') {
		returnValue = feature === 'YES';
	}
	return returnValue;
}

export default class EditSubmissionModal extends Component {
	constructor(props) {
		super(props);
		const { submission } = this.props;
		this.state = {
			display: true,
			submission: {
				submissionId: submission.submission_id,
				version: submission.product_submission_version,
				submission_type: submission.type,
				product_type: parseProductType(submission.product_type),
				online_multiplay: submission.online_mp,
				online_commerce: submission.online_commerce,
				e_manual_cfa: submission.e_manual_cfa,
				rom_cxi: submission.rom_cxi,
				master_data_info: submission.master_data_info,
				wifi: submission.wifi,
				ugc: submission.ugc,
				aoc: submission.consumable_aoc,
				data_crc: submission.data_crc,
				header_crc: submission.header_crc,
				file_name: submission.file_name,
				comments: '',
			},
			isLoading: false,
		};
		this.onInputChange = this.onInputChange.bind(this);
		this.updateSubmission = this.updateSubmission.bind(this);
		this.toggleButton = this.toggleButton.bind(this);
	}

	toggleButton() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	isFormValid() {
		const { submission } = this.state;
		const { isWUP } = this.props;
		let requiredFields = [submission.version];
		if (isWUP) {
			requiredFields = requiredFields.concat([submission.data_crc, submission.header_crc]);
		} else {
			requiredFields = requiredFields.concat([
				submission.rom_cxi,
				submission.master_data_info,
				submission.e_manual_cfa,
			]);
		}
		for (let i of requiredFields) {
			if (!i) {
				return false;
			}
		}
		return true;
	}

	updateSubmission() {
		this.setState({isLoading: true});
		const submission = this.state.submission;

		submission['online_multiplay'] = setFeatureForPut(submission.online_multiplay);
		submission['online_commerce'] = setFeatureForPut(submission.online_commerce);
		submission['wifi'] = setFeatureForPut(submission.wifi);
		submission['ugc'] = setFeatureForPut(submission.ugc);
		submission['aoc'] = setFeatureForPut(submission.aoc);
		submission.submission_type = submission.type;
		submission.receiving_comments = submission.comments;
		putEditSubmission(submission.submissionId, JSON.stringify(submission))
			.then((response) => {
				toasterNotify('Updated Submission', 'success');
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'updating submission'),
					'error',
					error
				);
			})
			.finally(() => {
				this.props.reloadActivity();
				this.props.reloadDetails();
				this.toggleButton();
				this.props.closeModal();
			});
	}

	onInputChange(name, value) {
		let submission = this.state.submission;
		submission[name] = value;
		this.setState({
			submission: submission,
		});
	}

	render() {
		let { display, submission, isLoading } = this.state;
		let { closeModal, isWUP } = this.props;
		return (
			<BaseModal show={display} onCancel={closeModal} isSubmitting={isLoading}>
				<BaseModal.Title>Edit Submission</BaseModal.Title>
				<Form className="gameCodeForm">
					{getTextBox(
						'version',
						'Submission Version',
						this.onInputChange,
						submission.version,
					)}
					{getSelector(
						'type',
						'Submission Type',
						generateOptions(submissionTypes),
						this.onInputChange,
						submission.submission_type,
					)}
					{getCheckbox(
						'online_multiplay',
						'Online Multi Play',
						this.onInputChange,
						submission.online_multiplay,
					)}
					{getCheckbox(
						'online_commerce',
						'Online Commerce',
						this.onInputChange,
						submission.online_commerce,
					)}
					{getCheckbox('wifi', 'WiFi', this.onInputChange, submission.wifi)}
					{getCheckbox('ugc', 'UGC', this.onInputChange, submission.ugc)}
					{getCheckbox('aoc', 'AOC', this.onInputChange, submission.aoc)}
					{getTextBox('file_name', 'File Name', this.onInputChange, submission.file_name)}
					{isWUP ? (
						<div>
							{getTextBox(
								'data_crc',
								'Data CRC',
								this.onInputChange,
								submission.data_crc,
							)}
							{getTextBox(
								'header_crc',
								'Header CRC',
								this.onInputChange,
								submission.header_crc,
							)}
						</div>
					) : (
						<div>
							{getTextBox(
								'rom_cxi',
								'ROM CXI (CRC32)',
								this.onInputChange,
								submission.rom_cxi,
							)}
							{getTextBox(
								'e_manual_cfa',
								'E-Manual CFA (CRC32)',
								this.onInputChange,
								submission.e_manual_cfa,
							)}
							{getTextBox(
								'master_data_info',
								'Master Data Info (CRC32)',
								this.onInputChange,
								submission.master_data_info,
							)}
						</div>
					)}

					<FormGroup as={Row}>
						<FormLabel column sm={4} id="comments" className="col-sm-4 text-sm-right">
							Comments
						</FormLabel>
						<Col sm={7}>
							<FormControl
								as="textarea"
								rows="5"
								onChange={(e) => {
									this.onInputChange('comments', e.currentTarget.value);
								}}
								value={this.state.submission.comments}
							/>
						</Col>
					</FormGroup>
				</Form>
				<BaseModal.Submit
					onClick={this.updateSubmission}
					disabled={this.state.isLoading || !this.isFormValid()}
				>
					Update
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}
