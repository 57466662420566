import React, { useState, useEffect, useMemo } from 'react';
import * as yup from 'yup';
import { FormCheck } from 'react-bootstrap';

import { roleConstants } from '../../../constants/roleConstants';
import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import {
	getEventDetails,
	getEventSystemFamilies,
	getEventSystemAssetLanguages,
	postMarketingEvents,
	putUpdateMarketingEvents,
} from '../../../services/eventsService';
import { getRoleAssignedUsers } from '../../../services/usersService';
import { formatLocale } from '../../../utils/localeUtils';
import { validateToSchema } from '../../../utils/validationUtils';
import { cancelToast, toasterNotify } from '../../../utils/toaster';
import { isEmptyObject, safeEval } from '../../../utils/dataUtils';

export const baseSchema = yup.object({
	externally_visible: yup.boolean().default(false),
	internally_visible: yup.boolean().default(false),
	name: yup
		.string()
		.default('')
		.required('Event name must be supplied')
		.test(
			'duplicate-name',
			'This event name is already used by another event',
			(value, { options }) => !options.context.duplicateNames.includes(value),
		),
	description: yup.string().default(''),
	category: yup
		.string()
		.default('')
		.required('A category must be selected'),
	start_datetime: yup
		.date()
		.default(null)
		.nullable(),
	end_datetime: yup
		.date()
		.default(null)
		.nullable()
		.min(new Date(), 'Event end cannot occur in the past')
		.when('start_datetime', (startDate, schema) =>
			startDate > new Date()
				? schema.min(startDate, 'Event end date cannot be before start date')
				: schema.min(new Date(), 'Event end cannot occur in the past'),
		),
	asset_languages: yup
		.array()
		.of(yup.string())
		.default([])
		.min(1, 'At least one asset language must be selected'),
	system_families: yup
		.array()
		.of(yup.string())
		.default([])
		.min(1, 'At least one system family must be selected'),
	managers: yup
		.array()
		.of(yup.string())
		.default([])
		.min(1, 'At least one manager must be selected'),
});

const EventModal = ({
	show,
	onClose,
	onChange,
	eventCategories,
	platforms,
	eventId,
	permissions,
	defaultValues = {},
}) => {
	const [formValues, setFormValues] = useState(defaultValues);
	const [isLoading, setIsLoading] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showAllErrors, setShowAllErrors] = useState(false);
	const [duplicateNames, setDuplicateNames] = useState(['xxx']);
	const [visibleFields, setVisibleFields] = useState([]);
	const [eventSystemFamilies, setEventSystemFamilies] = useState(null);
	const [assetLanguages, setAssetLanguages] = useState(null);
	const [teamMembers, setTeamMembers] = useState(null); // list of users

	const [showOnlyManagers, setShowOnlyManagers] = useState(false);
	const [fault, setFault] = useState(null);

	const platformNameFromCode = (code) =>
		((platforms.find && platforms.find((p) => p.system_family === code)) || {}).platform_name;

	const trimNonManagers = () => {
		if (formValues.managers) {
			const trimmedList = formValues.managers.filter(
				(id) => !!teamMembers.find((tm) => tm.ndid_user_id === id && tm.isPowerUser),
			);
			setFormValues((formValues) => ({ ...formValues, managers: trimmedList }));
		}
	};

	const { canSetVisibility, canSetEventDetails, canSetManagers } = permissions || {};
	const accessAllFields = !eventId;

	const schema = useMemo(() => {
		if (!eventId) {
			return baseSchema;
		}
		let pickedFields = [];
		if (canSetVisibility) {
			pickedFields.push('externally_visible', 'internally_visible');
		}
		if (canSetEventDetails) {
			pickedFields.push(
				'name',
				'description',
				'category',
				'start_datetime',
				'end_datetime',
				'asset_languages',
			);
		}
		if (canSetManagers) {
			pickedFields.push('managers');
		}
		pickedFields = pickedFields.filter((f) => visibleFields.includes(f));
		return baseSchema.pick(pickedFields);
	}, [eventId, permissions, visibleFields]);

	const validationErrors = validateToSchema(schema, formValues, { duplicateNames });

	const submitForm = () => {
		if (isSubmitting) {
			return;
		}
		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}

		const castedFormValues = schema.cast(formValues, { stripUnknown: true });
		setIsSubmitting(true);

		if (eventId) {
			putUpdateMarketingEvents(eventId, castedFormValues)
				.then(() => {
					onChange();
					toasterNotify(
						`Your event "${formValues.name}" has been successfully updated`,
						'success',
					);
				})
				.catch((err) => {
					if (
						safeEval(
							() => err.response.data.message.error.message && err.response.status,
						) &&
						err.response.status >= 400 &&
						err.response.data.message.error.message ===
							'A marketing event with this name already exists'
					) {
						cancelToast(err);
						setDuplicateNames((names) => {
							names.push(castedFormValues.name);
							return names;
						});
					} else {
						toasterNotify(
							'There was an unexpected error while updating this event.',
							'error',
							err,
						);
					}
				})
				.finally(() => {
					setIsSubmitting(false);
				});
		} else {
			postMarketingEvents(castedFormValues)
				.then(() => {
					onChange();
					toasterNotify(
						`Your new event "${castedFormValues.name}" has been successfully created`,
						'success',
					);
				})
				.catch((err) => {
					if (
						safeEval(
							() => err.response.data.message.error.message && err.response.status,
						) &&
						err.response.status >= 400 &&
						err.response.data.message.error.message ===
							'A marketing event with this name already exists'
					) {
						cancelToast(err);
						setDuplicateNames((names) => {
							names.push(castedFormValues.name);
							return names;
						});
					} else {
						toasterNotify(
							'There was an unexpected error while creating this event.',
							'error',
							err,
						);
					}
				})
				.finally(() => {
					setIsSubmitting(false);
				});
		}
	};

	const loadData = () => {
		// reset state to base values
		setIsLoading(true);
		setFormValues(defaultValues);
		setShowAllErrors(false);
		setFault(null);
		setDuplicateNames([]);

		let powerUsers, teamMembers;
		const calls = [];

		if (!eventSystemFamilies) {
			const systemsCall = getEventSystemFamilies().then((response) => {
				setEventSystemFamilies(response.data);
			});
			calls.push(systemsCall);
		}
		if (!assetLanguages) {
			const languagesCall = getEventSystemAssetLanguages().then((response) => {
				setAssetLanguages(response.data);
			});
			calls.push(languagesCall);
		}
		if (!teamMembers) {
			const powerUsersCall = getRoleAssignedUsers(
				roleConstants.MARKETING_EVENTS_POWER_USER,
			).then((response) => {
				powerUsers = response.data;
			});
			const teamMembersCall = getRoleAssignedUsers(
				roleConstants.MARKETING_EVENTS_TEAM_MEMBER,
			).then((response) => {
				teamMembers = response.data;
			});
			calls.push(powerUsersCall);
			calls.push(teamMembersCall);
		}
		if (eventId) {
			const existingDataCall = getEventDetails(eventId).then((response) => {
				setFormValues(response.data);
				setVisibleFields(Object.keys(response.data));
			});
			calls.push(existingDataCall);
		}

		Promise.all(calls)
			.then(() => {
				if (powerUsers && teamMembers) {
					setTeamMembers(
						teamMembers.map((tm) => {
							const isPowerUser = !!powerUsers.find(
								(pu) => pu.ndid_user_id === tm.ndid_user_id,
							);
							return {
								ndid_user_id: tm.ndid_user_id,
								isPowerUser,
								displayName: `${tm.user_name} (${
									isPowerUser ? 'Event Manager' : 'Team Member'
								})`,
							};
						}),
					);
				}
			})
			.catch((error) => {
				setFault(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	// data load
	useEffect(() => {
		if (show) {
			loadData();
		}
	}, [show]);

	return (
		<BaseModal
			show={show}
			onCancel={onClose}
			isLoading={isLoading}
			isSubmitting={isSubmitting}
			fault={fault}
		>
			<BaseModal.Title>{eventId ? 'Edit Event' : 'Create Event'}</BaseModal.Title>
			<Forms
				onChange={(v) => setFormValues(v)}
				values={formValues}
				validationErrors={validationErrors}
				showAllErrors={showAllErrors}
			>
				{(accessAllFields || 'internally_visible' in formValues) && (
					<Forms.SingleCheckbox
						id="internally_visible"
						disabled={!accessAllFields && !canSetVisibility}
					>
						<Forms.Heading>Visible Internally</Forms.Heading>
						<Forms.Label>YES</Forms.Label>
					</Forms.SingleCheckbox>
				)}
				{(accessAllFields || 'externally_visible' in formValues) && (
					<Forms.SingleCheckbox
						id="externally_visible"
						disabled={!accessAllFields && !canSetVisibility}
					>
						<Forms.Heading>Visible Externally</Forms.Heading>
						<Forms.Label>YES</Forms.Label>
					</Forms.SingleCheckbox>
				)}
				{(accessAllFields || 'name' in formValues) && (
					<Forms.Text
						id="name"
						disabled={!accessAllFields && !canSetEventDetails}
						maxLength="75"
					>
						<Forms.Heading>Name</Forms.Heading>
					</Forms.Text>
				)}
				{(accessAllFields || 'description' in formValues) && (
					<Forms.TextArea
						id="description"
						disabled={!accessAllFields && !canSetEventDetails}
						rows={4}
						maxLength="200"
					>
						<Forms.Heading>Description</Forms.Heading>
					</Forms.TextArea>
				)}
				{(accessAllFields || 'category' in formValues) && (
					<Forms.Select
						id="category"
						disabled={!accessAllFields && !canSetEventDetails}
						placeholder="Select a value"
					>
						<Forms.Heading>Category</Forms.Heading>
						{eventCategories &&
							Object.keys(eventCategories).map((key) => (
								<Forms.Option value={key} key={key}>
									{eventCategories[key]}
								</Forms.Option>
							))}
					</Forms.Select>
				)}
				{(accessAllFields || 'start_datetime' in formValues) && (
					<Forms.DateSelect
						id="start_datetime"
						disabled={!accessAllFields && !canSetEventDetails}
						showTimeSelect={true}
					>
						<Forms.Heading>Event Start Time</Forms.Heading>
					</Forms.DateSelect>
				)}
				{(accessAllFields || 'end_datetime' in formValues) && (
					<Forms.DateSelect
						id="end_datetime"
						disabled={!accessAllFields && !canSetEventDetails}
						showTimeSelect={true}
					>
						<Forms.Heading>Event End Time</Forms.Heading>
					</Forms.DateSelect>
				)}
				{(accessAllFields || 'system_families' in formValues) && (
					<Forms.CheckboxList
						id="system_families"
						disabled={!accessAllFields} // cannot be changed in edit mode
					>
						<Forms.Heading>Platforms</Forms.Heading>
						{eventSystemFamilies &&
							eventSystemFamilies.map((code) => (
								<Forms.Option value={code} key={code}>
									{platformNameFromCode(code)}
								</Forms.Option>
							))}
					</Forms.CheckboxList>
				)}
				{(accessAllFields || 'asset_languages' in formValues) && (
					<Forms.CheckboxList
						id="asset_languages"
						disabled={!accessAllFields && !canSetEventDetails}
					>
						<Forms.Heading>Asset Languages</Forms.Heading>
						{assetLanguages &&
							assetLanguages.map((lang) => (
								<Forms.Option value={lang} key={lang}>
									{formatLocale(lang)}
								</Forms.Option>
							))}
					</Forms.CheckboxList>
				)}
				{(accessAllFields || 'managers' in formValues) && (
					<Forms.MultiSelect
						id="managers"
						placeholder="Select a value"
						disabled={!accessAllFields && !canSetManagers}
						clearable
					>
						<Forms.Heading>Manager Permissions</Forms.Heading>
						<Forms.Preface>
							<FormCheck
								id="checkbox-event-managers-only"
								type="checkbox"
								onChange={(e) => {
									if (e.target.checked) {
										trimNonManagers();
									}
									setShowOnlyManagers(e.target.checked);
								}}
								checked={showOnlyManagers}
								disabled={!accessAllFields && !canSetManagers}
								label="Event Managers Only"
							/>
						</Forms.Preface>
						{teamMembers &&
							teamMembers.map((user) =>
								!showOnlyManagers || user.isPowerUser ? (
									<Forms.Option value={user.ndid_user_id} key={user.ndid_user_id}>
										{user.displayName}
									</Forms.Option>
								) : null,
							)}
					</Forms.MultiSelect>
				)}
			</Forms>
			<BaseModal.Submit onClick={() => submitForm()}>
				{eventId ? 'Update' : 'Create'}
			</BaseModal.Submit>
		</BaseModal>
	);
};

export default EventModal;
