import React, { VFC, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import * as yup from 'yup';

import AsperaFileUpload from '../../../components/AsperaFileUpload/AsperaFileUpload';
import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import { assetConst } from '../../../constants/assetConstants';
import { allowedFileExtensions, fileTransferConst } from '../../../constants/fileTransferConstants';
import { postAssetStatus } from '../../../services/productsService';
import { doAllFilesHaveExtension, fileNameHasGameCode } from '../../../utils/assetUtils';
import { isEmptyObject } from '../../../utils/dataUtils';
import { sleep } from '../../../utils/serviceUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { validateToSchema } from '../../../utils/validationUtils';

const GAME_CODE_PLACEHOLDER = '%GAME_CODE%';

const schema = yup.object().shape({
	files: yup
		.array()
		.test(
			'game_code_check',
			'Selected file must include the game code (' +
				GAME_CODE_PLACEHOLDER +
				") in the file's name",
			(value: any, { options }: yup.TestContext<any>) =>
				value?.every((item: AsperaFile) =>
					fileNameHasGameCode(item.file_name, options?.context?.gameCode),
				),
		)
		.test(
			'length_check',
			'A changed label file is required',
			(value) => !!value && value.length > 0,
		),
	comments: yup.string().optional(),
});

interface NCLApproveModalProps {
    show: boolean;
    onClose: () => void;
    onSuccess: () => void;
	product?: ProductData;
	asset?: ProductAssetData;
}
const NCLApproveModal: VFC<NCLApproveModalProps> = ({ show, onClose, onSuccess, product, asset}) => {
	const [formValues, setFormValues] = useState<Record<string,any>>({});
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);

	const [isUploadInitiated, setIsUploadInitiated] = useState<boolean>();
	const [selectedFiles, setSelectedFiles] = useState<AsperaFile[]>();
	const [transferSpecs, setTransferSpecs] = useState<{ transfer_specs: AsperaUploadSpec[] }>();
	const [revisionId, setRevisionId] = useState<number>();

	useEffect(() => {
		if (show) {
			setFormValues({});
			setIsSubmitting(false);
			setIsUploadInitiated(false);
			setTransferSpecs(undefined);
			
			setSelectedFiles(undefined);
			setShowAllErrors(false);
			setRevisionId(undefined);
		}
	}, [show]);

	const validationErrors = validateToSchema(
		schema,
		{ ...formValues, files: selectedFiles },
		{ gameCode: product?.game_code },
	);
	if (validationErrors.files && product?.game_code) {
		validationErrors.files = validationErrors.files.replace(GAME_CODE_PLACEHOLDER, product?.game_code);
	}

	const submitApproval = async () => {
		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}
		setIsSubmitting(true);
		const payload = {
			review_status: assetConst.STATUS.PRINT_PROOF_UPLOADING,
			file_upload_type: assetConst.UPLOAD.PROOF,
			comment: formValues.comment,
			files: selectedFiles,
		};

		try {
			const response = await postAssetStatus(String(asset?.asset_id), payload);
			toasterNotify('NCL proof submitted', 'success');
			if (response.data.transfer_specs) {
				setTransferSpecs({
					transfer_specs: response.data.transfer_specs,
				});
				setRevisionId(response.data.revision_id);
				setIsUploadInitiated(true);
				await sleep(100); // AssetDetails.js will destroy the component instance right away after closing, so this gap allows the file transfer to initiate before the close occurs.
			}
			onSuccess();
			onClose();
		} catch (error: unknown) {
			if (error instanceof Error && 'isAxiosError' in error && error.isAxiosError) {
				toasterNotify(
					createMessageForError(error, 'NCL approving asset'),
					'error',
					error
				);
			} else {
				throw error;
			}
		} finally {
			setIsSubmitting(false);
		}
	};
	
	return (
		<BaseModal show={show} onCancel={onClose} isSubmitting={isSubmitting}>
			<BaseModal.Title>NCL Approve</BaseModal.Title>
			<Alert variant="info">
				By confirming an NCL Approval, a notification will be sent to the publisher to
				accept NCL's Approval
			</Alert>
			<Forms
				values={formValues}
				onChange={(newValues) => setFormValues(newValues)}
				showAllErrors={showAllErrors}
				validationErrors={validationErrors}
			>
				<Forms.CustomArea id="files">
					<Forms.Heading>Attach File</Forms.Heading>

					<AsperaFileUpload
						updateFilesSelected={() => null}
						entityType={fileTransferConst.ENTITY_TYPE.PRODUCT_ASSET_REVISION}
						entityId={revisionId}
						isUploadInitiated={isUploadInitiated}
						allowedFileTypes={[
							{
								filter_name: 'Accepted Files',
								extensions: allowedFileExtensions.PACKAGING_ASSET,
							},
						]}
						validateFileType={(files: AsperaFile[]) => {
							setSelectedFiles(files);
							const allowedFileTypes = allowedFileExtensions.PACKAGING_ASSET;
							if (doAllFilesHaveExtension(files, allowedFileTypes)) {
								return true;
							}
							return false;
						}}
						prefetchedTransferSpecs={transferSpecs}
						singleFileOnly
					/>

					<Forms.Help>
						Selected file must include game code ({product?.game_code}) in the file name.
					</Forms.Help>
				</Forms.CustomArea>
				<Forms.TextArea id="comment" rows={4}>
					<Forms.Heading>Approval Comments</Forms.Heading>
					<Forms.Help>Optional</Forms.Help>
				</Forms.TextArea>
			</Forms>
			<BaseModal.Submit onClick={() => submitApproval()}></BaseModal.Submit>
		</BaseModal>
	);
};
export default NCLApproveModal;
