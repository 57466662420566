import React, { VFC } from 'react';
import { Table } from 'react-bootstrap';
import { useQuery } from 'react-query';

import { useUserProfile } from '../../../hooks/reduxHooks';
import { getPhysicalOrderChecklist } from '../../../services/productsService';
import SimpleModal from '../../../components/modals/SimpleModal/SimpleModal';
import FAIcon from '../../../components/FAIcon/FAIcon';

import './PhysicalOrderChecklistModal.css';

interface PhysicalOrderChecklistModalProps {
	productId?: number;
	closeModal?: () => void;
}

const PhysicalOrderChecklistModal: VFC<PhysicalOrderChecklistModalProps> = ({ productId, closeModal}) => {
	const userProfile = useUserProfile();
	const showPartNumbers = !!userProfile.companyProfile?.sku_number_required;

	const checklistQuery = useQuery(['getPhysicalOrderChecklist', productId], () =>
		getPhysicalOrderChecklist(String(productId)), { enabled: !!productId });
	const checklistData = checklistQuery.data?.data;

	function getChecklistIcon(value: boolean|undefined) {
		if (value) {
			return (<FAIcon name="check" className="text-success"/>);
		} else {
			return (<FAIcon name="times" className="text-danger"/>);
		}
	}

	const displayArray: [string, boolean|undefined][] =
		[
			['Retail Publisher Licensing Agreement', checklistData?.agreement],
			['Standard ESRB Rating', checklistData?.standard_rating],
			['Initial Label Submitted', checklistData?.initial_label],
			['ROM Submission Status OK or In Testing', checklistData?.valid_submission],
			['NOA Part Number Created', checklistData?.noa_part_number],
			(showPartNumbers ? ['Publisher Part/SKU Number Created', checklistData?.publisher_part_number] : ['',undefined]),
			['Price/Unit Available', checklistData?.priced],
			['Retail Distribution Rights', checklistData?.distribution_rights],
			['No Pending Rights Transfers', checklistData?.no_transfer]
	    ];

	return (
		<SimpleModal
			show={!!productId}
			title="Orderability Checklist"
			closeModal={closeModal}
			isLoading={checklistQuery.isLoading}
		>
			<p>To add this product to a physical order, these prerequisites need to be fulfilled:</p>
			<Table className="PhysicalOrderChecklistModal__table">
				{displayArray.map((displayItem) => {
					if (displayItem[0] === '') return;
					return (<tr><td>{displayItem[0]}</td>
						        <td>{getChecklistIcon(displayItem[1])}</td></tr>);
				})}
			</Table>
			<small>The publisher part number will also be required if the option has been added.</small>
		</SimpleModal>
	);
};

export default PhysicalOrderChecklistModal;
