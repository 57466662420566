import axios, { AxiosInstance, AxiosResponse } from 'axios';

import { hasUserProfile } from '../utils/reduxUtils';
import { createMessageForError, toasterNotify } from '../utils/toaster';
import { sleep } from '../utils/serviceUtils';
import { getHistory, getLocation, redirectToLogin } from '../utils/routeUtils';
import eventBus from '../utils/eventBus';
import { getStore } from '../store/store';
import { logout } from './usersService';


let apiInstance: AxiosInstance | undefined, 
	cachedApiInstance: AxiosInstance | undefined;
const cachedResponses: Record<string, AxiosResponse> = {};

export default function api(options?: { useCache: boolean }) {
	const { useCache } = options || {};
	let axiosInstance;

	if (useCache) {
		axiosInstance = cachedApi();
	} else {
		axiosInstance = basicApi();
	}
	return axiosInstance;
}


function basicApi(): AxiosInstance {
	if (!apiInstance) {
		apiInstance = axios.create({
			baseURL: global.config.apiUrl,
			withCredentials: true
		});
		apiInstance.interceptors.response.use(
			(response: AxiosResponse) => {
				if (response.headers['x-pdb-user-details-timestamp']) {
					eventBus.emit(eventBus.UPDATE_USER_PROFILE, response.headers['x-pdb-user-details-timestamp']);
				}

				if (response.status === 202 && response.config.pollingUrl) {
					const executionId = response.data.execution_id;
					return asyncApiPoll(
						`${response.config.pollingUrl}${executionId}`,
						response.config.pollingInterval,
						response.config.pollingTimeLimit,
						response.config.pollingCallback,
						response.config.description,
					);
				}
				return response;
			},
			errorHandler
		);
	}
	return apiInstance;
}

// Alternate instance of axios that will cache and serve data for the lifetime
// of the client instance. Use with data calls that are not expected to change
// within a user session and are too light to need a redux implementation.
function cachedApi() {
	if (!cachedApiInstance) {
		cachedApiInstance = axios.create({
			baseURL: global.config.apiUrl,
			withCredentials: true
		});
		cachedApiInstance.interceptors.request.use(
			(request) => {
				// limit caching to get calls with no search params
				if (request.method !== 'get' || /[?]+/.test(request.url || '')) {
					return request;
				}
				
				const cached = request.url && cachedResponses[request.url];
				if (cached) {
					request.data = cached;
					request.adapter = () => {
						return Promise.resolve({
							data: cached,
							status: 200,
							statusText: 'OK',
							headers: request.headers,
							config: request,
							request: request
						});
					};
				}
				return request;
			}
		);
		cachedApiInstance.interceptors.response.use(
			(response) => {
				const isCacheable = !response.config.params && response.config.method === 'get';
				if (isCacheable) {
					let url = response.config.url;
					url && (cachedResponses[url] = response.data);
				}
				return response;
			},
			errorHandler
		);
	}
	return cachedApiInstance;
}

function errorHandler(error: unknown): never {
	if (!!error && typeof error === 'object' && 'response' in error) {
		const response = error.response as AxiosResponse<unknown> | undefined;
		const config = response?.config;
		if (response?.status) {
			const { status } = response;
			if (
				(![401, 404].includes(status)) ||
				(status === 404 && config?.method !== 'get')
			) {
				toasterNotify(createMessageForError(error), 'error', error);
			}
			if (status === 503) {
				ping();
			} else if (status === 401 && hasUserProfile()) {
				ping();
			}
		} else {
			toasterNotify(createMessageForError(error), 'error', error);
		}
	} else {
		toasterNotify(createMessageForError(error), 'error', error);
	}
	throw error;
}

/**
 * Continuously poll an endpoint to check the completion of a task began by an
 * asynchronous API endpoint that defers completion of a task after the initializing
 * API call.
 * @param  {string} url path from api
 * @param  {Number} intervalLength=500 Milliseconds between poll attempts
 * @param  {Number} timeLimit=15000 Milliseconds timeout before polling fails
 * @param  {Function} progress Callback function when
 */
export async function asyncApiPoll(
	url: string,
	intervalLength: number = 800,
	timeLimit: number = 60000,
	progress?: (response?: AxiosResponse) => void,
	description?: string,
): Promise<AxiosResponse> {
	let lastResponse: AxiosResponse | undefined;
	const timeStart = Date.now();
	for (let i = 0; i < Math.ceil(timeLimit / intervalLength); i++) {
		try {
			lastResponse = await basicApi().get<{ status: string }>(url, { description });
			if (lastResponse?.data?.status === 'completed') {
				break;
			}
		} catch (err) {
			errorHandler(err || new Error('Server could not be reached'));
		}
		if (Date.now() > timeStart + timeLimit) {
			break;
		} else {
			progress && progress(lastResponse);
			await sleep(intervalLength);
		}
	}

	if (lastResponse?.data?.status === 'completed') {
		const response = {
			status: lastResponse.data?.result?.statusCode,
			data: lastResponse.data?.result?.body,
			headers: { ...lastResponse.headers, ...lastResponse.data?.result?.headers },
			statusText: lastResponse.data?.result?.statusText,
			config: { ...lastResponse.config },
		};
		if (lastResponse.data.result.statusCode < 400) {
			return response;
		} else {
			const newError: Error & {
				response?: AxiosResponse<unknown>;
			} = new Error('Poll result returned a failure');
			newError.response = response;
			errorHandler(newError); // this function will throw the error so this function will end here
		}
	}
	const newError: Error & { response?: AxiosResponse<any> } = new Error(
		'Poll limit reached without a completed response',
	);
	newError.response = lastResponse;
	errorHandler(newError);
}

export function clearInstances() {
	apiInstance = undefined;
	cachedApiInstance = undefined;
}

export function ping() {
	// bypass the error handler used by regular calls
	return axios.get('/auth/ping', {
		baseURL: global.config.apiUrl,
		withCredentials: true
	})
		// if PUG is logged-in but no user profile exists in client, log the user out of PUG
		.then((response) => {
			const state = getStore().getState();
			const userProfile = state?.authReducer?.userProfile;
			if (
				response?.status === 200 &&
				!userProfile &&
				!/^\/login/i.test(getLocation().pathname) // ignore if we're on the login page
			) {
				logout();
			}
			return response;
		})
		// if error caught, automatically commit actions
		.catch((error) => {
			if (error.response.status === 503) {
				if (!new URLSearchParams(getLocation().search).has('maintenance')) {
					const history = getHistory();
					const search = new URLSearchParams(history.location.search);
					search.set('maintenance', 'true');
					history.push(history.location.pathname + '?' + search.toString());
				}
			} else if (error.response.status === 401 && hasUserProfile()) {
				redirectToLogin();
			}
			throw error;
		});
}

export function log(payload: Record<string, any>) {
	return axios.post('/log', payload, {
		baseURL: global.config.apiUrl,
		withCredentials: true
	});
}
