export const subConst = {
	STATUS: {
		APPROVED: 'APPROVED',
		FAILED: 'FAILED',
		HOLD: 'HOLD',
		NOA_APPROVED: 'NOA_APPROVED',
		PRECHECK: 'PRECHECK',
		SAMPLE_APPROVED: 'SAMPLE APPROVED',
		TESTING: 'TESTING',
		OK: 'OK',
		SUBMITTING: 'SUBMITTING',
		SUBMITTED: 'SUBMITTED',
		INQUEUE: 'INQUEUE',
	} as const,
	TYPE: {
		E3: 'E3',
		PRECHECK: 'PRECHECK',
		LOTCHECK: 'LOTCHECK',
		SKIP_LOTCHECK: 'SKIP_LOTCHECK',
	} as const,
	DISTRIBUTION_TYPE: {
		BOTH: 'BOTH',
		PHYSICAL: 'PHYSICAL',
		DIGITAL: 'DIGITAL',
	} as const,
	CARD_SIZE: {
		NO_CARD_PRODUCTION: 'NO CARD PRODUCTION',
	} as const,
} as const;

export const submissionTypes = ['PRECHECK', 'LOTCHECK', 'E3'] as const;

export const productTypes = [
	'CTR-B',
	'CTR-E',
	'CTR-H',
	'CTR-M',
	'CTR-N',
	'CTR-T',
	'CTR-U',
	'CTR-P',
	'KTR-B',
	'KTR-M',
	'KTR-N',
	'KTR-U',
	'KTR-P',
	'KTR-T',
	'RVL',
	'WUP-B',
	'WUP-M',
	'WUP-N',
	'WUP-P',
	'WUP-T',
	'WUP-U',
] as const;

export const defaultNoRating = 'No Descriptor' as const;

export const gameRatings = [
	'ESRB E',
	'ESRB EC',
	'ESRB E10+',
	'ESRB T',
	'ESRB M',
	'IARC E',
	'IARC EC',
	'IARC E10+',
	'IARC T',
	'IARC M',
] as const;

export const ratingsDescriptorsESRB = [
	{
		key: 'No Descriptor',
		text: 'No Descriptor',
		value: 'No Descriptor',
	} as const,
	{
		key: 'Alcohol Reference',
		text: 'Alcohol Reference',
		value: 'Alcohol Reference',
	} as const,
	{ key: 'Animated Blood', text: 'Animated Blood', value: 'Animated Blood' } as const,
	{ key: 'Blood', text: 'Blood', value: 'Blood' } as const,
	{ key: 'Blood and Gore', text: 'Blood and Gore', value: 'Blood and Gore' } as const,
	{
		key: 'Cartoon Violence',
		text: 'Cartoon Violence',
		value: 'Cartoon Violence',
	} as const,
	{ key: 'Comic Mischief', text: 'Comic Mischief', value: 'Comic Mischief' } as const,
	{ key: 'Crude Humor', text: 'Crude Humor', value: 'Crude Humor' } as const,
	{ key: 'Drug Reference', text: 'Drug Reference', value: 'Drug Reference' } as const,
	{
		key: 'Fantasy Violence',
		text: 'Fantasy Violence',
		value: 'Fantasy Violence',
	} as const,
	{
		key: 'Intense Violence',
		text: 'Intense Violence',
		value: 'Intense Violence',
	} as const,
	{ key: 'Language', text: 'Language', value: 'Language' } as const,
	{ key: 'Mature Humor', text: 'Mature Humor', value: 'Mature Humor' } as const,
	{ key: 'Nudity', text: 'Nudity', value: 'Nudity' } as const,
	{ key: 'Partial Nudity', text: 'Partial Nudity', value: 'Partial Nudity' } as const,
	{ key: 'Real Gambling', text: 'Real Gambling', value: 'Real Gambling' } as const,
	{ key: 'Sexual Content', text: 'Sexual Content', value: 'Sexual Content' } as const,
	{ key: 'Sexual Themes', text: 'Sexual Themes', value: 'Sexual Themes' } as const,
	{
		key: 'Simulated Gambling',
		text: 'Simulated Gambling',
		value: 'Simulated Gambling',
	} as const,
	{
		key: 'Strong Language',
		text: 'Strong Language',
		value: 'Strong Language',
	} as const,
	{ key: 'Strong Lyrics', text: 'Strong Lyrics', value: 'Strong Lyrics' } as const,
	{
		key: 'Strong Sexual Content',
		text: 'Strong Sexual Content',
		value: 'Strong Sexual Content',
	} as const,
	{
		key: 'Suggestive Themes',
		text: 'Suggestive Themes',
		value: 'Suggestive Themes',
	} as const,
	{
		key: 'Tobacco Reference',
		text: 'Tobacco Reference',
		value: 'Tobacco Reference',
	} as const,
	{ key: 'Use of Alcohol', text: 'Use of Alcohol', value: 'Use of Alcohol' } as const,
	{ key: 'Use of Drugs', text: 'Use of Drugs', value: 'Use of Drugs' } as const,
	{ key: 'Violence', text: 'Violence', value: 'Violence' } as const,
	{
		key: 'Violent References',
		text: 'Violent References',
		value: 'Violent References',
	} as const,
];

export const gameRatingIARC = 'IARC' as const;
export const gameRatingESRB = 'ESRB' as const;

export const ratingsDescriptorsIARC = [
	{
		key: 'No Descriptor',
		text: 'No Descriptor',
		value: 'No Descriptor',
	} as const,
	{
		key: 'Abstract Violence',
		text: 'Abstract Violence',
		value: 'Abstract Violence',
	} as const,
	{ key: 'Violence', text: 'Violence', value: 'Violence' } as const,
	{
		key: 'Explicit Violence',
		text: 'Explicit Violence',
		value: 'Explicit Violence',
	} as const,
	{ key: 'War Themes', text: 'War Themes', value: 'War Themes' } as const,
	{
		key: 'Occasional Scary Contents',
		text: 'Occasional Scary Contents',
		value: 'Occasional Scary Contents',
	} as const,
	{ key: 'Scary Contents', text: 'Scary Contents', value: 'Scary Contents' } as const,
	{
		key: 'Scary and/or Horror Contents',
		text: 'Scary and/or Horror Contents',
		value: 'Scary and/or Horror Contents',
	} as const,
	{
		key: 'Nudity/Eroticism',
		text: 'Nudity/Eroticism',
		value: 'Nudity/Eroticism',
	} as const,
	{
		key: 'Sexual Innuendo',
		text: 'Sexual Innuendo',
		value: 'Sexual Innuendo',
	} as const,
	{ key: 'Sex/Eroticism', text: 'Sex/Eroticism', value: 'Sex/Eroticism' } as const,
	{
		key: 'Referenced Sexual Violence',
		text: 'Referenced Sexual Violence',
		value: 'Referenced Sexual Violence',
	} as const,
	{
		key: 'Sexual Violence',
		text: 'Sexual Violence',
		value: 'Sexual Violence',
	} as const,
	{
		key: 'Occasional Swearing',
		text: 'Occasional Swearing',
		value: 'Occasional Swearing',
	} as const,
	{
		key: 'Explicit Language',
		text: 'Explicit Language',
		value: 'Explicit Language',
	} as const,
	{ key: 'Tobacco Use', text: 'Tobacco Use', value: 'Tobacco Use' } as const,
	{ key: 'Alcohol Use', text: 'Alcohol Use', value: 'Alcohol Use' } as const,
	{ key: 'Drug Use', text: 'Drug Use', value: 'Drug Use' } as const,
	{ key: 'Discrimination', text: 'Discrimination', value: 'Discrimination' } as const,
	{
		key: 'Gambling With Cash Payouts',
		text: 'Gambling With Cash Payouts',
		value: 'Gambling With Cash Payouts',
	} as const,
	{ key: 'Users Interact', text: 'Users Interact', value: 'Users Interact' } as const,
	{
		key: 'Shares Location',
		text: 'Shares Location',
		value: 'Shares Location',
	} as const,
	{
		key: 'In-app-purchases/In-game-purchases',
		text: 'In-app-purchases/In-game-purchases',
		value: 'In-app-purchases/In-game-purchases',
	} as const,
	{
		key: 'Unrestricted Internet Access',
		text: 'Unrestricted Internet Access',
		value: 'Unrestricted Internet Access',
	} as const,
	{
		key: 'Contents For Different Age Groups',
		text: 'Contents For Different Age Groups',
		value: 'Contents For Different Age Groups',
	} as const,
] as const;
