import React from 'react';
import { Alert, Col, Form, FormCheck, FormGroup } from 'react-bootstrap';

import BaseModal from '../../../components/BaseModal/BaseModal';
import { putCompanyProjectability } from '../../../services/companiesService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';

import '../CompanyProfile.css';

export default class EditCompanyProjectabilityModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			display: true,
			canMakeProjections:
				props.companyProfile.company_information
					.projection_eligible_flag,
			isLoading: false
		};
		this.toggleCompanyProjectability = this.toggleCompanyProjectability.bind(this);
		this.updateCompanyProjectability = this.updateCompanyProjectability.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	toggleCompanyProjectability() {
		this.setState({
			canMakeProjections: !this.state.canMakeProjections
		});
	}

	updateCompanyProjectability() {
		this.toggleLoading();

		const { companyProfile } = this.props;
		const { canMakeProjections } = this.state;

		const companyId = companyProfile.company_information.ndid_company_id;

		const newValue = canMakeProjections ? 1 : 0;

		putCompanyProjectability(companyId, newValue)
			.then((response) => {
				this.props.updateProjectability(newValue);
				toasterNotify('Updated projection settings', 'success');
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'updating projection settings'),
					'error',
					error
				);
			})
			.finally(() => {
				this.props.closeFunction();
			});
	}

	render() {
		const { canMakeProjections } = this.state;

		return (
			<BaseModal
				show={this.state.display}
				onCancel={this.props.closeFunction}
				isSubmitting={this.state.isLoading}
			>
				<BaseModal.Title>Edit Company Projectability</BaseModal.Title>
				<div>
					<Alert variant="info">
						Selecting YES will allow this company to make projections.
					</Alert>
					<Form className="sku-form">
						<FormGroup>
							<Col offset={2} sm={5}>
								<strong>Can make projections</strong>
							</Col>
							<Col sm={5}>
								<FormCheck
									type="checkbox"
									id="canMakeProjections"
									defaultChecked={canMakeProjections}
									onChange={this.toggleCompanyProjectability}
									label="YES"
								/>
							</Col>
						</FormGroup>
					</Form>
				</div>
				<BaseModal.Submit onClick={this.updateCompanyProjectability}>Update</BaseModal.Submit>
			</BaseModal>
		);
	}
}
