import api from './baseService';

interface AnnouncementPrioritiesModel {

	priority_name: string;
	sort_order: number;
	priority_code: string;

}

interface AnnouncementTemplatesModel {
	message: string;
	template_name: string;
	template_id: number;
	display_heading: string;
	template_parameters: {
		parameter_sort_order: number;
		announcement_column_name: string;
		form_label: string;
		parameter_id: number;
		required_flag: number;
		parameter_data_type: string;
		template_id: number;
	} []

}

interface AnnouncementModel {
	modified_by_ndid: string;
	created_by_ndid: string;
	event_start_datetime: string;
	announcement_end_datetime: string;
	message: string;
	template_id: number;
	modification_date: string;
	creation_date: string;
	announcement_id: number;
	event_end_datetime: string;
	announcement_start_datetime: string;
	priority_code: string;
}


export const getAnnouncements = () =>
	api().get<AnnouncementModel[]>('/announcements');

export const getAnnouncementTemplates = () =>
	api().get<AnnouncementTemplatesModel[]>('/announcements/templates');

export const getAnnouncementPriorities = () =>
	api().get<AnnouncementPrioritiesModel[]>('/announcements/priorities');

export const createAnnouncement = (payload: Record<string, any>) =>
	api().post('/announcements', payload);

export const updateAnnouncement = (announcementId: string, payload: Record<string, any>) =>
	api().put(`/announcements/${announcementId}`, payload);

export const getAnnouncementsPublished = () =>
	api().get('/announcements/published');

export const deleteAnnouncement = (id: string) =>
	api().delete(`/announcements/${id}`);
