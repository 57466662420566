import { permConst } from '../../constants/permConst';
import { isAuthorized } from '../../utils/userUtils';
import { formatAssetStatus } from '../../utils/assetUtils';
import { assetConst } from '../../constants/assetConstants';
import { displayString } from '../../utils/stringUtils';
import { getAssetApprovalList } from '../../services/productsService';
import { useQuery } from 'react-query';


export const getPermissionsForAssets = (userProfile) => {
	return {
		showMarketingTab: isAuthorized(userProfile.permissions, [
			permConst.PRODUCT.ASSET.MARKETING.VIEW.ALL
		]),
		showPackagingTab: isAuthorized(userProfile.permissions, [
			permConst.PRODUCT.ASSET.PACKAGING.VIEW.ALL
		]),
		showCodeInBoxTab: isAuthorized(userProfile.permissions, [
			permConst.PRODUCT.ASSET.CODEINBOX.VIEW.ALL
		]),
		showMarketingEventTab: isAuthorized(userProfile.permissions, [
			permConst.MARKETING_EVENT.PRODUCT.ASSET.REVIEW.MANAGER,
			permConst.MARKETING_EVENT.PRODUCT.ASSET.REVIEW.COORDINATOR,
		]),
		showWSPRequestTab: isAuthorized(userProfile.permissions, [
			permConst.PRODUCT.COMPONENT.WHOLESALE_PRICE.REQUEST.APPROVE.ALL,
			permConst.PRODUCT.COMPONENT.WHOLESALE_PRICE.REQUEST.REVIEW.ALL,
		]),
		showDeclaredPriceTab: isAuthorized(userProfile.permissions, [
			permConst.PRODUCT.COMPONENT.DECLARED_PRICE.REQUEST.APPROVE.ALL,
			permConst.PRODUCT.COMPONENT.DECLARED_PRICE.REQUEST.REVIEW.ALL,
		])
	};
};

export function generateAssets(assets) {
	if (assets && assets.length > 0) {
		return assets.map((asset) => {
			asset.review_status = formatAssetStatus(asset.review_status);
			return asset;
		});
	}

	return [];
}

export const assetTypeCustomEvaluation = (value, filters) => {
	if (value === assetConst.TYPE.DISPLAY_SIGNAGE && filters.has('Display/Signage')) {
		return true;
	} else if (value === assetConst.TYPE.SWITCH_DLC_INSERT && filters.has('Switch DLC Insert')) {
		return true;
	} else if (
		value === assetConst.TYPE.RELEASE_ALERT &&
		filters.has('Release/Media Alert')
	) {
		return true;
	} else if (
		Object.keys(assetConst.TYPE).some(
			(type) => value === type && filters.has(displayString(type)),
		)
	) {
		return true;
	}
	return false;
};

export const useAssetQuery = (options) =>
	useQuery('getAssetApprovalList', () => getAssetApprovalList(), options);
