import React from 'react';

import { dateFormat, formatDate } from '../../../utils/dateUtils';

const DateTimeCell = ({ value }) => {
	if (!value) { return null; }
	return <span>{formatDate(value, dateFormat.DATETIME_PT)}</span>;
};

export default DateTimeCell;
