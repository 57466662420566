import produce from 'immer';

import { SAVE_TAB_STATE, SAVE_UI_CONTROL_STATE } from '../actions/action-types';
import { isEmptyObject } from '../../utils/dataUtils';

interface UITabState {
	[pathId: string]: {};
}

interface UIStore {
	uiStates?: UITabState;	
	tabStates?: UITabState;
}

interface UIAction {
	type: string;
	pathId: string;
	name: string;
	value: Record<string, any>[];
	tab?: any;
}

function uiReducer(state: UIStore={}, action: UIAction) {
	switch (action.type) {
		case SAVE_UI_CONTROL_STATE:
			return {...state, uiStates: createUIStates(state.uiStates, action)};
		case SAVE_TAB_STATE:
			return {...state, tabStates: createNewTabState(state.tabStates, action)};
		default:
			return state;
	}
}

function createUIStates(uiStates: UITabState={}, action: UIAction) {
	const pathState: Record<string, any> = action.pathId in uiStates ? {...(uiStates[action.pathId])} : {};
	if (action.value) {
		pathState[action.name] = action.value;
	} else {
		delete pathState[action.name];
	}
	const output = isEmptyObject(pathState) ? 
		produce(uiStates, (oldStates) => {
			const { [action.pathId]: removed, ...newStates } = oldStates;
			return newStates;
		}) :
		produce(uiStates, (oldStates) => (
			{
				...oldStates,
				[action.pathId]: pathState,
			}
		));
	return output;
}

function createNewTabState(original={}, action: UIAction) {
	return produce(original, (oldState) => ({
		...oldState,
		[action.pathId]: action.tab,
	}));
}

export default uiReducer;
