import React, { PropsWithChildren, VFC } from 'react';

const NoDataView: VFC<PropsWithChildren<unknown>> = ({
	children,
}: PropsWithChildren<unknown>) => (
	<div className="BaseTable__no-items">
		<i>{children}</i>
	</div>
);

export default NoDataView;
