import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';

import BaseModal from '../../BaseModal/BaseModal';
import { toasterNotify } from '../../../utils/toaster';
import { putUpdateMarketingEvents } from '../../../services/eventsService';
import { findEventStatus } from '../../../utils/eventUtils';
import { eventConstants } from '../../../constants/eventConstants';


const ACTIVATE = 'ACTIVATE';
const INACTIVATE = 'INACTIVATE';
const UNACTIONABLE = 'UNACTIONABLE';

const getAction = (eventData) => {
	return (eventData.active) ? INACTIVATE : ACTIVATE;
};

const EventActiveModal = ({show, eventData, onCancel, onComplete}) => {
	const { id, name } = eventData || {};
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [fault, setFault] = useState(false);

	useEffect(() => {
		// reset state when modal is opened
		if (show) { setFault(false); }
	}, [show]);

	const action = (eventData && !fault) ? getAction(eventData) : UNACTIONABLE;

	const setNewActiveState = async () => {
		if (action === UNACTIONABLE) {return;}

		if (findEventStatus(eventData) === eventConstants.STATUS.DRAFT) {
			setFault(true);
			return;
		}

		try {
			setIsSubmitting(true);
			await putUpdateMarketingEvents(id, {active: action === ACTIVATE});
			toasterNotify(`${name} was set to ${action === ACTIVATE ? 'active' : 'inactive'}`, 'success');
			onComplete();
		} catch(err) {
			if (err && err.response &&
				(err.response.status === 400 || (err.response.status >= 405 && err.response.status < 500))) {
				toasterNotify(`${name} active status could not be changed.`, 'error', err);
				setFault(true);
			}
			throw (err);
		} finally {
			setIsSubmitting(false);
		}
	};

	const title = (eventData && eventData.active === true) ? 'Set Inactive': 'Set Active';

	return <BaseModal
        show={show}
        onCancel={onCancel}
        isSubmitting={isSubmitting}
    >
        <BaseModal.Title>{title || ''}</BaseModal.Title>
        { (action === UNACTIONABLE)
            ? <>
				This event can not be set to active until it has an <b>active Product</b>, and the <b>Event Start Date/Time</b> and <b>Event End Date/Time</b> fields are set.
            </>
            : <>
                <Alert variant="info" className="mx-y">Please confirm that you would like to set <b>{name}</b> to {action === ACTIVATE ? 'active' : 'inactive'}.</Alert>

            </>
        }
        { action !== UNACTIONABLE &&
            <BaseModal.Submit onClick={e => setNewActiveState()}>Confirm</BaseModal.Submit>
        }
    </BaseModal>;
};

export default EventActiveModal;
