import React, { useState } from 'react';
import { Button, Col, FormControl, FormGroup, InputGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import FAIcon from '../../../components/FAIcon/FAIcon';
import HelpBlock from '../../../components/HelpBlock/HelpBlock';
import { validatePassword } from '../../../utils/digitalCodesUtils';

import './PasswordPrompt.css';


const PasswordPrompt = ({onChange, value, showFormErrors, disqualifiedPasswords, disabled}) => {
	const [peek, setPeek] = useState(false);

	const validationStatus = validatePassword(value);
	const passwordOK = validationStatus.every(v => v) && (!disqualifiedPasswords || !disqualifiedPasswords.some(dq => dq.password === value));

	const errorMessage = (() => {
		if (disqualifiedPasswords) {
			if (disqualifiedPasswords.find(dq => dq.password === value && dq.rejectedForReuse)) {
				return 'This password has been used within the last three entries. Please try another.';
			} else if (disqualifiedPasswords.find(dq => dq.password === value)) {
				return 'Server has rejected this password. Please try another.';
			}
		}
		if (showFormErrors && !passwordOK) {
			return 'Please fulfill all password requirements before proceeding';
		}
		return null;
	})();

	const validCharacterTooltip = <Tooltip id="valid-chars">Unaccented letters, numbers, and special characters:<br />
        {'! " # $ % & \' ( ) * + , - . / : ;'}<br />
        {'< > = ? @ [ ] \\ ^ _ ` { } | ~'}
    </Tooltip>;

	return (
		<>
			<Row>
				<Col sm={12}>
					<p>
						Please enter a password to unzip the download code file. Once a valid
						password is set, you will be able to download a password protected zip file
						with your digital codes.
					</p>

					<p>
						<b>Please note that the password cannot have been used the last 3 times.</b>
					</p>
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<FormGroup>
						<InputGroup>
							<FormControl
								type={peek ? 'text' : 'password'}
								id="password"
								value={value}
								onChange={(e) => onChange(e.target.value)}
								placeholder="Password"
								required
							/>
							<InputGroup.Append>
								<Button
									variant="outline-secondary"
									onMouseDown={() => setPeek(true)}
									onMouseOut={() => setPeek(false)}
									onMouseUp={() => setPeek(false)}
								>
									<FAIcon name="eye" />
								</Button>
							</InputGroup.Append>
						</InputGroup>
						{errorMessage && (
							<HelpBlock>
								<span className="text-danger">{errorMessage}</span>
							</HelpBlock>
						)}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<FormGroup>
						<ul className="PasswordPrompt__requirements">
							{validationStatus.map((status, index) => {
								const copy = [
									'12-50 characters',
									'At least one number',
									'At least one special character',
									'At least one capital letter',
									'At least one lowercase letter',
									<>
										Only{' '}
										<OverlayTrigger
											placement="bottom"
											overlay={validCharacterTooltip}
										>
											<span className="PasswordPrompt__tooltip-link">
												valid characters
											</span>
										</OverlayTrigger>
									</>,
									'No spaces',
								];
								return (
									<li
										key={index}
										className={`PasswordPrompt__requirement-item${
											status ? '--fulfilled' : ''
										}`}
									>
										{copy[index]}
									</li>
								);
							})}
						</ul>
					</FormGroup>
				</Col>
			</Row>
		</>
	);
};

export default PasswordPrompt;
