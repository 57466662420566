import React from 'react';
import { Alert, Col, Form, FormGroup, Row } from 'react-bootstrap';

import AsperaFileUpload from '../../../components/AsperaFileUpload/AsperaFileUpload';
import BaseModal from '../../../components/BaseModal/BaseModal';
import DatePicker from '../../../components/DatePicker/DatePicker';
import HelpBlock from '../../../components/HelpBlock/HelpBlock';
import {
	allowedFileExtensions,
	contentTypesConst,
	fileTransferConst
} from '../../../constants/fileTransferConstants';
import { transferRightsConstants } from '../../../constants/transferRightsConstants';
import { createProductTransferRequest } from '../../../services/productTransfersService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';


export default class TransferOwnershipModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			display: true,
			isLoading: false,
			retailFlag: false,
			digitalFlag: false,
			isFilesSelected: false,
			isUploadInitiated: false,
			invalidFileType: false,
			productTransferId: null,
			expectedTransferDate: null,
			isExpectedDateValid: false,
			expectedDateMessage: null,
			selectedFiles: null
		};

		this.toggleLoading = this.toggleLoading.bind(this);
		this.updateFilesSelected = this.updateFilesSelected.bind(this);
		this.validateFileType = this.validateFileType.bind(this);
		this.viewRequest = this.viewRequest.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading
		});
	}

	toggleRetailRight() {
		this.setState({
			retailFlag: !this.state.retailFlag
		});
	}

	toggleDigitalRight() {
		this.setState({
			digitalFlag: !this.state.digitalFlag
		});
	}

	updateFilesSelected(isFilesSelected) {
		this.setState({
			isFilesSelected: isFilesSelected
		});
	}

	handleDateChange(date) {
		let expectedDateValid = true;
		let expectedDateMessage = '';

		if (!date) {
			expectedDateValid = false;
			expectedDateMessage = 'Date is required';
		} else {
			date = new Date(date+'T00:00:00');
		}

		let today = new Date();
		today.setHours(0,0,0,0);
		if (date &&
			date < today) {
			expectedDateValid = false;
			expectedDateMessage = 'Date can not be in the past';
		}

		this.setState({
			expectedTransferDate: date,
			isExpectedDateValid: expectedDateValid,
			expectedDateMessage: expectedDateMessage
		});
	}

	viewRequest() {
		this.props.history.push('/admin/product-ownership-transfer-requests/');
	}

	validateFileType(selectedFiles) {
		const allowedContentTypes = [contentTypesConst.DOC, contentTypesConst.DOCX, contentTypesConst.PDF];
		let invalid = false;
		if (allowedContentTypes) {
			selectedFiles.forEach((file) => {
				if (!allowedContentTypes.includes(file.content_type)) {
					invalid = true;
				}
			});
		}
		this.setState({
			invalidFileType: invalid,
			selectedFiles: !invalid && selectedFiles
		});
	}

	createOwnershipTransferRequest() {
		const { productSummary } = this.props;
		const { retailFlag, digitalFlag, expectedTransferDate, selectedFiles } = this.state;
		let payload = {
			product_id: productSummary.product_id,
			retail_pub_flag: retailFlag,
			digital_pub_flag: digitalFlag,
			transfer_type: transferRightsConstants.TRANSFER_TYPE.OWNERSHIP,
			expected_transfer_date: expectedTransferDate,
			files: selectedFiles
		};

		this.toggleLoading();
		createProductTransferRequest(payload)
			.then((response) => {
				let productTransferId = response.data.product_transfer_id.toString();
				this.setState({
					productTransferId: productTransferId,
					isUploadInitiated: true,
					prefetchedTransferSpecs: response.data.transfer_specs && {
						transfer_specs: response.data.transfer_specs
					}
				});
				toasterNotify('Created ownership transfer request', 'success');
			})
			.catch((error) => {
				toasterNotify(createMessageForError(error, 'creating ownership transfer request'), 'error', error);
				this.setState({
					isLoading: false
				});
			});
	}

	doesProductOwnerHoldDistributionRights() {
		const { productSummary } = this.props;

		const ownershipAndDistribution = productSummary.distribution;

		// TODO: If the find function returns null, this line will cause an exception.
		const productOwner = ownershipAndDistribution.find((distribution) => {
			return (
				distribution.right_type ===
				transferRightsConstants.TRANSFER_TYPE.OWNERSHIP
			);
		}).company_name;

		return !ownershipAndDistribution.some(
			(e) => e.company_name !== productOwner
		);
	}

	getAlert() {
		if (this.doesProductOwnerHoldDistributionRights()) {
			return (
				'You are requesting to transfer the entire product\n' +
				'ownership to another publishing company. This will\n' +
				'sever all publishing rights with Nintendo related to\n' +
				'this title.'
			);
		} else {
			return (
				'One or more rights of this product are currently\n ' +
				'held by a different publisher. Revoking these rights\n' +
				'is required in order to proceed with your ownership\n ' +
				'transfer'
			);
		}
	}

	disableSubmitTransferButton() {
		const { isFilesSelected, isExpectedDateValid, isLoading, invalidFileType } = this.state;

		const productOwnerHoldsDistributionRights = this.doesProductOwnerHoldDistributionRights();

		return (
			isLoading ||
			!productOwnerHoldsDistributionRights ||
			!isFilesSelected ||
			invalidFileType ||
			!isExpectedDateValid
		);
	}

	render() {
		const {
			display,
			isUploadInitiated,
			productTransferId,
			expectedTransferDate,
			isExpectedDateValid,
			expectedDateMessage,
			prefetchedTransferSpecs
		} = this.state;
		const { closeModal, productSummary, userProfile } = this.props;
		const datePickerFormat = 'MM/dd/yyyy';

		return (
			<BaseModal
				show={display}
				className="TransferOwnershipModal"
				onCancel={closeModal}
				isSubmitting={this.state.isLoading}
			>
				<BaseModal.Title>Transfer Ownership</BaseModal.Title>
				<Alert variant="danger">{this.getAlert()}</Alert>
				<Form onSubmit={(e) => {
					if (!this.disableSubmitTransferButton()) {
						this.createOwnershipTransferRequest();
					}
					e.preventDefault();
					return false;
				}}>
					<FormGroup as={Row}>
						<label className="col-sm-4 control-label text-sm-right">
							Platform
						</label>
						<div className="col-sm-7 control-value">
							{productSummary.platform_name}
						</div>
					</FormGroup>
					<FormGroup as={Row}>
						<label className="col-sm-4 control-label text-sm-right">
							Product Name
						</label>
						<div className="col-sm-7 control-value">
							{productSummary.game_name}
						</div>
					</FormGroup>
					<FormGroup as={Row}>
						<label className="col-sm-4 control-label text-sm-right">
							Transfer Ownership Agreement(s)
						</label>
						<div className="col-sm-7 control-value">
							<AsperaFileUpload
								updateFilesSelected={this.updateFilesSelected}
								userProfile={userProfile}
								entityType={fileTransferConst.ENTITY_TYPE.PRODUCT_TRANSFER}
								entityId={productTransferId}
								isUploadInitiated={isUploadInitiated}
								onCompleteFunction={this.viewRequest}
								validateFileType={this.validateFileType}
								allowedFileTypes={[
									{
										filter_name: 'Accepted Files',
										extensions: allowedFileExtensions.PRODUCT_TRANSFER,
									},
								]}
								prefetchedTransferSpecs={prefetchedTransferSpecs}
							/>
							<HelpBlock>PDF, DOC, DOCX Only</HelpBlock>
						</div>
					</FormGroup>
					<FormGroup as={Row}>
						<label className="col-sm-4 control-label text-sm-right">
							Expected Transfer Date
						</label>
						<Col sm={7}>
							<DatePicker
								fixedHeight
								dropdownMode="select"
								value={expectedTransferDate}
								dateFormat={datePickerFormat}
								onChange={this.handleDateChange.bind(this)}
							/>
							{!isExpectedDateValid && (
								<HelpBlock>
									<span className="text-danger">
										{expectedDateMessage}
									</span>
								</HelpBlock>
							)}
						</Col>
					</FormGroup>
				</Form>
				<BaseModal.Submit
					disabled={this.disableSubmitTransferButton()}
					onClick={() => this.createOwnershipTransferRequest()}
				>
					Submit Transfer
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}
