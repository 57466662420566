export const announcementConstants = {
	STATUS: {
		SCHEDULED: 1,
		ACTIVE: 2,
		EXPIRED: 3,
	},
	PRIORITY: {
		CRITICAL: 'CRITICAL',
		MAJOR: 'MAJOR',
		MINOR: 'MINOR'
	}
};