import React, { VFC, useState } from 'react';
import { Alert, Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import ActionLink from '../../../components/ActionLink/ActionLink';
import FAIcon from '../../../components/FAIcon/FAIcon';
import Loading from '../../../components/Loading/Loading';
import { assetConst } from '../../../constants/assetConstants';
import { useUserProfile } from '../../../hooks/reduxHooks';
import { getProductAssets } from '../../../services/productsService';
import { makeKey } from '../../../utils/componentUtils';
import { formatLocale } from '../../../utils/localeUtils';
import { canAddAssets } from '../ProductProfile.helpers';
import { compareDates, dateFormat, formatDate, parseDateString } from '../../../utils/dateUtils';
import { formatAssetType } from '../../../utils/assetUtils';
import { displayString } from '../../../utils/stringUtils';
import AssetUploadModal from '../modals/AssetUploadModal';
import StatusText from '../../../components/StatusText/StatusText';

import asset_no_preview from '../../../images/asset-nopreview.png';


const sortAssets = (
	a: { asset_type?: typeof assetConst.TYPE[keyof typeof assetConst.TYPE]; submitted_date?: string },
	b: { asset_type?: typeof assetConst.TYPE[keyof typeof assetConst.TYPE]; submitted_date?: string },
) => {
	if (a.asset_type && b.asset_type) {
		const { LABEL, SWITCH_DLC_INSERT, TITLE_SHEET } = assetConst.TYPE;
		if (a.asset_type === LABEL && b.asset_type !== LABEL) { return -1; }
		if (a.asset_type !== LABEL && b.asset_type === LABEL) { return 1; }
		if (a.asset_type === TITLE_SHEET && b.asset_type !== TITLE_SHEET) { return -1; }
		if (a.asset_type !== TITLE_SHEET && b.asset_type === TITLE_SHEET) { return 1; }
		if (a.asset_type === SWITCH_DLC_INSERT && b.asset_type !== SWITCH_DLC_INSERT) { return -1; }
		if (a.asset_type !== SWITCH_DLC_INSERT && b.asset_type === SWITCH_DLC_INSERT) { return 1; }
	}

	if (a.submitted_date || b.submitted_date) {
		const compared = compareDates(a.submitted_date || 0, b.submitted_date || 0, false);
		if (compared !== 0) return compared * -1;
	}

	if ((a.asset_type || '') < (b.asset_type || '')) return -1;
	if ((a.asset_type || '') > (b.asset_type || '')) return 1;
	return 0;
};

interface AssetsTabProps {
	isSelected: boolean;
	product: ProductData;
	readOnly: boolean;
	category: typeof assetConst.CATEGORY[keyof typeof assetConst.CATEGORY];
}
interface UploadModalModel {
	type?: 'ASSET_UPLOAD_MODAL';
	productId?: number;
	category?: typeof assetConst.CATEGORY[keyof typeof assetConst.CATEGORY];
	isSubmitting?: boolean;
}
const AssetsTab: VFC<AssetsTabProps> = ({ isSelected, product, readOnly, category }) => {
	const userProfile = useUserProfile();
	const assetsQuery = useQuery(
		['getProductAssets', product?.product_id, category],
		() => getProductAssets(String(product?.product_id), category),
		{ enabled: product && isSelected }
	);
	const assetsOutput = assetsQuery.data?.data || [];
	type ProductAssetData = typeof assetsOutput[number];
	const [openModal, setOpenModal] = useState<UploadModalModel | null>(null);

	const isLoading = assetsQuery.isLoading;
	const fault = assetsQuery.isError && assetsQuery.error;
	const assets = assetsQuery.isSuccess ? [...assetsQuery.data.data].sort(sortAssets) : [];

	const categoryLabel = category === assetConst.CATEGORY.MARKETING ?
		'marketing' :
		category === assetConst.CATEGORY.PACKAGING ?
			'packaging' :
			category === assetConst.CATEGORY.CODE_IN_BOX ?
				'code-in-box' :
				'';
	const buttonLabel = category === assetConst.CATEGORY.MARKETING ?
		'Upload Marketing Asset for Approval' :
		category === assetConst.CATEGORY.PACKAGING ?
			'Upload Packaging/Label Asset for Approval' :
			category === assetConst.CATEGORY.CODE_IN_BOX ?
				'Upload Code-In-Box Asset for Approval' :
				'';

	const hasUploadPermissions = !!product && canAddAssets(userProfile, product, category);

	const getAssetThumbnailUrl = (asset: ProductAssetData) =>
		asset.asset_thumbnail_file_id ? `${global.config.sfsUrl}/files/${asset.asset_thumbnail_file_id}?thumbnail=true` : undefined;

	const showRequestToForgoAlert =
		category === assetConst.CATEGORY.PACKAGING &&
		assets.some((asset) => asset.asset_type === assetConst.TYPE.LABEL
			&& asset.final_label_forgo_status === assetConst.FINAL_LABEL_FORGO_STATUS.REQUEST_FORGO);

	const showCodeInBoxBanner =
		category === assetConst.CATEGORY.PACKAGING &&
		!readOnly &&
		!!product &&
		canAddAssets(userProfile, product, assetConst.CATEGORY.CODE_IN_BOX) &&
		hasUploadPermissions;

	return (
		<>
			{isLoading ? (
				<Loading />
			) : (
				<>
					{showRequestToForgoAlert && (
						<Alert variant="info">
							A request to Forgo Final Label Approval has been sent by Nintendo
						</Alert>
					)}
					{showCodeInBoxBanner && (
						<Alert variant="info">
							Do not use this tab to submit packaging for Code-in-Box releases. Please use the Code-In-Box Assets tab for accurate tracking.
						</Alert>
					)}
					{hasUploadPermissions && (
						<Button
							type="button"
							onClick={(e) => {
								setOpenModal({
									type: 'ASSET_UPLOAD_MODAL',
									productId: product.product_id,
									category,
								});
							}}
							disabled={readOnly}
						>
							<FAIcon name="upload" className="mr-1" />
							{buttonLabel}
						</Button>
					)}
					{fault ? (
						<Alert className="mt-3" variant="danger">
							There was an error while loading {categoryLabel} assets data.{' '}
							<ActionLink onClick={() => assetsQuery.refetch()}>
								Retry load
							</ActionLink>
							<FAIcon name="chevron-right" size="xs" />
						</Alert>
					) : (
						<Table className="ProductProfile__asset-table mt-2" striped hover>
							<thead>
								<tr>
									<th style={{ width: '10rem' }}>Asset</th>
									<th>File(s)</th>
									<th style={{ width: '12%' }}>Type</th>
									<th style={{ width: '12%' }}>Asset Language(s)</th>
									<th style={{ width: '8rem' }}>Upload Date</th>
									<th style={{ width: '15%' }}>Status</th>
									<th style={{ width: '3rem' }}></th>
								</tr>
							</thead>
							{assets?.length ? (
								<tbody>
									{assets?.map((asset, index) => (
										<tr key={index}>
											<td>
												{asset.asset_thumbnail_file_id && (
													<img
														src={getAssetThumbnailUrl(asset)}
														alt="No preview available."
													/>
												)}
												{!asset.asset_thumbnail_file_id && asset.most_recent_files && (
													<img
														src={asset_no_preview}
														alt="No preview available."
													/>
												)}
												{!asset.asset_thumbnail_file_id && !asset.most_recent_files && (
													<FAIcon name="exclamation-triangle" />
												)}
											</td>
											<td>
												<ul>
													{asset.most_recent_files &&
														asset.most_recent_files.length > 0 &&
														asset.most_recent_files.map((file, index) => (
															<li key={makeKey('li-', index)}>
																{file.file_name}
															</li>
														))
													}
												</ul>
											</td>
											<td>
												{formatAssetType(asset.asset_type)}
												{asset.asset_type_other_name
													? ' - ' + asset.asset_type_other_name
													: ''}
											</td>
											<td>
												<div>
													{asset.languages?.map((lang) => {
														return (
															<div key={makeKey('li-', lang)}>
																{formatLocale(lang)}
															</div>
														);
													})}
												</div>
											</td>
											<td>
												{formatDate(
													parseDateString(asset.submitted_date),
													dateFormat.DATE,
												)}
											</td>
											<td>
												<StatusText>
													{displayString(asset.review_status, [
														'DRI',
														'TV',
														'NCL',
													])}
												</StatusText>
											</td>
											<td>
												{asset.asset_id > 0 && (
													<Link
														to={
															'/products/' +
															product.product_id +
															'/assets/' +
															asset.asset_id
														}
													>
														<FAIcon name="chevron-right" />
													</Link>
												)}
											</td>
										</tr>
									))}
								</tbody>
							) : (

								<tbody><tr><td className="text-center" colSpan={7}><i>No {categoryLabel} assets submitted</i></td></tr></tbody>

							)}

						</Table>
					)}
					<AssetUploadModal
						show={openModal?.type === 'ASSET_UPLOAD_MODAL'}
						category={openModal?.category}
						productId={openModal?.productId}
						closeModal={() => setOpenModal({...openModal, type: undefined})}
						onSubmitComplete={() => assetsQuery.refetch()}
					/>
				</>
			)}
		</>
	);
};

export default AssetsTab;
