import React, { VFC, useEffect, useState } from 'react';
import * as yup from 'yup';

import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import AsperaFileUpload from '../../../components/AsperaFileUpload/AsperaFileUpload';
import { allowedFileExtensions, fileTransferConst } from '../../../constants/fileTransferConstants';
import { doAllFilesHaveExtension, fileNameHasGameCode } from '../../../utils/assetUtils';
import { assetConst } from '../../../constants/assetConstants';
import { postAssetStatus } from '../../../services/productsService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { validateToSchema } from '../../../utils/validationUtils';
import { isEmptyObject } from '../../../utils/dataUtils';

const GAME_CODE_PLACEHOLDER = '%GAME_CODE%';

const schema = yup.object().shape({
	files: yup.array()
		.test(
			'game_code_check',
			`Selected file must include the game code (${GAME_CODE_PLACEHOLDER}) in the file's name`,
			(value: any, { options }: yup.TestContext<any>) =>
				value?.every(
					(item: AsperaFile) =>
						fileNameHasGameCode(item.file_name, options?.context?.gameCode),
				),
		)
		.test( 
			'length_check',
			'At least one file must be selected',
			(value) => !!value && value.length > 0
		),
});

interface SubmitForApprovalModalProps {
    show: boolean;
    onClose: () => void;
	onSuccess: () => void;
	assetId?: number;
	gameCode?: string;
}
const SubmitForApprovalModal:VFC<SubmitForApprovalModalProps> = ({ show, onClose, assetId, gameCode, onSuccess }) => {
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [formValues, setFormValues] = useState<{ comment?: string }>({});
	const [isUploadInitiated, setIsUploadInitiated] = useState<boolean>(false);
	const [transferSpecs, setTransferSpecs] = useState<{ transfer_specs: AsperaUploadSpec[] }>();
	const [selectedFiles, setSelectedFiles] = useState<AsperaFile[]>();
	const [revisionId, setRevisionId] = useState<number>();
	const [showAllErrors, setShowAllErrors]  = useState<boolean>(false);

	useEffect(() => {
		if (show) {
			setFormValues({});
			setIsUploadInitiated(false);
			setTransferSpecs(undefined);
			setRevisionId(undefined);
			setSelectedFiles(undefined);
			setShowAllErrors(false);
		}
	}, [show]);

	const submitForm = async () => {
		if (!isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			return;
		}

		setIsSubmitting(true);

		const payload = {
			review_status: assetConst.STATUS.PRINT_PROOF_UPLOADING,
			file_upload_type: assetConst.UPLOAD.PROOF,
			comment: formValues.comment,
			files: selectedFiles,
		};
		try {
			const response = await postAssetStatus(String(assetId), payload);
			setTransferSpecs({
				transfer_specs: response.data.transfer_specs,
			});
			setRevisionId(response.data.revision_id);
			setIsUploadInitiated(true);
			onSuccess();
			onClose();
			toasterNotify('Proof has been submitted for approval', 'info');
		} catch (error: unknown) {
			if (error instanceof Error && 'isAxiosError' in error && error.isAxiosError) {
				toasterNotify(
					createMessageForError(error, 'submitting file(s)'),
					'error',
					error
				);
			} else {
				throw error;
			}
		} finally {
			setIsSubmitting(false);
		}
	};

	const validationErrors = validateToSchema(schema,  { ...formValues, files: selectedFiles }, { gameCode });
	if (validationErrors.files && gameCode) {
		validationErrors.files = String(validationErrors.files.replace(GAME_CODE_PLACEHOLDER, gameCode));
	}

	return (
        <BaseModal show={show} onCancel={onClose} isSubmitting={isSubmitting}>
            <BaseModal.Title>Submit Label for Approval</BaseModal.Title>
                <Forms
                    values={formValues}
                    onChange={(newValues) => setFormValues(newValues)}
					validationErrors={validationErrors}
					showAllErrors={showAllErrors}
                >
                    <Forms.CustomArea id="files">
                        <Forms.Heading>Print Proof File</Forms.Heading>
                        <AsperaFileUpload
							updateFilesSelected={() => null}
							entityType={fileTransferConst.ENTITY_TYPE.PRODUCT_ASSET_REVISION}
							entityId={revisionId}
							isUploadInitiated={isUploadInitiated}
							onCompleteFunction={() => 0}
							allowedFileTypes={[
								{
									filter_name: 'Accepted Files',
									extensions: allowedFileExtensions.FINAL_LABEL,
								},
							]}
							validateFileType={(files: AsperaFile[]) => {
								setSelectedFiles(files);
								const allowedFileTypes = allowedFileExtensions.PACKAGING_ASSET;
								if (doAllFilesHaveExtension(files, allowedFileTypes)) {
									return true;
								}
								return false;
							}}
							prefetchedTransferSpecs={transferSpecs}
						/>
						<Forms.Help>{`Selected file must include the game code (${gameCode}) in the file's name`}</Forms.Help>
                    </Forms.CustomArea>
                    <Forms.TextArea id="comment" rows={4}>
                        <Forms.Heading>Approval Comments</Forms.Heading>
						<Forms.Help>Optional</Forms.Help>
                    </Forms.TextArea>
                </Forms>
            <BaseModal.Submit onClick={() => submitForm()}>Submit</BaseModal.Submit>
        </BaseModal>
	);
};
export default SubmitForApprovalModal;
