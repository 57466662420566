import React, { VFC } from 'react';

import { isInvalid } from '../../../utils/dataUtils';
import { getReasonForInvalidValue } from '../../../utils/digitalCodesUtils';

interface InvalidDataErrorDisplayProps {
	subject: string;
	value?: InvalidData | string | number | null;
}
const InvalidDataErrorDisplay: VFC<InvalidDataErrorDisplayProps> = ({ subject, value }) => {
	const invalidValueObject = isInvalid(value);
	if (invalidValueObject) {
		return (
			<div className="text-danger">
				<small>{getReasonForInvalidValue(subject, invalidValueObject) || null}</small>
			</div>
		);
	}
	return null;
};

export default InvalidDataErrorDisplay;
