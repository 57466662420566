import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { actionsColumn } from '../../components/BaseTable/BaseTable';
import FilterableTable from '../../components/FilterableTable/FilterableTable';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import DeleteConfirmationModal from '../../components/modals/DeleteConfirmationModal/DeleteConfirmationModal';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import { permConst } from '../../constants/permConst';
import { getComponentOrphans, putComponentParent } from '../../services/digitalCodesService';
import { pullAllResults } from '../../utils/serviceUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { isAuthorized } from '../../utils/userUtils';
import SetParentModal from '../ComponentsWithoutProducts/modals/SetParentModal';

const SET_PARENT_MODAL = 'SET_PARENT_MODAL';
const RELEASE_MODAL = 'RELEASE_MODAL';

function getTableStructure({ userProfile, setOpenModal }) {
	const canEdit = isAuthorized(userProfile.permissions, [permConst.PRODUCT.COMPONENT.PARENT.EDIT.ALL]);

	const ActionsCell = ({ original }) => {
		return (
			<MeatballDropdown>
				{canEdit && original.parent_ns_uid && (
					<MeatballDropdown.Item
						key={`releaseParent-${original.part_number}`}
						onClick={(e) =>
							setOpenModal({
								type: RELEASE_MODAL,
								componentNsUid: original.ns_uid,
								componentDisplayName: `[${original.game_code.substring(4,9)}] ${original.game_name}`,
								parentNsUid: original.parent_ns_uid,
								parentDisplayName: `[${original.parent_game_code.substring(4,9)}] ${original.parent_game_name}`
							})
						}>
						<span className="text-danger">Release Parent</span>
					</MeatballDropdown.Item>
				)}
				{canEdit && !original.parent_ns_uid && (
					<MeatballDropdown.Item
						key={`setParent-${original.part_number}`}
						onClick={(e) =>
							setOpenModal({
								type: SET_PARENT_MODAL,
								orphan: original,
							})
						}>
						Set Parent
					</MeatballDropdown.Item>
				)}
			</MeatballDropdown>
		);
	};

	const tableConfiguration = [
		{ Header: 'NSUID', accessor: 'ns_uid' },
		{ Header: 'Component Name', accessor: 'game_name' },
		{ Header: 'Game Code', accessor: 'game_code' },
		{ Header: 'Parent NSUID', accessor: 'parent_ns_uid' },
		{ Header: 'Parent Product', accessor: 'parent_game_name' },
		{ Header: 'Parent Game Code', accessor: 'parent_game_code' },
		{
			...actionsColumn,
			Cell: ActionsCell,
		},
	];

	return tableConfiguration;
}

function getFilterProperties() {
	const filterProperties = new Map();
	filterProperties.set('Parent Status', {
		filter: 'parentStatus',
		selectableFilters: new Set(['With Parent', 'Without Parent']),
	});
	return filterProperties;
}

const ComponentsWithoutProducts = ({ userProfile }) => {
	const queryClient = useQueryClient();
	const [openModal, setOpenModal] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState();

	const orphansQuery = useQuery('pullAllResults:getComponentOrphans', () => pullAllResults(getComponentOrphans, 5000));
	const orphans = orphansQuery.isSuccess && orphansQuery.data.map((component) => {
		return {...component, parentStatus: component.parent_ns_uid ? 'With Parent' : 'Without Parent'};
	});

	const dataFormat = getTableStructure({
		userProfile,
		setOpenModal,
	});

	const releaseParent = async (componentNsUid, componentDisplayName, parentDisplayName) => {
		try {
			await putComponentParent(componentNsUid, {'parent_ns_uid': null});
			toasterNotify(`Parent component "${parentDisplayName}" was released from "${componentDisplayName}"`, 'success');
			setOpenModal(null);
			queryClient.invalidateQueries('pullAllResults:getComponentOrphans');
		} catch (error) {
			toasterNotify(createMessageForError(error, 'releasing parent'), 'error', error);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<div>
			<Page isLoading={orphansQuery.isLoading}>
				<Title title='Components Without Products' />
					<FilterableTable
						data={orphans}
						dataFormat={dataFormat}
						filterProperties={getFilterProperties()}
						searchableFields={['ns_uid', 'game_name', 'parent_game_name', 'game_code', 'parent_game_code']}
						searchableFieldPlaceHolder="Search NSUID, Component Name, Game Code"
						defaultSorted={[]}
					/>
				<SetParentModal
					orphanComponent={openModal && openModal.orphan }
					show={openModal && openModal.type === SET_PARENT_MODAL}
					closeModal={() => setOpenModal({...openModal, type: null})}
					onSubmitted={() => queryClient.invalidateQueries('pullAllResults:getComponentOrphans')}
				/>
				<DeleteConfirmationModal
					show={openModal && openModal.type === RELEASE_MODAL}
					closeModal={() => setOpenModal(null)}
					confirmDelete={() =>
						releaseParent(openModal.componentNsUid, openModal.componentDisplayName, openModal.parentDisplayName)
					}
					isSubmitting={isSubmitting}
					title="Release Parent"
					message={`Do you want to release parent "${openModal && openModal.parentDisplayName}" from component "${openModal && openModal.componentDisplayName}"?`}
					deleteLabel={'Release Parent'}
				/>
			</Page>
		</div>
	);
};

export default ComponentsWithoutProducts;
