import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { fileTransferConst } from '../../../constants/fileTransferConstants';
import { toProperCase } from '../../../utils/stringUtils';

const EventTaskAttachmentLinkCell = ({ original }) => {
	if (!original.attachment_file_name) { return null; }
	return (
		<>
			<OverlayTrigger
				placement="right"
				overlay={
					<Tooltip>
						<div className="text-left">
							<strong>Uploaded by:</strong><br/>
							{original.attachment_created_by_name}<br/>
							{original.attachment_upload_status === fileTransferConst.TRANSFER_STATES.COMPLETED ?
								<span className='badge badge-success mt-2'>{toProperCase(original.attachment_upload_status)}</span>
								:
								<span className='badge badge-warning mt-2'>{toProperCase(original.attachment_upload_status)}</span>
							}
						</div>
					</Tooltip>
				}
			>
				<a href={`${global.config.apiUrl}/marketing/events/tasks/${original.marketing_event_task_id}/attachments`}
				   download={original.attachment_file_name}>
					{original.attachment_file_name}
				</a>
			</OverlayTrigger>
		</>
	);
};

export default EventTaskAttachmentLinkCell;
