import React, { Component } from 'react';
import { Alert, Badge, Button, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FAIcon from '../../components/FAIcon/FAIcon';
import Loading from '../../components/Loading/Loading';
import LoadingText from '../../components/Loading/LoadingText';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import Page from '../../components/Page/Page';
import ProFormaInvoice from '../../components/ProFormaInvoice/ProFormaInvoice';
import PropertyDisplay from '../../components/PropertyDisplay/PropertyDisplay';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import StatusText from '../../components/StatusText/StatusText';
import Title from '../../components/Title/Title';
import { orderConst } from '../../constants/orderConstants';
import { permConst } from '../../constants/permConst';
import NotFound from '../../containers/NotFound/NotFound';
import { getCompanyProfile } from '../../services/companiesService';
import {
	getAssociatedPackoutFacilities,
	getCreateOrderPageResources,
	getOrder,
	getPrice
} from '../../services/ordersService';
import { getUsers } from '../../services/usersService';
import { formatCurrency, numberWithCommas } from '../../utils/currencyUtils';
import { dateFormat, formatDate, parseDateString } from '../../utils/dateUtils';
import { displayOrderStatus } from '../../utils/orderUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { isAuthorized } from '../../utils/userUtils';
import EditOrderItemVersionModal from './modals/EditOrderItemVersionModal';
import EditOrderModal from './modals/EditOrderModal';
import OrderReviewModal from './modals/OrderReviewModal';
import ShipmentsModal from './modals/ShipmentsModal';


export const APPROVE = 'APPROVE';
export const REJECT = 'REJECT';

function mapStateToProps(state) {
	return {
		userProfile: state.authReducer.userProfile
	};
}

function generateDataField(header, value) {
	return (
		<PropertyDisplay label={header}>{value}</PropertyDisplay>
	);
}

function generateOrderDetails(details) {
	if (details && details.length > 0) {
		return details.map((detail) => {
			detail.initial_order = detail.initial_order === 1;
			return detail;
		});
	}
}

function generatePackoutAddress(data) {
	return `${data.packout_street_address}, ${data.packout_city}, ${data.packout_region}`;
}

export class OrderReview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fault: null,
			order_header_id: '',
			platform_code: '',
			publisher_po_number: '',
			order_comments: [],
			order_status: '',
			ndid_company_id: '',
			order_details: [],
			company_name: '',
			order_error: null,

			newPrices: {},
			pricesLoadedCount: 0,

			created_by_ndid: '',
			created_by_user_name: '',
			created_by_company_name: '',
			created_by_date: '',
			modified_by_ndid: '',
			modified_by_user_name: '',
			modified_by_company_name: '',
			modified_by_date: '',

			freight_forwarder_code: '',
			port_of_entry_code: '',
			packout_facility_id: '',
			packout_facility_contact_name: '',
			packout_facility_contact_phone_number: '',
			commentsPoApprover: '',
			commentsNcl: '',
			approvalPOcomment: '',
			freight_forwarder_name: '',
			port_of_entry_name: '',

			japaneseContactName: '',
			japaneseEmail: '',
			consigneeCompanyName: '',
			consigneeAddress: '',
			consigneeCity: '',
			consigneeState: '',
			consigneeZip: '',
			serviceLevel: '',
			carrierAccountNumber: '',

			freightForwarders: [],
			customsEntryPorts: [],
			shipTos: [],
			pageMode: '', // Is either === '' or APPROVE or REJECT.
			getOrderApiPending: true,
			getPageResourcesApiPending: true,
			getCompanyProfilePending: true,
			getPackoutFacilityPending: true,
			freightForwardersRequiringConsignees: [],
			showModal: false,
			showEbsFields: false,
			selectedProduct: null,

			users: null,
			showStandardPriceWarning: false,
			showEBSPriceWarning: false,
		};
		this.changePageMode = this.changePageMode.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.ableToEdit = this.ableToEdit.bind(this);
		this.ableToCancel = this.ableToCancel.bind(this);
		this.ableToSeeEmail = this.ableToSeeEmail.bind(this);
		this.ableToSendEmail = this.ableToSendEmail.bind(this);
		this.requiresConsignee = this.requiresConsignee.bind(this);
		this.getPayloadStatus = this.getPayloadStatus.bind(this);
		this.getOrder = this.getOrder.bind(this);
		this.getCreateOrderPageResources = this.getCreateOrderPageResources.bind(
			this
		);
		this.getCommentByType = this.getCommentByType.bind(this);
		this.updateOrderDetailVersion = this.updateOrderDetailVersion.bind(
			this
		);
		this.bundlePriceCalls = this.bundlePriceCalls.bind(this);
		this.determineShowEbsFields = this.determineShowEbsFields.bind(this);
		this.determineShowError = this.determineShowError.bind(this);
		this.renderAllUsersEmailLink = this.renderAllUsersEmailLink.bind(this);
	}

	componentDidMount() {
		this.getOrder();
	}

	determineShowEbsFields() {
		const { userProfile } = this.props;
		const statuses = [
			orderConst.STATUS.SUBMITTED_FOR_APPROVAL,
			orderConst.STATUS.SUBMITTED_FOR_REVIEW,
			orderConst.STATUS.DRAFT
		];
		let authorized = isAuthorized(userProfile.permissions, [
			permConst.ORDER.VIEW.ALL
		]);
		let statusCheck = !statuses.includes(this.state.order_status);
		this.setState({
			showEbsFields: authorized && statusCheck
		});
	}

	determineShowError() {
		const orderError = this.state.order_error;
		return (
			isAuthorized(this.props.userProfile.permissions, [
				permConst.ORDER.VIEW.ALL
			]) &&
			this.state.order_status ===
				orderConst.STATUS.SUBMITTED_FOR_APPROVAL &&
			orderError !== null &&
			orderError['error_message'] !== undefined
		);
	}

	getPayloadStatus() {
		const { pageMode, order_status } = this.state;
		if (pageMode === APPROVE) {
			return orderConst.STATUS.SUBMITTED_FOR_APPROVAL;
		} else if (pageMode === REJECT) {
			return orderConst.STATUS.REJECTED;
		} else {
			return order_status;
		}
	}

	updateOrderDetailVersion(updatedOrderDetails) {
		this.setState({ order_details: updatedOrderDetails });
	}

	getCommentByType(type) {
		const { order_comments } = this.state;
		let comment = null,
			commentText = '';

		if (order_comments) {
			comment =
				order_comments[
					order_comments.findIndex((x) => x.type === type)
				];
			if (comment) {
				commentText = comment['comment'];
			}
		}
		return commentText;
	}

	getOrder() {
		const { match } = this.props;
		const orderId = match.params.orderHeaderId;
		getOrder(orderId)
			.then((response) => {
				const data = response.data;
				this.setState(
					{
						order_header_id: data.order_header_id,
						company_name: data.company_name,
						platform_code: data.platform_code,
						publisher_po_number: data.publisher_po_number,
						order_comments: data.order_comments,
						order_status: data.order_status,
						order_error: data.order_error,
						ndid_company_id: data.ndid_company_id,
						order_details: generateOrderDetails(
							data.order_details
						),
						submitted_by_user_name: data.submitted_by_user_name,
						submitted_by_ndid: data.submitted_by_ndid,
						submitted_by_company_name: data.submitted_by_company_name,
						submission_date: data.submission_date,
						created_by_ndid: data.created_by_ndid,
						created_by_user_name: data.created_by_user_name,
						created_by_company_name:
							data.created_by_company_name,
						created_by_date: data.created_by_date,
						modified_by_ndid: data.modified_by_ndid,
						modified_by_user_name: data.modified_by_user_name,
						modified_by_company_name:
							data.modified_by_company_name,
						modified_by_date: data.modified_by_date,
						port_of_entry_code: data.port_of_entry_code,
						packout_facility_name: data.packout_facility_name,
						packout_facility_address: generatePackoutAddress(
							data
						),
						freight_forwarder_code: data.freight_forwarder_code,
						packout_facility_id: data.packout_facility_id,
						packout_facility_contact_name:
							data.packout_facility_contact_name,
						packout_facility_contact_phone_number:
							data.packout_facility_contact_phone_number,
						japaneseContactName: data.consignee_contact_name,
						japaneseEmail: data.consignee_contact_email,
						japanesePhoneNumber:
							data.consignee_contact_phone_number,
						consigneeAddress:
							data.consignee_address_street_line_1,
						consigneeCompanyName: data.consignee_company_name,
						consigneeCity: data.consignee_address_city,
						consigneeState: data.consignee_address_region,
						consigneeZip: data.consignee_address_postal_code,
						consigneeCountryCode:
							data.consignee_address_country_code,
						serviceLevel: data.consignee_service_level,
						carrierAccountNumber:
							data.consignee_carrier_account_number,
						noa_po: data.noa_po,
						noa_sales_order_number: data.noa_sales_order_number,
						req_number: data.req_number,
						noa_approved_date: data.noa_approved_date,
						getOrderApiPending: false
					},
					() => {
						this.bundlePriceCalls();
						this.determineShowEbsFields();
						this.getCreateOrderPageResources();
						this.getCompanyProfile(data.noa_sales_order_number, data.ndid_company_id);
						this.getPackoutFacilities(data.ndid_company_id);
					}
				);
				if ((this.ableToSeeEmail() || this.ableToSendEmail()) && data.ndid_company_id) {
					this.getPublisherUsers(data.ndid_company_id);
				} else {
					this.setState({users: []});
				}
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'loading order'),
					'error',
					error
				);
				this.setState({
					fault: error,
					getOrderApiPending: false,
					getPageResourcesApiPending: false,
					getCompanyProfilePending: false,
					getPackoutFacilityPending: false
				});
			});
	}

	async getPublisherUsers(companyId) {
		try {
			const response = await getUsers({'role_name': 'Retail Order Management', 'company_id': companyId});
			this.setState({users: response.data});
		} catch (error) {
			toasterNotify(
				createMessageForError(error, 'loading submitter data'),
				'error',
				error
			);
			this.setState({users: []});
		}
	}

	bundlePriceCalls() {
		this.setState({pricesLoadedCount:  0});
		const { order_status, order_details } = this.state;

		if (!order_details) {
			return;
		}

		let priceCalls = [];
		const checkPrices = [
			orderConst.STATUS.DRAFT,
			orderConst.STATUS.SUBMITTED_FOR_APPROVAL,
			orderConst.STATUS.SUBMITTED_FOR_REVIEW
		].includes(order_status);

		if (checkPrices) {
			order_details.forEach((detail) => {
				if (detail.product_id && detail.quantity > 0) {
					priceCalls.push(
						this.getEbsPrice(detail.product_id, detail.quantity)
					);
				}
			});
		}
		if (priceCalls.length > 0) {
			Promise.all(priceCalls);
		} else {
			/* this terminates LoadModal */
			this.setState({
				pricesLoadedCount: this.state.order_details.length
			});
		}
	}

	getEbsPrice(productId, quantity) {
		const { order_header_id } = this.state;
		let unitPrice = 'Error';
		if (productId && quantity > 0) {
			getPrice(productId, order_header_id, quantity)
				.then((response) => {
					unitPrice = response.data.price;
				})
				.catch((error) => {
					toasterNotify(
						createMessageForError(error, 'retrieving the unit price'),
						'error',
						error
					);
				})
				.finally(() => {
					let prices = this.state.newPrices;
					prices[`${productId}`] = unitPrice;
					this.setState({
						newPrices: prices,
						pricesLoadedCount: this.state.pricesLoadedCount + 1
					});
				});
		}
	}

	getCreateOrderPageResources() {
		getCreateOrderPageResources()
			.then((response) => {
				const freightForwarders = response.data.freight_forwarders;
				const customsEntryPorts = response.data.customs_entry_ports;
				const shipTos = response.data.ship_tos;
				const countryCodes = response.data.country_codes;
				this.populateFreightForwardersRequiringConsignee(
					freightForwarders
				);
				this.setState({
					freightForwarders,
					customsEntryPorts,
					shipTos,
					countryCodes,
					getPageResourcesApiPending: false,
					freight_forwarder_name: this.lookupResources(
						freightForwarders,
						'value',
						this.state.freight_forwarder_code,
						'name'
					),
					port_of_entry_name: this.lookupResources(
						customsEntryPorts,
						'value',
						this.state.port_of_entry_code,
						'name'
					),
					consigneeCountryName: this.lookupResources(
						countryCodes,
						'country_code',
						this.state.consigneeCountryCode,
						'country_name'
					)
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving order resources'),
					'error',
					error
				);
			});
	}

	getCompanyProfile(noa_sales_order_number, ndid_company_id) {
		if (
			noa_sales_order_number === undefined ||
			noa_sales_order_number === null ||
			noa_sales_order_number === ''
		) {
			this.setState({
				getCompanyProfilePending: false,
			});
			return;
		}
		getCompanyProfile(ndid_company_id)
			.then((response) => {
				this.setState({
					companyData: response.data,
					getCompanyProfilePending: false
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving company profile'),
					'error',
					error
				);
			});
	}

	getPackoutFacilities(ndid_company_id) {
		getAssociatedPackoutFacilities(ndid_company_id)
			.then((response) => {
				this.setState({
					packoutData: response.data,
					getPackoutFacilityPending: false
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving facilities'),
					'error',
					error
				);
			});
	}

	lookupResources(collection, searchName, searchValue, fieldName) {
		let result = null;
		if (collection && collection.length > 0 && searchValue) {
			collection.some((d) => {
				if (d && d[searchName] === searchValue) {
					if (fieldName) {
						result = d[fieldName];
					} else {
						result = d;
					}
					return true;
				}
				return false;
			});
		}
		return result;
	}

	populateFreightForwardersRequiringConsignee(freightForwarders) {
		const freightForwardersRequiringConsignees = [];
		for (let i = 0; i < freightForwarders.length; i++) {
			if (freightForwarders[i].consignee_required) {
				freightForwardersRequiringConsignees.push(
					freightForwarders[i].value
				);
			}
		}
		this.setState({ freightForwardersRequiringConsignees });
	}

	ableToEdit() {
		const { userProfile } = this.props;
		const { order_status } = this.state;
		return (
			(order_status === orderConst.STATUS.SUBMITTED_FOR_REVIEW &&
				isAuthorized(userProfile.permissions, [
					permConst.ORDER.EDIT.STATUS.SUBMITTED_FOR_APPROVAL
				])) ||
			(order_status === orderConst.STATUS.SUBMITTED_FOR_APPROVAL &&
				isAuthorized(userProfile.permissions, [
					permConst.ORDER.EDIT.STATUS.ORDER_APPROVED
				]))
		);
	}

	ableToCancel() {
		const { userProfile } = this.props;
		const { order_status, ndid_company_id } = this.state;

		return (
			(order_status === orderConst.STATUS.SUBMITTED_FOR_REVIEW ||
				order_status === orderConst.STATUS.SUBMITTED_FOR_APPROVAL) &&
			userProfile.companyId === ndid_company_id &&
			isAuthorized(userProfile.permissions, [
				permConst.ORDER.EDIT.STATUS.CANCELLED
			])
		);
	}

	ableToInvoice() {
		const { noa_sales_order_number, getOrderApiPending, getPageResourcesApiPending, getCompanyProfilePending } = this.state;
		return (getOrderApiPending ||
		        getPageResourcesApiPending ||
			    getCompanyProfilePending ||
		        noa_sales_order_number  === undefined ||
		        noa_sales_order_number === null ||
		        noa_sales_order_number === '' ? false : true);
	}

	ableToSeeEmail() {
		const { userProfile } = this.props;
		return isAuthorized(userProfile.permissions, [
			permConst.ORDER.VIEW.ALL,
		]);
	}

	ableToSendEmail() {
		const { userProfile } = this.props;
		return isAuthorized(userProfile.permissions, [
			permConst.ORDER.EDIT.ALL.SUBMITTED_FOR_APPROVAL,
			permConst.ORDER.EDIT.ALL.SUBMITTED_FOR_REVIEW,
		]);
	}

	packoutIsStillValid() {
		if (!orderConst.VALID_PACKOUT_FACILITY_STATUS_CHECK.includes(this.state.order_status)) {
			return true;
		}
		return this.state.packoutData.find(po => po.packout_facility_id === this.state.packout_facility_id);
	}

	getInvoiceFileName(noa_approved_date, noa_sales_order_number) {
		return 'Proforma  ' + formatDate(parseDateString(noa_approved_date), 'YYYYMMDD') + ' ' + noa_sales_order_number + '.pdf';
	}

	getInvoiceGrandTotal(order_details) {
		let grand_total = 0;
		for(let i in order_details) { grand_total += order_details[i].unit_price * order_details[i].quantity; }
		return grand_total;
	}

	changePageMode(mode) {
		this.setState({ pageMode: mode });
	}

	toggleModal(key, data = null) {
		let type = null;
		if (!this.state.showModal) {
			type = key;
		}
		this.setState({
			showModal: !this.state.showModal,
			modalType: type,
			selectedProduct: data
		});
	}

	renderModal() {
		switch (this.state.modalType) {
			case 'editOrderModal':
				return (
					<EditOrderModal
						orderData={{ ...this.state }}
						ableToEdit={this.ableToEdit}
						requiresConsignee={this.requiresConsignee}
						getPayloadStatus={this.getPayloadStatus}
						userProfile={this.props.userProfile}
						changePageMode={this.changePageMode}
						getOrder={this.getOrder}
						getCreateOrderPageResources={
							this.getCreateOrderPageResources
						}
						toggleModal={this.toggleModal}
						getCommentByType={this.getCommentByType}
					/>
				);
			case 'editOrderItemVersionModal':
				return (
					<EditOrderItemVersionModal
						updateOrderDetailVersions={
							this.updateOrderDetailVersion
						}
						orderDetails={this.state.order_details}
						orderHeaderId={this.state.order_header_id}
						platformCode={this.state.platform_code}
						ndid_company_id={this.state.ndid_company_id}
						userProfile={this.props.userProfile}
						toggleModal={this.toggleModal}
						getOrder={this.getOrder}
					/>
				);
			case 'approveModal':
				return (
					<OrderReviewModal
						toggleModal={this.toggleModal}
						modalType={'approve'}
						orderData={{ ...this.state }}
						requiresConsignee={this.requiresConsignee}
						userProfile={this.props.userProfile}
						getOrder={this.getOrder}
						getCreateOrderPageResources={
							this.getCreateOrderPageResources
						}
						getPayloadStatus={this.getPayloadStatus}
						getCommentByType={this.getCommentByType}
					/>
				);
			case 'rejectModal':
				return (
					<OrderReviewModal
						toggleModal={this.toggleModal}
						modalType={'reject'}
						orderData={{ ...this.state }}
						requiresConsignee={this.requiresConsignee}
						userProfile={this.props.userProfile}
						getOrder={this.getOrder}
						getCreateOrderPageResources={
							this.getCreateOrderPageResources
						}
						getPayloadStatus={this.getPayloadStatus}
						getCommentByType={this.getCommentByType}
					/>
				);
			case 'cancelModal':
				return (
					<OrderReviewModal
						toggleModal={this.toggleModal}
						modalType={'cancel'}
						orderData={{ ...this.state }}
						requiresConsignee={this.requiresConsignee}
						userProfile={this.props.userProfile}
						getOrder={this.getOrder}
						getCreateOrderPageResources={
							this.getCreateOrderPageResources
						}
						getPayloadStatus={this.getPayloadStatus}
						getCommentByType={this.getCommentByType}
					/>
				);
			case 'shipmentsModal':
				return (
					<ShipmentsModal
						toggleModal={this.toggleModal}
						selectedProduct={this.state.selectedProduct}
						userProfile={this.props.userProfile}
					/>
				);
			default:
				return '';
		}
	}

	renderTitleButton() {
		const { userProfile } = this.props;
		const { companyData, noa_approved_date, noa_sales_order_number,	publisher_po_number, order_details } = this.state;
		let showMenu = false;
		if (
			this.state.order_status === orderConst.STATUS.SUBMITTED_FOR_REVIEW
		) {
			showMenu = isAuthorized(this.props.userProfile.permissions, [
				permConst.ORDER.EDIT.ALL.SUBMITTED_FOR_REVIEW
			]);
		} else if (
			this.state.order_status === orderConst.STATUS.SUBMITTED_FOR_APPROVAL
		) {
			showMenu = isAuthorized(this.props.userProfile.permissions, [
				permConst.ORDER.EDIT.ALL.SUBMITTED_FOR_APPROVAL
			]);
		}
		const total = formatCurrency(this.getInvoiceGrandTotal(order_details));
		return (
			<>
				{this.ableToCancel() && (
					<div>
						<Button
							type="button"
							variant="danger"
							id="cancelModal"
							onClick={(e) => this.toggleModal(e.target.id)}
						>
							Cancel
						</Button>
					</div>
				)}

				{this.ableToInvoice() && (
					<ProFormaInvoice
						companyData={companyData.company_information}
						invoiceDate={noa_approved_date}
						orderNumber={noa_sales_order_number}
						poNumber={publisher_po_number}
						orderDetails={order_details.map(order => ({...order, itemName: `[${order.game_code}] ${order.product_name}`}))}
						isDigitalOrder={false}
						subtotal={total}
						grandTotal={total}
						fileName={this.getInvoiceFileName(
							noa_approved_date,
							noa_sales_order_number,
						)}
					/>
				)}
				{this.ableToEdit() && (
					<>
						{this.packoutIsStillValid() && (
							<Button
								type="button"
								variant="success"
								id="approveModal"
								onClick={(e) => this.toggleModal(e.target.id)}
								disabled={
									this.state.order_details.length !== this.state.pricesLoadedCount
								}
							>
								Approve
							</Button>
						)}
						<Button
							type="button"
							variant="danger"
							id="rejectModal"
							onClick={(e) => this.toggleModal(e.target.id)}
							disabled={
								this.state.order_details.length !== this.state.pricesLoadedCount
							}
						>
							Reject
						</Button>
						{showMenu && this.packoutIsStillValid() && (
							<MeatballDropdown
								toggleSize="lg"
								id="dropdown-orders"
								disabled={
									this.state.order_details.length !== this.state.pricesLoadedCount
								}
							>
								<MeatballDropdown.Item
									id="editOrderModal"
									onSelect={() => this.toggleModal('editOrderModal')}
								>
									Edit Order
								</MeatballDropdown.Item>
								{isAuthorized(userProfile.permissions, [
									'order.edit.all.item.version',
								]) && (
									<MeatballDropdown.Item
										id="editOrderItemVersionModal"
										onSelect={() =>
											this.toggleModal('editOrderItemVersionModal')
										}
									>
										Edit Item Version
									</MeatballDropdown.Item>
								)}
							</MeatballDropdown>
						)}
					</>
				)}
			</>
		);
	}

	requiresConsignee(freightForwarder) {
		const { freightForwardersRequiringConsignees } = this.state;
		return freightForwardersRequiringConsignees.includes(freightForwarder);
	}

	renderOrderDetailsTable() {
		const showCardSize = !!(this.state.order_details && 'card_size' in this.state.order_details[0]);
		const showNewIndicator = isAuthorized(
			this.props.userProfile.permissions,
			[permConst.ORDER.VIEW.ALL]
		);

		let totalsQuant = 0;
		let totalsPrice = 0;

		return (
			<div>
				<Table>
					<thead>
						<tr>
							<th>Status</th>

							<th>Items</th>

							<th>Version</th>

							{showCardSize && <th>Card Size</th>}

							<th>Price/Unit</th>

							<th>Requested Ship Date</th>

							<th>Part / SKU</th>

							<th>Quantity</th>

							<th>Price</th>

							<th></th>
						</tr>
					</thead>

					{this.state.order_details && (
						<tbody>
							{this.state.order_details.length !==
							this.state.pricesLoadedCount ? (
								<tr>
									<td colSpan={8}>
										<LoadingText />
									</td>
								</tr>
							) : (
								this.state.order_details.map((detail) => {
									let productId = detail.product_id;
									let displayPrice = this.state.newPrices[
										productId
									]
										? this.state.newPrices[productId]
										: detail.unit_price;
									const priceMatchesDisplayPrice = Number(detail.unit_price) === Number(displayPrice);
									const priceIsStandard = (!detail.standard_price || Number(detail.unit_price) === Number(detail.standard_price));
									if (!priceMatchesDisplayPrice && this.state?.showEBSPriceWarning !== true) {
										this.setState({ showEBSPriceWarning: true });
									}
									if (!priceIsStandard && this.state?.showStandardPriceWarning !== true) {
										this.setState({ showStandardPriceWarning: true });
									}
									let priceClass = priceMatchesDisplayPrice && priceIsStandard
											? 'text'
											: 'text-danger font-weight-bold';
									totalsPrice +=
										displayPrice !== 'Error'
											? displayPrice * detail.quantity
											: totalsPrice;
									totalsQuant += detail.quantity;
									return (
										<tr key={detail.game_code}>
											<td>{detail.status}</td>
											<td>
												{showNewIndicator && detail.initial_order && (
													<span className="badge badge-warning">NEW</span>
												)}
												{`[${detail.game_code}] ${detail.product_name}`}
											</td>
											<td>
												{`${detail.product_release_version}.${detail.submission_version}`}
												{detail.submission_status && (
													<>
														<br />
														<Badge
															variant="light"
															className="border"
														>
															{detail.submission_status}
														</Badge>
													</>
												)}
											</td>
											{showCardSize && <td>{detail.card_size}</td>}
											<td className={priceClass}>{formatCurrency(displayPrice)}{!priceMatchesDisplayPrice ? <sup>&dagger;</sup> : null}{!priceIsStandard ? '*' : null}</td>
											<td>
												{formatDate(
													parseDateString(detail.requested_ship_date),
													dateFormat.DATE,
												)}
											</td>
											<td>{detail.publisher_part_number}</td>
											<td>{numberWithCommas(detail.quantity)}</td>
											<td className={priceClass}>
												{displayPrice !== 'Error'
													? `$${numberWithCommas(
															displayPrice * detail.quantity,
													  )}`
													: displayPrice}
											</td>
											{(this.state.order_status ===
												orderConst.STATUS.IN_MANUFACTURING ||
												this.state.order_status ===
												orderConst.STATUS.PRODUCTION_PLANNING ||
												this.state.order_status ===
													orderConst.STATUS.COMPLETE) && (
												<td>
													<Button
														id="shipmentsModal"
														className={'btn-sm, btn-default'}
														onClick={(e) =>
															this.toggleModal(e.target.id, {
																gameCode: detail.game_code,
																orderDetailId:
																	detail.order_detail_id,
																partSku:
																	detail.publisher_part_number,
																productName: detail.product_name,
																requestedShipDate: formatDate(
																	parseDateString(
																		detail.requested_ship_date,
																	),
																	dateFormat.DATE,
																),
															})
														}
													>
														Shipments
													</Button>
												</td>
											)}
										</tr>
									);
								})
							)}
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								{showCardSize && <th></th>}
								<th className={'order-details-totals'}>
									Totals:
								</th>
								<td className={'order-details-totals'}>
									{numberWithCommas(totalsQuant)}
								</td>
								<td className={'order-details-totals'}>
									{totalsPrice > 0
										? `$${numberWithCommas(totalsPrice)}`
										: ''}
								</td>
								<td className={'order-details-totals'}></td>
							</tr>
						</tbody>
					)}
				</Table>
			</div>
		);
	}

	renderFooter() {
		const { history } = this.props;
		return (
			<div className="btn-container">
				<div className="text-left">
					<Button
						variant="outline-secondary"
						onClick={() => history.push('/orders')}
					>
						<FAIcon name="chevron-left" className="mr-1"/>Back to Physical Orders
					</Button>
				</div>
			</div>
		);
	}

	renderEmailDisplayForUser(userId) {
		const { users } = this.state;
		if (!users || !userId || !this.ableToSeeEmail()) { return ''; }

		const emailAddress = (users.find((user) => user.ndid_user_id === userId) || {}).email_address;

		if (!emailAddress) {
			return '';
		} else if (this.ableToSendEmail()) {
			return <> (<a href={`mailto:${emailAddress}`}>{emailAddress}</a>)</>;
		} else  {
			return ` (${emailAddress})`;
		}
	}

	renderAllUsersEmailLink() {
		const { users } = this.state;
		if (this.ableToSendEmail() && users.length > 0) {
			const emailAddresses = users.map(user => user.email_address);
			return <>| <a href={`mailto:${emailAddresses.join(',')}`}>Email all order managers at publisher</a></>;
		}
		return null;
	}

	render() {
		const {
			userProfile,
		} = this.props;
		const {
			fault,
			modified_by_company_name,
			modified_by_user_name,
			submitted_by_company_name,
			submitted_by_user_name,
			submitted_by_ndid,
			showEBSPriceWarning,
			showStandardPriceWarning,
		} = this.state;

		if (fault) {
			return <NotFound />;
		}

		const isAbleToEdit = this.ableToEdit();

		const displayError = this.determineShowError();
		let displayedStatus = displayError
			? orderConst.STATUS.ERROR
			: this.state.order_status;
		const order_status = displayOrderStatus(
			displayedStatus,
			isAuthorized(this.props.userProfile.permissions, [
				permConst.ORDER.VIEW.ALL
			])
		);
		const titleStatus = <StatusText badge>{order_status}</StatusText>;

		const submittedByEmail = this.renderEmailDisplayForUser(submitted_by_ndid);

		const submittedBy = submitted_by_user_name
			? <>{submitted_by_user_name}{submittedByEmail},</>
			: null;

		const displaySubmitByCompanyName = submitted_by_company_name
			? `${submitted_by_company_name}`
			: '';

		const displayModifiedByCompanyName = modified_by_company_name || '';

		const rejectComment = this.getCommentByType(orderConst.COMMENT.REJECTION);
		const reviewerComment = this.getCommentByType(orderConst.COMMENT.REVIEWER);
		const noteToNCLComment = this.getCommentByType(orderConst.COMMENT.NOTES_TO_NCL);

		const showLinkToNewIndex = 
			isAuthorized(userProfile.permissions, [
				permConst.PHYSICAL_ORDER.VIEW.ALL,
				permConst.PHYSICAL_ORDER.VIEW.COMPANY,
			]);

		return (
			<Page>
				{this.state.getOrderApiPending ||
				this.state.getPageResourcesApiPending ||
				this.state.getPackoutFacilityPending ||
				this.state.getCompanyProfilePending ||
				((this.state.created_by_ndid || this.state.modified_by_ndid) &&
					!this.state.users) ? (
					<Loading />
				) : (
					<>
						<div className="orderlist">
							{displayError && (
								<Alert id="order-error-alert" variant="danger">
									{this.state.order_error.error_message}
								</Alert>
							)}
							{!this.packoutIsStillValid() && (
								<Alert id="order-error-alert" variant="danger">
									This order can only be rejected because the Packout Facility is
									no longer valid for this company.
								</Alert>
							)}
							{showStandardPriceWarning && isAbleToEdit && (
								<Alert id="unit-price-error-alert" variant="danger">
									This order contains a price that is inconsistent with our
									standard ROM price. Please double check all of the the prices
									prior to reviewing/approving this order.
								</Alert>
							)}
							{showEBSPriceWarning && isAbleToEdit && (
								<Alert id="unit-price-error-alert" variant="danger">
									This order contains a price that has changed since order
									creation. Please double check all of the prices prior to
									reviewing/approving this order.
								</Alert>
							)}
							{rejectComment && order_status === orderConst.STATUS.REJECTED && (
								<Alert id="unit-price-error-alert" variant="danger">
									This order was rejected with the following comment: "{rejectComment}"
								</Alert>
							)}
							<Breadcrumb>
								{showLinkToNewIndex ? <Breadcrumb.Item to="/orders/physical">Physical Orders</Breadcrumb.Item> : <Breadcrumb.Item to="/orders">Physical Orders</Breadcrumb.Item>}
								<Breadcrumb.Item active>View Physical Order</Breadcrumb.Item>
							</Breadcrumb>
							<Title
								title="View Physical Order"
								status={titleStatus}
								button={this.renderTitleButton()}
							/>
							<Page.ContentContainer>
								<SectionTitle>Order Info</SectionTitle>
								{generateDataField(
									'Publisher',
									<>
										{this.state.company_name} {this.renderAllUsersEmailLink()}
									</>,
								)}
								{generateDataField(
									'Publisher PO #',
									this.state.publisher_po_number,
								)}
								{this.state.showEbsFields && (
									<>
										{generateDataField('NOA PO', this.state.noa_po)}
										{generateDataField(
											'Sales Order Number',
											this.state.noa_sales_order_number,
										)}
										{generateDataField(
											'Requisition Number',
											this.state.req_number,
										)}
									</>
								)}
								{submittedBy &&
									generateDataField(
										'Submitted By',
										<>
											{submittedBy}
											{` ${displaySubmitByCompanyName}`}
											<br />
											<small>
												{formatDate(
													parseDateString(this.state.submission_date),
													dateFormat.DATETIME_PT,
												)}
											</small>
										</>,
									)}
								{isAuthorized(this.props.userProfile.permissions, [
									permConst.ORDER.VIEW.ALL,
								]) && (
									<>
										{modified_by_user_name &&
											generateDataField(
												'Modified By',
												<>
													{modified_by_user_name},
													{` ${displayModifiedByCompanyName}`}
													<br />
													<small>
														{formatDate(
															parseDateString(
																this.state.modified_by_date,
															),
															dateFormat.DATETIME_PT,
														)}
													</small>
												</>,
											)}
									</>
								)}
								{generateDataField(
									'Order Comments',
									this.getCommentByType(orderConst.COMMENT.PUBLISHER),
								)}
								<SectionTitle>Shipping</SectionTitle>
								{generateDataField(
									'Freight Forwarder',
									this.state.freight_forwarder_name,
								)}
								{this.requiresConsignee(this.state.freight_forwarder_code) && (
									<>
										{generateDataField(
											'Japanese Contact Name',
											this.state.japaneseContactName,
										)}
										{generateDataField(
											'Japanese Email',
											this.state.japaneseEmail,
										)}
										{generateDataField(
											'Japanese Phone Number',
											this.state.japanesePhoneNumber,
										)}
										{generateDataField(
											'Consignee Company Name',
											this.state.consigneeCompanyName,
										)}
										{generateDataField(
											'Consignee Address',
											this.state.consigneeAddress,
										)}
										{generateDataField(
											'Consignee City',
											this.state.consigneeCity,
										)}
										{generateDataField(
											'Consignee State',
											this.state.consigneeState,
										)}
										{generateDataField(
											'Consignee Zip',
											this.state.consigneeZip,
										)}
										{generateDataField(
											'Country',
											this.state.consigneeCountryName,
										)}
										{generateDataField(
											'Service Level',
											this.state.serviceLevel,
										)}
										{generateDataField(
											'Carrier Account Number',
											this.state.carrierAccountNumber,
										)}
									</>
								)}
								{generateDataField(
									'Customs Entry Port',
									this.state.port_of_entry_name,
								)}
								{generateDataField(
									'Packout Facility',
									this.state.packout_facility_name,
								)}
								{generateDataField(
									'Packout Facility Address',
									this.state.packout_facility_address,
								)}
								{generateDataField(
									'Destination Contact',
									this.state.packout_facility_contact_name,
								)}
								{generateDataField(
									'Destination Phone',
									this.state.packout_facility_contact_phone_number,
								)}
								{generateDataField(
									'Shipping Instructions',
									this.getCommentByType(orderConst.COMMENT.SHIPPING_INFO),
								)}
								{(rejectComment || reviewerComment || noteToNCLComment) && (
									<>
										<SectionTitle>Additional Comments</SectionTitle>
										{rejectComment &&
											generateDataField(
												'Comments on Rejection',
												rejectComment,
											)}
										{reviewerComment && generateDataField(
											'Comments to PO Approver',
											reviewerComment,
										)}
										{noteToNCLComment && generateDataField(
											'Comments to NCL',
											noteToNCLComment,
										)}
									</>
								)}
								<SectionTitle>Order Details</SectionTitle>

								<Col>
									{this.renderOrderDetailsTable()}
									{(this.state?.showEBSPriceWarning ||
										this.state?.showStandardPriceWarning) && (
										<p className="text-right">
											{this.state?.showEBSPriceWarning && (
												<div>
													<small>
														<sup>&dagger;</sup>{' '}
														<i>
															Price was changed since order creation
														</i>
													</small>
												</div>
											)}
											{this.state?.showStandardPriceWarning && (
												<div>
													<small>
														*{' '}
														<i>
															Price is inconsistent with our standard
															ROM price
														</i>
													</small>
												</div>
											)}
										</p>
									)}
								</Col>
								{this.renderFooter()}
							</Page.ContentContainer>
						</div>
					</>
				)}
				{this.state.showModal && this.state.modalType ? this.renderModal() : null}
			</Page>
		);
	}
}

export default connect(mapStateToProps)(OrderReview);
