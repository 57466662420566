import React, { Component } from 'react';
import { Button, FormLabel, FormControl, FormGroup } from 'react-bootstrap';

class CommentBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showCommentBox: false,
			showCommentPostStatus: false,
			commentText: ''
		};

		this.postComment = this.postComment.bind(this);
	}

	getCommentText() {
		return this.state.commentText;
	}

	renderCommentBox() {
		let commentAreaId = this.props.commentAreaId;
		if (commentAreaId === undefined) {
			commentAreaId = 'commentArea';
		}
		return (
			<div id={commentAreaId} className="mt-4">
				<FormGroup controlId="formControlsTextarea">
					<FormLabel>Comment</FormLabel>
					<FormControl
						rows="8"
						as="textarea"
						placeholder="Add your comment here"
						onChange={(e) => this.handleChange(e)}
						maxLength={2000}
					/>
				</FormGroup>
				<FormGroup>
					<Button variant="outline-secondary" onClick={this.postComment}>
						Add comment
					</Button>
				</FormGroup>
			</div>
		);
	}

	postComment() {
		this.props.postComment(this);
	}

	handleChange(e) {
		this.setState({
			commentText: e.target.value
		});
	}

	renderCommentButton() {
		let showCommentAreaId = this.props.showCommentAreaId;
		if (showCommentAreaId === undefined) {
			showCommentAreaId = 'showCommentArea';
		}
		return (
			<div id={showCommentAreaId} className="mt-4">
				<Button
					variant="outline-secondary"
					onClick={this.toggleCommentBox.bind(this)}
				>
					Comment
				</Button>
			</div>
		);
	}

	toggleCommentBox() {
		this.setState({
			commentText: '',
			showCommentBox: !this.state.showCommentBox
		});
	}

	render() {
		return (
			<div>
				{this.state.showCommentBox && this.renderCommentBox()}
				{!this.state.showCommentBox && this.renderCommentButton()}
			</div>
		);
	}
}

export default CommentBox;
