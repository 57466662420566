export const SET_USER_PROFILE = 'SET_USER_PROFILE';

export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS';

export const CHANGE_PLATFORM_LOAD_STATUS = 'CHANGE_PLATFORM_LOAD_STATUS';
export const SET_PLATFORMS = 'SET_PLATFORMS';

export const CHANGE_SUNSET_FEATURES_LOAD_STATUS = 'CHANGE_SUNSET_FEATURES_LOAD_STATUS';
export const SET_SUNSET_FEATURES = 'SET_SUNSET_FEATURES';

export const CHANGE_EVENT_CATEGORY_LOAD_STATUS = 'CHANGE_EVENT_CATEGORY_LOAD_STATUS';
export const SET_EVENT_CATEGORIES = 'SET_EVENT_CATEGORIES';

export const SAVE_UI_CONTROL_STATE = 'SAVE_UI_CONTROL_STATE';
export const SAVE_TAB_STATE = 'SAVE_TAB_STATE';

export const CHANGE_HELP_TOPICS_LOAD_STATUS = 'CHANGE_HELP_TOPICS_LOAD_STATUS';
export const SET_HELP_TOPICS = 'SET_HELP_TOPICS';

export const CHANGE_COMPANY_PROFILE_LOAD_STATUS = 'CHANGE_COMPANY_PROFILE_LOAD_STATUS';
export const SET_COMPANY_PROFILE = 'SET_COMPANY_PROFILE';
