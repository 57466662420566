import React, { Component } from 'react';
import dayjs from 'dayjs';
import { Button, ButtonToolbar, Table } from 'react-bootstrap';

import Loading from '../../../components/Loading/Loading';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { getProjectionsByProductId } from '../../../services/projectionsService';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';


const PREVIOUS_MONTHS_TO_DISPLAY = 2;
const FUTURE_MONTHS_TO_DISPLAY = 6;

class ProjectionsTab extends Component {
	constructor(props) {
		super(props);

		let companyNdid;
		let retailCompany = this.props.distribution.filter((c) => {
			return c.right_type === 'RETAIL_PUB' || c.right_type === 'RETAIL';
		});
		if (!retailCompany.length) {
			companyNdid = this.props.userProfile.companyId;
		} else {
			companyNdid = retailCompany[0].ndid_company_id;
		}
		this.state = {
			isLoadingProjections: true,
			companyNdid: companyNdid,
			projections: []
		};
	}

	componentDidMount() {
		this.getProjections();
	}

	getProjections() {
		const { productId } = this.props;
		const { companyNdid } = this.state;
		getProjectionsByProductId(productId, companyNdid)
			.then((response) => {
				this.setState({
					projections: response.data
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving projections'),
					'error',
					error
				);
			})
			.finally(() => {
				this.setState({
					isLoadingProjections: false
				});
			});
	}

	render() {
		const { isSelected, readOnly } = this.props;
		const { isLoadingProjections, projections } = this.state;

		if (!isSelected) {
			return <div />;
		}

		const firstDateOfThisMonth = dayjs().startOf('month');
		const firstDisplayDate = dayjs(firstDateOfThisMonth).subtract(
			PREVIOUS_MONTHS_TO_DISPLAY,
			'months'
		);
		const lastDisplayDate = dayjs(firstDateOfThisMonth).add(
			FUTURE_MONTHS_TO_DISPLAY,
			'months'
		);

		const dateHeaders = [];
		const dateValues = [];

		for (
			let currentMonth = firstDisplayDate;
			currentMonth.isSameOrBefore(lastDisplayDate);
			currentMonth = currentMonth.add(1, 'months')
		) {
			const date = currentMonth.format('MMM YY');

			dateHeaders.push(<th key={date}>{date}</th>);

			const currentProjection = projections.filter((p) =>
				dayjs(p.projection_year_month).isSame(currentMonth)
			)[0];
			const currentProjectionValue = currentProjection
				? currentProjection.projected_quantity
				: '';

			dateValues.push(
				<td key={`td-${date}`} className="projection-year-col">
					{currentProjectionValue}
				</td>
			);
		}

		return (
			<>
				{!readOnly && (
					<ButtonToolbar><Button
						type="button"
						className="btn btn-default"
						id="update-projections"
						onClick={() =>
							this.props.history.push(
								'/orders/projections'
							)
						}
					>
						Update Projections
					</Button></ButtonToolbar>
				)}
				<SectionTitle>Projections</SectionTitle>
				{isLoadingProjections ? (
					<Loading />
				) : (
					<Table striped>
						<thead>
							<tr>{dateHeaders}</tr>
						</thead>

						<tbody>
							<tr>{dateValues}</tr>
						</tbody>
					</Table>
				)}
			</>
		);
	}
}

export default ProjectionsTab;
