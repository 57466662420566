import React, { Component, Fragment } from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';

import { actionsColumn } from '../../components/BaseTable/BaseTable';
import FAIcon from '../../components/FAIcon/FAIcon';
import FilterableTable from '../../components/FilterableTable/FilterableTable';
import Loading from '../../components/Loading/Loading';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import { subConst } from '../../constants/submissionsConstants';
import { getSubmissionsList } from '../../services/productsService';
import { dateFormat, formatDate, parseDateString } from '../../utils/dateUtils';
import { generateOffsetBatches, paginationLimits } from '../../utils/paginationUtil';
import { displayString } from '../../utils/stringUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { isAuthorized } from '../../utils/userUtils';
import LotcheckPriorityModal from './modals/LotcheckPriorityModal';

import './LotcheckQueue.css';

function mapStateToProps(state) {
	return {
		userProfile: state.authReducer.userProfile,
	};
}

export class LotcheckQueue extends Component {
	constructor(props) {
		super(props);
		let dataFormat = this.getTableFormat();
		let filterProperties = new Map();
		filterProperties.set('Status', {
			filter: 'submission_status',
			selectedFilters: new Set(),
			selectableFilters: new Set()
		});
		filterProperties.set('Submission Type', {
			filter: 'submission_type',
			selectableFilters: new Set()
		});
		filterProperties.set('Days in Submission', {
			filter: 'days_in_submission',
			selectableFilters: new Set()
		});
		this.state = {
			title: 'Lotcheck Queue',
			isLoading: true,
			submissions: [],
			dataFormat,
			filterProperties,
			showModal: false,
			selectedSubmission: {}
		};

		this.saveTotalSubmissions = this.saveTotalSubmissions.bind(this);
		this.appendSubsToState = this.appendSubsToState.bind(this);
		this.batchGetSubmissionCalls = this.batchGetSubmissionCalls.bind(this);
	}

	historyPush(url) {
		this.props.history.push(url);
	}

	handleRowClick(event, submission) {
		if (event.target.closest('[aria-haspopup=true], a')) {
			// Just the ellipsis menu was clicked, don't follow link.
			event.preventDefault();
		} else {
			let url = `submission-details/${submission.original.product_id}/${submission.original.submission_id}`;
			this.historyPush(url);
		}
	}

	parseSubmissionResponse(data) {
		let daysInSub = [];
		let formattedSubmissions = [];
		const normalizeKeys = ['submission_type', 'submission_status'];
		for (let sub of data) {
			let formattedSub = {};
			for (let key in sub) {
				if (key === 'submission_features') {
					formattedSub['wifi'] = this.parseFeatures(sub[key], [
						'Wifi Connect',
						'wifi'
					]);
					formattedSub['aoc'] = this.parseFeatures(sub[key], [
						'Consumable AOC',
						'aoc'
					]);
					formattedSub['ugc'] = this.parseFeatures(sub[key], [
						'UGC',
						'ugc'
					]);
				} else if (key === 'submission_lotcheck_priority') {
					// If there is no priority, set it less than Hold (999) for sorting reasons.
					formattedSub[key] = !sub[key] ? 998 : sub[key];
				} else if (key === 'submission_date') {
					let days = this.generateSubmissionDays(sub[key]).toString();
					daysInSub.push(days);
					formattedSub['days_in_submission'] = days;
				} else if (key === 'product_release_type') {
					let value = sub[key];
					if (value.length > 5) {
						value = value.substring(0,5);
					}
					formattedSub[key] = value;
				} else {
					let value = sub[key];
					if (normalizeKeys.includes(key)) {
						// Normalize data inconsistencies for filtering
						value = value.toUpperCase();
						value = value.replace('_', ' ');
					}
					formattedSub[key] = value;
				}
			}
			this.sortDaysInSubmissionFilter(daysInSub);
			formattedSubmissions.push(formattedSub);
		}
		return formattedSubmissions;
	}

	saveTotalSubmissions(data) {
		this.setState({
			totalSubs: data.total
		});
	}

	appendSubsToState(data) {
		let currentSubs = this.state.submissions;
		this.setState({
			submissions: currentSubs.concat(this.parseSubmissionResponse(data))
		});
	}

	getSubmissionData = (queryString, callBack) => {
		return new Promise((resolve, reject) => {
			getSubmissionsList(queryString)
				.then((response) => {
					callBack(response.data);
				})
				.catch((error) => {
					toasterNotify(
						createMessageForError(error, 'loading submissions'),
						'error',
						error
					);
				})
				.finally(() => {
					resolve();
				});
		});
	};

	batchGetSubmissionCalls() {
		const { totalSubs } = this.state;
		this.setState({ submissions: [] });
		const limit = paginationLimits.lotcheckQueue;
		let batches = generateOffsetBatches(totalSubs, limit);
		batches.forEach((offset) => {
			let q_string = `limit=${limit}&offset=${offset}`;
			this.getSubmissionData(q_string, this.appendSubsToState);
		});
	}

	getTableData() {
		let searchableFields = [
			'company_name',
			'product_name',
			'product_game_code'
		];
		let searchableFieldPlaceHolder =
			'Search by Publisher, Title or Game Code';
		this.setState({
			searchableFields,
			searchableFieldPlaceHolder
		});
	}

	sortDaysInSubmissionFilter(arr) {
		const { filterProperties } = this.state;
		arr.sort((a, b) => {
			return a - b;
		});
		filterProperties.get('Days in Submission').selectableFilters = new Set(
			arr
		);
	}

	getStatus() {
		const { filterProperties } = this.state;
		filterProperties
			.get('Status')
			.selectableFilters.add(displayString(subConst.STATUS.PRECHECK));
		filterProperties
			.get('Status')
			.selectableFilters.add(displayString(subConst.STATUS.TESTING));
		filterProperties
			.get('Status')
			.selectableFilters.add(displayString(subConst.STATUS.FAILED));
		filterProperties
			.get('Status')
			.selectableFilters.add(displayString(subConst.STATUS.NOA_APPROVED));
		filterProperties
			.get('Status')
			.selectableFilters.add(displayString(subConst.STATUS.APPROVED));
		filterProperties
			.get('Status')
			.selectableFilters.add(
				displayString(subConst.STATUS.SAMPLE_APPROVED)
			);

		// Default Checked
		filterProperties
			.get('Status')
			.selectedFilters.add(displayString(subConst.STATUS.TESTING));
	}

	getTypes() {
		const { filterProperties } = this.state;
		filterProperties
			.get('Submission Type')
			.selectableFilters.add(displayString(subConst.TYPE.E3));
		filterProperties
			.get('Submission Type')
			.selectableFilters.add(displayString(subConst.TYPE.PRECHECK));
		filterProperties
			.get('Submission Type')
			.selectableFilters.add(displayString(subConst.TYPE.LOTCHECK));
	}

	generateSubmissionDays(value) {
		// Setting both dates (submission created and today) to be at 00:00
		// then finding diff between.
		let inputDate = dayjs(value)
			.hour(0)
			.minute(0);
		let nowDate = dayjs().startOf('day');
		return nowDate.diff(inputDate, 'days');
	}

	parseFeatures(values, names) {
		let value = '--';
		if (!values) {
			return;
		}
		let features = JSON.parse(values).features;
		// Features JSON are stored in different formats. This try catch handles this
		try {
			for (let feature of features) {
				if (feature.name === names[0]) {
					if (typeof feature.values[0].value !== 'boolean') {
						if (
							feature.values[0].value === 'YES' ||
							feature.values[0].value === 'Y'
						) {
							value = (
								<FAIcon name="check" className="text-success" />
							);
						}
					} else {
						if (feature.values[0].value) {
							value = (
								<FAIcon name="check" className="text-success" />
							);
						}
					}
				}
			}
		} catch {
			if (features[names[1]]) {
				value = (
					<FAIcon name="check" className="text-success"/>
				);
			}
		}
		return value;
	}

	statusText(value, approvalDate) {
		let cellText;
		switch (value) {
			case subConst.STATUS.APPROVED:
			case subConst.STATUS.NOA_APPROVED:
			case subConst.STATUS.SAMPLE_APPROVED:
				cellText = <strong className="text-success">{value}</strong>;
				break;
			case subConst.STATUS.TESTING:
			case subConst.STATUS.PRECHECK:
				cellText = <strong>{value}</strong>;
				break;
			case subConst.STATUS.FAILED:
				cellText = <strong className="text-danger">{value}</strong>;
				break;
			default:
				cellText = <strong>{value}</strong>;
		}

		let approveDate = this.getApprovalDate(approvalDate, value);
		return [cellText, approveDate];
	}

	getApprovalDate(modificationDate, status) {
		const formattedDate = formatDate(parseDateString(modificationDate), dateFormat.DATE_YMD);
		return status === subConst.STATUS.APPROVED ? (
			<strong>{formattedDate}</strong>
		) : (
			''
		);
	}

	getTableFormat() {
		let queueTable = [
			{
				Header: 'Priority',
				accessor: 'submission_lotcheck_priority',
				width: 72,
				className: 'lotcheck-queue-pointer',
				Cell: (cell) => (
					<div
						className={cell.value !== 998 ? 'pri-show' : 'pri-hide'}
					>
						{cell.value >= 999 ? subConst.STATUS.HOLD : cell.value}
					</div>
				)
			},
			{
				Header: 'Version',
				id: 'version',
				accessor: (d) =>
					`${d.product_release_version}.${d.submission_version}`,
				width: 71,
				className: 'lotcheck-queue-pointer'
			},
			{
				Header: 'Publisher',
				accessor: 'company_name',
				className: 'lotcheck-queue-pointer'
			},
			{
				Header: 'Product Name',
				accessor: 'product_name',
				width: 115,
				className: 'lotcheck-queue-pointer'
			},
			{
				Header: 'Game Code',
				accessor: 'product_game_code',
				width: 62,
				className: 'lotcheck-queue-pointer'
			},
			{
				Header: 'Submission Type',
				accessor: 'submission_type',
				width: 100,
				className: 'lotcheck-queue-pointer'
			},
			{
				Header: 'Product Type',
				accessor: 'product_release_type',
				className: 'lotcheck-queue-pointer'
			},
			{
				Header: 'WiFi',
				accessor: 'wifi',
				width: 52,
				className: 'lotcheck-queue-pointer'
			},
			{
				Header: 'UGC',
				accessor: 'ugc',
				width: 52,
				className: 'lotcheck-queue-pointer'
			},
			{
				Header: 'AOC',
				accessor: 'aoc',
				width: 52,
				className: 'lotcheck-queue-pointer'
			},
			{
				Header: 'Days in Submission',
				sortMethod: (a, b) => {
					return a - b;
				},
				accessor: 'days_in_submission',
				width: 99,
				className: 'lotcheck-queue-pointer'
			},
			{
				Header: 'Status',
				accessor: 'submission_status',
				width: 110,
				className: 'lotcheck-queue-pointer',
				Cell: (cell) => (
					cell.value
						? this.statusText(
								cell.value,
								cell.original.submission_approval_date
						).map((element, index) => <Fragment key={index}>{element}</Fragment>)
						: null
				)
			}
		];
		if (
			isAuthorized(this.props.userProfile.permissions, [
				'product.lotcheck.priority'
			])
		) {
			queueTable.push({
				...actionsColumn,
				className: 'lotcheck-queue-pointer',
				Cell: (props) => {
					return props.original.submission_status === 'TESTING'
						? this.updatePriority(props)
						: null;
				}
			});
		}
		return queueTable;
	}

	updatePriority(props) {
		return (
			<MeatballDropdown
				id={'dropdown-basic-lotcheck-' + props.original.submission_id}
			>
				<MeatballDropdown.Item
					onClick={() =>
						this.renderLotcheckPriorityModal(props.original)
					}
				>
					Update / Hold Priority
				</MeatballDropdown.Item>
			</MeatballDropdown>
		);
	}

	closeLotcheckPriorityModal = () => {
		this.setState({ showModal: false });
	};

	renderLotcheckPriorityModal(props) {
		if (!props) return;

		this.setState({
			selectedSubmission: props,
			showModal: true
		});
	}

	async componentDidMount() {
		await this.getSubmissionData('count=true', this.saveTotalSubmissions);
		this.batchGetSubmissionCalls();
		this.setState({ isLoading: false });
		this.getTableData();
		this.getStatus();
		this.getTypes();
	}

	render() {
		const {
			dataFormat,
			searchableFields,
			searchableFieldPlaceHolder,
			filterProperties,
			submissions,
			totalSubs,
			title,
			showModal,
			selectedSubmission
		} = this.state;
		const { userProfile } = this.props;
		let isLoading = submissions.length !== totalSubs;
		return (
			<Page>
				<Title title={title} />
				{isLoading ? (
					<Loading />
				) : (
					<FilterableTable
						data={submissions}
						dataFormat={dataFormat}
						filterProperties={filterProperties}
						searchableFields={searchableFields}
						searchableFieldPlaceHolder={
							searchableFieldPlaceHolder
						}
						getTrProps={(state, submission) => ({
							onClick: (e) => {
								this.handleRowClick(e, submission);
							}
						})}
						defaultSorted={[
							{
								id: 'submission_lotcheck_priority',
								desc: false
							},
							{
								id: 'days_in_submission',
								desc: true
							}
						]}
					/>
				)}
				{showModal && (
					<LotcheckPriorityModal
						submissionId={selectedSubmission.submission_id}
						title={selectedSubmission.product_name}
						gameCode={selectedSubmission.product_game_code}
						submissionVersion={
							selectedSubmission.submission_version
						}
						releaseVersion={
							selectedSubmission.product_release_version
						}
						closeFunction={this.closeLotcheckPriorityModal}
						userProfile={userProfile}
						pageRefresh={this.batchGetSubmissionCalls}
					/>
				)}
			</Page>
		);
	}
}

export default connect(
	mapStateToProps
)(LotcheckQueue);
