import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { Calendar, Navigate, Views, dayjsLocalizer } from 'react-big-calendar';
import Month from 'react-big-calendar/lib/Month';
import Week from 'react-big-calendar/lib/Week';

import { Button, ButtonGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { eventConstants } from '../../constants/eventConstants';
import { dateFormat, doPeriodsIntersect, formatDate, parseDateString } from '../../utils/dateUtils';
import ActionLink from '../ActionLink/ActionLink';
import BaseTable from '../BaseTable/BaseTable';
import FAIcon from '../FAIcon/FAIcon';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './EventCalendar.css';

const ALL = 'all';
const localizer = dayjsLocalizer(dayjs);

const dataAdapter = (data) => {
	return data ? data.map(item => ({
		id: item.id,
		title: item.name,
		allDay: false,
		start: new Date(item.start_datetime),
		end: new Date(item.end_datetime),
		status: item.status,
		active: item.active,
		category: item.category,
		internallyVisible: item.internally_visible,
		externallyVisible: item.externally_visible,
	})) : [];
};


const Event = (props) => {
	const { event, title } = props;
	return <div className='EventCalendar__event'>
		{ event.active && <FAIcon name="check" /> }
		{ title }
	</div>;
};
const EventWrapper = (props) => {
	const eventCategories = useSelector(store => store.referenceReducer.eventCategories?.content);

	const getVisibility = event => {
		if (event.internallyVisible && event.externallyVisible) {
			return 'Visible To All';
		} else if (event.internallyVisible) {
			return 'Internally Visible';
		} else if (event.externallyVisible) {
			return 'Externally Visible';
		} else {
			return 'Not Visible';
		}
	};

	const { children, event } = props;

	const url = `/marketing-events/${event.id}`;
	const style =
		event.status === eventConstants.STATUS.PLANNED
			? { backgroundColor: 'var(--blue)' }
			: event.status === eventConstants.STATUS.LIVE
				? { backgroundColor: 'var(--green)' }
				: { backgroundColor: 'var(--nin-color-dkgray3)' };
	return (
		<OverlayTrigger
			placement={'auto'}
			overlay={
				<Popover>
					<Popover.Title as="h3">{event.title}</Popover.Title>
					<Popover.Content>
						<ul className="EventCalendar__popup m-0 p-0">
							<li>
								{event.category in eventCategories ? eventCategories[event.category] : event.category}
							</li>
							{event.internallyVisible !== undefined && event.externallyVisible !== undefined &&
								<li>
									{getVisibility(event)}
								</li>
							}
							<li>
								{formatDate(parseDateString(event.start), dateFormat.DATE)} - {formatDate(parseDateString(event.end), dateFormat.DATE)}
							</li>
						</ul>
					</Popover.Content>
				</Popover>
			}
		>
			<Link to={url} style={{color: 'white'}}>{React.cloneElement(React.Children.only(children), { style })}</Link>
		</OverlayTrigger>
	);
};

const CustomMonth = (props) =>
	<div className="EventCalendar__calendar-container"><Month {...props}/></div>;
CustomMonth.title = Month.title;
CustomMonth.navigate = Month.navigate;
CustomMonth.range = Month.range;

const CalendarHeader = ({ label, view, onView, onNavigate }) => (
	<nav className="mb-3">
		<div className="d-flex">
			<h4 className="m-0">{label}</h4>
			<ButtonGroup className="ml-auto">
				<Button
					variant={view === ALL ? 'secondary' : 'outline-secondary'}
					onClick={(e) => onView(ALL)}
				>
					All
				</Button>
				<Button
					variant={view === Views.MONTH ? 'secondary' : 'outline-secondary'}
					onClick={(e) => onView(Views.MONTH)}
				>
					Month
				</Button>
				<Button
					variant={view === Views.WEEK ? 'secondary' : 'outline-secondary'}
					onClick={(e) => onView(Views.WEEK)}
				>
					Week
				</Button>
			</ButtonGroup>
		</div>
		{ view !== ALL &&
			<div className="d-flex">
				<ButtonGroup className="mr-4">
					<Button size="sm" variant="outline-secondary" onClick={e => onNavigate(Navigate.PREVIOUS)}>
						<FAIcon name="arrow-left" />
					</Button>
					<Button size="sm" variant="outline-secondary" onClick={e => onNavigate(Navigate.TODAY)}>
						Today
					</Button>
					<Button size="sm" variant="outline-secondary" onClick={e => onNavigate(Navigate.NEXT)}>
						<FAIcon name="arrow-right" />
					</Button>
				</ButtonGroup>
				{view === Views.MONTH && (
					<OverlayTrigger
						placement={'right'}
						overlay={
							<Popover id="popover-legend">
								<Popover.Title as="h3">Legend</Popover.Title>
								<Popover.Content>
									<ul className="EventCalendar__legend m-0 p-0">
										<li>
											<span style={{width: '2rem'}}><FAIcon name="square" style={{ color: 'var(--blue)' }} /></span>
											Planned
										</li>
										<li>
											<span style={{width: '2rem'}}><FAIcon name="square" style={{ color: 'var(--green)' }} /></span>
											Live
										</li>
										<li>
											<span style={{width: '2rem'}}><FAIcon name="square" style={{ color: 'var(--nin-color-dkgray3)'}}/></span>
											Completed
										</li>
										<li>
											<span style={{width: '2rem'}}><FAIcon name="check" /></span>
											Active
										</li>
									</ul>
								</Popover.Content>
							</Popover>
						}
					>
						<div className="pr-2">
							<ActionLink dotted>Legend</ActionLink>
						</div>
					</OverlayTrigger>
				)}
			</div>
		}
	</nav>
);


const EventCalendar = ({ data, dataFormat }) => {
	const AllView = useMemo(() => {
		const AllView = () => (
			<BaseTable
				data={data}
				columns={dataFormat}
				minRows={0}
				defaultSorted={[
					{
						id: 'status',
						desc: false
					},
					{
						id: 'start_datetime',
						desc: false
					}
				]}
				retainPageState
			/>
		);
		AllView.title = () => 'All Events';
		return AllView;
	}, [data, dataFormat]);

	const WeekView = useMemo(() => {
		const WeekView = (props) => {
			const range = Week.range(props.date, props);
			const startDate = range[0];
			const endDate = new Date(startDate.valueOf() + (1000*60*60*24*7));
			const limitedData = data.filter((event) =>
				event.start_datetime && event.end_datetime &&
					doPeriodsIntersect(event.start_datetime, event.end_datetime, startDate, endDate)
			);
			return (<BaseTable
				data={limitedData}
				columns={dataFormat}
				minRows={0}
				defaultSorted={[
					{
						id: 'status',
						desc: false
					},
					{
						id: 'start_datetime',
						desc: false
					}
				]}
				showPagination={false}
			/>);
		};
		WeekView.title = Week.title;
		WeekView.range = Week.range;
		WeekView.navigate = Week.navigate;
		return WeekView;
	}, [data, dataFormat]);

	return (
		<Calendar
			className="Page__fill-space"
			events={dataAdapter(data)}
			views={{
				'all': AllView,
				'month': CustomMonth,
				'week': WeekView
			}}
			defaultView={ALL}
			components={{
				event: Event,
				eventWrapper: EventWrapper,
				toolbar: CalendarHeader,
			}}
			localizer={localizer}
			popup
			tooltipAccessor={null}
		/>
	);
};

export default EventCalendar;
