import React, { Component } from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import FAIcon from '../../../components/FAIcon/FAIcon';
import FileLink from '../../../components/FileLink/FileLink';
import Loading from '../../../components/Loading/Loading';
import Page from '../../../components/Page/Page';
import PropertyDisplay from '../../../components/PropertyDisplay/PropertyDisplay';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import Title from '../../../components/Title/Title';
import { fileTransferConst } from '../../../constants/fileTransferConstants';
import { loadingConstants } from '../../../constants/loadingConstants';
import { permConst } from '../../../constants/permConst';
import { timeZoneConstants } from '../../../constants/timeZoneConstants';
import { transferRightsConstants } from '../../../constants/transferRightsConstants';
import NotFound from '../../../containers/NotFound/NotFound';
import { getProductRightsTransferRequest } from '../../../services/productTransfersService';
import { dateFormat, formatDate, parseDateString } from '../../../utils/dateUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { isAuthorized } from '../../../utils/userUtils';
import AcceptOwnershipTransferModal from './modals/AcceptOwnershipTransferModal';
import RejectOwnershipTransferModal from './modals/RejectOwnershipTransferModal';


function mapStateToProps(state, ownProps) {
	return {
		userProfile: state.authReducer.userProfile,
		platforms: state.referenceReducer.platforms?.content,
		platformsReady: state.referenceReducer.platforms?.meta.status === loadingConstants.COMPLETED
	};
}

const title = 'Ownership Transfer Details';

export class ProductOwnershipTransfer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			productRightsTransferId: props.match.params.id,
			isValidRequest: false,
			modalType: null,
			showModal: false
		};
		this.toggleModal = this.toggleModal.bind(this);
		this.updateTransferStatus = this.updateTransferStatus.bind(this);
	}

	componentDidMount() {
		this.componentDidUpdate();
	}

	componentDidUpdate() {
		if (!this.props.platformsReady) {
			return;
		}
		this.getProductRightsTransferRequest();
		this.componentDidUpdate = null;
	}

	updateTransferStatus(status) {
		const { transferRequest } = this.state;
		transferRequest.transfer_status = status;
		this.setState({
			transferRequest: transferRequest
		});
	}

	renderModal() {
		const { userProfile } = this.props;
		const { productRightsTransferId, transferRequest } = this.state;

		const transferStatus =
			transferRequest.transfer_status ===
			transferRightsConstants.REQUEST_STATUS.REVIEWED
				? transferRightsConstants.REQUEST_STATUS.PROCESSED
				: transferRightsConstants.REQUEST_STATUS.REVIEWED;

		switch (this.state.modalType) {
			case 'accept-transfer':
				return (
					<AcceptOwnershipTransferModal
						userProfile={userProfile}
						productRightsTransferId={productRightsTransferId}
						closeModal={this.toggleModal}
						updateTransferStatus={this.updateTransferStatus}
						newTransferStatus={transferStatus}
					/>
				);
			case 'reject-transfer':
				return (
					<RejectOwnershipTransferModal
						userProfile={userProfile}
						productRightsTransferId={productRightsTransferId}
						closeModal={this.toggleModal}
						updateTransferStatus={this.updateTransferStatus}
					/>
				);
			default:
				toasterNotify(
					`An unexpected error occurred opening modal ${this.state.modalType}`
				);
		}
	}

	toggleModal(type, transferId = null, loadResources = false) {
		if (this.state.showModal === true) {
			type = null;
		}

		this.setState(
			{
				showModal: !this.state.showModal,
				modalType: type,
				selectedTransferId: transferId
			},
			() => {
				if (loadResources) {
					this.loadResources();
				}
			}
		);
	}

	getProductRightsTransferRequest() {
		getProductRightsTransferRequest(this.state.productRightsTransferId)
			.then((response) => {
				this.setState({
					transferRequest: response.data,
					isValidRequest: this.validatePLA(response.data)
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving distribution rights requests'),
					'error',
					error
				);
			})
			.finally(() => {
				this.setState({
					isLoading: false
				});
			});
	}

	hasFileIssues() {
		const { transferRequest } = this.state;
		if (!transferRequest || !transferRequest.transfer_agreement_files) {
			return false;
		}
		return !!transferRequest.transfer_agreement_files.find(item => (
			item.status === fileTransferConst.TRANSFER_STATES.FAILED ||
			item.status === fileTransferConst.TRANSFER_STATES.INFECTED
		));
	}

	canApprove() {
		const { userProfile } = this.props;
		const { transferRequest } = this.state;

		if (this.hasFileIssues()) {
			 return false;
		}

		const validOrderReviewer =
			isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.RIGHT_TRANSFER.REVIEW.ALL_OWNERSHIP
			]) &&
			transferRequest.transfer_status ===
				transferRightsConstants.REQUEST_STATUS.PENDING;

		const validOrderApprover =
			isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.RIGHT_TRANSFER.PROCESS.ALL_OWNERSHIP
			]) &&
			transferRequest.transfer_status ===
				transferRightsConstants.REQUEST_STATUS.REVIEWED;

		return validOrderApprover || validOrderReviewer;
	}

	canReject() {
		const { userProfile } = this.props;
		const { transferRequest } = this.state;

		const validOrderReviewerReject =
			isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.RIGHT_TRANSFER.REVIEW_REJECT.ALL_OWNERSHIP
			]) &&
			transferRequest.transfer_status ===
				transferRightsConstants.REQUEST_STATUS.PENDING;

		const validOrderApproverReject =
			isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.RIGHT_TRANSFER.PROCESS_REJECT.ALL_OWNERSHIP
			]) &&
			transferRequest.transfer_status ===
				transferRightsConstants.REQUEST_STATUS.REVIEWED;

		return validOrderApproverReject || validOrderReviewerReject;
	}

	renderTitleButton() {
		const { isLoading, isValidRequest } = this.state;
		const displayApproveButton =
			isValidRequest && !isLoading && this.canApprove();
		const displayRejectButton = !isLoading && this.canReject();

		return (
			<>
				{displayApproveButton && (
					<Button
						type="button"
						variant="success"
						id="accept-transfer-button"
						onClick={(e) => {
							this.toggleModal('accept-transfer');
						}}
					>
						Accept Transfer
					</Button>
				)}
				{displayRejectButton && (
					<Button
						type="button"
						variant="danger"
						id="reject-transfer-button"
						onClick={(e) => {
							this.toggleModal('reject-transfer');
						}}
					>
						Reject Transfer
					</Button>
				)}
			</>
		);
	}

	renderFooterButton() {
		return (
			<div className="btn-container">
				<Button
					variant="outline-secondary"
					id="back-to-index"
					onClick={(e) =>
						this.props.history.push(
							'/admin/product-ownership-transfer-requests'
						)
					}
				>
					<FAIcon name="chevron-left" className="mr-1" />Back to Product Owner Transfers
				</Button>
			</div>
		);
	}

	validatePLA(transferRequest) {
		if (
			transferRequest &&
			transferRequest.digital_pub_flag &&
			transferRequest.transfer_to_company_invalid_digital_agreements_flag
		) {
			return false;
		}

		if (
			transferRequest &&
			transferRequest.retail_pub_flag &&
			transferRequest.transfer_to_company_invalid_retail_agreements_flag
		) {
			return false;
		}

		return true;
	}

	render() {
		const { transferRequest, isLoading, isValidRequest } = this.state;
		const { userProfile, platforms, platformsReady } = this.props;
		const transferStatus = transferRequest
			? transferRequest.transfer_status
			: '';
		const classType =
			transferRequest && transferRequest.transfer_status !== 'Rejected'
				? 'status-info'
				: 'status-danger';

		const titleStatus = {
			text: transferStatus,
			class: classType
		};

		const hasTransferAgreementFiles =
			transferRequest && transferRequest.transfer_agreement_files;

		const platformName = !isLoading && platformsReady && transferRequest &&
			(platforms.find(p => transferRequest.product_platform_code === p.platform_code) || {}).platform_name;

		if (
			!isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.RIGHT_TRANSFER.VIEW.ALL_OWNERSHIP
			]) || (!transferRequest && !isLoading)
		) {
			return <NotFound />;
		}
		return (
			<Page>
				{isLoading ? (
					<Loading />
				) : (
					<>
						<Row>
							<Col sm={12}>
								{this.hasFileIssues() ? (
									<Alert variant="danger">
										The file uploaded for this transfer is damaged or failed a
										virus scan. This transfer can't be accepted and a new
										transfer will need to be initiated by the publisher.
									</Alert>
								) : !isValidRequest ? (
									<Alert variant="danger">
										Receiving Publisher does not have needed PLA to support this
										transfer
									</Alert>
								) : (
									<Alert variant="success">Valid PLA Confirmed</Alert>
								)}
							</Col>
						</Row>

						<Breadcrumb>
							<Breadcrumb.Item to="/admin/product-ownership-transfer-requests">
								Transfer Product Ownership
							</Breadcrumb.Item>
							<Breadcrumb.Item active>{title}</Breadcrumb.Item>
						</Breadcrumb>

						<Title
							title={title}
							status={titleStatus}
							button={this.renderTitleButton()}
						/>
						<Page.FullPageCol>
							<Row>
								<Col sm={12}>
									<SectionTitle>Game Info</SectionTitle>
									<PropertyDisplay label="Request Date">
										{formatDate(
											parseDateString(transferRequest.creation_date),
											dateFormat.DATETIME_PT,
										)}
									</PropertyDisplay>
									<PropertyDisplay label="Transfer Date">
										{formatDate(
											parseDateString(transferRequest.transfer_date),
											dateFormat.DATETIME_PT,
										)}
									</PropertyDisplay>
									<PropertyDisplay label="Title">
										{transferRequest.product_game_name}
									</PropertyDisplay>
									<PropertyDisplay label="Platform">
										{platformName}
									</PropertyDisplay>
									<PropertyDisplay label="Game Code">
										{transferRequest.product_game_code}
									</PropertyDisplay>
									<SectionTitle>Initiating Publisher</SectionTitle>
									<PropertyDisplay label="Publisher">
										{transferRequest.transfer_from_company}
									</PropertyDisplay>
									<PropertyDisplay label="Company Admin">
										{transferRequest.transfer_from_company_requester}
									</PropertyDisplay>
									<PropertyDisplay label="Expected Transfer Date">
										{formatDate(
											parseDateString(
												transferRequest.expected_transfer_date,
												timeZoneConstants.UTC,
												timeZoneConstants.UTC,
											),
											dateFormat.DATE,
										)}
									</PropertyDisplay>
									<SectionTitle>Receiving Publisher</SectionTitle>
									<PropertyDisplay label="Publisher">
										{transferRequest.transfer_to_company}
									</PropertyDisplay>
									<PropertyDisplay label="Company Admin">
										{transferRequest.transfer_to_company_accepter}
									</PropertyDisplay>
									<SectionTitle>Transfer Docs</SectionTitle>
									{hasTransferAgreementFiles && (
										<ul>
											{transferRequest.transfer_agreement_files.map((f) => {
												return (
													<li>
														<FileLink
															className="download-transfer-file"
															fileName={f.file_name}
															fileId={f.file_id}
															status={f.status}
															saved={true}
														>
															{f.file_name}
														</FileLink>
														{f.status ===
															fileTransferConst.TRANSFER_STATES
																.FAILED && ' (UPLOAD FAILED)'}
														{f.status ===
															fileTransferConst.TRANSFER_STATES
																.INFECTED && ' (VIRUS SCAN FAILED)'}
													</li>
												);
											})}
										</ul>
									)}
									{this.renderFooterButton()}
								</Col>
							</Row>
						</Page.FullPageCol>
					</>
				)}
				{this.state.showModal && this.state.modalType ? this.renderModal() : null}
			</Page>
		);
	}
}

export default connect(mapStateToProps)(ProductOwnershipTransfer);
