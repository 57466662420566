import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useHistory } from 'react-router';

import FAIcon from '../FAIcon/FAIcon';

const NotFound = () => {
	const history = useHistory();
	return (
        <div className="d-block m-auto">
            <Card>
                <Card.Body className="d-flex flex-row align-items-center">
                <div className="d-flex flex-row align-items-center">
                    <FAIcon className="text-danger m-5" name="ban" size='5x' />
                    <div className="mr-5">
                        <h3>Ahhh!</h3>
                        <h3>The content you are looking for could not be found.</h3>
                    </div>
			    </div>
                </Card.Body>
                <Card.Body className="border-top text-right">
                    <Button variant="outline-secondary" onClick={(e) => history.go(-1)}>
                        Go Back
                    </Button>
                </Card.Body>
            </Card>
        </div>
	);
};

const ErrorDisplay = () => {
	const history = useHistory();
	return (
        <div className="d-block m-auto">
            <Card>
                <Card.Body className="d-flex flex-row align-items-center">
                    <div className="d-flex flex-row align-items-center">
                        <FAIcon className="text-danger m-5" name="exclamation-triangle" size='5x' />
                        <div className="mr-5">
                            <h3>Oh no!</h3>
                            <h3>There was an error that broke the page.</h3>
                        </div>
                    </div>
                </Card.Body>
                <Card.Body className="border-top text-right">
                    <Button variant="outline-secondary" onClick={(e) => history.go(-1)}>
                        Go Back
                    </Button>
                </Card.Body>
            </Card>
        </div>
	);
};

const Fault = ({errorObject}) => {
	if (errorObject.response) {
		switch (errorObject.response.status) {
			case 404:
				return <NotFound />;
			default:
		}
	}
	return <ErrorDisplay />;
};
export default Fault;
