import React, { Component } from 'react';
import { Button, FormLabel } from 'react-bootstrap';

import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { getFileUploadSpec } from '../../services/fileUploadsService';
import { doesFileHaveExtension } from '../../utils/assetUtils';
import { sleep } from '../../utils/serviceUtils';
import FAIcon from '../FAIcon/FAIcon';

import './AsperaFileUpload.css';


const SDK_INSTALLER = '//d3gcli72yxqn2z.cloudfront.net/connect/v4';
const MIN_VERSION = '3.8.0';

// EXPECTED PROPS:
// entityId            = The id of the entity having files uploaded for it. (eg: productId, submissionId, etc)
// entityType          = The pdb type of the entity (eg: submission, etc)
// isUploadInitiated   = Whether the parent modal has triggered the uploading to begin
// onCompleteFunction  = What to do when the service has completed what it needs to. (eg: redirect)
// updateFilesSelected = Callback executed when selected files change, with number of files passed in as first param (see: ReceiveSubmissionModal.updateFilesSelected)
// validateFileType    = OPTIONAL - Called when a file is selected, used for checking file types
// allowedFileTypes    = OPTIONAL - the FileFilters that define the allowed file types
// getToken            = OPTIONAL - Function to retrieve DA token if necessary
// singleFileOnly      = OPTIONAL - Boolean to only allow a single file to be selected.
//                                  This prop will also change allowMultipleSelection to false if set.
// disabled            = OPTIONAL - Boolean, button disabled if true

export const selectFiles = (
	{
		connection,
		onSuccess,
		onError,
		allowMultipleSelection,
		allowedFileTypes
	}) => {
	connection.showSelectFileDialog(
		{
			success: onSuccess,
			error: onError
		},
		{
			allowMultipleSelection: allowMultipleSelection,
			allowedFileTypes: allowedFileTypes
		}
	);
};

class AsperaFileUpload extends Component {
	constructor(props) {
		super(props);
		this.session = null;
		this.state = {
			sdkLocation: SDK_INSTALLER,
			minVersion: MIN_VERSION,
			// eslint-disable-next-line no-console
			errorHandler: console.log,
			entityId: this.props.entityId,
			entityType: this.props.entityType,
			isUploadInitiated: this.props.isUploadInitiated,
			installer: null,
			uploadSpec: null,
			selectedFiles: []
		};

		this.asperaInit = this.asperaInit.bind(this);
		this.initFileSelection = this.initFileSelection.bind(this);
		this.formatFilesSelected = this.formatFilesSelected.bind(this);
		this.formatFileSelected = this.formatFileSelected.bind(this);
		this.handleFilesSelected = this.handleFilesSelected.bind(this);
		this.runAsperaUpload = this.runAsperaUpload.bind(this);
		this.startFileUpload = this.startFileUpload.bind(this);
		this.removeFile = this.removeFile.bind(this);
		this.validateFileType = this.validateFileType.bind(this);
	}

	asperaInit() {
		const { errorHandler } = this.state;
		try {
			this.session = new window.AW4.Connect({
				sdkLocation: this.sdkLocation,
				minVersion: this.minVersion
			});
			this.installer = new window.AW4.ConnectInstaller({
				sdkLocation: this.sdkLocation
			});

			let statusEventListener = (eventType, data) => {
				if (
					eventType === window.AW4.Connect.EVENT.STATUS &&
					data === window.AW4.Connect.STATUS.INITIALIZING
				) {
					this.installer.showLaunching();
				} else if (
					eventType === window.AW4.Connect.EVENT.STATUS &&
					data === window.AW4.Connect.STATUS.FAILED
				) {
					this.installer.showDownload();
				} else if (
					eventType === window.AW4.Connect.EVENT.STATUS &&
					data === window.AW4.Connect.STATUS.OUTDATED
				) {
					this.installer.showUpdate();
				} else if (
					eventType === window.AW4.Connect.EVENT.STATUS &&
					data === window.AW4.Connect.STATUS.RUNNING
				) {
					this.installer.connected();
				}
			};

			this.session.addEventListener(
				window.AW4.Connect.EVENT.STATUS,
				statusEventListener
			);
			this.session.initSession();
		} catch (error) {
			errorHandler(error);
			toasterNotify('File upload error', 'error');
		}
	}

	initFileSelection() {
		const { allowedFileTypes, singleFileOnly } = this.props;
		this.asperaInit();
		let fileSelectHandler = (uploads) => {
			let fileUploads = uploads.dataTransfer.files;
			if (fileUploads && fileUploads.length > 0) {
				let formattedFiles = this.formatFilesSelected(fileUploads).filter(
					(file) => file.file_name?.length <= 200,
				);
				if (fileUploads.length > formattedFiles.length) {
					toasterNotify(
						'Files with names over 200 characters (extensions included) cannot be processed and have been left off the selection.',
						'error',
					);
				}
				formattedFiles?.length > 0 && this.handleFilesSelected(formattedFiles);
			}
		};

		let fileSelectErrorHandler = (selectError) => {
			let errorMessage = selectError.error.user_message;
			if (!errorMessage) {
				errorMessage = 'Error occurred when selecting files';
			}
			toasterNotify(errorMessage, 'error');
		};

		selectFiles({
			connection: this.session,
			onSuccess: fileSelectHandler,
			onError: fileSelectErrorHandler,
			allowMultipleSelection: (singleFileOnly ? false : true),
			allowedFileTypes: allowedFileTypes
		});
	}

	formatFilesSelected(fileUploads) {
		let files = [];
		fileUploads.forEach((upload) => {
			files.push(this.formatFileSelected(upload));
		});
		return files;
	}

	formatFileSelected(upload) {
		let filePath = upload.name.split(/\\|\//);
		let fileName = filePath[filePath.length - 1];

		return {
			file_name: fileName,
			file_path: upload.name,
			content_type: upload.type
		};
	}

	handleFilesSelected(newFiles) {
		const { selectedFiles } = this.state;
		const { singleFileOnly } = this.props;
		if (singleFileOnly) {
			selectedFiles.length = 0;
			selectedFiles.push(newFiles[0]);
		} else {
			newFiles.forEach((upload) => {
				selectedFiles.push(upload);
			});
		}
		this.setState(
			{
				selectedFiles: selectedFiles
			},
			() => {
				this.validateFileType();
			}
		);
		this.props.updateFilesSelected(true);
	}

	removeFile(fileName) {
		const { selectedFiles } = this.state;
		if (this.props.disabled) {
			return;
		}
		// TODO: Not sure this accounts for same name files in diff paths
		const filteredFiles = selectedFiles.filter(
			(file) => file.file_name !== fileName
		);
		this.setState(
			{
				selectedFiles: filteredFiles
			},
			() => {
				this.validateFileType();
			}
		);
		this.props.updateFilesSelected(filteredFiles.length > 0);
	}

	validateFileType() {
		const { validateFileType } = this.props;
		const { selectedFiles } = this.state;
		if (validateFileType) {
			validateFileType([...selectedFiles]);
		}
	}

	async initiateTransfer() {
		const { onCompleteFunction, getToken, prefetchedTransferSpecs } = this.props;
		const { selectedFiles, entityType, entityId } = this.state;

		try {
			let transferSpecs = prefetchedTransferSpecs;
			if (!transferSpecs) {
				const fileUploadPayload = {
					entity_type: entityType,
					entity_id: entityId,
					files: selectedFiles
				};
				const token = (getToken ? await getToken() : undefined);
				const response = await getFileUploadSpec(fileUploadPayload, token);
				transferSpecs = response.data;
			} else {
				// this is a hack to get the callback in setState() to execute
				await sleep(1);
			}
			this.setState(
				{
					uploadSpec: transferSpecs
				},
				() => {
					this.runAsperaUpload();
				}
			);
		} catch (err) {
			toasterNotify(
				createMessageForError(err, 'retrieving upload specs'),
				'error',
				err
			);
		} finally {
			if (onCompleteFunction != null) {
				onCompleteFunction();
			}
		}
	}

	runAsperaUpload() {
		const { uploadSpec } = this.state;
		uploadSpec.transfer_specs.forEach((specification) => {
			if (!specification) {
				return;
			}
			this.startFileUpload(specification.transfer_spec);
		});
	}

	startFileUpload(spec) {
		const { errorHandler } = this.state;
		const getFileName = this.getFileName;
		if (spec && !spec.error) {
			spec.create_dir = true;
			spec.authentication = 'token';
			this.session.startTransfer(
				spec,
				{},
				{
					success: function() {
						toasterNotify(
							'Uploading ' + getFileName(spec) + '...',
							'info'
						);
					},
					error: function() {
						errorHandler('An Unexpected Error Occurred');
						toasterNotify(
							'File upload error on ' + getFileName(spec),
							'error'
						);
					}
				}
			);
		} else {
			errorHandler(spec ? spec.error : 'An Unexpected Error Occurred');
			toasterNotify('File upload error', 'error');
		}
	}

	getFileName(spec) {
		let fileName = spec.paths[0].destination;
		if (fileName.length > 20) {
			fileName = fileName.slice(0, 20);
		}
		return fileName;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.isUploadInitiated && !prevProps.isUploadInitiated) {
			this.setState(
				{
					//TODO: May need to disable UI pieces when initiated
					isUploadInitiated: this.props.isUploadInitiated
				},
				() => {
					this.initiateTransfer();
				}
			);
		}
		if (this.props.entityId && prevProps.entityId !== this.props.entityId) {
			this.setState({ entityId: this.props.entityId });
		}
	}

	render() {
		const { allowedFileTypes, singleFileOnly, disabled } = this.props;
		const { selectedFiles } = this.state;

		return (
			<div>
				<input
					type="button"
					value={!singleFileOnly ? 'Select files...' : 'Select file...'}
					onClick={this.initFileSelection.bind(
						this,
						'initFileSelection'
					)}
					disabled={this.props.disabled}
				/>
				{selectedFiles && selectedFiles.length > 0 && (
					<div className={disabled ? 'AsperaFileUpload__disabled' : undefined}>
						{selectedFiles.map((file, index) => {
							const wrongType = allowedFileTypes && !allowedFileTypes.find(type =>
								doesFileHaveExtension(file.file_name, type.extensions)
							);
							return (
								<div key={index} style={{'wordBreak': 'break-word'}}>
									<FormLabel
										className={wrongType ? 'text-danger' : ''}
										id={file.file_name + '_control_label'}
										key={index + '_control_label'}
									>
										{file.file_name}
										<Button
											variant="plain"
											className="AsperaFileUpload__remove-file ml-1"
											id={file.file_name + '_button'}
											key={index + '_button'}
											onClick={() => {
												this.removeFile(file.file_name);
											}}
											disabled={this.props.disabled}
										>
											<FAIcon name="times-square" className={!this.props.disabled && 'text-danger'} />
										</Button>
									</FormLabel>

								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}

export default AsperaFileUpload;
