import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

import InfoRow from './InfoRow';
import Panel from '../../../components/Panel/Panel';
import { SubmissionAsset } from './SubmissionAsset';
import { dateFormat, formatDate, parseDateString } from '../../../utils/dateUtils';

import './SubmissionSidebar.css';

class SubmissionSidebar extends Component {
	displaySubmissionFiles() {
		return (
			<ul>
				{this.props.submissionFiles.map((item, i) => (
					<li key={'item-' + i}>
						{
							<SubmissionAsset
								key={i}
								asset={item}
								userProfile={this.props.userProfile}
							/>
						}
					</li>
				))}
			</ul>
		);
	}

	renderFeatureStatus(value) {
		return value === true ? 'YES' : 'NO';
	}

	renderWiiUProperties() {
		const { submission } = this.props;
		return (
			<div>
				<InfoRow
					title="Header CRC"
					value={
						submission.header_crc ? submission.header_crc : 'None'
					}
				/>
				<InfoRow
					title="Data CRC"
					value={submission.data_crc ? submission.data_crc : 'None'}
				/>
			</div>
		);
	}

	renderGenericProperties() {
		const { submission } = this.props;
		return (
			<div>
				<InfoRow
					title="ROM CXI (CRC32)"
					value={submission.rom_cxi ? submission.rom_cxi : 'None'}
				/>
				<InfoRow
					title="E-Manual CFA (CRC32)"
					value={
						submission.e_manual_cfa
							? submission.e_manual_cfa
							: 'None'
					}
				/>
				<InfoRow
					title="Master Data Info (CRC32)"
					value={
						submission.master_data_info
							? submission.master_data_info
							: 'None'
					}
				/>
			</div>
		);
	}

	render() {
		const { submission, submissionVersion, isWUP } = this.props;

		return (<>
			<Panel>
				<Panel.Heading>
					<Panel.Title>Submission Files</Panel.Title>
				</Panel.Heading>
				<Panel.Body>
					{this.props.submissionFiles && this.displaySubmissionFiles()}
				</Panel.Body>
			</Panel>
			<Panel>
				<Panel.Heading>
					<Panel.Title>Features</Panel.Title>
				</Panel.Heading>
				<Panel.Body>
					<Table responsive>
						<tbody>
							<tr>
								<td>Online Multi Play</td>
								<td>{this.renderFeatureStatus(submission.online_mp)}</td>
							</tr>

							<tr>
								<td>Online Commerce</td>
								<td>{this.renderFeatureStatus(submission.online_commerce)}</td>
							</tr>

							<tr>
								<td>Consumable AOC</td>
								<td>{this.renderFeatureStatus(submission.consumable_aoc)}</td>
							</tr>

							<tr>
								<td>UGC</td>
								<td>{this.renderFeatureStatus(submission.ugc)}</td>
							</tr>

							<tr>
								<td>Wifi</td>
								<td>{this.renderFeatureStatus(submission.wifi)}</td>
							</tr>
						</tbody>
					</Table>
				</Panel.Body>
			</Panel>

			<Panel>
				<Panel.Heading>
					<Panel.Title>Submission Properties</Panel.Title>
				</Panel.Heading>
				<Panel.Body>
					<InfoRow title="Submission Type" value={submission.type} />
					<InfoRow
						title="Submission Date"
						value={formatDate(
							parseDateString(submission.submission_date),
							dateFormat.DATETIME_PT,
						)}
					/>
					<InfoRow title="Game Code" value={submission.product_type} />
					<InfoRow title="Version" value={submissionVersion} />
					<InfoRow
						title="File Name"
						value={submission.file_name ? submission.file_name : 'None'}
					/>
					{isWUP ? this.renderWiiUProperties() : this.renderGenericProperties()}
				</Panel.Body>
			</Panel>
		</>);
	}
}

export default SubmissionSidebar;
