import React, { Component } from 'react';
import { Button, Col, Form, FormCheck, FormControl, FormGroup, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import BaseModal from '../../../components/BaseModal/BaseModal';
import DropdownSelect from '../../../components/DropdownSelect/DropdownSelect';


function ModalInput(props) {
	return (
		<FormGroup as={Row}>
			<Form.Label className="text-sm-right" column sm={4}>
				{props.label}
			</Form.Label>
			<Col sm={7}>
				<FormControl
					name={props.name}
					type="text"
					value={props.value}
					onChange={(e) => props.onChange(e.target)}
					maxLength="40"
					{...(!props.edit ? { disabled: true } : null)}
				/>
			</Col>
		</FormGroup>
	);
}
ModalInput.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	edit: PropTypes.bool
};
ModalInput.defaultProps = {
	edit: true,
	value: ''
};

function ModalMultiSelect(props) {
	const options = props.optionValues.map(o => ({value: o.value, label: o.text}));
	const values = options.filter(o => Array.isArray(props.values) && props.values?.includes(o.value));
	return (
		<FormGroup as={Row}>
			<Form.Label className="text-sm-right" column sm={4}>
				{props.label}
			</Form.Label>
			<Col sm={7}>
				<DropdownSelect 
					isSearchable
					isMulti
					options={options}
					value={values}
					onChange={(newValue) => props.multiSelectChange(
						null, 
						{ value: newValue.map(v => v.value) }
					)}
				/>
			</Col>
		</FormGroup>
	);
}

ModalMultiSelect.propTypes = {
	label: PropTypes.string,
	optionValues: PropTypes.array,
	multiSelectChange: PropTypes.func
};

function ModalCheckbox(props) {
	return (
		<FormGroup as={Row}>
			<Form.Label className="text-sm-right col-sm-4">{props.label}</Form.Label>
			<Col sm={7}>
				<FormCheck
					type="checkbox"
					id={props.id || 'checkbox-' + props.label}
					checked={props.publisherOwned ? 'checked' : ''}
					onChange={(e) => props.onChange(e)}
					label="YES"
				/>
			</Col>
		</FormGroup>
	);
}

function ModalSelect(props) {
	return (
		<FormGroup as={Row}>
			<Form.Label className="text-sm-right" column sm={4}>{props.label}</Form.Label>
			<Col sm={7}>
				<select
					disabled={props.edit ? '' : 'disabled'}
					name={props.name}
					className="form-control"
					value={props.value}
					onChange={(e) => props.onChange(e.target)}
				>
					{props.optionValues}
				</select>
			</Col>
		</FormGroup>
	);
}

ModalSelect.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	optionValues: PropTypes.array,
	edit: PropTypes.bool
};

class EditFacilityModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			publisherOwned: false,
			facilityData: {},
			isLoading: false
		};

		this.getModalFooter = this.getModalFooter.bind(this);
		this.togglePublisherOwned = this.togglePublisherOwned.bind(this);
		this.collectFormData = this.collectFormData.bind(this);
		this.isFormValid = this.isFormValid.bind(this);
	}

	getCountryOptionValues() {
		return this.props.countryCodes.map((country, key) => {
			return (
				<option
					key={'country_option_' + key}
					value={country.country_code}
				>
					{country.country_name}
				</option>
			);
		});
	}

	getCompanyOptions() {
		const { companies } = this.props;
		if (companies && companies.length > 0) {
			return companies
				.sort((a, b) => {
					return ('' + a.company_name).localeCompare(b.company_name);
				})
				.map((company) => {
					return {
						key: company.ndid_company_id,
						text: company.company_name,
						value: company.ndid_company_id
					};
				});
		}
		return [];
	}

	getFacilityAllowedCompanies() {
		const { allowed_companies } = this.props.facility;

		if (allowed_companies && allowed_companies.length > 0) {
			return allowed_companies.map((name) => {
				let result = this.props.companies.find((company) => {
					return name === company.company_name;
				});

				if (result) {
					return result.ndid_company_id;
				}
				return null;
			});
		}
		return [];
	}

	editCreateFacility(e) {
		e.preventDefault();
		this.setState({
			isLoading: true
		});
		if (this.props.edit) {
			this.props.onFacilityUpdate(
				this.state.facilityData.packout_facility_id,
				this.state.facilityData
			);
		} else {
			this.props.onFacilityCreate(e, this.state.facilityData);
		}
	}

	getModalFooter() {
		const isFormValid = this.isFormValid();
		const { isLoading } = this.state;
		return (
			<div>
				<Button className="pull-left" onClick={this.props.closeModal}>
					Cancel
				</Button>
				<Button
					variant="primary"
					onClick={(e) => {
						this.editCreateFacility(e);
					}}
					disabled={!isFormValid || isLoading}
				>
					{this.props.edit ? 'Save' : 'Create'}
				</Button>
			</div>
		);
	}

	togglePublisherOwned() {
		let { facilityData, publisherOwned } = this.state;

		if (publisherOwned) {
			facilityData.allowed_companies = [];
		}

		this.setState({
			facilityData: facilityData,
			publisherOwned: !publisherOwned
		});
	}

	componentDidMount() {
		const { facility } = this.props;

		let facilityData = {
			packout_facility_id: facility.packout_facility_id,
			name: facility.name || '',
			address_1: facility.address_1 || '',
			address_2: facility.address_2 || '',
			city: facility.city || '',
			state: facility.state || '',
			postal_code: facility.postal_code || '',
			country_code: facility.country_code || 'US',
			phone: facility.phone || '',
			status: facility.status || '',
			allowed_companies: facility.allowed_companies || []
		};

		if (this.isPublisherOwned()) {
			facilityData.allowed_companies = this.getFacilityAllowedCompanies();

			this.setState({
				facilityData: facilityData,
				publisherOwned: true
			});
		} else {
			this.setState({ facilityData });
		}
	}

	isPublisherOwned() {
		return (
			this.props.edit &&
			this.props.facility.allowed_companies &&
			this.props.facility.allowed_companies.length > 0
		);
	}

	collectFormData(el) {
		this.setState((prevState) => ({
			facilityData: {
				...prevState.facilityData,
				[el.name]: el.value
			}
		}));
	}

	isFormValid() {
		const { publisherOwned } = this.state;
		const { edit } = this.props;
		const fields = this.state.facilityData;

		let hasError = false;
		let fieldNames = [
			'name',
			'address_1',
			'city',
			'state',
			'postal_code',
			'country_code',
			'phone'
		];
		if (edit) {
			fieldNames = ['name', 'phone'];
		}

		if (publisherOwned) {
			fieldNames.push('allowed_companies');
		}

		if (fields) {
			fieldNames.forEach((name) => {
				hasError = hasError || !fields[name] || !fields[name].length;
			});
		}

		return !hasError;
	}

	render() {
		const { edit } = this.props;
		const {
			name,
			address_1,
			address_2,
			city,
			state,
			postal_code,
			country_code,
			phone,
			allowed_companies
		} = this.state.facilityData;

		const isFormValid = this.isFormValid();

		return (
			<BaseModal show={true} onCancel={this.props.closeModal} isSubmitting={this.props.isLoading || this.state.isLoading}>
				<BaseModal.Title>
					{this.props.edit
						? 'Edit Packout Facility'
						: 'Add New Packout Facility'}
				</BaseModal.Title>
				<Form className="review-order-form">
					<ModalInput
						onChange={this.collectFormData}
						name="name"
						value={name}
						edit={!edit}
						label="Facility Name"
					/>
					<ModalInput
						onChange={this.collectFormData}
						name="address_1"
						value={address_1}
						edit={!edit}
						label="Address 1"
					/>
					<ModalInput
						onChange={this.collectFormData}
						name="address_2"
						value={address_2}
						edit={!edit}
						label="Address 2"
					/>
					<ModalInput
						onChange={this.collectFormData}
						name="city"
						value={city}
						edit={!edit}
						label="City"
					/>
					<ModalInput
						onChange={this.collectFormData}
						name="state"
						value={state}
						edit={!edit}
						label="State / Province"
					/>
					<ModalInput
						onChange={this.collectFormData}
						name="postal_code"
						value={postal_code}
						edit={!edit}
						label="Postal Code"
					/>
					<ModalSelect
						onChange={this.collectFormData}
						name="country_code"
						edit={!edit}
						value={country_code}
						optionValues={this.getCountryOptionValues()}
						label="Country"
					/>
					<ModalInput
						onChange={this.collectFormData}
						value={phone}
						name="phone"
						label="Phone Number"
					/>
					<ModalCheckbox
						publisherOwned={this.state.publisherOwned}
						label="Publisher Owned"
						onChange={this.togglePublisherOwned}
					/>
					{this.state.publisherOwned ? (
						<ModalMultiSelect
							multiSelectChange={(e, { value }) =>
								this.collectFormData({
									name: 'allowed_companies',
									value: value
								})
							}
							name="allowed_companies"
							label="Publisher(s)"
							values={allowed_companies}
							optionValues={this.getCompanyOptions()}
						/>
					) : null}
				</Form>
				<BaseModal.Submit onClick={(e) => this.editCreateFacility(e)} disabled={!isFormValid}>
					{this.props.edit ? 'Save' : 'Create'}
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}

EditFacilityModal.propTypes = {
	admin: PropTypes.bool,
	edit: PropTypes.bool,
	isLoading: PropTypes.bool,
	countryCodes: PropTypes.array,
	companies: PropTypes.array,
	facility: PropTypes.object,
	onFacilityUpdate: PropTypes.func,
	onFacilityCreate: PropTypes.func
};

EditFacilityModal.defaultProps = {
	edit: false,
	isLoading: false,
	facility: {}
};

export default EditFacilityModal;
