import { AxiosResponse } from 'axios';
import { eventConstants } from '../constants/eventConstants';
import { PaginatedCall } from '../utils/serviceUtils';
import api from './baseService';

export const getEvents = () =>
	api().get('/marketing/events');
	
export const postMarketingEvents = (payload: Record<string, any>) =>
	api().post('/marketing/events', payload);
	
export const getEventDetails = (eventId: string) =>
	api().get(`/marketing/events/${eventId}`);

export const putUpdateMarketingEvents = (eventId: string, payload: Record<string, any>) =>
	api().put(`/marketing/events/${eventId}`, payload);

export const deleteEvent = (eventId: string) =>
	api().delete(`/marketing/events/${eventId}`);

export const getEventProducts = (eventId: string) =>
	api().get(`/marketing/events/${eventId}/products`);

export const postEventProduct = (eventId: string, payload: Record<string, any>) =>
	api().post(`/marketing/events/${eventId}/products`, payload);

export const postEventTask = (eventId: string, payload: Record<string, any>) =>
	api().post(`/marketing/events/${eventId}/tasks`, payload);

export const putEventTask = (taskId: string, payload: Record<string, any>) =>
	api().put(`/marketing/events/tasks/${taskId}`, payload);

export const postEventTaskToken = (taskId: string) =>
	api().post(`/marketing/events/tasks/${taskId}/token`);

export const putUpdateEventSupport = (eventId: string, payload: Record<string, any>) =>
	api().put(`/marketing/events/${eventId}/support`, payload);

export const getEventSystemAssetLanguages = () =>
	api({useCache: true}).get('/marketing/events/asset_languages');

interface EventCategory {
	TRADESHOW: string;
	DEVELOPER_EVENT: string;
	NINTENDO_NEXT: string;
	VIDEO: string;
	ESHOP_SALE: string;
	PRODUCT_RELEASE: string;
}

export const getEventCategories = () =>
	api().get<EventCategory>('/marketing/events/categories');

export const getEventProduct = (eventProductId: string) =>
	api().get(`/marketing/events/products/${eventProductId}`);

export const putEventProduct = (eventProductId: string, payload: Record<string, any>) =>
	api().put(`/marketing/events/products/${eventProductId}`, payload);

export const deleteEventProduct = (productId: string, callback: (response?: AxiosResponse<unknown>) => void) =>
	api().delete(`/marketing/events/products/${productId}`, {
		pollingUrl: '/marketing/events/executions/',
		pollingTimeLimit: 120000,
		pollingCallback: callback
	});

export const postNewEventProductAsset = (productId: string, payload: Record<string, any>) =>
	api().post(`/marketing/events/products/${productId}/assets`, payload);

export const getEventAssetsApprovalList = () =>
	api().get('/marketing/events/products/assets');

export const getEventProductAsset = (assetId: string) =>
	api().get(`/marketing/events/products/assets/${assetId}`);

export const putUpdateMarketingEventProductAsset = (assetId: string, payload: Record<string, any>) =>
	api().put(`/marketing/events/products/assets/${assetId}`, payload);

export const deleteProductAsset = (assetId: string) =>
	api().delete(`/marketing/events/products/assets/${assetId}`);

export const postGeneratePdbAccessToken = (assetId: string, operation: 'view' | 'comment' | 'review' | 'upload') =>
	api().post(`/marketing/events/products/assets/${assetId}/token?operation=${operation}`);

export const getSupportLevels = () =>
	api({useCache: true}).get('/marketing/events/products/support_levels');

export const getEventReportAssets: PaginatedCall = (count, limit, offset) =>
	api().get('/marketing/events/reports/assets/', { params: { count, limit, offset } });

export const getEventReportProducts: PaginatedCall = (count, limit, offset) =>
	api().get('/marketing/events/reports/products/', { params: { count, limit, offset } });

export const getEventSystemFamilies = () =>
	api({useCache: true}).get('/marketing/events/system_families');

export const getEventTasks = (eventId: string) =>
	api().get(`/marketing/events/${eventId}/tasks`);

export const getEventTaskDetails = (taskId: string)  =>
	api().get(`/marketing/events/tasks/${taskId}`);

export const putCompleteTask = (taskId: string) =>
	api().put(`/marketing/events/tasks/${taskId}`, { status: eventConstants.TASKS.STATUS.COMPLETED });

export const putClaimTask = (taskId: string, assigneeId: string) =>
	api().put(`/marketing/events/tasks/${taskId}`, { assigned_ndid_user_id: assigneeId });

export const deleteEventTask = (taskId: string) =>
	api().delete(`/marketing/events/tasks/${taskId}`);

export const getEventReportTasks: PaginatedCall = (count, limit, offset) =>
	api().get('/marketing/events/reports/tasks', { params: { count, limit, offset } });
