import React from 'react';

import BaseModal from '../../BaseModal/BaseModal';


const SimpleModal = ({
	show = false,
	size = '',
	title = '',
	closeModal,
	children,
	isLoading = false,
	ctaText = 'Close',
}) => (
	<BaseModal show={show} onCancel={closeModal} isLoading={isLoading} size={size}>
		<BaseModal.Title>{title}</BaseModal.Title>
		{children}
	</BaseModal>
);

export default SimpleModal;
