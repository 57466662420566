import React, { useEffect, useState, VFC } from 'react';
import { Button, Col, FormCheck, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import FAIcon from '../../../components/FAIcon/FAIcon';
import Forms from '../../../components/Forms/Forms';
import Loading from '../../../components/Loading/Loading';
import LoadingText from '../../../components/Loading/LoadingText';
import AssociatePackoutFacilityModalAdapter from './AssociatePackoutFacilityModalAdapter';
import ConsigneeModal from '../../../components/modals/ConsigneeModal/ConsigneeModal';
import AssociateFreightForwarderModal from '../../../components/modals/FreightForwarderModal/AssociateFreightForwarderModal';
import Page from '../../../components/Page/Page';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { permConst } from '../../../constants/permConst';
import { useUserProfile } from '../../../hooks/reduxHooks';
import {
	getShippingInfo,
	OrderShippingPayload,
	putSaveOrderShippingDetails
} from '../../../services/ordersService';
import { voidIfEmpty } from '../../../utils/arrayUtils';
import {
	getPossiblyInvalidValue,
	isEmptyObject,
	transformUndefinedToNull
} from '../../../utils/dataUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { isAuthorized } from '../../../utils/userUtils';
import { validateToSchema } from '../../../utils/validationUtils';
import { useCompanyProfileQuery } from '../../../hooks/queryHooks';
import ConsigneeContactModal from '../../../components/modals/ConsigneeContactModal/ConsigneeContactModal';


export const step2Schema = yup.object().shape({
	port_of_entry_code: yup.string().required('A port of entry must be selected'),
	freight_forwarder_code: yup.string().required('A freight forwarder must be selected'),
	packout_facility_id: yup
		.number()
		.required('A packout facility must be selected')
		.test(
			'undefined_check',
			'A packout facility must be selected',
			(value, { options }) => options.context?.selectedPackoutFacility,
		),
	shipping_instructions: yup
		.string()
		.optional()
		.trim()
		.nullable()
		.transform((value) => value || null),
	packout_facility_contact_name: yup
		.string()
		.trim()
		.required('A packout facility contact name must be provided'),
	packout_facility_contact_phone_number: yup
		.string()
		.trim()
		.required('A packout facility contact phone number must be provided'),
	packout_facility_contact_email: yup
		.string()
		.trim()
		.required('A packout facility contact email address must be provided'),
	consignee_company_id: yup
		.number()
		.required('A consignee must be selected'),
	consigneeContact: yup.mixed().nullable()
		.transform(() => undefined)
		.test(
			'contact_not_missing',
			'Selected consignee is missing contact information. Please use the link below to fill these details.',
			(value, { options }) => {
				const selectedConsignee = options?.context?.selectedConsignee;
				return selectedConsignee == null || (
					!!(selectedConsignee?.consignee_email_address) &&
					!!(selectedConsignee?.consignee_phone_number)
				);
			},
		),
});

interface FormValuesData {
	port_of_entry_code: string;
	freight_forwarder_code: string;
	packout_facility_id: number;
	self_consigned: boolean;
	shipping_instructions: string;
	packout_facility_contact_name: string;
	packout_facility_contact_phone_number: string;
	packout_facility_contact_email: string;
	consignee_company_id: number;
}
interface OpenModalData {
	type?:
		| 'ADD_CONSIGNEE_MODAL'
		| 'EDIT_CONSIGNEE_MODAL'
		| 'EDIT_CONSIGNEE_CONTACT_MODAL'
		| 'FREIGHT_FORWARDER_MODAL'
		| 'PACKOUT_FACILITY_MODAL';
}
interface Step2ShippingProps {
	orderHeaderId?: string;
	onNext: () => void;
	onPrevious: () => void;
	status?: string;
	onCancelOrder?: () => void;
	onDeleteOrder?: () => void;
	setSaveCall: (callback: () => void) => void;
}
const Step2Shipping: VFC<Step2ShippingProps> = ({
	orderHeaderId,
	onNext,
	onPrevious,
	status,
	onCancelOrder,
	onDeleteOrder,
	setSaveCall,
}) => {
	const userProfile = useUserProfile();

	const [formValues, setFormValues] = useState<Partial<FormValuesData | null>>(null);
	const [showAllErrors, setShowAllErrors] = useState<boolean>(false);
	const [openModal, setOpenModal] = useState<OpenModalData>({});
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const companyProfileQuery = useCompanyProfileQuery({ refetchOnMount: true });
	const companyProfile = companyProfileQuery.data?.data?.company_information;

	const shippingQuery = useQuery(
		['getShippingInfo', orderHeaderId],
		() => getShippingInfo(orderHeaderId as string),
		{
			enabled: !!orderHeaderId,
			onSettled: (response) => {
				const orderData = response?.data.physical_order;
				const defaultFreightForwarderCode = response?.data.freight_forwarders.find(
					(ff) => ff.company_freight_forwarder.default_flag,
				)?.code;
				const defaultConsigneeId = response?.data.consignees.find(
					(consignee) => consignee.default_flag,
				)?.consignee_company_id;
				if (orderData && formValues === null) {
					const defaultFormValues = {
						packout_facility_contact_name:
							orderData.packout_facility_contact_name || undefined,
						packout_facility_contact_phone_number:
							orderData.packout_facility_contact_phone_number || undefined,
						packout_facility_contact_email:
							orderData.packout_facility_contact_email || undefined,
						port_of_entry_code:
							getPossiblyInvalidValue<string | null>(orderData.port_of_entry_code) ||
							undefined,
						freight_forwarder_code:
							getPossiblyInvalidValue<string | null>(
								orderData.freight_forwarder_code,
							) || defaultFreightForwarderCode,
						packout_facility_id:
							getPossiblyInvalidValue<number | null>(orderData.packout_facility_id) ||
							undefined,
						consignee_company_id: orderData.self_consigned && !cannotSelfAssignConsignee
							? -1
							: getPossiblyInvalidValue<number | null>(
									orderData.consignee_company_id,
							  ) || defaultConsigneeId,
						shipping_instructions: orderData.order_comments?.['SHIPPING_INFO']?.comment,
					};
					setFormValues(defaultFormValues);
				}
			},
		},
	);

	const cannotSelfAssignConsignee =
		!companyProfile ||
		companyProfile.company_name.length > 60 ||
		companyProfile.city?.length > 60 ||
		companyProfile.region?.length > 60 ||
		[
			companyProfile.address_1 || '',
			companyProfile.address_2 || '',
		].join(' ,').length > 240 ||
		[
			companyProfile.company_name,
			companyProfile.address_1,
			companyProfile.address_2,
			companyProfile.city,
			companyProfile.region,
			companyProfile.postal_code,
		].join().match(/[^\u0020-\u007e]/) !== null;

	const consignees = shippingQuery.data?.data.consignees;
	const freightForwarders = shippingQuery.data?.data.freight_forwarders;
	const portsOfEntry = shippingQuery.data?.data.ports_of_entry;
	const packoutFacilities = shippingQuery.data?.data.packout_facilities;

	const userCompanyName = userProfile.companyName;

	const selectedFreightForwarder = freightForwarders?.find(
		(forwarders) => forwarders.code === formValues?.freight_forwarder_code,
	)?.company_freight_forwarder;
	const serviceLevel = selectedFreightForwarder
		? selectedFreightForwarder?.service_level || '—'
		: null;
	const carrierAccountNumber = selectedFreightForwarder
		? selectedFreightForwarder?.carrier_account_number || '—'
		: null;
	const japaneseContact = selectedFreightForwarder
		? [
			selectedFreightForwarder?.japanese_contact_name || '—',
			selectedFreightForwarder?.japanese_contact_email
				? 'Email: ' + selectedFreightForwarder?.japanese_contact_email
				: null,
			selectedFreightForwarder?.japanese_contact_phone_number
				? 'Phone: ' + selectedFreightForwarder?.japanese_contact_phone_number
				: null,
		  ]
		: null;
	const selectedPackoutFacility = packoutFacilities?.find(
		(facility) => facility.packout_facility_id === formValues?.packout_facility_id,
	);
	const packoutFacilityAddress = selectedPackoutFacility
		? [
			selectedPackoutFacility.street_line_1,
			selectedPackoutFacility.street_line_2,
			[selectedPackoutFacility.city, selectedPackoutFacility.region]
					.filter((v) => !!v)
					.join(', '),
			selectedPackoutFacility.country_name,
		  ]
		: [];

	const selectedConsignee =
		consignees && formValues?.consignee_company_id !== -1
			? consignees?.find(
					(consignee) =>
						consignee.consignee_company_id === formValues?.consignee_company_id,
			  )
			: null;
	const consigneeAddress =
		formValues?.consignee_company_id === -1
			? [
				companyProfile?.address_1,
				companyProfile?.address_2,
				[companyProfile?.city, companyProfile?.region]
						.filter((v) => !!v)
						.join(', '),
				companyProfile?.country_name,
				companyProfile?.consignee_email_address,
				companyProfile?.consignee_phone_number,
			  ]
			: [
				selectedConsignee?.street_line_1,
				selectedConsignee?.street_line_2,
				[selectedConsignee?.city, selectedConsignee?.region]
						.filter((v) => !!v)
						.join(', '),
				selectedConsignee?.country_name,
				selectedConsignee?.consignee_email_address,
				selectedConsignee?.consignee_phone_number,
			  ];

	const canEditFreightForwarderAssociation = isAuthorized(userProfile.permissions, [
		permConst.FREIGHT_FORWARDER.EDIT.COMPANY,
	]);
	const canEditConsignees = isAuthorized(userProfile?.permissions, [
		permConst.CONSIGNEE.EDIT.COMPANY,
	]);
	const canEditPackoutFacilities = isAuthorized(userProfile?.permissions, [
		permConst.COMPANY.PACKOUT_FACILITY.EDIT.COMPANY,
	]);
	const validationErrors = validateToSchema(step2Schema, formValues, {
		selectedPackoutFacility: !!selectedPackoutFacility,
		selectedConsignee: formValues?.consignee_company_id === -1 ? companyProfile : selectedConsignee
	});

	const sortConsigneesByDefault = (array?: typeof consignees) => {
		if (!Array.isArray(array)) {
			return;
		}
		return [...array].sort((a, b) =>
			a.default_flag && !b.default_flag ? -1 : !a.default_flag && b.default_flag ? 1 : 0,
		);
	};
	const sortFreightForwardersByDefault = (ff: typeof freightForwarders) => {
		if (!Array.isArray(ff)) {
			return;
		}
		return [...ff].sort((a, b) =>
			a.company_freight_forwarder.default_flag && !b.company_freight_forwarder.default_flag
				? -1
				: !a.company_freight_forwarder.default_flag &&
				  b.company_freight_forwarder.default_flag
					? 1
					: 0,
		);
	};

	const saveAndBacktrack = async () => {
		await submitForm();
		onPrevious();
	};

	const submitForm = async (advanceToNext: boolean = false) => {
		// check validation
		if (advanceToNext && !isEmptyObject(validationErrors)) {
			setShowAllErrors(true);
			toasterNotify(
				'Required fields must be filled with valid values to continue. See validation messages in the form for specific issues.',
				'error',
			);
			return;
		}

		// format payload
		const {consigneeContact, ...castedFormValues} = step2Schema.cast(formValues, { stripUnknown: true });

		const payload: OrderShippingPayload = transformUndefinedToNull({
			port_of_entry_code: null,
			freight_forwarder_code: null,
			packout_facility_id: null,
			consignee_company_id: null,
			shipping_instructions: null,
			packout_facility_contact_name: null,
			packout_facility_contact_phone_number: null,
			packout_facility_contact_email: null,
			...(advanceToNext ? castedFormValues : formValues),
			action: advanceToNext ? 'submit' : 'save',
		}) as OrderShippingPayload;

		if (payload.consignee_company_id == null) {
			payload['self_consigned'] = false;
			payload['consignee_company_id'] = null;
		} else if (payload.consignee_company_id === -1) {
			payload['self_consigned'] = true;
			payload['consignee_company_id'] = null;
		} else {
			payload['self_consigned'] = false;
		}

		if (!!payload.packout_facility_id && !selectedPackoutFacility){
			payload['packout_facility_id'] = null;
		}

		// transactions
		setIsSubmitting(true);
		if (!orderHeaderId) {
			return;
		}
		try {
			await putSaveOrderShippingDetails(orderHeaderId, payload);
			toasterNotify('Order was saved successfully', 'success');
			if (advanceToNext) {
				onNext();
			}
		} catch (error: unknown) {
			if (error instanceof Error) {
				toasterNotify(createMessageForError(error, 'saving the order'), 'error', error);
			}
		}

		setIsSubmitting(false);
	};

	useEffect(() => {
		setSaveCall(() => {
			submitForm();
		});
	});

	const isLoading = shippingQuery.isLoading || shippingQuery.isRefetching;
	return (
		<>
			<Page.FullPageCol>
				{isLoading || formValues === null ? (
					<Loading />
				) : (
					<>
						<Forms
							values={formValues}
							onChange={(newFormValues) => {
								const newSet = { ...newFormValues };
								if (
									formValues?.packout_facility_id &&
									newFormValues.packout_facility_id !==
										formValues.packout_facility_id
								) {
									newSet['packout_facility_contact_name'] = undefined;
									newSet['packout_facility_contact_phone_number'] = undefined;
									newSet['packout_facility_contact_email'] = undefined;
								}
								setFormValues(newSet);
							}}
							showAllErrors={showAllErrors}
							validationErrors={validationErrors}
							disabled={isSubmitting}
						>
							<SectionTitle>Freight Forwarder Information </SectionTitle>
							<Row>
								<Col sm={8}>
									<Forms.Select id="port_of_entry_code">
										<Forms.Heading>Customs Port of Entry</Forms.Heading>
										{portsOfEntry?.map(
											({ port_of_entry_code, port_of_entry_name }) => (
												<Forms.Option
													key={port_of_entry_code}
													value={port_of_entry_code}
												>
													{port_of_entry_name}
												</Forms.Option>
											),
										)}
									</Forms.Select>
									<Forms.Select id="freight_forwarder_code">
										<Forms.Heading>Freight Forwarder</Forms.Heading>
										{sortFreightForwardersByDefault(freightForwarders)?.map(
											({ code, name }) => (
												<Forms.Option key={code} value={code}>
													{name}
												</Forms.Option>
											),
										)}
										{canEditFreightForwarderAssociation && (
											<Forms.Postscript>
												<Button
													variant="link"
													onClick={() =>
														setOpenModal({
															type: 'FREIGHT_FORWARDER_MODAL',
														})
													}
												>
													Associate new freight forwarder
												</Button>
											</Forms.Postscript>
										)}
									</Forms.Select>
									<Forms.CustomArea>
										<Forms.Heading>Service Level </Forms.Heading>
										{serviceLevel}
									</Forms.CustomArea>
									<Forms.CustomArea>
										<Forms.Heading>Carrier Account # </Forms.Heading>
										{carrierAccountNumber}
									</Forms.CustomArea>
									<Forms.CustomArea>
										<Forms.Heading>Japanese Contact</Forms.Heading>
										{japaneseContact?.map((v, index) =>
											v ? <div key={`${index}-${v}`}>{v}</div> : null,
										)}
									</Forms.CustomArea>
								</Col>
							</Row>
							<SectionTitle>Consignee Information</SectionTitle>

							<Row>
								<Col sm={8}>
									<Forms.Select id="consignee_company_id">
										<Forms.Heading>Consignee Company</Forms.Heading>
										<Forms.Preface>
											<FormCheck
												id="checkbox-self-consignee"
												type="checkbox"
												onChange={(e) => {
													if (e.target.checked) {
														setFormValues({
															...formValues,
															consignee_company_id: -1,
														});
													} else {
														setFormValues({
															...formValues,
															consignee_company_id: undefined,
														});
													}
												}}
												disabled={isSubmitting || cannotSelfAssignConsignee}
												checked={formValues.consignee_company_id === -1}
												label="Use my company as consignee"
											/>
										</Forms.Preface>
										{!cannotSelfAssignConsignee && (
											<Forms.Option key={'default'} value={-1}>
												{userCompanyName + ' (Your Company)'}
											</Forms.Option>
										)}
										{sortConsigneesByDefault(consignees)?.map(
											({ consignee_company_id, name }) => (
												<Forms.Option
													key={consignee_company_id}
													value={consignee_company_id}
												>
													{name}
												</Forms.Option>
											),
										)}
										{canEditConsignees && (
											<Forms.Postscript>
												<Button
													variant="link"
													onClick={() =>
														setOpenModal({
															type: 'ADD_CONSIGNEE_MODAL',
														})
													}
												>
													Add new consignee
												</Button>
											</Forms.Postscript>
										)}
									</Forms.Select>
									<Forms.CustomArea id="consigneeContact" alwaysShowErrors>
										<Forms.Heading>Consignee Contact</Forms.Heading>
										{!!formValues?.consignee_company_id &&
											(voidIfEmpty(
												consigneeAddress
													?.filter((v) => !!v)
													.map((v, index) => (
														<div key={`${index}-${v}`}>{v}</div>
													)),
											) || <>&mdash;</>)}
										{formValues?.consignee_company_id === -1 &&
											canEditConsignees && (
												<Forms.Postscript>
													<Button
														variant="link"
														onClick={() =>
															setOpenModal({
																type: 'EDIT_CONSIGNEE_CONTACT_MODAL',
															})
														}
													>
														Edit self-consignee contact information
													</Button>
												</Forms.Postscript>
											)}
										{Number(formValues?.consignee_company_id) > 0 &&
											canEditConsignees && (
												<Forms.Postscript>
													<Button
														variant="link"
														onClick={() =>
															setOpenModal({
																type: 'EDIT_CONSIGNEE_MODAL',
															})
														}
													>
														Edit selected consignee
													</Button>
												</Forms.Postscript>
											)}
									</Forms.CustomArea>
								</Col>
							</Row>
							<SectionTitle>Packout Facility</SectionTitle>
							<Row>
								<Col sm={8}>
									<Forms.Select id="packout_facility_id">
										<Forms.Heading>Packout Facility</Forms.Heading>
										{packoutFacilities?.map(({ packout_facility_id, name }) => (
											<Forms.Option
												key={packout_facility_id}
												value={packout_facility_id}
											>
												{name}
											</Forms.Option>
										))}
										{canEditPackoutFacilities && (
											<Forms.Postscript>
												<Button
													variant="link"
													onClick={() =>
														setOpenModal({
															type: 'PACKOUT_FACILITY_MODAL',
														})
													}
												>
													Associate new packout facility
												</Button>
											</Forms.Postscript>
										)}
									</Forms.Select>
									<Forms.CustomArea>
										<Forms.Heading>Address</Forms.Heading>
										{!!formValues.packout_facility_id &&
											(voidIfEmpty(
												packoutFacilityAddress
													?.filter((v) => !!v)
													.map((v, index) => (
														<div key={`${index}-${v}`}>{v}</div>
													)),
											) || <>&mdash;</>)}
									</Forms.CustomArea>
									<Forms.Text id="packout_facility_contact_name">
										<Forms.Heading>Contact Name</Forms.Heading>
									</Forms.Text>
									<Forms.Text id="packout_facility_contact_phone_number">
										<Forms.Heading>Contact Phone</Forms.Heading>
									</Forms.Text>
									<Forms.Text id="packout_facility_contact_email">
										<Forms.Heading>Contact Email</Forms.Heading>
									</Forms.Text>
								</Col>
							</Row>
							<SectionTitle>Comments</SectionTitle>
							<Row>
								<Col sm={8}>
									<Forms.TextArea id="shipping_instructions" rows={4}>
										<Forms.Heading>Shipping Instructions</Forms.Heading>
									</Forms.TextArea>
								</Col>
							</Row>
						</Forms>
						<div className="btn-container">
							<div className="float-left">
								<Button
									variant="outline-secondary"
									disabled={isSubmitting}
									onClick={() => saveAndBacktrack()}
								>
									<FAIcon className="mr-1" name="chevron-left" />
									Previous (Info and Products)
								</Button>
								<Button
									as={Link}
									variant="link"
									to="/orders/physical"
									disabled={isSubmitting}
								>
									Return to Orders
								</Button>
							</div>
							<div className="float-right d-flex">
								{isSubmitting && <LoadingText inline className="m-a" />}
								<Button
									className="ml-3"
									variant="outline-secondary"
									type="button"
									onClick={() => submitForm()}
									disabled={isSubmitting}
								>
									Save
								</Button>
								<Button
									className="ml-3"
									variant="primary"
									onClick={() => submitForm(true)}
									disabled={isSubmitting}
								>
									Next (Confirm Order) <FAIcon name="chevron-right" />
								</Button>
							</div>
						</div>
					</>
				)}
			</Page.FullPageCol>
			<ConsigneeModal
				show={openModal?.type === 'ADD_CONSIGNEE_MODAL'}
				onClose={() => setOpenModal({})}
				onChange={async (returnValue) => {
					await shippingQuery.refetch();
					setOpenModal({});
					setFormValues({ ...formValues, consignee_company_id: returnValue });
				}}
			/>
			<ConsigneeModal
				show={openModal?.type === 'EDIT_CONSIGNEE_MODAL'}
				onClose={() => setOpenModal({})}
				onChange={async (returnValue) => {
					await shippingQuery.refetch();
					setOpenModal({});
					setFormValues({ ...formValues, consignee_company_id: returnValue });
				}}
				consigneeId={formValues?.consignee_company_id}
			/>
			<ConsigneeContactModal
				show={openModal?.type === 'EDIT_CONSIGNEE_CONTACT_MODAL' && !!companyProfile}
				onClose={() => setOpenModal({})}
				onChanged={async () => {
					await companyProfileQuery.refetch();
					setOpenModal({});
				}}
				initialValues={{
					consignee_email_address: companyProfile?.consignee_email_address || null,
					consignee_phone_number: companyProfile?.consignee_phone_number || null,
				}}
				companyId={companyProfile?.ndid_company_id}
			/>
			<AssociatePackoutFacilityModalAdapter
				show={openModal?.type === 'PACKOUT_FACILITY_MODAL'}
				onChange={async (returnValue) => {
					await shippingQuery.refetch();
					setOpenModal({});
					const newSet = { ...formValues };
					if (formValues?.packout_facility_id) {
						newSet['packout_facility_contact_name'] = undefined;
						newSet['packout_facility_contact_phone_number'] = undefined;
						newSet['packout_facility_contact_email'] = undefined;
					}
					newSet['packout_facility_id'] = returnValue;
					setFormValues(newSet);
				}}
				onClose={() => setOpenModal({})}
			/>
			<AssociateFreightForwarderModal
				show={openModal?.type === 'FREIGHT_FORWARDER_MODAL'}
				onCompletion={async (returnValue) => {
					await shippingQuery.refetch();
					setOpenModal({});
					setFormValues({ ...formValues, freight_forwarder_code: returnValue });
				}}
				onClose={() => setOpenModal({})}
			/>
		</>
	);
};
export default Step2Shipping;
