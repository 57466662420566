import { companyAgreementConstants } from '../constants/companyAgreementConstants';
import { compareDates } from './dateUtils';

export function validatePlatformAgreement(
	companyAgreements: CompanyAgreement[],
	platformCode: string,
	checkExpired: boolean = false,
) {
	return (
		companyAgreements.filter((agreement) => {
			return (
				agreement.platformDevelopmentCode === platformCode &&
				agreement.status !== companyAgreementConstants.STATUS.DECLINED &&
				agreement.status !== companyAgreementConstants.STATUS.PENDING &&
				(!checkExpired ||
					!agreement.expirationDate ||
					compareDates(agreement.expirationDate) > 0)
			);
		}).length > 0
	);
}

export function validateActiveAgreementType(
	agreements: CompanyAgreement[],
	type: string,
	platformCode: string,
	checkExpired: boolean = false,
) {
	return agreements?.some(
		(agreement: any) =>
			agreement.platformDevelopmentCode === platformCode &&
			agreement.agreementType === type &&
			agreement.status !== companyAgreementConstants.STATUS.DECLINED &&
			agreement.status !== companyAgreementConstants.STATUS.PENDING &&
			(!checkExpired ||
				!agreement.expirationDate ||
				compareDates(agreement.expirationDate) > 0),
	);
}

export function companyHasRight(
	companyID: string,
	distribution: Required<ProductData>['distribution'],
	rightType: 'OWNERSHIP' | 'RETAIL_PUB' | 'DIGITAL_PUB',
) {
	return (
		companyID === distribution?.find((right) => right.right_type === rightType)?.ndid_company_id
	);
}
