import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { linkColumn } from '../../../components/BaseTable/BaseTable';
import DateCell from '../../../components/cells/DateCell/DateCell';
import FAIcon from '../../../components/FAIcon/FAIcon';
import FilterableTable from '../../../components/FilterableTable/FilterableTable';
import Loading from '../../../components/Loading/Loading';
import Page from '../../../components/Page/Page';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { assetConst } from '../../../constants/assetConstants';
import { formatAssetType } from '../../../utils/assetUtils';
import { assetTypeCustomEvaluation, useAssetQuery } from '../ApprovalQueue.helpers';
import { TabState } from '../../../components/StatefulTabs/StatefulTabs';


const { BANNER_AD, BOX_PACK_SHOT, DISPLAY_SIGNAGE, OTHER, RELEASE_ALERT, TRAILER, TV_COMMERCIAL, WEBSITE } = assetConst.TYPE;

const MarketingAssetsTab = ({ eventKey }) => {
	const show = useContext(TabState).tab === eventKey;
	const assetQuery = useAssetQuery({ enabled: show });
	const assets = useMemo(() => assetQuery.data?.data.filter(
        ({ asset_category }) => asset_category === 'MARKETING',
	), [assetQuery.data]);

	const marketingDataFormat = useMemo(() => {
		const marketingChevronLink = ({ original }) => {
			return (
            <Link to={`/products/${original.product_id}/assets/${original.asset_id}`}>
                <FAIcon name="chevron-right" />
            </Link>
			);
		};
		return [
			{
				Header: 'Asset Type',
				id: 'asset_type',
				accessor: (d) => {
					let value = formatAssetType(d.asset_type);
					if (d.asset_type_other_name) {
						value = value + ' - ' + d.asset_type_other_name;
					}
					return value;
				},
			},
			{ Header: 'Title', accessor: 'game_name', width: 250 },
			{
				Header: 'Publisher',
				accessor: 'company_name',
			},
			{ Header: 'Game Code', accessor: 'game_code' },
			{
				Header: 'Date Modified',
				accessor: 'modified_date',
				sortMethod: (a, b) => {
					return new Date(a) > new Date(b) ? 1 : -1;
				},
				Cell: DateCell
			},
			{
				...linkColumn,
				Cell: marketingChevronLink,
			},
		];
	});

	const filterProperties = useMemo(() => new Map([
		[
			'Asset Type',
			{
				filter: 'asset_type',
				customEvaluation: assetTypeCustomEvaluation,
				selectableFilters: new Set(
					[
						BANNER_AD,
						BOX_PACK_SHOT,
						DISPLAY_SIGNAGE,
						OTHER,
						RELEASE_ALERT,
						TRAILER,
						TV_COMMERCIAL,
						WEBSITE,
					].map((value) => formatAssetType(value)),
				),
			},
		],
	]), []);

	const isLoading = assetQuery.isLoading;

	return (
		<Page.ContentContainer>
			<SectionTitle>Marketing Assets</SectionTitle>
			{isLoading ? (
				<Loading />
			) : (
				<FilterableTable
					data={assets}
					dataFormat={marketingDataFormat}
					filterProperties={filterProperties}
					searchableFields={['game_name', 'game_code', 'company_name']}
					searchableFieldPlaceHolder="Search by Publisher, Title or Game Code"
					defaultSorted={[
						{
							id: 'modified_date',
							desc: true,
						},
					]}
					noBorder
					retainPageState
					stateOnTab={eventKey}
				/>
			)}
		</Page.ContentContainer>
	);
};
export default MarketingAssetsTab;
