import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { fetchEventCategories } from '../../store/actions/referenceActions';
import { actionsColumn } from '../../components/BaseTable/BaseTable';
import ActiveCell from '../../components/cells/ActiveCell/ActiveCell';
import EventCalendar from '../../components/EventCalendar/EventCalendar';
import FAIcon from '../../components/FAIcon/FAIcon';
import FilterBar from '../../components/FilterBar/FilterBar';
import Loading from '../../components/Loading/Loading';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import DeleteConfirmationModal from '../../components/modals/DeleteConfirmationModal/DeleteConfirmationModal';
import EventActiveModal from '../../components/modals/EventActiveModal/EventActiveModal';
import EventModal from '../../components/modals/EventModal/EventModal';
import SimpleModal from '../../components/modals/SimpleModal/SimpleModal';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import { eventConstants } from '../../constants/eventConstants';
import { loadingConstants } from '../../constants/loadingConstants';
import { useUserProfile } from '../../hooks/reduxHooks';
import { deleteEvent, getEventProducts, getEvents } from '../../services/eventsService';
import { removeConsecutiveNulls } from '../../utils/dataUtils';
import { dateFormat, formatDate, parseDateString } from '../../utils/dateUtils';
import { findEventStatus } from '../../utils/eventUtils';
import { dateRangeEvaluation } from '../../utils/filterUtils';
import { toasterNotify } from '../../utils/toaster';
import { getPermissions, getPermissionsForEvent } from './EventManagement.helpers';
import ManageSupportModal from './modals/ManageSupportModal';


const title = 'Marketing Events';

const CREATE_MODAL = 'CREATE_MODAL';
const EDIT_MODAL = 'EDIT_MODAL';
const DELETE_CONFIRM_MODAL = 'DELETE_CONFIRM_MODAL';
const CANT_DELETE_MODAL = 'CANT_DELETE_MODAL';
const MANAGE_SUPPORT_MODAL = 'MANAGE_SUPPORT_MODAL';
const SET_EVENT_ACTIVE_MODAL = 'SET_EVENT_ACTIVE_MODAL';

const statusNames = {
	[eventConstants.STATUS.PLANNED]: 'Planned',
	[eventConstants.STATUS.LIVE]: 'Live',
	[eventConstants.STATUS.COMPLETED]: 'Completed',
	[eventConstants.STATUS.DRAFT]: 'Draft'
};

const DISPLAY_NULL = {display: null};
const DISPLAY_NONE= {display: 'none'};

const getTableStructure = ({
	history,
	userProfile,
	viewActiveStatus,
	viewVisibilityStatus,
	allowNavigationToAny,
	allowEditToAny,
	allowEditToAffiliated,
	allowViewSupportToCompanyAffiliated,
	eventCategories,
	setOpenModal
}) => {
	const timeFormatCell = ({value}) =>
		<div>{formatDate(parseDateString(value), dateFormat.DATE)}</div>;

	const statusCell = ({value}) => {
		switch (value) {
			case eventConstants.STATUS.PLANNED:
				return <strong className="text-info">{statusNames[value].toUpperCase()}</strong>;
			case eventConstants.STATUS.LIVE:
				return <strong className="text-success">{statusNames[value].toUpperCase()}</strong>;
			case eventConstants.STATUS.COMPLETED:
				return <strong className="text-muted">{statusNames[value].toUpperCase()}</strong>;
			case eventConstants.STATUS.DRAFT:
				return <strong>{statusNames[value].toUpperCase()}</strong>;
			default:
				return null;
		}
	};

	const categoryCell = ({value}) => {
		return value in eventCategories ? eventCategories[value] : value;
	};

	const visibleCell = ({original}) => {
		let visibility = '';
		if (original['internally_visible'] && original['externally_visible'])
			visibility = 'Both';
		else if (original['internally_visible'])
			visibility = 'Internal';
		else if (original['externally_visible'])
			visibility = 'External';
		return <div>
				{visibility}
			</div>;
	};

	const linkedCell = ({original}) => {
		return <Link to={linkToDetail(original.id)}>{original.name}</Link>;
	};

	const tableConfiguration = [
		{
			Header: 'Active',
			accessor: 'active',
			width: 72,
			Cell: ActiveCell,
			show: viewActiveStatus,
		},
		{ Header: 'Event Name', accessor: 'name', Cell: allowNavigationToAny ? linkedCell : undefined},
		{ Header: 'Event Category', accessor: 'category', Cell: categoryCell },
		{ Header: 'Visible', accessor: 'visible', Cell: visibleCell, show: viewVisibilityStatus },
		{ Header: 'Start Date', accessor: 'start_datetime', Cell: timeFormatCell },
		{ Header: 'End Date', accessor: 'end_datetime', Cell: timeFormatCell },
		{ Header: 'Status', accessor: 'status', Cell: statusCell },
	];

	if (allowNavigationToAny || allowEditToAny || allowEditToAffiliated  || allowViewSupportToCompanyAffiliated) {
		const actionsCell =  ({original}) => {
			const { canEdit, canDelete, canViewSupport, canEditSupport, canSetActive }
				= getPermissionsForEvent(userProfile, original);

			const menuItems = [];
			if (allowNavigationToAny) {
				menuItems.push(<MeatballDropdown.Item
					id="view-event-action"
					key={`view-${original.id}`}
					eventKey={original.id}
					onClick={e => history.push(linkToDetail(original.id))}
				>View Event</MeatballDropdown.Item>);
			}
			menuItems.push(null);
			if (canEdit) {
				menuItems.push(<MeatballDropdown.Item
					id="edit-event-action"
					key={`edit-${original.id}`}
					eventKey={original.id}
					onClick={e => setOpenModal({type: EDIT_MODAL, event: original})}
				>Edit Event</MeatballDropdown.Item>);
			}
			if (canSetActive) {
				menuItems.push(<MeatballDropdown.Item
					id="set-active-action"
					key={`set-active-${original.id}`}
					eventKey={original.id}
					onClick={e => setOpenModal({type: SET_EVENT_ACTIVE_MODAL, event: original})}
				>Set {original.active ? 'Inactive' : 'Active'}</MeatballDropdown.Item>);
			}
			if (canViewSupport) {
				menuItems.push(<MeatballDropdown.Item
					id="manage-support-action"
					key={`support-${original.id}`}
					eventKey={original.id}
					onClick={e => setOpenModal({type: MANAGE_SUPPORT_MODAL, event: original, canEditSupport})}
				>Manage Support</MeatballDropdown.Item>);
			}
			menuItems.push(null);
			if (canDelete && !original.active) {
				const status = findEventStatus(original);
				// should be INACTIVE and either PLANNED or DRAFT, else we should not delete
				if ([eventConstants.STATUS.PLANNED, eventConstants.STATUS.DRAFT].includes(status)) {
					menuItems.push(<MeatballDropdown.Item
						id="delete-event-action"
						key={`delete-${original.id}`}
						eventKey={original.announcement_id}
						onClick={async (e) => {
							try {
								const products = await getEventProducts(original.id);
								if (products.data && products.data.length > 0) {
									setOpenModal({type: CANT_DELETE_MODAL});
								} else {
									setOpenModal({type: DELETE_CONFIRM_MODAL, event: original});
								}
							}
							catch (error) {
								toasterNotify('Marketing event products data could not be retrieved. ', 'error', error);
							}
						}}
					><span className="text-danger">Delete Event</span></MeatballDropdown.Item>);
				}
			}
			const menuItemsWithSeparators = removeConsecutiveNulls(menuItems).map(
				(item, index) => item === null ? <MeatballDropdown.Divider key={'divider' + index} /> : item
			);

			return ( menuItemsWithSeparators.length &&
				<MeatballDropdown
					id={`dropdown-basic-${original.id}`}
				>
					{menuItemsWithSeparators}
				</MeatballDropdown>
			);

		};

		tableConfiguration.push({
			...actionsColumn,
			Cell: actionsCell
		});
	}
	return tableConfiguration;
};

const linkToDetail = (id) => `/marketing-events/${id}`;

const EventManagement = () => {
	const dispatch = useDispatch();
	const userProfile = useUserProfile();
	const [isEventsLoading, setIsEventsLoading] = useState(true);
	const [isEventsReloading, setIsEventsReloading] = useState(false);
	const [events, setEvents] = useState([]);
	const [filteredData, setFilteredData] = useState();
	const [openModal, setOpenModal] = useState({});
	const history = useHistory();

	// permissions
	const permissions = useMemo(() => getPermissions(userProfile.permissions), [userProfile]);
	const {
		viewVisibilityStatus,
		allowNavigationToAny,
		allowCreate,
		allowDeleteToManagerAffiliation,
		allowEditToAffiliated,
		allowEditToAny,
		allowViewSupportToCompanyAffiliated,
		canSetActiveToAffiliated
	} = permissions;

	// selectors
	const eventCategories = useSelector(store => store.referenceReducer.eventCategories?.content);
	const eventCategoriesMeta = useSelector(store => store.referenceReducer.eventCategories?.meta);
	const platforms = useSelector(store => store.referenceReducer?.platforms.content);
	const platformsMeta = useSelector(store => store.referenceReducer?.platforms.meta);

	const isLoading = isEventsLoading || eventCategoriesMeta.status === loadingConstants.LOADING;


	const viewActiveStatus = events.some((event) => event.active !== undefined);

	useEffect(() => {
		dispatch(fetchEventCategories());
	}, []);


	// load display data
	useEffect(() => {
		if (events.length === 0) {
			loadEvents();
		}
	}, []);

	const reloadEvents = () => {
		setIsEventsReloading(true);
		setEvents([]);
		getEvents()
			.then(results => {
				setEvents(results.data.map(event => { return {...event, status: findEventStatus(event)};} ));
			})
			.finally(() => {
				setIsEventsReloading(false);
			});
	};

	const loadEvents = () => {
		setIsEventsLoading(true);
		getEvents()
			.then(results => {
				setEvents(results.data.map(event => { return {...event, status: findEventStatus(event)};} ));
			})
			.finally(() => {
				setIsEventsLoading(false);
			});
	};

	const createEventModal = () => {
		return (
			<div>
				{allowCreate && !isLoading && !isEventsReloading && (
					<Button
						variant="primary"
						id="addAnnouncement"
						onClick={e => setOpenModal({type: CREATE_MODAL})}
					>
						<FAIcon name="plus" className="mr-1"/>
						Create Event
					</Button>
				)}
			</div>
		);
	};

	const dataFormat = useMemo(() => getTableStructure({
		history,
		userProfile,
		viewActiveStatus,
		viewVisibilityStatus,
		allowNavigationToAny,
		allowEditToAny,
		allowEditToAffiliated,
		eventCategories,
		allowDeleteToManagerAffiliation,
		allowViewSupportToCompanyAffiliated,
		setOpenModal

	}), [viewActiveStatus, viewVisibilityStatus, allowNavigationToAny, eventCategories]);

	const filterProperties = useMemo(() => {
		if (isLoading || !eventCategories) return null;
		let filterProperties = new Map();
		if (viewActiveStatus) {
			filterProperties.set('Active', {
				filter: 'active',
				selectableFilters: new Set(),
				customEvaluation: (active, filters) => {
					return filters.has(active ? 'Active' : 'Inactive');
				}
			});
		}
		filterProperties.set('Event Category', {
			filter: 'category',
			selectableFilters: new Set(),
			customEvaluation: (category, filters) => {
				return filters.has(eventCategories[category]);
			}
		});

		filterProperties.set('Start Date', {
			filter: 'start_datetime',
			selectableFilters: 'datetime_range',
			customEvaluation: dateRangeEvaluation
		});
		filterProperties.set('Status', {
			filter: 'status',
			selectedFilters: new Set([
				statusNames[eventConstants.STATUS.PLANNED],
				statusNames[eventConstants.STATUS.LIVE],
				statusNames[eventConstants.STATUS.DRAFT]
			]),
			selectableFilters: new Set(),
			customEvaluation: (status, filters) => {
				return filters.has(statusNames[status]);
			}
		});

		const getActive = () => {
			filterProperties
				.get('Active')
				.selectableFilters
				.add('Active')
				.add('Inactive');
		};

		const getStatuses = () => {
			filterProperties
				.get('Status')
				.selectableFilters
				.add(statusNames[eventConstants.STATUS.DRAFT])
				.add(statusNames[eventConstants.STATUS.PLANNED])
				.add(statusNames[eventConstants.STATUS.LIVE])
				.add(statusNames[eventConstants.STATUS.COMPLETED]);
		};

		const getCategories = () => {
			const selectableFilters = filterProperties
				.get('Event Category')
				.selectableFilters;
			Object.values(eventCategories).forEach(category => selectableFilters.add(category));
		};

		if (events && events.length > 0 && eventCategories) {
			if (viewActiveStatus) {
				getActive();
			}
			getStatuses();
			getCategories();
		}

		return filterProperties;
	}, [isLoading, eventCategories, events]);


	const eventPermissions =
		(!isLoading && !isEventsReloading && openModal && openModal.event && events && getPermissionsForEvent(userProfile, openModal.event)) || null;

	return (
        <Page>
            <div className="EventManagement">
                <Title title={title} button={createEventModal()}/>
                { isLoading ? <Loading /> :
					<div className='Page__fill-space' style={isEventsReloading ? DISPLAY_NONE : DISPLAY_NULL}>
						<FilterBar
							data={events}
							onFilter={(filteredData) => setFilteredData(filteredData)}
							filterProperties={filterProperties}
							searchableFields={['name']}
							searchableFieldPlaceHolder="Search by Event Name"
							retainPageState
						/>
						<Page.FullPageCol>
							<EventCalendar data={filteredData} dataFormat={dataFormat} />
						</Page.FullPageCol>
					</div>
                }
			</div>
			{ isEventsReloading && <Loading /> }
			{ (allowEditToAny || allowEditToAffiliated || allowCreate) &&

				<EventModal
					eventId={(openModal && openModal.event && openModal.event.id) || null}
					eventCategories={eventCategories}
					platforms={platformsMeta.status === loadingConstants.COMPLETED && platforms}
					onClose={() => setOpenModal({...openModal, type: null})}
					onChange={() => {reloadEvents(); setOpenModal({...openModal, type: null});}}
					show={!!openModal && [CREATE_MODAL, EDIT_MODAL].includes(openModal.type)}
					defaultValues={{managers: [userProfile.userId]}}
					permissions={eventPermissions}
				/>
			}
			{ allowDeleteToManagerAffiliation && <>
				<DeleteConfirmationModal
					show={openModal && openModal.type === DELETE_CONFIRM_MODAL}
					closeModal={() => {
						setOpenModal({...openModal, type: null});
					}}
					confirmDelete={async () => {
						if (openModal && openModal.event.id) {
							try {
								await deleteEvent(openModal.event.id);
								reloadEvents();
								toasterNotify('Event was deleted', 'success');
							}
							catch(error) {
								toasterNotify('Marketing event could not be deleted. ', 'error', error);
							}
						}
						setOpenModal({...openModal, type: null});
					}}
					title="Delete Event"
					message="Please confirm that you would like to permanently delete this event."
				/>
				<SimpleModal
					show={openModal && openModal.type === CANT_DELETE_MODAL}
					closeModal={() => setOpenModal({...openModal, type: null})}
					title="Delete Event"
				>
					Please delete all the Products from the Event before deleting the Event.
				</SimpleModal>
			</>}
			{ allowViewSupportToCompanyAffiliated &&
				<ManageSupportModal
					eventId={(openModal && openModal.event && openModal.event.id) || null}
					show={openModal && openModal.type === MANAGE_SUPPORT_MODAL}
					onClose={() => setOpenModal({...openModal, type: null})}
					canEdit={openModal && openModal.canEditSupport}
				/>
			}
			{ canSetActiveToAffiliated &&
				<EventActiveModal
					show={openModal && openModal.type === SET_EVENT_ACTIVE_MODAL}
					eventData={openModal && openModal.event}
					onCancel={e => setOpenModal({...openModal, type: null})}
					onComplete={e => {setOpenModal({...openModal, type: null}); reloadEvents();}}
				/>
			}
        </Page>
	);
};

export default EventManagement;
