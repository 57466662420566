import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import ActionLink from '../../../components/ActionLink/ActionLink';

import FAIcon from '../../../components/FAIcon/FAIcon';

const EditUserNotice = () => {
	const [expand, setExpand] = useState(false);
	return (
		<Alert className="alert-icon" variant="info">
			Please check that all requirements are met before submitting changes.{' '}
			<ActionLink
				onClick={() => {
					setExpand(!expand);
					return false;
				}}
			>
				View requirements
			</ActionLink>
			<FAIcon size="xs" name="chevron-right" />
			{expand && (
				<ul>
					<li>The Company Admin role is inherited from the Nintendo Developer Portal.</li>
					<li>Only Company Admins can edit user roles.</li>
					<li>
						All roles, except Company Admin, are additive and do not change the user's
						Nintendo Developer Portal access.
					</li>
					<li>
						Users must have a Nintendo Developer ID (NDID) in the Nintendo Developer
						Portal to be added.
					</li>
					<li>
						Users that are "Locked" in the Nintendo Developer Portal will not have
						access.
					</li>
					<li>At least one Company Admin must exist.</li>
					<li>Adding users from another company is not supported.</li>
				</ul>
			)}
		</Alert>
	);
};

export default EditUserNotice;
