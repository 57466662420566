import { AxiosResponse } from 'axios';
import { PaginatedCall } from '../utils/serviceUtils';
import api from './baseService';

const pollingUrlPath = '/dc/v1_1/executions/';

export const getComponentOrphans: PaginatedCall = (count, limit, offset) =>
	api().get('/dc/v1_1/components/orphans', { params: { count, limit, offset } });

interface EstimatedPriceData {
	adjustedPrice: string;
	partNumber: string;
	purpose: string;
	quantity: string;
	unitPrice: string;
}
export const getEstimatedPrice = (componentId: string, purpose: string, quantity: string) =>
	api().get<EstimatedPriceData>('dc/v1_1/component-price', {
		params: {
			componentId, // partNumber for EBS or the word 'DIGITALCODES'
			purposeId: purpose, // This is a purpose name actually because
			// EBS doesn't understand IDs but long string identifiers of purposes.
			quantity,
		},
	});

interface DigitallyOrderableComponentsData {
	partNumber: string;
	gameCode: string;
	itemCode: string | null;
	contentType: 'TITLE' | 'BUNDLE' | 'AOC';
	bundleType: 'TITLE ONLY' | 'AOC ONLY';
	gameName: string;
	publisherCode: string;
	publisherType: string;
	deviceType: PlatformCode;
	platformCode: string;
	nsUid: string;
	parentNsUid: string | null;
}
export const getDigitalOrderComponents = (gameCode: string, deviceType: PlatformCode) =>
	api().get<DigitallyOrderableComponentsData[]>('/dc/v1_1/components', {
		params: { game_code: gameCode, device_type: deviceType },
	});

export const getComponentsTitlesForCommonCode = (commonCode: string) =>
	api().get(`/dc/v1_1/components/titles?common_code=${commonCode}`);

export const getComponentsTitlesForNsUid = (componentNsUid: string) =>
	api().get(`/dc/v1_1/components/titles/${componentNsUid}`);

interface ComponentData {
	product: {
		active_rights_transfer: ProductData['active_rights_transfer'];
		company_name: string;
		game_code: string;
		name: string;
		platform_code: PlatformCode;
		product_id: number;
		product_rights?: {
			right_type: 'OWNERSHIP' | 'DIGITAL_PUB' | 'RETAIL_PUB';
			company_name: string;
			ndid_company_id: string;
		}[];
	};
	component: ProductComponentData;
	wholesale_price_requests: Required<WholesalePriceRequestData>[];
	can_create_wholesale_price_request: boolean;
	declared_price_requests: Required<DeclaredPriceRequestData>[];
	can_create_declared_price_request: boolean;
}
export const getComponentDetails = (componentNsUid: string) =>
	api().get<ComponentData>(`/dc/v1_1/components/${componentNsUid}`);

export const getWSPRequest = (componentNsUid: string) =>
	api().get(`/dc/v1_1/components/${componentNsUid}/wholesale_price_requests`);

export const putComponentParent = (componentNsUid: string, payload: Record<string, any>) =>
	api().put(`/dc/v1_1/components/${componentNsUid}/parent`, payload);

export const postWSPRequests = (
	componentNsUid: string,
	payload: Record<string, any>,
	callback: (response?: AxiosResponse<unknown>) => void,
) =>
	api().post(`/dc/v1_1/components/${componentNsUid}/wholesale_price_requests`, payload, {
		pollingUrl: pollingUrlPath,
		pollingTimeLimit: 120000,
		pollingCallback: callback,
	});

export const postDPRequestAction = (
	componentNsUid: number | string,
	payload: Record<string, any>,
) => api().post(`/dc/v1_1/components/declared_price_requests/${componentNsUid}/actions`, payload);

export const postWSPRequestAction = (
	componentNsUid: number | string,
	payload: Record<string, any>,
) => api().post(`/dc/v1_1/components/wholesale_price_requests/${componentNsUid}/actions`, payload);

export const getWSPRequestAgreement = (requestId: string) =>
	api().get(`/dc/v1_1/components/wholesale_price_requests/${requestId}/agreement`);

export const getDigitalCatalogPlatforms = () =>
	api({ useCache: true }).get('/dc/v1_1/digital/catalog/platforms');

export const getComponentPrice = (productId: string) =>
	api().get(`/dc/v1_1/products/${productId}/components/prices`);

interface DigitalOrdersTable {
	product: {
		game_name: string | null;
		game_code: string | null;
		product_id: number | null;
	};
	orderID: number;
	purpose: string;
	grand_total: string;
	reqActivationDate: string;
	submittedDate: string;
	component: {
		game_name: string | null;
		item_code: string | null;
		game_code: string | null;
	};
	NDID: string;
	reqDeliveryDate: string;
	ebsSalesOrderNum: string | null;
	purposeDescription: string;
	publisherPO: string;
	status: string;
}
export const getDigitalOrders = (callback: (response?: AxiosResponse<unknown>) => void) =>
	api().get<DigitalOrdersTable>('/dc/v1_1/orders', {
		pollingUrl: pollingUrlPath,
		pollingTimeLimit: 120000,
		pollingCallback: callback,
	});

export const getUsedPONums = (
	orderId?: string,
	callback?: (response?: AxiosResponse<unknown>) => void,
) =>
	api().get('/dc/v1_1/orders/po_numbers', {
		params: {
			...(orderId ? { order_id: orderId } : null),
		},
		pollingUrl: pollingUrlPath,
		pollingTimeLimit: 120000,
		pollingCallback: callback,
	});

export const postNewOrder = (payload: Record<string, any>) =>
	api().post<{orderID: number}>('/dc/v1_1/orders/', payload);

export const putUpdateNewOrder = (orderId: string, payload: Record<string, any>) =>
	api().put(`/dc/v1_1/orders/${orderId}`, payload);

export const putUpdateOrderDetails = (orderId: string, payload: Record<string, any>) =>
	api().put(`/dc/v1_1/orders/${orderId}/details`, payload);

interface ProductsAndComponentsData {
	game_code: string;
	product_id: number;
	game_name: string;
	product_release_date: string | null;
	components: {
		product_code: string;
		item_code: string | null;
		ns_uid: string;
		game_name: string;
		content_type: string;
		part_number: string;
		bundle_type: string;
	}[];
}
export const getProductsAndComponents = (purposeId: string) =>
	api().get<ProductsAndComponentsData[]>('/dc/v1_1/orders/products', {
		params: { digital_orders_purpose_id: purposeId },
	});

export interface DigitalOrderData {
	sales_flow_version: 1 | 2;
	activation_date: string | null;
	address1: string | null;
	address2: string | null;
	age_rating_submitted: boolean;
	city: string | null;
	codes_downloaded_by_userName: string | null;
	codes_downloaded_date: string | null;
	codes_upload_date: string | null;
	comments: string | null;
	component: {
		game_name: string;
		item_code: string;
		game_code: string;
	};
	componentID: string | InvalidData<string | null>;
	country: string;
	created_by_company_name?: string | null;
	created_by_user_email?: string | null;
	created_by_user_name?: string | null;
	createdDate?: string;
	ebsSalesOrderNum: string | null;
	invoice_file: string | null;
	invoiceBucket?: string; // removed in sales flow 2
	invoiceBucketRegion?: string; // removed in sales flow 2
	invoicePath?: string; // removed in sales flow 2
	is_upload_eligible: boolean;
	isCodesFileAvailable: boolean;
	NDID: string;
	orderID: number;
	order_comments: {
		comment: string;
		creation_date?: string;
		type: string;
	}[];
	paymentTransID: string | null;
	paymentType: string | null;
	postalCode: string | null;
	product_insert: {
		reviewed: boolean;
		submitted: boolean;
	};
	product: {
		game_name: string;
		game_code: string;
		product_id: number;
	};
	productID: number | InvalidData<number | null>;
	publisherPO: string;
	purposeDescription: string;
	purposeID: number | InvalidData<number | null>;
	quantity: number;
	reqActivationDate: string | InvalidData<string>;
	reqDeliveryDate: string | InvalidData<string>;
	rom_submission: {
		approved: boolean;
		submitted: boolean;
	};
	serviceFee: string | null;
	stateProvince: string;
	status: string;
	submitted_by_company_name?: string | null;
	submitted_by_user_email?: string | null;
	submitted_by_user_name?: string | null;
	submittedDate?: string;
	tax_amount: string | null;
	tax: string | null;
	tncCapturedAt: string;
	totalPrice: string;
	grand_total: string | null,
	unitPrice: string;
}
export const getDigitalOrder = (
	orderId: string,
	callback?: (response?: AxiosResponse<unknown>) => void,
) =>
	api().get<{ body: DigitalOrderData }>(`/dc/v1_1/orders/${orderId}`, {
		pollingUrl: pollingUrlPath,
		pollingTimeLimit: 120000,
		pollingCallback: callback,
	});

export const deleteDigitalCodeOrder = (orderId: string) =>
	api().delete(`/dc/v1_1/orders/${orderId}`);

export const postActivateDigitalCodes = (
	orderId: string,
	date: string,
	callback: (response?: AxiosResponse<unknown>) => void,
) =>
	api().post(
		`/dc/v1_1/orders/${orderId}/actions/activate_digital_codes`,
		{ activation_date: date },
		{
			pollingUrl: pollingUrlPath,
			pollingTimeLimit: 120000,
			pollingCallback: callback,
		},
	);

export const postOrderApproverApprove = (orderId: string, payload?: Record<string, any>) =>
	api().post(`/dc/v1_1/orders/${orderId}/actions/approver_approve`, payload);

export const postOrderCancel = (orderId: string) =>
	api().post(`/dc/v1_1/orders/${orderId}/actions/cancel`);

export const postOrderComplete = (orderId: string) =>
	api().post(`/dc/v1_1/orders/${orderId}/actions/complete`);

export const postOrderReject = (orderId: string, payload: Record<string, any>) =>
	api().post(`/dc/v1_1/orders/${orderId}/actions/reject`, payload);

export const postRequestForQuote = (
	orderId: string,
	payload: Record<string, any>,
	callback?: (response?: AxiosResponse<unknown>) => void,
) =>
	api().post(`/dc/v1_1/orders/${orderId}/actions/request_for_quote`, payload, {
		pollingUrl: pollingUrlPath,
		pollingTimeLimit: 120000,
		pollingCallback: callback,
	});

export const postRetractDigitalCodes = (
	orderId: string,
	callback: (response?: AxiosResponse<unknown>) => void,
) =>
	api().post(`/dc/v1_1/orders/${orderId}/actions/retract_digital_codes`, {
		pollingUrl: pollingUrlPath,
		pollingTimeLimit: 120000,
		pollingCallback: callback,
	});

export const postOrderReviewerResendOrder = (orderId: string) =>
	api().post(`/dc/v1_1/orders/${orderId}/actions/reviewer_resend_order`);

export const postOrderReviewerApprove = (orderId: string) =>
	api().post(`/dc/v1_1/orders/${orderId}/actions/reviewer_approve`);

export const postNotificationRequest = (
	orderId: string,
	callback: (response?: AxiosResponse<unknown>) => void,
) =>
	api().post(`/dc/v1_1/orders/${orderId}/actions/send_activation_notification`, null, {
		pollingUrl: pollingUrlPath,
		pollingTimeLimit: 120000,
		pollingCallback: callback,
	});

export const postSetPasswordTnc = (orderId: string, password: string) =>
	api().post(`/dc/v1_1/orders/${orderId}/actions/timestamp_tnc`, { file_password: password });

export const getCancelUploadToken = (orderId: string) =>
	api().get(`/dc/v1_1/orders/${orderId}/token?operation=cancel_upload`);

export const postSubmitWirePayment = (orderId: string, paymentId: string) =>
	api().post(`/dc/v1_1/orders/${orderId}/payments`, { paymentId, paymentType: 'WIRE' });

// TODO: Remove when the old PayPal button is removed from the client.
export const postRecordPayPalPayment = (orderId: string, paymentId: string) =>
	api().post(`/dc/v1_1/orders/${orderId}/payments`, { paymentId, paymentType: 'PAYPAL' });

interface DigitalCodePurposesData {
	digital_orders_purpose_id: number;
	platform_code: PlatformCode;
	purpose: string;
	purpose_description: string;
	code_batch_increments: number;
	price_type: string;
	min_num_of_codes: number;
	max_num_of_codes: number;
	min_price_orderable: number | null;
	sort_order: number;
	active: boolean;
	price_type_code: 'FEE' | 'PERMANENT' | 'WHOLESALE';
}
export const getDigitalCodePurposes = () =>
	api().get<DigitalCodePurposesData[]>('/dc/v1_1/orders/code_purposes');

interface DigitalCodePlatformLegendData {
	platform: PlatformCode;
	digital_orders_legend: {
		digital_orders_legend_id: number;
		platform_code: PlatformCode;
		price_type: string;
		cost_breakdown: number;
		sort_order: number;
	}[];
	digital_orders_purpose: DigitalCodePurposesData[];
}
export const getDigitalOrdersPlatformLegend = () =>
	api().get<DigitalCodePlatformLegendData[]>('/dc/v1_1/orders/platform_legends');

interface DigitalCatalogData {
	part_number: string;
	game_code: string;
	item_code: string | null;
	content_type: string;
	bundle_type: string;
	game_name: string;
	device_type: string;
	platform_code: string;
	ns_uid: string;
	parent_ns_uid: null;
	retail_prices: {
		price: string;
		start_datetime: string;
	}[];
	wholesale_price_requests: WholesalePriceRequestData[];
}

export const getDigitalCatalog = (productId: string) =>
	api().get<DigitalCatalogData[]>(`/dc/v1_1/products/${productId}/digital_catalog`);

export const getWSPRequestAgreementTemplates = (platformCode: string) =>
	api().get('/dc/v1_1/wholesale_price_requests/agreement_templates', {
		params: { platform_code: platformCode },
	});

interface DeclaredPriceRequestReview {
	declared_price_request_id: number;
	request_status: string;
	price_status: string;
	certified_price: string;
	submitted_datetime: string;
	ndid_company_id: string;
	company_name: string;
	game_name: string;
	game_code: string;
	item_code: string;
	content_type: string;
	bundle_type: string;
	device_type: string;
	ns_uid: string;
	parent_ns_uid: string;
	parent_component_game_code: string;
}
export const getDPReviewQueue = () =>
	api().get<DeclaredPriceRequestReview[]>('dc/v1_1/declared_price_requests/review');

interface WholesalePriceRequestReview {
	wholesale_price_request_id: number;
	request_status: string;
	certified_price: string;
	submitted_datetime: string;
	ndid_company_id: string;
	company_name: string;
	game_name: string;
	game_code: string;
	item_code: string | null;
	content_type: string;
	bundle_type: string;
	device_type: string;
	ns_uid: string;
	parent_ns_uid: string | null;
	parent_component_game_code: string | null;
}
export const getWSPRequestQueue = () =>
	api().get<WholesalePriceRequestReview[]>('/dc/v1_1/wholesale_price_requests/review');

interface DeclaredPriceAgreement {
	declared_price_request: {
		declared_price_request_id: number;
		ns_uid: string;
		declared_price_agreement_template_id: number;
		certified_flag: boolean;
		component_game_name: string;
		certified_price: string;
		requester_ndid_user_id: string;
		requester_login_id: string;
		requester_user_name: string;
		ndid_company_id: string;
		company_name: string;
		user_signature: string;
		submitted_datetime: Date;
		product_game_code: string;
		platform_code: string;
	};
	declared_price_agreement_template: {
		declared_price_agreement_template_id: number;
		platform_code: string;
		version: string;
		active_flag: boolean;
		template: Record<string, any>;
	};
}
export const getDeclaredPriceAgreement = (requestId: number) =>
	api().get<DeclaredPriceAgreement>(
		`/dc/v1_1/components/declared_price_requests/${requestId}/agreement`,
	);

interface DeclaredPriceAgreementTemplate {
	declared_price_agreement_template_id: number;
	platform_code: PlatformCode;
	version: string;
	template_body: string;
}
export const getDeclaredPriceRequestAgreementTemplates = (platformCode: string) =>
	api().get<DeclaredPriceAgreementTemplate>(
		'/dc/v1_1/declared_price_requests/agreement_templates',
		{
			params: { platform_code: platformCode },
		},
	);

export const postDeclaredPriceRequests = (
	componentNsUid: string,
	payload: Record<string, any>,
	callback: (response?: AxiosResponse<unknown>) => void,
) =>
	api().post(`/dc/v1_1/components/${componentNsUid}/declared_price_requests`, payload, {
		pollingUrl: pollingUrlPath,
		pollingTimeLimit: 120000,
		pollingCallback: callback,
	});

interface OrderDeliveryData {
	orderID: number;
	reqDeliveryDate?: String;
	reqActivationDate?: string;
	comments: {
		comment: string;
		type: string;
	}[];
}
export const getOrderDelivery = (orderHeaderId: string) =>
	api().get<OrderDeliveryData>(`/dc/v1_1/orders/${orderHeaderId}/delivery`, );

export interface OrderDeliveryPayload {
	reqDeliveryDate: string | null;
	reqActivationDate: string | null;
	comments: string | null;
}
export const putOrderDelivery = (orderHeaderId: number, payload: OrderDeliveryPayload) =>
	api().put<number>(`/dc/v1_1/orders/${orderHeaderId}/delivery_details`, payload);

type OrderSummaryData = Pick<DigitalOrderData, 'orderID' | 'status' | 'sales_flow_version'> & {
	rejection_comment: string | null;
};
export const getOrderSummary = (orderHeaderId: string) =>
	api().get<OrderSummaryData>(`/dc/v1_1/orders/${orderHeaderId}/summary`, );

export interface DigitalOrderResourcesData {
	purposes: {
		platform_code: PlatformCode;
		purpose: string;
		price_type_code: 'FEE' | 'PERMANENT' | 'WHOLESALE';
		min_num_of_codes: number | null;
		purpose_id: number;
		code_batch_increments: number;
		price_type: string;
		max_num_of_codes: number | null;
		sort_order: number;
		purpose_description: string;
	}[];
	publisher_po_numbers: string[];
	tax_percent: string;
}
export const getDigitalOrderResources = (callback?: () => void) =>
	api().get<DigitalOrderResourcesData>('/dc/v1_1/orders/details/resources', {
		pollingUrl: pollingUrlPath,
		pollingTimeLimit: 120000,
		pollingCallback: callback,
	});

interface DigitalOrderDetailsAndResourcesData extends DigitalOrderResourcesData {
	order_details: Partial<
		Pick<
			DigitalOrderData,
			| 'orderID'
			| 'status'
			| 'productID'
			| 'componentID'
			| 'purposeID'
			| 'purposeDescription'
			| 'sales_flow_version'
			| 'comments'
			| 'publisherPO'
			| 'quantity'
			| 'unitPrice'
			| 'tax'
		> & {
			productGameName: string | null;
			productGameCode: string | null;
			componentName: string | null;
			platform_code: PlatformCode;
			purpose: string | null;
			serviceFee: string | null;
		}
	>;
}
export const getDigitalOrderDetailAndResources = (orderHeaderId: string, callback?: () => void) =>
	api().get<DigitalOrderDetailsAndResourcesData>(`/dc/v1_1/orders/${orderHeaderId}/info_details`, {
		pollingUrl: pollingUrlPath,
		pollingTimeLimit: 120000,
		pollingCallback: callback,
	});

export interface ComponentPriceData {
	quantity?: number;
	service_fee?: string;
	unit_price?: string;
	total_price: string;
}
export const getComponentOrderPrice = (nsuid: string, purposeId: number, quantity: number = 1) =>
	api().get<ComponentPriceData>(`/dc/v1_1/components/${nsuid}/codes_price`, {
		description: 'a price lookup',
		params: { purpose_id: purposeId, quantity },
		pollingUrl: pollingUrlPath,
		pollingTimeLimit: 120000,
	});

export interface OrderQuoteData {
	orderID: number;
	sales_flow_version: 1 | 2;
	platformCode: string;
	status: string;
	purposeID: number | InvalidData<number | null>;
	purposeDescription: string;
	publisherPO: string;
	reqDeliveryDate:  string | InvalidData<string>;
	reqActivationDate: string | InvalidData<string>;
	productID?: number | InvalidData<number>;
	product_game_name: string;
	productGameCode: string;
	componentID: string;
	component_game_name: string;
	quantity: number;
	unitPrice: string;
	serviceFee: string;
	totalPrice: string;
	tax: string;
	tax_amount: string;
	grand_total: string;
	comments: {
		type: string;
		comment: string;
	}[];
}
export const getOrderQuote = (orderHeaderId: number) =>
	api().get<OrderQuoteData>(`/dc/v1_1/orders/${orderHeaderId}/request_for_quote`, );

// paypal stuff
export const postPaypalCreatePayment = (orderId: string) =>
	api().post<string>(`/dc/v1_1/orders/${orderId}/paypal/orders`);

export const postPaypalExecutePayment = (orderId: string, paymentId: string) =>
	api().post(`/dc/v1_1/orders/${orderId}/payments`, {
		intent: 'capture',
		paymentType: 'PAYPAL',
		paymentId,
	});
