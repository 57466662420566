import React, { VFC } from 'react';
import { UseQueryResult, useQuery, useQueryClient } from 'react-query';

import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../components/Page/Page';
import { permConst } from '../constants/permConst';
import { getCompanyAgreements, getCompanyProfile } from '../services/companiesService';
import { CHANGE_COMPANY_PROFILE_LOAD_STATUS, SET_COMPANY_PROFILE } from '../store/actions/action-types';
import { changeLoadStatus, setData } from '../store/actions/referenceActions';
import { RootState } from '../store/store';
import { isAuthorized } from '../utils/userUtils';
import { useUserProfile } from './reduxHooks';


export const useCompanyProfileQuery = (
	options?: Record<string, any>,
): UseQueryResult<AxiosResponse<{ company_information: CompanyProfile } | null, any>, unknown> => {
	const userProfile = useUserProfile();
	const companyId = userProfile?.companyId || '';
	const dispatch = useDispatch();
	const optionsWithDefaults = {
		enabled: !!companyId,
		staleTime: Infinity,
		placeholderData: useSelector((state: RootState) => {
			if (state.referenceReducer?.companyProfile?.content) {
				return { data: state.referenceReducer?.companyProfile?.content };
			}
			return undefined;
		}),
		onSuccess: (response: AxiosResponse) => {
			if (response.status === 200) {
				dispatch(setData(SET_COMPANY_PROFILE, response.data));
				dispatch(
					changeLoadStatus(
						CHANGE_COMPANY_PROFILE_LOAD_STATUS,
						'COMPLETED',
						response.headers['x-correlation-id'],
					),
				);
			}
		},
		...options,
	};
	const authorized =
		userProfile &&
		isAuthorized(userProfile.permissions, [
			permConst.COMPANY.VIEW.ALL,
			permConst.COMPANY.VIEW.COMPANY,
		]);
	return useQuery(
		['getCompanyProfile', companyId],
		() => (companyId ? getCompanyProfile(companyId) : null),
		authorized ? optionsWithDefaults : { enabled: false },
	);
};
export const useCompanyAgreementsQuery = (options?: Record<string, any>) => {
	const userProfile = useUserProfile();
	const optionsWithDefaults = { staleTime: Infinity, ...options };
	const authorized = userProfile && isAuthorized(userProfile.permissions, [
		permConst.COMPANY.VIEW.ALL,
		permConst.COMPANY.VIEW.COMPANY,
	]);
	return useQuery(
		['getCompanyAgreements', userProfile.companyId],
		() => getCompanyAgreements(userProfile.companyId),
		authorized ?  optionsWithDefaults : { enabled: false }
	);
};

export const connectQueryClient = <T extends JSX.IntrinsicAttributes = {}>(
	Component: VFC<T>,
) => {
	const ConnectQueryClientHOCComponent: VFC<T> = (props: T) => {
		const queryClient = useQueryClient();
		return <Component {...{ ...props, queryClient }} />;
	};
	return ConnectQueryClientHOCComponent;
};

export const connectQueryData = <T extends JSX.IntrinsicAttributes = {}, D = unknown, E = unknown>(
	Component: VFC<T>,
	useQueryHook: () => UseQueryResult<AxiosResponse<D>, E>,
	propName: string,
	isPageComponent?: boolean
) => {
	const ConnectQueryDataHOCComponent: VFC<T> = (props: T) => {
		const query = useQueryHook();
		return !query.isLoading && !query.isFetching ? (
			<Component {...{ ...props, [propName]: query.data?.data }} />
		) : isPageComponent ? (
			<Page isLoading={true} />
		) : (
			null
		);
	};
	return ConnectQueryDataHOCComponent;
};
