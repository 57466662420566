import React, { useState, VFC } from 'react';
import { Button } from 'react-bootstrap';
import { useQueries, useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';

import FAIcon from '../../../components/FAIcon/FAIcon';
import Loading from '../../../components/Loading/Loading';
import ActionConfirmationModal from '../../../components/modals/ActionConfirmationModal/ActionConfirmationModal';
import Page from '../../../components/Page/Page';
import PhysicalOrder from '../../../components/PhysicalOrder/PhysicalOrder';
import { permConst } from '../../../constants/permConst';
import { useCompanyProfileQuery } from '../../../hooks/queryHooks';
import { useUserProfile } from '../../../hooks/reduxHooks';
import { getCompanyProfile } from '../../../services/companiesService';
import {
	getNewOrderProductPrice,
	getPhysicalOrder,
	postOrderHeaderAction,
} from '../../../services/ordersService';
import { doesOrderHaveInvalidValues } from '../../../utils/orderUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { isAuthorized } from '../../../utils/userUtils';

interface ConfirmationModalModel {
	type?: 'CONFIRM_SUBMIT_MODAL';
}
interface Step3PhysicalOrderConfirmationProps {
	orderHeaderId?: string;
	onPrevious: () => void;
}
const Step3PhysicalOrderConfirmation: VFC<Step3PhysicalOrderConfirmationProps> = ({
	orderHeaderId,
	onPrevious,
}) => {
	const history = useHistory();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [openModal, setOpenModal] = useState<ConfirmationModalModel>();
	const userProfile = useUserProfile();

	const companyProfileQuery = useCompanyProfileQuery();
	const companyProfile = companyProfileQuery.data?.data?.company_information;

	const orderQuery = useQuery(['getPhysicalOrder', orderHeaderId], () =>
		getPhysicalOrder(orderHeaderId as string),
	);
	const orderData = orderQuery?.data?.data;
	const isSelfConsignee = orderData?.consignee_company_id === null;

	const pubisherQuery = useQuery(
		['getCompanyProfile', orderData?.ndid_company_id],
		() => getCompanyProfile(orderData?.ndid_company_id as string),
		{ enabled: !!orderData?.noa_sales_order_number || isSelfConsignee },
	);
	const publisherProfile = pubisherQuery.data?.data?.company_information;
	
	const orderIsInProcess = ['DRAFT', 'SUBMITTED_FOR_REVIEW', 'SUBMITTED_FOR_APPROVAL'].includes(
		String(orderData?.order_status),
	);
	const canSubmit =
		['DRAFT', 'REJECTED'].includes(String(orderData?.order_status)) &&
		isAuthorized(userProfile.permissions, [permConst.PHYSICAL_ORDER.EDIT.COMPANY]);

	const enableEBSPriceLookup = !!orderData && orderIsInProcess;

	const priceQueriesConfig =
		enableEBSPriceLookup &&
		orderData?.order_details.map(({ product_id }) => {
			const p = typeof product_id === 'object' ? product_id.value : product_id;
			return {
				queryKey: ['getNewOrderProductPrice', p],
				queryFn: () => getNewOrderProductPrice(String(p)),
			};
		});
	const priceQueries = useQueries(priceQueriesConfig || []);
	const ebsPrices = priceQueries?.map((q) => (typeof q.data?.data === 'string' && q.data?.data) || undefined);

	const isLoading = companyProfileQuery.isLoading || orderQuery.isLoading;
	const isEDIOrder = orderData?.edi_order_flag ? orderData.edi_order_flag : false;
	const hasPriceMismatch = isEDIOrder && orderData?.order_details?.some((line, index) =>
		ebsPrices &&
			Number(ebsPrices[index]) !== Number(line?.unit_price)
	);
	const orderHasInvalidValues = !!(orderData && doesOrderHaveInvalidValues(orderData));

	const getSelfConsigneeFormatted = () => ({
		consignee_company_name: publisherProfile?.company_name,
		consignee_address_street_line_1: publisherProfile?.address_1,
		consignee_address_street_line_2: publisherProfile?.address_2,
		consignee_address_city: publisherProfile?.city,
		consignee_address_region: publisherProfile?.region,
		consignee_address_postal_code: publisherProfile?.postal_code,
		consignee_address_country_name: publisherProfile?.country_name,
		consignee_phone_number: publisherProfile?.consignee_phone_number,
		consignee_email_address: publisherProfile?.consignee_email_address,
	});

	return (
		<>
			<Page.FullPageCol>
				{isLoading ? (
					<Loading />
				) : (
					<>
						<PhysicalOrder
							orderData={orderData}
							ebsPrices={ebsPrices}
							selfConsignee={
								isSelfConsignee ? getSelfConsigneeFormatted() : undefined
							}
							showPartNumbers={!!companyProfile?.sku_number_required}
							isInternalUser={isAuthorized(userProfile.permissions, [
								permConst.PHYSICAL_ORDER.VIEW.ALL,
							])}
						/>

						<div className="btn-container">
							<div className="float-left">
								<Button
									variant="outline-secondary"
									disabled={isSubmitting}
									onClick={() => onPrevious()}
								>
									<FAIcon className="mr-1" name="chevron-left" />
									Previous (Shipping)
								</Button>
								<Button
									as={Link}
									variant="link"
									to="/orders/physical/"
									disabled={isSubmitting}
								>
									Return To Orders
								</Button>
							</div>
							{canSubmit && (
								<div className="float-right d-flex">
									<Button
										className="ml-3"
										variant="primary"
										onClick={() => {
											if (hasPriceMismatch) {
												toasterNotify(
													<div>
														Price has changed since a product was added
														to the order.{' '}
														<a
															className="text-underline text-danger"
															href="mailto:thirdpartypublisher@noa.nintendo.com"
														>
															Please contact NOA via email.
														</a>
													</div>,
													'error',
												);
											} else if (orderHasInvalidValues) {
												toasterNotify(
													'Order cannot be submitted with invalid values. See order details for errors.',
													'error',
												);
											} else {
												setOpenModal({ type: 'CONFIRM_SUBMIT_MODAL' });
											}
										}}
										disabled={isSubmitting}
									>
										Submit Order
									</Button>
								</div>
							)}
						</div>
					</>
				)}
				<ActionConfirmationModal
					show={openModal && openModal.type === 'CONFIRM_SUBMIT_MODAL'}
					isSubmitting={isSubmitting}
					onCancel={() => setOpenModal({})}
					onConfirm={async () => {
						if (orderHeaderId) {
							try {
								setIsSubmitting(true);
								await postOrderHeaderAction(orderHeaderId, { action: 'SUBMIT' });
								toasterNotify('Order confirmed', 'success');
							} catch (error: unknown) {
								error instanceof Error &&
									toasterNotify(
										createMessageForError(error, 'submitting order'),
										'error',
										error,
									);
							} finally {
								setOpenModal({});
								setIsSubmitting(false);
								history.push(`/orders/physical/${orderHeaderId}`);
							}
						}
					}}
					title="Confirm order"
					confirmLabel="Submit Order"
					cancelLabel="Cancel"
				>
					<p>
						The price reflected in your Order Detail is an estimate of the total cost of
						your order.
					</p>
					<p>
						A pro-forma invoice will be available to download after your order is
						approved by Nintendo. Your pro-forma for this order will reflect any price
						difference.
					</p>
					<p>
						<span style={{ backgroundColor: '#FFFF33' }}>
							Please include the PO number in wire transfer. Note that you are
							responsible for all wire transfer fees.
						</span>{' '}
						The final amount transferred to Nintendo of America must match the amount
						shown on the pro-forma invoice.
					</p>
					<p>
						Once your order is accepted by NOA, it shall be governed by, and subject to,
						the terms and conditions of your Content and Distribution License Agreement
						for the "Platform" selected when placing the order.
					</p>
					<p>
						You cannot make any changes once your order proceeds to manufacturing.
						Please confirm that you would like to proceed with this order by selecting
						Submit Order below.
					</p>
				</ActionConfirmationModal>
			</Page.FullPageCol>
		</>
	);
};

export default Step3PhysicalOrderConfirmation;
