import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import AsperaFileUpload from '../../../components/AsperaFileUpload/AsperaFileUpload';
import BaseModal from '../../../components/BaseModal/BaseModal';
import Forms from '../../../components/Forms/Forms';
import HelpBlock from '../../../components/HelpBlock/HelpBlock';
import { allowedFileExtensions, fileTransferConst } from '../../../constants/fileTransferConstants';
import { useUserProfile } from '../../../hooks/reduxHooks';
import { doAllFilesHaveExtension } from '../../../utils/assetUtils';
import { toasterNotify } from '../../../utils/toaster';

const UploadDigitalCodesModal = ({show, order, onClose}) => {
	const userProfile = useUserProfile();
	const [fileSelected, setFileSelected] = useState();
	const [validFileSelected, setValidFileSelected] = useState();
	const [isUploadInitiated, setIsUploadInitiated] = useState(false);

	useEffect(() => {
		if (show) {
			setFileSelected(null);
			setIsUploadInitiated(false);
		}
	}, [show]);

	const validateFileType = (selectedFiles) => {
		const allowedFileTypes = allowedFileExtensions.DIGITAL_CODES;
		const isValid = doAllFilesHaveExtension(
			selectedFiles,
			allowedFileTypes
		);

		setValidFileSelected(isValid);

	};

	return (
		<BaseModal show={show} isSubmitting={isUploadInitiated} onCancel={() => onClose()}>
			<BaseModal.Title>Upload Code Batch</BaseModal.Title>
			<Row>
				<Col sm={4}>
					<Forms.Heading>Select file</Forms.Heading>
				</Col>
				<Col sm={8}>
					{show && (
						<AsperaFileUpload
							userProfile={userProfile}
							entityType={fileTransferConst.ENTITY_TYPE.DIGITAL_CODES}
							entityId={String(order.orderID)}
							allowedFileTypes={[
								{
									filter_name: 'Accepted Files',
									extensions: allowedFileExtensions.DIGITAL_CODES,
								},
							]}
							isUploadInitiated={isUploadInitiated}
							onCompleteFunction={() => {
								toasterNotify('File upload initiated', 'success');
								onClose();
							}}
							validateFileType={validateFileType}
							updateFilesSelected={(isFileSelected) =>
								setFileSelected(isFileSelected)
							}
							singleFileOnly
						/>
					)}
					{fileSelected && !validFileSelected && (
						<HelpBlock variant="danger">
							File is not of a valid type. Only zip files are allowed.
						</HelpBlock>
					)}
				</Col>
			</Row>
			<BaseModal.Submit
				onClick={() => {
					if (!fileSelected || !validFileSelected) {
						return;
					}
					setIsUploadInitiated(true);
				}}
			>
				Upload
			</BaseModal.Submit>
		</BaseModal>
	);


};
export default UploadDigitalCodesModal;


