import {
	CHANGE_PLATFORM_LOAD_STATUS,
	SET_PLATFORMS,
	CHANGE_SUNSET_FEATURES_LOAD_STATUS,
	SET_SUNSET_FEATURES,
	CHANGE_EVENT_CATEGORY_LOAD_STATUS,
	SET_EVENT_CATEGORIES,
	CHANGE_HELP_TOPICS_LOAD_STATUS,
	SET_HELP_TOPICS,
	CHANGE_COMPANY_PROFILE_LOAD_STATUS,
	SET_COMPANY_PROFILE,
} from '../actions/action-types';

const metaTemplate = {
	status: null,
	response: {status: null, statusText: null, headers: null, source: null},
	timestamp: null,
	correlationId: null
};

const initialState = {
	platforms: {
		meta: {...metaTemplate},
		content: null
	},
	sunsetFeatures: {
		meta: {...metaTemplate},
		content: null
	},
	eventCategories: {
		meta: {...metaTemplate},
		content: null
	},
	helpTopics: {
		meta: {...metaTemplate},
		content: null
	},
	companyProfile: {
		meta: {...metaTemplate},
		content: null
	},
};

const createMetaObject = (payload: any) => ({
	status: payload.status,
	timestamp: payload.timestamp,
	correlationId: payload.correlationId
});

function referenceReducer(state = initialState, action: { type: string, payload: any }) {
	switch (action.type) {
		case CHANGE_PLATFORM_LOAD_STATUS:
			return {
				...state,
				platforms: {
					...state.platforms,
					content: state.platforms?.content ? state.platforms.content : null,
					meta: createMetaObject(action.payload)
				}
			};
		case SET_PLATFORMS:
			return {
				...state,
				platforms: {
					...state.platforms,
					content: action.payload
				}
			};
		case CHANGE_SUNSET_FEATURES_LOAD_STATUS:
			return {
				...state,
				sunsetFeatures: {
					...state.sunsetFeatures,
					content: state.sunsetFeatures?.content ? state.sunsetFeatures.content : null,
					meta: createMetaObject(action.payload)
				}
			};
		case SET_SUNSET_FEATURES:
			return {
				...state,
				sunsetFeatures: {
					...state.sunsetFeatures,
					content: action.payload
				}
			};
		case CHANGE_EVENT_CATEGORY_LOAD_STATUS:
			return {
				...state,
				eventCategories: {
					...state.eventCategories,
					content: state.eventCategories?.content ? state.eventCategories.content : null,
					meta: createMetaObject(action.payload)
				}
			};
		case SET_EVENT_CATEGORIES:
			return {
				...state,
				eventCategories: {
					...state.eventCategories,
					content: action.payload
				}
			};
		case CHANGE_HELP_TOPICS_LOAD_STATUS:
			return {
				...state,
				helpTopics: {
					...state.helpTopics,
					content: state.helpTopics?.content ? state.helpTopics.content : null,
					meta: createMetaObject(action.payload)
				}
			};
		case SET_HELP_TOPICS:
			return {
				...state,
				helpTopics: {
					...state.helpTopics,
					content: action.payload
				}
			};
		case CHANGE_COMPANY_PROFILE_LOAD_STATUS:
			return {
				...state,
				companyProfile: {
					...state.helpTopics,
					content: state.companyProfile?.content ? state.companyProfile.content : null,
					meta: createMetaObject(action.payload)
				}
			};
		case SET_COMPANY_PROFILE:
			return {
				...state,
				companyProfile: {
					...state.companyProfile,
					content: action.payload
				}
			};
		default:
			return state;
	}
}

export default referenceReducer;
